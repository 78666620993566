
  import Select2 from '@app/components/select2.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { TimesheetQuestionOptions } from '@app/models/question-options/timesheet-question-options';

  @Component({ components: { Select2, EntitySelector, FormField } })
  export default class TimesheetFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<TimesheetQuestionOptions>;

    @Prop(Object) subForm!: SubForm;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['involvement_id', 'timesheet_type'].forEach((key) => this.$delete(this.value.config, key));
    }

    get timesheetTypeOptions() {
      return this.hashToOptions({
        expected: 'Expected',
        actual: 'Actual',
      });
    }

    get involvementFilters() {
      return { module_name: this.subForm.module_name };
    }

    hashToOptions(hash: Dictionary<string>) {
      return Object.keys(hash).map((k) => [k, hash[k]]);
    }
  }
