
  import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import type { ScoringGroup } from '@app/models/scoring-group';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { BaseTable } })
  export default class ModuleScoringGroupsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<ScoringGroup>;
    moduleName: Nullable<ModuleName> = null;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    scoringGroupModalVisible = false;
    manager: Nullable<ListManager<ScoringGroup>> = null;
    selectedScoringGroup: Partial<ScoringGroup> = {};
    activeScoreBandProfiles: Partial<ScoreBandProfile>[] = [];

    getManager(): ListManager<ScoringGroup> {
      return new ListManager<ScoringGroup>({
        fetchDataFunction: (params) => {
          return this.$api.getScoringGroups(
            {
              ...params,
              filters: {
                module_name: this.moduleName?.name,
                default: false,
              },
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          { title: this.$t('tenant.admin.scoring_groups.table_head.index'), name: 'index', sortField: 'index' },
          { title: this.$t('tenant.admin.scoring_groups.table_head.title'), name: 'title', sortField: 'title' },
          { title: this.$t('tenant.admin.scoring_groups.table_head.code'), name: 'code', sortField: 'code' },
          { title: this.$t('tenant.admin.scoring_groups.table_head.display_method'), name: 'display_method', sortField: 'display_method' },
          { title: this.$t('tenant.admin.scoring_groups.table_head.active'), name: 'active', sortField: 'active' },
        ],
      });
    }

    async mounted(): Promise<void> {
      this.manager = this.getManager();
      const { data } = await this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true });
      this.moduleName = data;
    }

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item) => item.index).sort();
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateScoringGroupIndexes({ data, sort })
          .then(({ data }) => {
            this.$api.cache.clear();
            toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }
  }
