
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import EntitySelector from '../entity-selector.vue';
  import Select2 from '../select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Relationship } from '@app/models/relationship';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';

  @Component({ components: { EntitySelector, DsModal, Select2, ValidationObserver, ValidationProvider } })
  export default class RelationshipFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly relationship!: Relationship;
    @Prop(String) readonly title!: string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Relationship> = {};
    toModuleMissing = false;

    codeRegex = /^[\w]+$/;

    disableFromModule = false;

    get modalTitle(): string {
      if (this.relationship.id) {
        return this.$t('app.labels.edit_relationship');
      } else {
        return this.$t('app.labels.add_new_relationship');
      }
    }

    get disableToModule(): boolean {
      return !!this.form.to_module && !!this.form.id && !this.toModuleMissing;
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    get canDelete(): boolean {
      return !!this.relationship.id && !this.relationship.active;
    }

    deleteRelationship(): void {
      if (this.canDelete) {
        if (confirm(this.$t('app.labels.confirm_delete_relationship').toString())) {
          this.$emit('delete', this.relationship);
        }
      }
    }

    changeActiveStatus(): void {
      this.form.active = !this.relationship.active;
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    initForm(): void {
      this.form = { ...this.relationship };
      this.disableFromModule = !!this.form.from_module;
      this.toModuleMissing = false;
      if (this.form.id && this.form.to_module) {
        this.$api
          .getModuleNames({ filters: { name: this.form.to_module }, only: ['name', 'display'] }, { cache: true })
          .then(({ data }) => {
            if (!data.length) {
              this.toModuleMissing = true;
            }
          });
      }
    }

    get moduleNameFilter(): object {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES,
      };
    }
  }
