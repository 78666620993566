
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { uniq } from 'lodash';
  import type { SuperReportGraphData, SuperReportGraphItem } from '@app/models/super-report';

  @Component({
    components: {},
  })
  export default class SuperReportDataTab extends Vue {
    @Prop() readonly reportData!: SuperReportGraphData;

    dataItem(row: SuperReportGraphItem, key: string): string | null {
      return Object.fromEntries(row.data.map(([k, v]) => [k, v]))[key] as string;
    }
    get graphData(): SuperReportGraphItem[] {
      return this.reportData.graph_data;
    }

    get columnKeys(): string[] {
      return uniq(
        this.graphData.reduce<string[]>((memo, dataItem) => {
          return memo.concat(dataItem.data.map((item) => item[0]));
        }, []) || []
      );
    }
  }
