
  import { Component } from 'vue-property-decorator';
  import BaseEventForm from '@app/components/admin/events/forms/base-event-form';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import { get } from 'lodash';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import EventInputMethodSwitch from '@app/components/admin/events/event-input-method-switch.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';

  interface RemovalFormValues {
    confidentiality_type_id: string;
    event_behavior: string;
    lockable_id: string;
    user_id?: number;
  }

  @Component({ components: { AdvancedAutosuggest, EventInputMethodSwitch, Select2, EntitySelector, FormField } })
  export default class RemoveConfidentialityEventForm extends BaseEventForm<RemovalFormValues> {
    behaviorChosen: Nullable<'single' | 'all'> = null;

    get behaviorOptions(): [string, string][] {
      return [
        ['all', this.$t('tenant.admin.events.form_fields.remove_all_confidential')],
        ['single', this.$t('tenant.admin.events.form_fields.remove_single_confidential')],
      ];
    }

    get confidentialityTypesFilters(): DonesafeFilterOptions<ConfidentialityType> {
      return {
        active: true,
      };
    }

    beforeMount(): void {
      this.form = { ...this.values };
      if (this.form.lockable_id !== undefined) {
        this.behaviorChosen = this.values?.user_id ? 'single' : 'all';
      }
    }

    groupByModuleName(option: Pick<ConfidentialityType, 'id' | 'name' | 'module_name'>): string {
      return `${get(option, 'module_name.display')} (${get(option, 'module_name.name')})`;
    }

    onBehaviorSelect(behavior: 'all' | 'single'): void {
      this.behaviorChosen = behavior;
      delete this.form.user_id;
    }
  }
