
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: { EntitySelector },
  })
  export default class ModuleBudgetsPage extends Vue {
    @Prop(Number) readonly moduleNameId!: number;

    moduleBudgetIds: string[] = [];

    submit(): void {
      this.$api
        .updateModuleName(Number(this.moduleNameId), { expensable_expensing_table_ids: this.moduleBudgetIds })
        .then(() => {
          toaster({ text: this.$t('app.labels.feature_set_saved') });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, icon: 'error' });
        });
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(
        this.moduleNameId,
        { only: [{ module_budgets: ['expensable_expensing_table_id', 'active'] }] },
        { cache: true }
      );
      this.moduleBudgetIds =
        data.module_budgets?.filter((budget) => budget.active).map((budget) => budget.expensable_expensing_table_id) || [];
    }
  }
