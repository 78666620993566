
  import QrCodeModal from '@app/components/module-record/qr-code-modal.vue';
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component({ components: { QrCodeModal } })
  export default class ModuleRecordQrCodeLinkModalButton extends Vue {
    @Prop({ type: String, default: () => 'li' }) readonly tag!: string;
    @Prop(String) readonly data!: string;
    @Prop(String) readonly title?: string;

    modalVisible = false;
  }
