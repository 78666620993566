
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Video } from '@app/models/video';

  @Component({})
  export default class VideoItem extends Vue {
    @Prop(Object) readonly video!: Video;
    @Prop(Boolean) readonly canEdit!: boolean;

    get accountStore() {
      return useAccountStore();
    }

    userName(video: Video): string | undefined {
      return video.user?.full_name;
    }
  }
