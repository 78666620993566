
  import bootbox from 'bootbox';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';

  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { WorkplaceIndustry } from '@app/models/workplace-industry';

  @Component({
    components: {
      DsModal,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class WorkplaceIndustryFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Boolean) readonly disabled?: boolean;

    form: Partial<WorkplaceIndustry> = {};

    beforeMount(): void {
      this.reset();
    }

    get existingRecord(): boolean {
      return !!this.form?.id;
    }

    get action(): string {
      return this.existingRecord ? this.$t('app.buttons.update') : this.$t('app.buttons.save');
    }

    get title(): string {
      return this.existingRecord
        ? this.$t('tenant.admin.settings.menus.user_settings.edit.workplace_industry')
        : this.$t('tenant.admin.settings.menus.user_settings.add.workplace_industry');
    }

    archiveOrRestore(workplaceIndustry: Partial<WorkplaceIndustry>, active: boolean): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        backdrop: false,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.submit({ ...this.form, active });
        },
      });
    }

    reset(workplaceIndustry?: Partial<WorkplaceIndustry>): void {
      this.form = { ...workplaceIndustry };
      this.validator?.reset();
    }

    submit(form: Partial<WorkplaceIndustry>): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', form);
      });
    }
  }
