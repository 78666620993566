
  import bootbox from 'bootbox';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';

  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { EmploymentType } from '@app/models/employment-type';

  @Component({
    components: {
      DsModal,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class EmploymentTypeFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Boolean) readonly disabled?: boolean;

    form: Partial<EmploymentType> = {};

    beforeMount(): void {
      this.reset();
    }

    get existingRecord(): boolean {
      return !!this.form?.id;
    }

    get action(): string {
      return this.existingRecord ? this.$t('app.buttons.update') : this.$t('app.buttons.save');
    }

    get title(): string {
      return this.existingRecord
        ? this.$t('tenant.admin.settings.menus.user_settings.edit.employment_type')
        : this.$t('tenant.admin.settings.menus.user_settings.add.employment_type');
    }

    archiveOrRestore(active: boolean): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.submit({ ...this.form, active });
        },
      });
    }

    reset(employmentType?: Partial<EmploymentType>): void {
      this.form = { ...employmentType };
      this.validator?.reset();
    }

    submit(form: Partial<EmploymentType>): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', form);
      });
    }
  }
