
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import PersonalDashboardPage from '@app/pages/personal-dashboard-page.vue';
  import type { Dashboard } from '@app/models/dashboard';
  import type { Widget } from '@app/models/widget';
  import { hasActiveNonEmptyPanesGuardFactory } from '@app/services/guards';

  import DashboardGrid from '../dashboard-grid.vue';

  @Component({ components: { DashboardGrid, PersonalDashboardPage } })
  export default class DashboardsShowPage extends Vue {
    @Prop({ type: [String, Number] }) readonly dashboardId!: string | number;

    dashboard: Nullable<Dashboard> = null;

    get widgets(): Widget[] {
      return this.dashboard?.widgets || [];
    }

    async beforeMount(): Promise<void> {
      const { data: dashboard } = await this.$api.getDashboard(
        Number(this.dashboardId),
        { only: ['widgets', 'active', 'dynamic', 'name', 'show_widget_border'] },
        { cache: true }
      );

      this.dashboard = dashboard;
      dashboard.dynamic && hasActiveNonEmptyPanesGuardFactory()(this.$route, this.$route, () => {});
    }
  }
