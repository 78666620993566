
  import ModuleTabForm from '@app/components/admin/module-tabs/module-tab-form.vue';
  import Blocking from '@app/mixins/blocking';
  import bootbox from 'bootbox';
  import { mixins } from 'vue-class-component';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleTab } from '@app/models/module-tab';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  // TODO: show/hide delete button
  @Component({ components: { ModuleTabForm } })
  export default class ModuleTabEditPage extends mixins(Blocking) {
    @Prop({ type: [String, Number] }) readonly moduleTabId!: string | number;
    @Ref() readonly form?: ModuleTabForm;
    moduleTab: ModuleTab | null = null;

    get isHybrid(): boolean {
      return !!this.moduleTab && HYBRID_MODULE_TAB_CODE === this.moduleTab.code;
    }

    get moduleName(): Pick<ModuleName, 'id' | 'name'> | null {
      return this.moduleTab?.related_module_name ?? null;
    }

    beforeMount(): void {
      this.$api.getModuleTab(Number(this.moduleTabId), { include: ['related_module_name'] }, { cache: true }).then(({ data }) => {
        this.moduleTab = data;
      });
    }

    deleteModuleTab(): void {
      this.moduleTab &&
        bootbox.confirm({
          size: 'small',
          backdrop: false,
          message: this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            result &&
              this.moduleTab &&
              this.$api
                .deleteModuleTab(this.moduleTab.id)
                .then(() => {
                  changeLocation(`/admin/settings/module_names/${this.moduleName?.id}/module_tabs`);
                })
                .catch(({ data }) => {
                  toaster({ text: data?.error, position: 'top-right', icon: 'error' });
                });
          },
        });
    }

    submit(moduleTab: ModuleTab): void {
      this.blocking(async () => {
        await this.$api.updateModuleTab(moduleTab.id, moduleTab).then(({ data }) => {
          this.$api.cache.clear();
          this.$router.push({ name: 'admin-module-tab-redirect', params: { moduleTabId: `${data.id}` } });
        });
      });
    }
  }
