
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { RecordParams } from '@app/services/api/rule-sets-api';
  import { isEmpty } from 'lodash';
  import type { Subscription } from 'rxjs';
  import { Subject } from 'rxjs';
  import { switchMap } from 'rxjs/operators';
  import { Component, Prop } from 'vue-property-decorator';
  import EntitySelector from '../../entity-selector.vue';
  import Select2 from '../../select2.vue';
  import UserSelector from '../../user/user-selector.vue';
  import RecordSelector from '../../record-selector.vue';
  import BaseForEverySelector from './base-for-every-selector';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import type { Event } from '@app/models/event';
  import { NEW_COMPLETION_COLLECTIONS_PREFIX, NEW_RECORD_COLLECTIONS_PREFIX } from '@app/models/event';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  interface UserCountRequest {
    injectableArguments: Dictionary<string>;
    rules?: QueryBuilderBlob;
  }

  @Component({ components: { Select2, UserSelector, RecordSelector, EntitySelector } })
  export default class ScheduledEventForEverySelector extends BaseForEverySelector {
    @Prop(Object) event!: Event;
    @Prop(Boolean) readonly submitting!: boolean;

    testModuleRecordId: Nullable<string> = null;
    testCompletionId: Nullable<string> = null;
    testUserId: Nullable<string | number> = null;
    countValue: Nullable<number> = null;

    userCountUpdate$ = new Subject<Maybe<UserCountRequest>>();
    userCountUpdateSubscription: Nullable<Subscription> = null;

    get showRecordRuleSets(): boolean {
      return this.everyOptionType === 'record_collections' || this.everyOptionType === 'completion_collections';
    }

    get countLabel(): string {
      if (this.showRecordRuleSets) {
        return this.$t('tenant.admin.events.form_fields.count_records');
      } else {
        return this.$t('tenant.admin.events.form_fields.count_users');
      }
    }

    get testRecordFilters(): DonesafeFilterOptions<ModuleRecord> {
      const filters: DonesafeFilterOptions<ModuleRecord> = {};
      if (this.everyOptionType === 'record_collections') {
        filters.module_name_id = this.value?.replace(NEW_RECORD_COLLECTIONS_PREFIX, '');
      } else if (this.everyOptionType === 'completion_collections') {
        const subFormId = this.value?.replace(NEW_COMPLETION_COLLECTIONS_PREFIX, '');
        const subForm = this.subForms.find((subForm) => `${subForm.id}` === `${subFormId}`);
        if (subForm) {
          filters.module_name = { name: subForm?.module_name };
        }
      }

      return filters;
    }

    get testSubFormCompletionsFilters(): DonesafeFilterOptions<SubFormCompletion> {
      if (!this.testModuleRecordId) {
        return {};
      }

      return {
        sub_form_id: this.value?.replace(NEW_COMPLETION_COLLECTIONS_PREFIX, ''),
        record_id: +this.testModuleRecordId,
        record_type: 'ModuleRecord',
      };
    }

    get testParams(): Maybe<RecordParams> {
      if (this.testDisabled) return;

      switch (this.everyOptionType) {
        case 'record_collections':
          return {
            record_type: 'ModuleRecord',
            record_id: Number(this.testModuleRecordId),
          };
        case 'completion_collections':
          return {
            record_type: 'SubFormCompletion',
            record_id: Number(this.testCompletionId),
          };
        default:
          return {
            record_type: 'TenantUser',
            record_id: Number(this.testUserId),
          };
      }
    }

    get everyOptionType(): 'user_collections' | 'record_collections' | 'completion_collections' {
      if (this.value?.startsWith(NEW_RECORD_COLLECTIONS_PREFIX)) {
        return 'record_collections';
      } else if (this.value?.startsWith(NEW_COMPLETION_COLLECTIONS_PREFIX)) {
        return 'completion_collections';
      }

      return 'user_collections';
    }

    get testRecordDisabled(): boolean {
      return isEmpty(this.testRecordFilters);
    }

    get testDisabled(): boolean {
      return (
        (this.everyOptionType === 'record_collections' && !this.testModuleRecordId) ||
        (this.everyOptionType === 'completion_collections' && (!this.testModuleRecordId || !this.testCompletionId)) ||
        this.submitting
      );
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    onSelectPerOption(value: string): void {
      this.$emit('input', value);
      this.$nextTick(() => this.requestUpdateCountValue());
    }

    requestUpdateCountValue(userCountRequest?: UserCountRequest): void {
      this.userCountUpdate$.next(userCountRequest);
    }

    updateCountValue(userCountRequest?: UserCountRequest): Promise<{ total: number | null }> {
      if (!this.value) Promise.reject('everyOption could not be blank');

      return this.$api
        .evaluateCollection(
          {
            per: this.value,
            injectable_arguments: userCountRequest?.injectableArguments,
            rules: userCountRequest?.rules,
          },
          { cache: true }
        )
        .then(({ data }) => data)
        .catch(() => ({ total: null }));
    }

    async beforeMount() {
      if (!!this.currentUserStore.data?.id) this.testUserId = this.currentUserStore.data?.id;

      this.userCountUpdateSubscription = this.userCountUpdate$.pipe(switchMap((request) => this.updateCountValue(request))).subscribe({
        next: (data) => (this.countValue = data.total),
        error: (error) => console.error('userCountUpdate request failed', error),
      });

      this.requestUpdateCountValue();
    }

    beforeDestroy(): void {
      this.userCountUpdateSubscription?.unsubscribe();
    }
  }
