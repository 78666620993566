
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class InputGroup extends Vue {
    @Prop(String) value!: string;
    @Prop(String) before?: string;
    @Prop(String) after?: string;

    get hasBefore() {
      return !!this.before || !!this.$slots['before'];
    }

    get hasAfter() {
      return !!this.after || !!this.$slots['after'];
    }

    get hasAddons() {
      return this.hasBefore || this.hasAfter;
    }
  }
