
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import ConfigValues from './shared/config-values.vue';
  import Scored from './shared/scored.vue';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';
  import type { ColorViewModeQuestionOptions } from '@app/models/question-options/color-view-mode-question-options';

  @Component({ components: { ConfigValues, Scored } })
  export default class RadioShowOptions extends BaseShowOptions<BaseSelectQuestionOptions & ColorViewModeQuestionOptions> {}
