import type { BaseEntity } from './base-entity';
import type { RecurringSetup } from './recurring';
import type { Role } from './role';
import type { SubFormList } from './sub-form-list';

export interface ReportBlobFilter {
  comparison: string;
  include_blank?: boolean;
  key: string;
  type: string;
  value: string;
}

export enum ColumnType {
  AddressResponse = 'address_response',
  ApprovalResponse = 'approval_response',
  ApprovalStage = 'approval_stage',
  Confidentiality = 'confidentiality',
  DateRecordField = 'date_record_field',
  DateResponse = 'date_response',
  ExpenseResponse = 'expense_response',
  HybridResponse = 'hybrid_response',
  Importable = 'importable',
  Indicator = 'indicator',
  Involvement = 'involvement',
  MatrixResponse = 'matrix_response',
  RecordCalculation = 'record_calculation',
  RecordField = 'record_field',
  RefReportingResponse = 'ref_reporting_response',
  RelatedRecordField = 'related_record_field',
  Response = 'response',
  ResponseCounter = 'response_counter',
  ScoreBand = 'score_band',
  ScoreBandCode = 'score_band_code',
  ScoreBandProfile = 'score_band_profile',
  ScoreGroupBand = 'score_group_band',
  ScoringGroup = 'scoring_group',
  ToocsResponse = 'toocs_response',
}

export enum ColumnSource {
  Record = 'record',
}

export interface ReportColumnBase {
  column_type: ColumnType;
  key: string;
  source?: ColumnSource;
}

export interface ReportColumn extends ReportColumnBase {
  archived: boolean;
  column_name: string;
  display: string;
  id: string;
  method_chain: string[];
  preload: string[];
  type: string;
}

export interface ReportBlobGraph {
  count?: string | null;
  group_by_1?: ReportColumnBase;
  group_by_2?: ReportColumnBase;
  include_blanks?: boolean;
  stacked?: boolean;
  target: string[][];
  type: string;
}

export interface BlobColumn extends ReportColumnBase {
  column_name: string;
}

export type ReportBlob = {
  filters: ReportBlobFilter[];
  graph: ReportBlobGraph;
  klass: string;
  super_report_model_id: string;
  table: {
    columns: BlobColumn[];
  };
} & Record<string, unknown>;

export function isColumnsEqual(left: ReportColumnBase, right: ReportColumnBase): boolean {
  return String(left.key) === String(right.key) && left.column_type === right.column_type && left.source === right.source;
}

export const KLASS_MONGO_TRAILS_VERSION = 'MongoTrails::Version';

export interface SuperReport extends BaseEntity {
  datetime_output_format: string;
  description: string;
  encrypted: boolean;
  is_exportable: boolean;
  is_public: boolean;
  machine_name: string;
  name: string;
  options: object;
  recurring_setup?: RecurringSetup;
  report_blob: ReportBlob;
  roles?: Role[];
  sub_form_lists?: SubFormList[];
}

export interface SuperReportTotal {
  target_total: number;
  total: number;
}

export interface SuperReportGraphItem {
  data: [string, string | number][];
  name?: string;
}

export interface SuperReportGraphData {
  colors: string[];
  graph_data: SuperReportGraphItem[];
  map_informations?: [number, number][];
  stacked: boolean;
  type: string;
}

export enum BaseConcept {
  Activity = 'Activity',
  Calculation = 'Calculation',
  Comment = 'Comment',
  Document = 'Document',
  Location = 'Location',
  ModuleRecord = 'ModuleRecord',
  Organization = 'Organization',
  ProcedureAcknowledgment = 'ProcedureAcknowledgment',
  RecordBudgetCategory = 'Expensable::RecordBudgetCategory',
  Relation = 'Relation',
  User = 'User',
  UserInvolvement = 'UserInvolvement',
  UserLocationTag = 'UserLocationTag',
}

export interface SuperReportData {
  columns: string[];
  rows: string[];
}
