
  import SubFormListItem from '../sub-form-lists/sub-form-list-item.vue';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import ModuleTabControlPanel from '@app/components/admin/module-tab/module-tab-control-panel.vue';
  import type { MtSflOnly } from './utils';
  import { MT_SFL_ONLY } from './utils';
  import type { ModuleTab } from '@app/models/module-tab';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Draggable, SubFormListItem, ModuleTabControlPanel } })
  export default class TabSectionsListPage extends Vue {
    @Prop() readonly moduleTabId!: number;

    moduleTab: Partial<ModuleTab> = {};
    subFormLists: Pick<SubFormList, MtSflOnly>[] = [];

    beforeMount(): void {
      this.$api.getModuleTab(this.moduleTabId, { include: ['related_module_name'] }, { cache: true }).then(({ data }) => {
        this.moduleTab = data;
      });
      this.getSubFormsLists();
    }

    get isHybridModule(): boolean {
      return !!this.moduleTab.hybrid_module;
    }

    get moduleName(): Nullable<ModuleName> {
      return this.moduleTab.related_module_name ?? null;
    }

    changeActive(active: boolean): void {
      this.$api.updateModuleTab(this.moduleTabId, { active }, { include: ['related_module_name'] }).then(({ data }) => {
        this.$api.cache.clear();
        this.moduleTab = data;
      });
    }

    subFormListKey(subFormList: Pick<SubFormList, MtSflOnly>): string {
      return `${subFormList.id}_${subFormList.updated_at}_${subFormList.sub_form_ids?.join('|') || ''}`;
    }

    getSubFormsLists(): void {
      this.$api
        .getSubFormLists(
          {
            filters: {
              module_tab_id: this.moduleTabId,
            },
            only: MT_SFL_ONLY,
            per_page: -1,
            sort: 'index',
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.subFormLists = data;
        });
    }

    dragEnd(): void {
      this.updateIndex();
    }

    updateIndex(): void {
      const data = this.subFormLists.map((item, index) => ({ id: item.id, index: index }));
      this.$api
        .updateSubFormListIndexes({ data }, { only: MT_SFL_ONLY })
        .then(() => {
          toaster({
            text: this.$t('app.labels.order_saved'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.getSubFormsLists();
      }
    }
  }
