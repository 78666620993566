
  import type { GeneratedFilterOption } from '@app/components/admin/questions/filter-configurator/utils';
  import { generateQuestionFilters } from '@app/components/admin/questions/filter-configurator/utils';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';
  import { isReadonlyLookup } from '@app/services/model-helpers';
  import { flatten } from 'lodash';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  import type { FilterOption } from './model';
  import FilterConfigurator from './filter-configurator.vue';

  @Component({ components: { FilterConfigurator } })
  export default class CompletionFilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly relatedSubForm?: SubForm;
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(Object) readonly currentQuestion?: SubFormQuestion;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noCurrentUser?: boolean;
    @Prop(Boolean) readonly noReverse?: boolean;

    get configOptions(): GeneratedFilterOption[] {
      const defaultOptions: FilterOption[] = [
        {
          type: 'completion',
          label: 'ID',
          multiple: true,
          key: 'id',
          filters: {
            // TODO: keep this empty for now to prevent selection of completions as it will be very confusing
          },
        },
        {
          type: 'datetime',
          label: 'Created at',
          key: 'created_at',
          options: [
            { key: 'week', value: 'This Week' },
            { key: 'today', value: 'Today' },
            { key: 'month', value: 'This Month' },
            { key: 'year', value: 'This Year' },
          ],
        },
        {
          type: 'select',
          label: 'State',
          key: 'stage',
          options: [
            { key: 'Complete', value: SubFormCompletionStage.Complete },
            { key: 'Draft', value: SubFormCompletionStage.Draft },
          ],
        },
        {
          type: 'user',
          label: 'Creator',
          multiple: true,
          key: 'user_id',
          allowNull: true,
        },
      ];
      const formQuestions = (
        flatten<SubFormQuestion<BaseQuestionOptions>>(
          this.relatedSubForm?.sub_form_sections?.map((s: SubFormSection) => s.sub_form_questions || [])
        ) || []
      )?.filter((q) => !isReadonlyLookup(q));

      const questionOptions = generateQuestionFilters(formQuestions, this.$api, this.currentQuestion);

      return [...defaultOptions, ...questionOptions];
    }
  }
