import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import type { ModuleName } from '@app/models/module-name';
import type { SubForm } from '@app/models/sub-form';
import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import { isUserCollection } from '@app/utils/is-user-collection';
import { NEW_COMPLETION_COLLECTIONS_PREFIX, NEW_RECORD_COLLECTIONS_PREFIX, NEW_USER_COLLECTION } from '@app/models/event';

interface PerOption {
  group?: string;
  label: string;
  value: string;
}

@Component
export default class BaseForEverySelector extends Vue {
  @Model('input') readonly value!: string;

  @Prop(Array) readonly options!: [string, string][];
  @Prop(Boolean) readonly hasTriggeringRecord?: boolean;
  @Prop(String) readonly inputId?: string;
  @Prop(Array) readonly preloadModuleNames?: ModuleName[];
  @Prop(Array) readonly preloadSubForms?: SubForm[];
  @Prop(Boolean) readonly globalUserCollection!: boolean;

  moduleNames: Pick<ModuleName, 'display' | 'name' | 'id'>[] = [];
  subForms: Pick<SubForm, 'id' | 'title' | 'module_name'>[] = [];

  get userCollectionPrefix(): string {
    return /^\d+$/.test(this.value) && !this.globalUserCollection ? this.value : NEW_USER_COLLECTION;
  }

  get perOptions(): PerOption[] {
    const blankValue = this.hasTriggeringRecord
      ? [{ value: '', label: this.$t('tenant.admin.events.form_fields.trigger').toString() }]
      : [];

    const userCollections = this.options
      .filter(([, value]) => isUserCollection(value))
      .map(([key, value]) => ({
        value: value,
        label: key,
        group: this.$t('tenant.admin.events.form_fields.user_collections').toString(),
      }));

    const newUserCollection = [
      {
        value: this.userCollectionPrefix,
        label: this.$t('tenant.admin.events.form_fields.quick_user_collection').toString(),
        group: this.$t('tenant.admin.events.form_fields.user_collections').toString(),
      },
    ];

    const multiValuedOptions = this.options
      .filter(([, value]) => {
        return (
          `${value}`.startsWith('#records_~records_') ||
          `${value}`.startsWith('~records_') ||
          `${value}`.startsWith('#records_~related_') ||
          `${value}`.startsWith('~related_')
        );
      })
      .map(([key, value]) => ({
        value: value,
        label: key,
        group: this.$t('tenant.admin.events.form_fields.record_collections').toString(),
      }));

    const recordCollections = this.moduleNames.map((moduleName) => ({
      value: `${NEW_RECORD_COLLECTIONS_PREFIX}${moduleName.id}`,
      label: `Record in "${moduleName.display}" module where...`,
      group: this.$t('tenant.admin.events.form_fields.record_collections').toString(),
    }));

    const subFormCollections = this.subForms.map((subForm) => ({
      value: `${NEW_COMPLETION_COLLECTIONS_PREFIX}${subForm.id}`,
      label: `All Records in "${this.moduleNames.find((mn) => mn.name === subForm.module_name)?.display || this.$t('app.labels.na')}: ${
        subForm.title
      }" where...`,
      group: this.$t('tenant.admin.events.form_fields.completion_collections').toString(),
    }));

    // eslint-disable-next-line prettier-vue/prettier
    return [
      ...blankValue,
      ...userCollections,
      ...multiValuedOptions,
      ...newUserCollection,
      ...recordCollections,
      ...subFormCollections
    ];
  }

  async loadModuleNames() {
    if (this.preloadModuleNames) {
      this.moduleNames = [...this.preloadModuleNames];
    } else {
      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          filters: { active: true, '!name': HARDCODED_MODULE_CODES.join(',') } as DonesafeFilterOptions<ModuleName>,
          per_page: -1,
          only: ['id', 'name', 'display'],
        },
        { cache: true }
      );
      this.moduleNames = [...moduleNames];
    }
  }

  async loadSubForms() {
    if (this.preloadSubForms) {
      this.subForms = [...this.preloadSubForms];
    } else {
      const { data: subForms } = await this.$api.getSubForms({
        filters: { active: true, module_name: this.moduleNames.map(({ name }) => name).join(',') },
        per_page: -1,
        only: ['id', 'title', 'module_name'],
        cache: true,
      });
      this.subForms = [...subForms];
    }
  }

  async beforeMount() {
    this.loadModuleNames();
    if (!this.moduleNames.length) return;
    this.loadSubForms();
  }
}
