
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import VClamp from 'vue-clamp';
  import DsModal from '@app/components/ds-modal.vue';
  import VJsoneditor from 'v-jsoneditor';

  const MAXIMUM_LINES = 3;

  @Component({ components: { VClamp, VJsoneditor, DsModal } })
  export default class MessageCell extends Vue {
    @Model('input') readonly value!: Nullable<string>;
    @Prop({ type: Number, default: MAXIMUM_LINES }) readonly maxLines!: number;

    jsonViewer = false;
    viewerOptions = {
      mode: 'preview',
      modes: ['tree', 'preview'], // allowed modes
    };

    get hasJsonValue() {
      if (!this.value) {
        return false;
      }

      try {
        JSON.parse(this.value);
      } catch (err) {
        return false;
      }

      return true;
    }
  }
