import type { SubFormData, SubFormCompletionExtraParameters } from '@app/services/api/sub-form-completions-api';
import { debounce, isEqual } from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import type { WithSfcSubmitOnly, SubFormSfcFormOnly } from '@app/components/sub-form-completion/utils';
import { WITH_SFC_SUBMIT_ONLY } from '@app/components/sub-form-completion/utils';
import type { SubFormListOnly } from '@app/pages/module-records/utils';
import type { SubForm } from '@app/models/sub-form';
import { SubFormType } from '@app/models/sub-form';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import type { SubFormList } from '@app/models/sub-form-list';
import { SubFormCompletionStage } from '@app/models/sub-form-completion';
import { onRecordSubmitError } from '@app/utils/on-record-submit-error';

@Component
export default class WithSubFormCompletionSubmit extends Vue {
  subFormCompletion: Nullable<Partial<SubFormCompletion>> = null;
  subFormList: Nullable<Pick<SubFormList, SubFormListOnly>> = null;
  subForm: Nullable<Pick<SubForm, SubFormSfcFormOnly>> = null;

  loading = false;
  savedForm: SubFormData = {};
  debounceCompletionUpdate = debounce(this.onUpdate, 3000);
  loadingStates: Record<number, boolean> = {};

  get shouldAutoSave(): boolean {
    return (
      !!this.subFormList?.auto_save &&
      this.subFormCompletion?.stage === SubFormCompletionStage.Draft &&
      this.subForm?.sub_form_type !== SubFormType.approval &&
      this.subForm?.sub_form_type !== SubFormType.workflow
    );
  }

  onCompletionSubmit(
    {
      responses,
      stage,
      form_uuid,
      attachments,
      related_activities,
      related_records,
      addAnother,
      form_component_id,
    }: SubFormCompletionExtraParameters & {
      addAnother?: boolean;
      stage?: SubFormCompletionStage;
    },
    onSubmitComplete: (subFormCompletion: Pick<SubFormCompletion, WithSfcSubmitOnly>, addAnother?: boolean) => void
  ): void {
    this.loading = true;
    this.debounceCompletionUpdate.cancel();

    if (this.subFormCompletion) {
      const { sub_form_list_id, sub_form_id, record_id, record_type, approval_for_sub_form_completion_id } = this.subFormCompletion;
      const lookup =
        this.subFormList?.auto_save &&
        this.subForm?.sub_form_type !== SubFormType.approval &&
        this.subForm?.sub_form_type !== SubFormType.workflow &&
        stage === SubFormCompletionStage.Draft;

      const promise = this.subFormCompletion.id
        ? this.$api.updateSubFormCompletion(
            this.subFormCompletion.id,
            {
              responses,
              stage,
              form_uuid,
              attachments,
              related_activities,
              related_records,
              lookup,
            },
            {
              only: WITH_SFC_SUBMIT_ONLY,
            }
          )
        : this.$api.createSubFormCompletion(
            {
              responses,
              stage,
              sub_form_list_id,
              sub_form_id,
              record_id,
              record_type,
              approval_for_sub_form_completion_id,
              attachments,
              related_activities,
              related_records,
              form_component_id,
              lookup,
            },
            {
              only: WITH_SFC_SUBMIT_ONLY,
            }
          );

      promise
        .then(({ data }) => {
          return onSubmitComplete(data, addAnother);
        })
        .catch(({ data }) => {
          onRecordSubmitError(data);
        })
        .finally(() => (this.loading = false));
    }
  }

  onUpdate({
    responses,
    form_uuid,
    attachments,
    related_activities,
    related_records,
    form_component_id,
    onUpdateComplete,
    submitFinally,
  }: SubFormCompletionExtraParameters & {
    onUpdateComplete: () => void;
    submitFinally: () => void;
  }): void {
    if (Object.values(this.loadingStates).some(Boolean)) {
      return;
    }

    if (
      this.subFormCompletion?.id &&
      this.shouldAutoSave &&
      (!isEqual(responses, this.savedForm) || attachments || related_activities || related_records)
    ) {
      this.$api
        .updateSubFormCompletion(this.subFormCompletion.id, {
          responses,
          form_uuid,
          attachments,
          related_activities,
          related_records,
          lookup: true,
          form_component_id,
        })
        .then(() => {
          this.savedForm = { ...responses };
          onUpdateComplete();
        })
        .catch(({ data }) => {
          onRecordSubmitError(data);
        })
        .finally(submitFinally);
    }
  }
}
