
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import { get } from 'lodash';
  import { Component, Ref } from 'vue-property-decorator';
  import ModuleRecordsTable from '../module-record/module-records-table.vue';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import { mixins } from 'vue-class-component';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { DefaultTemplate } from '@app/models/default-template';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { buildLink } from '@app/utils/build-link';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { textColor } from '@app/utils/text-color';

  @Component({ components: { ModuleRecordsTable } })
  export default class RecordRelationsTab extends mixins(SubFormListBase, ModuleRecordModals) {
    @Ref() readonly moduleRecordsTable!: InstanceType<typeof ModuleRecordsTable>;

    relatedModuleName: Nullable<ModuleName> = null;
    relatedQuestion: Nullable<SubFormQuestion> = null;
    defaultTemplate: Nullable<Pick<DefaultTemplate, 'id' | 'system_code'>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get filters(): DonesafeFilterOptions<ModuleRecord> {
      if (!this.relatedQuestion) {
        return {};
      }

      return {
        module_name_id: this.relatedModuleName?.id,
        [this.relatedQuestion.code]: this.record.id,
        ...convertInFormFilters(this.subFormList.record_relations_filters, { user: this.currentUserStore.data }),
      };
    }

    get addNewButtonStyles(): string {
      if (this.subFormList.add_background_color) {
        return `
          color: ${textColor(this.subFormList.add_background_color)} !important;
          background-color: ${this.subFormList.add_background_color} !important;
        `;
      }
      return '';
    }

    get recordLinkBehaviour(): RecordLinkBehaviour {
      return this.subFormList.options?.record_link_behaviour || RecordLinkBehaviour.GoToRecord;
    }

    get indexOptions(): string[] {
      if (this.subFormList.relationship_options?.index_options?.length) {
        return this.subFormList.relationship_options.index_options;
      }
      return this.relatedModuleName?.index_options.map((o) => o.key) || [];
    }

    get sort(): string | undefined {
      return this.subFormList.relationship_options?.sort || this.indexOptions[0];
    }

    get reverse(): boolean {
      return this.subFormList.relationship_options?.sort ? this.subFormList.relationship_options.reverse === 'true' : true;
    }

    get canCreate(): boolean {
      if (this.relatedModuleName) {
        return !!get(this.currentUserStore.data?.acl, ['module', this.relatedModuleName.name, 'create']);
      }
      return false;
    }

    get openInModal(): boolean {
      return 'true' === this.subFormList.relationship_options?.create_main_form_in_modal;
    }

    get addNewRecordLink(): string {
      const params = {
        module_name_id: this.relatedModuleName?.id,
        main_form_id: this.record.id,
        default_template_id: this.defaultTemplate?.system_code,
      };

      return buildLink('/module_records/new', params);
    }

    onRecordCreate() {
      this.$api.cache.clear();
      this.moduleRecordsTable.refreshTable();
    }

    addNewAction(event: MouseEvent): void {
      this.openModalOrLink({
        modal: this.openInModal,
        link: this.addNewRecordLink,
        event,
        modalProps: {
          mode: 'module-record-edit',
          moduleNameId: this.relatedModuleName?.id,
          mainFormId: this.record.id,
          defaultTemplateId: this.defaultTemplate?.system_code,
          title: this.moduleRecordEditTitle(this.relatedModuleName?.display),
          listeners: {
            'record-created': this.onRecordCreate,
          },
        },
      });
    }

    beforeMount(): void {
      if (this.subFormList.default_template_id) {
        this.$api
          .getDefaultTemplates({ filters: { id: this.subFormList.default_template_id }, only: ['id', 'system_code'] }, { cache: true })
          .then(({ data }) => (this.defaultTemplate = data[0] || null));
      }
      if (this.subFormList.record_relation_question_id) {
        const only: OnlyOptions<SubFormQuestion> = ['id', 'code', { sub_form_section: ['sub_form_id'] }];
        this.$api.getSubFormQuestion(this.subFormList.record_relation_question_id, { only }).then(({ data }) => {
          this.relatedQuestion = data;
          this.$api
            .getModuleNames({ filters: { sub_form_id: data.sub_form_section?.sub_form_id } })
            .then(({ data }) => (this.relatedModuleName = data[0]));
        });
      }
    }
  }
