import { initI18n } from '@app/i18n';
import Vue from 'vue';
import * as components from './components';
import router from './router';
import setup from '@app/vue/setup';
import withChartkick from '@app/vue/with-chartkick';

withChartkick(Vue);

document.addEventListener('DOMContentLoaded', () => initI18n().then(() => setup({ router, components })(Vue)));
