
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import IndicatorForm from '../../../components/admin/indicators/indicator-form.vue';
  import type { Indicator } from '@app/models/indicator';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { IndicatorForm } })
  export default class IndicatorEdit extends Vue {
    @Ref() readonly form?: IndicatorForm;

    indicator: Nullable<Indicator> = null;

    beforeMount(): void {
      this.$api
        .getIndicator(
          Number(this.$route.params.indicatorId),
          {
            only: ['active', 'default', 'id', 'color', 'name', 'description', 'system_code', 'indicator_set'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.indicator = data;
        });
    }
    onFormSubmit(indicator: Indicator): void {
      this.updateIndicator(indicator);
    }

    get isHybridIndicator(): boolean {
      return this.indicator?.indicator_set?.indicator_type === 'hybrid_checklist';
    }

    updateIndicator(indicator: Indicator): void {
      this.$api
        .updateIndicator(indicator.id, indicator)
        .then(({ data }) => {
          this.$api.cache.clear();
          toaster('Indicator Updated');
          this.$router.replace({
            name: 'admin-indicator-set-show',
            params: { indicatorSetId: `${data.indicator_set_id}` },
            query: this.$route.query,
          });
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
