
  import bootbox from 'bootbox';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import MobileAppRegistrationForm from '../../../components/admin/mobile-apps/mobile-app-registration-form.vue';
  import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppRegistrationForm, DsModal } })
  export default class EditRegistrationPage extends Vue {
    @Prop([String, Number]) readonly mobileAppRegistrationId!: string | number;
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Ref() readonly form?: MobileAppRegistrationForm;

    registration: Nullable<MobileAppRegistration> = null;

    visible = true;

    beforeMount(): void {
      this.$api.getMobileAppRegistration(this.mobileAppRegistrationId, {}, { cache: true }).then(({ data }) => {
        this.registration = data;
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$api.cache.clear();
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }

    onFormSubmit(registration: MobileAppRegistration): void {
      this.$api
        .updateMobileAppRegistration(registration.id, registration)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    onDelete(): void {
      bootbox.confirm({
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          const id = this.registration?.id;
          if (result && id) {
            this.$api
              .deleteMobileAppRegistration(id)
              .then(() => {
                this.$api.cache.clear();
                this.closeModal('reload');
              })
              .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }
  }
