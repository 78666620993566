
  import { Component, Model, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';
  import type { ColorViewModeQuestionOptions } from '@app/models/question-options/color-view-mode-question-options';

  @Component({
    components: { Select2, FormField },
  })
  export default class ColorViewMode extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseSelectQuestionOptions & ColorViewModeQuestionOptions>;

    beforeMount() {
      this.$$patch(this.value.config, { color_view_mode: 'read' }, { ifEmpty: true });
    }

    get colorViewModeOptions() {
      return [
        ['read', this.$t('app.labels.read_view')],
        ['edit', this.$t('app.labels.edit_view')],
        ['all', this.$t('app.labels.read_and_edit_view')],
      ];
    }
  }
