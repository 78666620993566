
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import SelectResponseList from '@app/components/question-response/select-response-list.vue';
  import Select2 from '@app/components/select2.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import keys from '@app/typed-keys';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { CalculationSelectQuestionOptions } from '@app/models/question-options/calculation-select-question-options';
  import { CalculationTextFormatValidation } from '@app/models/question-options/calculation-text-question-options';

  @Component({
    components: { FormField, Select2, SelectResponseList, DsCheckbox },
  })
  export default class CalculationSelectFieldOptions extends Vue {
    @Prop(Object) value!: SubFormQuestion<CalculationSelectQuestionOptions, { calculation_value: string; is_default: boolean }>;

    get scoreType(): 'number' | 'text' {
      const {
        config: { format_validation },
      } = this.value;

      if (format_validation && [CalculationTextFormatValidation.text, CalculationTextFormatValidation.auto].includes(format_validation)) {
        return 'text';
      }

      return 'number';
    }

    get scoreTip(): string {
      return this.value.config.format_validation === CalculationTextFormatValidation.percentage
        ? this.$t('tenant.admin.sub_form_questions.form_fields.edit.calculation_select_field.percentage_note').toString()
        : '';
    }

    get formatValidationOptions(): [CalculationTextFormatValidation, string][] {
      return [
        [CalculationTextFormatValidation.number, 'Number'],
        [CalculationTextFormatValidation.percentage, 'Percentage'],
        [CalculationTextFormatValidation.text, 'Text'],
        [CalculationTextFormatValidation.auto, 'Auto (auto identify numbers and text)'],
      ];
    }

    beforeMount(): void {
      this.populateDefaults();
      const formatValidationWas = this.value.config.format_validation;
      this.$$patch(this.value.config, { format_validation: this.formatValidationOptions[0][0] }, { ifEmpty: true });

      if (formatValidationWas !== this.value.config.format_validation) {
        this.onFormatChange(this.value.config.format_validation);
      }
    }

    onFormatChange(format: CalculationSelectQuestionOptions['format_validation']): void {
      if (format === CalculationTextFormatValidation.percentage) {
        const {
          options: { values = {} },
        } = this.value;

        Object.keys(values).forEach((key) => (values[key].calculation_value = ''));
      }
    }

    populateDefaults(): void {
      const {
        config: { default: { option: defaultOption, type: defaultType } = {} },
        options: { values = {} },
      } = this.value;

      if (defaultType !== 'select_value' || typeof defaultOption === 'undefined' || !values[defaultOption]) {
        return;
      }

      values[defaultOption].is_default = true;
    }

    updateDefaults(options: Record<string, { is_default: boolean }>) {
      const values = keys(options).filter((id) => options[id].is_default);

      this.$$patch(this.value.config.default, { option: values[0] });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'blank_value_amount',
        'currency_symbol',
        'decimal_places',
        'default_value_amount',
        'format_validation',
        'max_value',
        'min_value',
        'visibility_override',
      ].forEach((key) => this.$delete(this.value.config, key));

      this.$delete(this.value.options, 'values');
    }
  }
