
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import ModuleNameActions from '@app/components/admin/module-names/module-name-actions.vue';
  import type { ModuleName } from '@app/models/module-name';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';

  import type { AvailableTab } from './models';

  @Component({ components: { ModuleNameActions } })
  export default class ModuleNameTabs extends Vue {
    @Prop(Number) readonly moduleNameId!: number;

    moduleName: Pick<ModuleName, 'id' | 'name' | 'active' | 'module_type'> | null = null;
    showBudgets = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }
    get availableCustomTabs(): AvailableTab[] {
      return this.customTabs.filter((tab) => {
        if (tab.key === 'importer') {
          return this.currentUserStore.hasRecordImportingPermission;
        } else if (tab.key === 'budgets') {
          return this.showBudgets;
        }
        return true;
      });
    }

    get availableTabs(): AvailableTab[] {
      if (this.moduleName) {
        if (this.moduleName.module_type === 'integrated') {
          return [];
        }

        if (this.moduleName.name === 'Procedure') return this.procedureTabs;
        if (HARDCODED_MODULE_CODES.includes(this.moduleName.name)) {
          return this.classicTabs.concat(['Hazard', 'Incident'].includes(this.moduleName.name) ? [this.wizardTab] : []);
        }

        return this.availableCustomTabs;
      }
      return [];
    }

    get procedureTabs() {
      return [
        {
          key: 'workflow',
          name: this.$t('tenant.admin.module_names.sections.tabs.workflow'),
          route: 'admin-settings-module-names-workflow',
        },
        {
          key: 'role_profiles',
          name: this.$t('tenant.admin.module_names.sections.tabs.role_profiles'),
          route: 'admin-settings-module-names-role-profiles',
        },
      ];
    }

    get classicTabs() {
      return [
        {
          key: 'workflow',
          name: this.$t('tenant.admin.module_names.sections.tabs.workflow'),
          route: 'admin-settings-module-names-workflow',
        },
        {
          key: 'module_tabs',
          name: this.$t('tenant.admin.module_names.sections.tabs.module_tabs'),
          route: 'admin-settings-module-names-module-tabs',
        },
        { key: 'involvements', name: this.$t('tenant.admin.module_names.sections.tabs.involvements'), route: 'admin-involvements-index' },
        {
          key: 'role_profiles',
          name: this.$t('tenant.admin.module_names.sections.tabs.role_profiles'),
          route: 'admin-settings-module-names-role-profiles',
        },
      ];
    }

    get wizardTab() {
      return { key: 'wizard', name: this.$t('tenant.admin.module_names.sections.tabs.wizard'), route: 'admin-wizard' };
    }

    get customTabs() {
      return [
        ...this.classicTabs,
        { key: 'sub_form', name: this.$t('tenant.admin.module_names.sections.tabs.main_form'), route: 'admin-module-sub-form-page' },
        { key: 'sub_forms', name: this.$t('tenant.admin.module_names.sections.tabs.sub_forms'), route: 'admin-sub-forms-list' },
        {
          key: 'indicator_sets',
          name: this.$t('tenant.admin.module_names.sections.tabs.indicator_sets'),
          route: 'admin-indicator-set-index',
        },
        {
          key: 'question_sets',
          name: this.$t('tenant.admin.module_names.sections.tabs.question_sets'),
          route: 'admin-settings-module-names-question-sets',
        },
        { key: 'importer', name: this.$t('tenant.admin.module_names.sections.tabs.importer'), route: 'admin-module-name-import-page' },
        {
          key: 'audit_report_templates',
          name: this.$t('tenant.admin.module_names.sections.tabs.audit_report_templates'),
          route: 'admin-settings-module-names-audit-report-templates',
        },
        {
          key: 'show_options',
          name: this.$t('tenant.admin.module_names.sections.tabs.show_options'),
          route: 'admin-settings-module-names-show-options',
        },
        {
          key: 'confidentiality_types',
          name: this.$t('tenant.admin.module_names.sections.tabs.confidentiality_types'),
          route: 'admin-settings-module-names-confidentiality-types',
        },
        {
          key: 'default_templates',
          name: this.$t('tenant.admin.module_names.sections.tabs.default_templates'),
          route: 'admin-settings-module-names-default-templates',
        },
        {
          key: 'record_calculations',
          name: this.$t('tenant.admin.module_names.sections.tabs.record_calculations'),
          route: 'admin-settings-module-names-record-calculations',
        },
        {
          key: 'features',
          name: this.$t('tenant.admin.module_names.sections.tabs.features'),
          route: 'admin-settings-module-names-features',
        },
        {
          key: 'relationships',
          name: this.$t('tenant.admin.module_names.sections.tabs.relationships'),
          route: 'admin-module-name-relationships-page',
        },
        { key: 'scoring', name: this.$t('tenant.admin.module_names.sections.tabs.scoring'), route: 'admin-settings-module-name-scoring' },
        {
          key: 'tab_section_groups',
          name: this.$t('tenant.admin.module_names.sections.tabs.tab_section_group'),
          route: 'module-tab-section-groups-page',
        },
        { key: 'budgets', name: this.$t('tenant.admin.module_names.sections.tabs.budgets'), route: 'admin-module-name-budgets-page' },
        this.wizardTab,
        {
          key: 'dashboard_panes',
          name: this.$t('tenant.admin.module_names.sections.tabs.dashboard_panes'),
          route: 'admin-dashboard-panes-index',
        },
        {
          key: 'automation_definitions',
          name: this.$t('tenant.admin.module_names.sections.tabs.automation_definitions'),
          route: 'admin-module-automation-definitions-page',
        },
      ];
    }
    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['id', 'name', 'active', 'module_type', 'sub_form_id'];
      this.$api.getModuleName(this.moduleNameId, { only, show_all: true }, { cache: true }).then(({ data }) => {
        this.moduleName = data;
      });
      if (this.accountStore.expensingEnabled) {
        this.$api.getExpensableExpensingTables({ filters: { active: true }, only: ['id'] }).then(({ data }) => {
          this.showBudgets = !!data.length;
        });
      }
    }
  }
