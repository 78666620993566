
  import { Component, Model, Vue } from 'vue-property-decorator';
  import ApiRequestOptionsEditor from './api-request-options-editor.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import Select2 from '@app/components/select2.vue';
  import type { ApiRequestOptions, HttpMethod } from '@app/models/question-options/shared';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { PartialDeep } from 'type-fest';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  @Component({ components: { AdvancedAutosuggest, ApiRequestOptionsEditor, Select2, FormField, DsCheckbox } })
  export default class ApiRequestConfigurator extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseQuestionOptions & PartialDeep<ApiRequestOptions>>;

    injectableOptions: PlaceholderItem[] = [];

    async beforeMount(): Promise<void> {
      this.$$patch(
        this.value.config.api_request,
        {
          data_replace_mode: 'suggest_on_edit',
          cache: 'true',
          method: 'GET',
        },
        { ifEmpty: true }
      );

      this.injectableOptions = await this.fetchPlaceholderOptions();
    }

    get methods(): HttpMethod[] {
      return ['GET', 'POST', 'PUT'];
    }

    get hasBody(): boolean {
      return ['POST', 'PUT'].includes(this.value.config.api_request?.method || '');
    }

    get isSingleSelectQuestion(): boolean {
      return FieldType.single_select === this.value?.field_type;
    }

    get isSelfReferencing(): boolean {
      return this.value.config.api_request?.self_referencing === 'true';
    }

    get dataReplaceModes(): [string, string][] {
      return [
        ['suggest', this.$t('mode_api_request.data_replace_mode_type.suggest')],
        ['override', this.$t('mode_api_request.data_replace_mode_type.override')],
        ['suggest_on_edit', this.$t('mode_api_request.data_replace_mode_type.suggest_on_edit')],
      ];
    }

    get supportsSelfReferencing(): boolean {
      return [FieldType.single_select, FieldType.text].indexOf(this.value.field_type) > -1;
    }

    async fetchPlaceholderOptions(): Promise<PlaceholderItem[]> {
      const [{ data: global }, { data: question }] = await Promise.all([
        this.$api.getInjectableOptions({}, { cache: true }),
        this.$api.getInjectableOptions(
          {
            concept_name: 'SubFormQuestion',
            injectable_arguments: { sub_form_question_id: this.value.id },
          },
          { cache: true }
        ),
      ]);

      return [...global, ...question];
    }
  }
