
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import SubFormResponseOutput from '@app/components/sub-form-completion/sub-form-response-output.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { keyBy, orderBy, isEmpty } from 'lodash';
  import { isArrayTypeQuestion } from '@app/services/model-helpers';
  import { MODULE_RECORD_SHOW_ONLY, RECORD_DETAILS_MODULE_ONLY } from '@app/pages/module-records/utils';
  import type { Indicator } from '@app/models/indicator';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName, ShowIdOption } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import type { RecordIndicator } from '@app/models/record-indicator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { toaster } from '@app/utils/toaster';

  import IndicatorItem from '../indicator/indicator-item.vue';

  @Component({ components: { SubFormResponseOutput, IndicatorItem } })
  export default class ModuleRecordDetailsSection extends Vue {
    @Prop(Object) readonly moduleRecord!: Pick<ModuleRecord, 'id' | 'location_id'>;

    fetchedModuleRecord: Partial<ModuleRecord> = {};
    moduleName: Partial<ModuleName> = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get recordCalculationsById(): Record<number, RecordCalculation> {
      return keyBy(this.moduleName?.record_calculations, 'id');
    }

    get indicatorSetsById(): Record<number, IndicatorSet> {
      return keyBy(this.moduleName?.indicator_sets, 'id');
    }

    get involvementsById(): Record<number, Involvement> {
      return keyBy(this.moduleName?.involvements, 'id');
    }

    get userInvolvementsByInvolvementId(): Record<number, string[]> {
      return (this.fetchedModuleRecord?.user_involvements || []).reduce((memo, involvement) => {
        const user = involvement.user;
        if (user) {
          return {
            ...memo,
            [involvement.involvement_id]: [...(memo[involvement.involvement_id] || []), user.full_name],
          };
        }

        return memo;
      }, {} as Record<number, string[]>);
    }

    get recordIndicatorsByIndicatorId(): Record<number, Partial<RecordIndicator>> {
      return keyBy(this.fetchedModuleRecord?.record_indicators, 'indicator_id');
    }

    get mainFormQuestionsById(): Record<number, SubFormQuestion> {
      return keyBy(this.mainFormQuestions, 'id');
    }

    get mainFormQuestions(): SubFormQuestion[] {
      return (this.moduleName?.main_form?.sub_form_sections && this.moduleName?.main_form?.sub_form_sections[0].sub_form_questions) || [];
    }

    get subFormResponses(): SubFormResponse[] {
      return this.fetchedModuleRecord?.sub_form_completion?.sub_form_responses || [];
    }

    get responsesByQuestionId(): Record<number, SubFormResponse> {
      return keyBy(this.subFormResponses, 'sub_form_question_id');
    }

    get responsesAsShowOptions(): ShowIdOption[] {
      return orderBy(this.subFormResponses, [(r) => this.mainFormQuestionsById[r.sub_form_question_id]?.index]).map((response) => {
        return {
          id: response.sub_form_question_id,
          type: 'sub_form_question',
        };
      });
    }

    get calculationsAsShowOptions(): ShowIdOption[] {
      return Object.keys(this.recordCalculationsById).map((id) => {
        return {
          id: parseInt(id),
          type: 'record_calculation',
        };
      });
    }

    get quickDisplayConfig(): ShowIdOption[] {
      return (this.moduleName.show_options?.record_quick_display_config || []).filter((option) => !option.exclude);
    }

    get fieldLimit(): number | undefined {
      return this.moduleName.show_options?.record_quick_display_field_limit;
    }

    get hasCustomOrder(): boolean {
      return !!this.quickDisplayConfig.length;
    }

    get fields(): ShowIdOption[] {
      if (this.hasCustomOrder) {
        return this.quickDisplayConfig;
      }

      return [...this.responsesAsShowOptions, ...this.calculationsAsShowOptions];
    }

    get finalFields(): ShowIdOption[] {
      if (this.fieldLimit === 0) return [];

      const fields = this.fields.filter((field) => {
        switch (field.type) {
          case 'sub_form_question':
            const response = this.responsesByQuestionId[field.id];
            const question = this.mainFormQuestionsById[field.id];
            return this.canViewResponse(response, question) && (this.hasCustomOrder || !this.hideInReadView(question));
          case 'record_calculation':
            const rc = this.recordCalculationsById[field.id];
            return !!rc && this.canViewRecordCalculation(rc);
          case 'indicator_set':
            const indicatorSet = this.indicatorSetsById[field.id];
            return !!indicatorSet && !indicatorSet.multi && indicatorSet.active;
          case 'involvement':
            const involvement = this.involvementsById[field.id];
            return !!involvement && !this.hideEmptyInvolvement(involvement) && involvement.active;
        }
      });

      if (!this.fieldLimit) return fields;

      return fields.slice(0, this.fieldLimit);
    }

    get fallbackValue(): string {
      return this.$t('app.labels.na');
    }

    beforeMount(): void {
      this.fetchModuleRecord();
    }

    fetchModuleRecord(): void {
      this.$api
        .getModuleRecord(this.moduleRecord.id, { only: MODULE_RECORD_SHOW_ONLY }, { cache: true })
        .then(({ data }) => {
          this.fetchedModuleRecord = data;
          this.fetchModuleName();
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    fetchModuleName(): void {
      this.$api
        .getModuleName(Number(this.fetchedModuleRecord?.module_name_id), { only: RECORD_DETAILS_MODULE_ONLY }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    fieldTitle(field: ShowIdOption): string | undefined {
      switch (field.type) {
        case 'sub_form_question':
          const { title, short_title } = this.mainFormQuestionsById[field.id] || {};
          return this.$adminSanitize(short_title || title);
        default:
          return this.recordCalculationName(this.recordCalculationsById[field.id]);
        case 'indicator_set':
          return this.indicatorSetsById[field.id]?.name;
        case 'involvement':
          return this.involvementsById[field.id]?.name;
      }
    }

    hideEmptyInvolvement(involvement: Involvement): boolean {
      return !this.userInvolvementsByInvolvementId[involvement.id]?.length && involvement.hide_empty;
    }

    canViewResponse(response: SubFormResponse, question: SubFormQuestion): boolean {
      return response && question && !this.hiddenQuestion(question) && this.showAnswer(response, question);
    }

    hiddenQuestion(question: SubFormQuestion): boolean {
      return question.config?.hide_question === 'true';
    }

    hideInReadView(question: SubFormQuestion): boolean {
      return question.config?.read_view_mode === 'hidden';
    }

    showAnswer(response: SubFormResponse, question: SubFormQuestion): boolean {
      return question.active || !this.responseBlank(response, question);
    }

    responseBlank(response: SubFormResponse, question: SubFormQuestion): boolean {
      const responseValue = response.response;
      if (isEmpty(responseValue)) return true;

      return (
        !isArrayTypeQuestion(question) &&
        Object.values(responseValue).every((value) => {
          return !isEmpty(value);
        })
      );
    }

    canViewRecordCalculation(recordCalculation: RecordCalculation): boolean {
      return recordCalculation.options.visibility || this.currentUserStore.hasModuleConfigPermission;
    }

    recordCalculationName(recordCalculation: RecordCalculation): string {
      return recordCalculation.options.visibility
        ? recordCalculation.name
        : this.$t('app.labels.hidden_name', { name: recordCalculation.name });
    }

    indicatorItem(indicatorSetId: number): Indicator | undefined {
      const indicatorSet = this.indicatorSetsById[indicatorSetId];
      const indicator = (indicatorSet?.indicators || []).filter(({ id }) => !!this.recordIndicatorsByIndicatorId[id])[0];

      return indicator;
    }

    userInvolvements(id: number): string {
      return this.userInvolvementsByInvolvementId[id]?.join(', ');
    }
  }
