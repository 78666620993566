import type { BaseEntity } from './base-entity';
import type { WorkflowLink } from './workflow-link';
import type { UserCollection } from '@app/models/user-collection';

export interface Workflow extends BaseEntity {
  active: boolean;
  code: string;
  color: string;
  exclude_creator: boolean;
  locked_sub_form_question_ids: number[];
  module_name_id?: number;
  name: string;
  next_links: WorkflowLink[];
  next_steps_text?: string;
  prev_links: WorkflowLink[];
  starting_state?: boolean;
  sub_form_list_id?: number;
  system: boolean;
  user_collection?: UserCollection;
  user_collection_id?: number;
}

export const WORKFLOW_DEFAULT_BACKGROUND_COLOR = '#272d32';
