import { render, staticRenderFns } from "./calculation-text-field-options.vue?vue&type=template&id=154bde9c&scoped=true&lang=pug"
import script from "./calculation-text-field-options.vue?vue&type=script&lang=ts"
export * from "./calculation-text-field-options.vue?vue&type=script&lang=ts"
import style0 from "./calculation-text-field-options.vue?vue&type=style&index=0&id=154bde9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154bde9c",
  null
  
)

export default component.exports