import type { ValidationRule } from 'vee-validate/dist/types/types';

const snakeCaseRule: ValidationRule = {
  validate(value: unknown) {
    const valueAsString = String(value);
    return /^([0-9a-z_]+)$/.test(valueAsString);
  },
  message: 'Only Lowercase Characters, Numbers and Underscores are permitted.',
};

export default snakeCaseRule;
