import { BehaviorSubject } from 'rxjs';

export enum ClampedType {
  GroupLabel = 'group-label',
  RuleFilterSelect = 'rule-filter-select',
  RuleValueInput = 'rule-value-input',
  RuleValueSelect = 'rule-value-select',
}

export class ClampedState {
  private clampedValues = new Set<ClampedType>();
  readonly clampedState$ = new BehaviorSubject<boolean>(false);

  setClampedValues(value: ClampedType) {
    this.clampedValues.add(value);
    this.clampedState$.next(true);
  }

  removeClampedValues(value: ClampedType) {
    this.clampedValues.delete(value);
    this.clampedState$.next(this.isClamped);
  }

  get isClamped(): boolean {
    return !!this.clampedValues.size;
  }
}
