
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import BaseCreateObjectEvent from '@app/mixins/base-create-object-event-form';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { RequiredBy } from '@app/utils/types/required-by';

  @Component({
    components: { Select2, FormFields: () => import(/* webpackChunkName: "events-form-fields" */ '../events/forms/form-fields.vue') },
  })
  export default class CreateChainedEventForm extends BaseCreateObjectEvent {
    @Prop(Object) readonly injectableArguments!: RequiredBy<InjectableArguments, 'concept_name'>;
    @Prop(String) readonly executionContext!: 'automation' | 'schedule';
    @Prop(String) readonly basePer?: string;

    internalAutomationCreateEventOptions: [string, string][] = [];

    get automationCreateEventOptions(): [string, string][] {
      const data = this.internalAutomationCreateEventOptions;
      if (!useCurrentUserStore()?.isDefaultTechAdmin && this.syncedEvent.event_type !== 'delete_record') {
        return data.filter(([k]) => k !== 'delete_record');
      }
      return data;
    }

    get conceptName(): string {
      return this.injectableArguments?.concept_name;
    }

    @Watch('basePer', { immediate: true })
    syncPer(per: string): void {
      this.updatePerArguments(per, this.executionContext);
      this.getCollection(per);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeChainedType(type: string) {
      if (this.syncedEvent.options) {
        this.syncedEvent.options.values = {};
        this.syncedEvent.per = '';
      }
    }

    async getPerOptions(): Promise<void> {
      const { data } = await this.$api.getPerOptions(
        { concept_name: this.conceptName, injectable_arguments: this.injectableArguments },
        { cache: true }
      );
      this.perOptions = data;
    }

    async getAutomationCreateEventOptions(): Promise<void> {
      const { data } = await this.$api.getAutomationCreateEventOptions({ injectable_arguments: this.injectableArguments }, { cache: true });
      this.internalAutomationCreateEventOptions = data.filter(([k]) => k !== 'api_request'); // TODO: find a better way to handle this
    }

    async beforeMount(): Promise<void> {
      Promise.all([
        this.getAutomationCreateEventOptions(),
        this.getPerOptions(),
        ...this.getAutomationOptionsPromises(this.injectableArguments, this.executionContext, true),
      ]).finally(() => {
        this.createObjectEventFormLoading = false;
        this.sendLoaded();
      });
    }
  }
