
  import BaseSubFormListPage from '@app/components/sub-form-list/base-sub-form-list-page';
  import { Component } from 'vue-property-decorator';
  import SubFormListViewOptionsModal from '@app/components/sub-form-list/sub-form-list-view-options-modal.vue';
  import type { RuleSet } from '@app/models/rule-set';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  const only: OnlyOptions<SubFormList> = [
    'id',
    'custom_title',
    'display_sub_form_question_codes',
    'order_options',
    'user_avatar_option',
    'add_text',
    'add_icon',
    'add_background_color',
    'approval_button_text',
    'approval_button_icon',
    'approval_button_background_color',
    'display_actions_count',
    'module_tab_id',
    'tab_section_group_ids',
    'rule_set',
    'sub_form_list_type',
    'sub_form_ids',
    { module_name: ['name'] },
  ];

  @Component({ components: { SubFormListViewOptionsModal } })
  export default class SubFormListViewOptionsPage extends BaseSubFormListPage {
    async beforeMount(): Promise<void> {
      const { data: subFormList } = await this.$api.getSubFormList(Number(this.subFormListId), { only }, { cache: true });
      this.subFormList = { ...subFormList, rule_set: (subFormList?.rule_set || {}) as RuleSet };
    }

    submit(form: Partial<SubFormList>): void {
      const formForSubmit = {
        ...form,
        add_background_color: form.add_background_color || null,
        add_icon: form.add_icon || null,
        approval_button_icon: form.approval_button_icon || null,
        approval_button_background_color: form.approval_button_background_color || null,
      };

      this.blocking(async () => {
        return this.$api
          .updateSubFormList(Number(form.id), formForSubmit)
          .then(() => {
            toaster(this.$t('tenant.admin.sub_form_lists.edit_view_options.updated_successfully'));
            this.closeModal('reload');
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      });
    }
  }
