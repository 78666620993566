
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import AdminModuleRecordsImportInstructions from './admin-module-records-import-instructions.vue';
  import AdminImportsTable from './admin-imports-table.vue';
  import ImportForm from './import-form.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SuperImport } from '@app/models/super-import';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  interface TypeOption {
    code: string;
    label: string;
  }

  @Component({
    components: { AdminModuleRecordsImportInstructions, ImportForm, AdminImportsTable },
  })
  export default class AdminModuleNameImportPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop(Array) readonly importOptions?: TypeOption[];
    @Ref() readonly importsTable?: AdminImportsTable;
    @Ref() readonly form?: ImportForm;

    moduleName: Nullable<ModuleName> = null;
    initialized = false;
    subFormListIds: number[] = [];

    get validSubForms(): SubForm[] {
      return this.moduleName?.sub_forms?.filter((subForm) => subForm.active) || [];
    }

    onSubmit(newImport: SuperImport): void {
      this.$api
        .createImport(newImport)
        .then(({}) => {
          this.importsTable?.reload();
          this.form?.resetForm();
          toaster({
            text: this.$t('tenant.admin.module_names.sections.tab_importer.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['id', 'name', 'sub_forms', { main_form: ['id'], sub_form_lists: ['id'] }];
      this.$api
        .getModuleName(Number(this.moduleNameId), { only }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
          this.subFormListIds = data?.sub_form_lists?.map((subFormList) => subFormList.id) || [];
          this.initialized = true;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
