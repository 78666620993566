
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { Components, DisplayItemValue, Rules, Variables } from '../models/types';
  import ElementItem from './element-item.vue';

  @Component({ components: { ElementItem } })
  export default class TemplateItems extends Vue {
    @Prop({ required: true }) readonly heading!: string;
    @Prop({ required: true }) readonly elementsObject!: Rules | Components | Variables;
    @Prop({ required: true }) readonly toggleDisplayItem!: (elementId: DisplayItemValue) => void;
    @Prop({ required: true }) readonly displayItems!: DisplayItemValue[];
    @Prop({ required: true }) readonly checkIsDisplayItem!: (elementId: DisplayItemValue) => boolean;

    get elementItems(): DisplayItemValue[] {
      return Object.keys(this.elementsObject).sort((a, b) => {
        return this.elementsObject[a].index - this.elementsObject[b].index;
      });
    }

    get noElementItems(): boolean {
      return !Object.keys(this.elementsObject).length;
    }
  }
