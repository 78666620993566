
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import bootbox from 'bootbox';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import WithAuditTrailModal from '@app/mixins/with-audit-trail-modal';
  import type { ModuleName } from '@app/models/module-name';
  import { isLegacyModule, HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { changeLocation } from '@app/utils/change-location';

  import PaperTrailsModal from '../../paper-trails/paper-trails-modal.vue';

  @Component({ components: { DsDropdown, PaperTrailsModal, DsIconText } })
  export default class ModuleNameActions extends mixins(WithAuditTrailModal) {
    @Prop(Object) readonly moduleName!: ModuleName;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get showDeleteButton(): boolean {
      if (!this.moduleName) return false;

      return this.currentUserStore.isDefaultTechAdmin && !this.moduleName.active && !HARDCODED_MODULE_CODES.includes(this.moduleName.name);
    }

    get showActivateButton(): boolean {
      if (!this.moduleName) return false;
      if (isLegacyModule(this.moduleName, ['Procedure'])) return false;

      return !this.moduleName.active;
    }

    get isIntegratedModule(): boolean {
      return !!this.moduleName.module_type && this.moduleName.module_type === 'integrated';
    }

    activateModuleName(active: boolean): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: 'Are you sure?',
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .updateModuleName(this.moduleName.id, { active })
              .then(() => {
                toaster('Module successfully updated');
                changeLocation(this.actionPath(''));
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    deleteModuleName(): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.module_names.sections.tabs.delete_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteModuleName(this.moduleName.id)
              .then(() => {
                toaster('Module has been successfully deleted');
                changeLocation('/admin/settings/module_names');
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    actionPath(action: string): string {
      return `/admin/settings/module_names/${this.moduleName.id}/${action}`;
    }

    changeWorkflowVisibility(visible: boolean): void {
      const workflowEl = $('#workflow-container');
      visible ? workflowEl.show() : workflowEl.hide();
    }
  }
