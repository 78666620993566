
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { Matrix } from '@app/models/matrix/matrix';
  import type { MatrixQuestionOptions } from '@app/models/question-options/matrix-question-options';

  @Component({ components: {} })
  export default class MatrixShowOptions extends BaseShowOptions<MatrixQuestionOptions> {
    matrix: Partial<Pick<Matrix, 'id' | 'name'>> = { name: '' };

    beforeMount() {
      this.$api
        .getMatrix(this.config.matrix_id, { only: ['id', 'name'] }, { join: true, cache: true })
        .then(({ data }) => {
          this.matrix = data;
        })
        .catch(() => {
          this.matrix = { name: this.$t('tenant.admin.sub_form_questions.options.show.none_chosen') as string };
        });
    }
  }
