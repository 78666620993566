
  import { useAccountStore } from '@app/stores/account';
  import AuthDefaultRestrictionsConfigForm from './auth-default-restrictions-config-form.vue';
  import AuthUserRestrictionsConfigForm from './auth-user-restrictions-config-form.vue';
  import SsoSettingForm from './sso-setting-form.vue';
  import SamlListing from './saml-listing.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { Account } from '@app/models/account';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      AuthUserRestrictionsConfigForm,
      AuthDefaultRestrictionsConfigForm,
      SsoSettingForm,
      SamlListing,
    },
  })
  export default class AdminSettingsSamlsIndex extends Vue {
    get accountStore() {
      return useAccountStore();
    }

    submitSsoSetting(form: Partial<Account>): void {
      this.$api
        .updateCurrentAccount(form)
        .then(({ data }) => {
          toaster(this.$t('tenant.admin.settings.samls.account_saml_setting_form.account_sso_updated'));
          this.$api.cache.clear();
          this.setAccountChanges(data);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    setAccountChanges(account: Account): void {
      const columns: (keyof Account)[] = [
        'mfa',
        'saml_as_default_login',
        'force_password_change',
        'expire_password_after',
        'password_archiving_count',
        'password_policy',
      ];

      const attributesToUpdate = columns.reduce((acc, column) => ({ ...acc, [column]: account[column] }), {});
      this.accountStore.$patch({ data: { ...this.accountStore.data, ...attributesToUpdate } });
    }
  }
