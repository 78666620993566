
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class SubFormCompletionParentType extends Vue {
    @Prop(Number) readonly id!: number;

    title = '';
    subTitle: Nullable<string> = null;
    active = true;

    get recordPath(): string | undefined {
      if (this.active) {
        return `/sub_form_completions/${this.id}`;
      }
    }

    onComponentClick(e: MouseEvent) {
      if (this.recordPath) {
        return this.$link(e, this.recordPath, 'location', { prevent: false });
      }
    }

    async beforeMount() {
      try {
        const subFormCompletion = await this.$api.getSubFormCompletion(this.id, { only: ['id', 'title'] }, { cache: true });

        this.title = `[${this.id}] ${subFormCompletion.data.title}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let title;

        switch (error.status) {
          case 404:
            title = `(${this.$t('app.labels.title_inactive', { title: this.$t('app.labels.form_completion') })})`;
            break;
          case 403:
            title = `(${this.$t('app.labels.title_not_accessible', { title: this.$t('app.labels.form_completion') })})`;
            break;
        }

        this.active = false;
        this.title = `[${this.id}] ${title}`;
      }

      this.subTitle = 'Sub Form';
    }
  }
