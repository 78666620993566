
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { TextareaQuestionOptions } from '@app/models/question-options/textarea-question-options';

  @Component({
    components: { DsCheckbox, ScoredQuestionConfig },
  })
  export default class TextareaFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<TextareaQuestionOptions>;
    @Prop(Object) readonly features!: ModuleFeatures;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['rich_text'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
