
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import BasicElement from './basic-element.vue';
  import ElementShiftIcon from './element-shift-icon.vue';
  import ActionIcons from './action-icons.vue';
  import Draggable from 'vuedraggable';
  import { v4 as generateUUID } from 'uuid';
  import type { DisplayItemValue, FormulaElement, TemplateItemObject } from '../models/types';
  import { LogicElementTypes } from '../models/types';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({
    components: {
      ElementShiftIcon,
      BasicElement,
      Draggable,
      ActionIcons,
    },
  })
  export default class TemplateItem extends Vue {
    @Prop() readonly updateDragging?: (state: boolean) => void;
    @Prop({ required: true }) readonly index!: number | string;
    @Prop({ required: true }) readonly type!: LogicElementTypes | 'nullIcon';
    @Prop({ required: true }) readonly value!: string;
    @Prop() readonly color?: string;
    @Prop() readonly highlighted?: boolean;
    @Prop() readonly templateItems?: Dictionary<TemplateItemObject>;
    @Prop() readonly canDrag?: boolean;
    @Prop() readonly onClick?: () => void;
    @Prop() readonly deleteLogic?: () => void;
    @Prop() readonly dragging?: boolean;
    @Prop() readonly displayItem?: boolean;
    @Prop() readonly shiftElementOrder?: (elementId: string, rightOrLeft: 'right' | 'left') => void;
    @Prop() readonly errors?: string[];
    @Prop() readonly toggleDisplayItem?: (elementId: DisplayItemValue) => void;

    basicTemplateItems = ['12345', 'Text', '( )', '+', '-', '*', '/', '^', 'IF', 'ELSIF', 'ELSE', '=', '<', '>', '<=', '>='];
    id = generateUUID();
    mouseOverElement = false;

    get renderIcon(): boolean {
      const specialRenderItems = ['+', '-', '*', '/', '^', '<', '>', '<=', '>=', '=', 'IF', 'ELSIF', 'ELSE'];
      return specialRenderItems.includes(this.value);
    }

    get componentRuleOrVariable(): boolean {
      switch (this.type) {
        case LogicElementTypes.COMPONENT:
        case LogicElementTypes.RULE:
        case LogicElementTypes.VARIABLE:
          return true;
        default:
          return false;
      }
    }

    get basicElementStyle(): Dictionary<string> {
      const backgroundColorByType: Dictionary<string> = {
        number: '#005F7F',
        text: 'pink',
        operator: '#6ddba8',
        comparison: '#82aae5',
        bracket: '#555555',
        ifelse: 'orange',
        invalid: '#ff0000',
      };

      const style: Dictionary<string> = {};
      const backgroundColor = this.color || backgroundColorByType[this.type];
      style.backgroundColor = backgroundColor;

      if (!this.canDrag) {
        style.color = 'grey';
        style.cursor = 'default';
      }
      if (this.highlighted) style.boxShadow = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';

      return style;
    }

    get elementPosition(): {
      left?: boolean;
      right?: boolean;
    } {
      const position: {
        left?: boolean;
        right?: boolean;
      } = {};

      if (this.templateItems) {
        const elementIndex = this.templateItems[this.value].index;

        if (elementIndex === 0) position.left = true;
        if (elementIndex === Object.keys(this.templateItems).length - 1) position.right = true;
      }

      return position;
    }

    get draggableArray(): { id: string | number; index: string | number; value: FormulaElement }[] {
      return [
        {
          id: this.id,
          index: this.index,
          value: this.constructLogicElementValue(),
        },
      ];
    }

    get showWarningIcon(): boolean {
      return !!this.errors && !!this.errors.length;
    }

    get showDeleteIcon(): boolean {
      return !this.dragging && this.mouseOverElement && !!this.deleteLogic;
    }

    get showDisplayIcon(): boolean {
      return ((!this.dragging && this.mouseOverElement) || !!this.displayItem) && !!this.toggleDisplayItem;
    }

    constructLogicElementValue(): FormulaElement {
      const basicTemplateItems = this.basicTemplateItems;
      const dragIndex = this.index;
      const dragValue = this.value;

      if (this.type === LogicElementTypes.NUMBER) {
        return '';
      } else if (this.type === LogicElementTypes.TEXT) {
        return '""';
      } else if (
        this.type === LogicElementTypes.OPERATOR ||
        this.type === LogicElementTypes.COMPARISON ||
        this.type === LogicElementTypes.IFELSE
      ) {
        return basicTemplateItems[dragIndex as number];
      } else if (this.type === LogicElementTypes.BRACKET) {
        return [];
      } else {
        return dragValue;
      }
    }

    onDragStart(): void {
      this.updateDragging?.(true);
    }
    onDragEnd(): void {
      this.updateDragging?.(false);
    }

    onClone(): void {
      this.id = generateUUID();
    }

    toggleMouseOverElement(boolean: boolean): void {
      this.mouseOverElement = boolean;
    }
  }
