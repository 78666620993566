
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import { Component } from 'vue-property-decorator';
  import type { SubFormListResponseCounterData } from '@app/models/sub-form-list';

  @Component({ components: {} })
  export default class ResponseCounterTab extends SubFormListBase {
    responseCounterData: SubFormListResponseCounterData | null = null;

    beforeMount(): void {
      this.$api.getResponseCounterData(this.subFormList.id, { record_id: this.record.id }, { cache: true }).then(({ data }) => {
        this.responseCounterData = data;
      });
    }

    get showPercentage(): boolean {
      return this.subFormList?.response_counter_options.hide_percentage !== 'true';
    }

    get showTotal(): boolean {
      return this.subFormList?.response_counter_options.hide_total !== 'true';
    }
  }
