
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import {
    canResolveUsageReferenceRoute,
    resolveUsageReferenceRoute,
  } from '@app/components/admin/sub-form-questions/sub-form-question-usage-routes';
  import { changeLocation } from '@app/utils/change-location';

  @Component({})
  export default class SubFormQuestionUsageRouter extends Vue {
    @Prop({ type: [String] }) readonly referenceType!: string;
    @Prop({ type: [String, Number] }) readonly referenceId!: string | number;
    @Prop({ type: [String] }) readonly usageType!: string;

    beforeMount(): void {
      if (canResolveUsageReferenceRoute(this.referenceType)) {
        resolveUsageReferenceRoute(this.referenceType, this.referenceId, this.usageType);
      } else {
        changeLocation('/admin/settings/');
      }
    }
  }
