
  import { Component, Prop } from 'vue-property-decorator';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { BaseTablePagination } from '../base-table';
  import type { PaginationData } from '../base-table/utils';
  import { makePagination } from '../base-table/utils';
  import type { SuperReportData } from '@app/models/super-report';

  @Component({ components: { BaseTablePagination } })
  export default class SuperReportDataTable extends mixins(Blocking) {
    @Prop() readonly superReportId!: number;
    @Prop() readonly page!: number;

    superReportData: Nullable<SuperReportData> = null;
    initialPagination: Nullable<PaginationData> = null;

    beforeMount(): void {
      this.getData(this.page);
    }

    async getData(page: number): Promise<void> {
      await this.blocking(
        async () => {
          const per_page = 25;
          const { data, headers } = await this.$api.getSuperReportData(
            this.superReportId,
            {
              page: page,
              per_page,
            },
            { cache: true }
          );

          this.initialPagination = makePagination(+headers.total, per_page, page);
          this.superReportData = data;
        },
        {
          loading: true,
        }
      );
    }

    get colspan(): number | undefined {
      return this.superReportData?.columns.length;
    }

    changePage(key: number): void {
      this.$router.push({
        name: 'super-report-page',
        query: { ...(key === 1 ? {} : { page: `${key}` }), super_report_id: `${this.superReportId}` },
      });
      this.$nextTick(() => {
        this.getData(key);
      });
    }

    get linkCsvReport(): string {
      return `/reports/super_report.csv?super_report_id=${this.superReportId}`;
    }

    get emailMeLink(): string {
      return `/reports/export?super_report_id=${this.superReportId}`;
    }
  }
