
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';

  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    current_user: string;
    ex_tax: string;
    expensable_record_budget_category: string;
    inc_tax: string;
    line_item_id: string;
    line_item_quantity: string;
    line_item_tax_rate: string;
    tax: string;
  }

  @Component({ components: { Select2, AdvancedAutosuggest, FormField } })
  export default class ExpensableApproveLineItemEventForm extends BaseEventForm<FormValues> {
    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
