
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { canBeReadonly } from '@app/services/model-helpers';
  import ApiRequestConfigurator from '@app/components/admin/questions/api-request/api-request-configurator.vue';
  import ApiLookupConfigurator from '@app/components/admin/questions/api-lookup/api-lookup-configurator.vue';
  import Select2 from '@app/components/select2.vue';
  import ColorViewMode from './color-view-mode.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import { DatePickerUIOptions } from '@app/models/question-options/date-question-options';

  @Component({ components: { FormField, ApiRequestConfigurator, ApiLookupConfigurator, ColorViewMode, Select2 } })
  export default class TabVisualOptions extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;

    beforeMount(): void {
      this.$$patch(this.value.config, {
        use_type: 'normal' as const,
        read_view_mode: 'normal' as const,
        ...this.value.config,
      });
    }

    get showColorViewMode(): boolean {
      return [FieldType.button_select, FieldType.single_select, FieldType.radio].includes(this.value.field_type);
    }

    get readPrintUiViewOnly(): boolean {
      return this.value.field_type === FieldType.table_calculation;
    }

    get useTypes(): [string, string][] {
      return [
        ['normal', this.$t('tenant.admin.sub_form_questions.advanced_options.normal')],
        ['hidden', this.$t('tenant.admin.sub_form_questions.advanced_options.hidden')],
        ...(canBeReadonly(this.value)
          ? [['readonly', this.$t('tenant.admin.sub_form_questions.advanced_options.readonly')] as [string, string]]
          : []),
      ];
    }

    get showDatePickerUI(): boolean {
      return this.value.field_type === FieldType.date;
    }

    get showYearDatePickerDescription(): boolean {
      return this.value.config.date_picker_ui === DatePickerUIOptions.year;
    }

    get datePickerUITypes(): [DatePickerUIOptions, string][] {
      return [
        [DatePickerUIOptions.date, this.$t('tenant.admin.sub_form_questions.advanced_options.date_selector')],
        [DatePickerUIOptions.year, this.$t('tenant.admin.sub_form_questions.advanced_options.year_selector')],
      ];
    }

    get readPrintTypes(): [string, string][] {
      return [
        ['normal', this.$t('tenant.admin.sub_form_questions.advanced_options.normal')],
        ['hidden', this.$t('tenant.admin.sub_form_questions.advanced_options.hidden')],
        ['show_description', this.$t('tenant.admin.sub_form_questions.advanced_options.show_description')],
      ];
    }

    get horizontalDividerOptions() {
      return this.hashToOptions({
        above_title: this.$t('tenant.admin.sub_form_questions.advanced_options.above_title'),
        below_title: this.$t('tenant.admin.sub_form_questions.advanced_options.below_title'),
        below_response: this.$t('tenant.admin.sub_form_questions.advanced_options.below_response'),
      });
    }

    get mainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    hashToOptions(hash: Dictionary<string>) {
      return Object.keys(hash).map((k) => [k, hash[k]]);
    }
  }
