
  import OpenSectionForm from '@app/components/sub-form-section/open-section-form.vue';
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
  import OpenSectionGroupForm from '@app/components/sub-form-section/open-section-group-form.vue';
  import Draggable from 'vuedraggable';
  import { keyBy, isEmpty } from 'lodash';
  import type { Attachment } from '@app/models/attachment';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';

  import DraggableSectionGroupHelper from './draggable-section-group-helper';
  import { ASF_SFS_ONLY, ASF_SFSG_ONLY } from './utils';
  import type { AsfSfsOnly, AsfSfsgOnly, AsfAttachmentOnly } from './utils';
  import SubFormSectionGroupPanel from './sub-form-section-group-panel.vue';
  import SubFormSectionPanel from './sub-form-section-panel.vue';
  import SubFormSectionsNewButton from './sub-form-sections-new-button.vue';
  import SubFormSectionShow from './sub-form-section-show.vue';

  @Component({
    components: {
      OpenSectionForm,
      SubFormSectionShow,
      SubFormSectionsNewButton,
      OpenSectionGroupForm,
      Draggable,
      SubFormSectionPanel,
      SubFormSectionGroupPanel,
    },
  })
  export default class AdminSubForm extends DraggableSectionGroupHelper {
    @Ref() readonly openSectionForm?: InstanceType<typeof OpenSectionForm>;
    @Ref() readonly sectionGroupForm?: InstanceType<typeof OpenSectionGroupForm>;

    @Prop(Object) readonly subForm!: Pick<SubForm, 'id' | 'module_name' | 'scoring'>;
    @Prop(Object) readonly moduleName?: Pick<ModuleName, 'name' | 'sub_form_id'>;
    @Prop(Boolean) readonly firstSectionOnly?: boolean;
    @Prop(Boolean) readonly hideAddNewSection?: boolean;

    attachmentsBySectionId: Record<string, Pick<Attachment, AsfAttachmentOnly>[]> = {};
    sectionQuestionsLoaded: Record<string, boolean> = {};
    alreadyScrolled = false;

    get allSectionQuestionsLoaded() {
      return (
        !isEmpty(this.sectionQuestionsLoaded) &&
        Object.values(this.sectionQuestionsLoaded).every((loaded) => loaded) &&
        Object.keys(this.sectionQuestionsLoaded).length === Object.keys(this.sectionsById).length
      );
    }

    get subFormId(): number {
      return this.subForm.id;
    }

    get mainFormId(): Maybe<number> {
      return this.moduleName?.sub_form_id;
    }

    get moduleNameName(): string {
      return this.moduleName?.name || this.subForm.module_name;
    }

    get lastSectionOrGroupIndex(): number {
      return this.dndList.length - 1;
    }

    get moduleFormOrCoursesMainForm(): boolean {
      return !!this.firstSectionOnly;
    }

    get firstSubSection(): Pick<SubFormSection, AsfSfsOnly> {
      return this.subFormSections.sort((a, b) => a.index - b.index)[0];
    }

    @Watch('allSectionQuestionsLoaded')
    onAllSectionQuestionsLoadedChanged(val: boolean) {
      if (val && !this.alreadyScrolled) this.scrollToSectionBottom();
    }

    onAttachmentsUploaded(attachments: Pick<Attachment, AsfAttachmentOnly>[]) {
      attachments.forEach((attachment) => {
        this.attachmentsBySectionId = {
          ...this.attachmentsBySectionId,
          [attachment.attachable_id]: [...(this.attachmentsBySectionId[attachment.attachable_id] || []), attachment],
        };
      });
    }

    onAttachmentDeleted(attachment: Pick<Attachment, AsfAttachmentOnly>) {
      this.attachmentsBySectionId = {
        ...this.attachmentsBySectionId,
        [attachment.attachable_id]: this.attachmentsBySectionId[attachment.attachable_id].filter((a) => a.id !== attachment.id),
      };
    }

    onEditSection(section: Pick<SubFormSection, AsfSfsOnly>) {
      this.openSectionForm?.edit(section.id);
    }

    editSectionGroup(sectionGroup: Pick<SubFormSectionGroup, AsfSfsgOnly>) {
      this.sectionGroupForm?.edit(sectionGroup.id);
    }

    insertSection(sectionOrGroup?: Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>) {
      this.openSectionForm?.create({
        index: sectionOrGroup?.index,
        group_id: (sectionOrGroup as Pick<SubFormSection, AsfSfsOnly>)?.sub_form_section_group_id,
      });
    }

    insertSectionGroup(sectionOrGroup?: Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>) {
      this.sectionGroupForm?.create(sectionOrGroup?.index);
    }

    initAdminSubForm() {
      this.blocking(
        async () => {
          const { data: subFormSections } = await this.$api.getSubFormSections(
            { filters: { sub_form_id: this.subForm.id }, only: ASF_SFS_ONLY, per_page: -1 },
            { cache: true }
          );
          this.sectionsById = keyBy(subFormSections, 'id');

          subFormSections.forEach((section) => {
            this.attachmentsBySectionId = { ...this.attachmentsBySectionId, [section.id]: section.attachments };
          });

          const { data: subFormSectionsGroups } = await this.$api.getSubFormSectionGroups(
            { filters: { sub_form_id: this.subForm.id }, only: ASF_SFSG_ONLY, per_page: -1 },
            { cache: true }
          );
          this.sectionGroupsById = keyBy(subFormSectionsGroups, 'id');

          this.dndList = this.initializeDndList();
        },
        { loading: true }
      );
    }

    onSectionQuestionsLoaded(subFormSectionId: string) {
      this.sectionQuestionsLoaded = { ...this.sectionQuestionsLoaded, [subFormSectionId]: true };
    }

    scrollToSectionBottom() {
      this.$nextTick(() => {
        const scrollToSectionId = sessionStorage.getItem('sectionIdAfterNew');
        if (!!scrollToSectionId) {
          const elClass = `.sub-form-section-show-${scrollToSectionId}`;
          const container = document.querySelector(elClass) as HTMLElement;

          container &&
            requestAnimationFrame(() => {
              window.scrollTo({
                top: container.offsetTop + container.scrollHeight,
                behavior: 'smooth',
              });
              sessionStorage.removeItem('sectionIdAfterNew');
              this.alreadyScrolled = true;
            });
        }
      });
    }

    beforeMount() {
      this.initAdminSubForm();
    }
  }
