
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { Matrix } from '@app/models/matrix/matrix';
  import type { SubForm } from '@app/models/sub-form';
  import type { MatrixQuestionOptions } from '@app/models/question-options/matrix-question-options';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  import Select2 from '../../../select2.vue';

  type DisplayOption = { label: string; value: string };

  @Component({ components: { EntitySelector, Select2, FormField } })
  export default class MatrixFieldOptions extends Vue {
    @Prop(Object) readonly value!: SubFormQuestion<MatrixQuestionOptions>;

    @Prop(Object) subForm!: SubForm;

    restrictionOptions: DisplayOption[] = [
      { label: 'X Axis', value: 'x_axis' },
      { label: 'Nothing', value: '' },
    ];

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    archivedNameTemplate(matrix: Pick<Matrix, 'name' | 'active' | 'id'>): JQuery {
      return inactiveTitleTemplate(matrix, 'name', 'app.labels.archived_name');
    }

    beforeMount(): void {
      if (this.onMainForm) return;

      this.$$patch(this.value.config.restrict_to, { restrict_type: '' }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['matrix_id', 'restrict_to'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
