
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import SvgLibraryTemplateForm from '../../../components/admin/svg-library/svg-library-template-form.vue';
  import type { CreateSvgTemplateRequest } from '@app/services/api/svg-templates-api';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, SvgLibraryTemplateForm } })
  export default class NewSvgTemplatePage extends Vue {
    @Ref() readonly form?: SvgLibraryTemplateForm;

    visible = true;

    svgTemplate: Partial<SvgLibraryTemplate> = {};

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'svg-templates-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: CreateSvgTemplateRequest): void {
      this.$api
        .createSvgTemplate(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
