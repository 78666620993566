
  import EmploymentTypeFormModal from '@app/components/admin/user-settings/tabs/employment-types-form-modal.vue';
  import { mixins } from 'vue-class-component';
  import { Component, Ref } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import { BaseTable } from '../../../base-table';
  import Blocking from '@app/mixins/blocking';
  import type { EmploymentType } from '@app/models/employment-type';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Tab, Tabs, BaseTable, EmploymentTypeFormModal } })
  export default class EmploymentTypesTab extends mixins(Blocking) {
    @Ref() readonly table?: BaseTable<EmploymentType>;
    @Ref() readonly modal?: EmploymentTypeFormModal;

    manager: Nullable<ListManager<EmploymentType>> = null;
    modalVisible = false;

    getManager(): ListManager<EmploymentType> {
      return new ListManager<EmploymentType>({
        fetchDataFunction: (params) => {
          return this.$api.getEmploymentTypes(
            {
              ...params,
              only: ['active', 'code', 'default_selection', 'id', 'name'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'desc', field: 'default_selection', sortField: 'default_selection' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.code'),
            name: 'code',
            sortField: 'code',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.name'),
            name: 'name',
            sortField: 'name',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.active'),
            name: 'active',
            sortField: 'active',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.default_selection'),
            name: 'default_selection',
            sortField: 'default_selection',
          },
        ],
      });
    }

    createOrUpdateEmploymentType(form: Partial<EmploymentType>): void {
      const promise = form.id ? this.$api.updateEmploymentType(form.id as number, form) : this.$api.createEmploymentType(form);
      const message = form.id
        ? this.$t('tenant.admin.settings.menus.user_settings.updated.employment_type')
        : this.$t('tenant.admin.settings.menus.user_settings.created.employment_type');
      this.blocking(async () => {
        await promise;
        toaster(message);
        this.toggleModal(false);
        this.$api.cache.clear();
        this.table?.reload();
      });
    }

    toggleModal(value: boolean, employmentType?: Partial<EmploymentType>): void {
      value && this.modal?.reset(employmentType);
      this.modalVisible = value;
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }
  }
