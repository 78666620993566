
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import SearchInput from '@app/components/search-input.vue';
  import { BaseTable } from '@app/components/base-table';
  import type { UserCollection } from '@app/models/user-collection';
  import { ListManager } from '@app/services/list-manager/list-manager';

  import UserCollectionActions from './user-collection-actions.vue';

  @Component({
    components: {
      BaseTable,
      Select2,
      FilterSelect,
      SearchInput,
      PaperTrailsModalLink,
      DsDropdown,
      UserCollectionActions,
    },
  })
  export default class UserCollectionsIndex extends Vue {
    @Ref() readonly table?: BaseTable<UserCollection>;

    manager: Nullable<ListManager<UserCollection>> = null;
    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    getManager(): ListManager<UserCollection> {
      return new ListManager<UserCollection>({
        fetchDataFunction: (params) => {
          return this.$api.getUserCollections(
            {
              ...params,
              filters: { ...params.filters, global: true },
              only: ['id', 'name', 'active', { rule_set: ['rules_description'] }],
            },
            { cache: true }
          );
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'id', sortField: 'id' }],
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          { title: this.$t('tenant.admin.user_collections.rule_set_description'), name: 'rule_set_description' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
          { title: '', name: 'operations', width: 'max-content', dataClass: 'text-right' },
        ],
        allowFilters: true,
      });
    }

    beforeMount() {
      this.manager = this.getManager();
    }
  }
