
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import { ValidationObserver } from 'vee-validate';
  import type { RoleLegacySettingsForm } from './role-tab-details/utils';
  import { getProfilePermissionsBaseOptions, rolePropsToUpdate } from './role-tab-details/utils';
  import Select2 from '@app/components/select2.vue';
  import { pick } from 'lodash';
  import type { Role } from '@app/models/role';

  @Component({ components: { DsModal, DsCheckbox, DsTextInput, Select2, ValidationObserver } })
  export default class RoleLegacySettingsModal extends Vue {
    @Model('input', { type: Boolean }) readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop({ type: Number }) readonly roleId!: number;

    role: Nullable<RoleLegacySettingsForm> = null;
    form: Partial<RoleLegacySettingsForm> = {};
    profilePermissionsBaseOptions = getProfilePermissionsBaseOptions();

    get showForm(): boolean {
      return !!Object.keys(this.form).length;
    }

    get othersLearning(): string {
      return this.form.profile_permissions?.others?.learning || '';
    }

    get selfLearning(): string {
      return this.form.profile_permissions?.self?.learning || '';
    }

    fetchRole() {
      this.$api.getRole(this.roleId, { only: rolePropsToUpdate }).then(({ data }) => {
        this.role = data;
        this.initForm(data);
      });
    }

    submit(): void {
      this.validator?.validate().then((result) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    onProfilePermissionsInput(id: 'others' | 'self', key: string, value: string): void {
      this.form = {
        ...this.form,
        profile_permissions: {
          ...this.form.profile_permissions,
          [id]: {
            ...this.form.profile_permissions?.[id],
            [key]: value || '',
          },
        } as Role['profile_permissions'],
      };
    }

    initForm(role: RoleLegacySettingsForm): void {
      this.form = pick(role, rolePropsToUpdate);
    }
  }
