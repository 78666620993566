
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Vue } from 'vue-property-decorator';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import SearchInput from '@app/components/search-input.vue';
  import RoleProfileWarnings from '@app/components/admin/role-profiles/role-profile-warnings.vue';
  import RoleProfileSelector from '@app/components/sub-form-list/role-profile-selector.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { Role } from '@app/models/role';
  import { Tuple } from '@app/utils/types/tuple';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';

  const only = Tuple([
    'id',
    'name',
    'system_code',
    'user_count',
    'role_profile_ids',
    { role_profiles: ['id', 'name', 'active', 'module_name_id', { module_name: ['display', 'active'] }] },
    'role_module_names',
  ] as const);

  @Component({
    components: { RecordIndexPageFiltersLayout, BaseTable, SearchInput, RoleProfileWarnings, FilterSelect, Select2, RoleProfileSelector },
  })
  export default class AdminRolesPage extends Vue {
    manager: Nullable<ListManager<Role>> = null;
    moduleNames: ModuleName[] = [];
    get fields(): ListManagerField<Role>[] {
      return [
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: '65px' },
        { title: this.$t('app.labels.title_and_code'), name: 'name', sortField: 'name' },
        { title: this.$t('tenant.admin.dashboard_panes.role_profiles'), name: 'role_profiles', filter: true },
        { title: this.$t('app.labels.user_count'), name: 'user_count' },
      ];
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    prettyUsersCount(count?: number): string {
      if (typeof count !== 'number') {
        return '';
      } else {
        if (!count) {
          return this.$t('users_count.zero');
        } else if (count === 1) {
          return this.$t('users_count.one');
        } else {
          return this.$t('users_count.other', { count: count });
        }
      }
    }

    getManager(): ListManager<Role> {
      return new ListManager<Role>({
        fetchDataFunction: (params) => {
          return this.$api.getRoles(
            {
              ...params,
              filters: {
                ...params.filters,
                active: true,
              },
              only,
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: this.fields,
        allowFilters: true,
      });
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    beforeMount(): void {
      this.fetchModuleNames();
    }

    fetchModuleNames(): void {
      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['id', 'name', 'plural_display', 'role_profiles'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
        });
    }
  }
