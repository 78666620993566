
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ExpenseQuestionOptions } from '@app/models/question-options/expense-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { EntitySelector, FormField } })
  export default class ExpenseFieldOptions extends Vue {
    @Prop(Object) value!: SubFormQuestion<ExpenseQuestionOptions>;

    get subFormListFilter(): DonesafeFilterOptions<SubFormList> {
      return {
        active: true,
        sub_form_list_type: 'expense_estimates',
        module_tab: {
          active: true,
          module_name: this.value.sub_form_section?.sub_form?.module_name,
        },
      };
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['sub_form_list_id'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
