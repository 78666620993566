
  import WorkflowLinkForm from '@app/components/admin/workflow-links/workflow-link-form.vue';
  import WorkflowForm from '@app/components/admin/workflows/workflow-form.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import type { WorkflowLink } from '@app/models/workflow-link';
  import { toaster } from '@app/utils/toaster';
  import bootbox from 'bootbox';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';

  @Component({ components: { WorkflowLinkForm, DsModal, WorkflowForm } })
  export default class WorkflowLinkFormModal extends Vue {
    @Model('input', { type: Boolean }) readonly value!: boolean;
    @Prop(Object) readonly workflowLink?: Partial<WorkflowLink>;
    @Prop(String) readonly conceptName?: string;

    @Ref() readonly form?: InstanceType<typeof WorkflowLinkForm>;

    submitWorkflowLink(workflowLink: Partial<WorkflowLink>): void {
      const promise = workflowLink.id
        ? this.$api.updateWorkflowLink(workflowLink.id, workflowLink)
        : this.$api.createWorkflowLink(workflowLink);
      promise
        .then(() => {
          toaster(this.$t('tenant.admin.workflow_links.form.successfully_updated'));
          window.location.reload();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    get title(): string {
      return this.workflowLink?.id
        ? this.$t('tenant.admin.workflow_links.form.link_type')
        : this.$t('tenant.admin.workflow_links.form.create_title');
    }

    deleteWorkflowLink(): void {
      bootbox.confirm({
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.workflowLink?.id &&
            this.$api
              .deleteWorkflowLink(this.workflowLink?.id)
              .then(() => {
                toaster(this.$t('tenant.admin.workflow_links.form.successfully_deleted'));
                window.location.reload();
              })
              .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
        },
      });
    }
  }
