
  import { useAccountStore } from '@app/stores/account';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import ApprovalTabSettings from '@app/components/sub-form-list-types/approval-tab-settings.vue';
  import BudgetsTabSettings from '@app/components/sub-form-list-types/budgets-tab-settings.vue';
  import NormalTabSettings from '@app/components/sub-form-list-types/normal-tab-settings.vue';
  import ProceduresTabSettings from '@app/components/sub-form-list-types/procedures-tab-settings.vue';
  import RecordRelationsTabSettings from '@app/components/sub-form-list-types/record-relations-tab-settings.vue';
  import RelationshipTabSettings from '@app/components/sub-form-list-types/relationship-tab-settings.vue';
  import ResponseCounterTabSettings from '@app/components/sub-form-list-types/response-counter-tab-settings.vue';
  import type SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import WorkflowTabSettings from '@app/components/sub-form-list-types/workflow-tab-settings.vue';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleTab } from '@app/models/module-tab';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import { SUB_FORM_LIST_TYPE_LABELS } from './module-tabs/utils';

  // TODO: add validation
  @Component({
    components: {
      DsTextInput,
      Select2,
      EntitySelector,
      ApprovalTabSettings,
      NormalTabSettings,
      WorkflowTabSettings,
      BudgetsTabSettings,
      ProceduresTabSettings,
      RecordRelationsTabSettings,
      RelationshipTabSettings,
      ResponseCounterTabSettings,
      ValidationProvider,
      ValidationObserver,
    },
  })
  export default class SubFormListForm extends Vue {
    @Prop(Object) readonly subFormList!: Partial<SubFormList>;
    @Prop(Boolean) readonly isHybrid?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<SubFormList> = {};
    kbModuleEnabled = false;

    moduleTab: Pick<ModuleTab, 'id' | 'related_module_name' | 'code'> | null = null;

    get accountStore() {
      return useAccountStore();
    }

    get moduleTabFilters(): DonesafeFilterOptions<ModuleTab> {
      return { module_name: this.moduleName?.name };
    }

    get moduleName(): ModuleName | null {
      return this.moduleTab?.related_module_name || null;
    }

    get subFormListTypeDisabled(): boolean {
      return (
        this.isHybrid ||
        (!!this.subFormList.id &&
          !!this.subFormList.sub_form_list_type &&
          [SubFormListType.approval, SubFormListType.workflow].includes(this.subFormList.sub_form_list_type))
      );
    }

    get typeComponent(): typeof SubFormListSettingsBase | null {
      return this.form.sub_form_list_type
        ? {
            [SubFormListType.approval]: ApprovalTabSettings,
            [SubFormListType.normal]: NormalTabSettings,
            [SubFormListType.workflow]: WorkflowTabSettings,
            [SubFormListType.budgets]: BudgetsTabSettings,
            [SubFormListType.procedures]: ProceduresTabSettings,
            [SubFormListType.record_relations]: RecordRelationsTabSettings,
            [SubFormListType.relationship]: RelationshipTabSettings,
            [SubFormListType.response_counter]: ResponseCounterTabSettings,
            [SubFormListType.expense_estimates]: null,
          }[this.form.sub_form_list_type]
        : null;
    }

    get subFormListTypeOptions(): [SubFormListType, string][] {
      const options: SubFormListType[] = [
        SubFormListType.normal,
        SubFormListType.workflow,
        SubFormListType.record_relations,
        SubFormListType.relationship,
        SubFormListType.response_counter,
      ];

      const legacyOptions = [SubFormListType.approval, SubFormListType.expense_estimates];

      this.accountStore.expensingEnabled && options.push(SubFormListType.budgets);

      this.kbModuleEnabled && options.push(SubFormListType.procedures);

      this.subFormList.id &&
        this.subFormList.sub_form_list_type &&
        legacyOptions.indexOf(this.subFormList.sub_form_list_type) > -1 &&
        options.push(this.subFormList.sub_form_list_type);

      if (this.subFormList.id && this.subFormList.sub_form_list_type === SubFormListType.normal) {
        const filteredOptions = options.filter((o) =>
          [SubFormListType.workflow, SubFormListType.approval, SubFormListType.normal].includes(o)
        );
        return this.mapOptionsToLabels(filteredOptions);
      }

      return this.mapOptionsToLabels(options);
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result &&
          this.$emit('submit', {
            ...this.form,
            add_icon: this.form.add_icon || null,
            default_template_id: this.form.default_template_id || null,
          });
      });
    }

    reset() {
      this.form = { ...this.subFormList };
    }

    onTypeChange(type: SubFormListType) {
      if (![SubFormListType.normal, SubFormListType.workflow, SubFormListType.approval].includes(type)) {
        this.form = { ...this.form, sub_form_ids: [], draft: undefined, multiple: undefined, allow_confidentiality: undefined };
      } else if ((type !== SubFormListType.normal && this.form.sub_form_ids?.length) || 0 > 1) {
        // todo: optimize
        this.form = { ...this.form, sub_form_ids: [(this.form.sub_form_ids || [])[0]] };
      }

      // TODO: add all the other types
      if (type !== SubFormListType.procedures) {
        this.form = { ...this.form, involvement_id: undefined };
      }
      // TODO: add all the other types
      if (type !== SubFormListType.record_relations) {
        this.form = {
          ...this.form,
          involvement_id: undefined,
          record_relation_question_id: undefined,
          options: {},
          relationship_options: undefined,
          record_relations_filters: [],
        };
      }
    }

    beforeMount() {
      this.reset();
      this.$api.getModuleNames({ filters: { active: true, name: 'Procedure' } }, { cache: true }).then(({ data }) => {
        this.kbModuleEnabled = data.length > 0;
      });
      this.subFormList.module_tab_id &&
        this.$api.getModuleTab(this.subFormList.module_tab_id, { only: ['id', 'code', 'related_module_name'] }).then(({ data }) => {
          this.moduleTab = data || null;
        });
    }

    mapOptionsToLabels(options: SubFormListType[]): [SubFormListType, string][] {
      return options.map((option) => [option, this.$t(SUB_FORM_LIST_TYPE_LABELS[option])]);
    }
  }
