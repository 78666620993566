
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class Uniq extends Vue {
    @Prop() readonly question!: SubFormQuestion;

    get isUnique() {
      return this.question.config.validate && 'uniq' in this.question.config.validate && this.question.config.validate?.uniq === 'true'
        ? 'True'
        : 'False';
    }
  }
