
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  @Component({ components: {} })
  export default class ProtectedInput extends mixins(WithBootbox) {
    @Model('input') readonly value!: string;
    @Prop(String) readonly warning!: string;
    @Ref() readonly input?: HTMLInputElement;

    enabled = false;

    async onUpdate() {
      if (
        await this.confirm(this.warning, {
          size: 'medium',
          backdrop: false,
          buttons: {
            confirm: { label: this.$t('app.buttons.continue'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
        })
      ) {
        this.enabled = true;
        this.$nextTick(() => this.input?.focus());
        this.$emit('edit-toggled', this.enabled);
      }
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get userCanCodeMatch(): boolean {
      return this.currentUserStore.hasSystemAdministrationPermission || this.currentUserStore.hasModuleConfigPermission;
    }
  }
