
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import SubFormListRestrictionSet from '@app/components/sub-form-list/sub-form-list-restriction-set.vue';
  import type { RestrictionSetForm } from './utils';
  import { buildRestrictionSetForm } from './utils';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import Switcher from '../switcher.vue';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  type PermissionType = 'view' | 'edit' | 'archive' | 'approve';

  @Component({ components: { DsCheckbox, SubFormListRestrictionSet, DsModal, Tabs, Tab, Switcher } })
  export default class SubFormListEditRestrictionModal extends Vue {
    @Prop(String) readonly conceptName!: string;
    @Prop(Boolean) readonly approvalEnabled!: boolean;
    @Prop(Object) readonly subFormList!: SubFormList;

    visible = true;
    current: PermissionType = 'view';

    get permissionTypes(): PermissionType[] {
      return this.approvalEnabled ? ['view', 'edit', 'archive', 'approve'] : ['view', 'edit', 'archive'];
    }

    withPermissionType<T extends { permission_type: string }>(permissionType: PermissionType, array: T[] | undefined): T[] {
      return array?.filter((r) => r.permission_type === permissionType) || [];
    }

    form: Record<string, RestrictionSetForm> = {};

    beforeMount(): void {
      this.form = this.permissionTypes.reduce((memo, type) => {
        return {
          ...memo,
          [type]: buildRestrictionSetForm(
            this.withPermissionType(type, this.subFormList.sub_form_list_restrictions),
            this.withPermissionType(type, this.subFormList.sub_form_list_roles),
            this.withPermissionType(type, this.subFormList.sub_form_list_role_profiles)
          ),
        };
      }, {});

      this.enabledTypes = this.permissionTypes
        .map((type) => {
          const typeEnabled: { [key: string]: boolean } = {};
          typeEnabled[type] =
            !!(this.subFormList.sub_form_list_restrictions || []).find((r) => r.permission_type === type) ||
            !!(this.subFormList.sub_form_list_roles || []).find((r) => r.permission_type === type) ||
            !!(this.subFormList.sub_form_list_role_profiles || []).find((r) => r.permission_type === type);
          return typeEnabled;
        })
        .reduce((p, c) => ({ ...p, ...c }), {});
    }

    enabledTypes: Record<string, boolean> = {};

    submit(): void {
      const submitForm = this.permissionTypes.reduce((memo, type) => {
        if (this.enabledTypes[type]) {
          return { ...memo, [type]: this.form[type] };
        }
        return memo;
      }, {});
      this.$api
        .updateSubFormListRestrictions(this.subFormList.id, submitForm)
        .then(() => {
          this.$emit('reload');
          toaster({ text: this.$t('tenant.module_tabs.show.restriction_labels.restrictions_saved'), hideAfter: 1500 });
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    restrictedType(permissionType: string): boolean {
      return this.enabledTypes[permissionType] && !!this.form[permissionType]?.restrictions?.length;
    }

    restrictedLabel(permissionType: string): string {
      return this.restrictedType(permissionType)
        ? this.$t('tenant.module_tabs.show.restriction_labels.restricted')
        : this.$t('tenant.module_tabs.show.restriction_labels.unrestricted');
    }

    tabTitle(permissionType: string): string {
      switch (permissionType) {
        case 'edit':
          return this.$t('tenant.module_tabs.show.restriction_labels.edit');
        case 'archive':
          return this.$t('tenant.module_tabs.show.restriction_labels.archive');
        case 'approve':
          return this.$t('tenant.module_tabs.show.restriction_labels.approve');
        default:
          return this.$t('tenant.module_tabs.show.restriction_labels.view');
      }
    }

    toggleRestrictionEdit(permissionType: string, newValue: boolean): void {
      this.enabledTypes[permissionType] = newValue;
      if (newValue) {
        const restriction = this.form[permissionType].restrictions.find((r) => r.relationship == 'other');
        if (restriction) {
          if (restriction.viewable) restriction.viewable = false;
        } else if (!this.form[permissionType].restrictions.length) {
          this.form[permissionType].restrictions.push({ relationship: 'other', viewable: false });
        }
      }
    }
  }
