
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import HourTypeForm from '../../../components/admin/hour-types/hour-type-form.vue';
  import type { HourType } from '@app/models/hour-type';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { HourTypeForm, DsModal } })
  export default class HourTypeNew extends Vue {
    @Ref() readonly form?: HourTypeForm;

    hourType = {};

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-hour-types-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<HourType>): void {
      this.$api
        .createHourType(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
