
  import { FieldType } from '@app/models/sub-form-question';
  import { useAccountStore } from '@app/stores/account';
  import { Tab, Tabs } from 'uiv';
  import { ValidationObserver } from 'vee-validate';
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import RecordCalculationForm from './record-calculation-form.vue';
  import RecordCalculationValueHandlingForm from './record-calculation-value-handling-form.vue';
  import RecordCalculationVisualOptionsForm from './record-calculation-visual-options-form.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import {
    DEFAULT_PRECISION,
    EmptyHandlingType,
    RoundingBehaviour,
    VisualFormat,
    CalculationClass,
    CalculationMethod,
  } from '@app/models/record-calculation';
  import { toaster } from '@app/utils/toaster';
  import { pick } from 'lodash';

  const baseAttributes = [
    'id',
    'name',
    'module_name_id',
    'permission_check',
    'variable_code',
    'calculation_class',
    'data_source',
    'calculation_method',
  ];

  @Component({
    components: {
      RecordCalculationForm,
      RecordCalculationValueHandlingForm,
      RecordCalculationVisualOptionsForm,
      Tab,
      Tabs,
      ValidationObserver,
    },
  })
  export default class RecordCalculationTabs extends Vue {
    @Prop(Object) readonly recordCalculation!: RecordCalculation;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly mainValidator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly visualValidator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly valueValidator?: InstanceType<typeof ValidationObserver>;

    form: Partial<RecordCalculation> = {};
    currentTab = 0;
    stateOptions: string[] = [];
    mainInvalid = false;
    visualInvalid = false;
    valueInvalid = false;

    get disabledValueHandlingTab(): boolean {
      return this.form.calculation_method === CalculationMethod.record_count && this.form.calculation_class === CalculationClass.stored;
    }

    get accountStore() {
      return useAccountStore();
    }

    onSubmit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.submitForm(this.form);
        }
      });
    }

    submitForm(recordCalculation: Partial<RecordCalculation>): void {
      const promise = recordCalculation.id
        ? this.$api.updateRecordCalculation(recordCalculation.id, this.normalizeForm(recordCalculation))
        : this.$api.createRecordCalculation(this.normalizeForm(recordCalculation));
      promise
        .then(() => {
          toaster(this.$t('tenant.admin.record_calculation.modal.record_calculation_updated'));
          this.$emit('reload');
          this.validator?.reset();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    normalizeForm(recordCalculation: Partial<RecordCalculation>): Partial<RecordCalculation> {
      let baseOptions = pick(recordCalculation, [...baseAttributes, 'options']);
      if (recordCalculation.calculation_class === CalculationClass.stored) baseOptions = { ...baseOptions, permission_check: false };

      return recordCalculation.calculation_method === CalculationMethod.formula
        ? { ...baseOptions, formula: recordCalculation.formula }
        : {
            ...baseOptions,
            sub_form_question_code: recordCalculation.sub_form_question_code,
            sub_form_list_ids: recordCalculation.sub_form_list_ids,
            sub_form_ids: recordCalculation.sub_form_ids,
          };
    }

    showEmptyHandling(recordCalculation: Nullable<RecordCalculation>): boolean {
      return (
        recordCalculation?.calculation_class !== CalculationClass.stored &&
        recordCalculation?.calculation_method !== CalculationMethod.total
      );
    }

    updateTabsState() {
      this.mainInvalid = !!this.mainValidator?.flags?.invalid;
      this.visualInvalid = !!this.visualValidator?.flags?.invalid;
      this.valueInvalid = !!this.valueValidator?.flags?.invalid;
    }

    initForm(): void {
      const defaultOptions = this.recordCalculation?.id ? {} : { sub_form_states: this.stateOptions.length ? [this.stateOptions[0]] : [] };

      this.form = {
        ...pick(this.recordCalculation, [...baseAttributes, 'formula', 'sub_form_list_ids', 'sub_form_ids', 'sub_form_question_code']),
        options: {
          visibility: true,
          ...defaultOptions,
          value_rounding_behaviour: RoundingBehaviour.default,
          value_precision: DEFAULT_PRECISION,
          ...(this.showEmptyHandling(this.recordCalculation) ? { value_empty_handling: EmptyHandlingType.zero } : {}),
          visual_rounding_behaviour: RoundingBehaviour.default,
          visual_precision: DEFAULT_PRECISION,
          visual_currency_symbol: this.accountStore.currencySymbol,
          visual_format: VisualFormat.number,
          stored_in_question_types: [FieldType.calculator, FieldType.calculation_text, FieldType.calculation_select],
          ...this.recordCalculation?.options,
        },
      };
    }

    beforeMount(): void {
      this.initForm();
    }
  }
