
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  @Component({
    components: {
      EntitySelector,
      EventInputMethodSwitch,
      Select2,
      AdvancedAutosuggest,
      FormField,
    },
  })
  export default class UpdateSubFormCompletionStateEventForm extends BaseEventForm<Dictionary<string>> {
    get stateOptions(): [string, string][] {
      return [
        [SubFormCompletionStage.Draft, SubFormCompletionStage.Draft],
        [SubFormCompletionStage.Complete, SubFormCompletionStage.Complete],
      ];
    }

    get showWarning(): boolean {
      return this.form.state == SubFormCompletionStage.Complete;
    }

    beforeMount(): void {
      this.form = {
        ...this.values,
      };
    }
  }
