
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop } from 'vue-property-decorator';
  import ColorInput from '../color-input.vue';
  import Select2 from '../select2.vue';
  import { groupBy, mapValues, toPairs } from 'lodash';
  import AdvancedAutosuggest from '../advanced-autosuggest/advanced-autosuggest.vue';
  import type { FilterParams } from '@app/services/api/utils-api';
  import RuleRestrictionsSelector from '@app/components/admin/rule-sets/rule-restrictions-selector.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType, SUBFORM_FILTERABLE_TYPES, FILTERABLE_TYPES } from '@app/models/sub-form-question';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
  import OrderOptionsSelector from '@app/components/admin/questions/order-options/order-options-selector.vue';
  import { mixins } from 'vue-class-component';
  import { WithOrderOptions } from '@app/components/admin/questions/order-options/with-order-options';

  interface GroupedOption {
    group?: string;
    label: string;
    value: string;
  }

  @Component({
    components: { ColorInput, Select2, AdvancedAutosuggest, RuleRestrictionsSelector, OrderOptionsSelector },
  })
  export default class SubFormListViewOptionsForm extends mixins(WithOrderOptions) {
    @Prop({ required: true, type: Object }) readonly subFormList!: SubFormList;
    @Prop({ required: true, type: String }) readonly conceptName!: ModuleName['name'];

    form: Partial<SubFormList> = {};
    questions: SubFormQuestion[] = [];
    defaultUserAvatarOptions = [
      ['none', 'None'],
      ['creator', 'Creator'],
    ];
    injectableOptions: PlaceholderItem[] = [];
    tabSectionGroupsOptions: [number, string][] = [];
    orderColumns: GroupedOption[] = [];
    loading = false;

    async beforeMount(): Promise<void> {
      this.form = { ...this.subFormList };

      this.loading = true;

      await Promise.all([
        this.loadQuestions(),
        this.fetchInjectableOptions(),
        this.getTabSectionGroupsOptions(),
        this.loadSortingOptions({ subFormListId: this.form.id }).then((opts) => (this.orderColumns = opts)),
      ]);

      this.loading = false;
    }

    get filterParams(): Partial<FilterParams> {
      return { hide_visible_sub_form_list_rules: true };
    }

    get displaySubFormQuestionOptions(): [string, string][] {
      const questionsByCode = groupBy(
        this.questions.filter((q) => (SUBFORM_FILTERABLE_TYPES as FieldType[]).includes(q.field_type)),
        ({ code }) => code
      );

      return toPairs(mapValues(questionsByCode, (qs) => qs.map(({ title }) => title).join(' | ')));
    }

    get questionAvatarOptions(): [string, string][] {
      return this.questions.filter((q) => q.field_type === FieldType.single_person_selector).map(({ code, title }) => [code, title]);
    }

    get showApprovalFields(): boolean {
      return this.subFormListType === SubFormListType.approval || this.subFormListType === SubFormListType.workflow;
    }

    get subFormListType(): SubFormListType {
      return this.subFormList.sub_form_list_type;
    }

    get actionsLinkedToSubFormCompletion(): boolean {
      return this.isFormType && this.accountStore.data.action_options?.link_to_sub_form_completion === 'true';
    }

    get hasAddButton(): boolean {
      return this.isFormType || [SubFormListType.record_relations, SubFormListType.relationship].includes(this.subFormListType);
    }

    get hasAddIcon(): boolean {
      return this.hasAddButton && this.subFormListType !== SubFormListType.relationship;
    }

    get isFormType(): boolean {
      return [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow].includes(this.subFormListType);
    }

    get showTabSectionGroupConfiguration(): boolean {
      return this.isFormType && this.tabSectionGroupsOptions.length > 0;
    }

    get singleFormTypeApplicable(): boolean {
      return this.isFormType && this.subFormList.sub_form_ids?.length === 1 && !this.loading;
    }

    get userAvatarOptions(): string[][] {
      return [...this.defaultUserAvatarOptions, ...this.questionAvatarOptions];
    }

    get accountStore() {
      return useAccountStore();
    }

    async loadQuestions(): Promise<SubFormQuestion[]> {
      const { data } = await this.$api.getSubFormQuestions(
        {
          filters: {
            sub_form_section: { sub_form_id: this.subFormList.sub_form_ids },
            field_type: FILTERABLE_TYPES,
          },
        },
        { cache: true }
      );

      this.questions = data;

      return data;
    }

    fetchInjectableOptions(): Promise<void> {
      return this.$api
        .getInjectableOptions(
          {
            concept_name: 'SubFormCompletion',
            injectable_arguments: {
              sub_form_id: this.subFormList.sub_form_ids?.at(0) || NaN,
              include_all: false,
              include_calculations: false,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.injectableOptions = data;
        })
        .catch(() => {
          //
        });
    }

    getTabSectionGroupsOptions(): Promise<void> {
      return this.$api
        .getTabSectionGroups({ only: ['name', 'id'], filters: { module_name: this.conceptName, active: true } }, { cache: true })
        .then(({ data }) => {
          this.tabSectionGroupsOptions = data.map((tabSectionGroup) => [tabSectionGroup.id, tabSectionGroup.name]);
        })
        .catch(() => {
          //
        });
    }

    iconListTemplate(option: { id: string; text: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      return $(
        `<div>
            <i class="material-icons">${option.id}</i>
            <span style="position: relative; bottom: 7px">${option.id}</span>
          </div>`
      );
    }

    submit(): void {
      this.$emit('submit', this.form);
    }
  }
