
  import { Component, Ref, Watch, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../../../components/base-table';
  import type { AxiosPromise } from 'axios';
  import * as bootbox from 'bootbox';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { BaseTable } })
  export default class SvgTemplatesIndexPage extends Vue {
    @Ref() readonly table?: BaseTable<SvgLibraryTemplate>;

    manager = new ListManager<SvgLibraryTemplate>({
      fetchDataFunction: (): AxiosPromise<SvgLibraryTemplate[]> => {
        return this.$api.getSvgTemplates();
      },
      useHistory: true,
      per_page: 10,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: 'ID', name: 'id', sortField: 'id' },
        { title: 'Name', name: 'name', sortField: 'name' },
        { title: '', name: 'operations' },
      ],
    });

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      value === 'reload' && this.table?.reload();
    }

    removeTemplate(template: SvgLibraryTemplate): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure you want to remove this template?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteSvgTemplate(template.id)
              .then(() => {
                this.$api.cache.clear();
                this.table?.reload();
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }
  }
