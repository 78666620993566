import type { IndexFilter } from '@app/models/index-filter';
import qs from 'qs';

export const pathWithIndexFilterParams = (
  path: string,
  indexFilter?: IndexFilter,
  defaultIndexFilter?: Partial<IndexFilter>
): string | undefined => {
  const newFilter = indexFilter || defaultIndexFilter;
  if (newFilter) {
    const base = path.split('?')[0];
    const queryParams = qs.parse(path.split('?')[1]);
    if (!queryParams.filters) {
      const sortField = newFilter.sorting_order?.sort;
      const sort = queryParams.sort || (sortField && newFilter.sorting_order?.reverse ? `-${sortField}` : sortField);
      const queryString = qs.stringify({ ...queryParams, filters: newFilter.filter, sort }, { arrayFormat: 'brackets' });
      const newPath = [base, queryString].join('?');
      if (newPath != path) {
        return newPath;
      }
    }
  }
};
