
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { groupBy } from 'lodash';
  import { v4 as generateUUID } from 'uuid';
  import InvolvementsPanelItem from '@app/components/involvement/involvements-panel-item.vue';
  import type { BaseRecord } from '@app/models/module-record';
  import type { Involvement } from '@app/models/involvement';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { InvolvementsPanelItem } })
  export default class InvolvementsPanel extends Vue {
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(String) readonly moduleName!: string;
    @Prop(String) readonly entityType!: string;
    @Prop(Object) readonly entity!: Pick<BaseRecord, 'id'>; // this will be the whole record in the future

    involvements: Involvement[] = [];
    userInvolvements: UserInvolvement[] = [];
    refreshKey = generateUUID();
    savingHash: Record<number, boolean> = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    mounted(): void {
      this.fetchData();
    }

    editUserInvolvement(props: { userId: number; userInvolvement: UserInvolvement }, involvementId: number): void {
      this.deleteInvolvement(props.userInvolvement.id);
      this.addInvolvement(props.userId, involvementId);
    }

    deleteInvolvement(userInvolvementId: number): void {
      this.$api
        .deleteUserInvolvement(userInvolvementId)
        .then(() => this.fetchData())
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    get userInvolvementGroups(): Record<number, UserInvolvement[]> {
      return groupBy(this.userInvolvements, 'involvement_id');
    }

    get visibleInvolvements(): Involvement[] {
      return Array.isArray(this.involvements)
        ? this.involvements.filter((inv) => this.isVisible(inv) || (!this.printing && this.currentUserStore.data?.admin))
        : [];
    }

    isVisible(involvement: Involvement): boolean {
      const hidden = !this.userInvolvementGroups[involvement.id] && involvement.hide_empty;
      return !hidden || (this.canEdit && involvement.can_add && involvement.involvement_type === 'standard');
    }

    addInvolvement(userIds: number | number[], involvementId: number): void {
      const idsArray = (Array.isArray(userIds) ? userIds : [userIds]).filter(Boolean);

      if (idsArray.length) {
        this.savingHash = { ...this.savingHash, [involvementId]: true };
        Promise.all(
          idsArray.map((userId: number) => {
            return this.$api.createUserInvolvement({
              record_id: this.entity.id,
              record_type: this.entityType,
              user_id: userId,
              involvement_id: involvementId,
            });
          })
        )
          .then(() => {
            this.fetchData();
            toaster(this.$t('controllers.defaults.added'));
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          })
          .finally(() => {
            this.savingHash = { ...this.savingHash, [involvementId]: false };
          });
      }
    }

    fetchData(): void {
      this.$api
        .getInvolvements({
          filters: {
            module_name: this.moduleName,
            active: true,
          },
          per_page: -1,
          only: [
            'involvement_type',
            'can_add',
            'multiple',
            'can_delete',
            'hide_empty',
            'id',
            'name',
            'user_filters',
            'restrict_available_users',
          ],
          sort: 'index',
        })
        .then(({ data }) => {
          if (!Array.isArray(data)) {
            this.$rollbar.info('Involvements fetched', data);
          }
          this.involvements = data;
          // Just to refresh forms
          this.refreshKey = generateUUID();
        });

      this.$api
        .getUserInvolvements({
          filters: {
            record_type: this.entityType,
            record_id: this.entity.id,
          },
          per_page: -1,
          only: ['id', 'user', 'involvement_id'],
          include: ['user', 'can_be_opened', 'avatar_url'],
        })
        .then(({ data }) => {
          this.userInvolvements = data;
        });
    }
  }
