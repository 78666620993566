
  import { Component, Prop } from 'vue-property-decorator';
  import PlaceholderProfilesForm from '@app/components/admin/placeholder-profiles/placeholder-profiles-form.vue';
  import bootbox from 'bootbox';
  import BasePlaceholderProfile from './base-placeholder-profile';
  import type { PlaceholderFormOnly } from '@app/pages/admin/placeholder_profiles/utils';
  import { PLACEHOLDER_FORM_ONLY } from '@app/pages/admin/placeholder_profiles/utils';
  import { isEmpty } from 'lodash';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { PlaceholderProfilesForm, DsModal } })
  export default class PlaceholderProfilesEdit extends BasePlaceholderProfile {
    @Prop([String, Number]) readonly id!: string | number;

    get placeholderProfileLoaded(): boolean {
      return !isEmpty(this.placeholderProfile);
    }

    deletePlaceholderProfile({ id }: Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>>): void {
      id &&
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.settings.placeholder_profiles.edit.delete_warning'),
          buttons: {
            confirm: { label: this.$t('app.buttons.proceed'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.blocking(async () => {
                await this.$api.deletePlaceholderProfile(id);
                this.$api.cache.clear();
                this.closeModal('reload');
              });
            }
          },
        });
    }

    toggleActive({ active, id }: Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>>): void {
      if (active) {
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.settings.placeholder_profiles.edit.archive_warning'),
          buttons: {
            confirm: { label: this.$t('app.buttons.proceed'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result && id) this.updatePlaceholderProfile({ id, active: !active });
          },
        });
      } else {
        this.updatePlaceholderProfile({ id, active: !active });
      }
    }

    onFormSubmit(form: Partial<Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>>>): void {
      form.id && this.updatePlaceholderProfile(form);
    }

    updatePlaceholderProfile(form: Partial<Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>>>): void {
      const id = form.id;
      if (id) {
        this.blocking(
          async () => {
            await this.$api.updatePlaceholderProfile(id, form);
            this.$api.cache.clear();
            this.closeModal('reload');
          },
          {
            errorCb: this.onSubmitError,
          }
        );
      }
    }

    beforeMount() {
      this.$api
        .getPlaceholderProfile(Number(this.id), { only: PLACEHOLDER_FORM_ONLY, include: ['full_config'] }, { cache: true })
        .then(({ data }) => {
          this.placeholderProfile = data;
        });
    }
  }
