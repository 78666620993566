
  import DashboardGrid from '@app/components/dashboard-grid.vue';
  import DashboardSixBySixEditGrid from '@app/components/admin/dashboards/dashboard-six-by-six-edit-grid.vue';
  import Select2 from '@app/components/select2.vue';
  import Blocking from '@app/mixins/blocking';
  import type DashboardPane from '@app/models/dashboard-pane';
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
  import Chartkick from 'vue-chartkick';
  import type { Widget } from '@app/models/widget';
  import { pick, toPairs } from 'lodash';
  import { toaster } from '@app/utils/toaster';
  import { ValidationObserver } from 'vee-validate';
  import { DashboardPaneSize } from '@app/models/dashboard-pane';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';

  import FormField from '../questions/edit/form-field.vue';

  @Component({
    components: { DashboardGrid, Select2, FormField, ValidationObserver, DashboardSixBySixEditGrid },
  })
  export default class DashboardPaneEdit extends Blocking {
    @Prop({ type: [String, Number] }) readonly id!: number | string;
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;

    @Ref() readonly dashboardGrid?: DashboardGrid;

    dashboardPane: Nullable<DashboardPane> = null;
    dashboardKey: number = 0;

    get createWidgetRouteName() {
      switch (this.$route.name) {
        case 'admin-module-dashboard-panes-edit':
          return 'admin-module-dashboard-panes-edit-new-widget';
        default:
          return 'admin-dashboard-pane-edit-new-widget';
      }
    }

    get cancelTo() {
      switch (this.$route.name) {
        case 'admin-dashboard-pane-edit':
          return { name: 'admin-dashboard-pane-groups-index' };
        case 'admin-module-dashboard-panes-edit':
        default:
          return { name: 'admin-dashboard-panes-index', params: { id: `${this.id}`, moduleNameId: `${this.$route.params.moduleNameId}` } };
      }
    }

    get sixBySix() {
      return this.dashboardPane?.size === DashboardPaneSize.half;
    }

    get sizeOptions() {
      return toPairs({
        [DashboardPaneSize.standard]: this.$t('tenant.admin.dashboard_panes.sizes.standard'),
        [DashboardPaneSize.half]: this.$t('tenant.admin.dashboard_panes.sizes.6x6'),
      });
    }

    get widgets() {
      return this.dashboardPane?.widgets || [];
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.getDashboardPane();
      }
    }

    saveWidgets(showNotice: boolean) {
      this.blocking(async () => {
        const { subGridWidgets, widgetsToSave } = (await this.dashboardGrid?.save()) || {};
        const config = subGridWidgets ? subGridWidgets : widgetsToSave;

        const widgets = (widgetsToSave || []).map((widget) => {
          const chart = Chartkick.charts['chart-' + widget.id];
          if (!!chart) chart.refreshData();
          return { ...pick(widget, ['x', 'y']), id: Number(widget.id), width: widget.w, height: widget.h } as Pick<
            Widget,
            'id' | 'x' | 'y' | 'width' | 'height'
          >;
        });

        if (widgets.length) {
          await Promise.all([
            this.$api.updateWidgetPositions({ data: widgets }),
            this.$api.updateDashboardPane(this.id, { size: this.dashboardPane?.size, config }),
          ]);

          showNotice && toaster({ text: this.$t('tenant.admin.dashboard_panes.saved'), position: 'top-right' });
        }
      });
    }

    async getDashboardPane() {
      const { data: dashboardPane } = await this.$api.getDashboardPane(this.id, { include: ['widgets'] }, { cache: true });
      this.dashboardPane = dashboardPane;
      this.dashboardKey++;
    }

    beforeMount() {
      this.blocking(async () => {
        await this.getDashboardPane();
      });
    }
  }
