
  import Blocking from '@app/mixins/blocking';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { sortBy } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import SubFormCompletionShow from '../sub-form-completion/sub-form-completion-show.vue';
  import DsPopover from '../ds-popover.vue';
  import WithModuleRecordCompletionShow from './with-module-record-completion-show';
  import { mixins } from 'vue-class-component';

  @Component({ components: { SubFormCompletionShow, DsPopover } })
  export default class ModuleRecordShowMore extends mixins(Blocking, WithModuleRecordCompletionShow) {
    recordCalculations: RecordCalculation[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get sortedRecordCalculations() {
      return sortBy(this.recordCalculations || [], 'index');
    }

    calculationName(rc: RecordCalculation) {
      return rc.options.visibility
        ? this.$adminSanitize(rc.name)
        : this.$t('app.labels.hidden_name', { name: this.$adminSanitize(rc.name) });
    }

    init() {
      this.blocking(async () => {
        await this.fetchModuleRecord();

        const { data: mn } = await this.$api.getModuleName(
          Number(this.record?.module_name_id),
          { only: ['record_calculations'] },
          { cache: true }
        );
        this.recordCalculations = mn?.record_calculations || [];
      });
    }
  }
