
  import { Component } from 'vue-property-decorator';
  import ScheduledEventsForm from '@app/components/admin/scheduled-events/scheduled-events-form.vue';
  import type { ScheduledEventOnly } from '@app/components/admin/scheduled-events/utils';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import type { Event } from '@app/models/event';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import WithScheduleSubmit from './with-schedule-submit';

  @Component({ components: { ScheduledEventsForm } })
  export default class ScheduledEventsNew extends WithScheduleSubmit {
    scheduledEvent: Nullable<Omit<Pick<ScheduledEvent, ScheduledEventOnly>, 'id'>> = null;
    event: Partial<Event> = {
      enable_retries: false,
      notification_email: null,
      per: 'new_user_collection',
      event_type: this.$route.query.create_module as string,
      options: {
        values: {},
      },
      retries: 0,
    };

    beforeMount(): void {
      if (!this.$route.query.create_module) {
        changeLocation('/admin/settings/scheduled_events');
      }
    }

    onSubmitEvent(scheduledEvent: Omit<Pick<ScheduledEvent, ScheduledEventOnly>, 'id'>) {
      this.submit(scheduledEvent, this.event, {
        onSubmitComplete: this.onSubmitComplete,
      });
    }

    onSubmitComplete(data: Pick<ScheduledEvent, ScheduledEventOnly>) {
      toaster(this.$t('controllers.defaults.successfully_created'));
      this.$router.push({
        name: 'admin-settings-scheduled-events-edit',
        params: { scheduledEventId: `${data.id}` },
        ...(this.$route.query.redirect_to ? { query: { redirect_to: this.$route.query.redirect_to } } : {}),
      });
    }
  }
