
  import { isReadonlyLookup } from '@app/services/model-helpers';
  import { camelCase, compact, uniq, upperFirst } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { FieldType } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { titleize } from '@app/utils/titleize';
  import { Collapse } from 'uiv';

  import DsCollapseLink from '../../ds-collapse-link.vue';
  import type { DateCalculation, DateRestrictTo } from '../questions/settings/models';
  import { displayQuestionTitleAndId } from '../../../utils/display-question';
  import type { LookupSourceOption } from '../../../services/api/sub-form-questions-api';

  import { SFQ_SHOW_COMPONENTS } from './options/show/components';

  @Component({ components: { DsCollapseLink, ...SFQ_SHOW_COMPONENTS, Collapse }, methods: { displayQuestionTitleAndId, titleize } })
  export default class SubFormQuestionShowOptions extends Vue {
    @Prop(Object) readonly question!: SubFormQuestion;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Number) readonly subFormId!: number;

    relatedModuleName: Nullable<Pick<ModuleName, 'name' | 'id'>> = null;
    restrictSubFormList: Pick<SubFormList, 'title'> = { title: '' };
    moduleName: Nullable<Pick<ModuleName, 'feature_set' | 'id'>> = null;
    moduleRecordsModuleNames: Pick<ModuleName, 'name' | 'id'>[] = [];
    showQuestion: Nullable<Pick<SubFormQuestion, 'id' | 'title' | 'config' | 'question'>> = null;
    showLookupOption = false;
    lookupSourceOptionError: Nullable<string> = null;
    lookupSourceOptions: LookupSourceOption[] = [];
    showDateCalculationConfig = false;
    fetchingDateCalculationConfig = true;
    dateCalculationConfigError: Nullable<string> = null;
    dateCalculationQuestions: SubFormQuestion[] = [];
    apiLookupQuestion: Nullable<Pick<SubFormQuestion, 'title' | 'code'>> = null;
    baseFormId: number | null = null;

    get restrictTo(): Maybe<DateRestrictTo> {
      if (this.question.field_type !== FieldType.matrix) return this.config?.restrict_to as DateRestrictTo;
    }

    get config(): SubFormQuestion['config'] {
      return this.question.config;
    }

    get useType(): BaseQuestionOptions['use_type'] {
      return this.config.use_type;
    }

    get readViewMode(): BaseQuestionOptions['read_view_mode'] {
      return this.config.read_view_mode;
    }

    get mode(): BaseQuestionOptions['mode'] {
      return this.config.mode;
    }

    get isLookup(): boolean {
      return this.config?.mode === 'lookup';
    }

    get lookup(): BaseQuestionOptions['lookup'] {
      return this.config.lookup;
    }

    get dateCalculation(): Maybe<DateCalculation> {
      return this.config.date_calculation;
    }

    get nonDefaultMode(): string {
      if (!this.mode) return '';

      const mode = titleize(this.mode);

      if (this.isLookup) {
        if (this.lookup?.readonly === 'true') {
          return this.$t('tenant.admin.sub_form_questions.options.show.read_only_mode', { mode });
        } else {
          return this.$t('tenant.admin.sub_form_questions.options.show.fetch_mode', { mode });
        }
      } else {
        return mode;
      }
    }

    get prettyRestrictions(): Maybe<string> {
      if (!!this.restrictTo) {
        const baseRestrict = compact([this.restrictTo.operator, this.restrictTo.restrict_type]).join(' ');
        if (this.restrictTo.sub_form_question_code) {
          return `${baseRestrict}(${this.restrictTo.sub_form_question_code})`;
        }
        return baseRestrict;
      }
    }

    get hideIfPublic(): Maybe<StringBoolean> {
      return this.config.hide_if_public;
    }

    get showQuestionValue(): string | string[] {
      return this.question?.config?.show?.value || '';
    }

    get showQuestionReadonlyLookup(): boolean {
      return !!this.showQuestion && isReadonlyLookup(this.showQuestion);
    }

    get displayMfql(): boolean {
      return !!this.moduleName?.feature_set?.mfql && !!this.question?.supports_mfql;
    }

    get prettyCanAddOptions(): string {
      let options: string[] = this.canAddOptions();
      if (!!this.config.add?.module_records && !!this.moduleRecordsModuleNames.length) {
        options = [...options, ...this.moduleRecordsModuleNames.map((m) => m.name)];
      }
      if (!!this.config.main_form_id && this.relatedModuleName) options = [...options, this.relatedModuleName.name];
      return titleize(options.join(', '));
    }

    get component(): Maybe<string> {
      const componentName = `${upperFirst(camelCase(this.question.field_type))}ShowOptions`;
      if (componentName in SFQ_SHOW_COMPONENTS) return componentName;
    }

    get lookupSourceOption(): Maybe<{ field: string; title: string }> {
      const option = this.lookupSourceOptions[0];
      const lookup = this.lookup;

      if (!option || !lookup) return;

      const key = lookup[option.type];

      if (!key) return;

      const field = (option.options || []).find(({ code }) => code === key);

      if (!field) {
        this.lookupSourceOptionError = this.$t('tenant.admin.sub_form_questions.advanced_options.lookup_source_option_is_not_allowed');
        return;
      }

      return { title: option.title, field: field.title };
    }

    get dateCalculationSource(): Maybe<string> {
      switch (this.config?.date_calculation?.source_type) {
        case 'question':
          return this.$t('components.admin.questions.date_calculation_options.add_user_input_days_to_date_field');
        case 'custom':
          return this.$t('components.admin.questions.date_calculation_options.add_set_days_to_date_field');
      }
    }

    beforeMount() {
      if (!!this.restrictTo?.restrict_type && !!this.restrictTo?.sub_form_list_id) {
        this.$api.getSubFormList(this.restrictTo.sub_form_list_id, { only: ['title', 'id'] }, { join: true }).then(({ data }) => {
          this.restrictSubFormList = data;
        });
      }

      if (this.question.supports_mfql) {
        this.$api
          .getModuleNames({ filters: { name: this.moduleNameName }, only: ['feature_set'], per_page: 1 }, { cache: true })
          .then(({ data }) => {
            if (!!data.length) this.moduleName = data[0];
          });
      }

      if (!!Number(this.config.main_form_id)) {
        this.$api.getModuleName(Number(this.config.main_form_id), { only: ['id', 'name', 'display'] }, { join: true }).then(({ data }) => {
          this.relatedModuleName = data;
        });
      }

      if (!!this.config.add?.module_records?.length) {
        this.$api
          .getModuleNames({ filters: { id: this.config.add.module_records.join(','), active: true }, only: ['name'], per_page: -1 })
          .then(({ data }) => {
            this.moduleRecordsModuleNames = data;
          });
      }

      if (this.mainFormId) {
        this.fetchQuestions(this.mainFormId);
      }

      this.$api
        .getSubFormLists({ filters: { approval_sub_form_id: this.subFormId }, only: ['id', 'sub_form_ids'] }, { cache: true })
        .then(({ data }) => {
          if (data[0]?.sub_form_ids) {
            this.baseFormId = data[0].sub_form_ids[0];

            if (this.baseFormId && !this.showQuestion) {
              this.fetchQuestions(this.baseFormId);
            }
          }
        });
    }

    fetchQuestions(sourceId: number): void {
      if (!!this.config.show?.id) {
        this.$api
          .getSubFormQuestionsWithSharedFilters(
            {
              only: ['id', 'title', 'config', 'question', 'system_code'],
              filters: {
                active: true,
                sub_form_section: {
                  sub_form_id: uniq([this.subFormId, ...[sourceId]]),
                },
              },
            },
            'system_code',
            this.config.show.id,
            { cache: true }
          )
          .then(({ data }) => {
            this.showQuestion = data?.[0] || null;
          });
      } else {
        this.showQuestion = null;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canAddOptions(hash = (this.config.add as any) || {}, result: string[] = []) {
      Object.keys(hash).forEach((k) => {
        switch (hash[k]) {
          case 'true':
            if (k !== 'linked_main_form_records') {
              result.push(k);
            }
            break;
          case Object:
            this.canAddOptions(hash[k], result);
            break;
          default:
            break;
        }
      });

      return result;
    }

    showLookupModeOptions(): void {
      this.config.mode === 'lookup' && this.fetchLookupOptions();
      this.config.mode === 'api_lookup' && this.fetchApiLookupQuestion();
    }

    async fetchLookupOptions() {
      const code = this.lookup?.related_record_question_code;
      if (!code) return;

      const { data: lookupSourceOptions } = await this.$api.getSubFormQuestionLookupSourceOptions(
        this.question.id,
        { code, advanced: this.lookup?.advanced === 'true' },
        { cache: true }
      );
      this.lookupSourceOptions = lookupSourceOptions;
      this.lookupSourceOptionError = lookupSourceOptions.length
        ? null
        : this.$t('tenant.admin.sub_form_questions.advanced_options.lookup_source_option_error');
    }

    async fetchApiLookupQuestion() {
      const code = this.config.api_lookup?.api_request_question_code;
      if (!code) return;

      const { data: subFormQuestions } = await this.$api.getSubFormQuestionsWithSharedFilters(
        {
          filters: {
            sub_form_section: { sub_form_id: this.subFormId },
          },
          only: ['title', 'code'],
        },
        'code',
        code
      );
      this.apiLookupQuestion = subFormQuestions[0];
      this.lookupSourceOptionError = !!this.apiLookupQuestion
        ? null
        : this.$t('tenant.admin.sub_form_questions.advanced_options.api_lookup_request_question_error');
    }

    processDateCalculationConfig(questions: SubFormQuestion[]) {
      this.dateCalculationQuestions = questions;
      if (this.dateCalculation?.source_type === 'question' && questions.length === 2) {
        this.dateCalculationConfigError = null;
      } else if (this.dateCalculation?.source_type === 'custom' && questions.length == 1) {
        this.dateCalculationConfigError = null;
      } else {
        this.dateCalculationConfigError = this.$t('tenant.admin.sub_form_questions.advanced_options.date_calculation_option_error');
      }
      this.fetchingDateCalculationConfig = false;
    }

    async fetchDateCalculationConfig() {
      if (!this.dateCalculation) return;

      this.fetchingDateCalculationConfig = true;
      const { data: questions } = await this.$api.getSubFormQuestions(
        {
          filters: {
            sub_form_section: { sub_form_id: this.subFormId },
            system_code:
              this.dateCalculation.source_type === 'question'
                ? [this.dateCalculation.datetime_question_code, this.dateCalculation.days_question_code]
                : [this.dateCalculation.datetime_question_code],
          },
          only: ['id', 'code', 'system_code', 'title'],
        },
        { cache: true }
      );
      this.processDateCalculationConfig(questions);
    }
  }
