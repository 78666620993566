
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import FilterConfigurator from './filter-configurator.vue';
  import type { FilterOption } from './model';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { Location } from '@app/models/location';

  @Component({ components: { FilterConfigurator } })
  export default class LocationFilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(Object) readonly currentQuestion?: SubFormQuestion;
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(Boolean) readonly hasStickyFilters?: boolean;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noCurrentUser?: boolean;
    @Prop(Boolean) readonly isMobileApp?: boolean;

    get configOptions(): FilterOption<Location>[] {
      return [
        {
          type: 'location',
          label: 'Location',
          multiple: true,
          filters: { active: true },
          key: 'id',
        },
        {
          type: 'location',
          label: 'Parent Location',
          allowNull: true,
          filters: { active: true },
          multiple: true,
          key: 'location_id',
        },
        {
          type: 'location',
          label: this.$t('tenant.filter_configurator.ancestors_with_self'),
          filters: { active: true },
          multiple: true,
          key: 'ancestor_id',
        },
        {
          type: 'location',
          label: this.$t('tenant.filter_configurator.descendants_with_self'),
          filters: { active: true },
          multiple: true,
          key: 'descendant_id',
        },
        {
          type: 'text',
          label: 'Postal Code',
          key: 'postal_code',
        },
        {
          type: 'text',
          label: 'State',
          key: 'state',
        },
        {
          type: 'text',
          label: 'Suburb',
          key: 'suburb',
        },
        {
          type: 'text',
          label: 'Country',
          key: 'country',
        },
      ];
    }
  }
