
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { AxiosResponse } from 'axios';
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Blob } from '@rails/activestorage';
  import AttachmentsPanel from '@app/components/attachment/attachments-panel.vue';
  import AttachmentsUploader from '@app/components/attachment/attachments-uploader.vue';
  import Select2 from '@app/components/select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Blocking from '@app/mixins/blocking';
  import { omit } from 'lodash';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { SubForm } from '@app/models/sub-form';
  import { CustomScoring } from '@app/models/sub-form-options';
  import type { DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  import { ASF_ATTACHMENT_ONLY } from '../admin/sub-forms/utils';
  import type { AsfAttachmentOnly } from '../admin/sub-forms/utils';
  import CustomScoringPanel from '../scoring-custom/custom-scoring-panel.vue';
  import EntitySelector from '../entity-selector.vue';
  import AttachmentItem from '../attachment/attachment-item.vue';

  @Component({
    components: {
      Select2,
      AttachmentsUploader,
      AttachmentItem,
      DsModal,
      AttachmentsPanel,
      EntitySelector,
      CustomScoringPanel,
      Tooltip,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class SectionFormModal extends Blocking {
    @Model('input') readonly value!: boolean;
    @Prop(Number) readonly sectionId?: number;
    @Prop(Number) readonly triggerFromIndex?: number;
    @Prop(Number) readonly triggerFromGroupId?: Nullable<number>;
    @Prop(Object) readonly subForm!: Pick<SubForm, 'id' | 'module_name' | 'scoring'>;
    @Prop(Object) readonly attachmentsBySectionId!: Record<string, Pick<Attachment, AsfAttachmentOnly>[]>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<SubFormSection & { questionSetId?: number }> = {};
    section: Partial<SubFormSection> = {};
    blobs: Blob[] = [];
    loading = false;
    init = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get attachments(): Pick<Attachment, AsfAttachmentOnly>[] {
      return !!this.sectionId ? this.attachmentsBySectionId?.[this.sectionId] || [] : [];
    }

    get showForm() {
      return Object.keys(this.form).length > 0;
    }

    get modalTitle(): string {
      if (this.form.id) {
        return this.$t('app.labels.edit_section');
      } else {
        return this.$t('app.labels.new_section');
      }
    }

    get submitTitle(): string {
      if (this.form.id) {
        return this.$t('app.buttons.update');
      } else {
        return this.$t('app.labels.create');
      }
    }

    get disableDeleteButton(): boolean {
      return this.form.sub_form_questions?.some((q) => q.active) || false;
    }

    get showCustomScoringPanel(): boolean {
      return this.subForm?.scoring || false;
    }

    get questionSetOptions(): DonesafeIndexApiOptions<SubFormQuestionSet> {
      return {
        filters: {
          module_name: this.subForm?.module_name,
          active: true,
        },
        page: 1,
        per_page: 25,
        only: ['id', 'name'],
        sort: 'name',
      };
    }

    createBlob(blob: Blob): void {
      this.blobs = [...this.blobs, blob];
    }

    deleteBlob(blob: Blob): void {
      this.blobs = this.blobs.filter((b) => b.signed_id === blob.signed_id);
    }

    deleteAttachment(attachment: Attachment): void {
      if (confirm(this.$t('app.labels.delete_this_attachment') as string)) {
        this.$api.deleteAttachment(attachment.id).then(() => this.$emit('attachment-deleted', attachment));
      }
    }

    uploadAttachments(): Promise<AxiosResponse<Attachment>[]> {
      const promises = this.blobs.map((blob) => {
        return this.$api.createAttachment(
          {
            attachable_id: this.form.id,
            attachable_type: 'SubFormSection',
            file: blob.signed_id,
          },
          {
            only: ASF_ATTACHMENT_ONLY,
          }
        );
      });

      return Promise.all(promises);
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          const form = omit(this.form, ['index']);
          this.loading = true;
          const promise = !!form?.id ? this.$api.updateSubFormSection(form.id, { ...form }) : this.$api.createSubFormSection(form);
          promise
            .then(({ data }) => (this.form = { ...this.form, ...data }))
            .then(() => this.uploadAttachments())
            .then((data) => {
              if (!!data.length) {
                this.$emit(
                  'attachments-uploaded',
                  data.map(({ data }) => data)
                );
              }
            })
            .then(() => {
              if (!this.section?.id && this.form.questionSetId) {
                return this.$api
                  .updateSubFormSectionFromQuestionSet(this.form.id as number, this.form.questionSetId as number)
                  .then(({ data }) => (this.form = { ...data }));
              }
            })
            .then(() => {
              this.$emit('updated', {
                section: this.form,
                triggerFromIndex: this.triggerFromIndex,
                action: !!form?.id ? 'update' : 'create',
              });
              this.$emit('input', false);
            })
            .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
            .finally(() => (this.loading = false));
        }
      });
    }

    deleteSection(): void {
      if (!this.form.id) {
        return;
      }
      if (!confirm(this.$t('app.labels.delete_section_confirmation').toString())) {
        return;
      }

      this.loading = true;
      this.$api
        .deleteSubFormSection(this.form.id)
        .then(() => {
          this.$emit('updated', { section: this.form, action: 'delete' });
          this.$emit('input', false);
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => {
          this.loading = false;
        });
    }

    initForm(): void {
      this.form = {
        ...this.section,
        options: !this.section.options?.custom_scoring_type ? { custom_scoring_type: CustomScoring.none } : this.section.options,
        sub_form_section_group_id: this.triggerFromGroupId || undefined,
        sub_form_id: this.subForm.id,
      };
    }

    onQuestionSetUpdated(questionSetId?: number): void {
      if (!questionSetId) {
        return;
      }

      this.loading = true;
      this.$api
        .getQuestionSet(questionSetId, {
          only: ['options'],
        })
        .then(({ data }) => {
          this.form = {
            ...this.form,
            options: { custom_scoring_type: CustomScoring.none, ...data.options },
          };
        })
        .finally(() => {
          this.loading = false;
        });
    }

    allErrors(errors: { [x: string]: string[] }): string[] {
      return Object.values(errors)
        .filter((e) => e.length)
        .map((errors) => errors[0]);
    }

    beforeMount(): void {
      if (!!this.sectionId) {
        this.blocking(async () => {
          const { data } = await this.$api.getSubFormSection(this.sectionId as number, { include: ['sub_form_questions'] });
          this.section = data;
          this.initForm();
        });
      } else {
        this.initForm();
      }
    }
  }
