
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { cloneDeep } from 'lodash';
  import TextFieldOptions from './text-field-options.vue';
  import TextareaFieldOptions from './textarea-field-options.vue';
  import SingleSelectFieldOptions from './single-select-field-options.vue';
  import ScormFieldOptions from './scorm-field-options.vue';
  import MultiSelectFieldOptions from './multi-select-field-options.vue';
  import ButtonSelectFieldOptions from './button-select-field-options.vue';
  import KnowledgeBaseFieldOptions from './knowledge-base-field-options.vue';
  import VideoFieldOptions from './video-field-options.vue';
  import MultiCheckboxFieldOptions from './multi-checkbox-field-options.vue';
  import RadioFieldOptions from './radio-field-options.vue';
  import TableCalculationFieldOptions from './table-calculation-field-options.vue';

  type Question = object;

  // TODO deprecated
  @Component({
    components: {
      TextFieldOptions,
      TextareaFieldOptions,
      ButtonSelectFieldOptions,
      SingleSelectFieldOptions,
      MultiSelectFieldOptions,
      ScormFieldOptions,
      KnowledgeBaseFieldOptions,
      VideoFieldOptions,
      MultiCheckboxFieldOptions,
      RadioFieldOptions,
      TableCalculationFieldOptions,
    },
  })
  export default class QuestionState extends Vue {
    @Prop(String) component!: string;
    @Prop(Object) value!: Question;

    form: Partial<Question> = {};

    beforeMount() {
      this.form = cloneDeep(this.value);
    }

    onFormInput(updated: object) {
      this.form = cloneDeep(updated);
    }
  }
