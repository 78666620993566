
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { ModuleName } from '@app/models/module-name';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { BaseTable } })
  export default class ModuleIndicatorSetsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: string | number;
    @Ref() readonly table?: BaseTable<IndicatorSet>;

    manager: Nullable<ListManager<IndicatorSet>> = null;
    moduleName: Nullable<ModuleName> = null;

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateIndicatorSetIndexes({ data, sort })
          .then(({ data }) => {
            this.$api.cache.clear();
            toaster({
              text: this.$t('app.labels.order_saved'),
              position: 'top-right',
            });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    getManager(moduleName: ModuleName): ListManager<IndicatorSet> {
      return new ListManager<IndicatorSet>({
        fetchDataFunction: (params) => {
          return this.$api.getIndicatorSets({ ...params, filters: { module_name: moduleName.name } }, { cache: true });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index' },
          { title: 'ID', name: 'id', sortField: 'id' },
          { title: 'Title', name: 'name', sortField: 'name' },
          { title: 'Type', name: 'multi', sortField: 'multi' },
          { title: 'Visual Options', name: 'visual_options' },
          { title: 'Active', name: 'active', sortField: 'active' },
        ],
      });
    }

    mounted(): void {
      this.$api.getModuleName(Number(this.moduleNameId)).then(({ data }) => {
        this.moduleName = data;
        this.manager = this.getManager(this.moduleName);
      });
    }
  }
