
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { flatMap, isEmpty, isNull, sumBy, uniq, filter, groupBy } from 'lodash';
  import type { ReportBlob, ReportBlobGraph, SuperReportGraphData, SuperReportGraphItem } from '@app/models/super-report';

  @Component({})
  export default class SuperReportTargetsTab extends Vue {
    @Prop() readonly reportData!: SuperReportGraphData;
    @Prop() readonly reportBlob!: ReportBlob;
    @Prop() readonly superReportId!: string;

    getActualValue(key: string): string {
      return this.dataItems(this.graphDataValues.data, key);
    }

    dataItems(data: [string, string | number][], key: string): string {
      return (Object.fromEntries(data)[key] as string) || '0';
    }

    get fallbackValueItems() {
      const fallbackValues = flatMap(
        // TODO: might need to filter more than just Target
        filter(this.graphData || [], ({ name }) => name !== 'Target'),
        ({ data }) => data
      );
      const groupedItems = groupBy(fallbackValues, 0);
      const summedItems = Object.keys(groupedItems).map((key) => [key, sumBy(groupedItems[key], '1')]) as [string, string | number][];
      return summedItems;
    }

    get graphData(): SuperReportGraphItem[] | null {
      return this.reportData.graph_data ? this.reportData.graph_data : null;
    }

    get graphDataTargets(): SuperReportGraphItem | undefined {
      return this.graphData?.find((item) => item.name == 'Target');
    }

    get graphDataValues(): SuperReportGraphItem {
      const valueItem = this.graphData?.find((item) => item.name == 'Value');
      // create value item ourselves if it doesn't exist
      return valueItem || { name: 'Value', data: this.fallbackValueItems };
    }

    get columnKeys(): string[] {
      return uniq(
        this.reportData?.graph_data.reduce<string[]>((memo, dataItem) => {
          return memo.concat(dataItem.data.map((item) => item[0]));
        }, []) || []
      );
    }

    get graph(): ReportBlobGraph {
      return this.reportBlob.graph;
    }

    get blobTarget(): string[][] | null {
      return this.reportBlob ? this.reportBlob.graph.target : null;
    }

    get csvTargetsReportLink(): string {
      return `/reports/report_data.csv?super_report_id=${this.superReportId}`;
    }

    findDataTarget(key: string): string | number {
      if (this.graphDataTargets) {
        let targetValue = Object.fromEntries(this.graphDataTargets.data)[key];
        return targetValue;
      }
      return '';
    }

    findDataValue(key: string): string | number {
      if (this.graphDataValues) {
        let value = Object.fromEntries(this.graphDataValues.data)[key];
        return value;
      }
      return 0;
    }

    convertTargetValue(key: string): string | undefined {
      let targetValue = this.findDataTarget(key);
      if (targetValue && !isNull(targetValue)) {
        return targetValue.toString();
      } else if (!isNull(this.blobTarget) && this.blobTarget[0][0].toString() == 'default') {
        return '';
      }
    }

    targetPercentage(key: string): string {
      const dataValue = this.findDataValue(key).toString();
      const dataTarget = this.convertTargetValue(key);

      if (!dataTarget || isEmpty(dataTarget) || dataTarget == '0') {
        return '';
      } else {
        return dataValue ? ((parseFloat(dataValue) / parseFloat(dataTarget)) * 100).toFixed(2).toString() + '%' : '';
      }
    }
  }
