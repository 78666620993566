
  import { AccountService } from '@app/components/paper-trails/services';
  import { pick } from 'lodash';

  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import AccountActions from '@app/components/admin/accounts/account-actions.vue';
  import type { Account } from '@app/models/account';
  import type { Saml } from '@app/models/saml';
  import type { PartialNullBy } from '@app/utils/types/partial';

  const SECONDS_IN_DAY = 24 * 60 * 60;
  const DEFAULT_EXPIRE_PASSWORD_DAYS = 30;
  const ACCOUNT_ONLY = [
    'default_sso_page_enabled',
    'deny_old_passwords',
    'expire_password_after',
    'force_password_change',
    'id',
    'mfa',
    'password_archiving_count',
    'password_policy',
    'saml_as_default_login',
  ];

  @Component({
    components: {
      Select2,
      ValidationObserver,
      ValidationProvider,
      AccountActions,
    },
  })
  export default class SsoSettingForm extends Vue {
    @Prop(Object) account!: Account;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    // TODO: translations
    passwordComplexityOptions = [
      {
        description: 'Level 1 – No character type limits',
        level: '1',
      },
      {
        description: 'Level 2 - Password must have at least two character types',
        level: '2',
        note: this.$t('tenant.admin.settings.samls.account_saml_setting_form.password_complexity_note_level_2'),
      },
      {
        description: 'Level 3 - Password must have at least three character types',
        level: '3',
        note: this.$t('tenant.admin.settings.samls.account_saml_setting_form.password_complexity_note_level_3'),
      },
      {
        description: 'Level 4 - Password must use all four character types(lowercase, uppercase, number, special character)',
        level: '4',
        note: this.$t('tenant.admin.settings.samls.account_saml_setting_form.password_complexity_note_level_4'),
      },
    ];

    samls: Pick<Saml, 'id'>[] = [];
    form: Partial<PartialNullBy<Account, 'expire_password_after' | 'password_policy' | 'password_archiving_count'>> = {};
    expirePasswordAfterFocus = false;
    get auditTrailAttributeScope(): (keyof Account)[] {
      return AccountService.authenticationAndSSOAttributes;
    }

    get expirePasswordEnabled(): boolean {
      return this.expirePasswordAfter != null;
    }

    get denyOldPasswords(): boolean {
      return !!this.form.deny_old_passwords;
    }

    get passwordArchivingCount(): number | null {
      return this.denyOldPasswords ? (this.form.password_archiving_count || 0) + 1 : null;
    }

    get passwordComplexityNote(): string | undefined {
      return this.passwordComplexityOptions.find((c) => c.level === `${this.passwordComplexity}`)?.note;
    }

    get hasSamlConfigs(): boolean {
      return this.samls.length > 0;
    }

    get expirePasswordAfter(): Nullable<number> {
      const value = this.form.expire_password_after;
      return value ? value / SECONDS_IN_DAY : null;
    }

    get passwordLength(): number | null {
      return this.form.password_policy ? parseInt(this.form.password_policy.split('_')[1]) : null;
    }

    get passwordComplexity(): number | null {
      return this.form.password_policy ? parseInt(this.form.password_policy.split('_')[3]) : null;
    }

    set denyOldPasswords(value: boolean) {
      this.form = { ...this.form, deny_old_passwords: value, password_archiving_count: value ? 9 : null };
    }

    set expirePasswordEnabled(value: boolean) {
      this.expirePasswordAfter = value ? DEFAULT_EXPIRE_PASSWORD_DAYS : null;
    }

    set passwordArchivingCount(value: number | null) {
      this.form = { ...this.form, password_archiving_count: value != null ? value - 1 : null };
    }

    set passwordLength(value: number | null) {
      this.form = {
        ...this.form,
        password_policy: value ? `length_${value}_complexity_${this.passwordComplexity || 1}` : null,
      };
    }

    set passwordComplexity(value: number | null) {
      this.form = {
        ...this.form,
        password_policy: value != null ? `length_${this.passwordLength || 8}_complexity_${value}` : null,
      };
    }

    set expirePasswordAfter(value: Nullable<number>) {
      this.form = {
        ...this.form,
        expire_password_after: value ? value * SECONDS_IN_DAY : value,
      };
    }
    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    beforeMount(): void {
      this.$api.getSamls({ only: ['id'] }, { cache: true }).then(({ data }) => {
        this.samls = data;
      });
      this.form = { ...pick(this.account, ACCOUNT_ONLY) };
    }
  }
