
  import { isEmpty, uniq } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import BaseWidget from './base-widget';
  import type { ReportDataWidgetOptions } from '@app/models/widget';
  import type { SuperReport, SuperReportGraphData, SuperReportGraphItem } from '@app/models/super-report';

  @Component
  export default class ReportDataWidget extends BaseWidget<ReportDataWidgetOptions> {
    report: Nullable<SuperReport> = null;
    reportData: Nullable<SuperReportGraphData> = null;
    error = '';

    mounted(): void {
      this.fetchReport();
    }

    get columnKeys(): string[] {
      return uniq(
        this.reportData?.graph_data.reduce<string[]>((memo, dataItem) => {
          return memo.concat(dataItem.data.map((item) => item[0]));
        }, []) || []
      );
    }

    fetchReport(): void {
      this.$api
        .getSuperReportGraphData(this.reportId)
        .then(({ data }) => (this.reportData = data))
        .catch(({ data }) => (this.error = data.error));
    }

    dataItem(item: SuperReportGraphItem, key: string): string {
      return (Object.fromEntries(item.data.map(([k, v]) => [k, v]))[key] as string) || '0';
    }

    get hasRoleWithReport(): boolean {
      return !!this.currentUserStore.data?.role?.super_report_ids?.includes(this.reportId);
    }

    get reportId(): number {
      return Number(this.widget.options.super_report_id);
    }

    get reportLink(): string {
      return `/reports/super_report?super_report_id=${this.reportId}`;
    }

    get emptyData(): boolean {
      return !!this.reportData && isEmpty(this.reportData.graph_data[0].data);
    }
  }
