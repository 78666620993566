
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import LocationSelector from '../../../components/location/location-selector.vue';
  import FilterSelect from '../../../components/filter-select.vue';
  import Select2 from '../../../components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Location } from '@app/models/location';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import type { LocationTag } from '@app/models/location-tag';
  import type { UserLocationTag } from '@app/models/user-location-tag';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
  import type { FilterValue } from '@app/services/donesafe-api-utils';

  @Component({
    components: {
      FilterCheckbox,
      LocationSelector,
      BaseTable,
      FilterSelect,
      Select2,
      SearchInput,
      DsDropdown,
    },
  })
  export default class LocationTagReportsIndex extends Vue {
    @Ref() readonly table?: BaseTable<Location>;
    locationTags: LocationTag[] = [];
    userLocationTags: Pick<UserLocationTag, 'id' | 'location_id' | 'location_tag_id'>[] = [];
    userReportSubscription: boolean | undefined = false;
    manager: Nullable<ListManager<Location, DonesafeLocationsApiExtraOptions>> = null;

    loading = false;

    get fields(): ListManagerField<Location>[] {
      return [
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        ...this.locationTags.map((tag) => ({ title: tag.name, name: 'tag_count', tag: tag, evaluateClass: this.cellColor, filter: true })),
      ];
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    locationTagFilterValue(tagId: number): boolean {
      const ids = this.manager?.customFilters?.location_tag_counts?.location_tag_id || [];
      if (Array.isArray(ids)) {
        return ids.some((id) => id === tagId);
      } else {
        return ids === tagId;
      }
    }

    setLocationTagFilter(tagId: number, event: FilterValue) {
      const ids = this.manager?.customFilters?.location_tag_counts?.location_tag_id || [];
      const arrayIds = Array.isArray(ids) ? ids : [ids];

      if (event) {
        this.manager?.setFilter('location_tag_counts', { location_tag_id: [...arrayIds, tagId] });
      } else {
        const newIds = arrayIds.filter((id) => id !== tagId);
        this.manager?.setFilter('location_tag_counts', { location_tag_id: newIds });
      }
    }

    getManager(): ListManager<Location, DonesafeLocationsApiExtraOptions> {
      return new ListManager<Location, DonesafeLocationsApiExtraOptions>({
        fetchDataFunction: (params) => {
          return this.$api.getLocations(
            {
              ...params,
              only: ['active', 'id', 'name', 'state', 'location_tag_counts'],
              include: ['location_tag_counts'],
            },
            { cache: true } // for repetitive requests like: sorting or filtering
          );
        },
        afterFetch: async (data) => {
          const userLocationTags = await this.$api.getUserLocationTags(
            {
              active: true,
              only: ['id', 'location_id', 'location_tag_id'],
              per_page: -1,
              filters: { location_id: data.map((location) => location.id) },
            },
            { cache: true }
          );
          this.userLocationTags = userLocationTags.data;
        },
        filters: { active: true },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: this.fields,
        allowFilters: true,
        customFilters: {
          location_tag_counts: {
            location_tag_id: [],
          },
        },
      });
    }
    tagsCounter(locationId: number, tagId: number): number {
      return this.userLocationTags.reduce((count, el) => {
        return el.location_id === locationId && el.location_tag_id === tagId ? count + 1 : count;
      }, 0);
    }
    tagCounterExpected(location: Location, tagId: number): number | null {
      const tagsCount = location.location_tag_counts?.find((tag) => {
        return tag.location_tag_id === tagId;
      });
      return tagsCount ? tagsCount.count : null;
    }
    cellColor(field: ListManagerField<Location> & { tag?: LocationTag }, location: Location): 'active' | 'success' | 'danger' {
      const locationId = location.id;
      const tagId = field.tag?.id;

      if (tagId) {
        const tagExpected = this.tagCounterExpected(location, tagId);
        const tagCount = this.tagsCounter(locationId, tagId);

        if (tagExpected === null) {
          return 'active';
        } else if (tagCount < tagExpected) {
          return 'danger';
        }
      }
      return 'success';
    }

    cellText(location: Location, tagId: number): string {
      const expected = this.tagCounterExpected(location, tagId);
      return `${this.tagsCounter(location.id, tagId)} / ${expected === null ? this.$t('app.labels.na') : expected}`;
    }
    beforeMount(): void {
      this.$api
        .getLocationTags(
          { sort: 'name', only: ['id', 'name'], per_page: -1, filters: { tag_type: 'safety', active: true } },
          { cache: true }
        )
        .then(({ data }) => {
          this.locationTags = data;
          this.userReportSubscription = this.currentUserStore.isSubscribedToLocationTagReports;
          this.manager = this.getManager();
        });
    }

    async reportSubscription() {
      this.loading = true;
      try {
        if (this.userReportSubscription) {
          const { data } = await this.$api.reportUnsubscribe();
          this.userReportSubscription = data.subscribed;
        } else {
          const { data } = await this.$api.reportSubscribe();
          this.userReportSubscription = data.subscribed;
        }
      } finally {
        this.loading = false;
      }
    }
  }
