import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export const ASF_ATTACHMENT_ONLY = Tuple([
  'id',
  'url',
  'metadata',
  'attachable_id',
  'file_name',
  'created_at',
  'is_image',
  'is_variable',
  'extension',
  'web_thumbnail_url',
  { user: ['full_name'] },
] as const);
export type AsfAttachmentOnly = FirstLevelKeyLiterals<(typeof ASF_ATTACHMENT_ONLY)[number]>;

export const ASF_SFS_ONLY = Tuple([
  'id',
  'title',
  'description',
  'index',
  'sub_form_section_group_id',
  { attachments: ASF_ATTACHMENT_ONLY },
] as const);
export type AsfSfsOnly = FirstLevelKeyLiterals<(typeof ASF_SFS_ONLY)[number]>;

export const ASF_SFSG_ONLY = Tuple(['id', 'title', 'sub_form_section_ids', 'index'] as const);
export type AsfSfsgOnly = (typeof ASF_SFSG_ONLY)[number];

export type DraggableChangeEvent<T> = {
  added?: {
    element: T;
    newIndex: number;
  };
  moved?: {
    element: T;
    newIndex: number;
    oldIndex: number;
  };
  removed?: {
    element: T;
    oldIndex: number;
  };
};

export const intermediateIndex = (ind: number) => {
  return ind + 0.5 * (1 - (ind % 1));
};

export type SubFormDndList = { children?: { id: number }[]; id: number }[];
