
  import type { Activity } from '@app/models/activity';
  import { Component, Prop } from 'vue-property-decorator';
  import BasePrint from './base-print';
  import type { PrintActivitiesOnly } from './utils';

  @Component({ components: {} })
  export default class PrintActivities extends BasePrint {
    @Prop(Array) readonly activities!: Pick<Activity, PrintActivitiesOnly>[];
    @Prop(Boolean) readonly actionDetails?: boolean;

    activityTitle(activity: Pick<Activity, PrintActivitiesOnly>) {
      if (!activity.assignee) return activity.description;

      return `${activity.description} - ${activity.assignee.full_name}`;
    }

    activityState(activity: Pick<Activity, PrintActivitiesOnly>) {
      return this.$t('tenant.module_records.print.activity_state_date', {
        state: activity.state.toUpperCase(),
        date: activity.date,
      });
    }
  }
