
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import type { AxiosPromise } from 'axios';
  import { BaseTable } from '@app/components/base-table';
  import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Notification } from '@app/models/notification';
  import type { Dictionary } from '@app/models/dictionary';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { Tuple } from '@app/utils/types/tuple';
  import { ListManager } from '@app/services/list-manager/list-manager';

  interface ExtraAdminUserNotificationsFilters extends ExtraUserApiOptions {
    '!notification_ids'?: number | string;
    notification_ids?: number | string;
    subscribed?: undefined; // remove this
  }

  const USER_ONLY = Tuple(['id', 'full_name', 'type', 'email', 'notification_ids'] as const);

  @Component({
    components: { Select2, FilterSelect, BaseTable, Tooltip, SearchInput },
  })
  export default class AdminNotificationUsersTable extends Vue {
    @Prop(Number) readonly notificationId!: number;

    @Ref() readonly table?: BaseTable<TenantUser>;
    users: TenantUser[] = [];
    notification: Notification | null = null;
    userLoadingMap: Record<number, boolean> = {};
    manager: Nullable<ListManager<TenantUser, ExtraAdminUserNotificationsFilters>> = null;

    disableUpdate = false;

    @Watch('notification')
    onNotificationChanged(): void {
      this.onSubscribedFilterChanged(this.manager?.customFilters?.subscribed);
    }

    @Watch('manager.customFilters.subscribed', { immediate: true })
    onSubscribedFilterChanged(value: StringBoolean | undefined): void {
      if (this.manager) {
        const notificationsFilterBase: ExtraAdminUserNotificationsFilters = {
          notification_ids: undefined,
          '!notification_ids': undefined,
          subscribed: undefined,
        };
        if (value === 'true') {
          notificationsFilterBase.notification_ids = this.notificationId;
        } else if (value === 'false') {
          notificationsFilterBase['!notification_ids'] = this.notificationId;
        }
        this.manager.filters = { ...this.manager.filters, ...notificationsFilterBase };
      }
    }

    bulkUpdateAll(addAll: boolean): void {
      if (this.notification && !this.disableUpdate) {
        const promise = addAll
          ? this.$api.addUsersToNotification(this.notification.id, 'all')
          : this.$api.removeUsersFromNotification(this.notification.id, 'all');
        this.disableUpdate = true;
        promise
          .then(() => {
            this.$api.cache.clear();
            this.table?.reload();
          })
          .finally(() => {
            this.disableUpdate = false;
          });
      }
    }

    getManager(): ListManager<TenantUser, ExtraAdminUserNotificationsFilters> {
      return new ListManager<TenantUser, ExtraAdminUserNotificationsFilters>({
        fetchDataFunction: (params) => this.$api.getTenantUsers({ ...params, only: USER_ONLY }, { cache: true }),
        useHistory: true,
        customFilters: {
          search: '',
          type: [],
        },
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        fields: [
          {
            title: this.$t('app.labels.subscribed'),
            dataClass: 'text-center',
            width: 'max-content',
            name: 'notification_status',
            filter: true,
          },
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: 'max-content' },
          { title: this.$t('app.labels.name'), name: 'full_name', sortField: 'full_name' },
          { title: this.$t('app.labels.Email'), name: 'email', sortField: 'email' },
          { title: this.$t('app.labels.type'), name: 'type', sortField: 'type', width: '300px', filter: true },
        ],
        allowFilters: true,
      });
    }

    userClass(user: TenantUser): string {
      if (this.userLoadingMap[user.id]) {
        return 'fa-spin fa-spinner';
      } else if (this.notificationEnabled(user)) {
        return 'fa-toggle-on icon-on';
      } else {
        return 'fa-toggle-off icon-off';
      }
    }

    toggleNotification(user: TenantUser): void {
      if (this.notification && !this.userLoadingMap[user.id] && !this.disableUpdate) {
        this.setLoading(user, true);

        this.toggleNotificationRequest(this.notification, user)
          .then(({ data }) => {
            this.table?.setData(this.manager?.items.map((u) => (user.id === u.id ? data : u)) || []);
            this.$api.cache.clear();
          })
          .finally(() => this.setLoading(user, false));
      }
    }

    setLoading(user: TenantUser, state: boolean): void {
      this.userLoadingMap = {
        ...this.userLoadingMap,
        [user.id]: state,
      };
    }

    tooltipText(user: TenantUser): string {
      return this.userLoadingMap[user.id]
        ? this.$t('app.labels.updating')
        : this.notificationEnabled(user)
        ? this.$t('app.labels.disable')
        : this.$t('app.labels.enable');
    }

    toggleNotificationRequest(notification: Notification, user: TenantUser): AxiosPromise<TenantUser> {
      const notificationIds = this.notificationEnabled(user)
        ? user.notification_ids?.filter((id) => id !== notification.id) || []
        : [...(user.notification_ids || []), notification.id];
      return this.$api.updateTenantUser(user.id, { notification_ids: notificationIds }, { only: USER_ONLY });
    }

    notificationEnabled(user: TenantUser): boolean {
      return !!this.notification && !!user.notification_ids?.includes(this.notification.id);
    }

    get typesMap(): Dictionary<string> {
      return {
        TenantUser: this.$t('app.labels.tenant'),
        ContactUser: this.$t('app.labels.contact'),
        VisitorUser: this.$t('app.labels.visitor'),
        ContractorUser: this.$t('app.labels.contractor'),
        MedicalUser: this.$t('app.labels.medical_user'),
      };
    }

    get subscribedOptions(): [string, string][] {
      return [
        ['true', this.$t('app.labels.subscribed')],
        ['false', this.$t('app.labels.not_subscribed')],
      ];
    }

    get typeOptions(): [string, string][] {
      return Object.entries(this.typesMap);
    }

    beforeMount(): void {
      this.$api.getNotification(this.notificationId).then(({ data }) => {
        this.notification = data;
        this.manager = this.getManager();
        this.onSubscribedFilterChanged(this.manager?.customFilters?.subscribed);
      });
    }
  }
