
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class NumberElement extends Vue {
    @Prop({ required: true }) readonly id!: number | string;
    @Prop({ required: true }) readonly value!: string;
    @Prop({ required: true }) readonly updateElementValue!: (logicElementId: string | number, newValue: string) => void;
    @Prop({ required: true }) readonly backgroundColor!: string;

    localValue = '';
    editing = false;

    cancelEditing(): void {
      this.editing = false;
      this.localValue = this.value;
    }

    editValue(): void {
      this.updateElementValue(this.id, this.localValue);
      this.editing = false;
    }

    mounted(): void {
      this.localValue = this.value;
    }
  }
