
  import { Component, Prop } from 'vue-property-decorator';
  import type TabRoute from '@app/utils/types/tab-route';
  import UserSettingsPage from '@app/components/admin/users/user-settings-page.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import UserImage from '@app/components/user-image.vue';
  import { UserType } from '@app/models/tenant-user';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';

  @Component({
    components: { UserSettingsPage, UserImage },
  })
  export default class EditUserPage extends mixins(WithUser) {
    @Prop(String) readonly tabName?: String;

    user: Nullable<TenantUser> = null;
    tabs: TabRoute[] = [
      { key: 'details', name: this.$t('app.labels.details'), icon: 'fa-list-ul', route: { name: 'admin-user-details-page' } },
      { key: 'tags', name: this.$t('app.labels.tags'), icon: 'fa-tag', route: { name: 'admin-user-tags-page' } },
      { key: 'locations', name: this.$t('app.labels.locations'), icon: 'fa-building-o', route: { name: 'admin-user-active-locations' } },
      {
        key: 'manager_for',
        name: this.$t('tenant.users.sections.sections.tabs.manager_for'),
        icon: 'fa-users',
        route: { name: 'admin-user-manager-for' },
      },
      {
        key: 'acknowledgments',
        name: this.$t('app.labels.acknowledgments'),
        icon: 'fa-lightbulb-o',
        route: { name: 'admin-user-acknowledgments-page' },
      },
      {
        key: 'notifications',
        name: this.$t('tenant.users.sections.sections.tabs.notifications'),
        icon: 'fa-envelope-square',
        route: { name: 'admin-user-notifications-tab' },
      },
      { key: 'timesheets', name: this.$t('app.labels.timesheets'), icon: 'fa-calendar', route: { name: 'admin-user-timesheets-page' } },
      {
        key: 'organizations',
        name: this.$t('app.labels.organizations'),
        icon: 'fa-building',
        route: { name: 'admin-user-active-organizations' },
      },
      {
        key: 'personal_details',
        name: this.$t('app.labels.personal_details'),
        icon: 'fa-user',
        route: { name: 'admin-user-personal-details-tab' },
      },
      { key: 'pay_details', name: this.$t('app.labels.pay_details'), icon: 'fa-money', route: { name: 'admin-user-pay-details-tab' } },
      { key: 'documents', name: this.$t('app.labels.documents'), icon: 'fa-archive', route: { name: 'admin-user-documents-table' } },
      {
        key: 'admin_permissions',
        name: this.$t('tenant.users.tabs.admin_permissions'),
        icon: 'fa-user-circle',
        route: { name: 'admin-user-admin-permissions-page' },
      },
    ];

    get userTabs() {
      return this.tabs.filter((tab) => {
        if (tab.key === 'admin_permissions') {
          return this.currentUserStore.featureEnabled(AdminPermissionFeature.system_administration);
        } else if (tab.key == 'pay_details' || tab.key == 'timesheets') {
          return this.accountStore.data.show_pay_details_tab;
        }
        return true;
      });
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    beforeMount() {
      this.$api
        .getTenantUser(this.userId, { only: ['avatar_url', 'full_name', 'first_name', 'last_name'] }, { cache: true })
        .then((response) => {
          this.user = response.data;
        });
    }
  }
