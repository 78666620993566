
  import Blocking from '@app/mixins/blocking';
  import { ValidationObserver } from 'vee-validate';

  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import DsTextInput from '../ds-text-input.vue';
  import EntitySelector from '../entity-selector.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import type { AsfSfsgOnly } from '../admin/sub-forms/utils';
  import { ASF_SFSG_ONLY } from '../admin/sub-forms/utils';
  import { omit } from 'lodash';
  import { API_NULL } from '@app/constants';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ValidationObserver, DsTextInput, EntitySelector, DsModal } })
  export default class SectionGroupFormModal extends Blocking {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Number) readonly sectionGroupId?: number;
    @Prop(Object) readonly subForm!: Pick<SubForm, 'id' | 'module_name' | 'scoring'>;
    @Prop(Number) readonly triggerFromIndex?: number;
    @Prop(Array) readonly subFormSectionGroups?: Pick<SubFormSectionGroup, AsfSfsgOnly>[];

    form: Partial<SubFormSectionGroup> = {};

    get existingTitles(): string[] {
      return (this.subFormSectionGroups || [])?.filter((g) => g.id != this.sectionGroupId)?.map((group) => group.title);
    }

    get showForm(): boolean {
      return Object.keys(this.form).length > 0 && !this.blocked;
    }

    get sectionInGroupsFilters() {
      return {
        sub_form_id: this.subForm?.id,
        sub_form_section_group_id: [this.sectionGroupId, API_NULL].filter(Boolean).join(','),
      };
    }

    get submitTitle(): string {
      if (this.sectionGroupId) {
        return this.$t('app.buttons.update');
      } else {
        return this.$t('app.labels.create');
      }
    }

    get modalTitle(): string {
      if (this.sectionGroupId) {
        return this.$t('tenant.admin.sub_form_section_groups.modal.edit_title');
      } else {
        return this.$t('tenant.admin.sub_form_section_groups.modal.new_title');
      }
    }

    onSectionIdsChange(sectionIds: string[]) {
      // ensure that the sub_form_section_ids are numbers
      this.form.sub_form_section_ids = sectionIds.map(Number);
    }

    prettyError(errors: string[]): string {
      const error = errors[0];
      return error.endsWith('is not a valid value') ? this.$t('tenant.admin.sub_form_section_groups.modal.value_taken') : error;
    }

    submitForm(): void {
      // TODO;
      // If a user removes a section from the ‘Sections in Group’ selector, on save, those sections should appear immediately below the Group – but not in it.
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          const form = omit(this.form, ['index']);
          const promise = form.id
            ? this.$api.updateSubFormSectionGroup(form.id, {
                ...form,
              })
            : this.$api.createSubFormSectionGroup(form);

          promise
            .then(({ data }) => {
              this.form = { ...this.form, ...data };
              this.$emit('updated', { group: this.form, triggerFromIndex: this.triggerFromIndex, action: form.id ? 'update' : 'create' });
              this.$emit('input', false);
            })
            .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
        }
      });
    }

    deleteSectionGroup(): void {
      this.sectionGroupId &&
        this.$api
          .deleteSubFormSectionGroup(this.sectionGroupId)
          .then(() => {
            this.$emit('updated', { group: this.form, action: 'delete' });
            this.$emit('input', false);
          })
          .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    beforeMount(): void {
      if (!!this.sectionGroupId) {
        this.blocking(async () => {
          const { data } = await this.$api.getSubFormSectionGroup(this.sectionGroupId as number, {
            only: ASF_SFSG_ONLY,
          });
          this.form = data;
        });
      } else {
        this.form = {
          title: '',
          sub_form_section_ids: [],
          sub_form_id: this.subForm?.id,
        };
      }
    }
  }
