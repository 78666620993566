
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Account } from '@app/models/account';

  interface SmsSettings {
    smsNotifications: boolean;
    smsNotificationsLegacy: boolean;
    twilioApiKeySecret: string;
    twilioApiKeySid: string;
    twilioId: string;
    twilioSendingNumber: string;
    twilioToken: string;
    useClientTwilio: boolean;
  }

  @Component({ components: {} })
  export default class SmsSettingsPanel extends Vue {
    @Prop(Object) readonly account!: Account;

    smsSettings: Nullable<SmsSettings> = null;
    tokenCleared = false;
    useApiKey = false;

    beforeMount(): void {
      const {
        sms_notifications: smsNotifications,
        sms_notifications_legacy: smsNotificationsLegacy,
        twilio_account_settings: {
          id: twilioId,
          use_client_twilio: useClientTwilio = false,
          sending_number: twilioSendingNumber,
          token: twilioToken,
          api_key_sid: twilioApiKeySid,
          api_key_secret: twilioApiKeySecret,
          use_api_key: useApiKey,
        },
      } = this.account;

      this.useApiKey = '' + useApiKey === 'true';

      this.smsSettings = {
        smsNotifications,
        smsNotificationsLegacy,
        useClientTwilio,
        twilioId,
        twilioSendingNumber,
        twilioToken,
        twilioApiKeySid,
        twilioApiKeySecret,
      };
    }

    clearToken(): void {
      this.smsSettings!.twilioToken = '';
      this.smsSettings!.twilioApiKeySid = '';
      this.smsSettings!.twilioApiKeySecret = '';
      this.tokenCleared = true;
    }

    get showTwilioSettings(): boolean {
      return this.smsSettings?.useClientTwilio.toString() === 'true';
    }

    get tokenPresent(): boolean {
      return !this.tokenCleared && this.account.twilio_account_settings.id?.length > 0;
    }

    get tokenRequired(): boolean {
      return !!this.account.twilio_account_settings.id && !this.tokenPresent;
    }

    get tokenPlaceholder(): string {
      if (this.tokenPresent) {
        return this.$t('app.labels.twilio_hidden_token_placeholder');
      } else {
        return this.$t('app.labels.twilio_enter_token_placeholder');
      }
    }
  }
