
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import { omit, max, isEmpty } from 'lodash';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Event, EventFormFieldPair, EventOptionsDictionary } from '@app/models/event';
  import CreateChainedEventForm from '@app/components/admin/automation-definitions/create-chained-event-form.vue';
  import type { RequiredBy } from '@app/utils/types/required-by';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { ValidationProvider } from 'vee-validate';

  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface ApiRequestDictionary {
    [index: number]: { key: string; value: string };
  }

  interface ApiRequestFormData {
    authentication_type: string;
    chained_events: Event[];
    data?: ApiRequestDictionary;
    data_editor: boolean;
    enable_retries: boolean;
    file: ApiRequestDictionary;
    format_type?: string;
    header: ApiRequestDictionary;
    jwt?: {
      payload: Dictionary<string>;
      token_endpoint_url: string;
    };
    mapping: string;
    notification_email: string;
    proxy?: {
      enabled: boolean;
      uri?: string;
    };
    query?: ApiRequestDictionary;
    raw_data: string;
    request_type?: string;
    retries: number;
    url?: string;
  }

  @Component({ components: { CreateChainedEventForm, Select2, AdvancedAutosuggest, FormField, ValidationProvider } })
  export default class ApiRequestEventForm extends BaseEventForm<ApiRequestFormData> {
    @Prop(Boolean) readonly enableRetries!: boolean;
    @Prop(Number) readonly retries!: number;
    @Prop(String) readonly notificationEmail!: string;
    @Prop(Object) readonly injectableArguments?: RequiredBy<InjectableArguments, 'concept_name'>;
    @Prop({ type: Boolean, default: false }) readonly allowChained?: boolean;

    createObjectLoading = true;

    get requestTypeOptions(): [string, string][] {
      return [
        ['POST', this.$t('app.labels.request_type_options.post')],
        ['PUT', this.$t('app.labels.request_type_options.put')],
        ['GET', this.$t('app.labels.request_type_options.get')],
        ['PATCH', this.$t('app.labels.request_type_options.patch')],
        ['DELETE', this.$t('app.labels.request_type_options.delete')],
      ];
    }

    get formatTypeOptions(): [string, string][] {
      return [
        ['application/json', this.$t('app.labels.format_types.json')],
        ['multipart/form-data', this.$t('app.labels.format_types.form_data')],
      ];
    }

    get isJsonRequest(): boolean {
      return this.form.format_type === 'application/json';
    }

    @Watch('form', { deep: true })
    onApiRequestFormChanged(val: ApiRequestFormData): void {
      if (this.syncedEvent) {
        this.syncedEvent = {
          ...this.syncedEvent,
          chained_events: [this.readChainedEvent(this.syncedEvent) as Event],
          enable_retries: val.enable_retries,
          retries: val.retries,
          notification_email: val.enable_retries ? val.notification_email : '',
          mapping: 'inline',
          options: {
            ...this.syncedEvent.options,
            ...omit(val, 'retries', 'notification_email', 'enable_retries', 'data_editor'),
            header: this.cleanValues(val.header),
            query: this.cleanValues(val.query || {}),
            data: this.cleanValues(val.data_editor ? [] : val.data || {}),
            file: this.cleanValues(val.file),
            raw_data: val.data_editor && val.raw_data ? val.raw_data : '',
          },
        };
      }
    }

    beforeMount(): void {
      this.form = {
        request_type: 'GET',
        format_type: 'application/json',
        jwt: { payload: {}, token_endpoint_url: '' },
        header: {},
        query: { 0: { key: '', value: '' } },
        data: { 0: { key: '', value: '' } },
        raw_data: '',
        proxy: { enabled: false, uri: '' },
        authentication_type: 'basic',
        enable_retries: this.enableRetries,
        retries: this.retries,
        notification_email: this.notificationEmail,
        data_editor: !isEmpty(this.values?.raw_data),
        chained_events: [this.readChainedEvent(this.syncedEvent) as Event],
        ...this.values,
      };
    }

    cleanValues(value: EventOptionsDictionary): EventFormFieldPair[] {
      return Object.values(value || []).filter((x) => {
        return x['key'] && x['value'];
      });
    }

    removeKeyValue(key: string, index: number): Partial<ApiRequestFormData> {
      this.form = {
        ...this.form,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key]: omit((this.form as any)[key], index),
      };
      return this.form;
    }

    addKeyValue(key: string): Partial<ApiRequestFormData> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const next = this.findLastIndex((this.form as any)[key]) + 1;
      this.form = {
        ...this.form,
        [key]: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(this.form as any)[key],
          [next]: { key: '', value: '' },
        },
      };
      return this.form;
    }

    findLastIndex(data: ApiRequestDictionary | undefined): number {
      return max(Object.keys(data || {}).map((key) => parseInt(key))) || 0;
    }

    readChainedEvent(event?: Event): Partial<Event> {
      if (event?.chained_events?.[0]) {
        return omit(event.chained_events[0], 'id');
      } else {
        return {
          per: '',
          options: {
            values: {},
          },
        };
      }
    }
  }
