import 'imports-loader?define=>false&module=>false!froala-editor';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/align.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/code_beautifier.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/code_view.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/colors.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/draggable.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/edit_in_popup.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/emoticons.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/entities.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/font_family.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/font_size.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/forms.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/fullscreen.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/image.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/inline_class.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/inline_style.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/line_breaker.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/line_height.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/link.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/lists.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/paragraph_format.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/paragraph_style.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/print.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/quote.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/special_characters.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/table.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/url.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/video.min';
import 'imports-loader?define=>false&module=>false!froala-editor/js/plugins/word_paste.min';

import './styles/froala-editor.scss';
