
  import { Component } from 'vue-property-decorator';
  import { SORTABLE_TYPES } from '@app/services/model-helpers';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormIndexWidgetOptions } from '@app/models/widget';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ListManagerSortItem } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import JoinEntities from '@app/components/join-entities.vue';

  import { BaseTable } from '../base-table';

  import BaseWidget from './base-widget';

  @Component({ components: { BaseTable, JoinEntities } })
  export default class SubFormIndexWidget extends BaseWidget<SubFormIndexWidgetOptions> {
    manager: Nullable<ListManager<SubFormCompletion>> = null;
    question: Nullable<SubFormQuestion> = null;
    subForm: Nullable<SubForm> = null;

    get sortField(): string {
      return this.widget.options.sort || 'created_at';
    }

    get sortOrder(): ListManagerSortItem {
      return {
        direction: this.widget.options?.reverse === 'true' ? 'desc' : 'asc',
        field: this.sortField,
        sortField: this.sortField,
      };
    }

    get joinedRecordParams() {
      return { only: ['id', 'title'] };
    }

    responseByQuestionCode(completion: SubFormCompletion): string {
      return completion.sub_form_responses?.find((r) => r.sub_form_question_code === this.sortField)?.print_value || '';
    }

    getCompletionLink(completion: SubFormCompletion): string {
      return `/sub_form_completions/${completion.id}`;
    }

    getRecordLink(recordId: number): string {
      return `/module_records/${recordId}`;
    }

    getManager(): ListManager<SubFormCompletion> {
      return new ListManager<SubFormCompletion>({
        fetchDataFunction: (params) => {
          return this.$api.getSubFormCompletions(
            {
              ...params,
              filters: {
                sub_form_list_id: this.widget.options.sub_form_list_id,
                sub_form_id: this.widget.options.sub_form_id,
              },
              only: [
                'id',
                'record_id',
                'title',
                'created_at',
                {
                  sub_form_responses: ['id', 'sub_form_question_code', 'print_value', 'response', 'sub_form_question_id'],
                },
              ],
            },
            { cache: true }
          );
        },
        per_page: 25,
        sortOrder: [this.sortOrder],
        fields: [
          { title: this.$t('app.labels.title'), name: 'title' },
          { title: this.$t('app.labels.record_title'), name: 'record' },
          { title: '', name: 'sort' },
        ],
      });
    }

    beforeMount(): void {
      this.$api
        .getSubForm(Number(this.widget.options.sub_form_id ?? this.widget.options.sub_form_list_id), { cache: true })
        .then(({ data }) => {
          this.subForm = data;
          this.manager = this.getManager();
        });

      if (this.widget.options.sort) {
        this.$api
          .getSubFormQuestions(
            {
              filters: {
                sub_form_section: {
                  sub_form_id: this.widget.options.sub_form_id,
                },
                code: this.widget.options.sort,
                field_type: SORTABLE_TYPES,
              },
              only: ['title', 'code'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.question = data[0];
          });
      }
    }
  }
