import { BehaviorSubject } from 'rxjs';

import type { ClampedType } from './clamped-state';

export class ExpandedItems {
  readonly expandedItems$ = new BehaviorSubject<boolean>(false);

  private expandedValues = new Set<ClampedType>();

  get isExpanded(): boolean {
    return !!this.expandedValues.size;
  }

  setExpandedValues(value: ClampedType) {
    this.expandedValues.add(value);
    this.expandedItems$.next(true);
  }

  removeExpandedValues(value: ClampedType) {
    this.expandedValues.delete(value);
    this.expandedItems$.next(this.isExpanded);
  }
}
