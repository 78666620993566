
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import ButtonSelect from '@app/components/button-select.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import bootbox from 'bootbox';
  import { Tooltip } from 'uiv';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { sortBy } from 'lodash';
  import type { ModuleName, TranslatedType } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { isLegacyModule, ModuleType, moduleTypeOptions, UNCATEGORISED } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { changeLocation } from '@app/utils/change-location';

  import ModuleRenameFormModal from '../modules/module-rename-form-modal.vue';

  import KbNotificationUsersField from './kb-notification-users-field.vue';

  @Component({
    components: {
      Select2,
      EntitySelector,
      ButtonSelect,
      ValidationObserver,
      ValidationProvider,
      Tooltip,
      ModuleRenameFormModal,
      KbNotificationUsersField,
    },
  })
  export default class ModuleNameForm extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly moduleName!: Partial<ModuleName>;
    @Prop(Boolean) readonly submitting!: boolean;

    submittingNotifications: boolean = false;

    form: Partial<ModuleName> = {};
    showOrganizationOptions = false;
    showLocationOptions = false;
    showSchemeOptions = false;
    openModal = false;
    hierarchyTypeRequested = false;
    solutionTypeRequested = false;
    hierarchyTypeOptions: TranslatedType[] = [];
    solutionTypeOptions: TranslatedType[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get isSubmitting(): boolean {
      return this.submitting || this.submittingNotifications;
    }

    get accountStore() {
      return useAccountStore();
    }

    get yesNoOptions(): [string, string][] {
      return [
        ['true', this.$t('app.labels.yes')],
        ['false', this.$t('app.labels.no')],
      ];
    }

    get isProcedureModule(): boolean {
      return this.moduleName.name == 'Procedure';
    }

    get publishedName(): string {
      return `${this.moduleName.published_module_version?.internal_name} (${this.moduleName.published_module_version?.identifier})`;
    }

    get isDefaultTechAdmin(): boolean {
      return this.currentUserStore.isDefaultTechAdmin;
    }

    get isAdmin(): boolean {
      return this.currentUserStore.isTechAdmin;
    }

    get isInternal(): boolean {
      return this.currentUserStore.isInternal;
    }

    get hierarchyTypeDescription(): string {
      return this.hierarchyTypeOptions.find((option) => option.system_code === this.form.hierarchy_type)?.description || '';
    }

    get solutionTypeDescription(): string {
      return this.solutionTypeOptions.find((option) => option.system_code === this.form.solution_type)?.description || '';
    }

    get instructions(): string {
      if (this.moduleName.id) {
        if (this.form.module_type === ModuleType.standard) {
          return this.$t('tenant.admin.module_names.form.edit_instructions_standard');
        } else if (this.form.module_type === ModuleType.form_managed) {
          return this.$t('tenant.admin.module_names.form.edit_instructions_form_managed', { output_code: this.form.hybrid_question_code });
        } else if (this.form.module_type === ModuleType.integrated) {
          return this.$t('tenant.admin.module_names.form.edit_instructions_integrated');
        } else {
          return '';
        }
      } else {
        if (this.form.module_type === ModuleType.standard) {
          return this.$t('tenant.admin.module_names.form.create_instructions_standard');
        } else if (this.form.module_type === ModuleType.form_managed) {
          return this.$t('tenant.admin.module_names.form.create_instructions_form_managed');
        } else if (this.form.module_type === ModuleType.integrated) {
          return this.$t('tenant.admin.module_names.form.create_instructions_integrated');
        } else {
          return '';
        }
      }
    }

    get schemeFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return { active: true, field_type: FieldType.scheme, sub_form_section: { sub_form_id: this.moduleName.sub_form_id } };
    }

    get locationFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return { active: true, field_type: FieldType.location, sub_form_section: { sub_form_id: this.moduleName.sub_form_id } };
    }

    get organizationFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return { active: true, field_type: FieldType.organization, sub_form_section: { sub_form_id: this.moduleName.sub_form_id } };
    }

    get isCustomModule(): boolean {
      return !this.moduleName.id || !isLegacyModule(this.moduleName);
    }

    get offlineAppUsage(): boolean {
      return this.accountStore.data.enable_offline_app_usage;
    }

    get isIntegrated(): boolean {
      return ModuleType.integrated === this.form.module_type;
    }

    get showIntegratedOptions(): boolean {
      return !!this.form.module_type && this.form.module_type === ModuleType.integrated && this.isAdmin;
    }

    get isHybridSelected(): boolean {
      return this.form.module_type === ModuleType.form_managed;
    }

    get isNewRecord(): boolean {
      return !this.moduleName.id;
    }

    get locationLimit(): boolean {
      return this.accountStore.limitPermissionsByExplicitLocations || !!this.accountStore.data.limit_permissions_by_location;
    }

    get organizationLimit(): boolean {
      return !!this.accountStore.data.limit_permissions_by_organization;
    }

    get prefixChanged(): boolean {
      return !!this.moduleName.id && this.moduleName.record_prefix !== this.form.record_prefix;
    }

    get systemNameDisabled(): boolean {
      return !this.isNewRecord || !this.isCustomModule;
    }

    get showChangeLink(): boolean {
      return this.isDefaultTechAdmin && !this.isNewRecord && this.isCustomModule;
    }

    get showHardDeleteAttachments(): boolean {
      return !this.accountStore.data.hard_delete_attachments && this.isDefaultTechAdmin && this.isCustomModule && !this.isIntegrated;
    }

    get moduleTypeOptionsArray(): { label: string; value: ModuleType }[] {
      const types = [ModuleType.standard, ModuleType.form_managed, ModuleType.integrated, ModuleType.transactor].filter((option) => {
        if (option === ModuleType.transactor) {
          return this.moduleName.module_type === ModuleType.transactor;
        } else if (option === ModuleType.integrated) {
          return this.isDefaultTechAdmin || this.moduleName.module_type === ModuleType.integrated;
        } else {
          return true;
        }
      });
      const mapping = this.moduleTypeOptionsMap;
      return types.map((type) => ({ label: mapping[type], value: type }));
    }

    get moduleTypeOptionsMap(): Record<ModuleType, string> {
      return moduleTypeOptions(this.$t);
    }

    get installedOrPublished(): boolean {
      return !!this.form.published_module_version?.installation_date;
    }

    get publishedDateTitle(): string {
      return this.isPublished
        ? this.$t('tenant.admin.module_names.form.published_date')
        : this.$t('tenant.admin.module_names.form.install_date');
    }

    get isPublished(): boolean {
      return !!this.form.published_module_version?.locally_sourced;
    }

    get amsOptionsRequested(): boolean {
      return this.solutionTypeRequested && this.hierarchyTypeRequested;
    }

    get submitCaption(): string {
      return this.isNewRecord
        ? this.$t('tenant.admin.module_names.form.create_module')
        : this.$t('tenant.admin.module_names.form.update_module');
    }

    cancel(): void {
      changeLocation('/admin/settings/module_names');
    }

    submit(redirect?: string): void {
      this.validator?.validate().then((result: boolean) => {
        const data = {
          ...this.form,
          organization_sub_form_question_id: this.form.organization_sub_form_question_id || null,
          location_sub_form_question_id: this.form.location_sub_form_question_id || null,
          scheme_sub_form_question_id: this.form.scheme_sub_form_question_id || null,
        };

        if (this.prefixChanged) {
          let message = this.$t('tenant.admin.module_names.rename.record_prefix_warning');
          if (redirect === 'ams') {
            message = `${message}\n${this.$t('tenant.admin.module_names.publishing.submit_to_ams')}`;
          }
          bootbox.confirm({
            size: 'medium',
            message,
            buttons: {
              confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
              cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
            },
            callback: (confirmation: boolean) => {
              if (confirmation) {
                result && confirmation && this.$emit('submit', data, redirect);
              }
            },
          });
        } else if (redirect === 'ams') {
          bootbox.confirm({
            size: 'medium',
            message: this.$t('tenant.admin.module_names.publishing.submit_to_ams'),
            buttons: {
              confirm: { label: this.$t('app.buttons.save_and_continue'), className: 'btn-success' },
              cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
            },
            callback: (confirmation: boolean) => {
              if (confirmation) {
                result && confirmation && this.$emit('submit', data, redirect);
              }
            },
          });
        } else {
          result && this.$emit('submit', data, redirect);
        }
      });
    }

    beforeMount(): void {
      if (this.moduleName.sub_form_id) {
        this.$api
          .getSubFormQuestions(
            {
              filters: {
                field_type: [FieldType.location, FieldType.organization, FieldType.scheme],
                sub_form_section: { sub_form_id: this.moduleName.sub_form_id },
              },
              only: ['field_type'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            // TODO: remove readonly lookups later
            this.showOrganizationOptions = data.some((question) => question.field_type === FieldType.organization);
            this.showLocationOptions = data.some((question) => question.field_type === FieldType.location);
            this.showSchemeOptions = data.some((question) => question.field_type === FieldType.scheme);
          });
      }
      this.$api
        .getHierarchyTypes({ per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.hierarchyTypeOptions = sortBy(data, [(x) => x.system_code !== UNCATEGORISED, 'display']);
        })
        .finally(() => {
          this.hierarchyTypeRequested = true;
        });
      this.$api
        .getSolutionTypes({ per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.solutionTypeOptions = sortBy(data, [
            (x) => x.system_code !== UNCATEGORISED,
            (x) => x.system_code === 'other',
            (x) => x.display?.toLowerCase() || '',
          ]);
        })
        .finally(() => {
          this.solutionTypeRequested = true;
        });
      this.form = { ...this.moduleName };
    }
  }
