
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import WithAdminPermissions from '@app/mixins/with-admin-permissions';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import type { TenantUser } from '@app/models/tenant-user';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { Tooltip } from 'uiv';
  import { useAccountStore } from '@app/stores/account';
  import { orderBy } from 'lodash';
  type FeatureOption = { description: string; feature: AdminPermissionFeature; label: string };
  @Component({ components: { Tooltip, DsCheckbox, UserSelector, ValidationProvider, ValidationObserver } })
  export default class UserAdminPermissionsForm extends Mixins(WithAdminPermissions) {
    @Prop(Object) readonly user!: Partial<TenantUser>;
    topPermissions = [AdminPermissionFeature.system_administration, AdminPermissionFeature.module_config];

    form: Partial<TenantUser> = {};

    get accountStore() {
      return useAccountStore();
    }
    get allFeatures(): FeatureOption[] {
      const features = Object.values(AdminPermissionFeature)
        .filter((feature) => this.isPermissionManageable(feature))
        .map((feature) => ({
          label: this.adminPermissionNames[feature],
          description: this.adminPermissionDescriptions[feature],
          feature: feature,
          tooltip: this.featureTooltip(feature),
        }));
      const orderedFeatures = orderBy(features, [(feature) => (feature.feature === 'system_administration' ? 0 : 1)], ['asc']);

      return orderedFeatures;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get filteredTopLevelPermissions(): FeatureOption[] {
      const onlySystemAdminFeature = [AdminPermissionFeature.system_administration];
      if (!this.currentUserStore.isTechAdmin && !this.accountStore.moduleConfigForNonTechAdmins) {
        return this.allFeatures.filter(({ feature }) => onlySystemAdminFeature.includes(feature));
      }
      return this.topLevelPermissions;
    }

    get topLevelPermissions(): FeatureOption[] {
      return this.allFeatures.filter(({ feature }) => this.topPermissions.includes(feature));
    }

    get lowLevelPermissions(): FeatureOption[] {
      return this.allFeatures.filter(({ feature }) => !this.topPermissions.includes(feature));
    }

    isPermissionManageable(feature: AdminPermissionFeature): boolean {
      return this.currentUserStore.isAdminPermissionManageable(feature);
    }

    beforeMount() {
      this.reset();
    }

    reset() {
      this.form = { admin_permission_features: [], ...this.user };
    }
    featureLabel(feature: FeatureOption) {
      if (feature.feature == 'module_config' && !this.accountStore.moduleConfigForNonTechAdmins) {
        return `${feature.label} (${this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.feature_disabled')})`;
      }
      return feature.label;
    }

    featureTooltip(feature: string) {
      if (feature === 'module_config' && !this.accountStore.moduleConfigForNonTechAdmins) {
        return this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.feature_disabled_note');
      }
    }

    submit() {
      this.$emit('submit', this.form);
    }
  }
