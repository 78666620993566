
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import type { DateRangeFilterOption, FilterOption } from '../model';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';

  @Component({ components: {} })
  export default class FilterConfiguratorItemRequired extends Vue {
    @Model('input') readonly value!: FilterOption;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(String) readonly mode!: string;

    @Emit('input')
    updateRequired(required: boolean): FilterOption {
      return { ...this.value, required: `${required}` };
    }

    @Emit('range-required-change')
    updateRangeRequired(value: boolean, index: number) {
      return { value: `${value}`, index };
    }

    get option(): FilterOption {
      return this.value;
    }

    rangeRequiredDisabled(i: number): boolean {
      const source = (this.value as DateRangeFilterOption)?.value?.[i]?.source;
      return (
        !source ||
        !!this.noRequiredFilters ||
        source === ConfiguratorFilterSource.date_select ||
        source === ConfiguratorFilterSource.current_user
      );
    }

    get requiredDisabled(): boolean {
      return (
        !!this.noRequiredFilters ||
        this.mode === 'explicit' ||
        this.value.source === ConfiguratorFilterSource.current_user ||
        this.value.source === ConfiguratorFilterSource.current_record
      );
    }

    getRangeRequiredValue(i: number): string {
      return (this.value as DateRangeFilterOption)?.value?.[i]?.required || 'false';
    }
  }
