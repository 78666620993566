import RootActivityPage from '@app/components/activities/root-activity-page.vue';
import ViewActivityPage from '@app/components/activities/view-activity-page.vue';
import RecordBudgetCategoriesIndexPage from '@app/components/expensable/record-budget-categories-index-page.vue';
import KnowledgeBaseIndexPage from '@app/pages/knowledge-base-index-page.vue';
import ModuleRecordPrintPage from '@app/pages/module-records/module-record-print-page.vue';
import {
  accountFeatureGuardFactory,
  aclGuardFactory,
  hasActiveNonEmptyPanesGuardFactory,
  indexFiltersGuardFactory,
  permissionGuardFactory,
} from '@app/services/guards';
import type { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/order
import VueRouter from 'vue-router';

const ModuleRecordsWizardPage = () =>
  import(/* webpackChunkName: "module-records-wizard" */ './pages/module-records/module-records-wizard-page.vue');
const ModuleRecordEditPage = () =>
  import(/* webpackChunkName: "module-records-edit" */ './pages/module-records/module-record-edit-page.vue');
const ModuleRecordNewPage = () => import(/* webpackChunkName: "module-records-new" */ './pages/module-records/module-record-new-page.vue');
const ModuleRecordsPublicOrEmbed = () =>
  import(/* webpackChunkName: "module-records-public-or-embed" */ './pages/module-records/module-records-public-or-embed.vue');
const LegacyWizardPage = () => import(/* webpackChunkName: "legacy-wizard" */ './pages/legacy-wizard-page.vue');
import ActivitiesIndexPage from '@app/components/activities/activities-index-page.vue';
import ReportsPage from '@app/pages/reports-page.vue';
import SuperReportPage from '@app/components/super-report/super-report-page.vue';
import PersonalDashboardPage from '@app/pages/personal-dashboard-page.vue';
import adminRoutes from '@app/admin-routes';
import { ACTIVITY_CONCEPT } from '@app/constants';
import qs from 'qs';
import LocationTagReportsIndex from '@app/pages/admin/location-tag-reports/index.vue';

import UserDirectoryPage from './pages/user-directory-page.vue';
import NewActivityPage from './components/activities/new-activity-page.vue';
import EditActivityPage from './components/activities/edit-activity-page.vue';

const isPartnerPortal = !window.DONESAFE.account;

const routes: RouteConfig[] = [
  {
    path: '/actions',
    component: ActivitiesIndexPage,
    name: 'activities-index',
    beforeEnter: aclGuardFactory(
      ACTIVITY_CONCEPT,
      indexFiltersGuardFactory({ index: 'actions' }, { filter: { scope_filter: 'mine', date: 'week', status_filter: 'open' } })
    ),
    children: [
      {
        path: 'new',
        name: 'activities-new',
        component: NewActivityPage,
      },
      {
        path: ':activityId',
        component: RootActivityPage,
        children: [
          {
            path: 'edit',
            name: 'activity-edit',
            component: EditActivityPage,
          },
          {
            path: '',
            name: 'activity-view',
            component: ViewActivityPage,
          },
        ],
      },
    ],
  },
  {
    path: '/kb',
    component: KnowledgeBaseIndexPage,
    name: 'knowledge-base-index',
    beforeEnter: aclGuardFactory(
      'Procedure',
      //TODO: check index
      indexFiltersGuardFactory({ index: 'procedures' })
    ),
  },
  {
    path: '/users',
    component: UserDirectoryPage,
    name: 'user-directory',
    beforeEnter: permissionGuardFactory(
      'current_user',
      'role.user_directory_access',
      indexFiltersGuardFactory(
        { index: 'users' },
        {
          filter: {
            scope: 'all',
          },
        }
      )
    ),
  },
  ...adminRoutes,
  {
    path: '/module_records/:recordId/wizard',
    component: ModuleRecordsWizardPage,
    name: 'module-records-wizard',
    props: true,
  },
  {
    path: '/module_records/:recordId/print',
    name: 'module-record-print-page',
    props: true,
    component: ModuleRecordPrintPage,
  },
  {
    path: '/hazards/:recordId/wizard',
    component: LegacyWizardPage,
    props: (route) => ({ recordType: 'Hazard', recordId: Number(route.params.recordId) }),
  },
  {
    path: '/incidents/:recordId/wizard',
    component: LegacyWizardPage,
    name: 'legacy-wizard',
    props: (route) => ({ recordType: 'Incident', recordId: Number(route.params.recordId) }),
  },
  {
    path: '/module_records/new',
    component: ModuleRecordNewPage,
    name: 'module-records-new',
  },
  {
    path: '/module_records/public_new',
    component: ModuleRecordsPublicOrEmbed,
    name: 'module-records-public-new',
  },
  {
    path: '/module_records/embed',
    component: ModuleRecordsPublicOrEmbed,
    name: 'module-records-embed',
  },
  {
    path: '/module_records/:recordId/edit',
    component: ModuleRecordEditPage,
    name: 'module-records-edit',
    props: true,
  },
  {
    path: '/reports',
    name: 'reports-index',
    component: ReportsPage,
    beforeEnter: aclGuardFactory('Report'),
  },
  {
    path: '/reports/super_report/',
    name: 'super-report-page',
    component: SuperReportPage,
    props: (route) => ({ superReportId: Number(route.query.super_report_id), page: route.query.page }),
  },
  {
    path: '/module_records/:id/record_budget_categories/',
    name: 'record-budget-categories-index',
    component: RecordBudgetCategoriesIndexPage,
    props: true,
  },
  {
    path: '/personal_dashboard',
    name: 'personal-dashboard',
    component: PersonalDashboardPage,
    beforeEnter: hasActiveNonEmptyPanesGuardFactory(),
  },
  {
    path: '/location_tag_reports',
    name: 'location-tag-reports',
    component: LocationTagReportsIndex,
    beforeEnter: accountFeatureGuardFactory('disable_location_tag_report', true, aclGuardFactory('Report')),
  },
];

const router = new VueRouter({
  routes: isPartnerPortal ? [] : routes,
  base: '/',
  mode: 'history',
  parseQuery: qs.parse,
  stringifyQuery: (q) => qs.stringify(q, { addQueryPrefix: true, arrayFormat: 'brackets' }),
});

export default router;
