
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class ActivityParentType extends Vue {
    @Prop(Number) readonly id!: number;

    title = '';
    subTitle: Nullable<string> = null;
    notAvailable = false;

    get recordPath(): string | undefined {
      return this.notAvailable ? undefined : `/actions/${this.id}`;
    }

    async beforeMount(): Promise<void> {
      try {
        const { data: activity } = await this.$api.getActivity(
          this.id,
          { only: ['id', 'description'] },
          {
            cache: true,
            join: true,
          }
        );
        this.title = `[${this.id}] ${activity.description}`;
      } catch {
        this.notAvailable = true;
        this.title = `[${this.id}] (${this.$t('app.labels.not_available')})`;
      }
      this.subTitle = 'Action';
    }
  }
