
  import { Component } from 'vue-property-decorator';
  import ModuleRecordsTable from '../module-record/module-records-table.vue';
  import BaseWidget from './base-widget';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RecordIndexWidgetOptions } from '@app/models/widget';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';

  @Component({ components: { ModuleRecordsTable } })
  export default class FollowedRecordsWidget extends BaseWidget<RecordIndexWidgetOptions> {
    moduleNamesLoading: boolean = true;
    moduleIds: Array<number | string> = [];

    get filters(): DonesafeFilterOptions<ModuleRecord> {
      return { follow: true };
    }

    get customFilters(): DonesafeFilterOptions<ModuleRecord> {
      let formFilters = this.widgetFormFilters;
      formFilters['module_name_id'] = this.moduleIds;
      return {
        ...formFilters,
      };
    }

    get widgetFormFilters(): DonesafeFilterOptions<ModuleRecord> {
      return {
        ...convertInFormFilters(this.widget.options.filters, {
          user: this.currentUserStore.data,
        }),
      };
    }

    readOrFetchModuleNameIds() {
      if (!!this.widgetFormFilters['module_name_id']) {
        this.moduleIds = this.widgetFormFilters['module_name_id'] as Array<number | string>;
      } else {
        this.moduleIds = [];
      }
      this.moduleNamesLoading = false;
    }

    get reverse(): boolean {
      return this.widget.options.reverse === 'true';
    }

    get useHistory(): boolean {
      return this.widget.options.use_history === 'true';
    }

    get allowCustomFilters(): boolean {
      return this.widget.options.allow_custom_filters === 'true';
    }

    get recordLinkBehaviour(): RecordLinkBehaviour {
      return this.widget.options.record_link_behaviour || RecordLinkBehaviour.GoToRecord;
    }

    beforeMount() {
      this.readOrFetchModuleNameIds();
    }
  }
