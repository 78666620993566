
  import { Component, Prop } from 'vue-property-decorator';
  import TestResultSection from '@app/components/admin/automation-definitions/debug/test-result-section.vue';
  import type { DebugRuleSet } from '@app/services/api/rule-sets-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { toaster } from '@app/utils/toaster';
  import { groupAndCountEventTypes } from '@app/utils/group-and-count-event-types';
  import type { Event } from '@app/models/event';
  import type { EventsPerResults } from '@app/services/api/events-api';
  import EventOptionsMixin from '@app/components/admin/automation-definitions/event-options-mixin';
  import TestAutomationConfirmBox from '@app/components/admin/automation-definitions/test-automation-confirm-box.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import { Tooltip } from 'uiv';
  import { useAccountStore } from '@app/stores/account';

  @Component({
    components: {
      TestResultSection,
      TestAutomationConfirmBox,
      DsIcon,
      Tooltip,
    },
  })
  export default class EventsSection extends EventOptionsMixin {
    @Prop(Object) readonly testResults!: DebugRuleSet;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;
    @Prop([String, Number]) readonly recordId!: number;
    @Prop(String) readonly recordType!: string;
    @Prop(Boolean) readonly deleted!: boolean;

    eventToDebug: Nullable<Event> = null;
    eventsRunning: Record<number, boolean> = {};
    confirmBox = false;
    eventsPerResults: Record<number, EventsPerResults> = {};
    eventsTitle = '';

    get logLink(): string {
      return `/admin/settings/automation_definitions/${this.automationDefinition.id}/event_occurrences`;
    }

    get accountStore() {
      return useAccountStore();
    }

    runEventAction(): void {
      this.confirmBox = false;
      if (!this.eventToDebug) return;

      const eventId = this.eventToDebug.id;
      const eventType = this.eventTypeName[this.eventToDebug.event_type];
      this.eventsRunning = { ...this.eventsRunning, [eventId]: true };

      this.$api
        .runEventAction(this.eventToDebug.id, {
          triggered_type: this.recordType,
          triggered_id: Number(this.recordId),
        })
        .then(() => {
          toaster({
            text: this.$t('tenant.admin.automation_definitions.form.event_has_been_dispatched', { name: eventType }),
            icon: 'success',
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({
            text: data?.error,
            icon: 'error',
            position: 'top-right',
          });
        })
        .finally(() => {
          this.eventsRunning = { ...this.eventsRunning, [eventId]: false };
        });
    }

    debugEvent(event: Event) {
      this.eventToDebug = event;
      this.$nextTick(() => {
        this.confirmBox = true;
      });
    }

    beforeMount() {
      this.getAutomationCreateEventOptions();
      this.loadEventsPerResults();
      this.loadEventsTitle();
    }

    async loadEventsTitle() {
      const { data } = await this.$api.getAutomationDefinition(this.automationDefinition.id, {
        only: ['id', { events: ['event_type_name'] }],
      });
      this.eventsTitle = groupAndCountEventTypes(data);
    }

    async loadEventsPerResults() {
      const { data: eventsPerResults } = await this.$api.getEventsPerResults({
        source: 'automation_definition',
        source_id: this.automationDefinition.id,
        record_type: this.recordType,
        record_id: Number(this.recordId),
      });

      return (this.eventsPerResults = eventsPerResults);
    }
  }
