
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import Select2 from '../select2.vue';
  import EntitySelector from '../entity-selector.vue';
  import { ValidationObserver } from 'vee-validate';
  import type { DefaultScoreBandOptions } from '@app/models/default-score-band-options';

  @Component({ components: { DsModal, Select2, EntitySelector, ValidationObserver } })
  export default class DefaultScoreBandOptionsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Number) readonly moduleNameId!: number;
    @Prop(Object) readonly options!: Partial<DefaultScoreBandOptions>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<DefaultScoreBandOptions> = {};

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    initForm(): void {
      this.form = { ...this.options };
    }
  }
