
  import EntitySelector from '@app/components/entity-selector.vue';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { Component } from 'vue-property-decorator';
  import type { Involvement } from '@app/models/involvement';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { EntitySelector } })
  export default class ProceduresTabSettings extends SubFormListSettingsBase {
    get involvementFilters(): DonesafeFilterOptions<Involvement> {
      return {
        active: true,
        module_name: this.moduleName?.name,
        multiple: false,
      };
    }
  }
