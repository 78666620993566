
  import RecordCalculationTabs from '@app/components/record-calculations/record-calculation-tabs.vue';
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import bootbox from 'bootbox';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      RecordCalculationTabs,
      DsModal,
    },
  })
  export default class EditRecordCalculationFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ type: [String, Number] }) readonly recordCalculationId!: number | string;

    @Ref() readonly recordCalculationTabs?: RecordCalculationTabs;

    recordCalculation: Nullable<RecordCalculation> = null;
    visible = true;
    stateOptions: string[] = [];

    deleteRecordCalculation(recordCalculationId: number): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.record_calculation.form.delete_this_record_calculation'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteRecordCalculation(recordCalculationId)
              .then(() => {
                this.closeModal('reload');
                toaster(this.$t('tenant.admin.record_calculation.modal.record_calculation_deleted'));
              })
              .catch(({ data }) => {
                toaster({ text: data.error, position: 'top-right', icon: 'error' });
              });
        },
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-record-calculations',
        params: {
          reloadTable: command,
          moduleNameId: `${this.moduleNameId}`,
        },
        query: this.$route.query,
      });
    }

    beforeMount(): void {
      this.$api
        .getRecordCalculation(
          Number(this.recordCalculationId),
          { include: ['module_name', 'sub_form_ids', 'sub_form_list_ids'] },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordCalculation = data;
        });
    }
  }
