
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import Scored from './shared/scored.vue';
  import type { VideoQuestionOptions } from '@app/models/question-options/video-question-options';

  @Component({ components: { Scored } })
  export default class VideoShowOptions extends BaseShowOptions<VideoQuestionOptions> {
    get videoUrl(): string {
      return this.config.video_url;
    }
  }
