import HybridPage from '@app/components/admin/hybrid-modules/hybrid-page.vue';
import HybridModulePage from '@app/components/admin/hybrid-modules/hybrid-module-page.vue';
import NewHybridPage from '@app/components/admin/hybrid-modules/new-hybrid-page.vue';
import EditHybridPage from '@app/components/admin/hybrid-modules/edit-hybrid-page.vue';
import HybridSubFormPage from '@app/components/admin/hybrid-modules/hybrid-sub-form-page.vue';
import type { RouteConfig } from 'vue-router/types/router';
import HybridDashboard from '@app/components/admin/hybrid-modules/hybrid-dashboard.vue';

const hybridModulesRoutes: RouteConfig[] = [
  {
    path: '/admin/settings/hybrid_modules/',
    component: HybridPage,
    children: [
      {
        path: ':moduleNameId/',
        component: HybridModulePage,
        name: 'admin-settings-hybrid-module-page',
        props: (route) => ({ moduleNameId: Number(route.params.moduleNameId) }),
        children: [
          {
            path: 'sub_forms/new',
            name: 'new-hybrid-page',
            components: { modal: NewHybridPage },
            props: { modal: (route) => ({ moduleNameId: Number(route.params.moduleNameId) }) },
          },
          {
            path: 'sub_forms/:subFormListId/edit',
            name: 'edit-hybrid-page',
            components: { modal: EditHybridPage },
            props: {
              modal: (route) => ({ moduleNameId: Number(route.params.moduleNameId), subFormListId: Number(route.params.subFormListId) }),
            },
          },
        ],
      },
      {
        path: ':moduleNameId/sub_forms/:subFormListId',
        name: 'admin-settings-hybrid-sub-form-page',
        component: HybridSubFormPage,
        props: (route) => ({ moduleNameId: Number(route.params.moduleNameId), subFormListId: Number(route.params.subFormListId) }),
      },
      {
        path: '/',
        name: 'admin-settings-hybrid-dashboard',
        component: HybridDashboard,
        props: (route) => ({ moduleNameId: Number(route.params.moduleNameId) }),
      },
    ],
  },
];

export default hybridModulesRoutes;
