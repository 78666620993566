
  import bootbox from 'bootbox';
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ValueCopy } from './models';
  import PredefinedValueCopyInput from './predefined-value-copy-input.vue';
  import CopyNote from './copy-note.vue';
  import type { Saml } from '@app/models/saml';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ValidationObserver, ValidationProvider, PredefinedValueCopyInput, CopyNote } })
  export default class AdminSamlForm extends Vue {
    @Prop(Object) readonly saml!: Saml;
    @Prop(Boolean) readonly loading!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    nameIdentifierFormatValues: ValueCopy[] = [
      { label: 'Email', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress' },
      { label: 'Unspecified', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified' },
      { label: 'Persistent', value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent' },
      { label: 'Transient', value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient' },
    ];

    form: Partial<Saml> = {
      active: true,
      name_identifier_format: this.nameIdentifierFormatValues[0].value,
      request_attributes_field: '',
    };

    useLowercasedSamlResponseNameIDValue = true;

    // TODO: build host form schema + ENV var like DS_HOSTNAME
    // so when configuring SSO with site url that includes .slice, eg: abc.s9.donesafe.com
    // it shows the real final SP sso login url that client actually suppose to use: abc.donesafe.com
    get domain(): string {
      return window.location.host;
    }

    beforeMount(): void {
      this.form = { ...this.form, ...this.saml };
      this.useLowercasedSamlResponseNameIDValue = !this.form?.request_attributes_field;
    }

    deleteSaml(): void {
      if (this.loading) {
        return;
      }

      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteSaml(this.saml.id)
              .then(() => {
                toaster(this.$t('tenant.settings.samls.edit.deleted'));
                this.$api.cache.clear();
                this.$router.replace({
                  name: 'admin-settings-samls-index',
                  query: this.$route.query,
                });
              })
              .catch(({ data }) => {
                toaster({ text: data.error, icon: 'error' });
              });
          }
        },
      });
    }

    archiveOrRestore(active: boolean): void {
      if (this.loading) {
        return;
      }

      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.form = { ...this.form, active };
            this.submitForm(active ? this.$t('tenant.settings.samls.edit.restored') : this.$t('tenant.settings.samls.edit.archived'));
          }
        },
      });
    }

    submitForm(toast: string): void {
      if (this.loading) {
        return;
      }

      this.$nextTick(() => {
        this.validator?.validate().then((result: boolean) => {
          if (result) {
            this.$emit(
              'submit',
              {
                ...this.form,
                request_attributes_field: this.useLowercasedSamlResponseNameIDValue ? '' : this.form.request_attributes_field,
              },
              toast
            );
          }
        });
      });
    }
  }
