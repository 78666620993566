import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Widget } from '@app/models/widget';

@Component
export default class BaseWidget<V> extends Vue {
  @Prop(Object) readonly widget!: Widget<V>;
  @Prop(Boolean) readonly canEdit?: boolean;
  @Prop(Boolean) readonly isPublicDashboard?: boolean;

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }
}
