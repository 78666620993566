import { render, staticRenderFns } from "./expense-budget-uuid-field-options.vue?vue&type=template&id=2587e5dc&scoped=true&lang=pug"
import script from "./expense-budget-uuid-field-options.vue?vue&type=script&lang=ts"
export * from "./expense-budget-uuid-field-options.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2587e5dc",
  null
  
)

export default component.exports