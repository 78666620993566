
  import { Component, Prop } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  import Select2 from '../../../select2.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import EventCallToActionPanel from '../event-call-to-action-panel.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface SmsOptions {
    custom_include_record_link?: StringBoolean;
    custom_message?: string;
    custom_message_text?: StringBoolean;
    send_sms?: StringBoolean;
  }

  interface CallToAction {
    klass?: string;
    link?: string;
    text?: string;
  }

  // TODO: add NotificationMessage ts interface
  interface NotificationMessageEventValues {
    access_level: NotificationMessageAccessCheckLevel;
    attachment_question_codes?: string[];
    attachment_strategy: string;
    body: string;
    call_to_action?: CallToAction;
    related_id: string;
    sms_options?: SmsOptions;
    subject?: string;
    to_id: string;
  }

  enum NotificationMessageAccessCheckLevel {
    confidential = 'confidential',
    skip = 'skip',
    view = 'view',
  }

  enum NotificationMessageAttachmentStrategy {
    authenticatable_attachments = 'authenticatable_attachments',
    expirable_attachments = 'expirable_attachments',
    public_attachments = 'public_attachments',
    secured_attachments = 'secured_attachments',
  }

  @Component({
    components: { Select2, AdvancedAutosuggest, EventCallToActionPanel, EventInputMethodSwitch, FormField },
  })
  export default class NotificationMessageEventForm extends BaseEventForm<NotificationMessageEventValues> {
    @Prop(Boolean) readonly noRelatedObjects?: boolean;

    get accessLevelOptions(): [NotificationMessageAccessCheckLevel, string][] {
      return [
        [NotificationMessageAccessCheckLevel.view, this.$t('app.labels.notification.view_description')],
        [NotificationMessageAccessCheckLevel.confidential, this.$t('app.labels.notification.confidential_description')],
        [NotificationMessageAccessCheckLevel.skip, this.$t('app.labels.notification.skip_description')],
      ];
    }

    get attachmentStrategyOptions(): [NotificationMessageAttachmentStrategy, string][] {
      return [
        [NotificationMessageAttachmentStrategy.secured_attachments, this.$t('app.labels.secured_attachments')],
        [NotificationMessageAttachmentStrategy.authenticatable_attachments, this.$t('app.labels.authenticatable_attachments')],
        [
          NotificationMessageAttachmentStrategy.expirable_attachments,
          this.$t('app.labels.expirable_attachments', {
            days: window.DONESAFE.NOTIFICATION_MESSAGE_EXPIRATION_DAYS,
          }),
        ],
        [NotificationMessageAttachmentStrategy.public_attachments, this.$t('app.labels.public_attachments')],
      ];
    }

    get relatedType(): string {
      return this.noRelatedObjects ? '^class_name' : '~class_name';
    }

    get showSmsOptions(): boolean {
      return !this.accountStore.data.sms_notifications_legacy && !!this.accountStore.data.sms_notifications;
    }

    get showCustomsSmsSettings(): boolean {
      return this.formSmsOptions.custom_message === 'true';
    }

    get customMessageRequired(): boolean {
      return this.formSmsOptions.custom_message === 'true' && this.formSmsOptions.send_sms?.toString() === 'true';
    }

    get formSmsOptions(): SmsOptions {
      return this.form.sms_options as SmsOptions;
    }

    get valuesSmsOptions(): SmsOptions {
      return (this.values?.sms_options as SmsOptions) || this.defaultSmsOptions;
    }

    get defaultSmsOptions(): SmsOptions {
      return {
        custom_message: 'false',
        custom_include_record_link: 'true',
      };
    }

    beforeMount(): void {
      this.form = {
        call_to_action: {},
        sms_options: this.valuesSmsOptions,
        attachment_strategy: 'secured_attachments',
        access_level: NotificationMessageAccessCheckLevel.confidential,
        ...this.values,
      };
    }
  }
