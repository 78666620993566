import { API } from '@app/services/api-factory';
import { changeLocation } from '@app/utils/change-location';

interface UsageReferenceRouter {
  [referenceType: string]: (referenceId: string | number, usageType: string) => void;
}

const USAGE_REFERENCE_ROUTES: UsageReferenceRouter = {
  AutomationDefinition: (referenceId) => {
    changeLocation(`/admin/settings/automation_definitions/${referenceId}/edit`);
  },
  ModuleName: (referenceId, usageType) => {
    switch (usageType) {
      case 'module_record_title_placeholders':
        changeLocation(`/admin/settings/module_names/${referenceId}/show_options`);
        return;
      default:
        changeLocation(`/admin/settings/module_names/${referenceId}?tab=sub_form`);
    }
  },
  ScheduledEvent: (referenceId) => {
    changeLocation(`/admin/settings/scheduled_events/${referenceId}/edit`);
  },
  UserRecordCalendar: (referenceId) => {
    API.getUserRecordCalendar(Number(referenceId), { only: ['id', 'module_name_id'] }, { cache: true }).then(({ data }) => {
      changeLocation(`/admin/settings/module_names/${data.module_name_id}/show_options/calendars/${data.id}`);
    });
  },
  SubFormList: (referenceId, usageType) => {
    API.getSubFormList(Number(referenceId), { only: ['id', 'module_tab_id', { module_name: ['id'] }] }, { cache: true }).then(
      ({ data }) => {
        const moduleNameId = data.module_name?.id;
        if (moduleNameId && usageType === 'tab_section_record_title_placeholders') {
          changeLocation(
            `/admin/settings/module_names/${moduleNameId}/module_tabs/${data.module_tab_id}/tab_sections/${referenceId}/view_options`
          );
        } else if (moduleNameId) {
          changeLocation(`/admin/settings/module_names/${moduleNameId}/module_tabs/${data.module_tab_id}`);
        } else {
          changeLocation(`/admin/settings/module_tabs/${data.module_tab_id}`);
        }
      }
    );
  },
  SubFormQuestion: (referenceId) => {
    changeLocation(`/admin/settings/sub_form_questions/${referenceId}/edit`);
  },
};

export function canResolveUsageReferenceRoute(referenceType: string): boolean {
  return !!USAGE_REFERENCE_ROUTES[referenceType];
}
export function resolveUsageReferenceRoute(referenceType: string, referenceId: string | number, usageType: string): void {
  if (USAGE_REFERENCE_ROUTES[referenceType]) USAGE_REFERENCE_ROUTES[referenceType](referenceId, usageType);
}
