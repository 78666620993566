
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';

  import type { DsGroupNode } from '../models/ds-group-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';

  @Component({ components: { DsDropdown } })
  export default class GroupConditions extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() readonly group!: DsGroupNode;
  }
