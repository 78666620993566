
  import EventForEverySelector from '@app/components/admin/events/event-for-every-selector.vue';
  import RuleBuilderPanel from '@app/components/admin/events/rule-builder-panel.vue';
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import ScheduledEventForEverySelector from '@app/components/admin/events/scheduled-event-for-every-selector.vue';
  import BaseCreateObjectEvent from '@app/mixins/base-create-object-event-form';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { pick } from 'lodash';

  import FormFields from '../events/forms/form-fields.vue';

  interface InjectableArguments {
    concept_name?: string;
    include_all?: boolean;
    include_current_user?: boolean;
    involvement_id?: number;
    regulatory_report_config_id?: number;
    relationship_code?: string;
    sub_form_id?: number;
  }

  @Component({ components: { RuleBuilderPanel, EventForEverySelector, Select2, FormFields, ScheduledEventForEverySelector } })
  export default class CreateObjectEventForm extends BaseCreateObjectEvent {
    @Ref() readonly ruleBuilderPanel!: RuleBuilderPanel;
    @Ref() readonly everySelector!: ScheduledEventForEverySelector;
    @Prop(Boolean) readonly submitting!: boolean;

    conceptName = null;
    hasTriggeringRecord = false;

    injectableArguments: InjectableArguments = {};

    get ruleBuilderTriggeringInjectableArguments(): Nullable<InjectableArguments> {
      return { concept_name: this.syncedEvent.event_type };
    }

    onRuleBuilderChange(value: QueryBuilderBlob | undefined): void {
      this.syncedEvent.collection_rules = value || null;

      if (this.perArguments) {
        this.everySelector.requestUpdateCountValue({
          injectableArguments: {
            concept_name: this.perArguments.concept_name,
            ...pick(this.perArguments.injectable_arguments, 'sub_form_id'),
          },
          ...(value ? { rules: value } : {}),
        });
      }
    }

    onPerChange(per: string) {
      this.blocking(async () => {
        this.openRuleBuilderInEditMode = true;
        await Promise.all([this.updatePerArguments(per, 'schedule'), this.getCollection(per)]);
      });
    }

    getPerOptions(): Promise<void> {
      return this.$api
        .getPerOptions({ concept_name: this.conceptName, injectable_arguments: this.injectableArguments }, { cache: true })
        .then(({ data }) => {
          this.perOptions = data;
        });
    }

    async beforeMount(): Promise<void> {
      Promise.all([this.getPerOptions(), ...this.getDefaultOptionsPromises('schedule')]).finally(() => {
        this.createObjectEventFormLoading = false;
        this.sendLoaded();
      });

      if (!!this.per) {
        await this.updatePerArguments(this.per, 'schedule');
        await this.getCollection(this.per, { set: true });
      }
    }
  }
