
  import { startCase } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import ActivityParentType from './parent-types/activity-parent-type.vue';
  import ModuleRecordParentType from './parent-types/module-record-parent-type.vue';
  import SubFormCompletionParentType from './parent-types/sub-form-completion-parent-type.vue';
  import UserInvolvementParentType from './parent-types/user-involvement-parent-type.vue';
  import UserParentType from './parent-types/user-parent-type.vue';
  import type { CurrentUser } from '@app/models/current-user';

  const parentTypeComponents = {
    ModuleRecordParentType,
    UserParentType,
    ActivityParentType,
    SubFormCompletionParentType,
    UserInvolvementParentType,
  };

  @Component({ components: { ...parentTypeComponents } })
  export default class ParentTypeRow extends Vue {
    @Prop(Number) readonly id!: number;
    @Prop(String) readonly type!: string;
    @Prop(Object) readonly currentUser?: CurrentUser;

    get title(): string {
      return startCase(this.type);
    }

    parentTypeComponent(type: string): string | undefined {
      switch (type) {
        case 'ModuleRecord':
          return 'ModuleRecordParentType';
        case 'SubFormCompletion':
          return 'SubFormCompletionParentType';
        case 'Activity':
          return 'ActivityParentType';
        case 'User':
          return 'UserParentType';
        case 'UserInvolvement':
          return 'UserInvolvementParentType';
      }
    }
  }
