
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import FilterSelect from '../../../components/filter-select.vue';
  import Select2 from '../../../components/select2.vue';
  import OrganizationSelector from '../../../components/organization/organization-selector.vue';
  import SearchInput from '@app/components/search-input.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Organization } from '@app/models/organization';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import FallbackBadge from '@app/components/fallback-badge.vue';
  import { useAccountStore } from '@app/stores/account';

  @Component({
    components: {
      FilterCheckbox,
      PaperTrailsModalLink,
      OrganizationSelector,
      BaseTable,
      FilterSelect,
      Select2,
      SearchInput,
      DsDropdown,
      FallbackBadge,
    },
  })
  export default class AdminSettingsOrganizationsIndex extends Vue {
    @Ref() readonly table?: BaseTable<Organization>;

    manager = new ListManager<Organization>({
      fetchDataFunction: (params) => {
        const patchedParams = {
          ...params,
          filters: {
            ...params.filters,
            cost_centre: false,
          },
        };
        return this.$api.getOrganizations(
          {
            ...patchedParams,
            only: ['active', 'id', 'name', { organization: ['name'] }],
          },
          { cache: true }
        );
      },
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        {
          title: this.$t('tenant.settings.organizations.table_head.parent_organization'),
          name: 'organization',
          sortField: 'organization.name',
          filter: true,
        },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
        { title: '', name: 'operations', width: 'max-content' },
      ],
      allowFilters: true,
    });

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    get accountStore() {
      return useAccountStore();
    }
  }
