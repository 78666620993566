
  import MainRecordDetails from '@app/components/sub-form-completion/form/main-record-details.vue';
  import SubFormCompletionForm from '@app/components/sub-form-completion/form/sub-form-completion-form.vue';
  import { keyBy, isEqual, isEmpty } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import bootbox from 'bootbox';
  import WithSubFormCompletionSubmit from '@app/mixins/with-sub-form-completion-submit';
  import { mixins } from 'vue-class-component';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import PanelPage from '@app/components/sub-form-completion/panel-page.vue';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { Dictionary } from '@app/models/dictionary';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { WithPreviousCompletionForm } from '@app/mixins/with-previous-completion-form';
  import WithCompletionDefaultValues from '@app/mixins/with-completion-default-values';
  import { changeLocation } from '@app/utils/change-location';

  import { moduleRecordErrorHandler, SFC_FORM_RECORD_ONLY, SUB_FORM_LIST_ONLY } from './utils';
  import type { SubFormListOnly, SfcFormRecordOnly } from './utils';
  import QuickEditPanel from './quick-edit-panel.vue';

  @Component({ components: { QuickEditPanel, SubFormCompletionForm, MainRecordDetails, PanelPage } })
  export default class QuickEditPage extends mixins(WithSubFormCompletionSubmit, WithPreviousCompletionForm, WithCompletionDefaultValues) {
    @Prop(Number) readonly subFormCompletionId?: number;

    moduleRecord: Nullable<Pick<ModuleRecord, SfcFormRecordOnly>> = null;
    subFormLists: Pick<SubFormList, SubFormListOnly>[] = [];
    form: Nullable<SubFormData> = null;
    loading = false;
    subForms: Dictionary<SubForm> = {};
    completions: Pick<SubFormCompletion, 'id' | 'sub_form_list_id' | 'stage' | 'title' | 'workflow' | 'created_at'>[] = [];

    get showPage(): boolean {
      return (
        !isEmpty(this.moduleRecord) &&
        !!this.subFormLists.length &&
        !!Object.keys(this.subForms).length &&
        (!isEmpty(this.subFormCompletion) || this.$route.fullPath.includes('/quick_edit'))
      );
    }

    get subFormTitle(): string {
      return this.subForms[this.subFormCompletion?.sub_form_id || '']?.title || '';
    }

    get subFormDescription(): string {
      return this.subForms[this.subFormCompletion?.sub_form_id || '']?.description || '';
    }

    get recordId(): string {
      return this.$route.query.record_id as string;
    }

    get recordType(): string {
      return this.$route.query.record_type as string;
    }

    get subFormId(): Maybe<number> {
      return Number(this.$route.query.sub_form_id) || undefined;
    }

    get subFormListId(): Maybe<number> {
      return Number(this.$route.query.sub_form_list_id) || undefined;
    }

    async onCompletionSubmitComplete(): Promise<void> {
      if (this.moduleRecord) {
        changeLocation(`/sub_form_completions/quick_edit?record_id=${this.moduleRecord.id}&record_type=ModuleRecord`);
      } else {
        window.location.reload();
      }
    }

    // TODO: utilise beforeRouteLeave once migrated to vue-router
    checkDiff(event: MouseEvent, link: string): void {
      if (!isEqual(this.form, this.savedForm)) {
        event.preventDefault();

        bootbox.confirm({
          backdrop: false,
          message: 'Are you sure you want to continue without saving',
          size: 'small',
          callback: (result: boolean) => {
            result && changeLocation(link);
          },
        });
      }
    }

    getSubFormCompletions(recordId: string | number, recordType: string): Promise<void> {
      return this.$api
        .getSubFormCompletions({
          filters: { record_id: recordId, record_type: recordType },
          only: ['id', 'sub_form_list_id', 'stage', 'title', { workflow: ['color'] }, 'approval_for_sub_form_completion_id', 'created_at'],
        })
        .then(({ data }) => {
          this.completions = data.filter((c) => !c?.approval_for_sub_form_completion_id);
        });
    }

    getModuleRecord(recordId: number, recordType: string): Promise<void> {
      return this.$api
        .getModuleRecord(recordId, {
          only: SFC_FORM_RECORD_ONLY,
        })
        .then(({ data }) => {
          this.moduleRecord = data;

          if (!this.subFormCompletion && this.$route.fullPath.includes('/new')) {
            this.subFormCompletion = {
              sub_form_id: this.subFormId,
              sub_form_list_id: this.subFormListId,
              record_id: recordId,
              record_type: recordType,
            };
          }

          this.getDefaultValuesPromise({
            id: this.subFormCompletionId,
            sub_form_id: this.subFormId,
            sub_form_list_id: this.subFormListId,
            record_id: this.moduleRecord.id,
          }).then(({ data }) => {
            this.form = { ...data };
            this.savedForm = { ...this.form };
          });

          this.$api
            .getAccessibleSubFormLists({
              filters: {
                record_id: Number(recordId),
                record_type: recordType,
                module_tab: { active: true },
                sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
              },
              only: SUB_FORM_LIST_ONLY,
              per_page: -1,
              sort: 'module_tab.index,index',
            })
            .then(({ data }) => {
              this.subFormLists = data;

              if (this.subFormCompletion?.sub_form_list_id) {
                this.subFormList = keyBy(this.subFormLists, 'id')[this.subFormCompletion?.sub_form_list_id];
              }

              const subFormIds = this.subFormLists.reduce((acc, subFormList) => {
                return [...acc, ...(subFormList.sub_form_ids || [])];
              }, [] as number[]);

              this.$api
                .getSubForms({
                  filters: {
                    id: [...subFormIds, ...(this.subFormCompletion?.sub_form_id ? [this.subFormCompletion.sub_form_id] : [])],
                  },
                })
                .then(({ data }) => {
                  this.subForms = keyBy(data, 'id');

                  if (this.subFormCompletion?.sub_form_id) {
                    this.subForm = this.subForms[this.subFormCompletion?.sub_form_id];
                  }
                });
            });
        })
        .catch((data) => moduleRecordErrorHandler(data, recordId));
    }

    beforeMount(): void {
      if (this.subFormCompletionId) {
        this.$api.getSubFormCompletion(this.subFormCompletionId, {}).then(({ data }) => {
          this.subFormCompletion = data;
          this.previousCompletionForm = this.getPreviousForm(data);
          if (data.record_id && data.record_type) {
            Promise.all([
              this.getModuleRecord(data.record_id, data.record_type),
              this.getSubFormCompletions(data.record_id, data.record_type),
            ]);
          }
        });
      } else if (this.recordId && this.recordType) {
        Promise.all([
          this.getModuleRecord(Number(this.recordId), this.recordType),
          this.getSubFormCompletions(this.recordId, this.recordType),
        ]);
      }
    }
  }
