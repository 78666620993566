
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import AdminReportColumnsRenameModal from './admin-report-columns-rename-modal.vue';
  import type { ReportBlob, ReportColumn } from '@app/models/super-report';

  @Component({
    components: {
      AdminReportColumnsRenameModal,
    },
  })
  export default class AdminReportColumnsRename extends Vue {
    @Prop(Object) readonly reportBlob!: ReportBlob;
    @Prop(Array) readonly reportColumns!: ReportColumn[];

    value = false;
  }
