
  import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
  import { camelCase, snakeCase, upperFirst } from 'lodash';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { Event, EventOptions, EventSelectableOptions, UpdateTarget } from '@app/models/event';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  import PlaceholderProfiles from './placeholder-profiles.vue';
  import { EVENTS_FORM_COMPONENTS } from './components';

  const EVENT_CREATE_MODULES = [
    'change_workflow',
    'update_record',
    'delete_record',
    'UpdateUser',
    'api_request',
    'RegulatoryReport',
    'SubFormCompletion',
    'RecordRelation',
    'RemoveRecordRelation',
    'NotificationMessage',
    'Activity',
    'UserLocationTag',
    'RemoveUserLocationTag',
    'UserInvolvement',
    'RemoveUserInvolvement',
    'Confidentiality',
    'RemoveConfidentialities',
    'RecordIndicator',
    'RemoveRecordIndicator',
    'Audit',
    'Observation',
    'Expensable::ApproveLineItem',
    'IncidentParticipant',
    'ApprovedExpense',
    'UpdateSubFormCompletionState',
  ];

  @Component({ components: { ...EVENTS_FORM_COMPONENTS, PlaceholderProfiles } })
  export default class FormFields extends Vue {
    @Prop(Object) readonly injectableArguments!: InjectableArguments;
    @Prop(Array) readonly dateOptions?: [string, string][];
    @Prop(Object) readonly fieldOptions?: EventSelectableOptions;
    @Prop(Array) readonly tags?: PlaceholderItem[];
    @Prop(Object) readonly target?: UpdateTarget;
    @Prop(String) readonly executionContext!: 'automation' | 'schedule';
    @PropSync('event') syncedEvent!: Event;
    @Prop({ type: Boolean, default: false }) readonly allowChained?: boolean;
    @Prop({ type: [Number, String], default: 0 }) readonly index?: number | string;

    get missingPlaceholderProfiles(): Partial<PlaceholderProfile>[] {
      const { placeholder_profile_handles: handles = [], placeholder_profiles: profiles = [] } = this.syncedEvent;
      return handles.map((handle) => profiles.find((pp) => pp.handle === handle) || { missing: true, handle });
    }

    get createModuleTemplate(): Maybe<string> {
      if (this.type) {
        if (EVENT_CREATE_MODULES.includes(this.type)) {
          return `${snakeCase(this.type)}`;
        } else if (this.type.startsWith('module_record')) {
          return 'module_record';
        } else if (this.type.startsWith('from_') || this.type.startsWith('to_') || String(Number(this.type)) == String(this.type)) {
          return 'sub_form_list';
        }
      } else {
        return undefined;
      }
    }

    get retries(): number {
      return this.syncedEvent?.retries || 0;
    }

    get enableRetries(): boolean {
      return this.syncedEvent?.enable_retries || false;
    }

    get notificationEmail(): string {
      return this.syncedEvent.notification_email || '';
    }

    get type(): Maybe<string> {
      return this.syncedEvent.event_type;
    }

    get values(): EventOptions | EventOptions['values'] {
      return ['api_request'].includes(this.type || '') ? this.syncedEvent?.options || {} : this.syncedEvent?.options?.values || {};
    }

    get per(): string {
      return this.syncedEvent?.per || '';
    }

    get conceptName(): string {
      return this.injectableArguments?.concept_name || '';
    }

    get eventFormComponentName(): Maybe<string> {
      if (this.type === 'Activity') return `ActionEventForm`;
      if (this.type === 'RemoveConfidentialities') return `RemoveConfidentialityEventForm`;
      if (this.createModuleTemplate != undefined) return `${upperFirst(camelCase(this.createModuleTemplate))}EventForm`;
    }
  }
