
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Acl } from '@app/models/acl';
  import type { ModuleName } from '@app/models/module-name';
  import type { TenantUser } from '@app/models/tenant-user';
  import { ACTIVITY_CONCEPT } from '@app/constants';

  interface ScopeFilterOption {
    key: 'mine' | 'team' | 'wider_team' | 'all' | 'created';
    label: string;
  }

  @Component({ components: { DsDropdown } })
  export default class ScopeFilter extends Vue {
    @Model('input') readonly value!: string;
    @Prop({ required: true, type: Object }) readonly moduleName!: Pick<ModuleName, 'name' | 'plural_display'> & { id?: number };
    @Prop({ required: true, type: Object }) readonly user!: TenantUser;
    @Prop(Object) readonly acl?: Acl;

    get defaultOption(): ScopeFilterOption {
      return this.options[this.options.length - 1];
    }

    get options(): ScopeFilterOption[] {
      return [
        {
          key: 'mine' as const,
          label: this.$t('app.labels.my_name', { name: this.modulePluralName }),
          visible: true,
        },
        {
          key: 'created' as const,
          label: this.$t('app.labels.actions_i_created'),
          visible: this.moduleName.name === ACTIVITY_CONCEPT,
        },
        {
          key: 'team' as const,
          label: this.$t('app.labels.my_team_name', { name: this.modulePluralName }),
          visible: this.canViewTeamRecords,
        },
        {
          key: 'wider_team' as const,
          label: this.$t('app.labels.extended_team', { name: this.modulePluralName }),
          visible: this.canViewOrganizationRecords,
        },
        {
          key: 'all' as const,
          label: this.$t('app.labels.all_accessible', { name: this.modulePluralName }),
          visible: true,
        },
      ].filter((option) => option.visible);
    }

    get modulePluralName(): string {
      return this.moduleName.plural_display || 'Records';
    }

    get managerialHierarchyAccessLevel(): number | undefined {
      if (
        !this.moduleName.id ||
        !this.user.role?.module_managerial_access ||
        !(this.moduleName.id in this.user.role?.module_managerial_access)
      ) {
        return this.user.role?.managerial_hierarchy_access_level;
      }
      return this.user.role?.module_managerial_access?.[this.moduleName.id];
    }

    get nullManagerialHierarchyAccessLevel(): boolean {
      return this.managerialHierarchyAccessLevel == null;
    }

    get canViewTeamRecords(): boolean {
      return this.nullManagerialHierarchyAccessLevel || (this.managerialHierarchyAccessLevel as number) >= 1;
    }

    get canViewOrganizationRecords(): boolean {
      return this.nullManagerialHierarchyAccessLevel || (this.managerialHierarchyAccessLevel as number) > 1;
    }

    get currentOption(): ScopeFilterOption | undefined {
      return this.options.find((option) => option.key === this.value);
    }

    get currentLabel(): string {
      return this.currentOption?.label || this.defaultOption.label;
    }

    beforeMount(): void {
      this.currentOption || this.$emit('input', this.defaultOption.key);
    }
  }
