
  import type { AxiosPromise } from 'axios';
  import { Tooltip } from 'uiv';
  import { Component } from 'vue-property-decorator';
  import DatePicker from '@app/components/date-picker.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import RecordSelector from '@app/components/record-selector.vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import bootbox from 'bootbox';
  import UserDetails from '@app/mixins/user-details';
  import { mixins } from 'vue-class-component';
  import { pick } from 'lodash';
  import type { ModuleEntity } from '@app/models/module-entity';
  import type { Password, TenantUser } from '@app/models/tenant-user';
  import type { WorkplaceIndustry } from '@app/models/workplace-industry';
  import type { Notification } from '@app/models/notification';
  import type { RestrictionDefault } from '@app/models/restriction-default';
  import { UserType } from '@app/models/tenant-user';
  import { AUTH_LOGIN_TYPES } from '@app/models/restriction-default';
  import type { ApiRequestConfig, DonesafeIndexApiOptions, IncludeOption } from '@app/services/donesafe-api-utils';
  import type { PartialNullBy } from '@app/utils/types/partial';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import UserAvatar from '../../user-avatar.vue';

  @Component({
    components: {
      DatePicker,
      EntitySelector,
      Select2,
      UserSelector,
      LocationSelector,
      OrganizationSelector,
      RecordSelector,
      ValidationProvider,
      ValidationObserver,
      Tooltip,
      UserAvatar,
    },
  })
  export default class UserDetailsPage extends mixins(UserDetails) {
    workplaceIndustries: Partial<WorkplaceIndustry>[] = [];
    kbActive = false;
    notifications: Pick<Notification, 'id'>[] = [];
    notificationsOn = false;
    defaultAuthRestrictions: RestrictionDefault[] = [];
    localUser: Nullable<TenantUser> = null;

    get activeNotificationIds(): number[] {
      return this.notifications.map(({ id }) => id);
    }

    get userTypeOptions(): [string, string][] {
      return [
        [UserType.ContactUser, this.$t('app.labels.contact')],
        [UserType.TenantUser, this.$t('app.labels.tenant')],
        [UserType.MedicalUser, this.$t('app.labels.medical_user')],
        [UserType.VisitorUser, this.$t('app.labels.visitor')],
        [UserType.ContractorUser, this.$t('app.labels.contractor')],
      ];
    }

    get newRecord(): boolean {
      return !this.user?.id;
    }

    get usersPage(): string {
      return '/admin/settings/users';
    }

    get showInvalidEmailWarning(): boolean {
      return this.user?.valid_email !== undefined && !this.user.valid_email;
    }

    get restrictedUser(): boolean {
      return this.defaultAuthRestrictions.filter((res) => res.restrict_on === this.form.type).length === AUTH_LOGIN_TYPES.length;
    }

    get isUserConfirmed(): boolean {
      return !!this.user?.confirmed_at;
    }

    get confirmUser(): boolean {
      return this.restrictedUser ? false : !(this.form.unconfirmed as boolean);
    }

    get validatePassword(): boolean {
      return this.newRecord && this.confirmUser;
    }

    get validatePasswordConfirmation(): boolean {
      return this.passwordChangeRequired && this.confirmUser;
    }

    get showPasswordField(): boolean {
      if (this.newRecord) {
        return this.confirmUser;
      }

      return !!this.user?.can_be_changed_password;
    }

    get passwordChangeRequired(): boolean {
      return !!this.form.password;
    }

    defaultTeachAdminOrPartner(user: TenantUser): boolean {
      return this.isDefaultTechAdmin(user) || this.isPartnerProxy(user);
    }

    isPartnerProxy(user: TenantUser): boolean {
      return !!user.partner_proxy;
    }

    async fetchDefaultRestrictions(cache = true): Promise<void> {
      const { data } = await this.$api.getRestrictionDefaults(
        {
          filters: { restriction: AUTH_LOGIN_TYPES, action: 'auth', restrict: true },
          only: ['id', 'restrict_on', 'restriction', 'restrict', 'action'],
        },
        { cache }
      );
      this.defaultAuthRestrictions = data;
    }

    async fetchUser(cache = true): Promise<void> {
      if (!this.userId) {
        this.user = {} as TenantUser;
        return Promise.resolve();
      }

      const { data } = await this.$api.getTenantUser(
        this.userId,
        {
          include: [
            'role',
            'manager',
            'home_location',
            'home_organization',
            'user_module_records',
            'can_be_changed_password',
            'internal',
            'avatar_url',
            'mfa_signed_in',
            'locked',
            'generated_email',
            'valid_email',
            'ancestor_ids',
          ],
        },
        { cache }
      );
      this.user = data;
    }

    onNotificationsOnChange(value: boolean): void {
      this.form = {
        ...this.form,
        notification_ids: value ? this.activeNotificationIds : [],
      };
    }

    getCompanyRegisters(options: DonesafeIndexApiOptions<ModuleEntity>, config?: ApiRequestConfig): AxiosPromise<ModuleEntity[]> {
      const params = { ...options, filters: { ...options.filters, record_type: 'CompanyRegister' } };
      return this.$api.getModuleEntities(params, config);
    }

    userEditPage(userId: number): string {
      return `/admin/settings/users/${userId}/edit`;
    }

    isDefaultTechAdmin(user: TenantUser): boolean {
      return !!user.technical_admin;
    }

    onAvatarUpload(avatar: string): void {
      this.form = {
        ...this.form,
        avatar,
      };
    }

    resetMfa(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('tenant.admin.users.sections.tab_details.confirm_reset_mfa'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.userId &&
            this.$api
              .resetTenantUserMfa(this.userId)
              .then(({ data }) => {
                toaster('User MFA has been reset');
                this.form = { ...this.form, mfa_signed_in: data.mfa_signed_in };
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    unlockUser(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('tenant.admin.users.sections.tab_details.confirm_unlock'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.userId &&
            this.$api
              .unlockTenantUser(this.userId)
              .then(({ data }) => {
                toaster('User has been unlocked');
                this.form = { ...this.form, locked: data.locked };
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    toggleActive(active: boolean): void {
      bootbox.confirm({
        size: 'small',
        message: active ? this.$t('app.labels.reactivate_user_confirmation') : this.$t('app.labels.deactivate_user_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.createUpdateTenantUser({ ...this.form, active: active });
        },
      });
    }

    async getWorkplaceIndustries(): Promise<void> {
      try {
        const { data } = await this.$api.getWorkplaceIndustries({ only: ['id', 'name'], filters: { active: true } }, { cache: true });
        this.workplaceIndustries = data;
      } catch {}
    }

    async getKnowledgeBaseState(): Promise<void> {
      const { data } = await this.$api.getModuleNames(
        {
          filters: {
            active: true,
            name: 'Procedure',
          },
        },
        { cache: true }
      );
      this.kbActive = data.length > 0;
    }

    async getActiveNotifications(): Promise<void> {
      const { data } = await this.$api.getNotifications({ only: ['id'] }, { cache: true });
      this.notifications = data;
    }

    createUpdateTenantUser(
      user: Partial<
        PartialNullBy<
          TenantUser,
          | 'primary_signature_id'
          | 'organization_ceiling_id'
          | 'location_ceiling_id'
          | 'manager_id'
          | 'employment_type_id'
          | 'workplace_industry_id'
          | 'gender_identity_id'
          | 'timezone'
          | 'start_date'
          | 'end_date'
          | 'date_of_birth'
        >
      > &
        Password & {
          avatar?: string;
          confirmed?: boolean;
          related_module_records?: Record<string, number | null>;
        }
    ): void {
      this.submitting = true;

      const userToUpdate = {
        ...pick(
          user,
          'title',
          'first_name',
          'last_name',
          'email',
          this.passwordChangeRequired ? 'password' : '',
          user.id === this.currentUserStore.data?.id ? 'current_password' : '',
          'phone',
          'role_id',
          'company_register_id',
          'position',
          'manager_id',
          'home_location_id',
          'home_organization_id',
          'location_ceiling_id',
          'organization_ceiling_id',
          'employment_type_id',
          'workplace_industry_id',
          'gender_identity_id',
          'start_date',
          'end_date',
          'date_of_birth',
          'mobile',
          'timezone',
          'language',
          'type',
          'admin',
          'internal',
          'can_change_password',
          'external_uuid',
          'payroll_identifier',
          'avatar',
          'active',
          'kb_acknowledgements',
          'notification_ids',
          'confirmed'
        ),
        related_module_records: this.pickRelatedRecords(user),
      };

      const include: IncludeOption = ['user_module_records', 'internal'];

      const promise = user?.id
        ? this.$api.updateTenantUser(user.id, { ...userToUpdate, include })
        : this.$api.createTenantUser({
            ...userToUpdate,
            active: true,
            include,
          });

      promise
        .then(({ data }) => {
          this.setExistingUserRelatedRecords(data);
          if (this.user?.active !== user.active) changeLocation(this.usersPage);
          if (!user?.id) {
            changeLocation(this.userEditPage(data.id));
          } else if (this.form.avatar != undefined || this.form.full_name !== data.full_name) {
            window.location.reload();
          } else {
            toaster('User has been updated');
            this.localUser = data;
          }
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => {
          this.submitting = false;
        });
    }

    deleteUserSignature(): void {
      const signatureId = this.form.primary_signature_id;
      if (!signatureId) {
        return;
      }
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.userId &&
            this.$api.updateTenantUser(this.userId, { primary_signature_id: null }).then(() => {
              this.$api.cache.clear();
              this.form = { ...this.form, primary_signature_id: undefined };
            });
        },
      });
    }

    validateTechAdminRoles(): Promise<void> {
      if (!this.form.id || this.form.internal || this.form.internal === this.localUser?.internal) return Promise.resolve();

      return new Promise<void>((resolve) => {
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.admin.settings.administrative_roles.tech_admin_remove_internal_warning'),
          buttons: {
            confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            result && resolve();
          },
        });
      });
    }

    submit(): void {
      this.validator?.validate().then((result) => {
        result &&
          this.validateTechAdminRoles().then(() =>
            this.createUpdateTenantUser({
              ...this.form,
              confirmed: this.confirmUser,
              manager_id: this.form.manager_id || null,
              organization_ceiling_id: this.form.organization_ceiling_id || null,
              location_ceiling_id: this.form.location_ceiling_id || null,
              employment_type_id: this.form.employment_type_id || null,
              workplace_industry_id: this.form.workplace_industry_id || null,
              gender_identity_id: this.form.gender_identity_id || null,
              timezone: this.form.timezone || null,
            })
          );
      });
    }

    beforeMount(): void {
      if (this.userGeneratedEmail) {
        this.form.email = '';
      }
      this.loading = true;
      Promise.all([
        this.fetchDefaultRestrictions(),
        this.fetchUser(),
        this.getRelatedModules(),
        this.getGenderIdentities(),
        this.getEmploymentTypes(),
        this.getWorkplaceIndustries(),
        this.getKnowledgeBaseState(),
        ...(this.newRecord ? [this.getActiveNotifications()] : []),
      ]).finally(() => {
        if (this.user) {
          this.initForm(this.user);
          this.checkHomeLocationCeiling(this.form.home_location_id);
          this.checkHomeOrganizationCeiling(this.form.home_organization_id);
          this.localUser = this.user;
        }
        this.loading = false;
      });
    }
  }
