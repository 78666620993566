
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { textColor } from '@app/utils/text-color';
  import type { ShowOptions } from '@app/models/module-name';
  import type { DisplayScore, DisplayScoringGroup, Score } from '@app/components/module-record/display-score';
  import { pickDisplayMethod, pickScoreBand } from '@app/components/module-record/display-score';

  @Component({ components: {} })
  export default class ModuleRecordScoreView extends Vue {
    @Prop(Object) showOptions!: ShowOptions;
    @Prop(Array) groups!: DisplayScoringGroup[];
    @Prop(Object) total!: Score;

    pickScoreBand = pickScoreBand;

    get displayScoringGroups(): { color: string; id: number; score: string; title: string }[] {
      return this.groups.map(
        ({
          id,
          title,
          displayMethod,
          score: {
            score: { score_numerator: n, score_denominator: d },
            scoreBand,
          },
        }) => {
          const { options: { show_band_color = false } = {} } = scoreBand?.profile || {};
          const sbm = scoreBand?.profile && { profile: scoreBand.profile, code: scoreBand.code };
          const { color = 'transparent' } = (displayMethod === 'score_band' && show_band_color && sbm && pickScoreBand(sbm)) || {};

          return { id, title, color, score: pickDisplayMethod(displayMethod, sbm)(n, d) };
        }
      );
    }

    get totalScore(): string {
      const { score_numerator: n, score_denominator: d } = this.total.score || {};

      return this.displayModuleScore()(n, d);
    }

    get totalScoreStyle(): object {
      const { show_total_scoring } = this.showOptions;
      const { color } = pickScoreBand(this.total.scoreBand) || {};

      const {
        options: { show_band_color },
      } = this.total.scoreBand?.profile || { options: {} };

      if (show_total_scoring !== 'score_band' || !show_band_color || !color) {
        return {};
      }

      return { backgroundColor: color, color: textColor(color) };
    }

    get showTotalScore(): boolean {
      const { show_total_scoring, show_scoring_groups } = this.showOptions;

      return show_scoring_groups || ['percent', 'fraction', 'score_band'].includes(show_total_scoring || '');
    }

    displayModuleScore(): DisplayScore {
      const {
        options: { show_band_color, show_band_label, show_score_as_percent, show_score_as_fraction },
      } = this.total.scoreBand?.profile || { options: {} };

      const { show_total_scoring } = this.showOptions;
      const printScore = show_band_color || show_band_label || show_score_as_percent || show_score_as_fraction;

      if (show_total_scoring === 'score_band' && !!this.total.scoreBand && !printScore) {
        return () => this.$t('app.labels.no_scoring_display_settings');
      }

      return pickDisplayMethod(show_total_scoring, this.total.scoreBand);
    }
  }
