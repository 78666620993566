import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type { RoleTabDetailsModuleNameOnly, RoleTabDetailsRoleOnly, CustomRightsForm } from './utils';
import { extendedPermissionsByConceptAndName, epOptionsWithLabels } from './utils';
import type { ExtendedPermission } from '@app/models/extended-permission';
import type { ModuleName } from '@app/models/module-name';
import type { Permission } from '@app/models/permission';
import type { PermissionProvider, Role } from '@app/models/role';
import { GlobalAccess } from '@app/models/extended-permission';
import type { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
import type { ListManager } from '@app/services/list-manager/list-manager';
import type { ListManagerField } from '@app/services/list-manager/types';

@Component
export default class BaseRoleTable extends Vue {
  @Prop(Object) readonly role!: Pick<Role, RoleTabDetailsRoleOnly>;
  @Prop(String) readonly title!: string;
  @Prop(Boolean) readonly disabled?: boolean;

  @Emit('update')
  update(params: CustomRightsForm): CustomRightsForm {
    return params;
  }

  manager: Nullable<ListManager<ModuleName> | ListManagerStatic<ModuleName>> = null;
  customiseRightsModal = false;

  moduleHasGlobalExtendedPermission(
    moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>,
    access: keyof typeof GlobalAccess
  ): boolean {
    const extendedPermissions = extendedPermissionsByConceptAndName(this.role.extended_permissions)[moduleName.name];
    return !!extendedPermissions?.[GlobalAccess[access]];
  }

  conceptOptionsMap(concept_name: string): Record<string, string> {
    const options = epOptionsWithLabels(concept_name, []);

    return options.reduce((memo, ep) => {
      return {
        ...memo,
        [ep.name]: ep.label,
      };
    }, {});
  }

  get moduleNameStrings(): string[] {
    return [];
  }

  get extendedPermissionsOptions(): Record<string, Record<string, string>> {
    return this.moduleNameStrings.reduce((acc, mn) => {
      return { ...acc, [mn]: this.conceptOptionsMap(mn) };
    }, {});
  }

  get baseFields(): ListManagerField[] {
    return [
      {
        title: this.$t('app.labels.module_name'),
        name: 'name',
        titleClass: 'justify-content-end',
        width: 'minmax(100px, 1fr)',
      },
    ];
  }

  get permissionsFields(): ListManagerField[] {
    return [
      {
        title: this.$t('tenant.admin.roles.rtb.can_create'),
        name: 'create',
        titleClass: 'justify-content-center',
        width: 'minmax(90px, 0.5fr)',
      },
      {
        title: '',
        name: 'relationship',
        titleClass: 'p-none',
        dataClass: 'p-none',
        width: 'minmax(400px, 2fr)',
      },
      {
        title: this.$t('tenant.admin.roles.rtb.special_permissions'),
        name: 'special_permissions',
        width: 'minmax(200px, 1fr)',
      },
      {
        title: '',
        name: 'edit',
        dataClass: 'text-center',
        width: 'minmax(50px, 0.5fr)',
      },
    ];
  }

  permissionProviderFor(conceptName: string): PermissionProvider {
    return this.role;
  }

  extendedPermissionTitlesFor(conceptName: string): string[] {
    return (
      this.permissionProviderFor(conceptName)
        .extended_permissions?.filter(({ concept_name }) => concept_name === conceptName)
        .map(({ name }) => this.extendedPermissionsOptions[conceptName][name])
        .sort() || []
    );
  }

  extendedPermissionsFor(conceptName: string): Pick<ExtendedPermission, 'concept_name' | 'name'>[] {
    return (
      this.permissionProviderFor(conceptName)
        .extended_permissions?.filter(({ concept_name }) => concept_name === conceptName)
        .map(({ concept_name, name }) => ({ concept_name, name })) || []
    );
  }

  permissionsFor(conceptName: string): Permission[] {
    return this.permissionProviderFor(conceptName).permissions?.filter(({ concept_name }) => concept_name === conceptName) || [];
  }
}
