
  import { groupBy, sortBy, uniq, debounce, dropWhile, trim } from 'lodash';
  import { Component, Emit, Model, Prop, Ref } from 'vue-property-decorator';
  import { VueAutosuggest } from 'vue-autosuggest';
  import { v4 as generateUUID } from 'uuid';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
  import { mixins } from 'vue-class-component';
  import WithPlaceholderMappings from '@app/mixins/with-placeholder-mappings';

  import { getItemSteps } from './utils';

  enum KeyboardEventKey {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    Enter = 'Enter',
    Tab = 'Tab',
  }

  interface SuggestionSection {
    data: Suggestion[];
    label: string;
    name: string;
  }

  interface SuggestionItem {
    item: Suggestion;
    label: string;
    liClass: string;
    name: string;
    type: string;
  }

  interface OptionsWithSteps extends PlaceholderItem {
    steps: string[];
  }

  interface Suggestion extends PlaceholderItem {
    description?: string;
    index?: number;
    nextSteps?: string;
    title?: string;
  }

  const PLACEHOLDER_MENU_TRIGGER = '@';

  @Component({
    components: { VueAutosuggest },
  })
  export default class AdvancedAutosuggest extends mixins(WithPlaceholderMappings) {
    @Model('input') readonly value!: Maybe<string>;
    @Ref() readonly autosuggest!: InstanceType<typeof VueAutosuggest>;

    @Prop(Array) readonly options!: PlaceholderItem[];
    @Prop({ type: String, default: () => 'input' }) readonly type!: 'input' | 'textarea';
    @Prop({ type: Boolean, default: () => true }) readonly trim!: boolean;
    @Prop({ type: String, default: () => generateUUID() }) readonly autoSuggestUuid!: string;
    @Prop(String) readonly placeholder?: string;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly required?: boolean;
    @Prop(String) readonly inputClass?: string;
    @Prop(String) readonly inputId?: string;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(String) readonly dataParsleyErrorsContainer?: string;
    @Prop(String) readonly rows?: string;
    @Prop(Boolean) readonly overrideTabBehaviour?: boolean;

    comboboxLabel = 'Advanced Autosuggest';
    // highlighted item in the results menu
    highlighted: Nullable<SuggestionItem> = null;
    // a string based on the 'value' and 'position'
    // updated each time 'recalculateCurrentSelectionSteps' is called
    editablePlaceholderInput = '';
    // almost the same as 'editablePlaceholderInput' but saved as an array and without 'PLACEHOLDER_MENU_TRIGGER'
    currentSelectionSteps: string[] = [];
    // current position of the cursor in the input
    position = 0;
    // this is the same as position only it's not going to be updated when user navigates with 'left' and 'right' arrow keys
    // unless text selection cursor at the end of the editablePlaceholderInput
    endPlaceholderPosition = 0;

    debouncedOnInput = debounce(this.onInput, 50);
    hasUserInteracted = false;

    get inputProps() {
      return {
        placeholder: this.placeholder || this.$t('app.labels.value_or_placeholder'),
        class: `form-control ${this.inputClass || ''}`,
        id: this.inputId,
        name: this.name,
        required: this.required,
        disabled: this.disabled,
        dataParsleyErrorsContainer: this.dataParsleyErrorsContainer,
        is: this.type,
        position: this.position,
        rows: this.rows,
      };
    }

    get showNoMatchFound(): boolean {
      return (
        this.caretAtTheEnd &&
        this.allSuggestions.length === 0 &&
        !this.allValues.includes(this.currentSelectionString) &&
        !this.isDynamicPath
      );
    }

    get isDynamicPath() {
      return !!this.currentDynamicPath;
    }

    get currentDynamicPath(): Maybe<PlaceholderItem> {
      const path = this.currentSelectionSteps.join('');
      return this.options.filter(({ dynamic }) => dynamic).find((o) => path.startsWith(o.val));
    }

    get dynamicSuggestions(): OptionsWithSteps[] {
      if (!this.currentDynamicPath) {
        return [];
      }

      return this.dynamicSuggestionsFor(this.currentDynamicPath);
    }

    get lastStep(): Maybe<string> {
      return this.currentSelectionSteps.slice(-1)[0];
    }

    get previousStep(): Maybe<string> {
      return this.currentSelectionSteps.slice(-2)[0];
    }

    get hasNextStepOptions(): boolean {
      return this.localOptions.some((i) => !!i.steps[this.currentStep + 1]);
    }

    get caretAtTheEnd(): boolean {
      return (
        this.position - this.startingPosition === this.editablePlaceholderInput.length &&
        (!this.value?.[this.position] || this.value?.[this.position] === ' ')
      );
    }

    get startingPosition(): number {
      return this.value?.substring(0, this.position)?.lastIndexOf(this.editablePlaceholderInput) || 0;
    }

    get currentSelectionString(): string {
      return this.currentSelectionSteps.join('');
    }

    get currentStep(): number {
      return this.currentSelectionSteps?.length ? this.currentSelectionSteps.length - 1 : 0;
    }

    get allSuggestions(): Suggestion[] {
      return sortBy(
        this.suggestions.reduce((acc, cur) => {
          return [...acc, ...cur.data];
        }, [] as Suggestion[]),
        'index'
      );
    }

    get allValues(): string[] {
      if (!this.hasUserInteracted) return [];

      return this.options.filter(({ group, val }) => !group && !val.endsWith('.')).map(({ val }) => val);
    }

    get suggestions(): SuggestionSection[] {
      return [
        {
          name: 'main',
          label: '',
          data: sortBy(Object.keys(this.distinctLocalOptionsSteps).map(this.getFullSuggestion), 'index'),
        },
      ];
    }

    get distinctLocalOptionsSteps(): Record<string, OptionsWithSteps[]> {
      return groupBy(
        [...this.localOptions, ...this.dynamicSuggestions].filter((i) => !!i.steps[this.currentStep]),
        (i) => i.steps[this.currentStep]
      );
    }

    get distinctStepsOnly(): string[] {
      return Object.keys(this.distinctLocalOptionsSteps);
    }

    get localNonHiddenOptionsWithSteps() {
      if (!this.hasUserInteracted) return [];

      return this.options
        .filter(({ hidden }) => !hidden)
        .map((i) => {
          return { ...i, steps: getItemSteps(i.val) };
        });
    }

    get localOptions(): OptionsWithSteps[] {
      return this.localNonHiddenOptionsWithSteps.filter((i) => {
        if (!!this.currentSelectionString) {
          const upToLastStep = this.currentSelectionSteps.slice(0, this.currentStep).join('');
          const currentStep = this.currentSelectionSteps[this.currentStep].toLowerCase();

          return (
            // return all options that starts with the same string excluding the current step
            i.val.startsWith(upToLastStep) &&
            // and search for the matches in 'meta' if it's item's last step
            ((i.steps.length === this.currentStep + 1 && i.meta?.toLowerCase()?.includes(currentStep)) ||
              // or search for match in current step of the item
              i?.steps?.[this.currentStep]?.toLowerCase()?.includes(currentStep))
          );
        } else {
          return true;
        }
      });
    }

    @Emit('input')
    updateValue(value: string) {
      return value;
    }

    dynamicSuggestionsFor(dynamicNode: PlaceholderItem): OptionsWithSteps[] {
      return this.options
        .filter(({ val }) => val.startsWith(dynamicNode.val) && val !== dynamicNode.val)
        .map((item) => ({ ...item, val: this.injectDynamicStep(item.val) }))
        .map((item) => ({ ...item, steps: getItemSteps(item.val) }));
    }

    injectDynamicStep(path: string) {
      const steps = getItemSteps(path);

      const tail = dropWhile(steps, (step, i) => {
        if (step === '|' && this.lastStep === '' && !this.previousStep?.endsWith('.')) {
          return true;
        }

        return trim(this.currentSelectionSteps[i], '.') === trim(step, '.');
      });

      return [...this.currentSelectionSteps, ...tail].join('');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSuggestionValue(item: SuggestionItem): Maybe<string> {
      // should always return 'value'
      return this.value;
    }

    shouldRenderSuggestions(): boolean {
      return (
        // have to start with '@'
        this.editablePlaceholderInput.startsWith(PLACEHOLDER_MENU_TRIGGER) &&
        // if there are no other options and there is a match then do not show suggestions
        (this.allSuggestions.length > 1 || !this.allValues.includes(this.currentSelectionString)) &&
        // must have other steps values than only '.' or '|'
        (!this.distinctStepsOnly.length || !!this.distinctStepsOnly.filter((i) => !['.', '|'].includes(i)).length) &&
        // 'lastStep' either empty string or string and 'lastStep' not in 'distinctStepsOnly' or there are more than one suggestion available
        (((this.lastStep === '' || this.lastStep) && (!this.distinctStepsOnly.includes(this.lastStep) || this.allSuggestions.length > 1)) ||
          // or there are next step options available
          this.hasNextStepOptions ||
          // or no match found
          this.showNoMatchFound)
      );
    }

    updateElementPosition(): void {
      if (typeof this.autosuggest?.$refs?.textComponent?.selectionStart === 'number') {
        this.updatePosition(this.autosuggest.$refs.textComponent.selectionStart, true);
      }
    }

    recalculateCurrentSelectionSteps(value: Maybe<string>, clickEvent = false, highlightFirst = false): void {
      // split on any whitespace (including newlines)
      const stringsUpToPosition = value?.substring(0, this.position).split(/\s+/);
      const last = stringsUpToPosition?.slice(-1)[0];

      if (last?.startsWith(PLACEHOLDER_MENU_TRIGGER)) {
        this.editablePlaceholderInput = last;
        const steps = getItemSteps(this.editablePlaceholderInput.substring(1));
        const lastStep = steps.slice(-1)[0];
        const specialPlaceholderKeys = ['q', 'dataq'];
        const addStep =
          // add a step if there are more options or if there is a match and it was clicked or selected and
          ((this.hasNextStepOptions || (clickEvent && this.allValues.includes(this.editablePlaceholderInput.substring(1)))) &&
            // if the 'distinctStepsOnly' has only 1 value and it is 'lastStep'
            ((this.distinctStepsOnly.length === 1 && !!lastStep && this.distinctStepsOnly.includes(lastStep)) ||
              // or 'lastStep' does not equal to empty string already and it was clicked
              (lastStep != '' && clickEvent) ||
              // or 'last' string only contains trigger
              last === PLACEHOLDER_MENU_TRIGGER)) ||
          // or it's not a click event and 'lastStep' either one of the 'specialPlaceholderKeys' keys, '.',  '|' or ends with the dot character
          (!clickEvent && (specialPlaceholderKeys.includes(lastStep) || ['.', '|'].includes(lastStep) || lastStep?.endsWith('.')));

        this.currentSelectionSteps = [...steps, ...(addStep ? [''] : [])];

        // this is to correctly update highlighted item in autosuggest to the first item
        if (highlightFirst) {
          const { setCurrentIndex, setChangeItem } = this.autosuggest;
          setCurrentIndex(0);
          setChangeItem({ item: this.allSuggestions[0] }, true);
        }
      } else {
        this.editablePlaceholderInput = '';
        this.currentSelectionSteps = [];
      }
    }

    updateEndPlaceholderPosition(newPosition: number): void {
      this.endPlaceholderPosition = newPosition;
    }

    updatePosition(newPosition: number, updateEndPosition?: boolean): void {
      this.position = newPosition;
      if (updateEndPosition) this.updateEndPlaceholderPosition(newPosition);
    }

    // this is needed to avoid the selection being reset to the end of the input
    focusOnInput(): void {
      const input = this.autosuggest?.$refs?.textComponent;
      this.$nextTick(() => {
        input.selectionStart = this.position;
        input.selectionEnd = this.position;
        input.focus();
      });
    }

    // event handlers

    onKeyDownTab(event: KeyboardEvent): void {
      if (this.autosuggest.isOpen || this.overrideTabBehaviour) event.preventDefault();
      if (!this.autosuggest.isOpen) this.$emit('valid-keydown-tab', event);
    }

    onKeyUp(e: KeyboardEvent): void {
      const { key, target } = e;
      const arrowKeys = [KeyboardEventKey.ArrowLeft, KeyboardEventKey.ArrowRight];
      const actionKeys = [KeyboardEventKey.Tab, KeyboardEventKey.Enter];
      const currentPosition = (target as HTMLInputElement)?.selectionStart;
      const isArrowKeyPressed = arrowKeys.includes(key as KeyboardEventKey);
      const isActionKeyPressed = this.autosuggest.isOpen && actionKeys.includes(key as KeyboardEventKey);
      const currentPositionDefined = typeof currentPosition === 'number';

      if (isArrowKeyPressed && currentPositionDefined) {
        this.updatePosition(currentPosition);
        this.caretAtTheEnd && this.updateEndPlaceholderPosition(currentPosition);
        this.recalculateCurrentSelectionSteps(this.value, false);
      }

      if (isActionKeyPressed) {
        e.preventDefault();
        this.highlighted && this.onSelected(this.highlighted, true);
      }
    }

    onItemChanged(suggestion: SuggestionItem): void {
      this.highlighted = suggestion;
    }

    onClick(): void {
      if (!this.hasUserInteracted) {
        this.hasUserInteracted = true;
      }

      this.updateElementPosition();
      this.recalculateCurrentSelectionSteps(this.value);
    }

    onSelected(suggestion: SuggestionItem, setChangeItem = false): void {
      if (suggestion?.item) {
        // calculate the starting position of the last step
        const startingPosition = this.position - this.currentSelectionSteps[this.currentStep].length;

        const newInput =
          (this.value?.substr(0, startingPosition) || '') + suggestion.item.val + (this.value?.substr(this.endPlaceholderPosition) || '');
        this.updateValue(newInput);
        this.updatePosition(startingPosition + suggestion.item.val.length, true);
        this.recalculateCurrentSelectionSteps(newInput, true, setChangeItem);
        this.focusOnInput();
      }
    }

    onClickOutside(): void {
      if (this.trim && !!this.value) {
        this.updateValue(this.value.trim());
      }
    }

    onInput(value?: string): void {
      this.updateElementPosition();
      this.updateValue(value || '');
      this.recalculateCurrentSelectionSteps(value);
    }

    onFocus(_e: FocusEvent): void {
      if (!this.hasUserInteracted) {
        this.hasUserInteracted = true;
      }
    }

    getFullSuggestion(step: string): Suggestion {
      const stepObjects = this.distinctLocalOptionsSteps[step];
      const nextSteps =
        uniq(stepObjects.map((i) => i.steps[this.currentStep + 1]).filter(Boolean))
          .filter((i) => ['.', '|'].includes(i))
          .map((i) => `( ${i} )`)
          .join('') || '';

      const mappedItem = this.placeholderMappings.map((item, index) => ({ ...item, index })).find(({ key }) => key === step) || {};

      return {
        val: step,
        ...(mappedItem ? mappedItem : {}),
        meta: !stepObjects?.[0]?.steps?.[this.currentStep + 1] ? stepObjects?.[0]?.meta || '' : '',
        nextSteps: nextSteps || '',
      };
    }

    mounted(): void {
      this.$el.querySelector("[role='combobox']")?.setAttribute('aria-label', this.comboboxLabel);
    }
  }
