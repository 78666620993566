
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import { RELATIONSHIP_PREFIX } from '@app/constants';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import { get } from 'lodash';
  import bootbox from 'bootbox';
  import { mixins } from 'vue-class-component';
  import { Component, Ref } from 'vue-property-decorator';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RelationForeignKey } from '@app/models/record-relation';
  import type { Relationship } from '@app/models/relationship';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import { RecordRelationMode } from '@app/models/record-relation';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { buildLink } from '@app/utils/build-link';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { textColor } from '@app/utils/text-color';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  import RecordSelector from '../record-selector.vue';
  import ModuleRecordsTable from '../module-record/module-records-table.vue';
  import BaseTableCell from '../base-table/base-table-cell.vue';

  type RelationModuleNameKey = 'from_module_name' | 'to_module_name';

  @Component({ components: { RecordSelector, DsModal, ModuleRecordsTable, BaseTableCell, DsDropdown } })
  export default class RelationshipTab extends mixins(SubFormListBase, ModuleRecordModals) {
    @Ref() readonly moduleRecordsTable!: InstanceType<typeof ModuleRecordsTable>;

    relationship: Nullable<Relationship> = null;
    recordRelationModalVisible = false;
    recordRelationsHash: Record<number, number> = {};
    defaultTemplate: Nullable<Pick<DefaultTemplate, 'id' | 'system_code'>> = null;

    search = '';

    saving = false;

    selectedRecordIds: string[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get recordLinkBehaviour(): RecordLinkBehaviour {
      return this.subFormList.options?.record_link_behaviour || RecordLinkBehaviour.GoToRecord;
    }

    get addNewButtonStyles(): string {
      if (this.subFormList.add_background_color) {
        return `
          color: ${textColor(this.subFormList.add_background_color)} !important;
          background-color: ${this.subFormList.add_background_color} !important;
        `;
      }
      return '';
    }

    get indexOptions(): string[] {
      return (
        (this.subFormList.relationship_options.index_options?.length && this.subFormList.relationship_options.index_options) || [
          'uniq_id',
          'title',
          'state',
        ]
      );
    }

    get panelTitle(): string {
      return this.subFormList.title;
    }

    get mode(): RecordRelationMode {
      return this.subFormList.relationship_options.relationship_mode!;
    }

    get invertedMode(): RecordRelationMode {
      return this.mode === RecordRelationMode.from ? RecordRelationMode.to : RecordRelationMode.from;
    }

    get linkFirstColumn(): boolean {
      return this.subFormList.relationship_options?.link_first_column === 'true';
    }

    get foreignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'from_id' : 'to_id';
    }

    get relatedForeignKey(): RelationForeignKey {
      return this.mode === RecordRelationMode.from ? 'to_id' : 'from_id';
    }

    get relatedModuleNameKey(): RelationModuleNameKey {
      return this.mode === RecordRelationMode.from ? 'to_module_name' : 'from_module_name';
    }

    get allowSearch(): boolean {
      return this.subFormList.relationship_options?.allow_search === 'true';
    }

    get showHeaderRightSection(): boolean {
      return this.allowSearch || this.showAddOrSearch;
    }

    get showDropDown(): boolean {
      return !!this.editable && this.showAddNewButton && this.showSelectButton;
    }
    get showAddOrSearch(): boolean {
      return !!this.editable && (this.showAddNewButton || this.showSelectButton);
    }

    get showAddNewButton(): boolean {
      if (this.subFormList.relationship_options?.allow_add_new !== 'true') {
        return false;
      }
      const moduleName = get(this.relationship, [this.relatedModuleNameKey, 'name']);
      return !!(moduleName && get(this.currentUserStore.data?.acl, ['module', moduleName, 'create']));
    }

    get showSelectButton(): boolean {
      return this.subFormList.relationship_options?.allow_select === 'true';
    }

    get sort(): string | undefined {
      return this.subFormList.relationship_options?.sort || this.indexOptions[0];
    }

    get reverse(): boolean {
      return this.subFormList.relationship_options?.sort ? this.subFormList.relationship_options.reverse === 'true' : true;
    }

    get tableFilters(): DonesafeFilterOptions<ModuleRecord, { _do_not_fetch?: boolean }> {
      if (!this.relationship || !this.relationship[this.relatedModuleNameKey]) {
        return {};
      }

      const filters = this.subFormList.relationship_options?.filters;

      const baseFilter = {
        module_name_id: this.relationship[this.relatedModuleNameKey]?.id,
        ...convertInFormFilters(filters, { user: this.currentUserStore.data }),
      };

      return { ...baseFilter, [`${RELATIONSHIP_PREFIX}${this.invertedMode}:${this.relationship.code}`]: this.record.id };
    }

    get extraFields(): ListManagerField[] {
      return !this.archivable ? [] : [{ title: '', name: 'remove_relation' }];
    }

    get requiredSelectFilters(): DonesafeFilterOptions<ModuleRecord> {
      const moduleNameId = (this.relationship as Relationship)[this.relatedModuleNameKey]?.id;
      return { module_name_id: moduleNameId };
    }

    get selectFilters(): DonesafeFilterOptions<ModuleRecord> {
      const filters = this.subFormList.relationship_options?.filters_select;

      return {
        ...convertInFormFilters(filters, { user: this.currentUserStore.data }),
        [`!${RELATIONSHIP_PREFIX}${this.invertedMode}:${this.relationship?.code}`]: this.record.id,
      };
    }

    get openInModal(): boolean {
      return this.subFormList.relationship_options?.create_main_form_in_modal === 'true';
    }

    get addNewRelatedRecordUrl(): string | undefined {
      if (this.relationship) {
        const params = {
          main_form_id: this.record.id,
          module_name_id: this.relationship[this.relatedModuleNameKey]?.id,
          relationships: { [this.relationship.code]: this.record.id },
          default_template_id: this.defaultTemplate?.system_code,
        };

        return buildLink('/module_records/new', params);
      }
    }

    addNewRelation(event: MouseEvent): void {
      // call this.fetchData() manually
      // as subscribe-index-notifications update from ModuleRecordIndexNotificationsChannel does not reload properly

      if (this.relationship && this.addNewRelatedRecordUrl) {
        this.openModalOrLink({
          modal: this.openInModal,
          link: this.addNewRelatedRecordUrl,
          event,
          modalProps: {
            mode: 'module-record-edit',
            moduleNameId: this.relationship?.[this.relatedModuleNameKey]?.id,
            mainFormId: this.record.id,
            relationships: { [this.relationship.code]: this.record.id },
            defaultTemplateId: this.defaultTemplate?.system_code,
            title: this.moduleRecordEditTitle(this.relationship?.[this.relatedModuleNameKey]?.display),
            listeners: { 'record-created': this.reloadTable },
          },
        }).then(this.reloadTable);
      }
    }

    reloadTable(): void {
      this.$api.cache.clear();
      this.moduleRecordsTable.refreshTable();
    }

    submitForm(): void {
      if (!this.saving && this.record && this.relationship) {
        const recordId = this.record.id;
        const relationship_code = this.relationship.code;
        const createdIds: number[] = [];
        const promises = this.selectedRecordIds.map((relatedId) => {
          return this.$api
            .createRecordRelation({
              [this.relatedForeignKey]: relatedId,
              [this.foreignKey]: recordId,
              relationship_code,
            })
            .then(({ data }) => createdIds.push(data[this.relatedForeignKey]))
            .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
        });
        this.saving = true;
        Promise.all(promises)
          .then(() => {
            const newSelectedIds = this.selectedRecordIds.filter((id) => !createdIds.includes(parseInt(id)));
            this.selectedRecordIds.length !== newSelectedIds.length && this.reloadTable();
            this.selectedRecordIds = newSelectedIds;
            !this.selectedRecordIds.length && this.$nextTick(() => (this.recordRelationModalVisible = false));
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }

    onRecordsFetch(records: ModuleRecord[]): void {
      if (this.relationship && records.length) {
        this.$api
          .getRecordRelations(
            {
              filters: {
                relationship_code: this.relationship.code,
                [this.foreignKey]: this.record.id,
                [this.relatedForeignKey]: records.map((record) => record.id),
              },
              only: ['id', this.relatedForeignKey],
              per_page: -1,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.recordRelationsHash = data.reduce((memo, rr) => ({ ...memo, [rr[this.relatedForeignKey]]: rr.id }), {});
          });
      }
    }

    removeRelation(recordRelationId: number): void {
      if (!this.archivable) {
        return;
      }
      const message = this.$t('app.labels.confirm_remove_relation_question');
      bootbox.confirm({
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.saving = true;
            this.$api
              .deleteRecordRelation(recordRelationId)
              .then(this.reloadTable)
              .finally(() => {
                this.saving = false;
              });
          }
        },
      });
    }

    selectNewRelation(): void {
      this.recordRelationModalVisible = true;
    }

    beforeMount(): void {
      if (this.subFormList.default_template_id) {
        this.$api
          .getDefaultTemplates({ filters: { id: this.subFormList.default_template_id }, only: ['id', 'system_code'] }, { cache: true })
          .then(({ data }) => {
            this.defaultTemplate = data[0] || null;
          });
      }
      if (this.subFormList.relationship_options?.relationship_code) {
        this.$api
          .getRelationships({
            filters: { code: this.subFormList.relationship_options.relationship_code },
            only: [
              'id',
              'name',
              'code',
              'from_module',
              'to_module',
              { to_module_name: ['id', 'name', 'display'] },
              { from_module_name: ['id', 'name', 'display'] },
            ],
          })
          .then(({ data }) => {
            this.relationship = data[0];
          });
      }
    }
  }
