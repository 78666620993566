
  import DsTextInput from '@app/components/ds-text-input.vue';
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import type { ModuleTab } from '@app/models/module-tab';

  @Component({ components: { DsTextInput, Select2, ValidationProvider, ValidationObserver } })
  export default class ModuleTabForm extends Vue {
    @Prop(Object) readonly moduleTab!: Partial<ModuleTab>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<ModuleTab> = {};

    beforeMount(): void {
      this.form = { ...this.moduleTab };
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }
  }
