
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import moment from 'moment';
  import Select2 from '@app/components/select2.vue';
  import DatePicker from '@app/components/date-picker.vue';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import type { DateRangeFilterOption, SimpleOption } from '../model';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { DateRangeFilterValue } from '@app/models/configurator-filter';
  import { ConfiguratorFilterOperator, ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  type Mode = 'from' | 'to';

  // TODO remove parsley stuff once widgets are migrated to vee-validate
  @Component({ components: { Select2, DatePicker, DateTimePicker, FormField } })
  export default class DateRangeFilterValueRow extends Vue {
    @Model('input') readonly value?: DateRangeFilterValue;

    @Prop() readonly option!: DateRangeFilterOption;
    @Prop(Number) readonly index!: number;
    @Prop(String) readonly name!: string;

    @Emit('input')
    updateInput(newValue: DateRangeFilterValue[]) {
      return newValue;
    }

    form: DateRangeFilterValue = {};

    get accountStore() {
      return useAccountStore();
    }

    get startDate(): Maybe<Date> {
      if (this.mode === 'from' || !this.otherValue?.value || this.otherValue.source !== ConfiguratorFilterSource.date_select) {
        return undefined;
      } else {
        return this.dateValue(this.otherValue?.value);
      }
    }

    get endDate(): Maybe<Date> {
      if (this.mode === 'to' || !this.otherValue?.value || this.otherValue.source !== ConfiguratorFilterSource.date_select) {
        return undefined;
      } else {
        return this.dateValue(this.otherValue?.value);
      }
    }

    get otherValue(): Maybe<DateRangeFilterValue> {
      return this.option.value?.[this.otherIndex];
    }

    get required(): boolean {
      return !!this.value?.value || !this.otherValue?.value;
    }

    get namePrefix(): string {
      return `${this.name}[value][]`;
    }

    get optionName(): string {
      return `${this.namePrefix}[value]`;
    }

    get userOptions(): SimpleOption[] {
      return [
        ...(this.accountStore.data.display_employment_start_date ? [{ id: 'start_date', title: 'Start Date' }] : []),
        ...(this.accountStore.data.display_employment_separation_date ? [{ id: 'end_date', title: 'End Date' }] : []),
        ...(this.accountStore.data.display_date_of_birth ? [{ id: 'date_of_birth', title: 'Birth Date' }] : []),
      ];
    }

    get valueOptions(): SimpleOption[] {
      switch (this.form.source) {
        case ConfiguratorFilterSource.current_user:
          return this.userOptions;
        case ConfiguratorFilterSource.question_id:
          return this.option.dynamicOptions || [];
        case ConfiguratorFilterSource.question_code:
          return this.option.dynamicCodeOptions || [];
        default:
          return [];
      }
    }

    get format(): Maybe<string> {
      const formats: Record<'date' | 'datetime', Maybe<string>> = {
        date: this.accountStore.data.datetimepicker_date_format,
        datetime: this.accountStore.data.datetimepicker_datetime_format,
      };
      return formats[this.option.type] || undefined;
    }

    get dateType(): boolean {
      return this.option.type === 'date' && this.form.source === ConfiguratorFilterSource.date_select;
    }

    get dateTimeType(): boolean {
      return this.option.type === 'datetime' && this.form.source === ConfiguratorFilterSource.date_select;
    }

    get sourceOptions(): { label: string; value: string }[] {
      const baseOptions = [
        {
          value: ConfiguratorFilterSource.date_select,
          label: 'Date Select',
        },
        {
          value: ConfiguratorFilterSource.question_id,
          label: 'Form values',
        },
        {
          value: ConfiguratorFilterSource.question_code,
          label: 'Form values (Code Matched)',
        },
      ];
      return [
        ...baseOptions,
        ...(this.accountStore.data.display_date_of_birth ||
        this.accountStore.data.display_employment_start_date ||
        this.accountStore.data.display_employment_separation_date
          ? [
              {
                value: ConfiguratorFilterSource.current_user,
                label: 'Current user attributes',
              },
            ]
          : []),
      ];
    }

    get defaultOperator(): Record<Mode, ConfiguratorFilterOperator> {
      return {
        from: ConfiguratorFilterOperator.greater_than_or_equal,
        to: ConfiguratorFilterOperator.less_than_or_equal,
      };
    }

    get mode(): Mode {
      return this.index === 0 ? 'from' : 'to';
    }

    get otherIndex(): 0 | 1 {
      return this.index === 0 ? 1 : 0;
    }

    get operatorOptions(): Record<Mode, { description: string; label: ConfiguratorFilterOperator; value: ConfiguratorFilterOperator }[]> {
      return {
        to: [
          { value: ConfiguratorFilterOperator.less_than, label: ConfiguratorFilterOperator.less_than, description: 'Less Than' },
          {
            value: ConfiguratorFilterOperator.less_than_or_equal,
            label: ConfiguratorFilterOperator.less_than_or_equal,
            description: 'Less Than or Equal To',
          },
        ],
        from: [
          { value: ConfiguratorFilterOperator.greater_than, label: ConfiguratorFilterOperator.greater_than, description: 'Greater Than' },
          {
            value: ConfiguratorFilterOperator.greater_than_or_equal,
            label: ConfiguratorFilterOperator.greater_than_or_equal,
            description: 'Greater Than or Equal To',
          },
        ],
      };
    }

    onDateChange(date: Date | undefined): void {
      const dateString = date && moment(date).format(this.format);
      this.form = { ...this.form, value: dateString };
      this.onDateRangeValueUpdate(this.form);
    }

    dateValue(value: Maybe<string>): Maybe<Date> {
      return (!!value && moment(value, this.format).toDate()) || undefined;
    }

    dynamicKey(mode: ConfiguratorFilterSource): 'id' | 'code' | undefined {
      switch (mode) {
        case ConfiguratorFilterSource.current_user:
        case ConfiguratorFilterSource.question_id:
          return 'id';
        case ConfiguratorFilterSource.question_code:
          return 'code';
        default:
          return undefined;
      }
    }

    operatorTemplateResult(option: { id: string; text: string }): JQuery {
      const fullOption = this.operatorOptions[this.mode].find((i) => i.value === option.id);

      return select2ResponseTemplate(fullOption, {
        primaryAttribute: (option: { description: string; label: ConfiguratorFilterOperator; value: ConfiguratorFilterOperator }) => {
          return `${option.label} (${option.description})`;
        },
      });
    }

    onValueUpdate(newValue: string, key: string): void {
      this.form = { ...this.form, value: key === 'source' ? undefined : this.form.value, [key]: newValue };
      this.onDateRangeValueUpdate(this.form, key === 'source');
    }

    onDateRangeValueUpdate(value: Partial<DateRangeFilterValue>, clearValue = false): void {
      let newDateRangeValue: DateRangeFilterValue[] = [];
      newDateRangeValue.splice(this.index, 0, { ...value, value: clearValue ? undefined : value.value });
      if (!!this.otherValue) newDateRangeValue.splice(this.otherIndex, 0, this.otherValue);
      this.updateInput(newDateRangeValue);
    }

    beforeMount(): void {
      this.form = {
        required: this.value?.required || 'false',
        operator: this.value?.operator || this.defaultOperator[this.mode],
        source: this.value?.source || ConfiguratorFilterSource.date_select,
        value: this.value?.value,
      };
    }
  }
