import { isValidUrl } from '@app/utils/url';
import type { ValidationRule } from 'vee-validate/dist/types/types';

const urlRule: ValidationRule = {
  validate(value: string | null | undefined) {
    if (value) {
      return isValidUrl(value);
    }

    return false;
  },
  message: 'The URL provided is invalid',
};

export default urlRule;
