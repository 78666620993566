import { render, staticRenderFns } from "./module-name-left-hand-bar.vue?vue&type=template&id=0aebc19f&scoped=true&lang=pug"
import script from "./module-name-left-hand-bar.vue?vue&type=script&lang=ts"
export * from "./module-name-left-hand-bar.vue?vue&type=script&lang=ts"
import style0 from "./module-name-left-hand-bar.vue?vue&type=style&index=0&id=0aebc19f&prod&lang=scss&scoped=true"
import style1 from "./module-name-left-hand-bar.vue?vue&type=style&index=1&id=0aebc19f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aebc19f",
  null
  
)

export default component.exports