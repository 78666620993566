
  import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import type { CurrentTab, ElementType, Formula, RuleBuilderModes, TemplateItemObject, VariableMap } from '../models/types';
  import LogicElement from '../components/logic-element.vue';
  import VariableInfo from '../components/variable-info.vue';
  import { findIndex, isString } from 'lodash';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: { Draggable, LogicElement, VariableInfo } })
  export default class DropLayer extends Vue {
    @PropSync('logicElements') syncedLogicElements!: { id: number | string; value: Formula }[];
    @Prop({ required: true }) readonly componentTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly ruleTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly variableTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly updateElementValue!: (logicElementId: string, newValue: string) => void;
    @Prop({ required: true }) readonly getElementType!: (logicElementValue: string | Formula) => ElementType;
    @Prop() readonly isVariableTab?: boolean;
    @Prop({ required: true }) readonly variableMapping!: VariableMap;
    @Prop({ required: true }) readonly currentTab!: CurrentTab;
    @Prop({ required: true }) readonly calcValueSubFormQuestions!: SubFormQuestion[];
    @Prop({ required: true }) readonly switchPage!: (mode: RuleBuilderModes) => void;

    @Emit('update-dragging')
    updateDragging(state: boolean): boolean {
      return state;
    }

    control = 1;

    onDragStart(): void {
      this.updateDragging(true);
    }
    onDragEnd(): void {
      this.updateDragging(false);
    }

    removeOnOutsideDrag(event: { oldIndex: number }): void {
      this.syncedLogicElements.splice(event.oldIndex, 1);
      this.control++;
    }

    generateMappedQuestionDetail(): string | undefined {
      const elementId = this.currentTab.value;
      const value = this.variableMapping[elementId];
      if (value) {
        const variableMappingDetail = isString(value) ? JSON.parse(this.variableMapping[elementId]) : this.variableMapping[elementId];
        const calcValueSubFormQuestionIndex = findIndex(
          this.calcValueSubFormQuestions,
          (calcValueSubFormQuestion) => calcValueSubFormQuestion.id === variableMappingDetail.id
        );

        return (
          variableMappingDetail.id +
          ': ' +
          this.calcValueSubFormQuestions[calcValueSubFormQuestionIndex].title +
          ' [' +
          variableMappingDetail.fieldOption +
          ']'
        );
      }
    }

    get dropLayerStyles(): Dictionary<string> {
      let dropLayerStyle: Dictionary<string> = {
        border: '1px solid black',
        minHeight: '150px',
        overflowX: 'auto',
      };

      if (this.isVariableTab) dropLayerStyle = { ...dropLayerStyle, backgroundColor: '#f5f5f5' };
      return dropLayerStyle;
    }
  }
