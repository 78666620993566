
  import { Tooltip } from 'uiv';
  import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
  import type { SubFormList } from '@app/models/sub-form-list';

  @Component({ components: { Tooltip } })
  export default class NormalSingleSubFormListFlags extends Vue {
    @Prop(Boolean) readonly isHybrid?: boolean;
    @PropSync('subFormList') readonly subFormListSync!: Partial<SubFormList>;
  }
