
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { capitalize } from 'lodash';
  import TemplateItem from './template-item.vue';
  import type { CurrentTab, ParentRule, TemplateItemProps, TemplateItemObject } from '../models/types';
  import { LogicElementTypes } from '../models/types';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ components: { TemplateItem } })
  export default class LogicTemplateItems extends Vue {
    @Prop({ required: true }) readonly currentTabType!: CurrentTab['type'];
    @Prop() readonly parentRuleTemplateItem?: ParentRule;
    @Prop({ required: true }) readonly newElementType!:
      | LogicElementTypes.NEW_RULE
      | LogicElementTypes.NEW_COMPONENT
      | LogicElementTypes.NEW_VARIABLE;
    @Prop({ required: true }) readonly elementType!: LogicElementTypes.RULE | LogicElementTypes.COMPONENT | LogicElementTypes.VARIABLE;
    @Prop({ required: true }) readonly templateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly changeTab!: (tabType: CurrentTab['type'], tabValue: CurrentTab['value']) => void;
    @Prop({ required: true }) readonly generateTemplateItemProps!: () => TemplateItemProps;

    get displayNoneButton(): boolean {
      return (
        this.currentTabType !== this.newElementType &&
        this.elementType !== LogicElementTypes.RULE &&
        !Object.keys(this.templateItems).length
      );
    }

    get addNewButtonStyle(): string {
      return this.currentTabType !== this.newElementType ? 'cursor: pointer;' : 'color: grey;';
    }

    get elementTypeCapitalised(): string {
      return capitalize(this.elementType);
    }

    get sortedTemplateItemsKeys(): string[] {
      return Object.keys(this.templateItems).sort((a, b) => this.templateItems[a].index - this.templateItems[b].index);
    }
  }
