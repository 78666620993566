
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class ModuleRecordParentType extends Vue {
    @Prop(Number) readonly id!: number;

    title = '';
    subTitle: Nullable<string> = null;

    get recordPath(): string {
      return `/module_records/${this.id}`;
    }

    async beforeMount(): Promise<void> {
      try {
        const { data: record } = await this.$api.getModuleRecord(
          this.id,
          { only: ['id', 'title', 'module_name'] },
          {
            cache: true,
            join: true,
          }
        );
        this.title = `[${this.id}] ${record.title}`;
        this.subTitle = record.module_name?.display || null;
      } catch {
        this.title = `[${this.id}] Module Record`;
      }
    }
  }
