
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import FilterSelect from '../../../components/filter-select.vue';
  import Select2 from '../../../components/select2.vue';
  import OrganizationSelector from '../../../components/organization/organization-selector.vue';
  import type { Organization } from '@app/models/organization';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: { OrganizationSelector, BaseTable, FilterSelect, Select2 },
  })
  export default class AdminSettingsCostCentresIndex extends Vue {
    @Ref() readonly table?: BaseTable<Organization>;

    manager = new ListManager<Organization>({
      fetchDataFunction: (params) => {
        const patchedParams = { ...params, filters: { ...params.filters, cost_centre: true } };
        return this.$api.getOrganizations(
          {
            ...patchedParams,
            only: ['active', 'id', 'name', { organization: ['name'] }],
          },
          { cache: true }
        );
      },
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        {
          title: this.$t('tenant.settings.organizations.table_head.parent_organization'),
          name: 'organization',
          sortField: 'organization.name',
          filter: true,
        },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
      ],
      allowFilters: true,
    });

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
  }
