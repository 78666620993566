
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { groupBy, sum, uniq } from 'lodash';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import Select2 from '@app/components/select2.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { SubFormQuestionReference } from './models';

  const handleDuplicatedQuestionOptions = (questionOptions: [string, string][]) => {
    const squashedByCode = Object.values(groupBy(questionOptions, ([code]) => code)).map((v) => [
      v[0][0],
      uniq(v.map(([, label]) => label)).join(' | '),
    ]);

    return squashedByCode.map(([code, title]) => {
      const displayTitle = squashedByCode.find(([codeCmp, titleCmp]) => codeCmp !== code && title === titleCmp)
        ? `${title} (Code: ${code})`
        : title;

      return [code, displayTitle] as [string, string];
    });
  };

  @Component({ components: { EntitySelector, FormField, Select2 } })
  export default class SubFormQuestionSelector extends Vue {
    @Prop(Object) value!: SubFormQuestionReference;

    @Prop(String) fieldType!: FieldType;
    @Prop({ type: Boolean, default: true }) selectForm!: boolean;
    @Prop(String) moduleName?: string;
    @Prop(Number) subFormId?: SubForm['id'];

    questionOptions: [string, string][] = [];
    subFormListSubFormIds: number[] | null = null;

    async beforeMount(): Promise<void> {
      await this.loadQuestionOptions();
      this.onSubFormListChange(this.value.sub_form_list_id);
    }

    onChange(change: Partial<SubFormQuestionReference>) {
      this.$emit('input', { ...this.value, ...change });
    }

    async onSubFormListChange(sub_form_list_id?: number) {
      this.onChange({ sub_form_list_id });

      if (sub_form_list_id) {
        const {
          data: { sub_form_ids },
        } = await this.$api.getSubFormList(sub_form_list_id, { include: ['sub_form_ids'] }, { cache: true });
        this.subFormListSubFormIds = sub_form_ids || null;
      } else {
        this.subFormListSubFormIds = null;
      }
    }

    get transformEnabled(): boolean {
      return [FieldType.datetime, FieldType.date, FieldType.calculation_text].includes(this.fieldType || '');
    }

    get defaultIfMissingEnabled(): boolean {
      return [FieldType.text, FieldType.textarea, FieldType.calculation_text].includes(this.fieldType || '');
    }

    get compatibleFieldTypes(): FieldType[] {
      return [this.fieldType, ...(this.fieldType === FieldType.calculation_text ? [FieldType.calculator] : [])];
    }

    get subFormListFilters() {
      if (!this.moduleName) {
        return null;
      }

      return { active: true, module_tab: { active: true, module_name: this.moduleName } };
    }

    get columnSize(): number {
      return Math.floor(12 / sum([1, this.selectForm ? 1 : 0, this.transformEnabled ? 1 : 0]));
    }

    get questionFilters() {
      const base = {
        active: true,
        field_type: this.compatibleFieldTypes,
      };

      if (this.selectForm) {
        if (!this.subFormListSubFormIds) {
          return null;
        }

        return { ...base, sub_form_section: { sub_form_id: this.subFormListSubFormIds } };
      } else {
        if (!this.subFormId) {
          return null;
        }

        return {
          ...base,
          sub_form_section: { sub_form_id: this.subFormId },
        };
      }
    }

    @Watch('questionFilters')
    async loadQuestionOptions() {
      const [target, approval] = await Promise.all([this.fetchTargetQuestions(), this.fetchApprovalQuestions(this.value.sub_form_list_id)]);

      this.questionOptions = [
        ...handleDuplicatedQuestionOptions(target.map(({ code, title }) => [code, title] as [string, string])),
        ...handleDuplicatedQuestionOptions(
          approval.map(({ code, title }) => [`approval_${code}`, `Approval Question: ${title}`] as [string, string])
        ),
      ];
    }

    async fetchTargetQuestions() {
      if (!this.questionFilters) {
        return [];
      }

      return this.fetchQuestions(this.questionFilters);
    }

    async fetchApprovalQuestions(subFormListId: number | undefined) {
      if (!subFormListId) return [];

      const {
        data: { approval_sub_form_id },
      } = await this.$api.getSubFormList(subFormListId, { include: ['sub_form_ids'] });

      if (!approval_sub_form_id) return [];

      return this.fetchQuestions({ sub_form_section: { sub_form_id: approval_sub_form_id } });
    }

    async fetchQuestions(filters: DonesafeFilterOptions<SubFormQuestion>) {
      const { data: questions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only: ['id', 'code', 'title'],
          filters: {
            active: true,
            field_type: this.compatibleFieldTypes,
            ...filters,
          },
        },
        { cache: true }
      );

      return questions;
    }
  }
