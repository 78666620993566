
  import type { Relation, RelationItem } from '@app/models/relation';
  import { Component, Prop } from 'vue-property-decorator';
  import BasePrint from './base-print';
  import type { PrintRelatedOnly, PrintRelationItemOnly } from './utils';

  @Component({ components: {} })
  export default class PrintRelatedTo extends BasePrint {
    @Prop(Array) readonly relations!: Pick<Relation, PrintRelatedOnly>[];

    get relatedRecords(): Maybe<Pick<RelationItem, PrintRelationItemOnly>>[] {
      return this.relations.map(this.relatedRecord);
    }

    relatedRecord(relation: Pick<Relation, PrintRelatedOnly>): Maybe<Pick<RelationItem, PrintRelationItemOnly>> {
      if (relation.from_type === this.recordType && relation.from_id === Number(this.record?.id)) {
        return relation.to;
      } else {
        return relation.from;
      }
    }

    relatedRecordTitle(relatedRecord: Pick<RelationItem, PrintRelationItemOnly>) {
      return `${relatedRecord.title} (${relatedRecord.uniq_id}) - ${relatedRecord.module_name?.display || ''}`;
    }
  }
