
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '@app/components/admin/questions/filter-configurator/utils';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Component, Prop, Model, Vue } from 'vue-property-decorator';
  import RecordFilterConfigurator from '../filter-configurator/record-filter-configurator.vue';
  import SortConfigurator from '../sort-configurator/sort-configurator.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import type { MultiMainFormRelationQuestionOptions } from '@app/models/question-options/multi-main-form-relation-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({ components: { FormField, DsCheckbox, RecordFilterConfigurator, EntitySelector, SortConfigurator } })
  export default class MultiMainFormRelationFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<MultiMainFormRelationQuestionOptions>;
    @Prop(Object) subForm!: SubForm;

    selectedModuleName: Pick<ModuleName, FirstLevelKeyLiterals<(typeof WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY)[number]>> | null = null;

    get filtersRequired() {
      return this.autoSelectEnabled && !this.hasMandatoryOrNonFormFilters;
    }

    get autoSelectEnabled(): boolean {
      return this.value.config.auto_select_enabled === 'true';
    }

    get hasMandatoryOrNonFormFilters(): boolean {
      return !!this.value.config.filters?.some(
        (f) =>
          f.required === 'true' ||
          f.source === ConfiguratorFilterSource.current_record ||
          f.source == ConfiguratorFilterSource.current_user ||
          !f.source
      );
    }
    get moduleNameFilter(): DonesafeFilterOptions<ModuleName> {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES,
      };
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    inactiveModuleDisplayTemplate(moduleName: ModuleName): JQuery {
      return inactiveTitleTemplate(moduleName, 'display');
    }

    beforeMount(): void {
      this.onModuleNameSelect(this.value.config.main_form_id);
    }

    onModuleNameSelect(id?: number): void {
      if (id) {
        const only = WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY;
        this.$api.getModuleName(Number(id), { only }, { cache: true }).then(({ data }) => {
          this.selectedModuleName = data;
        });
      } else {
        this.selectedModuleName = null;
      }

      this.$$patch(this.value.config, {
        main_form_id: id,
        filters: (`${id}` === `${this.value.config.main_form_id}` ? this.value.config.filters : []) || [],
      });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['main_form_id', 'main_form_related', 'add', 'sort', 'auto_select_enabled', 'out_of_scope_selection', 'filters'].forEach((key) =>
        this.$delete(this.value.config, key)
      );
    }
  }
