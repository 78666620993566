
  import { Component, Model, Prop, Watch } from 'vue-property-decorator';
  import ValueDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/value.vue';
  import Select2 from '@app/components/select2.vue';
  import DaysAfterDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/days-after.vue';
  import RandomTextDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/random-text.vue';
  import MostRecentFormDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/most-recent-form.vue';
  import HighestFormDefaultTypeOptions from './default-types/highest-form.vue';
  import LowestFormDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/lowest-form.vue';
  import RecordCalculationDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/record-calculation.vue';
  import FormBeingApprovedDefaultTypeOptions from '@app/components/admin/questions/edit/default-types/form-being-approved.vue';
  import MainFormDefaultTypeOptions from './default-types/main-form.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import { titleize } from '@app/utils/titleize';

  type DefaultSettings = { days_after: number; days_from_now: number; option: string; type: string };

  @Component({
    components: {
      Select2,
      FormField,
      ValueDefaultTypeOptions,
      DaysAfterDefaultTypeOptions,
      RandomTextDefaultTypeOptions,
      MostRecentFormDefaultTypeOptions,
      HighestFormDefaultTypeOptions,
      LowestFormDefaultTypeOptions,
      RecordCalculationDefaultTypeOptions,
      FormBeingApprovedDefaultTypeOptions,
      MainFormDefaultTypeOptions,
    },
  })
  export default class DefaultValueOptions extends mixins(Blocking) {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & { default: DefaultSettings }>;

    @Prop(Object) subForm!: SubForm;

    defaultValueTypeOptions: [string, string][] = [];

    get componentName(): string | null {
      const tp = this.value.config.default?.type;

      const noComponentFor = ['no_default', 'current_users_value', 'current_users_manager', 'select_value', 'values', 'calculation_value'];

      if (!tp) return null;
      if (noComponentFor.includes(tp)) return null;

      return `${tp.replaceAll('_', '-')}-default-type-options`;
    }

    @Watch('value.field_type')
    async loadDefaultValueTypeOptions() {
      return await this.blocking(async () => {
        if (!this.value.field_type) return (this.defaultValueTypeOptions = []);

        const { data: defaultValueTypeOptions } = await this.$api.getSubFormDefaultValueTypeOptions(
          this.subForm.id,
          this.value.field_type,
          { cache: true }
        );

        const labelMapping: Dictionary<string> = { current_user: "User Filling Form's Home Organization's Cost Centre" };

        this.defaultValueTypeOptions = defaultValueTypeOptions.map((dvto) => [dvto, labelMapping[dvto] || titleize(dvto)]);
        const typeCodes = this.defaultValueTypeOptions.map(([code]) => code);
        const [firstTypeCode] = typeCodes;

        if (!typeCodes.includes(this.value.config.default.type)) {
          this.$$patch(this.value.config.default, { type: firstTypeCode || 'no_default' });
        }

        return this.defaultValueTypeOptions;
      });
    }

    async beforeMount() {
      await this.loadDefaultValueTypeOptions();
      const dflt = this.value.config.default;
      const type = dflt.type;

      if (!type) {
        const inferredType = (() => {
          if (!!dflt.days_after || !!dflt.days_from_now) return 'days_after';

          if (dflt.option === 'current_user') {
            return 'current_users_value';
          }

          if (dflt.option) {
            return 'select_value';
          }
        })();

        if (!!inferredType) {
          this.$$patch(this.value.config.default, { type: inferredType });
        }
      }
    }

    onDefaultTypeChange(type: string) {
      const option = {
        current_users_value: 'current_user',
        current_users_manager: 'current_user_manager',
      }[type];

      this.$$patch(this.value.config.default, { option, type });
    }
  }
