
  import qs from 'qs';
  import { tooltip } from 'uiv';
  import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
  import { useAccountStore } from '@app/stores/account';
  import ActivitiesTable from '@app/components/activities/activities-table.vue';
  import ActivitiesStatusFilter from '@app/components/filters/activities-status-filter.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { ValidIndexes } from '@app/models/index-filter';
  import { toaster } from '@app/utils/toaster';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import IndexFilters from '@app/mixins/index-filters';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Activity } from '@app/models/activity';
  import type { ModuleName } from '@app/models/module-name';
  import { SpecialPermissionName } from '@app/models/extended-permission';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';

  import GlobalDateFilter from '../filters/global-date-filter.vue';
  import ScopeFilter from '../filters/scope-filter.vue';

  @Component({
    components: {
      ActivitiesStatusFilter,
      ActivitiesTable,
      SearchInput,
      ScopeFilter,
      DsDropdown,
      RecordIndexPageFiltersLayout,
      GlobalDateFilter,
    },
    directives: { tooltip },
  })
  export default class ActivitiesIndexPage extends Mixins(IndexFilters<Activity>) {
    @Ref() table?: ActivitiesTable;
    initialized = false;
    index: ValidIndexes = 'actions';

    get accountStore() {
      return useAccountStore();
    }

    get actionsPseudoModule(): Pick<ModuleName, 'name' | 'plural_display'> {
      return { name: ACTIVITY_CONCEPT, plural_display: this.$t('app.labels.actions').toString() };
    }

    get canCreate(): boolean {
      return (
        this.currentUserStore.canCreateActivity &&
        this.currentUserStore.hasExtendedPermission(SpecialPermissionName.standalone_activity_creation)
      );
    }

    get indexOptions(): string[] {
      return [...(this.accountStore.data.action_options.index_options || []).map((o) => o.key), 'checkbox'];
    }

    get emptyFilter(): DonesafeFilterOptions<Activity, DonesafeActivitiesApiExtraOptions> {
      return { scope_filter: 'all', date: 'all', status_filter: 'all' };
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    onView(activity: Activity): void {
      this.$router.push({ name: 'activity-view', params: { activityId: `${activity.id}` }, query: this.$route.query });
    }

    onEdit(activity: Activity): void {
      this.$router.push({ name: 'activity-edit', params: { activityId: `${activity.id}` }, query: this.$route.query });
    }

    updateFilters(filters: DonesafeFilterOptions<Activity>): void {
      const newQueryParams = qs.stringify({ ...this.getQueryParams(), filters }, { addQueryPrefix: true, arrayFormat: 'brackets' });

      newQueryParams !== window.location.search && this.$router.replace(newQueryParams);
      this.table?.manager?.updateFromQueryParams();
    }

    clearAllFilters(): void {
      this.updateFilters(this.emptyFilter);
      toaster(this.$t('tenant.index_filters.clear.filters_cleared'));
    }
  }
