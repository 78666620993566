
  import { useAccountStore } from '@app/stores/account';
  import ModuleSecondaryOptionsSelector from '@app/components/admin/modules/module-secondary-options-selector.vue';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { debounce, includes, map } from 'lodash';
  import Draggable from 'vuedraggable';
  import { Tooltip } from 'uiv';
  import type { AvailableIndexOption, ModuleName, ModuleNameIndexOption } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';

  import DropdownSelect from '../dropdown-select.vue';
  import Select2 from '../select2.vue';
  import UserRecordCalendarModal from '../calendar/user-record-calendar-modal.vue';

  import SystemFilterFormModal from './module-names/system-filter-form-modal.vue';

  @Component({
    components: {
      ModuleSecondaryOptionsSelector,
      UserRecordCalendarModal,
      Select2,
      Draggable,
      SystemFilterFormModal,
      Tooltip,
      DropdownSelect,
    },
  })
  export default class ModuleNameIndexOptionsConfigurator extends Vue {
    @Prop(Object) readonly moduleName!: ModuleName;
    @Ref() readonly calendarModal?: UserRecordCalendarModal;
    selectedIndexOption: string | null = null;

    form: Partial<Pick<ModuleName, 'id' | 'index_options' | 'index_option_labels'>> = {};
    filterModalVisible = false;
    calendarModalVisible = false;
    isUpdating = false;
    debounceChangeOption = debounce(this.onChangeOption, 1500);
    get accountStore() {
      return useAccountStore();
    }

    get calendarIntegrationEnabled(): boolean {
      return !!this.accountStore.data.calendar_integration_enabled && !!this.moduleName.feature_set?.calendar_integration_enabled;
    }

    get availableIndexOptions(): [string, string][] {
      return map(this.moduleName.available_index_options, (option, key) => [key, option.name] as [string, string]).filter((pair) => {
        return !this.form.index_options?.find((opt) => opt.key === pair[0]);
      });
    }

    beforeMount(): void {
      this.initForm(this.moduleName);
    }

    mounted() {
      if (this.$route.name === 'admin-settings-module-names-show-options-calendar') {
        this.toggleCalendarModal(true, Number(this.$route.params.calendarId));

        this.$router.replace({ name: 'admin-settings-module-names-show-options' });
      }
    }

    dragEnd(): void {
      this.updateModuleName(this.form);
    }

    toggleCalendarModal(value: boolean, calendarId?: number): void {
      this.calendarModalVisible = value;
      calendarId && this.calendarModal?.selectCalendarById(calendarId);
    }

    initForm(moduleName: ModuleName): void {
      this.form = {
        id: moduleName.id,
        index_options: moduleName.index_options,
        index_option_labels: moduleName.index_option_labels,
      };
    }

    onChangeOption(): void {
      this.updateModuleName(this.form);
    }

    optionTitle(option: ModuleNameIndexOption): string {
      return this.availableOption(option)?.name || 'Archived option (please remove it)';
    }

    availableOption(option: ModuleNameIndexOption): AvailableIndexOption | undefined {
      return this.moduleName.available_index_options && this.moduleName.available_index_options[option.key];
    }

    isOptionWithRestrictions(option: ModuleNameIndexOption): boolean {
      if (this.availableOption(option)?.type === 'location') {
        return this.accountStore.limitPermissionsByExplicitLocations || !!this.accountStore.data.limit_permissions_by_location;
      } else if (this.availableOption(option)?.type === 'organization') {
        return !!this.accountStore.data.limit_permissions_by_organization;
      }
      return false;
    }

    isRecordOption(option: ModuleNameIndexOption): boolean {
      return this.availableOption(option)?.type === 'record';
    }

    isOptionWithUserRestrictions(option: ModuleNameIndexOption): boolean {
      return (
        (option.key === 'created_by' || includes(option.key, 'involvement_') || 'user' === this.availableOption(option)?.type) &&
        (this.accountStore.data.limit_permissions_by_location ||
          this.accountStore.data.limit_permissions_by_organization ||
          this.accountStore.limitPermissionsByExplicitLocations)
      );
    }

    removeIndexOption(key: string): void {
      // todo: alert if empty
      this.form = {
        ...this.form,
        index_options: this.form.index_options?.filter((option) => option.key !== key) || [],
      };
      this.updateModuleName(this.form);
    }

    updateModuleName(form: Partial<Pick<ModuleName, 'id' | 'index_options' | 'index_option_labels'>>): void {
      this.isUpdating = true;
      this.$api
        .updateModuleName(this.moduleName.id, form, { only: ['id', 'index_options', 'index_option_labels'] })
        .then((response) => {
          this.initForm(response.data);
          return response;
        })
        .then(() => {
          toaster(this.$t('app.labels.module_updated', { module_name: this.moduleName.display }));
          this.$api.cache.clear();
        })
        .catch((response) => toaster({ text: response.data.error, position: 'top-right', icon: 'error' }))
        .finally(() => {
          this.isUpdating = false;
        });
    }

    addSelectedOption(): void {
      if (this.selectedIndexOption) {
        this.form.index_options = [...(this.form.index_options || []), { key: this.selectedIndexOption as string }];
        this.selectedIndexOption = null;
        this.updateModuleName(this.form);
      }
    }

    toggleFilterModal(show: boolean): void {
      this.filterModalVisible = show;
    }
  }
