
  import { Component } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import UserCollectionForm from '@app/components/admin/user-collections/user-collection-form.vue';
  import Blocking from '@app/mixins/blocking';
  import type { UserCollection } from '@app/models/user-collection';
  import { toaster } from '@app/utils/toaster';

  const shape = {
    only: ['id', 'name', 'global', 'active', { rule_set: ['query_builder_blob'] }],
  };

  @Component({
    components: { UserCollectionForm },
  })
  export default class CreateUserCollection extends mixins(Blocking) {
    async onSubmit(form: UserCollection): Promise<void> {
      await this.blocking(async () => {
        const {
          data: { id },
        } = await this.$api.createUserCollection({ ...shape, ...form });

        await this.$router.push(`/admin/settings/user_collections/${id}/edit`);
        toaster({ text: this.$t('tenant.admin.user_collections.created'), position: 'top-right' });
      });
    }
  }
