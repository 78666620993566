
  import { Component, Vue } from 'vue-property-decorator';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { changeLocation } from '@app/utils/change-location';

  import EntitySelector from '../entity-selector.vue';

  @Component({ components: { EntitySelector } })
  export default class AdminModuleRecordImportsPage extends Vue {
    get moduleNameFilter(): object {
      return { active: true, '!name': HARDCODED_MODULE_CODES };
    }

    onModuleNameSelect(moduleNameId: string): void {
      changeLocation(`/admin/settings/module_record_imports/new?module_name_id=${moduleNameId}`);
    }
  }
