
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  @Component({ components: { DsTextInput } })
  export default class ValueDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & { default: { value: string } }>;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['value'].forEach((key) => this.$delete(this.value.config.default, key));
    }
  }
