import type { BaseEntity } from './base-entity';
import type { WithTenantUser } from './mixins';
import type { RegulatoryReportConfig } from './regulatory-report-config';
import type { Attachment } from '@app/models/attachment';

export enum RegulatoryReportState {
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  in_progress = 'in_progress',
  in_queue = 'in_queue',
}

export interface RegulatoryReportIssue {
  message: string;
  type: 'error' | 'warning';
}

export interface RegulatoryReport extends BaseEntity, WithTenantUser {
  attachments?: Attachment[];
  data?: {
    download_all_attachment_id?: number;
    download_all_error?: string;
    issues?: RegulatoryReportIssue[];
    visible_attachment_ids?: number[];
  };
  options: {
    report_filters?: Record<string, unknown>;
    report_formats?: string[];
  };
  regulatory_report_config?: RegulatoryReportConfig;
  regulatory_report_config_id: number;
  state: RegulatoryReportState;
}
