
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import GlobalDateFilter from '@app/components/filters/global-date-filter.vue';
  import ScopeFilter from '@app/components/filters/scope-filter.vue';
  import ProceduresTable from '@app/components/location/procedures/procedures-table.vue';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import SearchInput from '@app/components/search-input.vue';
  import IndexFilters from '@app/mixins/index-filters';
  import type { Activity } from '@app/models/activity';
  import type { ValidIndexes } from '@app/models/index-filter';
  import type { ModuleName } from '@app/models/module-name';
  import type { Procedure } from '@app/models/procedure';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { entityPathPrefix } from '@app/services/helpers';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { toaster } from '@app/utils/toaster';
  import { get } from 'lodash';
  import qs from 'qs';
  import { tooltip } from 'uiv';
  import { Component, Mixins, Ref } from 'vue-property-decorator';

  @Component({
    directives: { tooltip },
    components: { ProceduresTable, RecordIndexPageFiltersLayout, DsDropdown, ScopeFilter, SearchInput, GlobalDateFilter },
  })
  export default class KnowledgeBaseIndexPage extends Mixins(IndexFilters<Procedure>) {
    @Ref() readonly table?: ProceduresTable;
    initialized = false;

    index: ValidIndexes = 'procedures';

    moduleName: Nullable<Pick<ModuleName, 'id' | 'display' | 'plural_display'>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get addNewPath() {
      return `/${entityPathPrefix('Procedure')}/new`;
    }

    get canCreate(): boolean {
      return !!get(this.currentUserStore.data?.acl, ['module', 'Procedure', 'create']);
      // !get(this.currentUserStore.data?.acl, ['module', this.moduleName.name, 'hide_quick_add_button'])
    }

    beforeMount(): void {
      this.$api.getModuleNames({ only: ['id', 'display', 'plural_display'], filters: { name: 'Procedure' } }).then(({ data }) => {
        this.moduleName = data[0];
      });
    }

    updateFilters(filters: DonesafeFilterOptions<Activity> = {}): void {
      const newQueryParams = qs.stringify({ ...this.getQueryParams(), filters }, { addQueryPrefix: true, arrayFormat: 'brackets' });

      newQueryParams !== window.location.search && this.$router.replace(newQueryParams);
      this.table?.manager?.updateFromQueryParams();
    }

    clearAllFilters(): void {
      this.updateFilters();
      toaster(this.$t('tenant.index_filters.clear.filters_cleared'));
    }
  }
