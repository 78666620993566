
  import { BaseTable } from '@app/components/base-table';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { Tooltip } from 'uiv';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import bootbox from 'bootbox';
  import SearchInput from '@app/components/search-input.vue';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import type { Role } from '@app/models/role';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      BaseTable,
      FilterSelect,
      Select2,
      Tooltip,
      SearchInput,
      RecordIndexPageFiltersLayout,
    },
  })
  export default class RoleTabUsers extends Vue {
    @Prop({ type: [String, Number] }) readonly roleId!: string | number;
    @Ref() readonly table?: BaseTable<TenantUser>;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    manager: Nullable<ListManager<TenantUser>> = null;
    activeOptions: [string, string][] = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
    noRightsRole: Nullable<Role> = null;
    indexFilterWrapperClasses = {
      'search-input': {
        'p-l-none': true,
      },
      buttons: {
        'm-r-none': true,
      },
    };
    usersNewModal = false;

    toggleModal() {
      this.usersNewModal = !this.usersNewModal;
    }

    removeRole(userId: number): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.roles.rtu.delete_warning'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            !!this.noRightsRole?.id &&
            this.$api
              .updateTenantUser(userId, {
                role_id: this.noRightsRole.id,
              })
              .then(() => {
                this.$api.cache.clear();
                this.table?.reload();
                toaster(this.$t('tenant.admin.roles.rtu.user_removed_from_role'));
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    userLinkEdit(userId: string): string {
      return `/admin/settings/users/${userId}/edit`;
    }

    getManager(): ListManager<TenantUser, ExtraUserApiOptions> {
      return new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params) => {
          return this.$api.getTenantUsers(
            {
              ...params,
              filters: {
                ...params.filters,
                role_id: this.roleId,
              },
              only: ['id', 'full_name', 'email', 'active', 'secondary_information'],
            },
            { cache: true }
          );
        },
        useHistory: false,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        fields: this.getTableColumns,
        allowFilters: true,
      });
    }

    get getTableColumns() {
      let fields = [
        {
          title: 'ID',
          name: 'id',
          sortField: 'id',
        },
        {
          title: 'Name',
          name: 'full_name',
          sortField: 'full_name',
        },
        {
          title: 'Email',
          name: 'email',
        },
        {
          title: 'Active',
          name: 'active',
          sortField: 'active',
          filter: true,
        },
      ];

      if (this.noRightsRole?.id !== this.roleId) {
        fields.push({
          title: '',
          name: 'remove',
        });
      }

      return fields;
    }

    initForm() {
      this.$api
        .getRoles(
          {
            filters: {
              system_code: 'system_no_rights_user',
            },
            only: ['id'],
          },
          {
            cache: true,
          }
        )
        .then(({ data }) => {
          this.noRightsRole = data?.[0] || null;
          this.manager = this.getManager();
        });
    }

    beforeMount(): void {
      this.initForm();
    }
  }
