
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import { Btn } from 'uiv';
  import DsModal from '@app/components/ds-modal.vue';
  import Select2 from '../../select2.vue';
  import UserFilterConfigurator from '../questions/filter-configurator/user-filter-configurator.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import bootbox from 'bootbox';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import { FieldType } from '@app/models/sub-form-question';

  interface LinkingQuestionOption {
    id: number;
    moduleName: Pick<ModuleName, 'id' | 'display' | 'name' | 'sub_form_id'>;
    title: string;
  }

  interface InvolvementOption {
    group: string;
    id: number;
    name: string;
  }

  // TODO: get rid of Btn, or use BtnGroup as it does not work now :(
  @Component({ components: { Select2, UserFilterConfigurator, DsModal, Btn, ValidationObserver, ValidationProvider } })
  export default class InvolvementFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly involvement!: Partial<Involvement>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    moduleName: Nullable<ModuleName> = null;
    form: Partial<Involvement> = {};
    pseudoType: 'standard' | 'shared' = 'standard';

    childrenQuestionOptions: LinkingQuestionOption[] = [];
    currentFormQuestionOptions: LinkingQuestionOption[] = [];
    availableInvolvements: Involvement[] = [];

    moduleNames: Pick<ModuleName, 'id' | 'display' | 'name' | 'sub_form_id'>[] = [];

    get questionOptions(): LinkingQuestionOption[] {
      switch (this.form.involvement_type) {
        case 'children_sharing':
          return this.childrenQuestionOptions;
        case 'parent_sharing':
          return this.currentFormQuestionOptions;
        default:
          return [];
      }
    }

    get pseudoTypes(): Record<'standard' | 'shared', string> {
      return {
        standard: this.$t('tenant.admin.involvements.general_types.standard'),
        shared: this.$t('tenant.admin.involvements.general_types.shared'),
      };
    }

    get showWarning(): boolean {
      const changed =
        `${this.form.source_involvement_id}` !== `${this.involvement.source_involvement_id}` ||
        `${this.form.linking_question_id}` !== `${this.involvement.linking_question_id}`;
      return !!this.form.id && changed;
    }

    get pseudoTypeLabel(): string | undefined {
      switch (this.pseudoType) {
        case 'shared':
          return this.$t('tenant.admin.involvements.general_types.shared');
        case 'standard':
          return this.$t('tenant.admin.involvements.general_types.standard');
      }
    }

    get sharedTypeLabel(): string | undefined {
      switch (this.form.involvement_type) {
        case 'parent_sharing':
          return this.$t('tenant.admin.involvements.sharing_types.parent_sharing');
        case 'children_sharing':
          return this.$t('tenant.admin.involvements.sharing_types.children_sharing');
      }
    }

    get involvementOptions(): InvolvementOption[] | undefined {
      return this.availableInvolvements?.map((involvement) => {
        const group =
          involvement.involvement_type === 'standard'
            ? this.$t('tenant.admin.involvements.involvement_types.standard')
            : this.$t('tenant.admin.involvements.involvement_types.children_sharing');

        return {
          id: involvement.id,
          name: involvement.name,
          group: group,
        };
      });
    }

    get title(): string {
      if (this.involvement.id) {
        return 'Edit Involvement';
      }
      return 'Add Involvement';
    }

    get sourceInvolvementReadonly(): boolean {
      return (!!this.involvement.id && !!this.involvement.source_involvement_id) || !this.form.linking_question_id;
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value ? this.initForm() : this.resetForm();
    }

    onPseudoTypeChange(type: 'standard' | 'shared'): void {
      this.pseudoType = type;
      switch (type) {
        case 'standard':
          this.form = {
            ...this.form,
            involvement_type: 'standard',
            linking_question_id: undefined,
            source_involvement_id: undefined,
          };
          break;
        case 'shared':
          this.form = {
            ...this.form,
            involvement_type: 'children_sharing',
            linking_question_id: undefined,
            source_involvement_id: undefined,
          };
          break;
      }
    }

    resetForm(): void {
      this.validator?.reset();
      this.moduleName = null;
      this.form = {};
      this.childrenQuestionOptions = [];
      this.currentFormQuestionOptions = [];
      this.availableInvolvements = [];
    }

    initForm(): void {
      this.form = {
        involvement_type: 'standard',
        can_add: true,
        can_delete: true,
        ...this.involvement,
      };
      this.pseudoType = this.form.involvement_type === 'standard' ? 'standard' : 'shared';
      this.fetchModuleName();
    }

    fetchModuleName(): void {
      this.$api
        .getModuleNames(
          {
            filters: { name: this.involvement.module_name },
            include: ['main_form', 'sub_form_sections', 'sub_form_questions', 'involvements'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleName = data[0];
          if (this.moduleName) {
            this.$api
              .getSubFormQuestions(
                {
                  only: ['id', 'title', { sub_form_section: ['sub_form_id'] }],
                  filters: {
                    active: true,
                    field_type: FieldType.main_form_relation,
                    config: {
                      main_form_id: this.moduleName.id,
                    },
                  },
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.childrenQuestionOptions = data
                  .filter((q) => this.moduleNames.find((mn) => mn.sub_form_id === q.sub_form_section?.sub_form_id))
                  .map((q) => {
                    const moduleName = this.moduleNames.find((mn) => mn.sub_form_id === q.sub_form_section?.sub_form_id) as ModuleName;
                    return {
                      id: q.id,
                      title: `${q.title} (${moduleName.display})`,
                      moduleName,
                    };
                  });
                if (this.form.id && this.form.involvement_type !== 'standard' && this.form.linking_question_id) {
                  this.onQuestionSelect(this.form.linking_question_id);
                }
              });

            this.currentFormQuestionOptions = (this.moduleName.main_form?.sub_form_sections?.map((s) => s?.sub_form_questions || []) || [])
              .flat()
              .filter((q) => q.active && q.field_type === FieldType.main_form_relation)
              .filter((q) => this.moduleNames.find((mn) => `${mn.id}` === q.config.main_form_id))
              .map((q) => {
                const moduleName = this.moduleNames.find((mn) => `${mn.id}` === q.config.main_form_id) as ModuleName;
                return {
                  id: q.id,
                  title: `${q.title} (${moduleName.display})`,
                  moduleName,
                };
              });
          }
        });
    }

    onQuestionSelect(questionId: string | number): void {
      if (`${this.form.linking_question_id}` !== `${questionId}`) {
        this.form = {
          ...this.form,
          source_involvement_id: undefined,
        };
        this.validator?.refs?.source_involvement_id?.reset();
      }
      if (this.form.involvement_type === 'children_sharing') {
        const questionOption = this.childrenQuestionOptions.find((q) => `${q.id}` === `${questionId}`);
        if (questionOption) {
          this.$api
            .getInvolvements(
              {
                filters: {
                  involvement_type: 'standard',
                  module_name: questionOption.moduleName.name,
                },
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.availableInvolvements = data;
            });
        } else {
          this.availableInvolvements = [];
        }
      } else if (this.form.involvement_type === 'parent_sharing') {
        const questionOption = this.currentFormQuestionOptions.find((q) => `${q.id}` === `${questionId}`);
        if (questionOption) {
          this.$api
            .getInvolvements(
              {
                filters: {
                  involvement_type: ['standard', 'children_sharing'],
                  module_name: questionOption.moduleName.name,
                },
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.availableInvolvements = data;
            });
        } else {
          this.availableInvolvements = [];
        }
      }
    }

    submitForm(): void {
      this.$nextTick(() => {
        this.validator?.validate().then((result: boolean) => {
          if (result) {
            this.$emit('submit', this.form);
          }
        });
      });
    }

    archive(): void {
      if (this.involvement.id) {
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.admin.involvements.form.archive_warning'),
          buttons: {
            confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.$emit('submit', { ...this.involvement, active: false });
            }
          },
        });
      }
    }

    restore(): void {
      if (this.involvement.id) {
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.admin.involvements.form.restore_warning'),
          buttons: {
            confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.$emit('submit', { ...this.involvement, active: true });
            }
          },
        });
      }
    }

    onSharedInvolvementTypeChange(type: 'parent_sharing' | 'children_sharing'): void {
      this.form = {
        ...this.form,
        involvement_type: type,
        linking_question_id: undefined,
        source_involvement_id: undefined,
      };
      this.validator?.refs?.linking_question_id?.reset();
      this.validator?.refs?.source_involvement_id?.reset();
    }

    beforeMount(): void {
      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['id', 'name', 'display', 'sub_form_id'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
        });
    }
  }
