
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import AdminSubForm from '@app/components/admin/sub-forms/admin-sub-form.vue';
  import AdminMainFormControlPanel from '@app/components/admin/sub-forms/admin-main-form-control-panel.vue';
  import type { SubForm } from '@app/models/sub-form';

  @Component({
    components: { AdminSubForm, AdminMainFormControlPanel },
  })
  export default class ModuleSubFormPage extends Vue {
    @Prop([Number, String]) readonly moduleNameId!: number | string;

    moduleName: Nullable<ModuleName> = null;
    subForm: Nullable<SubForm> = null;

    async beforeMount(): Promise<void> {
      const { data: moduleName } = await this.$api.getModuleName(
        Number(this.moduleNameId),
        { only: ['id', 'name', 'sub_form_id'] },
        { cache: true }
      );
      this.moduleName = moduleName;
      if (this.moduleName.sub_form_id) {
        const { data: subForm } = await this.$api.getSubForm(
          this.moduleName.sub_form_id,
          { only: ['id', 'module_name', 'scoring'] },
          { cache: true }
        );
        this.subForm = subForm;
      }
    }
  }
