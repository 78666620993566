
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import RuleBuilderPanel from '@app/components/admin/events/rule-builder-panel.vue';
  import { isEmpty } from 'lodash';
  import type { FilterParams } from '@app/services/api/utils-api';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { ToggleButton } from 'vue-js-toggle-button';

  @Component({ components: { RuleBuilderPanel, ToggleButton } })
  export default class RuleRestrictionsSelector extends Vue {
    @Model('input', { type: Object }) ruleSet?: QueryBuilderBlob;
    @Prop({ type: String, required: true }) readonly conceptName!: string;
    @Prop(Object) readonly filterParams?: FilterParams;

    showRuleBuilder = false;

    beforeMount() {
      if (!isEmpty(this.ruleSet)) {
        this.showRuleBuilder = true;
      }
    }

    onRuleBuilderChange(value: QueryBuilderBlob) {
      this.$emit('input', {
        ...(this.ruleSet || {}),
        query_builder_blob: value,
      });
    }

    removeRuleRestriction() {
      this.$emit('input', {});
    }

    onShowRuleBuilderChanged(value: boolean) {
      if (!value) {
        this.removeRuleRestriction();
      }
    }

    get openRuleBuilderInEditMode() {
      return isEmpty(this.ruleSet);
    }
  }
