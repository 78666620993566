
  import { Component, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import CommentFormModal from '@app/components/comment/comment-form-modal.vue';
  import BaseActivity from './base-activity';
  import type { ActivitiesListActivityOnly, ActivityTableActivityOnly } from './utils';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { Activity } from '@app/models/activity';
  import { SpecialPermissionName } from '@app/models/extended-permission';
  import { ACTIVITY_CONCEPT } from '@app/constants';

  @Component({ components: { DsDropdown, PaperTrailsModalLink, CommentFormModal, DsIconText } })
  export default class ActivityActions extends BaseActivity<Pick<Activity, ActivitiesListActivityOnly | ActivityTableActivityOnly>> {
    @Prop({ type: String, default: () => 'div' }) readonly tag!: string;
    @Prop(String) readonly triggerClasses!: string;
    @Prop({ type: Boolean, default: true }) readonly appendToBody?: boolean;

    get renderDropdown() {
      return (
        this.canAddComments ||
        this.canMarkAsComplete ||
        this.canApproveIfNotApproved ||
        this.canUnapprove ||
        this.canEdit ||
        this.canReopen ||
        this.canSeeAuditTrail
      );
    }

    get canSeeAuditTrail() {
      return this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.audit_trail, ACTIVITY_CONCEPT);
    }
  }
