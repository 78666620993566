
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import SubFormListViewOptionsForm from '@app/components/sub-form-list/sub-form-list-view-options-form.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, SubFormListViewOptionsForm } })
  export default class SubFormListViewOptionsModal extends Vue {
    @Ref() readonly form?: SubFormListViewOptionsForm;
    @Prop({ required: true, type: Object }) readonly subFormList!: Partial<SubFormList>;
    @Prop(String) readonly conceptName!: ModuleName['name'];
    @Prop(Boolean) readonly saving?: boolean;

    visible = true;
  }
