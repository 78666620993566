
  import { useAccountStore } from '@app/stores/account';
  import { Tooltip } from 'uiv';
  import { isEmpty } from 'lodash';
  import moment from 'moment';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import type { AppBundleInstallation } from '@app/models/app-bundles/installation';
  import consumer from '@app/channels/consumer';
  import type { Subscription } from '@rails/actioncable';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import Restart from '@app/components/admin/app-bundles/restart.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  import { BaseTable } from '../../base-table';

  import AppBundleInstallationFormModal from './app-bundle-installation-form-modal.vue';

  @Component({ components: { AppBundleInstallationFormModal, BaseTable, Restart, Tooltip } })
  export default class AdminAppBundlesInstallationsPage extends Vue {
    @Ref() readonly table?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    formModalMode: 'create' | 'errors' | 'metadata' = 'create';

    appBundleInstallation: Nullable<AppBundleInstallation> = null;
    modalVisible = false;
    manager = new ListManager<AppBundleInstallation>({
      fetchDataFunction: (params) => {
        return this.$api.getAppBundleInstallations(
          {
            ...params,
            only: [
              'id',
              'bundle_name',
              'installed_at',
              'metadata',
              'stage',
              'url',
              'installation_errors',
              'created_at',
              { user: ['id', 'full_name'] },
            ],
          },
          { cache: true }
        );
      },
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created_at' }],
      fields: [
        {
          title: this.$t('app.labels.app_bundles.installations.bundle_name'),
          name: 'bundle_name',
          sortField: 'bundle_name',
        },
        {
          title: this.$t('app.labels.app_bundles.installations.installed_at'),
          name: 'installed_at',
        },
        { title: this.$t('app.labels.app_bundles.installations.user'), name: 'user', sortField: 'user.full_name' },
        { title: this.$t('app.labels.app_bundles.installations.stage'), name: 'stage' },
        {
          title: this.$t('app.labels.app_bundles.installations.installation_errors'),
          name: 'installation_errors',
        },
        {
          title: this.$t('app.labels.app_bundles.installations.created_at'),
          name: 'created_at',
          sortField: 'created_at',
        },
        {
          title: '',
          titleClass: 'text-nowrap',
          name: 'actions',
        },
      ],
    });
    notificationsSubscription: Nullable<Subscription> = null;

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    getFormattedDate(date: string): string {
      return moment(date).format(this.accountStore.dateTimePickerFormatWithTimeZone);
    }

    openStartModal(installation: AppBundleInstallation): void {
      this.appBundleInstallation = installation;
      this.formModalMode = 'metadata';
      this.modalVisible = true;
    }

    onSubmit(form: FormData): void {
      this.$api
        .createAppBundleInstallation(form)
        .then((resp) => {
          this.refresh();
          this.openStartModal(resp.data);
        })
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    onDestroy(id: string): void {
      this.$api
        .deleteAppBundleInstallation(id)
        .then(() => {
          this.refresh();
          this.closeModal();
        })
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    async onStartCopy(installation: AppBundleInstallation): Promise<void> {
      try {
        await this.$api.startCopyAppBundleInstallation(installation);
        this.refresh();
        this.closeModal();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const { data } = err;
        console.error(data?.error);
        toaster({ text: data?.error, position: 'top-right', icon: 'error' });
      }
    }

    onCreate(): void {
      this.appBundleInstallation = null;
      this.formModalMode = 'create';
      this.modalVisible = true;
    }

    showErrors(appBundleInstallation: AppBundleInstallation): void {
      this.appBundleInstallation = appBundleInstallation;
      this.formModalMode = 'errors';
      this.modalVisible = true;
    }

    closeModal(): void {
      this.formModalMode = 'create';
      this.appBundleInstallation = null;
      this.modalVisible = false;
    }

    hasErrors(appBundleInstallation: AppBundleInstallation): boolean {
      const installationErrors = appBundleInstallation.installation_errors;
      if (isEmpty(installationErrors)) {
        return false;
      }

      return !(
        isEmpty(installationErrors.base) &&
        isEmpty(installationErrors.dashboards) &&
        Object.keys(installationErrors.modules || {}).every((key) => isEmpty(installationErrors.modules[key])) &&
        isEmpty(installationErrors.regulatory_report_configs) &&
        isEmpty(installationErrors.placeholder_profiles) &&
        isEmpty(installationErrors.svg_library_templates)
      );
    }

    beforeDestroy(): void {
      this.notificationsSubscription?.unsubscribe();
    }

    refresh() {
      this.$api.cache.clear();
      this.table.reload();
    }

    mounted(): void {
      this.notificationsSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'AppBundle::Installation' },
        {
          received: this.refresh,
        }
      );
    }
  }
