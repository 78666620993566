
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { startCase } from 'lodash';
  import type { TextWidgetOptions, WidgetTranslatedOptions } from '@app/models/widget';
  import { TextWidgetAlignment, TextWidgetFontSize, TextWidgetFontWeight } from '@app/models/widget';

  import Select2 from '../../../select2.vue';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({
    components: { Select2, ValidationObserver, ValidationProvider },
  })
  export default class TextWidgetSettings extends BaseWidgetSettings<TextWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<TextWidgetOptions>;
    @PropSync('translated') widgetTranslatedOptions!: Partial<WidgetTranslatedOptions>;

    get textWidgetAlignment(): [TextWidgetAlignment, string][] {
      return Object.values(TextWidgetAlignment).map((x) => [x, startCase(x)]);
    }

    get textWidgetFontSize(): [TextWidgetFontSize, string][] {
      return Object.values(TextWidgetFontSize).map((x) => [x, startCase(x)]);
    }

    get textWidgetFontWeight(): [TextWidgetFontWeight, string][] {
      return Object.values(TextWidgetFontWeight).map((x) => [x, startCase(x)]);
    }

    beforeMount() {
      this.initializeWidgetOptions();
    }

    initializeWidgetOptions() {
      this.$set(this.widgetTranslatedOptions, 'text', this.widgetTranslatedOptions?.text || '');
      this.$set(this.widgetOptions, 'overrides', this.widgetOptions.overrides ?? { text_style: '' });
    }
  }
