import { render, staticRenderFns } from "./calculation-select-show-options.vue?vue&type=template&id=0c23c8eb&scoped=true&lang=pug"
import script from "./calculation-select-show-options.vue?vue&type=script&lang=ts"
export * from "./calculation-select-show-options.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c23c8eb",
  null
  
)

export default component.exports