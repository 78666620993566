
  import Select2 from '@app/components/select2.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { RestrictionDefault } from '@app/models/restriction-default';
  import { UserType } from '@app/models/tenant-user';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  @Component({
    components: { Select2 },
  })
  export default class AuthDefaultRestrictionConfigForm extends Vue {
    defaultRestrictions: RestrictionDefault[] = [];

    userTypes: UserType[] = Object.values(UserType);

    restrictionOptions = [
      { label: 'Enabled', value: 'false' },
      { label: 'Disabled', value: 'true' },
    ];

    form: Record<UserType, Record<'email' | 'saml', StringBoolean>> = {
      [UserType.TenantUser]: { email: 'false', saml: 'false' },
      [UserType.ContactUser]: { email: 'false', saml: 'false' },
      [UserType.ContractorUser]: { email: 'false', saml: 'false' },
      [UserType.MedicalUser]: { email: 'false', saml: 'false' },
      [UserType.VisitorUser]: { email: 'false', saml: 'false' },
    };

    onRestrictionChange(type: UserType, restriction: 'email' | 'saml', value: StringBoolean): void {
      if (value === 'true') {
        const otherRestriction = restriction === 'email' ? 'saml' : 'email';
        this.form[type][otherRestriction] === 'true' && this.setRestriction(type, otherRestriction, 'false');
      }
    }

    beforeMount(): void {
      this.fetchDefaultRestrictions(true).then(() => {
        this.defaultRestrictions.forEach((r) => this.setRestriction(r.restrict_on, r.restriction as 'email' | 'saml', `${r.restrict}`));
      });
    }

    fetchDefaultRestrictions(cache = false): Promise<void> {
      return this.$api
        .getRestrictionDefaults(
          { filters: { restriction: ['email', 'saml'], action: 'auth' }, only: ['id', 'restrict_on', 'restriction', 'restrict', 'action'] },
          { cache }
        )
        .then(({ data }) => {
          this.defaultRestrictions = data;
        });
    }

    setRestriction(type: UserType, restriction: 'email' | 'saml', value: StringBoolean): void {
      this.form = { ...this.form, [type]: { ...this.form[type], [restriction]: value } };
    }

    canAuthenticate(userType: UserType): boolean {
      return this.defaultRestrictions.filter((dr) => dr.restrict && dr.restrict_on === userType).length < 2;
    }

    getTitle(key: string): string {
      return key.replace('User', '');
    }

    async submit(): Promise<void> {
      this.fetchDefaultRestrictions().then(() => {
        return Object.keys(this.form)
          .map((type) => {
            return Object.keys(this.form[type as UserType])
              .map((restriction) => {
                const restrict = this.form[type as UserType][restriction as 'email' | 'saml'] === 'true';
                const defaultRestriction = this.defaultRestrictions.find(
                  (r) => r.restrict_on === (type as UserType) && r.restriction === restriction
                );
                if (defaultRestriction) {
                  if (defaultRestriction.restrict !== restrict) {
                    return this.$api.updateRestrictionDefault(defaultRestriction.id, {
                      ...defaultRestriction,
                      restrict,
                    });
                  }
                } else {
                  return this.$api.createRestrictionDefault({
                    restrict_on: type as UserType,
                    restriction: restriction as 'email' | 'saml',
                    restrict,
                    action: 'auth',
                  });
                }
              })
              .filter(Boolean);
          })
          .flat();
      });
    }
  }
