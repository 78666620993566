
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { toaster } from '@app/utils/toaster';
  import type { DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import type { Event } from '@app/models/event';
  import { changeLocation } from '@app/utils/change-location';

  import EventOccurrences from '../event-occurrences/event-occurrences.vue';

  @Component({ components: { EventOccurrences } })
  export default class AutomationDefinitionEventOccurrences extends Vue {
    @Prop({ type: [Number, String], required: true }) readonly automationDefinitionId!: number | string;
    automationDefinition: Nullable<AutomationDefinition> = null;

    get eventIds(): number[] {
      return this.automationDefinition?.events?.map((e) => [e.id, ...(e.chained_events?.map(({ id }) => id) || [])]).flat() || [];
    }

    get eventFilters(): DonesafeIndexApiOptions<Event> {
      return { automation_definition: { id: this.automationDefinitionId } };
    }

    get tab(): string {
      // TODO: use query or props
      return this.$route.params.tab;
    }

    beforeMount(): void {
      this.$api
        .getAutomationDefinition(this.automationDefinitionId, { only: [{ events: ['id', { chained_events: ['id'] }] }] }, { cache: true })
        .then(({ data }) => {
          this.automationDefinition = data;
        })
        .catch(() => {
          toaster({ text: 'Automation does not exist', icon: 'error' });
          changeLocation(`/admin/automation-definitions${this.tab ? `?tab=${this.tab}` : ''}`);
        });
    }
  }
