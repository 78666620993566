import type { EventWithTempId, GroupOrEvent, GroupWithTempId } from '@app/components/admin/automation-definitions/with-automation-submit';
import { cloneDeep, flatten, min, omit, sortBy } from 'lodash';

type TempId = string | number;
type Red<T = TempId[]> = Record<TempId, T>;

export const buildEventsTree = (_events: EventWithTempId[], _groups: GroupWithTempId[]) => {
  const events = cloneDeep(_events) as EventWithTempId[];
  const groups = cloneDeep(_groups) as GroupWithTempId[];

  const [groupMap, childrenMap, childrenEventsMap] = groups.reduce<[Red<GroupWithTempId>, Red<GroupWithTempId[]>, Red<EventWithTempId[]>]>(
    ([gMemo, cMemo, autMemo], group) => {
      gMemo[group.id] = group;
      cMemo[group.id] = groups.filter((g) => g.parent_id === group.id);
      autMemo[group.id] = events.filter((event) => event.automation_event_group_id == group.id);

      return [gMemo, cMemo, autMemo];
    },
    [{}, {}, {}]
  );

  const [descendantGroupIdsMap, descendantEventsMap, descendantEventsIdsMap] = groups.reduce<[Red, Red<EventWithTempId[]>, Red]>(
    ([desGroupIdsMemo, eventsMemo, eventIdsMemo], group) => {
      // DESCENDANT GROUP IDS MAP
      const findDescendants = (group: GroupWithTempId): TempId[] =>
        childrenMap[group.id].reduce((memo, children) => [...memo, children.id, ...findDescendants(children)], [] as TempId[]);

      desGroupIdsMemo[group.id] = findDescendants(group);

      // DESCENDANT EVENTS MAP
      const groupIds = [group.id, ...desGroupIdsMemo[group.id]];
      eventsMemo[group.id] = flatten(groupIds.map((groupId) => childrenEventsMap[groupId].map((event) => event)));

      // DESCENDANT EVENTS IDS MAP
      eventIdsMemo[group.id] = (eventsMemo[group.id] as EventWithTempId[]).map((e) => e.id);

      return [desGroupIdsMemo, eventsMemo, eventIdsMemo];
    },
    [{}, {}, {}]
  );

  const [rootMap, indexGroupMap] = groups.reduce<[Red<GroupWithTempId>, Red<number>]>(
    ([rootMemo, indexMemo], group) => {
      // ROOT MAP
      const findRoot = (g: GroupWithTempId): GroupWithTempId =>
        !g.parent_id || !groupMap[g.parent_id] ? g : findRoot(groupMap[g.parent_id]);

      rootMemo[group.id] = findRoot(group);

      // INDEX GROUP MAP
      indexMemo[group.id] = group.index || min(descendantEventsMap[group.id].map((e) => e.index)) || 0;

      return [rootMemo, indexMemo];
    },
    [{}, {}]
  );

  function tree({ displayEmptyGroups } = { displayEmptyGroups: false }): GroupOrEvent[] {
    let visitedEventIds: TempId[] = [];
    let visitedGroupIds: TempId[] = [];

    const checkAllGroups = displayEmptyGroups && groups.every((g) => g.index) && events.every((e) => e.index);
    const sortedItems: GroupOrEvent[] = checkAllGroups ? sortBy([...groups, ...events], 'index') : events;

    const groupsAndEvents = sortedItems.reduce<GroupOrEvent[]>((acc, item) => {
      if ('parent_id' in item) {
        // ITEM IS A GROUP
        if (visitedGroupIds.includes(item.id)) return acc;

        const rootGroup = rootMap[item.id];
        visitedGroupIds = [rootGroup.id, ...descendantGroupIdsMap[rootGroup.id]];
        visitedEventIds = [...visitedEventIds, ...descendantEventsIdsMap[rootGroup.id]];

        acc.push(buildTreeFromRootGroup(rootGroup));
      } else {
        // ITEM IS AN EVENT
        if (visitedEventIds.includes(item.id)) return acc;

        visitedEventIds.push(item.id);
        if (!item.automation_event_group_id) return [...acc, { ...item }];

        const group = groupMap[item.automation_event_group_id];
        if (!group || visitedGroupIds.includes(group.id)) return [...acc, { ...item }];

        const rootGroup = rootMap[group.id];
        visitedEventIds = [...visitedEventIds, ...descendantEventsIdsMap[rootGroup.id]];
        visitedGroupIds = [...visitedGroupIds, group.id, ...descendantGroupIdsMap[group.id]];
        acc = [...acc, buildTreeFromRootGroup(rootGroup)];
      }

      return acc;
    }, []);

    return sortBy(groupsAndEvents, 'index');
  }

  function buildTreeFromRootGroup(group: GroupWithTempId): GroupOrEvent {
    group.index = indexGroupMap[group.id];
    group.items = childrenMap[group.id].reduce<GroupOrEvent[]>((memo, childGroup) => {
      const groupOrEvent = buildTreeFromRootGroup(childGroup as GroupWithTempId);
      groupOrEvent && memo.push(groupOrEvent);
      return memo;
    }, []);

    if (!descendantEventsMap[group.id]) return group;
    childrenEventsMap[group.id].forEach((event) => {
      group.items.push({ ...event });
    });

    if (!group.items) return group;
    group.items = sortBy(group.items, 'index');

    return group;
  }

  return {
    tree,
    buildTreeFromRootGroup,
    descendantGroupIdsMap,
    descendantEventsMap,
    rootMap,
  };
};

type ParseType = { events: EventWithTempId[]; groups: GroupWithTempId[] };
export const parseEventTree = (tree: GroupOrEvent[]): ParseType => {
  let eventIndex = 1;
  const newGroups: GroupWithTempId[] = [];
  const newEvents: EventWithTempId[] = [];

  const parse = (items: GroupOrEvent[], parentId: TempId | null) => {
    items.forEach((item) => {
      // Check if it's an event early return
      if ('event_type' in item) return newEvents.push({ ...item, automation_event_group_id: parentId || null, index: eventIndex++ });

      // if it's a group recursive call
      const group = omit(item, 'items');
      newGroups.push({ ...group, parent_id: parentId || null, index: eventIndex } as GroupWithTempId);
      parse(item.items, item.id);
    });
  };
  parse(tree, null);

  return {
    groups: newGroups,
    events: newEvents,
  };
};
