
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import OrganizationSelector from '../organization/organization-selector.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, OrganizationSelector } })
  export default class UserActiveOrganizationsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly user!: Pick<TenantUser, 'organization_ids' | 'skip_explicit_organization_restrictions'>;

    @Emit('submit')
    submit(): Partial<Pick<TenantUser, 'organization_ids' | 'skip_explicit_organization_restrictions'>> {
      return { ...this.form, organization_ids: [...(this.user?.organization_ids || []), ...this.newUserOrganizationIds] };
    }

    form: Partial<Pick<TenantUser, 'organization_ids' | 'skip_explicit_organization_restrictions'>> = {};
    newUserOrganizationIds: number[] = [];

    initForm(): void {
      this.form = {
        skip_explicit_organization_restrictions: this.user.skip_explicit_organization_restrictions,
        organization_ids: [],
      };
      this.newUserOrganizationIds = [];
    }

    beforeMount(): void {
      this.initForm();
    }
  }
