
  import Select2 from '@app/components/select2.vue';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { AUTOMATION_STRUCTURE, AutomationTriggerOn } from '@app/models/automation-definition';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { displayModuleName } from '@app/utils/display-module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { ModuleName } from '@app/models/module-name';
  import { castArray, compact, groupBy, keyBy, uniq } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  import FormField from '../questions/edit/form-field.vue';

  import DsSection from './ds-section.vue';
  import type { Form } from './models';

  @Component({
    components: {
      DsIconText,
      Select2,
      FormField,
      DsSection,
    },
  })
  export default class AutomationTriggerForm extends Vue {
    @Model() readonly form!: Form;

    @Prop(Object) readonly automationRuleSetBuilder!: AutomationRuleSetBuilder;

    autoSave = false;
    hardDelete = false;
    triggeringFieldsOptions: [string, string][] = [];

    subForm: Nullable<SubForm> = null;
    moduleName: Nullable<ModuleName> = null;

    get triggeredMultipleTimes(): boolean {
      return !!this.form.trigger_on && [AutomationTriggerOn.ConditionsMet].includes(this.form.trigger_on);
    }

    get triggerOnWarnings(): string | undefined {
      if (this.hardDeleteWarning) {
        return this.$t('tenant.admin.automation_definitions.form.hard_delete_warning');
      }

      if (this.autoSaveWarning) {
        return this.$t('tenant.admin.automation_definitions.form.auto_save_warning');
      }

      if (this.autoSaveDraftWarning) {
        return this.$t('tenant.admin.automation_definitions.form.auto_save_draft_warning');
      }
    }

    get hardDeleteWarning() {
      return this.hardDelete && this.form.trigger_on === AutomationTriggerOn.Delete;
    }

    get autoSaveWarning() {
      return this.autoSave && this.form.trigger_on === AutomationTriggerOn.Create;
    }

    get autoSaveDraftWarning() {
      return this.autoSave && this.form.trigger_on === AutomationTriggerOn.FieldUpdate;
    }

    get triggerWhenOptions(): [string, string][] {
      return [
        ['always', this.$t('tenant.admin.automation_definitions.form.trigger_always')],
        ['once', this.$t('tenant.admin.automation_definitions.form.trigger_once')],
      ];
    }

    get triggerOnOptions(): [string, string][] {
      return [
        [AutomationTriggerOn.Create, this.$t('tenant.admin.automation_definitions.triggers.create', { type: this.triggerDisplayName })],
        this.fieldUpdateTriggerAvailable && ['field_update', this.$t('tenant.admin.automation_definitions.triggers.field_update')],
        [AutomationTriggerOn.Delete, this.$t('tenant.admin.automation_definitions.triggers.delete', { type: this.triggerDisplayName })],
        !this.isRelation && [AutomationTriggerOn.ConditionsMet, this.$t('tenant.admin.automation_definitions.triggers.conditions_met')],
      ].filter((v) => !!v) as [string, string][];
    }

    get triggerDisplayName() {
      if (this.form.concept_name) {
        return displayModuleName(this.form.concept_name, this.subForm, keyBy(compact([this.moduleName]), 'name'), this.$t);
      }

      return '';
    }

    get subFormTrigger() {
      return this.form.concept_name === 'SubFormCompletion';
    }

    get hasTriggeringFields() {
      return this.form.trigger_on === 'field_update';
    }

    get isSubForm() {
      return !!this.form.sub_form_id && this.form.concept_name === 'SubFormCompletion';
    }

    get isMainForm() {
      const hardcodedConceptName =
        !!AUTOMATION_STRUCTURE[this.form.concept_name || ''] ||
        Object.values(AUTOMATION_STRUCTURE)
          .flat()
          .some((v) => v === this.form.concept_name);

      return !this.isSubForm && !hardcodedConceptName && !this.isRelation && !this.isInvolvement;
    }

    get isRelation() {
      return this.form.concept_name == 'RecordRelation';
    }

    get isInvolvement() {
      return this.form.concept_name == 'UserInvolvement';
    }

    get fieldUpdateTriggerAvailable() {
      return this.isSubForm || this.isMainForm;
    }

    @Watch('form.sub_form_id')
    async checkForWarnings() {
      let autoSave = false;
      let hardDelete = ['Procedure', 'TenantUser'].includes(this.form.concept_name || '');

      await this.loadData();

      if (this.subForm) {
        const { sub_form_lists = [] } = this.subForm;

        autoSave = sub_form_lists.some(({ auto_save }) => auto_save);
      }

      if (this.moduleName) {
        hardDelete ||= this.moduleName.hard_delete_records;
      }

      this.autoSave = autoSave;
      this.hardDelete = hardDelete;
    }

    beforeMount() {
      this.loadTriggeringFields();
      this.checkForWarnings();
    }

    async loadData() {
      let moduleName = this.form.concept_name;

      if (this.subFormTrigger && !!this.form.sub_form_id) {
        const subForm = await this.loadSubForm();

        if (subForm) {
          const { module_name } = subForm;

          moduleName = module_name;
        }
      }

      await this.loadModuleName(moduleName);
    }

    async loadSubForm() {
      if (this.subFormTrigger && !!this.form.sub_form_id) {
        const { data } = await this.$api.getSubForm(
          this.form.sub_form_id,
          { only: ['id', 'title', 'sub_form_lists', 'approval_sub_form_list', 'module_name'] },
          { cache: true }
        );

        this.subForm = data;

        return data;
      }
    }

    async loadModuleName(name?: string) {
      if (name) {
        const {
          data: [moduleName],
        } = await this.$api.getModuleNames({ filters: { name }, only: ['name', 'display', 'hard_delete_records'] }, { cache: true });

        this.moduleName = moduleName;

        return moduleName;
      }
    }

    onRuleBuilderChange(value?: QueryBuilderBlob): void {
      this.$$patch(this.form, { rule_builder_rules: value ? value : null });
    }

    async loadTriggeringFields() {
      const optionsFromForm = (f: SubForm, options: { namePrefix?: string; prefix?: string } = {}): [string, string][] => {
        const withDupCodes = compact(f.sub_form_sections?.flatMap((s) => s.sub_form_questions) || []);
        const byCode = groupBy(withDupCodes, ({ code }) => code);

        return Object.keys(byCode).map((code) => optionFromQuestion(byCode[code], options));
      };

      const optionFromQuestion = (
        sameCodeQs: SubFormQuestion | SubFormQuestion[],
        { prefix = '', namePrefix = '' } = {}
      ): [string, string] => {
        sameCodeQs = castArray(sameCodeQs);

        const titles = uniq(sameCodeQs.map((q) => q.title).filter((v) => !!v)).join(' | ');

        const [{ code }] = sameCodeQs;

        return [[prefix, code].filter((v) => !!v).join('.'), [namePrefix, titles].filter((v) => !!v).join('')];
      };

      const subFormProjection = { include: ['sub_form_sections', 'sub_form_questions'] };

      let moduleCode = this.form.concept_name;
      let currentForm;
      let mainForm;
      let moduleName: ModuleName | undefined;

      if (this.isSubForm) {
        const { data } = await this.$api.getSubForm(Number(this.form.sub_form_id), subFormProjection, { cache: true });

        currentForm = data;
        moduleCode = data.module_name;
      }

      if (this.isMainForm || this.isSubForm) {
        const { data } = await this.$api.getModuleNames(
          {
            filters: { name: moduleCode },
            include: ['display', 'main_form', 'sub_form_sections', 'sub_form_questions'],
            only: ['id', 'display', { main_form: ['sub_form_sections', 'id', 'system_code'] }],
          },
          { cache: true }
        );

        moduleName = data[0];
        mainForm = moduleName.main_form;
        currentForm ||= mainForm;
      }

      const asCurrentForm = ({ id }: SubForm) => ({ prefix: `@form[${id}]`, namePrefix: 'Question: ' });

      const { data: indicators } = await this.$api.getIndicatorSets({ filters: { module_name: moduleCode } }, { cache: true });

      const indicatorOptions: [string, string][] = indicators.map(({ name, system_code }) => [
        `@indicator[${system_code}]`,
        `${this.$t('app.labels.indicator_set')}: ${name}`,
      ]);

      const mainFormOptions: [string, string][] = [['workflow', this.$t('app.labels.workflow')]];

      const subFormOptions: [string, string][] = [['stage', this.$t('app.labels.stage')]];

      return (this.triggeringFieldsOptions = [
        ...(this.isSubForm ? subFormOptions : []),
        ...(this.isMainForm ? mainFormOptions : []),
        ...(currentForm ? optionsFromForm(currentForm, asCurrentForm(currentForm)) : []),
        ...(this.isSubForm ? [] : indicatorOptions),
      ]);
    }
  }
