
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import IndicatorSetForm from '../../../components/admin/indicator-sets/indicator-set-form.vue';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { ModuleName } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { IndicatorSetForm } })
  export default class IndicatorSetNew extends Vue {
    @Prop({ type: [Number, String] }) readonly moduleNameId?: number | string;
    @Ref() readonly form?: IndicatorSetForm;

    moduleName: Nullable<Pick<ModuleName, 'id' | 'name'>> = null;

    beforeMount(): void {
      this.moduleNameId &&
        this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true }).then(({ data }) => {
          this.moduleName = data;
        });
    }

    onFormSubmit(form: Partial<IndicatorSet>): void {
      this.moduleName &&
        this.$api
          .createIndicatorSet({
            ...form,
            module_name: this.moduleName.name,
          })
          .then(({ data }) => {
            this.$api.cache.clear();
            this.$router.replace({
              name: 'admin-indicator-set-show',
              params: { indicatorSetId: `${data.id}` },
              query: this.$route.query,
            });
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
