import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { BaseActivityAllowedKeys } from './utils';
import { canEditContent, getActivityUiState } from './utils';
import type { SubmitCommentEvent } from '@app/services/api/comments-api';
import type { Activity } from '@app/models/activity';
import { ActivityState } from '@app/models/activity';
import { SpecialPermissionName } from '@app/models/extended-permission';
import { ACTIVITY_CONCEPT } from '@app/constants';

@Component
export default class BaseActivity<T extends Pick<Activity, BaseActivityAllowedKeys>> extends Vue {
  @Prop(Object) readonly activity!: T;

  // comment modal state

  commentModalState = false;
  commentMode = 'comment';
  commentModalTitle = this.$t('app.labels.add_new_comment');

  get accountStore() {
    return useAccountStore();
  }

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get uiState() {
    return getActivityUiState(this.activity.status);
  }

  // formatted data

  get id() {
    return this.activity.id;
  }

  get assignedUser() {
    return this.activity.assignee;
  }

  get completedByUser() {
    return this.activity.completed_by;
  }

  get assignedApprover() {
    return this.activity.assigned_approver;
  }

  get formattedDate() {
    return this.accountStore.dateFormat(this.activity?.date);
  }

  get formattedClosedAt() {
    return this.accountStore.dateTimeFormat(this.activity?.closed_at);
  }

  get approvedBy() {
    return this.activity.approved_by;
  }

  get approvedDate() {
    return this.accountStore.dateTimeFormat(this.activity?.approved_at);
  }

  get dueDate() {
    return this.formattedDate;
  }

  // permissions

  get canEdit() {
    return this.activity.can_edit;
  }

  get canAddComments() {
    return (
      this.canEdit || this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.allow_commenting_without_edit, ACTIVITY_CONCEPT)
    );
  }

  get hasApprover() {
    return !!this.activity.assigned_approver_id;
  }

  get requiresApproval() {
    return this.activity.requires_approval && this.notApproved;
  }

  get canMarkAsComplete() {
    return this.canEdit && this.activity.state === ActivityState.Open;
  }

  get canReopen() {
    return this.canEdit && this.activity.state === ActivityState.Closed;
  }

  get showPriorities(): boolean {
    return this.accountStore.data.action_options.show_action_priorities === 'true';
  }

  get notApproved() {
    return this.activity.state === ActivityState.Closed && !this.activity.approved;
  }

  get canApproveBase() {
    return this.canApprove && this.accountStore.data.enable_action_approvals;
  }

  get canApprove(): boolean {
    return !!this.activity.can_approve;
  }

  get showApprovalButton() {
    return this.requiresApproval && this.canApprove;
  }

  get canApproveIfNotApproved() {
    return this.notApproved && this.canApproveBase;
  }

  get canUnapprove() {
    return this.activity.approved && this.canApproveBase;
  }

  get canReassignApprover() {
    return this.hasApprover && (canEditContent(this.activity?.user?.id) || this.canApprove);
  }

  get renderCommentModal() {
    return this.activity.require_completion_comment || this.canApprove || this.canReopen || this.canAddComments;
  }

  reviewAndApprove(e: MouseEvent): void {
    this.$emit('review-approve', e);
  }

  submitComment({ data, mode }: SubmitCommentEvent) {
    this.commentModalState = false;
    this.commentMode = 'comment';
    this.commentModalTitle = this.$t('app.labels.add_new_comment');

    switch (mode) {
      case 'approve':
        this.$emit('approve', data);
        break;
      case 'unapprove':
        this.$emit('unapprove', data);
        break;
      case 'reopen':
        this.$emit('reopen', data);
        break;
      case 'close':
        this.$emit('close', data);
      case 'comment':
      default:
        this.$emit('comment', data);
        break;
    }
  }

  reopenActivity(e: MouseEvent) {
    this.commentMode = 'reopen';
    this.commentModalState = true;
    this.commentModalTitle = this.$t('app.labels.add_reopen_comment_to_this_action');
  }

  approveActivity(e: MouseEvent) {
    this.commentMode = 'approve';
    this.commentModalState = true;
    this.commentModalTitle = this.$t('app.labels.add_approval_comment_to_this_action');
  }

  unapproveActivity(e: MouseEvent) {
    this.commentMode = 'unapprove';
    this.commentModalState = true;
    this.commentModalTitle = this.$t('app.labels.add_unapproval_comment_to_this_action');
  }

  completeActivity(e: MouseEvent) {
    if (this.activity.require_completion_comment) {
      this.commentModalState = true;
      this.commentMode = 'close';
      this.commentModalTitle = this.$t('app.labels.add_closing_comment_to_this_action');
    } else {
      this.$emit('close', {});
    }
  }

  addComment(e: MouseEvent) {
    this.commentModalState = true;
    this.commentMode = 'comment';
    this.commentModalTitle = this.$t('app.labels.add_new_comment');
  }
}
