
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import type { ApiRequestOption } from '../../../../models/question-options/shared';
  import ApiRequestOptionItem from './api-request-option-item.vue';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  @Component({ components: { ApiRequestOptionItem, Select2 } })
  export default class ApiRequestOptionsEditor extends Vue {
    @Model('input') readonly value!: ApiRequestOption[];
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly injectableOptions!: PlaceholderItem[];

    get options(): ApiRequestOption[] {
      return this.value || [];
    }

    onOptionRemove(index: number): void {
      this.$emit('input', this.options.slice(0, index).concat(this.options.slice(index + 1)));
    }

    addOption(): void {
      this.$emit('input', [...this.options, { key: '', value: '' }]);
    }
  }
