import { SUB_FORM_COMPLETION_SHOW_ONLY } from '@app/components/sub-form-completion/utils';
import type { ModuleRecord } from '@app/models/module-record';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export interface PrintRecord extends Pick<ModuleRecord, PrintRecordOnly> {}

export const PRINT_RELATION_ITEM_ONLY = Tuple(['title', 'uniq_id', { module_name: ['display'] }] as const);
export type PrintRelationItemOnly = FirstLevelKeyLiterals<(typeof PRINT_RELATION_ITEM_ONLY)[number]>;

export const PRINT_RELATION_ONLY = Tuple([
  'id',
  'from_type',
  'from_id',
  'to_type',
  'to_id',
  { to: PRINT_RELATION_ITEM_ONLY },
  { from: PRINT_RELATION_ITEM_ONLY },
] as const);
export type PrintRelatedOnly = FirstLevelKeyLiterals<(typeof PRINT_RELATION_ONLY)[number]>;

export const PRINT_ACTIVITIES_ONLY = Tuple([
  'id',
  'description',
  'state',
  'date',
  'comment',
  { assignee: ['full_name'] },
  'comments',
] as const);
export type PrintActivitiesOnly = FirstLevelKeyLiterals<(typeof PRINT_ACTIVITIES_ONLY)[number]>;

export const PRINT_SUB_FORM_LISTS_ONLY = Tuple([
  'id',
  'index',
  'sub_form_ids',
  'sub_form_list_type',
  'order_options',
  'title',
  'response_counter_options',
  'relationship_options',
  'default_template_id',
  'add_background_color',
  'options',
  'record_relation_question_id',
  'record_relations_filters',
  'involvement_id',
  'active',
  { sub_forms: ['id', 'active', 'title'] },
] as const);
export type PrintSubFormListsOnly = FirstLevelKeyLiterals<(typeof PRINT_SUB_FORM_LISTS_ONLY)[number]>;

const PRINT_MODULE_TABS_ONLY = Tuple(['id', 'title', 'active', 'index', { sub_form_lists: PRINT_SUB_FORM_LISTS_ONLY }] as const);
export type PrintModuleTabsOnly = FirstLevelKeyLiterals<(typeof PRINT_MODULE_TABS_ONLY)[number]>;

export const PRINT_MODULE_NAME_ONLY = Tuple(['id', 'name', 'display', 'show_options', { module_tabs: PRINT_MODULE_TABS_ONLY }] as const);
export type PrintModuleNameOnly = FirstLevelKeyLiterals<(typeof PRINT_MODULE_NAME_ONLY)[number]>;

export const PRINT_RECORD_ONLY = Tuple([
  'id',
  'location_id',
  'organization_id',
  'user_id',
  'workflow_id',
  'module_name_id',
  'uniq_id',
  'title',
  'score',
  'indicators',
  'related_records',
  'attachments',
  { sub_form_completion: [...SUB_FORM_COMPLETION_SHOW_ONLY, 'sub_form_list_id'] },
  { module_name: PRINT_MODULE_NAME_ONLY },
  { user_involvements: ['id', 'involvement_id', 'user'] },
] as const);
export type PrintRecordOnly = FirstLevelKeyLiterals<(typeof PRINT_RECORD_ONLY)[number]>;

export const PRINT_COMPLETION_ONLY = Tuple([...SUB_FORM_COMPLETION_SHOW_ONLY, 'sub_form_list_id', 'approval_form', 'title'] as const);
export type PrintCompletionOnly = FirstLevelKeyLiterals<(typeof PRINT_COMPLETION_ONLY)[number]>;
