import { Tuple } from '@app/utils/types/tuple';

export const AUDIT_REPORT_TEMPLATE_ONLY = Tuple([
  'attachment',
  'id',
  'module_name_id',
  'name',
  'purview',
  'report_format',
  'report_type',
  'row_limit',
  'sub_form_list_id',
  'user_collection_id',
  'visibility',
] as const);
