
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Icon, addIcon } from '@iconify/vue2/dist/offline';
  import plus from '@iconify-icons/typcn/plus';
  import minus from '@iconify-icons/typcn/minus';
  import times from '@iconify-icons/typcn/times';
  import divide from '@iconify-icons/typcn/divide';
  import chevronLeft from '@iconify-icons/typcn/chevron-left';
  import chevronRight from '@iconify-icons/typcn/chevron-right';
  import equals from '@iconify-icons/typcn/equals';
  import type { Dictionary } from '@app/models/dictionary';

  addIcon('typcn:plus', plus);
  addIcon('typcn:minus', minus);
  addIcon('typcn:times', times);
  addIcon('typcn:divide', divide);
  addIcon('typcn:chevron-left', chevronLeft);
  addIcon('typcn:chevron-right', chevronRight);
  addIcon('typcn:equals', equals);

  interface IconObject {
    icon: string;
    style?: Dictionary<string>;
  }

  @Component({ components: { Icon } })
  export default class BasicElement extends Vue {
    @Prop({ required: true }) readonly value!: string;
    @Prop() readonly title?: string;
    @Prop() readonly renderIcon?: boolean;

    get currentIcon(): IconObject[] | string {
      const valueToIcon: Dictionary<IconObject[] | string> = {
        '+': [{ icon: 'typcn:plus' }],
        '-': [{ icon: 'typcn:minus' }],
        '*': [{ icon: 'typcn:times' }],
        '/': [{ icon: 'typcn:divide' }],
        '^': '^',
        '<': [{ icon: 'typcn:chevron-left' }],
        '>': [{ icon: 'typcn:chevron-right' }],
        '<=': [{ icon: 'typcn:chevron-left', style: { marginLeft: '-3px' } }, { icon: 'typcn:equals' }],
        '>=': [{ icon: 'typcn:chevron-right', style: { marginLeft: '-3px' } }, { icon: 'typcn:equals' }],
        '=': [{ icon: 'typcn:equals' }],
        IF: 'IF',
        ELSIF: 'ELSE IF',
        ELSE: 'ELSE',
      };

      return valueToIcon[this.value] ? valueToIcon[this.value] : this.value;
    }
  }
