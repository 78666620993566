
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import InputGroup from '@app/components/admin/questions/edit/input-group.vue';
  import UniqQuestionConfig from '@app/components/admin/questions/settings/shared/uniq-question-config.vue';
  import ButtonSelect from '@app/components/button-select.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { CalculationTextQuestionOptions } from '@app/models/question-options/calculation-text-question-options';
  import {
    CalculationTextDecimalHandlingBehaviour,
    CalculationTextFormatValidation,
  } from '@app/models/question-options/calculation-text-question-options';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { convertToDatabaseValue, convertToDisplayValue } from '@app/services/calculation';
  import { useAccountStore } from '@app/stores/account';
  import { enumToOptions } from '@app/utils/enumToOptions';
  import { Component, Model, Vue, Watch } from 'vue-property-decorator';
  import { isNil } from 'lodash';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const numberIsInteger = (value: any) => typeof value === 'number' && isFinite(value) && Math.floor(value) === value;

  @Component({
    components: { ButtonSelect, DsCheckbox, FormField, InputGroup, Select2, UniqQuestionConfig },
  })
  export default class CalculationTextFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<CalculationTextQuestionOptions>;

    hasDefaultValue = false;
    hasBlankValue = false;

    tempDefaultValue = '';
    tempBlankValue = '';

    get decimalHandlingOptions() {
      return enumToOptions(CalculationTextDecimalHandlingBehaviour);
    }

    get valueRules() {
      const double = this.decimal && (!!this.value.config.decimal_places ? { decimals: this.value.config.decimal_places } : true);

      return { integer: this.integer, double };
    }

    get decimal(): boolean {
      return this.currency || this.percentage || this.number;
    }

    get currency(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.currency;
    }

    get percentage(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.percentage;
    }

    get text(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.text;
    }

    get auto(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.auto;
    }

    get number(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.number;
    }

    get integer(): boolean {
      return this.value.config.format_validation === CalculationTextFormatValidation.integer;
    }

    get currencySymbol(): string {
      return this.value.config.currency_symbol || this.defaultCurrency;
    }

    get accountStore() {
      return useAccountStore();
    }

    get defaultCurrency(): string {
      return this.accountStore.data.currency_symbol;
    }

    get override() {
      return this.value.config.visibility_override === 'override';
    }

    get calculationValueDefault() {
      return this.value.config.default?.type === 'calculation_value';
    }

    get defaultValueEnabled() {
      if (!this.calculationValueDefault) {
        return false;
      }

      return this.hasDefaultValue || !this.override;
    }

    get calculationTextOptions() {
      return this.hashToOptions({
        currency: 'Currency',
        number: 'Number',
        integer: 'Integer',
        percentage: 'Percentage',
        text: 'Text',
        auto: 'Auto (auto identify numbers and text)',
      }) as [CalculationTextFormatValidation, string][];
    }

    get visibilityOverrideOptions() {
      return this.hashToOptions({
        override: 'Editable',
        hide: 'Hidden',
        show: 'Read Only',
      });
    }

    get defaultValue() {
      const { format_validation } = this.value.config;

      return convertToDisplayValue((this.hasDefaultValue && this.tempDefaultValue) || '', format_validation);
    }

    get blankValue() {
      const { format_validation } = this.value.config;

      return convertToDisplayValue((this.hasBlankValue && this.tempBlankValue) || '', format_validation);
    }

    @Watch('value.config.default.type')
    onDefaultValueTypeChange() {
      if (!this.calculationValueDefault) {
        this.hasDefaultValue = false;
        this.onTempDefaultValueChange('');
      }
    }

    beforeMount() {
      const { default_value_amount, blank_value_amount } = this.value.config;

      if (!isNil(default_value_amount)) {
        this.hasDefaultValue = true;
        this.tempDefaultValue = default_value_amount;
      }

      if (!isNil(blank_value_amount)) {
        this.hasBlankValue = true;
        this.tempBlankValue = blank_value_amount;
      }

      this.$$patch(
        this.value.config,
        {
          visibility_override: 'override',
          decimal_handling_behaviour: CalculationTextDecimalHandlingBehaviour.Floor,
          format_validation: this.calculationTextOptions[0][0],
        },
        { ifEmpty: true }
      );
    }

    onTempDefaultValueChange(value: string) {
      this.tempDefaultValue = value;

      if (!this.isValidInput(value)) return;

      this.value.config.default_value_amount = '' + this.convertValue(value);
    }

    onTempBlankValueChange(value: string) {
      this.tempBlankValue = value;

      if (!this.isValidInput(value)) return;

      this.value.config.blank_value_amount = '' + this.convertValue(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isValidInput(value: any) {
      if (!this.hasBlankValue && !value) return true;

      if (this.decimal) {
        value = parseFloat(value);

        if (isNaN(value)) return false;

        const decimalPlaces = this.value.config.decimal_places;

        if (!decimalPlaces || !(parseInt(decimalPlaces) > 0)) {
          return numberIsInteger(value);
        }

        return true;
      }

      return true;
    }

    convertValue(value: string) {
      const { format_validation, decimal_places, decimal_handling_behaviour } = this.value.config;

      return convertToDatabaseValue(value, format_validation, decimal_places, decimal_handling_behaviour);
    }

    hashToOptions(hash: Dictionary<string>) {
      return Object.keys(hash).map((k) => [k, hash[k]]);
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['format_validation', 'visibility_override', 'allow_clear_response'].forEach((key) => this.$delete(this.value.config, key));
      this.$delete(this.value.options, 'values');
    }
  }
