import { Vue, Component, Model, Prop } from 'vue-property-decorator';
import type { ReportBlobFilter } from '@app/models/super-report';

@Component
export default class FilterValidation extends Vue {
  @Model('input') readonly value!: ReportBlobFilter;
  @Prop(String) readonly name!: string;

  checkValidation(): void {
    const valid = !!this.value.include_blank || !!this.value.value?.length;
    $('.add-filter-button').prop('disabled', !valid);
  }

  mounted(): void {
    this.checkValidation();
  }
}
