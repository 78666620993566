
  import DsTextInput from '@app/components/ds-text-input.vue';
  import Select2 from '@app/components/select2.vue';
  import type { MenuCollection } from '@app/models/menu-collection';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import type { MenuCollectionsFormOnly } from '@app/pages/admin/menu_collections/utils';

  @Component({ components: { DsTextInput, Select2, ValidationObserver } })
  export default class MenuCollectionsForm extends Vue {
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly menuCollection!: Partial<Pick<MenuCollection, MenuCollectionsFormOnly>>;
    @Prop(Boolean) readonly disabled!: boolean;

    form: Partial<MenuCollection> = {};

    iconListTemplate(option: { text: string; id: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      return $(
        `<div class="d-flex align-items-center">
            <i class="material-icons m-r-xs" style="overflow:hidden;">${option.id}</i>
            <span>${option.id}</span>
          </div>`
      );
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    beforeMount(): void {
      this.form = { ...this.menuCollection };
    }
  }
