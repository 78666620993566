
  import Select2 from '@app/components/select2.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import { CalculationMethod, RoundingBehaviour, VisualFormat } from '@app/models/record-calculation';
  import { enumToOptions } from '@app/utils/enumToOptions';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { CURRENCIES } from '@app/models/currencies';
  import { currencyTitle } from '@app/services/locale';
  import { useAccountStore } from '@app/stores/account';
  import FormField from '../admin/questions/edit/form-field.vue';
  import DsCheckbox from '../ds-checkbox.vue';

  @Component({ components: { FormField, DsCheckbox, Select2 } })
  export default class RecordCalculationVisualOptionsForm extends Vue {
    @Model('input') value!: RecordCalculation;
    @Prop(Object) readonly recordCalculation!: Partial<RecordCalculation>;

    displayValueFormatOptions = enumToOptions(VisualFormat, true);

    get roundingBehaviourOptions(): [string, string][] {
      return Object.keys(RoundingBehaviour).map((key) => {
        var name =
          key === RoundingBehaviour.default
            ? this.$t('components.admin.record_calculations.rounding_behaviours.do_not_round')
            : this.$t('components.admin.record_calculations.rounding_behaviours.round');
        return [key, name];
      });
    }

    get currencySymbolOptions(): [string, string][] {
      return Object.keys(CURRENCIES).map((currency) => {
        const title = currencyTitle(currency, window.DONESAFE.defaultLocale);
        return [currency, `${CURRENCIES[currency]} - ${title}`];
      });
    }

    get accountStore() {
      return useAccountStore();
    }

    get currencyCode() {
      return this.accountStore.currencyCode;
    }

    get isNonRecordCountCalculationMethod(): boolean {
      return this.value.calculation_method !== CalculationMethod.record_count;
    }

    onRoundingBehaviourChange(behaviour: RoundingBehaviour): void {
      if (behaviour === RoundingBehaviour.round) {
        this.$emit('input', { ...this.value, options: { ...this.value.options, visual_trailing_zeros: true } });
      }
    }
  }
