
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { isEmpty } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { UserRecordCalendar } from '@app/models/user-record-calendar';
  import { FieldType } from '@app/models/sub-form-question';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  import Select2 from '../select2.vue';
  import AdvancedAutosuggest from '../advanced-autosuggest/advanced-autosuggest.vue';
  import EntitySelector from '../entity-selector.vue';
  import RecordFilterConfigurator from '../admin/questions/filter-configurator/record-filter-configurator.vue';

  @Component({
    components: {
      RecordFilterConfigurator,
      Select2,
      EntitySelector,
      DsDropdown,
      AdvancedAutosuggest,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class UserRecordCalendarForm extends Vue {
    @Prop(Object) readonly calendar!: Partial<UserRecordCalendar>;
    @Prop(Object) readonly moduleName!: ModuleName;
    @Prop(Boolean) readonly global?: boolean;
    @Prop(Array) readonly filters?: ConfiguratorFilter[];
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<UserRecordCalendar> = {};
    tags: PlaceholderItem[] = [];
    questions: SubFormQuestion[] = [];

    get isEditable(): boolean {
      return !this.calendar.id || this.global || !!this.calendar.user_id;
    }

    get filtersNotEmpty(): boolean {
      return !!this.form.config?.filters?.length;
    }

    get dateTimeQuestions(): SubFormQuestion[] {
      return this.questions.filter((q) => q.field_type === FieldType.datetime && `${q.id}` !== this.form.config?.start_question_id);
    }

    get selectedStartQuestion(): SubFormQuestion | undefined {
      return this.questions.find((q) => `${q.id}` === `${this.form.config?.start_question_id}`);
    }

    get cleanForm(): Partial<UserRecordCalendar> {
      return {
        ...this.form,
        config: {
          ...this.form.config,
          filters: this.form.config?.filters,
        },
      };
    }

    copyFilters(filters: ConfiguratorFilter[] = []): void {
      const cleanFilters = this.cleanFilters(filters);
      this.form = {
        ...this.form,
        config: {
          ...this.form.config,
          filters: cleanFilters,
        },
      };
    }
    copyToClipboard(text: string): void {
      this.$copyText(text);
    }

    beforeMount(): void {
      this.form = { ...this.calendar, config: { ...this.calendar.config } };
      this.$api
        .getSubFormQuestions(
          {
            filters: {
              active: true,
              field_type: [FieldType.datetime, FieldType.date],
              sub_form_section: {
                sub_form_id: this.moduleName.sub_form_id,
              },
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.questions = data;
        });

      if (this.global) {
        this.$api
          .getInjectableOptions(
            {
              concept_name: 'ModuleRecord',
              injectable_arguments: {
                concept_name: this.moduleName.name,
              },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.tags = data;
          });
      }
    }

    cleanFilters(filters: ConfiguratorFilter[] = []): ConfiguratorFilter[] {
      return filters.filter((filter) => !isEmpty(filter.value));
    }

    onSubmit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.cleanForm);
      });
    }
  }
