
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ReportGraphWidgetOptions } from '@app/models/widget';
  import type { SuperReport } from '@app/models/super-report';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({ components: { EntitySelector, ValidationObserver, ValidationProvider } })
  export default class ReportDataWidgetSettings extends BaseWidgetSettings<ReportGraphWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<ReportGraphWidgetOptions>;

    get superImportFilters(): DonesafeFilterOptions<SuperReport> {
      return {
        report_blob: {
          '!klass': 'MongoTrails::Version',
          graph: { '!type': 'heatmap' },
        },
      };
    }
  }
