import { render, staticRenderFns } from "./new-hybrid-page.vue?vue&type=template&id=27a5f986&scoped=true&lang=pug"
import script from "./new-hybrid-page.vue?vue&type=script&lang=ts"
export * from "./new-hybrid-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a5f986",
  null
  
)

export default component.exports