
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { map } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component } from 'vue-property-decorator';
  import RecordFilterConfigurator from '../admin/questions/filter-configurator/record-filter-configurator.vue';
  import EntitySelector from '../entity-selector.vue';
  import Select2 from '../select2.vue';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';
  import type { RelationshipOptions, SubFormList } from '@app/models/sub-form-list';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import { RecordRelationMode } from '@app/models/record-relation';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { Tuple } from '@app/utils/types/tuple';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';

  @Component({
    components: { RecordFilterConfigurator, EntitySelector, Select2, ValidationProvider, ValidationObserver },
    methods: { getRecordLinkBehaviourOptions },
  })
  export default class RelationshipTabSettings extends SubFormListSettingsBase {
    targetModuleName: ModuleName | null = null;
    modeOptions: [RecordRelationMode, string][] = [];

    name = 'sub_form_list';
    relationshipOptionsName = `${this.name}[relationship_options]`;

    get relationshipsFilter(): DonesafeFilterOptions<Relationship> {
      const defaultFilter = { active: true };
      if (this.subFormListSync.relationship_options?.relationship_mode === RecordRelationMode.from) {
        return {
          ...defaultFilter,
          from_module: this.moduleName?.name,
        };
      } else if (this.subFormListSync.relationship_options?.relationship_mode === RecordRelationMode.to) {
        return {
          ...defaultFilter,
          to_module: this.moduleName?.name,
        };
      } else {
        return defaultFilter;
      }
    }

    beforeMount(): void {
      const relationshipDefaultOptions = {
        link_first_column: 'true' as const,
        allow_select: 'true' as const,
        allow_search: 'true' as const,
        allow_add_new: 'true' as const,
      };

      this.subFormListSync.options = {
        record_link_behaviour: RecordLinkBehaviour.GoToRecord,
        ...this.subFormListSync.options,
      };

      this.subFormListSync.relationship_options = {
        ...relationshipDefaultOptions,
        ...(this.subFormListSync.relationship_options || {}),
      } as SubFormList['relationship_options'];

      this.preFetchRelationships();
    }

    get availableIndexOptions(): [string, string][] {
      return map(this.targetModuleName?.available_index_options || {}, (option, key) => [key, option.name]);
    }

    get defaultTemplatesFilters(): DonesafeFilterOptions<DefaultTemplate> {
      return {
        active: true,
        module_name_id: this.targetModuleName?.id,
      };
    }

    onRelationshipCodeUpdate(code?: string): void {
      this.subFormListSync.relationship_options = {
        ...(this.subFormListSync.relationship_options || {}),
        filters: [],
      } as RelationshipOptions;
      this.fetchTargetModule(code);
    }

    fetchTargetModule(code?: string): void {
      if (code && this.subFormListSync.relationship_options) {
        const moduleOnly = [
          'id',
          'available_index_options',
          'feature_set',
          'indicator_sets',
          'involvements',
          { main_form: [{ sub_form_sections: ['sub_form_questions'] }] },
        ];
        const moduleNameKey =
          this.subFormListSync.relationship_options.relationship_mode === RecordRelationMode.from ? 'to_module_name' : 'from_module_name';
        this.$api
          .getRelationships({
            filters: { code },
            only: ['id', 'name', { [moduleNameKey]: moduleOnly }],
          })
          .then(({ data }) => {
            this.targetModuleName = data[0][moduleNameKey] || null;
          });
      } else {
        this.targetModuleName = null;
      }
    }

    onUpdateMode(): void {
      this.onRelationshipCodeUpdate();
    }

    preFetchRelationships(): void {
      const defaultOptions = {
        filters: { active: true },
        page: 1,
        per_page: 25,
        only: Tuple(['code', 'name'] as const),
        sort: 'name',
      };
      if (this.moduleName) {
        this.$api
          .getRelationships(
            {
              ...defaultOptions,
              filters: { ...defaultOptions.filters, from_module: this.moduleName.name },
            },
            { cache: true }
          )
          .then(({ data }) => {
            if (data.length) {
              this.modeOptions = [...this.modeOptions, [RecordRelationMode.from, 'From this module to...']];
            }
          });
        this.$api
          .getRelationships(
            {
              ...defaultOptions,
              filters: { ...defaultOptions.filters, to_module: this.moduleName.name },
            },
            { cache: true }
          )
          .then(({ data }) => {
            if (data.length) {
              this.modeOptions = [...this.modeOptions, [RecordRelationMode.to, 'To this module from...']];
            }
          });
      }
      if (this.subFormListSync.relationship_options?.relationship_mode) {
        this.fetchTargetModule(this.subFormListSync.relationship_options.relationship_code);
      }
    }
  }
