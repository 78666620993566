
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import type { Components, Formula, FormulaElement, Rules, Variables } from '../models/types';

  @Component({ components: {} })
  export default class TextEditor extends Vue {
    @Prop({ required: true }) readonly currentTabType!: string;
    @Prop({ required: true }) readonly convertAndUpdateCurrentFormula!: (
      dragAndDropFormulaLogicElements: { id: number; value: string | Formula }[],
      refreshFormulaEditorComponent: boolean
    ) => void;
    @Prop({ required: true }) readonly assignIdAndTypeToLogicElements!: (
      logicElementsArray: Formula,
      startingId: number
    ) => {
      logicElementsArray: { id: number; value: Formula }[];
      currentId: number;
    };
    @Prop({ required: true }) readonly logicElements!: { id: number | string; value: Formula }[];
    @Prop({ required: true }) readonly currentFormula!: Formula;
    @Prop({ required: true }) readonly rules!: Rules;
    @Prop({ required: true }) readonly components!: Components;
    @Prop({ required: true }) readonly variables!: Variables;

    @Watch('currentFormula', { immediate: true })
    onCurrentFormulaChanged(value: Formula): void {
      this.convertArrayFormulaToString(value);
    }

    formulaString = '';
    validation: string[] = [];

    fixSingleElements(arrayFormula: any): Formula {
      return arrayFormula.map((item: FormulaElement, index: number) => {
        if (['IF', 'ELSIF'].indexOf(arrayFormula[index - 2]) > -1 || ['ELSE'].indexOf(arrayFormula[index - 1]) > -1) {
          return Array.isArray(item) ? this.fixSingleElements(item) : [item];
        } else {
          return item;
        }
      });
    }

    convertArrayFormulaToString(arrayFormula: Formula): void {
      const formulaString = JSON.stringify(this.fixSingleElements(arrayFormula))
        .replace(/\\"/g, '$')
        .replace(/"/g, '')
        .replace(/,/g, '')
        .replace(/\[/g, '(')
        .replace(/]/g, ')')
        .replace(/\$/g, '"');

      this.formulaString = formulaString.substr(1, formulaString.length - 2);
    }

    convertTextLogic(): void {
      this.validation = [];

      try {
        const logicArray = this.convertStringFormulaToArray(this.formulaString);
        const logicElements = this.assignIdAndTypeToLogicElements(logicArray, 0).logicElementsArray;

        const invalidElements = this.checkAllElementsAreValid(logicElements);

        invalidElements.length
          ? (this.validation = ["The following elements don't exist: " + invalidElements.join(', ')])
          : this.convertAndUpdateCurrentFormula(logicElements, true);
      } catch (err) {
        this.validation = ['Invalid formula, check your opening and closing brackets'];
      }
    }

    checkAllElementsAreValid(formula: { id: number; value: unknown }[]): string[] {
      let invalidElementIds: string[] = [];
      const elementIds = Object.keys(this.rules).concat(Object.keys(this.components)).concat(Object.keys(this.variables));

      for (let i = 0; i < formula.length; i++) {
        if ((formula[i].value as { id: number; value: Formula }[]).constructor === Array) {
          invalidElementIds = invalidElementIds.concat(this.checkAllElementsAreValid(formula[i].value as { id: number; value: Formula }[]));
        } else if (
          ['~', '@', '#'].indexOf((formula[i].value as string)[0]) !== -1 &&
          elementIds.indexOf(formula[i].value as string) === -1
        ) {
          invalidElementIds.push(formula[i].value as string);
        }
      }

      return invalidElementIds;
    }

    convertStringFormulaToArray(formulaString: string): Formula {
      const splitStringArray = this.convertStringToSplitStringArray(formulaString);
      const convertedString = this.convertSplitArrayToJson(splitStringArray);
      return JSON.parse(convertedString) as Formula;
    }

    convertStringToSplitStringArray(formulaString: string): string[] {
      const bracketReplacedString = ('(' + formulaString + ')').replace(/\)/g, ']').replace(/\(/g, '[').replace(/"/g, '\\"');

      const splitStringArray = bracketReplacedString.split('');
      let currentChar = 0;

      while (true) {
        let additionalChars = 0;

        if (splitStringArray[currentChar] === '[') {
          if (['[', ']', undefined].indexOf(splitStringArray[currentChar + 1]) === -1) {
            splitStringArray.splice(currentChar + 1, 0, '"');
            additionalChars++;
          }
          if (['[', ']', undefined].indexOf(splitStringArray[currentChar - 1]) === -1) {
            splitStringArray.splice(currentChar, 0, '",');
            additionalChars++;
          }
        } else if (splitStringArray[currentChar] === ']') {
          if (['[', ']', undefined].indexOf(splitStringArray[currentChar + 1]) === -1) {
            splitStringArray.splice(currentChar + 1, 0, ',"');
            additionalChars++;
          }
          if (['[', ']', undefined].indexOf(splitStringArray[currentChar - 1]) === -1) {
            splitStringArray.splice(currentChar, 0, '"');
            additionalChars++;
          }
        }

        currentChar++;
        currentChar += additionalChars;

        if (currentChar === splitStringArray.length) break;
      }

      const convertedString = splitStringArray.join('').replace(/]\[/g, '],[');

      return convertedString.split('');
    }

    convertSplitArrayToJson(splitStringArray: string[]): string {
      const doubleSplitters = ['(', ')', '+', '-', '*', '/', '^', '=', '<', '>'];
      const specialSplitters = ['<', '>', '","<","', '",">","', '","<', '",">'];

      let currentChar = 0;
      let openBracket = false;

      while (true) {
        if (openBracket) {
          if (doubleSplitters.indexOf(splitStringArray[currentChar]) !== -1)
            splitStringArray[currentChar] =
              splitStringArray[currentChar + 2] === ']'
                ? '","' + splitStringArray[currentChar]
                : '","' + splitStringArray[currentChar] + '","';
          if (specialSplitters.indexOf(splitStringArray[currentChar]) !== -1 && splitStringArray[currentChar + 1] === '=')
            splitStringArray[currentChar] = splitStringArray[currentChar].substr(0, 4);
          if (splitStringArray[currentChar] === '","=","' && specialSplitters.indexOf(splitStringArray[currentChar - 1]) !== -1)
            splitStringArray[currentChar] = splitStringArray[currentChar].substr(3);
        }

        if (splitStringArray[currentChar] === '"' && splitStringArray[currentChar - 1] !== '\\') openBracket = !openBracket;

        currentChar++;

        if (currentChar === splitStringArray.length) break;
      }

      return splitStringArray
        .join('')
        .replace(/,"\s*"/g, '')
        .replace(/\["\s*"]/g, '[]')
        .replace(/\["\s*",/g, '[')
        .replace(/"\s*\\"/g, '"\\"')
        .replace(/\\"\s*"/g, '\\""')
        .replace(/\\"/g, '&')
        .replace(/"\s+/g, '"')
        .replace(/\s+"/g, '"')
        .replace(/&/g, '\\"');
    }

    handleChange(value: string): void {
      this.formulaString = value;
    }
  }
