
  import { Component, Model, Prop, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { DebugRuleSet } from '@app/services/api/rule-sets-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { Dictionary } from '@app/models/dictionary';
  import { toaster } from '@app/utils/toaster';
  import ConditionSection from '@app/components/admin/automation-definitions/debug/condition-section.vue';
  import ToggleHistorySection from '@app/components/admin/automation-definitions/debug/toggle-history-section.vue';
  import { useAccountStore } from '@app/stores/account';
  import type { ValidationResult } from '@app/services/api/automation-definition-api';
  import DsIconText from '@app/components/ds-icon-text.vue';

  import FormField from '../questions/edit/form-field.vue';

  import EventOptionsMixin from './event-options-mixin';
  import EventsSection from './debug/events-section.vue';

  @Component({
    components: {
      DsModal,
      DsIconText,
      ValidationObserver,
      ValidationProvider,
      FormField,
      ConditionSection,
      EventsSection,
      ToggleHistorySection,
    },
  })
  export default class AutomationDefinitionDebugModal extends EventOptionsMixin {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    recordId: Nullable<number> = null;
    record: Partial<ValidationResult> = {};
    testResults: Nullable<DebugRuleSet> = null;
    loading = false;

    get showTestResults(): boolean {
      return !!Object.keys(this.testResults?.results || {}).length;
    }

    get recordType(): string {
      const types: Dictionary<string> = {};
      ['User', 'TenantUser'].forEach((type) => (types[type] = 'User'));
      ['RecordRelation', 'RegulatoryReport', 'UserInvolvement', 'Document', 'SubFormCompletion', 'Activity'].forEach(
        (type) => (types[type] = this.automationDefinition.concept_name)
      );
      return types[this.automationDefinition.concept_name] || 'ModuleRecord';
    }

    get recordTypeLabel(): string {
      const labels: Dictionary<string> = {
        RecordRelation: this.$t('tenant.admin.automation_definitions.debug.record_types.record_relation'),
        RegulatoryReport: this.$t('tenant.admin.automation_definitions.debug.record_types.regulatory_report'),
        UserInvolvement: this.$t('tenant.admin.automation_definitions.debug.record_types.user_involvement'),
        Document: this.$t('tenant.admin.automation_definitions.debug.record_types.document'),
        SubFormCompletion: this.$t('tenant.admin.automation_definitions.debug.record_types.sub_form_completion'),
        Activity: this.$t('app.labels.action'),
      };
      ['User', 'TenantUser'].forEach((type) => (labels[type] = this.$t('app.labels.user')));
      return (
        labels[this.automationDefinition.concept_name] || this.$t('tenant.admin.automation_definitions.debug.record_types.module_record')
      );
    }

    get accountStore() {
      return useAccountStore();
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.runRecordCheck();
      });
    }

    runRecordCheck(): void {
      if (!this.recordId) return;

      this.loading = true;

      this.$api
        .validateRecord(
          this.automationDefinition.id,
          {
            record_type: this.recordType,
            record_id: this.recordId,
          },
          { cache: true }
        )
        .then(({ data }) => {
          if (data.valid) {
            this.record = data;

            this.$api
              .debugRuleSet({
                id: this.automationDefinition.rule_set_id,
                record_type: this.recordType,
                record_id: Number(this.recordId),
              })
              .then(({ data }) => {
                this.testResults = data;
                this.loading = false;
              });
          } else if (data.error != null) {
            this.testResults = null;
            this.loading = false;
            toaster({
              text: this.$t(`tenant.admin.automation_definitions.debug.record_validation_errors.${data.error}`),
              icon: 'error',
            });
          } else if (data.error != null) {
            this.testResults = null;
            this.loading = false;
            toaster({
              text: this.$t(`tenant.admin.automation_definitions.debug.record_validation_errors.${data.error}`),
              icon: 'error',
            });
          }
        });
    }

    handleShow() {
      this.getAutomationCreateEventOptions();
      this.runRecordCheck();
    }
  }
