
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import SearchInput from '@app/components/search-input.vue';
  import { API_NULL } from '@app/constants';
  import { Tooltip } from 'uiv';
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import type { AuditReportTemplate, AuditReportRecordType } from '@app/models/audit-report-template';
  import {
    AuditReportTemplateFormat,
    AuditReportTemplateVisibility,
    AuditReportTemplateReportType,
    AuditReportTemplatePurviewType,
  } from '@app/models/audit-report-template';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import Blocking from '@app/mixins/blocking';
  import { enumLabelFromKey } from '@app/utils/enumLabelFromKey';
  import { toaster } from '@app/utils/toaster';
  import type { Subscription } from '@rails/actioncable';
  import consumer from '@app/channels/consumer';
  import { downloadFile } from '@app/utils/download-file';
  import type { DocumentTemplateFileReceivedForDownload } from '@app/utils/download-file';

  @Component({ components: { SearchInput, BaseTable, DsModal, Tooltip } })
  export default class AuditReportGenerateModal extends Blocking {
    @Model('input') visible!: boolean;
    @Prop(Number) readonly moduleNameId!: number;
    @Prop(Number) readonly subFormListId?: number;
    @Prop(Number) readonly recordId!: number;
    @Prop(String) readonly recordType!: AuditReportRecordType;

    manager: Nullable<ListManager<AuditReportTemplate>> = null;
    generating: Record<number, boolean> = {};
    notificationsSubscriptions: Record<string, Subscription> = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get adminOrTechAdmin() {
      return this.currentUserStore.isDefaultOrTechAdmin;
    }

    get templateFilters(): DonesafeFilterOptions<AuditReportTemplate> {
      const baseFilters = {
        module_name_id: this.moduleNameId,
        sub_form_list_id: this.subFormListId || API_NULL,
        report_type: this.subFormListId ? AuditReportTemplateReportType.SubFormCompletion : AuditReportTemplateReportType.ModuleRecord,
        record_id: this.recordId,
        record_type: this.recordType,
        purview: [AuditReportTemplatePurviewType.single_record, AuditReportTemplatePurviewType.all_records],
      };

      return this.adminOrTechAdmin ? baseFilters : { ...baseFilters, restricted: false };
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    getManager(): ListManager<AuditReportTemplate> {
      return new ListManager<AuditReportTemplate>({
        fetchDataFunction: (params) => {
          return this.$api.getAuditReportTemplates(
            {
              ...params,
              include: this.adminOrTechAdmin ? ['restricted'] : [],
              filters: {
                ...params.filters,
                ...this.templateFilters,
              },
            },
            { cache: true }
          );
        },
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: 10,
        fields: [
          { title: this.$t('tenant.shared.audit_report.template_name'), name: 'name', sortField: 'name' },
          {
            title: this.$t('tenant.shared.audit_report.format'),
            name: 'report_format',
            sortField: 'report_format',
            filter: true,
            hidden: !window.DONESAFE.documentConverterPresent,
          },
          { title: this.$t('tenant.shared.audit_report.generate_document'), name: 'generate_template' },
        ],
      });
    }

    toggleGenerating(templateId: number, value: boolean): void {
      this.generating = { ...this.generating, [templateId]: value };
    }

    generateClick(template: AuditReportTemplate): void {
      this.toggleGenerating(template.id, true);
      this.$api
        .generateAuditReport(template.id, {
          record_id: this.recordId,
          record_type: this.recordType,
          report_format: template.report_format,
          async: true,
        })
        .then(({ data }) => {
          this.subscribeToNotifications(data.job_id);
          toaster(data.message);
        })
        .catch(({ data }) => {
          toaster({ text: this.transformErrorMessage(data.error), icon: 'error' });
          this.toggleGenerating(template.id, false);
        });
    }

    reportLabel(template: AuditReportTemplate): string {
      if (template.visibility === AuditReportTemplateVisibility.Hidden) return this.$t('app.labels.hidden_name', { name: template.name });
      if (template.restricted) return this.$t('app.labels.restricted_name', { name: template.name });

      return template.name;
    }

    reportFormatLabel(key: AuditReportTemplateFormat): string | undefined {
      return enumLabelFromKey(AuditReportTemplateFormat, key);
    }

    closeModal(): void {
      this.$emit('input', false);
    }

    subscribeToNotifications(jobId: string): void {
      this.notificationsSubscriptions[jobId] = consumer.subscriptions.create(
        {
          channel: 'WebNotificationsChannel',
          record_type: 'AuditReportTemplateResult',
          job_id: jobId,
        },
        {
          received: ({ audit_report_template_id, downloadable_url, file_name, job_id, error }: DocumentTemplateFileReceivedForDownload) => {
            if (downloadable_url && file_name) {
              this.notificationsSubscriptions[job_id]?.unsubscribe();
              delete this.notificationsSubscriptions[job_id];
              downloadFile(downloadable_url, file_name);
            } else {
              toaster({ text: this.transformErrorMessage(error), icon: 'error' });
            }
            this.toggleGenerating(audit_report_template_id, false);
          },
        }
      );
    }

    transformErrorMessage(error?: string): string {
      return error || this.$t('components.export_confirm_modal.export_failed');
    }

    beforeDestroy(): void {
      Object.values(this.notificationsSubscriptions).forEach((subscription) => subscription.unsubscribe());
    }
  }
