
  import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({ components: { BaseTable } })
  export default class ModuleScoreBandsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<ScoreBandProfile>;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    manager: Nullable<ListManager<ScoreBandProfile>> = null;

    getManager(): ListManager<ScoreBandProfile> {
      return new ListManager<ScoreBandProfile>({
        fetchDataFunction: (params) => {
          return this.$api.getScoreBandProfiles(
            {
              ...params,
              filters: {
                module_name_id: this.moduleNameId,
                active: true,
              },
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('tenant.admin.score_bands.table_head.name'),
            name: 'name',
            sortField: 'name',
          },
          {
            title: this.$t('tenant.admin.score_bands.table_head.code'),
            name: 'code',
            sortField: 'code',
          },
          {
            title: this.$t('tenant.admin.score_bands.table_head.options'),
            name: 'options',
          },
        ],
      });
    }

    async mounted(): Promise<void> {
      this.manager = this.getManager();
    }
  }
