
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import UniqQuestionConfig from '@app/components/admin/questions/settings/shared/uniq-question-config.vue';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  @Component({
    components: { ScoredQuestionConfig, UniqQuestionConfig },
  })
  export default class TextFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseSelectQuestionOptions>;

    @Prop(Object) readonly features!: ModuleFeatures;
  }
