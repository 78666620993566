
  import { keyBy, groupBy } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormListOnly } from './utils';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { printSubFormListTitle } from '@app/utils/print-sub-form-list-title';

  @Component({ components: {} })
  export default class QuickEditPanel extends Vue {
    @Prop(Object) readonly moduleRecord!: Pick<ModuleRecord, 'id' | 'module_name_id' | 'workflow' | 'sub_form_completion' | 'module_name'>;
    @Prop(Object) readonly currentSubFormCompletion?: SubFormCompletion;
    @Prop(Array) readonly subFormLists!: Pick<SubFormList, SubFormListOnly>[];
    @Prop(Object) readonly subForms!: Dictionary<SubForm>;
    @Prop(Array) readonly completions!: Pick<
      SubFormCompletion,
      'id' | 'sub_form_list_id' | 'stage' | 'title' | 'workflow' | 'created_at'
    >[];
    @Prop(Function) readonly checkDiff!: () => void;
    @Prop(Boolean) readonly mobile!: boolean;

    get currentSubFormList(): Pick<SubFormList, SubFormListOnly> | undefined {
      return this.currentSubFormCompletion?.sub_form_list_id
        ? this.subFormListsMap[this.currentSubFormCompletion?.sub_form_list_id]
        : undefined;
    }

    get subFormListsMap(): Record<string, Pick<SubFormList, SubFormListOnly>> {
      return keyBy(this.subFormLists, 'id');
    }

    get completionsBySubFormList(): Record<
      string,
      Pick<SubFormCompletion, 'id' | 'sub_form_list_id' | 'stage' | 'title' | 'workflow' | 'created_at'>[]
    > {
      return groupBy(this.completions, 'sub_form_list_id');
    }

    get subFormListsWithSortedCompletions(): Pick<
      SubFormList & {
        completions: Partial<SubFormCompletion>[];
        prettyTitle?: string;
      },
      SubFormListOnly | 'prettyTitle' | 'completions'
    >[] {
      return this.subFormLists.map((subFormList) => {
        return {
          ...subFormList,
          prettyTitle: printSubFormListTitle(subFormList, this.subForms),
          completions: this.completionsBySubFormList[subFormList.id] || [],
        };
      });
    }

    isActive(entityId: number, type: 'sub_form_list' | 'sub_form_completion' | 'sub_form'): boolean {
      if (type === 'sub_form_list') {
        return (
          (!this.currentSubFormCompletion || !this.currentSubFormCompletion.id) &&
          !!this.currentSubFormList &&
          entityId === this.currentSubFormList.id
        );
      }
      if (type === 'sub_form_completion') return !!this.currentSubFormList && entityId === this.currentSubFormCompletion?.id;
      if (type === 'sub_form') return !!this.currentSubFormCompletion && entityId === this.currentSubFormCompletion.sub_form_id;
      return false;
    }

    statusIndicatorBg(
      completion: Pick<SubFormCompletion, 'id' | 'sub_form_list_id' | 'stage' | 'title' | 'workflow' | 'created_at'>
    ): string {
      return completion.workflow ? completion.workflow.color : this.stateToHexColor(completion.stage);
    }

    getEditLink(subFormCompletionId: number): string {
      return `/sub_form_completions/${subFormCompletionId}/edit?quick_edit=true`;
    }

    stateToHexColor(state: string): string {
      switch (state) {
        case 'Draft':
          return '#f0ad4e';
        case 'Complete':
        case 'Approved':
        default:
          return '#039DB0';
        case 'Rejected':
          return '#d9534f';
      }
    }

    getNewLink(subFormId: number, subFormListId: number): string {
      // TODO: do not hardcode record_type
      return `/sub_form_completions/new?quick_edit=true&sub_form_id=${subFormId}&sub_form_list_id=${subFormListId}&record_type=ModuleRecord&record_id=${this.moduleRecord.id}`;
    }

    getSubFormList(subFormListId: number): Pick<SubFormList, SubFormListOnly> | undefined {
      return this.subFormListsMap[subFormListId];
    }

    subFormListTitle(subFormListId: number): string | undefined {
      return this.getSubFormList(subFormListId)?.title;
    }
  }
