
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import ActionPriorityForm from '@app/components/admin/actions/action-priority-form.vue';
  import type { ActionPriority } from '@app/models/action-priority';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, ActionPriorityForm } })
  export default class ActionPriorityEdit extends mixins(Blocking) {
    @Prop(Number) id!: number;
    @Ref() readonly form?: ActionPriorityForm;

    value: ActionPriority | null = null;

    async beforeMount() {
      const { data: value } = await this.$api.getActionPriority(this.id);
      this.value = value;
    }

    async onSubmit(form: Partial<ActionPriority>): Promise<ActionPriority | null> {
      return this.blocking(async () => {
        if (!form.id) return null;

        const { data } = await this.$api.updateActionPriority(form.id, form);
        this.close();

        return data;
      });
    }

    toggleActive(): Promise<void> {
      return this.onChangeActive(!this.value?.active);
    }

    async onChangeActive(active: boolean): Promise<void> {
      await this.blocking(async () => {
        await this.$api.updateActionPriority(this.id, { active });
        this.close();
      });
    }

    close() {
      this.$router.push({ name: 'admin-action-settings' });
    }
  }
