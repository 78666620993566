
  import { Component, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import { upperFirst } from 'lodash';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import TextHighlight from 'vue-text-highlight';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { changeLocation } from '@app/utils/change-location';

  import { BaseTable } from '../base-table';
  import FilterSelect from '../filter-select.vue';
  import SearchInput from '../search-input.vue';
  import Select2 from '../select2.vue';
  import RecordIndexPageFiltersLayout from '../record-index-page-filters-layout.vue';
  import DsDropdown from '../ds-dropdown.vue';

  import ScheduledEventsActions from './scheduled-events/scheduled-events-actions.vue';

  @Component({
    components: {
      BaseTable,
      DsDropdown,
      FilterSelect,
      RecordIndexPageFiltersLayout,
      ScheduledEventsActions,
      SearchInput,
      Select2,
      TextHighlight,
    },
  })
  export default class AdminScheduledEventsPage extends mixins(WithSearchableDsDropdown) {
    @Ref() readonly table?: BaseTable<ScheduledEvent>;
    eventOptions: [string, string][] = [];
    manager: Nullable<ListManager<ScheduledEvent>> = null;
    scheduleTypes = [
      {
        label: 'One Off',
        value: 'once',
      },
      {
        label: 'Recurring',
        value: 'recurring',
      },
    ];
    statuses = ['pending', 'active', 'completed', 'unpublished'].map((status) => ({
      label: upperFirst(status),
      value: status,
    }));
    eventOptionsDropdownOpen = false;

    get searchableEventOptions(): [string, string][] {
      return this.getSearchableItems<[string, string]>(this.eventOptions, '1');
    }

    getManager(): ListManager<ScheduledEvent> {
      return new ListManager<ScheduledEvent>({
        fetchDataFunction: (params) => {
          return this.$api.getScheduledEvents(
            {
              ...params,
              include: ['schedule', 'event', 'collection_description', 'creation_display'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('app.labels.ID'),
            name: 'id',
            sortField: 'id',
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.name'),
            name: 'name',
            sortField: 'name',
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.schedule_type'),
            name: 'schedule_type',
            sortField: 'schedule_type',
            filter: true,
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.next_schedule_date'),
            name: 'next_schedule_date',
            sortField: 'next_schedule_date',
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.reoccurs'),
            name: 'reoccurs',
            sortField: 'reoccurs',
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.target_records'),
            name: 'collection_description',
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.event'),
            name: 'event',
            sortField: 'event_creation',
            filter: true,
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.status'),
            name: 'status',
            sortField: 'status',
            filter: true,
          },
          {
            title: this.$t('tenant.admin.scheduled_events.table_head.log'),
            name: 'log',
          },
        ],
        allowFilters: true,
      });
    }

    statusLabelColour(scheduledEvent: ScheduledEvent): string {
      switch (scheduledEvent.status) {
        case 'unpublished':
          return 'btn-warning';
        case 'active':
          return 'btn-success';
        case 'completed':
          return 'btn-success';
        default:
          return 'btn-info';
      }
    }

    beforeMount(): void {
      this.$api.getAutomationCreateEventOptions({ injectable_arguments: {} }, { cache: true }).then(({ data }) => {
        this.eventOptions = data;
      });
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    openNewScheduleForm(key: string): void {
      changeLocation(`/admin/settings/scheduled_events/new?create_module=${key}`);
    }
  }
