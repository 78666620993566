
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { IncidentParticipantRole } from '@app/models/incident-participant-role';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    description: string;
    incident_id: string;
    incident_participant_role_id: string;
    user_id: string;
  }

  @Component({ components: { Select2, AdvancedAutosuggest, EntitySelector, FormField } })
  export default class IncidentParticipantEventForm extends BaseEventForm<FormValues> {
    get roleFilters(): DonesafeFilterOptions<IncidentParticipantRole> {
      return {
        active: true,
      };
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
