
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import { uniq } from 'lodash';
  import SuccessPageSetupModal from '@app/components/admin/module-names/form-wizard/success-page-setup-modal.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import type { FormComponent } from '@app/models/form-component';
  import type { PaperTrailsFilters } from '@app/models/paper-trail-version';
  import { toaster } from '@app/utils/toaster';

  import FormComponentModal from '../form-components/form-component-modal.vue';

  @Component({ components: { Draggable, PaperTrailsModalLink, FormComponentModal, SuccessPageSetupModal } })
  export default class ModuleFormWizardPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: string | number;

    formComponents: FormComponent[] = [];
    subFormListNameMap: Record<number, string> = {};
    formComponentModalVisible = false;
    selectedFormComponent: Nullable<Partial<FormComponent>> = {};
    successPageSetupVisible = false;
    moduleName: Nullable<string> = null;

    auditFilters: Partial<PaperTrailsFilters> = {
      item_type: ['FormComponent', 'ModuleName', 'ModuleName::Translation', 'RuleSet'],
      attributes: [
        'FormComponent:id',
        'FormComponent:component_id',
        'FormComponent:component_type',
        'FormComponent:order',
        'FormComponent:can_skip',
        'FormComponent:complete_multiple',
        'FormComponent:user_collection_id',
        'FormComponent:rule_set_id',
        'ModuleName:show_options',
        'ModuleName:config',
        'ModuleName::Translation:wizard_labels',
        'RuleSet:query_builder_blob',
      ],
    };

    async beforeMount(): Promise<void> {
      await this.fetchModuleName();
      await this.fetchFormComponents();
      await this.fetchSubFormLists();
    }

    async fetchModuleName(): Promise<void> {
      const {
        data: { name },
      } = await this.$api.getModuleName(
        Number(this.$route.params.moduleNameId),
        { filters: { name: this.moduleName }, only: ['name', 'id'] },
        { cache: true }
      );
      this.moduleName = name;
    }

    async fetchFormComponents(): Promise<void> {
      const { data } = await this.$api.getFormComponents(
        {
          filters: {
            related_module_name: {
              id: this.moduleNameId,
            },
          },
          sort: 'order',
          per_page: -1,
          only: [
            'id',
            'component_id',
            'component_type',
            'order',
            'can_skip',
            'complete_multiple',
            'user_collection_id',
            { rule_set: ['id', 'rules_description', 'model', 'query_builder_blob'] },
            { user_collection: ['id', 'name'] },
          ],
        },
        { cache: true }
      );
      this.formComponents = data;
    }

    async fetchSubFormLists(): Promise<void> {
      const subFormListIds = uniq(
        this.formComponents.reduce((memo, fc) => {
          if (fc.component_type === 'sub_form_lists') {
            return [...memo, fc.component_id];
          }
          return memo;
        }, [] as number[])
      );

      if (subFormListIds.length) {
        const { data } = await this.$api.getSubFormLists(
          {
            filters: {
              id: subFormListIds.join(','),
            },
            only: ['id', 'title'],
            per_page: -1,
          },
          { cache: true }
        );
        this.subFormListNameMap = data.reduce((memo, sfl) => {
          return { ...memo, [sfl.id]: sfl.title };
        }, {});
      }
    }

    formWizardComponentTitle(formComponent: FormComponent): string | undefined {
      const componentType = formComponent.component_type;
      if (componentType === 'sub_form_lists') {
        return 'Sub Form';
      } else if (componentType === 'hybrid_selected') {
        return 'Selected Sub Form';
      }
    }

    subFormListLabel(formComponent: FormComponent): string {
      return this.subFormListNameMap[formComponent.component_id];
    }

    toggleFormComponentModal(value: boolean, formComponent?: FormComponent): void {
      this.selectedFormComponent = formComponent || {};
      this.formComponentModalVisible = value;
    }

    dragEnd(): void {
      this.updateOrder();
    }

    updateOrder(): void {
      const data = this.formComponents.map((item, index) => ({ id: item.id, index: index }));
      this.$api
        .updateFormComponentOrder({ data })
        .then(() => {
          toaster({
            text: this.$t('app.labels.order_saved'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }
  }
