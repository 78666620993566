import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
import type { SubFormListRestriction } from '@app/models/sub-form-list-restriction';
import type { SubFormListRole } from '@app/models/sub-form-list-role';
import type { SubFormListRoleProfile } from '@app/models/sub-form-list-role-profile';

export interface Restriction {
  relationship: string;
  rule_set?: { query_builder_blob?: QueryBuilderBlob };
  viewable: boolean | null;
}
export interface RoleOverride {
  bypass: boolean | null;
  role_id: number;
}

export interface RestrictionSetForm {
  restrictions: Restriction[];
  role_overrides: RoleOverride[];
  role_profile_ids: number[];
}

export function buildRestrictionSetForm(
  listRestrictions: SubFormListRestriction[],
  listRoles: SubFormListRole[],
  listRoleProfiles: SubFormListRoleProfile[]
) {
  return {
    restrictions: listRestrictions.map((item) => {
      if (item.rule_set) {
        return { relationship: item.relationship, rule_set: { query_builder_blob: item.rule_set.query_builder_blob }, viewable: null };
      } else {
        return { relationship: item.relationship, viewable: false };
      }
    }),
    role_overrides: listRoles.map((item) => ({ role_id: item.role_id, bypass: item.bypass })),
    role_profile_ids: listRoleProfiles.map((item) => item.role_profile_id),
  };
}
