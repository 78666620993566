
  import type { Indicator } from '@app/models/indicator';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { flatten, groupBy, keyBy, mapValues, orderBy } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import IndicatorItem from '@app/components/indicator/indicator-item.vue';
  import BasePrint from './base-print';

  @Component({ components: { IndicatorItem } })
  export default class PrintIndicators extends BasePrint {
    @Prop(Array) readonly indicatorSets!: IndicatorSet[];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get recordIndicatorsBySetId() {
      return mapValues(
        groupBy(this.record?.indicators || [], (ri) => this.indicatorsById[ri.id].indicator_set_id),
        (arr) => orderBy(arr, (ri) => this.indicatorsById[ri.id]?.index)
      );
    }

    get indicatorsById() {
      return keyBy(this.indicators, 'id');
    }

    get indicators(): Indicator[] {
      return orderBy(flatten(this.indicatorSets.map((s) => s.indicators || [])), 'index');
    }
  }
