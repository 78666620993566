
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';

  import Select2 from '../../../select2.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface DeleteEventValues {
    record_id: string;
    record_type: string;
  }

  @Component({ components: { EventInputMethodSwitch, Select2, AdvancedAutosuggest, FormField } })
  export default class DeleteRecordEventForm extends BaseEventForm<DeleteEventValues> {
    get recordTypes(): [string, string][] {
      return [
        ['ModuleRecord', this.$t('app.labels.main_form')],
        ['SubFormCompletion', this.$t('app.labels.sub_form')],
        ['Activity', this.$t('app.labels.action')],
      ];
    }

    onRecordTypeChange(): void {
      this.form = { ...this.form, record_id: undefined };
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
