import { render, staticRenderFns } from "./sub-form-sections-new-button.vue?vue&type=template&id=5d2cd95e&scoped=true&lang=pug"
import script from "./sub-form-sections-new-button.vue?vue&type=script&lang=ts"
export * from "./sub-form-sections-new-button.vue?vue&type=script&lang=ts"
import style0 from "./sub-form-sections-new-button.vue?vue&type=style&index=0&id=5d2cd95e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2cd95e",
  null
  
)

export default component.exports