
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { keyBy, orderBy } from 'lodash';
  import type { Attachment } from '@app/models/attachment';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { FieldType } from '@app/models/sub-form-question';
  import { moduleRecordPath } from '@app/services/helpers';
  import type { SfcFormRecordOnly } from '@app/pages/module-records/utils';

  @Component({})
  export default class MainRecordDetails extends Vue {
    @Prop(Object) readonly record!: Pick<ModuleRecord, SfcFormRecordOnly>;

    attachments: Record<string, Pick<Attachment, 'id' | 'url' | 'file_name'>> = {};

    get responsesToShow(): SubFormResponse[] {
      if (!this.moduleSubFormShowIds.length) return this.responsesByQuestionIndex;

      return this.moduleSubFormShowIds
        .map((id) => {
          return this.responsesByQuestionId[Number(id)];
        })
        .filter(Boolean);
    }

    get responsesByQuestionIndex(): SubFormResponse[] {
      return orderBy(this.mainFormResponses, (r) => r.sub_form_question?.index, 'asc');
    }

    get responsesByQuestionId(): Record<string, SubFormResponse> {
      return keyBy(this.mainFormResponses, 'sub_form_question_id');
    }

    get moduleSubFormShowIds(): number[] {
      return this.moduleName?.show_options?.sub_form_show_ids?.map(Number) || [];
    }

    get mainFormResponses(): SubFormResponse[] {
      return this.mainCompletion?.sub_form_responses || [];
    }

    get recordPath() {
      return moduleRecordPath(this.record.id);
    }

    get moduleName(): Maybe<Pick<ModuleName, 'display' | 'show_options'>> {
      return this.record?.module_name;
    }

    get mainCompletion(): Maybe<Pick<SubFormCompletion, 'sub_form_responses'>> {
      return this.record.sub_form_completion;
    }

    isFileUploadType(response: SubFormResponse): boolean {
      return response.sub_form_question_field_type === FieldType.file_upload;
    }

    mounted(): void {
      this.mainFormResponses.forEach((response) => {
        if (this.isFileUploadType(response)) {
          (response as SubFormResponse<FieldType.file_upload>).response.forEach((id) => {
            this.$api
              .getAttachmentsWithSharedFilter(
                {
                  filters: { attachable_type: 'SubFormResponse', attachable_id: response.id },
                  only: ['id', 'url', 'file_name'],
                },
                'id',
                id
              )
              .then(({ data }) => {
                this.attachments = {
                  ...this.attachments,
                  ...keyBy(data, 'id'),
                };
              });
          });
        }
      });
    }
  }
