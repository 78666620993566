import type { ModuleRecord } from '@app/models/module-record';
import type { SubFormCompletion } from '@app/models/sub-form-completion';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SUB_FORM_COMPLETION_SHOW_ONLY } from '../sub-form-completion/utils';

@Component
export default class WithModuleRecordCompletionShow extends Vue {
  @Prop(Number) readonly recordId!: ModuleRecord['id'];

  record: ModuleRecord | null = null;
  completion: SubFormCompletion | null = null;

  async fetchModuleRecord() {
    const { data: mr } = await this.$api.getModuleRecord(
      this.recordId,
      {
        only: [
          'id',
          'organization_id',
          'location_id',
          'user_id',
          'workflow_id',
          'calculations',
          'module_name_id',
          { sub_form_completion: SUB_FORM_COMPLETION_SHOW_ONLY },
        ],
      },
      { cache: true }
    );
    this.record = mr;
    this.completion = mr.sub_form_completion;
  }
}
