
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import FormField from '../admin/questions/edit/form-field.vue';
  import type { RawOption } from './models';
  import { extend } from 'vee-validate';
  import fail from '@app/validators/fail';

  @Component({ components: { FormField } })
  export default class SelectResponseItem extends Vue {
    @Model('input') readonly value!: RawOption;
    @Prop([Number, String]) readonly index!: number | string;
    @Prop(String) readonly name!: string;
    @Prop(String) readonly scoreKey!: string;
    @Prop(Boolean) readonly isDefault!: boolean;
    @Prop(Boolean) readonly hasScore!: boolean;
    @Prop(String) readonly scoreType?: string;
    @Prop(Boolean) readonly highlight!: boolean;
    @Prop(Boolean) readonly defaultDisabled!: boolean;
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(Boolean) readonly duplicatedKey?: boolean;
    @Prop(Boolean) readonly editMode?: boolean;
    @Prop(Boolean) readonly limitedEditingMode?: boolean;

    editDisabled = false;

    beforeMount() {
      extend('fail', fail);
    }

    mounted(): void {
      if (this.highlight) {
        const textColor = $(this.$el).find('.text-color');
        const backgroundColor = $(this.$el).find('.background-color');
        textColor.minicolors({
          hide: () => textColor.val() && this.onItemChange('highlight_text', textColor.val() as string),
        });

        backgroundColor.minicolors({
          hide: () => backgroundColor.val() && this.onItemChange('highlight_background', backgroundColor.val() as string),
        });
      }
    }

    @Emit('input')
    onItemChange(key: keyof RawOption, value: string): RawOption {
      return { ...this.value, [key]: value };
    }

    fieldName(key: string): string {
      return `${this.name}[${key}]`;
    }

    get isNa(): boolean {
      return this.index === 'na';
    }

    get scoreKeyRules() {
      return { double: this.scoreType === 'number' };
    }

    get keyRules() {
      return this.duplicatedKey ? { fail: { message: 'This value is duplicated' } } : {};
    }

    get disabled(): boolean {
      return !!this.limitedEditingMode && !this.editMode;
    }
  }
