
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { DetailQuestionOptions } from '@app/models/question-options/detail-question-options';
  import { DETAILS_MAPPING } from '@app/models/question-options/detail-question-options';

  @Component({ components: {} })
  export default class DetailShowOptions extends BaseShowOptions<DetailQuestionOptions> {
    detailFieldQuestion: Nullable<Partial<Pick<SubFormQuestion, 'title' | 'field_type' | 'system_code' | 'active'>>> = null;
    get property(): string | undefined {
      if (this.detailFieldQuestion) {
        return DETAILS_MAPPING[this.detailFieldQuestion.field_type as keyof typeof DETAILS_MAPPING]?.available_methods[this.config.method];
      }
    }

    get detailFieldQuestionTitle(): string | undefined {
      if (this.detailFieldQuestion) {
        return this.detailFieldQuestion.active
          ? this.detailFieldQuestion.title
          : this.$t('app.labels.archived_name', { name: this.detailFieldQuestion.title });
      }
    }

    beforeMount(): void {
      this.$api
        .getSubFormSection(this.question.sub_form_section_id, { only: ['id', 'sub_form_id'] }, { cache: true })
        .then(({ data: section }) => {
          this.$api
            .getSubFormQuestionsWithSharedFilters(
              {
                filters: {
                  field_type: Object.keys(DETAILS_MAPPING),
                  sub_form_section: {
                    sub_form_id: section.sub_form_id,
                  },
                },
                only: ['title', 'system_code', 'field_type', 'active'],
              },
              'system_code',
              this.config.sub_form_question_system_code,
              { cache: true }
            )
            .then(({ data }) => {
              this.detailFieldQuestion = data[0];
            });
        });
    }
  }
