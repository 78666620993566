import type { ConfiguratorFilter } from '@app/models/configurator-filter';

import type { BaseEntity } from '../base-entity';
import type { WithModuleName } from '../mixins';
import type { UserType } from '../tenant-user';

export interface VisitorAppExtraField {
  label?: string;
  question_id: number;
  required?: boolean;
}

export enum MobileAppConfigProfile {
  visitor_app = 'visitor_app',
}

export interface MobileAppConfig extends BaseEntity, WithModuleName {
  config: {
    courses?: {
      course_module_name_id?: string;
      participant_question_id?: string;
      restrict_to_specific_kiosks?: boolean;
      visitor_module_relation_question_id?: string;
    };
    disable_new_user?: boolean;
    extra_fields?: VisitorAppExtraField[];
    filters?: {
      visitee?: ConfiguratorFilter[];
      visitor?: ConfiguratorFilter[];
    };
    mapping?: Partial<{
      photo?: string;
      role_id?: number;
      sign_in_abandoned_workflow_id?: string;
      sign_in_datetime: string;
      sign_in_in_progress_workflow_id?: string;
      sign_in_workflow_id?: string;
      sign_out_datetime: string;
      sign_out_workflow_id?: string;
      user_type?: UserType;
      visitee?: string;
      visitor: string;
      workflow_state_question_id?: string;
    }>;
    not_found_message?: string;
    profile?: MobileAppConfigProfile;
    static_qr_code?: boolean;
  };
  description: string;
  module_name_id: number;
  name: string;
  published: boolean;
  uuid: string;
}
