
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Copy from '@app/components/admin/questions/edit/copy.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { humanize } from '@app/utils/humanize';
  import DsCollapseLink from '@app/components/ds-collapse-link.vue';
  import { Collapse } from 'uiv';

  @Component({ components: { Copy, DsCollapseLink, Collapse } })
  export default class QuestionSummary extends Vue {
    @Prop(Object) value!: SubFormQuestion;

    showValue = false;

    get displayFieldType(): string {
      return this.value.field_type && humanize(this.value.field_type).toUpperCase();
    }
  }
