
  import { Component, Vue } from 'vue-property-decorator';
  import HybridSidebar from './hybrid-sidebar.vue';

  @Component({
    components: {
      HybridSidebar,
    },
  })
  export default class HybridPage extends Vue {}
