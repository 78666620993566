export enum CustomScoring {
  both = 'both',
  none = 'none',
  threshold = 'threshold',
  weighting = 'weighting',
}

export interface SubFormOptions {
  custom_scoring_type: CustomScoring;
  question_codes_locked_in_complete_state?: string[];
  question_codes_locked_in_draft_state?: string[];
  threshold?: number;
  weighting?: number;
}
