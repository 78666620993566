import type { INode as SvgNode } from 'svgson';

type ParsedTemplate = { id: string; title: string };

export function findComponents(root: SvgNode): ParsedTemplate[] {
  const ids: ParsedTemplate[] = [];

  if (root.attributes.id && root.attributes['data-selectable'] === 'true') {
    ids.push({
      id: root.attributes.id,
      title: root.attributes['aria-label'] ?? root.attributes.id,
    });
  }

  if (root.children?.length) {
    return root.children.map((child) => findComponents(child)).reduce((newIds, childIds) => [...newIds, ...childIds], ids);
  }

  return ids;
}
