import {
  SubFormCompletionEventForm,
  RecordRelationEventForm,
  RemoveRecordRelationEventForm,
  ModuleRecordEventForm,
  SubFormListEventForm,
  NotificationMessageEventForm,
  ApiRequestEventForm,
  ActionEventForm,
  UserLocationTagEventForm,
  RemoveUserLocationTagEventForm,
  UserInvolvementEventForm,
  RemoveUserInvolvementEventForm,
  ConfidentialityEventForm,
  RemoveConfidentialityEventForm,
  RecordIndicatorEventForm,
  RemoveRecordIndicatorEventForm,
  AuditEventForm,
  ObservationEventForm,
  ExpensableApproveLineItemEventForm,
  IncidentParticipantEventForm,
  ApprovedExpenseEventForm,
  ChangeWorkflowEventForm,
  RegulatoryReportEventForm,
  UpdateUserEventForm,
  UpdateRecordEventForm,
  DeleteRecordEventForm,
  UpdateSubFormCompletionStateEventForm,
} from './index';

export const EVENTS_FORM_COMPONENTS = {
  SubFormCompletionEventForm,
  RecordRelationEventForm,
  RemoveRecordRelationEventForm,
  ModuleRecordEventForm,
  SubFormListEventForm,
  NotificationMessageEventForm,
  ApiRequestEventForm,
  ActionEventForm,
  UserLocationTagEventForm,
  RemoveUserLocationTagEventForm,
  UserInvolvementEventForm,
  RemoveUserInvolvementEventForm,
  ConfidentialityEventForm,
  RemoveConfidentialityEventForm,
  RecordIndicatorEventForm,
  RemoveRecordIndicatorEventForm,
  AuditEventForm,
  ObservationEventForm,
  ExpensableApproveLineItemEventForm,
  IncidentParticipantEventForm,
  ApprovedExpenseEventForm,
  ChangeWorkflowEventForm,
  RegulatoryReportEventForm,
  UpdateUserEventForm,
  UpdateRecordEventForm,
  DeleteRecordEventForm,
  UpdateSubFormCompletionStateEventForm,
};
