
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import AuditReportTemplateForm from '@app/components/audit-report-templates/audit-report-template-form.vue';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DsModal, AuditReportTemplateForm } })
  export default class NewAuditReportTemplateFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly auditReportTemplateForm?: AuditReportTemplateForm;

    auditReportTemplate: Partial<AuditReportTemplate> = {
      module_name_id: Number(this.moduleNameId),
      row_limit: 100,
    };

    visible = true;
    saving = false;

    submitForm(auditReportTemplate: AuditReportTemplate & { file?: string }): void {
      this.saving = true;
      this.$api
        .createAuditReportTemplate(auditReportTemplate)
        .then(() => {
          toaster(this.$t('tenant.admin.audit_report_template.form.created'));
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => (this.saving = false));
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-audit-report-templates',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }
  }
