import { Vue, Component } from 'vue-property-decorator';

@Component
export default class WithImportModal extends Vue {
  importModalVisibility = false;
  importCreated = false;

  openImportModal(): void {
    this.importModalVisibility = true;
  }
}
