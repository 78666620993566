
  import { useAccountStore } from '@app/stores/account';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import PaperTrailsModal from '@app/components/paper-trails/paper-trails-modal.vue';
  import { Tabs, Tab } from 'uiv';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Location } from '@app/models/location';

  interface TabDetail {
    routeCommonKey: string;
    routeName: string;
    title: string;
  }

  @Component({ components: { PaperTrailsModalLink, PaperTrailsModal, Tab, Tabs, DsDropdown } })
  export default class AdminSettingsLocationEdit extends Vue {
    @Prop([String, Number]) readonly locationId!: string | number;

    location: Nullable<Location> = null;

    locationName = this.location?.name || '';

    tabIndex = 0;

    updateLocationName(location: Location) {
      this.locationName = location.name;
    }

    get accountStore() {
      return useAccountStore();
    }

    get tabs(): TabDetail[] {
      return [
        {
          routeName: 'admin-settings-locations-edit-details',
          title: this.$t('app.labels.details'),
          routeCommonKey: 'details',
        },
        {
          routeName: 'admin-settings-locations-edit-active-users-and-visitors',
          title: this.$t('tenant.settings.locations.sections.tabs.active_users_and_visitors'),
          routeCommonKey: 'active-users-and-visitors',
        },
        {
          routeName: 'admin-settings-locations-edit-minimum-tag-count',
          title: this.$t('tenant.settings.locations.sections.tabs.minimum_tag_count'),
          routeCommonKey: 'minimum-tag-count',
        },
      ];
    }

    get visibleTabs(): TabDetail[] {
      if (this.accountStore.limitPermissionsByExplicitLocations) {
        return this.tabs;
      }
      return this.tabs.filter((tab) => tab.routeCommonKey !== 'active-users-and-visitors');
    }

    beforeChangeTab(indexFrom: number, indexTo: number, done: (err?: unknown) => void): void {
      const toRoute = {
        name: this.visibleTabs[indexTo].routeName,
      };

      this.$router.push(toRoute);
      done();
    }

    beforeMount(): void {
      this.$api.getLocation(Number(this.locationId), { only: ['name', 'id', { location: ['name'] }] }, { cache: true }).then(({ data }) => {
        this.location = data;
        this.locationName = data.name;
      });

      this.tabIndex = this.visibleTabs.findIndex((tab) => this.$route?.name?.includes(tab.routeCommonKey)) || 0;
    }
  }
