
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { SvgSelectorQuestionOptions } from '@app/models/question-options/svg-selector-question-config';

  @Component({ components: {} })
  export default class SvgSelectorShowOptions extends BaseShowOptions<SvgSelectorQuestionOptions> {
    svgTemplate: Partial<Pick<SvgLibraryTemplate, 'id' | 'name'>> = { name: '' };

    beforeMount() {
      this.$api
        .getSvgTemplate(this.config.svg_template_id, { only: ['id', 'name'] }, { join: true, cache: true })
        .then(({ data }) => {
          this.svgTemplate = data;
        })
        .catch(() => {
          this.svgTemplate = { name: this.$t('tenant.admin.sub_form_questions.options.show.none_chosen') as string };
        });
    }
  }
