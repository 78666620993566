
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import { flatten } from 'lodash';

  import type { SinglePersonSelectorFormField } from '../extras/model';
  import SinglePersonSelectorFieldPicker from '../extras/single-person-selector-field-picker.vue';
  import UserFilterConfigurator from '../filter-configurator/user-filter-configurator.vue';
  import type { AxiosPromise } from 'axios';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import RadioButtons from '@app/components/admin/questions/edit/radio-buttons.vue';
  import Select2 from '@app/components/select2.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '../edit/form-field.vue';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import type { Role } from '@app/models/role';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { FieldType } from '@app/models/sub-form-question';
  import { UserType } from '@app/models/tenant-user';
  import type { SinglePersonSelectorQuestionOptions } from '@app/models/question-options/single-person-selector-question-options';
  import { EmailMatchingBehaviour } from '@app/models/question-options/single-person-selector-question-options';
  import type { DonesafeFilterOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  const defaults = {
    fields: [
      { key: 'email', name: '[user][email]', type: 'email', label: 'Email', required: false },
      {
        key: 'mobile',
        name: '[user][mobile]',
        type: 'text',
        label: 'Best Contact Number',
        required: false,
      },
      {
        key: 'address_line_1',
        name: '[user][profile_attributes][address_line_1]',
        type: 'text',
        label: 'Address Line 1',
        required: false,
      },
      {
        key: 'address_line_2',
        name: '[user][profile_attributes][address_line_2]',
        type: 'text',
        label: 'Address Line 2',
        required: false,
      },
      {
        key: 'suburb',
        name: '[user][profile_attributes][suburb]',
        type: 'text',
        label: 'Suburb',
        required: false,
      },
      {
        key: 'postcode',
        name: '[user][profile_attributes][postcode]',
        type: 'text',
        label: 'Postcode',
        required: false,
      },
    ] as SinglePersonSelectorFormField[],
    required_filters: {
      active: 'true',
    },
    filters: [{ key: 'type', value: [UserType.TenantUser] }],
    add_new_user_only: 'false' as const,
    new_user_type: UserType.ContactUser,
    email_matching_behaviour: EmailMatchingBehaviour.UseMatchingUser,
  };

  @Component({
    components: {
      UserFilterConfigurator,
      SinglePersonSelectorFieldPicker,
      EntitySelector,
      Select2,
      DsCheckbox,
      RadioButtons,
      FormField,
    },
  })
  export default class SinglePersonSelectorFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<SinglePersonSelectorQuestionOptions>;

    @Prop(Object) readonly subForm!: SubForm;

    locationQuestionOptions: { label: string; value: number }[] = [];
    organizationQuestionOptions: { label: string; value: number }[] = [];

    defaultRole: Nullable<Role> = null;
    moduleName: Nullable<ModuleName> = null;

    loaded = false;

    get newUserTypes(): [string, string][] {
      return [
        ['TenantUser', 'Tenants Only'],
        ['ContactUser', 'Contacts Only'],
        ['VisitorUser', 'Visitors Only'],
        ['ContractorUser', 'Contractors Only'],
        ['MedicalUser', 'Medical Users Only'],
      ];
    }

    get emailMatchingOptions(): { key: string; label: string }[] {
      return [
        {
          key: EmailMatchingBehaviour.UseMatchingUser,
          label: this.$t('tenant.admin.sub_form_questions.form_fields.edit.single_person_selector.use_matching_user'),
        },
        {
          key: EmailMatchingBehaviour.RequireUniqueEmail,
          label: this.$t('tenant.admin.sub_form_questions.form_fields.edit.single_person_selector.require_uniq_email'),
        },
      ];
    }

    get showAddNewSettings(): boolean {
      return this.isPublic || this.value.config.enable_add_new_user === 'true';
    }

    get forceAddNewUser(): boolean {
      return this.value.config.add_new_user_only === 'true';
    }

    get isPublic(): boolean {
      return !!this.moduleName?.feature_set?.is_public;
    }

    get involvementFilters(): DonesafeFilterOptions<Involvement> {
      return {
        active: true,
        module_name: this.moduleName?.name,
        involvement_type: 'standard',
      };
    }

    @Watch('state.add_new_user_only', { immediate: true })
    onAddNewUserOnlyChanged(): void {
      if (this.forceAddNewUser) {
        this.$$patch(this.value.config, { enable_add_new_user: 'true' });
      }
    }

    initForm(): void {
      if (!this.value.id) {
        this.$$patch(this.value.config, { ...defaults, role_id: this.defaultRole?.id }, { ifEmpty: true });
      }

      if (this.value.config.fields) {
        this.$$patch(
          this.value.config.fields,
          this.value.config.fields?.map((field: SinglePersonSelectorFormField) => ({
            ...field,
            required: String(field.required) === 'true',
          }))
        );
      }
    }

    async beforeMount(): Promise<void> {
      const allQuestions = flatten(this.subForm.sub_form_sections?.map((s) => s.sub_form_questions || []));

      this.locationQuestionOptions = allQuestions
        .filter((q) => q.field_type === FieldType.location)
        .map((question) => ({
          value: question.id,
          label: question.title,
        }));

      this.organizationQuestionOptions = allQuestions
        .filter((q) => q.field_type === FieldType.organization)
        .map((question) => ({
          value: question.id,
          label: question.title,
        }));

      const moduleNameFilter =
        this.subForm.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };

      const [
        {
          data: [defaultRole],
        },
        {
          data: [moduleName],
        },
      ] = await Promise.all([
        this.$api.getRoles({ filters: { system: true }, only: ['id', 'name'], sort: 'id' }),
        this.$api.getModuleNames({
          include: ['feature_set'],
          filters: {
            active: true,
            ...moduleNameFilter,
          },
        }),
      ]);

      this.defaultRole = defaultRole;
      this.moduleName = moduleName;

      this.loaded = true;
      this.initForm();
    }

    getInvolvements(options: DonesafeIndexApiOptions<Involvement>, config?: object): AxiosPromise<(Involvement & { label: string })[]> {
      return this.$api.getInvolvements(options, config).then((result) => {
        return {
          ...result,
          data: result.data.map((involvement) => {
            return {
              ...involvement,
              label: involvement.active ? involvement.name : this.$t('app.labels.archived_name', { name: involvement.name }),
            };
          }),
        };
      });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'filters',
        'required_filters',
        'fields',
        'involvement_id',
        'role_id',
        'new_user_type',
        'add_new_user_only',
        'home_location_question_id',
        'home_organization_question_id',
        'enable_add_new_user',
        'add_new_user_panel_title',
        'notifications_on',
        'email_matching_behaviour',
      ].forEach((key) => this.$delete(this.value.config, key));
    }

    $$t(key: string) {
      return this.$t(`tenant.admin.sub_form_questions.form_fields.edit.single_person_selector.${key}`);
    }
  }
