import { render, staticRenderFns } from "./edit-layout-page.vue?vue&type=template&id=197e9515&scoped=true&lang=pug"
import script from "./edit-layout-page.vue?vue&type=script&lang=ts"
export * from "./edit-layout-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197e9515",
  null
  
)

export default component.exports