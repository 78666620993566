
  import { isLegacyModule } from '@app/models/module-name';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import ActivityFormModal from '@app/components/activities/activity-form-modal.vue';
  import AuditReportGenerateModal from '@app/components/audit-report-templates/audit-report-generate-modal.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import QrCodeModal from '@app/components/module-record/qr-code-modal.vue';
  import SetupConfidentialAccessModal from '@app/components/module-record/setup-confidential-access-modal.vue';
  import ModuleRecordPrintConfigModal from '@app/components/module-record/module-record-print-config-modal.vue';
  import PaperTrailsModal from '@app/components/paper-trails/paper-trails-modal.vue';
  import bootbox from 'bootbox';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import qs from 'qs';
  import type { Activity } from '@app/models/activity';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { AuditReportRecordType } from '@app/models/audit-report-template';
  import { SpecialPermissionName } from '@app/models/extended-permission';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { textColor } from '@app/utils/text-color';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import type { NormalTabRecordOnly, NormalTabSfcOnly, NormalTabSflOnly } from './utils';

  @Component({
    components: {
      PaperTrailsModal,
      QrCodeModal,
      AuditReportGenerateModal,
      ActivityFormModal,
      DsDropdown,
      SetupConfidentialAccessModal,
      ModuleRecordPrintConfigModal,
    },
  })
  export default class SubFormCompletionActions extends Vue {
    @Prop(Boolean) readonly printView?: boolean;
    @Prop(Boolean) readonly editable?: boolean;
    @Prop(Boolean) readonly archivable?: boolean;
    @Prop(Boolean) readonly approvable?: boolean;
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly confidential?: boolean;
    @Prop(Boolean) readonly showDocumentExportButton?: boolean;
    @Prop(Object) readonly subFormList!: Pick<SubFormList, NormalTabSflOnly>;
    @Prop(Object) readonly subFormCompletion!: Pick<SubFormCompletion, NormalTabSfcOnly>;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'display' | 'name' | 'show_options'>;
    @Prop(Object) readonly approvalSubFormCompletion?: SubFormCompletion;
    @Prop(Object) readonly record!: Pick<ModuleRecord, NormalTabRecordOnly>;

    activityFormModalState = false;
    qrCodeLinkModalState = false;
    paperTrailsModalState = false;
    confidentialLinkModalState = false;
    generateReportModalState = false;
    SubFormCompletionType = AuditReportRecordType.SubFormCompletion;
    paperTrailsRecordId = '';
    submittingActivity = false;

    newActivity: Partial<Activity> = {
      actionable_type: 'ModuleRecord',
      actionable_id: this.subFormCompletion.record_id || undefined,
      sub_form_completion_id: this.subFormCompletion.id,
    };

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get awaitingApproval() {
      const stage = this.subFormCompletion.stage;
      return stage !== SubFormCompletionStage.Draft && stage !== SubFormCompletionStage.Complete && !this.subFormCompletion?.approval_form;
    }

    get canAccessConfidentialRecord(): boolean {
      return (
        isLegacyModule(this.moduleName) ||
        this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.confidentiality, this.moduleName.name)
      );
    }

    get showAuditTrail(): boolean {
      return (
        this.currentUserStore.hasSystemAdministrationPermission ||
        this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.audit_trail, this.moduleName.name)
      );
    }

    get qrCodeTitle(): string {
      return `${this.$t('module_names.copy_link_modal.sub_form_completion')}: ${this.subFormCompletion.id}`;
    }
    get absoluteUrl(): string {
      return `${window.location.origin}/sub_form_completions/${this.subFormCompletion.id}`;
    }

    get editLink(): string {
      return `/sub_form_completions/${this.subFormCompletion.id}/edit`;
    }

    get classicMenu(): boolean {
      return !!this.accountStore.data.classic_sub_form_menu_enabled;
    }

    get addApprovalLink(): string {
      const queryObject = {
        approval_for_sub_form_completion_id: this.subFormCompletion.id,
        record_id: this.subFormCompletion.record_id,
        record_type: this.subFormCompletion.record_type,
        sub_form_id: this.subFormList.approval_sub_form_id,
        sub_form_list_id: this.subFormList.id,
      };
      return `/sub_form_completions/new?${qs.stringify(queryObject)}`;
    }

    get editApprovalLink(): string {
      return `/sub_form_completions/${this.approvalSubFormCompletion?.id}/edit`;
    }

    get canAddActions(): boolean {
      return !!this.canEdit && this.currentUserStore.canCreateActivity;
    }

    get showManageConfidentiality(): boolean {
      return this.canAccessConfidentialRecord && !!(this.subFormList.allow_confidentiality || this.confidential);
    }

    get approval(): boolean {
      return (
        this.subFormList.sub_form_list_type === SubFormListType.approval || this.subFormList.sub_form_list_type === SubFormListType.workflow
      );
    }

    get completeWithoutApproval(): boolean {
      return this.subFormCompletion.stage === 'Complete' && !this.approvalSubFormCompletion;
    }

    get showAddApprovalButton(): boolean {
      return this.approval && !!this.approvable && (this.awaitingApproval || this.completeWithoutApproval);
    }

    get showEditApprovalButton(): boolean {
      return !!this.approvable && !!this.approvalSubFormCompletion;
    }

    get defaultApprovalButtonClass(): string {
      return this.subFormList.approval_button_background_color ? '' : 'btn-info';
    }

    get approvalButtonStyles(): Record<string, string> {
      if (this.subFormList.approval_button_background_color) {
        return {
          'background-color': `${this.subFormList.approval_button_background_color}`,
          color: textColor(this.subFormList.approval_button_background_color),
        };
      }
      return {};
    }

    openPaperTrailsModal(recordId: string): void {
      this.paperTrailsRecordId = recordId;
      this.paperTrailsModalState = true;
    }

    onDelete(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('tenant.sub_form_completions.index.delete_this_response'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteSubFormCompletion(this.subFormCompletion.id)
              .then(() => changeLocation(`/module_records/${this.subFormCompletion.record_id}?tab=${this.subFormList.module_tab_id}`));
          }
        },
      });
    }

    copyEditLink(): void {
      this.$copyText(this.absoluteUrl);
    }

    createNewAction(): void {
      this.activityFormModalState = true;
    }

    onActivityCreate(form: Partial<Activity>): void {
      this.submittingActivity = true;
      this.$api
        .createActivity(form)
        .then(({ data }) => {
          const activity = data[0];
          if (activity) {
            this.activityFormModalState = false;
            window.location.reload();
          }
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
        .finally(() => (this.submittingActivity = false));
    }
  }
