
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { SignatureQuestionOptions } from '@app/models/question-options/signature-question-options';

  @Component({ components: {} })
  export default class SignatureShowOptions extends BaseShowOptions<SignatureQuestionOptions> {
    get quickAddOwnSignature(): boolean {
      return this.config.quick_add_own_signature === 'true';
    }

    get displaySignatureAddedBy(): boolean {
      return this.config.display_signature_added_by === 'true';
    }
  }
