
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Collapse } from 'uiv';

  @Component({
    components: {
      Collapse,
    },
  })
  export default class TestResultSection extends Vue {
    @Prop(String) readonly name!: String;
    @Prop(String) readonly summary!: String;

    open = false;
  }
