
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import ColorInput from '@app/components/color-input.vue';
  import { startCase } from 'lodash';
  import type { ReportCountWidgetOptions } from '@app/models/widget';
  import { ReportCountTargetDifference } from '@app/models/widget';

  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({
    components: { Select2, EntitySelector, ColorInput, ValidationObserver, ValidationProvider },
  })
  export default class ReportCountWidgetSettings extends BaseWidgetSettings<ReportCountWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<ReportCountWidgetOptions>;

    get superReportFilter(): object {
      return { report_blob: { '!klass': ['MongoTrails::Version', 'Calculation'], graph: { '!type': 'heatmap' } } };
    }

    get getTargetDifferenceOptions(): [ReportCountTargetDifference, string][] {
      return Object.values(ReportCountTargetDifference).map((x) => [x, startCase(x)]);
    }

    iconListTemplate(option: { id: string; text: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      return $(
        `<div>
            <i class="material-icons">${option.id}</i>
            <span style="position: relative; bottom: 7px">${option.id}</span>
          </div>`
      );
    }

    beforeMount(): void {
      extend('super_report_id_type', {
        validate: (value: string) => {
          return this.$api
            .getSuperReport(Number(value), { only: 'report_blob' }, { cache: true })
            .then(({ data }) => {
              return { valid: data.report_blob.klass !== 'Calculation' };
            })
            .catch(({ data }) => {
              return {
                valid: false,
                data: data?.error,
              };
            });
        },
        message: this.$t('app.labels.invalid_report_type'),
      });
    }
  }
