
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class OneHsiNavigation extends Vue {
    get hideBackButton(): boolean {
      const referrer = document.referrer;
      if (referrer === '') return true;

      const url = new URL(referrer);
      return url.pathname.includes('users/sign_in') || url.pathname === '/';
    }

    back(): void {
      history.back();
    }
  }
