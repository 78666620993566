
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { cloneDeep } from 'lodash';
  import type { CalculationRule } from '@app/models/calculation-rule';

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class CalculationRuleForm extends Vue {
    @Prop(Object) readonly calculationRule?: Partial<CalculationRule>;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<CalculationRule> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    initForm(): void {
      if (this.calculationRule) {
        this.form = cloneDeep(this.calculationRule);
      }
    }

    beforeMount() {
      this.initForm();
    }
  }
