
  import WithAdminPermissions from '@app/mixins/with-admin-permissions';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { mixins } from 'vue-class-component';
  import { Component, Ref } from 'vue-property-decorator';
  import { pick, includes, groupBy, intersection, castArray } from 'lodash';
  import type { ModuleName } from '@app/models/module-name';
  import { ModuleType } from '@app/models/module-name';

  import SearchInput from '../search-input.vue';

  import type { AdminLink } from './models';
  import RoleProfileWarnings from './role-profiles/role-profile-warnings.vue';

  @Component({ components: { SearchInput, RoleProfileWarnings } })
  export default class AdminSettingsPage extends mixins(WithAdminPermissions) {
    @Ref() readonly targetElement!: HTMLElement[];
    intersectionObserver?: IntersectionObserver;

    loading = true;

    moduleNames: Pick<ModuleName, 'id' | 'name' | 'plural_display' | 'module_type'>[] = [];
    search = (this.$route.query.search as string) || '';

    navFlags: Record<string, boolean> = {};
    observeScroll = true;
    hasRegulatoryReports = false;

    get hasHybridModules() {
      return !!this.moduleNames.filter(({ module_type }) => module_type === ModuleType.form_managed).length;
    }

    get navs(): Record<AdminPermissionFeature, string> {
      return this.adminPermissionNames;
    }

    get getAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.users'),
          heading: this.$t('tenant.admin.settings.menus.headings.users_locations_organizations'),
          path: 'users',
          icon: 'fa-users',
          feature: AdminPermissionFeature.users_locations_organizations,
        },
        {
          title: this.$t('tenant.admin.settings.menus.locations'),
          heading: this.$t('tenant.admin.settings.menus.headings.users_locations_organizations'),
          path: 'locations',
          icon: 'fa-map-marker',
          feature: AdminPermissionFeature.users_locations_organizations,
        },
        {
          title: this.$t('tenant.admin.settings.menus.organizations'),
          heading: this.$t('tenant.admin.settings.menus.headings.users_locations_organizations'),
          path: 'organizations',
          icon: 'fa-building',
          feature: AdminPermissionFeature.users_locations_organizations,
        },
        {
          title: this.$t('tenant.admin.settings.menus.roles'),
          heading: this.$t('tenant.admin.settings.menus.headings.roles_and_tags'),
          path: 'roles',
          icon: 'fa-graduation-cap',
          feature: AdminPermissionFeature.roles_and_tags,
          key: 'Roles',
        },
        {
          title: this.$t('tenant.admin.settings.menus.tags'),
          heading: this.$t('tenant.admin.settings.menus.headings.roles_and_tags'),
          path: 'location_tags',
          icon: 'fa-tag',
          feature: AdminPermissionFeature.roles_and_tags,
        },
        // module_config
        {
          title: this.$t('tenant.admin.settings.menus.modules'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'module_names',
          icon: 'fa-cubes',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hybrid_modules'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'hybrid_modules',
          icon: 'fa-file-text',
          feature: [AdminPermissionFeature.module_config, AdminPermissionFeature.hybrid_modules],
          hidden: !this.hasHybridModules,
        },
        {
          title: this.$t('tenant.admin.settings.menus.automations'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'automation_definitions',
          icon: 'fa-magic',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.master_events_log'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'event_occurrences',
          icon: 'fa-asterisk',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.placeholder_profiles'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'placeholder_profiles',
          icon: 'fa-magic',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.user_collections'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'user_collections',
          icon: 'fa-users',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.scheduler'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'scheduled_events',
          icon: 'fa-clock-o',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hour_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'hour_types',
          icon: 'fa-calendar',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.unsubscription_collections'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'unsubscription_collections',
          icon: 'fa-envelope-o',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.matrices'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'matrices',
          icon: 'fa-table',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.matrix_calculation_rules'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'calculation_rules',
          icon: 'fa-calculator',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.svg_templates'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_config'),
          path: 'svg_templates',
          icon: 'fa-image',
          feature: AdminPermissionFeature.module_config,
        },
        {
          title: this.$t('tenant.admin.settings.menus.reports'),
          heading: this.$t('tenant.admin.settings.menus.headings.reporting'),
          path: 'reports',
          icon: 'fa-bar-chart',
          feature: AdminPermissionFeature.reporting,
        },
        {
          title: this.$t('tenant.admin.settings.menus.dashboards'),
          heading: this.$t('tenant.admin.settings.menus.headings.reporting'),
          path: 'dashboards',
          icon: 'fa-laptop',
          feature: AdminPermissionFeature.reporting,
        },
        {
          title: this.$t('tenant.admin.settings.menus.dynamic_dashboard'),
          heading: this.$t('tenant.admin.settings.menus.headings.reporting'),
          path: 'dashboard_pane_groups',
          icon: 'fa-laptop',
          feature: [AdminPermissionFeature.reporting, AdminPermissionFeature.roles_and_tags],
        },
        {
          title: this.$t('tenant.admin.settings.menus.export_your_data_to_csv'),
          heading: this.$t('tenant.admin.settings.menus.headings.reporting'),
          path: 'export_all',
          icon: 'fa-cloud-download',
          feature: AdminPermissionFeature.system_administration,
          nav: AdminPermissionFeature.reporting,
          hidden: !this.currentUserStore.hasSystemAdministrationPermission || !this.accountStore.data.can_export_data,
        },
        {
          title: this.$t('tenant.admin.settings.menus.special_reports_and_extracts'),
          heading: this.$t('tenant.admin.settings.menus.headings.reporting'),
          path: 'regulatory_reports',
          icon: 'fa-cloud-download',
          feature: 'regulatory_reporting',
          nav: AdminPermissionFeature.reporting,
          hidden: !this.hasRegulatoryReports,
        },
        {
          title: this.$t('tenant.admin.settings.menus.module_bundle_publishing'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_publishing'),
          path: 'app_bundles',
          icon: 'fa-calculator',
          feature: AdminPermissionFeature.system_administration,
          hidden: !this.currentUserStore.isTechAdmin || this.accountStore.data.app_bundle_options?.enabled !== 'true',
        },
        {
          title: this.$t('tenant.admin.settings.menus.module_bundle_installation'),
          heading: this.$t('tenant.admin.settings.menus.headings.module_publishing'),
          path: 'app_bundles/installations',
          icon: 'fa-cloud-download',
          feature: AdminPermissionFeature.system_administration,
          hidden: !this.currentUserStore.isTechAdmin || this.accountStore.data.app_bundle_options?.show_installation_link !== 'true',
        },
        {
          title: this.$t('tenant.admin.settings.menus.mobile_apps'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'mobile_apps',
          icon: 'fa-mobile',
          feature: AdminPermissionFeature.system_administration,
          hidden: !this.accountStore.data.ds_connect_integration_enabled,
        },

        {
          title: this.$t('tenant.admin.settings.menus.notifications'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'notifications',
          icon: 'fa-envelope-square',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.restriction_method'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'restriction_methods',
          icon: 'fa-envelope-square',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.user_settings.tabs.user_settings'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'user_settings',
          icon: 'fa-user',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.action_settings'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'action_settings',
          icon: 'fa-check',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.authentication_and_sso'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'samls',
          icon: 'fa-sign-in',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.account_settings.title'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'account_settings',
          icon: 'fa-cog',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.administrative_roles'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'administrative_roles',
          icon: 'fa-graduation-cap',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.scorm_courses'),
          heading: this.$t('tenant.admin.settings.menus.headings.system_administration'),
          path: 'scorm_courses',
          icon: '',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.translations'),
          heading: this.$t('tenant.admin.settings.menus.headings.translations'),
          path: 'translations',
          icon: 'fa-language',
          feature: AdminPermissionFeature.translations,
          hidden: (this.accountStore.data.all_languages || []).length < 2,
        },
        ...this.specialModuleLinks,
        // look_and_feel
        {
          title: this.$t('tenant.admin.settings.menus.look_and_logo'),
          heading: this.$t('tenant.admin.settings.menus.headings.look_and_feel'),
          path: 'looks',
          icon: 'fa-picture-o',
          feature: AdminPermissionFeature.look_and_feel,
        },
        {
          title: this.$t('tenant.admin.settings.menus.menu_collections'),
          heading: this.$t('tenant.admin.settings.menus.headings.look_and_feel'),
          path: 'menu_collections',
          icon: 'fa-object-group',
          feature: AdminPermissionFeature.look_and_feel,
        },
        {
          title: this.$t('tenant.admin.settings.menus.users_locations_organizations_imports'),
          heading: this.$t('tenant.admin.settings.menus.headings.record_importing'),
          path: 'super_imports',
          icon: 'fa-download',
          feature: AdminPermissionFeature.record_importing,
        },
        {
          title: this.$t('tenant.admin.settings.menus.module_record_imports'),
          heading: this.$t('tenant.admin.settings.menus.headings.record_importing'),
          path: 'module_record_imports',
          icon: 'fa-download',
          feature: AdminPermissionFeature.record_importing,
        },
        {
          title: this.$t('tenant.admin.settings.menus.all_imports'),
          heading: this.$t('tenant.admin.settings.menus.headings.record_importing'),
          path: 'all_imports',
          icon: 'fa-download',
          feature: AdminPermissionFeature.record_importing,
        },
      ];
    }

    get getExpensingAdvancedAdminLinks(): AdminLink[] {
      if (!this.accountStore.expensingEnabled) {
        return [];
      }

      return [
        {
          title: this.$t('tenant.admin.settings.menus.expensing_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.expensing_advanced'),
          path: 'expensable/categories',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.expensing_behaviours'),
          heading: this.$t('tenant.admin.settings.menus.headings.expensing_advanced'),
          path: 'expensable/behaviours',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.expensing_table'),
          heading: this.$t('tenant.admin.settings.menus.headings.expensing_advanced'),
          path: 'expensable/expensing_tables',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.expensing_transaction_ledgers'),
          heading: this.$t('tenant.admin.settings.menus.headings.expensing_advanced'),
          path: 'expensable/transaction_ledgers',
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get getGhsModuleAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.ghs_hazard_classification_classes'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'ghs_hazard_classification_classes',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.ghs_hazard_classification_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'ghs_hazard_classification_categories',
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get getCompanyRegisterModuleAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.company_register_terms_and_conditions'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'terms',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.company_register_service_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'service_categories',
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get getKbModuleAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.knowledge_base_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'procedure_categories',
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get getVisitorRegisterModuleAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.visitor_register_apps'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'visitor_register_apps',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.visitor_register_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.special_module_settings'),
          path: 'visitor_register_types',
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get getAccountSpecificAdminLinks(): AdminLink[] {
      return [
        {
          title: this.$t('tenant.admin.settings.menus.suncorp_csvs'),
          heading: this.$t('tenant.admin.settings.menus.headings.account_specific'),
          path: 'suncorp_csvs/new',
          feature: AdminPermissionFeature.system_administration,
          hidden: !['suncorp', 'suncorptest', 'suncorptesttimezone'].includes(this.accountStore.data.schema),
        },
        {
          title: this.$t('tenant.admin.settings.menus.nab_file_extractors'),
          heading: this.$t('tenant.admin.settings.menus.headings.account_specific'),
          path: 'file_extractors',
          feature: AdminPermissionFeature.system_administration,
          hidden: !window.DONESAFE.fileExtractorEnabled,
        },
      ];
    }

    get getClassicSettingsAdminLinks(): AdminLink[] {
      let links: AdminLink[] = [
        {
          title: this.$t('tenant.admin.settings.menus.cost_centres'),
          heading: this.$t('tenant.admin.settings.menus.headings.classic_settings'),
          path: 'cost_centres',
          feature: AdminPermissionFeature.system_administration,
          hidden: !this.currentUserStore.isTechAdmin,
        },
        {
          title: this.$t('tenant.admin.settings.menus.expense_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.classic_settings'),
          path: 'expense_categories',
          feature: AdminPermissionFeature.system_administration,
          hidden: !this.currentUserStore.isTechAdmin,
        },
      ];

      if (this.accountStore.data.ds_version === 1) {
        links = links.concat([
          {
            title: this.$t('tenant.admin.settings.menus.areas'),
            heading: this.$t('tenant.admin.settings.menus.headings.classic_settings'),
            path: 'areas',
            hidden: !this.currentUserStore.isTechAdmin,
            feature: AdminPermissionFeature.system_administration,
          },
          {
            title: this.$t('tenant.admin.settings.menus.action_tracks'),
            heading: this.$t('tenant.admin.settings.menus.headings.classic_settings'),
            path: 'action_tracks',
            hidden: !this.currentUserStore.isTechAdmin,
            feature: AdminPermissionFeature.system_administration,
          },
        ]);
      }

      return links;
    }

    get getClassicModulesAdminLinks(): AdminLink[] {
      if (this.accountStore.data.ds_version !== 1) {
        return [];
      }

      return [
        {
          title: this.$t('tenant.admin.settings.menus.audit_detail_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          path: 'audit_detail_categories',
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.audit_detail_ratings'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          path: 'audit_detail_ratings',
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.observation_categories'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          path: 'observation_categories',
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.observation_ratings'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          path: 'observation_ratings',
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.observe_response_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          path: 'observe_response_types',
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.observe_templates'),
          heading: this.$t('tenant.admin.settings.menus.headings.audit_and_observations'),
          path: 'observe_templates',
          hidden: !this.moduleEnabled(['Audit', 'Observation']),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hazard_risk_model'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'risk_models/edit',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hazard_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'hazard_types',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.risk_likelihoods'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'risk_likelihoods',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.risk_seriousnesses'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'risk_seriousnesses',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hazard_contributing_factor_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'contributing_types',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hazard_contributing_factor_subtypes'),
          heading: this.$t('tenant.admin.settings.menus.headings.hazard'),
          path: 'contributing_subtypes',
          hidden: !this.moduleEnabled('Hazard'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_claim_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_work_types',
          feature: AdminPermissionFeature.system_administration,
          hidden: this.accountStore.data.schema !== 'suncorp' || !this.currentUserStore.isInternal || !this.moduleEnabled('Incident'),
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_classifications'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_classifications',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_types',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_detail_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_detail_types',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_participant_roles'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_participant_roles',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_potential_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_potentials',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_response_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_response_types',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_templates'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'incident_templates',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.body_parts'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'body_parts',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.incident_lti_definitions'),
          heading: this.$t('tenant.admin.settings.menus.headings.incident'),
          path: 'lti_definitions?lti_type=Incident',
          hidden: !this.moduleEnabled('Incident'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.employment_statuses'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'employment_statuses',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.employment_programs'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'employment_programs',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.body_parts'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'body_parts',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.medical_certificate_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'medical_cert_types',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.lti_schemes'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'schemes',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.pay_days'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'pay_days',
          hidden: !this.currentUserStore.isTechAdmin || !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.case_notes_contact_type'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'contact_types',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.case_notes_mechanism_type'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'mechanisms',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.workers_compensation_case_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'case_types',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.rehabilitation_update_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'rehabilitation_updates',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.lti_definitions'),
          heading: this.$t('tenant.admin.settings.menus.headings.workers_compensation'),
          path: 'lti_definitions?lti_type=WorkerCompensation',
          hidden: !this.moduleEnabled('WorkerCompensation'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.learning_types'),
          heading: this.$t('tenant.admin.settings.menus.headings.learn'),
          path: 'learning_types',
          hidden: !this.moduleEnabled('LearningRecord'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.competency_outcomes'),
          heading: this.$t('tenant.admin.settings.menus.headings.learn'),
          path: 'competency_outcomes',
          hidden: !this.moduleEnabled('LearningRecord'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.delivery_methods'),
          heading: this.$t('tenant.admin.settings.menus.headings.learn'),
          path: 'delivery_methods',
          hidden: !this.moduleEnabled('LearningRecord'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.courses'),
          heading: this.$t('tenant.admin.settings.menus.headings.learn'),
          path: 'courses',
          hidden: !this.moduleEnabled('LearningRecord'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.lms_courses'),
          heading: this.$t('tenant.admin.settings.menus.headings.learn'),
          path: 'lms_courses',
          hidden: !this.moduleEnabled('LmsCourseEnrollment'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.hazard_classes'),
          heading: this.$t('tenant.admin.settings.menus.headings.chemical_register'),
          path: 'hazard_classes',
          hidden: !this.moduleEnabled('ChemicalRegister'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.packaging_groups'),
          heading: this.$t('tenant.admin.settings.menus.headings.chemical_register'),
          path: 'packaging_groups',
          hidden: !this.moduleEnabled('ChemicalRegister'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.job_management_register_ppes'),
          heading: this.$t('tenant.admin.settings.menus.headings.job_management_register'),
          path: 'personal_protective_equipments',
          hidden: !this.moduleEnabled('JobManagementRegister'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.job_management_register_code_of_practices'),
          heading: this.$t('tenant.admin.settings.menus.headings.job_management_register'),
          path: 'code_of_practices',
          hidden: !this.moduleEnabled('JobManagementRegister'),
          feature: AdminPermissionFeature.system_administration,
        },
        {
          title: this.$t('tenant.admin.settings.menus.job_requirement_templates'),
          heading: this.$t('tenant.admin.settings.menus.headings.job_management_register'),
          path: 'job_requirement_templates',
          hidden: !this.moduleEnabled('JobManagementRegister'),
          feature: AdminPermissionFeature.system_administration,
        },
      ];
    }

    get specialModuleLinks(): AdminLink[] {
      return [
        ...(this.moduleEnabled('GhsChemicalRegister') ? this.getGhsModuleAdminLinks : []),
        ...(this.moduleEnabled('CompanyRegister') ? this.getCompanyRegisterModuleAdminLinks : []),
        ...(this.moduleEnabled('Procedure') ? this.getKbModuleAdminLinks : []),
        ...(this.moduleEnabled('VisitorRegister') ? this.getVisitorRegisterModuleAdminLinks : []),
      ];
    }

    get links(): AdminLink[] {
      const links = [
        ...this.getAdminLinks,
        ...this.getExpensingAdvancedAdminLinks,
        ...this.getAccountSpecificAdminLinks,
        ...this.getClassicSettingsAdminLinks,
        ...this.getClassicModulesAdminLinks,
      ];

      return links
        .filter(({ hidden }) => !hidden)
        .filter(({ feature }) => this.currentUserStore.isTechAdmin || !!intersection(this.myAdminFeatures, castArray(feature)).length)
        .map((link: AdminLink) => ({
          ...link,
          nav: link.nav || castArray(link.feature)[0],
          showGroupTitle: () => includes(this.pathsWithVisibleGroupTitle, link.path),
        }));
    }

    get pathsWithVisibleGroupTitle(): string[] {
      return Object.entries(groupBy(this.filteredLinks, 'heading'))
        .map((heading) => heading[1][0])
        .map((link: AdminLink) => link.path);
    }

    get filteredNavs(): Record<string, string> {
      return pick(this.navs, Object.keys(groupBy(this.filteredLinks, 'nav')));
    }

    get filteredLinks(): AdminLink[] {
      if (!this.search) {
        return this.links;
      }

      const search = this.search.toLowerCase();

      // search by heading or title
      return this.links.filter(
        (link: AdminLink) => includes(`${link.title}`.toLowerCase(), search) || includes(`${link.heading}`.toLowerCase(), search)
      );
    }

    get myAdminFeatures(): AdminPermissionFeature[] {
      const features = this.currentUserStore.data?.admin_permission_features || [];
      return features.filter((feature) => !this.moduleConfigDisabledForNonTechAdmins(feature));
    }

    moduleEnabled(moduleName: string | string[]): boolean {
      const moduleNames = castArray(moduleName);
      return this.moduleNames.some((mn) => moduleNames.includes(mn.name));
    }

    onSearchChange(search: string): void {
      this.$router.replace({ query: { search } });
      this.$nextTick(() => {
        this.reObserveElements();
      });
    }

    onNavClick(nav: string): void {
      this.setActiveFlag(nav);
      this.observeScroll = false;
      this.$scrollTo(`#admin-settings-${nav}`, {
        onDone: () => {
          this.observeScroll = true;
        },
      });
    }

    setupIntersectionObserver() {
      const observerOptions = {
        threshold: [0, 1],
        rootMargin: '-90px 0px 0px 0px',
      };

      this.intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;
          if (this.observeScroll && entry.isIntersecting && entry.boundingClientRect.top <= 100) {
            this.setActiveFlag(target.accessKey);
          }
        });
      }, observerOptions);

      this.setObserve();
    }

    reObserveElements() {
      this.setUnObserve();
      this.initialNavFlags();
      this.setObserve();
    }

    setObserve() {
      this.targetElement.forEach((ref: HTMLElement) => {
        this.intersectionObserver?.observe(ref);
      });
    }

    setUnObserve() {
      this.targetElement.forEach((ref: HTMLElement) => {
        this.intersectionObserver?.unobserve(ref);
      });
    }

    initialNavFlags() {
      const filteredNavs = this.filteredNavs;
      this.navFlags = Object.keys(filteredNavs).reduce<Record<string, boolean>>((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
    }

    setActiveFlag(key: string) {
      Object.keys(this.navFlags).forEach((k) => {
        this.navFlags[k] = false;
      });
      this.navFlags[key] = true;
    }

    beforeMount(): void {
      this.$api.getRegulatoryReportConfigs({ only: ['id'], filters: { active: true } }, { cache: true }).then(({ data }) => {
        this.hasRegulatoryReports = !!data.length;
      });

      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['id', 'name', 'plural_display', 'module_type'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
          this.loading = false;
          this.$nextTick(() => {
            this.setupIntersectionObserver();
          });
        });
      this.initialNavFlags();
    }
    beforeDestroy() {
      this.intersectionObserver?.disconnect();
    }
  }
