
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import { SORTABLE_TYPES } from '@app/services/model-helpers';
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { SubFormIndexWidgetOptions } from '@app/models/widget';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({ components: { Select2, EntitySelector, ValidationObserver, ValidationProvider } })
  export default class SubFormIndexWidgetSettings extends BaseWidgetSettings<SubFormIndexWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<SubFormIndexWidgetOptions>;

    subForms: Pick<SubForm, 'id' | 'title' | 'module_name'>[] = [];
    subFormLists: Pick<SubFormList, 'id' | 'title' | 'module_name' | 'sub_form_ids' | 'approval_sub_form_id'>[] = [];
    moduleNames: Pick<ModuleName, 'display' | 'name' | 'id'>[] = [];

    get subFormOptions(): [number, string][] {
      let subForms = this.subForms;
      if (this.availableSubFormIds) {
        subForms = subForms.filter((subForm) => this.availableSubFormIds.includes(String(subForm.id)));
      }
      return subForms.map((item) => [item.id, `${this.displayModuleNames[item.module_name]}: ${item.title}`]);
    }

    get subFormListOptions(): [number, string][] {
      return this.subFormLists.map((item) => {
        const title = item.module_name?.display ? `${item.module_name.display}: ${item.title}` : item.title;
        return [item.id, title];
      });
    }

    get subFormQuestionsFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return {
        field_type: SORTABLE_TYPES,
        sub_form_section: { sub_form_id: this.widgetOptions.sub_form_id },
        active: true,
      };
    }

    get selectedSubFormList(): Pick<SubFormList, 'id' | 'title' | 'module_name' | 'sub_form_ids' | 'approval_sub_form_id'> | undefined {
      return this.subFormLists.find((sfl) => String(sfl.id) === this.widgetOptions.sub_form_list_id);
    }

    get displayModuleNames(): Record<string, string> {
      return Object.assign({}, ...this.moduleNames.map((module) => ({ [module.name]: module.display })));
    }

    get availableSubFormIds(): string[] {
      if (this.selectedSubFormList) {
        const baseSelection = [
          ...(this.selectedSubFormList.sub_form_ids || []),
          ...(this.selectedSubFormList.approval_sub_form_id ? [this.selectedSubFormList.approval_sub_form_id] : []),
        ].map(String);

        if (baseSelection.includes(String(this.widgetOptions.sub_form_id))) return baseSelection;
        return [...baseSelection, String(this.widgetOptions.sub_form_id)];
      }
      return [];
    }

    clearForm(): void {
      this.widgetOptions = { ...this.widgetOptions, sort: '', reverse: 'false' };
    }

    onSubFormListChange(): void {
      this.clearForm();
      if (this.selectedSubFormList?.sub_form_ids?.length === 1) {
        this.widgetOptions.sub_form_id = this.selectedSubFormList?.sub_form_ids[0];
      } else {
        this.widgetOptions.sub_form_id = undefined;
      }
    }

    onSubFormChange(): void {
      this.clearForm();
    }

    fetchAllSubFormLists(): Promise<void> {
      return this.$api
        .getSubFormLists(
          {
            filters: {
              active: true,
              sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
              module_tab: {
                module_name: this.moduleNames.map((m) => m.name).join(','),
              },
            },
            per_page: -1,
            only: ['id', 'title', 'module_name', 'sub_form_ids', 'approval_sub_form_id'],
            cache: true,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.subFormLists = data;

          if (this.subFormLists[0]) {
            if (!this.widgetOptions.sub_form_list_id) {
              this.widgetOptions.sub_form_list_id = this.subFormLists[0].id;
            }
          }
        });
    }

    fetchAllSubForms(): Promise<void> {
      return this.$api
        .getSubForms(
          {
            filters: {
              // DO NOT use !module_name filter
              // as it will return sub forms with module_name: nil
              active: true,
              module_name: this.moduleNames.map((m) => m.name),
            },
            per_page: -1,
            only: ['id', 'title', 'module_name'],
            cache: true,
            sort: 'module_name',
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.subForms = [...data.filter((item) => item.module_name != MAIN_FORM_MODULE_NAME)];
        });
    }

    beforeMount(): void {
      this.$api
        .getModuleNames(
          {
            filters: {
              '!name': HARDCODED_MODULE_CODES.join(','),
              active: true,
            },
            per_page: -1,
            only: ['name', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = [...data];
          this.fetchAllSubFormLists();
          this.fetchAllSubForms();
        });
    }
  }
