
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class UserParentType extends Vue {
    @Prop(Number) readonly id!: number;

    title = '';

    get recordPath(): string | undefined {
      if (this.currentUserStore.data?.technical_admin) return `/admin/settings/users/${this.id}/edit`;
      return;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    async beforeMount(): Promise<void> {
      try {
        const { data: user } = await this.$api.getTenantUser(
          this.id,
          { only: ['id', 'full_name'] },
          {
            cache: true,
            join: true,
          }
        );
        this.title = `[${this.id}] ${user.full_name}`;
      } catch {
        this.title = `[${this.id}] User`;
      }
    }
  }
