
  import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver } from 'vee-validate';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type DashboardPane from '@app/models/dashboard-pane';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Select2 } from '@app/components';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { ModuleName } from '@app/models/module-name';
  import { mixins } from 'vue-class-component';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import DsButton from '@app/components/ds-button.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { toPairs } from 'lodash';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { DashboardPaneSize } from '@app/models/dashboard-pane';

  @Component({
    components: {
      DsCheckbox,
      DsButton,
      DsIconText,
      FormField,
      DsModal,
      ValidationObserver,
      EntitySelector,
      Select2,
    },
  })
  export default class DashboardPaneModal extends mixins(WithBootbox) {
    @Prop(Object) readonly value!: DashboardPane;
    @Prop(Object) readonly moduleName?: ModuleName;
    @Prop(Boolean) readonly blocked!: boolean;
    @Prop({ type: Boolean, default: false }) readonly group!: boolean;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<DashboardPane> = {};
    DashboardPaneSize = DashboardPaneSize;

    get modalTitle(): string {
      if (this.exists) {
        return this.standalone
          ? this.$t('tenant.admin.dashboard_panes.title_edit')
          : this.$t('tenant.admin.dashboard_panes.title_edit_module');
      } else {
        return this.standalone
          ? this.$t('tenant.admin.dashboard_panes.title_new')
          : this.$t('tenant.admin.dashboard_panes.title_new_module');
      }
    }

    get standalone() {
      return !this.moduleName;
    }

    get exists() {
      return !!this.form.id;
    }

    // only changes to role config are allowed without this
    get changesEnabled() {
      return this.currentUserStore.featureEnabled([AdminPermissionFeature.reporting, AdminPermissionFeature.module_config]);
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get roleProfileFilters() {
      return { active: true, module_name: { active: true, name: this.moduleName?.name } };
    }

    get roleFilters() {
      return { active: true };
    }

    get sizeOptions() {
      return toPairs({
        [DashboardPaneSize.standard]: this.$t('tenant.admin.dashboard_panes.sizes.standard'),
        [DashboardPaneSize.half]: this.$t('tenant.admin.dashboard_panes.sizes.6x6'),
      });
    }

    @Watch('value', { immediate: true })
    initForm() {
      this.form = { ...this.value };
      this.validator?.reset();
    }

    async save(editDashboard: boolean = false): Promise<void> {
      if (!(await this.validator?.validate())) return;

      this.$emit('save', this.form, editDashboard);
    }

    roleProfileTemplate(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result as { [key: string]: string }, {
        primaryAttribute: 'name',
        secondaryAttribute: 'secondary_information',
      });
    }

    async close() {
      if (!(await this.canClose())) return;

      this.$emit('close');
    }

    async canClose(): Promise<boolean> {
      if (
        this.validator?.flags.changed &&
        !(await this.confirm(this.$t('tenant.admin.dashboard_panes.close_confirmation'), { backdrop: false }))
      ) {
        return false;
      }

      return true;
    }

    onSubmit(ev: SubmitEvent) {
      if (ev?.submitter?.id !== 'submit-dashboard-pane-form') {
        return;
      } // prevent form from saving after random <button>s inside it were pressed

      this.save(this.exists);
    }
  }
