
  import type { Involvement } from '@app/models/involvement';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import { groupBy } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import BasePrint from './base-print';

  @Component({ components: {} })
  export default class PrintInvolvements extends BasePrint {
    @Prop() readonly involvements!: Involvement[];

    get userInvolvementsById(): Record<number, UserInvolvement[]> {
      return groupBy(this.record?.user_involvements, 'involvement_id');
    }
  }
