
  import Blocking from '@app/mixins/blocking';
  import BaseAdminRolesPage from '@app/pages/admin/roles/base-admin-roles-page';
  import { omit } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import RoleTabDetailsForm from './role-tab-details-form.vue';
  import type { RoleTabDetailsFormType, RoleTabDetailsRoleOnly } from './utils';
  import { RTB_ROLE_ONLY } from './utils';
  import type { NavigationGuardNext, Route } from 'vue-router';
  import type { Role } from '@app/models/role';
  import { toaster } from '@app/utils/toaster';

  Component.registerHooks(['beforeRouteLeave']);

  @Component({ components: { RoleTabDetailsForm } })
  export default class RoleTabDetails extends mixins(Blocking, BaseAdminRolesPage) {
    @Prop({ type: Number }) readonly roleId?: number;

    role: Nullable<Pick<Role, RoleTabDetailsRoleOnly>> = null;

    get showForm(): boolean {
      return !this.blocked && (!this.roleId || !!this.role);
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
      this.beforeRouteLeaveHelper(to, from, next);
    }

    onFormSubmit(form: RoleTabDetailsFormType): void {
      const entity = {
        ...omit(form, 'permissions', 'role_profiles'),
        permission_ids: form.permissions?.map(({ id }) => id),
      };
      const params = { only: RTB_ROLE_ONLY };
      const promise = entity.id ? this.$api.updateRole(entity.id, entity, params) : this.$api.createRole(entity, params);
      this.blocking(async () => {
        const { data: role } = await promise;
        toaster(
          entity.id ? this.$t('controllers.tenant.admin.roles.update.success') : this.$t('controllers.tenant.admin.roles.create.success')
        );
        this.$api.cache.clear();
        if (!entity?.id && !!role.id) {
          this.$router.push({ name: 'admin-settings-roles-edit-details', params: { roleId: `${role.id}`, ignoreRouteLeaveGuard: 'true' } });
        }
        this.role = role;
      });
    }

    beforeMount(): void {
      this.blocking(async () => {
        if (!this.roleId) return;
        const { data } = await this.$api.getRole(this.roleId, { only: RTB_ROLE_ONLY });
        this.role = data;
      });
    }
  }
