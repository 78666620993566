import { render, staticRenderFns } from "./admin-report-page-actions.vue?vue&type=template&id=5b9772f4&scoped=true&lang=pug"
import script from "./admin-report-page-actions.vue?vue&type=script&lang=ts"
export * from "./admin-report-page-actions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9772f4",
  null
  
)

export default component.exports