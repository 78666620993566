
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Location } from '@app/models/location';
  import type { TenantUser } from '@app/models/tenant-user';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: {} })
  export default class LocationActiveUsersForm extends Vue {
    @Prop([String, Number]) readonly locationId!: string | number;

    location: Nullable<Location> = null;
    form: Partial<Location> = {};
    allUsers: TenantUser[] = [];
    loading = true;

    get visitorUsers(): TenantUser[] {
      return this.allUsers.filter((user) => user.type === 'VisitorUser');
    }

    get tenantUsers(): TenantUser[] {
      return this.allUsers.filter((user) => user.type === 'TenantUser');
    }

    beforeMount(): void {
      this.getUsers();
      this.$api.getLocation(Number(this.locationId), { only: ['id', 'user_ids'] }, { cache: true }).then(({ data }) => {
        this.location = data;
        this.form = { ...data };
      });
    }

    getUsers(): void {
      this.$api
        .getTenantUsers(
          {
            per_page: -1,
            sort: 'full_name',
            only: ['id', 'full_name', 'type'],
            filters: { active: true, type: ['TenantUser', 'VisitorUser'] },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.allUsers = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    toggleChecked(userId: number): void {
      if (this.form && this.form.user_ids?.includes(userId)) {
        this.form = {
          ...this.form,
          user_ids: this.form.user_ids.filter((existingUserId) => existingUserId !== userId),
        };
      } else {
        this.form = { ...this.form, user_ids: [...(this.form.user_ids || []), userId] };
      }
    }

    checkIfLocationUser(userId: number): boolean {
      return !!this.location?.user_ids?.includes(userId);
    }

    onFormSubmit(): void {
      this.$api
        .updateLocation(this.form.id as number, { user_ids: this.form.user_ids })
        .then(() => {
          toaster(this.$t('tenant.settings.locations.update.location_updated'));
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
