import type { ValidationRule } from 'vee-validate/dist/types/types';

const fail: ValidationRule = {
  params: ['message'],

  validate() {
    return false;
  },

  message(value: unknown, args) {
    return (args as { message: string }).message;
  },
};

export default fail;
