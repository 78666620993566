
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import bootbox from 'bootbox';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { pick } from 'lodash';
  import { Tooltip } from 'uiv';
  import ActionFilterIndexDropdown from '../actions/action-filter-index-dropdown.vue';
  import type { FeatureSet } from '@app/models/feature-set';
  import type { ModuleName } from '@app/models/module-name';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActionFilterIndexDropdown, Tooltip } })
  export default class ModuleFeaturesPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    form: Partial<FeatureSet> = {};
    moduleName: Nullable<Pick<ModuleName, 'name' | 'id'>> = null;

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get allowClassicUIFeature(): boolean {
      if (!this.accountStore.data?.one_hsi_ui) {
        return false;
      }

      return this.currentUserStore.isDefaultTechAdmin;
    }

    submit(): void {
      if (!this.form) return;

      this.$api
        .updateModuleName(Number(this.moduleNameId), { feature_set_attributes: this.form })
        .then(() => {
          toaster({ text: this.$t('app.labels.feature_set_saved') });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, icon: 'error' });
        });
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['feature_set', 'name', 'id'];
      this.$api.getModuleName(Number(this.moduleNameId), { only }, { cache: true }).then(({ data }) => {
        this.moduleName = data;
        this.form = pick(
          data.feature_set,
          'is_public',
          'allow_embed',
          'quick_edit',
          'user_related',
          'user_related_required',
          'in_place_update',
          'mfql',
          'main_form_workflow_field_enabled',
          'create_main_form_in_modal',
          'calendar_integration_enabled',
          'export_record_index_to_csv',
          'classic_ui'
        );
      });
    }

    alert(text: string): void {
      bootbox.alert(text);
    }
  }
