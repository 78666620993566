
  import { Component, Model, Prop, Emit } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { CallToAction } from '@app/models/event';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
  import FormField from '../questions/edit/form-field.vue';
  import { WithUniqID } from './forms/base-event-form';

  @Component({ components: { AdvancedAutosuggest, FormField } })
  export default class EventCallToActionPanel extends WithUniqID {
    @Model('input') readonly value?: CallToAction;
    @Prop(Array) readonly injectableOptions!: PlaceholderItem[];

    expanded = false;

    @Emit('input')
    onChange(): Partial<CallToAction> {
      return this.expanded ? { ...this.value, klass: 'CallToAction' } : {};
    }

    beforeMount(): void {
      this.expanded = !!(this.value?.text || this.value?.link) && !!this.value?.klass;
    }

    toggleCustomLink(): void {
      this.expanded = !this.expanded;
      this.onChange();
    }
  }
