
  import DsCollapseLink from '@app/components/ds-collapse-link.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import type { RecordCalculation, StoredInQuestionType } from '@app/models/record-calculation';
  import { CalculationClass, CalculationDataSource, CalculationMethod } from '@app/models/record-calculation';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { FieldType } from '@app/models/sub-form-question';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { enumToOptions } from '@app/utils/enumToOptions';
  import { capitalize, compact, uniq } from 'lodash';
  import { Collapse } from 'uiv';
  import { extend } from 'vee-validate';
  import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import FormField from '../admin/questions/edit/form-field.vue';
  import DsCheckbox from '../ds-checkbox.vue';

  interface StoredInQuestionTypeOption {
    code: StoredInQuestionType;
    isChecked: boolean;
    label: string;
  }

  type RecordCalculationSubForm = Pick<SubFormList, 'id' | 'title' | 'approval_sub_form_id' | 'sub_form_ids'>;

  const baseStates = ['Draft', 'Complete'];

  @Component({
    components: { DsCheckbox, FormField, Select2, EntitySelector, DsCollapseLink, Collapse },
  })
  export default class RecordCalculationForm extends Vue {
    @Model('input') value!: RecordCalculation;
    @Prop(Object) readonly recordCalculation!: Partial<RecordCalculation>;
    @Prop(Array) readonly stateOptions!: string[];

    recordCalculations: Partial<RecordCalculation>[] = [];
    validVariableCodes: string[] = [];
    baseWhitelistChars = ['.', '(', ')', '0-9', '&&', '+', '*', '/', '**', '%', '-'];
    storedInQuestionTypeOptions: StoredInQuestionTypeOption[] = [];

    calculationTypeOptions = enumToOptions(CalculationMethod, true);
    mainFormQuestions: string[] = [];
    suggestedCode = '';
    subFormLists: RecordCalculationSubForm[] = [];
    showSubFormsWarning = false;
    showCalculationCallsDescription = false;
    subFormQuestionCodes: [string, string][] = [];
    subFormQuestionCodeInvalid = false;
    relatedMainFormId: Nullable<number> = null;

    get subFormListsFilters(): DonesafeFilterOptions<SubFormList> {
      return this.subFormListsFiltersFor(this.value);
    }

    get subFormsFilters(): DonesafeFilterOptions<SubForm> {
      return {
        active: true,
        id: this.allSubFormsIds(this.value),
      };
    }

    get validVariablesRegex(): RegExp {
      const codesRegex = this.validVariableCodes.join('|');

      const baseWhitelistChars = [...this.baseWhitelistChars];
      baseWhitelistChars.splice(this.baseWhitelistChars.indexOf('0-9'), 1); // numbers are being capture by Regex
      baseWhitelistChars.splice(this.baseWhitelistChars.indexOf('.'), 1); // dots are part if numbers Regex
      const whitelistRegex = baseWhitelistChars.map((v) => this.escapeRegExp(v)).join('|'); // join all special characters

      // main form variables
      const mainFormVars = this.mainFormQuestions.map((v) => v).join('|');
      const mainFormRegex = `\{\{(?:${mainFormVars})\}\}`; // regex for valid codes of main form questions

      return new RegExp(`\\d+(?:\\.\\d+)?|\\b(${codesRegex})\\b|${mainFormRegex}|${whitelistRegex}`, 'g');
    }

    get isFormulaCalculationMethod(): boolean {
      return this.value.calculation_method === CalculationMethod.formula;
    }

    get isNonRecordCountCalculationMethod(): boolean {
      return this.value.calculation_method !== CalculationMethod.record_count;
    }

    get isInstantCalculationClass(): boolean {
      return this.value.calculation_class === CalculationClass.instant;
    }

    get isStoredCalculationClass(): boolean {
      return this.value.calculation_class === CalculationClass.stored;
    }

    get subFormsPlaceholder(): string {
      return !this.hasSubForms
        ? this.$t('components.admin.record_calculations.no_active_sub_forms_available')
        : this.$t('app.labels.select_placeholder');
    }

    get hasSubForms() {
      return !!this.subFormsIds(this.value).length;
    }

    get hasSubFormsOrStates() {
      return this.hasSubForms || !!this.value.options?.sub_form_states?.length;
    }

    get isNewRecord(): boolean {
      return !this.value.id;
    }

    get hasStoredInQuestionTypeChecked(): boolean {
      return this.storedInQuestionTypeOptions.some((option) => option.isChecked);
    }

    get hasActiveOutputCodes(): boolean {
      return (
        this.hasStoredInQuestionTypeChecked &&
        (this.value.data_source === CalculationDataSource.sub_forms || !!this.value.sub_form_list_ids?.length)
      );
    }

    get subFormQuestionCodePlaceholder(): string {
      return !this.hasActiveOutputCodes
        ? this.$t('components.admin.record_calculations.no_active_output_codes_available')
        : this.$t('app.labels.select_placeholder');
    }

    @Emit('input')
    onStoredInQuestionTypeChange(): RecordCalculation {
      const stored_in_question_types = this.storedInQuestionTypeOptions.filter((option) => option.isChecked).map((option) => option.code);
      const newValue: RecordCalculation = {
        ...this.value,
        options: {
          ...this.value.options,
          stored_in_question_types,
        },
      };

      if (stored_in_question_types.length) {
        this.fetchSubFormQuestionCodes(newValue);
      } else {
        this.subFormQuestionCodes = [];
      }
      return newValue;
    }

    @Watch('value.calculation_class')
    createValidVariableCodes(calculation_class: CalculationClass) {
      this.validVariableCodes = this.recordCalculations
        .filter((recordCalculation) => {
          // Remove self from array
          const validCode = !!recordCalculation.variable_code && recordCalculation.variable_code != this.recordCalculation.variable_code;

          // Check if it's an instant
          if (!validCode || calculation_class === CalculationClass.instant) return validCode;

          // if form is a stored calculation then only show stored variables
          return recordCalculation.calculation_class === CalculationClass.stored;
        })
        .map((recordCalculation) => recordCalculation.variable_code) as string[];
    }

    subFormsIds(recordCalculation: RecordCalculation): number[] {
      if (recordCalculation.sub_form_ids?.length) return recordCalculation.sub_form_ids;

      return this.allSubFormsIds(recordCalculation);
    }

    allSubFormsIds(recordCalculation: RecordCalculation): number[] {
      const subFormLists = this.selectedSubFormLists(recordCalculation);
      return [...compact(subFormLists.map((sl) => sl?.approval_sub_form_id)), ...compact(subFormLists.flatMap((sl) => sl?.sub_form_ids))];
    }

    selectedSubFormLists(recordCalculation: RecordCalculation): RecordCalculationSubForm[] {
      return this.subFormLists.filter((subFormList) => recordCalculation.sub_form_list_ids?.includes(subFormList.id));
    }

    subFormListsFiltersFor(recordCalculation: RecordCalculation): DonesafeFilterOptions<SubFormList> {
      switch (recordCalculation.data_source) {
        case CalculationDataSource.related_records:
          return {
            sub_form_list_type: SubFormListType.record_relations,
            use_in_stored_calculations: recordCalculation.calculation_class === CalculationClass.stored ? true : undefined,
            module_tab: {
              related_module_name: {
                id: this.recordCalculation.module_name_id,
              },
            },
          };
        case CalculationDataSource.sub_forms:
          return {
            active: true,
            module_tab: { module_name: this.recordCalculation.module_name?.name },
            sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
          };
      }
      return {};
    }

    subFormQuestionFiltersFor(recordCalculation: RecordCalculation): Maybe<DonesafeFilterOptions<SubFormQuestion>> {
      switch (recordCalculation.data_source) {
        case CalculationDataSource.related_records:
          if (!this.relatedMainFormId) return;

          return {
            sub_form_id: this.relatedMainFormId,
          };
        case CalculationDataSource.sub_forms:
          return {
            sub_form_id: this.subFormsIds(recordCalculation),
            sub_form_section: {
              sub_form: {
                active: true,
                module_name: this.recordCalculation.module_name?.name,
              },
            },
          };
      }
    }

    onCalculationMethodChange(calculationMethod?: CalculationMethod): void {
      const isFormula = calculationMethod === CalculationMethod.formula;
      const extraFields = isFormula ? { data_source: CalculationDataSource.formula } : {};
      this.$emit('input', {
        ...this.value,
        ...extraFields,
      });
    }

    onSuggestedCodeClick() {
      this.$emit('input', { ...this.value, variable_code: this.suggestedCode });
      this.suggestedCode = '';
    }

    onFormCalculationCodeChange() {
      if (this.value.variable_code == '' && !this.recordCalculation.id) {
        this.onFormNameChange();
      } else {
        this.suggestedCode = '';
      }
    }

    onFormNameChange() {
      if (!this.value.variable_code || this.value.variable_code === '') {
        if (!this.recordCalculation.id && this.value.name) {
          const regex = /\b[a-zA-Z_0-9]*[a-zA-Z_]+[a-zA-Z_0-9]*\b/g;

          const words = this.value.name.split(' ').join('_').match(regex)?.join('_') || '';

          this.suggestedCode = words;
        }
      }
    }

    onSubFormListChange(subFormListIds: (string | number)[]): void {
      let newValue: RecordCalculation = { ...this.value, sub_form_list_ids: subFormListIds.map(Number) };
      const subFormsIds = this.subFormsIds(newValue);
      if (!subFormListIds.length || !subFormsIds.length) {
        newValue = { ...newValue, sub_form_ids: [] };
        this.showSubFormsWarning = false;
      } else {
        this.$api
          .getSubForms(
            {
              filters: {
                active: true,
                id: subFormsIds,
              },
              only: ['id', 'title'],
            },
            { cache: true }
          )
          .then(({ data: subForms }) => {
            this.showSubFormsWarning = subForms.length >= 2;
          });
      }
      this.$emit('input', newValue);
      this.fetchQuestionStates(newValue);
      this.fetchSubFormQuestionCodes(newValue);
    }

    onSubFormChange(subFormIds: string[]): void {
      let newValue: RecordCalculation = { ...this.value, sub_form_ids: subFormIds.map(Number) };

      this.$emit('input', newValue);
      this.fetchQuestionStates(newValue);
      this.fetchSubFormQuestionCodes(newValue);
    }

    async onRelatedSubFormListChange(subFormListId: number | undefined): Promise<void> {
      const newValue: RecordCalculation = {
        ...this.value,
        sub_form_list_ids: subFormListId ? [subFormListId] : [],
        sub_form_question_code: subFormListId === this.value.sub_form_list_ids?.[0] ? this.value.sub_form_question_code : undefined,
        options: {
          ...this.value.options,
          sub_form_states: [],
        },
      };
      this.$emit('input', newValue);

      await this.fetchRelatedMainFormId(newValue);
      await this.fetchSubFormQuestionCodes(newValue);
    }

    onCalculationClassChange() {
      if (this.value.data_source === CalculationDataSource.related_records) {
        this.$emit('input', { ...this.value, sub_form_list_ids: [] });
      }
      this.showCalculationCallsDescription = false;
    }

    customFormulaRegexValidator(value: string): boolean | string {
      // If regex captures everything, noIntRegex should be an empty string
      const notInRegex = value.replace(this.validVariablesRegex, '');
      const validCharacters = notInRegex.trim() === '';
      if (validCharacters) return true; // is Valid

      // Validation error message
      const invalidWords = notInRegex.split(' ').filter(Boolean); // removes all spaces and filter empty strings
      let errorMessage: string[] = [];
      if (this.recordCalculation.variable_code && invalidWords.includes(this.recordCalculation.variable_code)) {
        errorMessage.push(`Formula cannot reference itself`);
        invalidWords.splice(invalidWords.indexOf(this.recordCalculation.variable_code), 1);
      }
      if (invalidWords.length) {
        errorMessage.push(`Invalid characters or words detected: ${invalidWords.join(', ')}`);
      }
      return errorMessage.join(', ');
    }

    async getCalculationSuiteQuestions() {
      const sub_form_id = await this.$api
        .getModuleName(
          this.recordCalculation?.module_name_id as number,
          {
            only: ['sub_form_id'],
          },
          { cache: true }
        )
        .then(({ data }) => data.sub_form_id);

      this.mainFormQuestions = await this.$api
        .getSubFormQuestions(
          {
            filters: {
              active: true,
              sub_form_section: { sub_form_id },
              field_type: [FieldType.calculator, FieldType.calculation_text, FieldType.calculation_select],
            },
            only: ['code'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          return uniq(data.map((v) => v.code));
        });
    }

    escapeRegExp(text: string): string {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    calculationClassName(name: string): string {
      return capitalize(name);
    }

    hasStoredInQuestionType(code: StoredInQuestionType): boolean {
      return !!this.value.options?.stored_in_question_types?.includes(code);
    }

    async onDataSourceChange(dataSource: CalculationDataSource): Promise<void> {
      const newValue: RecordCalculation = {
        ...this.value,
        data_source: dataSource,
        sub_form_list_ids: [],
        sub_form_ids: [],
        sub_form_question_code: undefined,
      };
      this.$emit('input', newValue);

      dataSource === CalculationDataSource.sub_forms && (await this.fetchSubFormLists(newValue));
      await this.fetchSubFormQuestionCodes(newValue);
    }

    async fetchSubFormLists(recordCalculation: RecordCalculation): Promise<void> {
      const { data: subFormLists } = await this.$api.getSubFormLists(
        {
          filters: this.subFormListsFiltersFor(recordCalculation),
          only: ['id', 'title', 'approval_sub_form_id', 'sub_form_ids'],
          per_page: -1,
        },
        { cache: true }
      );
      this.subFormLists = subFormLists;
      this.onSubFormListChange(recordCalculation.sub_form_list_ids || []);
    }

    async fetchRelatedMainFormId(recordCalculation: RecordCalculation): Promise<void> {
      const subFormListId = recordCalculation.sub_form_list_ids?.[0];
      if (!subFormListId) return;

      const { data: subFormList } = await this.$api.getSubFormList(
        subFormListId,
        { only: ['id', 'record_relation_question_id'] },
        { cache: true }
      );
      if (!subFormList.record_relation_question_id) return;

      const { data: subFormQuestion } = await this.$api.getSubFormQuestion(
        subFormList.record_relation_question_id,
        { only: ['sub_form_id'] },
        { cache: true }
      );
      this.relatedMainFormId = subFormQuestion.sub_form_id || null;
    }

    async fetchSubFormQuestionCodes(recordCalculation: RecordCalculation): Promise<void> {
      const dataSourceFilters = this.subFormQuestionFiltersFor(recordCalculation);
      if (!dataSourceFilters) {
        this.subFormQuestionCodes = [];
        return;
      }

      const { data: subFormQuestions } = await this.$api.getSubFormQuestions(
        {
          filters: {
            active: true,
            field_type: recordCalculation.options.stored_in_question_types || [],
            ...dataSourceFilters,
          },
          only: ['id', 'title', 'code'],
          per_page: -1,
        },
        { cache: true }
      );
      const actualCodes = uniq(subFormQuestions.map((subFormQuestion) => subFormQuestion.code)).sort();
      this.subFormQuestionCodeInvalid = false;
      if (recordCalculation.sub_form_question_code) {
        const allCodes = uniq([...actualCodes, recordCalculation.sub_form_question_code]).sort();
        this.subFormQuestionCodeInvalid = !actualCodes.includes(recordCalculation.sub_form_question_code);
        const currentCodeTitle = this.subFormQuestionCodeInvalid
          ? `${recordCalculation.sub_form_question_code} (invalid)`
          : recordCalculation.sub_form_question_code;
        this.subFormQuestionCodes = allCodes.map((code) => [
          code,
          code === recordCalculation.sub_form_question_code ? currentCodeTitle : code,
        ]);
      } else {
        this.subFormQuestionCodes = actualCodes.map((code) => [code, code]);
      }
    }

    fetchQuestionStates(recordCalculation: RecordCalculation): void {
      const subFormIds = this.subFormsIds(recordCalculation);
      this.$api
        .getSubFormQuestions(
          {
            only: ['id', 'config'],
            filters: {
              field_type: FieldType.approval_state,
              sub_form_section: {
                sub_form: { sub_form_module_name: { id: this.recordCalculation.module_name_id } },
                sub_form_id: subFormIds.length ? subFormIds : undefined,
              },
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.$emit(
            'updateStateOptions',
            uniq([baseStates, ...data.map((subFormQuestion) => subFormQuestion.config.stages)].flat()) as string[]
          );
        });
    }

    beforeMount(): void {
      extend('formulaRegex', this.customFormulaRegexValidator);

      this.$emit('updateStateOptions', baseStates);
      this.storedInQuestionTypeOptions = [
        {
          label: this.$t('components.admin.record_calculations.calculation_fields'),
          isChecked: this.hasStoredInQuestionType('calculator'),
          code: 'calculator',
        },
        {
          label: this.$t('components.admin.record_calculations.calculation_text_fields'),
          isChecked: this.hasStoredInQuestionType('calculation_text'),
          code: 'calculation_text',
        },
        {
          label: this.$t('components.admin.record_calculations.calculation_select_fields'),
          isChecked: this.hasStoredInQuestionType('calculation_select'),
          code: 'calculation_select',
        },
      ];
      this.showCalculationCallsDescription = this.isNewRecord;
      this.getCalculationSuiteQuestions();

      this.$api
        .getRecordCalculations(
          {
            only: ['variable_code', 'calculation_class'],
            filters: { module_name_id: this.recordCalculation.module_name_id },
            per_page: -1,
          },
          { cache: true }
        )
        .then((response) => {
          this.recordCalculations = response.data;
          this.createValidVariableCodes(this.recordCalculation.calculation_class || CalculationClass.instant);
        });

      switch (this.value.data_source) {
        case CalculationDataSource.sub_forms:
          this.fetchSubFormLists(this.value);
          this.fetchSubFormQuestionCodes(this.value);
          break;
        case CalculationDataSource.related_records:
          this.onRelatedSubFormListChange(this.value.sub_form_list_ids?.[0]);
          break;
      }
    }
  }
