
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import RelationshipFormModal from '../../relationship/relationship-form-modal.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { RelationshipFormModal, BaseTable } })
  export default class ModuleRelationshipsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<Relationship>;

    moduleName: Nullable<ModuleName> = null;

    relationshipModalVisible = false;
    manager: Nullable<ListManager<Relationship>> = null;
    selectedRelationship: Partial<Relationship> = {};

    getManager(moduleName: ModuleName): ListManager<Relationship> {
      return new ListManager<Relationship>({
        fetchDataFunction: (params) => {
          const only = [
            'id', // required for update and to prevent edit certain fields
            'code',
            'name',
            'from_module',
            'to_module',
            'active',
            { from_module_name: ['display'] },
            { to_module_name: ['display'] },
          ] as OnlyOptions<Relationship>;
          return this.$api.getRelationships({ ...params, filters: { from_module: moduleName.name }, only }, { cache: true });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          { title: 'Code', name: 'code', sortField: 'code' },
          { title: 'Name', name: 'name', sortField: 'name' },
          { title: 'From Module', name: 'from_module', sortField: 'from_module' },
          { title: 'To Module', name: 'to_module', sortField: 'to_module' },
          { title: 'Active', name: 'active', sortField: 'active' },
        ],
      });
    }

    addNewRelationship(): void {
      this.selectedRelationship = { from_module: this.moduleName?.name, active: true };
      this.toggleRelationShipModal(true);
    }

    editRelationship(entity: Relationship): void {
      this.selectedRelationship = entity;
      this.toggleRelationShipModal(true);
    }

    beforeMount(): void {
      this.$api.getModuleName(Number(this.moduleNameId)).then(({ data }) => {
        this.moduleName = data;
        this.manager = this.getManager(this.moduleName);
      });
    }

    toggleRelationShipModal(value: boolean): void {
      this.relationshipModalVisible = value;
    }

    submitRelationshipForm(entity: Partial<Relationship>): void {
      const promise = entity.id ? this.$api.updateRelationship(entity.id, entity) : this.$api.createRelationship(entity);
      promise
        .then(() => {
          this.$api.cache.clear();
          this.table?.debounceUpdate();
          this.toggleRelationShipModal(false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    deleteRelationship(entity: Partial<Relationship>): void {
      if (entity.id) {
        const promise = this.$api.deleteRelationship(entity.id);
        promise
          .then(() => {
            this.$api.cache.clear();
            this.table?.debounceUpdate();
            this.toggleRelationShipModal(false);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }
  }
