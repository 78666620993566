
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import AdminSubForm from '@app/components/admin/sub-forms/admin-sub-form.vue';
  import type { SubForm } from '@app/models/sub-form';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  @Component({
    components: {
      AdminSubForm,
    },
  })
  export default class HybridSubFormPage extends Vue {
    @Prop(Number) readonly subFormListId!: number;
    @Prop(Number) readonly moduleNameId!: number;

    subForm: Nullable<SubForm> = null;

    beforeMount(): void {
      this.$api
        .getSubForms({ filters: { sub_form_lists: { id: this.subFormListId } }, include: ['sub_form_module_name'] }, { cache: true })
        .then(({ data }) => {
          this.subForm = data[0];
          if (this.subForm.sub_form_module_name?.id !== this.moduleNameId) {
            this.$router.push({
              name: 'admin-settings-hybrid-sub-form-page',
              params: { moduleNameId: String(this.subForm.sub_form_module_name?.id), subFormListId: String(this.subFormListId) },
            });
          }
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
          changeLocation('/admin/settings/hybrid_modules');
        });
    }
  }
