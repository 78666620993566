import type { ScoreBandProfile } from '@app/models/score-band-profile';
import { isNil } from 'lodash';
import I18n from '@app/i18n';
import type { ModuleRecord } from '@app/models/module-record';
import type { ShowOptions } from '@app/models/module-name';

export type DisplayScore = (numerator?: number, denominator?: number) => string;
export type ScoreBandMatch = { code: string; profile?: ScoreBandProfile };
export type ScoreData = NonNullable<ModuleRecord['score_data']>;
export type Score = { score: ScoreData; scoreBand?: ScoreBandMatch };
export type DisplayScoringGroup = { displayMethod: string; id: number; score: Score; title: string };

const orNa: (method: DisplayScore, displayType: ShowOptions['show_total_scoring']) => DisplayScore = (method, displayType) => (n, d) => {
  const nd = Number(d);
  if (nd === 0 && displayType === 'percent') return '0%';

  if ((nd === 0 && displayType === 'fraction') || isNil(n) || !isFinite(Number(n)) || isNil(d) || !isFinite(Number(d))) {
    return I18n.t('app.labels.na');
  }

  return method(n, d);
};

export const pickScoreBand = (sbm?: ScoreBandMatch) => {
  const { code: sbc, profile } = sbm || {};
  return profile?.score_bands.find(({ code }) => code === sbc) || profile?.no_band_score_band;
};

export const displayPercent: DisplayScore = orNa((n, d) => `${((Number(n) / Number(d)) * 100).toFixed(2)}%`, 'percent');
export const displayFraction: DisplayScore = orNa((n, d) => [n, d].map(Number).join('/'), 'fraction');

export const displayScoreBand: (sbm: ScoreBandMatch) => DisplayScore = (sbm) => (n, d) => {
  const {
    active,
    options: { show_band_label, show_score_as_percent, show_score_as_fraction },
  } = sbm.profile || { options: {} };

  const { label } = pickScoreBand(sbm) || {};
  const displayLabel = show_band_label ? label : null;
  const fraction = show_score_as_fraction && displayFraction(n, d);
  const percent = show_score_as_percent && displayPercent(n, d);

  return [displayLabel, percent, fraction && (displayLabel || percent) ? `(${fraction})` : fraction, !active && '[Archived]']
    .filter((v) => !!v)
    .join(' ');
};

export const pickDisplayMethod: (method?: string, sbm?: ScoreBandMatch) => DisplayScore = (method = 'fraction', sbm) => {
  if (method == 'score_band' && sbm && pickScoreBand(sbm)) {
    return displayScoreBand(sbm);
  }

  if (method == 'percent') {
    return displayPercent;
  }

  return displayFraction;
};
