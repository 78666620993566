
  import { useAccountStore } from '@app/stores/account';
  import ExpenseEstimatesTabEditModal from '@app/components/sub-form-list-types/expense_estimates/expense-estimates-tab-edit-modal.vue';
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import type { ApprovedExpense } from '@app/models/approved_expense';
  import type { ExpenseCategoryOverwrite } from '@app/models/expense-category-overwrite';
  import type { AxiosPromise } from 'axios';
  import { groupBy, keyBy } from 'lodash';
  import { Component, Ref } from 'vue-property-decorator';
  import type { ExpenseCategory, ExpenseCategoryBase } from '@app/models/expense-category';
  import { toaster } from '@app/utils/toaster';

  interface CategoryTotals {
    sessions: number;
    spend: number;
  }

  interface EstimateTotals {
    currentSessions: number;
    currentTotalSpend: number;
    maxSessions: number;
    maxSpendPerClaim: number;
    maxSpendPerUnit: number;
  }

  type PartialExpenseCategoryOverwrite = Pick<ExpenseCategoryOverwrite, 'id' | 'expense_category_id' | keyof ExpenseCategoryBase>;

  @Component({ components: { ExpenseEstimatesTabEditModal } })
  export default class ExpenseEstimatesTab extends SubFormListBase {
    @Ref() readonly modal?: ExpenseEstimatesTabEditModal;

    expenseCategories: ExpenseCategory[] = [];
    expenseCategoryOverwrites: PartialExpenseCategoryOverwrite[] = [];
    approvedExpenses: ApprovedExpense[] = [];

    loading = false;
    modalVisible = false;

    get locale(): string {
      const localeString = window.DONESAFE.locale.toLowerCase();
      return localeString.startsWith('en') ? 'en' : localeString;
    }

    get subFormListTitle(): string {
      // TODO: print_title logic or remove getter?
      return this.subFormList.title;
    }

    get groupedApprovedExpenses(): Record<number, ApprovedExpense[]> {
      return groupBy(this.approvedExpenses, 'expense_category_id');
    }

    get totalsByCategory(): Record<number, CategoryTotals> {
      return this.expenseCategories.reduce((memo, ec) => {
        if (this.groupedApprovedExpenses[ec.id]) {
          const spend = this.groupedApprovedExpenses[ec.id].reduce((memo, expense) => memo + expense.amount, 0);
          const sessions = this.groupedApprovedExpenses[ec.id].length;
          return { ...memo, [ec.id]: { spend, sessions } };
        }
        return memo;
      }, {});
    }

    get allTotals(): EstimateTotals {
      return this.expenseCategoriesWithOverwrites.reduce(
        (memo, [category, overwrite]) => {
          memo.maxSpendPerUnit += (overwrite || category).spend_per_unit;
          memo.maxSpendPerClaim += (overwrite || category).spend_per_claim;
          memo.maxSessions += (overwrite || category).default_max_sessions;
          memo.currentSessions += this.totalsByCategory[category.id]?.sessions || 0;
          memo.currentTotalSpend += this.totalsByCategory[category.id]?.spend || 0;
          return memo;
        },
        {
          maxSpendPerUnit: 0,
          currentTotalSpend: 0,
          maxSpendPerClaim: 0,
          currentSessions: 0,
          maxSessions: 0,
        }
      );
    }

    get overwritesByCategory(): Record<number, PartialExpenseCategoryOverwrite> {
      return keyBy(this.expenseCategoryOverwrites, 'expense_category_id');
    }

    get accountStore() {
      return useAccountStore();
    }

    get expenseCategoriesWithOverwrites(): [ExpenseCategory, PartialExpenseCategoryOverwrite | undefined][] {
      return this.expenseCategories.map((ec) => [ec, this.overwritesByCategory[ec.id]]);
    }

    beforeMount(): void {
      this.loadData();
    }

    getValueFor(categoryId: number, target: keyof CategoryTotals): number {
      return this.totalsByCategory[categoryId]?.[target] || 0;
    }

    numberToCurrency(value: number): string {
      return value.toLocaleString(this.locale, { style: 'currency', currency: this.accountStore.data.currency_code });
    }

    getExpenseCategories(): Promise<void> {
      return this.$api
        .getExpenseCategories(
          {
            filters: { active: true },
            only: ['id', 'name', 'spend_per_unit', 'spend_per_claim', 'default_max_sessions', 'active'],
            sort: 'name',
            per_page: -1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.expenseCategories = data;
          return this.getApprovedExpenses();
        });
    }

    getExpenseCategoryOverwrites(): void {
      this.$api
        .getExpenseCategoryOverwrites(
          {
            filters: { sub_form_list_id: this.subFormList.id, record_id: this.record.id, record_type: 'ModuleRecord' },
            only: ['id', 'expense_category_id', 'spend_per_unit', 'spend_per_claim', 'default_max_sessions', 'active'],
            per_page: -1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.expenseCategoryOverwrites = data;
        });
    }

    getApprovedExpenses(): Promise<void> {
      const ids = this.expenseCategories.map((ec) => ec.id);

      if (ids.length) {
        return this.$api
          .getApprovedExpenses({
            only: ['amount', 'expense_category_id', 'id'],
            filters: {
              expense_category_id: ids,
              record_type: 'ModuleRecord',
              record_id: this.record.id,
              sub_form_list_id: this.subFormList.id,
            },
            per_page: -1,
          })
          .then(({ data }) => {
            this.approvedExpenses = data;
          })
          .finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
      return Promise.resolve();
    }

    createOrUpdateOverwrites(form: Record<number, ExpenseCategoryBase>): void {
      const promises: AxiosPromise[] = [];

      this.expenseCategories.forEach((category) => {
        if (form[category.id]) {
          const overwrite = this.overwritesByCategory[category.id];
          if (overwrite) {
            promises.push(this.$api.updateExpenseCategoryOverwrite(overwrite.id, form[category.id]));
          } else {
            const newOverwrite = {
              ...form[category.id],
              expense_category_id: category.id,
              record_id: this.record.id,
              record_type: 'ModuleRecord',
              sub_form_list_id: this.subFormList.id,
            };
            promises.push(this.$api.createExpenseCategoryOverwrite(newOverwrite));
          }
        }
      });

      Promise.all(promises)
        .then(() => {
          toaster(this.$t('tenant.module_tabs.expense_estimates.updated'));
          this.toggleModal(false);
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }))
        .finally(() => this.loadData(true));
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    loadData(resetCache = false): void {
      resetCache && this.$api.cache.clear();

      this.loading = true;

      this.getExpenseCategories();
      this.getExpenseCategoryOverwrites();
    }
  }
