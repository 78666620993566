
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../../../components/base-table';
  import SearchInput from '@app/components/search-input.vue';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { Indicator } from '@app/models/indicator';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      PaperTrailsModalLink,
      BaseTable,
      SearchInput,
    },
  })
  export default class IndicatorSetShow extends Vue {
    @Ref() readonly table?: BaseTable<Indicator>;

    indicatorSet: Nullable<IndicatorSet> = null;
    manager: Nullable<ListManager<Indicator>> = null;

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateIndicatorIndexes({ data, sort })
          .then(({ data }) => {
            this.$api.cache.clear();
            toaster({
              text: this.$t('app.labels.order_saved'),
              position: 'top-right',
            });
            this.manager?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    getManager(): ListManager<Indicator> {
      return new ListManager<Indicator>({
        fetchDataFunction: (params) => {
          const patchedParams = {
            ...params,
            filters: {
              ...params.filters,
              indicator_set_id: this.$route.params.indicatorSetId,
            },
          };
          return this.$api.getIndicators(
            {
              ...patchedParams,
              only: ['active', 'id', 'name', 'description', 'color', 'default', 'index'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        per_page: -1,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index' },
          { title: 'ID', name: 'id', sortField: 'id' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          { title: this.$t('app.labels.description'), name: 'description', sortField: 'description' },
          { title: this.$t('app.labels.color'), name: 'color' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
          { title: this.$t('app.labels.default'), name: 'def', sortField: 'default' },
        ],
      });
    }

    setActive(active: boolean) {
      if (this.indicatorSet) {
        this.$api
          .updateIndicatorSet(this.indicatorSet.id, { active })
          .then(({ data }) => {
            if (data.active) {
              toaster(this.$t('tenant.admin.indicator_sets.edit.publish'));
            } else {
              toaster(this.$t('tenant.admin.indicator_sets.edit.unpublish'));
            }
            this.indicatorSet = { ...data };
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      }
    }

    beforeMount(): void {
      this.$api
        .getIndicatorSet(
          Number(this.$route.params.indicatorSetId),
          {
            only: ['active', 'id', 'module_name', 'name', 'indicator_type', 'hide_title', 'display_only', 'hidden', 'multi'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.indicatorSet = data;
        });
    }

    mounted(): void {
      this.manager = this.getManager();
    }
  }
