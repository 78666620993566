
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class Switcher extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(String) readonly label?: string;
    @Prop(String) readonly textDisabled?: string;
    @Prop(String) readonly textEnabled?: string;

    get shouldShowLabel(): boolean {
      return !!(this.label || this.textEnabled || this.textDisabled);
    }

    get classObject(): object {
      const { value, disabled } = this;

      return {
        ['switcher--unchecked']: !value,
        ['switcher--disabled']: disabled,
      };
    }
  }
