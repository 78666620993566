
  import Blocking from '@app/mixins/blocking';
  import type { RequiredBy } from '@app/utils/types/required-by';
  import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import { toaster } from '@app/utils/toaster';
  import SubFormSettings from '../../../components/sub-form/sub-form-settings.vue';
  @Component({ components: { SubFormSettings } })
  export default class AdminSubFormsNew extends Mixins(Blocking) {
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;
    @Ref() readonly form?: SubFormSettings;
    moduleName: Nullable<Pick<ModuleName, 'id' | 'module_type' | 'name'>> = null;

    get moduleTabId() {
      return `${this.$route.query.module_tab_id}` || undefined;
    }
    beforeMount(): void {
      this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name', 'module_type'] }, { cache: true }).then(({ data }) => {
        this.moduleName = data;
      });
    }

    onFormSubmit(form: RequiredBy<Partial<SubForm>, 'title'>): void {
      if (!this.loading && this.moduleName) {
        this.blocking(
          async () =>
            this.moduleName?.name &&
            this.$api
              .createSubForm({ ...form, module_name: this.moduleName?.name })
              .then(({ data }) => {
                this.$api.cache.clear();
                return this.$router.replace({
                  name: 'admin-sub-forms-show',
                  params: {
                    subFormId: `${data.id}`,
                    moduleNameId: `${this.moduleNameId}`,
                    moduleTabId: this.moduleTabId || '',
                  },
                  query: { ...this.$route.query, just_created: 'true' },
                });
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
        );
      }
    }
  }
