import { render, staticRenderFns } from "./filter-configurator.vue?vue&type=template&id=16780fc0&scoped=true&lang=pug"
import script from "./filter-configurator.vue?vue&type=script&lang=ts"
export * from "./filter-configurator.vue?vue&type=script&lang=ts"
import style0 from "./filter-configurator.vue?vue&type=style&index=0&id=16780fc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16780fc0",
  null
  
)

export default component.exports