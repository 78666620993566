
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { SubFormList } from '@app/models/sub-form-list';

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class HybridForm extends Vue {
    @Prop(Object) readonly subFormList?: Partial<SubFormList>;
    @Prop(String) readonly formTitle!: string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<SubFormList> = {};

    beforeMount(): void {
      this.form = { ...this.subFormList };
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }
  }
