
  import DefaultTemplateForm from '@app/components/admin/module-names/default-templates/default-template-form.vue';
  import type { DefaultTemplate } from '@app/models/default-template';
  import Blocking from '@app/mixins/blocking';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import { toaster } from '@app/utils/toaster';
  import { Component, Prop } from 'vue-property-decorator';

  @Component({ components: { DsDefaultTemplateForm: DefaultTemplateForm } })
  export default class DefaultTemplatesEditPage extends Blocking {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    @Prop([String, Number]) readonly defaultTemplateId!: string | number;

    defaultTemplate: Nullable<DefaultTemplate> = null;
    isLoading = false;

    onSubmit(form: Partial<DefaultTemplate> & { responses: SubFormData }) {
      this.blocking(async () => {
        await this.$api
          .updateDefaultTemplate(Number(this.defaultTemplateId), form)
          .then(() => {
            toaster(this.$t('tenant.admin.default_templates.update.successfully'));
            this.$api.cache.clear();
            this.$router.replace({
              name: 'admin-settings-module-names-default-templates',
              params: { moduleNameId: `${this.moduleNameId}` },
            });
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
      });
    }

    beforeMount() {
      this.isLoading = true;
      this.$api
        .getDefaultTemplate(Number(this.defaultTemplateId), {}, { cache: true })
        .then(({ data }) => {
          this.defaultTemplate = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
