
  import { useAccountStore } from '@app/stores/account';
  import { flatten, groupBy } from 'lodash';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import RecordFilterConfigurator from '../filter-configurator/record-filter-configurator.vue';
  import SortConfigurator from '../sort-configurator/sort-configurator.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import Select2 from '@app/components/select2.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '../edit/form-field.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { MainFormRelationQuestionOptions } from '@app/models/question-options/main-form-relation-question-options';
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '../filter-configurator/utils';

  @Component({ components: { FormField, DsCheckbox, Select2, RecordFilterConfigurator, EntitySelector, SortConfigurator } })
  export default class MainFormRelationFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<MainFormRelationQuestionOptions>;
    @Prop(Object) subForm!: SubForm;

    selectedModuleName: Pick<ModuleName, FirstLevelKeyLiterals<(typeof WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY)[number]>> | null = null;

    get moduleNameFilter(): object {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES,
      };
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    get mapSelectionEnabled(): boolean {
      return this.accountStore.allowMapSelection && this.value.config.map_selection === 'true';
    }

    get accountStore() {
      return useAccountStore();
    }

    get locationCenterSourceOptions(): [string, string][] {
      const centerSourceOptions = flatten(this.subForm.sub_form_sections?.map((s) => s.sub_form_questions || []) || [])
        .filter((q) => q.field_type === FieldType.location || q.field_type === FieldType.address)
        .map((q) => [q.code, `Question: ${q.title}`]);
      const locationCenterSourceOptions = [
        ['home_location', 'User Home Location'],
        ['geolocation', 'User Geolocation'],
        ...centerSourceOptions,
      ];
      if (!this.onMainForm) {
        locationCenterSourceOptions.unshift(['location', 'Record Location']);
      }

      return Object.values(groupBy(locationCenterSourceOptions, ([code]) => code)).map((v) => [
        v[0][0],
        v.map(([, label]) => label).join(' | '),
      ]);
    }

    get locationSourceOptions(): [string, string][] {
      const sections = this.selectedModuleName?.main_form?.sub_form_sections || [];
      const locationSourceOptions =
        sections[0]?.sub_form_questions
          ?.filter((q) => {
            return q.field_type === FieldType.location || q.field_type === FieldType.address;
          })
          .map((q) => [q.code, `Question: ${q.title}`] as [string, string]) || [];

      return Object.values(groupBy([['location', 'Record Location'], ...locationSourceOptions], ([code]) => code)).map((v) => [
        v[0][0],
        v.map(([, label]) => label).join(' | '),
      ]);
    }

    get autoSelectEnabled(): boolean {
      return this.value.config.auto_select_enabled === 'true';
    }

    inactiveModuleDisplayTemplate(moduleName: ModuleName): JQuery {
      return inactiveTitleTemplate(moduleName, 'display');
    }

    beforeMount(): void {
      this.onModuleNameSelect(this.value.config.main_form_id);
    }

    onSortChange(sort: string) {
      this.$$patch(this.value.config, { sort });
    }

    onModuleNameSelect(id?: string): void {
      if (id) {
        const only = WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY;
        this.$api.getModuleName(Number(id), { only }, { cache: true }).then(({ data }) => {
          this.selectedModuleName = data;
        });
      } else {
        this.selectedModuleName = null;
      }

      this.$$patch(this.value.config, {
        main_form_id: id,
        filters: (`${id}` === `${this.value.config.main_form_id}` ? this.value.config.filters : []) || [],
      });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'main_form_id',
        'map_location_source',
        'map_center_source',
        'main_form_related',
        'expensable_mode',
        'map_selection',
        'add',
        'allow_clear_response',
        'sort',
        'auto_select_enabled',
        'out_of_scope_selection',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
