
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { SuperImport } from '@app/models/super-import';
  import type { Attachment } from '@app/models/attachment';
  import { downloadFileFromContent } from '@app/utils/download-file-from-content';
  import { Severity } from '@app/models/super-import-error';

  import DisplayError from './display-error.vue';

  @Component({ components: { DsModal, DisplayError } })
  export default class ErrorsCell extends Vue {
    @Prop(Object) superImport!: SuperImport;
    @Prop(String) scope!: Severity;
    @Prop(Boolean) canPresentStats!: boolean;

    modalVisible = false;
    modalTitle = '!!SET_ME!!';

    attachmentId: Nullable<string> = null;
    attachment: Nullable<Attachment> | undefined = null;
    downloading = false;

    downloadCSV(): void {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.$api
        .getSuperImportErrorsCSV(
          this.superImport.id,
          {
            only: ['id', 'message', 'severity', 'error', 'info', 'attachment_id'],
            filters: {
              severity: this.scope,
              attachment_id: this.attachmentId as string,
            },
          },
          { cache: false }
        )
        .then(({ data, headers }) => {
          const filename = headers['content-disposition'].split('=')[1];
          downloadFileFromContent(data, filename);
        })
        .finally(() => {
          this.downloading = false;
        });
    }

    showErrors(attachmentId: number | 'source_error'): void {
      this.attachmentId = attachmentId.toString();

      this.attachment = this.superImport.attachments.find(({ id }) => id.toString() == attachmentId);

      this.modalTitle = [this.scope === Severity.error ? 'Errors' : 'Warnings', this.attachment?.file_name].filter((v) => !!v).join(' ');

      this.modalVisible = true;
    }

    closeErrors() {
      this.modalVisible = false;
    }

    getErrorsCount(attachmentId: number | string): number {
      const stat = this.superImport.stats[attachmentId];
      const key = this.scope === Severity.warning ? 'warnings' : 'errors';
      return (stat && (stat[`${key}_count`] || (stat[key] && stat[key]?.length))) || 0;
    }
  }
