import type { RuleSetFilter } from '@app/models/rule-set-filter';
import type { Subscription } from 'rxjs';

import type { DsGroupNode } from './ds-group-node';
export interface NodeConfig {
  filters?: RuleSetFilter[];
  parent?: DsGroupNode;
}

export class DsNode {
  static idCounter = 0;

  parent?: DsGroupNode;
  localId: number;
  subs: Subscription[] = [];

  protected filters: RuleSetFilter[];

  constructor({ parent, filters }: NodeConfig = {}) {
    this.localId = DsNode.getNewId();
    this.parent = parent;
    this.filters = filters || (parent && parent.filters) || [];
  }

  static getNewId() {
    return ++this.idCounter;
  }

  remove() {
    if (this.parent) {
      this.parent.removeRuleOrGroup(this.localId);
    }
  }

  cleanup() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
