
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { Component } from 'vue-property-decorator';
  import type { ExpensableModuleBudget } from '@app/models/expensable-module-budget';

  @Component({ components: { Select2, EntitySelector } })
  export default class BudgetsTabSettings extends SubFormListSettingsBase {
    moduleBudgets: ExpensableModuleBudget[] = [];

    beforeMount(): void {
      this.$api
        .getModuleBudgets(
          {
            only: ['id', 'expensable_expensing_table_id', { expensing_table: ['id', 'name'] }],
            filters: { module_name_id: this.moduleName?.id, active: true },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleBudgets = data;
        });
    }
  }
