
  import { Component, Vue, Prop, Ref, Model, Emit } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import ImportForm from '../../super-import/import-form.vue';
  import AdminImportsTable from '../../super-import/admin-imports-table.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SuperImport } from '@app/models/super-import';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  interface TypeOption {
    code: string;
    label: string;
  }

  @Component({ components: { DsModal, ImportForm, AdminImportsTable } })
  export default class AdminSubFormImportModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Number) readonly subFormId!: number;
    @Prop(Array) readonly importOptions?: TypeOption[];
    @Ref() readonly importsTable?: AdminImportsTable;
    @Ref() readonly form?: ImportForm;

    moduleName: Nullable<ModuleName> = null;
    initialized = false;

    @Emit('create')
    onSubmit(newImport: SuperImport): void {
      this.$api
        .createImport(newImport)
        .then(({}) => {
          this.importsTable?.reload();
          this.form?.resetForm();
          toaster({
            text: this.$t('tenant.admin.module_names.sections.tab_importer.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['id', 'name', 'sub_forms'];
      const filters = { name: this.moduleNameName };
      this.$api
        .getModuleNames({ only, filters }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data[0];
          this.initialized = true;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
