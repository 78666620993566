
  import { Component, Prop } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import SubFormQuestionShow from '../sub-form-questions/sub-form-question-show.vue';
  import type { AsfAttachmentOnly, AsfSfsOnly, DraggableChangeEvent } from './utils';
  import NewQuestion from '@app/components/admin/sub-form-questions/new-question.vue';
  import Blocking from '@app/mixins/blocking';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { SubFormQuestionShow, Draggable, NewQuestion } })
  export default class SubFormSectionShow extends Blocking {
    @Prop(Object) readonly subFormSection!: Pick<SubFormSection, AsfSfsOnly>;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Array) readonly attachments!: Pick<Attachment, AsfAttachmentOnly>[];
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Number) readonly subFormId!: number;

    subFormQuestions: SubFormQuestion[] = [];

    onDragChange(event: DraggableChangeEvent<SubFormQuestion>): void {
      const newIndexes = this.subFormQuestions.map((question, index) => {
        return { id: question.id, index: index + 1 };
      });

      this.$api
        .updateSubFormQuestionIndexes({ data: newIndexes })
        .then(() => {
          !event.added?.element?.id &&
            toaster({
              text: this.$t('app.labels.order_saved'),
              position: 'top-right',
            });
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });

      !!event.added?.element?.id &&
        this.$api.updateSubFormQuestion(event?.added?.element?.id, { sub_form_section_id: this.subFormSection.id });
    }

    beforeMount() {
      this.loadQuestions();
    }

    loadQuestions() {
      this.blocking(() =>
        this.$api
          .getSubFormQuestionsWithSharedFilters(
            {
              filters: {
                active: true,
              },
              sort: 'index',
              include: ['attachments', 'is_code_matched'],
              per_page: -1,
            },
            'sub_form_section_id',
            this.subFormSection.id
          )
          .then(({ data }) => {
            this.subFormQuestions = data;
            this.$nextTick(() => this.$emit('questions-loaded', this.subFormSection.id));
          })
      );
    }
  }
