
  import { sortBy, upperFirst } from 'lodash';
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Select2 from '@app/components/select2.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { API_NULL, ACTIVITY_CONCEPT } from '@app/constants';
  import type { LocationTag } from '@app/models/location-tag';
  import type { ModuleName } from '@app/models/module-name';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import { GlobalAccess, CONFIDENTIALITY_BYPASS_PREFIX } from '@app/models/extended-permission';

  interface GroupedOption {
    group?: string;
    value: string;
  }

  type ModuleConfidentialityTypes = Record<number, Pick<ConfidentialityType, 'id' | 'name'>[]>;

  @Component({ components: { ValidationObserver, ValidationProvider, Select2, EntitySelector } })
  export default class AdminSettingsLocationTagsForm extends Vue {
    @Prop(Object) readonly locationTag?: Partial<LocationTag>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Omit<LocationTag, 'extended_permissions'>> & { extended_permissions: string[] } = {
      extended_permissions: [],
    };
    moduleNames: Pick<ModuleName, 'display' | 'plural_display' | 'name' | 'id'>[] = [];
    tagCategoryOptions = [
      ['safety', 'Safety (Will show in user directory)'],
      ['duty', "Duty (Won't show in user directory)"],
    ];
    hasGlobalTags = false;
    confidentialityTypes: Pick<ConfidentialityType, 'id' | 'name' | 'module_name_id'>[] = [];

    get moduleConfidentialityTypes(): ModuleConfidentialityTypes {
      return this.confidentialityTypes.reduce((memo, c) => {
        return {
          ...memo,
          [c.module_name_id]: [...(memo[c.module_name_id] || []), { id: c.id, name: c.name }],
        };
      }, {} as ModuleConfidentialityTypes);
    }

    get moduleNameByConcept(): Record<string, Partial<ModuleName>> {
      return this.moduleNames.reduce((memo, moduleName) => ({ ...memo, [moduleName.name]: moduleName }), {
        [ACTIVITY_CONCEPT]: { plural_display: this.$t('app.labels.actions').toString() },
      });
    }

    get extendedPermissionOptions(): GroupedOption[] {
      const activityOptions = this.basePermissions(ACTIVITY_CONCEPT, this.$t('app.labels.actions').toString());

      const moduleOptions = this.moduleNames.reduce((memo: GroupedOption[], moduleName) => {
        const basePermissions = this.basePermissions(moduleName.name, moduleName.display);

        const specialPermissions = (this.moduleConfidentialityTypes[moduleName.id] || []).map((type) => {
          return { value: `${CONFIDENTIALITY_BYPASS_PREFIX}${type.id}|${moduleName.name}`, group: moduleName.display };
        });

        return [...memo, ...basePermissions, ...specialPermissions];
      }, []);

      return sortBy([...activityOptions, ...moduleOptions], (option) => option.value);
    }

    get globalDisabled(): boolean {
      return !!this.locationTag?.allow_global && this.hasGlobalTags;
    }

    get permissionsToOptions(): string[] {
      return this.locationTag?.extended_permissions?.map((xp) => `${xp.name}|${xp.concept_name}`) || [];
    }

    get optionsToPermissions(): Partial<ExtendedPermission>[] {
      return this.form.extended_permissions.map((option) => {
        const [name, ...rest] = option.split('|');
        const concept_name = rest.join('|');
        return { name, concept_name };
      });
    }

    basePermissions(moduleName: string, group: string): GroupedOption[] {
      return [
        { value: `${GlobalAccess.View}|${moduleName}`, group },
        { value: `${GlobalAccess.Edit}|${moduleName}`, group },
        { value: `${GlobalAccess.Delete}|${moduleName}`, group },
      ];
    }

    getPermissionType(type: string, moduleName: Partial<ModuleName>): string | undefined {
      const { plural_display: modulePluralDisplay, id: moduleId, display: moduleDisplay } = moduleName;
      switch (type) {
        case GlobalAccess.View:
          return this.$t('app.labels.all_unrestricted_module', { module: modulePluralDisplay, type: 'View' });
        case GlobalAccess.Edit:
        case GlobalAccess.Delete:
          const label = type?.split('_')[1];
          return this.$t('app.labels.all_accessible_module', { module: modulePluralDisplay, type: upperFirst(label) });
      }

      if (type.includes(CONFIDENTIALITY_BYPASS_PREFIX) && moduleId && this.moduleConfidentialityTypes[moduleId]) {
        const type_id = parseInt(type.split('_').pop() as string);
        const confidentialityType = this.moduleConfidentialityTypes[moduleId].find((ct) => {
          return ct.id === type_id;
        });

        if (confidentialityType) return `Can Bypass ${moduleDisplay} ${confidentialityType.name} Confidentiality Restrictions`;
      }
    }

    // Examples of template:
    // - View All Unrestricted {module_plural}
    // - Edit All Accessible {module_plural}
    // - Delete All Accessible {module_plural}
    extendedPermissionTemplate(option: { id: string; text: string }): JQuery<HTMLElement> | string {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      const [name, ...rest] = option.id.split('|');
      const concept_name = rest.join('|');
      const moduleName = this.moduleNameByConcept[concept_name];

      return $(`<span class="text-capitalize">${this.getPermissionType(name, moduleName)}</span>`);
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result &&
          this.$emit('submit', {
            ...this.form,
            extended_permissions: this.optionsToPermissions,
          });
      });
    }

    fetchConfidentialityTypes(): void {
      const moduleIds = this.moduleNames.map((moduleName) => moduleName.id);
      if (moduleIds) {
        this.$api
          .getConfidentialityTypes(
            {
              filters: { active: true, module_name_id: moduleIds },
              per_page: -1,
              only: ['id', 'name', 'module_name_id'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.confidentialityTypes = data;
          });
      }
    }

    beforeMount(): void {
      this.form = {
        ...this.locationTag,
        extended_permissions: this.permissionsToOptions,
      };
      this.$api
        .getModuleNames(
          {
            filters: { active: true, '!sub_form_id': API_NULL },
            per_page: -1,
            only: ['display', 'id', 'name', 'plural_display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
          this.fetchConfidentialityTypes();
        });
      if (this.locationTag?.id) {
        this.$api
          .getUserLocationTags(
            {
              per_page: 1,
              only: ['id'],
              filters: {
                location_tag_id: this.locationTag?.id,
                location_id: API_NULL,
              },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.hasGlobalTags = !!data.length;
          });
      }
    }
  }
