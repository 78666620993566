import { render, staticRenderFns } from "./admin-regulatory-reporting-page.vue?vue&type=template&id=b60298d2&scoped=true&lang=pug"
import script from "./admin-regulatory-reporting-page.vue?vue&type=script&lang=ts"
export * from "./admin-regulatory-reporting-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b60298d2",
  null
  
)

export default component.exports