
  import BaseSubFormListPage from '@app/components/sub-form-list/base-sub-form-list-page';
  import SubFormListEditRestrictionModal from '@app/components/sub-form-list/sub-form-list-edit-restriction-modal.vue';
  import SubFormListFormModal from '@app/components/sub-form-list/sub-form-list-form-modal.vue';
  import { Component } from 'vue-property-decorator';
  import { SubFormListType } from '@app/models/sub-form-list';

  @Component({ components: { SubFormListEditRestrictionModal, SubFormListFormModal } })
  export default class SubFormListRestrictionsPage extends BaseSubFormListPage {
    visible = true;

    get approvalEnabled(): boolean {
      return !!this.subFormList && [SubFormListType.approval, SubFormListType.workflow].includes(this.subFormList.sub_form_list_type);
    }

    beforeMount(): void {
      // use only instead
      this.$api
        .getSubFormList(
          Number(this.subFormListId),
          {
            include: [
              'sub_form_ids',
              'module_tab',
              'sub_form_list_restrictions.rule_set',
              'sub_form_list_roles',
              'sub_form_list_role_profiles',
            ],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.subFormList = data;
        });
    }
  }
