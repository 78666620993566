
  import { Component, Model, Prop, Watch, Mixins } from 'vue-property-decorator';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { PrintConfiguration } from '@app/models/print-configuration';
  import DsModal from '@app/components/ds-modal.vue';
  import { toaster } from '@app/utils/toaster';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import Blocking from '@app/mixins/blocking';
  import DsCheckbox from '@app/components/ds-checkbox.vue';

  @Component({ components: { EntitySelector, DsCheckbox, DsModal } })
  export default class ModuleRecordPrintConfigModal extends Mixins(Blocking) {
    @Model('input') value!: boolean;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'name'>;
    @Prop(Number) readonly recordId!: number;

    printConfig: Partial<PrintConfiguration> = {
      options: {
        actions: true,
        action_details: true,
        attachments: true,
        comments: true,
        details: true,
        indicators: true,
        involvements: true,
        related_to: true,
      },
    };

    checkboxProps = {
      invertLabel: true,
      horizontal: true,
      compactLabel: true,
    };

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get subFormListsFilters(): DonesafeFilterOptions<SubFormList, { record_id: number; record_type: string }> {
      return {
        active: true,
        record_id: this.recordId,
        record_type: 'ModuleRecord',
        module_tab: {
          active: true,
          module_name: this.moduleName.name,
        },
      };
    }

    get printUrl() {
      return {
        name: 'module-record-print-page',
        params: { recordId: String(this.recordId) },
        query: this.printConfig,
      };
    }

    @Watch('value')
    onModalValueChanged(newValue: boolean): void {
      if (newValue) {
        this.getPrintConfigurations();
      }
    }

    printAndSave(): void {
      const promise = this.printConfig.id
        ? this.$api.updatePrintConfiguration(this.printConfig.id, this.printConfig)
        : this.$api.createPrintConfiguration({ ...this.printConfig, module_name_id: this.moduleName.id });
      promise
        .then(() => {
          this.$api.cache.clear();
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    groupByModuleTab(option: Pick<SubFormList, 'id' | 'title' | 'module_tab'>): string | undefined {
      return option.module_tab?.title;
    }

    closeModal(): void {
      this.$emit('input', false);
    }

    getPrintConfigurations(): Promise<void | null> {
      return this.blocking(async () => {
        await this.$api
          .getPrintConfigurations({ filters: { module_name_id: this.moduleName.id }, only: ['options', 'id'] }, { cache: true })
          .then(({ data }) => {
            if (data.length) this.printConfig = data[0];
          });
      });
    }

    printSubFormListTitle(subFormList: Pick<SubFormList, 'title' | 'id'>): string {
      return `${subFormList.title} (${subFormList.id})`;
    }
  }
