
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import type { MatchData } from './duplicate-code-questions-finder';
  import type DuplicateCodeQuestionsFinder from './duplicate-code-questions-finder';
  import { DuplicateCategory } from './duplicate-code-questions-finder';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { RULES_APPLICABLE_TYPES } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ShowHideSource } from '@app/models/question-options/base-question-options';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  type PermissionsRequired = 'permissions-required';

  interface HeadingOptions {
    class?: string;
    heading: string;
    headingClass?: string | string[];
    icon?: string;
    iconContent?: string;
    subheading?: string;
    subheadingClass?: string | string[];
    subheadingDetail?: string;
  }

  @Component({
    components: { BaseTable },
  })
  export default class DuplicateCodeQuestions extends Vue {
    @Prop(Object) readonly questionFinder!: DuplicateCodeQuestionsFinder;
    @Prop(Object) readonly subForm!: SubForm;

    sameFormQuestions: SubFormQuestion[] = [];
    mainFormQuestions: SubFormQuestion[] = [];

    PERMISSIONS_REQUIRED: PermissionsRequired = 'permissions-required';

    visualOptions: Record<DuplicateCategory | PermissionsRequired, HeadingOptions> = {
      [DuplicateCategory.CanMatch]: {
        icon: 'fa fa-exclamation-triangle',
        class: 'text-warning',
        headingClass: 'with-colon m-r-xs',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.duplicates',
        subheading: 'tenant.admin.sub_form_questions.form.code_matches.can_match',
      },
      [DuplicateCategory.CannotMatch]: {
        icon: 'fa fa-exclamation-triangle',
        class: 'text-warning',
        headingClass: 'with-colon m-r-xs',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.duplicates',
        subheading: 'tenant.admin.sub_form_questions.form.code_matches.cannot_match',
        subheadingClass: 'text-danger',
      },
      [DuplicateCategory.MissingShowHideRules]: {
        icon: 'fa fa-exclamation-triangle',
        class: 'text-warning',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.duplicates',
        headingClass: 'with-colon m-r-xs',
        subheading: 'tenant.admin.sub_form_questions.form.code_matches.cannot_match',
        subheadingClass: 'text-danger',
        subheadingDetail: 'tenant.admin.sub_form_questions.form.code_matches.must_add_conditions',
      },
      [DuplicateCategory.NoShowHideRules]: {
        icon: 'fa fa-exclamation-triangle',
        class: 'text-warning',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.duplicates',
        headingClass: 'with-colon m-r-xs',
        subheading: 'tenant.admin.sub_form_questions.form.code_matches.cannot_match',
        subheadingClass: 'text-danger',
      },
      [DuplicateCategory.CodeMatched]: {
        icon: 'material-icons settings-ethernet',
        iconContent: 'settings_ethernet',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.code_matched',
      },
      [DuplicateCategory.CodeShared]: {
        heading: 'tenant.admin.sub_form_questions.form.code_matches.code_shared',
      },
      [DuplicateCategory.Inactive]: {
        icon: 'fa fa-check',
        class: 'text-success',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.unique_active',
      },
      [DuplicateCategory.NoMatches]: {
        icon: 'fa fa-check',
        class: 'text-success',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.no_matches',
      },
      ['permissions-required']: {
        icon: 'fa fa-exclamation-triangle',
        class: 'text-danger',
        heading: 'tenant.admin.sub_form_questions.form.code_matches.duplicates',
        headingClass: 'with-colon m-r-xs',
        subheading: 'tenant.admin.sub_form_questions.form.code_matches.must_be_unique',
        subheadingClass: 'text-danger',
      },
    };

    get loading(): boolean {
      return !!this.questionFinder?.loading;
    }

    get topCategory(): DuplicateCategory {
      return this.questionFinder?.topCategory || DuplicateCategory.NoMatches;
    }

    get sortedData(): MatchData[] {
      return this.questionFinder?.sortedData || [];
    }

    get groupedRelatedQuestions(): Record<string, SubFormQuestion> {
      const result: Record<string, SubFormQuestion> = {};
      this.sameFormQuestions.forEach((q) => (result[q.system_code] = q));
      this.mainFormQuestions.forEach((q) => (result[`${ShowHideSource.MainForm}|${q.system_code}`] = q));
      return result;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get userCanCodeMatch(): boolean {
      return this.currentUserStore.hasSystemAdministrationPermission || this.currentUserStore.hasModuleConfigPermission;
    }

    get codeMatchNeeded(): boolean {
      return this.topCategory !== DuplicateCategory.Inactive && this.topCategory !== DuplicateCategory.NoMatches;
    }

    get permissionRequiredVisualOptions(): HeadingOptions {
      return this.visualOptions[this.PERMISSIONS_REQUIRED];
    }

    beforeMount(): void {
      this.fetchRelatedQuestions();
    }

    async fetchMainFormQuestions(subForm: SubForm, only: OnlyOptions<SubFormQuestion>) {
      if (!this.subForm || this.subForm.module_name === MAIN_FORM_MODULE_NAME) return [];

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({ filters: { name: subForm.module_name }, only: ['id', 'sub_form_id'] }, { cache: true });

      if (!moduleName) return [];

      const { data: mainFormQuestions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only,
          filters: {
            field_type: RULES_APPLICABLE_TYPES,
            sub_form_section: { sub_form_id: moduleName.sub_form_id },
            active: true,
          },
        },
        { cache: true }
      );

      return mainFormQuestions;
    }

    async fetchRelatedQuestions(): Promise<void> {
      const questionOnlyOptions: OnlyOptions<SubFormQuestion> = ['field_type', 'config', 'options', 'system_code', 'title', 'code'];
      const { data: sameFormQuestions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only: questionOnlyOptions,
          filters: {
            field_type: RULES_APPLICABLE_TYPES,
            sub_form_section: { sub_form_id: this.subForm.id },
            active: true,
          },
        },
        { cache: true }
      );

      this.sameFormQuestions = sameFormQuestions;

      this.mainFormQuestions = await this.fetchMainFormQuestions(this.subForm, questionOnlyOptions);
    }

    showQuestionTitle(question: SubFormQuestion): string | undefined {
      if (!question.config.show?.id) return;
      const questionKey = question.config.show?.source
        ? `${ShowHideSource.MainForm}|${question.config.show?.id}`
        : question.config.show?.id;
      return this.groupedRelatedQuestions[questionKey]?.title;
    }

    isMultiValueShowHide(question: SubFormQuestion): boolean {
      if (!question.config.show) return false;
      if (!Array.isArray(question.config.show.value)) return false;

      return question.config.show.value.length > 1;
    }

    showHideValuesText(question: SubFormQuestion): string {
      if (!question.config.show?.value) return '';
      if (Array.isArray(question.config.show.value)) return question.config.show.value.join(', ');

      return question.config.show.value;
    }
  }
