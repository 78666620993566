
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import type { LatLng } from '@app/models/geocoder-result';

  @Component
  export default class Heatmap extends Vue {
    @Ref() readonly map!: google.maps.Map & { $mapPromise: Promise<google.maps.Map> };
    @Prop() readonly coordinates!: LatLng[];

    zoom = 11;

    mapOptions: google.maps.MapOptions = {
      center: { lat: 37.775, lng: -122.434 },
      zoom: this.zoom,
    };

    getPoints(): google.maps.LatLng[] {
      return this.coordinates.map(({ lat, lng }) => {
        return new google.maps.LatLng(lat, lng);
      });
    }

    handleZoomChange(event: number) {
      this.zoom = event - 0.2;
    }

    initMap() {
      if (!this.coordinates.length) return;

      const latitudes = this.coordinates.map(({ lat }) => lat);
      const longitudes = this.coordinates.map(({ lng }) => lng);
      this.map.$mapPromise.then((mapInstance) => {
        const latMin = Math.min(...latitudes);
        const lngMin = Math.min(...longitudes);
        const latMax = Math.max(...latitudes);
        const lngMax = Math.max(...longitudes);

        if (latMin != 0) {
          mapInstance.setCenter(new google.maps.LatLng((latMax + latMin) / 2.0, (lngMax + lngMin) / 2.0));
          mapInstance.fitBounds(
            new google.maps.LatLngBounds(
              // bottom left
              new google.maps.LatLng(latMin, lngMin),
              // top right
              new google.maps.LatLng(latMax, lngMax)
            )
          );
        }

        new google.maps.visualization.HeatmapLayer({
          data: this.getPoints(),
          map: mapInstance,
        });
      });
    }

    mounted() {
      this.initMap();
    }
  }
