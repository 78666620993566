
  import type { Restriction } from './utils';
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import RuleBuilderPanel from '@app/components/admin/events/rule-builder-panel.vue';
  import { keyBy, sortBy } from 'lodash';
  import type { Involvement } from '@app/models/involvement';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';

  @Component({ components: { SimpleGridTable, RuleBuilderPanel } })
  export default class SubFormListRestrictionForm extends Vue {
    @Prop(String) readonly conceptName!: string;
    @Prop(String) readonly permissionType!: string;
    @Prop(String) readonly title!: string;
    @Prop(String) readonly subtitle!: string;
    @Prop(Array) readonly involvements?: Involvement[];
    @Prop(Array) readonly relationships?: [string, string][];
    @Prop(Array) readonly currentRestrictions!: Restriction[];

    restrictionsOpen = false;
    form: Restriction[] = [];

    @Emit('submit')
    submit(): { relationships: Record<string, string>; restrictions: Restriction[] } {
      this.restrictionsOpen = false;
      const newRestrictions = this.form
        .filter((r) => !r.viewable)
        .map((r) => {
          if (r.viewable === false) {
            return { relationship: r.relationship, viewable: false };
          } else {
            return { relationship: r.relationship, rule_set: r.rule_set, viewable: null };
          }
        });
      return { restrictions: newRestrictions, relationships: this.relationshipNames };
    }

    updateRuleSet(restriction: Restriction, newValue: QueryBuilderBlob) {
      restriction.rule_set = { query_builder_blob: newValue };
    }

    toggleForm(open: boolean): void {
      this.form = this.rebuildRestrictions();
      this.restrictionsOpen = open;
      this.$emit('input', open);
    }

    getRelationshipName(id: string): string {
      return this.relationshipNames[id] || 'N/A';
    }

    involvementRestrictionLabel(restriction: Restriction): string {
      const involvementName = this.getRelationshipName(restriction.relationship); // handle no involvements loaded yet

      if (restriction?.viewable === null) {
        return `${involvementName} (${this.$t('tenant.module_tabs.show.restriction_labels.conditional')})`;
      } else if (restriction?.viewable === false) {
        return `${involvementName} (${this.$t('tenant.module_tabs.show.restriction_labels.no')})`;
      } else {
        return involvementName;
      }
    }

    involvementRestrictionClass(restriction: Restriction): string {
      if (restriction.viewable === true) {
        return 'label-success';
      } else if (restriction.viewable === null) {
        return 'label-warning';
      } else {
        return 'label-default line-through';
      }
    }

    get relationshipNames(): Record<string, string> {
      return this.allRelationships.reduce((memo, [key, name]) => ({ ...memo, [key]: name }), {});
    }

    get allRelationships(): [string, string][] {
      return [
        ...(sortBy(this.involvements || [], 'name').map((item) => [`${item.id}`, item.name]) as [string, string][]),
        ...(this.relationships || []),
      ];
    }

    rebuildRestrictions(): Restriction[] {
      const restrictionsByRelationship = keyBy(this.currentRestrictions, 'relationship');
      return this.allRelationships.map((item) => {
        const current = restrictionsByRelationship[item[0]];
        if (current && current.rule_set) {
          return { relationship: item[0], rule_set: { query_builder_blob: current.rule_set.query_builder_blob }, viewable: null };
        } else if (current) {
          return { relationship: item[0], rule_set: {}, viewable: current.viewable };
        } else {
          return { relationship: item[0], rule_set: {}, viewable: true };
        }
      });
    }

    get liveList(): Restriction[] {
      return this.rebuildRestrictions();
    }

    beforeMount(): void {
      this.form = this.rebuildRestrictions();
    }
  }
