
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { flatten, groupBy, mapValues, orderBy } from 'lodash';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import IndicatorSetComponent from './indicator-set.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Indicator } from '@app/models/indicator';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { RecordIndicator } from '@app/models/record-indicator';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { DsDropdown, IndicatorSet: IndicatorSetComponent } })
  export default class IndicatorsPanel extends Vue {
    @Prop(Boolean) readonly canEdit!: boolean;
    @Prop(String) readonly moduleName!: string;
    @Prop(String) readonly recordType!: string;
    @Prop(Number) readonly recordId!: number;

    indicatorSets: IndicatorSet[] = [];
    recordIndicators: RecordIndicator[] = [];
    saving = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get indicators(): Indicator[] {
      return orderBy(flatten(this.indicatorSets.map((s) => s.indicators || [])), 'index');
    }

    get indicatorsById(): Dictionary<Indicator> {
      return this.indicators.reduce((m, i) => ({ ...m, [i.id]: i }), {});
    }

    get recordIndicatorsBySetId(): Dictionary<RecordIndicator[]> {
      const risByIndicatorSetId = groupBy(this.recordIndicators, (ri) => this.indicatorsById?.[ri.indicator_id]?.indicator_set_id);

      return mapValues(risByIndicatorSetId, (arr) => orderBy(arr, (ri) => this.indicatorsById?.[ri.indicator_id]?.index));
    }

    beforeMount(): void {
      this.loadIndicatorSets();
      this.loadRecordIndicators();
    }

    async loadRecordIndicators(): Promise<RecordIndicator[]> {
      const { data: recordIndicators } = await this.$api.getRecordIndicators({
        per_page: -1,
        only: ['id', 'indicator_id'],
        filters: {
          indicatable_type: this.recordType,
          indicatable_id: this.recordId,
        },
      });

      this.recordIndicators = recordIndicators;

      return recordIndicators;
    }

    async loadIndicatorSets(): Promise<IndicatorSet[]> {
      const filters: DonesafeFilterOptions<IndicatorSet> = {
        active: true,
        module_name: this.moduleName,
      };

      if (!this.currentUserStore.hasModuleConfigPermission) {
        filters.hidden = false;
      }

      const { data: indicatorSets } = await this.$api.getIndicatorSets({
        per_page: -1,
        only: ['id', 'name', 'multi', 'indicators', 'active', 'index', 'hidden', 'display_only', 'hide_title', 'indicator_type'],
        filters,
        include: ['indicators'],
        sort: 'index',
      });

      this.indicatorSets = indicatorSets;

      return indicatorSets;
    }
  }
