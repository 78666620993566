
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  interface FileUploadQuestionOptions extends BaseQuestionOptions {
    display_full: StringBoolean;
  }

  @Component({
    components: { DsCheckbox },
  })
  export default class FileUploadFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<FileUploadQuestionOptions>;

    beforeMount() {
      this.$$patch(this.value.config, { display_full: 'true' }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['display_full'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
