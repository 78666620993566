
  import { Component, Vue } from 'vue-property-decorator';
  import EventOccurrences from '@app/components/admin/event-occurrences/event-occurrences.vue';

  @Component({
    components: {
      EventOccurrences,
    },
  })
  export default class EvenOccurrencesIndex extends Vue {}
