
  import { useAccountStore } from '@app/stores/account';
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop, Vue, Ref } from 'vue-property-decorator';
  import Select2 from '../select2.vue';
  import RecordSelector from '../record-selector.vue';
  import EntitySelector from '../entity-selector.vue';
  import LocationSelector from '../location/location-selector.vue';
  import OrganizationSelector from '../organization/organization-selector.vue';
  import DatePicker from '../date-picker.vue';
  import DateTimePicker from '../date-time-picker.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { RegulatoryReport } from '@app/models/regulatory-report';
  import type { RegulatoryReportConfig, RegulatoryReportFilter } from '@app/models/regulatory-report-config';
  import RecordFilterSelector from './record-filter-selector.vue';

  @Component({
    components: {
      RecordSelector,
      EntitySelector,
      LocationSelector,
      Select2,
      OrganizationSelector,
      DatePicker,
      DateTimePicker,
      DsModal,
      ValidationObserver,
      ValidationProvider,
      RecordFilterSelector,
    },
  })
  export default class RegulatoryReportFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly regulatoryReportConfig!: RegulatoryReportConfig;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<RegulatoryReport> = {};
    submitDisabled = false;

    get defaultFilters(): Record<string, unknown> {
      return this.regulatoryReportConfig.config.default_filters || {};
    }

    get defaultReportFormats(): string[] {
      return this.regulatoryReportConfig.config?.available_formats?.filter((format) => format.checked)?.map((format) => format.key) || [];
    }

    get dateFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_date_format;
    }

    get dateTimeFormat(): Maybe<string> {
      return this.accountStore.data.datetimepicker_datetime_format;
    }

    get dateTimeZone(): Maybe<string> {
      return this.accountStore.data.timezone_identifier;
    }

    get accountStore() {
      return useAccountStore();
    }

    initForm(): void {
      this.form = {
        regulatory_report_config_id: this.regulatoryReportConfig.id,
        options: {
          report_filters: { ...this.defaultFilters },
          report_formats: [...this.defaultReportFormats],
        },
      };
      this.submitDisabled = false;
    }

    onModalShow(): void {
      this.initForm();
      this.validator?.reset();
    }

    validatorConfig(filter: RegulatoryReportFilter): string {
      if (filter.required) {
        return 'required';
      }
      return '';
    }

    convertFilters(filter: object = {}): object {
      return Object.fromEntries(
        Object.entries(filter).map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return [key, this.convertFilters(value)];
          } else if (key.startsWith('~')) {
            if (this.form.options?.report_filters && this.form.options?.report_filters[value]) {
              return [key.replace(/^[~]+/, ''), this.form.options?.report_filters[value]];
            }

            return [key, value];
          } else {
            return [key, value];
          }
        })
      );
    }

    disableAndNullifyField(filter: RegulatoryReportFilter): boolean {
      const need_disable = this.disabledField(this.convertFilters(filter.filters || {}));

      if (need_disable) {
        if (this.form.options?.report_filters && this.form.options?.report_filters[filter.key]) {
          this.form.options.report_filters[filter.key] = null;
        }
      }

      return need_disable;
    }

    disabledField(filter: object): boolean {
      let disabled = false;

      for (const [key, value] of Object.entries(filter)) {
        if (typeof value === 'object' && value !== null) {
          disabled = this.disabledField(value);
        } else if (key.startsWith('~')) {
          disabled = true;
        }

        if (disabled) {
          break;
        }
      }

      return disabled;
    }

    callbackOnChange(currentFilter: RegulatoryReportFilter): void {
      if (!this.form.options?.report_filters) {
        return;
      }

      this.regulatoryReportConfig.config.available_filters?.forEach((filter) => {
        if (this.filterIsRelated(filter, currentFilter.key)) {
          if (this.form.options?.report_filters && this.form.options?.report_filters[filter.key]) {
            this.form.options.report_filters[filter.key] = null;
          }
        }
      });
    }

    filterIsRelated(filter: object, relatedField: string): boolean {
      let related = false;

      for (const [key, value] of Object.entries(filter)) {
        if (typeof value === 'object' && value !== null) {
          related = this.filterIsRelated(value, relatedField);
        } else if (key.startsWith('~')) {
          related = value === relatedField;
        }

        if (related) {
          break;
        }
      }

      return related;
    }

    submitForm(): void {
      this.submitDisabled = true;
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        } else {
          this.submitDisabled = false;
        }
      });
    }

    beforeMount(): void {
      this.initForm();
    }
  }
