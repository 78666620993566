import type { DonesafeApiOptions, OnlyOption } from '@app/services/donesafe-api-utils';
import type { AutomationDefinition } from '@app/models/automation-definition';
import type { AutomatedEvent } from '@app/models/automated-event';
import type { AutomationEventGroup } from '@app/models/automation-event-group';
import type { Event } from '@app/models/event';

const automationDefinitionProjection: (opts?: { withoutIds?: boolean }) => DonesafeApiOptions<AutomationDefinition> = ({
  withoutIds = false,
} = {}) => ({
  only: [
    ...(withoutIds ? [] : (['id'] as OnlyOption<AutomationDefinition>[])),
    'name',
    'description',
    'concept_name',
    'rule_set_id',
    'sub_form_id',
    'index',
    'relationship_code',
    'involvement_id',
    'regulatory_report_config_id',
    'triggering_fields',
    'trigger_on',
    'trigger_when',
    'state',
    'display',
    'placeholder_profiles',
    'rule_set',
    {
      automation_event_groups: [...(withoutIds ? [] : (['id'] as OnlyOption<AutomationEventGroup>[])), 'name', 'parent_id'],
    },
    {
      automated_events: [
        ...(withoutIds ? [] : (['id'] as OnlyOption<AutomatedEvent>[])),
        'index',
        'event_id',
        'automation_event_group_id',
        {
          event: [
            ...(withoutIds ? [] : (['id'] as OnlyOption<Event>[])),
            'chained_events',
            'enable_retries',
            'event_type',
            'name',
            'notification_email',
            'options',
            'per',
            'placeholder_profiles',
            'placeholder_profile_handles',
            'collection_rules',
            'retries',
          ],
        },
      ],
    },
  ],
});

export default automationDefinitionProjection;
