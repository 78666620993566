import { render, staticRenderFns } from "./record-indicator-event-form.vue?vue&type=template&id=7b65e1bd&scoped=true&lang=pug"
import script from "./record-indicator-event-form.vue?vue&type=script&lang=ts"
export * from "./record-indicator-event-form.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b65e1bd",
  null
  
)

export default component.exports