
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Indicator } from '@app/models/indicator';

  @Component({ components: {} })
  export default class IndicatorItem extends Vue {
    @Prop(Boolean) readonly canEdit!: boolean;
    @Prop(Object) readonly indicator!: Indicator;
    @Prop(Boolean) readonly hideDescription?: boolean;
  }
