
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class RelatedMainForm extends Vue {
    @Prop(Object) readonly question!: SubFormQuestion;

    relatedModuleName: Nullable<Pick<ModuleName, 'id' | 'name' | 'display' | 'active'>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get mainFormId(): number {
      return Number(this.question.config.main_form_id);
    }

    get moduleLabel(): string | undefined {
      if (this.relatedModuleName) {
        return this.relatedModuleName.active
          ? this.relatedModuleName.display
          : this.$t('app.labels.title_inactive', { title: this.relatedModuleName.display });
      }
    }

    beforeMount() {
      !!this.mainFormId &&
        this.$api
          .getModuleName(this.mainFormId, { only: ['id', 'name', 'display', 'active'] }, { join: true, cache: true })
          .then(({ data }) => {
            this.relatedModuleName = data;
          });
    }
  }
