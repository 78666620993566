
  import { Component, Vue, Model, Prop } from 'vue-property-decorator';
  import QrCode from '@chenfengyuan/vue-qrcode';
  import { qrCodeLink } from '@app/utils/qr-code-link';

  @Component({ components: { QrCode } })
  export default class QrCodePanel extends Vue {
    @Model('input') value!: boolean;
    @Prop(String) readonly data!: string;
    @Prop(String) readonly title?: string;

    get qrCodeUrl(): string {
      return qrCodeLink(this.data, {
        output_format: 'png',
        download: true,
      });
    }
  }
