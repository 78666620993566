
  import Select2 from '@app/components/select2.vue';
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import type { RoleTabDetailsModuleNameOnly, RoleTabDetailsRoleOnly } from './utils';
  import { extendedPermissionsByConceptAndName } from './utils';
  import { keyBy } from 'lodash';
  import type { ModuleName } from '@app/models/module-name';
  import type { Role } from '@app/models/role';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { GlobalAccess } from '@app/models/extended-permission';
  import { displayModuleName } from '@app/utils/display-module-name';

  @Component({ components: { Select2 } })
  export default class IntegratedModulesRoleSettings extends Vue {
    @Prop(Array) readonly moduleNames!: Pick<ModuleName, RoleTabDetailsModuleNameOnly>[];
    @Prop(Object) readonly role!: Pick<Role, RoleTabDetailsRoleOnly>;
    @Prop(Boolean) readonly disabled?: boolean;

    @Emit('update')
    onGlobalViewAccessInput(moduleNameName: string, value?: string[]): { concept_name: string; name: string; type: 'remove' | 'add' } {
      return {
        concept_name: moduleNameName,
        name: GlobalAccess.View,
        type: value?.includes(GlobalAccess.View) ? 'add' : 'remove',
      };
    }

    get globalViewAccessOptions(): [string, string][] {
      return [[GlobalAccess.View, this.$t('app.labels.enabled')]];
    }

    get integratedModules(): Pick<ModuleName, RoleTabDetailsModuleNameOnly>[] {
      return this.moduleNames.filter((moduleName) => moduleName.module_type === 'integrated');
    }

    get extendedPermissionsByConceptAndName(): Record<string, Record<string, Pick<ExtendedPermission, 'name' | 'concept_name'>[]>> {
      return extendedPermissionsByConceptAndName(this.role?.extended_permissions);
    }

    getGlobalViewAccessValue(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): string[] {
      return Object.keys(this.extendedPermissionsByConceptAndName[moduleName.name] || {});
    }

    displayIntegratedName(concept: string): string {
      if (HARDCODED_MODULE_CODES.includes(concept)) {
        return displayModuleName(concept, null, keyBy(this.moduleNames, 'name'), this.$t);
      } else {
        return concept;
      }
    }
  }
