
  import { Component, Model, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { AddressQuestionOptions } from '@app/models/question-options/address-question-options';
  import { MapSize } from '@app/models/question-options/address-question-options';
  import { useAccountStore } from '@app/stores/account';

  type SelectOptions = [MapSize, string][];

  @Component({ components: { Select2, DsCheckbox, FormField } })
  export default class AddressFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<AddressQuestionOptions>;

    mapSizeOptions: SelectOptions = [
      [MapSize.minimal, this.$t('tenant.admin.sub_form_questions.form_fields.edit.address_field.map_sizes.minimal')],
      [MapSize.small, this.$t('tenant.admin.sub_form_questions.form_fields.edit.address_field.map_sizes.small')],
      [MapSize.normal, this.$t('tenant.admin.sub_form_questions.form_fields.edit.address_field.map_sizes.normal')],
      [MapSize.huge, this.$t('tenant.admin.sub_form_questions.form_fields.edit.address_field.map_sizes.huge')],
    ];

    get mapSelectEnabled(): boolean {
      return this.value.config.map_select_enabled === 'true';
    }

    get accountStore() {
      return useAccountStore();
    }

    beforeMount(): void {
      this.$$patch(
        this.value.config,
        {
          map_select_enabled: this.value.id ? 'false' : 'true',
          map_select_height: MapSize.normal,
          map_display_height: MapSize.small,
        },
        { ifEmpty: true }
      );
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['map_display_height', 'map_select_enabled', 'map_select_height'].forEach((key) => this.$delete(this.value.config, key));
      ['address'].forEach((key) => this.$delete(this.value.config.validate || {}, key));
    }
  }
