import type { default as _Vue } from 'vue';
import Chartkick from 'vue-chartkick';
import Highcharts from 'highcharts';

const withChartkick = (Vue: typeof _Vue): typeof _Vue => {
  Vue.use(Chartkick.use(Highcharts));
  return Vue;
};

export default withChartkick;
