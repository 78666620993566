
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { ModuleTab } from '@app/models/module-tab';

  import AdminSubFormControlPanel from './admin-sub-form-control-panel.vue';
  import AdminSubFormImportModal from './admin-sub-form-import-modal.vue';
  import AdminSubForm from './admin-sub-form.vue';
  import SubFormSectionsNewButton from './sub-form-sections-new-button.vue';

  // TODO: replace haml with this component
  // -#%admin-sub-forms-show{'data-behaviour': 'vue', ':sub-form': @sub_form.to_json, ':module-tab': @module_tab.to_json, ':module-name': @sub_form.related_module_name.to_json}

  const SUB_FORM_ONLY = Tuple([
    'id',
    'description',
    'scoring',
    'title',
    'active',
    'module_name',
    { sub_form_lists: ['module_tab_id'] },
  ] as const);

  @Component({ components: { AdminSubFormControlPanel, AdminSubForm, AdminSubFormImportModal, SubFormSectionsNewButton } })
  export default class AdminSubFormsShow extends Vue {
    @Prop({ type: [Number, String] }) readonly subFormId!: number | string;
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;
    @Prop({ type: [Number, String] }) readonly moduleTabId?: number | string;

    moduleName: Nullable<ModuleName> = null;
    subForm: Nullable<Pick<SubForm, FirstLevelKeyLiterals<(typeof SUB_FORM_ONLY)[number]>>> = null;
    moduleTab: Nullable<ModuleTab> = null;

    get isHybridModule(): boolean {
      return !!this.moduleTab?.hybrid_module;
    }

    get tabId(): Maybe<number> {
      return Number(this.moduleTab?.id) || (this.subForm?.sub_form_lists?.length && this.subForm?.sub_form_lists[0].module_tab_id);
    }

    get routeQuery(): object {
      return this.$route.query.just_created ? { ...this.$route.query, selected_sub_form_id: this.subFormId } : {};
    }

    beforeMount(): void {
      this.fetchModuleName();
      this.fetchSubForm();
      this.fetchModuleTab();
    }

    async fetchSubForm(): Promise<void> {
      const { data } = await this.$api.getSubForm(
        Number(this.subFormId),
        {
          only: SUB_FORM_ONLY,
        },
        { cache: true }
      );
      this.subForm = data;
    }

    async fetchModuleName(): Promise<void> {
      const { data } = await this.$api.getModuleName(
        Number(this.moduleNameId),
        { only: ['id', 'name', 'module_type', 'sub_form_id'] },
        { cache: true }
      );
      this.moduleName = data;
    }

    async fetchModuleTab(): Promise<void> {
      !!this.moduleTabId &&
        this.$api.getModuleTab(Number(this.moduleTabId), { only: ['id', 'hybrid_module'] }, { cache: true }).then(({ data }) => {
          this.moduleTab = data;
        });
    }
  }
