
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import SingleSelectFieldOptions from '@app/components/admin/questions/settings/single-select-field-options.vue';
  import HideQuestion from '@app/components/admin/questions/settings/shared/hide-question.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  @Component({
    components: { HideQuestion, SingleSelectFieldOptions },
  })
  export default class RadioFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseSelectQuestionOptions>;

    @Prop(Object) readonly features!: ModuleFeatures;
  }
