
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import EventForEverySelector from '@app/components/admin/events/event-for-every-selector.vue';
  import RuleBuilderPanel from '@app/components/admin/events/rule-builder-panel.vue';
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import BaseCreateObjectEvent from '@app/mixins/base-create-object-event-form';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { extend } from 'vee-validate';
  import DsIcon from '@app/components/ds-icon.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { Collapse } from 'uiv';
  import EventOptionsMixin from '@app/components/admin/automation-definitions/event-options-mixin';
  import type { RequiredBy } from '@app/utils/types/required-by';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import Blocking from '@app/mixins/blocking';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';

  import FormFields from '../events/forms/form-fields.vue';
  import FormField from '../questions/edit/form-field.vue';

  @Component({
    components: {
      RuleBuilderPanel,
      EventForEverySelector,
      Select2,
      FormFields,
      DsIcon,
      DsIconText,
      DsDropdown,
      Collapse,
      FormField,
    },
  })
  export default class AutomationCreateObjectEventForm extends mixins(BaseCreateObjectEvent, EventOptionsMixin, Blocking) {
    @Ref() readonly ruleBuilderPanel!: RuleBuilderPanel;
    @Ref() readonly eventTypeSelect!: Select2;
    @Ref() readonly formFields!: FormFields;

    @Prop(Object) readonly injectableArguments!: RequiredBy<InjectableArguments, 'concept_name'>;
    @Prop({ type: [Number, String], default: 0 }) readonly index?: number | string;
    @Prop() readonly preloadedForEveryModuleNames?: ModuleName[];
    @Prop() readonly preloadedForEverySubForms?: SubForm[];
    @Prop() readonly disableFooter!: (cb: () => Promise<void>) => void;

    internalAutomationCreateEventOptions: [string, string][] = [];
    formFieldsLoaded = false;

    get newEvent(): boolean {
      return typeof this.syncedEvent.id !== 'number';
    }

    get executionContext(): string {
      return 'automation';
    }

    get automationCreateEventOptions(): [string, string][] {
      const data = this.internalAutomationCreateEventOptions;
      if (!useCurrentUserStore()?.isDefaultTechAdmin && this.syncedEvent.event_type !== 'delete_record') {
        return data.filter(([k]) => k !== 'delete_record');
      }
      return data;
    }

    get hasTriggeringRecord(): boolean {
      return !!this.injectableArguments?.concept_name;
    }

    get conceptName(): string {
      return this.injectableArguments?.concept_name || '';
    }

    get showEventForEvery(): boolean {
      return !!this.preloadedForEveryModuleNames && !!this.preloadedForEverySubForms;
    }

    @Watch('createObjectEventFormLoading')
    @Watch('formFieldsLoaded')
    onCreateObjectEventFormLoadingChange(): void {
      if (!this.createObjectEventFormLoading && (!this.syncedEvent.event_type || this.formFieldsLoaded)) {
        this.sendLoaded();
      }
    }

    onRuleBuilderChange(value: QueryBuilderBlob | undefined): void {
      this.openRuleBuilderInEditMode = !value;
      if (this.syncedEvent.collection_rules !== value) {
        this.syncedEvent.collection_rules = value ? value : null;
      }
    }

    onPerChange(per: string) {
      this.disableFooter(async () => {
        await this.blocking(async () => {
          this.openRuleBuilderInEditMode = true;
          await Promise.all([this.updatePerArguments(per, 'automation'), this.getCollection(per)]);
          this.syncedEvent.collection_rules = null;
        });
      });
    }

    async getPerOptions(): Promise<void> {
      return this.$api
        .getPerOptions({ concept_name: this.conceptName, injectable_arguments: this.injectableArguments }, { cache: true })
        .then(({ data }) => {
          this.perOptions = data;
        });
    }

    focusEventTypeSelect(): void {
      this.$nextTick(() => {
        setTimeout(() => {
          const el = this.eventTypeSelect?.$el as HTMLSelectElement;
          if (!el) return;
          !this.syncedEvent.event_type && this.eventTypeSelect?.open();
        }, 1);
      });
    }

    async beforeMount(): Promise<void> {
      Promise.all([
        this.getAutomationCreateEventOptions(),
        this.getPerOptions(),
        ...this.getAutomationOptionsPromises(this.injectableArguments, 'automation'),
      ]).finally(() => {
        extend('eventTypeRequired', {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          validate: (value: string) => this.automationCreateEventOptions.some(([option, _]) => `${option}` === `${value}`),
          message: this.$t('tenant.admin.events.form_fields.for_every_required'),
        });
        this.createObjectEventFormLoading = false;
      });

      this.$on('loaded', () => {
        this.focusEventTypeSelect();
      });

      if (!!this.per) {
        this.updatePerArguments(this.per, 'automation');
        this.getCollection(this.per, { set: true });
      }

      this.$on('loaded', () => {
        this.focusEventTypeSelect();
      });
    }
  }
