import { render, staticRenderFns } from "./record-index-widget-settings.vue?vue&type=template&id=6a33c44f&scoped=true&lang=pug"
import script from "./record-index-widget-settings.vue?vue&type=script&lang=ts"
export * from "./record-index-widget-settings.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a33c44f",
  null
  
)

export default component.exports