
  import { Component } from 'vue-property-decorator';
  import type { DisplayImageQuestionOptions } from '@app/models/question-options/display-image-question-options';

  import BaseShowOptions from './base-show-options';

  @Component({ components: {} })
  export default class DisplayImageShowOptions extends BaseShowOptions<DisplayImageQuestionOptions> {
    get attachmentUrl() {
      return this.question.attachments?.[this.question.attachments.length - 1]?.url;
    }

    get altText(): string {
      return this.question.config.alt_text || '';
    }
  }
