
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import UserCollectionSelector from '../user-collections/user-collection-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import ColorInput from '@app/components/color-input.vue';

  import { compact, omit } from 'lodash';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { Workflow } from '@app/models/workflow';
  import { NOT_LOCKABLE_BY_WORKFLOW } from '@app/models/sub-form-question';

  @Component({
    components: { Select2, ValidationObserver, ValidationProvider, UserCollectionSelector, ColorInput },
  })
  export default class WorkflowForm extends Vue {
    @Prop(Object) readonly workflow?: Partial<Workflow>;
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;

    form: Partial<Workflow> = {};
    moduleName: Nullable<Pick<ModuleName, 'main_form' | 'name' | 'start_workflow_id'>> = null;
    subFormList: Nullable<Pick<SubFormList, 'start_workflow_id'>> = null;
    mainFormQuestions: Pick<SubFormQuestion, 'id' | 'field_type' | 'title'>[] = [];
    subFormListQuestions: Pick<SubFormQuestion, 'id' | 'field_type' | 'title'>[] = [];
    selectorKey = 0;
    isLoading = true;

    async beforeMount(): Promise<void> {
      this.initForm();
      await this.fetchParent();
      await this.fetchUserCollection();
      this.isLoading = false;
    }

    initForm(): void {
      this.form = { ...this.workflow };
    }

    async fetchParent(): Promise<void> {
      if (this.moduleNameId) {
        const { data } = await this.$api.getModuleName(
          this.moduleNameId,
          {
            filters: { active: true },
            only: [
              'name',
              'start_workflow_id',
              { main_form: [{ sub_form_sections: [{ sub_form_questions: ['id', 'field_type', 'title'] }] }] },
            ],
          },
          { cache: true }
        );
        this.moduleName = data;
        this.setStartingState(this.moduleName.start_workflow_id);
        this.mainFormQuestions =
          this.moduleName.main_form?.sub_form_sections?.map((s: SubFormSection) => s.sub_form_questions || []).flat() || [];
      } else if (this.subFormListId) {
        const { data } = await this.$api.getSubFormList(
          this.subFormListId,
          { only: ['sub_form_ids', 'approval_sub_form_id', 'start_workflow_id'] },
          { cache: true }
        );
        this.subFormList = data;
        this.setStartingState(data.start_workflow_id);
        const allSubFormIds = compact([...(data.sub_form_ids || []), data.approval_sub_form_id]);
        if (allSubFormIds.length > 0) {
          await this.fetchSubFormQuestions(allSubFormIds);
        }
      }
    }

    setStartingState(startWorkflowId?: number): void {
      if (!startWorkflowId) return;
      this.form = { ...this.form, starting_state: this.workflow?.id === startWorkflowId };
    }

    async fetchSubFormQuestions(subFormIds: number[]): Promise<void> {
      const { data } = await this.$api.getSubFormQuestions(
        {
          filters: {
            sub_form_section: {
              sub_form_id: subFormIds,
            },
          },
          per_page: -1,
          only: ['id', 'field_type', 'title'],
        },
        { cache: true }
      );

      this.subFormListQuestions = data;
    }

    async fetchUserCollection(): Promise<void> {
      if (this.workflow?.user_collection_id) {
        const { data } = await this.$api.getUserCollection(
          this.workflow.user_collection_id,
          { only: ['id', 'name', 'global', { rule_set: ['rules_description', 'query_builder_blob'] }] },
          { cache: true }
        );
        this.form = { ...this.form, user_collection: data };
        this.selectorKey++;
      }
    }

    get moduleNameId(): number | undefined {
      return this.workflow?.module_name_id;
    }

    get subFormListId(): number | undefined {
      return this.workflow?.sub_form_list_id;
    }

    get triggeringConceptName(): string | undefined {
      if (this.moduleName) return this.moduleName.name;
    }

    get startWorkflowId(): number {
      return Number(this.moduleName?.start_workflow_id) || Number(this.subFormList?.start_workflow_id);
    }

    get lockedSubFormQuestionOptions(): (string | number)[][] {
      const data = this.moduleNameId ? this.mainFormQuestions : this.subFormListQuestions;
      return (
        data.reduce((memo, q) => {
          if (q.field_type && !NOT_LOCKABLE_BY_WORKFLOW.includes(q.field_type)) {
            return [...memo, [q.id, q.title || '']];
          }
          return memo;
        }, [] as (string | number)[][]) || []
      );
    }

    get formForSubmit(): Partial<Workflow> {
      const mappedForm = { ...this.form, color: this.form.color || null } as Partial<Workflow>;
      if (!this.form?.user_collection) return mappedForm;

      return mappedForm?.user_collection?.global
        ? omit({ ...mappedForm, user_collection_id: mappedForm.user_collection.id }, 'user_collection')
        : omit(mappedForm, 'user_collection_id');
    }

    submit(): void {
      this.validator.validate().then((valid) => {
        if (valid) {
          this.$emit('submit', this.formForSubmit);
        }
      });
    }
  }
