import { Vue, Prop, Component } from 'vue-property-decorator';
import type { InjectableArguments } from '@app/services/api/utils-api';

import type { EventWithTempId } from './with-automation-submit';

@Component
export default class EventOptionsMixin extends Vue {
  @Prop(Object) readonly injectableArguments!: InjectableArguments;

  internalAutomationCreateEventOptions: [string, string][] = [];

  get automationCreateEventOptions(): [string, string][] {
    return this.internalAutomationCreateEventOptions;
  }

  get eventTypeName(): Record<string, string> {
    return this.automationCreateEventOptions.reduce((memo, val) => {
      return { ...memo, [val[0]]: val[1] };
    }, {});
  }

  eventTitle(event: EventWithTempId): string {
    return (
      event.name || this.eventTypeName[event?.event_type || ''] || this.$t('tenant.admin.automation_definitions.form.no_event_selected')
    );
  }

  async getAutomationCreateEventOptions(): Promise<void> {
    return await this.$api
      .getAutomationCreateEventOptions({ injectable_arguments: this.injectableArguments }, { cache: true })
      .then(({ data }) => {
        this.internalAutomationCreateEventOptions = data;
      });
  }
}
