
  import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
  import bootbox from 'bootbox';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Confidentiality } from '@app/models/confidentiality';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { ModuleName } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  import UserSelector from '../user/user-selector.vue';

  @Component({ components: { UserSelector, DsModal } })
  export default class SetupConfidentialAccessModal extends Vue {
    @Model('input') value!: boolean;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id'>;
    @Prop(String) readonly lockableType!: string;
    @Prop(Number) readonly lockableId!: number;
    @Prop({ type: Boolean, default: true }) allowCreateConfidentiality!: boolean;

    confidentialTypes: ConfidentialityType[] = [];
    form: Record<string, number[]> = {};
    loading = false;
    confidentiality = false;
    get disableAndClearAll(): boolean {
      return this.lockableType === 'SubFormCompletion' && !this.allowCreateConfidentiality;
    }

    get confidentialityTypesFilters(): DonesafeFilterOptions<ConfidentialityType> {
      return {
        active: true,
        module_name_id: this.moduleName.id,
      };
    }

    get confidentialitiesToUpdate(): Pick<
      Confidentiality & { user_id: number[] },
      'lockable_id' | 'lockable_type' | 'confidentiality_type_id'
    >[] {
      return Object.keys(this.form).map((key) => {
        return {
          lockable_id: Number(this.lockableId),
          lockable_type: this.lockableType,
          confidentiality_type_id: Number(key),
          user_ids: this.form[+key].map(Number),
        };
      });
    }
    @Watch('value')
    onModalValueChanged(newValue: boolean): void {
      if (newValue) {
        this.getConfidentialityTypes();
        this.getConfidentialities();
      }
    }

    clearAll(): void {
      this.form = Object.keys(this.form).reduce((memo, key) => ({ ...memo, [key]: [] }), this.form);
    }

    submitForm(): void {
      const message = this.confidentialityRemoved()
        ? this.$t('tenant.shared.setup_confidential_access.clear_confirmation')
        : this.$t('app.labels.are_you_sure');
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: message,
        buttons: {
          confirm: { label: this.$t('app.buttons.proceed'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .bulkCreateConfidentialities({ confidentialities: this.confidentialitiesToUpdate })
              .then(() => {
                this.$emit('input', false);
                window.location.reload();
              })
              .catch((error) => toaster({ text: error.message, icon: 'error' }));
        },
      });
    }

    getConfidentialityTypes(): Promise<void> {
      return this.$api
        .getConfidentialityTypes({ filters: this.confidentialityTypesFilters, only: ['id', 'name'] }, { cache: true })
        .then(({ data }) => {
          this.confidentialTypes = data;
        });
    }

    closeModal(): void {
      this.$emit('input', false);
    }

    confidentialityRemoved(): boolean {
      return (
        this.confidentiality &&
        Object.keys(this.form).every((key) => {
          return !this.form[key].length;
        })
      );
    }

    getConfidentialities(): Promise<void> {
      this.loading = true;
      return this.$api
        .getConfidentialities(
          {
            filters: {
              lockable_id: this.lockableId,
              lockable_type: this.lockableType,
            },
            per_page: -1,
            only: ['id', 'user_id', 'confidentiality_type_id'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.form = data.reduce(
            (memo, item) => ({
              ...memo,
              [item.confidentiality_type_id]: [...(memo[item.confidentiality_type_id] || []), item.user_id],
            }),
            {} as Record<string, number[]>
          );
          this.confidentiality = !!Object.keys(this.form).length;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
