
  import { Component, Model, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { SubFormOptions, CustomScoring } from '@app/models/sub-form-options';

  import Select2 from '../select2.vue';

  interface StringOption {
    code: string;
    label: string;
  }

  @Component({ components: { Select2, ValidationObserver, ValidationProvider } })
  export default class CustomScoringPanel extends Vue {
    @Model('input') readonly value?: SubFormOptions;
    @Prop(String) readonly label?: string;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly compactView?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    get customScoringOptions(): StringOption[] {
      return [
        { code: 'none', label: this.$t('tenant.admin.sub_forms.custom_scoring_options.none') },
        { code: 'both', label: this.$t('tenant.admin.sub_forms.custom_scoring_options.both') },
        {
          code: 'weighting',
          label: this.$t('tenant.admin.sub_forms.custom_scoring_options.weighting'),
        },
        {
          code: 'threshold',
          label: this.$t('tenant.admin.sub_forms.custom_scoring_options.threshold'),
        },
      ];
    }
    @Emit('input')
    updateCustomScoring(customScoring: CustomScoring): Partial<SubFormOptions> {
      let update: Partial<SubFormOptions> = {};
      if (customScoring === 'none') {
        update = { weighting: undefined, threshold: undefined };
      } else if (customScoring === 'threshold') {
        update = { weighting: undefined };
      } else if (customScoring === 'weighting') {
        update = { threshold: undefined };
      }

      return { ...this.value, custom_scoring_type: customScoring, ...update };
    }

    // Will be prevent `e` and `-`
    preventNonNumericInput(event: KeyboardEvent): void {
      if (!/^\d+$/.test(event.key)) {
        event.preventDefault();
      }
    }

    fieldName(field: string): string {
      if (this.name) {
        return `${this.name}[${field}]`;
      }

      return field;
    }
  }
