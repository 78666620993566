import I18n from '@app/i18n';
import type { BaseEntity } from '@app/models/base-entity';
import { API } from '@app/services/api-factory';
import { sortBy, get } from 'lodash';
import qs from 'qs';
import type { NavigationGuardNext } from 'vue-router';
import type { NavigationGuard, Route } from 'vue-router/types/router';
import type { AdminPermissionFeature } from '@app/models/admin-permission';
import type { IndexFilter } from '@app/models/index-filter';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import { featureEnabled } from '@app/utils/feature-enabled';
import { pathWithIndexFilterParams } from '@app/utils/path-with-index-filter-params';
import { toaster } from '@app/utils/toaster';
import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import type { Account } from '@app/models/account';
import { changeLocation } from '@app/utils/change-location';

const goNextFactory = (to: Route, from: Route, next: NavigationGuardNext, nextGuard?: NavigationGuard): NavigationGuardNext => {
  return (route) => (nextGuard ? nextGuard(to, from, (newPath) => next(newPath || route)) : next(route));
};

const navigateToRoot = () => window.location.pathname !== '/' && changeLocation('/');

export const aclGuardFactory = (moduleName: string, nextGuard?: NavigationGuard): NavigationGuard => {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);
    const moduleAcl = window.DONESAFE.current_user.acl?.module?.[moduleName];
    if (moduleAcl?.view || moduleAcl?.mha) {
      goNext();
    } else {
      toaster({ text: I18n.t('controllers.defaults.no_access'), position: 'top-right', icon: 'error' });
      navigateToRoot();
    }
  };
};

export function indexFiltersGuardFactory<T extends BaseEntity = BaseEntity, F = {}>(
  indexFilterFilter: DonesafeFilterOptions<IndexFilter<T, F>>,
  defaultIndexFilter?: Partial<IndexFilter<T, F>>,
  nextGuard?: NavigationGuard
): NavigationGuard {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);
    API.getIndexFilters({ filters: indexFilterFilter }, { cache: true })
      .then(({ data }) => goNext(pathWithIndexFilterParams(to.fullPath, sortBy(data, 'user_id')[0], defaultIndexFilter)))
      .catch(() => goNext());
  };
}

export const permissionGuardFactory = (
  type: 'current_user' | 'account',
  location: string | string[],
  nextGuard?: NavigationGuard,
  redirectDestination = '/'
): NavigationGuard => {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);

    if (get(window.DONESAFE[type], location)) {
      goNext();
    } else {
      toaster({ text: I18n.t<string>('controllers.defaults.no_access'), position: 'top-right', icon: 'error' });
      changeLocation(redirectDestination);
    }
  };
};

export const featureGuardFactory = (
  feature: AdminPermissionFeature | AdminPermissionFeature[],
  nextGuard?: NavigationGuard
): NavigationGuard => {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);
    const user = window.DONESAFE['current_user'];

    if (featureEnabled(user, feature)) {
      goNext();
    } else {
      toaster({ text: I18n.t<string>('controllers.defaults.no_access'), position: 'top-right', icon: 'error' });
      navigateToRoot();
    }
  };
};

export const subFormModuleRedirectGuard = (): NavigationGuard => {
  return (to) => {
    API.getSubForm(Number(to.params.subFormId), { only: ['id', { sub_form_module_name: ['id'] }] }, { cache: true })
      .then(({ data }) => {
        const query = qs.stringify({ ...to.query, sub_forms_list: true });
        changeLocation(`/admin/settings/module_names/${data.sub_form_module_name?.id}/sub_forms/${data.id}?${query}`);
      })
      .catch(() => navigateToRoot());
  };
};

export const defaultFiltersGuardFactory = <T extends BaseEntity = BaseEntity, F = {}>(
  filters: DonesafeFilterOptions<T, F>,
  nextGuard?: NavigationGuard
): NavigationGuard => {
  return (to, from, next) => {
    const params = qs.parse(to.fullPath.split('?')[1]);
    const goNext = goNextFactory(to, from, next, nextGuard);
    if (params.filters) return goNext();

    goNext([to.path, qs.stringify({ filters }, { arrayFormat: 'brackets' })].join('?'));
  };
};

export const hasActiveNonEmptyPanesGuardFactory = (nextGuard?: NavigationGuard): NavigationGuard => {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);
    useCurrentUserStore().data?.has_active_panes ? goNext() : navigateToRoot();
  };
};

export const accountFeatureGuardFactory = (feature: keyof Account, invert?: boolean, nextGuard?: NavigationGuard): NavigationGuard => {
  return (to, from, next) => {
    const goNext = goNextFactory(to, from, next, nextGuard);
    const fieldValue = useAccountStore().data[feature];
    const result = invert ? !fieldValue : !!fieldValue;

    if (result) {
      goNext();
    } else {
      toaster({ text: I18n.t('controllers.defaults.no_access'), position: 'top-right', icon: 'error' });
      navigateToRoot();
    }
  };
};
