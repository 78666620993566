import type { BaseEntity } from '@app/models/base-entity';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import { Vue, Component } from 'vue-property-decorator';
import qs from 'qs';
import type { ValidIndexes, IndexFilter } from '@app/models/index-filter';
import { toaster } from '@app/utils/toaster';

@Component
export default class IndexFilters<T extends BaseEntity = BaseEntity, F = {}> extends Vue {
  // 'index' needs to be specified in the component where this mixin will be imported
  index!: ValidIndexes;
  indexFilters: IndexFilter<T, F>[] = [];

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }

  get personalFilter(): IndexFilter<T, F> | undefined {
    return this.indexFilters.find((f) => f.user_id === this.currentUserStore.data?.id);
  }

  saveCurrentFilter(): void {
    const queryParams = this.getQueryParams();
    const filter = (queryParams.filters || {}) as DonesafeFilterOptions<T, F>;
    const index = this.index;
    let sort = `${queryParams.sort}`;
    let reverse = (queryParams.reverse as Maybe<boolean>) || false;
    if (sort.startsWith('-')) {
      sort = sort.replace(/^-/, '');
      reverse = true;
    }
    const sortingOrder = { sort, reverse };
    const newIndexFilter: Partial<IndexFilter<T, F>> = { index, filter, sorting_order: sortingOrder };
    const filterId = this.personalFilter?.id;
    const promise = filterId ? this.$api.updateIndexFilter(filterId, newIndexFilter) : this.$api.createIndexFilter(newIndexFilter);
    promise
      .then(({ data }) => {
        this.replacePersonalFilter(data);
        toaster({ text: this.$t('tenant.index_filters.create.saved_new_filter'), position: 'top-right' });
      })
      .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
  }

  getQueryParams() {
    const parseOptions = { ignoreQueryPrefix: true, comma: true };
    return qs.parse(unescape(window.location.search), parseOptions);
  }

  clearPersonalFilter(): void {
    if (this.personalFilter) {
      this.$api.deleteIndexFilter(this.personalFilter.id).then(() => {
        this.replacePersonalFilter();
        toaster(this.$t('tenant.index_filters.delete.filter_deleted'));
      });
    }
  }

  replacePersonalFilter(newFilter?: IndexFilter<T, F>): void {
    this.indexFilters = this.indexFilters.filter((f) => f.user_id !== this.currentUserStore.data?.id).concat(newFilter ? [newFilter] : []);
  }

  fetchIndexFilters(): void {
    this.$api.getIndexFilters<T, F>({ filters: { index: this.index } }, { cache: true }).then(({ data }) => (this.indexFilters = data));
  }

  beforeMount(): void {
    this.fetchIndexFilters();
  }
}
