
  import { tooltip } from 'uiv';
  import DsPopover from '@app/components/ds-popover.vue';
  import { Component } from 'vue-property-decorator';
  import type { ValidIndexes } from '@app/models/index-filter';
  import { BaseTable } from '@app/components/base-table';
  import FilterSelect from '@app/components/filter-select.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { ExtraUserApiOptions, ValidUserScopes } from '@app/services/api/tenant-users-api';
  import UserImageLink from '@app/components/user-image-link.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import LocationTagSelector from '@app/components/location/location-tag-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';

  import type { UserLocationTag } from '@app/models/user-location-tag';
  import { groupBy, chunk } from 'lodash';
  import { mixins } from 'vue-class-component';
  import IndexFilters from '@app/mixins/index-filters';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Organization } from '@app/models/organization';
  import type { LocationTag } from '@app/models/location-tag';
  import type { Location } from '@app/models/location';
  import type { DonesafeFilterOptions, FilterValue } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';

  interface GroupedUserLocationTag {
    locations?: Partial<Location>[];
    tag?: Partial<LocationTag>;
  }

  @Component({
    components: {
      FilterSelect,
      BaseTable,
      DsDropdown,
      SearchInput,
      UserImageLink,
      LocationSelector,
      OrganizationSelector,
      LocationTagSelector,
      DsPopover,
    },
    directives: { tooltip },
  })
  export default class UserDirectoryPage extends mixins(IndexFilters<TenantUser>) {
    manager: Nullable<ListManager<TenantUser, ExtraUserApiOptions>> = null;
    index: ValidIndexes = 'users';

    get scopeOptions(): Dictionary<string> {
      return {
        all: this.$t('app.labels.all'),
        user_team: this.$t('app.labels.user_team'),
        user_wider_team: this.$t('app.labels.user_wider_team'),
      };
    }

    get currentScopeFilterValue(): string {
      const key: ValidUserScopes = this.manager?.customFilters?.scope || 'all';
      return this.scopeOptions[key];
    }

    get locationTagsLocationFilterValue(): FilterValue | undefined {
      return this.manager?.customFilters?.user_location_tags?.location_id;
    }

    get permissionFilters(): DonesafeFilterOptions<Organization | Location, { with_restrictions?: boolean }> {
      const base = { with_restrictions: this.accountStore.data.apply_permission_to_selectors };

      if (this.accountStore.data.hide_inactive_olu_for_filters) {
        return { ...base, active: true };
      }
      return base;
    }

    changeScopeFilter(val: ValidUserScopes): void {
      this.manager?.setFilter('scope', val);
    }

    getManager(): ListManager<TenantUser, ExtraUserApiOptions> {
      return new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params) => {
          return this.$api.getTenantUsers(
            {
              ...params,
              only: [
                'id',
                'avatar_url',
                'first_name',
                'last_name',
                'mobile',
                'payroll_identifier',
                'position',
                'can_be_opened',
                {
                  home_location: ['name'],
                  home_organization: ['name'],
                  user_location_tags: ['id', { location: ['name', 'id'], location_tag: ['name'] }, 'location_tag_id'],
                },
              ],
            },
            { cache: true }
          );
        },
        useHistory: true,
        filters: { active: true },
        customFilters: {
          user_location_tags: {
            location_id: [],
          },
        },
        sortOrder: [{ direction: 'asc', field: 'first_name', sortField: 'first_name' }],
        per_page: 25,
        fields: [
          { title: this.$t('app.labels.user'), name: 'user', dataClass: 'user-image-wrapper' },
          { title: this.$t('app.labels.first_name'), name: 'first_name', sortField: 'first_name' },
          { title: this.$t('app.labels.last_name'), name: 'last_name', sortField: 'last_name' },
          { title: this.$t('app.labels.phone'), name: 'mobile', sortField: 'mobile' },
          { title: this.$t('app.labels.position'), name: 'position', sortField: 'position' },
          { title: this.$t('app.labels.payroll_id'), name: 'payroll_identifier', sortField: 'payroll_identifier' },
          { title: this.$t('app.labels.home_location'), name: 'home_location.name', sortField: 'home_location.name', filter: true },
          {
            title: this.$t('app.labels.home_organization'),
            name: 'home_organization.name',
            sortField: 'home_organization.name',
            filter: true,
          },
          { title: this.$t('app.labels.tags'), name: 'user_location_tags', filter: true },
        ],
        allowFilters: true,
      });
    }

    groupUserLocationTags(userLocationTags: UserLocationTag[]): GroupedUserLocationTag[][] {
      return chunk(
        Object.values(groupBy(userLocationTags, 'location_tag_id')).map((tag) => {
          return {
            tag: tag[0].location_tag,
            locations: tag.map(({ location }) => location),
          } as GroupedUserLocationTag;
        }),
        3
      );
    }

    mounted(): void {
      this.manager = this.getManager();

      if (this.manager && !('user_location_tags' in this.manager.customFilters)) {
        this.manager.customFilters = {
          ...this.manager.customFilters,
          user_location_tags: { location_id: [] },
        };
      }
    }
  }
