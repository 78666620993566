
  import { useAccountStore } from '@app/stores/account';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import { uniqBy } from 'lodash';
  import { Tooltip } from 'uiv';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { permissionViewName, RTB_PERMISSIONS_ONLY } from './utils';
  import type { ModuleName } from '@app/models/module-name';
  import type { Permission } from '@app/models/permission';
  import { PermissionAccess } from '@app/models/permission';
  import { ACTION_APPROVAL_RELATIONSHIPS } from '@app/models/role';
  import { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
  import { ACTIVITY_CONCEPT } from '@app/constants';

  @Component({ components: { DsCheckbox, SimpleGridTable, Tooltip } })
  export default class RelationshipRightsTable extends Vue {
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'name'>;
    @Prop(Array) readonly permissions!: Permission[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly renderAsCell!: boolean;

    form: Record<string, boolean> = {};
    manager: Nullable<ListManagerStatic<{ id: string }>> = null;
    permissionsForModule: Permission[] = [];
    permissionAndViewName: (Permission & { view_name: string })[] = [];
    PermissionAccess = PermissionAccess;
    get actionApprovalsEnabled(): boolean {
      return !!useAccountStore()?.data?.enable_action_approvals;
    }

    showTooltip(value: string): boolean {
      return !this.actionApprovalsEnabled && ACTION_APPROVAL_RELATIONSHIPS.includes(value);
    }

    onPermissionInput(permissionId: number, value: boolean): void {
      this.form = { ...this.form, [permissionId]: value };
      const permission = this.permissionsForModule.find((p) => p.id === permissionId);
      permission && this.$emit('update', { permission, type: value ? 'add' : 'remove' });
    }

    @Watch('permissions', { deep: true })
    onPermissionsForCurrentConceptChanged() {
      // need to refresh the form when permissions change
      this.initForm();
    }

    getPermissionId(relationship: string, access: PermissionAccess): Maybe<number> {
      return this.permissionsForModule.find((permission) => permission.relationship === relationship && permission.access === access)?.id;
    }

    getManager(): ListManagerStatic<{ id: string }> {
      return new ListManagerStatic<{ id: string }>({
        localData: this.moduleRelationships,
        per_page: -1,
        fields: [
          {
            title: this.$t('app.labels.involvement'),
            name: 'relationship',
            width: 'minmax(90px, 28%)',
            titleClass: 'justify-content-end',
            dataClass: `d-flex align-items-center justify-content-end text-right`,
          },
          {
            title: this.$t('tenant.admin.roles.rtb.can_view'),
            name: 'view',
            width: 'minmax(90px, 24%)',
            titleClass: 'justify-content-center',
            dataClass: `d-flex align-items-center justify-content-center text-center`,
          },
          {
            title: this.$t('tenant.admin.roles.rtb.can_edit'),
            name: 'edit',
            width: 'minmax(90px, 24%)',
            titleClass: 'justify-content-center',
            dataClass: `d-flex align-items-center justify-content-center text-center`,
          },
          {
            title: this.$t('tenant.admin.roles.rtb.can_delete'),
            name: 'delete',
            width: 'minmax(90px, 24%)',
            titleClass: 'justify-content-center',
            dataClass: `d-flex align-items-center justify-content-center text-center`,
          },
        ],
      });
    }

    get showTable(): boolean {
      return !!this.manager;
    }

    get moduleRelationships(): { id: string }[] {
      return uniqBy(
        this.permissionsForModule.map((p) => {
          return { id: p.relationship };
        }),
        'id'
      );
    }

    getPrettyRelationshipName(key: string): string {
      return this.permissionAndViewName.filter((p) => p.relationship === key)?.[0]?.view_name || '';
    }

    initForm(): void {
      this.form = this.permissionsForModule.reduce((memo, { id }) => {
        return {
          ...memo,
          [id]: this.permissions?.some((p) => p.id === id),
        };
      }, {});
    }

    get sortParam(): string {
      return this.moduleName.name === ACTIVITY_CONCEPT ? '-name' : 'name';
    }

    async beforeMount(): Promise<void> {
      const { data: permissionsForModule } = await this.$api.getPermissionsWithSharedFilters(
        { only: RTB_PERMISSIONS_ONLY, sort: this.sortParam },
        'concept_name',
        this.moduleName.name,
        { cache: true }
      );
      this.permissionsForModule = permissionsForModule;
      const promises = (permissionsForModule || []).map(async (permission) => {
        return {
          ...permission,
          view_name: await permissionViewName(permission),
        };
      });
      this.permissionAndViewName = (await Promise.allSettled(promises))
        .filter((value) => value.status === 'fulfilled')
        .map((value) => (value as PromiseFulfilledResult<Permission & { view_name: string }>).value);
      this.initForm();
      this.manager = this.getManager();
      this.$emit('loaded');
    }
  }
