import type { OPERATORS_BY_TYPE } from '@app/components/ds-query-builder/defaults';
import type { RuleSetFilter } from './rule-set-filter';

export type QueryBuilderGroupRule = QueryBuilderRule | QueryBuilderGroup;
export type DebugQueryBuilderGroupRule = DebugQueryBuilderRule | DebugQueryBuilderGroup;

export interface QueryBuilderGroup {
  condition: 'AND' | 'OR';
  label?: string;
  rules: QueryBuilderGroupRule[];
  valid?: boolean;
}

export interface DebugQueryBuilderGroup extends QueryBuilderGroup {
  pass?: boolean;
}

export interface QueryBuilderRule {
  field: string;
  id: string;
  // maybe 'number' as well
  input: 'select' | 'text' | (string & {});
  operator: keyof typeof OPERATORS_BY_TYPE;
  type: RuleSetFilter['type'];
  value: string | string[] | null;
}

export interface DebugQueryBuilderRule extends QueryBuilderRule {
  pass?: boolean;
}

export const DEFAULT_QUERY_BUILDER_RULE: QueryBuilderRule = {
  field: '',
  id: '',
  input: '',
  operator: 'equal',
  type: '',
  value: '',
};

export const DEFAULT_QUERY_BUILDER_BLOB: QueryBuilderBlob = {
  condition: 'AND',
  rules: [DEFAULT_QUERY_BUILDER_RULE],
  label: '',
};

export type QueryBuilderBlob = QueryBuilderGroup;
export type DebugQueryBuilderBlob = DebugQueryBuilderGroup;
