
  import { Component } from 'vue-property-decorator';
  import type { ImageWidgetOptions } from '@app/models/widget';

  import BaseWidget from './base-widget';

  @Component
  export default class ImageWidget extends BaseWidget<ImageWidgetOptions> {
    imageLink = '';

    get imageStyle(): Record<string, string> | string {
      return !!this.imageLink
        ? {
            'background-image': `url('${this.imageLink}')`,
          }
        : '';
    }

    fetchImageLink(): void {
      if (this.accountStore.data.schema) {
        const filters = { attachable_id: this.widget.id, attachable_type: 'Widget' };
        const request = this.isPublicDashboard
          ? this.$api.getPublicAttachments({ filters, schema: this.accountStore.data.schema }, { cache: true })
          : this.$api.getAttachments({ filters }, { cache: true });
        request.then((response) => {
          this.imageLink = response.data[0]?.url;
        });
      }
    }

    mounted(): void {
      this.fetchImageLink();
    }
  }
