
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import AuditReportTemplateFormModal from '../module-names/audit-report-template-form-modal/new-audit-report-template-form-modal.vue';
  import { BaseTable } from '../../base-table';
  import { AUDIT_REPORT_TEMPLATE_ONLY } from '@app/components/admin/modules/utils';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { SubFormList } from '@app/models/sub-form-list';
  import {
    AuditReportTemplateFormat,
    AuditReportTemplatePurviewType,
    AuditReportTemplateReportType,
    AuditReportTemplateVisibility,
  } from '@app/models/audit-report-template';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { FilterValue } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { enumLabelFromKey } from '@app/utils/enumLabelFromKey';
  import { enumToOptions } from '@app/utils/enumToOptions';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { AuditReportTemplateFormModal, BaseTable, Select2, FilterSelect } })
  export default class ModuleAuditReportTemplatesPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<AuditReportTemplate>;

    manager: Nullable<ListManager<AuditReportTemplate>> = null;
    reportFormatOptions = enumToOptions(AuditReportTemplateFormat);
    visibilityOptions = enumToOptions(AuditReportTemplateVisibility);
    subFormLists: SubFormList[] = [];

    beforeMount(): void {
      this.$api
        .getSubFormLists(
          {
            filters: {
              active: true,
              sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
              module_tab: {
                related_module_name: { id: this.moduleNameId },
              },
            },
            per_page: -1,
            only: ['id', 'title'],
          },
          { cache: true }
        )
        .then((result) => {
          this.subFormLists = result.data;
        });
    }

    getManager(): ListManager<AuditReportTemplate> {
      return new ListManager<AuditReportTemplate>({
        fetchDataFunction: (params) => {
          return this.$api.getAuditReportTemplates(
            {
              ...params,
              filters: {
                ...params.filters,
                module_name_id: this.moduleNameId,
                ...this.reportTypeFilters,
              },
              only: AUDIT_REPORT_TEMPLATE_ONLY,
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          {
            title: this.$t('app.labels.index'),
            name: 'index',
            sortField: 'index',
          },
          {
            title: this.$t('app.labels.ID'),
            name: 'id',
            sortField: 'id',
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.name'),
            name: 'name',
            sortField: 'name',
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.visibility'),
            name: 'visibility',
            sortField: 'visibility',
            filter: true,
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.output_format'),
            name: 'report_format',
            sortField: 'report_format',
            filter: true,
            hidden: !window.DONESAFE.documentConverterPresent,
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.download'),
            name: 'download_template',
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.report_type'),
            name: 'report_type',
            sortField: 'report_type',
            filter: true,
          },
          {
            title: this.$t('tenant.admin.audit_report_template.table_head.generated_from'),
            name: 'sub_form_list_id',
            sortField: 'sub_form_list_id',
            filter: true,
          },
        ],
        allowFilters: true,
      });
    }

    get reportTypeFilters(): Record<string, FilterValue | undefined> {
      const reportType = this.manager?.customFilters.report_type;
      switch (reportType) {
        case AuditReportTemplateReportType.ModuleRecord:
          return { report_type: reportType, purview: AuditReportTemplatePurviewType.single_record };
        case AuditReportTemplateReportType.SubFormCompletion:
          return { report_type: reportType };
        case AuditReportTemplateReportType.RecordIndex:
          return {
            report_type: AuditReportTemplateReportType.ModuleRecord,
            purview: [AuditReportTemplatePurviewType.all_records, AuditReportTemplatePurviewType.record_index],
          };
      }

      return { report_type: reportType };
    }

    reportFormatLabel(key: AuditReportTemplateFormat): string | undefined {
      return enumLabelFromKey(AuditReportTemplateFormat, key);
    }

    visibilityLabel(key: AuditReportTemplateVisibility): string | undefined {
      return enumLabelFromKey(AuditReportTemplateVisibility, key);
    }

    reportTypeLabel(reportType: AuditReportTemplateReportType, purview: AuditReportTemplatePurviewType): string | undefined {
      if (this.isMainFormIndex(reportType, purview)) {
        return this.$t('tenant.admin.audit_report_template.form.main_form_index').toString();
      } else if (reportType === AuditReportTemplateReportType.ModuleRecord) {
        return this.$t('tenant.admin.audit_report_template.form.main_form').toString();
      } else if (reportType === AuditReportTemplateReportType.SubFormCompletion) {
        return this.$t('tenant.admin.audit_report_template.form.subform_tab_section').toString();
      }
    }

    subFormListTitle(sub_form_list_id?: number): string | undefined {
      if (sub_form_list_id) return this.subFormLists.find((subFormList) => subFormList.id === sub_form_list_id)?.title || 'N/A';
    }

    get reportTypeFilterOptions(): [AuditReportTemplateReportType, string][] {
      return [
        [AuditReportTemplateReportType.ModuleRecord, this.$t('tenant.admin.audit_report_template.form.main_form').toString()],
        [
          AuditReportTemplateReportType.SubFormCompletion,
          this.$t('tenant.admin.audit_report_template.form.subform_tab_section').toString(),
        ],
        [AuditReportTemplateReportType.RecordIndex, this.$t('tenant.admin.audit_report_template.form.main_form_index').toString()],
      ];
    }

    get subFormListIdFilterOptions(): (string | number)[][] {
      return [...this.subFormLists.map((sub_from_list) => [sub_from_list.id, sub_from_list.title])];
    }

    isMainFormIndex(reportType: AuditReportTemplateReportType, purview: AuditReportTemplatePurviewType) {
      return reportType === AuditReportTemplateReportType.ModuleRecord && purview !== AuditReportTemplatePurviewType.single_record;
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateAuditReportTemplateIndexes({ data, sort, include: ['attachment'] })
          .then(({ data }) => {
            toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
  }
