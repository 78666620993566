
  import { useAccountStore } from '@app/stores/account';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import DashboardGrid from '../dashboard-grid.vue';
  import type { Dashboard } from '@app/models/dashboard';
  import type { Widget } from '@app/models/widget';

  @Component({ components: { DashboardGrid } })
  export default class PublicDashboardsShowPage extends Vue {
    @Prop({ type: [String, Number] }) readonly dashboardId!: string | number;

    loading = true;

    dashboard: Nullable<Dashboard> = null;

    get accountStore() {
      return useAccountStore();
    }

    get compatibleWidgets(): Widget[] {
      const compatibleWidgetTypes = ['image', 'video', 'link', 'report_count', 'report_graph', 'text'];
      return this.dashboard?.widgets?.filter((w) => compatibleWidgetTypes.includes(w.widget_type)) || [];
    }

    beforeMount(): void {
      if (this.dashboardId) {
        this.$api
          .getPublicDashboard(
            Number(this.dashboardId),
            `${this.accountStore.data.schema}`,
            {
              only: ['widgets', 'active', 'name', 'show_widget_border'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.dashboard = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
