
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { ProgressBar, ProgressBarStack, tooltip } from 'uiv';

  @Component({ components: { ProgressBar, ProgressBarStack }, directives: { tooltip } })
  export default class AutomationDefinitionsActivationProgressBar extends Vue {
    @Prop(Boolean) readonly showLabelText?: boolean;
    @Prop(Number) readonly progress?: number;

    beforeDestroy(): void {
      this.$emit('destroy');
    }

    get labelText(): string {
      return this.showLabelText ? this.$t('tenant.admin.automation_definitions.form.activating_please_wait') : '';
    }
  }
