
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import AdminSamlForm from '@app/components/admin/samls/form.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Saml } from '@app/models/saml';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      AdminSamlForm,
      PaperTrailsModalLink,
      DsDropdown,
    },
  })
  export default class AdminSettingsSamlEdit extends Vue {
    saml: Nullable<Saml> = null;

    loading = false;

    updateSaml(saml: Saml, toast: string): void {
      this.loading = true;
      this.saml?.id &&
        this.$api
          .updateSaml(this.saml.id, saml)
          .then(({ data }) => {
            this.$api.cache.clear();
            this.saml = data;
            toaster(toast);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, icon: 'error' });
          })
          .finally(() => {
            this.loading = false;
          });
    }

    beforeMount(): void {
      this.$api
        .getSaml(this.$route.params.samlId, {}, { cache: false })
        .then(({ data }) => {
          this.saml = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
          this.$router.replace({
            name: 'admin-settings-samls-index',
            query: this.$route.query,
          });
        });
    }
  }
