import type { DebugQueryBuilderBlob, QueryBuilderBlob } from '@app/models/query-builder-blob';
import type { RuleSetFilter } from '@app/models/rule-set-filter';
import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

import { DEFAULTS } from '../defaults';

import { DsGroupNode } from './ds-group-node';

interface DsQueryBuilderConfig {
  debug?: boolean;
  editable?: boolean;
  filters: RuleSetFilter[];
  injectable_options?: PlaceholderItem[];
  missed_rule_ids?: string[];
  name: string;
  query_builder_blob: string;
}

export default class DsQueryBuilderHandler {
  rootGroup: Nullable<DsGroupNode> = null;
  config = DEFAULTS;
  filters: RuleSetFilter[] = [];
  editable = false;
  debug = false;
  validationErrors = {};
  missedRuleIds: string[] = [];
  name: string = '';
  injectableOptions: PlaceholderItem[] = [];

  constructor({ query_builder_blob, filters, editable, debug, missed_rule_ids, name, injectable_options }: DsQueryBuilderConfig) {
    if (!query_builder_blob) return;

    this.setFilters(filters);
    this.rootGroup = this.initialize(JSON.parse(query_builder_blob));
    this.editable = !!editable && !debug;
    this.debug = !!debug;
    this.missedRuleIds = missed_rule_ids || [];
    this.name = name;
    this.injectableOptions = injectable_options || [];
  }

  setValidationErrors(results: Record<string, string[]>) {
    this.validationErrors = results;
  }

  clearValidationErrors() {
    this.validationErrors = {};
  }

  setFilters(filters: RuleSetFilter[]) {
    this.filters = filters;
  }

  prepareForSave() {
    return this.rootGroup?.prepareForSave();
  }

  initialize(blob: QueryBuilderBlob | DebugQueryBuilderBlob): DsGroupNode {
    return new DsGroupNode({
      condition: blob.condition,
      rules: blob.rules,
      filters: this.filters,
      level: 1,
      label: blob?.label,
      ...('pass' in blob ? { pass: blob?.pass } : {}),
    });
  }

  destroy() {
    this.rootGroup?.destroy();
  }
}
