
  import QrCode from '@chenfengyuan/vue-qrcode';
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import EntitySelector from '../../../components/entity-selector.vue';
  import Select2 from '../../../components/select2.vue';
  import type { WSAction } from '@app/services/api/mobile-app-registrations-api';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  interface TestFormData {
    closable?: boolean;
    layout?: string | number;
    mode?: 'browser' | 'app';
    soft?: boolean;
    url?: string;
  }
  @Component({ components: { EntitySelector, Select2, QrCode, DsModal, ValidationObserver, ValidationProvider } })
  export default class TestRegistrationPage extends Vue {
    @Prop([String, Number]) readonly mobileAppRegistrationId!: string | number;
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    registration: Nullable<MobileAppRegistration> = null;

    visible = true;
    form: { action: WSAction; data: TestFormData } = { action: 'change_mode', data: {} };

    beforeMount(): void {
      this.$api.getMobileAppRegistration(this.mobileAppRegistrationId, {}, { cache: true }).then(({ data }) => {
        this.registration = data;
      });
    }

    onModeChange(mode: 'app' | 'browser'): void {
      this.form = { ...this.form, data: { mode } };
    }

    onActionChange(): void {
      this.form = { ...this.form, data: {} };
    }

    get availableActions(): WSAction[] {
      return ['change_mode', 'unregister', 'fetch', 'print'];
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }

    testRegistration(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          if (this.registration) {
            this.$api.notifyMobileAppRegistration(this.registration.id, this.form).then(({ data }) => {
              toaster(`WS action sent: ${JSON.stringify(data)}`);
            });
          }
        }
      });
    }
  }
