import type { SubFormQuestion } from '@app/models/sub-form-question';
import Big from 'big.js';

export const displayCalculatedValue = (
  value: string,
  question: Pick<SubFormQuestion, 'config'>,
  defaultCurrency: string
): string | undefined => {
  if (!!value) {
    switch (question.config.format_validation) {
      case 'currency':
        return `${question.config.currency_symbol || defaultCurrency}${value}`;
      case 'percentage':
        return displayPercentage(value, question.config.decimal_places);
      default:
        return value;
    }
  }
};

const displayPercentage = (decimalValueString: string, decimalPlacesString: null | string = null): string => {
  if (!decimalValueString) decimalValueString = '0';
  let percentageString: string | number = new Big(decimalValueString) * 100;
  if (!decimalPlacesString) return percentageString.toString() + '%';
  const decimalPlaces = parseInt(decimalPlacesString);
  percentageString = parseFloat(`${percentageString}`).toFixed(decimalPlaces).toString();
  const decimalIndex = percentageString.indexOf('.');

  if (decimalPlaces === 0) {
    if (decimalIndex) percentageString = percentageString.slice(0, decimalIndex);
  } else if (decimalIndex) {
    const digitsAfterDecimal = percentageString.length - (decimalIndex + 1);
    percentageString += '0'.repeat(decimalPlaces - digitsAfterDecimal);
  } else {
    percentageString += '.' + '0'.repeat(decimalPlaces);
  }

  return percentageString + '%';
};
