
  import { Component, Vue, Model, Watch, Prop } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ModuleName } from '@app/models/module-name';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { ReportQuestionOptions } from '@app/models/question-options/report-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import RecordFilterConfigurator from '../filter-configurator/record-filter-configurator.vue';
  import type { SubForm } from '@app/models/sub-form';
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '@app/components/admin/questions/filter-configurator/utils';

  @Component({ components: { EntitySelector, FormField, RecordFilterConfigurator } })
  export default class ReportFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<ReportQuestionOptions>;

    @Prop(Object) readonly subForm?: SubForm;

    targetModuleName: ModuleName | null = null;

    get moduleNameFilter(): DonesafeFilterOptions<ModuleName> {
      return {
        active: true,
        '!name': this.excludedModuleNames,
      };
    }

    get excludedModuleNames(): string[] {
      return HARDCODED_MODULE_CODES.filter((moduleCode) => ['Audit', 'Hazard', 'Incident'].indexOf(moduleCode) < 0);
    }

    @Watch('value.config.module_name')
    async loadTargetModuleName() {
      this.targetModuleName = !!this.value.config.module_name ? await this.loadModuleName(this.value.config.module_name) : null;
    }

    beforeMount() {
      this.loadTargetModuleName();
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['module_name'].forEach((key) => this.$delete(this.value.config, key));
    }

    async loadModuleName(code?: string): Promise<ModuleName | null> {
      if (!code || HARDCODED_MODULE_CODES.includes(code)) {
        return null;
      }

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames(
        {
          filters: { name: code },
          only: WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY,
        },
        {
          cache: true,
        }
      );

      return moduleName || null;
    }
  }
