
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import RuleBuilderPanel from '@app/components/admin/events/rule-builder-panel.vue';
  import { isEmpty } from 'lodash';
  import { v4 as generateUUID } from 'uuid';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { RuleSet } from '@app/models/rule-set';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import type { UserCollection } from '@app/models/user-collection';

  @Component({
    components: { Select2, RuleBuilderPanel },
  })
  export default class UserCollectionSelector extends Vue {
    @Model('input') userCollection?: Partial<UserCollection>;
    @Prop(Boolean) readonly withQuickCollection?: boolean;
    @Prop(Object) readonly triggeringInjectableArguments?: Partial<InjectableArguments>;
    @Prop(String) readonly triggeringConceptName?: string;
    @Prop(String) readonly label!: string;
    @Prop(Boolean) readonly horizontal?: boolean;

    userCollections: UserCollection[] = [];
    collectionNameId: string = generateUUID();
    openRuleBuilderInEditMode = false;

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getUserCollections(
        { filters: { global: true }, only: ['active', 'id', 'name', 'global', { rule_set: ['rules_description'] }] },
        { cache: true }
      );
      this.userCollections = data;
    }

    onInput(input: string): void {
      if (input === 'quick_user_collection') {
        this.emit({ global: false });
        this.openRuleBuilderInEditMode = true;
      } else {
        this.emit(this.userCollectionsHash[parseInt(input)] || {});
      }
    }

    emit(userCollection: Partial<UserCollection>): void {
      this.$emit('input', userCollection);
    }

    get selectedOption(): string | undefined {
      if (this.isQuickUserCollection) return 'quick_user_collection';

      return this.userCollection?.id?.toString();
    }

    onBuilderChange(value: QueryBuilderBlob): void {
      this.emit({
        ...this.userCollection,
        rule_set: {
          query_builder_blob: value,
        } as RuleSet,
      });
    }

    get isQuickUserCollection(): boolean {
      return !isEmpty(this.userCollection) && !this.userCollection?.global;
    }

    get userCollectionsAsOptions(): [string, string][] {
      return this.userCollections.map((uc) => {
        const name = uc.active ? uc.name : this.$t('app.labels.archived_name', { name: uc.name });
        return [uc.id.toString(), name];
      });
    }

    get userCollectionsHash(): Record<number, Partial<UserCollection>> {
      return this.userCollections.reduce((memo, uc) => ({ ...memo, [uc.id]: uc }), {});
    }

    get userCollectionOptions(): [string, string][] {
      if (this.withQuickCollection) {
        return [
          ...this.userCollectionsAsOptions,
          ['quick_user_collection', this.$t('tenant.admin.events.form_fields.quick_user_collection').toString()],
        ];
      }

      return this.userCollectionsAsOptions;
    }

    get labelClass(): Record<string, boolean> {
      return {
        'col-sm-3': !!this.horizontal,
        'control-label': true,
      };
    }

    get inputWrapperClasses(): Record<string, boolean> {
      return {
        'col-sm-9': !!this.horizontal,
      };
    }

    get inputClass(): Record<string, boolean> {
      return {
        'form-control': true,
      };
    }
  }
