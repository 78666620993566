
  import { omit } from 'lodash';
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import RoleProfileForm from '@app/components/role-profile/role-profile-form.vue';
  import { showArchiveConfirmation } from '../../role-profile/utils';
  import type { ModuleName } from '@app/models/module-name';
  import type { RoleProfile } from '@app/models/role-profile';
  import { RecordActivityExtendedPermissions } from '@app/models/extended-permission';
  import { ROLE_PROFILE_ADMIN_RECORD_CODE } from '@app/models/role-profile';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      DsModal,
      RoleProfileForm,
    },
  })
  export default class RoleProfileFormModal extends Vue {
    @Ref() readonly roleProfileForm?: RoleProfileForm;
    @Prop(Number) readonly roleProfileId?: number;
    @Prop(Number) readonly moduleNameId!: number;

    roleProfile: Nullable<Partial<RoleProfile>> = null;
    visible = true;
    loading = true;
    moduleName: Nullable<ModuleName> = null;

    get title(): string {
      if (this.roleProfileId) {
        return this.$t('app.labels.edit_role_profile');
      }
      return this.$t('app.labels.add_role_profile');
    }

    submitForm(roleProfile: Partial<RoleProfile>): void {
      const params = {
        ...omit(roleProfile, 'permissions'),
        permission_ids: roleProfile.permissions?.map((p) => p.id),
      };
      const [promise, message] = roleProfile?.id
        ? [this.$api.updateRoleProfile(Number(roleProfile?.id), params), this.$t('tenant.admin.role_profiles.form.updated')]
        : [this.$api.createRoleProfile(params), this.$t('tenant.admin.role_profiles.form.created')];

      promise
        .then(() => {
          this.toasterAndClose(message);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
        });
    }

    updateRoleProfileAndClose(roleProfileId: number, params: Partial<RoleProfile>, message: string): void {
      this.$api
        .updateRoleProfile(roleProfileId, params)
        .then(() => {
          this.toasterAndClose(message);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
        });
    }

    toasterAndClose(message: string): void {
      toaster(message);
      this.closeModal('reload');
    }

    archive(): void {
      showArchiveConfirmation().then((confirmed) => {
        if (!this.roleProfileForm?.form?.id) return;

        confirmed &&
          this.updateRoleProfileAndClose(
            this.roleProfileForm.form.id,
            { active: false },
            this.$t('tenant.admin.role_profiles.form.archived')
          );
      });
    }

    restore(): void {
      if (!this.roleProfileForm?.form?.id) return;

      this.updateRoleProfileAndClose(this.roleProfileForm.form.id, { active: true }, this.$t('tenant.admin.role_profiles.form.restored'));
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-role-profiles',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(this.moduleNameId, { only: ['id', 'name'] }, { cache: true });
      this.moduleName = data;

      if (this.roleProfileId) {
        const { data: roleProfile } = await this.$api.getRoleProfile(
          Number(this.roleProfileId),
          { include: ['extended_permissions', 'permissions', 'module_name', 'dashboard_pane_ids'] },
          { cache: true }
        );
        this.roleProfile = roleProfile;
      } else {
        this.roleProfile = {
          managerial_hierarchy_access: true,
          module_name_id: this.moduleName.id,
          module_name: this.moduleName,
          extended_permissions: Object.values(RecordActivityExtendedPermissions).map((name) => ({
            concept_name: this.moduleName?.name as string,
            name,
          })),
        };
      }
    }

    get adminRecordCode(): boolean {
      return this.roleProfile?.code === ROLE_PROFILE_ADMIN_RECORD_CODE;
    }

    get canEdit(): boolean {
      return !!this.roleProfile?.id;
    }
  }
