
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import CommentsList from '@app/components/comment/comments-list.vue';
  import bootbox from 'bootbox';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import AttachmentItem from '../attachment/attachment-item.vue';
  import SidePanel from '../side-panel.vue';
  import CommentFormModal from './comment-form-modal.vue';
  import type { AxiosPromise } from 'axios';
  import type { SubmitCommentEvent } from '@app/services/api/comments-api';
  import type { Comment } from '@app/models/comment';
  import type { ModuleName } from '@app/models/module-name';
  import { SpecialPermissionName } from '@app/models/extended-permission';

  @Component({ components: { CommentsList, AttachmentItem, CommentFormModal, SidePanel } })
  export default class CommentsPanel extends Vue {
    @Prop(Boolean) canEdit?: boolean;
    @Prop(String) entityType!: string;
    @Prop(Number) entityId!: number;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'name' | 'show_options'>;

    comments: Comment[] = [];
    modalVisible = false;
    loading = false;
    submitting = false;

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    deleteComment(comment: Comment): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.shared.comments_and_relations.delete_this_comment'),
        callback: (result: boolean) => {
          result &&
            this.$api.deleteComment(comment.id).then(() => {
              this.$api.cache.clear();
              this.fetchComments();
            });
        },
      });
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get canAddComments() {
      return (
        this.canEdit ||
        this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.allow_commenting_without_edit, this.moduleName.name)
      );
    }

    submitForm({ data }: SubmitCommentEvent): void {
      if (!data.comment) return;

      this.submitting = true;
      this.$api
        .createComment({
          comment: data.comment,
          attachments: data.attachments,
          commentable_id: this.entityId,
          commentable_type: this.entityType,
        })
        .then(() => {
          this.toggleModal(false);
          this.$api.cache.clear();
          return this.fetchComments();
        })
        .finally(() => (this.submitting = false));
    }

    fetchComments(): AxiosPromise<Comment[]> {
      this.loading = true;
      return this.$api
        .getComments({
          filters: {
            commentable_id: this.entityId,
            commentable_type: this.entityType,
          },
          include: ['user', 'attachments'],
          sort: '-created_at',
        })
        .then((res) => {
          this.comments = res.data;
          return res;
        })
        .finally(() => (this.loading = false));
    }

    mounted(): void {
      this.fetchComments();
    }
  }
