
  import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
  import type {
    Components,
    Config,
    CurrentTab,
    DisplayItemValue,
    DragAndDropFormulaObject,
    ElementType,
    Formula,
    FormulaErrors,
    RuleBuilderModes,
    Rules,
    Validation,
    VariableMap,
    Variables,
    ComponentRuleOrVariable,
    BasicObject,
    ValidPrefixes,
    FormulaElement,
    LogicSetDefinitions,
    TemplateItemObject,
  } from '../models/types';
  import { LogicElementTypes } from '../models/types';
  import FormulaEditor from './formula-editor.vue';
  import FormulaOptions from './formula-options.vue';
  import { uniq } from 'lodash';
  import type { AxiosPromise } from 'axios';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { Dictionary } from '@app/models/dictionary';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';

  @Component({ components: { FormulaEditor, FormulaOptions } })
  export default class LogicSetEditor extends Vue {
    @Prop({ required: true }) readonly logicSetId!: string;
    @Prop({ required: true }) readonly calcValueSubFormQuestions!: SubFormQuestion[];
    @Prop({ required: true }) readonly displayItems!: DisplayItemValue[];
    @Prop({ required: true }) readonly toggleDisplayItem!: (elementId: DisplayItemValue) => void;
    @Prop({ required: true }) readonly variableMapping!: VariableMap;
    @Prop({ required: true }) readonly configs!: Config;
    @Prop({ required: true }) readonly updateConfigs!: (name: string, checked: boolean) => void;
    @Prop() readonly savePending?: boolean;
    @Prop({ required: true }) readonly updateSavePending!: (state: boolean, callback?: () => void) => void;
    @Prop() readonly textEditMode?: boolean;
    @Prop({ required: true }) readonly switchPage!: (mode: RuleBuilderModes) => void;
    @Prop({ required: true }) readonly getElementType!: (logicElementValue: string | Formula) => ElementType;
    @Prop({ required: true }) readonly archiveFormula!: (logicSetId: string, callback: () => void) => void;
    @PropSync('logicSetDefinitions') syncedLogicSetDefinitions!: LogicSetDefinitions;
    @Prop({ required: true }) readonly setFormulas!: (id: string, logicSetDefinitions: LogicSetDefinitions) => void;
    @Prop({ required: true }) readonly confirmChangeTab!: (callback: () => void) => void;
    @Prop({ required: true }) readonly updateVariableMapping!: (value: string, name: string) => void;
    @Prop({ required: true }) readonly removeDisplayItem!: (elementId: DisplayItemValue) => void;

    refreshFormulaEditorComponentKey = 0;
    formulaErrors: FormulaErrors = {
      parentRule: [],
      rules: {},
      components: {},
    };

    currentTab: CurrentTab = {
      type: LogicElementTypes.PARENT_RULE,
      value: '',
    };

    currentName = '';
    currentFormula: Formula = [];
    currentColor = '';
    validation: Validation = {
      name: [],
      formula: [],
    };
    saving = false;

    saveApiCall(): AxiosPromise {
      const logicSetToUpdate = {
        logic_sets: {
          definitions: JSON.stringify({
            parentRule: this.syncedLogicSetDefinitions.parentRule,
            rules: this.syncedLogicSetDefinitions.rules,
            components: this.syncedLogicSetDefinitions.components,
            variables: this.syncedLogicSetDefinitions.variables,
            configs: this.configs,
          }),
        },
      };

      return this.$api.updateLogicSet(this.logicSetId, logicSetToUpdate);
    }

    saveElementOrdering(): void {
      this.saveApiCall().catch((error) => {
        console.error(error);
      });
    }

    saveApiCallChangeTab(tabType: CurrentTab['type'], tabValue: CurrentTab['value']): void {
      this.saveApiCall()
        .then((response) => {
          this.setFormulas(response.data.id, response.data.definitions);
          this.updateSavePending(false, () => this.changeTab(tabType, tabValue));
          this.saving = false;
        })
        .catch((error) => {
          this.saving = false;
          console.error(error);
        });
    }

    saveParentRule(): void {
      const parentRule = objectCloneDeep(this.syncedLogicSetDefinitions.parentRule);
      parentRule.name = this.currentName;
      parentRule.formula = this.currentFormula;
      parentRule.color = this.currentColor;

      this.syncedLogicSetDefinitions.parentRule = parentRule;

      this.$nextTick(() => {
        this.saveApiCallChangeTab(LogicElementTypes.PARENT_RULE, '');
        this.runAllFormulaValidations();
      });
    }

    saveNewLogic(elementType: ComponentRuleOrVariable): void {
      const elementTypePluralized = this.resolvePluralizedType(elementType);
      const elementTypePrefix = this.getElementTypePrefix(elementType);
      const elementTypeObjects = this.syncedLogicSetDefinitions[elementTypePluralized];

      // set unique ID
      const ids = Object.keys(elementTypeObjects || {}).map((elementId) => parseInt(elementId.substr(1)));
      const newId = ids.length ? Math.max(...ids) + 1 : 0;
      const index = Object.keys(elementTypeObjects || {}).length;

      const logics = objectCloneDeep(elementTypeObjects);
      logics[elementTypePrefix + newId] = {
        name: this.currentName,
        color: this.currentColor,
        index,
      };
      if (elementType !== LogicElementTypes.VARIABLE) logics[elementTypePrefix + newId].formula = this.currentFormula;

      this.syncedLogicSetDefinitions[elementTypePluralized] = logics;

      this.saveApiCallChangeTab(elementType, elementTypePrefix + newId);
      this.runAllFormulaValidations();
    }

    saveExistingLogic(elementType: ComponentRuleOrVariable): void {
      const elementTypePluralized = this.resolvePluralizedType(elementType);

      const logics = objectCloneDeep(this.syncedLogicSetDefinitions[elementTypePluralized]);
      logics[this.currentTab.value].name = this.currentName;
      logics[this.currentTab.value].color = this.currentColor;
      if (elementType !== LogicElementTypes.VARIABLE) logics[this.currentTab.value].formula = this.currentFormula;

      this.syncedLogicSetDefinitions[elementTypePluralized] = logics;
      this.saveApiCallChangeTab(elementType, this.currentTab.value);
      this.runAllFormulaValidations();
    }

    saveChanges(dragAndDropFormulaLogicElements: Formula): void {
      this.saving = true;

      this.$nextTick(() => {
        const logicElements = objectCloneDeep(dragAndDropFormulaLogicElements);
        const currentFormula = this.convertDragAndDropFormulaToArray(logicElements);

        this.currentFormula = currentFormula;

        this.$nextTick(() => {
          if (this.runValidations()) {
            switch (this.currentTab.type) {
              case LogicElementTypes.PARENT_RULE:
                this.saveParentRule();
                break;
              case LogicElementTypes.NEW_RULE:
                this.saveNewLogic(LogicElementTypes.RULE);
                break;
              case LogicElementTypes.RULE:
                this.saveExistingLogic(LogicElementTypes.RULE);
                break;
              case LogicElementTypes.NEW_COMPONENT:
                this.saveNewLogic(LogicElementTypes.COMPONENT);
                break;
              case LogicElementTypes.COMPONENT:
                this.saveExistingLogic(LogicElementTypes.COMPONENT);
                break;
              case LogicElementTypes.NEW_VARIABLE:
                this.saveNewLogic(LogicElementTypes.VARIABLE);
                break;
              case LogicElementTypes.VARIABLE:
                this.saveExistingLogic(LogicElementTypes.VARIABLE);
                break;
              default:
                break;
            }
          } else {
            this.saving = false;
          }
        });
      });
    }

    changeTab(tabType: CurrentTab['type'], tabValue: CurrentTab['value']): void {
      this.confirmChangeTab(() => {
        this.currentTab = {
          type: tabType,
          value: tabValue,
        };

        this.$nextTick(() => {
          this.updateSavePending(this.newObject, () => this.setCurrentNameFormulaColor());
        });
      });
    }

    setCurrentNameFormulaColor(): void {
      this.validation = { name: [], formula: [] };
      const newLogic: Omit<BasicObject, 'index'> = {
        name: '',
        color: '#000000',
      };
      let selectedLogic;

      switch (this.currentTab.type) {
        case LogicElementTypes.PARENT_RULE:
          selectedLogic = this.syncedLogicSetDefinitions.parentRule;
          break;
        case LogicElementTypes.RULE:
          selectedLogic = this.syncedLogicSetDefinitions?.rules?.[this.currentTab.value];
          break;
        case LogicElementTypes.COMPONENT:
          selectedLogic = this.syncedLogicSetDefinitions?.components?.[this.currentTab.value];
          break;
        case LogicElementTypes.VARIABLE:
          selectedLogic = this.syncedLogicSetDefinitions?.variables?.[this.currentTab.value];
          break;
        default:
          selectedLogic = newLogic;
          break;
      }
      this.currentName = selectedLogic.name;
      this.currentColor = selectedLogic.color;
      this.currentFormula = selectedLogic.formula || [];
      this.refreshFormulaEditorComponentKey = this.refreshFormulaEditorComponentKey + 1;
      this.runValidations(true);
    }

    convertAndUpdateCurrentFormula(
      dragAndDropFormulaLogicElements: { id: number; value: string | Formula }[],
      refreshFormulaEditorComponent: boolean
    ): void {
      const logicElements = objectCloneDeep(dragAndDropFormulaLogicElements);
      const currentFormula = this.convertDragAndDropFormulaToArray(logicElements);

      if (!this.newObject) this.updateSavePending(this.formulaSavePending(currentFormula));

      this.currentFormula = currentFormula;

      const validation = objectCloneDeep(this.validation);
      this.formulaValidations(validation);
      this.validation = validation;
      if (refreshFormulaEditorComponent) this.refreshFormulaEditorComponentKey = this.refreshFormulaEditorComponentKey + 1;
    }

    formulaSavePending(currentFormula: Formula): boolean {
      let beforeFormula;

      switch (this.currentTab.type) {
        case LogicElementTypes.PARENT_RULE:
          beforeFormula = this.syncedLogicSetDefinitions.parentRule;
          break;
        case LogicElementTypes.RULE:
          beforeFormula = this.syncedLogicSetDefinitions?.rules?.[this.currentTab.value];
          break;
        case LogicElementTypes.COMPONENT:
          beforeFormula = this.syncedLogicSetDefinitions?.components?.[this.currentTab.value];
          break;
        case LogicElementTypes.VARIABLE:
          return false;
        default:
          break;
      }

      beforeFormula = beforeFormula?.formula;

      return JSON.stringify(beforeFormula) !== JSON.stringify(currentFormula);
    }

    convertDragAndDropFormulaToArray(dragAndDropFormulaLogicElements: { id: number; value: unknown }[]): Formula {
      const newDragAndDropFormulaLogicElements: Formula = [];
      for (let i = 0; i < dragAndDropFormulaLogicElements.length; i++) {
        if ((dragAndDropFormulaLogicElements[i].value as FormulaElement).constructor != Array) {
          newDragAndDropFormulaLogicElements.push(dragAndDropFormulaLogicElements[i].value as FormulaElement);
        } else {
          newDragAndDropFormulaLogicElements.push(
            this.convertDragAndDropFormulaToArray(dragAndDropFormulaLogicElements[i].value as { id: number; value: Formula }[])
          );
        }
      }
      return newDragAndDropFormulaLogicElements;
    }

    runAllFormulaValidations(): void {
      const formulaErrors: FormulaErrors = {
        parentRule: [],
        rules: {},
        components: {},
      };

      if (this.syncedLogicSetDefinitions?.parentRule?.formula) {
        if (
          this.syncedLogicSetDefinitions.parentRule.formula &&
          this.includesInvalidElement(this.syncedLogicSetDefinitions.parentRule.formula)
        ) {
          formulaErrors.parentRule.push('invalid element(s) present');
        }
        if (this.checkFormulaDependencies('', this.syncedLogicSetDefinitions.parentRule.formula, []).length) {
          formulaErrors.parentRule.push('circular formula reference');
        }
      }

      Object.keys(this.syncedLogicSetDefinitions.rules || {}).map((elementId) => {
        formulaErrors.rules[elementId] = [];

        if (this.includesInvalidElement(this.syncedLogicSetDefinitions.rules[elementId]?.formula || [])) {
          formulaErrors.rules[elementId].push('invalid element(s) present');
        }
        if (this.checkFormulaDependencies(elementId, this.syncedLogicSetDefinitions.rules[elementId]?.formula || [], []).length) {
          formulaErrors.rules[elementId].push('circular formula reference');
        }
      });

      Object.keys(this.syncedLogicSetDefinitions.components || {}).map((elementId) => {
        formulaErrors.components[elementId] = [];
        if (this.includesInvalidElement(this.syncedLogicSetDefinitions.components[elementId]?.formula || [])) {
          formulaErrors.components[elementId].push('invalid element(s) present');
        }
        if (this.checkFormulaDependencies(elementId, this.syncedLogicSetDefinitions.components[elementId]?.formula || [], []).length) {
          formulaErrors.components[elementId].push('circular formula reference');
        }
      });

      this.formulaErrors = formulaErrors;
    }

    includesInvalidElement(formula: Formula): boolean {
      return formula.some((formulaElement: string | Formula) => {
        let includesInvalidElement;

        if (this.getElementType(formulaElement) === LogicElementTypes.BRACKET) {
          includesInvalidElement = this.includesInvalidElement(formulaElement as Formula);
        } else {
          includesInvalidElement = this.getElementType(formulaElement) === LogicElementTypes.INVALID;
        }

        if (includesInvalidElement) return true;
        return false;
      });
    }

    runValidations(initialLoad?: boolean): boolean {
      const validation = objectCloneDeep(this.validation);

      if (!initialLoad) this.nameValidations(validation);
      this.formulaValidations(validation);

      this.validation = validation;
      return validation.name.length === 0 && validation.formula.length === 0;
    }

    nameValidations(validationObject: Validation): void {
      validationObject.name = [];

      if (!this.currentName) validationObject.name.push('Name cannot be blank.');

      if (this.checkDuplicateName(LogicElementTypes.PARENT_RULE)) {
        validationObject.name.push('A parent rule with this name already exists.');
      }
      if (this.checkDuplicateName(LogicElementTypes.COMPONENT)) validationObject.name.push('A component with this name already exists.');
      if (this.checkDuplicateName(LogicElementTypes.RULE)) validationObject.name.push('A rule with this name already exists.');
      if (this.checkDuplicateName(LogicElementTypes.VARIABLE)) validationObject.name.push('A variable with this name already exists.');
    }

    formulaValidations(validationObject: Validation): void {
      validationObject.formula = [];

      // ensure a rule/component does not bring in another component which
      // the definition relies on itself resulting in an infinity loop
      const dependenciesArray = this.checkFormulaDependencies(this.currentTab.value, this.currentFormula, []);

      const dependenciesArrayToName = dependenciesArray.map((dependency) => {
        return this.getElementType(dependency) === LogicElementTypes.COMPONENT
          ? this.syncedLogicSetDefinitions?.components?.[dependency]?.name
          : this.syncedLogicSetDefinitions?.rules?.[dependency]?.name;
      });

      if (dependenciesArrayToName.length > 0) {
        validationObject.formula.push('The following element(s) contain circular references: ' + uniq(dependenciesArrayToName).join(', '));
      }

      if (this.anyComponent && this.ruleInsideFormula(this.currentFormula)) {
        validationObject.formula.push('A component may not include a rule');
      }
    }

    checkDuplicateName(objectType: ComponentRuleOrVariable | LogicElementTypes.PARENT_RULE): boolean {
      if (objectType === LogicElementTypes.PARENT_RULE) {
        return (
          this.syncedLogicSetDefinitions.parentRule?.name === this.currentName && this.currentTab.type !== LogicElementTypes.PARENT_RULE
        );
      } else {
        const objectTypePluralized = this.resolvePluralizedType(objectType);
        const componentsOrRulesObject = this.syncedLogicSetDefinitions[objectTypePluralized];

        let duplicate = false;
        const componentsOrRulesObjectKeys = Object.keys(componentsOrRulesObject || {});
        for (let i = 0; i < componentsOrRulesObjectKeys.length; i++) {
          const key = componentsOrRulesObjectKeys[i];
          const elementName = componentsOrRulesObject?.[key]?.name;
          if (elementName === this.currentName) {
            duplicate = true;
            break;
          }
        }

        if (this.newObject) {
          return duplicate;
        } else {
          if (this.currentTab.type === LogicElementTypes.PARENT_RULE) {
            return this.currentName !== this.syncedLogicSetDefinitions.parentRule?.name && duplicate;
          } else if (this.componentRuleOrVariable(this.currentTab.type)) {
            const currentTabPluralized = this.resolvePluralizedType(this.currentTab.type as ComponentRuleOrVariable);
            return this.currentName !== this.syncedLogicSetDefinitions[currentTabPluralized][this.currentTab.value]?.name && duplicate;
          } else {
            return false;
          }
        }
      }
    }

    ruleInsideFormula(formula: Formula): boolean {
      formula.forEach((element) => {
        let ruleInsideFormula;
        if (element.constructor === Array) {
          ruleInsideFormula = this.ruleInsideFormula(element);
        } else {
          ruleInsideFormula = this.getElementType(element) === LogicElementTypes.RULE;
        }

        if (ruleInsideFormula) return true;
      });

      return false;
    }

    get anyComponent(): boolean {
      return this.currentTab.type === LogicElementTypes.COMPONENT || this.currentTab.type === LogicElementTypes.NEW_COMPONENT;
    }

    get newObject(): boolean {
      return (
        this.currentTab.type === LogicElementTypes.NEW_RULE ||
        this.currentTab.type === LogicElementTypes.NEW_COMPONENT ||
        this.currentTab.type === LogicElementTypes.NEW_VARIABLE
      );
    }

    // return an array of elementIds from the formula where the currentItem is already defined inside
    checkFormulaDependencies(currentItem: string, formula: Formula, dependenciesArray: string[]): string[] {
      let newDependenciesArray: string[] = [];

      for (let i = 0; i < formula.length; i++) {
        if (this.getElementType(formula[i]) === LogicElementTypes.BRACKET) {
          newDependenciesArray = newDependenciesArray.concat(
            this.checkFormulaDependencies(currentItem, formula[i] as Formula, dependenciesArray)
          );
        } else {
          const dependencyItem = this.getDependencyItem(currentItem, formula[i]);
          if (dependencyItem && typeof dependencyItem != 'boolean') newDependenciesArray.push(dependencyItem as string);
        }
      }

      return newDependenciesArray;
    }

    getDependencyItem(currentItem: FormulaElement, formulaOrItem: unknown, lastItem?: FormulaElement): boolean | FormulaElement {
      if (formulaOrItem) {
        const formulaOrItemArray = (formulaOrItem as Formula)?.constructor === Array ? formulaOrItem : [formulaOrItem];

        const elements = formulaOrItemArray as Formula;
        for (let index = 0; index < elements.length; index += 1) {
          const element = elements[index];
          if (element === currentItem || element === lastItem) return element;

          const elementType = this.getElementType(element);
          let nestedDependencyCheck;

          switch (elementType) {
            case LogicElementTypes.COMPONENT:
              nestedDependencyCheck = this.getDependencyItem(
                currentItem,
                this.syncedLogicSetDefinitions.components[element as string]?.formula,
                element
              );
              break;
            case LogicElementTypes.RULE:
              nestedDependencyCheck = this.getDependencyItem(
                currentItem,
                this.syncedLogicSetDefinitions.rules[element as string]?.formula,
                element
              );
              break;
            case LogicElementTypes.BRACKET:
              nestedDependencyCheck = this.getDependencyItem(currentItem, element, element);
              break;
            default:
              break;
          }
          if (nestedDependencyCheck) return element;
        }
      }

      return false;
    }

    generateDragAndDropFormulaObject(): DragAndDropFormulaObject {
      const logicElementsAndId = this.assignIdAndTypeToLogicElements(objectCloneDeep(this.currentFormula), 0);
      const startingId = logicElementsAndId.currentId;
      const parentRuleTemplateItem = objectCloneDeep(this.syncedLogicSetDefinitions.parentRule);
      const ruleTemplateItems = this.generateTemplateItems('rules');
      const componentTemplateItems = this.generateTemplateItems('components');
      const variableTemplateItems = this.generateTemplateItems('variables');
      const logicElements = logicElementsAndId.logicElementsArray;

      return {
        startingId,
        parentRuleTemplateItem,
        ruleTemplateItems,
        componentTemplateItems,
        variableTemplateItems,
        logicElements,
      };
    }

    assignIdAndTypeToLogicElements(
      logicElementsArray: Formula,
      startingId: number
    ): {
      currentId: number;
      logicElementsArray: { id: number; value: Formula }[];
    } {
      const updatedLogicElements = objectCloneDeep(logicElementsArray);
      let currentId = startingId;

      for (let i = 0; i < updatedLogicElements.length; i++) {
        const logicElementValue = updatedLogicElements[i];

        updatedLogicElements[i] = { id: currentId, value: logicElementValue };
        currentId++;

        if (logicElementValue.constructor === Array) {
          const updatedLogicElementsArrayAndCurrentId = this.assignIdAndTypeToLogicElements(updatedLogicElements[i].value, currentId);
          currentId = updatedLogicElementsArrayAndCurrentId.currentId;
          updatedLogicElements[i].value = updatedLogicElementsArrayAndCurrentId.logicElementsArray;
        }
      }

      return {
        logicElementsArray: updatedLogicElements,
        currentId,
      };
    }

    generateTemplateItems(componentsRulesOrVariables: 'rules' | 'components' | 'variables'): Dictionary<TemplateItemObject> {
      const templateItems: Dictionary<TemplateItemObject> = {};
      const componentsRulesOrVariablesObject = this.syncedLogicSetDefinitions[componentsRulesOrVariables];

      Object.entries(componentsRulesOrVariablesObject).forEach(([key, value]) => {
        if (value) {
          templateItems[key] = {
            name: value.name,
            color: value.color,
            index: value.index,
            canDrag: true,
          };
        }
      });

      return templateItems;
    }

    getElementTypePrefix(elementType: ComponentRuleOrVariable): ValidPrefixes {
      if (elementType === LogicElementTypes.COMPONENT) {
        return '@';
      } else if (elementType === LogicElementTypes.VARIABLE) {
        return '#';
      } else {
        return '~';
      }
    }

    deleteLogic(elementId: string): void {
      const elementType = this.getElementType(elementId);

      if (this.componentRuleOrVariable(elementType)) {
        const elementTypePluralized = this.resolvePluralizedType(elementType as ComponentRuleOrVariable);
        const deleteConfirmation = confirm('Are you sure to delete this ' + elementType + '?');
        if (!deleteConfirmation) return;

        const dependencies = this.deletionDependencyCheck(elementId);

        if (dependencies.length) {
          alert('You must remove this ' + elementType + ' from the elements below before proceeding:\n\n' + dependencies.join('\n'));
        } else {
          let elementsObject = this.selectElementsObject(elementType as ComponentRuleOrVariable);

          this.removeDisplayItem(elementId);
          if (elementType === LogicElementTypes.VARIABLE) this.updateVariableMapping('none', elementId);

          if (elementsObject) {
            elementsObject = this.shiftOtherElementsOrderOnDeletion(elementId, elementsObject);
            delete elementsObject[elementId];

            (this.syncedLogicSetDefinitions[elementTypePluralized] = elementsObject),
              elementId === this.currentTab.value
                ? this.saveApiCallChangeTab(LogicElementTypes.PARENT_RULE, '')
                : this.saveApiCallChangeTab(this.currentTab.type, this.currentTab.value);
          }
        }
      }
    }

    deletionDependencyCheck(elementId: string): string[] {
      const elementType = this.getElementType(elementId);

      const dependencies = [];

      if (
        elementType === LogicElementTypes.RULE ||
        elementType === LogicElementTypes.COMPONENT ||
        elementType === LogicElementTypes.VARIABLE
      ) {
        if (this.checkFormulaDependencies(elementId, this.syncedLogicSetDefinitions.parentRule.formula, []).indexOf(elementId) > -1) {
          dependencies.push('- ' + this.syncedLogicSetDefinitions.parentRule?.name + ' (Parent Rule)');
        }

        Object.keys(this.syncedLogicSetDefinitions.rules || {}).map((ruleId) => {
          if (
            this.checkFormulaDependencies(elementId, this.syncedLogicSetDefinitions.rules[ruleId]?.formula || [], []).indexOf(elementId) >
            -1
          ) {
            dependencies.push('- ' + this.syncedLogicSetDefinitions.rules[ruleId]?.name + ' (Rule)');
          }
        });
      }
      if (elementType === LogicElementTypes.COMPONENT || elementType === LogicElementTypes.VARIABLE) {
        Object.keys(this.syncedLogicSetDefinitions.components || {}).map((componentId) => {
          if (
            this.checkFormulaDependencies(elementId, this.syncedLogicSetDefinitions.components[componentId]?.formula || [], []).indexOf(
              elementId
            ) > -1
          ) {
            dependencies.push('- ' + this.syncedLogicSetDefinitions.components[componentId]?.name + ' (Component)');
          }
        });
      }

      return dependencies;
    }

    shiftElementOrder(elementId: string, rightOrLeft: 'right' | 'left'): void {
      const elementType = this.getElementType(elementId);

      if (this.componentRuleOrVariable(elementType)) {
        const elementTypePluralized = this.resolvePluralizedType(elementType as ComponentRuleOrVariable);
        let elementsObject = this.selectElementsObject(elementType as ComponentRuleOrVariable);

        if (elementsObject) {
          const swapElementId = Object.keys(elementsObject).filter((elementIdKey) => {
            return (
              (rightOrLeft === 'right' ? elementsObject[elementIdKey].index - 1 : elementsObject[elementIdKey].index + 1) ===
              elementsObject[elementId].index
            );
          })[0];

          const newIndex = elementsObject[swapElementId].index;
          elementsObject[swapElementId].index = elementsObject[elementId].index;
          elementsObject[elementId].index = newIndex;

          this.syncedLogicSetDefinitions[elementTypePluralized] = elementsObject;

          this.refreshFormulaEditorComponentKey = this.refreshFormulaEditorComponentKey + 1;
          this.saveElementOrdering();
        }
      }
    }

    shiftOtherElementsOrderOnDeletion(elementId: string, elementsObject: Rules | Components | Variables): Rules | Components | Variables {
      const elementIndex = elementsObject[elementId].index;
      const lastIndex = Object.keys(elementsObject).length - 1;

      if (elementIndex === lastIndex) return elementsObject;

      for (let i = elementIndex + 1; i <= lastIndex; i++) {
        const elementId = Object.keys(elementsObject).filter((elementIdKey) => {
          return i === elementsObject[elementIdKey].index;
        })[0];

        elementsObject[elementId].index = elementsObject[elementId].index - 1;
      }

      return elementsObject;
    }

    selectElementsObject(elementType: ComponentRuleOrVariable): Rules | Components | Variables {
      if (elementType === LogicElementTypes.RULE) {
        return objectCloneDeep(this.syncedLogicSetDefinitions.rules);
      } else if (elementType === LogicElementTypes.COMPONENT) {
        return objectCloneDeep(this.syncedLogicSetDefinitions.components);
      } else {
        return objectCloneDeep(this.syncedLogicSetDefinitions.variables);
      }
    }

    resolvePluralizedType(elementType: ComponentRuleOrVariable): 'rules' | 'components' | 'variables' {
      return `${elementType}s`;
    }

    componentRuleOrVariable(elementType: LogicElementTypes): boolean {
      return [LogicElementTypes.COMPONENT, LogicElementTypes.RULE, LogicElementTypes.VARIABLE].includes(elementType);
    }

    mounted(): void {
      this.refreshFormulaEditorComponentKey = this.refreshFormulaEditorComponentKey + 1;
      this.changeTab(LogicElementTypes.PARENT_RULE, '');
      this.runAllFormulaValidations();
    }
  }
