
  import WorkflowConfigurator from '@app/components/admin/workflows/workflow-configurator.vue';
  import Blocking from '@app/mixins/blocking';
  import type { OperatorBlobType } from '@app/models/mixins';
  import { SubFormType } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';
  import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import { toaster } from '@app/utils/toaster';
  import SubFormSettings from '../../../components/sub-form/sub-form-settings.vue';

  const SUB_FORM_EDIT_ONLY_FIELDS = Tuple([
    'id',
    'active',
    'description',
    'title',
    'free_navigation',
    'module_name',
    'score_band_profile_id',
    'scoring',
    'scoring_type',
    'scoring_group_id',
    'scoring_view_option',
    'sub_form_type',
    'system_code',
    'sub_form_questions',
    'options',
    {
      sub_form_lists: ['id', { module_tab: ['id', 'hybrid_module'] }],
      sub_form_sections: ['id', 'sub_form_questions'],
    },
  ] as const);
  @Component({ components: { WorkflowConfigurator, SubFormSettings } })
  export default class AdminSubFormsEdit extends Mixins(Blocking) {
    @Prop({ type: [Number, String] }) readonly moduleTabId!: number | string;
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;
    @Prop({ type: [Number, String] }) readonly subFormId!: number | string;
    @Ref() readonly form?: SubFormSettings;
    subForm: Nullable<Pick<SubForm, FirstLevelKeyLiterals<(typeof SUB_FORM_EDIT_ONLY_FIELDS)[number]>>> = null;
    moduleName: Nullable<ModuleName> = null;

    subFormList: Nullable<SubFormList> = null;

    get isWorkflow(): boolean {
      return this.subForm?.sub_form_type === SubFormType.workflow;
    }

    beforeMount(): void {
      this.fetchModuleName();
      this.fetchSubForm();
    }

    blobChange(blob: { operators: OperatorBlobType }) {
      this.subFormList &&
        this.$api.updateWorkflowBlob({
          entity_type: 'SubFormList',
          entity_id: this.subFormList.id,
          operator_blob: blob.operators,
        });
    }

    async fetchSubForm(): Promise<void> {
      const { data } = await this.$api.getSubForm(
        Number(this.subFormId),
        {
          only: SUB_FORM_EDIT_ONLY_FIELDS,
        },
        { cache: true }
      );
      this.subForm = data;

      if (this.isWorkflow && this.subForm?.id) {
        this.$api
          .getSubFormLists(
            {
              filters: {
                approval_sub_form_id: this.subForm.id,
              },
              include: ['workflows', 'next_links', 'operator_blob'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.subFormList = data[0] || null;
          });
      }
    }

    async fetchModuleName(): Promise<void> {
      const { data } = await this.$api.getModuleName(Number(this.moduleNameId), {}, { cache: true });
      this.moduleName = data;
    }

    onFormSubmit(subForm: SubForm): void {
      this.updateSubForm(subForm);
    }

    updateSubForm(subForm: SubForm): void {
      !this.loading &&
        this.blocking(async () =>
          this.$api
            .updateSubForm(subForm.id, subForm)
            .then(() => {
              this.$api.cache.clear();
              toaster(this.$t('tenant.sub_forms.update'));
              return this.$router.replace({
                name: 'admin-sub-forms-show',
                query: this.$route.query,
              });
            })
            .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
        );
    }
  }
