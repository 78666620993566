
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import LocationSelector from '../location/location-selector.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, LocationSelector } })
  export default class UserActiveLocationsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly user!: Pick<TenantUser, 'location_ids' | 'skip_explicit_location_restrictions'>;

    form: Partial<Pick<TenantUser, 'location_ids' | 'skip_explicit_location_restrictions'>> = {};
    newUserLocationIds: number[] = [];

    get accountStore() {
      return useAccountStore();
    }

    @Emit('submit')
    submit(): Partial<Pick<TenantUser, 'location_ids' | 'skip_explicit_location_restrictions'>> {
      return { ...this.form, location_ids: [...(this.user?.location_ids || []), ...this.newUserLocationIds] };
    }

    initForm(): void {
      this.form = {
        skip_explicit_location_restrictions: this.user.skip_explicit_location_restrictions,
        location_ids: [],
      };
      this.newUserLocationIds = [];
    }

    beforeMount(): void {
      this.initForm();
    }
  }
