
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import { useAccountStore } from '@app/stores/account';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';
  import { Component, Ref } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import { Tooltip } from 'uiv';
  import SearchInput from '@app/components/search-input.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import bootbox from 'bootbox';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import UserActiveOrganizationsModal from '@app/components/user-active-organizations/user-active-organizations-modal.vue';
  import type { AxiosResponse } from 'axios';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Organization } from '@app/models/organization';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';
  const ONLY_OPTIONS = Tuple(['id', 'skip_explicit_organization_restrictions', 'organization_ids', 'home_organization_id'] as const);
  @Component({
    components: {
      FilterCheckbox,
      BaseTable,
      Tooltip,
      SearchInput,
      OrganizationSelector,
      FilterSelect,
      Select2,
      UserActiveOrganizationsModal,
    },
  })
  export default class UserActiveOrganizations extends mixins(WithUser) {
    @Ref() readonly table!: BaseTable<Organization>;
    @Ref() readonly modal?: UserActiveOrganizationsModal;

    form: Partial<Pick<TenantUser, FirstLevelKeyLiterals<(typeof ONLY_OPTIONS)[number]>>> = {};
    manager: Nullable<ListManager<Organization>> = null;
    addOrganizationsModal = false;
    activeOptions: [string, string][] = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
    user: Nullable<TenantUser> = null;

    get accountStore() {
      return useAccountStore();
    }

    getManager(): ListManager<Organization> {
      return new ListManager<Organization>({
        fetchDataFunction: (params) => {
          const idsToFetch = this.form?.organization_ids?.join(',');

          return !!idsToFetch
            ? this.$api.getOrganizations(
                {
                  ...params,
                  filters: {
                    ...params.filters,
                    id: idsToFetch,
                  },
                  only: ['id', 'name', 'active', { organization: ['name'] }],
                },
                { cache: true }
              )
            : new Promise((resolve) => resolve({ data: [] as Organization[] } as AxiosResponse<Organization[]>));
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: 'max-content' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          {
            title: this.$t('tenant.admin.users.sections.tab_organizations.parent_organization'),
            name: 'organization',
            sortField: 'organization.name',
            filter: true,
          },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true, width: '50px' },
          { title: '', name: 'actions' },
        ],
        allowFilters: true,
      });
    }

    organizationLinkEdit(organizationId: string): string {
      return `/admin/settings/organizations/${organizationId}/edit`;
    }

    initForm(user: TenantUser): void {
      this.form = {
        ...this.form,
        skip_explicit_organization_restrictions: user.skip_explicit_organization_restrictions || false,
        organization_ids: user.organization_ids || [],
      };
      this.modal?.initForm();
    }

    organizationName(organization: Pick<Organization, 'id' | 'name'>): string {
      if (organization.id != this.user?.home_organization_id) {
        return organization.name;
      }
      return `${organization.name} (${this.$t('app.labels.home_organization')})`;
    }

    organizationChange(id: number, checked: boolean): void {
      const neworganizationIds = checked
        ? [...(this.form.organization_ids || []), id]
        : this.form.organization_ids?.filter((value) => value !== id);
      this.form = {
        ...this.form,
        organization_ids: neworganizationIds,
      };
    }

    removeOrganization(organization: Organization): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.users.sections.tab_organizations.remove_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.organizationChange(organization.id, false);
            this.submitForm(this.form);
          }
        },
      });
    }

    submitForm(form: Partial<Pick<TenantUser, FirstLevelKeyLiterals<(typeof ONLY_OPTIONS)[number]>>>, fromModal = false): void {
      this.$api
        .updateTenantUser(this.userId, { ...form }, { only: ONLY_OPTIONS })
        .then(({ data }) => {
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_organizations.success'),
            position: 'top-right',
          });
          this.initForm(data);
          this.$api.cache.clear();
          this.table?.reload();
          fromModal && this.toggleModal();
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    toggleModal() {
      this.addOrganizationsModal = !this.addOrganizationsModal;
    }

    beforeMount(): void {
      this.$api.getTenantUser(this.userId, { only: ONLY_OPTIONS }, { cache: true }).then(({ data }) => {
        this.user = data;
        this.initForm(data);
        this.manager = this.getManager();
      });
    }
  }
