
  import { BaseTable } from '@app/components/base-table';
  import { Component, Vue, Prop, Model } from 'vue-property-decorator';
  import type { RoleTabDetailsRoleOnly } from './utils';
  import { getProfilePermissionsBaseOptions } from './utils';
  import Select2 from '@app/components/select2.vue';
  import { upperFirst } from 'lodash';
  import type { ProfilePermission, Role } from '@app/models/role';
  import { ListManagerStatic } from '@app/services/list-manager/list-manager-static';

  type LocalProfilePermission = ProfilePermission & {
    collection?: 'all' | 'team' | 'organization' | '';
    id: 'self' | 'others';
    prettyId: string;
  };

  @Component({
    components: {
      BaseTable,
      Select2,
    },
  })
  export default class UserProfileRights extends Vue {
    @Model('input') readonly value!: Role['profile_permissions'];
    @Prop(Object) readonly role!: Pick<Role, RoleTabDetailsRoleOnly>;
    @Prop(Boolean) readonly disabled?: boolean;

    manager: Nullable<ListManagerStatic<LocalProfilePermission>> = null;
    localData: LocalProfilePermission[] = [];
    profilePermissionsBaseOptions = getProfilePermissionsBaseOptions();

    get collectionOptions(): [string, string][] {
      return [
        ['all', this.$t('app.labels.all')],
        ['team', upperFirst(this.$t('app.labels.team') as string)],
        ['organization', this.$t('app.labels.organization')],
        ['', this.$t('app.labels.none')],
      ];
    }

    onInput(id: 'others' | 'self', key: string, value: string) {
      this.$emit('input', {
        ...this.value,
        [id]: {
          ...this.value?.[id],
          // needed as select2 converts '' to undefined
          [key]: value || '',
        },
      });
    }

    getValueByKeyAndId(id: 'others' | 'self', key: keyof ProfilePermission) {
      return this.value?.[id]?.[key] || '';
    }

    getManager(): ListManagerStatic<LocalProfilePermission> {
      return new ListManagerStatic<LocalProfilePermission>({
        localData: this.localData,
        per_page: -1,
        fields: [
          {
            title: '',
            name: 'prettyId',
            titleClass: 'justify-content-end',
            dataClass: 'd-flex justify-content-end text-bold align-items-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.can_view'),
            name: 'collection',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.basic_user_details'),
            name: 'basic',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.permission_based_details'),
            name: 'permission_based',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('app.labels.tags'),
            name: 'tags',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('app.labels.documents'),
            name: 'documents',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.user_personal_details'),
            name: 'personal_details',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.notifications_subscriptions'),
            name: 'notification_subscriptions',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('tenant.admin.roles.rtb.user_rights.user_financial_details'),
            name: 'pay_details',
            titleClass: 'justify-content-center',
          },
        ],
      });
    }

    getDefaultProfilePermissions() {
      return { self: {}, others: {}, ...this.role.profile_permissions };
    }

    beforeMount() {
      this.localData = Object.entries(this.getDefaultProfilePermissions()).map(([key, values]) => {
        return {
          id: key as 'self' | 'others',
          prettyId:
            key === 'self' ? this.$t('tenant.admin.roles.rtb.user_rights.self') : this.$t('tenant.admin.roles.rtb.user_rights.others'),
          ...values,
        };
      });
      this.manager = this.getManager();
    }
  }
