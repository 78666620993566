
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import TestResultSection from '@app/components/admin/automation-definitions/debug/test-result-section.vue';
  import type { DebugRuleSet } from '@app/services/api/rule-sets-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { AutomationToggle } from '@app/models/automation-toggle';
  import { formatCreatedAt } from '@app/utils/format-created-at';

  @Component({
    components: {
      TestResultSection,
    },
  })
  export default class ToggleHistorySection extends Vue {
    @Prop(Object) readonly testResults!: DebugRuleSet;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;
    @Prop([String, Number]) readonly recordId!: number;
    @Prop(String) readonly recordType!: string;

    toggleHistory: AutomationToggle[] = [];

    formatCreatedAt = formatCreatedAt;

    get skippingEventOptions(): Record<string, string> {
      return {
        null: '',
        scheduler_or_run_time_based_automation: '',
        callback: '',
        automation_updated: 'Event Skipped Reason: Automation Update',
        callback_skipping_event: 'Yes: Skipped Event',
        callback_record_calculation_recalculating: 'Event Skipped Reason: Record Calculations',
        toggles_only: 'Yes: Skipped Event',
      };
    }

    beforeMount() {
      this.loadToggleHistory();
    }

    async loadToggleHistory() {
      const { data: toggleHistory } = await this.$api.getAutomationToggles({
        filters: {
          automation_definition_id: this.automationDefinition.id,
          concept_type: this.recordType,
          concept_id: Number(this.recordId),
        },
      });

      return (this.toggleHistory = toggleHistory);
    }
  }
