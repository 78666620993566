import { render, staticRenderFns } from "./automation-definitions-page.vue?vue&type=template&id=e70f6e8a&scoped=true&lang=pug"
import script from "./automation-definitions-page.vue?vue&type=script&lang=ts"
export * from "./automation-definitions-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e70f6e8a",
  null
  
)

export default component.exports