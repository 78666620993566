
  import { Component, Prop, Vue, Model } from 'vue-property-decorator';
  import { get } from 'lodash';

  type ButtonSelectOption = Record<string, unknown> | string[] | string;

  @Component({})
  export default class ButtonSelect extends Vue {
    @Model('input', { type: [String, Number, Boolean] }) value!: string | number | boolean;
    @Prop(Array) readonly options?: ButtonSelectOption[];
    @Prop([String, Array]) readonly labelKey?: string | string[];
    @Prop([String, Array]) readonly valueKey?: string | string[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly disabled?: boolean;

    optionLabel(option: ButtonSelectOption): string {
      if (typeof option === 'string') {
        return option;
      } else if (Array.isArray(option)) {
        return option[1] || option[0];
      } else {
        return get(option, this.labelKey || 'label');
      }
    }

    optionValue(option: ButtonSelectOption): string | number {
      if (typeof option === 'string') {
        return option;
      } else if (Array.isArray(option)) {
        return option[0];
      } else {
        return get(option, this.valueKey || 'value');
      }
    }
    click(option: ButtonSelectOption): void {
      this.$emit('input', this.optionValue(option));
    }
  }
