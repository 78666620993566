
  import { Component } from 'vue-property-decorator';
  import WithModuleRecordCompletionShow from './with-module-record-completion-show';
  import SubFormCompletionShow from '../sub-form-completion/sub-form-completion-show.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';

  @Component({ components: { SubFormCompletionShow } })
  export default class ModuleRecordTabsCompletion extends mixins(Blocking, WithModuleRecordCompletionShow) {
    beforeMount() {
      this.blocking(async () => {
        await this.fetchModuleRecord();
      });
    }
  }
