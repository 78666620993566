
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import TestResultSection from '@app/components/admin/automation-definitions/debug/test-result-section.vue';
  import type { DebugRuleSet } from '@app/services/api/rule-sets-api';
  import type { RuleSet } from '@app/models/rule-set';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AutomationTriggerOn } from '@app/models/automation-definition';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { groupBy, keyBy, uniq } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  import RuleBuilderPanel from '../../events/rule-builder-panel.vue';

  type TriggeringFieldInfo = {
    code?: string;
    deleted?: boolean;
    link?: string;
    name: string;
    type: 'record' | 'question' | 'indicator';
  };

  @Component({
    components: {
      TestResultSection,
      RuleBuilderPanel,
      DsIconText,
    },
  })
  export default class ConditionSection extends Vue {
    @Prop(Object) readonly testResults!: DebugRuleSet;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;
    @Prop(Object) readonly injectableArguments!: InjectableArguments;

    triggeringFields: TriggeringFieldInfo[] = [];

    get passOptions() {
      const pass = this.testResults?.results?.pass;

      return {
        text: pass ? this.$t('app.labels.pass') : this.$t('app.labels.fail'),
        icon: pass ? 'fa-check-circle' : 'fa-times-circle',
        color: pass ? '#1ebd1e' : '#d9534f',
      };
    }

    get ruleSet(): Nullable<RuleSet> {
      if (this.automationDefinition.rule_set_id && this.automationDefinition.rule_set && this.testResults?.results) {
        return { ...this.automationDefinition.rule_set, query_builder_blob: this.testResults.results };
      }
      return null;
    }

    get automationRuleSetBuilder(): AutomationRuleSetBuilder {
      return {
        concept_name: this.automationDefinition?.concept_name,
        injectable_arguments: this.injectableArguments,
        triggering_concept_name: null,
        for_collection_options: false,
        triggering_injectable_arguments: {},
      };
    }

    get fieldUpdate() {
      return this.automationDefinition.trigger_on === AutomationTriggerOn.FieldUpdate;
    }

    get conditionsMet() {
      return this.automationDefinition.trigger_on === AutomationTriggerOn.ConditionsMet;
    }

    get triggerOnOptions(): Record<string, string> {
      return {
        create: this.$t('tenant.admin.automation_definitions.triggers.on_create'),
        field_update: this.$t('tenant.admin.automation_definitions.triggers.on_update'),
        delete: this.$t('tenant.admin.automation_definitions.triggers.on_delete'),
        conditions_met: this.$t('tenant.admin.automation_definitions.triggers.on_conditions'),
      };
    }

    get triggerWhenOptions(): Record<string, string> {
      return {
        always: 'every time',
        once: 'once',
      };
    }

    beforeMount() {
      if (this.fieldUpdate) {
        this.loadTriggeringFields();
      }
    }

    async loadTriggeringFields() {
      let questionFields: { formId: string; questionCode: string }[] = [];
      let indicatorFields: { indicatorSetCode: string }[] = [];
      let recordFields: { field: string }[] = [];

      this.automationDefinition.triggering_fields.forEach((field) => {
        if (field.startsWith('@form')) {
          const [form, questionCode] = field.split('.');
          const formId = form.replace(/^@form\[/, '').replace(/]$/, '');

          return questionFields.push({ formId, questionCode });
        }

        if (field.startsWith('@indicator')) {
          const indicatorSetCode = field.replace(/^@indicator\[/, '').replace(/]$/, '');

          return indicatorFields.push({ indicatorSetCode });
        }

        return recordFields.push({ field });
      });

      const [
        { data: forms },
        { data: indicators },
        {
          data: [moduleName],
        },
      ] = await Promise.all([
        this.$api.getSubForms(
          {
            filters: { id: uniq(questionFields.map(({ formId }) => formId)) },
            include: ['sub_form_sections', 'sub_form_questions'],
          },
          { cache: true }
        ),
        this.$api.getIndicatorSets(
          { filters: { system_code: indicatorFields.map(({ indicatorSetCode }) => indicatorSetCode) }, include: ['indicators'] },
          { cache: true }
        ),
        await this.$api.getModuleNames(
          {
            filters: { name: this.automationDefinition.concept_name },
            only: ['id'],
          },
          { cache: true }
        ),
      ]);

      const formsById: Record<string, SubForm> = keyBy(forms, 'id');
      const indicatorsByCode: Record<string, IndicatorSet> = keyBy(indicators, 'system_code');

      const questionsByFormAndCode: Record<string, Record<string, SubFormQuestion[]>> = Object.keys(formsById).reduce(
        (acc, k) => ({ ...acc, [k]: groupBy(formsById[k].sub_form_questions, 'code') }),
        {}
      );

      const recordLink = (() => {
        if (this.automationDefinition.sub_form_id && this.automationDefinition.concept_name === 'SubFormCompletion') {
          return `/admin/settings/sub_forms/${this.automationDefinition.sub_form_id}`;
        }

        if (moduleName?.id) {
          return `/admin/settings/module_names/${moduleName?.id}`;
        }

        return;
      })();

      this.triggeringFields = [
        ...recordFields.map(({ field }) => ({
          name: this.recordFieldName(field),
          type: 'record' as TriggeringFieldInfo['type'],
          link: recordLink,
        })),
        ...questionFields.flatMap(({ formId, questionCode }) => {
          const { module_name, description } = formsById[formId] || {};
          const mainForm = module_name === MAIN_FORM_MODULE_NAME;

          return ((questionsByFormAndCode[formId] || {})[questionCode] || [{ title: questionCode, id: null, active: false }]).map(
            ({ title, id, active }) => ({
              name: [mainForm ? null : description, title].filter((v) => !!v).join(': '),
              code: [mainForm || !active ? null : formId, questionCode].filter((v) => !!v).join('.'),
              type: 'question' as TriggeringFieldInfo['type'],
              link: id ? `/admin/settings/sub_form_questions/${id}/edit` : undefined,
              deleted: !active,
            })
          );
        }),
        ...indicatorFields.map(({ indicatorSetCode }) => {
          const indicatorSet = indicatorsByCode[indicatorSetCode];

          return {
            name: `${this.$t('app.labels.indicator_set')}: ${
              indicatorSet?.indicators?.map(({ name }) => name).join(' | ') || indicatorSetCode
            }`,
            code: indicatorSetCode,
            type: 'indicator' as TriggeringFieldInfo['type'],
            link:
              indicatorSet && moduleName?.id
                ? `/admin/settings/module_names/${moduleName?.id}/indicator_sets/${indicatorSet.id}`
                : undefined,
            deleted: !indicatorSet,
          };
        }),
      ];
    }

    recordFieldName(code: string) {
      return (
        {
          workflow: this.$t('app.labels.workflow'),
          stage: this.$t('app.labels.stage'),
        }[code] || code
      );
    }
  }
