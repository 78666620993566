
  import type { Subscription } from 'rxjs';
  import { mergeAll, Subject, groupBy } from 'rxjs';
  import { debounceTime, map } from 'rxjs/operators';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { LocationTagCount } from '@app/models/location-tag-count';
  import { groupBy as lodashGroupBy } from 'lodash';
  import type { UserLocationTag } from '@app/models/user-location-tag';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Location } from '@app/models/location';
  import type { LocationTag } from '@app/models/location-tag';
  import { toaster } from '@app/utils/toaster';

  interface LocationTagCountData {
    count: number | undefined;
    isUpdating?: boolean;
  }

  @Component
  export default class LocationTagCountsForm extends Vue {
    @Prop([String, Number]) readonly locationId!: string | number;

    location: Nullable<Location> = null;
    userLocationTags: UserLocationTag[] = [];
    locationTags: LocationTag[] = [];
    locationTagCounts: LocationTagCount[] = [];
    loading = false;

    maxValue = 2147483647;

    form: Dictionary<LocationTagCountData> = {};

    source$: Subject<{ count: number | string; locationTagId: number }> = new Subject();
    sourceSubscription: Nullable<Subscription> = null;

    get userLocationTagsByTagId(): Dictionary<UserLocationTag[]> {
      return lodashGroupBy(this.userLocationTags, 'location_tag_id');
    }

    beforeMount(): void {
      this.loading = true;
      Promise.all([this.getLocation(), this.getLocationTags()])
        .then(() => Promise.all([this.getUserLocationTags(), this.getLocationTagCounts()]))
        .finally(() => {
          this.loading = false;
          this.initSubscription();
        });
    }

    getLocation(): Promise<Location> {
      return this.$api.getLocation(Number(this.locationId), { only: ['id'] }, { cache: true }).then(({ data }) => {
        this.location = data;
        return data;
      });
    }

    initSubscription(): void {
      this.sourceSubscription = this.source$
        .pipe(
          groupBy((item) => item.locationTagId),
          map((group) => group.pipe(debounceTime(1500))),
          mergeAll()
        )
        .subscribe(({ locationTagId, count }) => {
          this.$set(this.form[locationTagId], 'isUpdating', true);
          this.createOrUpdateLocationTagsCount(locationTagId, +count)
            .then(({ location_tag_id, count }) => {
              this.form = { ...this.form, [location_tag_id]: { count: count || undefined } };
            })
            .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
            .finally(() => {
              this.$set(this.form[locationTagId], 'isUpdating', false);
            });
        });
    }

    beforeDestroy(): void {
      this.sourceSubscription?.unsubscribe();
    }

    createOrUpdateLocationTagsCount(locationTagId: number, dirtyCount: number): Promise<LocationTagCount> {
      const count = dirtyCount > this.maxValue ? this.maxValue : dirtyCount < 0 ? 0 : dirtyCount;
      const locationTagCount = this.findLocationTagCount(locationTagId);
      return locationTagCount ? this.updateLocationTagCount(locationTagCount.id, count) : this.createLocationTagCount(locationTagId, count);
    }

    preventNonNumericInput(event: KeyboardEvent): void {
      if (!/^\d+$/.test(event.key)) {
        event.preventDefault();
      }
    }

    debouncedUpdateOrCreateLocationTagCount(count: number, locationTagId: number): void {
      this.source$.next({ count, locationTagId });
    }

    updateLocationTagCount(locationTagCountId: number, count: number): Promise<LocationTagCount> {
      return this.$api.updateLocationTagCount(locationTagCountId, { count: count || 0 }).then(({ data }) => {
        this.locationTagCounts = this.locationTagCounts.map((ltc) => (ltc.id === data.id ? data : ltc));
        return data;
      });
    }

    createLocationTagCount(tagId: number, count: number): Promise<LocationTagCount> {
      return this.$api
        .createLocationTagCount({
          count,
          location_tag_id: tagId,
          location_id: this.location?.id,
        })
        .then(({ data }) => {
          this.locationTagCounts = [...this.locationTagCounts, data];
          return data;
        });
    }

    findLocationTagCount(locationTagId: string | number): LocationTagCount | undefined {
      return this.locationTagCounts.find((ltc) => `${ltc.location_tag_id}` === `${locationTagId}`);
    }

    getUserLocationTags(): Promise<UserLocationTag[]> {
      return this.$api
        .getUserLocationTags(
          {
            per_page: -1,
            only: ['id', 'location_tag_id'],
            filters: {
              location_tag_id: this.locationTags.map((t) => t.id),
              location_id: this.location?.id,
              user: { active: true },
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.userLocationTags = data;
          return data;
        });
    }

    getLocationTagCounts(): Promise<LocationTagCount[]> {
      if (!this.locationTags.length) {
        return Promise.resolve([]);
      }
      return this.$api
        .getLocationTagCounts(
          {
            per_page: -1,
            only: ['id', 'location_tag_id', 'count', 'location_id'],
            filters: {
              location_tag_id: this.locationTags.map((t) => t.id),
              location_id: this.location?.id,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.locationTagCounts = data;
          this.form = this.locationTags.reduce<Dictionary<LocationTagCountData>>((memo, lt) => {
            const ltc = this.findLocationTagCount(lt.id);
            return { ...memo, [lt.id]: { count: ltc?.count || undefined } };
          }, {});
          return data;
        });
    }

    getLocationTags(): Promise<LocationTag[]> {
      return this.$api
        .getLocationTags(
          {
            per_page: -1,
            only: ['id', 'name'],
            filters: {
              active: true,
              tag_type: 'safety',
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.locationTags = data;
          return data;
        });
    }
  }
