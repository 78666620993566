
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import bootbox from 'bootbox';
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import type { MobileAppConfig } from '@app/models/mobile-app/config';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { BaseTable } })
  export default class ConfigsListPage extends Vue {
    @Ref() readonly table?: BaseTable<MobileAppConfig>;

    manager = new ListManager<MobileAppConfig>({
      fetchDataFunction: (params) => {
        return this.$api.getMobileAppConfigs({ ...params, include: ['module_name'] }, { cache: true });
      },
      useHistory: true,
      per_page: 10,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: 'Name', name: 'name', sortField: 'name' },
        { title: 'Description', name: 'description', sortField: 'description' },
        { title: 'Module', name: 'module_name', sortField: 'module_name' },
        { title: '', name: 'operations' },
      ],
    });

    get currentUserStore() {
      return useCurrentUserStore();
    }

    removeConfig(config: MobileAppConfig): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure that you want to remove app config?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteMobileAppConfig(config.id)
              .then(() => {
                this.$api.cache.clear();
                this.table?.reload();
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
          }
        },
      });
    }

    @Watch('$route.params.reload')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
  }
