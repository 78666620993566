
  import type { ParsedQs } from 'qs';
  import qs from 'qs';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { SuperImport } from '@app/models/super-import';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import ImportForm from './import-form.vue';
  import ImportInstructions from './import-instructions.vue';

  interface TypeOption {
    code: string;
    label: string;
  }

  @Component({ components: { ImportForm, ImportInstructions } })
  export default class AdminImportPage extends Vue {
    @Prop(Array) readonly importOptions?: TypeOption[];
    @Prop(Number) readonly moduleNameId!: number;

    get params(): ParsedQs {
      return qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    get importType(): string | undefined {
      if (this.params.module_name_id) {
        return;
      }

      return (this.params.type as string) || 'User';
    }

    get importFormOptions() {
      if (this.params.module_name_id) {
        // hybrid modules
        return [
          { code: 'TabSection', label: this.$t('app.labels.forms'), model_name: 'tab_section' },
          {
            code: 'TabSectionSubFormQuestion',
            label: this.$t('app.labels.form_questions'),
            model_name: 'tab_section_sub_form_question',
          },
        ];
      }

      return this.importOptions;
    }

    onSubmit(newImport: SuperImport): void {
      this.$api
        .createImport(newImport)
        .then(({}) => {
          changeLocation('/admin/settings/super_imports');
          toaster({
            text: this.$t('tenant.admin.module_names.sections.tab_importer.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
