
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '@app/components/admin/questions/filter-configurator/utils';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { API_NULL, MAIN_FORM_MODULE_NAME } from '@app/constants';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { map } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Ref } from 'vue-property-decorator';
  import RecordFilterConfigurator from '../admin/questions/filter-configurator/record-filter-configurator.vue';
  import EntitySelector from '../entity-selector.vue';
  import Select2 from '../select2.vue';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { Dictionary } from '@app/models/dictionary';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import { FieldType } from '@app/models/sub-form-question';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { Select2Placeholder } from '@app/utils/types/select2-placeholder';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import { CalculationClass } from '@app/models/record-calculation';
  import { STORED_CALCULATION_ALLOWED_FILTERS } from '@app/models/sub-form-list';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';

  @Component({
    components: { RecordFilterConfigurator, EntitySelector, Select2, ValidationProvider, ValidationObserver, FormField },
    methods: { getRecordLinkBehaviourOptions },
  })
  export default class RecordRelationsTabSettings extends SubFormListSettingsBase {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    initialUseInStoredCalculationValue = false;
    relatedQuestion: SubFormQuestion | null = null;
    relatedModuleName: Pick<
      ModuleName,
      FirstLevelKeyLiterals<(typeof WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY)[number]> | 'available_index_options'
    > | null = null;
    recordFilterConfiguratorKey = 0;
    relatedRecordCalculations: RecordCalculation[] = [];
    moduleNamesBySubFormId: Dictionary<string> = {};

    get relatedQuestionFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return {
        field_type: FieldType.main_form_relation,
        config: {
          main_form_id: `${this.moduleName?.id}`,
        },
        sub_form_section: {
          sub_form: {
            module_name: MAIN_FORM_MODULE_NAME,
          },
        },
      };
    }

    get disablingUseInStoredCalculationError(): string {
      const calculationNames = this.relatedRecordCalculations.map((rc) => {
        return `${rc.name}`;
      });

      return `${this.$t('tenant.admin.record_calculation.form.cannot_disable_stored_calculation_flag')} ${calculationNames.join(', ')}`;
    }

    get useInStoredCalculationsEnabled(): boolean {
      return !!this.subFormListSync.use_in_stored_calculations;
    }

    get disablingUseInStoredCalculation(): boolean {
      return (
        this.useInStoredCalculationsEnabled !== this.initialUseInStoredCalculationValue &&
        !this.useInStoredCalculationsEnabled &&
        !!this.relatedRecordCalculations.length
      );
    }

    get hasInvalidOptionFilters(): boolean {
      if (!this.subFormListSync.record_relations_filters || !this.subFormListSync.use_in_stored_calculations) return false;

      const invalidFilters = this.subFormListSync.record_relations_filters
        .map((filter) => filter.key)
        .filter((s) => !s.match(/indicator|workflow/i));

      return !!invalidFilters.length;
    }

    get onlyFilterTypes(): string[] {
      return this.useInStoredCalculationsEnabled ? STORED_CALCULATION_ALLOWED_FILTERS : [];
    }

    get defaultTemplatesFilters(): DonesafeFilterOptions<DefaultTemplate> {
      return {
        active: true,
        module_name_id: this.relatedModuleName?.id,
      };
    }

    get availableIndexOptions(): [string, string][] {
      return map(this.relatedModuleName?.available_index_options || {}, (option, key) => [key, option.name]);
    }

    fetchRelatedRecordCalculations(sub_form_list_ids: number[]): void {
      if (sub_form_list_ids.length) {
        this.$api
          .getRecordCalculations(
            {
              filters: { sub_form_list_ids, calculation_class: CalculationClass.stored },
              only: ['id', 'sub_form_list_ids', 'name', { module_name: ['id', 'display'] }],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.relatedRecordCalculations = data;
          });
      }
    }

    fetchRelatedModuleName(question?: Nullable<SubFormQuestion>): void {
      if (question?.sub_form_section?.sub_form_id) {
        const filters = { sub_form_id: question.sub_form_section.sub_form_id };
        this.$api
          .getModuleNames({ filters, only: ['available_index_options', ...WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY] }, { cache: true })
          .then(({ data }) => {
            this.relatedModuleName = data[0];
          });
      }
    }

    questionItemTemplate(question: Pick<SubFormQuestion, 'id' | 'title' | 'code'>): JQuery {
      return select2ResponseTemplate(question, {
        secondaryAttribute: (question: SubFormQuestion) => {
          if (question.sub_form_section?.sub_form_id) {
            return this.moduleNamesBySubFormId[question.sub_form_section.sub_form_id] || 'Missing module';
          }
        },
        primaryAttribute: (question: SubFormQuestion | Select2Placeholder) => {
          return question.id ? `${question.title} (${question.code})` : (question as Select2Placeholder).text;
        },
      });
    }

    fetchRelatedQuestion(questionId: string | number | undefined): void {
      if (questionId) {
        const only: OnlyOptions<SubFormQuestion> = ['id', 'title', { sub_form_section: ['sub_form_id'] }];
        this.$api.getSubFormQuestion(Number(questionId), { only }, { cache: true }).then(({ data }) => {
          this.relatedQuestion = data;
          this.fetchRelatedModuleName(data);
        });
      } else {
        this.relatedQuestion = null;
      }
    }

    beforeMount(): void {
      this.initialUseInStoredCalculationValue = this.useInStoredCalculationsEnabled;
      this.subFormListSync = {
        relationship_options: {},
        record_relations_filters: [],
        ...this.subFormListSync,
        options: {
          record_link_behaviour: RecordLinkBehaviour.GoToRecord,
          ...this.subFormListSync.options,
        },
      };

      this.fetchRelatedQuestion(this.subFormListSync.record_relation_question_id);
      this.fetchRelatedRecordCalculations([this.subFormListSync.id as number]);

      this.$api
        .getModuleNames(
          {
            per_page: -1,
            filters: { '!sub_form_id': API_NULL },
            only: ['id', 'display', 'sub_form_id'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNamesBySubFormId = data.reduce((memo, moduleName) => {
            return { ...memo, [moduleName.sub_form_id || '']: moduleName.display };
          }, {});
        });
    }
  }
