
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Workflow } from '@app/models/workflow';
  import type { ModuleName } from '@app/models/module-name';
  import { isUserCollection } from '@app/utils/is-user-collection';
  import { isRecordCollection } from '@app/utils/is-record-collection';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  @Component({ components: { EventInputMethodSwitch, Select2, AdvancedAutosuggest, FormField } })
  export default class ChangeWorkflowEventForm extends BaseEventForm<Dictionary<string>> {
    @Prop(Object) readonly injectableArguments!: Dictionary<string>;

    moduleNames: Pick<ModuleName, 'name' | 'id' | 'display'>[] = [];
    workflows: Pick<Workflow, 'name' | 'id' | 'module_name_id'>[] = [];

    targetModuleName = '';
    formReady = false;

    get recordIdOptions(): [string, string][] {
      return (this.flatFieldOptionsRecord?.Concept || [])
        ?.filter((x) => x[0] != '~location_id')
        .filter((x) => x[0] != '~home_location_id')
        .filter((x) => x[0] != '^home_location_id');
    }

    get forUserCollection(): boolean {
      return isUserCollection(this.per);
    }

    get forRecordCollection(): boolean {
      return isRecordCollection(this.per);
    }

    get currentModuleName(): Pick<ModuleName, 'name' | 'id' | 'display'> | undefined {
      return this.moduleNames.find((x) => x.name === this.form.module_name);
    }

    get workflowOptions(): Pick<Workflow, 'name' | 'id' | 'module_name_id'>[] {
      if (!this.currentModuleName?.id) {
        return [];
      }
      return this.workflows.filter((x) => x.module_name_id === this.currentModuleName?.id);
    }

    get forSubForm(): boolean {
      return this.injectableArguments.concept_name === 'SubFormCompletion';
    }

    get defaultRecordId(): string {
      if (this.injectableArguments.relationship_code) {
        return '~from_id'; // Iterated Record: Link To Record
      } else if (this.injectableArguments.involvement_id) {
        return '~record_id'; // Iterated Record: Link To Record
      } else if (this.forUserCollection) {
        return ''; // cause custom input to show
      } else if (this.forRecordCollection) {
        if (this.per?.includes('completion_collection')) {
          return '^#records_id';
        }
        return '^id'; // Iterated Record: Link To Record
      } else if (this.forSubForm) {
        return '~#records_id'; // Module Records Link To Record
      } else {
        return '~id'; // Link To Record
      }
    }

    @Watch('per')
    evaluatePerOption() {
      if (this.per) {
        this.$api
          .evaluatePerOption({ per: this.per }, { cache: true })
          .then(({ data }) => {
            if (data.injectable_arguments.sub_form_id) {
              this.$api.getSubForm(Number(data.injectable_arguments.sub_form_id), { cache: true }).then(({ data }) => {
                this.targetModuleName = data.module_name;
              });
            } else {
              this.targetModuleName = data.concept_name;
            }
          })
          .catch(() => {
            this.formLoaded();
          });
      } else {
        if (this.injectableArguments.concept_name === 'SubFormCompletion') {
          this.$api
            .getSubForm(Number(this.injectableArguments.sub_form_id), { cache: true })
            .then(({ data }) => (this.targetModuleName = data.module_name));
        } else if (this.injectableArguments.relationship_code) {
          this.$api
            .getRelationships({ filters: { code: this.injectableArguments.relationship_code } }, { cache: true }) // relationship_code is unique globally
            .then(({ data }) => (this.targetModuleName = data[0]?.from_module));
        } else if (this.injectableArguments.involvement_id) {
          this.$api
            .getInvolvement(this.injectableArguments.involvement_id, { cache: true })
            .then(({ data }) => (this.targetModuleName = data.module_name));
        } else {
          this.targetModuleName = this.injectableArguments.concept_name;
        }
      }
    }

    @Watch('targetModuleName')
    onTargetModuleNameChange(): void {
      this.formReady = false;
      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['id', 'name', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;

          this.$api
            .getWorkflows(
              {
                filters: {
                  active: true,
                  module_name_id: this.moduleNames.map((x) => x.id),
                },
                per_page: -1,
                only: ['name', 'id', 'module_name_id'],
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.workflows = data;
              this.onModuleNameChange();
              this.form = {
                module_name: this.targetModuleName,
                record_id: this.defaultRecordId,
                ...this.values,
              };
              this.formLoaded();
            });
        });
    }

    onModuleNameChange(): void {
      this.form = { ...this.form, workflow_id: undefined };
    }

    mounted(): void {
      this.evaluatePerOption();
    }
  }
