
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { RequiredBy } from '@app/utils/types/required-by';
  import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import { ValidationObserver } from 'vee-validate';
  import type { SubForm } from '@app/models/sub-form';
  import { debounce } from 'lodash';

  import EventForEverySelector from '../events/event-for-every-selector.vue';

  import AutomationCreateObjectEventForm from './automation-create-object-event-form.vue';
  import type { EventWithTempId } from './with-automation-submit';

  @Component({
    components: {
      AutomationCreateObjectEventForm,
      EventForEverySelector,
      ValidationObserver,
    },
  })
  export default class EventFormDisplay extends Vue {
    @PropSync('event') syncedEvent!: EventWithTempId;
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;

    @Prop(Object) readonly injectableArguments!: RequiredBy<InjectableArguments, 'concept_name'>;
    @Prop({ type: [Number, String], default: 0 }) readonly index?: number | string;
    @Prop() readonly preloadedForEveryModuleNames?: ModuleName[];
    @Prop() readonly preloadedForEverySubForms?: SubForm[];
    @Prop() readonly selectedPane?: string | null;
    @Prop() readonly invalidForms!: Record<string, boolean>;
    @Prop() readonly disableFooter!: (cb: () => Promise<void>) => void;

    eventFormLoaded = false;

    @Watch('syncedEvent', { deep: true })
    onSyncedEventChange(): void {
      if (!(typeof this.selectedPane == 'string')) return;

      const id = this.syncedEvent.id;
      if (this.selectedPane.split(':')[0] == id && this.invalidForms[id]) {
        debounce(this.validate, 300)();
      }
    }

    @Emit('set-invalid')
    emitInvalid(state: boolean) {
      return state;
    }

    @Emit('loaded')
    loaded() {
      this.eventFormLoaded = true;
      return true;
    }

    validate(): void {
      this.validator?.validate().then((result: boolean) => this.emitInvalid(result));
    }
  }
