
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SubFormSectionPanel from './sub-form-section-panel.vue';
  import SubFormSectionsNewButton from './sub-form-sections-new-button.vue';
  import Draggable from 'vuedraggable';
  import type { AsfAttachmentOnly, AsfSfsgOnly, AsfSfsOnly, DraggableChangeEvent, SubFormDndList } from './utils';
  import { keyBy } from 'lodash';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';

  @Component({ components: { SubFormSectionPanel, SubFormSectionsNewButton, Draggable } })
  export default class SubFormSectionGroupPanel extends Vue {
    @Prop(Object) readonly subFormSectionGroup!: Pick<SubFormSectionGroup, AsfSfsgOnly>;
    @Prop(Array) readonly subFormSections!: Pick<SubFormSection, AsfSfsOnly>[];
    @Prop(Boolean) readonly hideAddNewSection?: boolean;
    @Prop(Boolean) readonly dragging?: boolean;
    @Prop(Boolean) readonly last?: boolean;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Array) readonly dndList!: SubFormDndList;
    @Prop(Object) readonly attachmentsBySectionId!: Record<string, Pick<Attachment, AsfAttachmentOnly>[]>;
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Number) readonly subFormId!: number;

    onGroupDragChange(event: DraggableChangeEvent<Pick<SubFormSection, AsfSfsOnly>>) {
      this.$emit('group-drag-change', event);
    }

    get sectionsById(): Record<string, Pick<SubFormSection, AsfSfsOnly>> {
      return keyBy(this.subFormSections, 'id');
    }

    get sectionIds(): { id: number }[] {
      return this.dndList.find((item) => item.id === this.subFormSectionGroup.id)?.children || [];
    }

    get lastSectionIndex(): number {
      return (this.sectionIds?.length || 0) - 1;
    }
  }
