
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class Scored extends Vue {
    @Prop() readonly question!: SubFormQuestion;

    get questionScored(): boolean {
      return this.question.config.scored === 'true';
    }
  }
