
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Account } from '@app/models/account';

  @Component({ components: { PaperTrailsModalLink, DsDropdown } })
  export default class AccountActions extends Vue {
    @Prop(Object) readonly account!: Account;
    @Prop(Array) readonly attributeScope?: string[];
  }
