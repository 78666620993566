
  import { keyBy, uniq, groupBy } from 'lodash';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import InvolvementFormModal from '../module-names/involvement-form-modal.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: { InvolvementFormModal, BaseTable },
  })
  export default class ModuleInvolvementsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<Involvement>;

    moduleName: Nullable<ModuleName> = null;

    involvementModalVisible = false;
    manager: Nullable<ListManager<Involvement>> = null;
    selectedInvolvement: Partial<Involvement> = {};

    questionsMap: Dictionary<SubFormQuestion> = {};
    involvementsMap: Dictionary<Involvement> = {};
    modulesMap: Dictionary<ModuleName> = {};

    sharedInvolvements: Involvement[] = [];

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateInvolvementIndexes({ data, sort })
          .then(({ data }) => {
            toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    editInvolvement(involvement: Involvement): void {
      this.selectedInvolvement = involvement;
      this.toggleModal(true);
    }

    get sharedInvolvementsHash(): Record<number, Record<string, Involvement[]>> {
      const groupBySourceInvolvementId = groupBy(this.sharedInvolvements, 'source_involvement_id');
      return Object.keys(groupBySourceInvolvementId).reduce((memo, involvementId) => {
        return {
          ...memo,
          [involvementId]: groupBy(groupBySourceInvolvementId[involvementId], (inv) => inv.related_module_name?.id),
        };
      }, {});
    }

    getManager(moduleName: ModuleName): ListManager<Involvement> {
      return new ListManager<Involvement>({
        fetchDataFunction: (params) => {
          return this.$api.getInvolvements({ ...params, filters: { module_name: moduleName.name } }, { cache: true });
        },
        afterFetch: (data): void => {
          const questionIds = data.filter((inv) => !!inv.linking_question_id).map((inv) => inv.linking_question_id);
          const involvementIds = data.filter((inv) => !!inv.source_involvement_id).map((inv) => inv.source_involvement_id);

          if (questionIds.length) {
            this.$api
              .getSubFormQuestions(
                {
                  filters: { id: uniq(questionIds) },
                  only: ['id', 'title', 'active'],
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.questionsMap = keyBy(data, 'id');
              });
          }

          if (involvementIds.length) {
            this.$api
              .getInvolvements(
                {
                  filters: { id: uniq(involvementIds) },
                  only: ['id', 'name', 'active', 'module_name', 'system_code'],
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.involvementsMap = keyBy(data, 'id');
                const moduleNames = data.map((inv) => inv.module_name);
                if (moduleNames.length) {
                  this.$api
                    .getModuleNames(
                      {
                        filters: { name: uniq(moduleNames) },
                        per_page: -1,
                        only: ['id', 'name', 'display'],
                      },
                      { cache: true }
                    )
                    .then(({ data }) => {
                      this.modulesMap = keyBy(data, 'name');
                    });
                }
              });
          }

          if (data.length) {
            this.$api
              .getInvolvements(
                {
                  filters: {
                    active: true,
                    source_involvement_id: data.map((r) => r.id),
                    related_module_name: {
                      active: true,
                    },
                  },
                  only: ['id', 'name', 'source_involvement_id', { related_module_name: ['id', 'display'] }],
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.sharedInvolvements = data;
              });
          }
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index' },
          { title: 'ID', name: 'id', sortField: 'id' },
          { title: 'Title', name: 'name', sortField: 'name' },
          { title: 'Type', name: 'involvement_type', sortField: 'involvement_type' },
          { title: 'System Code', name: 'system_code', sortField: 'system_code' },
          { title: 'Share Details', name: 'share_details' },
          { title: 'Visual Options', name: 'visual_options' },
          { title: 'Active', name: 'active', sortField: 'active' },
        ],
      });
    }

    addNew(): void {
      if (this.moduleName) {
        this.selectedInvolvement = { module_name: this.moduleName.name };
        this.toggleModal(true);
      }
    }

    typeLabel(involvement: Involvement): string {
      switch (involvement.involvement_type) {
        case 'children_sharing':
          return this.$t('tenant.admin.involvements.involvement_altered_types.children_sharing');
        case 'standard':
          return this.$t('tenant.admin.involvements.involvement_altered_types.standard');
        case 'parent_sharing':
          return this.$t('tenant.admin.involvements.involvement_altered_types.parent_sharing');
      }
    }

    toggleModal(value: boolean): void {
      this.involvementModalVisible = value;
    }

    submitForm(entity: Partial<Involvement>): void {
      const promise = entity.id ? this.$api.updateInvolvement(entity.id, entity) : this.$api.createInvolvement(entity);
      promise
        .then(() => {
          this.$api.cache.clear();
          this.table?.debounceUpdate();
          this.toggleModal(false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    beforeMount(): void {
      this.$api.getModuleName(Number(this.moduleNameId)).then(({ data }) => {
        this.moduleName = data;
        this.manager = this.getManager(this.moduleName);
      });
    }
  }
