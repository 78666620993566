
  import bootbox from 'bootbox';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { orderBy } from 'lodash';
  import { BaseTable } from '@app/components/base-table';
  import SamlIndexActions from '@app/components/admin/samls/saml-index-actions.vue';
  import type { Saml, SamlLogMessage } from '@app/models/saml';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      BaseTable,
      DsModal,
      SamlIndexActions,
    },
    methods: {
      orderBy,
    },
  })
  export default class SamlListing extends Vue {
    @Ref() readonly table?: BaseTable<Saml>;

    currentSaml: Partial<Saml> = {};
    historyModalVisible = false;
    manager: Nullable<ListManager<Saml>> = null;

    get commonErrors(): { error: string; fix: string }[] {
      return [
        {
          error: 'Invalid settings, idp_sso_service_url is not set!',
          fix: 'Please configure Donesafe#IdP SSO target URL.',
        },
        {
          error: 'Invalid Audiences ... did not match the expected audience ...',
          fix: "Please fix Donesafe#Issuer to match iDP SAML application's configured Audience.",
        },
        {
          error: 'Nested asn1 error',
          fix: 'Please fix newlines in Donesafe#IdP X509 cert, or paste the iDP cert without newlines.',
        },
        {
          error: 'Fingerprint mismatch',
          fix: 'Please check Donesafe#IdP X509 cert and ensure it matches the iDP X509 cert.',
        },
        {
          error: 'Current time is earlier than NotBefore condition',
          fix: 'Please ensure iDP server time is up to date and in sync with a time server, or add Donesafe#SSO clock drift in seconds.',
        },
        {
          error: 'The status code of the Response was not Success, was Responder',
          fix: 'iDP did not indicate user can login.',
        },
        {
          error: 'User returning to Donesafe login page with skip_sso=true in the query param',
          fix: 'Please check and make sure the user exists with specified matching critera or a valid SAML config exists.',
        },
      ];
    }

    logsLost(list?: SamlLogMessage[]): SamlLogMessage[] {
      return list || [];
    }

    getManager(): ListManager<Saml> {
      return new ListManager<Saml>({
        fetchDataFunction: (params) => {
          return this.$api.getSamls(
            {
              ...params,
              only: ['id', 'system_default', 'issuer', 'service_name', 'sp_login_link', 'sp_metadata_link', 'active', 'logs'],
            },
            { cache: true } // for repetitive requests like: sorting
          );
        },
        sortOrder: [
          {
            direction: 'desc',
            field: 'system_default',
            sortField: 'system_default',
          },
        ],
        per_page: -1,
        fields: [
          {
            title: this.$t('tenant.admin.settings.samls.index.system_default'),
            name: 'system_default',
            sortField: 'system_default',
            width: '90px',
          },
          {
            title: this.$t('tenant.admin.settings.samls.form.issuer'),
            name: 'issuer',
            sortField: 'issuer',
            width: '150px',
          },
          {
            title: this.$t('tenant.admin.settings.samls.form.service_name'),
            name: 'service_name',
            sortField: 'service_name',
            width: '150px',
          },
          {
            title: this.$t('tenant.admin.settings.samls.index.sp_login_link'),
            name: 'sp_login_link',
          },
          {
            title: this.$t('tenant.admin.settings.samls.index.sp_metadata_link'),
            name: 'sp_metadata_link',
          },
          {
            title: this.$t('app.labels.active'),
            name: 'active',
            sortField: 'active',
            width: '90px',
          },
          { title: '', name: 'operations', width: '100px' },
        ],
      });
    }

    clearLogs(type: 'success' | 'error'): void {
      this.currentSaml.id &&
        bootbox.confirm({
          size: 'small',
          backdrop: false,
          message: this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
            cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            result &&
              this.currentSaml.id &&
              this.$api
                .clearLogs(this.currentSaml.id, type)
                .then(({ data }) => {
                  this.currentSaml = data;
                  this.$api.cache.clear();
                  this.table?.reload();
                })
                .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
          },
        });
    }

    viewHistory(saml: Saml): void {
      this.currentSaml = saml;
      this.historyModalVisible = true;
    }

    copyText(text: string): void {
      this.$copyText(text);
      toaster(this.$t('app.labels.copied_to_clipboard'));
    }

    cloneSaml(saml: Saml): void {
      bootbox.confirm({
        size: 'small',
        message: 'Do you want to make a copy of the SAML config?',
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .cloneSaml(saml.id, {
                issuer: `${saml.issuer} (Copy)`,
                service_name: saml.service_name ? `${saml.service_name} (Copy)` : '',
              })
              .then(() => {
                this.$api.cache.clear();
                this.table?.debounceUpdate();
              })
              .catch(({ data }) =>
                toaster({
                  text: data?.error,
                  position: 'top-right',
                  icon: 'error',
                })
              );
          }
        },
      });
    }

    mounted(): void {
      this.manager = this.getManager();
    }
  }
