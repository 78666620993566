
  import { entityPathPrefix } from '@app/services/helpers';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import DsLabel from '@app/components/ds-label.vue';
  import qs from 'qs';
  import type { Workflow } from '@app/models/workflow';
  import type { WorkflowLink } from '@app/models/workflow-link';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import WorkflowPanelStepsModal from './workflow-panel-steps-modal.vue';

  @Component({ components: { DsLabel, WorkflowPanelStepsModal } })
  export default class WorkflowPanel extends Vue {
    @Prop(Number) readonly recordId!: number;
    @Prop([Number, String]) readonly tab?: number | string;
    @Prop(Object) readonly workflow?: Workflow;
    @Prop(String) readonly moduleName!: string;
    @Prop(String) readonly recordType!: string;

    nextLinks: WorkflowLink[] = [];
    rejectionLinks: WorkflowLink[] = [];
    nextModalVisible = false;
    prevModalVisible = false;
    submitting = false;

    get hardcodedModule(): boolean {
      return HARDCODED_MODULE_CODES.filter((item) => item !== 'Report').includes(this.moduleName);
    }

    get displayWorkflow(): boolean {
      return !!this.workflow?.id;
    }

    updateModuleRecord(newWorkflowId: number): void {
      this.submitting = true;
      if (this.hardcodedModule) {
        const path = `/${entityPathPrefix(this.recordType)}/${this.recordId}`;
        const params = { event: newWorkflowId, tab: this.tab || undefined };
        changeLocation(`${path}/run_event?${qs.stringify(params)}`);
        return;
      } else {
        this.$api
          .updateModuleRecord(this.recordId, {
            workflow_id: newWorkflowId,
            only: { workflow: ['name'] },
          })
          .then(({ data }) => {
            toaster({
              text: this.$t('controllers.defaults.state_changed_to_name', { name: data.workflow?.name || '' }),
              position: 'top-right',
            });
            this.prevModalVisible = false;
            this.nextModalVisible = false;
            this.$api.cache.clear();
            window.location.reload();
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    }

    beforeMount(): void {
      if (this.workflow?.id) {
        this.$api
          .getWorkflowActiveLinks(
            this.workflow.id,
            {
              filters: {
                record_id: this.recordId,
                record_type: this.recordType,
              },
              include: ['to'],
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.rejectionLinks = data.filter((link) => link.rejection);
            this.nextLinks = data.filter((link) => !link.rejection);
          });
      }
    }
  }
