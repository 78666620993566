
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import ColorInput from '../../color-input.vue';
  import EntitySelector from '../../entity-selector.vue';
  import PublicFileUploader from '../../file-uploader/public-file-uploader.vue';
  import Select2 from '../../select2.vue';
  import UserSelector from '../../user/user-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Dictionary } from '@app/models/dictionary';
  import type { MobileAppLayoutEntity } from '@app/models/mobile-app/entity';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      ColorInput,
      PublicFileUploader,
      Select2,
      UserSelector,
      EntitySelector,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class MobileAppLayoutEntityForm extends Vue {
    @Prop(Object) readonly layoutEntity?: Partial<MobileAppLayoutEntity>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<MobileAppLayoutEntity> = {};

    get typeOptions(): MobileAppLayoutEntity['type'][] {
      return ['image', 'button', 'text', 'qr'];
    }

    get mobileAppConfigId(): string {
      return this.$route.params.mobileAppConfigId;
    }

    get imageStyle(): object {
      if (!this.form.config?.src) {
        return {};
      }
      return {
        height: `${this.form.config.height}px`,
        width: `${this.form.config.width}px`,
      };
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    beforeMount(): void {
      this.form = { ...this.layoutEntity, config: { ...this.layoutEntity?.config } };
    }

    get configJSON(): string {
      return JSON.stringify(this.form.config, null, 2);
    }

    set configJSON(value: string) {
      this.onJSONChange(value);
    }

    onLinkTypeChange(type?: 'app' | 'browser'): void {
      const config: Dictionary<string | boolean> = { ...this.form.config };
      switch (type) {
        case 'app':
          delete config.url;
          break;
        case 'browser':
          delete config.layout;
          break;
        default:
          delete config.url;
          delete config.layout;
          break;
      }
      this.form = { ...this.form, config };
    }

    onJSONChange(json: string): void {
      try {
        this.form = {
          ...this.form,
          config: JSON.parse(json),
        };
      } catch {
        toaster('Attributes JSON is invalid. Reverting.');
        this.form = { ...this.form };
      }
    }
  }
