import { render, staticRenderFns } from "./instructions.vue?vue&type=template&id=36b2edd2&scoped=true&lang=pug"
import script from "./instructions.vue?vue&type=script&lang=ts"
export * from "./instructions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b2edd2",
  null
  
)

export default component.exports