
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import Blocking from '@app/mixins/blocking';
  import type { BaseEntity } from '@app/models/base-entity';
  import type { MenuCollection } from '@app/models/menu-collection';
  import { UI_OBSERVER } from '@app/services/global-observer';
  import type { AxiosPromise } from 'axios';
  import { groupBy } from 'lodash';

  import { Component, Watch } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import { MENU_COLLECTIONS_FORM_ONLY } from './utils';
  import type { ModuleName } from '@app/models/module-name';
  import type { UpdateIndexParams } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { Tuple } from '@app/utils/types/tuple';
  import { toaster } from '@app/utils/toaster';

  const MODULE_NAME_ONLY = Tuple(['id', 'display', 'menu_collection_id'] as const);
  type ModuleNameOnly = (typeof MODULE_NAME_ONLY)[number];

  @Component({ components: { Draggable } })
  export default class MenuCollectionsPage extends Blocking {
    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.blocking(() => this.fetchMenuCollections());
      }
    }

    menuCollections: MenuCollection[] = [];
    mnByMenuCollection: Record<string, Pick<ModuleName, ModuleNameOnly>[]> = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    menuCollectionName(mc: MenuCollection): string {
      return mc.active ? mc.name : this.$t('app.labels.archived_name', { name: mc.name });
    }

    updateIndexesPromise<T extends BaseEntity>(promise: AxiosPromise<T[]>): Promise<void> {
      return promise
        .then(() => {
          // refreshes order in the 'page-header' and 'side-menu'
          UI_OBSERVER.menuCollectionOrderUpdate$.next();
          toaster({
            text: this.$t('app.labels.order_saved'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    getNewIndexes<T extends BaseEntity>(data: T[]): UpdateIndexParams['data'] {
      return data.map((question, index) => {
        return { id: question.id, index: index + 1 };
      });
    }

    onMenuCollectionDragChange(): void {
      this.updateIndexesPromise(this.$api.updateMenuCollectionIndexes({ data: this.getNewIndexes(this.menuCollections) }));
    }

    onModuleNameDragChange(mc: MenuCollection): void {
      this.updateIndexesPromise(this.$api.updateModuleNameIndexes({ data: this.getNewIndexes(this.mnByMenuCollection[mc.id]) }));
    }

    editModuleNamePath(mn: Pick<ModuleName, ModuleNameOnly>): string {
      return `/admin/settings/module_names/${mn.id}/edit`;
    }

    async fetchMenuCollections() {
      const { data } = await this.$api.getMenuCollections(
        { only: MENU_COLLECTIONS_FORM_ONLY, sort: 'index', per_page: -1 },
        { cache: true }
      );
      this.menuCollections = data;
    }

    beforeMount(): void {
      this.blocking(async () => {
        const { data: moduleNames } = await this.$api.getModuleNames(
          {
            only: MODULE_NAME_ONLY,
            filters: { active: true },
            show_all: true,
            sort: 'index',
            per_page: -1,
          },
          { cache: true }
        );
        this.mnByMenuCollection = groupBy(moduleNames, 'menu_collection_id');
        await this.fetchMenuCollections();
      });
    }
  }
