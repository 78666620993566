
  import EntitySelector from '@app/components/entity-selector.vue';
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { omit } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { isHybridModule } from '@app/services/model-helpers';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { FormComponent } from '@app/models/form-component';
  import type { RuleSet } from '@app/models/rule-set';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ModuleName } from '@app/models/module-name';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import RuleRestrictionsSelector from '../rule-sets/rule-restrictions-selector.vue';
  import UserCollectionSelector from '../user-collections/user-collection-selector.vue';

  @Component({
    components: { EntitySelector, Select2, UserCollectionSelector, ValidationProvider, ValidationObserver, RuleRestrictionsSelector },
  })
  export default class FormComponentForm extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly formComponent?: Pick<
      FormComponent,
      'id' | 'component_type' | 'component_id' | 'can_skip' | 'complete_multiple' | 'rule_set' | 'user_collection_id'
    >;
    @Prop(String) readonly conceptName!: string;

    form: Partial<FormComponent & { rule_set: Nullable<Partial<RuleSet>> }> = {};
    moduleName: Nullable<Pick<ModuleName, 'id' | 'module_type' | 'sub_form_id'>> = null;
    formComponents: FormComponent[] = [];
    selectorKey = 0;

    get subFormListFilters(): DonesafeFilterOptions<SubFormList> {
      return {
        active: true,
        module_tab: { module_name: this.conceptName },
        sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
        '!id': this.formComponents.map((component) => component.component_id),
      };
    }

    get triggeringInjectableArguments(): Partial<InjectableArguments> {
      return { concept_name: this.conceptName };
    }

    get moduleWithMainForm(): boolean {
      return !!this.moduleName?.sub_form_id;
    }

    get componentTypeOptions(): string[][] {
      if (this.moduleWithMainForm || this.conceptName === 'Hazard') {
        const options = [['sub_form_lists', 'Sub Form']];
        if (this.moduleName && isHybridModule(this.moduleName)) {
          return [...options, ['hybrid_selected', 'Selected Sub Form']];
        }

        return options;
      }

      return [];
    }

    get formForSubmit(): Nullable<Partial<FormComponent & { rule_set: Nullable<Partial<RuleSet>> }>> {
      if (!this.form?.user_collection && !this.form?.user_collection_id) return omit(this.form, 'user_collection', 'user_collection_id');
      if (!this.form?.user_collection) return this.form;

      return this.form?.user_collection?.global
        ? omit({ ...this.form, user_collection_id: this.form.user_collection.id }, 'user_collection')
        : omit(this.form, 'user_collection_id');
    }

    async beforeMount(): Promise<void> {
      this.initForm();
      await Promise.all([this.fetchModuleName(), this.fetchUserCollection(), this.fetchFormComponents()]);
    }

    initForm(): void {
      this.form = {
        complete_multiple: false,
        can_skip: false,
        ...this.formComponent,
        id: this.formComponent?.id,
        module_name: this.conceptName,
        rule_set: (this.formComponent?.rule_set || {}) as RuleSet,
      };
    }

    inactiveTitleTemplate(subFormList: Pick<SubFormList, 'id' | 'title' | 'active'>) {
      return inactiveTitleTemplate(subFormList, 'title', 'app.labels.title_inactive');
    }

    async fetchUserCollection(): Promise<void> {
      if (this.formComponent?.user_collection_id) {
        const { data } = await this.$api.getUserCollection(
          Number(this.formComponent.user_collection_id),
          { only: ['id', 'name', 'global', { rule_set: ['rules_description', 'query_builder_blob'] }] },
          { cache: true }
        );
        this.form = { ...this.form, user_collection: data };
        this.selectorKey++;
      }
    }

    async fetchModuleName(): Promise<void> {
      const { data } = await this.$api.getModuleNames(
        { filters: { name: this.conceptName }, only: ['id', 'name', 'module_type', 'sub_form_id'] },
        { cache: true }
      );
      this.moduleName = data[0] || null;
    }

    async fetchFormComponents() {
      const { data } = await this.$api.getFormComponents(
        {
          filters: {
            module_name: this.conceptName,
          },
          per_page: -1,
          only: ['id', 'component_id', 'component_type'],
        },
        { cache: true }
      );

      this.formComponents = data;
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.formForSubmit);
        }
      });
    }
  }
