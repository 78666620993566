
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SubFormCompletionIndexItem from './sub-form-completion-index-item.vue';
  import type { NormalTabRecordOnly, NormalTabSfcOnly, NormalTabSflOnly } from './utils';
  import { NORMAL_TAB_SFC_ONLY } from './utils';
  import { Pagination } from 'uiv';
  import type { Pagination as PaginationType } from '../base-table/utils';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { BaseRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { AuditReportTemplateReportType, AuditReportTemplateVisibility } from '@app/models/audit-report-template';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { SubFormCompletionIndexItem, Pagination } })
  export default class SubFormCompletionIndex extends Vue {
    @Prop(Object) readonly subFormList!: Pick<SubFormList, NormalTabSflOnly>;
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly editable?: boolean;
    @Prop(Boolean) readonly canAddMore?: boolean;
    @Prop(Boolean) readonly approvable?: boolean;
    @Prop(Boolean) readonly archivable?: boolean;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(Object) readonly record!: Pick<BaseRecord, NormalTabRecordOnly>;
    @Prop(String) readonly recordType!: string;

    auditReportTemplates: AuditReportTemplate[] = [];
    subFormCompletions: Pick<SubFormCompletion, NormalTabSfcOnly>[] = [];
    // TODO: potentially store in URL query params if we want to be able to link to a specific page with open completion id
    pagination: PaginationType = {
      total: 0,
      per_page: 25,
      current_page: 1,
      last_page: 1,
    };
    additionalCompletionFilters = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    fetchCompletions(additionalFilters?: DonesafeFilterOptions<SubFormCompletion>) {
      this.$emit('sub-form-completions-fetching');

      if (!!additionalFilters) this.additionalCompletionFilters = additionalFilters;

      this.$api
        .getSubFormCompletions(
          {
            filters: {
              record_id: this.record.id,
              record_type: this.recordType,
              sub_form_list_id: this.subFormList.id,
              ...(!!this.subFormList.sub_form_ids?.length ? { sub_form_id: this.subFormList.sub_form_ids } : {}),
              ...this.additionalCompletionFilters,
            },
            only: NORMAL_TAB_SFC_ONLY,
            per_page: this.pagination.per_page,
            page: this.pagination.current_page,
          },
          {
            cache: true,
          }
        )
        .then(({ data, headers }) => {
          this.subFormCompletions = data;
          this.pagination = {
            ...this.pagination,
            total: +headers.total,
            last_page: Math.ceil(+headers.total / +headers['per-page']),
          };
          this.$emit('sub-form-completions-fetched', data);
        });
    }

    beforeMount() {
      this.$api
        .getAuditReportTemplates(
          {
            filters: {
              ...(this.currentUserStore.hasModuleConfigPermission ? {} : { visibility: AuditReportTemplateVisibility.Visible }),
              record_id: this.record.id,
              report_type: AuditReportTemplateReportType.SubFormCompletion,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.auditReportTemplates = data;
        });

      this.fetchCompletions();
    }
  }
