import type { AutomatedEvent } from './automated-event';
import type { BaseEntity } from './base-entity';
import type { Event } from './event';
import type { AutomationEventGroup } from './automation-event-group';
import type { PlaceholderProfile } from './placeholder-profile';
import type { RuleSet } from './rule-set';

export const AUTOMATION_STRUCTURE: Record<string, string[]> = {
  Activity: [],
  Audit: [],
  Hazard: [],
  Incident: ['IncidentParticipant'],
  Observation: [],
  Procedure: [],
  LearningRecord: [],
  ChemicalRegister: [],
  VisitorRegister: [],
  WorkerCompensation: ['MedicalCertificate'],
  CompanyRegister: ['Insurance'],
  Kpi: [],
  JobManagementRegister: [],
  SafetyDataSheet: [],
  GhsChemicalRegister: [],
  LmsCourseEnrollment: [],
  TenantUser: ['Document'],
};

export enum AutomationDefinitionState {
  activating = 'activating',
  active = 'active',
  inactive = 'inactive',
}

export enum AutomationTriggerOn {
  ConditionsMet = 'conditions_met',
  Create = 'create',
  Delete = 'delete',
  FieldUpdate = 'field_update',
}

export interface AutomationDefinition extends BaseEntity {
  automated_events?: AutomatedEvent[];
  automation_event_groups?: AutomationEventGroup[];
  concept_name: string;
  description?: string;
  display?: string;
  event_ids?: number[];
  events?: Event[];
  index: number;
  involvement_id?: number;
  name: string;
  notification_email: string;
  placeholder_profiles?: PlaceholderProfile[];
  regulatory_report_config_id?: number;
  relationship_code?: string;
  rule_set?: RuleSet;
  rule_set_id: number;
  rule_set_label?: string;
  state: AutomationDefinitionState;
  sub_form_id?: number;
  trigger_on: AutomationTriggerOn;
  trigger_when?: 'always' | 'once';
  triggering_fields: string[];
}
