import { render, staticRenderFns } from "./admin-sub-form-actions.vue?vue&type=template&id=248d6373&scoped=true&lang=pug"
import script from "./admin-sub-form-actions.vue?vue&type=script&lang=ts"
export * from "./admin-sub-form-actions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248d6373",
  null
  
)

export default component.exports