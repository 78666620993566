
  import DashboardFormModal from '@app/components/admin/dashboards/dashboard-form-modal.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Dashboard } from '@app/models/dashboard';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DashboardFormModal } })
  export default class AdminDashboardsEditPage extends Vue {
    @Prop({ type: [String, Number] }) readonly dashboardId!: number | string;

    dashboard: Dashboard | null = null;
    visible = true;
    submitDashboardForm(data: Dashboard): void {
      this.$api
        .updateDashboard(data.id, data)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-dashboards-show',
        params: { reloadTable: command, dashboardId: `${this.dashboardId}` },
        query: this.$route.query,
      });
    }

    beforeMount(): void {
      this.$api.getDashboard(Number(this.dashboardId), {}, { cache: true }).then(({ data }) => {
        this.dashboard = data;
      });
    }
  }
