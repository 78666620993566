
  import DsIconText from '@app/components/ds-icon-text.vue';
  import bootbox from 'bootbox';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import { mixins } from 'vue-class-component';
  import QuestionSummary from './question-summary.vue';
  import Blocking from '@app/mixins/blocking';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import TabFieldSettings from '@app/components/admin/questions/edit/tab-field-settings.vue';
  import TabVisualOptions from '@app/components/admin/questions/edit/tab-visual-options.vue';
  import TabAdvancedFeatures from '@app/components/admin/questions/edit/tab-advanced-features.vue';
  import TabFieldUsage from '@app/components/admin/questions/edit/tab-field-usage.vue';
  import { canDelete, isReadonlyLookup } from '@app/services/model-helpers';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import { ValidationObserver } from 'vee-validate';
  import deepmerge from 'deepmerge';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import type { PartialDeep } from 'type-fest';
  import { isBoolean, isEmpty, isEqualWith, isNumber, isObject, mapValues, omit, omitBy, xor } from 'lodash';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';
  import { toaster } from '@app/utils/toaster';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import DsModal from '@app/components/ds-modal.vue';

  type QuestionForm = PartialDeep<SubFormQuestion<{}, {}>>;

  const prepareForm = (form: QuestionForm) => {
    const { attachments, options } = form;

    const prepareValues = (vs: object) => mapValues(vs, (v) => omit(v, '_key', 'is_default'));

    if (options?.values) {
      options.values = prepareValues(options.values);
    }

    if (options?.historical_values) {
      options.historical_values = prepareValues(options.historical_values);
    }

    return {
      ...form,
      ...(!!attachments ? { attachments: attachments?.every(({ url }) => !!url) ? undefined : attachments } : {}),
      ...(!!options ? { options } : {}),
    };
  };

  @Component({
    components: {
      DsIconText,
      DsModal,
      Tabs,
      Tab,
      TabFieldSettings,
      TabVisualOptions,
      TabAdvancedFeatures,
      TabFieldUsage,
      QuestionSummary,
      ValidationObserver,
      DsDropdown,
      PaperTrailsModalLink,
    },
  })
  export default class EditQuestion extends mixins(Blocking, WithBootbox) {
    @Prop(Number) subFormId!: number;
    @Prop(Number) questionId?: number;
    @Prop(Number) subFormSectionId?: number;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly fieldSettingsValidator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly visualOptionsValidator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly advancedFeaturesValidator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly fieldUsageValidator?: InstanceType<typeof ValidationObserver>;

    currentTab = 0;
    fieldSettingsInvalid = false;
    visualOptionsInvalid = false;
    advancedFeaturesInvalid = false;
    fieldUsageInvalid = false;

    subForm!: SubForm;
    moduleName!: ModuleName;

    form: QuestionForm = {
      active: true,
      attachments: [],
      config: {
        add: {
          module_records_default: {},
        },
        default: {},
        show: {},
        validate: {},
        api_lookup: {},
        api_request: {},
        lookup: {},
        restrict_to: {},
      },
      options: {
        historical_values: {},
        values: {},
      },
    };

    initialForm: QuestionForm = {};

    loaded = false;
    closeConfirmationOpen = false;

    get features(): ModuleFeatures {
      const { is_public = false, mfql = false } = this.moduleName.feature_set || {};
      const { scoring = false } = this.subForm;

      return { is_public, mfql, scoring };
    }

    get canDelete() {
      if (this.newQuestion) return false;

      return canDelete(this.form as SubFormQuestion);
    }

    get newQuestion(): boolean {
      return !this.form?.id;
    }

    get mainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    async beforeMount() {
      await Promise.all([this.loadQuestion(), this.ensureModuleName()]);

      this.loaded = true;
    }

    async loadQuestion(id?: SubFormQuestion['id']): Promise<SubFormQuestion | undefined> {
      id ||= this.questionId;

      if (!id) {
        return;
      }

      const { data: value } = await this.$api.getSubFormQuestion(id, { include: ['sub_form_section', 'attachments'] }, { cache: true });
      this.form = deepmerge(this.form, value, { arrayMerge: (dst, src) => src || dst });
      this.initialForm = objectCloneDeep(this.form);
      this.validator?.reset();

      return value;
    }

    async ensureSubForm(): Promise<SubForm> {
      if (this.subForm) {
        return this.subForm;
      }

      const { data: value } = await this.$api.getSubForm(
        this.subFormId,
        {
          include: ['sub_form_sections', 'sub_form_questions', 'module_name'],
        },
        { cache: true }
      );

      return (this.subForm = value);
    }

    async ensureModuleName(): Promise<ModuleName> {
      await this.ensureSubForm();

      const filters =
        this.subForm.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({ filters, include: ['feature_set'] }, { cache: true });

      return (this.moduleName = moduleName);
    }

    async onSave({ close = false } = {}) {
      const readLookupConfirmationPassed = await this.checkQuestionResponses();
      if (!readLookupConfirmationPassed) return;

      await this.blocking(async () => {
        const valid = await this.validator?.validate();
        this.updateTabsState();

        if (!valid) {
          const invalidTabs = [this.fieldSettingsInvalid, this.visualOptionsInvalid, this.advancedFeaturesInvalid, this.fieldUsageInvalid];

          if (!invalidTabs[this.currentTab]) this.currentTab = invalidTabs.findIndex((v) => v);

          this.$scrollTo('.form-group.has-error', { container: '.edit-question' });
          return;
        }

        const toSave = prepareForm(objectCloneDeep(this.form)) as Partial<SubFormQuestion>;

        const {
          data: { id },
        } = await (!this.form.id
          ? this.$api.createSubFormQuestion({ ...toSave, sub_form_section_id: this.subFormSectionId })
          : this.$api.updateSubFormQuestion(this.form.id, toSave));

        this.$api.cache.clear();

        if (close) {
          sessionStorage.setItem('questionIdAfterEdit', `${id}`);
          this.closeModal(false, true);
        } else {
          await this.loadQuestion(id);
        }

        toaster(this.$t('app.labels.form_saved'));

        this.validator?.reset();
      });
    }

    async onDelete() {
      await this.blocking(async () => {
        if (!this.form.id) return;
        if (!(await this.confirm(`${this.$t('tenant.admin.sub_form_questions.form.delete_confirm')}`, { backdrop: false }))) return;

        await this.$api.deleteSubFormQuestion(this.form.id);
        this.$api.cache.clear();
        toaster(this.$t('tenant.admin.sub_form_questions.form.deleted'));
        this.closeModal(false, true);
      });
    }

    async checkQuestionResponses(): Promise<boolean> {
      if (this.form.id && isReadonlyLookup(this.form as SubFormQuestion) && !isReadonlyLookup(this.initialForm as SubFormQuestion)) {
        const { data: result } = await this.$api.hasSubFormQuestionResponses(this.form.id, { cache: true });
        if (result) {
          return new Promise((resolve) => {
            bootbox.confirm({
              backdrop: false,
              size: 'small',
              message: this.$t('tenant.admin.sub_form_questions.advanced_options.read_lookup_data_warning'),
              callback: (res: boolean) => {
                if (!res) {
                  this.currentTab = 2;
                }

                resolve(res);
              },
            });
          });
        }
      }

      return true;
    }

    onSubmit(ev: SubmitEvent) {
      if (ev?.submitter?.id !== 'submit-question-form') {
        return;
      } // prevent form from saving after random <button>s inside it were pressed

      this.onSave();
    }

    async closeModal(checkDirty = true, reload = false) {
      if (checkDirty && (await this.checkDirty())) {
        return;
      }

      this.$emit('close', reload);
    }

    async checkDirty(): Promise<boolean> {
      if (this.closeConfirmationOpen) {
        return true;
      }

      if (this.hasChanges()) {
        this.closeConfirmationOpen = true;
        const confirmed = await this.confirm(this.$t('tenant.admin.sub_form_questions.form.close_unsaved_confirm'), { backdrop: false });
        this.closeConfirmationOpen = false;

        if (!confirmed) return true;
      }

      return false;
    }

    hasChanges() {
      const defaults: QuestionForm = {
        config: {
          filters: [],
          read_view_mode: 'normal',
          color_view_mode: 'read',
          mode: 'default',
          use_type: 'normal',
          map_display_height: '300px',
          map_select_enabled: false,
          map_select_height: '500px',
          sort: 'id',
          scored: false,
          weighting: 1,
          record_link_behaviour: 'go_to_record',
          expanded: true,
          date_picker_ui: 'date',
          default: {
            type: 'no_default',
          },
          add: {
            module_records_default: {},
          },
          show: {},
          validate: {},
          api_lookup: {},
          api_request: {},
          lookup: {},
          restrict_to: {},
        },
        field_type: FieldType.text,
        active: true,
        options: {
          historical_values: {},
          values: {},
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const equal = (f: any, s: any): boolean =>
        isEqualWith(f, s, (a, b) => {
          if (isEmpty(a) && isEmpty(b)) {
            return true;
          }

          if (isBoolean(a) || isBoolean(b) || isNumber(a) || isNumber(b)) return '' + a === '' + b;

          if (isObject(a) && isObject(b) && !!xor(Object.keys(a), Object.keys(b)).length) {
            a = omitBy(a, isEmpty);
            b = omitBy(b, isEmpty);

            return !!xor(Object.keys(a), Object.keys(b)).length ? false : equal(a, b);
          }
        });

      const hasChanges = !equal(deepmerge(defaults, prepareForm(this.form)), deepmerge(defaults, prepareForm(this.initialForm)));

      if (hasChanges) {
        // eslint-disable-next-line no-console
        console.info(JSON.stringify(deepmerge(defaults, prepareForm(this.form))));
        // eslint-disable-next-line no-console
        console.info(JSON.stringify(deepmerge(defaults, prepareForm(this.initialForm))));
      }

      return hasChanges;
    }

    updateTabsState() {
      this.fieldSettingsInvalid = !!this.fieldSettingsValidator?.flags?.invalid;
      this.visualOptionsInvalid = !!this.visualOptionsValidator?.flags?.invalid;
      this.advancedFeaturesInvalid = !!this.advancedFeaturesValidator?.flags?.invalid;
      this.fieldUsageInvalid = !!this.fieldUsageValidator?.flags?.invalid;
    }

    $$t(key: string) {
      return this.$t(`tenant.admin.sub_form_questions.form.${key}`);
    }
  }
