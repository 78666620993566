
  import CalculationRuleForm from '../../../components/admin/calculation-rules/calculation-rule-form.vue';
  import bootbox from 'bootbox';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { CalculationRule } from '@app/models/calculation-rule';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      CalculationRuleForm,
      DsModal,
    },
  })
  export default class CalculationRulesNewPage extends Vue {
    @Ref() readonly form?: CalculationRuleForm;

    calculationRule: Nullable<CalculationRule> = null;
    loading = false;
    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-calculation-rules-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(params: Partial<CalculationRule>) {
      if (this.calculationRule) {
        this.$api
          .updateCalculationRule(this.calculationRule.id, params)
          .then(() => {
            this.closeModal('reload');
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      }
    }

    destroy() {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.calculation_rule.form.delete_calculation_rule'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          this.calculationRule &&
            result &&
            this.$api
              .deleteCalculationRule(this.calculationRule.id)
              .then(() => {
                this.closeModal('reload');
                toaster(this.$t('tenant.admin.calculation_rule.modal.calculation_rule_deleted'));
              })
              .catch(({ data }) => {
                toaster({ text: data.error, position: 'top-right', icon: 'error' });
              });
        },
      });
    }

    loadCalculationRule(): void {
      this.loading = true;

      this.$api
        .getCalculationRule(Number(this.$route.params.calculationRuleId), {}, { cache: true })
        .then(({ data }) => {
          this.calculationRule = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    beforeMount() {
      this.loadCalculationRule();
    }
  }
