
  import { Component, Vue } from 'vue-property-decorator';
  import type { Area } from '@app/models/area';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';

  import { BaseTable } from '../base-table';

  @Component({ components: { BaseTable } })
  export default class AdminSettingsAreasPage extends Vue {
    manager = new ListManager<Area>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<Area> = ['id', 'name', 'active', { location: ['id', 'name'] }];
        return this.$api.getAreas({ ...params, only });
      },
      useHistory: true,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      per_page: 25,
      fields: this.fields,
    });

    get fields() {
      return [
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.location'), name: 'location' },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
      ];
    }
  }
