
  import { debounce } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class PanelPage extends Vue {
    @Prop(Boolean) readonly panelVisible?: boolean;
    @Prop(Boolean) readonly fullHeight?: boolean;
    @Prop(String) readonly contentParentClasses!: string;
    @Prop(Boolean) readonly loading!: boolean;
    @Prop({ type: Boolean, default: true }) readonly hasBottomButton?: boolean;

    mobile = false;

    get panelClass() {
      return this.panelVisible ? 'col-md-0' : 'col-md-3';
    }

    get mainContentClass() {
      return this.panelVisible || !this.$scopedSlots.panel ? 'col-md-12' : 'col-md-9';
    }

    onResize(): void {
      if (window.innerWidth < 992 && !this.mobile) {
        this.mobile = true;
      } else if (window.innerWidth >= 992 && this.mobile) {
        this.mobile = false;
      }
    }

    onResizeDebounced = debounce(this.onResize, 50);

    mounted(): void {
      window.addEventListener('resize', this.onResizeDebounced);
      this.onResize();
    }

    beforeDestroy(): void {
      window.removeEventListener('resize', this.onResizeDebounced);
    }
  }
