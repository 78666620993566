
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestionReference } from '@app/components/admin/questions/edit/models';
  import SubFormQuestionSelector from '@app/components/admin/questions/edit/sub-form-question-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: { SubFormQuestionSelector } })
  export default class MainFormDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;

    loaded = false;
    mainFormId: SubForm['id'] | null = null;

    async beforeMount() {
      const {
        data: [{ sub_form_id: mainFormId }],
      } = await this.$api.getModuleNames({ filters: { name: this.subForm.module_name }, only: ['id', 'sub_form_id'] }, { cache: true });

      this.loaded = true;

      if (!mainFormId) {
        return;
      }

      this.mainFormId = mainFormId;
    }

    onQuestionChange(change: SubFormQuestionReference) {
      if (!this.value.config.default) {
        return;
      }

      this.$$patch(this.value.config.default, change);
    }
  }
