
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import type { TrackEntry } from '@app/models/field-integration/track-entry';
  import { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
  import { canResolveUsageReferenceRoute } from '@app/components/admin/sub-form-questions/sub-form-question-usage-routes';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import uniq from 'lodash/uniq';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { Select2, FilterSelect, SimpleGridTable } })
  export default class UsageTrackingTable extends Vue {
    @Prop(String) readonly title!: string;
    @Prop(Array) readonly trackedEntries?: TrackEntry[];

    manager: Nullable<ListManagerStatic<TrackEntry>> = null;

    activeOptions = [
      [true, this.$t('app.labels.active')],
      [false, this.$t('app.labels.inactive')],
    ];

    get availableFilterValues(): Record<'type' | 'subtype', string[]> {
      if (!this.trackedEntries) return { type: [], subtype: [] };

      return {
        type: uniq(this.trackedEntries?.map((x) => x['type'])),
        subtype: uniq(this.trackedEntries?.map((x) => x['subtype'])),
      };
    }

    get allFilters(): DonesafeFilterOptions<TrackEntry> {
      return this.manager ? { ...this.manager?.customFilters } : {};
    }

    @Watch('allFilters', { deep: true })
    onFiltersUpdate(val: DonesafeFilterOptions<TrackEntry>, oldVal: DonesafeFilterOptions<TrackEntry>): void {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.manager?.refresh();
      }
    }

    beforeMount(): void {
      const baseColumn = {
        width: 'minmax(90px, 25%)',
        titleClass: 'justify-content-center',
        dataClass: 'd-flex align-items-center justify-content-center text-center',
      };
      this.manager = new ListManagerStatic<TrackEntry>({
        localData: this.trackedEntries,
        per_page: -1,
        allowFilters: true,
        fields: [
          {
            ...baseColumn,
            title: this.$t('tenant.field_integration.usages.type'),
            name: 'type',
            filter: true,
            sortField: 'type',
          },
          {
            ...baseColumn,
            title: this.$t('tenant.field_integration.usages.subtype'),
            name: 'subtype',
            filter: true,
            sortField: 'subtype',
          },
          {
            ...baseColumn,
            title: this.$t('tenant.field_integration.usages.label_link'),
            name: 'label_link',
          },
          {
            ...baseColumn,
            title: this.$t('tenant.field_integration.usages.instances'),
            name: 'instances',
          },
          {
            ...baseColumn,
            title: this.$t('tenant.field_integration.usages.status'),
            name: 'status',
            filter: true,
            sortField: 'active',
          },
        ],
      });
    }

    canRedirectToReference = (entry: TrackEntry): boolean => canResolveUsageReferenceRoute(entry.reference_type);

    linkFor(entry: TrackEntry): string {
      if (!entry.reference_id) return '#';

      return `/admin/settings/sub_form_question_usages/${entry.reference_type}/${entry.reference_id}/${entry.usage_type}`;
    }
  }
