
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Location } from '@app/models/location';
  import type { ModuleName } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';
  import FallbackBadge from '@app/components/fallback-badge.vue';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import { mixins } from 'vue-class-component';

  import EntitySelector from '../entity-selector.vue';

  import LocationSelector from './location-selector.vue';

  @Component({ components: { FallbackBadge, LocationSelector, EntitySelector, ValidationObserver, ValidationProvider } })
  export default class LocationDetailsForm extends mixins(WithBootbox) {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop([String, Number]) readonly locationId!: string | number;

    location: Nullable<Location> = null;
    form: Partial<Location> = {};
    legacyModules: Pick<ModuleName, 'id' | 'name'>[] = [];

    get legacyCheck(): boolean {
      return !!this.legacyModules.length;
    }

    get parentLocationFilters(): DonesafeFilterOptions<Location> {
      if (this.location?.id) {
        return { '!id': this.location?.id };
      }
      return {};
    }

    get accountStore() {
      return useAccountStore();
    }

    async archiveOrRestore(location: Partial<Location>, active: boolean): Promise<void> {
      if (!(await this.confirm(this.$t('app.labels.are_you_sure')))) return;

      return this.onSubmit({ ...location, active });
    }

    async onSubmit(location: Partial<Location>): Promise<void> {
      if (!(await this.validator?.validate())) return;

      try {
        const { data } = await this.$api.updateLocation(Number(this.locationId), {
          ...location,
          location_id: location.location_id || null,
          scheme_id: location.scheme_id || null,
          timezone: location.timezone || '',
          location: undefined,
        } as Location);

        this.form = { ...this.form, ...data };
        this.$emit('update-location', this.form);
        this.$api.cache.clear();
        toaster(this.$t('tenant.settings.locations.update.location_updated'));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        toaster({ text: err.data.error, icon: 'error' });
      }
    }

    beforeMount(): void {
      this.$api
        .getModuleNames({ filters: { name: ['Hazard', 'Incident'], active: true }, only: ['id', 'name'] }, { cache: true })
        .then(({ data }) => {
          this.legacyModules = data;
        });
      this.$api
        .getLocation(
          Number(this.locationId),
          {
            only: [
              'active',
              'id',
              'name',
              'address_line_1',
              'address_line_2',
              'suburb',
              'state',
              'country',
              'postal_code',
              'scheme_id',
              'timezone',
              'hours_worked',
              'other_as_location_opt',
              'location_id',
              'external_uuid',
              { location: ['name'] },
            ],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.location = data;
          this.form = { ...this.location };
        });
    }
  }
