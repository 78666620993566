
  import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import { CalculationClass, CalculationMethod } from '@app/models/record-calculation';
  import { invert, upperFirst } from 'lodash';
  import { Tooltip, ProgressBar, ProgressBarStack } from 'uiv';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import bootbox from 'bootbox';
  import { useAccountStore } from '@app/stores/account';
  import { titleize } from '@app/utils/titleize';
  import consumer from '@app/channels/consumer';
  import type { Subscription } from '@rails/actioncable';

  type RecalculationNotification = {
    counter: number;
    ids: number[];
    recalculated_at: string | null;
    recalculating: boolean;
    total: number;
  };

  @Component({
    components: { BaseTable, Tooltip, DsDropdown, PaperTrailsModalLink, DsIconText, ProgressBar, ProgressBarStack },
    methods: { upperFirst, titleize },
  })
  export default class ModuleRecordCalculationsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<RecordCalculation>;

    subFormListsTitles: Record<string, string> = {};
    calculationTypeOptions = invert(CalculationMethod);
    recalculationProgress: Record<number, number> = {};

    emptyHandlingOptions = {
      zero: 'Zero',
      not_available: 'N/A',
    };

    manager: Nullable<ListManager<RecordCalculation>> = null;

    notificationsSubscription: Nullable<Subscription> = null;

    get accountStore() {
      return useAccountStore();
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    subscribeToNotifications(): Subscription {
      return consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'RecordCalculation' },
        {
          received: ({ ids, counter, recalculating, total }: RecalculationNotification) => {
            const progress = Math.round((counter * 100) / total);
            const recalculationProgress = this.recalculationProgress;

            ids.forEach((id) => {
              recalculationProgress[id] = recalculating ? progress : 0;
            });

            this.recalculationProgress = { ...recalculationProgress };

            if (!recalculating) {
              this.manager?.refresh();
            }
          },
        }
      );
    }

    progressLabel(rowData: RecordCalculation): string {
      return `${this.recalculationProgress[rowData.id]} %`;
    }

    showProgressBar(rowData: RecordCalculation): boolean {
      return rowData.calculation_class == CalculationClass.stored && rowData.recalculating && !!this.recalculationProgress[rowData.id];
    }

    showEmptyHandling(calculationMethod: CalculationMethod): boolean {
      return calculationMethod !== CalculationMethod.total;
    }

    showRecalculationTool({ calculation_class }: RecordCalculation): boolean {
      return calculation_class === CalculationClass.stored;
    }

    showRecalculatedAt(rowData: RecordCalculation): Maybe<string> {
      if (rowData.calculation_class == CalculationClass.stored) {
        if (!rowData.recalculated_at) return '';
        return this.accountStore.dateTimeFormat(String(rowData.recalculated_at));
      } else {
        return this.$t('app.labels.na');
      }
    }

    handleRecalculationToolClick(rowData: RecordCalculation): void {
      if (rowData.can_recalculate) {
        this.$router.replace({
          name: 'admin-settings-module-names-record-calculations-recalculate',
          params: { moduleNameId: `${this.moduleNameId}`, recordCalculationId: `${rowData.id}` },
          query: this.$route.query,
        });
      }
    }

    getManager(): ListManager<RecordCalculation> {
      return new ListManager<RecordCalculation>({
        fetchDataFunction: (params) => {
          return this.$api.getRecordCalculations({
            ...params,
            only: [
              'calculation_class',
              'calculation_method',
              'data_source',
              'formula',
              'id',
              'index',
              'name',
              'options',
              'permission_check',
              'sub_form_ids',
              'variable_code',
              'recalculating',
              'can_recalculate',
              'recalculated_at',
            ],
            per_page: -1,
            filters: { module_name_id: this.moduleNameId },
          });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index' },
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: '30px' },
          { title: this.$t('app.labels.title'), name: 'name', sortField: 'name', width: '200px' },
          { title: this.$t('app.labels.code'), name: 'variable_code', sortField: 'variable_code', width: 'minmax(280px, 1fr)' },
          { title: this.$t('app.labels.class'), name: 'calculation_class', sortField: 'calculation_class' },
          { title: this.$t('app.labels.method'), name: 'calculation_method', sortField: 'calculation_method' },
          { title: this.$t('app.labels.ui_options'), name: 'ui_options', sortField: 'calculation_method' },
          { title: this.$t('app.labels.last_recalculated'), name: 'recalculated_at', sortField: 'recalculated_at' },
          {
            title: '',
            name: 'actions',
            titleClass: 'justify-content-center',
            dataClass: 'text-center',
            width: 'minmax(50px, 0.5fr)',
          },
        ],
        allowFilters: true,
      });
    }

    mounted(): void {
      this.manager = this.getManager();
      this.notificationsSubscription = this.subscribeToNotifications();
    }

    beforeDestroy(): void {
      this.notificationsSubscription?.unsubscribe();
    }

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((_item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index] + 1,
        }));

        this.$api
          .updateRecordCalculationIndexes({ data, sort })
          .then(({ data }) => {
            this.$api.cache.clear();
            toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    onDeleteRecordCalculation(recordCalculationId: number): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.record_calculation.form.delete_this_record_calculation'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteRecordCalculation(recordCalculationId)
              .then(() => {
                this.reloadData('reload');
                toaster(this.$t('tenant.admin.record_calculation.modal.record_calculation_deleted'));
              })
              .catch(({ data }) => {
                toaster({ text: data.error, position: 'top-right', icon: 'error' });
              });
        },
      });
    }
  }
