import { render, staticRenderFns } from "./audit-report-generate-modal-button.vue?vue&type=template&id=1a20c76a&scoped=true&lang=pug"
import script from "./audit-report-generate-modal-button.vue?vue&type=script&lang=ts"
export * from "./audit-report-generate-modal-button.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a20c76a",
  null
  
)

export default component.exports