
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import type { Blob } from '@rails/activestorage';
  import DsModal from '@app/components/ds-modal.vue';
  import bootbox from 'bootbox';
  import Select2 from '../select2.vue';
  import FileUploader from '../file-uploader/file-uploader.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ScormCourse } from '@app/models/scorm-course';
  import { toaster } from '@app/utils/toaster';

  interface StringOption {
    code: string;
    label: string;
  }

  @Component({ components: { FileUploader, DsModal, Select2, ValidationObserver, ValidationProvider } })
  export default class ScormCourseFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly scormCourse!: ScormCourse;
    @Prop(Boolean) readonly submitDisabled?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<ScormCourse & { file: string }> = {};
    fileName: string | undefined = '';
    fetchingData = false;
    noLinkedQuestions = false;

    get existingRecord(): boolean {
      return !!this.form.id;
    }

    get courseTypeOptions(): StringOption[] {
      return [
        {
          code: 'imported',
          label: this.$t('tenant.admin.scorm_courses.form.course_type_options.import'),
        },
        {
          code: 'linked',
          label: this.$t('tenant.admin.scorm_courses.form.course_type_options.link'),
        },
      ];
    }

    get formTitle(): string {
      if (this.scormCourse.id) {
        return this.$t('tenant.admin.scorm_courses.edit.form_title');
      } else {
        return this.$t('tenant.admin.scorm_courses.new.form_title');
      }
    }

    get isActive(): boolean {
      return !!this.form.active;
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    onUpload(blob?: Blob): void {
      if (blob) {
        this.fileName = blob.filename;
        this.form = { ...this.form, file: blob.signed_id };
      } else {
        this.fileName = '';
        this.form = { ...this.form, file: undefined };
      }
    }

    archive(): void {
      const message = this.$t('tenant.admin.scorm_courses.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$emit('submit', { ...this.scormCourse, active: false });
          }
        },
      });
    }

    restore(): void {
      const message = this.$t('tenant.admin.scorm_courses.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$emit('submit', {
              ...this.scormCourse,
              filename: this.scormCourse.filename || undefined,
              active: true,
            });
          }
        },
      });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    getLinkedQuestions(): void {
      this.fetchingData = true;
      this.$api
        .getSubFormQuestions({
          only: ['id'],
          filters: {
            active: true,
            config: {
              scorm_course_id: this.form.id,
            },
          },
        })
        .then(({ data }) => {
          return (this.noLinkedQuestions = !data.length);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => {
          this.fetchingData = false;
        });
    }

    initForm(): void {
      this.getLinkedQuestions();
      this.form = { ...this.scormCourse, max_score: 100 };
      this.fileName = this.form.filename;
    }
  }
