
  import DefaultTemplateForm from '@app/components/admin/module-names/default-templates/default-template-form.vue';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import { toaster } from '@app/utils/toaster';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: { DsDefaultTemplateForm: DefaultTemplateForm } })
  export default class DefaultTemplatesNewPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    isLoading = false;
    moduleName: Nullable<ModuleName> = null;

    onSubmit(form: Partial<DefaultTemplate> & { responses: SubFormData }) {
      this.$api
        .createDefaultTemplate(form)
        .then(() => {
          toaster(this.$t('tenant.admin.default_templates.create.successfully'));
          this.$api.cache.clear();
          this.$router.replace({ name: 'admin-settings-module-names-default-templates', params: { moduleNameId: `${this.moduleNameId}` } });
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
    beforeMount() {
      this.isLoading = true;
      this.$api
        .getModuleName(Number(this.moduleNameId), {}, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
