
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import froalaConfig from '@app/froala-config';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import FieldTypeSpecificOptions from './field-type-specific-options.vue';
  import { canEdit } from '@app/services/model-helpers';
  import DefaultValueOptions from '@app/components/admin/questions/edit/default-value-options.vue';
  import QuestionShowHideConfigurator from '../question-show-hide-configurator.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import Select2 from '@app/components/select2.vue';
  import ProtectedInput from '@app/components/admin/questions/edit/protected-input.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { QuestionOptions, SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { humanize } from '@app/utils/humanize';
  import { titleize } from '@app/utils/titleize';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import DuplicateCodeQuestions from '@app/components/admin/questions/edit/duplicate-code-questions.vue';
  import { extend } from 'vee-validate';
  import DuplicateCodeQuestionsFinder, { DuplicateCategory } from './duplicate-code-questions-finder';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import { DatePickerUIOptions } from '@app/models/question-options/date-question-options';
  import type { ValidationRuleResult } from 'vee-validate/dist/types/types';

  type Config = BaseQuestionOptions & QuestionOptions;

  @Component({
    components: {
      DuplicateCodeQuestions,
      DsCheckbox,
      DsTextInput,
      FieldTypeSpecificOptions,
      QuestionShowHideConfigurator,
      DfltValueOptions: DefaultValueOptions,
      FormField,
      Select2,
      ProtectedInput,
    },
  })
  export default class TabFieldSettings extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;
    @Prop(Object) readonly features!: ModuleFeatures;

    fieldTypeOptions: [string, string][] = [];
    loaded = false;

    editingCode = false;
    froalaConfig = froalaConfig({ public: this.features.is_public });
    froalaConfigComplex = froalaConfig({ complex: true, public: this.features.is_public });

    duplicateCodeQuestionFinder: Nullable<DuplicateCodeQuestionsFinder> = null;

    get canSelectFieldType(): boolean {
      return this.newQuestion && this.canEdit && !this.approvalOrWorkflow;
    }

    get mfqlEnabled(): boolean {
      return this.features.mfql;
    }

    get supportsMfql(): boolean {
      return this.value.supports_mfql;
    }

    get canEdit() {
      return canEdit(this.value);
    }

    get approvalOrWorkflow() {
      return [FieldType.approval_state, FieldType.workflow_state].includes(this.value.field_type);
    }

    get newQuestion(): boolean {
      return !this.value?.id;
    }

    get displayFieldType(): string {
      return this.value.field_type && humanize(this.value.field_type).toUpperCase();
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get userCanCodeMatch(): boolean {
      return this.currentUserStore.hasSystemAdministrationPermission || this.currentUserStore.hasModuleConfigPermission;
    }

    get validateUniqueActiveCode(): boolean {
      return !this.value.config?.show?.id;
    }

    get requiredMessage(): string {
      return this.duplicateCodeQuestionFinder?.topCategoryIsError
        ? this.$t('tenant.admin.sub_form_questions.form.code_matches.code_match_requires_conditions')
        : '';
    }

    @Watch('value.code')
    updateFinderCode = () => this.updateFinder(true);

    @Watch('value.field_type')
    @Watch('value.config.show', { deep: true })
    updateFinderType = () => this.updateFinder(false);

    scrubFields() {
      const fields = [];
      if (this.value.field_type != FieldType.date) fields.push('date_picker_ui');

      fields.forEach((key) => this.$delete(this.value.config as Object, key));
    }

    onFieldTypeChange(fieldType: FieldType) {
      const config = this.value.config as Config | null;
      const isDateField = fieldType === FieldType.date;

      if (isDateField && !config?.date_picker_ui) {
        this.$$patch(this.value.config, { date_picker_ui: DatePickerUIOptions.date });
      } else if (!isDateField && config?.date_picker_ui) {
        this.scrubFields();
      }
    }

    async beforeMount() {
      if (this.canSelectFieldType) {
        await this.loadFieldTypeOptions();
      }
      this.editingCode = !this.value.id;

      extend('unique_active_code', {
        validate: this.uniqueActiveCodeValidation,
        message: this.$t('tenant.admin.sub_form_questions.form.code_matches.code_match_requires_conditions'),
      });

      extend('code_match_show_hide', {
        validate: this.uniqueActiveCodeValidation,
        message: () => {
          const clashes = this.duplicateCodeQuestionFinder?.codeClashes() || [];
          if (clashes.length) {
            return this.$t('tenant.admin.sub_form_questions.form.code_matches.validation_code_clashes', { values: clashes.join(', ') });
          } else {
            return this.$t('tenant.admin.sub_form_questions.form.code_matches.missing_show_hide_rules');
          }
        },
      });
      this.onFieldTypeChange(this.value.field_type);
      this.loaded = true;
    }

    async loadFieldTypeOptions() {
      const { data: fieldTypeOptions } = await this.$api.getSubFormFieldTypeOptions(this.subForm.id, { cache: true });
      this.fieldTypeOptions = fieldTypeOptions.map((o) => [o, titleize(o)]);

      if (!this.value.field_type && fieldTypeOptions[0]) this.$$patch(this.value, { field_type: fieldTypeOptions[0] });
    }

    mounted(): void {
      this.duplicateCodeQuestionFinder = new DuplicateCodeQuestionsFinder(this.value, this.subForm, this.editingCode);
      this.duplicateCodeQuestionFinder.refresh();
    }

    updateFinder(refresh: boolean = false): void {
      if (this.duplicateCodeQuestionFinder) {
        this.duplicateCodeQuestionFinder.sourceQuestion = this.value;
        this.duplicateCodeQuestionFinder.editingCode = this.editingCode;
        refresh ? this.duplicateCodeQuestionFinder.debounceRefresh() : this.duplicateCodeQuestionFinder.recalculate();
      }
    }

    codeEditionToggle(enabled: boolean): void {
      this.editingCode = enabled;
      this.updateFinder(false);
    }

    uniqueActiveCodeValidation(): boolean | Promise<ValidationRuleResult> {
      if (this.duplicateCodeQuestionFinder) {
        return this.duplicateCodeQuestionFinder
          .refresh()
          .then(({ errorCategory, category }) => {
            if (this.userCanCodeMatch) {
              return {
                valid:
                  errorCategory !== DuplicateCategory.CannotMatch &&
                  errorCategory !== DuplicateCategory.NoShowHideRules &&
                  errorCategory !== DuplicateCategory.MissingShowHideRules,
              };
            } else {
              return { valid: category === DuplicateCategory.Inactive || category === DuplicateCategory.NoMatches };
            }
          })
          .catch(({ data }) => {
            return {
              valid: false,
              data: data?.error || this.$t('app.labels.something_went_wrong'),
            };
          });
      } else {
        return true;
      }
    }
  }
