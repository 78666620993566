
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import Draggable from 'vuedraggable';
  import FormField from '../edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { DEFAULT_APPROVAL_STATE_CONFIG } from '@app/models/sub-form-question';
  import type { ApprovalStateQuestionOptions } from '@app/models/question-options/approval-state-question-options';

  @Component({
    components: { Draggable, Select2, FormField },
  })
  export default class ApprovalStateFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<ApprovalStateQuestionOptions>;

    @Prop(Object) subForm!: SubForm;

    newStage = '';

    beforeMount(): void {
      const { stages = DEFAULT_APPROVAL_STATE_CONFIG.stages, approved_step = '' } = this.value.config;

      this.$$patch(this.value.config, { stages, approved_step });
    }

    addStage(): void {
      if (!this.newStage) {
        return;
      }

      this.$$patch(this.value.config, { stages: [...this.stages, this.newStage] });
      this.newStage = '';
    }

    removeStage(index: number) {
      this.$$patch(this.value.config, { stages: this.stages.filter((_, i) => i !== index) });
    }

    get stages() {
      return this.value.config.stages;
    }
  }
