import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
import type { PlaceholderProfile } from '@app/models/placeholder-profile';

import type { AutomationDefinition } from './automation-definition';
import type { BaseEntity } from './base-entity';
import type { Dictionary } from './dictionary';
import type { ScheduledEvent } from './scheduled-event';

export const NEW_RECORD_COLLECTIONS_PREFIX = 'new_record_collection_';
export const NEW_COMPLETION_COLLECTIONS_PREFIX = 'new_completion_collection_';
export const NEW_USER_COLLECTION = 'new_user_collection';
export const RECORD_COLLECTIONS_PREFIX = 'record_collection_';
export const COMPLETION_COLLECTIONS_PREFIX = 'completion_collection_';

export type EventSelectableOptions = Record<string, EventSelectableOption>;

export type EventSelectableOption = Record<string, string>;
export interface EventFormFieldPair {
  key?: string;
  value: string;
}
export interface EventOptionsDictionary {
  [index: number]: EventFormFieldPair;
}

export interface UpdateTarget {
  record_id: string;
  record_type: string;
}

export interface EventOptions {
  data?: EventOptionsDictionary;
  file?: EventOptionsDictionary;
  header?: EventOptionsDictionary;
  query?: EventOptionsDictionary;
  raw_data?: string;
  target?: UpdateTarget;
  values?: Dictionary<string>;
}

export interface Event extends BaseEntity {
  automation_definition?: AutomationDefinition;
  //legacy
  chained_events?: Event[];
  collection_rules?: Nullable<QueryBuilderBlob>;
  creation_display?: string;
  deleted?: boolean;
  enable_retries?: boolean;
  event_type: string;
  event_type_name?: string;
  mapping?: 'inline';
  name?: string;
  notification_email?: Nullable<string>;
  options?: EventOptions;
  parent_automation_definition?: AutomationDefinition;
  parent_scheduled_events?: ScheduledEvent[];
  per: string | null;
  placeholder_profile_handles?: string[];
  placeholder_profiles?: PlaceholderProfile[];
  retries?: number;
  scheduled_events?: ScheduledEvent[];
}

export interface CallToAction {
  klass?: 'CallToAction';
  link?: string;
  text?: string;
}
