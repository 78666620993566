
  import DashboardFormModal from '@app/components/admin/dashboards/dashboard-form-modal.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { Dashboard } from '@app/models/dashboard';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DashboardFormModal } })
  export default class AdminDashboardsNewPage extends Vue {
    dashboard: Partial<Dashboard> = {};
    visible = true;
    submitDashboardForm(data: Partial<Dashboard>): void {
      this.$api
        .createDashboard(data)
        .then(() => {
          this.$api.cache.clear();
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-dashboards-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
