import { render, staticRenderFns } from "./domo-widget.vue?vue&type=template&id=2451ade2&scoped=true&lang=pug"
import script from "./domo-widget.vue?vue&type=script&lang=ts"
export * from "./domo-widget.vue?vue&type=script&lang=ts"
import style0 from "./domo-widget.vue?vue&type=style&index=0&id=2451ade2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2451ade2",
  null
  
)

export default component.exports