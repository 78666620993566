
  import { extend } from 'vee-validate';
  import { Component } from 'vue-property-decorator';

  import Select2 from '../../select2.vue';
  import FormField from '../questions/edit/form-field.vue';

  import BaseForEverySelector from './base-for-every-selector';

  @Component({ components: { Select2, FormField } })
  export default class EventForEverySelector extends BaseForEverySelector {
    isValueInvalid(valueToCheck: string): boolean {
      return !this.perOptions.some(({ value }) => `${value}` === `${valueToCheck}`);
    }

    mounted() {
      extend('forEveryRequired', {
        validate: (value: string) => !this.isValueInvalid(value),
        message: this.$t('tenant.admin.events.form_fields.for_every_required'),
      });
    }
  }
