
  import { Component, Vue } from 'vue-property-decorator';
  import AdminSamlForm from '@app/components/admin/samls/form.vue';
  import type { Saml } from '@app/models/saml';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { AdminSamlForm } })
  export default class AdminSettingsSamlNew extends Vue {
    loading = false;

    createSaml(saml: Saml, toast: string): void {
      this.loading = true;
      this.$api
        .createSaml(saml)
        .then(() => {
          toaster(toast);
          this.$api.cache.clear();
          this.$router.replace({
            name: 'admin-settings-samls-index',
            query: this.$route.query,
          });
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }))
        .finally(() => {
          this.loading = false;
        });
    }
  }
