
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  import SelectResponseList from '../../../question-response/select-response-list.vue';

  type Question = SubFormQuestion<SubFormQuestion['options'] & BaseSelectQuestionOptions>;

  @Component({
    components: { ScoredQuestionConfig, SelectResponseList },
  })
  export default class MultiSelectFieldOptions extends Vue {
    @Model('input') readonly value!: Question;

    @Prop(Object) readonly features!: ModuleFeatures;

    get hasScore(): boolean {
      return this.value?.config?.scored + '' === 'true';
    }

    beforeMount() {
      this.populateDefaultValues();
    }

    populateDefaultValues() {
      const { values: defaultValues = [] } = this.value.config.default || {};

      defaultValues.forEach((valueKey) => {
        const options = this.value.options.values;

        if (!options) {
          return;
        }

        const key = Object.keys(options).find((k) => options[k].key === valueKey);

        if (!key || !options[key]) {
          return;
        }

        this.$$patch(options[key], { is_default: true });
      });
    }

    updateDefaults(options: Record<string, SelectOption>) {
      const values = Object.values(options)
        .filter(({ is_default }) => is_default)
        .map(({ key }) => key);

      this.$$patch(this.value.config.default, { values });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      this.$delete(this.value.options, 'values');
    }
  }
