import AdminLookAndLogoPage from '@app/components/admin/admin-look-and-logo-page.vue';
import AdminModuleRecordImportsPage from '@app/components/admin/admin-module-record-imports-page.vue';
import AdminDashboardsEditPage from '@app/components/admin/dashboards/admin-dashboards-edit-page.vue';
import AdminDashboardsNewWidgetPage from '@app/components/admin/dashboards/admin-dashboards-new-widget-page.vue';
import AdminDashboardsEditWidgetPage from '@app/components/admin/dashboards/admin-dashboards-edit-widget-page.vue';
import AdminDashboardsNewPage from '@app/components/admin/dashboards/admin-dashboards-new-page.vue';
import AdminDashboardsPage from '@app/components/admin/dashboards/admin-dashboards-page.vue';
import AdminDashboardsShowPage from '@app/components/admin/dashboards/admin-dashboards-show-page.vue';
import AdminModuleNameWorkflowPage from '@app/components/admin/module-names/admin-module-name-workflow-page.vue';
import DefaultTemplatesEditPage from '@app/components/admin/module-names/default-templates/default-templates-edit-page.vue';
import DefaultTemplatesNewPage from '@app/components/admin/module-names/default-templates/default-templates-new-page.vue';
import ModuleNamesListPage from '@app/components/admin/module-names/module-names-list-page.vue';
import ModuleTabEditPage from '@app/components/admin/module-tabs/module-tab-edit-page.vue';
import ModuleTabNewPage from '@app/components/admin/module-tabs/module-tab-new-page.vue';
import ModuleTabRouter from '@app/components/admin/module-tabs/module-tab-router.vue';
import AdminNotificationsDetailsPage from '@app/components/admin/notifications/admin-notifications-details-page.vue';
import AdminNotificationsEditPage from '@app/components/admin/notifications/admin-notifications-edit-page.vue';
import AdminNotificationsIndexPage from '@app/components/admin/notifications/admin-notifications-index-page.vue';
import AdminNotificationsUsersPage from '@app/components/admin/notifications/admin-notifications-users-page.vue';
import EmploymentTypesTab from '@app/components/admin/user-settings/tabs/employment-types-tab.vue';
import GenderListTab from '@app/components/admin/user-settings/tabs/gender-list-tab.vue';
import AdminTransactionLedgersPage from '@app/components/expensable/admin-transaction-ledgers-page.vue';
import IndicatorSetShow from '@app/pages/admin/indicator-sets/show.vue';
import IndicatorSetEdit from '@app/pages/admin/indicator-sets/edit.vue';
import IndicatorSetNew from '@app/pages/admin/indicator-sets/new.vue';
import IndicatorEdit from '@app/pages/admin/indicators/edit.vue';
import IndicatorNew from '@app/pages/admin/indicators/new.vue';
import UserSettingsTab from '@app/components/admin/user-settings/tabs/user-settings-tab.vue';
import WorkplaceIndustryTab from '@app/components/admin/user-settings/tabs/workplace-industry-tab.vue';
import AdminAccountSettingsPage from '@app/components/admin/admin-account-settings-page.vue';
import LocationDetailsForm from '@app/components/location/location-details-form.vue';
import LocationActiveUsersForm from '@app/components/location/location-active-users-form.vue';
import LocationTagCountsForm from '@app/components/location/location-tag-counts-form.vue';
import SubFormListEditPage from '@app/components/sub-form-list/sub-form-list-edit-page.vue';
import SubFormListNewPage from '@app/components/sub-form-list/sub-form-list-new-page.vue';
import SubFormListRestrictionsPage from '@app/components/sub-form-list/sub-form-list-restrictions-page.vue';
import SubFormListViewOptionsPage from '@app/components/sub-form-list/sub-form-list-view-options-page.vue';
import AdminSettingsLocationTagsEdit from '@app/pages/admin/location-tags/edit.vue';
import NewLayoutPage from '@app/pages/admin/mobile-apps/new-layout-page.vue';
import TestRegistrationPage from '@app/pages/admin/mobile-apps/test-registration-page.vue';
import AdminSubFormsListPage from '@app/components/admin/sub-forms/admin-sub-forms-list-page.vue';
import AdminSubFormEditPage from '@app/components/admin/sub-forms/admin-sub-forms-edit.vue';
import AdminSubFormNewPage from '@app/components/admin/sub-forms/admin-sub-forms-new.vue';
import AdminSubFormShowPage from '@app/components/admin/sub-forms/admin-sub-forms-show.vue';
import {
  AdminAdministrativeRolesPage,
  AdminAppBundlesInstallationsPage,
  AdminAppBundlesPage,
  AutomationDefinitionEdit,
  AutomationDefinitionNew,
  AutomationDefinitionsPage,
  EditAuditReportTemplateFormModal,
  EditRecordCalculationFormModal,
  ModuleAuditReportTemplatesPage,
  ModuleQuestionSetsPage,
  ModuleNameEditPage,
  ModuleNameNewPage,
  ModuleRecordCalculationsPage,
  ModuleScoreBandsPage,
  ModuleScoringGroupsPage,
  ModuleScoringOptionsPage,
  ModuleShowOptionsPage,
  NewAuditReportTemplateFormModal,
  NewRecordCalculationFormModal,
  QuestionSetsFormModal,
  AdminScheduledEventsPage,
  AllImportsPage,
  ModuleTabsPage,
  ModuleIndicatorSetsPage,
  ModuleInvolvementsPage,
  AdminModuleNameImportPage,
  ModuleRelationshipsPage,
  ModuleFormWizardPage,
} from '@app/components';
import RecalculationAssistantModal from '@app/components/admin/module-names/record-calculations-form-modal/recalculation-assistant-modal.vue';
import ViewConfigPage from '@app/pages/admin/mobile-apps/view-config-page.vue';
import AdminSettingsLocationsIndex from '@app/pages/admin/locations/index.vue';
import AdminSettingsLocationTagsNew from '@app/pages/admin/location-tags/new.vue';
import NewConfigPage from '@app/pages/admin/mobile-apps/new-config-page.vue';
import AdminSettingsLocationEdit from '@app/pages/admin/locations/edit.vue';
import AdminSettingsSamlNew from '@app/pages/admin/samls/new.vue';
import AdminSettingsLocationNew from '@app/pages/admin/locations/new.vue';
import AdminSettingsCostCentresIndex from '@app/pages/admin/cost_centres/index.vue';
import AdminRegulatoryReportingPage from '@app/components/admin/admin-regulatory-reporting-page.vue';
import AdminSettingsRestrictionMethodsPage from '@app/components/admin/admin-settings-restriction-methods-page.vue';
import AdminSettingsOrganizationEdit from '@app/pages/admin/organizations/edit.vue';
import AdminSettingsSamlEdit from '@app/pages/admin/samls/edit.vue';
import ScoreBandProfileFormModal from '@app/components/score-band-profile/score-band-profile-form-modal.vue';
import AdminSettingsOrganizationNew from '@app/pages/admin/organizations/new.vue';
import AdminSettingsCostCentreNew from '@app/pages/admin/cost_centres/new.vue';
import AdminSettingsCostCentreEdit from '@app/pages/admin/cost_centres/edit.vue';
import AutomationDefinitionClone from '@app/components/admin/automation-definitions/automation-definition-clone.vue';
import NewRegistrationPage from '@app/pages/admin/mobile-apps/new-registration-page.vue';
import ViewRegistrationPage from '@app/pages/admin/mobile-apps/view-registration-page.vue';
import AdminSettingsOrganizationsIndex from '@app/pages/admin/organizations/index.vue';
import ScoringGroupFormModal from '@app/components/scoring-group/scoring-group-form-modal.vue';
import EditConfigPage from '@app/pages/admin/mobile-apps/edit-config-page.vue';
import ModuleScoringPage from '@app/components/admin/modules/module-scoring-page.vue';
import AdminRegulatoryReportsPage from '@app/components/admin/admin-regulatory-reports-page.vue';
import ConfigsListPage from '@app/pages/admin/mobile-apps/configs-list-page.vue';
import AdminSettingsLocationTagsIndex from '@app/pages/admin/location-tags/index.vue';
import EditRegistrationPage from '@app/pages/admin/mobile-apps/edit-registration-page.vue';
import ViewSvgTemplatePage from '@app/pages/admin/svg-templates/view-template-page.vue';
import EditSvgTemplatePage from '@app/pages/admin/svg-templates/edit-template-page.vue';
import NewSvgTemplatePage from '@app/pages/admin/svg-templates/new-template-page.vue';
import SvgTemplatesIndexPage from '@app/pages/admin/svg-templates/index-page.vue';
import EditLayoutPage from '@app/pages/admin/mobile-apps/edit-layout-page.vue';
import AdminSettingsSamlsIndex from '@app/pages/admin/samls/index.vue';
import UserCollectionsIndex from '@app/components/admin/user-collections/user-collections-index.vue';
import EditUserCollection from '@app/components/admin/user-collections/edit-user-collection.vue';
import CreateUserCollection from '@app/components/admin/user-collections/create-user-collection.vue';
import type { RouteConfig } from 'vue-router/types/router';
import TabSectionsListPage from '@app/components/admin/module-tabs/tab-sections-list-page.vue';
import AdminRolesPage from '@app/pages/admin/roles/admin-roles-page.vue';
import AdminRolesEdit from '@app/pages/admin/roles/admin-roles-edit.vue';
import AdminRolesNew from '@app/pages/admin/roles/admin-roles-new.vue';
import RoleTabUsersNewModal from '@app/components/admin/roles/role-tab-users-new-modal.vue';
import RoleTabDetails from '@app/components/admin/roles/role-tab-details/role-tab-details.vue';
import RoleTabUsers from '@app/components/admin/roles/role-tab-users.vue';
import type { Route } from 'vue-router';
import HourTypesIndex from '@app/pages/admin/hour-types/index.vue';
import HourTypeNew from '@app/pages/admin/hour-types/new.vue';
import HourTypeEdit from '@app/pages/admin/hour-types/edit.vue';
import ActionPriorityEdit from '@app/components/admin/actions/action-priority-edit.vue';
import ActionPriorityNew from '@app/components/admin/actions/action-priority-new.vue';
import ScheduledEventsEdit from '@app/pages/admin/scheduled-events/scheduled-events-edit.vue';
import ScheduledEventsNew from '@app/pages/admin/scheduled-events/scheduled-events-new.vue';
import SubFormQuestionUsageRouter from '@app/components/admin/sub-form-questions/sub-form-question-usage-router.vue';
import hybridModulesRoutes from '@app/components/admin/hybrid-modules/routes';
import ModuleDashboardPanesPage from '@app/components/admin/modules/module-dashboard-panes-page.vue';
import DashboardPaneGroups from '@app/components/admin/dashboard-panes/dashboard-pane-groups.vue';
import DashboardPaneEdit from '@app/components/admin/dashboard-panes/dashboard-pane-edit.vue';
import UserDetailsPage from '@app/components/admin/users/user-details-page.vue';
import UserTagsPage from '@app/components/admin/users/user-tags-page.vue';
import UserActiveLocations from '@app/components/admin/users/user-active-locations.vue';
import UserManagerFor from '@app/components/admin/users/user-manager-for.vue';
import UserAcknowledgementsPage from '@app/components/admin/users/user-acknowledgements-page.vue';
import UserNotificationsTab from '@app/components/admin/users/user-notifications-tab.vue';
import UserTimesheetsPage from '@app/components/admin/users/user-timesheets-page.vue';
import UserActiveOrganizations from '@app/components/admin/users/user-active-organizations.vue';
import UserPersonalDetailsTab from '@app/components/admin/users/user-personal-details-tab.vue';
import UserPayDetailsTab from '@app/components/admin/users/user-pay-details-tab.vue';
import UserDocumentsTable from '@app/components/user/user-documents-table.vue';
import UserAdminPermissionsPage from '@app/components/admin/users/user-admin-permissions-page.vue';
import EditUserPage from '@app/components/admin/users/edit-user-page.vue';
import NewUserPage from '@app/components/admin/users/new-user-page.vue';
import ModuleBudgetsPage from '@app/components/admin/modules/module-budgets-page.vue';
import ModuleSubFormPage from '@app/components/admin/modules/module-sub-form-page.vue';
import ModuleNameShowPage from '@app/components/admin/module-names/module-name-show-page.vue';
import ModuleTabSectionGroupsPage from '@app/components/tab-section-group/module-tab-section-groups-page.vue';

import AdminUsersPage from './components/admin/admin-users-page.vue';
import { AdminPermissionFeature } from './models/admin-permission';
import { defaultFiltersGuardFactory, featureGuardFactory, permissionGuardFactory, subFormModuleRedirectGuard } from './services/guards';
import CalculationRulesEditPage from './pages/admin/calculation-rules/calculation-rules-edit-page.vue';
import CalculationRulesNewPage from './pages/admin/calculation-rules/calculation-rules-new-page.vue';
import CalculationRulesIndexPage from './pages/admin/calculation-rules/calculation-rules-index-page.vue';
import MenuCollectionsEdit from './pages/admin/menu_collections/menu-collections-edit.vue';
import MenuCollectionsNew from './pages/admin/menu_collections/menu-collections-new.vue';
import MenuCollectionsPage from './pages/admin/menu_collections/menu-collections-page.vue';
import PlaceholderProfilesEdit from './pages/admin/placeholder_profiles/placeholder-profiles-edit.vue';
import PlaceholderProfilesNew from './pages/admin/placeholder_profiles/placeholder-profiles-new.vue';
import PlaceholderProfilesPage from './pages/admin/placeholder_profiles/placeholder-profiles-page.vue';
import EventOccurrencesIndex from './pages/admin/event-occurrences/event-occurrences-index.vue';
import type { EventOccurrence } from './models/event-occurrence';
import ModuleConfidentialityTypesPage from './components/admin/modules/module-confidentiality-types-page.vue';
import ModuleDefaultTemplatesPage from './components/admin/modules/module-default-templates-page.vue';
import ModuleFeaturesPage from './components/admin/modules/module-features-page.vue';
import AdminAuditTrailsPage from './components/admin/admin-audit-trails-page.vue';
import AdminUserSettingsPage from './components/admin/user-settings/admin-user-settings-page.vue';
import ScheduledEventsEventOccurrences from './components/admin/scheduled-events/scheduled-events-event-occurrences.vue';
import ModuleRoleProfilesPage from './components/admin/modules/module-role-profiles-page.vue';
import RoleProfileFormModal from './components/admin/module-names/role-profile-form-modal.vue';
import AutomationDefinitionEventOccurrences from './components/admin/automation-definitions/automation-definition-event-occurrences.vue';
import AdminActionSettingsPage from './components/admin/admin-action-settings-page.vue';
import ModuleAutomationDefinitionsPage from './components/admin/modules/module-automation-definitions-page.vue';
import { changeLocation } from './utils/change-location';

const adminRoutes: RouteConfig[] = [
  {
    path: '/admin/settings/dashboards/',
    component: AdminDashboardsPage,
    name: 'admin-dashboards-index',
    children: [
      {
        path: 'new',
        name: 'admin-dashboards-new',
        component: AdminDashboardsNewPage,
      },
    ],
  },
  {
    path: '/admin/settings/dashboard_panes/:id',
    name: 'admin-dashboard-pane-edit',
    props: true,
    component: DashboardPaneEdit,
    children: [
      {
        path: 'widgets/new',
        name: 'admin-dashboard-pane-edit-new-widget',
        component: AdminDashboardsNewWidgetPage,
        props: (route) => ({ dashboardPaneId: Number(route.params.id) }),
      },
      {
        path: 'widgets/:widgetId',
        name: 'admin-dashboard-pane-edit-widget',
        component: AdminDashboardsEditWidgetPage,
        props: (route) => ({ dashboardPaneId: Number(route.params.id), widgetId: Number(route.params.widgetId) }),
      },
    ],
  },
  {
    path: '/admin/settings/dashboard_pane_groups/',
    name: 'admin-dashboard-pane-groups-index',
    component: DashboardPaneGroups,
    beforeEnter: featureGuardFactory([AdminPermissionFeature.reporting, AdminPermissionFeature.roles_and_tags]),
  },
  {
    path: '/admin/settings/users/',
    component: AdminUsersPage,
    name: 'admin-users-index',
  },
  {
    path: '/admin/settings/users/new',
    name: 'new-user-page',
    component: NewUserPage,
  },
  {
    path: '/admin/settings/users/:userId/edit/',
    name: 'edit-user-page',
    redirect: { name: 'admin-user-details-page' },
    component: EditUserPage,
    props: (route) => {
      const match = route.path.match(/\/admin\/settings\/users\/\d+\/edit\/([^\/]*)$/);
      return { userId: Number(route.params.userId), tabName: match && match[1] };
    },
    children: [
      {
        path: 'details',
        name: 'admin-user-details-page',
        component: UserDetailsPage,
        props: (route) => (route.params.userId ? { userId: Number(route.params.userId) } : {}),
      },
      {
        path: 'tags',
        name: 'admin-user-tags-page',
        component: UserTagsPage,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'active_locations',
        name: 'admin-user-active-locations',
        component: UserActiveLocations,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'manager_for',
        name: 'admin-user-manager-for',
        component: UserManagerFor,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'acknowledgments',
        name: 'admin-user-acknowledgments-page',
        component: UserAcknowledgementsPage,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'notifications',
        name: 'admin-user-notifications-tab',
        component: UserNotificationsTab,
        props: (route) => ({ userId: Number(route.params.userId), admin: true }),
      },
      {
        path: 'timesheets',
        name: 'admin-user-timesheets-page',
        component: UserTimesheetsPage,
        props: (route) => ({ userId: Number(route.params.userId), admin: true }),
      },
      {
        path: 'active_organisations',
        name: 'admin-user-active-organizations',
        component: UserActiveOrganizations,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'personal_details',
        name: 'admin-user-personal-details-tab',
        component: UserPersonalDetailsTab,
        props: (route) => ({ userId: Number(route.params.userId), admin: true }),
      },
      {
        path: 'pay_details',
        name: 'admin-user-pay-details-tab',
        component: UserPayDetailsTab,
        props: (route) => ({ userId: Number(route.params.userId), admin: true }),
      },
      {
        path: 'documents',
        name: 'admin-user-documents-table',
        component: UserDocumentsTable,
        props: (route) => ({ userId: Number(route.params.userId) }),
      },
      {
        path: 'admin_permissions',
        name: 'admin-user-admin-permissions-page',
        component: UserAdminPermissionsPage,
        props: (route) => ({ userId: Number(route.params.userId) }),
        beforeEnter: featureGuardFactory(AdminPermissionFeature.system_administration),
      },
    ],
  },
  {
    path: '/admin/settings/notifications/',
    component: AdminNotificationsIndexPage,
    name: 'admin-notifications-index',
  },
  {
    path: '/admin/settings/notifications/:id/',
    name: 'admin-notifications-edit',
    props: true,
    redirect: { name: 'admin-notifications-details' },
    component: AdminNotificationsEditPage,
    children: [
      {
        path: 'edit',
        name: 'admin-notifications-details',
        props: true,
        component: AdminNotificationsDetailsPage,
      },
      {
        path: 'users',
        name: 'admin-notifications-users',
        props: true,
        component: AdminNotificationsUsersPage,
      },
    ],
  },
  {
    path: '/admin/settings/module_names/new',
    name: 'admin-settings-admin-module-name-new',
    component: ModuleNameNewPage,
    beforeEnter: permissionGuardFactory(
      'current_user',
      'admin',
      permissionGuardFactory('current_user', 'internal'),
      '/admin/settings/module_names'
    ),
  },
  {
    path: '/admin/settings/module_names/:moduleNameId/',
    name: 'admin-module-name-show-page',
    redirect: { name: 'admin-settings-module-names-workflow' },
    props: true,
    component: ModuleNameShowPage,
    children: [
      {
        path: 'dashboard_panes',
        name: 'admin-dashboard-panes-index',
        props: true,
        component: ModuleDashboardPanesPage,
      },
      {
        path: 'dashboard_panes/:id/edit',
        name: 'admin-module-dashboard-panes-edit',
        props: ({ params }: Route) => ({ id: Number(params.id), moduleNameId: Number(params.moduleNameId) }),
        component: DashboardPaneEdit,
        children: [
          {
            path: 'widgets/new',
            name: 'admin-module-dashboard-panes-edit-new-widget',
            component: AdminDashboardsNewWidgetPage,
            props: (route) => ({ dashboardPaneId: Number(route.params.id) }),
          },
          {
            path: 'widgets/:widgetId',
            name: 'admin-module-dashboard-panes-edit-widget',
            component: AdminDashboardsEditWidgetPage,
            props: (route) => ({ dashboardPaneId: Number(route.params.id), widgetId: Number(route.params.widgetId) }),
          },
        ],
      },
      {
        path: 'scoring',
        name: 'admin-settings-module-name-scoring',
        props: true,
        redirect: { name: 'admin-settings-module-name-scoring-module-scoring' },
        component: ModuleScoringPage,
        children: [
          {
            path: 'score_bands',
            name: 'admin-settings-module-name-scoring-score-bands',
            props: true,
            component: ModuleScoreBandsPage,
            children: [
              {
                path: 'new',
                name: 'admin-settings-module-name-scoring-score-bands-new',
                component: ScoreBandProfileFormModal,
                props: true,
              },
              {
                path: ':scoreBandId/edit',
                name: 'admin-settings-module-name-scoring-score-bands-edit',
                component: ScoreBandProfileFormModal,
                props: true,
              },
            ],
          },
          {
            path: 'scoring_groups',
            name: 'admin-settings-module-name-scoring-scoring-groups',
            props: true,
            component: ModuleScoringGroupsPage,
            children: [
              {
                path: 'new',
                props: true,
                name: 'admin-settings-module-name-scoring-scoring-groups-new',
                component: ScoringGroupFormModal,
              },
              {
                path: ':scoringGroupId/edit',
                props: true,
                name: 'admin-settings-module-name-scoring-scoring-groups-edit',
                component: ScoringGroupFormModal,
              },
            ],
          },
          {
            path: 'module_scoring',
            name: 'admin-settings-module-name-scoring-module-scoring',
            props: true,
            component: ModuleScoringOptionsPage,
          },
        ],
      },
      {
        path: 'question_sets',
        props: true,
        name: 'admin-settings-module-names-question-sets',
        component: ModuleQuestionSetsPage,
        children: [
          {
            path: 'new',
            props: true,
            name: 'admin-settings-module-names-question-sets-new',
            component: QuestionSetsFormModal,
          },
          {
            path: ':questionSetId/edit',
            props: true,
            name: 'admin-settings-module-names-question-sets-edit',
            component: QuestionSetsFormModal,
          },
        ],
      },
      {
        path: 'show_options',
        name: 'admin-settings-module-names-show-options',
        component: ModuleShowOptionsPage,
        props: true,
      },
      {
        path: 'show_options/calendars/:calendarId',
        name: 'admin-settings-module-names-show-options-calendar',
        component: ModuleShowOptionsPage,
        props: true,
      },
      {
        path: 'features',
        name: 'admin-settings-module-names-features',
        component: ModuleFeaturesPage,
        props: true,
      },
      {
        path: 'role_profiles',
        name: 'admin-settings-module-names-role-profiles',
        component: ModuleRoleProfilesPage,
        props: (to: Route) => ({ moduleNameId: Number(to.params.moduleNameId) }),
        children: [
          {
            path: 'new',
            name: 'admin-settings-module-names-role-profiles-new',
            component: RoleProfileFormModal,
            props: (to: Route) => ({ moduleNameId: Number(to.params.moduleNameId) }),
          },
          {
            path: ':id/edit',
            name: 'admin-settings-module-names-role-profiles-edit',
            component: RoleProfileFormModal,
            props: (to: Route) => ({
              roleProfileId: Number(to.params.id),
              moduleNameId: Number(to.params.moduleNameId),
            }),
          },
        ],
      },
      {
        path: 'workflow',
        name: 'admin-settings-module-names-workflow',
        component: AdminModuleNameWorkflowPage,
        props: true,
      },
      {
        path: 'involvements',
        name: 'admin-involvements-index',
        props: true,
        component: ModuleInvolvementsPage,
      },
      {
        path: 'importer',
        name: 'admin-module-name-import-page',
        props: true,
        component: AdminModuleNameImportPage,
      },
      {
        path: 'relationships',
        name: 'admin-module-name-relationships-page',
        props: true,
        component: ModuleRelationshipsPage,
      },
      {
        path: 'indicator_sets',
        name: 'admin-indicator-set-index',
        props: true,
        component: ModuleIndicatorSetsPage,
      },
      {
        path: 'wizard',
        name: 'admin-wizard',
        props: true,
        component: ModuleFormWizardPage,
      },
      {
        path: 'indicator_sets/:indicatorSetId/edit',
        name: 'admin-indicator-set-edit',
        props: true,
        component: IndicatorSetEdit,
      },
      {
        path: 'indicator_sets/new',
        name: 'admin-indicator-set-new',
        props: true,
        component: IndicatorSetNew,
      },
      {
        path: 'indicator_sets/:indicatorSetId',
        name: 'admin-indicator-set-show',
        props: true,
        component: IndicatorSetShow,
      },
      {
        path: 'indicator_sets/:indicatorSetId/indicators',
        name: 'admin-settings-indicators',
        redirect: { name: 'admin-indicator-set-show' },
      },
      {
        path: 'indicator_sets/:indicatorSetId/indicators/:indicatorId/',
        name: 'admin-indicator-show',
        redirect: { name: 'admin-settings-indicator-edit' },
        component: IndicatorEdit,
      },
      {
        path: 'indicator_sets/:indicatorSetId/indicators/new/',
        name: 'admin-settings-indicator-new',
        props: true,
        component: IndicatorNew,
      },
      {
        path: 'indicator_sets/:indicatorSetId/indicators/:indicatorId/edit/',
        name: 'admin-settings-indicator-edit',
        props: true,
        component: IndicatorEdit,
      },
      {
        path: 'budgets',
        name: 'admin-module-name-budgets-page',
        props: true,
        component: ModuleBudgetsPage,
      },
      {
        path: 'sub_form',
        name: 'admin-module-sub-form-page',
        props: true,
        component: ModuleSubFormPage,
      },
      {
        path: 'record_calculations',
        name: 'admin-settings-module-names-record-calculations',
        props: true,
        component: ModuleRecordCalculationsPage,
        children: [
          {
            path: ':recordCalculationId/recalculate',
            name: 'admin-settings-module-names-record-calculations-recalculate',
            props: true,
            component: RecalculationAssistantModal,
          },
          {
            path: 'new',
            name: 'admin-settings-module-names-record-calculations-new',
            props: true,
            component: NewRecordCalculationFormModal,
          },
          {
            path: ':recordCalculationId/edit',
            name: 'admin-settings-module-names-record-calculations-edit',
            props: true,
            component: EditRecordCalculationFormModal,
          },
        ],
      },
      {
        path: 'audit_report_templates',
        props: true,
        name: 'admin-settings-module-names-audit-report-templates',
        component: ModuleAuditReportTemplatesPage,
        children: [
          {
            path: 'new',
            props: true,
            name: 'admin-settings-module-names-audit-report-templates-new',
            component: NewAuditReportTemplateFormModal,
          },
          {
            path: ':auditReportTemplateId/edit',
            name: 'admin-settings-module-names-audit-report-templates-edit',
            component: EditAuditReportTemplateFormModal,
            props: true,
          },
        ],
      },
      {
        path: 'default_templates',
        name: 'admin-settings-module-names-default-templates',
        props: true,
        component: ModuleDefaultTemplatesPage,
      },
      {
        path: 'default_templates/:defaultTemplateId/edit',
        name: 'admin-default-templates-edit-page',
        props: true,
        component: DefaultTemplatesEditPage,
      },
      {
        path: 'default_templates/new',
        name: 'admin-default-templates-new-page',
        props: true,
        component: DefaultTemplatesNewPage,
      },
      {
        path: 'confidentiality_types',
        name: 'admin-settings-module-names-confidentiality-types',
        props: true,
        component: ModuleConfidentialityTypesPage,
      },
      {
        path: 'sub_forms',
        name: 'admin-sub-forms-list',
        component: AdminSubFormsListPage,
        props: true,
      },
      {
        path: 'sub_forms/new',
        name: 'admin-sub-forms-new',
        component: AdminSubFormNewPage,
        props: true,
      },
      {
        path: 'sub_forms/:subFormId/edit',
        name: 'admin-sub-forms-edit',
        component: AdminSubFormEditPage,
        props: true,
      },
      {
        path: 'sub_forms/:subFormId',
        name: 'admin-sub-forms-show',
        component: AdminSubFormShowPage,
        props: (route: Route) => ({
          moduleTabId: Number(route.query.module_tab_id),
          moduleNameId: Number(route.params.moduleNameId),
          subFormId: Number(route.params.subFormId),
        }),
      },
      {
        path: 'module_tabs/:moduleTabId',
        name: 'admin-module-tabs-view-page',
        component: TabSectionsListPage,
        props: (to: Route) => ({
          moduleNameId: Number(to.query.module_name_id),
          moduleTabId: Number(to.params.moduleTabId),
        }),
        children: [
          {
            path: 'tab_sections/new',
            name: 'admin-settings-module-tabs-tab-section-new',
            component: SubFormListNewPage,
            props: (to: Route) => ({
              selectedSubFormId: Number(to.query.selected_sub_form_id),
              moduleTabId: Number(to.params.moduleTabId),
            }),
          },
          {
            path: 'tab_sections/:subFormListId',
            name: 'admin-settings-module-tabs-tab-section-edit',
            component: SubFormListEditPage,
            props: true,
          },
          {
            path: 'tab_sections/:subFormListId/restrictions',
            name: 'admin-settings-module-tabs-tab-section-restrictions',
            component: SubFormListRestrictionsPage,
            props: true,
          },
          {
            path: 'tab_sections/:subFormListId/view_options',
            name: 'admin-settings-module-tabs-tab-section-view-options',
            component: SubFormListViewOptionsPage,
            props: true,
          },
        ],
      },
      {
        path: 'module_tabs/:moduleTabId/edit',
        name: 'admin-module-tabs-edit-page',
        component: ModuleTabEditPage,
        props: true,
      },
      {
        path: 'module_tabs',
        name: 'admin-settings-module-names-module-tabs',
        props: true,
        component: ModuleTabsPage,
      },
      {
        path: 'module_tabs/new',
        name: 'admin-module-tabs-new',
        component: ModuleTabNewPage,
        props: true,
      },
      {
        path: 'automation_definitions',
        name: 'admin-module-automation-definitions-page',
        component: ModuleAutomationDefinitionsPage,
        props: (route: Route) => ({
          moduleNameId: Number(route.params.moduleNameId),
        }),
      },
      {
        path: 'automation_definitions/new',
        name: 'admin-module-automation-definitions-new',
        component: AutomationDefinitionNew,
      },
      {
        path: 'automation_definitions/:automationDefinitionId/edit',
        name: 'admin-module-automation-definitions-edit',
        component: AutomationDefinitionEdit,
        props: true,
      },
      {
        path: 'automation_definitions/automationDefinitionId/clone',
        name: 'admin-module-automation-definitions-clone',
        component: AutomationDefinitionClone,
        props: true,
      },
      {
        path: 'automation_definitions/:automationDefinitionId/event_occurrences',
        name: 'admin-module-automation-definitions-event-occurrences',
        component: AutomationDefinitionEventOccurrences,
        props: true,
      },
      {
        path: 'tab_section_groups',
        name: 'module-tab-section-groups-page',
        props: true,
        component: ModuleTabSectionGroupsPage,
      },
    ],
  },
  {
    path: '/admin/settings/dashboards/:dashboardId',
    component: AdminDashboardsShowPage,
    name: 'admin-dashboards-show',
    props: true,
    children: [
      {
        path: 'edit',
        name: 'admin-dashboards-edit',
        component: AdminDashboardsEditPage,
        props: true,
      },
      {
        path: 'widgets/new',
        name: 'admin-dashboards-new-widget',
        component: AdminDashboardsNewWidgetPage,
        props: true,
      },
      {
        path: 'widgets/:widgetId',
        name: 'admin-dashboards-edit-widget',
        component: AdminDashboardsEditWidgetPage,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/placeholder_profiles/',
    component: PlaceholderProfilesPage,
    name: 'admin-settings-placeholder-profiles',
    children: [
      {
        path: 'new',
        name: 'admin-settings-placeholder-profiles-new',
        component: PlaceholderProfilesNew,
      },
      {
        path: ':id/edit',
        name: 'admin-settings-placeholder-profiles-edit',
        component: PlaceholderProfilesEdit,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/mobile_apps/',
    component: ConfigsListPage,
    name: 'mobile-app-configs',
    children: [
      {
        path: 'new',
        name: 'mobile-app-config-new',
        component: NewConfigPage,
      },
    ],
  },
  {
    path: '/admin/settings/organizations/',
    name: 'admin-settings-organizations-index',
    component: AdminSettingsOrganizationsIndex,
    meta: { title: 'tenant.admin.settings.menus.organizations' },
    children: [
      {
        path: 'new',
        name: 'admin-settings-organization-new',
        component: AdminSettingsOrganizationNew,
      },
      {
        path: ':organizationId/edit',
        name: 'admin-settings-organization-edit',
        component: AdminSettingsOrganizationEdit,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/module_record_imports',
    name: 'admin-module-record-imports-page',
    component: AdminModuleRecordImportsPage,
  },
  {
    path: '/admin/settings/module_record_imports/new',
    name: 'admin-module-record-imports-page-new',
    component: AdminModuleNameImportPage,
    props: (route: Route) => ({ moduleNameId: route.query.module_name_id }),
  },
  {
    path: '/admin/settings/hour_types/',
    name: 'admin-settings-hour-types-index',
    component: HourTypesIndex,
    children: [
      {
        path: 'new',
        name: 'admin-settings-hour-type-new',
        component: HourTypeNew,
      },
      {
        path: ':hourTypeId/edit',
        name: 'admin-settings-hour-type-edit',
        component: HourTypeEdit,
      },
      {
        path: ':hourTypeId',
        name: 'admin-settings-hour-type',
        redirect: { name: 'admin-settings-hour-type-edit' },
        component: HourTypeEdit,
      },
    ],
  },
  {
    path: '/admin/settings/cost_centres/',
    name: 'admin-settings-cost-centres-index',
    component: AdminSettingsCostCentresIndex,
    children: [
      {
        path: 'new',
        name: 'admin-settings-cost-centre-new',
        component: AdminSettingsCostCentreNew,
      },
      {
        path: ':organizationId/edit',
        name: 'admin-settings-cost-centre-edit',
        component: AdminSettingsCostCentreEdit,
      },
    ],
  },
  {
    path: '/admin/settings/locations/',
    name: 'admin-settings-locations-index',
    component: AdminSettingsLocationsIndex,
    meta: { title: 'app.labels.locations' },
  },
  {
    path: '/admin/settings/locations/new',
    name: 'admin-settings-location-new',
    component: AdminSettingsLocationNew,
  },
  {
    path: '/admin/settings/locations/:locationId/edit',
    alias: '/admin/settings/locations/:locationId/',
    name: 'admin-settings-location-edit',
    redirect: { name: 'admin-settings-locations-edit-details' },
    component: AdminSettingsLocationEdit,
    props: true,
    children: [
      {
        path: 'details',
        name: 'admin-settings-locations-edit-details',
        component: LocationDetailsForm,
        props: true,
      },
      {
        path: 'active_users_and_visitors',
        name: 'admin-settings-locations-edit-active-users-and-visitors',
        component: LocationActiveUsersForm,
        props: true,
      },
      {
        path: 'minimum_tag_count',
        name: 'admin-settings-locations-edit-minimum-tag-count',
        component: LocationTagCountsForm,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/samls/',
    name: 'admin-settings-samls-index',
    component: AdminSettingsSamlsIndex,
    meta: { title: 'tenant.admin.settings.menus.samls' },
  },
  {
    path: '/admin/settings/samls/new',
    name: 'admin-settings-samls-new',
    component: AdminSettingsSamlNew,
  },
  {
    path: '/admin/settings/samls/:samlId/edit',
    name: 'admin-settings-saml-edit',
    component: AdminSettingsSamlEdit,
  },
  {
    path: '/admin/settings/mobile_apps/:mobileAppConfigId',
    name: 'mobile-app-config-view',
    component: ViewConfigPage,
    props: true,
    children: [
      {
        path: 'edit',
        name: 'mobile-app-config-edit',
        component: EditConfigPage,
        props: true,
      },
      {
        path: 'layouts/new',
        name: 'mobile-app-layout-new',
        component: NewLayoutPage,
        props: true,
      },
      {
        path: 'layouts/:mobileAppLayoutId/edit',
        name: 'mobile-app-layout-edit',
        component: EditLayoutPage,
        props: true,
      },
      {
        path: 'registrations/new',
        name: 'mobile-app-registration-new',
        component: NewRegistrationPage,
        props: true,
      },
      {
        path: 'registrations/:mobileAppRegistrationId/edit',
        name: 'mobile-app-registration-edit',
        component: EditRegistrationPage,
        props: true,
      },
      {
        path: 'registrations/:mobileAppRegistrationId/test',
        name: 'mobile-app-registration-test',
        component: TestRegistrationPage,
        props: true,
      },
      {
        path: 'registrations/:mobileAppRegistrationId',
        name: 'mobile-app-registration-view',
        component: ViewRegistrationPage,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/svg_templates',
    name: 'svg-templates-index',
    component: SvgTemplatesIndexPage,
    children: [
      {
        path: 'new',
        name: 'svg-templates-new',
        component: NewSvgTemplatePage,
      },
      {
        path: ':svgTemplateId/edit',
        name: 'svg-templates-edit',
        component: EditSvgTemplatePage,
      },
      {
        path: ':svgTemplateId',
        name: 'svg-templates-view',
        component: ViewSvgTemplatePage,
      },
    ],
  },
  {
    path: '/admin/settings/event_occurrences',
    name: 'event-occurrences-index',
    component: EventOccurrencesIndex,
    beforeEnter: defaultFiltersGuardFactory<EventOccurrence>({
      created_at: 'month',
    }),
  },
  {
    path: '/admin/settings/restriction_methods',
    name: 'admin-settings-restriction-methods-page',
    component: AdminSettingsRestrictionMethodsPage,
  },
  {
    path: '/admin/settings/administrative_roles',
    name: 'admin-settings-administrative-roles-page',
    component: AdminAdministrativeRolesPage,
  },
  {
    path: '/admin/settings/regulatory_reports',
    name: 'admin-regulatory-reports-index',
    component: AdminRegulatoryReportingPage,
  },
  {
    path: '/admin/settings/regulatory_reports/:regulatoryReportConfigId',
    name: 'admin-regulatory-reports-show',
    component: AdminRegulatoryReportsPage,
    props: true,
  },
  {
    path: '/admin/settings/app_bundles',
    name: 'admin-settings-app-bundles',
    component: AdminAppBundlesPage,
  },
  {
    path: '/admin/settings/app_bundles/installations',
    name: 'admin-settings-app-bundles-installations',
    component: AdminAppBundlesInstallationsPage,
  },
  {
    path: '/admin/settings/location_tags',
    name: 'admin-settings-location-tags-index',
    component: AdminSettingsLocationTagsIndex,
    children: [
      {
        path: 'new',
        name: 'admin-settings-location-tags-new',
        component: AdminSettingsLocationTagsNew,
      },
      {
        path: '/admin/settings/location_tags/:locationTagId/edit',
        name: 'admin-settings-location-tags-edit',
        component: AdminSettingsLocationTagsEdit,
        props: true,
      },
    ],
  },
  {
    path: '/admin/settings/scheduled_events',
    name: 'admin-settings-scheduled-events-index',
    component: AdminScheduledEventsPage,
  },
  {
    path: '/admin/settings/scheduled_events/new',
    name: 'admin-settings-scheduled-events-new',
    component: ScheduledEventsNew,
  },
  {
    path: '/admin/settings/scheduled_events/:scheduledEventId/edit',
    props: ({ params }: Route) => ({
      scheduledEventId: Number(params.scheduledEventId),
    }),
    name: 'admin-settings-scheduled-events-edit',
    component: ScheduledEventsEdit,
  },
  {
    path: '/admin/settings/scheduled_events/:scheduledEventId/event_occurrences',
    name: 'admin-settings-scheduled-events-event-occurrences',
    component: ScheduledEventsEventOccurrences,
  },
  {
    path: '/admin/settings/automation_definitions',
    name: 'admin-settings-automation-definitions-index',
    component: AutomationDefinitionsPage,
  },
  {
    path: '/admin/settings/automation_definitions/new',
    name: 'admin-settings-admin-automation-definition-new',
    component: AutomationDefinitionNew,
  },
  {
    path: '/admin/settings/automation_definitions/:automationDefinitionId',
    beforeEnter: (to) => changeLocation(`/admin/settings/automation_definitions/${to.params.automationDefinitionId}/edit`),
  },
  {
    path: '/admin/settings/automation_definitions/:automationDefinitionId/edit',
    name: 'admin-settings-admin-automation-definition-edit',
    component: AutomationDefinitionEdit,
    props: true,
  },
  {
    path: '/admin/settings/automation_definitions/:automationDefinitionId/clone',
    name: 'admin-settings-admin-automation-definition-clone',
    component: AutomationDefinitionClone,
    props: true,
  },
  {
    path: '/admin/settings/automation_definitions/:automationDefinitionId/event_occurrences',
    name: 'admin-settings-admin-automation-definition-event-occurrences',
    component: AutomationDefinitionEventOccurrences,
    props: true,
  },
  {
    path: '/admin/settings/user_collections',
    name: 'admin-settings-user-collections',
    component: UserCollectionsIndex,
  },
  {
    path: '/admin/settings/user_collections/:id/edit',
    name: 'admin-settings-edit-user-collection',
    props: true,
    component: EditUserCollection,
  },
  {
    path: '/admin/settings/user_collections/new',
    name: 'admin-settings-create-user-collection',
    component: CreateUserCollection,
  },
  {
    path: '/admin/settings/action_settings',
    name: 'admin-action-settings',
    component: AdminActionSettingsPage,
    children: [
      {
        path: 'priorities',
        redirect: { name: 'admin-action-settings' },
      },
      {
        path: 'priorities/new',
        name: 'admin-action-settings-priorities-new',
        component: ActionPriorityNew,
      },
      {
        path: 'priorities/:id',
        name: 'admin-action-settings-priorities-edit',
        props: true,
        component: ActionPriorityEdit,
      },
    ],
  },
  {
    path: '/admin/settings/account_settings',
    name: 'admin-account-settings',
    component: AdminAccountSettingsPage,
  },
  {
    path: '/admin/settings/user_settings',
    name: 'admin-user-settings',
    component: AdminUserSettingsPage,
    redirect: { name: 'user-settings-tab' },
    children: [
      {
        path: 'user_settings_tab',
        name: 'user-settings-tab',
        component: UserSettingsTab,
      },
      {
        path: 'gender_list',
        name: 'gender-list-tab',
        component: GenderListTab,
      },
      {
        path: 'workplace_industry',
        name: 'workplace-industry-tab',
        component: WorkplaceIndustryTab,
      },
      {
        path: 'employment_types',
        name: 'employment-types-tab',
        component: EmploymentTypesTab,
      },
    ],
  },
  {
    path: '/admin/settings/looks',
    name: 'admin-look-and-logo',
    component: AdminLookAndLogoPage,
  },
  {
    path: '/admin/settings/audit_trails',
    name: 'admin-audit-trails',
    component: AdminAuditTrailsPage,
  },
  {
    path: '/admin/settings/module_names',
    name: 'admin-settings-module-names-index',
    component: ModuleNamesListPage,
  },
  {
    path: '/admin/settings/sub_form_question_usages/:referenceType/:referenceId/:usageType',
    name: 'admin-sub-form-question-usages-redirect',
    component: SubFormQuestionUsageRouter,
    props: true,
  },
  {
    path: '/admin/settings/sub_forms',
    name: 'admin-all-sub-forms-list',
    component: AdminSubFormsListPage,
    props: () => ({
      showModuleColumn: true,
    }),
  },
  {
    path: '/admin/settings/sub_forms/:subFormId',
    name: 'admin-sub-forms-show-redirect',
    beforeEnter: subFormModuleRedirectGuard(),
  },
  {
    path: '/admin/settings/module_tabs/:moduleTabId',
    name: 'admin-module-tab-redirect',
    component: ModuleTabRouter,
    props: true,
  },
  {
    path: '/admin/settings/module_names/:moduleNameId/edit',
    name: 'admin-settings-admin-module-name-edit',
    props: true,
    component: ModuleNameEditPage,
  },
  {
    path: '/admin/settings/all_imports',
    name: 'all-imports-page',
    component: AllImportsPage,
  },
  {
    path: '/admin/settings/roles',
    name: 'admin-settings-roles',
    component: AdminRolesPage,
  },
  {
    path: '/admin/settings/roles/new',
    name: 'admin-settings-roles-new',
    component: AdminRolesNew,
  },
  {
    path: '/admin/settings/roles/:roleId/edit',
    name: 'admin-settings-roles-edit',
    redirect: { name: 'admin-settings-roles-edit-details' },
    props: ({ params }: Route) => ({ roleId: Number(params.roleId) }),
    component: AdminRolesEdit,
    children: [
      {
        path: '/admin/settings/roles/:roleId/edit/details',
        name: 'admin-settings-roles-edit-details',
        props: ({ params }: Route) => ({ roleId: Number(params.roleId) }),
        component: RoleTabDetails,
      },
      {
        path: '/admin/settings/roles/:roleId/edit/users',
        name: 'admin-settings-roles-edit-users',
        props: ({ params }: Route) => ({ roleId: Number(params.roleId) }),
        component: RoleTabUsers,
        children: [
          {
            path: '/admin/settings/roles/:roleId/edit/users/add',
            name: 'admin-settings-roles-edit-users-add-new',
            props: ({ params }: Route) => ({ roleId: Number(params.roleId) }),
            component: RoleTabUsersNewModal,
          },
        ],
      },
    ],
  },
  {
    path: '/admin/settings/menu_collections/',
    component: MenuCollectionsPage,
    name: 'admin-settings-menu-collections',
    beforeEnter: featureGuardFactory(AdminPermissionFeature.look_and_feel),
    children: [
      {
        path: 'new',
        name: 'admin-settings-menu-collections-new',
        component: MenuCollectionsNew,
      },
      {
        path: ':menuCollectionId/edit',
        name: 'admin-settings-menu-collections-edit',
        props: ({ params }: Route) => ({
          menuCollectionId: Number(params.menuCollectionId),
        }),
        component: MenuCollectionsEdit,
      },
    ],
  },
  {
    path: '/admin/settings/expensable/transaction_ledgers',
    name: 'admin-settings-expensable-transaction-ledgers',
    component: AdminTransactionLedgersPage,
  },
  {
    path: '/admin/settings/calculation_rules/',
    name: 'admin-calculation-rules-index',
    component: CalculationRulesIndexPage,
    children: [
      {
        path: 'new',
        name: 'admin-calculation-rules-new',
        component: CalculationRulesNewPage,
      },
      {
        path: ':calculationRuleId/edit',
        name: 'admin-calculation-rules-edit',
        component: CalculationRulesEditPage,
      },
    ],
  },
  ...hybridModulesRoutes,
];

export default adminRoutes;
