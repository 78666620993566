
  import bootbox from 'bootbox';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { sortBy } from 'lodash';
  import { BaseTable } from '../base-table';
  import EntitySelector from '../entity-selector.vue';
  import FilterSelect from '../filter-select.vue';
  import SearchInput from '../search-input.vue';
  import Select2 from '../select2.vue';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import type { SuperReportExtraFilters } from '@app/services/api/super-reports-api';
  import AdminReportPageActions from '@app/components/admin/reports/admin-report-page-actions.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';
  import type { Role } from '@app/models/role';
  import type { SubForm } from '@app/models/sub-form';
  import type { SuperReport } from '@app/models/super-report';
  import { BaseConcept, KLASS_MONGO_TRAILS_VERSION } from '@app/models/super-report';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  type ReportRelationship = Pick<Relationship, 'name' | 'code' | 'from_module'>;
  type ReportSubForm = Pick<SubForm, 'title' | 'id' | 'module_name'>;
  interface GroupedOption {
    group?: string;
    label: string;
    value: string;
  }

  @Component({
    components: {
      BaseTable,
      SearchInput,
      FilterSelect,
      Select2,
      EntitySelector,
      RecordIndexPageFiltersLayout,
      AdminReportPageActions,
    },
  })
  export default class AdminReportsPage extends Vue {
    @Ref() readonly table?: BaseTable<SuperReport>;

    manager: Nullable<ListManager<SuperReport, SuperReportExtraFilters>> = null;
    isPublicOptions = [
      ['true', 'Public'],
      ['false', 'Private'],
    ];
    scheduleEnableOptions = [
      ['true', 'Enabled'],
      ['false', 'Disabled'],
    ];
    newReportLink = '/admin/settings/reports/new';
    moduleNames: Pick<ModuleName, 'id' | 'name' | 'display'>[] = [];
    relationships: ReportRelationship[] = [];
    subForms: ReportSubForm[] = [];

    get fields(): ListManagerField<SuperReport>[] {
      return [
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.description'), name: 'description' },
        { title: this.$t('app.labels.type'), name: 'concept', sortField: 'concept.display', filter: true },
        { title: this.$t('app.labels.sub_type'), name: 'sub_type', filter: true },
        { title: this.$t('app.labels.roles'), name: 'roles', filter: true },
        {
          title: this.$t('tenant.admin.reports.index.recurring_export'),
          name: 'recurring_export',
          sortField: 'recurring_setup.enabled',
          filter: true,
        },
        { title: this.$t('app.labels.public_on_dashboard'), name: 'is_public', sortField: 'is_public', filter: true },
        { title: '', name: 'operations', width: 'max-content' },
      ];
    }

    get conceptOptions(): GroupedOption[] {
      return sortBy(
        [
          ...this.baseConceptOptions.map((option) => ({ ...option, group: this.$t('app.labels.base_type') })),
          ...this.moduleNames.map((moduleName) => ({
            value: moduleName.name,
            label: moduleName.display,
            group: this.$t('app.labels.module_names'),
          })),
        ],
        (option) => option.value
      );
    }

    get subTypeOptions(): GroupedOption[] {
      return sortBy(
        [
          ...this.moduleNameSubTypeOptions.map((option) => ({ ...option, group: this.$t('app.labels.all') })),
          ...this.subForms.map((form) => ({
            value: `sub_form_id:${form.id}`,
            label: `Subform: ${form.title}`,
            group: this.$t('app.labels.sub_forms'),
          })),
          ...this.relationships.map((relationship) => ({
            value: `relationship_code:${relationship.code}`,
            label: `Relationship: ${relationship.name}`,
            group: this.$t('app.labels.relationships'),
          })),
          ...this.moduleNames.map((moduleName) => ({
            value: `module_name_id:${moduleName.id}`,
            label: `Main Form: ${moduleName.display}`,
            group: this.$t('app.labels.main_forms'),
          })),
        ],
        (option) => option.label
      );
    }

    get baseConceptOptions(): GroupedOption[] {
      return [
        { value: BaseConcept.Calculation, label: this.$t('app.labels.report_concepts.calculation') },
        { value: BaseConcept.Activity, label: this.$t('app.labels.report_concepts.activity') },
        { value: BaseConcept.Comment, label: this.$t('app.labels.report_concepts.comment') },
        { value: BaseConcept.Document, label: this.$t('app.labels.report_concepts.document') },
        { value: BaseConcept.Location, label: this.$t('app.labels.report_concepts.location') },
        { value: BaseConcept.Organization, label: this.$t('app.labels.report_concepts.organization') },
        { value: BaseConcept.Relation, label: this.$t('app.labels.report_concepts.relation') },
        { value: BaseConcept.User, label: this.$t('app.labels.report_concepts.user') },
        {
          value: BaseConcept.UserLocationTag,
          label: this.$t('app.labels.report_concepts.user_location_tag'),
        },
        {
          value: BaseConcept.ProcedureAcknowledgment,
          label: this.$t('app.labels.report_concepts.procedure_acknowledgment'),
        },
        {
          value: BaseConcept.UserInvolvement,
          label: this.$t('app.labels.report_concepts.user_involvement'),
        },
        {
          value: BaseConcept.RecordBudgetCategory,
          label: this.$t('app.labels.report_concepts.estimates'),
        },
      ];
    }

    get moduleNameSubTypeOptions(): GroupedOption[] {
      return [
        { value: 'klass:ModuleRecord', label: this.$t('app.labels.all_main_forms') },
        { value: 'klass:SubFormCompletion', label: this.$t('app.labels.all_sub_forms') },
        { value: 'klass:RecordRelation', label: this.$t('app.labels.all_relationships') },
        { value: 'klass:Expensable::RecordBudgetCategory', label: this.$t('app.labels.all_estimates') },
        { value: `klass:${KLASS_MONGO_TRAILS_VERSION}`, label: this.$t('app.labels.workflow_changes') },
      ];
    }

    get subTypeValue(): string | undefined {
      const reportBlob = this.manager?.customFilters?.report_blob;
      return reportBlob && (reportBlob['@filters'] || (reportBlob.klass && `klass:${reportBlob.klass}`));
    }

    beforeMount(): void {
      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['id', 'name', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
        });
    }

    mounted(): void {
      this.manager = this.getManager();
      this.fetchDependencies(this.manager?.customFilters?.concept);
    }

    getManager(): ListManager<SuperReport, SuperReportExtraFilters> {
      return new ListManager<SuperReport, SuperReportExtraFilters>({
        fetchDataFunction: (params) => {
          const filters = {
            ...(params.filters as DonesafeFilterOptions<Omit<SuperReport, 'roles'>, SuperReportExtraFilters>),
            report_blob: this.useNullModuleNameFilter(params.filters?.concept)
              ? { ...(params.filters?.report_blob as SuperReportExtraFilters), '@filters': 'module_name_id:NULL' }
              : params.filters?.report_blob,
          };

          return this.$api.getSuperReports(
            {
              ...params,
              filters,
              include: ['roles', 'recurring_setup'],
              only: ['id', 'name', 'description', 'report_blob', 'is_public', { roles: ['id', 'name'], recurring_setup: ['enabled'] }],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: this.fields,
        allowFilters: true,
      });
    }

    cloneReport(report: SuperReport): void {
      const confirmation = bootbox.prompt({
        size: 'small',
        title: this.$t('app.labels.report_duplicate_confirmation_title'),
        value: `${report.name} (Copy)`,
        placeholder: this.$t('app.labels.name'),
        buttons: {
          confirm: { label: this.$t('app.buttons.create_duplicate'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (name: string | null): boolean => {
          if (name) {
            this.$api
              .cloneSuperReport(report.id, { name })
              .then(() => {
                this.$api.cache.clear();
                this.table?.debounceUpdate();
                confirmation.modal('hide');
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
            return false;
          }
          if (name === '') {
            toaster({ text: this.$t('app.labels.report_required'), position: 'top-right', icon: 'warning' });
            return false;
          }

          return true; // Only cancel will close modal
        },
      });
    }

    deleteReport(report: SuperReport): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure you want to delete this report? All widgets associated will also be deleted.',
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteSuperReport(report.id)
              .then(() => {
                this.$api.cache.clear();
                this.table?.debounceUpdate();
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }

    printRoles(roles: Role[]): string {
      return roles.map((role) => role.name).join(', ');
    }

    useNullModuleNameFilter(conceptFilter?: string): boolean {
      return this.baseConceptOptions.some((option) => option.value === conceptFilter);
    }

    onConceptChanged(conceptName?: string): void {
      if (this.baseConceptOptions.find((option) => option.value === conceptName)) {
        this.subForms = [];
        this.relationships = [];
      } else {
        this.fetchDependencies(conceptName);
      }
      this.manager?.setFilter('concept', conceptName);
    }

    onSubTypeChanged(subType?: string): void {
      let klassValue;
      let filterValue = undefined;
      if (subType?.startsWith('klass:')) {
        klassValue = subType.replace(/^klass:/, '');
      } else {
        filterValue = subType;
      }
      if (this.manager) {
        this.manager.customFilters = {
          ...this.manager.customFilters,
          report_blob: { klass: klassValue, '@filters': filterValue },
        };
      }
    }

    conceptName(report: SuperReport): string {
      const { subForm, relationship, baseOption } = this.findDependencies(report);
      const moduleName = this.moduleNames.find(
        (moduleName) =>
          subForm?.module_name === moduleName.name ||
          relationship?.from_module === moduleName.name ||
          report.report_blob.filters.find((filter) => filter.key === 'module_name_id' && filter.value === `${moduleName.id}`) ||
          report.report_blob.filters.find((filter) => filter.key === 'version_module' && filter.value === `${moduleName.id}`)
      );

      return moduleName?.display || (baseOption && baseOption.label) || this.$t('app.labels.loading').toString();
    }

    subTypeName(report: SuperReport): string {
      switch (report.report_blob.klass) {
        case 'ModuleRecord':
          return this.$t('app.labels.report_concepts.module_record');
        case 'Expensable::RecordBudgetCategory':
          return this.$t('app.labels.report_concepts.estimates');
        case KLASS_MONGO_TRAILS_VERSION:
          return this.$t('app.labels.workflow_changes');
      }

      const { subForm, relationship, baseOption } = this.findDependencies(report);

      return subForm?.title || relationship?.name || (baseOption && baseOption.label) || this.$t('app.labels.loading').toString();
    }

    fetchDependencies(conceptName?: string): void {
      this.$api
        .getSubForms({
          filters: { active: true, module_name: conceptName },
          per_page: -1,
          only: ['id', 'title', 'module_name'],
          cache: true,
        })
        .then(({ data }) => {
          this.subForms = [...data];
        });
      this.$api
        .getRelationships({
          filters: { active: true, from_module_name: { name: conceptName } },
          per_page: -1,
          only: ['name', 'code', 'from_module'],
          cache: true,
        })
        .then(({ data }) => {
          this.relationships = [...data];
        });
    }

    findDependencies(report: SuperReport): {
      baseOption?: GroupedOption;
      relationship?: ReportRelationship;
      subForm?: ReportSubForm;
    } {
      const baseOption = this.baseConceptOptions.find((option) => option.value === report.report_blob.klass);
      const subForm =
        report.report_blob.filters.find((filter) => filter.key === 'sub_form_id') &&
        this.subForms.find((form) =>
          report.report_blob.filters.find((filter) => filter.key === 'sub_form_id' && filter.value === `${form.id}`)
        );
      const relationship =
        report.report_blob.filters.find((filter) => filter.key === 'relationship_code') &&
        this.relationships.find((relationship) =>
          report.report_blob.filters.find((filter) => filter.key === 'relationship_code' && filter.value === relationship.code)
        );

      return { subForm, relationship, baseOption };
    }
  }
