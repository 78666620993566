import { render, staticRenderFns } from "./logic-element.vue?vue&type=template&id=71b86724&scoped=true&lang=pug"
import script from "./logic-element.vue?vue&type=script&lang=ts"
export * from "./logic-element.vue?vue&type=script&lang=ts"
import style0 from "./logic-element.vue?vue&type=style&index=0&id=71b86724&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b86724",
  null
  
)

export default component.exports