import type { BaseEntity } from './base-entity';
import type { Attachment } from './attachment';
import type { WithTenantUser } from './mixins';

export const PAUSABLE_STATES = ['in_queue', 'scanned', 'started'];
export const FINAL_STATES = ['completed', 'failed', 'canceled'];
export const DELETABLE_STATES = ['completed', 'canceled'];

export enum SuperImportState {
  canceled = 'canceled',
  completed = 'completed',
  failed = 'failed',
  in_queue = 'in_queue',
  paused = 'paused',
  scanned = 'scanned',
  started = 'started',
  virus_scanning = 'virus_scanning',
}

export interface SuperImportFileStats {
  errors?: string[]; // legacy
  errors_count?: number;
  failed_rows_count?: number;
  parent_hash?: Record<string, { column: string; index: number }>;
  processed?: number;
  record_count?: number;
  total?: number;
  warnings?: string[]; // legacy
  warnings_count?: number;
}

export interface SuperImport extends BaseEntity, WithTenantUser {
  attachments: Attachment[];
  batch_id?: string;
  end_at?: string;
  import_id: number;
  import_type:
    | 'User'
    | 'Activity'
    | 'ModuleRecord'
    | 'SubFormCompletion'
    | 'SubFormQuestion'
    | 'Location'
    | 'Translation'
    | 'TranslationYML'
    | 'Comment'
    | 'Organization'
    | 'Attachment'
    | 'TabSection'
    | 'TabSectionSubFormQuestion'
    | 'RecordRelation';
  method: 'manual' | 'sftp';
  options: {
    disable_versions?: boolean;
  };
  parent_id?: number;
  start_at?: string;
  // TODO: missing for now
  state: SuperImportState;
  stats: Record<number | string, SuperImportFileStats>;
  sub_form_list_id: number | null;
  title_of_importing?: string;
}
