
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { supportsApiRequest, supportsApiLookup, canEdit } from '@app/services/model-helpers';
  import ApiRequestConfigurator from '@app/components/admin/questions/api-request/api-request-configurator.vue';
  import ApiLookupConfigurator from '@app/components/admin/questions/api-lookup/api-lookup-configurator.vue';
  import Select2 from '@app/components/select2.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import CanAddSelector from '@app/components/admin/questions/edit/can-add-selector.vue';
  import FormField from './form-field.vue';
  import RadioButtons from '@app/components/admin/questions/edit/radio-buttons.vue';
  import LookupConfigurator from '@app/components/admin/questions/edit/lookup-configurator.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({
    components: {
      LookupConfigurator,
      RadioButtons,
      ApiRequestConfigurator,
      ApiLookupConfigurator,
      CanAddSelector,
      Select2,
      DsCheckbox,
      FormField,
    },
  })
  export default class TabAdvancedFeatures extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;

    get readPrintUiViewOnly(): boolean {
      return this.value.field_type === FieldType.table_calculation;
    }

    get modes(): [string, string][] {
      return [
        ['default', 'Default'],
        ['lookup', 'Lookup'],
        ...((supportsApiRequest(this.value) ? [['api_request', 'API Request']] : []) as [string, string][]),
        ...((supportsApiLookup(this.value) ? [['api_lookup', 'API Lookup']] : []) as [string, string][]),
      ];
    }

    get hideModeSelector(): boolean {
      return this.value.field_type === FieldType.workflow_state || this.readPrintUiViewOnly;
    }

    get isLookup(): boolean {
      return this.value.config?.mode === 'lookup';
    }

    get isApiLookup(): boolean {
      return this.value.config?.mode === 'api_lookup';
    }

    get isApiRequest(): boolean {
      return this.value.config?.mode === 'api_request';
    }

    get canEdit() {
      return canEdit(this.value);
    }

    get canAdd() {
      return this.canEdit && !this.onMainForm;
    }

    get canAddAttachment() {
      return this.canAdd && !(this.value.field_type == FieldType.file_upload);
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    beforeMount(): void {
      this.$$patch(this.value.config, { mode: 'default' }, { ifEmpty: true });
    }
  }
