
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import RelationshipRightsTable from '../admin/roles/role-tab-details/relationship-rights-table.vue';
  import DsTextInput from '../ds-text-input.vue';
  import Switcher from '../switcher.vue';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { Permission } from '@app/models/permission';
  import type { RoleProfile } from '@app/models/role-profile';
  import { GlobalAccess } from '@app/models/extended-permission';
  import { ROLE_PROFILE_ADMIN_RECORD_CODE } from '@app/models/role-profile';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import SpecialPermissionCheckboxList from '@app/components/extended-permissions/special-permission-checkbox-list.vue';
  import FormField from '../admin/questions/edit/form-field.vue';
  import EntitySelector from '../entity-selector.vue';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  @Component({
    components: {
      Select2,
      ValidationObserver,
      ValidationProvider,
      DsCheckbox,
      RelationshipRightsTable,
      DsTextInput,
      Switcher,
      SpecialPermissionCheckboxList,
      FormField,
      EntitySelector,
    },
  })
  export default class RoleProfileForm extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly roleProfile!: RoleProfile;

    form: Partial<RoleProfile> = {};
    confidentialityTypes: Pick<ConfidentialityType, 'name' | 'id' | 'active'>[] = [];

    submit(): void {
      this.$nextTick(() => {
        this.validator?.validate().then((result: boolean) => {
          if (result) {
            this.$emit('submit', this.form);
          }
        });
      });
    }

    async beforeMount(): Promise<void> {
      await this.initForm();
    }

    async initForm(): Promise<void> {
      this.form = {
        ...this.roleProfile,
      };

      const moduleName = this.roleProfile.module_name?.name;
      if (moduleName !== ACTIVITY_CONCEPT) {
        const { data: confidentialityTypes } = await this.$api.getConfidentialityTypes(
          { filters: { module_name: { name: moduleName }, active: true }, only: ['id', 'name', 'active'], per_page: -1 },
          { cache: true }
        );
        this.confidentialityTypes = confidentialityTypes;
      }
    }

    get canCreate(): boolean {
      return this.hasExtendedPermission(GlobalAccess.Create);
    }

    set canCreate(value: boolean) {
      this.setExtendedPermission(GlobalAccess.Create, value);
    }

    hasExtendedPermission(name: string): boolean {
      return !!this.form.extended_permissions?.find((i) => {
        return i.concept_name === this.form.module_name?.name && i.name === name;
      });
    }

    setExtendedPermission(name: string, value: boolean): void {
      if (value) {
        this.form.extended_permissions = [
          ...(this.form.extended_permissions || []),
          {
            concept_name: this.form.module_name?.name || '',
            name: name,
          },
        ];
      } else {
        this.form.extended_permissions = (this.form.extended_permissions || []).filter((ep) => {
          return !(ep.concept_name === this.form.module_name?.name && ep.name === name);
        });
      }
    }

    onPermissionUpdate(params: { permission: Permission; type: 'add' | 'remove' }): void {
      const { permission, type } = params;
      if (type === 'add') {
        this.form.permissions = [...(this.form.permissions || []), permission];
      } else {
        this.form.permissions = this.form.permissions?.filter((p) => {
          return p.id !== permission.id;
        });
      }
    }

    get adminRecordCode(): boolean {
      return this.form.code === ROLE_PROFILE_ADMIN_RECORD_CODE;
    }

    get dashboardPaneFilter() {
      return { module_name_id: this.form.module_name?.id };
    }

    dashboardPaneSelectTemplate(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        primaryAttribute: 'name',
        secondaryAttribute: 'description',
      });
    }
  }
