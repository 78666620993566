import type { ValidationRule } from 'vee-validate/dist/types/types';
import I18n from '../i18n';
import { API } from '@app/services/api-factory';

const appBundleUniqueCodeRule: ValidationRule = {
  validate(code: string) {
    return API.getAppBundles({ only: 'id', filters: { code } }, { cache: true })
      .then(({ data }) => {
        const message = data.length ? I18n.t('app.labels.code_already_been_taken') : I18n.t('app.labels.something_went_wrong');
        return {
          valid: !data.length,
          data: data.length ? { message } : undefined,
        };
      })
      .catch(({ data }) => {
        return {
          valid: false,
          data: data?.error || I18n.t('app.labels.something_went_wrong'),
        };
      });
  },
};

export default appBundleUniqueCodeRule;
