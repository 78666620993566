
  import CommentFormModal from '@app/components/comment/comment-form-modal.vue';
  import CommentForm from '@app/components/comment/comment-form.vue';
  import CommentsList from '@app/components/comment/comments-list.vue';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import DatePicker from '@app/components/date-picker.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import { entityPathPrefix } from '@app/services/helpers';
  import bootbox from 'bootbox';
  import { Tabs, Tab } from 'uiv';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { Component, Ref } from 'vue-property-decorator';
  import PriorityLabel from '@app/components/activities/priority-label.vue';
  import BaseActivity from './base-activity';
  import type { ActivityShowModalActivityOnly, ViewActivityPageActivityOnly } from './utils';
  import type { CommentParams } from '@app/services/api/activities-api';
  import ActivityActions from './activity-actions.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { Activity } from '@app/models/activity';
  import type { Comment } from '@app/models/comment';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  @Component({
    components: {
      CommentFormModal,
      CommentsList,
      CommentForm,
      DateTimePicker,
      EntitySelector,
      Select2,
      UserSelector,
      DatePicker,
      ValidationProvider,
      ValidationObserver,
      Tabs,
      Tab,
      PriorityLabel,
      ActivityActions,
      DsIconText,
    },
  })
  export default class ActivityShow extends BaseActivity<Pick<Activity, ViewActivityPageActivityOnly | ActivityShowModalActivityOnly>> {
    @Ref() readonly commentForm?: CommentForm;

    savingComment = false;
    activeTab = 0;

    comments: Comment[] = [];

    newComment: Partial<Comment> = {};

    tabClasses = {
      'justified-via-flex': true,
    };

    get relatedToName(): string | undefined {
      if (this.activity.actionable_type !== 'Location') {
        if (this.activity.related_to === undefined) {
          return this.$t('app.labels.not_accessible').toString();
        } else if (this.activity.related_to === '') {
          return `${entityPathPrefix(this.activity.actionable_type)}/${this.activity.actionable_id}`;
        } else {
          return this.activity.related_to;
        }
      }
    }

    get relatedToPath(): string | undefined {
      if (this.activity && this.activity.actionable_type !== 'Location' && this.activity.related_to !== undefined) {
        const prefix = entityPathPrefix(this.activity.actionable_type);
        return `/${prefix}/${this.activity.actionable_id}`;
      }
    }

    get relatedToTag() {
      return this.relatedToPath ? 'a' : 'span';
    }

    get commentsCount(): number {
      return this.comments.length;
    }

    get commentsTabTitle(): string {
      const count = this.commentsCount ? ` (${this.commentsCount})` : '';
      return `${this.$t('app.labels.comments')}${count}`;
    }

    onDeleteComment(comment: Comment): void {
      bootbox.confirm({
        backdrop: false,
        message: this.$t('tenant.shared.comments_and_relations.delete_this_comment'),
        size: 'small',
        callback: (result: boolean) => {
          result &&
            this.$api.deleteComment(comment.id).then(() => {
              this.$api.cache.clear();
              this.fetchComments();
            });
        },
      });
    }

    onCommentFormSubmit(commentData: Partial<Omit<Comment, 'attachments'>> & CommentParams['attachments']): void {
      if (!commentData.comment) return;

      if (this.activity && !this.savingComment) {
        this.savingComment = true;
        this.$api
          .createComment({
            ...commentData,
            comment: commentData.comment,
            commentable_id: this.activity.id,
            commentable_type: ACTIVITY_CONCEPT,
          })
          .then(() => {
            this.newComment = {};
            this.commentForm?.reset();
            this.$api.cache.clear();
            this.fetchComments();
          })
          .finally(() => {
            this.savingComment = false;
          });
      }
    }

    getCompletionLink(completion: Pick<SubFormCompletion, 'id'>): string {
      return `/sub_form_completions/${completion.id}`;
    }

    beforeMount(): void {
      this.fetchComments();
    }

    fetchComments(): void {
      this.$api
        .getComments(
          {
            sort: '-created_at',
            include: ['user', 'attachments'],
            filters: { commentable_id: this.activity.id, commentable_type: ACTIVITY_CONCEPT },
            per_page: -1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.comments = data;
        });
    }

    reassignApprover(): void {
      this.$emit('edit');
    }
  }
