
  import bootbox from 'bootbox';
  import { Tooltip } from 'uiv';
  import { v4 as generateUUID } from 'uuid';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import type { RawOption, ResponseItem } from './models';
  import SelectResponseItem from './select-response-item.vue';
  import { mapValues } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import { ValidationObserver } from 'vee-validate';

  type OptionsHash = { [key: string]: RawOption };

  @Component({ components: { SelectResponseItem, Tooltip, Draggable, ValidationObserver } })
  export default class SelectResponseList extends Vue {
    @Model('input') readonly value?: Maybe<OptionsHash>;

    @Prop(Object) readonly question!: SubFormQuestion;

    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly hasScore!: boolean;
    @Prop(Boolean) readonly hasNa?: boolean;
    @Prop(String) readonly scoreTip?: string;
    @Prop(String) readonly scoreKey?: 'score' | 'calculation_value';
    @Prop(String) readonly scoreLabel?: string;
    @Prop(String) readonly scoreType?: 'text' | 'number';
    @Prop(Boolean) readonly isHighlighted?: boolean;
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(Object) readonly defaults?: { option?: string; values?: string[] };

    @Ref() readonly optionsValidator?: InstanceType<typeof ValidationObserver>;

    editKey: Nullable<string> = null;
    showValues = false;

    get duplicatedKeys(): Record<string, boolean> {
      const optionsKeys = this.optionsArray.map(({ key }) => key?.trim() || '');

      return optionsKeys.reduce<Record<string, boolean>>((acc, key, i, arr) => {
        return key && arr.indexOf(key) !== i ? { ...acc, [key]: true } : acc;
      }, {});
    }

    get options(): OptionsHash {
      return this.value || {};
    }

    get optionsArray(): ResponseItem[] {
      return this.sortedKeys(this.options).map((key) => {
        return { ...this.options[key], index: key };
      });
    }

    set optionsArray(items: ResponseItem[]) {
      let newDefaultOption: string | undefined;
      const currentDefaultOption = this.defaults?.option;
      const newOptions = items.reduce((memo, item, index) => {
        const newKey = item.index === 'na' ? item.index : `${index + 1}`;
        if (!newDefaultOption && currentDefaultOption !== newKey && currentDefaultOption === item.index) {
          newDefaultOption = newKey;
        }
        return { ...memo, [newKey]: this.options[item.index] };
      }, {});
      if (newDefaultOption) {
        this.$emit('update-defaults', { ...this.defaults, option: newDefaultOption });
      }
      this.$emit('input', newOptions);
    }

    sortedKeys(options: OptionsHash): string[] {
      return Object.keys(options).sort((a, b) => {
        if (a === 'na') {
          return 1;
        } else if (b === 'na') {
          return -1;
        } else {
          return parseInt(a) - parseInt(b);
        }
      });
    }

    async addResponse(index?: string): Promise<void> {
      const nextIndex = (this.count || 0) + 1;
      const key = index ? index : nextIndex;
      const newOptions = { ...this.options, [key]: { value: '', key: '' } };
      const normalizedOptions = this.normalizeOptions(newOptions);
      this.$emit('input', normalizedOptions);
      this.editKey = normalizedOptions[nextIndex]._key || null;
    }

    normalizeOptions(options: OptionsHash): OptionsHash {
      const indexMapping: { [key: string]: string } = {};
      return this.sortedKeys(options).reduce<OptionsHash>((memo, key, index) => {
        const newKey = key === 'na' ? key : `${index + 1}`;
        indexMapping[key] = newKey;
        return { ...memo, [newKey]: { _key: generateUUID(), ...options[key] } };
      }, {});
    }

    onOptionUpdate(index: string, option: RawOption): void {
      const resetOtherDefaultFlags = !this.multiple && option.is_default;
      const options = resetOtherDefaultFlags
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mapValues(this.options, ({ is_default, ...rest }) => ({ ...rest, is_default: false }))
        : this.options;

      this.$emit('input', { ...options, [index]: option });
    }

    get defaultDisabled(): boolean {
      return this.question.config.default?.type !== (this.multiple ? 'values' : 'select_value');
    }

    mounted(): void {
      const value = this.value && Object.keys(this.value).length ? this.value : { ...this.defaultOptions, ...this.options };

      this.$emit('input', this.normalizeOptions(value));
      this.$nextTick(() => {
        this.showValues = true;
      });
    }

    beforeMount(): void {
      extend('min_items', {
        validate: (value: ResponseItem[] | null | undefined) => {
          if (value) {
            return Object.keys(value).length >= this.minItems;
          }
          return false;
        },
        message: this.$t('app.labels.at_least_n_responses_needed', { amount: this.minItems }),
      });
    }

    removeResponse(index: number | string): void {
      const minItems = this.minItems;
      if (index !== 'na' && Object.keys(this.options).length <= minItems) {
        bootbox.alert(this.$t('app.labels.at_least_n_responses_needed', { amount: minItems }));
        return;
      }

      const updatedOptions = Object.keys(this.options)
        .filter((key) => key !== index.toString())
        .reduce((a, key) => ({ ...a, [key]: this.options[key] }), {});
      this.$emit('input', this.normalizeOptions(updatedOptions));
      this.editKey = null;
    }

    async editResponse(key: string): Promise<void> {
      const isValid = await this.optionsValidator?.validate();
      if (isValid) this.editKey = key;
    }

    get defaultOptions(): OptionsHash {
      if (this.noDefault) {
        return {};
      }

      if (this.scoreKey === 'calculation_value') {
        return {
          1: { value: 'Value - 10', key: '', calculation_value: '' },
          2: { value: 'Value - 50', key: '', calculation_value: '' },
        };
      }

      return {
        1: { value: this.$t('app.labels.yes').toString(), key: 'yes', score: '1' },
        2: { value: this.$t('app.labels.no').toString(), key: 'no', score: '0' },
      };
    }

    get naResponsePresent(): boolean {
      return !!this.options['na'];
    }

    get noDefault() {
      return this.isApiRequest;
    }

    get isApiRequest(): boolean {
      return this.question?.config?.mode === 'api_request';
    }

    get minimumItems(): number {
      if (this.isApiRequest) {
        return 0;
      }

      return this.isCheckbox ? 1 : 2;
    }

    get isCheckbox(): boolean {
      return FieldType.multi_checkbox === this.question.field_type;
    }

    get count(): number {
      return Object.keys(this.options).length;
    }
  }
