
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import { BaseTable } from '../base-table';
  import ScormCourseFormModal from '../scorm-course/scorm-course-form-modal.vue';
  import type { ScormCourse } from '@app/models/scorm-course';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ScormCourseFormModal, BaseTable } })
  export default class AdminScormCoursesPage extends Vue {
    @Ref() readonly table?: BaseTable<ScormCourse>;

    scormCourseModalVisible = false;
    manager: Nullable<ListManager<ScormCourse>> = null;
    selectedScormCourse: Partial<ScormCourse> = {};
    savingForm = false;

    getManager(): ListManager<ScormCourse> {
      return new ListManager<ScormCourse>({
        fetchDataFunction: (params) => {
          return this.$api.getScormCourses({ ...params, include: ['user'] }, { cache: true });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'title', sortField: 'title' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('tenant.admin.scorm_courses.table_head.title'),
            name: 'title',
            sortField: 'title',
          },
          {
            title: this.$t('tenant.admin.scorm_courses.table_head.description'),
            name: 'description',
            sortField: 'description',
          },
          {
            title: this.$t('tenant.admin.scorm_courses.table_head.course_type'),
            name: 'course_type',
            sortField: 'course_type',
          },
          {
            title: this.$t('tenant.admin.scorm_courses.table_head.created_by'),
            name: 'user',
          },
          {
            title: this.$t('tenant.admin.scorm_courses.table_head.active'),
            name: 'active',
            sortField: 'active',
          },
        ],
      });
    }

    addNewScormCourse(): void {
      this.selectedScormCourse = { course_type: 'imported' };
      this.toggleScormCourseModal(true);
    }

    editScormCourse(entity: ScormCourse): void {
      this.selectedScormCourse = entity;
      this.toggleScormCourseModal(true);
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    toggleScormCourseModal(value: boolean): void {
      this.scormCourseModalVisible = value;
    }

    submitScormCourseForm(entity: Partial<ScormCourse>): void {
      const promise = entity.id ? this.$api.updateScormCourse(entity.id, entity) : this.$api.createScormCourse(entity);
      this.savingForm = true;
      promise
        .then(({}) => {
          this.$api.cache.clear();
          this.table?.debounceUpdate();
          this.toggleScormCourseModal(false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => {
          this.savingForm = false;
        });
    }
  }
