
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { epOptionsWithLabels } from '@app/components/admin/roles/role-tab-details/utils';
  import { Tooltip } from 'uiv';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import { useAccountStore } from '@app/stores/account';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import { GlobalAccess, SpecialPermissionName, UiToggles } from '@app/models/extended-permission';
  import type { ModuleName } from '@app/models/module-name';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';

  type LocalExtendedPermission = {
    label: string;
    name: string;
    value: boolean;
  };

  @Component({ components: { DsCheckbox, Tooltip } })
  export default class SpecialPermissionCheckboxList extends Vue {
    @Prop({ type: Array, required: true }) readonly extendedPermissions!: ExtendedPermission[];
    @Prop({ type: Object, required: true }) readonly moduleName!: ModuleName;
    @Prop({ type: Array, required: true }) readonly confidentialityTypes!: ConfidentialityType[];

    hasExtendedPermission(name: string): boolean {
      return !!this.extendedPermissions?.find((i) => {
        return i.concept_name === this.moduleName.name && i.name === name;
      });
    }

    get localExtendedPermissions(): LocalExtendedPermission[] {
      return epOptionsWithLabels(this.moduleName.name, this.confidentialityTypes).reduce((acc, ep) => {
        return [...acc, { name: ep.name, label: ep.label, value: this.hasExtendedPermission(ep.name) }];
      }, [] as LocalExtendedPermission[]);
    }

    get epWithoutGlobalCreate(): LocalExtendedPermission[] {
      return this.localExtendedPermissions.filter((p) => {
        return p.name !== GlobalAccess.Create && p.name !== UiToggles.HideOnMainMenu && p.name !== UiToggles.HideQuickAddButton;
      });
    }

    get uiToggleEps(): LocalExtendedPermission[] {
      return this.localExtendedPermissions.filter((p) => p.name === UiToggles.HideOnMainMenu || p.name === UiToggles.HideQuickAddButton);
    }

    get actionApprovalsEnabled(): boolean {
      return !!useAccountStore()?.data?.enable_action_approvals;
    }

    showTooltip(value: string): boolean {
      return (
        !this.actionApprovalsEnabled &&
        [SpecialPermissionName.approve_editable_standalone_actions, SpecialPermissionName.approve_editable_actions].includes(
          value as SpecialPermissionName
        )
      );
    }
  }
