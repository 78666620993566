
  import { Tooltip } from 'uiv';
  import { Component, Prop } from 'vue-property-decorator';
  import SubFormListActions from '@app/components/admin/sub-form-lists/sub-form-list-actions.vue';

  import type { MtSflOnly } from '../module-tabs/utils';
  import { SUB_FORM_LIST_TYPE_LABELS } from '../module-tabs/utils';
  import Blocking from '@app/mixins/blocking';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { RecordRelationMode } from '@app/models/record-relation';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';

  const SUB_FORM_ONLY = Tuple(['id', 'title', 'description', 'sub_form_type', 'active'] as const);

  type PartialSubForm = Pick<SubForm, FirstLevelKeyLiterals<(typeof SUB_FORM_ONLY)[number]>>;
  @Component({ components: { SubFormListActions, Tooltip } })
  export default class SubFormListItem extends Blocking {
    @Prop() readonly subFormList!: Pick<SubFormList, MtSflOnly>;
    @Prop(Boolean) readonly hybridModule!: boolean;
    @Prop([String, Number]) readonly moduleNameId!: string | number;

    recordRelationQuestion: null | SubFormQuestion = null;
    relationship: null | Relationship = null;
    subForms: PartialSubForm[] = [];

    get subFormsHash(): Record<number, PartialSubForm> {
      return this.subForms.reduce((acc, subForm) => ({ ...acc, [subForm.id]: subForm }), {});
    }

    get isSubFormValidType(): boolean {
      return [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow].includes(this.subFormList.sub_form_list_type);
    }

    getSubFormLink(id: number): string {
      return `/admin/settings/module_names/${this.moduleNameId}/sub_forms/${id}`;
    }

    get prettySubFormListType(): string {
      return this.$t(SUB_FORM_LIST_TYPE_LABELS[this.subFormList.sub_form_list_type]);
    }

    get hasRestrictions(): boolean {
      return !!this.subFormList.sub_form_list_restrictions?.length;
    }

    get isRecordRelations(): boolean {
      return this.subFormList.sub_form_list_type === SubFormListType.record_relations;
    }

    get isResponseCounter(): boolean {
      return this.subFormList.sub_form_list_type === SubFormListType.response_counter;
    }

    get isRelationship(): boolean {
      return this.subFormList.sub_form_list_type === SubFormListType.relationship;
    }

    get modeDescription(): string {
      return this.subFormList.relationship_options.relationship_mode === RecordRelationMode.from
        ? 'From this module to'
        : 'To this module from';
    }

    get relationshipModule(): ModuleName | null {
      return (
        (this.subFormList.relationship_options.relationship_mode === RecordRelationMode.from
          ? this.relationship?.to_module_name
          : this.relationship?.from_module_name) || null
      );
    }

    async fetchSubForms(): Promise<void> {
      if (this.isSubFormValidType && (this.subFormList.sub_form_ids || []).length) {
        const { data } = await this.$api.getSubForms(
          {
            filters: { sub_form_lists: { module_tab: { id: this.subFormList.module_tab_id } } },
            only: ['id', 'title', 'description', 'sub_form_type', 'active'],
            per_page: -1,
          },
          { cache: true }
        );

        this.subForms = data;
      } else if (this.isResponseCounter && this.subFormList.response_counter_options.sub_form_ids.length) {
        const { data } = await this.$api.getSubForms(
          {
            filters: { id: this.subFormList.response_counter_options.sub_form_ids },
            only: ['id', 'title', 'description', 'sub_form_type', 'active'],
            per_page: -1,
          },
          { cache: true }
        );
        this.subForms = data;
      }
    }
    async fetchRecordRelationQuestion(): Promise<void> {
      if (this.isRecordRelations && this.subFormList.record_relation_question_id) {
        const { data } = await this.$api.getSubFormQuestion(this.subFormList.record_relation_question_id, {}, { cache: true, join: true });
        this.recordRelationQuestion = data;
      }
    }

    beforeMount(): void {
      this.blocking(async () => {
        if (this.isRelationship && this.subFormList.relationship_options.relationship_code) {
          const { data } = await this.$api.getRelationshipsWithSharedFilter(
            { only: ['id', 'name', 'code', 'from_module_name', 'to_module_name'] },
            'code',
            this.subFormList.relationship_options.relationship_code,
            { cache: true }
          );
          this.relationship = data[0];
        }

        await this.fetchSubForms();
        await this.fetchRecordRelationQuestion();
      });
    }
  }
