
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  @Component({
    components: { PaperTrailsModalLink, DsDropdown },
  })
  export default class AdminNotificationsEditPage extends Vue {
    @Prop({ type: [Number, String] }) readonly id!: number | string;
  }
