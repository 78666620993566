
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import SubFormQuestionShowOptions from './sub-form-question-show-options.vue';
  import EditQuestion from '../questions/edit/edit-question.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { APP_SUPPORTED_FIELD_TYPES } from '@app/models/sub-form-question';
  import { humanize } from '@app/utils/humanize';

  @Component({ components: { EditQuestion, SubFormQuestionShowOptions, DsDropdown } })
  export default class SubFormQuestionShow extends Vue {
    @Prop(Object) readonly question!: SubFormQuestion;
    @Prop(Number) readonly index!: number;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Number) readonly subFormId!: number;

    @Ref() readonly editButton?: HTMLAnchorElement;

    showEditModal = false;

    onClose(reload = false) {
      this.showEditModal = false;
      reload && this.$emit('reload');
    }

    get isSupportedAppType(): boolean {
      return APP_SUPPORTED_FIELD_TYPES.includes(this.question.field_type);
    }

    get prettyQuestionType(): string {
      return humanize(this.question.field_type).toUpperCase();
    }

    mounted() {
      this.restoreScroll();

      if (this.$route.query.edit_sub_form_question_modal === `${this.question.id}`) {
        this.editButton?.click();
        this.$router.replace({ query: { ...this.$route.query, edit_sub_form_question_modal: undefined } });
      }
    }

    restoreScroll() {
      if (sessionStorage.getItem('questionIdAfterEdit') === `${this.question.id}`) {
        requestAnimationFrame(() => {
          this.$scrollTo(this.$el, 500, { offset: -100 });
          sessionStorage.removeItem('questionIdAfterEdit');
        });
      }
    }
  }
