import { Vue, Component } from 'vue-property-decorator';
import type { Library } from '@app/models/widgets/report-graph';
import type { SuperReportGraphData, SuperReportGraphItem } from '@app/models/super-report';

@Component
export default class WithReportGraph extends Vue {
  reportData!: SuperReportGraphData;
  pieDataLabels!: Library['plotOptions']['pie']['dataLabels'];
  defaultRadius!: number;

  get getStaked(): boolean {
    return this.reportData.stacked;
  }

  get radius(): number {
    if (this.reportData) {
      return this.defaultRadius;
    }
    return 0;
  }

  get plotOptions(): Library['plotOptions'] {
    return {
      series: { marker: { radius: this.radius }, turboThreshold: 1000000 },
      pie: { showInLegend: true, dataLabels: { ...this.pieDataLabels } },
    };
  }

  get library(): Library {
    return {
      yAxis: { allowDecimals: false },
      xAxis: { labels: { padding: 4, style: { fontSize: 10 } } },
      plotOptions: this.plotOptions,
    };
  }

  get graphType(): string {
    return this.reportData?.type?.replace('_', '-');
  }

  get chartColors(): string[] {
    return this.reportData.colors;
  }

  get graphData(): Maybe<SuperReportGraphItem[] | [string, string | number][]> {
    return this.graphType === 'pie-chart' ? this.reportData?.graph_data?.[0]?.data : this.reportData?.graph_data;
  }
}
