
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';

  @Component({
    components: { DsCheckbox, FormField, EntitySelector, Select2 },
  })
  export default class TimesheetSummaryFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;

    beforeMount() {
      this.$$patch(this.value.config, { calculate_pay: 'true' });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['involvement_id', 'date_from_question_code', 'date_to_question_code', 'allow_overrides', 'calculate_pay'].forEach((key) =>
        this.$delete(this.value.config, key)
      );
    }

    get involvementFilters() {
      return { module_name: this.subForm.module_name };
    }

    get dateQuestionFilters() {
      return {
        field_type: FieldType.date,
        sub_form_section: { sub_form_id: this.subForm.id },
        active: true,
      };
    }

    get hourlyRateQuestionFilters() {
      return {
        sub_form_section: { sub_form_id: this.subForm.id },
        active: true,
      };
    }

    get allowOverridesOptions() {
      return this.hashToOptions({
        hours: 'Hours',
        ...(this.calculatePay
          ? {
              pay: 'Pay',
              hours_and_pay: 'Hours and Pay',
            }
          : {}),
      });
    }

    get calculatePay() {
      return '' + this.value.config.calculate_pay === 'true';
    }

    hashToOptions(hash: Dictionary<string>) {
      return Object.keys(hash).map((k) => [k, hash[k]]);
    }
  }
