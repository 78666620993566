
  import consumer from '@app/channels/consumer';
  import { BaseTable } from '@app/components/base-table';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import SearchInput from '@app/components/search-input.vue';
  import Select2 from '@app/components/select2.vue';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import type { DonesafeAutomationDefinitionsApiExtraOptions } from '@app/services/api/automation-definition-api';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { Subscription } from '@rails/actioncable';
  import bootbox from 'bootbox';
  import { flatten, groupBy, keyBy, sortBy, uniq } from 'lodash';
  import qs from 'qs';
  import { ProgressBar, ProgressBarStack } from 'uiv';
  import { mixins } from 'vue-class-component';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import PlaceholderProfiles from '@app/components/admin/events/forms/placeholder-profiles.vue';
  import TextHighlight from 'vue-text-highlight';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AUTOMATION_STRUCTURE, AutomationDefinitionState, AutomationTriggerOn } from '@app/models/automation-definition';
  import { groupAndCountEventTypes } from '@app/utils/group-and-count-event-types';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';
  import type { Relationship } from '@app/models/relationship';
  import type { SubForm } from '@app/models/sub-form';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { displayModuleName } from '@app/utils/display-module-name';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import DsPopover from '@app/components/ds-popover.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';

  import AutomationDefinitionsActions from './automation-definitions-actions.vue';
  import AutomationDefinitionActivationProgressBar from './automation-definition-activation-progress-bar.vue';
  import AutomationDefinitionDebugModalLink from './automation-definition-debug-modal-link.vue';
  import TriggerConditionsModal from './trigger-conditions-modal.vue';
  import type { FilterType } from './models';

  interface NewOption {
    ia?: object;
    name: string;
    type: string;
  }

  type NewOptionsMenuItem = Record<string, NewOption[]>;
  type NewOptionsMenu = Record<string, NewOptionsMenuItem>;

  interface RouterLink {
    name: string;
    params?: Record<string, string>;
    query?: Record<string, string>;
  }

  @Component({
    components: {
      DsPopover,
      AutomationDefinitionActivationProgressBar,
      AutomationDefinitionsActions,
      BaseTable,
      DsDropdown,
      DsIcon,
      FilterSelect,
      PlaceholderProfiles,
      ProgressBar,
      ProgressBarStack,
      RecordIndexPageFiltersLayout,
      SearchInput,
      Select2,
      TextHighlight,
      DsIconText,
      PaperTrailsModalLink,
      AutomationDefinitionDebugModalLink,
      TriggerConditionsModal,
    },
    methods: { groupAndCountEventTypes },
  })
  export default class AutomationDefinitionsTable extends mixins(WithSearchableDsDropdown) {
    @Prop() readonly newLinkBuilder!: (type: string, injectableArguments?: InjectableArguments) => RouterLink;
    @Prop() readonly editLinkBuilder!: (automationDefinition: AutomationDefinition) => RouterLink;
    @Prop() readonly cloneLinkBuilder!: (automationDefinition: AutomationDefinition) => RouterLink;
    @Prop() readonly logLinkBuilder!: (automationDefinition: AutomationDefinition) => RouterLink;
    @Prop(Boolean) readonly hideTabFilter?: boolean;
    @Ref() readonly table?: BaseTable<AutomationDefinition>;
    @Ref() readonly addNewDropdown?: DsDropdown;
    @Ref() readonly recordIndexFilters?: RecordIndexPageFiltersLayout;

    displayModuleOptions: string[][] = [];
    eventTypeOptions: string[][] = [];
    placeholderProfilesOptions: [number, string][] = [];
    manager: Nullable<ListManager<AutomationDefinition, DonesafeAutomationDefinitionsApiExtraOptions>> = null;
    moduleNames: Record<string, Pick<ModuleName, 'display' | 'name' | 'sub_form_id' | 'id' | 'hard_delete_records'>> = {};
    subForms: Record<string, Pick<SubForm, 'id' | 'title' | 'module_name'>[]> = {};
    involvements: Record<string, Pick<Involvement, 'id' | 'name'>[]> = {};
    relationships: Record<string, Pick<Relationship, 'code' | 'name'>[]> = {};
    automationDefinitionSubscription: Nullable<Subscription> = null;
    automationActivationProgress: Record<number, number> = {};
    reportConfigs: Pick<RegulatoryReportConfig, 'id' | 'name' | 'active'>[] = [];
    stateOptions = [
      [AutomationDefinitionState.active, 'Active'],
      [AutomationDefinitionState.inactive, 'Inactive'],
      [AutomationDefinitionState.activating, 'Activating'],
    ];
    addNewOptionsDropdownOpen = false;
    triggerConditionsAutomation: Nullable<AutomationDefinition> = null;
    triggerConditionsModalVisible = false;
    eventsExpanded: Record<string, boolean> = {};
    minHeight: string | null = null;

    get triggerOnOptions(): [string, string][] {
      return [
        [AutomationTriggerOn.Create, this.$t('tenant.admin.automation_definitions.triggers.on_create')],
        [AutomationTriggerOn.FieldUpdate, this.$t('tenant.admin.automation_definitions.triggers.on_update')],
        [AutomationTriggerOn.Delete, this.$t('tenant.admin.automation_definitions.triggers.on_delete')],
        [AutomationTriggerOn.ConditionsMet, this.$t('tenant.admin.automation_definitions.triggers.on_conditions')],
      ];
    }

    get triggerOnOptionsObject(): Record<string, string> {
      return this.triggerOnOptions.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, string>);
    }

    get showPlaceholderProfiles(): boolean {
      return !!this.placeholderProfilesOptions.length;
    }

    get tabs(): string[] {
      return (this.manager?.customFilters?.tab as string[]) || [];
    }

    get activeReportConfigs(): Pick<RegulatoryReportConfig, 'id' | 'name' | 'active'>[] {
      return this.reportConfigs.filter((reportConfig) => reportConfig.active);
    }

    get displayAutomationStructuresKeys(): string[] {
      return Object.keys(AUTOMATION_STRUCTURE).filter((key) => {
        return Object.keys(this.moduleNames).includes(key) || ['TenantUser', ACTIVITY_CONCEPT].includes(key);
      });
    }

    get displayModuleNameKeys(): string[] {
      return uniq(Object.keys(this.moduleNames).filter((key) => this.moduleNames[key].sub_form_id));
    }

    get filterTypes(): FilterType[] {
      const hardCoded = sortBy(
        [...this.displayAutomationStructuresKeys, ...(this.activeReportConfigs.length ? ['RegulatoryReport'] : [])].map((key) => {
          return {
            key: key,
            name: this.displayModuleName(key),
            group: this.$t('tenant.admin.automation_definitions.index.other'),
          };
        }),
        'name'
      );

      return [
        ...sortBy(
          [...this.displayModuleNameKeys].map((key) => {
            return {
              key: key,
              name: this.displayModuleName(key),
              group: this.$t('tenant.admin.automation_definitions.index.modules'),
              id: this.moduleNames[key].id,
            };
          }),
          (ft) => `${ft.name}`.toLowerCase()
        ),
        ...hardCoded,
      ];
    }

    get filterTypesForIndexAction(): FilterType[] {
      return this.tabs.length ? this.filterTypes.filter((ft) => this.tabs.includes(ft.key)) : this.filterTypes;
    }

    get searchableFilterTypes(): FilterType[] {
      return this.getSearchableItems<FilterType>(this.filterTypes, 'name');
    }

    get hiddenOptionsLength() {
      return this.filterTypes.length - (this.manager?.customFilters?.tab?.length || 0);
    }

    get showHiddenOptions() {
      return !this.hideTabFilter && !!this.hiddenOptionsLength && !!this.tabs.length;
    }

    get addNewOptions(): NewOptionsMenu {
      const concepts = this.tabs.length ? this.tabs : this.filterTypes.map((ft) => ft.key);

      return concepts.reduce((acc: NewOptionsMenu, tab) => {
        let addNewOptions: Record<string, NewOption[]> = {};

        if (tab === 'RegulatoryReport') {
          const options = this.reportConfigs.map((rrc) => {
            return {
              type: 'RegulatoryReport',
              ia: { regulatory_report_config_id: rrc.id },
              name: `Regulatory Report: ${rrc.name}`,
            };
          });
          addNewOptions = { ...addNewOptions, report: options };
        } else {
          const moduleName = this.moduleNames[tab];
          const subForms = this.subForms[tab];
          const relationships = this.relationships[tab];
          const involvements = this.involvements[tab];

          if (tab in AUTOMATION_STRUCTURE) {
            const hardcodedOptions = [tab, ...AUTOMATION_STRUCTURE[tab]];

            const options = hardcodedOptions.map((key) => {
              return {
                type: key,
                name: this.displayModuleName(key),
              };
            });
            addNewOptions = { ...addNewOptions, hardcoded: options };
          } else if (moduleName) {
            const options = [
              {
                type: moduleName.name,
                name: this.displayModuleName(moduleName.name),
              },
            ];
            addNewOptions = { ...addNewOptions, main_form: options };
          }

          if (subForms) {
            const subFormOptions = subForms.map((subForm) => {
              return {
                type: 'SubFormCompletion',
                ia: { sub_form_id: subForm.id },
                name: `Sub Form: ${subForm.title}`,
              };
            });
            addNewOptions = { ...addNewOptions, sub_forms: subFormOptions };
          }

          if (relationships) {
            const relationshipOptions = relationships.map((relationship) => {
              return {
                type: 'RecordRelation',
                ia: { relationship_code: relationship.code },
                name: `Relationship: ${relationship.name}`,
              };
            });
            addNewOptions = { ...addNewOptions, relationships: relationshipOptions };
          }

          if (involvements) {
            const involvementOptions = involvements.map((involvement) => {
              return {
                type: 'UserInvolvement',
                ia: { involvement_id: involvement.id },
                name: `User Involvement: ${involvement.name}`,
              };
            });
            addNewOptions = { ...addNewOptions, involvements: involvementOptions };
          }
        }
        return { ...acc, [tab]: addNewOptions };
      }, {});
    }

    get searchableAddNewOptions(): NewOptionsMenu {
      return Object.keys(this.addNewOptions).reduce((acc: NewOptionsMenu, tab): NewOptionsMenu => {
        const option = this.addNewOptions[tab];
        const newOption = Object.keys(option).reduce((acc: NewOptionsMenuItem, key): NewOptionsMenuItem => {
          const selected = option[key].filter((item) =>
            item.name?.toLowerCase().includes(this.searchQueries['addNewOptions'].toLowerCase())
          );
          return selected.length ? { ...acc, [key]: selected } : acc;
        }, {});

        return Object.keys(newOption).length ? { ...acc, [tab]: newOption } : acc;
      }, {});
    }

    onDropdownOpen(open: boolean) {
      const dropdownMenuElt = this.addNewDropdown?.dropdown?.$refs?.['dropdown'] as Maybe<HTMLElement>;
      const recordIndexFiltersElt = this.recordIndexFilters?.$el as Maybe<HTMLElement>;

      setTimeout(() => {
        this.minHeight = open ? `${(dropdownMenuElt?.offsetHeight || 0) + (recordIndexFiltersElt?.offsetHeight || 0)}px` : null;
      }, 1);
    }

    showExpandLink(refName: string): boolean {
      const element = this.$refs[refName] as HTMLElement;
      const keys = Object.keys(this.eventsExpanded);
      return (element && element.scrollHeight > element.clientHeight) || keys.includes(refName);
    }

    expandEventsClick(key: string): void {
      this.eventsExpanded = { ...this.eventsExpanded, [key]: !this.eventsExpanded[key] };
    }

    expandEventsTitle(key: string) {
      return this.eventsExpanded[key] ? this.$t('app.labels.collapse') : this.$t('app.labels.expand');
    }

    displayDivider(index: number, items: FilterType[]): boolean {
      return items?.[index]?.group !== items?.[index + 1]?.group && items.length !== index + 1;
    }

    conditionsClick(automationDefinition: AutomationDefinition) {
      this.triggerConditionsAutomation = automationDefinition;
      this.triggerConditionsModalVisible = true;
    }

    moduleNameLink(tab: string): string | undefined {
      const id = this.moduleNames[tab]?.id;
      if (id) {
        return `/admin/settings/module_names/${id}/automation_definitions`;
      }
    }

    addNewGroupName(key: string): string | undefined {
      switch (key) {
        case 'main_form':
          return this.$t('app.labels.main_form');
        case 'sub_forms':
          return this.$t('app.labels.sub_forms');
        case 'relationships':
          return this.$t('app.labels.relationships');
        case 'involvements':
          return this.$t('app.labels.user_involvements');
      }
    }

    addNewMenuItemName(key: string): string {
      return this.filterTypes.find((ft) => ft.key === key)?.name || '';
    }

    activationProgress(id: number): number {
      return this.automationActivationProgress[id] || 0;
    }

    isActivating(automationDefinition: AutomationDefinition): boolean {
      return automationDefinition.state === AutomationDefinitionState.activating;
    }

    deactivateAutomationDefinition(automationDefinition: AutomationDefinition): void {
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: this.$t('tenant.admin.automation_definitions.form.confirm_action'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api.deactivateAutomationDefinition(automationDefinition.id).then(() => {
              this.resetProgressCache(automationDefinition.id);
              this.$api.cache.clear();
              this.table?.reload();
              toaster({ text: this.$t('tenant.admin.automation_definitions.form.deactivated') as string });
            });
          }
        },
      });
    }

    resetProgressCache(id: number): void {
      this.automationActivationProgress = { ...this.automationActivationProgress, [id]: 0 };
    }

    displayModuleName(moduleName: string, subForm?: SubForm): string {
      return displayModuleName(moduleName, subForm, this.moduleNames, this.$t);
    }

    updateTab(key: string[]): void {
      this.manager?.setFilter('tab', key);
      this.fetchFilters(key);
    }

    linkAttributes(link: string | RouterLink) {
      if (typeof link === 'string') {
        return { href: link, role: 'button' };
      } else {
        return { to: link as RouterLink };
      }
    }

    newLink(type: string, injectableArguments?: InjectableArguments): string {
      const params = { ...injectableArguments, type, redirect_to: window.location.href };
      return `/admin/settings/automation_definitions/new?${qs.stringify(params)}`;
    }

    stateFilterMapping(states?: (AutomationDefinitionState.active | AutomationDefinitionState.inactive)[]): AutomationDefinitionState[] {
      const mapping = {
        [AutomationDefinitionState.active]: [AutomationDefinitionState.active],
        [AutomationDefinitionState.inactive]: [AutomationDefinitionState.inactive, AutomationDefinitionState.activating],
      };
      return states?.map((state) => mapping[state]).flat() || [];
    }

    getManager(): ListManager<AutomationDefinition, DonesafeAutomationDefinitionsApiExtraOptions> {
      return new ListManager<AutomationDefinition, DonesafeAutomationDefinitionsApiExtraOptions>({
        fetchDataFunction: (params) => {
          return this.$api.getAutomationDefinitions(
            {
              ...params,
              only: [
                'id',
                'name',
                'description',
                'display',
                'placeholder_profiles',
                'rule_set_label',
                'rule_set_id',
                'rule_set',
                'trigger_on',
                'trigger_when',
                'concept_name',
                'index',
                'state',
                'sub_form_id',
                'involvement_id',
                'relationship_code',
                'regulatory_report_config_id',
                { automated_events: ['event'] },
                { events: ['event_type_name', { chained_events: ['event_type_name'] }] },
              ],
              filters: {
                ...params.filters,
                state: this.stateFilterMapping(
                  params.filters?.state as (AutomationDefinitionState.active | AutomationDefinitionState.inactive)[]
                ),
              },
            },
            { cache: true }
          );
        },
        customFilters: {
          tab: [],
          state: [AutomationDefinitionState.active, AutomationDefinitionState.activating],
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created_at' }],
        fields: [
          { title: 'ID', name: 'id', sortField: 'id' },
          { title: 'Name', name: 'name', sortField: 'name' },
          { title: 'Triggered From', name: 'display', sortField: 'display', filter: true },
          { title: 'Triggered Type', name: 'trigger_on', sortField: 'trigger_on', filter: true },
          { title: 'Event Type', name: 'event_types', filter: true },
          { title: 'Number of Events', name: 'event_number' },
          { title: 'Order', name: 'index', sortField: 'index' },
          ...(this.showPlaceholderProfiles ? [{ title: 'Placeholder Profile(s)', name: 'placeholder_profiles', filter: true }] : []),
          { title: 'Active', name: 'state', sortField: 'state', filter: true },
          { title: '', name: 'menu' },
        ],
        allowFilters: true,
      });
    }

    async beforeMount(): Promise<void> {
      this.extendsDefaultSearchable('addNewOptions');
      await Promise.all([this.fetchPlaceholderProfileFilters(null), this.fetchModuleNames()]);
      this.manager = this.getManager();
      await this.fetchFilters(this.tabs);

      this.$api.getRegulatoryReportConfigs({ only: ['active', 'id', 'name'] }, { cache: true }).then(({ data }) => {
        this.reportConfigs = data;
      });

      this.$api
        .getSubForms({ only: ['id', 'title', 'module_name'], filters: { active: true }, per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.subForms = groupBy(data, 'module_name');
        });

      this.$api
        .getRelationships({ only: ['code', 'name', 'from_module'], filters: { active: true }, per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.relationships = groupBy(data, 'from_module');
        });

      this.$api
        .getInvolvements({ only: ['id', 'name', 'module_name'], filters: { active: true }, per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.involvements = groupBy(data, 'module_name');
        });
    }

    async fetchModuleNames() {
      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          filters: { active: true },
          per_page: -1,
          sort: 'display',
          only: ['name', 'display', 'sub_form_id', 'id', 'hard_delete_records'],
        },
        { cache: true }
      );

      this.moduleNames = keyBy(moduleNames, 'name');
    }

    async fetchFilters(tabs: string[]): Promise<void> {
      await Promise.all([this.fetchDisplayFilters(tabs), this.fetchEventTypeFilters(tabs), this.fetchPlaceholderProfileFilters(tabs)]);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchPlaceholderProfileFilters(_tabs: string[] | null): Promise<void> {
      const { data: placeholderProfiles } = await this.$api.getPlaceholderProfiles({}, { cache: true });
      this.placeholderProfilesOptions = placeholderProfiles.map(({ id, name }) => [id, name]);
    }

    async fetchDisplayFilters(tabs: string[]): Promise<void> {
      const { data: displayFilters } = await this.$api.getDisplayFilters({ filters: { tab: tabs } }, { cache: true });
      this.displayModuleOptions = Object.entries(displayFilters);
    }

    async fetchEventTypeFilters(tabs: string[]): Promise<void> {
      const { data: eventTypeFilters } = await this.$api.getEventTypeFilters({ filters: { tab: tabs } }, { cache: true });
      this.eventTypeOptions = Object.entries(eventTypeFilters);
    }

    detailsFor(rowData: AutomationDefinition) {
      return [
        this.$t(`tenant.admin.automation_definitions.triggers.${rowData.trigger_on}`, {
          type: this.displayModuleName(rowData.concept_name),
        }),
        rowData.rule_set_id ? this.$sanitize(rowData.rule_set_label || '') : null,
      ]
        .filter((v) => !!v)
        .join(': ');
    }

    warningsFor(rowData: AutomationDefinition) {
      if (!(rowData.trigger_on === 'delete')) {
        return;
      }

      const moduleName =
        rowData.concept_name == 'SubFormCompletion'
          ? flatten(Object.values(this.subForms)).find(({ id }) => id === rowData.sub_form_id)?.module_name
          : rowData.concept_name;

      const hardDeletedTrigger =
        this.moduleNames[moduleName || '']?.hard_delete_records || ['Procedure', 'TenantUser'].includes(rowData.concept_name);

      if (hardDeletedTrigger) {
        return this.$t('tenant.admin.automation_definitions.form.hard_delete_warning_list');
      }
    }

    beforeDestroy(): void {
      this.automationDefinitionSubscription?.unsubscribe();
    }

    mounted(): void {
      this.automationDefinitionSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'AutomationDefinition' },
        {
          received: ({ id, state, progress }: { id: number; progress?: string; state: string }) => {
            if (id) {
              const targetAutomation = this.manager?.items.find((item) => item.id === id);
              if (targetAutomation) {
                state && Vue.set(targetAutomation, 'state', state);
                progress && Vue.set(this.automationActivationProgress, targetAutomation.id, +progress * 100);
              }
            }
          },
        }
      );
    }
  }
