
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      DsModal,
      DsIcon,
    },
  })
  export default class RecalculationAssistantModal extends Vue {
    @Prop({ required: true, type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ required: true, type: [String, Number] }) readonly recordCalculationId!: number | string;

    recordCalculations: Partial<RecordCalculation>[] = [];
    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.push({
        name: 'admin-settings-module-names-record-calculations',
        params: {
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    recalculateFormula(): void {
      this.$api
        .recalculateRecordCalculation(this.recordCalculationId as RecordCalculation['id'])
        .then(() => {
          this.closeModal('reload');
          toaster({ text: this.$t('tenant.admin.record_calculation.modal.recalculation.submitted'), position: 'top-right' });
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    getDependantVariables(): void {
      this.$api
        .getRecordCalculation(this.recordCalculationId as RecordCalculation['id'], { only: ['id', 'connections'] }, { cache: true })
        .then(({ data }) => {
          this.recordCalculations = data.connections || [];
        });
    }

    beforeMount(): void {
      this.getDependantVariables();
    }
  }
