
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { groupBy } from 'lodash';

  import { Component, Vue } from 'vue-property-decorator';
  import type { Dashboard } from '@app/models/dashboard';
  import type { SuperReport } from '@app/models/super-report';

  interface ClassicReportLink {
    title: string;
    url: string;
    visible: boolean;
  }

  @Component
  export default class ReportsPage extends Vue {
    dashboards: Dashboard[] = [];
    superReports: SuperReport[] = [];
    activeModules: Record<string, boolean> = {};
    loading = true;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    getDashboardTitle(dashboard: Dashboard): string {
      return this.currentUserStore.data?.role?.dashboard_id === dashboard.id
        ? `${this.$t('app.labels.name_default', { name: dashboard.name })}`
        : dashboard.name;
    }

    tenantIs(tenant: string): boolean {
      return this.accountStore.data.schema === tenant || this.accountStore.data.name.toLowerCase() === tenant.toLowerCase();
    }

    get reportsSet(): Record<string, ClassicReportLink[]> {
      return groupBy(
        [
          {
            url: '/reports/incident_summary',
            title: this.$t('custom_reports.incident_summary'),
            visible: !this.accountStore.data.hide_classic_reports && this.activeModules.Incident,
            column: 'a',
          },
          {
            url: '/reports/injury_summary',
            title: this.$t('custom_reports.injury_summary'),
            visible: !this.accountStore.data.hide_classic_reports && this.activeModules.Incident,
            column: 'a',
          },
          {
            url: '/calendars/safety_calendar',
            title: this.$t('custom_reports.safety_calendar'),
            visible: !this.accountStore.data.hide_classic_reports && !this.tenantIs('suncorp'),
            column: 'b',
          },
          {
            url: '/reports/summary_report',
            title: this.$t('custom_reports.report_builder'),
            visible: !this.accountStore.data.hide_classic_reports && !!this.currentUserStore.data?.role?.report_builder_access,
            column: 'b',
          },
          {
            url: '/reports/audit_summary',
            title: this.$t('custom_reports.audit_summary'),
            visible: this.activeModules.Audit,
            column: 'a',
          },
          {
            url: '/reports/hazard_summary',
            title: this.$t('custom_reports.hazard_summary'),
            visible: this.activeModules.Hazard,
            column: 'a',
          },
          {
            url: '/reports/observation_summary',
            title: this.$t('custom_reports.observation_summary'),
            visible: this.activeModules.Observation,
            column: 'a',
          },
          {
            url: '/location_tag_reports',
            title: this.$t('custom_reports.safety_tag_report'),
            visible: !this.accountStore.data.disable_location_tag_report,
            column: 'b',
          },
          {
            url: '/acknowledgement_reports/acknowledgement_report',
            title: this.$t('custom_reports.acknowledgement_report'),
            visible: this.activeModules.Procedure,
            column: 'b',
          },
          {
            url: '/users',
            title: this.$t('tenant.users.index.user_directory'),
            visible: !!this.currentUserStore.data?.role?.user_directory_access,
            column: 'b',
          },
        ].filter(({ visible }) => visible),
        'column'
      );
    }

    beforeMount(): void {
      Promise.all([
        this.$api
          .getDashboards(
            {
              filters: { active: true },
              only_accessible: true,
              only: ['id', 'name'],
              per_page: -1,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.dashboards = data;
          }),
        this.$api
          .getSuperReports(
            {
              only: ['id', 'name'],
              sort: 'name',
              per_page: -1,
              filters: { ...(!!this.currentUserStore.data?.role_id ? { roles: { id: this.currentUserStore.data?.role_id } } : {}) },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.superReports = data;
          }),
        this.$api.getModuleNames({ filters: { active: true }, only: ['name'] }, { cache: true }).then(({ data }) => {
          this.activeModules = data.reduce((memo, { name }) => ({ ...memo, [name]: true }), {});
        }),
      ]).finally(() => (this.loading = false));
    }
  }
