
  import { Component, Emit, Watch } from 'vue-property-decorator';
  import { toaster } from '@app/utils/toaster';
  import { debounce } from 'lodash';
  import UserSelector from '@app/components/user/user-selector.vue';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import type { TenantUser } from '@app/models/tenant-user';

  @Component({ components: { UserSelector } })
  export default class KbNotificationUsersField extends mixins(Blocking) {
    acknowledgementsUserIds: (string | number)[] = [];

    fetchFilters = { active: true, kb_acknowledgements: true };

    debouceApiCall = debounce(this.callApi, 1000);
    @Watch('blocked')
    @Emit('submitting')
    emitSubmitting(value: boolean) {
      return value;
    }

    userWithNotificationsOn(): void {
      this.blocking(
        async () => {
          const { data } = await this.$api.getTenantUsers(
            {
              per_page: -1,
              sort: 'full_name',
              only: ['id', 'full_name', 'kb_acknowledgements'],
              filters: this.fetchFilters,
            },
            { cache: false }
          );
          this.acknowledgementsUserIds = data.map((v) => v.id);
          this.loading = false;
        },
        { loading: true }
      );
    }

    onUserAddedToKBNotifications(ids: (string | number)[]) {
      this.acknowledgementsUserIds = ids;
      this.emitSubmitting(true);
      this.debouceApiCall(ids);
    }

    callApi(ids: (string | number)[]) {
      this.blocking(
        async () => {
          const { data } = await this.$api.updateUserAcknowledgements({ user_ids: ids });
          this.acknowledgementsUserIds = data.map((v: TenantUser) => v.id);
          toaster({
            text: this.$t('tenant.admin.module_names.form.user_added_to_notifications'),
            position: 'top-right',
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        },
        {
          errorCb: (_err, text) => {
            toaster({ text, icon: 'error' });
          },
        }
      );
    }

    beforeMount() {
      this.userWithNotificationsOn();
    }
  }
