
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ScoredQuestionConfig from './shared/scored-question-config.vue';
  import FormField from '../edit/form-field.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { VideoQuestionOptions } from '@app/models/question-options/video-question-options';

  @Component({ components: { ScoredQuestionConfig, FormField } })
  export default class VideoFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<VideoQuestionOptions>;

    @Prop(Object) readonly features!: ModuleFeatures;

    videoValidationRules = { regex: /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/ };

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['video_url'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
