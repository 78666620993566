
  import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: { BaseTable },
  })
  export default class ModuleQuestionSetsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<SubFormQuestionSet>;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    manager: Nullable<ListManager<SubFormQuestionSet>> = null;
    moduleName: Nullable<ModuleName> = null;

    getManager(): ListManager<SubFormQuestionSet> {
      return new ListManager<SubFormQuestionSet>({
        fetchDataFunction: (params) => {
          return this.$api.getQuestionSets(
            {
              ...params,
              filters: { module_name: this.moduleName?.name },
              include: ['automation_definition_ids', 'sub_form_question_ids'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: -1,
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: 'max-content' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
        ],
      });
    }

    async mounted(): Promise<void> {
      const { data } = await this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true });
      this.moduleName = data;
      this.manager = this.getManager();
    }
  }
