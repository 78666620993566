import { render, staticRenderFns } from "./configs-list-page.vue?vue&type=template&id=5a2ab2f1&scoped=true&lang=pug"
import script from "./configs-list-page.vue?vue&type=script&lang=ts"
export * from "./configs-list-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2ab2f1",
  null
  
)

export default component.exports