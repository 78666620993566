
  import { Component, Model, Vue } from 'vue-property-decorator';
  import { AttachmentsUploader } from '@app/components';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  import FormField from '../edit/form-field.vue';

  @Component({
    components: { AttachmentsUploader, FormField },
  })
  export default class MarkableFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion;

    get imageUrl(): string {
      return [...(this.value.attachments || [])].reverse()[0]?.url;
    }

    get showUploader() {
      return !this.value.id || !this.value.attachments;
    }

    onAttachmentInput(input: string[]) {
      this.value.attachments = input.map((id) => ({ id })) as unknown as Attachment[];
    }
  }
