
  import { Component, Prop, Vue, Ref, Model, Watch } from 'vue-property-decorator';
  import froalaConfig from '@app/froala-config';

  const DEFAULT_WIDTH = 400;

  @Component
  export default class TranslationField extends Vue {
    @Ref() readonly froalaView?: Vue;
    @Model('input') readonly value!: string;
    @Prop(String) readonly name!: string;

    width: number = DEFAULT_WIDTH;
    newValue: string = this.value;
    readonly = true;

    get froalaConfig() {
      return froalaConfig({ complex: false }, { autofocus: true, width: this.width }, { blur: this.handleBlur });
    }

    @Watch('newValue')
    updateValue() {
      this.$emit('input', this.newValue);
    }

    click() {
      this.showEditor();
    }

    handleFocus() {
      this.showEditor();
    }

    showEditor() {
      this.readonly = false;
      this.width = (this.froalaView?.$el as HTMLElement)?.offsetWidth || DEFAULT_WIDTH;
    }

    handleBlur() {
      this.readonly = true;
    }
  }
