import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import type { Widget } from '@app/models/widget';
import type { ValidationObserver } from 'vee-validate';

@Component
export default class BaseWidgetSettings<V> extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Object) readonly widget!: Widget<V>;
  @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;

  form: Partial<V> = {};
}
