
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { ObserveTemplate } from '@app/models/observe_template';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    auditor_ids: string;
    conducted: string;
    description: string;
    expiry_date: string;
    location_id: string;
    observe_template_id: string;
    title: string;
    user_id: string;
  }

  @Component({ components: { Select2, EntitySelector, EventInputMethodSwitch, AdvancedAutosuggest, FormField } })
  export default class AuditEventForm extends BaseEventForm<FormValues> {
    get templateFilters(): DonesafeFilterOptions<ObserveTemplate> {
      return {
        active: true,
        template_type: 'audit',
      };
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
