
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Watch } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import { BaseTable } from '@app/components/base-table';
  import SearchInput from '@app/components/search-input.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';

  import DsPopover from '@app/components/ds-popover.vue';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import WithPlaceholderProfileTypeOptions from '@app/mixins/with-placeholder-profile-type-options';

  @Component({
    components: { PaperTrailsModalLink, DsDropdown, BaseTable, SearchInput, FilterSelect, Select2, DsPopover },
  })
  export default class PlaceholderProfilesPage extends mixins(WithPlaceholderProfileTypeOptions) {
    @Ref() readonly table?: BaseTable<PlaceholderProfile>;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    manager: Nullable<ListManager<PlaceholderProfile>> = null;
    activeOptions: [string, string][] = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    getManager() {
      return new ListManager<PlaceholderProfile>({
        fetchDataFunction: (params) =>
          this.$api.getPlaceholderProfiles(
            {
              ...params,
              include: ['automation_definition_ids', 'sub_form_questions'],
              filters: { ...params.filters },
            },
            { cache: true }
          ),
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        fields: [
          { title: this.$t('app.labels.title'), name: 'name', sortField: 'name' },
          { title: this.$t('tenant.settings.placeholder_profiles.index.handle'), name: 'handle', sortField: 'handle' },
          // { title: this.$t('app.labels.type'), name: 'profile_type', sortField: 'profile_type', filter: true },
          { title: this.$t('app.labels.status'), name: 'active', sortField: 'active', filter: true },
          { title: this.$t('tenant.settings.placeholder_profiles.index.automations'), name: 'automation_definitions' },
          { title: this.$t('tenant.settings.placeholder_profiles.index.sub_form_questions'), name: 'sub_form_questions' },
          { title: '', name: 'operations' },
        ],
        allowFilters: true,
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }
  }
