
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class UserInvolvementParentType extends Vue {
    @Prop(Number) readonly id!: number;

    title = '';
    subTitle: Nullable<string> = null;

    get recordPath(): string | undefined {
      return;
    }

    async beforeMount(): Promise<void> {
      try {
        const { data } = await this.$api.getUserInvolvement(
          this.id,
          { only: ['id', { involvement: ['name'], user: ['full_name'] }] },
          { cache: true, join: true }
        );
        const deletedText = `(${this.$t('app.labels.deleted')})`;
        this.title = `[${this.id}] ${data.involvement?.name ?? deletedText} - ${data.user?.full_name ?? deletedText}`;
      } catch (error) {
        this.title = `[${this.id}] (${this.$t('app.labels.not_available')})`;
      }
      this.subTitle = this.$t('app.labels.user_involvement');
    }
  }
