
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { PayCalculatorQuestionOptions } from '@app/models/question-options/pay-calculator-options';

  @Component({
    components: { DsCheckbox },
  })
  export default class PayCalculatorFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<PayCalculatorQuestionOptions>;

    beforeMount() {
      this.$$patch(this.value.config, { expanded: 'true' });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['expanded'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
