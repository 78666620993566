import { mapKeys, mapValues } from 'lodash';

export const processSelectableOptions = (
  options: Record<string, Record<string, string>>,
  prefix: '~' | '^' | '&',
  labelTransform?: (label: string) => string
): Record<string, Record<string, string>> => {
  return mapValues(options, (option) =>
    mapValues(
      mapKeys(option, (value, key) => `${prefix}${key}`),
      labelTransform || ((label) => label)
    )
  );
};
