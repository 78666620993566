
  import type { RuleSetFilter } from '@app/models/rule-set-filter';
  import { Component, Model, Prop, Provide, Ref, Vue } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { addIcon } from '@iconify/vue2/dist/offline';
  import egual from '@iconify-icons/mdi/equal';
  import notEqual from '@iconify-icons/mdi/not-equal-variant';
  import lessThan from '@iconify-icons/mdi/less-than';
  import lessThanOrEqual from '@iconify-icons/mdi/less-than-or-equal';
  import greaterThan from '@iconify-icons/mdi/greater-than';
  import greaterThanOrEqual from '@iconify-icons/mdi/greater-than-or-equal';
  import contain from '@iconify-icons/mdi/contain';
  import containEnd from '@iconify-icons/mdi/contain-end';
  import containStart from '@iconify-icons/mdi/contain-start';
  import setCenter from '@iconify-icons/mdi/set-center';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  import DsQueryBuilderHandler from './models/ds-query-builder-handler';
  import DsGroupItem from './group/ds-group-item.vue';

  addIcon('mdi:equal', egual);
  addIcon('mdi:not-equal-variant', notEqual);
  addIcon('mdi:less-than', lessThan);
  addIcon('mdi:less-than-or-equal', lessThanOrEqual);
  addIcon('mdi:greater-than', greaterThan);
  addIcon('mdi:greater-than-or-equal', greaterThanOrEqual);
  addIcon('mdi:contain', contain);
  addIcon('mdi:contain-end', containEnd);
  addIcon('mdi:contain-start', containStart);
  addIcon('mdi:set-center', setCenter);

  const FIELD_VID_PREFIXES = ['rule-filter-', 'rule-value-select-', 'rule-value-number-input-', 'rule-value-text-input-'];

  @Component({ components: { DsGroupItem, ValidationObserver, ValidationProvider } })
  export default class DsQueryBuilder extends Vue {
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;
    @Model('input') value!: string;
    @Prop() readonly filters!: RuleSetFilter[];
    @Prop(Boolean) readonly editable?: boolean;
    @Prop(Boolean) readonly debug?: boolean;
    @Prop(Array) readonly missedRuleIds?: string[];
    @Prop(String) readonly name!: string;
    @Prop() readonly injectableOptions?: PlaceholderItem[];
    @Prop(Boolean) readonly hideFirstGroupLabel?: boolean;

    @Provide() qb = new DsQueryBuilderHandler({
      filters: this.filters,
      editable: this.editable,
      debug: this.debug,
      query_builder_blob: this.value,
      missed_rule_ids: this.missedRuleIds,
      name: this.name,
      injectable_options: this.injectableOptions,
    });

    get qbReady() {
      return this.qb?.rootGroup !== null;
    }

    get rootGroupLength() {
      return this.qb?.rootGroup?.rules?.length || 0;
    }

    validate() {
      return this.validator.validateWithInfo().then(({ isValid, errors }) => {
        if (isValid) {
          this.qb?.clearValidationErrors();
        } else {
          const nonEmptyErrors = this.getNonEmptyErrors(errors);
          this.qb?.setValidationErrors(nonEmptyErrors);
        }

        return isValid;
      });
    }

    removePrefix(str: string, prefixesToRemove: string[]) {
      for (const prefix of prefixesToRemove) {
        if (str.startsWith(prefix)) {
          return str.substring(prefix.length);
        }
      }
      return str;
    }

    getNonEmptyErrors(errors: Record<string, string[]>) {
      return Object.keys(errors).reduce((acc, fieldId) => {
        if (!!errors[fieldId].length) {
          const ruleId = this.removePrefix(fieldId, FIELD_VID_PREFIXES);
          return { ...acc, [ruleId]: [...(acc?.[ruleId] || []), ...errors[fieldId]] };
        }
        return acc;
      }, {} as Record<string, string[]>);
    }

    save() {
      return this.validate().then((result) => {
        if (result) {
          const preparedValue = this.qb?.prepareForSave();
          return preparedValue;
        } else {
          return null;
        }
      });
    }

    beforeDestroy() {
      this.qb?.destroy();
    }
  }
