
  import DsDropdown from '@app/components/ds-dropdown.vue';

  import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';

  @Component({ components: { DsDropdown } })
  export default class SubFormSectionsNewButton extends Vue {
    @Ref() readonly subFormSectionsNewButton?: Element;

    @Prop(Boolean) readonly withGroupOption?: boolean;
    @Prop(Boolean) readonly dragging?: boolean;
    @Prop(Boolean) readonly last?: boolean;

    @Emit('insert-section')
    insertSection() {
      return;
    }

    @Emit('insert-section-group')
    insertSectionGroup() {
      return;
    }

    onMouseEnter() {
      this.hoveredOver = true;
      if (this.subFormSectionsNewButton) this.notCloseElements = [this.subFormSectionsNewButton];
    }

    onMouseLeave() {
      this.hoveredOver = false;
      this.notCloseElements = [];
    }

    hoveredOver = false;
    dropdownOpen = false;
    notCloseElements: Element[] = [];

    get buttonVisible(): boolean {
      return this.hoveredOver || !!this.last;
    }

    get insertNewSectionLabel(): string {
      return this.withGroupOption
        ? this.$t('tenant.admin.sub_form_section_groups.insert_new_section_to_group')
        : this.$t('tenant.admin.sub_form_section_groups.insert_new_section');
    }
  }
