
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import RestrictOptions from './shared/restrict-options.vue';
  import DateCalculationOptions from './shared/date-calculation-options.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({
    components: { DateCalculationOptions, RestrictOptions },
  })
  export default class DateFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion;

    @Prop(Object) readonly subForm!: SubForm;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'date_calculation',
        'if_empty',
        'if_missing',
        'operator',
        'restrict_type',
        'sub_form_list_id',
        'sub_form_question_code',
        'transform',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
