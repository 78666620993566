
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import RelatedInvolvement from './shared/related-involvement.vue';
  import type { TimesheetQuestionOptions } from '@app/models/question-options/timesheet-question-options';
  import { titleize } from '@app/utils/titleize';

  @Component({ components: { RelatedInvolvement } })
  export default class TimesheetShowOptions extends BaseShowOptions<TimesheetQuestionOptions> {
    get timesheetType(): string {
      return !!this.question.config?.timesheet_type ? titleize(this.question.config.timesheet_type) : '';
    }
  }
