
  import { Component } from 'vue-property-decorator';
  import BaseWidget from './base-widget';
  import { v4 as generateUUID } from 'uuid';
  import type { ReportCountWidgetOptions } from '@app/models/widget';
  import { ReportCountTargetDifference } from '@app/models/widget';
  import type { RequiredBy } from '@app/utils/types/required-by';

  @Component
  export default class ReportCountWidget extends BaseWidget<ReportCountWidgetOptions> {
    total = 0;
    targetTotal = 0;
    isContentHidden = false;
    loading = true;

    uuid = generateUUID();

    get widgetId(): string {
      return `widget_${this.uuid}`;
    }

    get overrideStyleHtml(): string {
      return `
       @media (min-width: 779px) {
          #${this.widgetId} .widget-content { ${this.overrideStyle('widget_content_style')} }
          #${this.widgetId} h2 { ${this.overrideStyle('value_style')} }
          #${this.widgetId} h2 span { ${this.overrideStyle('value_style')} }
          #${this.widgetId} h3.percentage { ${this.overrideStyle('percentage_style')} }
          #${this.widgetId} h3 i.material-icons { ${this.arrowStyle} }
          #${this.widgetId} h4 { ${this.overrideStyle('title_style')} }
          #${this.widgetId} h4 span { ${this.overrideStyle('title_style')} }
        }
     `;
    }

    get percentageString(): string {
      return `${Math.abs(this.percentage)}%`;
    }

    get totalString(): string {
      return Math.round(this.total).toString();
    }

    get targetDifferenceIsTotal(): boolean {
      return this.widget.options.target_difference === ReportCountTargetDifference.total && !!this.targetTotal;
    }

    get targetDifferenceIsDifference(): boolean {
      return this.widget.options.target_difference === ReportCountTargetDifference.difference && !!this.targetTotal;
    }

    get expendType(): string {
      return this.percentage > 0 ? 'expand_less' : 'expand_more';
    }

    get reportLink(): string {
      return `/reports/super_report?super_report_id=${this.widget.options.super_report_id}`;
    }

    get widgetStyle(): Record<string, string> {
      if (this.widget.options.background_color) {
        return { 'background-color': this.widget.options.background_color };
      }
      return {};
    }

    overrideStyle(styleName: keyof RequiredBy<ReportCountWidgetOptions, 'overrides'>['overrides']): string | undefined {
      const overrides = this.widget.options.overrides;

      if (overrides?.[styleName]) {
        return overrides[styleName];
      }
    }

    get arrowStyle(): string | undefined {
      if (this.widget.options.overrides?.arrow_positive_style && this.percentage > 0) {
        return this.widget.options.overrides.arrow_positive_style;
      } else if (this.widget.options.overrides?.arrow_parity_style && this.percentage === 0) {
        return this.widget.options.overrides.arrow_parity_style;
      } else if (this.widget.options.overrides?.arrow_negative_style && this.percentage < 0) {
        return this.widget.options.overrides.arrow_negative_style;
      }
    }

    get textColorStyle(): string {
      if (this.widget.options.text_color) {
        return `color: ${this.widget.options.text_color};`;
      }
      return '';
    }

    fetchCount(): void {
      if (this.accountStore.data.schema) {
        const reportId = Number(this.widget.options.super_report_id);
        const request = this.isPublicDashboard
          ? this.$api.getPublicSuperReportTotal(reportId, this.accountStore.data.schema, { cache: true })
          : this.$api.getSuperReportTotal(reportId, { cache: true });
        request
          .then(({ data }) => {
            this.total = data.total;
            this.targetTotal = data.target_total;
            this.loading = false;
          })
          .catch(() => {
            this.isContentHidden = !!this.isPublicDashboard;
          });
      }
    }

    get percentage(): number {
      if (this.targetDifferenceIsTotal) {
        return Math.round((this.total / this.targetTotal) * 100);
      } else if (this.targetDifferenceIsDifference) {
        return Math.round((this.total / this.targetTotal) * 100) - 100;
      } else {
        return 0;
      }
    }

    get showReportLink(): boolean {
      return (
        !this.isPublicDashboard &&
        !!this.currentUserStore.data?.role?.super_report_ids?.map((id) => `${id}`)?.includes(this.widget.options.super_report_id)
      );
    }

    mounted(): void {
      this.fetchCount();
    }
  }
