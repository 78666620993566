
  import { useAccountStore } from '@app/stores/account';
  import ActivityActionButton from './activity-action-button.vue';
  import PriorityLabel from './priority-label.vue';
  import { Component, Prop } from 'vue-property-decorator';
  import ActivityActions from './activity-actions.vue';
  import BaseActivity from './base-activity';
  import type { ActivitiesListActivityOnly } from './utils';
  import DsIconText from '../ds-icon-text.vue';
  import type { Activity } from '@app/models/activity';
  import { ActivityState } from '@app/models/activity';

  @Component({ components: { ActivityActionButton, PriorityLabel, ActivityActions, DsIconText } })
  export default class ActivitiesListItem extends BaseActivity<Pick<Activity, ActivitiesListActivityOnly>> {
    @Prop(Boolean) readonly loading?: boolean;

    get accountStore() {
      return useAccountStore();
    }

    get statusIcon() {
      return this.uiState?.state === ActivityState.Open ? 'contrast' : 'circle';
    }

    get showFooter() {
      return this.showPriorities || this.uiState?.state === ActivityState.Closed || this.activity.can_edit;
    }
  }
