
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';

  @Component({ components: {} })
  export default class HybridSidebar extends Vue {
    hybridModuleNames: ModuleName[] = [];
    requestCompleted = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleNames(
        {
          filters: {
            active: true,
            module_type: 'form_managed',
            allow_editing_for_settings_admin: this.currentUserStore.hasSystemAdministrationPermission ? undefined : true,
          },
        },
        { cache: true }
      );

      this.hybridModuleNames = data;
      this.requestCompleted = true;
    }

    get noSettings(): boolean {
      return !this.hybridModuleNames.length;
    }

    get accountStore() {
      return useAccountStore();
    }
  }
