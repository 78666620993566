
  import { ValidationObserver } from 'vee-validate';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import Select2 from '@app/components/select2.vue';

  import type { PlaceholderProfileForm } from '@app/pages/admin/placeholder_profiles/utils';

  @Component({ components: { ValidationObserver, Select2, Tooltip, DsTextInput } })
  export default class GlobalPlaceholderProfileConfigForm extends Vue {
    @Prop(Object) readonly form!: Partial<PlaceholderProfileForm>;
    @Prop(Boolean) readonly disabled?: boolean;

    labelClass(index: number): Record<string, boolean> {
      return { 'visible-xs visible-sm': index !== 0 };
    }
  }
