import { Component } from 'vue-property-decorator';
import WithBeforeUnload from '@app/mixins/with-before-unload';
import I18n from '@app/i18n';

@Component
export default class BaseAdminRolesPage extends WithBeforeUnload {
  formHasChanges = false;
  customBeforeUnloadMessage = I18n.t('tenant.admin.roles.rtb.unsaved_changes_alert');
  shouldPreventNavigation = true;
}
