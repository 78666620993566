
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import ColorInput from '../color-input.vue';
  import { isEmpty } from 'lodash';
  import bootbox from 'bootbox';
  import DefaultScoreBandOptionsModal from './default-score-band-options-modal.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import type { ScoreBand } from '@app/models/score-band';
  import type { DefaultScoreBandOptions } from '@app/models/default-score-band-options';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      DsModal,
      ColorInput,
      'def-score-band-options-modal': DefaultScoreBandOptionsModal,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class ScoreBandProfileFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ type: [String, Number] }) readonly scoreBandId?: number | string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Omit<ScoreBandProfile, 'score_bands' | 'no_band_score_band'>> & {
      no_band_score_band?: Partial<ScoreBand>;
      score_bands?: Partial<ScoreBand>[];
    } = {};
    defaultScoreBandEnabled = false;
    scoreBandStatesOptionsModalVisible = false;
    visible = true;
    validationErrors: string[] = [];
    scoreBandProfile: Partial<ScoreBandProfile> = {};

    get formTitle(): string {
      if (this.scoreBandProfile.id) {
        return this.$t('tenant.admin.score_bands.edit.form_title');
      } else {
        return this.$t('tenant.admin.score_bands.new.form_title');
      }
    }

    get defaultStateOptionsText(): string {
      let result = '';
      if (this.form.options?.default_states) {
        if (this.form.options?.default_states.sub_form_states.length > 0) {
          result = 'In ';
          const states = this.form.options?.default_states.sub_form_states.map(
            (stateName) => stateName.charAt(0).toUpperCase() + stateName.slice(1)
          );
          result = result + states.join(', ') + ' state/s';

          if (this.form.options?.default_states.award_when_score_zero) {
            result = result + ' or 0/0';
          }
        } else if (this.form.options?.default_states.award_when_score_zero) {
          result = 'With 0/0 score';
        } else if (this.form.options?.default_states.main_form_states.length > 0) {
          result = 'For special main form state/s';
        } else {
          result = 'Not specified';
        }
      } else {
        result = 'Not specified';
      }

      return result;
    }

    get activeRanges(): Partial<ScoreBand>[] {
      return this.form.score_bands || [];
    }

    get defaultScoreBands(): Partial<ScoreBand>[] {
      return [
        {
          label: this.$t('app.labels.pass') as string,
          code: 'pass',
          range_start: 50,
          range_end: 100,
          color: '#BFE562',
        },
        {
          label: this.$t('app.labels.fail') as string,
          code: 'fail',
          range_start: 0,
          range_end: 49,
          color: '#CE6B68',
        },
      ];
    }

    toggleScoreBandStatesOptionsModal(value: boolean): void {
      this.scoreBandStatesOptionsModalVisible = value;
    }

    submitDefaultScoreBandOptionsForm(options: DefaultScoreBandOptions): void {
      this.form.options = { ...this.form.options, default_states: options };
      this.toggleScoreBandStatesOptionsModal(false);
    }

    archive(): void {
      const message = this.$t('tenant.admin.score_bands.ranges.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: {
            label: this.$t('app.buttons.confirm'),
            className: 'btn-success',
          },
          cancel: {
            label: this.$t('app.buttons.cancel'),
            className: 'btn-default',
          },
        },
        callback: (result: boolean) => {
          if (result && this.scoreBandProfile?.id) {
            this.$api
              .updateScoreBandProfile(this.scoreBandProfile.id, { active: false })
              .then(({}) => {
                this.closeModal('reload');
              })
              .catch(({ data }) => {
                this.validationErrors = data.error;
              });
          }
        },
        backdrop: false,
      });
    }

    restore(): void {
      const message = this.$t('tenant.admin.score_bands.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result && this.scoreBandProfile.id) {
            this.$api
              .updateScoreBandProfile(this.scoreBandProfile.id, { active: true })
              .then(({}) => {
                this.closeModal('reload');
              })
              .catch(({ data }) => {
                this.validationErrors = data.error;
              });
          }
        },
      });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.createUpdateScoreBandProfile({
            ...this.form,
            no_band_score_band: this.defaultScoreBandEnabled ? this.form.no_band_score_band : undefined,
          } as Partial<ScoreBandProfile>);
        }
      });
    }

    createUpdateScoreBandProfile(scoreBandProfile: Partial<ScoreBandProfile>): void {
      const promise = scoreBandProfile.id
        ? this.$api.updateScoreBandProfile(scoreBandProfile.id, scoreBandProfile)
        : this.$api.createScoreBandProfile(scoreBandProfile);

      promise
        .then(({}) => {
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          this.validationErrors = [data.error].flat();
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-name-scoring-score-bands',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    addNewRange(): void {
      const ranges = this.form.score_bands || [];
      const newRange = {
        range_start: 0,
        range_end: 100,
        color: '#000000',
        _new: true,
      };

      this.form.score_bands = [...ranges, newRange];
    }

    removeRange(index: number): void {
      const message = this.$t('tenant.admin.score_bands.ranges.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: {
            label: this.$t('app.buttons.confirm'),
            className: 'btn-success',
          },
          cancel: {
            label: this.$t('app.buttons.cancel'),
            className: 'btn-default',
          },
        },
        callback: (result: boolean) => {
          if (result) {
            const ranges = this.form.score_bands || [];
            const newScoreBands = ranges.filter((_, rangeIndex) => rangeIndex != index);

            this.form = { ...this.form, score_bands: newScoreBands };
          }
        },
        backdrop: false,
      });
    }

    editDefaultRangeOptions(): void {
      this.toggleScoreBandStatesOptionsModal(true);
    }

    initForm(): void {
      if (this.scoreBandProfile) {
        const defaultScoreBand = {
          range_start: 0,
          range_end: 0,
          label: this.$t('app.labels.incomplete') as string,
          code: 'no_range',
          color: '#808080',
        };
        const defaultScoreBandOptions = {
          award_when_score_zero: true,
          sub_form_states: ['draft'],
          main_form_states: [],
        };
        this.defaultScoreBandEnabled = !isEmpty(this.scoreBandProfile.no_band_score_band);
        this.form = {
          ...this.scoreBandProfile,
          no_band_score_band: this.scoreBandProfile.no_band_score_band || defaultScoreBand,
          options: {
            ...this.scoreBandProfile.options,
            default_states: this.scoreBandProfile.options?.default_states || defaultScoreBandOptions,
          },
          score_bands: this.scoreBandProfile.score_bands?.length ? this.scoreBandProfile.score_bands : this.defaultScoreBands,
        };
      }
    }

    beforeMount(): void {
      if (this.scoreBandId) {
        this.$api.getScoreBandProfile(Number(this.scoreBandId)).then(({ data }) => {
          this.scoreBandProfile = data;
          this.initForm();
        });
      } else {
        this.scoreBandProfile = {
          module_name_id: Number(this.moduleNameId),
          score_bands: [],
          options: {},
        };
        this.initForm();
      }
    }
  }
