
  import { useAccountStore } from '@app/stores/account';
  import ActivityFormModal from '@app/components/activities/activity-form-modal.vue';
  import ActivityShowModal from '@app/components/activities/activity-show-modal.vue';
  import type { SubmitCommentEvent } from '@app/services/api/comments-api';
  import bootbox from 'bootbox';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import ActivitiesListItem from './activities-list-item.vue';
  import type { ActivitiesListActivityOnly } from './utils';
  import { ACTIVITIES_LIST_ACTIVITY_ONLY } from './utils';
  import ActivityActionButton from './activity-action-button.vue';
  import type { Activity } from '@app/models/activity';
  import type { Comment } from '@app/models/comment';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';
  import { ACTIVITY_CONCEPT } from '@app/constants';

  @Component({ components: { ActivityFormModal, ActivityShowModal, ActivitiesListItem, ActivityActionButton } })
  export default class ActivitiesList extends Vue {
    @Prop(Object) readonly filters?: DonesafeFilterOptions<Activity>;
    @Prop({ type: Number, default: () => 25 }) readonly pageSize!: number;
    @Prop(String) readonly sort?: string;
    @Prop(Boolean) readonly reverse?: boolean;
    @Ref() readonly activityShowModal!: ActivityShowModal;

    showMode = 'view';

    showActivity: Nullable<Pick<Activity, ActivitiesListActivityOnly>> = null;
    formActivity: Nullable<Partial<Pick<Activity, ActivitiesListActivityOnly>>> = null;

    formModalVisible = false;
    showModalVisible = false;
    submitting = false;

    activities: Pick<Activity, ActivitiesListActivityOnly>[] = [];
    loadingHash: Record<number, boolean> = {};

    get accountStore() {
      return useAccountStore();
    }

    get sortOrder(): string {
      return this.sort ? `${this.reverse ? '-' : ''}${this.sort}` : `${this.reverse ? '-' : ''}date`;
    }

    @Watch('sortOrder')
    onReverseChanged(): void {
      this.fetchActivities();
    }

    @Watch('filters', { deep: true })
    fetchActivities(): void {
      this.$emit('loading', true);
      this.$api
        .getActivities(
          {
            only: ACTIVITIES_LIST_ACTIVITY_ONLY,
            filters: { ...this.filters },
            per_page: this.pageSize,
            sort: this.sortOrder,
          },
          { cache: true }
        )
        .then(({ data }) => (this.activities = data))
        .finally(() => this.$emit('loading', false));
    }

    editActivity(activity: Pick<Activity, ActivitiesListActivityOnly>): void {
      this.showActivity = activity;
      this.showModalVisible = true;
      this.activityShowModal.setMode('edit');
    }

    viewActivity(activity: Pick<Activity, ActivitiesListActivityOnly>): void {
      this.showActivity = activity;
      this.showModalVisible = true;
    }

    closeShowModal(): void {
      this.showActivity = null;
      this.showModalVisible = false;
      this.activityShowModal.setMode('view');
    }

    deleteActivity(activity: Pick<Activity, ActivitiesListActivityOnly>): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.shared.tab_actions.delete_this_action'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteActivity(activity.id)
              .then(() => {
                toaster(this.$t('tenant.activities.destroy.activity_successfully_deleted'));
                this.showModalVisible = false;
                this.showActivity = null;
                this.$api.cache.clear();
                this.fetchActivities();
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    addNewActivity(data: Partial<Activity> = {}): void {
      this.formActivity = { ...data };
      this.formModalVisible = true;
    }

    onFormSubmit(form: Partial<Pick<Activity, ActivitiesListActivityOnly>>): void {
      this.setSubmitting(true);
      const promise = form.id
        ? this.$api.updateActivity(form.id, form).then(() => {
            toaster(this.$t('tenant.activities.update.activity_successfully_updated'));
            this.activityShowModal.setMode('view', true);
          })
        : this.$api.createActivity(form).then(() => {
            toaster(this.$t('tenant.activities.create.activity_successfully_added'));
            this.closeShowModal();
          });

      promise
        .then(() => {
          this.formModalVisible = false;
          this.formActivity = {};
          this.$api.cache.clear();
          this.fetchActivities();
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
        .finally(() => this.setSubmitting(false));
    }

    setSubmitting(value: boolean) {
      this.submitting = value;
    }

    updateActivityItem(activity: Pick<Activity, ActivitiesListActivityOnly>): void {
      this.activities = this.activities.map((a) => (a.id === activity.id ? activity : a));
    }

    toggleLoading(activity: Pick<Activity, ActivitiesListActivityOnly>, loading: boolean): void {
      this.loadingHash = { ...this.loadingHash, [activity.id]: loading };
    }

    closeActivity(
      activity: Pick<Activity, ActivitiesListActivityOnly>,
      data?: Partial<Pick<Comment, 'comment'> & { attachments?: string[] }>
    ): void {
      this.toggleLoading(activity, true);
      const closeData = {
        ...data,
        attachments: data?.attachments || [],
        only: ACTIVITIES_LIST_ACTIVITY_ONLY,
      };
      this.$api
        .closeActivity(activity.id, closeData)
        .then(({ data }) => {
          this.updateActivityItem(data);
          this.$api.cache.clear();
          this.closeShowModal();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => this.toggleLoading(activity, false));
    }

    openActivity(activity: Pick<Activity, ActivitiesListActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      this.toggleLoading(activity, true);
      this.$api
        .openActivity(activity.id, { ...commentParams, only: ACTIVITIES_LIST_ACTIVITY_ONLY })
        .then(({ data }) => {
          this.updateActivityItem(data);
          this.$api.cache.clear();
          this.closeShowModal();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => this.toggleLoading(activity, false));
    }

    reopenActivity(activity: Pick<Activity, ActivitiesListActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.openActivity(activity, commentParams);
    }

    approveActivity(activity: Pick<Activity, ActivitiesListActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .approveActivity(activity.id, {
          ...commentParams,
          comment: commentParams?.comment,
          approved: true,
          only: ACTIVITIES_LIST_ACTIVITY_ONLY,
        })
        .then(({ data }) => {
          this.updateActivityItem(data);
          this.$api.cache.clear();
          this.closeShowModal();
        });
    }

    unapproveActivity(activity: Pick<Activity, ActivitiesListActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .unapproveActivity(activity.id, {
          ...commentParams,
          comment: commentParams?.comment,
          approved: true,
          only: ACTIVITIES_LIST_ACTIVITY_ONLY,
        })
        .then(({ data }) => {
          this.updateActivityItem(data);
          this.$api.cache.clear();
          this.closeShowModal();
        });
    }

    createComment(activity: Activity, commentParams: SubmitCommentEvent['data']): void {
      if (!commentParams.comment) return;

      this.$api
        .createComment({
          comment: commentParams.comment,
          attachments: commentParams.attachments,
          commentable_id: activity.id,
          commentable_type: ACTIVITY_CONCEPT,
        })
        .then(() => {
          this.$api.cache.clear();
          this.closeShowModal();
        });
    }

    beforeMount(): void {
      this.fetchActivities();
    }
  }
