
  import bootbox from 'bootbox';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import WithImportModal from '@app/mixins/with-import-modal';
  import WithAuditTrailModal from '@app/mixins/with-audit-trail-modal';
  import type { SubForm } from '@app/models/sub-form';
  import { toaster } from '@app/utils/toaster';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { downloadFile } from '@app/utils/download-file';
  import qs from 'qs';
  import { changeLocation } from '@app/utils/change-location';

  import PaperTrailsModal from '../../paper-trails/paper-trails-modal.vue';

  import AdminSubFormImportModal from './admin-sub-form-import-modal.vue';
  import AdminSubFormActions from './admin-sub-form-actions.vue';

  @Component({ components: { AdminSubFormImportModal, AdminSubFormActions, PaperTrailsModal, DsIconText } })
  export default class AdminSubFormControlPanel extends mixins(WithAuditTrailModal, WithImportModal) {
    @Prop(Object) subForm!: SubForm;
    @Prop(Object) routeQuery!: object;
    @Prop(Number) readonly moduleTabId?: number;
    @Prop(Boolean) readonly hybridModule?: boolean;
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;

    loading = false;

    get goBackLabel(): string {
      if (this.$route.query.sub_forms_list) {
        return this.$t('app.labels.go_back_to_sub_forms');
      }
      return this.$route.query.just_created ? this.$t('app.labels.publish_to_tab') : this.$t('app.labels.go_back_to_module_tab');
    }

    get routeName(): string {
      if (this.$route.query.sub_forms_list) {
        return 'admin-sub-forms-list';
      }
      return this.$route.query.just_created ? 'admin-settings-module-tabs-tab-section-new' : 'admin-module-tabs-view-page';
    }

    onModalVisibilityUpdated(open: true): void {
      if (open) return;
      interface QueryParams {
        just_created?: boolean;
        module_tab_id: number | undefined;
      }
      const baseUrl = `/admin/settings/module_names/${this.moduleNameId}/sub_forms/${this.subForm.id}`;
      const queryParams: QueryParams = {
        module_tab_id: this.moduleTabId,
      };
      if (this.importCreated && this.$route.query.just_created) {
        queryParams.just_created = true;
      }
      const queryString = qs.stringify(queryParams, { addQueryPrefix: true });
      const combinedUrl = `${baseUrl}${queryString}`;
      changeLocation(combinedUrl);
    }

    openExport(): void {
      this.$api
        .exportSubForm(this.subForm.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileName = response.headers['content-disposition'].split('=')[1];
          downloadFile(url, fileName);
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    toggleActive(): void {
      if (this.subForm?.active) {
        bootbox.confirm({
          size: 'small',
          message: this.$t('tenant.admin.sub_forms.archive.confirm'),
          buttons: {
            confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
            cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.sendToggleRequest();
            }
          },
        });
      } else {
        this.sendToggleRequest();
      }
    }

    sendToggleRequest(): void {
      if (this.subForm?.id) {
        this.loading = true;
        this.$api
          .updateSubForm(this.subForm.id, { active: !this.subForm.active })
          .then(({ data }) => {
            this.$$patch(this.subForm, data);
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }))
          .finally(() => (this.loading = false));
      }
    }
  }
