
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import SingleSelectFieldOptions from '@app/components/admin/questions/settings/single-select-field-options.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  @Component({
    components: { SingleSelectFieldOptions },
  })
  export default class ButtonSelectFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseSelectQuestionOptions>;

    @Prop(Object) readonly features!: ModuleFeatures;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['allow_clear_response'].forEach((key) => this.$delete(this.value.config, key));
      this.$delete(this.value.options, 'values');
    }
  }
