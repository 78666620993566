
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import FormComponentForm from './form-component-form.vue';
  import bootbox from 'bootbox';
  import type { FormComponent } from '@app/models/form-component';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { FormComponentForm, DsModal } })
  export default class FormComponentModal extends mixins(Blocking) {
    @Prop(Object) readonly formComponent?: Pick<
      FormComponent,
      'id' | 'component_type' | 'component_id' | 'can_skip' | 'complete_multiple' | 'rule_set' | 'user_collection_id'
    >;
    @Prop(String) readonly conceptName!: string;
    @Ref() readonly form?: FormComponentForm;

    visible = true;
    saved = false;

    get buttonText(): string {
      return this.recordExists ? this.$t('app.buttons.update') : this.$t('app.buttons.save');
    }

    get formTitle(): string {
      return this.recordExists ? this.$t('tenant.admin.form_components.edit') : this.$t('tenant.admin.form_components.add');
    }

    get recordExists(): boolean {
      return !!this.formComponent?.id;
    }

    deleteFormComponent(): void {
      bootbox.confirm({
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.formComponent &&
            this.$api
              .deleteFormComponent(Number(this.formComponent.id))
              .then(() => {
                toaster(this.$t('tenant.admin.form_components.deleted'));
                window.location.reload();
              })
              .catch(({ data }) => {
                toaster({ text: data.error, position: 'top-right', icon: 'error' });
              });
        },
      });
    }

    closeModal(): void {
      this.visible = false;
      this.$emit('close');
    }

    submit(form: Partial<FormComponent>): void {
      const promise = form.id ? this.$api.updateFormComponent(Number(form.id), form) : this.$api.createFormComponent(form);
      const message = form.id ? this.$t('tenant.admin.form_components.updated') : this.$t('tenant.admin.form_components.created');

      this.blocking(async () => {
        return promise
          .then(() => {
            toaster(message);
            window.location.reload();
            this.saved = true;
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      });
    }
  }
