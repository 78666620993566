
  import { Component } from 'vue-property-decorator';
  import PaperTrailsAttributes from './paper-trails-attributes.vue';
  import TextHighlight from 'vue-text-highlight';
  import PaperTrailsDetails from './paper-trails-details.vue';
  import PaperTrailsItem from './paper-trails-item.vue';
  import PaperTrailsUser from './paper-trails-user.vue';

  @Component({ components: { PaperTrailsAttributes, TextHighlight, PaperTrailsDetails, PaperTrailsUser } })
  export default class PaperTrailsNoTableItem extends PaperTrailsItem {}
