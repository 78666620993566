
  import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
  import DsIcon from '@app/components/ds-icon.vue';
  import DsLabel from '@app/components/ds-label.vue';
  import DsCollapseLink from '@app/components/ds-collapse-link.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { mixins } from 'vue-class-component';
  import type { Event } from '@app/models/event';
  import { isEmpty, sum } from 'lodash';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { Collapse, Tooltip } from 'uiv';
  import bootbox from 'bootbox';

  import type EventGroupsSorting from './event-groups-sorting.vue';
  import EventOptionsMixin from './event-options-mixin';
  import ForEveryPreloadData from './for-every-preload-data';
  import type { GroupOrEvent, GroupWithTempId } from './with-automation-submit';

  @Component({
    components: {
      DsIcon,
      DsLabel,
      DsIconText,
      DsCollapseLink,
      DsDropdown,
      Collapse,
      Tooltip,
      EventGroupsSorting: () => import(/* webpackChunkName: "events-gorup-pane" */ './event-groups-sorting.vue'),
      EventFormDisplay: () => import(/* webpackChunkName: "events-gorup-pane" */ './event-form-display.vue'),
    },
  })
  export default class EventGroupPane extends mixins(EventOptionsMixin, ForEveryPreloadData) {
    @Ref() nameInputElement!: HTMLInputElement;
    @Ref() eventGroupsSortingRef!: InstanceType<typeof EventGroupsSorting>;

    @Prop() item!: GroupOrEvent;
    @Prop() isChild!: boolean;
    @Prop({ type: Object, default: () => ({}) }) invalidForms!: Record<string, boolean>;

    @PropSync('selected') selectedPane!: string | null;

    editingEventName = this.isItemAGroup(this.item) && isEmpty(this.item.items);
    collapseOpen: boolean = true;

    get itemSelected(): boolean {
      return this.selectedPane === this.itemSelectedKey;
    }

    get itemSelectedKey(): string {
      return `${this.item.id}:${this.isGroup ? 'group' : 'event'}`;
    }

    get isGroup(): boolean {
      return this.isItemAGroup(this.item);
    }

    get invalid(): boolean {
      return this.invalidForms[this.item.id] || false;
    }

    get hasInvalidEvents(): boolean {
      if (!this.isItemAGroup(this.item)) return false;
      const invalidForms = this.invalidForms;

      return this.item.items.some((e) => {
        if (!this.isItemAGroup(e)) {
          const id = e.id;
          return invalidForms[id];
        }
      });
    }

    get hasDeepEmptyGroups(): boolean {
      if (!this.isItemAGroup(this.item)) return false; // An event

      return this.groupsWithNoItems(this.item);
    }

    get hasDeepInvalidEvents(): boolean {
      if (!this.isItemAGroup(this.item)) return false;

      return this.groupsWithInvalidEvents(this.item.items);
    }

    get itemClasses() {
      return {
        selected: this.itemSelected,
        invalid: this.invalid || this.hasInvalidEvents || (!this.collapseOpen && (this.hasDeepInvalidEvents || this.hasDeepEmptyGroups)),
        'grey-bg': this.isGroup && this.isChild,
        'is-group': this.isGroup,
        'is-event': !this.isGroup,
        'is-child': this.isChild,
        pointer: !this.isGroup,
      };
    }

    get eventsCount(): number {
      if (!this.isItemAGroup(this.item)) return 0;

      return this.countEvents(this.item.items);
    }

    @Watch('selectedPane')
    onSelectedPaneChange(_value: string | number | null, previous: string | number | null) {
      if (!this.isItemAGroup(this.item)) return;
      if (previous !== this.itemSelectedKey) return;

      this.$nextTick(() => {
        this.eventGroupsSortingRef?.validateEmptiness();
      });
    }

    isItemAGroup(event: GroupOrEvent): event is GroupWithTempId {
      return 'items' in event;
    }

    countEvents(events: GroupOrEvent[]): number {
      return sum(events.map((e) => (this.isItemAGroup(e) ? this.countEvents(e.items) : 1)));
    }

    groupsWithNoItems(group: GroupOrEvent): boolean {
      if (!this.isItemAGroup(group) || !group.items.length) return true;

      return group.items.some((e) => (this.isItemAGroup(e) ? this.groupsWithNoItems(e) : false));
    }

    groupsWithInvalidEvents(group: GroupOrEvent[]): boolean {
      return group.some((e) => (this.isItemAGroup(e) ? this.groupsWithInvalidEvents(e.items) : this.invalidForms[e.id]));
    }

    removeItem(item: GroupOrEvent): void {
      bootbox.confirm({
        title: this.$t('app.labels.are_you_sure'),
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.automation_definitions.form.remove_event_confirm'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (!result) return;

          this.$emit('remove', item);
        },
      });
    }

    select() {
      this.selectedPane = this.itemSelectedKey;
    }

    groupTitle(item: GroupWithTempId): string {
      if (!this.isItemAGroup(item)) return '';

      return isEmpty(item.name) ? this.eventTitlesForGroup(item.items) : item.name;
    }

    eventTitlesForGroup(events: GroupOrEvent[]): string {
      const allTitles = events.reduce<string[]>((memo, e) => {
        if (this.isItemAGroup(e)) {
          return e.name ? [...memo, e.name] : [...memo, this.$t('tenant.admin.automation_definitions.form.event_group')];
        }
        return [...memo, this.eventTitle(e)];
      }, []);

      const counted = allTitles.reduce((memo, name) => {
        if (!name) return memo;
        memo[name] = memo[name] ? memo[name] + 1 : 1;
        return memo;
      }, {} as Record<string, number>);

      return Object.keys(counted)
        .map((key) => (counted[key] === 1 ? key : `${key} (${counted[key]})`))
        .join(', ');
    }

    onCollapseChange() {
      this.editingEventName = false;
    }

    showSecondaryEventTag(chained_events?: Partial<Event>[]): boolean {
      return !!chained_events && chained_events.some((v) => v.event_type);
    }

    selectEventInputName() {
      this.$nextTick(() => {
        this.nameInputElement?.select();
      });
    }

    onEventNameEdit(): void {
      this.editingEventName = !this.editingEventName;
      !this.editingEventName && this.$emit('input');

      this.editingEventName && this.selectEventInputName();
    }

    mounted() {
      this.editingEventName && this.selectEventInputName();
    }

    beforeMount() {
      this.getAutomationCreateEventOptions();
    }
  }
