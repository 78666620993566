
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import type { Dictionary } from 'lodash';
  import { keyBy, sortBy, uniq } from 'lodash';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import type { RoleOverride } from './utils';
  import type { Role } from '@app/models/role';
  import { ListManagerStatic } from '@app/services/list-manager/list-manager-static';

  @Component({ components: { SimpleGridTable } })
  export default class RoleOverrideForm extends Vue {
    @Prop(String) readonly permissionType!: string;
    @Prop(String) readonly title!: string;
    @Prop(Array) readonly currentOverrides?: RoleOverride[];
    @Prop(Array) readonly roleProfileIds?: number[];

    @Emit('submit')
    submit(): RoleOverride[] {
      return this.roleOverrides.filter((item) => item.bypass !== null);
    }

    roles: Role[] = [];
    roleOverrideOpen = false;
    manager: Nullable<ListManagerStatic<RoleOverride>> = null;
    roleOverrides: RoleOverride[] = [];

    getRoleName(roleId: number): string {
      return this.rolesById[roleId].name;
    }

    setRoleOverrideOpen(open: boolean): void {
      if (open) {
        this.roleOverrides =
          sortBy(this.roles || [], 'name').map((role) => {
            const current = this.overridesByRoleId[role.id];
            return { role_id: role.id, bypass: current ? current.bypass : null };
          }) || [];
        this.manager = new ListManagerStatic<RoleOverride>({
          localData: this.roleOverrides,
          per_page: -1,
          fields: [
            {
              title: this.$t('app.labels.roles'),
              name: 'role',
              width: '25%',
              titleClass: 'text-right justify-content-end wrap-column-title',
              dataClass: 'd-flex align-items-center justify-content-end text-right',
            },
            {
              title: this.$t('tenant.module_tabs.show.restriction_labels.set_by_role_profile'),
              name: 'by_role_profile',
              width: '25%',
              titleClass: 'text-center justify-content-center wrap-column-title',
              dataClass: 'd-flex align-items-center justify-content-center text-center',
            },
            {
              title: this.$t('tenant.module_tabs.show.restriction_labels.bypass'),
              name: 'bypass',
              width: '25%',
              titleClass: 'text-center justify-content-center wrap-column-title',
              dataClass: 'd-flex align-items-center justify-content-center text-center',
            },
            {
              title: this.$t('tenant.module_tabs.show.restriction_labels.no_bypass'),
              name: 'no_bypass',
              width: '25%',
              titleClass: 'text-center justify-content-center wrap-column-title',
              dataClass: 'd-flex align-items-center justify-content-center text-center',
            },
          ],
        });
      }
      this.roleOverrideOpen = open;
      this.$emit('input', open);
    }

    get rolesById(): Dictionary<Role> {
      return keyBy(this.roles, 'id');
    }

    get activeRoleOverrides(): RoleOverride[] {
      const rolesChecked: { [role_id: number]: boolean } = {};
      const enabledRoles: RoleOverride[] = [];
      const disabledRoles: RoleOverride[] = [];
      const overrideList: RoleOverride[] = (this.roleOverrideOpen ? this.roleOverrides : this.currentOverrides) || [];
      (this.profileEnabledRoleIds || []).forEach((roleId) => {
        if (rolesChecked[roleId]) return;
        rolesChecked[roleId] = true;
        const override = overrideList.find((ro) => ro.role_id === roleId);
        (override?.bypass !== false ? enabledRoles : disabledRoles).push({
          role_id: roleId,
          bypass: override ? override.bypass : null,
        });
      });
      overrideList.forEach((override) => {
        if (rolesChecked[override.role_id]) return;
        if (override.bypass === null) return;
        (override.bypass ? enabledRoles : disabledRoles).push({ role_id: override.role_id, bypass: override.bypass });
      });

      return [...enabledRoles.sort(this.compareWith), ...disabledRoles.sort(this.compareWith)];
    }

    compareWith = (a: RoleOverride, b: RoleOverride): number => {
      return (this.rolesById[a.role_id]?.name || '').localeCompare(this.rolesById[b.role_id]?.name || '');
    };

    roleOverrideLabel(override: RoleOverride): string {
      const role = this.rolesById[override.role_id];
      if (override.bypass === null) {
        return role?.name || this.$t('app.labels.na').toString();
      } else {
        return `${role?.name || this.$t('app.labels.na')} (${this.$t('tenant.module_tabs.show.restriction_labels.override')})`;
      }
    }

    roleOverrideClass(override: RoleOverride): string {
      if (override.bypass === false) {
        return 'label-default line-through';
      } else {
        return 'label-success';
      }
    }

    get overridesByRoleId() {
      return keyBy(this.currentOverrides, 'role_id');
    }

    get rolesIdsPerProfile(): Record<number, number[]> {
      return (this.roles || []).reduce((dict, role) => {
        role.role_profile_ids?.forEach((roleProfileId) => {
          if (!dict[roleProfileId]) dict[roleProfileId] = [];
          dict[roleProfileId].push(role.id);
        });
        return dict;
      }, {} as Record<number, number[]>);
    }

    get profileEnabledRoleIds(): number[] {
      return uniq(this.roleProfileIds?.flatMap((id) => this.rolesIdsPerProfile[id] || []) || []);
    }

    beforeMount(): void {
      this.$api
        .getRoles({ per_page: -1, filters: { active: true }, only: ['id', 'name', 'role_profile_ids'] }, { cache: true })
        .then(({ data }) => (this.roles = data));
    }
  }
