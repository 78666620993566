
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import ColorInput from '@app/components/color-input.vue';
  import type { Indicator } from '@app/models/indicator';

  @Component({
    components: { ValidationObserver, ValidationProvider, ColorInput },
  })
  export default class IndicatorForm extends Vue {
    @Prop(Object) readonly indicator?: Partial<Indicator>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Indicator> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', { ...this.form, color: this.form.color || null });
        }
      });
    }

    beforeMount(): void {
      this.form = { ...this.indicator };
    }
  }
