
  import ActivityForm from '@app/components/activities/activity-form.vue';
  import ActivityShow from '@app/components/activities/activity-show.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ActivityShowModalActivityOnly } from './utils';
  import { ACTIVITY_SHOW_MODAL_ACTIVITY_ONLY } from './utils';
  import type { Activity } from '@app/models/activity';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActivityForm, ActivityShow, DsModal } })
  export default class ActivityShowModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly activity!: Pick<Activity, 'id'>;
    @Prop(Boolean) readonly disabled?: boolean;

    mode: 'view' | 'edit' = 'view';

    activityDetails: Nullable<Pick<Activity, ActivityShowModalActivityOnly>> = null;

    get title(): string {
      return this.mode === 'view' ? this.$t('app.labels.action') : this.$t('app.labels.edit_action');
    }

    setMode(mode: 'view' | 'edit', fetchDetails = false): void {
      this.mode = mode;
      fetchDetails && this.fetchDetails(true);
    }

    removeDetails(): void {
      this.activityDetails = null;
    }

    fetchDetails(clearCache = false): void {
      clearCache && this.$api.cache.clear();

      this.$api
        .getActivity(this.activity.id, { only: ACTIVITY_SHOW_MODAL_ACTIVITY_ONLY, include: ['legacy'] }, { cache: true })
        .then(({ data }) => {
          this.activityDetails = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }
  }
