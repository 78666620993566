import { render, staticRenderFns } from "./builder-menu.vue?vue&type=template&id=297bb504&scoped=true&lang=pug"
import script from "./builder-menu.vue?vue&type=script&lang=ts"
export * from "./builder-menu.vue?vue&type=script&lang=ts"
import style0 from "./builder-menu.vue?vue&type=style&index=0&id=297bb504&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297bb504",
  null
  
)

export default component.exports