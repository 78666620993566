
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import SvgTemplateConfigurator from '../svg-selector/svg-template-configurator.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { SvgSelectorQuestionOptions } from '@app/models/question-options/svg-selector-question-config';

  const DEFAULTS: Partial<SvgSelectorQuestionOptions> = {
    selectable_components: [],
    allow_multiple: 'true',
    default_categories: {},
    categories: [{ color: '#447cbf', title: '', code: '' }],
  };

  @Component({
    components: { Select2, EntitySelector, SvgTemplateConfigurator, FormField },
  })
  export default class SvgSelectorFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<SvgSelectorQuestionOptions>;
    @Prop(Object) readonly subForm!: SubForm;

    templates: SvgLibraryTemplate[] = [];
    loading = false;

    get selectedTemplate(): SvgLibraryTemplate | undefined {
      const templateId = this.value.config.svg_template_id;

      if (templateId) {
        return this.templates?.find((t) => `${t.id}` == templateId);
      }
    }

    async beforeMount(): Promise<void> {
      this.loading = true;

      try {
        const { data: templates } = await this.$api.getSvgTemplates({ include: ['components', 'attachment'] }, { cache: true });

        this.templates = templates;
      } finally {
        this.loading = false;
        this.initForm();
      }
    }

    setDefaultSelectableComponents(): void {
      if (this.value.id) {
        return;
      }

      if (this.selectedTemplate !== undefined) {
        this.value.config.selectable_components = this.selectedTemplate.components?.map((c) => c.path) ?? [];
        this.value.config.default_categories = this.value.config.selectable_components.reduce(
          (acc, path) => ({ ...acc, [path]: this.value.config.categories?.[0].code }),
          {}
        );
      }
    }

    initForm(): void {
      this.$$patch(this.value.config, DEFAULTS, { ifEmpty: true });
      this.$$patch(this.value.config.default, { values: [] }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['svg_template_id', 'selectable_components', 'default_categories', 'allow_multiple', 'show_summary', 'categories'].forEach((key) =>
        this.$delete(this.value.config, key)
      );
    }
  }
