
  import AttachmentsUploader from '@app/components/attachment/attachments-uploader.vue';
  import type { Blob } from '@rails/activestorage';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Attachment } from '@app/models/attachment';
  import type { Comment } from '@app/models/comment';

  @Component({ components: { AttachmentsUploader, ValidationObserver, ValidationProvider } })
  export default class CommentForm extends Vue {
    @Prop(Object) readonly comment?: Partial<Comment>;
    @Ref() readonly attachmentUploader?: AttachmentsUploader;
    @Ref() readonly textarea?: HTMLInputElement;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Comment> & { attachments?: { file: string }[] } = {};
    submitting = false;

    beforeMount(): void {
      this.reset();
    }

    focusTextarea(): void {
      this.textarea?.focus();
    }

    reset(): void {
      this.form = { ...this.comment, attachments: [] };
      this.attachmentUploader?.reset();
      this.validator?.reset();
    }

    submit(): void {
      this.submitting = true;
      this.validator
        ?.validate()
        .then((result: boolean) => {
          if (result) {
            this.$emit('submit', {
              ...this.form,
              attachments: this.form.attachments?.map((a: { file: string }) => a.file),
            });
          } else {
            this.focusTextarea();
          }
        })
        .finally(() => (this.submitting = false));
    }

    createBlob(blob: Blob): void {
      const attachments = this.form.attachments || [];
      attachments.push({ file: blob.signed_id, file_name: blob.filename } as Attachment & {
        file: string;
      });
      this.form = { ...this.form, attachments };
    }
  }
