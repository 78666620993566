
  import bootbox from 'bootbox';
  import { orderBy } from 'lodash';
  import { Tabs, Tab } from 'uiv';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import MobileAppLayoutEntityForm from '../../../components/admin/mobile-apps/mobile-app-layout-entity-form.vue';
  import MobileAppLayoutForm from '../../../components/admin/mobile-apps/mobile-app-layout-form.vue';
  import type { MobileAppLayout } from '@app/models/mobile-app/layout';
  import type { MobileAppLayoutEntity } from '@app/models/mobile-app/entity';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppLayoutEntityForm, MobileAppLayoutForm, DsModal, Tabs, Tab } })
  export default class EditLayoutPage extends Vue {
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Prop([String, Number]) readonly mobileAppLayoutId!: string | number;
    @Ref() readonly form?: MobileAppLayoutForm;
    @Ref() readonly entityForm?: MobileAppLayoutEntityForm;

    layout: Nullable<MobileAppLayout> = null;
    entities: MobileAppLayoutEntity[] = [];
    visible = true;
    entityModalVisible = false;

    currentEntity: Partial<MobileAppLayoutEntity> = {};

    beforeMount(): void {
      this.fetchLayout();
    }

    get sortedEntities(): MobileAppLayoutEntity[] {
      return orderBy(this.entities, 'id');
    }

    fetchLayout(): void {
      this.$api.getMobileAppLayout(this.mobileAppLayoutId, { include: ['entities'] }, { cache: true }).then(({ data }) => {
        this.layout = data;
        this.entities = data.entities || [];
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }

    onFormSubmit(layout: MobileAppLayout): void {
      this.$api
        .updateMobileAppLayout(layout.id, layout)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    addNewEntity(): void {
      this.currentEntity = {
        mobile_app_layout_id: this.layout?.id,
      };
      this.toggleEntityModal(true);
    }

    toggleEntityModal(value: boolean): void {
      this.entityModalVisible = value;
    }

    onEntitySubmit(data: Partial<MobileAppLayoutEntity>): void {
      // console.log(data);
      const promise = data.id ? this.$api.updateMobileAppLayoutEntity(data.id, data) : this.$api.createMobileAppLayoutEntity(data);
      promise.then(() => {
        this.$api.cache.clear();
        this.fetchLayout();
        this.toggleEntityModal(false);
        this.currentEntity = {};
      });
    }

    onEntityEdit(entity: MobileAppLayoutEntity): void {
      this.currentEntity = entity;
      this.toggleEntityModal(true);
    }

    onEntityRemove(entity: MobileAppLayoutEntity): void {
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: 'Are you sure?',
        callback: (result: boolean) => {
          result &&
            this.$api.deleteMobileAppLayoutEntity(entity.id).then(({ data }) => {
              this.entities = this.entities.filter((e) => e.id !== data.id);
            });
        },
      });
    }
  }
