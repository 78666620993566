
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { Tooltip } from 'uiv';
  import snakeCaseRule from '@app/validators/snake-case-rule';
  import ColorInput from '@app/components/color-input.vue';
  import type { ActionPriority } from '@app/models/action-priority';

  @Component({ components: { Tooltip, ValidationProvider, ValidationObserver, ColorInput } })
  export default class ActionPriorityForm extends Vue {
    @Prop(Object) value!: Partial<ActionPriority>;
    @Prop(Boolean) blocked = false;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<ActionPriority> = {};

    beforeMount() {
      extend('snake-case', snakeCaseRule);

      this.form = { ...this.value };
    }

    get existing(): boolean {
      return !!this.form?.id;
    }

    async save() {
      const valid = await this.validator?.validate();

      if (!valid) return null;

      this.$emit('submit', this.form);
    }

    get disableDefault(): boolean {
      return this.existing && !this.form.active;
    }
  }
