
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import LocationSelector from '../../../components/location/location-selector.vue';
  import FilterSelect from '../../../components/filter-select.vue';
  import Select2 from '../../../components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Location } from '@app/models/location';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { useAccountStore } from '@app/stores/account';
  import FallbackBadge from '@app/components/fallback-badge.vue';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';

  @Component({
    components: {
      FilterCheckbox,
      PaperTrailsModalLink,
      LocationSelector,
      BaseTable,
      FilterSelect,
      Select2,
      SearchInput,
      DsDropdown,
      FallbackBadge,
    },
  })
  export default class AdminSettingsLocationsIndex extends Vue {
    @Ref() readonly table?: BaseTable<Location>;

    manager = new ListManager<Location, DonesafeLocationsApiExtraOptions>({
      fetchDataFunction: (params) => {
        return this.$api.getLocations(
          {
            ...params,
            only: ['active', 'id', 'name', 'state', { location: ['name'] }],
          },
          { cache: true } // for repetitive requests like: sorting or filtering
        );
      },
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        {
          title: this.$t('tenant.settings.locations.sections.tab_details.country_state'),
          name: 'state',
          sortField: 'state',
        },
        {
          title: this.$t('tenant.settings.locations.sections.tab_details.parent_location'),
          name: 'location',
          sortField: 'location.name',
          filter: true,
        },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
        { title: '', name: 'operations', width: 'max-content' },
      ],
      allowFilters: true,
    });

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    get accountStore() {
      return useAccountStore();
    }
  }
