
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import Select2 from '@app/components/select2.vue';
  import Draggable from 'vuedraggable';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { ColumnOrder, OrderOptions } from '@app/models/sub-form-list';
  import { columnOrderKey } from '@app/components/admin/questions/order-options/with-order-options';
  import { extend } from 'vee-validate';
  import { isNil } from 'lodash';

  interface OrderOptionsForm {
    columns: Partial<ColumnOrder>[];
  }

  interface GroupedOption {
    group?: string;
    isText?: boolean;
    label: string;
    value: string;
  }

  @Component({
    components: {
      Select2,
      DsCheckbox,
      Draggable,
      FormField,
    },
  })
  export default class OrderOptionsSelector extends Vue {
    @Model('input') readonly value!: OrderOptions;

    @Prop(Array) readonly columns!: GroupedOption[];
    @Prop(String) readonly name?: string;

    form: OrderOptionsForm = { columns: [] };

    columnOrderKey = columnOrderKey;

    updateColumn(i: number, update: string) {
      const patch = update.split('|').reduce(
        (acc, next) => {
          let [k, v] = next.split(':');

          if (!v) {
            [k, v] = ['field', k];
          }

          k = k.toLowerCase();

          return {
            ...acc,
            [k]: v,
          };
        },
        { source: undefined, field: undefined, question: undefined, name: undefined } as Partial<ColumnOrder>
      );

      const option = this.columns.find(({ value }) => value === update);

      if (!option?.isText) {
        patch.method = 'DEFAULT';
      }

      this.$$patch(this.form.columns[i], patch);
      this.pushUpdate();
    }

    beforeMount() {
      extend('requires_any_key', (value, keys) => {
        return !!(keys as string[]).find((k) => !isNil(value[k])) || 'This value is required';
      });

      if (!!this.value) {
        this.form = { ...this.value };
      } else {
        this.form = { columns: [{ method: 'DEFAULT', field: 'id', direction: 'ASC' }] };
        this.pushUpdate();
      }
    }

    add() {
      this.form = {
        columns: [...(this.form.columns || []), { method: 'DEFAULT' }],
      };

      this.pushUpdate();
    }

    remove(index: number) {
      this.form = {
        columns: (this.form.columns || []).filter((_, i) => i !== index),
      };

      this.pushUpdate();
    }

    setReverseDirection(column: Partial<ColumnOrder>, reverse: boolean) {
      this.$$patch(column, { direction: reverse ? 'DESC' : 'ASC' });
      this.pushUpdate();
    }

    isReverseDirection(column: Partial<ColumnOrder>) {
      return column.direction === 'DESC';
    }

    methodOptionsFor(column: Partial<ColumnOrder>): [ColumnOrder['method'], string][] {
      const option = this.columns.find(({ value }) => value === this.columnOrderKey(column));

      return [
        ['DEFAULT', this.$t('tenant.admin.sub_form_lists.edit_view_options.order_default')],
        ...((option?.isText ? [['NATURAL', this.$t('tenant.admin.sub_form_lists.edit_view_options.order_natural')]] : []) as [
          ColumnOrder['method'],
          string
        ][]),
      ];
    }

    availableColumns(selectedValue: string): GroupedOption[] {
      const selected = this.form.columns.map((c) => this.columnOrderKey(c as ColumnOrder));
      return this.columns.filter(({ value }) => value === selectedValue || !selected.includes(value));
    }

    pushUpdate(): void {
      this.$emit('input', this.form);
    }

    templateSelection(result: { id: string; text: string }): JQuery {
      const option = this.columns.find((option) => result.id === option.value);
      const text = option ? [option.group, option.label].filter((v) => !!v).join(': ') : result.text;

      return $(
        `<div style="display:inline-block;float:left;padding-right:3px">
            <p style="margin-bottom:0;">${text}</p>
         </div>`
      );
    }
  }
