
  import DatePicker from '@app/components/date-picker.vue';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import { activeRecordBudgetBehaviours } from '@app/components/expensable/expensable-helpers';
  import DateField from '@app/components/form-fields/date-field.vue';
  import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';
  import type { ExpensableRecordBudgetBehaviour } from '@app/models/expensable-record-budget-behaviour';
  import type { ExpensableRecordBudgetCategory } from '@app/models/expensable-record-budget-category';

  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  @Component({ components: { DateTimePicker, DatePicker, DateField, DsTextInput, DsModal, ValidationObserver, ValidationProvider } })
  export default class RecordBudgetCategoryFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly recordBudgetCategory?: ExpensableRecordBudgetCategory;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    beforeMount(): void {
      // this.initForm();
    }

    form: Record<string, string | undefined> = {};

    budget: Nullable<ExpensableRecordBudget> = null;

    isRequired(rbb: ExpensableRecordBudgetBehaviour): boolean {
      return false; // TODO: fix later
    }

    label(rbb: ExpensableRecordBudgetBehaviour) {
      return rbb.behaviour?.name;
    }

    fieldId(rbb: ExpensableRecordBudgetBehaviour) {
      return `behaviour-${rbb.id}`;
    }

    initForm(): void {
      // TODO: show spinner
      this.form = {};
      this.recordBudgetCategory &&
        this.$api
          .getExpensableRecordBudget(
            this.recordBudgetCategory.expensable_record_budget_id,
            { include: ['record_budget_behaviours', 'behaviour', 'expensing_table', 'expensing_table_behaviours'] },
            { cache: true }
          )
          .then(({ data }) => {
            this.budget = data;
            this.form =
              this.recordBudgetCategory?.record_budget_category_values?.reduce((acc, value) => {
                return { ...acc, [value.expensable_record_budget_behaviour_id]: value.expected_value };
              }, {}) || {};
          });
    }

    resetData(): void {
      this.budget = null;
      this.form = {};
    }

    get title() {
      return this.recordBudgetCategory?.category?.name || '';
    }

    toggleActive(active: boolean) {
      this.$emit('submit', { ...this.recordBudgetCategory, active });
    }

    submitForm(): void {
      this.validator?.validate().then((result) => {
        const valuesData = Object.keys(this.form).map((behaviourId) => ({
          expensable_record_budget_behaviour_id: behaviourId,
          expected_value: this.form[behaviourId],
        }));
        const data = {
          ...this.recordBudgetCategory,
          record_budget_category_values: valuesData,
        };
        result && this.$emit('submit', data);
      });
    }

    get activeBehaviours(): ExpensableRecordBudgetBehaviour[] {
      return activeRecordBudgetBehaviours(this.budget);
    }
  }
