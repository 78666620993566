
  import ModuleSecondaryOptionsSelector from '@app/components/admin/modules/module-secondary-options-selector.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import ModuleNameIndexOptionsConfigurator from '../module-name-index-options-configurator.vue';
  import Select2 from '@app/components/select2.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import ModuleDisplayBarConfigTable from '@app/components/admin/modules/module-display-bar-config-table.vue';
  import ActionFilterIndexDropdown from '../actions/action-filter-index-dropdown.vue';
  import { Tooltip } from 'uiv';
  import type { ModuleName, RecordLinkBehaviour, ShowIdOption, ShowOptions } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      ModuleSecondaryOptionsSelector,
      ModuleDisplayBarConfigTable,
      ModuleNameIndexOptionsConfigurator,
      Select2,
      AdvancedAutosuggest,
      ActionFilterIndexDropdown,
      Tooltip,
    },
  })
  export default class ModuleShowOptionsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    fieldOrderType = 'Default';
    showDisplayConfigTable = false;
    form: Partial<ModuleName> = {};
    moduleName: Nullable<ModuleName> = null;
    mainFormQuestions: Nullable<SubFormQuestion[]> = null;
    injectableOptions: PlaceholderItem[] = [];
    currentShowIdOptions: ShowIdOption[] = [];

    get fieldCountLimitOptions(): [string, string][] {
      return [
        ['0', '0'],
        ['4', '4'],
        ['8', '8'],
        ['', this.$t('tenant.admin.show_options.labels.field_count_limit_default')],
      ];
    }

    get recordLinkBehaviourOptions(): [RecordLinkBehaviour, string][] {
      return getRecordLinkBehaviourOptions();
    }

    get mainFormQuestionsOptions(): string[][] {
      return this.mainFormQuestions?.map((question: SubFormQuestion) => [String(question.id), question.title]) || [];
    }

    toggleFieldOrderRadio(value: boolean): void {
      if (this.form.show_options) {
        if (value) {
          this.form.show_options.record_quick_display_config = this.currentShowIdOptions;
        } else {
          this.currentShowIdOptions = this.form?.show_options?.record_quick_display_config || [];
          this.form.show_options.record_quick_display_config = [];
        }
      }
      this.showDisplayConfigTable = value;
    }

    iconListTemplate(option: { id: string; text: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      return $(
        `<div>
            <i class="material-icons m-r-xs">${option.id}</i><span style="position: relative; bottom: 7px">${option.id}</span>
          </div>`
      );
    }

    signatureClick(value: boolean): void {
      if (value) {
        this.form.show_options = { ...this.form.show_options, quick_add_own_signature: true };
      }
    }

    updateFormShowOptions(showOptions: ShowOptions): void {
      this.form = { ...this.form, show_options: showOptions };
      this.currentShowIdOptions = this.form.show_options?.record_quick_display_config || [];
    }

    updateVisualOptions(): void {
      this.$api
        .updateModuleName(Number(this.moduleNameId), this.form, {
          only: ['id', 'show_options'],
        })
        .then(({ data }) => {
          toaster(this.$t('portal.modules.update.module_successfully_updated'));
          this.$api.cache.clear();
          this.updateFormShowOptions(data.show_options);
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = [
        'sub_form_id',
        'record_link_behaviour',
        'show_options',
        'custom_title',
        'name',
        'id',
        'index_options',
        'index_option_labels',
        'available_index_options',
        'plural_display',
        'display',
        'record_calculations',
        'feature_set',
        { main_form: [{ sub_form_sections: ['sub_form_questions'] }] },
        {
          indicator_sets: ['active', 'id', 'name', 'multi', 'index', { indicators: ['id', 'name'] }],
        },
        { involvements: ['active', 'id', 'name'] },
      ];
      this.$api.getModuleName(Number(this.moduleNameId), { only }, { cache: true }).then(({ data }) => {
        this.moduleName = data;
        this.mainFormQuestions =
          this.moduleName.main_form?.sub_form_sections?.map((s: SubFormSection) => s.sub_form_questions || []).flat() || [];

        this.form = {
          id: this.moduleName.id,
          record_link_behaviour: this.moduleName.record_link_behaviour,
          show_options: {
            follow_icon: 'star_border',
            active_follow_icon: 'star',
            ...this.moduleName.show_options,
            record_quick_display_config: this.moduleName.show_options?.record_quick_display_config || [],
            sub_form_show_ids: this.moduleName.show_options?.sub_form_show_ids || [],
          },
          custom_title: this.moduleName.custom_title,
          record_calculations: this.moduleName.record_calculations,
          display: this.moduleName.display,
          indicator_sets: this.moduleName.indicator_sets,
          involvements: this.moduleName.involvements,
        };

        if (!!this.form.show_options?.record_quick_display_config?.length) {
          this.showDisplayConfigTable = true;
          this.fieldOrderType = 'Custom';
        }

        this.$api
          .getInjectableOptions(
            {
              concept_name: 'ModuleRecord',
              injectable_arguments: {
                concept_name: this.moduleName.name,
                include_all: false,
                include_calculations: false,
              },
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.injectableOptions = data;
          });
      });
    }
  }
