
  import { Component, Ref } from 'vue-property-decorator';
  import { titleize } from '@app/utils/titleize';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { Tooltip, tooltip } from 'uiv';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { mixins } from 'vue-class-component';
  import { BaseTable } from '@app/components/base-table';
  import JoinEntities from '@app/components/join-entities.vue';
  import DashboardPaneModal from '@app/components/admin/dashboard-panes/dashboard-pane-modal.vue';
  import type DashboardPaneGroup from '@app/models/dashboard-pane-group';
  import WithDashboardPaneForm from '@app/components/admin/dashboard-panes/with-dashboard-pane-form';
  import DynamicDashboardSettingsForm from '@app/components/admin/dashboard-panes/dynamic-dashboard-settings-form.vue';
  import SearchInput from '@app/components/search-input.vue';
  import PageLayout from './page-layout.vue';
  import AccountActions from '../accounts/account-actions.vue';
  import { useAccountStore } from '@app/stores/account';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import DsCheckbox from '@app/components/ds-checkbox.vue';

  @Component({
    components: {
      DynamicDashboardSettingsForm,
      DashboardPaneModal,
      BaseTable,
      PageLayout,
      JoinEntities,
      Tooltip,
      DsDropdown,
      DsIconText,
      DsCheckbox,
      SearchInput,
      AccountActions,
    },
    directives: {
      tooltip,
    },
  })
  export default class DashboardPaneGroups extends mixins(WithDashboardPaneForm) {
    @Ref() readonly table?: BaseTable<DashboardPaneGroup>;

    manager: Nullable<ListManager<DashboardPaneGroup>> = null;
    refreshed = 0;
    hideEmpty = true;

    titleize = titleize;

    get joinedDashboardPanesParams() {
      return { only: ['name', 'id', 'role_ids', 'active'] };
    }

    get dragDisabled() {
      return !!this.manager?.customFilters?.search || !this.changesEnabled;
    }

    get changesEnabled() {
      return this.currentUserStore.featureEnabled([AdminPermissionFeature.reporting, AdminPermissionFeature.module_config]);
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get auditTrailAttributeScope(): string[] {
      return [
        'dynamic_dashboard_settings',
        ...['active', 'config', 'description', 'module_name_id', 'priority', 'size'].map((attr) => `DashboardPane.${attr}`),
        ...['locale', 'name', 'description'].map((attr) => `DashboardPane::Translation.${attr}`),
      ];
    }

    getManager(): ListManager<DashboardPaneGroup> {
      return new ListManager<DashboardPaneGroup>({
        fetchDataFunction: (params) => {
          const only = ['id', 'priority', 'module_name_id', 'dashboard_pane_ids'] as OnlyOptions<DashboardPaneGroup>;

          return this.$api.getDashboardPaneGroups(
            {
              ...params,
              only,
              filters: { ...(params.filters || {}), with_active_panes: this.hideEmpty },
            },
            { cache: true }
          );
        },
        afterFetch: () => (this.refreshed += 1),
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'priority', sortField: 'priority' }],
        per_page: -1,
        fields: [
          { title: '', name: 'sortable_handle' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.priority'), name: 'priority' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.type'), name: 'type' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.module'), name: 'module_name' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.display_name'), name: 'display_name' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.roles'), name: 'roles' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.panes_count'), name: 'panes_count' },
          { title: this.$t('tenant.admin.dashboard_pane_groups.active'), name: 'active' },
          { title: '', name: 'open' },
          { title: '', name: 'actions' },
        ],
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }

    async saveIndexes() {
      if (!this.manager) return;

      await this.$api.updateDashboardPaneGroupsIndexes({ data: this.manager.items.map(({ id }, i) => ({ id, index: i + 1 })) });
      this.refresh();
    }

    refresh() {
      this.$api.cache.clear();
      this.table?.debounceUpdate();
    }
  }
