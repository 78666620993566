
  import { Component, Vue, Prop, Model } from 'vue-property-decorator';
  import DsLabel from '@app/components/ds-label.vue';
  import type { WorkflowLink } from '@app/models/workflow-link';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsLabel, DsModal } })
  export default class WorfklowPanelStepsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Array) readonly steps!: WorkflowLink[];
    @Prop(Boolean) readonly reject!: boolean;
    @Prop(Boolean) readonly loading!: boolean;
  }
