
  import type { PartialNullBy } from '@app/utils/types/partial';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import AdminSettingsCostCentreForm from '../../../components/admin/organizations/admin-settings-cost-centre-form.vue';
  import bootbox from 'bootbox';
  import type { Organization } from '@app/models/organization';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { AdminSettingsCostCentreForm, DsModal } })
  export default class AdminSettingsCostCentreEdit extends Vue {
    @Ref() readonly form?: AdminSettingsCostCentreForm;
    @Prop([String, Number]) readonly organizationId!: string | number;

    organization: Nullable<Organization> = null;

    visible = true;

    beforeMount(): void {
      this.$api
        .getOrganization(
          Number(this.organizationId),
          {
            only: ['active', 'external_uuid', 'id', 'name', 'organization_id'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.organization = data;
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-cost-centres-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    toggleActive(organization: Organization, active: boolean): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => result && this.updateOrganization({ ...organization, active }),
      });
    }

    onFormSubmit(organization: Partial<PartialNullBy<Organization, 'organization_id'>>): void {
      this.updateOrganization(organization);
    }

    updateOrganization(organization: Partial<PartialNullBy<Organization, 'organization_id'>>): void {
      this.$api
        .updateOrganization(Number(this.organizationId), organization)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
