
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SettingsSection from '@app/components/admin/roles/role-tab-details/settings-section.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { RoleTabDetailsFormType } from '@app/components/admin/roles/role-tab-details/utils';
  import type { Dashboard } from '@app/models/dashboard';
  import { useAccountStore } from '@app/stores/account';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import { difference, intersection, union } from 'lodash';
  import I18n from '@app/i18n';

  @Component({ components: { SettingsSection, FormField, EntitySelector, DsCheckbox } })
  export default class DashboardSettings extends Vue {
    @Prop(Object) readonly form!: RoleTabDetailsFormType;
    @Prop(Object) dashboardPaneIdsByModuleName!: Record<number | 'null', number[]>;

    @Prop(Boolean) readonly disabled!: boolean;

    dashboardPaneIds: number[] = [];

    get dashboardPlaceholder(): string {
      if (this.accountStore.data.ds_version === 1) {
        return this.$t('controllers.tenant.admin.roles.dashboard_blank_option.default');
      } else if (this.form?.dashboard_id === null) {
        return this.$t('controllers.tenant.admin.roles.dashboard_blank_option.not_selected');
      } else {
        return '';
      }
    }

    get accountStore() {
      return useAccountStore();
    }

    get dashboardPaneStandaloneFilter() {
      return { module_name_id: 'NULL' };
    }

    get dashboardFilter() {
      return this.form.dynamic_dashboard_access ? { dynamic: [true, false] } : { dynamic: [false] };
    }

    beforeMount() {
      this.dashboardPaneIds = intersection(this.dashboardPaneIdsByModuleName['null'], this.form.dashboard_pane_ids);
    }

    dashboardTemplate(dashboard: Pick<Dashboard, 'id' | 'name' | 'active'>): JQuery {
      return inactiveTitleTemplate(dashboard, 'name', 'app.labels.archived_name');
    }

    updateDashboardPaneIds(dashboard_pane_ids: number[]) {
      dashboard_pane_ids = dashboard_pane_ids.map((id) => Number(id));
      this.dashboardPaneIds = dashboard_pane_ids;
      this.form.dashboard_pane_ids = union(
        difference(this.form.dashboard_pane_ids, this.dashboardPaneIdsByModuleName['null']),
        dashboard_pane_ids
      );
    }

    dashboardPaneSelectTemplate(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        primaryAttribute: (entity: { [key: string]: string }) => {
          return entity.active ? entity.name : I18n.t('app.labels.archived_name', { name: entity.name });
        },
        secondaryAttribute: 'description',
      });
    }
  }
