
  import DsIconText from '@app/components/ds-icon-text.vue';
  import bootbox from 'bootbox';
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import PaperTrailsModalLink from '../../paper-trails/paper-trails-modal-link.vue';
  import DsDropdown from '../../ds-dropdown.vue';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown } })
  export default class ScheduledEventActions extends Vue {
    @Prop(Object) readonly scheduledEvent!: Partial<ScheduledEvent>;
    @Prop(Boolean) readonly showSaveDebugButton?: boolean;
    @Prop(Boolean) readonly submitting?: boolean;

    get logLink(): string {
      return `/admin/settings/scheduled_events/${this.scheduledEvent.id}/event_occurrences`;
    }

    onDeleteScheduledEvent(): void {
      this.showConfirmation().then((id) => {
        this.$api
          .deleteScheduledEvent(id)
          .then(() => {
            toaster(
              this.$t('tenant.admin.scheduled_events.edit.scheduled_event_destroyed', {
                name: this.scheduledEvent.name,
              })
            );
            changeLocation('/admin/settings/scheduled_events');
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
      });
    }

    showConfirmation(): Promise<number> {
      const scheduledEventId = this.scheduledEvent.id;
      if (!scheduledEventId) throw Error('scheduledEventId is required');

      return new Promise<number>((resolve) => {
        bootbox.confirm({
          backdrop: false,
          size: 'small',
          message: this.$t('tenant.admin.scheduled_events.edit.confirm'),
          buttons: {
            confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              return resolve(scheduledEventId);
            }
          },
        });
      });
    }
  }
