
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import type { VideoWidgetOptions } from '@app/models/widget';
  import urlRule from '@app/validators/url-rule';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({ components: { ValidationObserver, ValidationProvider } })
  export default class VideoWidgetSettings extends BaseWidgetSettings<VideoWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<VideoWidgetOptions>;

    beforeMount(): void {
      extend('url', urlRule);
    }
  }
