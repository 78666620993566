
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import FileUploader from '@app/components/file-uploader/file-uploader.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Blob } from '@rails/activestorage';
  import bootbox from 'bootbox';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { omit } from 'lodash';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { Tooltip } from 'uiv';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { UserCollection } from '@app/models/user-collection';
  import {
    AuditReportTemplateFormat,
    AuditReportTemplatePurviewType,
    AuditReportTemplateReportType,
    AuditReportTemplateVisibility,
  } from '@app/models/audit-report-template';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { enumToOptions } from '@app/utils/enumToOptions';

  import UserCollectionSelector from '../admin/user-collections/user-collection-selector.vue';

  @Component({
    components: { FileUploader, ValidationObserver, ValidationProvider, Select2, EntitySelector, UserCollectionSelector, Tooltip },
  })
  export default class AuditReportTemplateForm extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly auditReportTemplate!: AuditReportTemplate;

    form: Partial<AuditReportTemplate & { file: string }> = {};
    fileName: string | undefined = '';
    selectorKey = 0;

    reportFormatOptions = enumToOptions(AuditReportTemplateFormat);
    visibilityOptions = enumToOptions(AuditReportTemplateVisibility);
    subFormLists: SubFormList[] = [];
    subFormId: Nullable<number> = null;
    selectedSubFormList: Nullable<Pick<SubFormList, 'sub_forms'>> = null;
    moduleName: Nullable<Pick<ModuleName, 'name'>> = null;
    mainFormCompatible = false;
    selectedReportType = '';
    loading = true;

    get isTechnicalAdmin(): boolean {
      return !!useCurrentUserStore()?.isDefaultTechAdmin;
    }

    get rowLimitText(): string {
      return this.$t('tenant.admin.audit_report_template.form.row_limit_count', { count: this.form.row_limit });
    }

    get pendingTabSection(): boolean {
      return this.form.report_type === AuditReportTemplateReportType.SubFormCompletion && !this.selectedSubFormList;
    }

    get triggeringConceptName(): string | undefined {
      switch (this.selectedReportType) {
        case AuditReportTemplateReportType.ModuleRecord:
          return this.moduleName?.name;
        case AuditReportTemplateReportType.SubFormCompletion:
          return 'SubFormCompletion';
      }
    }

    get triggeringInjectableArguments(): Partial<InjectableArguments> | undefined {
      if (this.showSubFormListId) {
        const subForms = this.selectedSubFormList?.sub_forms;
        if (subForms?.length === 1) {
          return { sub_form_id: subForms[0]['id'] };
        }
      }
    }

    get reportTypeOptions(): [string, string][] {
      return [
        [AuditReportTemplateReportType.ModuleRecord, this.$t('tenant.admin.audit_report_template.form.main_form')],
        [AuditReportTemplateReportType.RecordIndex, this.$t('tenant.admin.audit_report_template.form.main_form_index')],
        [AuditReportTemplateReportType.SubFormCompletion, this.$t('tenant.admin.audit_report_template.form.subform_tab_section')],
      ];
    }

    get showSubFormListId(): boolean {
      return this.form.report_type === AuditReportTemplateReportType.SubFormCompletion;
    }

    get subFormListFilters(): DonesafeFilterOptions<SubFormList> {
      return {
        active: true,
        sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
        module_tab: {
          related_module_name: { id: this.auditReportTemplate.module_name_id },
        },
      };
    }

    get showWarning(): boolean {
      return !!this.accountStore.data.classic_sub_form_menu_enabled;
    }

    get multipleFormatsEnabled(): boolean {
      return window.DONESAFE.documentConverterPresent;
    }

    get accountStore() {
      return useAccountStore();
    }

    get formForSubmit(): Partial<AuditReportTemplate> {
      if (!this.form?.user_collection) return this.form as Partial<AuditReportTemplate>;

      return this.form?.user_collection?.global
        ? omit({ ...this.form, user_collection_id: this.form.user_collection.id }, 'user_collection')
        : omit(this.form, 'user_collection_id');
    }

    setInitialReportTypeAndPurview(): void {
      if (
        this.auditReportTemplate.report_type === AuditReportTemplateReportType.ModuleRecord &&
        [AuditReportTemplatePurviewType.record_index, AuditReportTemplatePurviewType.all_records].includes(this.auditReportTemplate.purview)
      ) {
        this.selectedReportType = AuditReportTemplateReportType.RecordIndex;
        this.mainFormCompatible = this.form.purview === AuditReportTemplatePurviewType.all_records;
        this.form = { ...this.form, report_type: AuditReportTemplateReportType.ModuleRecord };
      } else {
        this.form = { ...this.form, report_type: this.auditReportTemplate.report_type || AuditReportTemplateReportType.ModuleRecord };
        this.selectedReportType = this.auditReportTemplate.report_type || AuditReportTemplateReportType.ModuleRecord;
      }
    }

    async beforeMount(): Promise<void> {
      this.initForm();
      await Promise.all([await this.fetchModuleName(), await this.fetchUserCollection()]);
      if (this.form.sub_form_list_id) await this.setSelectedSubFormList(this.form.sub_form_list_id);
      this.loading = false;
    }

    initForm(): void {
      this.form = {
        ...this.auditReportTemplate,
        report_format: this.auditReportTemplate.report_format || AuditReportTemplateFormat.Word,
        visibility: this.auditReportTemplate.visibility || AuditReportTemplateVisibility.Visible,
        row_limit: this.auditReportTemplate.row_limit,
      };
      this.setInitialReportTypeAndPurview();
      this.fileName = this.form.attachment?.file_name;
    }

    async fetchModuleName(): Promise<void> {
      const { data } = await this.$api.getModuleName(this.auditReportTemplate.module_name_id, { only: ['name'] }, { cache: true });

      this.moduleName = data;
    }

    async fetchUserCollection(): Promise<void> {
      if (this.auditReportTemplate.user_collection_id) {
        const { data } = await this.$api.getUserCollection(
          Number(this.auditReportTemplate.user_collection_id),
          { only: ['id', 'name', 'global', { rule_set: ['rules_description', 'query_builder_blob'] }] },
          { cache: true }
        );
        this.form = { ...this.form, user_collection: data };
      }
    }

    setReportType(value: AuditReportTemplateReportType): void {
      if (value === AuditReportTemplateReportType.RecordIndex) {
        this.form = { ...this.form, report_type: AuditReportTemplateReportType.ModuleRecord };
      } else {
        this.mainFormCompatible = false;
        this.form = { ...this.form, report_type: value as AuditReportTemplateReportType };
      }

      this.resetRestrictions();
    }

    async setSelectedSubFormList(id: SubFormList['id']): Promise<void> {
      const { data } = await this.$api.getSubFormList(id, { only: [{ sub_forms: ['id'] }] }, { cache: true });
      this.selectedSubFormList = data;
    }

    resetRestrictions(): void {
      this.form = { ...this.form, user_collection: {} as UserCollection };
      this.selectorKey++;
    }

    onUpload(blob?: Blob): void {
      this.fileName = blob?.filename;
      this.form = { ...this.form, file: blob?.signed_id };
    }

    setPurview(): void {
      if (this.selectedReportType === AuditReportTemplateReportType.RecordIndex && this.mainFormCompatible) {
        this.form = { ...this.form, purview: AuditReportTemplatePurviewType.all_records };
      } else if (this.selectedReportType === AuditReportTemplateReportType.RecordIndex && !this.mainFormCompatible) {
        this.form = { ...this.form, purview: AuditReportTemplatePurviewType.record_index };
      } else {
        this.form = { ...this.form, purview: AuditReportTemplatePurviewType.single_record };
      }
    }

    submit(): void {
      this.setPurview();
      this.validator?.validate().then((result: boolean) => {
        result &&
          this.$emit('submit', {
            ...this.formForSubmit,
            sub_form_list_id: this.showSubFormListId ? this.form.sub_form_list_id : null,
          });
      });
    }

    deleteAuditReportTemplate(): void {
      const message = this.$t('tenant.admin.audit_report_template.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        backdrop: false,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.$emit('delete', this.form.id);
        },
      });
    }
  }
