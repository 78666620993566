
  import Select2 from '@app/components/select2.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Notification } from '@app/models/notification';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: { Select2 },
  })
  export default class AdminNotificationsDetailsPage extends Vue {
    @Prop({ type: [Number, String] }) readonly id!: number | string;

    notification: Notification | null = null;
    form: Partial<Notification> = {};

    activeOptions = [
      ['true', this.$t('app.labels.yes')],
      ['false', this.$t('app.labels.no')],
    ];

    get selectModel(): StringBoolean {
      return this.form.active ? 'true' : 'false';
    }

    set selectModel(value: StringBoolean) {
      this.form = { ...this.form, active: value === 'true' };
    }

    beforeMount(): void {
      this.$api.getNotification(Number(this.id), {}, { cache: true }).then(({ data }) => {
        this.notification = data;
        this.form = { ...data };
      });
    }

    submit(): void {
      this.notification &&
        this.$api.updateNotification(this.notification.id, { active: this.form.active }).then(() => {
          this.$api.cache.clear();
          toaster('Notification successfully updated!');
        });
    }
  }
