
  import ModuleTabForm from '@app/components/admin/module-tabs/module-tab-form.vue';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleTab } from '@app/models/module-tab';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  @Component({ components: { ModuleTabForm } })
  export default class ModuleTabNewPage extends mixins(Blocking) {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: string | number;
    @Ref() readonly form?: ModuleTabForm;

    moduleTab: Partial<ModuleTab> | null = null;
    moduleName: Pick<ModuleName, 'id' | 'name'> | null = null;

    beforeMount(): void {
      if (this.moduleNameId) {
        this.$api
          .getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] })
          .then(({ data }) => {
            this.moduleName = data;
            this.moduleTab = { module_name: data.name };
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          });
      } else {
        changeLocation('/admin/settings/module_names');
      }
    }

    submit(moduleTab: Partial<ModuleTab>): void {
      this.blocking(async () => {
        await this.$api.createModuleTab(moduleTab).then(({ data }) => {
          this.$api.cache.clear();
          this.$router.push({ name: 'admin-module-tab-redirect', params: { moduleTabId: `${data.id}` } });
        });
      });
    }
  }
