
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue, Emit, Ref } from 'vue-property-decorator';
  import type { FilterParams } from '@app/services/api/utils-api';
  import { groupBy, pick, uniqBy, unescape } from 'lodash';
  import type { Dictionary } from '@app/models/dictionary';
  import type { RuleSetFilter } from '@app/models/rule-set-filter';
  import type { RuleSet } from '@app/models/rule-set';
  import type { QueryBuilderBlob, QueryBuilderRule } from '@app/models/query-builder-blob';
  import { DEFAULT_QUERY_BUILDER_BLOB, DEFAULT_QUERY_BUILDER_RULE } from '@app/models/query-builder-blob';
  import { extractQueryBuilderRules } from '@app/utils/extract-query-builder-rules';
  import { uniqWithDuplicates } from '@app/utils/uniq-with-duplicates';
  import DsQueryBuilder from '@app/components/ds-query-builder/ds-query-builder.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import { getApplicableOperators } from '@app/components/ds-query-builder/utils';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  import FormField from '../questions/edit/form-field.vue';

  @Component({
    components: {
      DsQueryBuilder,
      DsIconText,
      DsModal,
      FormField,
    },
  })
  export default class RuleBuilderPanel extends Vue {
    @Ref() readonly editQueryBuilder!: DsQueryBuilder;
    @Ref() readonly readQueryBuilder!: DsQueryBuilder;
    @Prop(String) readonly conceptName?: string;
    @Prop(Object) readonly injectableArguments!: Dictionary<string>;
    @Prop(String) readonly triggeringConceptName?: string;
    @Prop(Object) readonly triggeringInjectableArguments?: Dictionary<string>;
    @Prop(String) readonly name!: string;
    @Prop(Object) readonly ruleSet?: RuleSet;
    @Prop(Object) readonly filterParams?: FilterParams;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly hideEditButton?: boolean;
    @Prop(Boolean) readonly openInEditMode?: boolean;
    @Prop(Boolean) readonly debug?: boolean;
    @Prop(String) readonly executionContext?: 'automation' | 'schedule';
    @Prop(Boolean) readonly withPlaceholdersInRules?: boolean;
    @Prop(Boolean) readonly hideFirstGroupLabel?: boolean;
    @Prop(String) readonly title?: string;

    filters: RuleSetFilter[] = [];
    queryBuilderRules = '';
    editModal = false;
    missedRuleIds: string[] = [];
    editableRules = '';
    injectableOptions: PlaceholderItem[] = [];

    get mergedTitle() {
      return this.title || this.$t('components.ds_query_builder.conditions');
    }

    get firstRule(): Maybe<QueryBuilderRule> {
      const firstFilter = this.filters[0];

      if (firstFilter) {
        return {
          id: firstFilter.id,
          type: firstFilter.type,
          input: firstFilter.input,
          field: firstFilter.field,
          operator: firstFilter.operators?.[0],
          value: firstFilter.default_value || null,
        } as QueryBuilderRule;
      }
    }

    get ruleSetBlob(): Nullable<QueryBuilderBlob> {
      return this.ruleSet?.query_builder_blob || null;
    }

    get defaultRules(): QueryBuilderBlob {
      return {
        ...DEFAULT_QUERY_BUILDER_BLOB,
        rules: [this.firstRule || DEFAULT_QUERY_BUILDER_RULE],
      };
    }

    get accountStore() {
      return useAccountStore();
    }

    @Emit('input')
    updateBuilderRules(input?: QueryBuilderBlob): Maybe<QueryBuilderBlob> {
      this.queryBuilderRules = JSON.stringify(input);
      return input;
    }

    applyMissedFilter(rule: QueryBuilderRule, others: QueryBuilderRule[]): RuleSetFilter {
      const label = `${this.$t('app.labels.missing_rule', { code: rule.id })}`;
      return {
        ...pick(rule, ['field', 'id', 'input', 'operator', 'type']),
        label,
        input: 'select',
        values: Object.fromEntries(
          uniqBy(others, 'value').map(({ value }) => [`${value}`, `${this.$t('app.labels.missing_rule_label_value', { value })}`])
        ),
        operators: uniqBy(others, 'operator').map(({ operator }) => operator),
      };
    }

    applyMissedFilters(filters: RuleSetFilter[], missedBuilderRules: QueryBuilderRule[]): RuleSetFilter[] {
      const missedGroups = groupBy(missedBuilderRules, 'id');
      return [...filters, ...uniqBy(missedBuilderRules, 'id').map((r) => this.applyMissedFilter(r, missedGroups[r.id]))];
    }

    getFilters() {
      return this.$api
        .getRuleSetFilters(
          {
            concept_name: this.conceptName,
            injectable_arguments: this.injectableArguments,
            triggering_concept_name: this.triggeringConceptName,
            triggering_injectable_arguments: this.triggeringInjectableArguments,
            filter_params: this.filterParams,
            execution_context: this.executionContext,
          },
          { cache: true }
        )
        .then(({ data }) => {
          const { unique, duplicates } = uniqWithDuplicates(data, 'id');
          duplicates.length && console.error('Duplicated rule set IDs:', duplicates);

          return unique;
        });
    }

    getInjectableOptions() {
      if (!this.withPlaceholdersInRules) return Promise.resolve([]);

      return this.$api
        .getInjectableOptions(
          {
            concept_name: this.conceptName,
            injectable_arguments: { include_calculations: true },
            execution_context: this.executionContext,
          },
          { cache: true }
        )
        .then(({ data }) => data);
    }

    toggleEditModal() {
      this.editModal = !this.editModal;
    }

    processFilters(filters: RuleSetFilter[]): RuleSetFilter[] {
      const queryBuilderRules = (this.ruleSetBlob && extractQueryBuilderRules(this.ruleSetBlob)) || [];
      const filtersIds = filters.map((filter) => filter.id);
      const missedBuilderRules = queryBuilderRules.filter((r) => !filtersIds.includes(r.id));
      this.missedRuleIds = missedBuilderRules.map((r) => r.id);
      const fixedLabels = filters.map((filter) => {
        return { ...filter, label: filter.label && unescape(filter.label) };
      });
      const missedFilters = this.applyMissedFilters(fixedLabels, missedBuilderRules);

      const missedFiltersWithApplicableOperators = missedFilters.map((filter) => ({
        ...filter,
        operators: getApplicableOperators(filter),
      }));

      return missedFiltersWithApplicableOperators;
    }

    saveRules() {
      if (this.editQueryBuilder) {
        this.editQueryBuilder.save().then((value) => {
          if (!!value) {
            this.editableRules = JSON.stringify(value);
            this.updateBuilderRules(value);
            this.editModal = false;
          }
        });
      }
    }

    initNewRuleBuilder(uniqueFilters: RuleSetFilter[], injectableOptions: PlaceholderItem[]) {
      this.injectableOptions = injectableOptions;
      this.filters = this.processFilters(uniqueFilters);
      const defaultRules = this.ruleSet ? this.ruleSet.query_builder_blob || this.defaultRules : this.defaultRules;
      this.editableRules = JSON.stringify(defaultRules);
      // do not set 'queryBuilderRules' to default values if ruleSet is not provided
      this.ruleSet?.query_builder_blob && this.updateBuilderRules(this.ruleSet.query_builder_blob);
      this.openInEditMode && this.toggleEditModal();
      this.$emit('loaded');
    }

    mounted(): void {
      Promise.all([this.getFilters(), this.getInjectableOptions()]).then(([uniqueFilters, injectableOptions]) => {
        this.initNewRuleBuilder(uniqueFilters, injectableOptions);
      });
    }
  }
