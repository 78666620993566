import {
  AddressShowOptions,
  ButtonSelectShowOptions,
  CalculationSelectShowOptions,
  CalculationTextShowOptions,
  CalculatorShowOptions,
  CompanyRegisterShowOptions,
  DetailShowOptions,
  DisplayImageShowOptions,
  KnowledgeBaseShowOptions,
  MainFormRelationShowOptions,
  MarkableShowOptions,
  MatrixShowOptions,
  MultiCheckboxShowOptions,
  MultiMainFormRelationShowOptions,
  MultiSelectShowOptions,
  PayCalculatorShowOptions,
  RadioShowOptions,
  RecordRelationShowOptions,
  ReportShowOptions,
  SignatureShowOptions,
  SinglePersonSelectorShowOptions,
  SingleSelectShowOptions,
  SubFormRelationShowOptions,
  SvgSelectorShowOptions,
  TextShowOptions,
  TextareaShowOptions,
  TimesheetShowOptions,
  VideoShowOptions,
} from './index';

export const SFQ_SHOW_COMPONENTS = {
  AddressShowOptions,
  ButtonSelectShowOptions,
  CalculationSelectShowOptions,
  CalculationTextShowOptions,
  CalculatorShowOptions,
  CompanyRegisterShowOptions,
  DetailShowOptions,
  DisplayImageShowOptions,
  KnowledgeBaseShowOptions,
  MainFormRelationShowOptions,
  MarkableShowOptions,
  MatrixShowOptions,
  MultiCheckboxShowOptions,
  MultiMainFormRelationShowOptions,
  MultiSelectShowOptions,
  PayCalculatorShowOptions,
  RadioShowOptions,
  RecordRelationShowOptions,
  ReportShowOptions,
  SignatureShowOptions,
  SinglePersonSelectorShowOptions,
  SingleSelectShowOptions,
  SubFormRelationShowOptions,
  SvgSelectorShowOptions,
  TextShowOptions,
  TextareaShowOptions,
  TimesheetShowOptions,
  VideoShowOptions,
};
