
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import sortBy from 'lodash/sortBy';
  import ActionPriorityForm from '@app/components/admin/actions/action-priority-form.vue';
  import ColorInput from '@app/components/color-input.vue';
  import { BaseTable } from '@app/components/base-table';
  import type { ActionPriority } from '@app/models/action-priority';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: {
      ActionPriorityForm,
      Draggable,
      ColorInput,
      BaseTable,
    },
  })
  export default class ActionPriorities extends Vue {
    manager: ListManager<ActionPriority> | null = null;

    @Ref() readonly table?: BaseTable<ActionPriority>;

    unsubscribeRouter: () => void = () => null;

    async beforeMount() {
      this.manager = this.getManager();

      this.unsubscribeRouter = this.$router.afterEach(() => {
        this.table?.reload();
        this.onReorderPriorities();
      });
    }

    beforeDestroy() {
      this.unsubscribeRouter();
    }

    getManager(): ListManager<ActionPriority> {
      return new ListManager<ActionPriority>({
        // TODO: cache
        fetchDataFunction: (params) => this.$api.getActionPriorities({ sort: '-active,index', ...params }),
        per_page: -1,
        sortOrder: [
          { direction: 'desc', field: 'active', sortField: 'active' },
          { direction: 'asc', field: 'index', sortField: 'index' },
        ],
        fields: [
          { title: '', name: 'index' },
          { title: this.$t('app.views.admin.action_priorities.title'), name: 'title' },
          { title: this.$t('app.views.admin.action_priorities.code'), name: 'code' },
          { title: this.$t('app.views.admin.action_priorities.color'), name: 'color' },
          { title: this.$t('app.views.admin.action_priorities.is_default'), name: 'is_default' },
          { title: this.$t('app.views.admin.action_priorities.active'), name: 'active' },
        ],
      });
    }

    async onReorderPriorities() {
      if (!this.manager) return;

      this.moveInactiveToBottom();
      await this.$api.updateActionPrioritiesIndexes({ data: this.manager.items.map(({ id, index }) => ({ id, index })) });
      this.table?.reload();
    }

    moveInactiveToBottom() {
      if (!this.manager) return;

      this.table?.setData(sortBy(this.manager.items, (p) => (p.active ? 0 : 1)).map((p, i) => ({ ...p, index: i })));
    }
  }
