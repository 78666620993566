
  import BaseSubFormListPage from '@app/components/sub-form-list/base-sub-form-list-page';
  import SubFormListFormModal from '@app/components/sub-form-list/sub-form-list-form-modal.vue';
  import { Component } from 'vue-property-decorator';
  import type { SubFormList } from '@app/models/sub-form-list';

  import { toaster } from '@app/utils/toaster';

  @Component({ components: { SubFormListFormModal } })
  export default class SubFormListEditPage extends BaseSubFormListPage {
    visible = true;

    submit(form: SubFormList): void {
      this.blocking(async () => {
        return (
          this.subFormList &&
          this.$api
            .updateSubFormList(this.subFormList.id, form)
            .then(() => this.closeModal('reload'))
            .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }))
        );
      });
    }

    beforeMount(): void {
      // use only instead
      this.$api
        .getSubFormList(Number(this.subFormListId), { include: ['sub_form_ids', 'module_tab'] }, { cache: true })
        .then(({ data }) => {
          this.subFormList = data;
        });
    }

    deleteSubFormList(): void {
      this.subFormList &&
        this.$api
          .deleteSubFormList(this.subFormList.id)
          .then(() => {
            this.$api.cache.clear();
            this.closeModal('reload');
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }
  }
