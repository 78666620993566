
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import { Component, Ref } from 'vue-property-decorator';
  import ActionPriorityForm from '@app/components/admin/actions/action-priority-form.vue';
  import type { ActionPriority } from '@app/models/action-priority';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, ActionPriorityForm } })
  export default class ActionPriorityNew extends mixins(Blocking) {
    @Ref() readonly form?: ActionPriorityForm;

    async onSubmit(form: Partial<ActionPriority>): Promise<ActionPriority | null> {
      return this.blocking(async () => {
        const { data } = await this.$api.createActionPriority(form);
        this.close();

        return data;
      });
    }

    close() {
      this.$router.push({ name: 'admin-action-settings' });
    }
  }
