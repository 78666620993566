
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ components: {} })
  export default class ElementShiftIcon extends Vue {
    @Prop({ required: true }) readonly rightOrLeft!: 'right' | 'left';
    @Prop() readonly disabled?: boolean;

    get elementShiftIconStyle(): Dictionary<string> {
      let elementShiftIconStyle: Dictionary<string> = {
        position: 'absolute',
        fontSize: '8px',
        top: '13px',
        color: 'white',
        zIndex: '2',
      };

      if (this.disabled) {
        elementShiftIconStyle.color = 'grey';
      } else {
        elementShiftIconStyle.color = 'white';
        elementShiftIconStyle.cursor = 'pointer';
      }

      if (this.rightOrLeft === 'left') elementShiftIconStyle.left = '6px';
      if (this.rightOrLeft === 'right') elementShiftIconStyle.right = '6px';

      return elementShiftIconStyle;
    }
  }
