
  import Select2 from '@app/components/select2.vue';
  import { Component, Model, Vue } from 'vue-property-decorator';

  @Component({ components: { Select2 } })
  export default class ModuleSecondaryOptionsSelector extends Vue {
    @Model('input') readonly value!: string[];
    get secondaryInformationOptions(): [string, string][] {
      return [
        ['uniq_id', 'Uniq ID'],
        ['organization_name', 'Organization'],
        ['location_name', 'Location'],
        ['module_name_display', 'Module Name'],
        ['external_uuid', 'External UUID'],
        ['user_full_name', 'Creator Name'],
        ['workflow_name', 'Workflow'],
        ['id', 'System ID'],
      ];
    }
  }
