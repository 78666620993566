
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { Icon } from '@iconify/vue2/dist/offline';
  import { tooltip } from 'uiv';

  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import { OPERATORS_BY_TYPE } from '../defaults';

  @Component({ components: { DsDropdown, Icon, DsIconText }, directives: { tooltip } })
  export default class RuleOperatorSelect extends Vue {
    @Inject() qb!: DsQueryBuilderHandler;
    @Prop() readonly rule!: DsRuleNode;

    get operatorSelectDisabled() {
      return !this.qb.editable || this.operators.length < 2;
    }

    get dynamicButtonClasses() {
      return [!this.operatorSelectDisabled ? 'bordered-btn' : 'transparent-btn', { 'disable-interactions': this.operatorSelectDisabled }];
    }

    get tooltipProps() {
      return {
        text: this.operatorLabelTooltip,
        customClass: 'modal-tooltip',
      };
    }

    get operatorLabelTooltip() {
      if (this.operatorSelectDisabled) {
        return this.currentOption?.label;
      }
    }

    get currentOption() {
      if (!this.rule?.operator) {
        return;
      }
      return this.optionsByType[this.rule.operator];
    }

    get optionsByType() {
      return OPERATORS_BY_TYPE;
    }

    get filter() {
      return this.rule.filter;
    }

    get operators() {
      if (!this.filter) {
        return [];
      }

      return this.filter.operators;
    }
  }
