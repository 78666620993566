import type { BaseEntity } from '@app/models/base-entity';
import type { UpdateIndexParams } from '@app/services/donesafe-api-utils';
import type { ListManager } from '@app/services/list-manager/list-manager';

export const getUpdateIndexParams = <T extends BaseEntity>(manager: ListManager<T>): UpdateIndexParams => {
  const sort = manager.getSort(manager.sortOrder);
  const indexes = manager.items.map((item, index) => index + 1);
  const desc = sort && sort[0] === '-';
  desc && indexes.reverse();
  return {
    data: manager.items.map((item, index) => ({
      id: item.id,
      index: indexes[index],
    })),
    sort,
  };
};
