
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { RegulatoryReportFilter } from '@app/models/regulatory-report-config';
  import RecordSelector from '../record-selector.vue';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { castArray } from 'lodash';

  type FilterSelectorValue = number | string | number[] | string[];

  @Component({ components: { RecordSelector } })
  export default class RecordFilterSelector extends Vue {
    @Model('input') value!: FilterSelectorValue;
    @Prop(Object) filter?: RegulatoryReportFilter;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<ModuleRecord> | (() => DonesafeFilterOptions<ModuleRecord>);

    @Ref() readonly selector?: RecordSelector;

    get isEmptyValue(): boolean {
      return !this.value || !castArray(this.value).length;
    }

    selectAll(): void {
      this.selector?.selectAll();
    }

    clearAll(): void {
      this.selector?.clearAll();
    }
  }
