
  import type { LogicSet } from '@app/components/rule-builder/models/types';
  import { upperFirst } from 'lodash';

  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { CalculatorQuestionOptions, DisplaySettings } from '@app/models/question-options/calculator-question-options';

  @Component({ components: {} })
  export default class CalculatorShowOptions extends BaseShowOptions<CalculatorQuestionOptions> {
    logicSet: Nullable<LogicSet> = null;

    get currencySymbol(): string {
      return this.displaySettings?.currency || '$';
    }

    get decimalsFormat(): string {
      return this.displaySettings.decimals
        ? this.$t('tenant.admin.sub_form_questions.options.show.decimal_places', {
            decimal_places: this.displaySettings.decimals,
          })
        : '';
    }
    get displayFormat(): string {
      return upperFirst(this.displaySettings.format);
    }

    get calculatorName(): string {
      return this.logicSet?.definitions?.parentRule?.name || '';
    }

    get logicSetId(): number {
      return Number(this.config.logic_set_id);
    }

    get displaySettings(): DisplaySettings {
      return JSON.parse(this.config.display_settings);
    }

    beforeMount() {
      this.logicSetId &&
        this.$api.getLogicSet(this.logicSetId, { cache: true }).then(({ data }) => {
          this.logicSet = data;
        });
    }
  }
