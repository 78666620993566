import type { Dictionary } from '@app/models/dictionary';
import type { SubForm } from '@app/models/sub-form';
import { SubFormListType, type SubFormList } from '@app/models/sub-form-list';
import I18n from '../i18n';

export const printSubFormListTitle = (
  subFormList: Pick<SubFormList, 'title' | 'sub_form_ids' | 'sub_form_list_type'>,
  subForms: Dictionary<Pick<SubForm, 'id' | 'title' | 'active'>>
): string | undefined => {
  if (subFormList.title) {
    return subFormList.title;
  } else if (subFormList.sub_form_ids?.length) {
    const { active, title, id } = subForms[subFormList.sub_form_ids[0]];
    return active ? title : I18n.t('app.labels.title_id_inactive', { title: title, id: id });
  } else if (subFormList.sub_form_list_type === SubFormListType.expense_estimates) {
    return I18n.t('app.labels.expense_estimates');
  }
};
