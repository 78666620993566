
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import ModuleNameForm from './module-name-form.vue';

  @Component({ components: { ModuleNameForm } })
  export default class ModuleNameEditPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;

    submitting = false;
    moduleName: Nullable<ModuleName> = null;

    submit(moduleName: ModuleName, redirect?: string): void {
      this.submitting = true;

      this.$api
        .updateModuleName(moduleName.id, moduleName)
        .then(() => {
          toaster('Module successfully updated!');
          if (redirect === 'ams') {
            changeLocation(`/admin/settings/module_names/${moduleName.id}/release`);
          } else {
            changeLocation(`/admin/settings/module_names/${moduleName.id}`);
          }
        })
        .catch(({ data }) => {
          this.submitting = false;
          toaster({ text: data.error, icon: 'error' });
        });
    }

    beforeMount(): void {
      this.$api
        .getModuleName(Number(this.moduleNameId), { show_all: true }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        })
        .catch(() => {
          // TODO: redirect to fallback route and show notice after fully switched to vue-router
        });
    }
  }
