
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import type { ReportBlob, ReportColumnBase } from '@app/models/super-report';
  import { isColumnsEqual } from '@app/models/super-report';

  interface GroupByOption extends ReportColumnBase {
    disabled?: boolean;
    id?: string;
    name: string;
  }

  type CountBy = [string, string];

  @Component({ components: { Select2 } })
  export default class AdminReportGraphConfig extends Vue {
    @Prop(Object) readonly reportBlob!: ReportBlob;
    @Prop(Array) readonly groupBys!: GroupByOption[];
    @Prop(Array) readonly countBys!: CountBy[];

    groupByOne: string | null = null;
    groupByTwo: string | null = null;
    countOn: string | null = null;

    get groupByOneOptions(): GroupByOption[] {
      return this.groupBys.map((option, index) => ({
        ...option,
        id: `${index}`,
        disabled: `${index}` === this.groupByTwo,
      }));
    }

    get groupByTwoOptions(): GroupByOption[] {
      return this.groupBys.map((option, index) => ({
        ...option,
        id: index.toString(),
        disabled: index.toString() === this.groupByOne,
      }));
    }

    get groupByOneValue(): string | undefined {
      return this.groupBValue(this.groupByOne);
    }

    get groupByTwoValue(): string | undefined {
      return this.groupBValue(this.groupByTwo);
    }

    groupBValue(index: string | null): string | undefined {
      if (index) {
        const option = this.groupBys[parseInt(index)];
        if (option) {
          return JSON.stringify(option, ['key', 'column_type', 'source']);
        }
      }
    }

    isGroupBysEqual(left: ReportColumnBase | undefined, right: ReportColumnBase | undefined): boolean {
      if (!left || !right) return false;

      return isColumnsEqual(left, right);
    }

    beforeMount() {
      this.groupByOne =
        this.groupBys.findIndex((option) => this.isGroupBysEqual(option, this.reportBlob.graph.group_by_1))?.toString() ?? null;
      this.groupByTwo =
        this.groupBys.findIndex((option) => this.isGroupBysEqual(option, this.reportBlob.graph.group_by_2))?.toString() ?? null;
      this.countOn = this.reportBlob.graph.count || null;
    }
  }
