
  import RecordSelector from '@app/components/record-selector.vue';
  import { Component, Prop } from 'vue-property-decorator';
  import FilterValidation from '@app/mixins/filter-validation';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { RecordSelector } })
  export default class FilterRecordSelector extends FilterValidation {
    @Prop(Array) readonly moduleNameIds?: number[];

    get moduleNameFilter(): DonesafeFilterOptions<ModuleRecord> {
      return { module_name_id: this.moduleNameIds };
    }
  }
