import type { BaseEntity } from './base-entity';
import type { RecurringSetup } from './recurring';
import type { Schedule } from './schedule';
import type { TenantUser } from './tenant-user';
import type { Event } from './event';

export enum ScheduledEventStatus {
  active = 'active',
  completed = 'completed',
  pending = 'pending',
  unpublished = 'unpublished',
}
export interface ScheduledEvent extends BaseEntity {
  active: boolean;
  collection_description?: string;
  event?: Event;
  event_id: number;
  name: string;
  recurring_setup?: RecurringSetup;
  schedule?: Schedule;
  schedule_id: number;
  status: ScheduledEventStatus;
  user?: TenantUser;
  user_id: number;
}
