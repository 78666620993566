
  import { Component, Prop, Ref, PropSync, Watch } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AutomationDefinitionState, AutomationTriggerOn } from '@app/models/automation-definition';
  import { ValidationObserver } from 'vee-validate';
  import AutomationTriggerForm from '@app/components/admin/automation-definitions/automation-trigger-form.vue';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import AutomationConditionsForm from '@app/components/admin/automation-definitions/automation-conditions-form.vue';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import qs from 'qs';
  import { toaster } from '@app/utils/toaster';
  import Blocking from '@app/mixins/blocking';

  import type EventGroupsSorting from './event-groups-sorting.vue';
  import AutomationDefinitionActions from './automation-definition-actions.vue';
  import AutomationDetailsForm from './automation-details-form.vue';
  import type { Form } from './models';
  import ForEveryPreloadData from './for-every-preload-data';
  import ListOfEventsAndGroups from './list-of-events-and-groups.vue';
  import type { EventWithTempId, GroupOrEvent, GroupWithTempId } from './with-automation-submit';

  @Component({
    components: {
      AutomationTriggerForm,
      AutomationDefinitionActions,
      ValidationObserver,
      AutomationConditionsForm,
      AutomationDetailsForm,
      ListOfEventsAndGroups,
    },
  })
  export default class AutomationDefinitionForm extends mixins(WithBootbox, ForEveryPreloadData, Blocking) {
    @Ref() readonly eventGroupsSorting!: InstanceType<typeof EventGroupsSorting>;
    @Ref() readonly formValidator!: InstanceType<typeof ValidationObserver>; // Used to validate the form before submit
    @Ref() readonly listOfEventsAndGroups!: InstanceType<typeof ListOfEventsAndGroups>; // Used to validate the form before submit
    @Ref() readonly automationConditionsForm!: InstanceType<typeof AutomationConditionsForm>; // Used to validate the form before submit

    @Prop(Object) readonly automationDefinition!: Partial<AutomationDefinition>;
    @Prop(Object) readonly automationRuleSetBuilder!: AutomationRuleSetBuilder;
    @PropSync('events') syncedEvents!: EventWithTempId[];
    @PropSync('groups') syncedGroups!: GroupWithTempId[];
    @Prop(Boolean) readonly submitting!: boolean;
    @Prop(Boolean) readonly clone?: boolean;
    @Prop(Number) readonly automationActivationProgress?: number;
    @Prop(Number) readonly ruleBuilderPanelKey?: number;
    @Prop(Object) readonly injectableArguments!: InjectableArguments;

    ruleBuilderLoaded = false;
    form: Form = {};

    selectedPane: string | null = null;
    eventFormsLoaded: Record<string, boolean> = {};
    invalidForms: Record<string, boolean> = {};
    initialEvents: EventWithTempId[] = [];

    statusMap: Record<string, string> = {
      [AutomationDefinitionState.active]: 'Active',
      [AutomationDefinitionState.inactive]: 'Inactive',
      [AutomationDefinitionState.activating]: 'Activating',
    };

    triggerOnConditions = false;
    openRuleBuilderInEditMode = false;

    get status(): string {
      return this.statusMap[this.automationDefinition.state as string];
    }

    get linkToTriggeredFrom(): string | undefined {
      const { sub_form_id } = this.automationDefinition;

      if (sub_form_id) return `/admin/settings/sub_forms/${sub_form_id}`;
    }

    get hasInvalidEventForms(): boolean {
      return this.syncedEvents.some((event) => this.invalidForms[event.id]);
    }

    get formReady(): boolean {
      return !(!this.eventDisplayFormsLoaded || (!this.ruleBuilderLoaded && this.hasConditions));
    }

    get hasConditions() {
      return this.form.trigger_on === AutomationTriggerOn.ConditionsMet;
    }

    get disableSaveButtons(): boolean {
      return this.submitting || !this.syncedEvents.length || this.blocked;
    }

    get stateBadgeClass(): Maybe<string> {
      switch (this.automationDefinition.state) {
        case AutomationDefinitionState.active:
          return 'badge-info';
        case AutomationDefinitionState.inactive:
          return 'badge-danger';
        case AutomationDefinitionState.activating:
          return 'badge-warning';
      }
    }

    get eventDisplayFormsLoaded(): boolean {
      const eventFormsLoaded = this.eventFormsLoaded;

      return (function allLoaded(events: GroupOrEvent[]): boolean {
        return events.every((event) => {
          if ('items' in event) {
            return allLoaded(event.items);
          }
          return eventFormsLoaded[event.id];
        });
      })(this.syncedEvents);
    }

    // Events that are stored in the db
    get initialEventDisplayFormsLoaded(): boolean {
      return this.initialEvents.every((event) => this.eventFormsLoaded[event.id]);
    }

    // This will validate initial event forms when they are loaded
    @Watch('initialEventDisplayFormsLoaded')
    onEventDisplayFormsLoadedChange(value: boolean): void {
      if (value) {
        !this.hasInvalidEventForms &&
          this.$nextTick(() => {
            this.listOfEventsAndGroups.validateAll();
          });
      }
    }

    onSubmit(type: 'redirect' | 'debug' | null): void {
      this.formValidator.validate().then((valid) => {
        if (valid) return this.$emit('submit', this.form, type);

        this.automationConditionsForm.validate();
        this.listOfEventsAndGroups.validateAll();
      });
    }

    onFormLoad(event: EventWithTempId): void {
      this.eventFormsLoaded = { ...this.eventFormsLoaded, [event.id]: true };
    }

    setInvalidForm(event: EventWithTempId, valid: boolean): void {
      this.invalidForms = { ...this.invalidForms, [event.id]: !valid };
    }

    deactivateAutomation(): void {
      this.showConfirmation(
        this.$t('tenant.admin.automation_definitions.form.archive_action'),
        this.$t('app.buttons.archive'),
        'btn-warning'
      ).then((confirmed) => confirmed && this.$emit('deactivate'));
    }

    deleteAutomation(): void {
      this.showConfirmation(
        this.$t('tenant.admin.automation_definitions.form.delete_action'),
        this.$t('app.buttons.delete'),
        'btn-danger'
      ).then((confirmed) => confirmed && this.$emit('delete'));
    }

    activateAutomation(): void {
      this.showConfirmation(this.$t('tenant.admin.automation_definitions.form.confirm_action'), this.$t('app.buttons.confirm')).then(
        (confirmed) => confirmed && this.$emit('activate')
      );
    }

    async showConfirmation(message: string, confirmButtonText: string, confirmButtonClass = 'btn-success'): Promise<boolean> {
      if (!this.automationDefinition.id) return false;

      return await this.confirm(message, {
        backdrop: false,
        buttons: { confirm: { label: confirmButtonText, className: confirmButtonClass } },
      });
    }

    findEvent(id: number): EventWithTempId | undefined {
      return this.syncedEvents.find((event) => {
        return event.id.toString() === id.toString();
      });
    }

    selectEventIdFromParams(): void {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (!params.event_id) return;
      if (isNaN(Number(params.event_id))) return;

      const matchedEvent = this.findEvent(Number(params.event_id));
      if (matchedEvent) {
        this.selectedPane = `${matchedEvent.id}:event`;
        return;
      }

      toaster({
        text: this.$t('tenant.admin.automation_definitions.form.event_not_found', { id: params.event_id }),
        position: 'top-right',
        icon: 'warning',
      });
    }

    beforeMount() {
      this.initialEvents = this.syncedEvents;
      this.form = { ...this.automationDefinition };
      this.triggerOnConditions = !!this.automationDefinition?.rule_set_id;
      this.selectEventIdFromParams();
      this.preloadForEveryComponentData();
    }
  }
