
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { uniqBy } from 'lodash';
  import SidePanel from '@app/components/side-panel.vue';
  import EntitySelector from './entity-selector.vue';
  import type { PgDocument } from '@app/models/pg-document';
  import type { Relation, RelationItem } from '@app/models/relation';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { EntitySelector, DsModal, SidePanel } })
  export default class RelationPanel extends Vue {
    @Prop(Boolean) canEdit?: boolean;
    @Prop(String) entityType!: string;
    @Prop(Number) entityId!: number;

    relations: Relation[] = [];
    selectedRelations: PgDocument[] = [];

    modalVisible = false;

    get selectedRelationIds(): number[] {
      return this.selectedRelations.map((r) => r.id);
    }

    onSelect(selected: PgDocument): void {
      this.selectedRelations = [...this.selectedRelations, selected];
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }

    relatedRecord(relation: Relation): Maybe<RelationItem> {
      if (relation.from_type === this.entityType && relation.from_id === this.entityId) {
        return relation.to;
      } else {
        return relation.from;
      }
    }

    deleteRelation(relation: Relation): void {
      if (confirm(this.$t('tenant.relations.delete_this_relation') as string)) {
        this.$api.deleteRelation(relation.id).then(({}) => {
          this.fetchRelations();
        });
      }
    }

    submitForm(): void {
      const promises = this.selectedRelations.map((relation) => {
        const { searchable_id, searchable_type } = relation;
        return this.$api
          .createRelation({
            from_id: this.entityId,
            from_type: this.entityType,
            to_id: searchable_id,
            to_type: searchable_type,
          })
          .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
      });
      Promise.all(promises).then(() => {
        this.selectedRelations = [];
        this.toggleModal(false);
        this.$api.cache.clear();
        this.fetchRelations();
      });
    }

    fetchRelations(): void {
      this.$api
        .getRelations(
          {
            filters: {
              record_id: this.entityId,
              record_type: this.entityType,
            },
            include: ['from', 'to'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.relations = uniqBy(data, (r) =>
            [`${r.from_id}_${r.from_type}`, `${r.to_id}_${r.to_type}`].sort((a, b) => a.localeCompare(b)).join('-')
          );
        });
    }

    mounted(): void {
      this.fetchRelations();
    }
  }
