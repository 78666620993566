
  import ActivityShow from '@app/components/activities/activity-show.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { RawLocation } from 'vue-router';
  import type { ViewActivityPageActivityOnly } from './utils';
  import { VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY } from './utils';
  import type { SubmitCommentEvent } from '@app/services/api/comments-api';
  import type { Activity } from '@app/models/activity';
  import type { Comment } from '@app/models/comment';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActivityShow } })
  export default class ViewActivityPage extends Vue {
    activity: Nullable<Activity> = null;

    get activityId(): number {
      return parseInt(this.$route.params.activityId);
    }

    get defaultEditRoute(): RawLocation {
      return {
        name: 'activity-edit',
        params: { activityId: this.activityId.toString() },
        query: this.$route.query,
      };
    }

    get editRoute(): RawLocation {
      try {
        const queryParamRoute = this.$route.query.editRoute && JSON.parse(atob(this.$route.query.editRoute as string));
        return queryParamRoute || this.defaultEditRoute;
      } catch (e) {
        return this.defaultEditRoute;
      }
    }

    goToEdit(): void {
      this.$router.push(this.editRoute);
    }

    beforeMount(): void {
      this.$api
        .getActivity(this.activityId, { only: VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY, include: ['legacy'] }, { cache: true })
        .then(({ data }) => {
          this.activity = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
          this.closeModal('reload');
        });
    }

    closeActivity(data?: Partial<Pick<Comment, 'comment'> & { attachments?: string[] }>): Promise<void> {
      const closeData = {
        ...data,
        attachments: data?.attachments || [],
      };
      return this.$api
        .closeActivity(this.activityId, closeData)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    reopenActivity(activity: Activity, commentParams?: SubmitCommentEvent['data']): void {
      activity &&
        this.$api
          .openActivity(activity.id, { ...commentParams })
          .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
          .then(() => this.closeModal('reload'));
    }

    closeModal(reload?: ModalCloseCommand): void {
      this.$emit('close', reload);
    }

    approveActivity(activity: Pick<Activity, ViewActivityPageActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .approveActivity(activity.id, {
          ...commentParams,
          comment: commentParams?.comment,
          approved: true,
          only: VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY,
        })
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    unapproveActivity(activity: Pick<Activity, ViewActivityPageActivityOnly>, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .unapproveActivity(activity.id, {
          ...commentParams,
          comment: commentParams.comment,
          approved: false,
          only: VIEW_ACTIVITY_PAGE_ACTIVITY_ONLY,
        })
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }
  }
