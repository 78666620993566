
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import SvgInteractiveTemplate from '../../svg-template/svg-interactive-template.vue';
  import { ValidationProvider } from 'vee-validate';
  import type { SvgLibraryComponent } from '@app/models/svg-library/component';

  const VALID_COLOR = '#1abc9c';

  @Component({
    components: { SvgInteractiveTemplate, ValidationProvider },
  })
  export default class SvgComponentEditor extends Vue {
    @Prop() readonly svgContent?: string;
    @Model('change') readonly components!: SvgLibraryComponent[];

    selectedId = '';

    get ids(): string[] {
      return this.components.map((c) => c.path).sort();
    }

    get svgColors(): Record<string, string> {
      return this.components.reduce((acc, { path, code, label }) => {
        if (label?.length && code?.length) {
          acc[path] = VALID_COLOR;
        }
        return acc;
      }, {} as Record<string, string>);
    }

    get selectedComponent(): SvgLibraryComponent | undefined {
      return this.components.find((component) => component.path === this.selectedId);
    }

    mounted(): void {
      if (this.ids?.length) {
        this.selectedId = this.ids[0];
      }
    }

    @Emit('change')
    handleNameChange(label: string): SvgLibraryComponent[] {
      return this.components.map((component) => {
        return this.selectedId === component.path ? { ...component, label } : component;
      });
    }

    @Emit('change')
    handleCodeChange(value: string): SvgLibraryComponent[] {
      const code = value.replace(/[^a-z0-9-_]/g, '');
      return this.components.map((component) => {
        return this.selectedId === component.path ? { ...component, code } : component;
      });
    }

    handleSelectedIdChange(event: Event): void {
      if (event.target) {
        this.selectedId = (event.target as HTMLSelectElement).value;
      }
    }
  }
