
  import { round } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  type TargetData = { default?: string } & Record<string, number>;

  @Component({ components: {} })
  export default class ReportTargetForm extends Vue {
    // TODO: use @Model later
    @Prop(Object) readonly targetData!: TargetData;
    @Prop(Object) readonly graphData!: Record<string, number>;

    localTargets: TargetData = {};

    beforeMount(): void {
      this.localTargets = { ...this.targetData };
    }

    targetPercentage(key: string, value: number | string): string | undefined {
      const target = this.localTargets[key] || this.localTargets.default;
      if (target) {
        const decimalTarget = parseFloat(`${target}`);
        const decimalValue = parseFloat(`${value}`);
        const percentValue = round(100 * (decimalValue / decimalTarget));
        return `${percentValue}%`;
      }
    }
  }
