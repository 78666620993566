
  import { Component } from 'vue-property-decorator';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import bootbox from 'bootbox';
  import type { Attachment } from '@app/models/attachment';
  import type { ImageWidgetOptions } from '@app/models/widget';
  import { toaster } from '@app/utils/toaster';

  import AttachmentsUploader from '../../../attachment/attachments-uploader.vue';

  import BaseWidgetSettings from './base-widget-settings';

  type BaseWidgetSettingsOptions = Partial<ImageWidgetOptions & { files?: string[] }> & { attachments: string };

  @Component({ components: { AttachmentsUploader } })
  export default class ImageWidgetSettings extends BaseWidgetSettings<BaseWidgetSettingsOptions> {
    attachment: Nullable<Attachment> = null;
    blobLink = '';

    get imageLink(): string | undefined {
      return this.attachment?.url;
    }

    get imageSrc(): string {
      return this.imageLink || this.blobLink;
    }

    updateLinkUrl(): void {
      this.$emit('update:options', this.form);
    }

    beforeMount(): void {
      this.form = { ...this.form, link_url: this.widget?.options?.link_url ?? '', files: [] };
      this.fetchImageLink();
    }

    fetchImageLink(): void {
      this.widget.id &&
        this.$api
          .getAttachments({
            filters: { attachable_id: this.widget.id, attachable_type: 'Widget' },
            url_options: { expirable: true },
          })
          .then((response) => {
            this.attachment = response.data[0];
          });
    }

    createBlob(storageBlob: ActiveStorageBlob & { blob: Blob }): void {
      this.form = { ...this.form, files: [...(this.form.files || []), storageBlob.signed_id] };
      const urlCreator = window.URL || window.webkitURL;
      this.blobLink = urlCreator.createObjectURL(storageBlob.blob);
      this.$emit('update:options', this.form);
    }

    deleteBlob(): void {
      this.form = { ...this.form, attachments: '' };
      this.blobLink = '';
      this.$emit('update:options', this.form);
    }

    onDeleteImage(): void {
      bootbox.confirm({
        backdrop: false,
        title: 'Are you sure?',
        message: 'The image will be deleted',
        callback: (result: boolean) => {
          if (result) {
            if (this.attachment?.id) {
              this.$api
                .deleteAttachment(this.attachment.id)
                .then(() => {
                  this.attachment = null;
                  this.$emit('update:options', this.form);
                })
                .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
            } else {
              this.deleteBlob();
            }
          }
        },
      });
    }
  }
