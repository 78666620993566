
  import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: { BaseTable, FilterSelect, Select2 },
  })
  export default class ModuleConfidentialityTypesPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    @Ref() readonly table?: BaseTable<ConfidentialityType>;
    manager: Nullable<ListManager<ConfidentialityType>> = null;
    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
    getManager(): ListManager<ConfidentialityType> {
      return new ListManager<ConfidentialityType>({
        fetchDataFunction: (params) => {
          return this.$api.getConfidentialityTypes(
            {
              ...params,
              only: ['id', 'name', 'active', 'module_name'],
              filters: { ...params.filters, module_name_id: this.moduleNameId },
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'id', sortField: 'id' }],
        per_page: 25,
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name', filter: true },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
        ],
        allowFilters: true,
      });
    }
    mounted(): void {
      this.manager = this.getManager();
    }
  }
