import { render, staticRenderFns } from "./video-item.vue?vue&type=template&id=75f09c5b&scoped=true&lang=pug"
import script from "./video-item.vue?vue&type=script&lang=ts"
export * from "./video-item.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f09c5b",
  null
  
)

export default component.exports