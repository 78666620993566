
  import { Component, Prop } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';

  @Component
  export default class Restart extends mixins(Blocking) {
    @Prop(Number) installationId!: string;
    @Prop(Boolean) inProgress?: boolean;

    async restart(id: string): Promise<void> {
      await this.blocking(
        async () => {
          await this.$api.restartAppBundleInstallation(id);
          this.$emit('complete');
        },
        { exclusive: true }
      );
    }

    get disabled() {
      return !!(this.blocked || this.inProgress);
    }
  }
