import type { FilterOption } from '@app/components/admin/questions/filter-configurator/model';
import I18n from '@app/i18n';
import { groupBy, uniq } from 'lodash';
import type { ExpensableCategory } from '@app/models/expensable-category';
import type { ExpensableExpensingTable } from '@app/models/expensable-expensing-table';
import type { ModuleRecord } from '@app/models/module-record';
import type { Scheme } from '@app/models/scheme';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import { FieldType } from '@app/models/sub-form-question';
import type { DonesafeApi } from '@app/services/donesafe-api';
import { Tuple } from '@app/utils/types/tuple';

export type GeneratedFilterOption =
  | FilterOption
  | FilterOption<ModuleRecord>
  | FilterOption<Scheme>
  | FilterOption<ExpensableExpensingTable>
  | FilterOption<ExpensableCategory>;

export const generateQuestionFilters = (
  questions: SubFormQuestion[],
  $api: DonesafeApi,
  currentQuestion?: SubFormQuestion
): GeneratedFilterOption[] => {
  const groupedQuestion = groupBy(questions, 'code');
  return Object.keys(groupedQuestion).reduce<GeneratedFilterOption[]>((memo, code) => {
    const questions = groupedQuestion[code];
    if (currentQuestion?.code === code) {
      return memo;
    }
    const label = uniq(questions.map((q) => q.title)).join(' | ');
    const firstQuestion = questions[0];
    switch (firstQuestion.field_type) {
      case FieldType.main_form_relation:
      case FieldType.multi_main_form_relation:
        return memo.concat({
          type: 'record',
          multiple: true,
          allowNull: true,
          key: code,
          label: label,
          filters: {
            module_name_id: firstQuestion.config.main_form_id,
          },
        } as FilterOption<ModuleRecord>);
      case FieldType.location:
        return memo.concat({
          type: 'location',
          multiple: true,
          allowNull: true,
          key: code,
          label: label,
        });
      case FieldType.scheme:
        return memo.concat({
          type: 'entity',
          multiple: true,
          allowNull: true,
          labelKey: 'name',
          valueKey: 'id',
          only: ['id', 'name'],
          method: $api.getSchemes,
          key: code,
          label: label,
        });
      case FieldType.expense_budget_select:
        return memo.concat({
          type: 'entity',
          // allowNull: true,
          multiple: true,
          labelKey: 'name',
          valueKey: 'name',
          only: ['name'],
          method: $api.getExpensableExpensingTables,
          filters: { active: true },
          key: code,
          label: label,
        } as FilterOption<ExpensableExpensingTable>);
      case FieldType.expense_budget_category_select:
        return memo.concat({
          type: 'entity',
          // allowNull: true,
          multiple: true,
          filters: { active: true },
          labelKey: 'name',
          valueKey: 'code',
          only: ['code', 'name'],
          method: $api.getExpensableCategories,
          key: code,
          label: label,
        } as FilterOption<ExpensableCategory>);
      case FieldType.date:
        return memo.concat({
          type: 'date',
          label: label,
          key: code,
          options: [
            { key: 'week', value: 'This Week' },
            { key: 'today', value: 'Today' },
            { key: 'month', value: 'This Month' },
            { key: 'year', value: 'Within the past year' },
            { key: 'calendar_year', value: 'Within this calendar year' },
          ],
          invertDisabled: true,
        });
      case FieldType.datetime:
        return memo.concat({
          type: 'datetime',
          label: label,
          key: code,
          options: [
            { key: 'week', value: 'This Week' },
            { key: 'today', value: 'Today' },
            { key: 'month', value: 'This Month' },
            { key: 'year', value: 'Within the past year' },
            { key: 'calendar_year', value: 'Within this calendar year' },
          ],
          invertDisabled: true,
        });
      case FieldType.organization:
        return memo.concat({
          type: 'organization',
          multiple: true,
          allowNull: true,
          key: code,
          label: label,
        });
      case FieldType.single_person_selector:
      case FieldType.multi_person_selector:
        return memo.concat({
          allowNull: true,
          type: 'user',
          multiple: true,
          key: code,
          label: label,
        });
      case FieldType.calculation_select:
      case FieldType.button_select:
      case FieldType.single_select:
      case FieldType.radio:
      case FieldType.multi_select:
      case FieldType.multi_checkbox:
        const groupedOptions = questions
          .reduce<{ key: string; value: string }[]>((memo, question) => {
            const historicalOptions = Object.values(question.options.historical_values || {}).reduce(
              (m, v) => ({ ...m, [v.key]: I18n.t('app.labels.archived_name', { name: v.value }).toString() }),
              {}
            );
            const finalQuestionOptions = Object.values(question.options.values || {}).reduce(
              (m, v) => ({ ...m, [v.key]: v.value }),
              historicalOptions as Record<string, string>
            );
            return memo.concat(Object.keys(finalQuestionOptions).map((key) => ({ key, value: finalQuestionOptions[key] })));
          }, [])
          .reduce(
            (memo, option) => ({ ...memo, [option.key]: (memo[option.key] || []).concat(option.value) }),
            {} as Record<string, string[]>
          );

        const options = Object.keys(groupedOptions).map((key) => ({ key, value: groupedOptions[key].join(' | ') }));
        return memo.concat({
          type: 'select',
          multiple: true,
          allowNull: true,
          key: code,
          label: label,
          options,
        });
    }
    return memo;
  }, []);
};

export const WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY = Tuple([
  'id',
  'display',
  'plural_display',
  'involvements',
  'feature_set',
  'indicator_sets',
  'from_relationships',
  'to_relationships',
  { main_form: ['id', { sub_form_sections: ['sub_form_questions'] }] },
] as const);
