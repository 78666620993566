
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import Select2 from '../select2.vue';
  import ScoringPanel from './scoring-panel.vue';
  import { groupBy } from 'lodash';
  import type { SubForm } from '@app/models/sub-form';
  import { ScoringType, ScoringViewOption, SubFormType } from '@app/models/sub-form';
  import type { ModuleTab } from '@app/models/module-tab';
  import type { ModuleName } from '@app/models/module-name';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';

  @Component({ components: { DsCheckbox, EntitySelector, Select2, ScoringPanel, ValidationObserver, ValidationProvider } })
  export default class SubFormSettings extends Vue {
    @Prop(Object) readonly subForm?: Partial<SubForm>;
    @Prop(Object) readonly moduleName!: Partial<ModuleName>;
    @Prop({ type: [Number, String] }) readonly moduleTabId!: number | string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<SubForm> = {
      scoring_type: ScoringType.addition,
      free_navigation: false,
      scoring_view_option: ScoringViewOption.number,
      module_name: this.moduleName.name,
    };

    moduleTab: Nullable<ModuleTab> = null;

    get showScoringTypeSelector(): boolean {
      return this.subForm?.scoring_type === ScoringType.multiplication;
    }

    get isNormal(): boolean {
      return !this.isWorkflow && !this.isApproval;
    }

    get isHybrid(): boolean {
      return !!this.moduleTab?.hybrid_module || !!this.subForm?.sub_form_lists?.some((l) => l?.module_tab?.hybrid_module);
    }

    get isWorkflow(): boolean {
      return this.subForm?.sub_form_type === SubFormType.workflow;
    }

    get isApproval(): boolean {
      return this.subForm?.sub_form_type === SubFormType.approval;
    }

    get subFormQuestionOptions(): string[][] {
      const questions = this.form?.sub_form_sections?.map((s) => s?.sub_form_questions)?.flat() || [];
      const groupedQuestions = groupBy(questions, 'code');
      return Object.keys(groupedQuestions).map((code) => [code, `${groupedQuestions[code].map((q) => q?.title).join(', ')} [${code}]`]);
    }

    get noCompleteCodesSelected(): boolean {
      return !this.form?.options?.question_codes_locked_in_complete_state?.length;
    }

    get noDraftCodesSelected(): boolean {
      return !this.form?.options?.question_codes_locked_in_draft_state?.length;
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result &&
          this.$emit('submit', {
            ...this.form,
            score_band_profile_id: this.form.score_band_profile_id ?? null,
            scoring_group_id: this.form.scoring_group_id ?? null,
            options: {
              ...this.form.options!,
              question_codes_locked_in_complete_state: this.noCompleteCodesSelected
                ? []
                : this.form.options?.question_codes_locked_in_complete_state,
              question_codes_locked_in_draft_state: this.noDraftCodesSelected
                ? []
                : this.form.options?.question_codes_locked_in_draft_state,
            },
          });
      });
    }

    beforeMount(): void {
      this.moduleTabId &&
        this.$api.getModuleTab(Number(this.moduleTabId), { only: ['id', 'hybrid_module'] }, { cache: true }).then(({ data }) => {
          this.moduleTab = data;
        });
      this.form = { ...this.form, ...this.subForm };
    }
  }
