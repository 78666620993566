import { DEFAULT_QUERY_BUILDER_BLOB, DEFAULT_QUERY_BUILDER_RULE } from '@app/models/query-builder-blob';
import type { DebugQueryBuilderGroupRule, QueryBuilderBlob, QueryBuilderGroupRule } from '@app/models/query-builder-blob';
import type { DraggableChangeEvent } from '@app/components/admin/sub-forms/utils';
import type { Observable } from 'rxjs';
import { combineLatest, map, of } from 'rxjs';

import { DsRuleNode } from './ds-rule-node';
import type { NodeConfig } from './ds-node';
import { DsNode } from './ds-node';
import { Collapsible } from './collapsible';
import { ClampedState } from './clamped-state';
import type { ClampedType } from './clamped-state';
import { ExpandedItems } from './expanded';

interface DsGroupNodeConfig extends NodeConfig {
  condition: 'AND' | 'OR';
  label?: string;
  level: number;
  pass?: boolean;
  rules: (QueryBuilderGroupRule | DebugQueryBuilderGroupRule)[];
}
export const DEFAULT_GROUP_LABEL = 'Rule Group';

export class DsGroupNode extends DsNode {
  condition: 'AND' | 'OR';
  rules: (DsGroupNode | DsRuleNode)[];
  level = 0;
  label: string;
  pass?: boolean;

  private collapsible: Collapsible;
  private clampedState: ClampedState;
  private expandedItems: ExpandedItems;
  private clamped = false;

  constructor({ condition, rules, filters, parent, level, label, pass }: DsGroupNodeConfig) {
    super({ filters, parent });
    this.level = level;

    this.condition = condition;
    this.label = label || DEFAULT_GROUP_LABEL;
    this.pass = pass;
    this.rules = rules.map((rule) =>
      'rules' in rule
        ? new DsGroupNode({
            condition: rule.condition,
            rules: rule.rules,
            filters,
            parent: this,
            level: this.level + 1,
            label: rule.label,
            ...('pass' in rule ? { pass: rule?.pass } : {}),
          })
        : new DsRuleNode({ rule, filters, parent: this })
    );

    this.collapsible = new Collapsible();
    this.clampedState = new ClampedState();
    this.expandedItems = new ExpandedItems();

    this.subs.push(this.isClamped$.subscribe(this.onGroupClampedChange));
  }

  get expanded() {
    return this.collapsible.isExpanded();
  }

  get expanded$(): Observable<boolean> {
    return this.collapsible.expandedChanges$;
  }

  get expandedItems$(): Observable<boolean> {
    return this.expandedItems.expandedItems$;
  }

  get isClamped$(): Observable<boolean> {
    return combineLatest([...this.rules.map((rule) => rule.isClamped$.pipe()), this.clampedState.clampedState$]).pipe(
      map((clampedStates) => clampedStates.some((state) => state))
    );
  }

  get isExpandedShown$() {
    const childrenExpanded$ = combineLatest(this.rules.map((rule) => ('expanded$' in rule ? rule.expanded$ : of(false))));
    const expandedItems$ = combineLatest([this.expandedItems$, ...this.rules.map((rule) => rule.expandedItems$)]);

    return combineLatest([this.isClamped$, childrenExpanded$, this.expanded$, expandedItems$]).pipe(
      map(([clamped, childrenExpanded, groupExpanded, expandedItems$]) => {
        return groupExpanded || clamped || childrenExpanded.some((state) => state) || expandedItems$.some((state) => state);
      })
    );
  }

  addRule(top = false) {
    const rule = DEFAULT_QUERY_BUILDER_RULE;

    const newRule = new DsRuleNode({ rule: rule, parent: this, filters: this.filters });
    top ? this.rules.unshift(newRule) : this.rules.push(newRule);

    return newRule;
  }

  addGroup(top = false) {
    const group = DEFAULT_QUERY_BUILDER_BLOB;
    const newGroup = new DsGroupNode({
      condition: group.condition,
      rules: group.rules,
      parent: this,
      filters: this.filters,
      level: this.level + 1,
      label: group.label,
    });

    top ? this.rules.unshift(newGroup) : this.rules.push(newGroup);

    return newGroup;
  }

  onGroupClampedChange = (clamped: boolean) => {
    this.clamped = clamped;
  };

  removeRuleOrGroup(localId: number) {
    const index = this.rules.findIndex((rule) => rule.localId === localId);
    if (index > -1) {
      this.rules.splice(index, 1);
    }
  }

  updateCondition(condition: 'AND' | 'OR') {
    this.condition = condition;
  }

  prepareForSave(): QueryBuilderBlob {
    return {
      condition: this.condition,
      rules: this.rules.map((rule) => rule.prepareForSave()),
      label: this.label,
    };
  }

  updateLabel(label: string) {
    this.label = label;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragChange = (source: DraggableChangeEvent<DsGroupNode | DsRuleNode>, target: DraggableChangeEvent<DsGroupNode | DsRuleNode>) => {
    if ('removed' in source && this.rules?.length === 0) {
      this.parent?.removeRuleOrGroup(this.localId);
    }

    if ('added' in source && !!source.added?.element) {
      source.added.element.parent = this;
      if ('level' in source.added.element) source.added.element.level = this.level + 1;
    }
  };

  toggleExpanded() {
    this.rules.forEach((groupOrRule) => {
      if ('toggleExpanded' in groupOrRule) groupOrRule.toggleExpanded();
    });
    this.collapsible.toggle();
  }

  setExpanded(expanded: boolean) {
    this.rules.forEach((groupOrRule) => {
      if ('setExpanded' in groupOrRule) groupOrRule.setExpanded(expanded);
    });

    if (!this.expanded && !this.clamped) return;
    this.collapsible.setExpanded(expanded);
  }

  toggleClamped(value: ClampedType, clamped: boolean) {
    if (clamped) this.clampedState?.setClampedValues(value);
    else this.clampedState?.removeClampedValues(value);
  }

  setExpandedItems(value: ClampedType, expanded: boolean) {
    if (expanded) this.expandedItems?.setExpandedValues(value);
    else this.expandedItems?.removeExpandedValues(value);
  }

  destroy() {
    this.rules.forEach((rule) => rule.cleanup());
  }
}
