
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import UserCollectionSelector from '../user-collections/user-collection-selector.vue';
  import { omit } from 'lodash';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import RuleRestrictionsSelector from '@app/components/admin/rule-sets/rule-restrictions-selector.vue';
  import type { RuleSet } from '@app/models/rule-set';
  import type { WorkflowLink } from '@app/models/workflow-link';

  @Component({
    components: { Select2, UserCollectionSelector, RuleRestrictionsSelector },
  })
  export default class WorkflowLinkForm extends Vue {
    @Prop(Object) readonly workflowLink!: WorkflowLink;
    @Prop(String) readonly conceptName?: string;
    @Prop(Boolean) readonly withRestrictionRules?: boolean;

    form: Nullable<Partial<WorkflowLink & { rule_set: Nullable<Partial<RuleSet>> }>> = {};
    selectorKey = 0;

    get triggeringInjectableArguments(): Partial<InjectableArguments> {
      return { concept_name: this.conceptName };
    }

    async beforeMount(): Promise<void> {
      this.initForm();
      if (this.workflowLink.user_collection_id) {
        const { data } = await this.$api.getUserCollection(
          this.workflowLink.user_collection_id,
          { only: ['id', 'name', 'global', { rule_set: ['rules_description', 'query_builder_blob'] }] },
          { cache: true }
        );
        this.form = { ...this.form, user_collection: { ...data, rule_set: data.rule_set } };
        this.selectorKey++;
      }
    }

    initForm(): void {
      this.form = {
        ...this.workflowLink,
        rule_set: (this.workflowLink?.rule_set || {}) as RuleSet,
      };
    }

    redirect(): void {
      window.location.reload();
    }

    submit(): void {
      this.$emit('submit', this.formForSubmit);
    }

    get formForSubmit(): Partial<WorkflowLink> {
      if (!this.form?.user_collection) return this.form as Partial<WorkflowLink>;

      return this.form?.user_collection?.global
        ? omit({ ...this.form, user_collection_id: this.form.user_collection.id }, 'user_collection')
        : omit(this.form, 'user_collection_id');
    }
  }
