
  import { useAccountStore } from '@app/stores/account';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import { difference, groupBy, mapValues, range, union, uniq } from 'lodash';
  import { ValidationObserver } from 'vee-validate';

  import { Component, Prop, Ref } from 'vue-property-decorator';
  import ActionRoleTable from './action-role-table.vue';
  import IntegratedModulesRoleSettings from './integrated-modules-role-settings.vue';
  import ModuleNameRoleTable from './module-name-role-table.vue';
  import UserProfileRights from './user-profile-rights.vue';
  import type { CustomRightsForm, RoleTabDetailsModuleNameOnly, RoleTabDetailsRoleOnly } from './utils';
  import { RTB_MODULE_NAME_ONLY, RTB_PERMISSIONS_ONLY } from './utils';
  import type { Permission } from '@app/models/permission';
  import { PermissionAccess } from '@app/models/permission';
  import WithDetectRoleChanges from './with-detect-role-changes';
  import type { ModuleName } from '@app/models/module-name';
  import type { Role } from '@app/models/role';
  import { GlobalAccess, RecordActivityExtendedPermissions, SpecialPermissionName } from '@app/models/extended-permission';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import DashboardSettings from './dashboard-settings.vue';
  import SettingsSection from './settings-section.vue';
  import FormField from '../../questions/edit/form-field.vue';

  @Component({
    components: {
      ActionRoleTable,
      DsCheckbox,
      DsTextInput,
      EntitySelector,
      IntegratedModulesRoleSettings,
      ModuleNameRoleTable,
      Select2,
      UserProfileRights,
      ValidationObserver,
      DashboardSettings,
      SettingsSection,
      FormField,
    },
  })
  export default class RoleTabDetailsForm extends WithDetectRoleChanges {
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;
    @Prop(Boolean) readonly submitting?: boolean;

    moduleNames: Pick<ModuleName, RoleTabDetailsModuleNameOnly>[] = [];
    dashboardPaneIdsByModuleName: Record<number | 'null', number[]> = { null: [] };

    get accountStore() {
      return useAccountStore();
    }

    get yesNoOptions(): [boolean, string][] {
      return [
        [true, this.$t('app.labels.yes')],
        [false, this.$t('app.labels.no')],
      ];
    }

    get showForm() {
      return !!Object.keys(this.form).length;
    }

    get hasIntegratedModules(): boolean {
      return this.moduleNames.some((mn) => mn.module_type === 'integrated');
    }

    get managerialHierarchyAccessLevelsOptions(): [number | 'null', string][] {
      return [
        ['null', this.$t('tenant.admin.roles.rtb.form.complete_access')],
        [0, this.$t('tenant.admin.roles.rtb.form.no_access_below')],
        ...range(5).map((x) => [x + 1, this.$t('tenant.admin.roles.rtb.form.level_access_below', { level: x + 1 })] as [number, string]),
      ];
    }

    get validManagerialHierarchyAccessLevel(): string | number {
      if (this.form.managerial_hierarchy_access_level === null) {
        return 'null';
      } else {
        return this.form.managerial_hierarchy_access_level?.toString() || 0;
      }
    }

    onManagerialHierarchyAccessLevelChange(value: string): void {
      if (value === 'null') {
        this.form.managerial_hierarchy_access_level = null;
      } else {
        this.form.managerial_hierarchy_access_level = Number(value) || 0;
      }
    }

    onModulePermissionsUpdate(params: CustomRightsForm): void {
      const {
        concept_name,
        role_profile,
        configuration_type,
        permissions,
        extended_permissions,
        managerial_hierarchy_access,
        dashboard_pane_ids: form_dashboard_pane_ids,
        excluded_dashboard_pane_ids: form_excluded_dashboard_pane_ids,
      } = params;

      const moduleName = this.moduleNames.find((moduleName) => moduleName.name === concept_name);

      const role_module_names = moduleName?.id
        ? [
            ...(this.form.role_module_names?.filter((rmn) => moduleName?.id !== rmn.module_name_id) || []),
            {
              module_name_id: moduleName?.id,
              role_id: this.role?.id,
              role_profile_id: role_profile?.id || null,
              configuration_type: configuration_type,
              managerial_hierarchy_access: managerial_hierarchy_access,
            },
          ]
        : undefined;

      const moduleDashboardPaneIds = moduleName?.id ? this.dashboardPaneIdsByModuleName[moduleName.id] : [];

      const dashboard_pane_ids = uniq(union(difference(this.form.dashboard_pane_ids, moduleDashboardPaneIds), form_dashboard_pane_ids));
      const excluded_dashboard_pane_ids = uniq(
        union(difference(this.form.excluded_dashboard_pane_ids, moduleDashboardPaneIds), form_excluded_dashboard_pane_ids)
      );

      this.form = {
        ...this.form,
        permissions: [...(this.form.permissions?.filter((p) => concept_name !== p.concept_name) || []), ...(permissions || [])],
        extended_permissions: [
          ...(this.form.extended_permissions?.filter((p) => concept_name !== p.concept_name) || []),
          ...(extended_permissions || []),
        ],
        role_profiles: [
          ...(this.form.role_profiles?.filter((p) => concept_name !== p.module_name?.name) || []),
          ...(!!role_profile ? [role_profile] : []),
        ],
        dashboard_pane_ids,
        excluded_dashboard_pane_ids,
        role_module_names,
      };
    }

    onActionPermissionsUpdate(params: CustomRightsForm): void {
      const { concept_name, permissions, extended_permissions } = params;
      this.form = {
        ...this.form,
        permissions: [...(this.form.permissions?.filter((p) => concept_name !== p.concept_name) || []), ...(permissions || [])],
        extended_permissions: [
          ...(this.form.extended_permissions?.filter((p) => concept_name !== p.concept_name) || []),
          ...(extended_permissions || []),
        ],
      };
    }

    updateExtendedPermissions(params: { concept_name: string; name: string; type: 'remove' | 'add' }) {
      const { concept_name, type, name } = params;

      if (type === 'add') {
        this.form = {
          ...this.form,
          extended_permissions: [...(this.form.extended_permissions || []), { concept_name, name }],
        };
      } else {
        this.form = {
          ...this.form,
          extended_permissions: (this.form.extended_permissions || []).filter(
            (ep) => !(ep.concept_name === concept_name && ep.name === name)
          ),
        };
      }
    }

    submitForm(): void {
      this.validator.validate().then((result) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    async initForm(role: Nullable<Pick<Role, RoleTabDetailsRoleOnly>>): Promise<void> {
      if (role) {
        const { data: modulePanes } = await this.$api.getDashboardPanes({
          only: ['id', 'module_name_id'],
          per_page: -1,
        });

        this.dashboardPaneIdsByModuleName = {
          null: [],
          ...mapValues(groupBy(modulePanes, 'module_name_id'), (arr) => arr.map(({ id }) => id)),
        };

        this.form = {
          ...role,
          extended_permissions: role.extended_permissions?.map(({ concept_name, name }) => ({ concept_name, name })),
          managerial_hierarchy_access_level: role.managerial_hierarchy_access_level,
          user_directory_access: role.user_directory_access || false,
          role_module_names: role.role_module_names || [],
        };
      } else {
        const defaultRecordActionPermissions = this.moduleNames
          .map((moduleName) => Object.values(RecordActivityExtendedPermissions).map((name) => ({ concept_name: moduleName.name, name })))
          .flat();
        const defaultActionSpecialPermissions = [
          { concept_name: ACTIVITY_CONCEPT, name: SpecialPermissionName.standalone_activity_creation },
          { concept_name: ACTIVITY_CONCEPT, name: SpecialPermissionName.edit_activity_content },
          { concept_name: ACTIVITY_CONCEPT, name: GlobalAccess.Create },
        ];

        this.defaultNewForm = {
          managerial_hierarchy_access_level: 0,
          user_directory_access: false,
          profile_permissions: {
            self: {
              tags: '',
              basic: 'view',
              learning: '',
              documents: '',
              pay_details: '',
              permission_based: '',
              personal_details: '',
              notification_subscriptions: '',
            },
            others: {
              tags: '',
              basic: '',
              learning: '',
              documents: '',
              collection: '',
              pay_details: '',
              permission_based: '',
              personal_details: '',
              notification_subscriptions: '',
            },
          },
          extended_permissions: [...defaultRecordActionPermissions, ...defaultActionSpecialPermissions],
          permissions: await this.fetchDefaultActivityPermissions(),
        };
        this.form = { ...this.defaultNewForm };
      }
    }

    async fetchModuleNames(): Promise<void> {
      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          only: RTB_MODULE_NAME_ONLY,
          per_page: -1,
          show_all: true,
          filters: {
            active: true,
          },
        },
        {
          cache: true,
        }
      );
      this.moduleNames = moduleNames;
    }

    async fetchDefaultActivityPermissions(): Promise<Permission[]> {
      const { data: permissionsForModule } = await this.$api.getPermissions(
        {
          filters: {
            concept_name: ACTIVITY_CONCEPT,
            relationship: ['approved_by', 'assigned_approver'],
            access: [PermissionAccess.view_access, PermissionAccess.edit_access],
          },
          only: RTB_PERMISSIONS_ONLY,
        },
        { cache: true }
      );
      return permissionsForModule;
    }

    async beforeMount(): Promise<void> {
      await this.fetchModuleNames();
      await this.fetchDefaultActivityPermissions();
      await this.initForm(this.role);
    }
  }
