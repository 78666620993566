
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import SearchInput from '@app/components/search-input.vue';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import { userEditLink } from '@app/components/paper-trails/utils';
  import { API_NULL } from '@app/constants';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      BaseTable,
      SearchInput,
      Select2,
      FilterSelect,
    },
  })
  export default class UserProfilePermission extends Vue {
    @Ref() readonly table?: BaseTable<TenantUser>;

    manager: Nullable<ListManager<TenantUser>> = null;
    activeOptions = [
      [true, 'Active'],
      [false, 'Inactive'],
    ];
    userTypeOptions = [
      ['TenantUser', 'Tenant'],
      ['ContractorUser', 'Contractor'],
      ['VisitorUser', 'Visitor'],
      ['ContactUser', 'Contact'],
      ['MedicalUser', 'Medical'],
    ];
    authOptions = [
      ['true', 'Disabled'],
      ['false', 'Enabled'],
      [API_NULL, 'Default'],
    ];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }

    userEditLink(user: TenantUser): Nullable<string> {
      return userEditLink(user, this.currentUserStore.data) || null;
    }

    getManager(): ListManager<TenantUser, ExtraUserApiOptions> {
      return new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params) =>
          this.$api.getTenantUsers({ ...params, only: ['id', 'full_name', 'type', 'active', 'restrictions'] }, { cache: true }),
        customFilters: { search: '' },
        // useHistory: true,
        per_page: 10,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
          { title: this.$t('app.labels.name'), name: 'full_name', sortField: 'full_name' },
          { title: this.$t('app.labels.type'), name: 'type', filter: true, sortField: 'type' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
          { title: 'Password', name: 'restriction_auth_email', filter: true },
          { title: 'SSO', name: 'restriction_auth_saml', filter: true },
        ],
        allowFilters: true,
      });
    }

    restrictionValue(user: TenantUser, restriction: 'email' | 'saml'): StringBoolean | typeof API_NULL {
      const userRestriction = user.restrictions?.find((r) => r.restriction === restriction && r.action === 'auth');
      return userRestriction ? (`${userRestriction.restrict}` as StringBoolean) : API_NULL;
    }

    async restrictionUpdate(user: TenantUser, restriction: 'email' | 'saml', restrict: StringBoolean | typeof API_NULL): Promise<void> {
      const userRestriction = user.restrictions?.find((r) => r.restriction === restriction && r.action === 'auth');
      try {
        if (userRestriction) {
          if (restrict === API_NULL) {
            await this.$api.deleteRestriction(userRestriction.id);
          } else {
            await this.$api.updateRestriction(userRestriction.id, { restrict });
          }
        } else if (restrict !== API_NULL) {
          await this.$api.createRestriction({
            action: 'auth',
            restriction,
            user_id: user.id,
            restrict,
          });
        }

        this.$api.cache.clear();
        this.table?.reload();

        toaster('Successfully updated');
      } catch (err) {
        console.error(err);
        toaster({ text: 'Update failed', position: 'top-right', icon: 'error' });
      }
    }

    getTitle(userType: string): string {
      // TODO: use mapping
      return userType.replace('User', '');
    }
  }
