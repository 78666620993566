
  import { Tooltip } from 'uiv';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsLabel from './ds-label.vue';

  @Component({
    components: {
      Tooltip,
      DsLabel,
    },
  })
  export default class FallbackBadge extends Vue {
    @Prop(String) readonly tooltip!: string;
    @Prop(String) readonly appendTo?: string;
  }
