
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import RuleBuilder from '@app/components/rule-builder/rule-builder.vue';
  import { DEFAULT_DISPLAY_SETTINGS } from '@app/components/rule-builder/models/types';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { CalculatorQuestionOptions } from '@app/models/question-options/calculator-question-options';
  import Select2 from '@app/components/select2.vue';

  @Component({ components: { Select2, DsCheckbox, RuleBuilder } })
  export default class CalculatorFieldOptions extends Vue {
    @Prop(Object) value!: SubFormQuestion<Partial<CalculatorQuestionOptions>>;
    @Prop(Object) subForm!: SubForm;

    form: Partial<CalculatorQuestionOptions> = {};

    calcValueSubFormQuestions!: SubFormQuestion[];
    loaded = false;
    initialDisplayShowHideHandling = false;

    get logicSetId(): Nullable<string> {
      return this.value.config?.logic_set_id || null;
    }

    updateConfig(config: CalculatorQuestionOptions): void {
      this.form = config;
      this.initialDisplayShowHideHandling = config.legacy_show_hide_handling === 'true';
      this.$$patch(this.value.config, config);
    }

    async beforeMount(): Promise<void> {
      const { data: calcValueSubFormQuestions } = await this.$api.getSubFormQuestions(
        {
          filters: {
            active: true,
            '!id': this.value.id,
            sub_form_section: {
              sub_form_id: this.subForm.id,
            },
          },
          per_page: -1,
        },
        { cache: true }
      );
      this.initialDisplayShowHideHandling = this.value.config.legacy_show_hide_handling === 'true';

      this.calcValueSubFormQuestions = calcValueSubFormQuestions;

      this.$$patch(
        this.form,
        {
          variables_mapping: '{}',
          configs: '{}',
          items_to_display: '[]',
          display_settings: DEFAULT_DISPLAY_SETTINGS,
          preserve_quotes: 'false',
          ...this.value.config,
        },
        { ifEmpty: true }
      );

      this.loaded = true;
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['variables_mapping', 'configs', 'items_to_display', 'display_settings', 'preserve_quotes'].forEach((key) =>
        this.$delete(this.value.config, key)
      );
    }

    get missingVariableHandlingOptions(): string[][] {
      return [['zero', this.$t('tenant.admin.sub_form_questions.form_fields.edit.calculator_field.attempt_calculation')]];
    }
  }
