
  import { Component, Vue } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleName } from '@app/models/module-name';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface AvailableUserFieldToUpdate {
    field_type?: 'TenantUser' | 'Location' | 'Organization' | 'Concept';
    key: string;
    label: string;
  }

  @Component({
    components: {
      EntitySelector,
      EventInputMethodSwitch,
      Select2,
      AdvancedAutosuggest,
      FormField,
    },
  })
  export default class UpdateUserEventForm extends BaseEventForm<Dictionary<string>> {
    relatedModuleNames: ModuleName[] = [];
    newField = '';

    userTypes = [
      ['TenantUser', 'Tenant'],
      ['ContactUser', 'Contact'],
      ['VisitorUser', 'Visitor'],
      ['ContractorUser', 'Contractor'],
      ['MedicalUser', 'Medical'],
    ];

    get activeOptions(): [string, string][] {
      return [
        ['true', 'Active'],
        ['false', 'Inactive'],
      ];
    }

    get confirmationHandlingOptions(): [string, string][] {
      return [
        ['send_confirmation_email', this.$t('app.labels.send_confirmation_email')],
        ['confirm', this.$t('app.labels.confirm_account')],
      ];
    }

    get stillAvailableFields(): AvailableUserFieldToUpdate[] {
      return this.availableFields
        .filter((field) => {
          if (!this.accountStore.data.limit_permissions_by_location && field.key === 'location_ceiling_id') {
            return false;
          } else if (!this.accountStore.data.limit_permissions_by_organization && field.key === 'organization_ceiling_id') {
            return false;
          }
          return true;
        })
        .filter((field) => !this.isFieldSelected(field));
    }

    get selectedFields(): AvailableUserFieldToUpdate[] {
      return this.availableFields.filter((field) => this.isFieldSelected(field));
    }

    get availableFields(): AvailableUserFieldToUpdate[] {
      return [
        { key: 'confirmation_handling', label: this.$t('app.labels.confirmation_handling') },
        { key: 'active', label: this.$t('app.labels.user_active_status') },
        { key: 'role_id', label: this.$t('app.labels.role') },
        { key: 'type', label: this.$t('app.labels.user_type') },
        { key: 'manager_id', label: this.$t('app.labels.manager'), field_type: 'TenantUser' },
        { key: 'home_location_id', label: this.$t('app.labels.home_location'), field_type: 'Location' },
        { key: 'home_organization_id', label: this.$t('app.labels.home_organization'), field_type: 'Organization' },
        { key: 'location_ceiling_id', label: this.$t('app.labels.location_ceiling'), field_type: 'Location' },
        {
          key: 'organization_ceiling_id',
          label: this.$t('app.labels.organization_ceiling'),
          field_type: 'Organization',
        },
        ...this.relatedModuleNames.map((moduleName) => {
          return {
            key: `related_module_records_${moduleName.id}`,
            label: `Related Record: ${moduleName.display}`,
            field_type: 'Concept',
          } as AvailableUserFieldToUpdate;
        }),
      ];
    }

    isFieldSelected(field: AvailableUserFieldToUpdate): boolean {
      return this.form.hasOwnProperty(field.key);
    }

    onNewFieldSelect(key: string): void {
      this.newField = '';
      this.form = { ...this.form, [key]: '' };
    }

    removeField(field: AvailableUserFieldToUpdate): void {
      Vue.delete(this.form, field.key);
    }

    getRelatedModules(): Promise<void> {
      return this.$api
        .getModuleNames(
          {
            filters: { active: true, feature_set: { user_related: true } },
            only: ['id', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.relatedModuleNames = data;
          this.fetchSavedModules();
        });
    }

    fetchSavedModules(): void {
      const prefix = 'related_module_records_';

      let currentModuleNames = this.relatedModuleNames.map((moduleName) => moduleName.id);

      let savedModuleIds = Object.keys(this.form).reduce((memo: number[], key) => {
        if (key.startsWith(prefix)) {
          let moduleNameId = parseInt(key.substring(prefix.length));

          if (currentModuleNames.includes(moduleNameId)) {
            return memo;
          }

          return [...memo, moduleNameId];
        }

        return memo;
      }, []);

      if (savedModuleIds.length) {
        this.querySavedModels(savedModuleIds);
      }
    }

    querySavedModels(savedModuleIds: number[]): Promise<void> {
      return this.$api
        .getModuleNames(
          {
            filters: { id: savedModuleIds },
            only: ['id', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.relatedModuleNames = [...this.relatedModuleNames, ...data];
        });
    }

    beforeMount(): void {
      this.form = { ...this.values };
      this.getRelatedModules();
    }
  }
