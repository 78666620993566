
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import TemplateItems from '../components/template-items.vue';
  import ElementItem from '../components/element-item.vue';
  import { capitalize } from 'lodash';
  import Draggable from 'vuedraggable';
  import type { Components, DisplayItemValue, Formula, ParentRule, Rules, Variables, ElementType } from '../models/types';
  import { LogicElementTypes } from '../models/types';

  @Component({ components: { TemplateItems, ElementItem, Draggable } })
  export default class DisplayItems extends Vue {
    @Prop({ required: true }) readonly parentRule!: ParentRule;
    @Prop({ required: true }) readonly rules!: Rules;
    @Prop({ required: true }) readonly components!: Components;
    @Prop({ required: true }) readonly variables!: Variables;
    @Prop({ required: true }) readonly displayItems!: DisplayItemValue[];
    @Prop({ required: true }) readonly getElementType!: (logicElementValue: string | Formula) => ElementType;
    @Prop({ required: true }) readonly toggleDisplayItem!: (elementId: DisplayItemValue) => void;

    capitalize = capitalize;

    checkIsDisplayItem(elementId: DisplayItemValue): boolean {
      return this.displayItems.indexOf(elementId) !== -1;
    }
    getElementsObject(elementId: DisplayItemValue): Rules | Components | Variables | null {
      const elementType = this.getElementType(elementId);

      switch (elementType) {
        case LogicElementTypes.COMPONENT:
          return this.components;
        case LogicElementTypes.RULE:
          return this.rules;
        case LogicElementTypes.VARIABLE:
          return this.variables;
        default:
          return null;
      }
    }
  }
