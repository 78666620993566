
  import { Component, Vue, Ref } from 'vue-property-decorator';
  import type { Matrix } from '@app/models/matrix/matrix';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import MatrixForm from './matrix-form.vue';

  @Component({ components: { MatrixForm } })
  export default class MatrixNewPage extends Vue {
    @Ref() readonly form?: MatrixForm;

    onFormSubmit(form: Partial<Matrix>): void {
      this.$api
        .createMatrix(form)
        .then(({ data }) => {
          changeLocation(`/admin/settings/matrices/${data.id}`);
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }
  }
