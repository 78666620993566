
  import { Component, Prop } from 'vue-property-decorator';
  import BaseWidget from './base-widget';
  import type { VideoWidgetOptions } from '@app/models/widget';

  @Component
  export default class VideoWidget extends BaseWidget<VideoWidgetOptions> {
    @Prop(Boolean) readonly canEdit?: boolean;

    get isEditing(): boolean {
      return this.canEdit || false;
    }
  }
