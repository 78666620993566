
  import { Component, Ref } from 'vue-property-decorator';
  import MenuCollectionsForm from '@app/components/admin/menu-collections/menu-collections-form.vue';
  import Blocking from '@app/mixins/blocking';
  import type { MenuCollectionsFormOnly } from './utils';
  import type { MenuCollection } from '@app/models/menu-collection';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MenuCollectionsForm, DsModal } })
  export default class MenuCollectionNew extends Blocking {
    @Ref() readonly form!: MenuCollectionsForm;

    visible = true;

    onFormSubmit(form: Partial<Pick<MenuCollection, MenuCollectionsFormOnly>>): void {
      this.blocking(async () => {
        await this.$api.createMenuCollection(form);
        this.$api.cache.clear();
        this.closeModal('reload');
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-menu-collections',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
