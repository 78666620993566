
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { Component, Ref, Prop, Vue } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { cloneDeep } from 'lodash';
  import type { Matrix } from '@app/models/matrix/matrix';

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
      EntitySelector,
    },
    methods: { inactiveTitleTemplate },
  })
  export default class MatrixForm extends Vue {
    @Prop(Object) readonly matrix?: Partial<Matrix>;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Matrix> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    initForm(): void {
      if (this.matrix) {
        this.form = cloneDeep(this.matrix);
      }

      this.form = {
        ...this.form,
        options: {
          ...this.form.options,
        },
      };
    }

    beforeMount() {
      this.initForm();
    }
  }
