
  import DsIconText from '@app/components/ds-icon-text.vue';
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import UserImage from '@app/components/user-image.vue';
  import WithAdminPermissions from '@app/mixins/with-admin-permissions';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import { omit } from 'lodash';
  import bootbox from 'bootbox';
  import { Tooltip } from 'uiv';
  import { Component, Mixins } from 'vue-property-decorator';
  import SearchInput from '@app/components/search-input.vue';
  import type { AdminFlag, ExtraUserApiOptions, ValidUserScopes } from '@app/services/api/tenant-users-api';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { RestrictionDefault } from '@app/models/restriction-default';
  import type { TenantUser } from '@app/models/tenant-user';
  import { AUTH_LOGIN_TYPES } from '@app/models/restriction-default';
  import { UserType } from '@app/models/tenant-user';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  import PaperTrailsModalLink from '../paper-trails/paper-trails-modal-link.vue';
  import UserSelector from '../user/user-selector.vue';
  import Select2 from '../select2.vue';
  import FilterSelect from '../filter-select.vue';
  import EntitySelector from '../entity-selector.vue';
  import { BaseTable } from '../base-table';

  import UserExportButton from './user-export-button.vue';

  @Component({
    components: {
      DsIconText,
      UserImage,
      FilterCheckbox,
      EntitySelector,
      UserSelector,
      Select2,
      FilterSelect,
      BaseTable,
      DsDropdown,
      Tooltip,
      SearchInput,
      PaperTrailsModalLink,
      UserExportButton,
    },
  })
  export default class AdminUsersPage extends Mixins(WithAdminPermissions) {
    hideUserExport = window.DONESAFE.HIDE_USER_EXPORT;

    manager: Nullable<ListManager<TenantUser, ExtraUserApiOptions>> = null;
    defaultAuthRestrictions: RestrictionDefault[] = [];

    scopeOptions = {
      all: this.$t('app.labels.all'),
      user_team: this.$t('app.labels.user_team'),
      user_wider_team: this.$t('app.labels.user_wider_team'),
    };

    activeOptions = [
      ['true', this.$t('app.labels.active')],
      ['false', this.$t('app.labels.inactive')],
    ];

    internalOptions = [
      ['true', 'Yes'],
      ['false', 'No'],
    ];

    typesMap = {
      TenantUser: 'Tenant',
      ContactUser: 'Contact',
      VisitorUser: 'Visitor',
      ContractorUser: 'Contractor',
      MedicalUser: 'Medical User',
    };

    typeOptions = Object.entries(this.typesMap);

    get workersUsed(): string {
      return !this.accountStore.data.worker_limit
        ? `${this.accountStore.data.active_users_count}`
        : `${this.accountStore.data.active_users_count} / ${this.accountStore.data.worker_limit}`;
    }

    get currentScopeFilterValue(): string {
      const key: ValidUserScopes = this.manager?.customFilters?.scope || 'all';
      return this.scopeOptions[key];
    }

    get hasSecondaryInfo(): boolean {
      return !!this.accountStore.data.user_information_options.length;
    }

    get fields(): ListManagerField<TenantUser>[] {
      return [
        {
          title: this.$t('app.labels.name'),
          name: 'full_name',
          sortField: 'full_name',
        },
        {
          title: this.$t('app.labels.ID'),
          name: 'id',
          sortField: 'id',
        },
        {
          title: this.$t('app.labels.Email'),
          name: 'email',
          sortField: 'email',
        },
        {
          title: this.$t('app.labels.manager'),
          name: 'manager',
          filter: true,
          sortField: 'manager.full_name',
        },
        {
          title: this.$t('app.labels.type'),
          name: 'type',
          filter: true,
          sortField: 'type',
        },
        {
          title: this.$t('app.labels.role'),
          filter: true,
          name: 'role',
          sortField: 'role.name',
        },
        {
          title: this.$t('app.labels.position'),
          name: 'position',
          sortField: 'position',
        },
        {
          title: this.$t('app.labels.last_sign_in'),
          name: 'last_sign_in_at',
          sortField: 'last_sign_in_at',
        },
        {
          title: this.$t('app.labels.account'),
          name: 'confirmed_at',
          sortField: 'confirmed_at',
        },
        {
          title: this.$t('app.labels.active'),
          name: 'active',
          sortField: 'active',
          filter: true,
        },
        {
          title: 'Internal',
          name: 'internal',
          sortField: 'internal',
          hidden: !this.currentUserStore.isInternal,
          filter: true,
        },
        {
          title: 'Admin Permissions',
          name: 'admin_flags',
          width: 'minmax(min-content, 250px)',
          filter: true,
          hidden: !this.currentUserStore.hasSystemAdministrationPermission,
        },
        { title: '', name: 'operations', width: '50px' },
      ];
    }

    get flagOptions(): Dictionary<string> {
      const options = {
        admin: this.$t('app.labels.technical_admin'),
        can_change_password: 'Password Management',
        ...Object.values(AdminPermissionFeature)
          .filter((feature) => this.permissionCheck(feature))
          .reduce((acc, feature) => ({ ...acc, [feature]: this.adminPermissionNames[feature] }), {}),
      };
      const omitOptions = [];
      !this.currentUserStore.isTechAdmin && omitOptions.push('admin');
      !this.currentUserStore.canChangePassword &&
        !this.currentUserStore.hasSystemAdministrationPermission &&
        omitOptions.push('can_change_password');

      return omit(options, omitOptions);
    }

    manageableAdminFeatures(user: TenantUser): AdminPermissionFeature[] {
      return (
        user.admin_permission_features?.filter((feature) => {
          if (feature === AdminPermissionFeature.module_config && !this.accountStore.moduleConfigForNonTechAdmins) {
            return false;
          } else {
            return this.currentUserStore.isAdminPermissionManageable(feature);
          }
        }) || []
      );
    }

    permissionCheck(feature: AdminPermissionFeature): boolean {
      if (this.moduleConfigDisabledForNonTechAdmins(feature)) {
        return false;
      } else {
        return this.currentUserStore.isAdminPermissionManageable(feature);
      }
    }

    changeScopeFilter(val: ValidUserScopes): void {
      this.manager?.setFilter('scope', val);
    }

    toggleFlag(flag: AdminFlag, value: boolean): void {
      if (this.manager) {
        const flags = this.manager.customFilters?.admin_flags || [];
        const newValue = value ? flags.concat([flag]) : flags.filter((f) => f !== flag);
        this.manager.setFilter('admin_flags', newValue);
      }
    }

    flagSelected(flag: AdminFlag): boolean {
      return (this.manager?.customFilters?.admin_flags || []).includes(flag);
    }

    confirmUser(user: TenantUser): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure?',
        callback: (result: boolean) => {
          if (result) {
            $.post(`/admin/settings/users/${user.id}/welcome`).then((result) => {
              if (result) {
                toaster(`Welcome email was sent to ${user.full_name}`);
              } else {
                const text = 'The email used has been marked as inactive, check the email address, or contact your system admin';
                toaster({ text, position: 'top-right', icon: 'error' });
              }
            });
          }
        },
      });
    }

    getManager(): ListManager<TenantUser, ExtraUserApiOptions> {
      return new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params) => {
          return this.$api.getTenantUsers(
            {
              ...params,
              include: ['secondary_information'],
              only: [
                'id',
                'full_name',
                'first_name',
                'last_name',
                'avatar_url',
                'secondary_information',
                'email',
                'type',
                'position',
                'last_sign_in_at',
                'confirmation_sent_at',
                'current_sign_in_at',
                'confirmed_at',
                'active',
                'internal',
                'admin',
                'can_change_password',
                'admin_permission_features',
                { role: ['id', 'name'], manager: ['id', 'full_name'] },
              ],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        per_page: 25,
        fields: this.fields,
        allowFilters: true,
      });
    }

    restrictedUser(user: TenantUser): boolean {
      return this.defaultAuthRestrictions.filter((res) => res.restrict_on === user.type).length === AUTH_LOGIN_TYPES.length;
    }

    userConfirmationType(user: TenantUser): string {
      switch (user.type) {
        case UserType.ContactUser:
          return this.$t('app.labels.contact');
        case UserType.MedicalUser:
          return this.$t('app.labels.medical_practitioner');
        default:
          if (user.confirmed_at) {
            return this.$t('app.labels.confirmed');
          } else if (this.restrictedUser(user)) {
            return this.$t('app.labels.unconfirmed');
          } else if (!user.email) {
            return this.$t('app.labels.no_email');
          } else if (user.confirmation_sent_at) {
            return this.$t('app.labels.send_welcome_email');
          } else {
            return this.$t('app.labels.resend_welcome_email');
          }
      }
    }

    showConfirmationLink(user: TenantUser): boolean {
      if (!user.email) {
        return false;
      }

      return !user.confirmed_at && user.type !== UserType.ContactUser;
    }

    fetchDefaultRestrictions(cache = true): Promise<void> {
      return this.$api
        .getRestrictionDefaults(
          {
            filters: { restriction: AUTH_LOGIN_TYPES, action: 'auth', restrict: true },
            only: ['id', 'restrict_on', 'restriction', 'restrict', 'action'],
          },
          { cache }
        )
        .then(({ data }) => {
          this.defaultAuthRestrictions = data;
        });
    }

    beforeMount(): void {
      this.fetchDefaultRestrictions();
    }

    mounted(): void {
      this.manager = this.getManager();
    }
  }
