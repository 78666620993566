
  import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
  import type { DisplaySettingsObject } from '../models/types';
  import type { CalculatorQuestionOptions } from '@app/models/question-options/calculator-question-options';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';

  @Component({ components: {} })
  export default class DisplaySettings extends Vue {
    @PropSync('displaySettings') syncedDisplaySettings!: DisplaySettingsObject;
    @Prop({ required: true }) readonly updateQuestionConfig!: (
      newConfigItems: Partial<CalculatorQuestionOptions>
    ) => CalculatorQuestionOptions;

    updateDisplaySettings(value: string, name: string): void {
      const displaySettings = objectCloneDeep(this.syncedDisplaySettings);
      displaySettings[name] = value;

      if (name === 'format') {
        if (['number', 'percentage', 'currency'].indexOf(displaySettings.format) === -1) displaySettings.decimals = '';
        if (displaySettings.format !== 'currency') displaySettings.currency = '';
      }

      this.syncedDisplaySettings = displaySettings;
      this.updateQuestionConfig({ display_settings: JSON.stringify(displaySettings) });
    }
  }
