
  import SearchInput from '@app/components/search-input.vue';
  import { BaseTable } from '@app/components/base-table';
  import { Component, Ref, Watch, Vue } from 'vue-property-decorator';
  import type { CalculationRule } from '@app/models/calculation-rule';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: {
      BaseTable,
      SearchInput,
    },
  })
  export default class CalculationRulesIndexPage extends Vue {
    @Ref() readonly table?: BaseTable<CalculationRule>;

    manager: Nullable<ListManager<CalculationRule>> = null;

    getManager(): ListManager<CalculationRule> {
      return new ListManager<CalculationRule>({
        fetchDataFunction: (params) => {
          return this.$api.getCalculationRules(
            {
              ...params,
              filters: { ...params.filters },
              only: ['id', 'name', 'formula', 'fallback_formula', 'x_condition', 'y_condition', 'manual_scoring', 'active'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          { title: this.$t('app.labels.formula'), name: 'formula', sortField: 'formula' },
          { title: this.$t('app.labels.fallback_formula'), name: 'fallback_formula', sortField: 'fallback_formula' },
          { title: this.$t('app.labels.x_condition'), name: 'x_condition', sortField: 'x_condition' },
          { title: this.$t('app.labels.y_condition'), name: 'y_condition', sortField: 'y_condition' },
          { title: this.$t('app.labels.manual_scoring'), name: 'manual_scoring', sortField: 'manual_scoring' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
        ],
        allowFilters: true,
      });
    }

    beforeMount() {
      this.manager = this.getManager();
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
  }
