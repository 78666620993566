
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { RoleProfile } from '@app/models/role-profile';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  const SHOW_ALL_MODULES = '--show-all-modules--';

  @Component({ components: { EntitySelector } })
  export default class RoleProfileSelector extends Vue {
    @Model('input') value!: number | string;
    @Prop(String) placeholder?: string;
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) allowClear?: boolean;
    @Prop(Boolean) readonly?: boolean;
    @Prop([Object, Function]) filters?: DonesafeFilterOptions<RoleProfile>;
    @Prop([Object, Function]) requiredFilters!: DonesafeFilterOptions<RoleProfile>;
    @Prop([Object, Function]) showAllProfileFilters?: DonesafeFilterOptions<RoleProfile>;
    @Prop(String) readonly sort?: string;
    @Prop(Boolean) readonly sortable?: boolean;
    @Prop(Function) readonly openMethod?: () => boolean;
    @Prop(Function) readonly noResultsMethod?: () => JQuery;
    @Ref() readonly selector?: EntitySelector<RoleProfile>;

    only: OnlyOptions<RoleProfile> = ['id', 'name', 'secondary_information'];
    clearFiltersPressed = false;

    get effectiveFilters(): DonesafeFilterOptions<RoleProfile> {
      if (this.clearFiltersPressed) {
        return this.showAllProfileFilters || {};
      } else {
        return this.filters || {};
      }
    }

    templateResult(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result as { [key: string]: string }, {
        primaryAttribute: 'name',
        secondaryAttribute: 'secondary_information',
      });
    }

    clearFilters(): void {
      this.clearFiltersPressed = true;
      this.selector?.reFetch();
    }

    onSelect(event: { params: { args: { data: { id: string } } } }): boolean {
      if (event.params.args.data.id === SHOW_ALL_MODULES) {
        this.clearFilters();
        this.selector?.focusSearch();
        return false;
      }

      return true;
    }

    footerItem(): { id: string; name: string; secondary_information: string } | undefined {
      if (!this.showAllProfileFilters || this.clearFiltersPressed) return undefined;
      return {
        id: SHOW_ALL_MODULES,
        name: this.$t('tenant.module_tabs.show.restriction_labels.showing_filtered_profiles').toString(),
        secondary_information: this.$t('tenant.module_tabs.show.restriction_labels.show_all_profiles').toString(),
      };
    }

    toggle(value: boolean): void {
      this.selector?.toggle(value);
    }
  }
