
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';

  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    amount: string;
    expense_category_code: string;
    record_id: string;
    sub_form_list_id: string;
    user_id: string;
  }

  @Component({ components: { Select2, AdvancedAutosuggest, FormField } })
  export default class ApprovedExpenseEventForm extends BaseEventForm<FormValues> {
    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
