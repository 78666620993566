
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import AuditReportGenerateModal from '@app/components/audit-report-templates/audit-report-generate-modal.vue';
  import { AuditReportRecordType } from '@app/models/audit-report-template';

  @Component({ components: { AuditReportGenerateModal } })
  export default class AuditReportGenerateModalButton extends Vue {
    @Prop({ type: String, default: () => 'li' }) readonly tag!: string;
    @Prop(String) readonly moduleRecordId!: string;
    @Prop(String) readonly moduleNameId!: string;

    modalVisible = false;
    recordType = AuditReportRecordType.ModuleRecord;
  }
