
  import { buildEventsTree, parseEventTree } from '@app/utils/build-event-trees';
  import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';

  import DsSection from './ds-section.vue';
  import EventFormDisplay from './event-form-display.vue';
  import EventGroupsSorting from './event-groups-sorting.vue';
  import EventOptionsMixin from './event-options-mixin';
  import ForEveryPreloadData from './for-every-preload-data';
  import type { EventWithTempId, GroupOrEvent, GroupWithTempId } from './with-automation-submit';

  @Component({
    components: {
      EventGroupsSorting,
      EventFormDisplay,
      DsSection,
    },
  })
  export default class ListOfEventsAndGroups extends Mixins(EventOptionsMixin, ForEveryPreloadData) {
    @PropSync('events') syncedEvents!: EventWithTempId[];
    @PropSync('groups') syncedGroups!: GroupWithTempId[];
    @PropSync('selected') selectedPane!: string | null;
    @Prop() eventFormsLoaded!: Record<string, boolean>;
    @Prop() invalidForms!: Record<string, boolean>;
    @Prop() readonly disableFooter!: (cb: () => Promise<void>) => void;

    get tree(): GroupOrEvent[] {
      return buildEventsTree(this.syncedEvents, this.syncedGroups).tree({ displayEmptyGroups: true });
    }

    get hasInvalidForms() {
      return this.syncedEvents.some((event) => this.invalidForms[event.id]);
    }

    set tree(value: GroupOrEvent[]) {
      const { events, groups } = parseEventTree(value);
      this.syncedEvents = events;
      this.syncedGroups = groups;
    }

    @Watch('selectedPane')
    onSelectedPaneChange(value: string | null, previous: string | null) {
      if (typeof value === 'string') {
        const id = value.split(':')[0];
        this.eventFormDisplayRef(id)?.validate();
      }

      if (typeof previous === 'string') {
        const id = previous.split(':')[0];
        this.eventFormDisplayRef(id)?.validate();
      }
    }

    eventFormDisplayId(id: Nullable<string | number>): string {
      return `event-form-display-${id}`;
    }

    eventFormDisplayRef(id: Nullable<string | number>): Nullable<EventFormDisplay> {
      const key = this.eventFormDisplayId(id);
      if (!(key in this.$refs)) return null;

      const refs = this.$refs[key] as Array<EventFormDisplay>;
      if (!!refs && refs.length < 1) return null;
      return refs[0];
    }

    validateAll() {
      this.syncedEvents.forEach((event) => {
        this.eventFormDisplayRef(event.id)?.validate();
      });
    }

    beforeMount() {
      this.preloadForEveryComponentData();
    }
  }
