
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DatePicker from '../date-picker.vue';
  import { formattedDate } from './helper';
  import SetupForm from './setup-form.vue';
  import bootbox from 'bootbox';
  import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
  import type { SuperReport } from '@app/models/super-report';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DatePicker, SetupForm } })
  export default class RecurringSetupPanel extends Vue {
    @Model('input') readonly value?: RecurringSetup;
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly enabled?: boolean;
    @Prop(Boolean) readonly allowEncryption?: boolean;
    @Prop(Object) readonly report?: SuperReport;

    expanded = false;

    form: Partial<SuperReport> = {};
    setupForm: Partial<RecurringSetup> = {};

    beforeMount(): void {
      this.expanded = !!this.value?.enabled;
      this.form = this.report || {};
      this.setupForm = this.value || { enabled: true };
    }

    reportFormName(fieldName: string): string {
      return `super_report[${fieldName}]`;
    }

    formattedDate(date: Date): string {
      return formattedDate(date);
    }

    onExportNow(): void {
      bootbox.confirm({
        backdrop: false,
        title: this.$t('tenant.admin.reports.recurring_setup_panel.export'),
        message: this.$t('tenant.admin.reports.recurring_setup_panel.export_message'),
        buttons: {
          confirm: { label: this.$t('tenant.admin.reports.recurring_setup_panel.export'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-danger' },
        },
        callback: (result: boolean) => {
          if (result && this.report?.id) {
            this.$api
              .sftpExportSuperReport(this.report.id, { notify: true, encrypt: true })
              .then(() => {
                toaster(this.$t('tenant.admin.reports.recurring_setup_panel.export_in_progress'));
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }

    get showStartAndEndRange(): boolean {
      return window.DONESAFE.REPORTS_SHOW_START_END_RANGE_PICKER;
    }

    get accountStore() {
      return useAccountStore();
    }
  }
