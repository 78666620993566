
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import SearchInput from '@app/components/search-input.vue';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import { get } from 'lodash';
  import qs from 'qs';
  import { Tooltip, tooltip } from 'uiv';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import RecordIndexPageFiltersLayout from '@app/components/record-index-page-filters-layout.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import { mixins } from 'vue-class-component';
  import type { ModuleName } from '@app/models/module-name';
  import type { IndexFilter } from '@app/models/index-filter';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { buildLink } from '@app/utils/build-link';
  import { mapOldIndexFilterKeys } from '@app/utils/map-old-index-filter-keys';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import ScopeFilter from '../filters/scope-filter.vue';
  import GlobalDateFilter from '../filters/global-date-filter.vue';
  import UserRecordCalendarModal from '../calendar/user-record-calendar-modal.vue';

  import ModuleRecordsTable from './module-records-table.vue';

  @Component({
    components: {
      SearchInput,
      UserRecordCalendarModal,
      GlobalDateFilter,
      ScopeFilter,
      ModuleRecordsTable,
      Tooltip,
      DsDropdown,
      RecordIndexPageFiltersLayout,
    },
    directives: { tooltip },
  })
  export default class ModuleRecordsIndexPage extends mixins(ModuleRecordModals) {
    @Prop(Object) readonly moduleName!: ModuleName;
    @Ref() readonly moduleRecordsTable!: ModuleRecordsTable;

    indexFilter: Nullable<Partial<IndexFilter>> = null;
    initialized = false;
    calendarModalVisible = false;
    indexFilters: IndexFilter<ModuleRecord, DonesafeModuleRecordExtraFilters>[] = [];
    defaultTemplates: Pick<DefaultTemplate, 'id' | 'name' | 'system_code' | 'module_name_id'>[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get indexOptions(): string[] {
      return this.moduleName.index_options.map((o) => o.key);
    }

    get calendarIntegrationEnabled(): boolean {
      return !!this.accountStore.data.calendar_integration_enabled && !!this.moduleName.feature_set?.calendar_integration_enabled;
    }

    get sort(): string | undefined {
      return this.indexFilter?.sorting_order?.sort;
    }

    get reverse(): boolean {
      return this.indexFilter?.sorting_order?.reverse || false;
    }

    get filterId(): number | undefined {
      return this.indexFilter?.user_id === this.currentUserStore.data?.id ? this.indexFilter?.id : undefined;
    }

    get emptyFilter(): DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters> {
      return { scope: 'all', created_at: 'all', module_name_id: this.moduleName.id };
    }

    get canCreate(): boolean {
      return (
        !!get(this.currentUserStore.data?.acl, ['module', this.moduleName.name, 'create']) &&
        !get(this.currentUserStore.data?.acl, ['module', this.moduleName.name, 'hide_quick_add_button'])
      );
    }

    get openInModal(): boolean {
      return !!this.moduleName.feature_set?.create_main_form_in_modal;
    }

    get addNewRecordLink(): string {
      return buildLink('/module_records/new', { module_name_id: this.moduleName.id });
    }

    get defaultIndexFilter(): Partial<IndexFilter<ModuleRecord, DonesafeModuleRecordExtraFilters>> {
      return { filter: { scope: 'mine', created_at: 'week', module_name_id: this.moduleName.id } };
    }

    get hasDefaultTemplates(): boolean {
      return !!this.defaultTemplates.length;
    }

    addNewAction(event: MouseEvent): void {
      this.openModalOrLink({
        modal: this.openInModal,
        link: this.addNewRecordLink,
        event,
        modalProps: {
          mode: 'module-record-edit',
          moduleNameId: this.moduleName.id,
          title: this.moduleRecordEditTitle(this.moduleName.display),
        },
      });
    }

    toggleCalendarModal(value: boolean): void {
      this.calendarModalVisible = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getQueryParams(): any {
      const parseOptions = { ignoreQueryPrefix: true, comma: true };
      return qs.parse(window.location.search, parseOptions);
    }

    saveCurrentFilter(): void {
      const queryParams = this.getQueryParams();
      const filter = queryParams.filter || queryParams.filters || {};
      let sort = queryParams.sort;
      let reverse = queryParams.reverse || false;
      if (sort?.startsWith('-')) {
        sort = sort.replace(/^-/, '');
        reverse = true;
      }

      const newIndexFilter: Partial<IndexFilter> = {
        index: 'module_records',
        filter: mapOldIndexFilterKeys(filter),
        sorting_order: { sort, reverse },
      };
      const filterId = this.filterId;

      const promise = filterId ? this.$api.updateIndexFilter(filterId, newIndexFilter) : this.$api.createIndexFilter(newIndexFilter);
      promise
        .then((response) => {
          this.indexFilter = response.data;
          toaster(this.$t('tenant.index_filters.create.saved_new_filter'));
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    clearPersonalFilter(): void {
      if (this.indexFilter?.id && this.indexFilter.user_id === this.currentUserStore.data?.id) {
        this.$api.deleteIndexFilter(this.indexFilter.id).then(() => {
          this.indexFilters = this.indexFilters.filter((indexFilter) => indexFilter.id !== this.indexFilter?.id);
          this.selectIndexFilter();
          toaster(this.$t('tenant.index_filters.delete.filter_deleted'));
        });
      }
    }

    clearAllFilters(): void {
      const filterId = this.filterId;

      if (filterId) {
        this.indexFilter = { ...this.indexFilter, filter: this.emptyFilter };
      } else {
        this.indexFilter = { filter: this.emptyFilter };
      }

      toaster({
        text: this.$t('tenant.index_filters.clear.filters_cleared'),
        position: 'top-right',
      });
    }

    addNewRecordLinkWithTemplate(template: Pick<DefaultTemplate, 'id' | 'name' | 'system_code' | 'module_name_id'>) {
      return buildLink('/module_records/new', {
        module_name_id: template.module_name_id,
        default_template_id: template.system_code,
      });
    }

    getIndexFilters(): Promise<IndexFilter[]> {
      return this.$api
        .getIndexFilters({ filters: { index: 'module_records', filter: { module_name_id: this.moduleName.id } } }, { cache: true })
        .then(({ data }) => (this.indexFilters = data));
    }

    selectIndexFilter(): void {
      const userFilter = this.indexFilters.find((indexFilter) => indexFilter.user_id === this.currentUserStore.data?.id);
      const globalFilter = this.indexFilters.find((indexFilter) => !indexFilter.user_id);
      this.indexFilter = userFilter || globalFilter || this.defaultIndexFilter;
    }

    mounted(): void {
      this.getIndexFilters().then(() => this.selectIndexFilter());

      const queryParams = this.getQueryParams();

      if (queryParams.filters?.date) {
        this.$api
          .getSubFormQuestions({
            filters: {
              code: ['date'], // TODO: add start_date and end_date support?
              sub_form_section: {
                sub_form_id: this.moduleName.sub_form_id,
              },
            },
          })
          .then(({ data }) => {
            if (!data.length) {
              if (!queryParams.filters.created_at) {
                queryParams.filters.created_at = queryParams.filters.date;
              }
              delete queryParams.filters.date;

              window.history.replaceState(null, '', qs.stringify(queryParams, { addQueryPrefix: true, arrayFormat: 'brackets' }));
            }
          });
      }
      this.$api
        .getDefaultTemplates(
          {
            only: ['id', 'name', 'system_code', 'module_name_id'],
            sort: 'index',
            per_page: -1,
            filters: { active: true, show_ui: true, module_name_id: this.moduleName.id },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.defaultTemplates = data;
        });
    }

    convertToConfiguratorFilters(customFilters?: DonesafeFilterOptions<ModuleRecord>): ConfiguratorFilter[] {
      if (!customFilters) {
        return [];
      }
      return Object.keys(customFilters).map((key) => ({
        key,
        value: customFilters[key as keyof DonesafeFilterOptions<ModuleRecord>] as string,
      }));
    }

    beforeMount(): void {
      const hideOnMainMenu = get(this.currentUserStore.data?.acl, ['module', this.moduleName.name, 'hide_on_main_menu']);
      if (hideOnMainMenu) {
        toaster({ text: this.$t('controllers.defaults.no_access'), position: 'top-right', icon: 'error' });
        changeLocation('/');
      }
    }
  }
