import type { ModuleName } from '@app/models/module-name';
import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
import type { SubForm } from '@app/models/sub-form';
import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ForEveryPreloadData extends Vue {
  preloadedForEverySubForms: Nullable<SubForm[]> = null;
  preloadedForEveryModuleNames: Nullable<ModuleName[]> = null;

  preloadForEveryComponentData() {
    this.$api
      .getModuleNames(
        {
          filters: { active: true, '!name': HARDCODED_MODULE_CODES.join(',') } as DonesafeFilterOptions<ModuleName>,
          per_page: -1,
          only: ['id', 'name', 'display'],
        },
        { cache: true }
      )
      .then(({ data }) => {
        this.preloadedForEveryModuleNames = [...data];

        if (data.length) {
          this.$api
            .getSubForms(
              {
                filters: { active: true, module_name: this.preloadedForEveryModuleNames.map(({ name }) => name).join(',') },
                per_page: -1,
                only: ['id', 'title', 'module_name'],
              },
              { cache: true }
            )
            .then(({ data: subForms }) => {
              this.preloadedForEverySubForms = [...subForms];
            });
        } else {
          this.preloadedForEverySubForms = [];
        }
      });
  }
}
