
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Model, Vue } from 'vue-property-decorator';
  import VJsoneditor from 'v-jsoneditor';
  import schema from '@app/validators/table-calculation-field-config-schema.json';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import { extend } from 'vee-validate';
  import fail from '@app/validators/fail';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { TECHNICAL_ADMIN_EMAIL } from '@app/models/acl';
  import type {
    TableCalculationFieldConfig,
    TableCalculationQuestionOptions,
  } from '@app/models/question-options/table-calculation-question-options';

  type Question = SubFormQuestion<TableCalculationQuestionOptions>;
  type Value = Partial<TableCalculationFieldConfig>;

  @Component({ components: { VJsoneditor, FormField } })
  export default class TableCalculationFieldOptions extends Vue {
    @Model('input') readonly value!: Question;

    json: Value = {};
    jsonValid = false;

    editorOptions = {
      schema,
      mode: 'code',
      modes: ['code', 'tree', 'preview'], // allowed modes
    };

    get currentUserStore() {
      return useCurrentUserStore();
    }

    async onInput(value: Partial<TableCalculationFieldConfig>) {
      this.json = value;
      this.jsonValid = true;
      this.$set(this.value.config, 'table_calculation', this.inputValue);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async onError(_: object) {
      this.jsonValid = false;
      this.$set(this.value.config, 'table_calculation', this.inputValue);
    }

    get inputValue(): string {
      // set to '' when it's empty to prevent form submit
      return this.jsonValid ? JSON.stringify(this.json) : '';
    }

    get canEditJson(): boolean {
      return this.currentUserStore.data?.email === TECHNICAL_ADMIN_EMAIL;
    }

    get validationRules() {
      return this.jsonValid ? {} : { fail: { message: 'Valid JSON is required' } };
    }

    beforeMount() {
      extend('fail', fail);
    }

    mounted() {
      const value = this.value?.config?.table_calculation;

      this.json = value;

      if (value) {
        this.jsonValid = true;
      }
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['table_calculation'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
