
  import { useAccountStore } from '@app/stores/account';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
  import Chartkick from 'vue-chartkick';
  import bootbox from 'bootbox';
  import type { Dashboard } from '@app/models/dashboard';
  import type { Widget } from '@app/models/widget';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import Blocking from '@app/mixins/blocking';
  import { pick } from 'lodash';

  import DashboardGrid from '../../dashboard-grid.vue';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown, DashboardGrid } })
  export default class AdminDashboardsShowPage extends Blocking {
    @Prop({ type: [String, Number] }) readonly dashboardId!: number | string;
    @Ref('dashboardGrid') readonly dashboardGrid?: DashboardGrid;

    dashboard: Nullable<Dashboard> = null;
    dashboardKey: number = 0;

    get widgets(): Widget[] {
      return this.dashboard?.widgets || [];
    }

    get dashboardName(): string {
      if (!this.dashboard?.active) return this.$t('app.labels.archived_name', { name: this.dashboard?.name });
      return this.dashboard.name;
    }

    get accountStore() {
      return useAccountStore();
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.fetchDashboard();
      }
    }

    deleteDashboard(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.dashboard &&
            this.$api
              .deleteDashboard(this.dashboard.id)
              .then(() => {
                this.$api.cache.clear();
                this.$router.push({ name: 'admin-dashboards-index' });
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
        },
      });
    }

    archive(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.views.admin.dashboard.show.delete_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result && this.dashboard?.id) {
            this.updateDashboard({ id: this.dashboard.id, active: false });
          }
        },
      });
    }

    restore(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.views.admin.dashboard.show.restore_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result && this.dashboard?.id) {
            this.updateDashboard({ id: this.dashboard.id, active: true });
          }
        },
      });
    }

    updateDashboard(dashboard: Partial<Dashboard>): void {
      if (dashboard?.id) {
        this.$api.updateDashboard(dashboard.id, dashboard).then(() => {
          this.$api.cache.clear();
          this.$router.push({ name: 'admin-dashboards-index' });
        });
      }
    }

    saveWidgets(showNotice: boolean): void {
      this.blocking(async () => {
        const { widgetsToSave } = (await this.dashboardGrid?.save()) || {};
        const widgets = (widgetsToSave || []).map((widget) => {
          const chart = Chartkick.charts['chart-' + widget.id];
          if (!!chart) chart.refreshData();
          return { ...pick(widget, ['x', 'y']), id: Number(widget.id), width: widget.w, height: widget.h } as Pick<
            Widget,
            'id' | 'x' | 'y' | 'width' | 'height'
          >;
        });

        if (widgets.length) {
          const { data } = await this.$api.updateWidgetPositions({ data: widgets });
          if (data.length && showNotice) toaster({ text: this.$t('app.views.admin.dashboard.show.saved'), position: 'top-right' });
        }
      });
    }

    beforeMount(): void {
      this.fetchDashboard();
    }

    fetchDashboard(): void {
      this.$api
        .getDashboard(
          Number(this.dashboardId),
          { only: ['widgets', 'active', 'name', 'id', 'hidden', 'is_public', 'show_widget_border'] },
          { cache: true }
        )
        .then(({ data }) => {
          this.dashboard = data;
          this.dashboardKey++;
        })
        .catch(() => {
          this.$router.push({ name: 'admin-dashboards-index' });
        });
    }
  }
