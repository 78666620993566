
  import Heatmap from '@app/components/super-report/heatmap.vue';
  import { Component } from 'vue-property-decorator';
  import type { Library } from '@app/models/widgets/report-graph';
  import WithReportGraph from '@app/mixins/with-report-graph';
  import { mixins } from 'vue-class-component';
  import type { LatLng } from '@app/models/geocoder-result';
  import type { ReportGraphWidgetOptions } from '@app/models/widget';
  import type { SuperReportGraphData } from '@app/models/super-report';

  import BaseWidget from './base-widget';

  @Component
  class BaseReportGraphWidget extends BaseWidget<ReportGraphWidgetOptions> {}

  @Component({ components: { Heatmap } })
  export default class ReportGraphWidget extends mixins(BaseReportGraphWidget, WithReportGraph) {
    loading = true;
    isContentHidden = false;
    isError = false;
    reportData: SuperReportGraphData = {
      graph_data: [],
      type: '',
      stacked: false,
      colors: [],
    };
    pieDataLabels: Library['plotOptions']['pie']['dataLabels'] = { enabled: false };
    defaultRadius = 1;

    get reportLink(): string {
      return `/reports/super_report?super_report_id=${this.widget.options.super_report_id}`;
    }

    get coordinates(): LatLng[] {
      return (
        this.reportData.map_informations
          ?.filter((item) => item[0] !== null || item[1] !== null)
          .map((item) => ({
            lat: item[0],
            lng: item[1],
          })) || []
      );
    }

    get showReportLink(): boolean {
      return (
        !this.isPublicDashboard &&
        !!this.currentUserStore.data?.role?.super_report_ids?.map((id) => `${id}`)?.includes(this.widget.options.super_report_id)
      );
    }

    fetchReportData(): void {
      if (this.accountStore.data.schema) {
        const reportId = Number(this.widget.options.super_report_id);
        const request = this.isPublicDashboard
          ? this.$api.getPublicSuperReportGraphData(reportId, this.accountStore.data.schema, { cache: true })
          : this.$api.getSuperReportGraphData(reportId, { cache: true });
        request
          .then(({ data }) => {
            this.reportData = data;
          })
          .catch(() => {
            this.isContentHidden = !!this.isPublicDashboard;
            this.isError = !this.isContentHidden;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }

    mounted(): void {
      this.fetchReportData();
    }
  }
