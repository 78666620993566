
  import { useAccountStore } from '@app/stores/account';
  import DsLabel from '@app/components/ds-label.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import type { GlobalSearchParams } from '@app/services/api/global-search-api';
  import { entityPathPrefix } from '@app/services/helpers';
  import { uniq } from 'lodash';
  import { Component, Vue } from 'vue-property-decorator';
  import Select2 from './select2.vue';
  import { BaseTable } from './base-table';
  import DateTimePicker from './date-time-picker.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { PgDocument } from '@app/models/pg-document';
  import type { Workflow } from '@app/models/workflow';
  import { ListManager } from '@app/services/list-manager/list-manager';

  // TODO: fix sorting
  // TODO: add "for" attributes and control-label classes for labels
  @Component({
    components: {
      DsLabel,
      LocationSelector,
      DateTimePicker,
      BaseTable,
      Select2,
    },
  })
  export default class GlobalSearchPage extends Vue {
    moduleNames: Pick<ModuleName, 'display' | 'name'>[] = [];
    workflowsMap: Record<number, Pick<Workflow, 'id' | 'color' | 'name'>> = {};
    locationsMap: Record<number, string> = {};
    organizationsMap: Record<number, string> = {};
    usersMap: Record<number, string> = {};

    manager: Nullable<ListManager<PgDocument, GlobalSearchParams>> = null;

    get accountStore() {
      return useAccountStore();
    }

    getRecordPath(pgDocument: PgDocument): string {
      const prefix = entityPathPrefix(pgDocument.searchable_type);
      return `/${prefix}/${pgDocument.searchable_id}`;
    }

    resetFilters(): void {
      if (this.manager) {
        this.manager.customFilters = { search: '' };
      }
    }

    beforeMount(): void {
      this.manager = new ListManager<PgDocument, GlobalSearchParams>({
        fetchDataFunction: (params) => {
          return this.$api.globalSearch(
            {
              ...params,
              include: ['type', 'user_id', 'workflow_id', 'location_id', 'organization_id', 'external_uuid'],
            },
            { cache: true }
          );
        },
        afterFetch: (results): void => {
          if (results.length) {
            const workflowIds = uniq(results.map((x) => x.workflow_id)).filter(Boolean);
            if (workflowIds.length) {
              this.$api
                .getWorkflows(
                  { filters: { id: workflowIds }, per_page: workflowIds.length, only: ['id', 'name', 'color'] },
                  { cache: true }
                )
                .then(({ data }) => (this.workflowsMap = data.reduce((memo, item) => ({ ...memo, [item.id]: item }), {})));
            }

            const organizationIds = uniq(results.map((x) => x.organization_id)).filter(Boolean);

            if (organizationIds.length) {
              this.$api
                .getOrganizations(
                  { filters: { id: organizationIds }, per_page: organizationIds.length, only: ['id', 'name'] },
                  { cache: true }
                )
                .then(({ data }) => (this.organizationsMap = data.reduce((memo, item) => ({ ...memo, [item.id]: item.name }), {})));
            }

            const userIds = uniq(results.map((x) => x.user_id)).filter(Boolean);

            if (userIds.length) {
              this.$api
                .getTenantUsers({ filters: { id: userIds }, per_page: userIds.length, only: ['id', 'full_name'] }, { cache: true })
                .then(({ data }) => (this.usersMap = data.reduce((memo, item) => ({ ...memo, [item.id]: item.full_name }), {})));
            }

            const locationIds = uniq(results.map((x) => x.location_id)).filter(Boolean);

            if (locationIds.length) {
              this.$api
                .getLocations({ filters: { id: locationIds }, per_page: locationIds.length, only: ['id', 'name'] }, { cache: true })
                .then(({ data }) => (this.locationsMap = data.reduce((memo, item) => ({ ...memo, [item.id]: item.name }), {})));
            }
          }
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created' }],
        fields: [
          { title: this.$t('app.labels.title'), name: 'title', sortField: 'title' },
          { title: this.$t('app.labels.state'), name: 'workflow_id', sortField: 'state' },
          { title: this.$t('app.labels.type'), name: 'type', sortField: 'type' },
          { title: this.$t('app.labels.date_created'), name: 'created_at', sortField: 'created' },
          { title: this.$t('app.labels.created_by'), name: 'user_id', sortField: 'author' },
          { title: this.$t('app.labels.location'), name: 'location_id', sortField: 'location' },
          { title: this.$t('app.labels.organization'), name: 'organization_id', sortField: 'organization' },
          { title: this.$t('app.labels.external_uuid'), name: 'external_uuid', sortField: 'external_uuid' },
        ],
        customFilters: { search: '' },
      });

      this.$api
        .getModuleNames(
          {
            filters: { active: true },
            per_page: -1,
            only: ['name', 'display'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
        });
    }
  }
