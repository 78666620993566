
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { DateRestrictionOptions } from './models';
  import RestrictOptions from './shared/restrict-options.vue';
  import DateCalculationOptions from './shared/date-calculation-options.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  type DatetimeQuestionOptions = BaseQuestionOptions &
    DateRestrictionOptions & {
      sub_form_question_system_code?: string;
      sync_with_timezone?: boolean;
    };

  @Component({
    components: { DateCalculationOptions, RestrictOptions, FormField, DsCheckbox, EntitySelector },
  })
  export default class DatetimeFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<DatetimeQuestionOptions>;

    @Prop(Object) readonly subForm!: SubForm;

    get locationQuestionFilters() {
      return {
        field_type: FieldType.location,
        sub_form_section: { sub_form_id: this.subForm.id },
        active: true,
      };
    }

    get accountStore() {
      return useAccountStore();
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'date_calculation',
        'if_empty',
        'if_missing',
        'operator',
        'restrict_type',
        'sub_form_list_id',
        'sub_form_question_code',
        'sub_form_question_system_code',
        'sync_with_timezone',
        'transform',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
