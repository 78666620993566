
  import { Component } from 'vue-property-decorator';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';
  import type { Relationship } from '@app/models/relationship';
  import type { SubForm } from '@app/models/sub-form';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AutomationTriggerOn } from '@app/models/automation-definition';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { automationDefinitionEditPath } from '@app/utils/automation-definition-edit-path';
  import { getValueFromLocationSearch } from '@app/utils/get-value-from-location-search';
  import { toaster } from '@app/utils/toaster';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import { changeLocation } from '@app/utils/change-location';

  import WithAutomationSubmit from './with-automation-submit';
  import type { RedirectionMode } from './models';
  import AutomationDefinitionForm from './automation-definition-form.vue';

  @Component({ components: { AutomationDefinitionForm } })
  export default class AutomationDefinitionNew extends WithAutomationSubmit {
    subForm: Nullable<Pick<SubForm, 'module_name' | 'title'>> = null;
    involvement: Nullable<Pick<Involvement, 'name'>> = null;
    relationship: Nullable<Pick<Relationship, 'name'>> = null;
    reportConfig: Nullable<Pick<RegulatoryReportConfig, 'name'>> = null;
    moduleName: Nullable<Pick<ModuleName, 'display'>> = null;
    loading = false;

    get automationDefinition(): Partial<AutomationDefinition> {
      return {
        name: '',
        concept_name: this.type,
        sub_form_id: Number(this.$route.query.sub_form_id) || undefined,
        relationship_code: this.$route.query.relationship_code as string,
        index: 0,
        trigger_when: 'always',
        trigger_on: AutomationTriggerOn.Create,
        display: this.display,
      };
    }

    get display(): string {
      return this.type === 'SubFormCompletion'
        ? `${this.moduleName?.display || ''} - ${this.getAutomationDefinitionDisplay}`
        : this.getAutomationDefinitionDisplay;
    }

    get injectableArguments(): InjectableArguments {
      return {
        concept_name: this.type,
        sub_form_id: this.$route.query.sub_form_id as unknown as number,
        involvement_id: this.$route.query.involvement_id as unknown as number,
        relationship_code: this.$route.query.relationship_code as string,
        regulatory_report_config_id: this.$route.query.regulatory_report_config_id as unknown as number,
      };
    }

    get automationRuleSetBuilder(): AutomationRuleSetBuilder {
      return {
        concept_name: this.type,
        injectable_arguments: this.injectableArguments,
        triggering_concept_name: null,
        for_collection_options: false,
        triggering_injectable_arguments: {},
      };
    }

    get type(): string {
      return this.$route.query?.type as string;
    }

    get getAutomationDefinitionDisplay(): string {
      switch (this.type) {
        case 'Action':
          return 'Action';
        case 'MedicalCertificate':
          return 'Medical Certificate';
        case 'IncidentParticipant':
          return 'Incident Participant';
        case 'TenantUser':
          return 'User';
        case 'UserInvolvement':
          return this.involvement ? `Involvement: ${this.involvement.name}` : 'Involvement';
        case 'Document':
          return 'Document';
        case 'RecordRelation':
          return this.relationship ? `Relationship: ${this.relationship.name}` : 'Relationship';
        case 'RegulatoryReport':
          return this.reportConfig ? `Regulatory Report: ${this.reportConfig.name}` : 'Regulatory Report';
        case 'SubFormCompletion':
          return this.subForm ? `Sub Form: ${this.subForm.title}` : 'Sub Form';
        case ACTIVITY_CONCEPT:
          return 'Action';
        default:
          return this.moduleName?.display || '';
      }
    }

    onSubmitEvent(
      automationDefinition: Partial<AutomationDefinition> & { rule_builder_rules: Nullable<QueryBuilderBlob> },
      mode?: RedirectionMode
    ) {
      this.submit(automationDefinition, this.events, {
        mode,
        onSubmitComplete: this.onSubmitComplete,
        injectableArguments: this.injectableArguments,
      });
    }

    onSubmitComplete(data: AutomationDefinition, mode?: RedirectionMode) {
      toaster('Automation successfully added!');

      if (mode === 'redirect') {
        changeLocation(getValueFromLocationSearch('redirect_to') || '/admin/settings/automation_definitions');
      } else if (mode === 'debug') {
        changeLocation(
          automationDefinitionEditPath(data.id, {
            debug: true,
            redirect_to: getValueFromLocationSearch('redirect_to'),
          })
        );
      } else {
        changeLocation(automationDefinitionEditPath(data.id, { redirect_to: getValueFromLocationSearch('redirect_to') }));
      }
    }

    getSubForm(): Promise<void> {
      return this.$api
        .getSubForm(Number(this.$route.query.sub_form_id), { only: ['module_name', 'title'] }, { cache: true })
        .then(({ data }) => {
          this.subForm = data;
          this.getModuleName(data.module_name);
        });
    }

    getInvolvement(): Promise<void> {
      return this.$api
        .getInvolvement(this.$route.query.involvement_id as string, {
          only: ['name'],
          cache: true,
        })
        .then(({ data }) => {
          this.involvement = data;
        });
    }

    async getRelationship(): Promise<void> {
      return this.$api
        .getRelationships(
          { filters: { code: this.$route.query.relationship_code }, only: ['name'] },
          {
            cache: true,
          }
        )
        .then(({ data }) => {
          this.relationship = data[0];
        });
    }

    async getReportConfig(): Promise<void> {
      return this.$api
        .getRegulatoryReportConfig(
          Number(this.$route.query.regulatory_report_config_id),
          { only: ['name'] },
          {
            cache: true,
          }
        )
        .then(({ data }) => {
          this.reportConfig = data;
        });
    }

    async getModuleName(name: string): Promise<void> {
      return this.$api
        .getModuleNames(
          { filters: { name: name }, only: ['display'] },
          {
            cache: true,
          }
        )
        .then(({ data }) => {
          this.moduleName = data[0];
        });
    }

    beforeMount(): void {
      const hardcodedTypes = [
        'Action',
        'MedicalCertificate',
        'IncidentParticipant',
        'TenantUser',
        'UserInvolvement',
        'Document',
        'RecordRelation',
        'RegulatoryReport',
        'SubFormCompletion',
        'Activity',
      ];
      let promises = [];

      this.loading = true;

      if (this.$route.query.sub_form_id) promises.push(this.getSubForm());
      if (this.$route.query.involvement_id) promises.push(this.getInvolvement());
      if (this.$route.query.relationship_code) promises.push(this.getRelationship());
      if (this.$route.query.regulatory_report_config_id) promises.push(this.getReportConfig());

      if (!hardcodedTypes.includes(this.type)) {
        promises.push(this.getModuleName(this.type));
      }

      Promise.all(promises).then(() => {
        this.loading = false;
      });
    }
  }
