import type { BaseQuestionOptions } from './base-question-options';

export interface DetailQuestionOptions extends BaseQuestionOptions {
  method: string;
  sub_form_question_system_code: string;
}

export const DETAILS_MAPPING: Record<
  'single_person_selector' | 'location' | 'organization',
  { available_methods: Record<string, string>; name: string }
> = {
  single_person_selector: {
    name: 'User',
    available_methods: {
      first_name: 'First Name',
      last_name: 'Last Name',
      profile_other_names: 'Other Names',
      email: 'Email',
      mobile: 'Mobile',
      profile_home_number: 'Home Number',
      position: 'Position',
      gender_identity_identity: 'Gender',
      title: 'Title',
      formatted_date_of_birth: 'DOB',
      profile_residential_address_1: 'Residential Address Line 1',
      profile_residential_address_2: 'Residential Address Line 2',
      profile_suburb: 'Suburb',
      profile_postcode: 'Postcode',
      payroll_identifier: 'Payroll Identifier',
      profile_state_display: 'State',
      profile_country_display: 'Country',
      profile_personal_email: 'Personal Email',
      role_name: 'Role',
      manager_full_name: 'Manager',
      home_location_name: 'Home Location',
      location_ceiling_name: 'Location Ceiling',
      home_organization_name: 'Home Organization',
      organization_ceiling_name: 'Organization Ceiling',
      formatted_start_date: 'Start Date',
      formatted_end_date: 'End Date',
      type: 'User Type',
      created_at_as_string: 'Created At',
      active_status: 'Active (true/false)',
    },
  },
  location: {
    name: 'Location',
    available_methods: {
      name: 'Name',
      address_line_1: 'Address Line 1',
      address_line_2: 'Address Line 2',
      suburb: 'Suburb',
      postal_code: 'Postcode',
      country: 'Country',
      timezone: 'Timezone',
      state: 'State',
      parent_name: 'Location Parent',
      scheme_name: 'Applicable Scheme',
      created_at_as_string: 'Created At',
    },
  },
  organization: {
    name: 'Organization',
    available_methods: {
      name: 'Name',
      parent_name: 'Organization Parent',
      level: 'Level',
      created_at_as_string: 'Created At',
    },
  },
};
