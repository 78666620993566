
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { Component, Vue } from 'vue-property-decorator';
  import type { AutomationDefinition } from '@app/models/automation-definition';

  import AutomationDefinitionsTable from './automation-definitions-table.vue';

  @Component({
    components: { AutomationDefinitionsTable },
  })
  export default class AutomationDefinitionsPage extends Vue {
    newLink(type: string, injectableArguments?: InjectableArguments) {
      const query = { ...injectableArguments, type, redirect_to: window.location.href };
      return {
        name: 'admin-settings-admin-automation-definition-new',
        query: query,
      };
    }

    logLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-settings-admin-automation-definition-event-occurrences');
    }

    editLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-settings-admin-automation-definition-edit');
    }

    cloneLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-settings-admin-automation-definition-clone');
    }

    link(automationDefinition: AutomationDefinition, name: string) {
      return {
        name,
        params: { automationDefinitionId: String(automationDefinition.id) } as Record<string, string>,
        query: { redirect_to: window.location.href } as Record<string, string>,
      };
    }
  }
