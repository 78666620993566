
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import SvgParser from 'svgson';
  import Select2 from '../../select2.vue';
  import { findComponents } from './svg-traverse';
  import SvgComponentEditor from './svg-component-editor.vue';
  import AttachmentsUploader from '../../attachment/attachments-uploader.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import { cloneDeep, omit } from 'lodash';
  import EntitySelector from '../../entity-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { SvgLibraryComponent } from '@app/models/svg-library/component';
  import type { SvgLibraryStaticTemplate } from '@app/models/svg-library/static-template';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      Select2,
      EntitySelector,
      SvgComponentEditor,
      AttachmentsUploader,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class SvgLibraryTemplateForm extends Vue {
    @Prop(Object) readonly svgTemplate?: Partial<SvgLibraryTemplate>;

    @Ref() readonly uploader!: AttachmentsUploader;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    svgContent = '';
    allowUploads = true;
    fileReader = new FileReader();
    form: Partial<SvgLibraryTemplate> = {};
    staticTemplates: SvgLibraryStaticTemplate[] = [];
    staticTemplateId: Nullable<string> = null;

    selectStaticTemplate(staticTemplateId: string): void {
      const staticTemplate = this.staticTemplates.find((t) => t.id === staticTemplateId);
      this.$nextTick(() => (this.staticTemplateId = null));
      if (staticTemplate) {
        this.$api.getSvgStaticTemplateFile(staticTemplate.url).then(({ data: svgFileContents }) => {
          const blob = new Blob([svgFileContents], { type: 'text/txt' });
          this.$nextTick(() => this.uploader.addFile(blob));
        });
      }
    }

    onFileLoad(): void {
      const svgFileContents = atob((this.fileReader.result as string).split(',', 2)[1]);
      this.svgContent = svgFileContents;
      this.parseSvgFile(svgFileContents);
    }

    parseSvgFile(svgFileContents: string): void {
      SvgParser.parse(svgFileContents)
        .then((parsedSvg) => {
          return findComponents(parsedSvg).map(
            ({ id, title }) =>
              ({
                label: title,
                code: id,
                path: id,
              } as SvgLibraryComponent)
          );
        })
        .then((components) => {
          this.form = {
            ...this.form,
            components: components,
          };
          if (components.length) {
            this.validator?.refs?.template?.reset();
          } else {
            this.validator?.refs?.template?.validate(null).then(() => {
              this.validator?.refs?.template?.setErrors([this.$t('app.labels.invalid_svg_template_components') as string]);
            });
          }
        })
        .catch((error) => {
          toaster({ text: error.message, icon: 'error' });
          console.error(error);
        });
    }

    onBlobCreate(event: ActiveStorageBlob & { blob: File }): void {
      this.form = {
        ...this.form,
        file: event.signed_id,
      };
      this.fileReader.readAsDataURL(event.blob);
      this.validator?.refs.template.validate(event.blob);
    }

    onBlobDelete(): void {
      this.form = omit(this.form, 'file');
      this.svgContent = '';
      this.validator?.refs.template.reset();
    }

    beforeMount(): void {
      this.fileReader.addEventListener('load', this.onFileLoad);

      this.$api.getSvgStaticTemplates({ cache: true }).then(({ data }) => {
        this.staticTemplates = data;
      });

      if (this.svgTemplate) {
        this.form = cloneDeep(this.svgTemplate);

        if (this.svgTemplate.id) {
          this.$api.getSvgTemplateFile(this.svgTemplate.id).then(({ data }) => {
            this.svgContent = data;
            this.allowUploads = false;
          });
        }
      }
    }

    beforeDestroy(): void {
      this.fileReader.removeEventListener('load', this.onFileLoad);
    }

    submit(): void {
      this.validator?.validate({ silent: false }).then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }
  }
