
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import MobileAppConfigForm from '../../../components/admin/mobile-apps/mobile-app-config-form.vue';
  import type { MobileAppConfig } from '@app/models/mobile-app/config';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppConfigForm, DsModal } })
  export default class EditConfigPage extends Vue {
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Ref() readonly form?: MobileAppConfigForm;

    config: Nullable<MobileAppConfig> = null;

    visible = true;

    beforeMount(): void {
      this.$api.getMobileAppConfig(this.mobileAppConfigId, {}, { cache: true }).then(({ data }) => {
        this.config = data;
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: { mobileAppConfigId: `${this.mobileAppConfigId}`, reload: command },
      });
    }

    onFormSubmit(config: MobileAppConfig): void {
      this.$api
        .updateMobileAppConfig(config.id, config)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
