
  import { Component, Prop } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import { get } from 'lodash';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    confidentiality_type_id: string;
    lockable_id: string;
    user_id: string;
  }

  interface InjectableArguments {
    concept_name: string;
    relationship_code: string;
    sub_form_id: number;
  }

  @Component({ components: { Select2, EntitySelector, EventInputMethodSwitch, AdvancedAutosuggest, FormField } })
  export default class ConfidentialityEventForm extends BaseEventForm<FormValues> {
    @Prop(Object) readonly injectableArguments!: InjectableArguments;

    get confidentialityTypesFilters(): DonesafeFilterOptions<ConfidentialityType> {
      return {
        active: true,
      };
    }

    groupByModuleName(option: Pick<ConfidentialityType, 'id' | 'name' | 'module_name'>): string {
      return `${get(option, 'module_name.display')} (${get(option, 'module_name.name')})`;
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
