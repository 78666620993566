import { Component, Prop, Vue } from 'vue-property-decorator';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

@Component
export default class BaseShowOptions<T extends BaseQuestionOptions> extends Vue {
  @Prop() readonly question!: SubFormQuestion<T>;

  get config() {
    return this.question.config;
  }
}
