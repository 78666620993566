
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { BaseTable } from '../base-table';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import RegulatoryReportConfigFormModal from '../regulatory-report/regulatory-report-config-form-modal.vue';
  import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { RegulatoryReportConfigFormModal, BaseTable } })
  export default class AdminRegulatoryReportingPage extends Vue {
    @Ref() readonly table?: BaseTable<RegulatoryReportConfig>;

    manager = new ListManager<RegulatoryReportConfig>({
      fetchDataFunction: (params) => {
        return this.$api.getRegulatoryReportConfigs({ ...params, filters: { active: true } }, { cache: true });
      },
      useHistory: true,
      per_page: 25,
      fields: [
        { title: 'Extract type', name: 'name', sortField: 'name' },
        {
          title: 'Configure',
          width: '100px',
          dataClass: 'text-center',
          name: 'configure',
          hidden: !this.currentUserStore.isDefaultTechAdmin,
        },
      ],
    });

    selectedConfig: Nullable<RegulatoryReportConfig> = null;
    configModalVisible = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    editConfig(config: RegulatoryReportConfig): void {
      this.selectedConfig = config;
      this.configModalVisible = true;
    }

    onSubmit(config: RegulatoryReportConfig): void {
      if (this.selectedConfig) {
        this.$api
          .updateRegulatoryReportConfig(this.selectedConfig.id, config)
          .then(() => {
            this.$api.cache.clear();
            this.configModalVisible = false;
            this.selectedConfig = null;
            this.table?.reload();
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          });
      }
    }
  }
