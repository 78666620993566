
  import { Component, Model, Prop } from 'vue-property-decorator';
  import { ValidationProvider } from 'vee-validate';
  import BaseDsInput from '@app/mixins/base-ds-input';

  const VALID_TYPES = ['url', 'text', 'password', 'tel', 'search', 'number', 'email'];
  type ValidType = (typeof VALID_TYPES)[number];

  @Component({ components: { ValidationProvider } })
  export default class DsTextInput extends BaseDsInput {
    @Model('input') readonly value!: string | number;
    @Prop([String, Object]) readonly rules?: string | object;
    @Prop({ validator: (value: string) => VALID_TYPES.includes(value) })
    readonly type!: ValidType;
    @Prop(String) readonly placeholder?: string;
    @Prop(Number) readonly step?: number;
  }
