
  import CommentForm from '@app/components/comment/comment-form.vue';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { SubmitCommentEvent } from '@app/services/api/comments-api';
  import type { Comment } from '@app/models/comment';

  @Component({ components: { CommentForm, DsModal } })
  export default class CommentFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly comment?: Comment;
    @Prop(String) readonly title?: string;
    @Prop(Boolean) readonly disabled?: boolean;
    @Ref() readonly form?: CommentForm;
    @Prop({ default: 'comment' }) readonly commentMode!: SubmitCommentEvent['mode'];

    reset(): void {
      this.form?.reset();
      this.form?.focusTextarea();
    }
  }
