
  import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
  import BuilderMenu from './components/builder-menu.vue';
  import LogicSetEditor from './containers/logic-set-editor.vue';
  import LogicSetSelector from './containers/logic-set-selector.vue';
  import FormsUsingLogicSet from './containers/forms-using-logic-set.vue';
  import VariableMapping from './containers/variable-mapping.vue';
  import DisplaySettings from './containers/display-settings.vue';
  import DisplayItems from './containers/display-items.vue';
  import type { AxiosPromise } from 'axios';
  import type {
    RuleBuilderModes,
    LogicSetDefinitions,
    Config,
    DisplaySettingsObject,
    DisplayItemValue,
    Formula,
    Rules,
    Components,
    Variables,
    VariableMap,
    ElementType,
  } from './models/types';
  import { LogicElementTypes, DEFAULT_DISPLAY_SETTINGS } from './models/types';
  import { isString } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { CalculatorQuestionOptions } from '@app/models/question-options/calculator-question-options';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';

  @Component({
    components: {
      BuilderMenu,
      LogicSetEditor,
      DisplaySettings,
      VariableMapping,
      FormsUsingLogicSet,
      LogicSetSelector,
      DisplayItems,
    },
  })
  export default class RuleBuilder extends Vue {
    @Prop() readonly logicSetId!: Nullable<string>;
    @Prop({ required: true }) readonly calcValueSubFormQuestions!: SubFormQuestion[];
    @Prop({ required: true }) readonly config!: CalculatorQuestionOptions;

    @Emit('update')
    updateQuestionConfig(newConfigItems: Partial<CalculatorQuestionOptions>): CalculatorQuestionOptions {
      return { ...this.config, ...newConfigItems };
    }

    @Watch('displayItems')
    onDisplayItemsChanged(displayItems: DisplayItemValue[]): void {
      if (JSON.stringify(displayItems) != this.config.items_to_display) {
        this.updateQuestionConfig({ items_to_display: JSON.stringify(displayItems) });
      }
    }

    textEditMode = false;
    mode: Nullable<RuleBuilderModes> = null;
    logicSetDefinitions: LogicSetDefinitions & {
      logicSetId: Nullable<string>;
    } = {
      logicSetId: null,
      parentRule: {
        color: '#000000',
        name: '',
        formula: [],
      },
      components: {},
      rules: {},
      variables: {},
      configs: {},
    };

    configs: Config = {};
    overrideQuestion = '';
    variableMapping: VariableMap = {};
    displayItems: DisplayItemValue[] = [];
    displaySettings: DisplaySettingsObject = {};
    savePending = false;
    formsUsingLogicSetLoading = false;
    formsUsingLogicSet: string[] = [];

    switchPage(mode: RuleBuilderModes): void {
      this.confirmChangeTab(() => {
        this.updateSavePending(false, () => (this.mode = mode));
      });
    }

    confirmChangeTab(callback: () => void): void {
      if (this.savePending) {
        const changeTabConfirmation = confirm(
          'Changes have been made to this element. Are you sure to proceed without saving the changes?'
        );
        if (changeTabConfirmation) {
          this.savePending = false;
          this.$nextTick(() => {
            callback();
          });
        }
      } else {
        callback();
      }
    }

    selectLogicSet(skipWarningMessage?: boolean): void {
      if (!skipWarningMessage && Object.keys(this.logicSetDefinitions).length) {
        const selectLogicConfirmation = confirm(
          'Selecting a new logic set will reset all settings (variables mapping, display items and result display settings). Are you sure to continue?'
        );
        if (!selectLogicConfirmation) return;
      }

      this.savePending = false;

      this.$nextTick(() => {
        this.updateQuestionConfig({
          logic_set_id: '',
          variables_mapping: '{}',
          configs: '{}',
          display_settings: DEFAULT_DISPLAY_SETTINGS,
          items_to_display: '[]',
          override_question: '',
        });

        this.logicSetDefinitions = {
          logicSetId: null,
          parentRule: {
            color: '#000000',
            name: '',
            formula: [],
          },
          components: {},
          rules: {},
          variables: {},
          configs: {},
        };
        this.overrideQuestion = '';
        this.variableMapping = {};
        this.displayItems = [];
        this.displaySettings = {};
        this.configs = {};
        this.mode = 'selectLogicSet';
      });
    }

    loadFormula(): void {
      if (this.logicSetId) {
        this.getFormsUsingLogicSet(this.logicSetId);

        this.$api
          .getLogicSet(this.logicSetId)
          .then(({ data }) => {
            if (data.active) {
              const formulaDefinitions = data.definitions;

              this.setFormulas(String(data.id), formulaDefinitions);
              this.cleanInvalidVariableMappings(formulaDefinitions.variables);
              this.cleanInvalidDisplayItems(formulaDefinitions.rules, formulaDefinitions.components, formulaDefinitions.variables);
            } else {
              this.selectLogicSet();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    setFormulas(id: string, logicSetDefinitions: LogicSetDefinitions): void {
      this.getFormsUsingLogicSet(id);

      this.updateQuestionConfig({
        logic_set_id: id,
      });

      this.logicSetDefinitions = { ...logicSetDefinitions, logicSetId: id };
      this.mode = 'logicSetEditor';
    }

    cleanInvalidVariableMappings(variablesObject: Variables): void {
      const formQuestionIds = this.calcValueSubFormQuestions.map(
        (calcValueSubFormQuestion: SubFormQuestion) => calcValueSubFormQuestion.id
      );

      if (this.overrideQuestion && formQuestionIds.indexOf(JSON.parse(this.overrideQuestion).id) < 0) this.updateOverrideQuestion('none'); // remove override mapping as question does not exist

      Object.keys(this.variableMapping).map((variableId) => {
        if (!variablesObject[variableId]) {
          // remove variable mapping as variable does not exist
          this.updateVariableMapping('none', variableId);
        } else {
          const value = this.variableMapping[variableId];
          const mappedQuestionId = isString(value) ? JSON.parse(value).id : (value as VariableMap).id;
          if (formQuestionIds.indexOf(mappedQuestionId) === -1) this.updateVariableMapping('none', variableId); // remove variable mapping as question does not exist
        }
      });
    }

    cleanInvalidDisplayItems(rulesObject: Rules, componentsObject: Components, variablesObject: Variables): void {
      const elementIds = ['parent_rule']
        .concat(Object.keys(rulesObject))
        .concat(Object.keys(componentsObject))
        .concat(Object.keys(variablesObject));

      this.displayItems.map((elementId) => {
        if (elementIds.indexOf(elementId) === -1) this.toggleDisplayItem(elementId); // remove display item as formula does not exist
      });
    }

    updateOverrideQuestion(value: string): void {
      const overrideQuestion = value === 'none' ? '' : value;

      this.overrideQuestion = overrideQuestion;
      this.updateQuestionConfig({ override_question: overrideQuestion });
    }

    updateVariableMapping(value: string, name: string): void {
      const newVariableMapping = objectCloneDeep(this.variableMapping);
      value === 'none' ? delete newVariableMapping[name] : (newVariableMapping[name] = value);

      const update = { variables_mapping: JSON.stringify(newVariableMapping) };
      this.variableMapping = newVariableMapping;
      this.updateQuestionConfig(update);
    }

    updateConfigs(name: string, checked: boolean): void {
      const newConfigs = objectCloneDeep(this.configs);
      !checked ? delete newConfigs[name] : (newConfigs[name] = checked);

      this.configs = newConfigs;
      this.updateQuestionConfig({ configs: JSON.stringify(newConfigs) });
    }

    toggleDisplayItem(elementId: DisplayItemValue): void {
      const displayItems = objectCloneDeep(this.displayItems);
      const displayItemIndex = displayItems.indexOf(elementId);

      displayItemIndex === -1 ? displayItems.push(elementId) : displayItems.splice(displayItemIndex, 1);

      this.displayItems = displayItems;
      this.updateQuestionConfig({ items_to_display: JSON.stringify(displayItems) });
    }

    removeDisplayItem(elementId: DisplayItemValue): void {
      if (this.displayItems.indexOf(elementId) !== -1) this.toggleDisplayItem(elementId);
    }

    updateSavePending(state: boolean, callback?: () => void): void {
      this.savePending = state;
      this.$nextTick(() => {
        if (callback) callback();
      });
    }

    getElementType(logicElementValue: string | Formula): ElementType {
      if (logicElementValue.constructor === Array || logicElementValue === '( )') {
        return LogicElementTypes.BRACKET;
      } else if (logicElementValue[0] === '@') {
        return LogicElementTypes.COMPONENT;
      } else if (logicElementValue[0] === '#') {
        return LogicElementTypes.VARIABLE;
      } else if (logicElementValue[0] === '~') {
        return LogicElementTypes.RULE;
      } else if (typeof logicElementValue === 'string' && ['+', '-', '*', '/', '^'].indexOf(logicElementValue) !== -1) {
        return LogicElementTypes.OPERATOR;
      } else if (typeof logicElementValue === 'string' && ['<', '>', '<=', '>=', '='].indexOf(logicElementValue) !== -1) {
        return LogicElementTypes.COMPARISON;
      } else if (typeof logicElementValue === 'string' && ['IF', 'ELSIF', 'ELSE'].indexOf(logicElementValue) !== -1) {
        return LogicElementTypes.IFELSE;
      } else if (logicElementValue === 'Text' || logicElementValue[0] === '"') {
        return LogicElementTypes.TEXT;
      } else {
        return isNaN(Number(logicElementValue)) ? LogicElementTypes.INVALID : LogicElementTypes.NUMBER;
      }
    }

    getFormsUsingLogicSet(logicSetId: string): void {
      this.formsUsingLogicSetLoading = true;
      this.$nextTick(() => {
        this.formsUsingLogicSetEndPoint(logicSetId)
          .then(({ data }) => {
            this.formsUsingLogicSetLoading = false;
            this.formsUsingLogicSet = data.result;
          })
          .catch((error) => {
            console.error(error);
            this.formsUsingLogicSetLoading = false;
          });
      });
    }

    formsUsingLogicSetEndPoint(logicSetId: string): AxiosPromise {
      return this.$api.getSubFormsUsingLogicSet(logicSetId);
    }

    archiveFormulaFromFormulaEditor(): void {
      if (this.logicSetDefinitions?.logicSetId) this.archiveFormula(this.logicSetDefinitions.logicSetId, () => this.selectLogicSet(true));
    }

    archiveFormula(logicSetId: string, callback: () => void): void {
      const archiveFormulaConfirmation = confirm(
        'Any questions using this logic set will not be able to use it after archiving. Are you sure to proceed?'
      );

      if (archiveFormulaConfirmation) {
        this.$api
          .archiveLogicSet(logicSetId)
          .then(() => {
            if (!!callback) callback();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    get logicSetSelected(): boolean {
      return !!this.logicSetId;
    }

    mounted(): void {
      this.overrideQuestion = this.config.override_question;
      try {
        this.variableMapping = isString(this.config.variables_mapping)
          ? JSON.parse(this.config.variables_mapping as string)
          : this.config.variables_mapping;
      } catch {
        this.variableMapping = {};
      }

      this.configs = JSON.parse(this.config.configs as string);
      this.displayItems = JSON.parse(this.config.items_to_display as string);
      this.displaySettings = JSON.parse(this.config.display_settings as string);

      this.$nextTick(() => {
        this.logicSetId ? this.loadFormula() : this.selectLogicSet(true);
      });
    }
  }
