
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { Relationship } from '@app/models/relationship';
  import type { RecordRelationQuestionOptions } from '@app/models/question-options/record-relation-question-options';

  @Component({ components: {} })
  export default class RecordRelationShowOptions extends BaseShowOptions<RecordRelationQuestionOptions> {
    relationship: Partial<Pick<Relationship, 'name' | 'code'>> = { name: '' };

    beforeMount() {
      !!this.config.relationship_code &&
        this.$api
          .getRelationshipsWithSharedFilter({ only: ['name', 'code'] }, 'code', this.config.relationship_code, { cache: true })
          .then(({ data }) => {
            if (!!data.length) this.relationship = data[0];
          });
    }
  }
