
  import { Component, Model, Vue, Prop } from 'vue-property-decorator';

  import Switcher from '../../switcher.vue';
  import FormField from '../questions/edit/form-field.vue';

  type Mode = 'select' | 'custom';

  @Component({ components: { Switcher, FormField } })
  export default class EventInputMethodSwitch extends Vue {
    @Model('input') readonly value!: object | string;
    @Prop(Array) readonly options?: Record<string, string>[];
    @Prop(String) readonly defaultMode?: Mode;
    @Prop(String) readonly valueKey?: string;
    @Prop(String) label?: string;
    @Prop(String) forId?: string;
    @Prop(Boolean) required?: boolean;

    mode: Mode = 'custom';

    get valueInOptions(): boolean {
      return this.options?.some((item) => item[this.valueKey || 'key'] + '' === this.value + '') || false;
    }

    get enabled(): boolean {
      return this.mode === 'select';
    }

    onToggle(value: true): void {
      this.$emit('input', undefined);
      this.mode = value ? 'select' : 'custom';
    }

    beforeMount(): void {
      if (
        this.value &&
        (typeof this.value === 'object' ||
          (typeof this.value === 'string' && ['~', '^'].indexOf(this.value[0]) > -1) ||
          this.valueInOptions)
      ) {
        this.mode = 'select';
      } else {
        this.mode = (!this.value && this.defaultMode) || 'custom';
      }
    }
  }
