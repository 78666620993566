
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import CollapsedText from './collapsed-text.vue';
  import Select2 from '@app/components/select2.vue';
  import { ValidationProvider } from 'vee-validate';

  @Component({ components: { CollapsedText, Select2, ValidationProvider } })
  export default class RuleFilterSelect extends Vue {
    @Inject() qb!: DsQueryBuilderHandler;
    @Prop() readonly rule!: DsRuleNode;

    get rules() {
      return {
        required: true,
        is_not: this.qb.missedRuleIds,
      };
    }
  }
