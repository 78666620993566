// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application', defer: false %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'core-js/proposals/string-match-all'; // for Safari 12 and older versions
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'custom-event-polyfill';
import '@app/services/rollbar';

import 'jquery';
import 'jquery-ujs';
import 'form-serializer';
import 'webpack-jquery-ui';
import 'webpack-jquery-ui/css';
import 'bootstrap-sass';
import 'big.js';
import 'jquery-toast-plugin';
import 'qs-parser';
// eslint-disable-next-line
import 'lodash';
import '../intercom';
import '../froala';
import I18n from './../i18n';
import Panzoom from '../services/panzoom/panzoom';
import FroalaEditor from 'froala-editor';
import SignaturePad from 'signature_pad';
import CaretCoordinates from 'textarea-caret-position';
import consumer from '../channels/consumer';
import '../modernizr';
import Chartkick from 'chartkick';
import Highcharts from 'highcharts';
import ClipboardJS from 'clipboard';
import Bootbox from 'bootbox';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch.min.js';
import '../legacy';

// Todo: Move to webpack config?
import 'imports-loader?define=>false&module=>false!gasparesganga-jquery-loading-overlay';
import 'imports-loader?this=>window&define=>false&module=>false!jquery-extendext';
import 'imports-loader?this=>window&define=>false&module=>undefined!parsleyjs';
import 'imports-loader?this=>window&define=>false&exports=>undefined!easy-pie-chart/dist/jquery.easypiechart';
import 'imports-loader?define=>false&exports=>undefined!jquery.flowchart/jquery.flowchart.min';

import '@claviska/jquery-minicolors';

// styles
import '../styles/application.scss';
import '../vue-sections';
import { API } from '@app/services/api-factory';

import * as ActiveStorage from '@rails/activestorage';
import { areAddressesEqual } from '../services/location';

API.setToken(window.DONESAFE.authentication_token || '');

ActiveStorage.start();
window.FLASH_ALERT_DURATION = parseInt(String(process.env.FLASH_ALERT_DURATION || 4000));
window.SignaturePad = SignaturePad;
window.I18n = I18n;
window.consumer = consumer;
window.Chartkick = Chartkick;
window.FroalaEditor = FroalaEditor;
window.CaretCoordinates = CaretCoordinates;
window.Panzoom = Panzoom;
window.ClipboardJS = ClipboardJS;
window.bootbox = Bootbox;
window.areAddressesEqual = areAddressesEqual;
$(() => new ClipboardJS('.copy'));
Chartkick.addAdapter(Highcharts);

$(() => {
  $.fn.modal.Constructor.prototype.enforceFocus = $.noop;

  if ($.fn.select2) {
    $('select.select2').select2();
  }
});
