
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import ActivitiesList from '@app/components/activities/activities-list.vue';
  import SidePanel from '@app/components/side-panel.vue';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import { entityPathPrefix } from '@app/services/helpers';
  import { pick } from 'lodash';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import ActionFilterIndexDropdown from '../admin/actions/action-filter-index-dropdown.vue';
  import type { ActivitiesStats, Activity } from '@app/models/activity';
  import type { IndexFilter } from '@app/models/index-filter';
  import type { ModuleName } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';

  @Component({
    components: { ActivitiesList, SidePanel, DsDropdown, ActionFilterIndexDropdown },
    methods: { entityPathPrefix },
  })
  export default class ActivitiesPanel extends Vue {
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(String) readonly entityType!: string;
    @Prop(Number) readonly entityId!: number;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'name'>;
    @Ref() readonly activitiesList?: ActivitiesList;

    modalVisible = false;
    loading = false;
    activitiesListLoading = false;
    indexFilter: Nullable<Partial<IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>>> = null;
    activitiesStats: Nullable<ActivitiesStats> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get indexFilterIndex(): string {
      return `actions_${this.moduleName.id}`;
    }

    get canAddActivities(): boolean {
      return !!this.canEdit && this.currentUserStore.canCreateActivity;
    }

    get actionsFilter(): DonesafeFilterOptions<Activity, DonesafeActivitiesApiExtraOptions> {
      return {
        ...pick(this.indexFilter?.filter || {}, ['scope_filter', 'status_filter']),
        actionable_type: this.entityType,
        actionable_id: this.entityId,
      };
    }
    get caption(): string {
      let caption = null;

      switch (this.indexFilter?.filter?.scope_filter) {
        case 'mine':
          caption =
            this.indexFilter?.filter?.status_filter === 'open'
              ? this.$t('tenant.activities.caption.my_open', { openCount: this.activitiesStats?.my_open })
              : this.$t('tenant.activities.caption.my_all', { openCount: this.activitiesStats?.my_open });
          break;
        case 'all':
          caption =
            this.indexFilter?.filter?.status_filter === 'open'
              ? this.$t('tenant.activities.caption.all_open', { openCount: this.openCount })
              : this.$t('tenant.activities.caption.all', { openCount: this.openCount });
          break;
      }

      return caption || this.$t('app.labels.actions');
    }

    get plusOpen(): string {
      const diff = (this.activitiesStats?.total_open || 0) - (this.activitiesStats?.my_open || 0);

      return this.$t('tenant.activities.caption.plus_open', { openCount: diff });
    }

    get plusHidden(): string {
      const diff = (this.activitiesStats?.total_open || 0) - this.openCount;

      return this.$t('tenant.activities.caption.plus_hidden', { openCount: diff });
    }

    get openCount(): number {
      if (this.indexFilter?.filter?.scope_filter === 'mine') {
        return this.activitiesStats?.my_open || 0;
      } else {
        return this.activitiesStats?.accessible_open || 0;
      }
    }

    get showPlusOpen(): boolean {
      return (
        this.indexFilter?.filter?.scope_filter === 'mine' && (this.activitiesStats?.total_open || 0) > (this.activitiesStats?.my_open || 0)
      );
    }

    get showPlusHidden(): boolean {
      return this.indexFilter?.filter?.scope_filter === 'all' && (this.activitiesStats?.total_open || 0) > this.openCount;
    }

    @Watch('$route.query.reload')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.activitiesList?.fetchActivities();
        this.$router.replace(location.pathname);
      }
    }

    clickPlusOpen(): void {
      if (this.indexFilter?.filter) {
        this.indexFilter.filter = { ...this.indexFilter.filter, scope_filter: 'all' };
      }
    }

    toggleModal(): void {
      this.activitiesList?.addNewActivity({ actionable_type: this.entityType, actionable_id: this.entityId });
    }

    beforeMount(): void {
      this.$api.getActivitiesStats({ actionable_id: this.entityId, actionable_type: this.entityType }, { cache: true }).then(({ data }) => {
        this.activitiesStats = data;
      });
    }
  }
