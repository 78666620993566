import { AdminPermissionFeature } from '@app/models/admin-permission';
import { Vue, Component } from 'vue-property-decorator';
import { useCurrentUserStore } from '@app/stores/currentUser';
import { useAccountStore } from '@app/stores/account';
import type { TenantUser } from '@app/models/tenant-user';

@Component
export default class WithAdminPermissions extends Vue {
  get adminPermissionNames(): Record<AdminPermissionFeature, string> {
    return {
      [AdminPermissionFeature.users_locations_organizations]: this.$t('tenant.admin.settings.menu_groups.users_locations_organizations'),
      [AdminPermissionFeature.roles_and_tags]: this.$t('tenant.admin.settings.menu_groups.roles_and_tags'),
      [AdminPermissionFeature.module_config]: this.$t('tenant.admin.settings.menu_groups.module_config'),
      [AdminPermissionFeature.reporting]: this.$t('tenant.admin.settings.menu_groups.reporting'),
      [AdminPermissionFeature.system_administration]: this.$t('tenant.admin.settings.menu_groups.system_administration'),
      [AdminPermissionFeature.look_and_feel]: this.$t('tenant.admin.settings.menu_groups.look_and_feel'),
      [AdminPermissionFeature.record_importing]: this.$t('tenant.admin.settings.menu_groups.record_importing'),
      [AdminPermissionFeature.regulatory_reporting]: this.$t('tenant.admin.settings.menu_groups.regulatory_reporting'),
      [AdminPermissionFeature.hybrid_modules]: this.$t('tenant.admin.settings.menu_groups.hybrid_modules'),
      [AdminPermissionFeature.translations]: this.$t('tenant.admin.settings.menu_groups.translations'),
    };
  }

  get adminPermissionDescriptions(): Record<AdminPermissionFeature, string> {
    return {
      [AdminPermissionFeature.users_locations_organizations]: this.$t(
        'tenant.admin.settings.administrative_roles.modal.descriptions.users_locations_organizations'
      ),
      [AdminPermissionFeature.roles_and_tags]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.roles_and_tags'),
      [AdminPermissionFeature.module_config]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.module_config'),
      [AdminPermissionFeature.reporting]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.reporting'),
      [AdminPermissionFeature.system_administration]: this.$t(
        'tenant.admin.settings.administrative_roles.modal.descriptions.system_administration'
      ),
      [AdminPermissionFeature.look_and_feel]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.look_and_feel'),
      [AdminPermissionFeature.record_importing]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.record_importing'),
      [AdminPermissionFeature.regulatory_reporting]: this.$t(
        'tenant.admin.settings.administrative_roles.modal.descriptions.regulatory_reporting'
      ), // for administrative roles index permission filter
      [AdminPermissionFeature.hybrid_modules]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.hybrid_modules'),
      [AdminPermissionFeature.translations]: this.$t('tenant.admin.settings.administrative_roles.modal.descriptions.translations'),
    };
  }

  get currentUserStore() {
    return useCurrentUserStore();
  }

  get accountStore() {
    return useAccountStore();
  }
  showInactiveModuleConfigLabel(row: Partial<TenantUser>): string | undefined {
    const features = row.admin_permission_features || [];

    if (this.currentUserStore.isTechAdmin && !this.accountStore.moduleConfigForNonTechAdmins) {
      const hasModuleConfigFeature = features.some((feature) => feature === AdminPermissionFeature.module_config);
      if (hasModuleConfigFeature) {
        return this.adminPermissionNames[AdminPermissionFeature.module_config];
      }
    }
  }

  moduleConfigDisabledForNonTechAdmins(feature: AdminPermissionFeature): boolean {
    return (
      feature === AdminPermissionFeature.module_config &&
      !this.currentUserStore.isTechAdmin &&
      !this.accountStore.moduleConfigForNonTechAdmins
    );
  }
}
