
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { RoleTabDetailsModuleNameOnly, RoleTabDetailsRoleOnly, CustomRightsForm, RoleTabDetailsRoleProfileOnly } from './utils';
  import { UiRoleProfileIds, areProfileRolePermsEqual, noRightsPerms } from './utils';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import RelationshipRightsTable from './relationship-rights-table.vue';
  import Switcher from '@app/components/switcher.vue';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import type { ModuleName } from '@app/models/module-name';
  import type { Permission } from '@app/models/permission';
  import type { PermissionProvider, Role } from '@app/models/role';
  import type { RoleProfile } from '@app/models/role-profile';
  import { ConfigurationType } from '@app/models/role-module-name';
  import { GlobalAccess } from '@app/models/extended-permission';
  import { ACTIVITY_CONCEPT } from '@app/constants';
  import SpecialPermissionCheckboxList from '@app/components/extended-permissions/special-permission-checkbox-list.vue';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import { difference, intersection, union, uniq } from 'lodash';

  @Component({
    components: {
      DsModal,
      DsCheckbox,
      RelationshipRightsTable,
      Switcher,
      SpecialPermissionCheckboxList,
      FormField,
      EntitySelector,
    },
  })
  export default class CustomiseRightsModal extends Vue {
    @Model('input', { type: Boolean }) readonly value!: boolean;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, RoleTabDetailsModuleNameOnly>;
    @Prop(Object) readonly role!: Pick<Role, RoleTabDetailsRoleOnly>;
    @Prop(String) readonly roleProfileId!: string | UiRoleProfileIds;
    @Prop(Object) readonly roleProfileById?: Record<string, Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>>;
    @Prop(Array) readonly permissions!: Permission[];
    @Prop(Array) readonly extendedPermissions!: ExtendedPermission[];
    @Prop(Array) readonly moduleDashboardPaneIds!: number[];
    @Prop(Boolean) readonly managerialHierarchyAccess?: boolean;
    @Prop(Boolean) readonly ignoreManagerialHierarchyAccess?: boolean;
    @Prop(Boolean) readonly hideResetButton?: boolean;

    form: Partial<CustomRightsForm> = {};
    confidentialityTypes: Pick<ConfidentialityType, 'name' | 'id' | 'active'>[] = [];
    loading = true;

    get roleProfile(): Maybe<Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>> {
      return this.roleProfileById?.[this.roleProfileId];
    }

    get roleProfileName(): string {
      if (this.roleProfileId === UiRoleProfileIds.customised) return this.$t('tenant.admin.roles.rtb.custom');
      else if (this.roleProfileId === UiRoleProfileIds.none) return this.$t('tenant.admin.roles.rtb.no_rights_user');
      else return this.roleProfile?.name || '';
    }

    get resetButtonShown(): boolean {
      return !this.hideResetButton && !this.samePermissions;
    }

    get permissionProvider(): PermissionProvider {
      if (this.roleProfileId === UiRoleProfileIds.customised) {
        return {
          extended_permissions: this.role.extended_permissions
            ?.filter(({ concept_name }) => concept_name === this.moduleName.name)
            .map(({ concept_name, name }) => ({ concept_name, name })),
          permissions: this.role.permissions?.filter(({ concept_name }) => concept_name === this.moduleName.name),
        };
      }

      return this.roleProfile || noRightsPerms;
    }

    get samePermissions(): boolean {
      const {
        managerial_hierarchy_access,
        permissions,
        extended_permissions,
        dashboard_pane_ids = [],
        excluded_dashboard_pane_ids = [],
      } = this.form;

      const sameMha = this.roleProfileById ? managerial_hierarchy_access == this.permissionProvider.managerial_hierarchy_access : true;

      const samePermissions = areProfileRolePermsEqual(this.moduleName.name, this.permissionProvider, {
        ...noRightsPerms,
        permissions,
        extended_permissions,
      });

      const sameDashboardPanes = !dashboard_pane_ids.length && !excluded_dashboard_pane_ids.length;

      return sameMha && samePermissions && sameDashboardPanes;
    }

    get canCreate(): boolean {
      return this.hasExtendedPermission(GlobalAccess.Create);
    }

    get label(): string {
      const name = this.moduleName.plural_display || this.moduleName.name;
      return name === ACTIVITY_CONCEPT
        ? `${this.$t('app.labels.action')}: ${this.role.name}`
        : this.$t('tenant.admin.roles.rtb.customise_rights_modal.label', {
            concept_name: name,
            profile_name: this.roleProfileName,
            role_name: this.role.name,
          });
    }

    get configuredDashboardPaneIds(): number[] {
      const roleProfileDashboardPaneIds = this.roleProfile?.dashboard_pane_ids || [];

      return difference(
        union(roleProfileDashboardPaneIds, intersection(this.moduleDashboardPaneIds, this.form.dashboard_pane_ids)),
        intersection(this.moduleDashboardPaneIds, this.form.excluded_dashboard_pane_ids)
      );
    }

    get dashboardPaneFilter() {
      return { module_name_id: this.moduleName.id };
    }

    @Emit('submit')
    submit(): Partial<CustomRightsForm> {
      return {
        ...this.form,
        configuration_type: this.roleProfile && this.samePermissions ? ConfigurationType.role_profile_used : ConfigurationType.role_used,
      };
    }

    resetCustomisation(): void {
      const provider = this.permissionProvider;
      this.form = {
        ...this.form,
        permissions: provider.permissions,
        extended_permissions: provider.extended_permissions,
        managerial_hierarchy_access: provider.managerial_hierarchy_access,
        dashboard_pane_ids: [],
        excluded_dashboard_pane_ids: [],
      };
    }

    onRelationshipRightsTableLoaded() {
      this.loading = false;
    }

    onPermissionUpdate(params: { permission: Permission; type: 'add' | 'remove' }): void {
      const { permission, type } = params;
      if (type === 'add') {
        this.form.permissions = [...(this.form.permissions || []), permission];
      } else {
        this.form.permissions = this.form.permissions?.filter(({ id }) => id !== permission.id);
      }
    }

    onDashboardPaneIdsUpdate(ids: number[]) {
      ids = ids.map(Number);

      const updatedByModule = uniq([...ids, ...this.moduleDashboardPaneIds]);
      this.setArray(this.moduleDashboardPaneIds, updatedByModule);

      const roleProfileDashboardPaneIds = this.roleProfile?.dashboard_pane_ids || [];

      const included = difference(ids, roleProfileDashboardPaneIds);
      const excluded = difference(roleProfileDashboardPaneIds, ids);

      if (this.form.dashboard_pane_ids) {
        this.setArray(this.form.dashboard_pane_ids, included);
      }

      if (this.form.excluded_dashboard_pane_ids) {
        this.setArray(this.form.excluded_dashboard_pane_ids, excluded);
      }
    }

    setArray<T>(array: T[], value: T[]) {
      array.length = 0;
      array.push(...value);
    }

    hasExtendedPermission(name: string): boolean {
      return !!this.form.extended_permissions?.find((i) => {
        return i.concept_name === this.moduleName.name && i.name === name;
      });
    }

    setExtendedPermission(name: string, value: boolean): void {
      if (value) {
        this.form.extended_permissions = [
          ...(this.form.extended_permissions || []),
          {
            concept_name: this.moduleName.name,
            name: name,
          },
        ];
      } else {
        this.form.extended_permissions = (this.form.extended_permissions || []).filter((ep) => {
          return !(ep.concept_name === this.moduleName.name && ep.name === name);
        });
      }
    }

    async initForm(): Promise<void> {
      if (this.moduleName.name !== ACTIVITY_CONCEPT) {
        const { data: confidentialityTypes } = await this.$api.getConfidentialityTypes(
          { filters: { module_name: { name: this.moduleName.name } }, only: ['id', 'name', 'active'], per_page: -1 },
          { cache: true }
        );
        this.confidentialityTypes = confidentialityTypes;
      }

      const baseForm = {
        extended_permissions: this.extendedPermissions,
        permissions: this.permissions,
        concept_name: this.moduleName.name,
        role_profile: this.roleProfile,
        dashboard_pane_ids: intersection(this.moduleDashboardPaneIds, this.role.dashboard_pane_ids),
        excluded_dashboard_pane_ids: intersection(this.moduleDashboardPaneIds, this.role.excluded_dashboard_pane_ids),
      };

      this.form = this.ignoreManagerialHierarchyAccess
        ? baseForm
        : { ...baseForm, managerial_hierarchy_access: this.managerialHierarchyAccess };
    }

    async beforeMount(): Promise<void> {
      await this.initForm();
    }

    dashboardPaneSelectTemplate(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, {
        primaryAttribute: 'name',
        secondaryAttribute: 'description',
      });
    }
  }
