
  import { useAccountStore } from '@app/stores/account';
  import type { Subscription } from '@rails/actioncable';
  import { keyBy, uniq } from 'lodash';
  import bootbox from 'bootbox';
  import { ProgressBar, ProgressBarStack, Btn } from 'uiv';
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Attachment } from '@app/models/attachment';
  import type { SuperImport } from '@app/models/super-import';
  import type { TenantUser } from '@app/models/tenant-user';
  import { FINAL_STATES, PAUSABLE_STATES, DELETABLE_STATES, SuperImportState } from '@app/models/super-import';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  import UserImage from '../user-image.vue';
  import consumer from '../../channels/consumer';
  import { BaseTable } from '../../components/base-table';

  import ErrorsCell from './errors-cell.vue';

  @Component({
    components: {
      UserImage,
      BaseTable,
      ProgressBar,
      ProgressBarStack,
      DsDropdown,
      Btn,
      ErrorsCell,
    },
  })
  export default class AdminImportsTable extends Vue {
    @Prop(Object) filters!: DonesafeFilterOptions<SuperImport, { type?: string }>;
    @Prop(Number) perPage?: number;
    @Prop({ type: Array, default: () => PAUSABLE_STATES }) pausableStates!: string[];
    @Prop({ type: Array, default: () => FINAL_STATES }) finalStates!: string[];
    @Prop({ type: Array, default: () => DELETABLE_STATES }) deletableStates!: string[];
    @Ref() readonly table?: BaseTable<SuperImport>;

    usersMap: Record<number, TenantUser> = {};

    superImportSubscription: Nullable<Subscription> = null;
    modalVisible = false;
    modalTitle = 'Errors';
    manager: Nullable<ListManager<SuperImport>> = null;

    get accountStore() {
      return useAccountStore();
    }

    get perPageValue(): number {
      return this.perPage || 20;
    }

    get fields(): ListManagerField<SuperImport>[] {
      return [
        { title: 'ID', name: 'id', sortField: 'id', group: 'info' },
        { title: 'Date', name: 'date', sortField: 'created_at', group: 'info' },
        { title: 'Import Type', width: '200px', name: 'import_type', sortField: 'import_type', group: 'info' },
        { title: 'Creator', name: 'user_id', group: 'info' },
        { title: 'Method', dataClass: 'text-uppercase', name: 'method', sortField: 'method', group: 'info' },
        { title: 'Status', dataClass: 'text-uppercase', name: 'state', sortField: 'state', group: 'info' },
        { title: 'Files', name: 'files', group: 'status' },
        { title: 'Errors', name: 'errors', group: 'status' },
        { title: 'Warnings', name: 'warnings', group: 'status' },
        { title: 'Total Rows', name: 'total', group: 'status' },
        { title: 'Actions', name: 'actions', group: 'status' },
      ];
    }

    getManager(): ListManager<SuperImport> {
      return new ListManager<SuperImport>({
        fetchDataFunction: (params) => {
          const only: OnlyOptions<SuperImport> = [
            'id',
            'batch_id',
            'created_at',
            'start_at',
            'end_at',
            'import_type',
            'import_id',
            'attachments',
            'state',
            'method',
            'stats',
            'title_of_importing',
            'user_id',
          ];
          return this.$api.getSuperImports({ ...params, only, filters: this.filters });
        },
        afterFetch: (data) => {
          const userIds = uniq(data.map((si) => si.user_id).filter(Boolean));
          if (userIds.length) {
            this.$api
              .getTenantUsers(
                { filters: { id: userIds }, only: ['id', 'first_name', 'last_name', 'avatar_url', 'full_name'] },
                { cache: true }
              )
              .then(({ data }) => {
                this.usersMap = keyBy(data, 'id');
              });
          }
        },
        sortOrder: [
          {
            field: 'id',
            sortField: 'id',
            direction: 'desc',
          },
        ],
        useHistory: true,
        per_page: this.perPageValue,
        fields: this.fields,
      });
    }

    reload(): void {
      this.table?.reload();
    }

    canPause(superImport: SuperImport): boolean {
      return this.pausableStates.indexOf(superImport.state) > -1;
    }

    canResume(superImport: SuperImport): boolean {
      return superImport.state === SuperImportState.paused && !superImport.batch_id;
    }

    canCancel(superImport: SuperImport): boolean {
      return this.finalStates.indexOf(superImport.state) === -1;
    }

    canPresentStats(superImport: SuperImport): boolean {
      return [...this.finalStates, SuperImportState.started, SuperImportState.paused].indexOf(superImport.state) >= 0;
    }

    canDelete(superImport: SuperImport): boolean {
      return this.deletableStates.indexOf(superImport.state) >= 0;
    }

    pauseImport(superImport: SuperImport): void {
      this.$api.pauseImport(superImport.id).then(() => {
        toaster({
          text: this.$t('tenant.settings.super_imports.messages.import_paused') as string,
        });
        this.reload();
      });
    }

    resumeImport(superImport: SuperImport): void {
      this.$api.resumeImport(superImport.id).then(() => {
        toaster({
          text: this.$t('tenant.settings.super_imports.messages.import_resumed') as string,
        });
        this.reload();
      });
    }

    cancelImport(superImport: SuperImport): void {
      bootbox.confirm(this.$t('tenant.settings.super_imports.messages.import_cancel_confirm') as string, async (confirmed: boolean) => {
        if (confirmed) {
          this.$api.cancelImport(superImport.id).then(() => {
            this.reload();
            toaster({
              text: this.$t('tenant.settings.super_imports.messages.import_canceled') as string,
            });
          });
        }
      });
    }

    deleteImport(superImport: SuperImport): void {
      bootbox.confirm(this.$t('tenant.settings.super_imports.messages.import_delete_confirm') as string, async (confirmed: boolean) => {
        if (confirmed) {
          this.$api.deleteImport(superImport.id).then(() => {
            this.reload();
            toaster({
              text: this.$t('tenant.settings.super_imports.messages.import_deleted') as string,
            });
          });
        }
      });
    }

    gridColumnStyleFor(groupName: string): Record<string, string> {
      const size = this.fields.filter((f) => f.group === groupName).length;

      return {
        gridColumn: `span ${size}`,
      };
    }

    getImportProgress(superImport: SuperImport, attachment: Attachment): number {
      const statItem = superImport.stats[attachment.id as number];
      if (statItem) {
        const processed = statItem.processed;
        const total = statItem.total;
        if (!total && !processed) {
          return 100;
        } else if (!total || !processed) {
          return 0;
        }
        return Math.round((100 * processed) / total);
      }
      return 0;
    }

    getProgressBarText(superImport: SuperImport, attachment: Attachment): string {
      return `${this.getImportProgress(superImport, attachment)}% - ${attachment.file_name}`;
    }

    getProgressBarType(superImport: SuperImport, attachment: Attachment): 'warning' | 'default' {
      return this.getErrorsCount(superImport, attachment.id as number) ? 'warning' : 'default';
    }

    getErrorsCount(superImport: SuperImport, attachmentId: number): number {
      const stat = superImport.stats[attachmentId];
      return (stat && ((stat.errors && stat.errors.length) || stat.errors_count)) || 0;
    }

    beforeDestroy(): void {
      this.superImportSubscription?.unsubscribe();
    }

    mounted(): void {
      this.manager = this.getManager();

      this.superImportSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'SuperImport' },
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          received: (data: { id: number; state: string; stats: any }) => {
            if (data.id) {
              const importItem = this.manager?.items.find((item) => item.id === data.id);
              if (importItem) {
                if (data.stats) {
                  const stats = { ...importItem.stats, ...data.stats };
                  Vue.set(importItem, 'stats', stats);
                }
                if (data.state) {
                  if ((data.state === 'completed' || data.state === 'failed') && importItem.state !== data.state) {
                    this.reload();
                  }
                  Vue.set(importItem, 'state', data.state);
                  this.$emit('update-state', data.state);
                }
              }
            }
          },
        }
      );
    }
  }
