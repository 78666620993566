
  import { Component } from 'vue-property-decorator';
  import PlaceholderProfilesForm from '@app/components/admin/placeholder-profiles/placeholder-profiles-form.vue';
  import BasePlaceholderProfile from './base-placeholder-profile';
  import type { PlaceholderProfileForm } from './utils';
  import { PlaceholderProfileOauth2AuthType } from './utils';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { PlaceholderProfilesForm, DsModal } })
  export default class PlaceholderProfilesNew extends BasePlaceholderProfile {
    newPlaceholderProfile = { ...this.placeholderProfile, config: { oauth2_auth_type: PlaceholderProfileOauth2AuthType.client_assertion } };

    onFormSubmit(form: PlaceholderProfileForm): void {
      this.blocking(
        async () => {
          await this.$api.createPlaceholderProfile(form);
          this.$api.cache.clear();
          this.closeModal('reload');
        },
        {
          errorCb: this.onSubmitError,
        }
      );
    }
  }
