import { render, staticRenderFns } from "./module-names-list-page.vue?vue&type=template&id=3ff59b72&scoped=true&lang=pug"
import script from "./module-names-list-page.vue?vue&type=script&lang=ts"
export * from "./module-names-list-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ff59b72",
  null
  
)

export default component.exports