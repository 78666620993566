
  import { Tuple } from '@app/utils/types/tuple';
  import { omit } from 'lodash';
  import { ValidationObserver } from 'vee-validate';
  import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import Select2 from '../../../select2.vue';
  import SuccessPageSetupItemForm from './success-page-setup-item-form.vue';
  import type { ModuleName, WizardOptions } from '@app/models/module-name';
  import { isLegacyModule } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';

  const DEFAULT_ICON = 'check_circle';
  const DEFAULT_CUSTOM_LINK_ICON = 'link';

  const SUCCESS_PAGE_MODULE_ONLY = Tuple(['id', 'name', 'display', 'plural_display', 'config', 'wizard_labels', 'feature_set'] as const);

  @Component({ components: { DsModal, Select2, SuccessPageSetupItemForm, ValidationObserver } })
  export default class SuccessPageSetupModal extends Vue {
    @Model('input') value!: boolean;
    @Prop(Number) readonly moduleNameId!: number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    initialized = false;
    isSubmitting = false;
    form: WizardOptions = {};
    moduleName: Nullable<Pick<ModuleName, (typeof SUCCESS_PAGE_MODULE_ONLY)[number]>> = null;

    get isLegacyModule(): boolean {
      return !!this.moduleName && isLegacyModule(this.moduleName);
    }

    validateAndSubmitForm(): void {
      this.validator?.validate().then((result) => {
        result && this.submitForm();
      });
    }

    submitForm(): void {
      if (!this.moduleName) {
        return;
      }
      this.isSubmitting = true;
      const form: Pick<ModuleName, 'id' | 'config' | 'wizard_labels'> = {
        id: this.moduleName.id,
        config: {
          ...this.moduleName.config,
          wizard_options: {
            standard: {
              active: !!this.form.standard?.active,
              icon: this.form.standard?.icon,
              show_links: this.form.standard?.show_links,
              custom_link: {
                active: !!this.form.standard?.custom_link?.active,
                ...omit(this.form.standard?.custom_link || {}, ['text']),
              },
            },
            related_tab: {
              active: !!this.form.related_tab?.active,
              icon: this.form.related_tab?.icon,
              show_links: this.form.related_tab?.show_links,
              custom_link: {
                active: !!this.form.related_tab?.custom_link?.active,
                ...omit(this.form.related_tab?.custom_link || {}, ['text']),
              },
            },
            public: {
              active: !!this.form.public?.active,
              icon: this.form.public?.icon,
              show_links: this.form.public?.show_links,
              custom_link: {
                active: !!this.form.public?.custom_link?.active,
                ...omit(this.form.public?.custom_link || {}, ['text']),
              },
            },
          },
        },
        wizard_labels: {
          standard_text: this.form.standard?.text,
          standard_description: this.form.standard?.description,
          standard_link_text: this.form.standard?.custom_link?.text,

          related_tab_text: this.form.related_tab?.text,
          related_tab_description: this.form.related_tab?.description,
          related_tab_link_text: this.form.related_tab?.custom_link?.text,

          public_text: this.form.public?.text,
          public_description: this.form.public?.description,
          public_link_text: this.form.public?.custom_link?.text,
        },
      };
      this.$api
        .updateModuleName(this.moduleName.id, form, { only: ['id', 'config', 'wizard_labels'] })
        .then(() => {
          toaster(this.$t('app.labels.success_page.submit_success'));
          this.$emit('input', false);
          this.$api.cache.clear();
        })
        .catch((response) => toaster({ text: response.data.error, position: 'top-right', icon: 'error' }))
        .finally(() => {
          this.isSubmitting = false;
        });
    }

    initForm(): void {
      this.$api.getModuleName(this.moduleNameId, { only: SUCCESS_PAGE_MODULE_ONLY }, { cache: true }).then(({ data }) => {
        this.moduleName = data;
        if (!this.moduleName) {
          return;
        }
        const labels = this.moduleName.wizard_labels || {};
        const options = this.moduleName.config.wizard_options || {};
        this.form = {
          standard: {
            active: false,
            icon: options.standard ? '' : DEFAULT_ICON,
            text: labels.standard_text,
            description: labels.standard_description,
            ...options.standard,
            show_links: {
              continue_to: true,
              ...options.standard?.show_links,
            },
            custom_link: {
              active: false,
              icon: DEFAULT_CUSTOM_LINK_ICON,
              text: labels.standard_link_text,
              ...options.standard?.custom_link,
            },
          },
          related_tab: {
            active: false,
            icon: options.related_tab ? '' : DEFAULT_ICON,
            text: labels.related_tab_text,
            description: labels.related_tab_description,
            ...options.related_tab,
            show_links: {
              continue_to: true,
              back_to: true,
              ...options.related_tab?.show_links,
            },
            custom_link: {
              active: false,
              icon: DEFAULT_CUSTOM_LINK_ICON,
              text: labels.related_tab_link_text,
              ...options.related_tab?.custom_link,
            },
          },
          public: {
            active: false,
            icon: options.public ? '' : DEFAULT_ICON,
            text: labels.public_text,
            description: labels.public_description,
            ...options.public,
            show_links: {
              add_another: true,
              ...options.public?.show_links,
            },
            custom_link: {
              active: false,
              icon: DEFAULT_CUSTOM_LINK_ICON,
              text: labels.public_link_text,
              ...options.public?.custom_link,
            },
          },
        };
      });
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.$nextTick(() => {
        this.validator?.reset();
      });
    }
  }
