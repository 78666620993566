
  import { useAccountStore } from '@app/stores/account';
  import bootbox from 'bootbox';
  import { has } from 'lodash';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import { BaseTable } from '@app/components/base-table';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import SearchInput from '@app/components/search-input.vue';

  @Component({ components: { SearchInput, BaseTable, Tooltip } })
  export default class HybridTable extends Vue {
    @Prop(Number) readonly moduleNameId!: number;
    @Ref() readonly table?: BaseTable<SubFormList>;

    manager: Nullable<ListManager<SubFormList>> = null;
    showInListLoadingMap: Record<number, boolean> = {};
    activeToggleMap: Record<number, boolean> = {};
    showInListToggleMap: Record<number, boolean> = {};

    get accountStore() {
      return useAccountStore();
    }

    reloadTable(): void {
      this.$api.cache.clear();
      this.table?.reload();
    }

    getManager(moduleName: Pick<ModuleName, 'name' | 'display'>): ListManager<SubFormList> {
      return new ListManager<SubFormList>({
        fetchDataFunction: (params) => {
          return this.$api.getSubFormLists(
            {
              ...params,
              filters: {
                ...params.filters,
                active: true,
                sub_form_list_type: [SubFormListType.normal],
                module_tab: {
                  code: HYBRID_MODULE_TAB_CODE,
                  module_name: moduleName.name,
                },
              },
              only: ['id', 'title', 'show_in_list', 'number_of_questions', 'active', 'index'],
            },
            { cache: true }
          );
        },
        sortOrder: [
          {
            direction: 'asc',
            field: 'index',
            sortField: 'index',
          },
        ],
        per_page: 25,
        useHistory: true,
        fields: [
          {
            title: this.$t('app.labels.ID'),
            name: 'id',
            width: '60px',
          },
          {
            title: this.$t('tenant.settings.hybrid.index.name_for', { module_name: moduleName.display }),
            name: 'title',
            width: '354px',
          },
          {
            title: this.$t('tenant.settings.hybrid.index.number_of_questions'),
            name: 'number_of_questions',
          },
          {
            title: this.$t('tenant.settings.hybrid.index.show_in_list'),
            name: 'show_in_list',
            width: '120px',
            titleClass: 'justify-content-center',
          },
          {
            title: this.$t('app.labels.actions'),
            titleClass: 'justify-content-end',
            name: 'operations',
            width: '180px',
          },
        ],
      });
    }

    async mounted(): Promise<void> {
      await this.loadTable();
    }

    async loadTable() {
      const { data: moduleName } = await this.$api.getModuleName(
        this.moduleNameId,
        {
          only: ['id', 'name', 'display', 'sub_form_id', 'module_type'],
        },
        { cache: true }
      );

      this.manager = this.getManager(moduleName);
    }

    showInListClass(subFormList: SubFormList): string {
      if (this.showInListLoadingMap[subFormList.id]) {
        return 'fa-spin fa-spinner';
      } else if (this.shownInList(subFormList)) {
        return 'fa-toggle-on icon-on';
      } else {
        return 'fa-toggle-off icon-off';
      }
    }

    setLoading(subFormList: SubFormList, state: boolean): void {
      this.showInListLoadingMap = {
        ...this.showInListLoadingMap,
        [subFormList.id]: state,
      };
    }

    tooltipText(subFormList: SubFormList): string {
      return this.showInListLoadingMap[subFormList.id]
        ? this.$t('app.labels.updating')
        : this.shownInList(subFormList)
        ? this.$t('app.labels.unpublish')
        : this.$t('app.labels.publish');
    }

    shownInList(subFormList: SubFormList): boolean {
      if (has(this.showInListToggleMap, subFormList.id)) return this.showInListToggleMap[subFormList.id];

      return subFormList.show_in_list;
    }

    isActive(subFormList: SubFormList): boolean {
      if (has(this.activeToggleMap, subFormList.id)) return this.activeToggleMap[subFormList.id];

      return subFormList.active;
    }

    showTrashCan(subFormList: SubFormList): boolean {
      if (this.shownInList(subFormList)) return false;

      return this.isActive(subFormList);
    }

    archiveSubFormList(subFormList: SubFormList): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('tenant.admin.sub_form_lists.archive.hybrid_confirm'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api.manageChecklist(subFormList.id, { active: false }).then(({ data }) => {
              this.activeToggleMap = {
                ...this.activeToggleMap,
                [subFormList.id]: data.active,
              };
              this.reloadTable();
            });
          }
        },
      });
    }

    toggleShowInListSubFormList(subFormList: SubFormList): void {
      if (!this.showInListLoadingMap[subFormList.id]) {
        this.setLoading(subFormList, true);

        const update = has(this.showInListToggleMap, subFormList.id)
          ? !this.showInListToggleMap[subFormList.id]
          : !subFormList.show_in_list;
        this.$api
          .manageChecklist(subFormList.id, { show_in_list: update })
          .then(({ data }) => {
            this.showInListToggleMap = {
              ...this.showInListToggleMap,
              [subFormList.id]: data.show_in_list,
            };
          })
          .finally(() => this.setLoading(subFormList, false));
      }
    }
  }
