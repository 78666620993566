
  import { Component, Vue, Model, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Event } from '@app/models/event';

  @Component({ components: { DsModal } })
  export default class TestAutomationConfirmBox extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Number) readonly collectionSize!: number;
    @Prop(Object) readonly event!: Event;
    @Prop(String) readonly parsedEventType!: string;

    confirmCheckbox = false;
  }
