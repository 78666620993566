
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class PublicFileUploader extends Vue {
    @Ref() readonly fileInput!: HTMLElement;
    @Prop(Boolean) readonly public?: boolean;
    @Prop(Boolean) readonly absolute?: boolean;
    @Prop(String) readonly accept?: string;

    open(): void {
      this.fileInput.click();
    }

    onFileChanged(event: Event): void {
      const file = (event.target as HTMLInputElement).files?.item(0);
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('public', 'true');
        this.$api.uploadFile(formData).then(({ data }) => {
          this.$emit('upload', data.link);
        });
      }
    }
  }
