
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import bootbox from 'bootbox';
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import SearchInput from '@app/components/search-input.vue';
  import type { Dashboard } from '@app/models/dashboard';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  import Select2 from '../../select2.vue';
  import FilterSelect from '../../filter-select.vue';
  import BaseTable from '../../base-table/base-table.vue';

  import DashboardFormModal from './dashboard-form-modal.vue';

  @Component({
    components: {
      DsIconText,
      PaperTrailsModalLink,
      DsDropdown,
      DashboardFormModal,
      Select2,
      FilterSelect,
      BaseTable,
      SearchInput,
    },
  })
  export default class AdminDashboardsPage extends Vue {
    @Ref() readonly table?: BaseTable<Dashboard>;

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    manager = new ListManager<Dashboard>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<Dashboard> = ['id', 'name', 'active', 'is_public', 'hidden'];
        return this.$api.getDashboards({ ...params, only }, { cache: true });
      },
      useHistory: true,
      sortOrder: [
        {
          field: 'id',
          sortField: 'id',
          direction: 'desc',
        },
      ],
      per_page: 20,
      fields: [
        { title: 'ID', name: 'id', sortField: 'id', width: '90px' },
        { title: 'Name', name: 'name', sortField: 'name' },
        { title: 'Hidden', name: 'hidden', sortField: 'hidden', filter: true, width: '100px' },
        { title: 'Active', name: 'active', sortField: 'active', filter: true, width: '100px' },
        { title: 'Public', name: 'is_public', sortField: 'is_public', width: '100px' },
        { title: '', name: 'operations', width: 'max-content' },
      ],
      allowFilters: true,
    });

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    cloneDashboard(dashboard: Dashboard): void {
      bootbox.confirm({
        size: 'small',
        message: 'Do you want to make a copy of the Dashboard?',
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .cloneDashboard(dashboard.id, { name: `${dashboard.name} (Copy)` })
              .then(() => {
                this.$api.cache.clear();
                this.table?.debounceUpdate();
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }

    deleteDashboard(dashboard: Dashboard): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.$api
              .deleteDashboard(dashboard.id)
              .then(() => {
                this.$api.cache.clear();
                this.table?.debounceUpdate();
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
        },
      });
    }
  }
