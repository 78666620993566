
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SectionFormModal from './section-form-modal.vue';
  import type { AsfAttachmentOnly } from '../admin/sub-forms/utils';
  import type { Attachment } from '@app/models/attachment';
  import type { SubForm } from '@app/models/sub-form';

  @Component({ components: { SectionFormModal } })
  export default class OpenSectionForm extends Vue {
    @Prop(Object) readonly subForm!: Pick<SubForm, 'id' | 'module_name' | 'scoring'>;
    @Prop(Object) readonly attachmentsBySectionId!: Record<string, Pick<Attachment, AsfAttachmentOnly>[]>;

    open = false;
    sectionId: Nullable<number> = null;
    showModal = false;

    triggerFromIndex = 0;
    triggerFromGroupId: Nullable<number> = null;

    edit(sectionId: number): void {
      this.showModal = true;
      this.sectionId = sectionId;
      this.open = true;
    }

    create(params: { index?: number; group_id?: number | null }): void {
      this.showModal = true;
      this.triggerFromIndex = params?.index || 0;
      this.triggerFromGroupId = params?.group_id || null;
      this.open = true;
    }

    onHide(): void {
      this.open = false;
      this.showModal = false;
      this.sectionId = null;
      this.triggerFromGroupId = null;
      this.triggerFromIndex = 0;
    }
  }
