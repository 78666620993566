
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AutomationDefinitionState } from '@app/models/automation-definition';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';

  @Component({ components: { DsDropdown, PaperTrailsModalLink, DsIconText } })
  export default class AutomationDefinitionActions extends Vue {
    @Prop(Object) readonly automationDefinition!: Partial<AutomationDefinition>;
    @Prop(Boolean) readonly submitting?: boolean;

    get logLink(): string {
      return `/admin/settings/automation_definitions/${this.automationDefinition.id}/event_occurrences`;
    }

    get cloneLink(): string {
      return `/admin/settings/automation_definitions/${this.automationDefinition.id}/clone`;
    }

    get isActive(): boolean {
      return this.automationDefinition.state === AutomationDefinitionState.active;
    }

    get isInactive(): boolean {
      return this.automationDefinition.state === AutomationDefinitionState.inactive;
    }

    get isActivating(): boolean {
      return this.automationDefinition.state === AutomationDefinitionState.activating;
    }
  }
