
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import TextFieldOptions from '@app/components/admin/questions/settings/text-field-options.vue';
  import TextareaFieldOptions from '@app/components/admin/questions/settings/textarea-field-options.vue';
  import ButtonSelectFieldOptions from '@app/components/admin/questions/settings/button-select-field-options.vue';
  import SingleSelectFieldOptions from '@app/components/admin/questions/settings/single-select-field-options.vue';
  import MultiSelectFieldOptions from '@app/components/admin/questions/settings/multi-select-field-options.vue';
  import ScormFieldOptions from '@app/components/admin/questions/settings/scorm-field-options.vue';
  import KnowledgeBaseFieldOptions from '@app/components/admin/questions/settings/knowledge-base-field-options.vue';
  import VideoFieldOptions from '@app/components/admin/questions/settings/video-field-options.vue';
  import MultiCheckboxFieldOptions from '@app/components/admin/questions/settings/multi-checkbox-field-options.vue';
  import RadioFieldOptions from '@app/components/admin/questions/settings/radio-field-options.vue';
  import TableCalculationFieldOptions from '@app/components/admin/questions/settings/table-calculation-field-options.vue';
  import DateFieldOptions from '@app/components/admin/questions/settings/date-field-options.vue';
  import TimesheetFieldOptions from '@app/components/admin/questions/settings/timesheet-field-options.vue';
  import FileUploadFieldOptions from '@app/components/admin/questions/settings/file-upload-field-options.vue';
  import CompanyRegisterFieldOptions from '@app/components/admin/questions/settings/company-register-field-options.vue';
  import DatetimeFieldOptions from '@app/components/admin/questions/settings/datetime-field-options.vue';
  import {
    AddressFieldOptions,
    CalculationSelectFieldOptions,
    CalculatorFieldOptions,
    DetailFieldOptions,
    ExpenseBudgetSelectFieldOptions,
    ExpenseFieldOptions,
    LocationFieldOptions,
    MatrixFieldOptions,
    OrganizationFieldOptions,
    ReportFieldOptions,
    SignatureFieldOptions,
  } from '@app/components';
  import TimesheetSummaryFieldOptions from '@app/components/admin/questions/settings/timesheet-summary-field.vue';
  import PayCalculatorFieldOptions from '@app/components/admin/questions/settings/pay-calculator-field-options.vue';
  import ExpenseBudgetUuidFieldOptions from '@app/components/admin/questions/settings/expense-budget-uuid-field-options.vue';
  import ExpenseBudgetCategorySelectFieldOptions from '@app/components/admin/questions/settings/expense-budget-category-select-field-options.vue';
  import ExpenseBudgetCategoryUuidFieldOptions from '@app/components/admin/questions/settings/expense-budget-category-uuid-field-options.vue';
  import CalculationTextFieldOptions from '@app/components/admin/questions/settings/calculation-text-field-options.vue';
  import ApprovalStateFieldOptions from '@app/components/admin/questions/settings/approval-state-field-options.vue';
  import SvgSelectorFieldOptions from '@app/components/admin/questions/settings/svg-selector-field-options.vue';
  import MainFormRelationFieldOptions from '@app/components/admin/questions/settings/main-form-relation-field-options.vue';
  import MultiMainFormRelationFieldOptions from '@app/components/admin/questions/settings/multi-main-form-relation-field-options.vue';
  import RecordRelationFieldOptions from '@app/components/admin/questions/settings/record-relation-field-options.vue';
  import SubFormRelationFieldOptions from '@app/components/admin/questions/settings/sub-form-relation-field-options.vue';
  import SinglePersonSelectorFieldOptions from '@app/components/admin/questions/settings/single-person-selector-field-options.vue';
  import MultiPersonSelectorFieldOptions from '@app/components/admin/questions/settings/multi-person-selector-field-options.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import MarkableFieldOptions from '@app/components/admin/questions/settings/markable-field-options.vue';
  import DisplayImageFieldOptions from '@app/components/admin/questions/edit/display-image-field-options.vue';
  import AreaFieldOptions from '@app/components/admin/questions/settings/area-field-options.vue';
  import ApprovalExpenseFieldOptions from '@app/components/admin/questions/settings/approval-expense-field-options.vue';
  import MainFormQuestionListFieldOptions from '@app/components/admin/questions/settings/main-form-question-listfield-options.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';

  @Component({
    components: {
      AddressFieldOptions,
      ApprovalExpenseFieldOptions,
      ApprovalStateFieldOptions,
      AreaFieldOptions,
      ButtonSelectFieldOptions,
      CalculationSelectFieldOptions,
      CalculationTextFieldOptions,
      CalculatorFieldOptions,
      CompanyRegisterFieldOptions,
      DateFieldOptions,
      DatetimeFieldOptions,
      DetailFieldOptions,
      DisplayImageFieldOptions,
      ExpenseBudgetCategorySelectFieldOptions,
      ExpenseBudgetCategoryUuidFieldOptions,
      ExpenseBudgetSelectFieldOptions,
      ExpenseBudgetUuidFieldOptions,
      ExpenseFieldOptions,
      FileUploadFieldOptions,
      KnowledgeBaseFieldOptions,
      LocationFieldOptions,
      MainFormQuestionListFieldOptions,
      MainFormRelationFieldOptions,
      MarkableFieldOptions,
      MatrixFieldOptions,
      MultiCheckboxFieldOptions,
      MultiMainFormRelationFieldOptions,
      MultiPersonSelectorFieldOptions,
      MultiSelectFieldOptions,
      OrganizationFieldOptions,
      PayCalculatorFieldOptions,
      RadioFieldOptions,
      RecordRelationFieldOptions,
      ReportFieldOptions,
      ScormFieldOptions,
      SignatureFieldOptions,
      SinglePersonSelectorFieldOptions,
      SingleSelectFieldOptions,
      SubFormRelationFieldOptions,
      SvgSelectorFieldOptions,
      TableCalculationFieldOptions,
      TextFieldOptions,
      TextareaFieldOptions,
      TimesheetFieldOptions,
      TimesheetSummaryFieldOptions,
      VideoFieldOptions,
    },
  })
  export default class FieldTypeSpecificOptions extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) subForm!: SubForm;
    @Prop(Object) readonly features!: ModuleFeatures;

    get componentName(): string | null {
      return this.componentForFieldType(this.value.field_type);
    }

    componentForFieldType(fieldType: FieldType) {
      const noAdditionalOptions = [FieldType.toocs, FieldType.display_text, FieldType.scheme, FieldType.workflow_state];

      if (!fieldType || noAdditionalOptions.includes(fieldType)) return null;

      return `${fieldType.replaceAll('_', '-')}-field-options`;
    }
  }
