
  import ScheduledEventsForm from '@app/components/admin/scheduled-events/scheduled-events-form.vue';
  import Blocking from '@app/mixins/blocking';
  import type { RunEventParams } from '@app/services/api/events-api';
  import type { RecordParams } from '@app/services/api/rule-sets-api';
  import { Component, Prop } from 'vue-property-decorator';
  import type { ScheduledEventOnly } from '@app/components/admin/scheduled-events/utils';
  import { SCHEDULED_EVENT_ONLY } from '@app/components/admin/scheduled-events/utils';
  import { mixins } from 'vue-class-component';
  import WithScheduleSubmit from './with-schedule-submit';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import type { Event } from '@app/models/event';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ScheduledEventsForm } })
  export default class ScheduledEventsEdit extends mixins(Blocking, WithScheduleSubmit) {
    @Prop(Number) readonly scheduledEventId!: number;

    scheduledEvent: Nullable<Pick<ScheduledEvent, ScheduledEventOnly>> = null;
    event: Partial<Event> = {};

    onSubmitComplete(data: Pick<ScheduledEvent, ScheduledEventOnly>) {
      const redirectTo =
        typeof this.$route.query.redirect_to === 'string'
          ? this.$route.query.redirect_to
          : { name: 'admin-settings-scheduled-events-index' };
      this.$router.push(redirectTo);
    }

    onSubmitEvent(scheduledEvent: Pick<ScheduledEvent, ScheduledEventOnly>) {
      this.submit(scheduledEvent, this.event, {
        onSubmitComplete: this.onSubmitComplete,
      });
    }

    testAfterComplete(scheduledEvent: Pick<ScheduledEvent, ScheduledEventOnly>, testParams: RecordParams) {
      this.blocking(async () => {
        if (this.event.id) {
          await this.$api.runEventAction(this.event.id, {
            per_id: testParams.record_id,
            per_type: testParams.record_type as RunEventParams['per_type'],
          });
          toaster(this.$t('app.labels.test_occurred'));
        }
        this.submitting = false;
      });
    }

    onTestEvent(scheduledEvent: Pick<ScheduledEvent, ScheduledEventOnly>, testParams: RecordParams) {
      this.submit(scheduledEvent, this.event, {
        onSubmitComplete: (data) => {
          this.testAfterComplete(data, testParams);
        },
      });
    }

    initForm(scheduledEvent: Pick<ScheduledEvent, ScheduledEventOnly>) {
      this.scheduledEvent = scheduledEvent;
      if (!!scheduledEvent.event) {
        this.event = scheduledEvent.event;
      }
    }

    beforeMount(): void {
      this.blocking(async () => {
        const { data } = await this.$api.getScheduledEvent(this.scheduledEventId, { only: SCHEDULED_EVENT_ONLY });
        this.initForm(data);
      });
    }
  }
