
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SubFormSectionShow from './sub-form-section-show.vue';
  import SubFormSectionsNewButton from './sub-form-sections-new-button.vue';
  import type { AsfAttachmentOnly } from './utils';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormSection } from '@app/models/sub-form-section';

  @Component({ components: { SubFormSectionShow, SubFormSectionsNewButton } })
  export default class SubFormSectionPanel extends Vue {
    @Prop() readonly subFormSection!: SubFormSection;
    @Prop(Boolean) readonly hideAddNewSection?: boolean;
    @Prop(Boolean) readonly withGroupOption!: boolean;
    @Prop(Boolean) readonly dragging?: boolean;
    @Prop(Boolean) readonly last?: boolean;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Array) readonly attachments!: Pick<Attachment, AsfAttachmentOnly>[];
    @Prop(Number) readonly mainFormId?: number;
    @Prop(Number) readonly subFormId!: number;
  }
