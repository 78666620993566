
  import { Component, Model, Vue } from 'vue-property-decorator';
  import FormField from './form-field.vue';
  import UsageTrackingTable from '@app/components/field-integration/usage-tracking-table.vue';
  import type { TrackEntry } from '@app/models/field-integration/track-entry';
  import { partition } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: { UsageTrackingTable, FormField } })
  export default class TabFieldUsage extends Vue {
    @Model('input') value!: SubFormQuestion;

    SUPPORTED_LIST = ['placeholders', 'event_select_options', 'field_conditions', 'display_order'];

    certainEntries: TrackEntry[] = [];
    uncertainEntries: TrackEntry[] = [];
    loading = true;

    get captions(): Record<string, string> {
      return {
        placeholders: this.$t('usage_tracking.sub_form_questions.types.placeholders'),
        event_select_options: this.$t('usage_tracking.sub_form_questions.types.event_select_options'),
        field_conditions: this.$t('usage_tracking.sub_form_questions.types.field_conditions'),
        field_defaults: this.$t('usage_tracking.sub_form_questions.types.field_defaults'),
        lookups: this.$t('usage_tracking.sub_form_questions.types.lookups'),
        api_lookup_placeholders: this.$t('usage_tracking.sub_form_questions.types.api_lookup_placeholders'),
        report_filters: this.$t('usage_tracking.sub_form_questions.types.report_filters'),
        event_form_fields: this.$t('usage_tracking.sub_form_questions.types.event_form_fields'),
        index_columns: this.$t('usage_tracking.sub_form_questions.types.index_columns'),
        filters: this.$t('usage_tracking.sub_form_questions.types.filters'),
        display_order: this.$t('usage_tracking.sub_form_questions.types.display_order'),
        calculation_variables: this.$t('usage_tracking.sub_form_questions.types.calculation_variables'),
        record_calculation_variables: this.$t('usage_tracking.sub_form_questions.types.record_calculation_variables'),
        record_multiplier_based_on_question: this.$t('usage_tracking.sub_form_questions.types.record_multiplier_based_on_question'),
        rules: this.$t('usage_tracking.sub_form_questions.types.rules'),
      };
    }

    get supportedListCaptions(): string {
      return this.SUPPORTED_LIST.map((type) => this.captions[type]).join('; ');
    }

    get unsupportedListCaptions(): string {
      const unsupported = Object.keys(this.captions).filter((type) => !this.SUPPORTED_LIST.includes(type));
      return unsupported.map((type) => this.captions[type]).join('; ');
    }

    async beforeMount(): Promise<void> {
      if (this.value.id) {
        const { data } = await this.$api.getSubFormQuestion(this.value.id, { only: ['id', 'tracked_usages'] }, { cache: true });

        if (data.tracked_usages) {
          const [certain, uncertain] = partition(data.tracked_usages, (x) => x.certain);
          this.certainEntries = certain;
          this.uncertainEntries = uncertain;
        } else {
          this.certainEntries = [];
          this.uncertainEntries = [];
        }
        this.loading = false;
      } else {
        this.certainEntries = [];
        this.uncertainEntries = [];
        this.loading = false;
      }
    }
  }
