// Fallback to strictly Unicode BCP47 Locale format
// DONESAFE.defaultLocale => en_au -> en-AU
export const fallbackLocale = (localeCode: string): Intl.Locale => {
  const defaultSplit = localeCode.split('_');
  try {
    return new Intl.Locale(defaultSplit.join('-'));
  } catch (e) {
    if (e instanceof RangeError) {
      return new Intl.Locale(defaultSplit[0]);
    }
  }
  return new Intl.Locale('en');
};

export const currencyTitle = (currency: string, localeCode: string): string => {
  const locale = fallbackLocale(localeCode).baseName;
  const options = { style: 'currency', currencyDisplay: 'name', currency };
  const formatted = new Intl.NumberFormat(locale, options).format(0);
  return formatted.replace(/(\d|,|\.)+/, '').trim();
};
