
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';

  interface UIPlaceholderProfile extends Partial<PlaceholderProfile> {
    missing?: boolean;
  }

  @Component
  export default class PlaceholderProfiles extends Vue {
    @Prop(Array) profiles!: UIPlaceholderProfile[];
    @Prop(Boolean) missingOnly?: boolean;

    get displayProfiles(): UIPlaceholderProfile[] {
      return this.missingOnly ? this.profiles.filter(({ missing, active }) => missing || !active) : this.profiles;
    }
  }
