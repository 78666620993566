
  import { useAccountStore } from '@app/stores/account';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import type { AxiosPromise } from 'axios';
  import { BaseTable } from '../base-table';
  import AppBundleFormModal from '../app-bundle/app-bundle-form-modal.vue';
  import type { AppBundleValidationResponse } from '@app/models/app-bundles/validation-response';
  import I18n from '../../i18n';
  import type { AppBundle } from '@app/models/app-bundle';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { downloadFileFromContent } from '@app/utils/download-file-from-content';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { AppBundleFormModal, BaseTable } })
  export default class AdminAppBundlesPage extends Vue {
    @Ref() readonly table?: BaseTable<AppBundle>;

    appBundle: Nullable<AppBundle> = null;
    modalVisible = false;
    showSvgTemplatesColumn = false;
    formModalMode: 'loading' | 'create' | 'edit' | 'show' | 'errors' = 'loading';
    formModalData: Nullable<AppBundleValidationResponse> = null;
    loadingBundleId: Nullable<string> = null;
    manager: Nullable<ListManager<AppBundle>> = null;

    initializeManager(): void {
      this.manager = new ListManager<AppBundle>({
        fetchDataFunction: (params) => {
          return this.$api.getAppBundles(
            {
              ...params,
              only: [
                'id',
                'name',
                'code',
                'description',
                'internal_description',
                'active',
                'published',
                'module_name_ids',
                'dashboard_ids',
                'dashboard_pane_ids',
                'regulatory_report_config_ids',
                'placeholder_profile_ids',
                'svg_library_template_ids',
                'created_at',
                {
                  module_names: ['display', 'id'],
                  dashboards: ['name', 'id'],
                  dashboard_panes: ['name', 'id'],
                  regulatory_report_configs: ['name', 'id'],
                  placeholder_profiles: ['name', 'id', 'active'],
                  svg_library_templates: ['name', 'id'],
                },
              ],
              filters: { active: true },
            },
            { cache: true }
          );
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created_at' }],
        fields: [
          { title: I18n.t('app.labels.name'), name: 'name', sortField: 'name' },
          { title: I18n.t('app.labels.published'), name: 'published' },
          { title: I18n.t('app.labels.modules'), name: 'modules' },
          { title: I18n.t('app.labels.svg_templates_included'), name: 'svg_templates', hidden: !this.showSvgTemplatesColumn },
          { title: I18n.t('app.labels.description'), name: 'description' },
          { title: I18n.t('app.labels.created_at'), name: 'created_at', sortField: 'created_at' },
          {
            title: I18n.t('app.labels.actions'),
            name: 'actions',
            titleClass: 'justify-content-center',
            dataClass: 'text-center',
          },
        ],
      });
    }

    get accountStore() {
      return useAccountStore();
    }

    async showSvgTemplates(): Promise<void> {
      await this.$api
        .getSvgTemplates(
          {
            only: ['id', 'name'],
            sort: 'name',
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.showSvgTemplatesColumn = !!data.length;
        });
    }

    async beforeMount(): Promise<void> {
      await this.showSvgTemplates().finally(() => {
        this.initializeManager();
      });
    }

    onSubmit(form: Partial<AppBundle>): void {
      const promise = form.id ? this.updateAppBundle(form.id.toString(), form) : this.createAppBundle(form);
      promise
        .then(() => {
          this.$api.cache.clear();
          this.table?.reload();
          this.closeModal();
        })
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    onShow(bundle: AppBundle): void {
      this.appBundle = { ...bundle };
      this.formModalMode = 'show';
      this.modalVisible = true;
    }

    onCreate(): void {
      this.appBundle = null;
      this.formModalMode = 'create';
      this.modalVisible = true;
    }

    createAppBundle(data: Partial<AppBundle>): AxiosPromise<AppBundle> {
      return this.$api.createAppBundle(data);
    }

    updateAppBundle(id: string, data: Partial<AppBundle>): AxiosPromise<AppBundle> {
      return this.$api.updateAppBundle(id, data);
    }

    checkBlob(bundle: AppBundle): void {
      this.loadingBundleId = bundle.id;

      this.$api
        .validateAppBundle(bundle.id)
        .then((response) => {
          if (response.data.errors.length) {
            this.appBundle = { ...bundle };
            this.formModalMode = 'errors';
            this.formModalData = response.data;
            this.modalVisible = true;
          } else {
            this.downloadBlob(bundle.id);
          }
          this.loadingBundleId = null;
        })
        .catch(({ data }) => {
          this.loadingBundleId = null;
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    onEditBundle(bundle: AppBundle): void {
      this.appBundle = { ...bundle };
      this.formModalMode = 'edit';
      this.modalVisible = true;
    }

    onDownloadBlob(id: string): void {
      this.downloadBlob(id);
      this.modalVisible = false;
    }

    downloadBlob(id: string): void {
      this.loadingBundleId = id;

      this.$api
        .downloadAppBundleBlob(id)
        .then(({ data }) => downloadFileFromContent(JSON.stringify(data), id + '.json'))
        .finally(() => (this.loadingBundleId = null));
    }

    modules(bundle: AppBundle): string {
      if (!bundle.module_names) {
        return '';
      }
      return bundle.module_names.map((module) => module.display).join(', ');
    }

    svgTemplates(bundle: AppBundle): string {
      if (!bundle.svg_library_templates) {
        return '';
      }
      return bundle.svg_library_templates.map((template) => template.name).join(', ');
    }

    closeModal(): void {
      this.appBundle = null;
      this.modalVisible = false;
    }
  }
