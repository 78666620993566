
  import { Component, Prop } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Involvement } from '@app/models/involvement';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    involvement_id: string;
    record_id: string;
    user_id: string;
  }

  interface InjectableArguments {
    concept_name: string;
    involvement_id?: string;
    relationship_code?: string;
    sub_form_id?: number;
  }

  @Component({ components: { EntitySelector, Select2, EventInputMethodSwitch, AdvancedAutosuggest, FormField } })
  export default class UserInvolvementEventForm extends BaseEventForm<FormValues> {
    @Prop(Object) readonly injectableArguments!: InjectableArguments;

    get userInvolvementFilters(): DonesafeFilterOptions<Involvement> {
      return {
        active: true,
        involvement_type: 'standard',
      };
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
