import { render, staticRenderFns } from "./remove-user-involvement-event-form.vue?vue&type=template&id=eeeb227c&scoped=true&lang=pug"
import script from "./remove-user-involvement-event-form.vue?vue&type=script&lang=ts"
export * from "./remove-user-involvement-event-form.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eeeb227c",
  null
  
)

export default component.exports