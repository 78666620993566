
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import UserSelector from '@app/components/user/user-selector.vue';
  import { uniq } from 'lodash';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, UserSelector } })
  export default class RoleTabUsersNewModal extends Vue {
    @Prop({ type: Number }) readonly roleId!: number;

    newUserIds: number[] = [];
    existingUserIds: number[] = [];
    visible = true;

    submit() {
      const newUserIds = uniq([...this.newUserIds.map(Number), ...this.existingUserIds]);
      this.$api
        .updateRole(this.roleId, { user_ids: newUserIds })
        .then(() => {
          this.closeModal('reload');
          toaster(this.$t('controllers.tenant.admin.roles.update.success'));
        })
        .catch((data) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-roles-edit-users',
        params: {
          roleId: `${this.roleId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    initForm() {
      this.$api
        .getTenantUsers(
          {
            filters: {
              role_id: this.roleId,
            },
            only: ['id'],
            per_page: -1,
          },
          {
            cache: true,
          }
        )
        .then(({ data }) => {
          this.existingUserIds = data.map((user) => user.id);
        });
    }

    beforeMount() {
      this.initForm();
    }
  }
