
  import { Component } from 'vue-property-decorator';
  import ModuleRecordsTable from '../module-record/module-records-table.vue';
  import BaseWidget from './base-widget';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { RecordIndexWidgetOptions } from '@app/models/widget';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';
  import { buildLink } from '@app/utils/build-link';

  @Component({ components: { ModuleRecordsTable } })
  export default class RecordIndexWidget extends BaseWidget<RecordIndexWidgetOptions> {
    get filters(): DonesafeFilterOptions<ModuleRecord> {
      return {
        ...convertInFormFilters(this.widget.options.filters, {
          user: this.currentUserStore.data,
        }),
      };
    }

    get href(): string {
      return buildLink('/module_records', { filters: this.filters });
    }

    get reverse(): boolean {
      return this.widget.options.reverse === 'true';
    }

    get useHistory(): boolean {
      return this.widget.options.use_history === 'true';
    }

    get allowCustomFilters(): boolean {
      return this.widget.options.allow_custom_filters === 'true';
    }

    get recordLinkBehaviour(): RecordLinkBehaviour {
      return this.widget.options.record_link_behaviour || RecordLinkBehaviour.GoToRecord;
    }
  }
