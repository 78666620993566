
  import { get, mapValues, set, toPairs } from 'lodash';
  import { Component, Emit, Model, Prop, Watch } from 'vue-property-decorator';
  import { isMultiSelect, isReadonlyLookup, isSingleSelect } from '@app/services/model-helpers';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { EventSelectableOptions } from '@app/models/event';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { RUN_AUTO_SELECT_COMMAND, CALCULATE_COMMAND, FieldType } from '@app/models/sub-form-question';
  import type { SingleSelectQuestionOptions } from '@app/models/question-options/single-select-question-options';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
  import { mixins } from 'vue-class-component';

  import FormField from '../questions/edit/form-field.vue';
  import Select2 from '../../select2.vue';

  import { WithUniqID } from './forms/base-event-form';
  import EventInputMethodSwitch from './event-input-method-switch.vue';

  interface SelectKeyAndValue {
    key: string;
    value: string;
  }
  const EMPTY_VALUE_CMD = '!';

  @Component({
    components: { EventInputMethodSwitch, Select2, AdvancedAutosuggest, FormField },
    methods: { isSingleSelect, isMultiSelect, isReadonlyLookup },
  })
  export default class EventQuestionFields extends mixins(WithUniqID) {
    @Model('input') readonly value!: Dictionary<string>;
    @Prop(Array) readonly formQuestions!: SubFormQuestion[];
    @Prop(Array) readonly tags!: PlaceholderItem[];
    @Prop(Object) readonly fieldOptions!: EventSelectableOptions;
    @Prop(Array) readonly dateOptions?: [string, string][];

    form: Partial<Dictionary<string>> = {};

    get editableQuestions(): SubFormQuestion[] {
      return this.formQuestions.filter((q) => ![FieldType.display_image, FieldType.display_text].includes(q.field_type));
    }

    get flatFieldOptionsRecord(): Record<string, [string, string][]> {
      return mapValues(this.fieldOptions, (value) => toPairs(value));
    }

    get forceRequiredFields(): FieldType[] {
      return [FieldType.workflow_state, FieldType.approval_state];
    }

    get calculatorSelectOptions(): [string, string][] {
      return [[CALCULATE_COMMAND, this.$t('app.labels.perform_calculation')]];
    }

    get calculatorSelectOptionsAsRecords(): Record<string, string>[] {
      return this.calculatorSelectOptions.map((opt) => ({ key: opt[0] }));
    }

    get commandsForSwitch(): Record<string, string>[] {
      return [{ key: RUN_AUTO_SELECT_COMMAND }];
    }

    get commandsForEmptyValue(): Record<string, string>[] {
      return [{ key: EMPTY_VALUE_CMD }];
    }

    @Watch('form', { deep: true })
    @Emit('input')
    updateValues(): Partial<Dictionary<string>> {
      return this.form;
    }

    withAutoSelectOptions(question: SubFormQuestion, options: [string, string][]): [string, string][] {
      if (question?.config?.auto_select_enabled !== 'true') return options;

      return [[RUN_AUTO_SELECT_COMMAND, `[${this.$t('app.labels.auto_select_command.perform')}]`], ...options];
    }

    beforeMount(): void {
      this.form = { ...this.value };
    }

    questionOptions(question: SubFormQuestion<SingleSelectQuestionOptions>): SelectKeyAndValue[] {
      const hasHistoricalValues = !!Object.keys(question.options.historical_values || {}).length;
      const activeValues = question.options.values || {};
      const questionOptions = hasHistoricalValues ? question.options.historical_values || {} : activeValues;
      return Object.values(questionOptions).map((item) => {
        let value = item.value;
        if (hasHistoricalValues && !Object.values(activeValues).some((val) => val.key === item.key)) {
          value = this.$t('app.labels.archived_name', { name: item.value }).toString();
        }
        return { key: item.key, value: `${value} [${item.key}]` };
      });
    }

    setFormValue(path: string[], value: string): void {
      this.form = { ...set(this.form, path, value) };
    }

    getFormValue(path: string[]): string | number | undefined {
      return get(this.form, path);
    }

    emptyValue(options: [string, string][]): [string, string][] {
      return [...(options || []), [EMPTY_VALUE_CMD, this.$t('tenant.admin.events.event_form_fields_form.empty_value')] as [string, string]];
    }

    nameValue(question: SubFormQuestion): string {
      return isReadonlyLookup(question)
        ? `${question.title} (${question.code}) [${this.$t('tenant.admin.automation_definitions.form.readonly_lookup')}]`
        : `${question.title} (${question.code})`;
    }
  }
