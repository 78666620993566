
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  interface KnowledgeBaseQuestionOptions extends BaseQuestionOptions {
    procedure_id: number;
  }

  @Component({
    components: { EntitySelector, FormField, ScoredQuestionConfig },
  })
  export default class KnowledgeBaseFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<KnowledgeBaseQuestionOptions>;

    @Prop(Object) readonly features!: ModuleFeatures;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['procedure_id'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
