import { render, staticRenderFns } from "./trigger-source-filter.vue?vue&type=template&id=c145bdc4&scoped=true&lang=pug"
import script from "./trigger-source-filter.vue?vue&type=script&lang=ts"
export * from "./trigger-source-filter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c145bdc4",
  null
  
)

export default component.exports