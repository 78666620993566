
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import LogicTemplateItems from '../components/logic-template-items.vue';
  import TemplateItem from '../components/template-item.vue';
  import TextEditor from '../components/text-editor.vue';
  import type {
    DisplayItemValue,
    RuleBuilderModes,
    VariableMap,
    Formula,
    DragAndDropFormulaObject,
    CurrentTab,
    Validation,
    FormulaErrors,
    Rules,
    Components,
    Variables,
    ParentRule,
    TemplateItemProps,
    TemplateItemObject,
    ElementType,
  } from '../models/types';
  import { LogicElementTypes } from '../models/types';
  import DropLayer from '../components/drop-layer.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';

  @Component({
    name: 'FormulaEditor',
    components: {
      LogicTemplateItems,
      TextEditor,
      TemplateItem,
      DropLayer,
    },
  })
  export default class FormulaEditor extends Vue {
    @Prop() readonly logicSetId?: string;
    @Prop({ required: true }) readonly currentTab!: CurrentTab;
    @Prop({ required: true }) readonly currentFormula!: Formula;
    @Prop({ required: true }) readonly dragAndDropFormulaObject!: DragAndDropFormulaObject;
    @Prop({ required: true }) readonly changeTab!: (tabType: CurrentTab['type'], tabValue: CurrentTab['value']) => void;
    @Prop({ required: true }) readonly saveChanges!: (dragAndDropFormulaLogicElements: Formula) => void;
    @Prop({ required: true }) readonly convertAndUpdateCurrentFormula!: (
      dragAndDropFormulaLogicElements: { id: number; value: string | Formula }[],
      refreshFormulaEditorComponent?: boolean
    ) => void;
    @Prop({ required: true }) readonly getElementType!: (logicElementValue: string | Formula) => ElementType;
    @Prop({ required: true }) readonly validation!: Validation;
    @Prop({ required: true }) readonly deleteLogic!: (elementId: string) => void;
    @Prop({ required: true }) readonly calcValueSubFormQuestions!: SubFormQuestion[];
    @Prop({ required: true }) readonly variableMapping!: VariableMap;
    @Prop({ required: true }) readonly displayItems!: DisplayItemValue[];
    @Prop({ required: true }) readonly toggleDisplayItem!: (elementId: DisplayItemValue) => void;
    @Prop() readonly saving?: boolean;
    @Prop() readonly savePending?: boolean;
    @Prop({ required: true }) readonly assignIdAndTypeToLogicElements!: (
      logicElementsArray: Formula,
      startingId: number
    ) => {
      currentId: number;
      logicElementsArray: { id: number; value: Formula }[];
    };
    @Prop() readonly textEditMode?: boolean;
    @Prop({ required: true }) readonly switchPage!: (mode: RuleBuilderModes) => void;
    @Prop({ required: true }) readonly shiftElementOrder!: (elementId: string, rightOrLeft: 'right' | 'left') => void;
    @Prop({ required: true }) readonly archiveFormula!: (logicSetId: string, callback: () => void) => void;
    @Prop({ required: true }) readonly rules!: Rules;
    @Prop({ required: true }) readonly components!: Components;
    @Prop({ required: true }) readonly variables!: Variables;
    @Prop({ required: true }) readonly formulaErrors!: FormulaErrors;

    newId = 0;
    parentRuleTemplateItem: ParentRule = {
      color: '#000000',
      name: '',
      formula: [],
    };
    componentTemplateItems: Dictionary<TemplateItemObject> = {};
    ruleTemplateItems: Dictionary<TemplateItemObject> = {};
    variableTemplateItems: Dictionary<TemplateItemObject> = {};
    basicTemplateItems = ['12345', 'Text', '( )', '+', '-', '*', '/', '^', 'IF', 'ELSIF', 'ELSE', '=', '<', '>', '<=', '>='];
    logicElements: { id: number | string; value: Formula }[] = [];
    dragging = false;
    LogicElementTypes = LogicElementTypes;

    @Watch('logicElements', { deep: true })
    onLogicElementsChange(newValue: { id: number; value: Formula }[]): void {
      this.convertAndUpdateCurrentFormula(newValue);
    }

    setAllVariablesFormulas(): void {
      const dragAndDropFormulaObject = objectCloneDeep(this.dragAndDropFormulaObject);

      this.newId = dragAndDropFormulaObject.startingId;
      this.parentRuleTemplateItem = dragAndDropFormulaObject.parentRuleTemplateItem;
      this.componentTemplateItems = dragAndDropFormulaObject.componentTemplateItems;
      this.ruleTemplateItems = dragAndDropFormulaObject.ruleTemplateItems;
      this.variableTemplateItems = dragAndDropFormulaObject.variableTemplateItems;
      this.logicElements = dragAndDropFormulaObject.logicElements;
    }

    updateDragging(state: boolean): void {
      this.dragging = state;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getParentArrayAndIndex(logicElementId: string, array: { id: number | string; value: unknown }[]): any {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === logicElementId) {
          return { parentArray: array, index: i };
        }
        if ((array[i].value as { id: number; value: Formula }[]).constructor === Array) {
          const nestedArray = this.getParentArrayAndIndex(logicElementId, array[i].value as { id: number; value: Formula }[]);
          if (nestedArray) return nestedArray;
        }
      }
    }

    updateElementValue(logicElementId: string, newValue: string): void {
      const logicElements = objectCloneDeep(this.logicElements);
      const parentArrayAndIndex = this.getParentArrayAndIndex(logicElementId, logicElements);
      if (
        this.getElementType(parentArrayAndIndex.parentArray[parentArrayAndIndex.index].value) === LogicElementTypes.NUMBER &&
        isNaN(Number(newValue))
      ) {
        return;
      }
      parentArrayAndIndex.parentArray[parentArrayAndIndex.index].value = newValue;
      this.logicElements = logicElements;
      this.convertAndUpdateCurrentFormula(logicElements);
    }

    generateTemplateItemProps(elementId: string, i: number): TemplateItemProps {
      const elementType = this.getElementType(elementId) as CurrentTab['type'];
      let templateItems;
      let canDrag = false;
      let errors: string[] = [];

      switch (elementType) {
        case LogicElementTypes.RULE:
          templateItems = this.ruleTemplateItems;
          canDrag =
            this.currentTab.type === LogicElementTypes.PARENT_RULE ||
            this.currentTab.type === LogicElementTypes.NEW_RULE ||
            this.currentTab.type === LogicElementTypes.RULE;
          errors = this.formulaErrors.rules[elementId];
          break;
        case LogicElementTypes.COMPONENT:
          templateItems = this.componentTemplateItems;
          canDrag = this.currentTab.type !== LogicElementTypes.NEW_VARIABLE && this.currentTab.type !== LogicElementTypes.VARIABLE;
          errors = this.formulaErrors.components[elementId];
          break;
        case LogicElementTypes.VARIABLE:
          templateItems = this.variableTemplateItems;
          canDrag = this.currentTab.type !== LogicElementTypes.NEW_VARIABLE && this.currentTab.type !== LogicElementTypes.VARIABLE;
        default:
          break;
      }

      return {
        key: i,
        index: elementId,
        type: elementType,
        value: elementId,
        color: templateItems?.[elementId].color,
        templateItems,
        highlighted: this.currentTab.value === elementId,
        updateDragging: this.updateDragging,
        canDrag,
        onClick: () => this.changeTab(elementType, elementId),
        deleteLogic: () => this.deleteLogic(elementId),
        displayItem: this.displayItems.indexOf(elementId) !== -1,
        toggleDisplayItem: () => this.toggleDisplayItem(elementId),
        dragging: this.dragging,
        shiftElementOrder: this.shiftElementOrder,
        errors,
      };
    }

    get saveButtonText(): string {
      let type;
      switch (this.currentTab.type) {
        case LogicElementTypes.NEW_COMPONENT:
          type = 'New Component';
          break;
        case LogicElementTypes.COMPONENT:
          type = 'Component';
          break;
        case LogicElementTypes.NEW_RULE:
          type = 'New Rule';
          break;
        case LogicElementTypes.RULE:
          type = 'Rule';
          break;
        case LogicElementTypes.NEW_VARIABLE:
          type = 'New Variable';
          break;
        case LogicElementTypes.VARIABLE:
          type = 'Variable';
          break;
        default:
          type = 'Parent Rule';
          break;
      }

      return this.saving ? 'Saving...' : 'Save ' + type;
    }

    get isVariableTab(): boolean {
      return [LogicElementTypes.VARIABLE, LogicElementTypes.NEW_VARIABLE].indexOf(this.currentTab.type) !== -1;
    }

    beforeMount(): void {
      this.setAllVariablesFormulas();
    }
  }
