
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Ref } from 'vue-property-decorator';
  import ActivityForm from './activity-form.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import type { Activity } from '@app/models/activity';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActivityForm, DsModal } })
  export default class NewActivityPage extends mixins(Blocking) {
    @Ref() readonly form?: ActivityForm;

    activity: Partial<Activity> = {};

    visible = true;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'activities-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    getActionableParams(): Partial<Activity> {
      if (this.$route.query.actionableId && this.$route.query.actionableType) {
        return {
          actionable_type: this.$route.query.actionableType as string,
          actionable_id: parseInt(this.$route.query.actionableId as string),
        };
      } else {
        return { actionable_id: this.currentUserStore.data?.home_location_id, actionable_type: 'Location' };
      }
    }

    beforeMount(): void {
      this.activity = { ...this.getActionableParams() };
    }

    onFormSubmit(form: Partial<Activity>): void {
      this.blocking(async () => {
        await this.$api.createActivity(form);
        toaster(this.$t('tenant.activities.create.activity_successfully_added'));
        this.closeModal('reload');
      });
    }
  }
