
  import { Component, Model, Vue } from 'vue-property-decorator';
  import FormField from './form-field.vue';
  import UsageTrackingTable from '@app/components/field-integration/usage-tracking-table.vue';
  import TabFieldUsageTables from '@app/components/admin/questions/edit/tab-field-usage-tables.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toCsv = (data: any[][]) => {
    return data
      .map((row) =>
        row
          .map(String)
          .map((v) => v.replaceAll('"', '""'))
          .map((v) => `"${v}"`)
          .join(',')
      )
      .join('\n');
  };

  @Component({ components: { TabFieldUsageTables, UsageTrackingTable, FormField } })
  export default class TabFieldUsage extends Vue {
    @Model('input') value!: SubFormQuestion;

    get optionsDump(): string {
      const { options, config } = this.value;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { historical_values, ...dump } = { ...options, ...config };

      return toCsv([[JSON.stringify(dump)]]);
    }
  }
