
  import SubFormListForm from '@app/components/admin/sub-form-list-form.vue';
  import * as bootbox from 'bootbox';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';

  @Component({ components: { SubFormListForm, DsModal } })
  export default class SubFormListFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly form?: SubFormListForm;
    @Prop(String) readonly title?: string;
    @Prop(Boolean) readonly saving?: boolean;
    @Prop(Object) readonly subFormList?: Partial<SubFormList>;

    initForm(): void {
      // this.form?.reset();
    }

    get isHybrid(): boolean {
      return !!this.subFormList?.module_tab && HYBRID_MODULE_TAB_CODE === this.subFormList?.module_tab.code;
    }

    onDelete(): void {
      bootbox.confirm({
        backdrop: false,
        title: this.$t('app.labels.are_you_sure'),
        message: 'Tab section deletion will delete all the form completions for the tab section.', // TODO: translate
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.$emit('delete');
        },
      });
    }
  }
