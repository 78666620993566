
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop } from 'vue-property-decorator';
  import { keyBy, groupBy, difference, union, intersection } from 'lodash';
  import BaseRoleTable from './base-role-table';
  import type { RoleTabDetailsModuleNameOnly, RoleTabDetailsRoleProfileOnly } from './utils';
  import {
    RTB_MODULE_NAME_ONLY,
    extendedPermissionsByConceptAndName,
    epOptionsWithLabels,
    RTB_RP_ONLY,
    UiRoleProfileIds,
    areProfileRolePermsEqual,
    noRightsPerms,
  } from './utils';
  import CustomiseRightsModal from './customise-rights-modal.vue';
  import RelationshipRightsTable from './relationship-rights-table.vue';
  import Select2 from '@app/components/select2.vue';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import type { ModuleName } from '@app/models/module-name';
  import type { Permission } from '@app/models/permission';
  import type { PermissionProvider } from '@app/models/role';
  import type { RoleModuleName } from '@app/models/role-module-name';
  import type { RoleProfile } from '@app/models/role-profile';
  import { ConfigurationType } from '@app/models/role-module-name';
  import { GlobalAccess } from '@app/models/extended-permission';
  import { PermissionAccess } from '@app/models/permission';
  import type { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { displayModuleName } from '@app/utils/display-module-name';
  import JoinEntities from '@app/components/join-entities.vue';

  const ROLE_PROFILE_CUSTOMIZED = 'customised';
  type TranslatePair = [string, string];

  @Component({ components: { CustomiseRightsModal, RelationshipRightsTable, Select2, SimpleGridTable, JoinEntities } })
  export default class ModuleNameRoleTable extends BaseRoleTable {
    @Prop(Object) dashboardPaneIdsByModuleName!: Record<number | 'null', number[]>;

    moduleNamesByName: Record<string, Pick<ModuleName, RoleTabDetailsModuleNameOnly>> = {};
    manager: Nullable<ListManager<ModuleName> | ListManagerStatic<ModuleName>> = null;
    customiseRightsModuleName: Nullable<Pick<ModuleName, RoleTabDetailsModuleNameOnly>> = null;
    customiseRightsModal = false;
    confidentialityTypesByModuleName: Record<string, Pick<ConfidentialityType, 'name' | 'id' | 'active'>[]> = {};
    roleProfilesByModuleName: Record<string, Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>[]> = {};
    selectedProfileByConcept: Record<string, UiRoleProfileIds | string> = {};
    defaultRoleModuleNames: RoleModuleName[] = [];
    defaultPermissions: Permission[] = [];
    defaultExtendedPermissions: ExtendedPermission[] = [];

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get permissionProviderByModuleName(): Record<string, PermissionProvider> {
      return Object.keys(this.moduleNamesByName).reduce((acc, conceptName) => {
        return { ...acc, [conceptName]: this.permissionProviderFor(conceptName) };
      }, {});
    }

    get defaultRoleProfileOptions(): TranslatePair[] {
      return [
        [UiRoleProfileIds.none, this.$t('tenant.admin.roles.rtb.no_rights_user')],
        [UiRoleProfileIds.customised, this.$t('tenant.admin.roles.rtb.custom')],
      ];
    }

    get profileOptionsByConcept(): Record<string, TranslatePair[]> {
      return Object.keys(this.moduleNamesByName).reduce<Record<string, TranslatePair[]>>((acc, moduleName) => {
        return { ...acc, [moduleName]: this.roleProfileOptions(moduleName) };
      }, {});
    }

    get roleProfileById(): Record<string, Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>> {
      return keyBy(Object.values(this.roleProfilesByModuleName).flat(), 'id');
    }

    get moduleNameStrings(): string[] {
      return Object.keys(this.moduleNamesByName);
    }

    get joinedDashboardPanesParams() {
      return { only: ['name', 'id'] };
    }

    configuredDashboardPaneIds(moduleId: number, moduleName: string): number[] {
      const roleProfileId = this.selectedProfileByConcept[moduleName];
      const roleProfileDashboardPaneIds =
        this.roleProfilesByModuleName[moduleName]?.find(({ id }) => '' + id === '' + roleProfileId)?.dashboard_pane_ids || [];
      const moduleDashboardPaneIds = this.dashboardPaneIdsByModuleName[moduleId] || [];

      return difference(
        union(roleProfileDashboardPaneIds, intersection(moduleDashboardPaneIds, this.role.dashboard_pane_ids)),
        intersection(moduleDashboardPaneIds, this.role.excluded_dashboard_pane_ids)
      );
    }

    limitedProfileOptionsBy(conceptName: string): TranslatePair[] {
      const beforeCustomSelected = this.previouslyCustomSelected(conceptName);
      if (this.accountStore.data.custom_roles_enabled || beforeCustomSelected) {
        return this.profileOptionsByConcept[conceptName];
      }

      return this.profileOptionsByConcept[conceptName].filter((o) => o[0] !== ROLE_PROFILE_CUSTOMIZED);
    }

    permissionProviderFor(conceptName: string): PermissionProvider {
      const moduleName = this.moduleNamesByName[conceptName];
      if (!moduleName) return {};

      const roleModuleName = this.role.role_module_names?.find(
        ({ role_id, module_name_id }) => role_id === role_id && module_name_id === moduleName.id
      );
      const roleProfile = this.role.role_profiles?.find(({ module_name_id }) => module_name_id === moduleName.id);
      const roleOrProfile = roleModuleName?.configuration_type === ConfigurationType.role_used ? this.role : roleProfile;
      return {
        managerial_hierarchy_access: roleModuleName ? roleModuleName.managerial_hierarchy_access : false,
        extended_permissions: roleOrProfile?.extended_permissions
          ?.filter(({ concept_name }) => concept_name === moduleName.name)
          .map(({ concept_name, name }) => ({ concept_name, name })),
        permissions: roleOrProfile?.permissions?.filter(({ concept_name }) => concept_name === moduleName.name),
      };
    }

    roleProfileDescriptionExists(concept: string): boolean {
      const roleProfileId = this.selectedProfileByConcept[concept];
      return !!this.roleProfileById[roleProfileId]?.description;
    }

    isCustomised(concept: string, moduleId: number): boolean {
      const roleProfileId = this.selectedProfileByConcept[concept];
      const currentProfile = this.roleProfileById[roleProfileId] || noRightsPerms;
      if (roleProfileId === UiRoleProfileIds.none) return false;
      if (roleProfileId === UiRoleProfileIds.customised) return false;

      const moduleName = this.moduleNamesByName[concept];
      const roleModuleName = this.role.role_module_names?.find(
        ({ role_id, module_name_id }) => role_id === role_id && module_name_id === moduleName.id
      );
      const mhaEqual = roleModuleName ? currentProfile.managerial_hierarchy_access === roleModuleName.managerial_hierarchy_access : true;
      const permsEqual = areProfileRolePermsEqual(concept, currentProfile, this.role);

      const moduleDashboardPaneIds = this.dashboardPaneIdsByModuleName[moduleId] || [];
      const samePanes =
        !intersection(moduleDashboardPaneIds, this.role.dashboard_pane_ids).length &&
        !intersection(moduleDashboardPaneIds, this.role.excluded_dashboard_pane_ids).length;

      return !mhaEqual || !permsEqual || !samePanes;
    }

    showEditButton(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): boolean {
      const roleProfileId = this.selectedProfileByConcept[moduleName.name];
      return roleProfileId !== UiRoleProfileIds.none;
    }

    profileTemplateResult(option: { id: string; text: string }): JQuery<HTMLElement> | string {
      const defaultDesc = {
        [UiRoleProfileIds.none]: this.$t('tenant.admin.roles.rtb.no_rights_user_desc'),
        [UiRoleProfileIds.customised]: this.$t('tenant.admin.roles.rtb.custom_desc'),
      }[option.id];
      const roleProfile = this.roleProfileById[option.id];
      const description = roleProfile?.description || defaultDesc;
      const roleProfileDesc = !!description ? `<div><small>${description}</small></div>` : '';

      if (roleProfile && this.unlinkedRoleProfile(roleProfile)) {
        return $(`<div class="text-bold">
                        ${option.text}
                        </div>
                        ${roleProfileDesc}
                        <i class="fa fa-warning text-danger"></i>
                        <small class="text-danger">${this.$t('tenant.admin.role_profiles.required_but_unassigned')}</small>
                `);
      }
      return $(`<div class="text-bold">${option.text}</div> ${roleProfileDesc}`);
    }

    unlinkedRoleProfile(roleProfile: Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>): boolean {
      const roles = (roleProfile.roles || []).filter((role) => role.active);
      return roleProfile?.required && !roles.length;
    }

    onRoleProfileChange(concept: string, value: string): void {
      this.selectedProfileByConcept[concept] = value;
      const roleProfile =
        this.roleProfilesByModuleName[concept] && this.roleProfilesByModuleName[concept].find(({ id }) => `${id}` === value);
      let permissions: Permission[] = [];
      let extended_permissions: Pick<ExtendedPermission, 'name' | 'concept_name'>[] = [];
      let configuration_type: ConfigurationType;
      let managerial_hierarchy_access: boolean;
      switch (value) {
        case UiRoleProfileIds.customised:
          configuration_type = ConfigurationType.role_used;
          permissions = this.defaultPermissions.filter((p) => p.concept_name === concept) || [];
          extended_permissions = this.defaultExtendedPermissions.filter((ep) => ep.concept_name === concept) || [];
          managerial_hierarchy_access =
            !this.role.id ||
            permissions.some((p) => p.access == PermissionAccess.view_access) ||
            extended_permissions.some((p) => p.name == GlobalAccess.View);
          break;
        case UiRoleProfileIds.none:
          configuration_type = ConfigurationType.no_access;
          managerial_hierarchy_access = false;
          break;
        default:
          configuration_type = ConfigurationType.role_profile_used;
          permissions = roleProfile?.permissions || [];
          extended_permissions = roleProfile?.extended_permissions || [];
          managerial_hierarchy_access = roleProfile ? roleProfile.managerial_hierarchy_access : false;
      }

      this.update({
        concept_name: concept,
        role_profile: roleProfile,
        configuration_type,
        managerial_hierarchy_access,
        permissions,
        extended_permissions,
        dashboard_pane_ids: [],
        excluded_dashboard_pane_ids: [],
      });
    }

    getRoleProfile(conceptName: string): string {
      const roleProfile = this.role.role_profiles?.find(({ module_name }) => module_name?.name === conceptName);
      const moduleName = this.moduleNamesByName[conceptName];

      if (roleProfile?.id) {
        return `${roleProfile.id}`;
      } else {
        // check if has any access, then it's customised profile
        if (this.configurationType(moduleName) === 'role_used') return UiRoleProfileIds.customised;
        // else it's no rights profile
        else return UiRoleProfileIds.none;
      }
    }

    configurationType(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): string | null {
      return (
        this.role.role_module_names?.find(({ role_id, module_name_id }) => role_id === role_id && module_name_id === moduleName.id)
          ?.configuration_type || null
      );
    }

    roleProfileOptions(moduleName: string): TranslatePair[] {
      const options = this.roleProfilesByModuleName?.[moduleName]?.map(({ name, id }) => [`${id}`, name] as TranslatePair) || [];
      return [...options, ...this.defaultRoleProfileOptions];
    }

    getExtendedPermissions(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): string[] {
      return (
        this.permissionProviderFor(moduleName.name)
          ?.extended_permissions?.map(({ name }) => this.extendedPermissionsOptions[moduleName.name][name])
          .sort() || []
      );
    }

    onCustomiseRightsModalInput(value: boolean): void {
      this.customiseRightsModal = value;
      if (!value) this.customiseRightsModuleName = null;
    }

    openCustomiseRoleModal(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): void {
      this.customiseRightsModuleName = moduleName;
      this.customiseRightsModal = true;
    }

    moduleHasGlobalExtendedPermission(
      moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>,
      access: keyof typeof GlobalAccess
    ): boolean {
      const provider = this.permissionProviderFor(moduleName.name);
      const extendedPermissions = extendedPermissionsByConceptAndName(provider.extended_permissions)[moduleName.name];
      return !!extendedPermissions?.[GlobalAccess[access]];
    }

    getPrettyModuleName(moduleName: Pick<ModuleName, RoleTabDetailsModuleNameOnly>): string {
      const displayName = displayModuleName(moduleName.name, null, this.moduleNamesByName, this.$t);
      return `${displayName} ${displayName !== moduleName.name ? `(${moduleName.name})` : ''}`;
    }

    conceptOptionsMap(concept_name: string): Record<string, string> {
      const options = epOptionsWithLabels(concept_name, this.confidentialityTypesByModuleName?.[concept_name] || []);

      return options.reduce((memo, ep) => {
        return {
          ...memo,
          [ep.name]: ep.label,
        };
      }, {});
    }

    getManager(): ListManager<ModuleName> | ListManagerStatic<ModuleName> {
      const baseManager = {
        fields: [
          ...this.baseFields,
          {
            title: this.$t('app.labels.role_profile'),
            name: 'profile',
            titleClass: 'justify-content-center',
            width: 'minmax(100px, 0.75fr)',
          },
          ...this.permissionsFields,
        ],
      };

      return new ListManager<ModuleName>({
        fetchDataFunction: (params) => {
          return this.$api.getModuleNames({ ...params, only: RTB_MODULE_NAME_ONLY }, { cache: true });
        },
        afterFetch: async (data) => {
          this.moduleNamesByName = keyBy(data, 'name');
          const { data: confidentialityTypes } = await this.$api.getConfidentialityTypes(
            {
              filters: { module_name_id: data.map((mn) => mn.id) },
              only: ['id', 'name', 'active', { module_name: ['name'] }],
              per_page: -1,
            },
            { cache: true }
          );
          this.confidentialityTypesByModuleName = groupBy(confidentialityTypes, 'module_name.name');
          const { data: roleProfiles } = await this.$api.getRoleProfiles(
            {
              filters: { module_name_id: data.map((mn) => mn.id), active: true },
              only: RTB_RP_ONLY,
              per_page: -1,
              sort: 'index',
            },
            { cache: true }
          );
          this.roleProfilesByModuleName = groupBy(roleProfiles, 'module_name.name');
          this.initSelectedProfiles();
        },
        customFilters: { active: true },
        useHistory: false,
        per_page: -1,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        ...baseManager,
      });
    }

    previouslyCustomSelected(conceptName: string): boolean {
      const moduleName = this.moduleNamesByName[conceptName];
      return this.defaultRoleModuleNames.some(
        (rmn) => rmn.module_name_id === moduleName?.id && rmn.configuration_type === ConfigurationType.role_used && !rmn.role_profile_id
      );
    }

    initSelectedProfiles(): void {
      this.selectedProfileByConcept = Object.keys(this.moduleNamesByName).reduce((acc, conceptName) => {
        return { ...acc, [conceptName]: this.getRoleProfile(conceptName) };
      }, {});
    }

    initDefaults(): void {
      this.defaultRoleModuleNames = this.role?.role_module_names || [];
      this.defaultPermissions = this.role?.permissions || [];
      this.defaultExtendedPermissions = this.role?.extended_permissions || [];
    }

    beforeMount(): void {
      this.manager = this.getManager();
      this.initDefaults();
    }
  }
