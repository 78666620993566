import { render, staticRenderFns } from "./sort-configurator.vue?vue&type=template&id=a9f5b37a&scoped=true&lang=pug"
import script from "./sort-configurator.vue?vue&type=script&lang=ts"
export * from "./sort-configurator.vue?vue&type=script&lang=ts"
import style0 from "./sort-configurator.vue?vue&type=style&index=0&id=a9f5b37a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9f5b37a",
  null
  
)

export default component.exports