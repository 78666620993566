
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  @Component({ components: { DsCheckbox } })
  export default class UniqQuestionConfig extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseQuestionOptions & { validate: { uniq?: StringBoolean } }>;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['uniq'].forEach((key) => this.$delete(this.value.config.validate, key));
    }
  }
