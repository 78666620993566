
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import HybridControlPanel from './hybrid-control-panel.vue';
  import HybridTable from './hybrid-table.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';

  @Component({
    components: {
      HybridControlPanel,
      HybridTable,
    },
  })
  export default class HybridModulePage extends Vue {
    @Prop(Number) readonly moduleNameId?: number;
    @Ref() readonly table?: InstanceType<typeof HybridTable>;

    moduleName: Nullable<ModuleName> = null;

    beforeMount() {
      this.loadModuleName();
    }

    beforeUpdate() {
      if (this.moduleName?.id != this.moduleNameId) {
        this.loadModuleName();
      }
    }

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.table?.reloadTable();
      }
    }

    async loadModuleName(): Promise<void> {
      if (this.moduleNameId) {
        const { data } = await this.$api.getModuleName(this.moduleNameId, { only: ['id', 'display', 'plural_display'] }, { cache: true });
        this.moduleName = data;
      }
    }
  }
