
  import BaseTable from '@app/components/base-table/base-table.vue';
  import type { ExpensableTransactionLedger } from '@app/models/expensable-transaction-ledger';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { formatMoney } from '@app/utils/format-money';

  @Component({
    methods: { formatMoney },
    components: { BaseTable },
  })
  export default class TransactionLedgersTable extends Vue {
    @Prop(Object) readonly filters?: DonesafeFilterOptions<ExpensableTransactionLedger>;

    manager: Nullable<ListManager<ExpensableTransactionLedger>> = null;
    get accountStore() {
      return useAccountStore();
    }

    getManager() {
      return new ListManager<ExpensableTransactionLedger>({
        fetchDataFunction: (params) =>
          this.$api.getExpensableTransactionLedgers(
            {
              ...params,
              only: [
                'id',
                'expensable_record_budget_category_id',
                'line_item_id',
                'created_at',
                'user_id',
                'line_item_quantity',
                'line_item_tax_rate',
                'ex_tax',
                'tax',
                'inc_tax',
                { user: ['id', 'full_name'] },
              ],
              filters: {
                ...params.filters,
                ...this.filters,
              },
            },
            { cache: true }
          ),
        fields: [
          { title: 'Transaction ID', name: 'id' },
          { title: 'Record Budget Instance', name: 'expensable_record_budget_category_id' },
          { title: 'Line Item ID', name: 'line_item_id', sortField: 'line_item_id' },
          { title: 'Transaction Date', name: 'created_at', sortField: 'created_at' },
          { title: 'Transaction Creator', name: 'user_id', sortField: 'user.full_name' },
          { title: 'LI QTY', name: 'line_item_quantity', sortField: 'line_item_quantity' },
          { title: 'LI Tax Rate', name: 'line_item_tax_rate', sortField: 'line_item_tax_rate' },
          { title: 'Adjustment ex.tax', name: 'ex_tax', sortField: 'ex_tax' },
          { title: 'Line Item tax', name: 'tax', sortField: 'tax' },
          { title: 'Adjustment inc.tax', name: 'inc_tax', sortField: 'inc_tax' },
        ],
      });
    }

    beforeMount() {
      this.manager = this.getManager();
    }
  }
