import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import type { PlaceholderFormOnly } from './utils';
import Blocking from '@app/mixins/blocking';
import type { PlaceholderProfile } from '@app/models/placeholder-profile';
import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
import { toaster } from '@app/utils/toaster';

@Component
export default class BasePlaceholderProfile extends Blocking {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() readonly form?: any;

  placeholderProfile: Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>> = {};
  visible = true;

  closeModal(command: ModalCloseCommand): void {
    this.$router.replace({
      name: 'admin-settings-placeholder-profiles',
      params: { reloadTable: command },
      query: this.$route.query,
    });
  }

  onSubmitError(_err: { data: { error: string } }, text: string) {
    toaster({ text: text, icon: 'error' });
  }
}
