
  import EntitySelector from '@app/components/entity-selector.vue';
  import ToocsField from '@app/components/form-fields/toocs-field.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import type { SelectOption } from '@app/models/question-options/shared';
  import { isReadonlyLookup, isSelect } from '@app/services/model-helpers';
  import { sortBy } from 'lodash';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { TOOCS_TYPE_SHOW_HIDE_CODES } from '@app/models/toocs';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SvgLibraryComponent } from '@app/models/svg-library/component';
  import type { Workflow } from '@app/models/workflow';
  import { FieldType, RULES_APPLICABLE_TYPES } from '@app/models/sub-form-question';
  import type { SvgSelectorQuestionOptions } from '@app/models/question-options/svg-selector-question-config';
  import { ShowHideSource } from '@app/models/question-options/base-question-options';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({ components: { EntitySelector, OrganizationSelector, LocationSelector, Select2, FormField, ToocsField } })
  export default class QuestionShowHideConfigurator extends Vue {
    @Model('input') value!: SubFormQuestion;
    @Prop(Object) subForm!: SubForm;
    @Prop(Object) rules?: Record<string, object>;
    @Prop(String) requiredMessage?: string;

    sameFormQuestions: SubFormQuestion[] = [];
    mainFormQuestions: SubFormQuestion[] = [];
    baseFormQuestions: SubFormQuestion[] = [];

    workflows: Workflow[] = [];
    subFormListWorkflows: Workflow[] = [];
    svgTemplateComponentsHash: Record<string, SvgLibraryComponent[]> = {};

    toocsSelectorOptions: { group: string; label: string; value: string }[] = [];

    get showConfigurator(): boolean {
      return !!this.availableOptions.length && ![FieldType.approval_state, FieldType.workflow_state].includes(this.value.field_type);
    }

    get sourceType():
      | 'calculator'
      | 'location'
      | 'organization'
      | 'select'
      | 'text'
      | 'svg'
      | 'expense_category'
      | 'expensing_table'
      | 'expensing_category'
      | 'toocs' {
      switch (this.showQuestion?.field_type) {
        case FieldType.location:
          return 'location';
        case FieldType.calculator:
          return 'calculator';
        case FieldType.organization:
          return 'organization';
        case FieldType.expense:
          return 'expense_category';
        case FieldType.expense_budget_select:
          return 'expensing_table';
        case FieldType.expense_budget_category_select:
          return 'expensing_category';
        case FieldType.toocs:
          return 'toocs';
        default:
          return 'select';
      }
    }

    get workflowOptions(): [string, string][] {
      if (this.subForm) {
        if (this.subForm.module_name === MAIN_FORM_MODULE_NAME) {
          return this.workflows.map((w) => [w.code, w?.name || '']);
        } else if (this.subForm.sub_form_type === 'workflow') {
          return this.subFormListWorkflows.map((w) => [`${w.id}`, w?.name || '']);
        } else {
          return this.workflows.map((w) => [w.code, w?.name || '']);
        }
      }
      return [];
    }

    get svgSelectorOptions(): [string, string][] {
      return (
        this.svgTemplateComponentsHash[`${this.showQuestion?.config.svg_template_id}`]?.map((t) => [t.code, `${t.label} (${t.code})`]) || []
      );
    }

    get showQuestion(): Maybe<SubFormQuestion> {
      const { source, id } = this.value.config.show || {};

      let questionList: SubFormQuestion[] = [];

      switch (source) {
        case ShowHideSource.MainForm:
          questionList = this.mainFormQuestions;
          break;
        case ShowHideSource.BaseForm:
          questionList = this.baseFormQuestions;
          break;
        default:
          questionList = this.sameFormQuestions;
      }

      return questionList.find((q) => `${q.system_code}` === `${id}`);
    }

    get showReadOnlyQuestionWarning(): boolean {
      const { id } = this.value.config.show || {};

      return !!id && !!this.showQuestion && isReadonlyLookup(this.showQuestion);
    }

    get selectOptions(): [string, string][] {
      if (this.sourceType === 'select' || this.sourceType === 'toocs') {
        const question = this.showQuestion;

        if (question) {
          switch (question.field_type) {
            case FieldType.approval_state:
              return (question.config.stages || [])?.map((s: string) => [s, s]);
            case FieldType.workflow_state:
              return this.workflowOptions;
            case FieldType.svg_selector:
              return this.svgSelectorOptions;
            default:
              if (isSelect(question) || question.field_type === FieldType.main_form_question_list) {
                return Object.values((question.options.values as Dictionary<SelectOption>) || {}).map((v: SelectOption) => [
                  v.key,
                  v.value === v.key ? v.value : `${v.value} (${v.key})`,
                ]);
              }
          }
        }
      }
      return [];
    }

    get allQuestions(): SubFormQuestion[] {
      return [...this.mainFormQuestions, ...this.baseFormQuestions, ...this.sameFormQuestions];
    }

    get showHideQuestionId(): string | undefined {
      const { source, id } = this.value.config.show || {};

      if (!id) return undefined;
      const stringId = String(id);

      if (source && source == ShowHideSource.MainForm) {
        return this.optionKeyWithCode(stringId, ShowHideSource.MainForm);
      }

      if (source && source == ShowHideSource.BaseForm) {
        return this.optionKeyWithCode(stringId, ShowHideSource.BaseForm);
      }

      return stringId;
    }

    get availableOptions(): { key: string; label: string }[] {
      const filteredSameFormQuestions = this.sameFormQuestions.filter((question) => question.system_code !== this.value.system_code);
      const availableOptions = [
        ...filteredSameFormQuestions.map((q) => ({ key: q.system_code, label: `${q.title} (${q.code})` })),
        ...this.mainFormQuestions.map((q) => ({
          key: this.optionKeyWithCode(q.system_code, ShowHideSource.MainForm),
          label: this.$t('tenant.admin.sub_form_questions.form.main_form', { name: `${q.title} (${q.code})` }).toString(),
        })),
        ...this.baseFormQuestions.map((q) => ({
          key: this.optionKeyWithCode(q.system_code, ShowHideSource.BaseForm),
          label: this.$t('tenant.admin.sub_form_questions.form.base_form', { name: `${q.title} (${q.code})` }).toString(),
        })),
      ];
      return sortBy(availableOptions, 'label');
    }

    get showFieldValidationMode(): string {
      return !!this.rules && !!Object.keys(this.rules).length ? 'passive' : '';
    }

    get errorMessages(): Record<string, string> {
      if (this.requiredMessage?.length) {
        return { required: this.requiredMessage };
      } else {
        return {};
      }
    }

    set showHideQuestionId(value: string | undefined) {
      if (!!value) {
        let source = undefined;

        if (this.optionStartsWithCode(value, ShowHideSource.MainForm)) {
          source = ShowHideSource.MainForm;
        } else if (this.optionStartsWithCode(value, ShowHideSource.BaseForm)) {
          source = ShowHideSource.BaseForm;
        }

        this.$$patch(this.value.config.show, {
          source: source,
          id: this.optionKeyWithoutCode(value, source),
          value: [],
        });
      } else {
        this.$$patch(this.value.config.show, {
          value: [],
          id: undefined,
          source: undefined,
        });
      }
    }

    onShowQuestionChange(): void {
      this.fetchRequiredData();
    }

    optionKeyWithCode(systemCode: string, source: ShowHideSource): string {
      return `${source}|${systemCode}`;
    }

    optionKeyWithoutCode(key: string, source: ShowHideSource | undefined): string {
      return key.replace(`${source}|`, '');
    }

    optionStartsWithCode(key: string, source: ShowHideSource): boolean {
      return key.startsWith(`${source}|`);
    }

    async fetchMainFormQuestions(subForm: SubForm, only: OnlyOptions<SubFormQuestion>) {
      if (!this.subForm || this.subForm.module_name === MAIN_FORM_MODULE_NAME) return [];

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({ filters: { name: subForm.module_name }, only: ['id', 'sub_form_id'] }, { cache: true });

      if (!moduleName) return [];

      const { data: mainFormQuestions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only,
          filters: {
            field_type: RULES_APPLICABLE_TYPES,
            sub_form_section: { sub_form_id: moduleName.sub_form_id },
            active: true,
          },
        },
        { cache: true }
      );

      return mainFormQuestions;
    }

    fetchBaseFormQuestions(baseFormId: number, only: OnlyOptions<SubFormQuestion>) {
      this.$api
        .getSubFormQuestions(
          {
            per_page: -1,
            only,
            filters: {
              field_type: RULES_APPLICABLE_TYPES,
              sub_form_section: { sub_form_id: baseFormId },
              active: true,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.baseFormQuestions = data;
        });
    }

    async beforeMount(): Promise<void> {
      const questionOnlyOptions: OnlyOptions<SubFormQuestion> = ['field_type', 'config', 'options', 'system_code', 'title', 'code'];

      this.loadToocsOptions();

      const { data: sameFormQuestions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only: questionOnlyOptions,
          filters: {
            field_type: RULES_APPLICABLE_TYPES,
            sub_form_section: { sub_form_id: this.subForm.id },
            active: true,
          },
        },
        { cache: true }
      );

      this.sameFormQuestions = sameFormQuestions;

      if (this.subForm.sub_form_type === 'workflow') {
        this.$api
          .getSubFormLists(
            { filters: { approval_sub_form_id: this.subForm.id }, only: ['id', 'workflows', 'sub_form_ids'] },
            { cache: true }
          )
          .then(({ data }) => {
            if (data[0]?.sub_form_ids?.[0]) {
              this.fetchBaseFormQuestions(data[0]?.sub_form_ids?.[0], questionOnlyOptions);
            }

            if (data[0]?.workflows) {
              this.subFormListWorkflows = data[0].workflows;
            }
          });
      }

      this.mainFormQuestions = await this.fetchMainFormQuestions(this.subForm, questionOnlyOptions);
      this.fetchRequiredData();
    }

    async loadToocsOptions(): Promise<void> {
      await this.$api.getToocs({ cache: true }).then(({ data }) => {
        this.toocsSelectorOptions = TOOCS_TYPE_SHOW_HIDE_CODES.map(([key, label]) => ({
          label: `${label}`,
          value: `type:${key}`,
          group: 'Type',
        })).concat(
          data.flatMap((toocsField) =>
            toocsField.options.map((toocsOption) => ({
              label: toocsOption[1],
              value: `${toocsField.key}:${toocsOption[0]}`,
              group: toocsField.label,
            }))
          )
        );
      });
    }

    fetchRequiredData(): void {
      if (!this.showConfigurator || !this.subForm) {
        return;
      }

      if (this.showQuestion?.field_type === FieldType.svg_selector) {
        this.$api
          .getSvgTemplate(
            (this.showQuestion?.config as SvgSelectorQuestionOptions).svg_template_id,
            { include: ['components'] },
            { cache: true }
          )
          .then(({ data }) => {
            this.svgTemplateComponentsHash = {
              ...this.svgTemplateComponentsHash,
              [`${(this.showQuestion?.config as SvgSelectorQuestionOptions).svg_template_id}`]: data.components || [],
            };
          });
      } else if (this.showQuestion?.field_type === FieldType.workflow_state) {
        if (this.subForm.module_name === MAIN_FORM_MODULE_NAME) {
          this.$api
            .getModuleNames(
              {
                filters: { sub_form_id: this.subForm.id },
                only: ['id', { workflows: ['code', 'name'] }],
              },
              { cache: true }
            )
            .then(({ data }) => {
              if (data.length) {
                this.workflows = data[0]?.workflows || [];
              }
            });
        } else {
          this.$api
            .getModuleNames(
              {
                filters: { name: this.subForm.module_name },
                only: ['id', { workflows: ['code', 'name'] }],
              },
              { cache: true }
            )
            .then(({ data }) => {
              if (data.length) {
                this.workflows = data[0]?.workflows || [];
              }
            });
        }
      }
    }
  }
