
  import { Component, Prop } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import BaseEventForm from './base-event-form';
  import EventQuestionFields from '../event-question-fields.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { completeLabel } from '@app/utils/complete-label';
  import FormField from '../../questions/edit/form-field.vue';

  @Component({ components: { Select2, EventQuestionFields, FormField } })
  export default class SubFormListEventForm extends BaseEventForm<Dictionary<string>> {
    @Prop(String) readonly createModule!: string;

    @Prop(Array) readonly dateOptions?: [string, string][];

    selectedSubFormList: Nullable<SubFormList> = null;
    subFormListAttributes: OnlyOptions<SubFormList> = [
      'id',
      'sub_form_ids',
      'approval_sub_form_id',
      'sub_form_list_type',
      {
        module_tab: ['id', 'module_name'],
        approval_sub_form: ['id', 'title'],
      },
    ];
    formQuestions: SubFormQuestion[] = [];

    get subFormListId(): number | undefined {
      if (!this.createModule.startsWith('to_') && !this.createModule.startsWith('from_') && !parseInt(this.createModule)) {
        return;
      }

      return parseInt(this.createModule.replace('to_', '').replace('from_', ''));
    }

    get stageOptions(): [string, string][] {
      return [
        ['Draft', this.$t('app.labels.draft').toString()],
        ['Complete', completeLabel(this.selectedSubFormList?.sub_form_list_type)],
      ];
    }

    async beforeMount(): Promise<void> {
      if (!this.subFormListId) {
        return;
      }

      this.form = { stage: 'Complete', ...this.values };
      const { data: selectedSubFormList } = await this.$api.getSubFormList(
        this.subFormListId,
        { only: this.subFormListAttributes },
        { cache: true }
      );

      this.selectedSubFormList = selectedSubFormList;

      if (isFinite(Number(selectedSubFormList?.sub_form_ids?.[0]))) {
        const { data: questions } = await this.$api.getSubFormQuestions(
          {
            filters: { sub_form_section: { sub_form_id: selectedSubFormList?.sub_form_ids?.[0] }, active: true },
            per_page: -1,
            sort: 'sub_form_section.index,index',
          },
          { cache: true }
        );
        this.formQuestions = questions;
      }
    }
  }
