
  import { useAccountStore } from '@app/stores/account'; // todo change the template to get rid of duplicates
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import AttachmentItem from './attachment-item.vue';
  import FileUploader from '@app/components/file-uploader/file-uploader.vue';
  import SidePanel from '@app/components/side-panel.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import type { File as FilePondFile } from 'filepond';
  import type { Attachment } from '@app/models/attachment';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ components: { FileUploader, SidePanel, AttachmentItem } })
  export default class AttachmentsPanel extends Vue {
    @Prop(Boolean) readonly canEdit!: boolean;
    @Prop(String) readonly entityType!: string;
    @Prop(Number) readonly entityId!: number;
    @Ref() readonly uploader?: FileUploader;

    attachments: Pick<Attachment, 'id' | 'is_image' | 'user' | 'extension' | 'file_name' | 'url' | 'created_at' | 'metadata'>[] = [];
    loading = false;

    loadingHash: Dictionary<boolean> = {};

    get accountStore() {
      return useAccountStore();
    }

    onDelete(attachment: Attachment): void {
      if (confirm(this.$t('app.labels.delete_this_attachment') as string)) {
        this.loadingHash[attachment.id] = true;
        this.$api
          .deleteAttachment(attachment.id)
          .then(({}) => {
            this.$api.cache.clear();
            this.fetchAttachments();
          })
          .finally(() => {
            delete this.loadingHash[attachment.id];
          });
      }
    }

    onEdit(attachment: Attachment, newBlob: Blob): void {
      this.uploader?.addFile(newBlob)?.then((fpf: FilePondFile) => {
        fpf.setMetadata('attachmentId', attachment.id);
      });
    }

    onUpload(blob: ActiveStorageBlob & { metadata: { attachmentId: number } }): void {
      this.loading = true;
      this.$api
        .createAttachment({
          attachable_id: this.entityId,
          attachable_type: this.entityType,
          file: blob.signed_id,
        })
        .then(() => {
          if (blob.metadata.attachmentId) {
            return this.$api.deleteAttachment(blob.metadata.attachmentId).then(() => {
              this.attachments = this.attachments.filter((a) => a.id !== blob.metadata.attachmentId);
            });
          }
        })
        .finally(() => {
          this.$api.cache.clear();
          this.loading = false;
          this.fetchAttachments();
        });
    }

    fetchAttachments(): void {
      this.loading = true;
      this.$api
        .getAttachments(
          {
            only: [
              'id',
              'is_variable',
              'is_image',
              'extension',
              'file_name',
              'url',
              'web_thumbnail_url',
              'created_at',
              'metadata',
              'is_previewable',
              { user: ['id', 'full_name'] },
            ],
            sort: '-created_at',
            filters: {
              attachable_id: this.entityId,
              attachable_type: this.entityType,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.attachments = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    mounted(): void {
      this.fetchAttachments();
    }
  }
