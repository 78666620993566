
  import { API_NULL } from '@app/constants';
  import { mixins } from 'vue-class-component';
  import { Component, Model, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import TextHighlight from 'vue-text-highlight';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import type { ModuleName } from '@app/models/module-name';

  interface TriggerSourceFilterOption {
    key: string;
    label: string;
    subFormIds?: number[];
  }
  @Component({ components: { DsDropdown, TextHighlight } })
  export default class TriggerSourceFilter extends mixins(WithSearchableDsDropdown) {
    @Model('input') readonly value!: string;
    @Prop(String) readonly label!: string;
    @Prop(Boolean) readonly disabled?: boolean;

    moduleNames: ModuleName[] = [];
    dropdownOpen = false;

    async fetchModuleNames(): Promise<void> {
      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          only: ['display', 'id', 'sub_form_id', { sub_forms: ['id'] }],
          per_page: -1,
          show_all: true,
          filters: { active: true },
          sort: 'display',
        },
        {
          cache: true,
        }
      );
      this.moduleNames = moduleNames;
    }

    beforeMount(): void {
      this.fetchModuleNames();
    }

    staticOptions: TriggerSourceFilterOption[] = [
      { key: '', label: this.$t('app.labels.all') },
      { key: 'Activity', label: this.$t('app.labels.action') },
      { key: API_NULL, label: this.$t('app.labels.scheduler') },
      { key: 'TenantUser', label: this.$t('app.labels.user') },
    ];

    get moduleNameOptions(): TriggerSourceFilterOption[] {
      return this.moduleNames.map((mn) => ({
        key: `ModuleName|${mn.id}`,
        label: mn.display,
        subFormIds: [mn.sub_form_id as number, ...(mn.sub_forms?.map((f) => f.id) || [])],
      }));
    }

    get options(): TriggerSourceFilterOption[] {
      return [...this.staticOptions, ...this.moduleNameOptions];
    }

    get currentOption(): TriggerSourceFilterOption | undefined {
      return this.options.find((option) => option.key === this.value);
    }

    get currentLabel(): string {
      const current = this.currentOption?.label || this.options[0].label;
      return `${this.label}: ${current}`;
    }

    get searchableOptions(): TriggerSourceFilterOption[] {
      return this.getSearchableItems<TriggerSourceFilterOption>(this.moduleNameOptions, 'label');
    }
  }
