
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestionReference } from '@app/components/admin/questions/edit/models';
  import SubFormQuestionSelector from '@app/components/admin/questions/edit/sub-form-question-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  @Component({ components: { SubFormQuestionSelector } })
  export default class HighestFormDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & { default: SubFormQuestionReference }>;
    @Prop(Object) subForm!: SubForm;

    onQuestionChange(change: SubFormQuestionReference) {
      if (!this.value.config.default) {
        return;
      }

      this.$$patch(this.value.config.default, change);
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['sub_form_question_code', 'sub_form_list_id', 'if_missing', 'transform'].forEach((key) =>
        this.$delete(this.value.config.default, key)
      );
    }
  }
