
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '@app/components/admin/questions/filter-configurator/utils';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { map } from 'lodash';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import RecordFilterConfigurator from '../filter-configurator/record-filter-configurator.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { RecordRelationMode } from '@app/models/record-relation';
  import { RecordLinkBehaviour } from '@app/models/module-name';
  import type { RecordRelationQuestionOptions } from '@app/models/question-options/record-relation-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { Tuple } from '@app/utils/types/tuple';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  const MODULE_NAME_ONLY = Tuple(['available_index_options', ...WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY] as const);

  @Component({
    components: { FormField, DsCheckbox, Select2, RecordFilterConfigurator, EntitySelector },
    methods: { getRecordLinkBehaviourOptions },
  })
  export default class RecordRelationFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<RecordRelationQuestionOptions>;
    @Prop(Object) subForm!: SubForm;

    moduleName: ModuleName | null = null;
    targetModuleName: Pick<ModuleName, FirstLevelKeyLiterals<(typeof MODULE_NAME_ONLY)[number]>> | null = null;
    modeOptions: [RecordRelationMode, string][] = [];
    loaded = false;

    get availableIndexOptions(): [string, string][] {
      return map(this.targetModuleName?.available_index_options || {}, (option, key) => [key, option.name]);
    }

    get defaultTemplatesFilters(): DonesafeFilterOptions<DefaultTemplate> {
      return {
        active: true,
        module_name_id: this.targetModuleName?.id,
      };
    }

    get relationshipsFilter() {
      return this.relationshipsFilterFor(this.mode);
    }

    get mode(): RecordRelationMode {
      return this.value.config.relationship_mode;
    }

    relationshipsFilterFor(mode: RecordRelationMode): DonesafeFilterOptions<Relationship> {
      const defaultFilter = { active: true };

      if (mode === RecordRelationMode.from) {
        return {
          ...defaultFilter,
          from_module: this.moduleName?.name,
        };
      }

      if (mode === RecordRelationMode.to) {
        return {
          ...defaultFilter,
          to_module: this.moduleName?.name,
        };
      }

      return defaultFilter;
    }

    onRelationshipCodeUpdate(code?: string): void {
      this.$$patch(this.value.config, { filters_select: [], filters: [] });

      this.fetchTargetModule(code);
    }

    async fetchTargetModule(code?: string): Promise<void> {
      this.targetModuleName = await this.loadModuleName(code);
    }

    async loadModuleName(code?: string): Promise<ModuleName | null> {
      if (!code) {
        return null;
      }

      const moduleNameKey = this.mode === RecordRelationMode.from ? 'to_module_name' : 'from_module_name';

      const { data: [{ [moduleNameKey]: moduleName } = {}] = [] } = await this.$api.getRelationships(
        { filters: { code }, only: ['id', 'name', { [moduleNameKey]: MODULE_NAME_ONLY }] },
        { cache: true }
      );

      return moduleName || null;
    }

    onUpdateMode(): void {
      this.onRelationshipCodeUpdate();
    }

    async preFetchRelationships(): Promise<void> {
      const defaultOptions = {
        page: 1,
        per_page: 25,
        only: Tuple(['code', 'name'] as const),
        sort: 'name',
      };

      const [{ data: from }, { data: to }] = await Promise.all([
        this.$api.getRelationships({ ...defaultOptions, filters: this.relationshipsFilterFor(RecordRelationMode.from) }, { cache: true }),
        this.$api.getRelationships({ ...defaultOptions, filters: this.relationshipsFilterFor(RecordRelationMode.to) }, { cache: true }),
      ]);

      this.modeOptions = [
        ...this.modeOptions,
        ...(from.length ? [[RecordRelationMode.from, 'From this module to...']] : []),
        ...(to.length ? [[RecordRelationMode.to, 'To this module from...']] : []),
      ] as [[RecordRelationMode, string]];
    }

    async beforeMount(): Promise<void> {
      this.$$patch(
        this.value.config,
        {
          link_first_column: 'true',
          allow_remove_proposed: 'true',
          allow_remove_existing: 'true',
          allow_select: 'true',
          allow_search: 'true',
          allow_add_new: 'true',
          record_link_behaviour: RecordLinkBehaviour.GoToRecord,
        },
        { ifEmpty: true }
      );

      if (this.mode) {
        this.fetchTargetModule(this.value.config.relationship_code);
      }

      const moduleNameFilter =
        this.subForm.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({ filters: { active: true, ...moduleNameFilter } }, { cache: true });

      this.moduleName = moduleName;

      if (moduleName) {
        await this.preFetchRelationships();
      }

      this.loaded = true;
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'allow_add_new',
        'allow_remove_existing',
        'allow_remove_proposed',
        'allow_search',
        'allow_select',
        'default_template_id',
        'filters',
        'filters_select',
        'index_options',
        'link_first_column',
        'panel_title',
        'record_link_behaviour',
        'relationship_code',
        'relationship_mode',
        'reverse',
        'sort',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
