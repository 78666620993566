import { render, staticRenderFns } from "./view-registration-page.vue?vue&type=template&id=206f5ff6&scoped=true&lang=pug"
import script from "./view-registration-page.vue?vue&type=script&lang=ts"
export * from "./view-registration-page.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206f5ff6",
  null
  
)

export default component.exports