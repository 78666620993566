
  import { Component } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { RegulatoryReport } from '@app/models/regulatory-report';
  import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  @Component({
    components: { EventInputMethodSwitch, EntitySelector, Select2, AdvancedAutosuggest, FormField },
  })
  export default class RegulatoryReportEventForm extends BaseEventForm<
    Pick<RegulatoryReport, 'user_id' | 'regulatory_report_config_id' | 'options'>
  > {
    selectedRegulatoryReportConfig: Nullable<RegulatoryReportConfig> = null;

    get defaultReportFormats(): string[] {
      return (
        this.selectedRegulatoryReportConfig?.config?.available_formats?.filter((format) => format.checked)?.map((format) => format.key) ||
        []
      );
    }

    selectRegulatoryReportConfig(regulatoryReportConfigId?: string): void {
      this.resetForm();
      this.loadRegulatoryReportConfig(Number(regulatoryReportConfigId));
    }

    convertFilters(filter: object = {}): object {
      return Object.fromEntries(
        Object.entries(filter).map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return [key, this.convertFilters(value)];
          } else if (key.startsWith('~')) {
            if (this.form.options?.report_filters && this.form.options?.report_filters[value]) {
              return [key.replace(/^[~]+/, ''), this.form.options?.report_filters[value]];
            }

            return [key, value];
          } else {
            return [key, value];
          }
        })
      );
    }

    loadRegulatoryReportConfig(regulatoryReportConfigId?: number): void {
      if (regulatoryReportConfigId) {
        this.$api.getRegulatoryReportConfig(regulatoryReportConfigId, {}, { cache: true }).then(({ data }) => {
          this.selectedRegulatoryReportConfig = data;
          this.form = {
            ...this.form,
            regulatory_report_config_id: regulatoryReportConfigId,
            options: {
              ...this.form.options,
              report_formats: this.form.options?.report_formats || this.defaultReportFormats,
            },
          };
        });
      } else {
        this.selectedRegulatoryReportConfig = null;
      }
    }

    resetForm(): void {
      this.form = {
        options: {
          report_formats: [],
          report_filters: {},
        },
      };
    }

    beforeMount(): void {
      this.form = {
        ...this.values,
        options: {
          ...this.values?.options,
          report_formats: [...(this.values?.options?.report_formats || [])],
          report_filters: { ...this.values?.options?.report_filters },
        },
      };
      this.loadRegulatoryReportConfig(this.form.regulatory_report_config_id);
    }
  }
