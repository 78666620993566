
  import { Component, Vue } from 'vue-property-decorator';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal } })
  export default class RootActivityPage extends Vue {
    visible = true;

    get translateTitle(): string {
      const title = this.$route.name ?? '';
      switch (title) {
        case 'activity-view':
          return this.$t('app.labels.action');
        case 'activity-edit':
          return this.$t('app.labels.edit_action');
        default:
          return this.$t('app.labels.action');
      }
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'activities-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
      this.$emit('reload');
    }
  }
