import type { BaseEntity } from './base-entity';

export enum ConfigurationType {
  no_access = 'no_access',
  role_profile_used = 'role_profile_used',
  role_used = 'role_used',
}

export interface RoleModuleName extends BaseEntity {
  configuration_type: ConfigurationType;
  managerial_hierarchy_access: boolean;
  module_name_id: number;
  role_id?: number;
  role_profile_id: Nullable<number>;
}
