
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Prop, Vue } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import type { DefaultTemplate } from '@app/models/default-template';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { RawLocation } from 'vue-router';
  import ModuleDefaultTemplateActions from './module-default-template-actions.vue';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      BaseTable,
      FilterSelect,
      Select2,
      SearchInput,
      PaperTrailsModalLink,
      DsDropdown,
      ModuleDefaultTemplateActions,
    },
  })
  export default class ModuleDefaultTemplatesPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    @Ref() readonly table?: BaseTable<DefaultTemplate>;

    manager: Nullable<ListManager<DefaultTemplate>> = null;

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    getManager(): ListManager<DefaultTemplate> {
      return new ListManager<DefaultTemplate>({
        fetchDataFunction: (params) => {
          return this.$api.getDefaultTemplates(
            {
              ...params,
              only: ['id', 'name', 'active', 'show_ui', 'system_code', 'index'],
              filters: { ...params.filters, module_name_id: this.moduleNameId },
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        customFilters: { search: '' },
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index' },
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name', filter: true },
          { title: this.$t('app.labels.system_code'), name: 'system_code', sortField: 'system_code' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
          {
            title: this.$t('tenant.admin.default_templates.form.allow_select_on_create'),
            name: 'show_ui',
            sortField: 'show_ui',
            filter: true,
          },
          { title: '', name: 'operations', width: '50px' },
        ],
        allowFilters: true,
      });
    }

    editDefaultTemplateLink(defaultTemplateId: number): RawLocation {
      return {
        name: 'admin-default-templates-edit-page',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          defaultTemplateId: `${defaultTemplateId}`,
        },
      };
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateDefaultTemplateIndexes({ data, sort })
          .then(({ data }) => {
            this.$api.cache.clear();
            toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    get addDefaultTemplateLink(): RawLocation {
      return {
        name: 'admin-default-templates-new-page',
        params: { moduleNameId: `${this.moduleNameId}` },
      };
    }
  }
