
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import type { ModuleName, WizardOptionsItem } from '@app/models/module-name';

  @Component({ components: { Select2, ValidationProvider, ValidationObserver } })
  export default class SuccessPageSetupItemForm extends Vue {
    @Model('input') value!: WizardOptionsItem;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'display' | 'plural_display'>;
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly withContinueTo?: boolean;
    @Prop(Boolean) readonly withBackToDashboard?: boolean;
    @Prop(Boolean) readonly withBackTo?: boolean;
    @Prop(Boolean) readonly withBackToList?: boolean;
    @Prop(Boolean) readonly withAddAnother?: boolean;
    @Prop(Boolean) readonly withAddAnotherRelated?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    iconListTemplate(option: { id: string; text: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      const title = option.id === 'check_circle' ? `${option.id} (default)` : option.id;
      return $(
        `<div>
            <i class="material-icons">${option.id}</i>
            <span style="position: relative; bottom: 7px">${title}</span>
          </div>`
      );
    }

    submit(): void {
      this.$emit('input', this.value);
    }

    getNumberOfCharactersRemain(measuredString?: string): number {
      return measuredString?.length || 0;
    }
  }
