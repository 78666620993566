
  import BaseTable from '@app/components/base-table/base-table.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import { activeRecordBudgetBehaviours } from '@app/components/expensable/expensable-helpers';
  import RecordBudgetCategoryFormModal from '@app/components/expensable/record-budget-category-form-modal.vue';
  import TransactionLedgersTable from '@app/components/expensable/transaction-ledgers-table.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import type { ExpensableRecordBudgetBehaviour } from '@app/models/expensable-record-budget-behaviour';
  import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';
  import type { ExpensableRecordBudgetCategory } from '@app/models/expensable-record-budget-category';
  import { SpecialPermissionName } from '@app/models/extended-permission';
  import { entityPathPrefix } from '@app/services/helpers';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { useAccountStore } from '@app/stores/account';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { formatMoney } from '@app/utils/format-money';
  import bootbox from 'bootbox';
  import qs from 'qs';
  import { Component, Ref, Mixins } from 'vue-property-decorator';

  @Component({
    components: {
      RecordBudgetCategoryFormModal,
      DsDropdown,
      TransactionLedgersTable,
      DsTextInput,
      PaperTrailsModalLink,
      BaseTable,
      DsModal,
    },
    methods: { formatMoney },
  })
  export default class BudgetsTab extends Mixins(SubFormListBase) {
    @Ref() readonly table?: BaseTable<ExpensableRecordBudgetCategory>;
    categoryDataFields = [
      { title: 'Current Units', name: 'dataField', field: 'line_item_quantity' },
      { title: 'Current Total Spend tax', name: 'dataField', field: 'tax' },
      { title: 'Current Total Spend ex.tax', name: 'dataField', field: 'ex_tax' },
      { title: 'Current Total Spend inc.tax', name: 'dataField', field: 'inc_tax' },
    ];
    budget: Nullable<ExpensableRecordBudget> = null;

    showLedgers = false;

    loading = false;

    moduleBudgetInactive = false;

    manager: Nullable<ListManager<ExpensableRecordBudgetCategory>> = null;

    selectedRecordBudgetCategory: Nullable<ExpensableRecordBudgetCategory> = null;

    get accountStore() {
      return useAccountStore();
    }

    get totalColumnStyle() {
      return {
        gridColumn: `span ${this.activeBehaviours.length + 1}`,
      };
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get showAuditTrail(): boolean {
      return (
        !!this.subFormList.module_name &&
        (this.currentUserStore.hasSystemAdministrationPermission ||
          this.currentUserStore.hasRoleExtendedPermission(SpecialPermissionName.audit_trail, this.subFormList.module_name?.name))
      );
    }

    get recordBudgetCategories() {
      return this.manager?.items || [];
    }

    get manageCategoriesPath() {
      return `/${entityPathPrefix('ModuleRecord')}/${this.record.id}/record_budget_categories${qs.stringify(
        { tab: this.$route.query.tab, record_budget_id: this.budget?.id },
        { addQueryPrefix: true }
      )}`;
    }

    get fields(): ListManagerField[] {
      return [
        { title: 'Expense Category', name: 'category', sortField: 'category.name' },
        ...this.activeBehaviours.map((b) => ({ title: b.behaviour?.name, name: 'behaviour', behaviour: b })),
        ...this.categoryDataFields,
        { title: '', name: 'actions', hidden: !this.editable },
      ];
    }

    get activeBehaviours(): ExpensableRecordBudgetBehaviour[] {
      return activeRecordBudgetBehaviours(this.budget);
    }

    get showEditModal() {
      return !!this.selectedRecordBudgetCategory;
    }

    set showEditModal(value: boolean) {
      if (!value) {
        this.selectedRecordBudgetCategory = null;
      }
    }

    createNow() {
      this.subFormList.expensable_expensing_table_id &&
        this.$api
          .createExpensableRecordBudget({
            budgetable_id: this.record.id,
            budgetable_type: 'ModuleRecord',
            expensable_expensing_table_id: this.subFormList.expensable_expensing_table_id,
          })
          .then(() => {
            this.$api.cache.clear();
            this.fetchRecordBudgets();
          });
    }

    markRecordBudgetReady() {
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result && this.budget) {
            this.$api.updateExpensableRecordBudget(this.budget.id, { ready: true }).then(() => {
              this.$api.cache.clear();
              this.fetchRecordBudgets();
            });
          }
        },
      });
    }

    dataFieldOutput(rowData: ExpensableRecordBudgetCategory, field: keyof ExpensableRecordBudgetCategory['transaction_ledgers_data']) {
      if (field === 'line_item_quantity') {
        return rowData.transaction_ledgers_data?.[field] || 0;
      }

      return formatMoney(rowData.transaction_ledgers_data?.[field] || 0);
    }

    dataFieldTotal(field: keyof ExpensableRecordBudgetCategory['transaction_ledgers_data']) {
      if (field === 'line_item_quantity') {
        return this.recordBudgetCategories.reduce((memo, rbc) => memo + (rbc.transaction_ledgers_data?.[field] || 0), 0);
      }

      return formatMoney(
        this.recordBudgetCategories.reduce((memo, rbc) => memo + parseFloat(rbc.transaction_ledgers_data?.[field] || '0'), 0)
      );
    }

    updateCategory(recordBudgetCategory: ExpensableRecordBudgetCategory) {
      this.$api.updateExpensableRecordBudgetCategory(recordBudgetCategory.id, recordBudgetCategory).then(() => {
        this.$api.cache.clear();
        this.selectedRecordBudgetCategory = null;
        this.table?.reload();
      });
    }

    editRecordBudgetCategory(rbc: ExpensableRecordBudgetCategory) {
      this.selectedRecordBudgetCategory = rbc;
    }

    getRecordBudgetCategoriesManager(budget: ExpensableRecordBudget) {
      return new ListManager<ExpensableRecordBudgetCategory>({
        fetchDataFunction: (params) =>
          this.$api.getExpensableRecordBudgetCategories(
            {
              ...params,
              per_page: -1,
              include: ['record_budget_category_values', 'category', 'transaction_ledgers_data'],
              filters: {
                ...params.filters,
                visible: true,
                expensable_record_budget_id: budget.id,
              },
            },
            { cache: true }
          ),
        sortOrder: [{ direction: 'desc', field: 'category', sortField: 'category.name' }],
        rowClass: (item) => (item.active ? '' : 'warning-color-text'),
        fields: this.fields,
      });
    }

    expectedValue(behaviour: ExpensableRecordBudgetBehaviour, recordBudgetCategory: ExpensableRecordBudgetCategory) {
      return (
        recordBudgetCategory?.record_budget_category_values?.find((value) => value.expensable_record_budget_behaviour_id === behaviour.id)
          ?.expected_value || ''
      );
    }

    async fetchRecordBudgets() {
      if (!this.subFormList.expensable_expensing_table_id) {
        return;
      }
      this.loading = true;
      try {
        const { data: budgets } = await this.$api.getExpensableRecordBudgets(
          {
            filters: {
              budgetable_id: this.record.id,
              budgetable_type: 'ModuleRecord',
              expensable_expensing_table_id: this.subFormList.expensable_expensing_table_id,
            },
            // TODO: replace include with only
            include: ['record_budget_behaviours', 'behaviour', 'expensing_table', 'expensing_table_behaviours'],
          },
          { cache: true }
        );
        this.budget = budgets[0] || null;
        this.budget && (this.manager = this.getRecordBudgetCategoriesManager(this.budget));
      } finally {
        this.loading = false;
      }
    }

    beforeMount() {
      this.fetchRecordBudgets();
    }
  }
