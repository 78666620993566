
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import { useAccountStore } from '@app/stores/account';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import SearchInput from '@app/components/search-input.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import { ModuleType, moduleTypeOptions } from '@app/models/module-name';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({ components: { Select2, FilterSelect, SearchInput, BaseTable } })
  export default class ModuleNamesListPage extends Vue {
    manager = new ListManager<ModuleName>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<ModuleName> = ['id', 'name', 'display', 'plural_display', 'module_type'];
        return this.$api.getModuleNames(
          { ...params, filters: { ...params.filters, active: !this.$route.query.hidden }, only, show_all: true },
          { cache: true }
        );
      },
      useHistory: true,
      sortOrder: [{ direction: 'asc', field: 'display', sortField: 'display' }],
      per_page: 25,
      allowFilters: true,
      fields: [
        { title: 'ID', name: 'id', sortField: 'id' },
        { title: 'Display Name', name: 'display', sortField: 'display' },
        { title: 'Display Plural ', name: 'plural_display', sortField: 'plural_display' },
        { title: 'System Name', name: 'name', sortField: 'name' },
        { title: 'Module Type', name: 'module_type', sortField: 'module_type', filter: true },
        { title: '', name: 'operations' },
      ],
    });

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get moduleTypeOptions(): [string, string][] {
      return Object.entries(this.moduleTypeMapping).filter(([key]) => {
        if (!this.accountStore.expensingEnabled) {
          return key !== (ModuleType.transactor as string);
        }
        return true;
      });
    }

    get moduleTypeMapping(): Record<ModuleType, string> {
      return moduleTypeOptions(this.$t);
    }
  }
