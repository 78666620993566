
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import AdminSettingsOrganizationForm from '../../../components/admin/organizations/admin-settings-organization-form.vue';
  import type { Organization } from '@app/models/organization';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { AdminSettingsOrganizationForm, DsModal } })
  export default class AdminSettingsOrganizationNew extends Vue {
    @Ref() readonly form?: AdminSettingsOrganizationForm;

    organization = {};

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-organizations-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<Organization>): void {
      this.$api
        .createOrganization(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
