
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import Scored from './shared/scored.vue';
  import type { Procedure } from '@app/models/procedure';
  import type { KnowledgeBaseQuestionOptions } from '@app/models/question-options/knowledge-base-question-options';

  @Component({ components: { Scored } })
  export default class KnowledgeBaseShowOptions extends BaseShowOptions<KnowledgeBaseQuestionOptions> {
    knowledgeBase: Partial<Pick<Procedure, 'id' | 'name'>> = { name: '' };

    beforeMount() {
      this.$api
        .getProcedure(Number(this.config.procedure_id), { only: ['id', 'name'] }, { join: true, cache: true })
        .then(({ data }) => {
          this.knowledgeBase = data;
        })
        .catch(() => {
          this.knowledgeBase = { name: this.$t('tenant.admin.sub_form_questions.options.show.does_not_exist') as string };
        });
    }
  }
