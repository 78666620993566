export interface PlaceholderKeyMapping {
  description: string;
  key: string;
  title: string;
}

// split on 'dataq', '|' and on words ending with dot
// split on 'q' if it is a first character or have '.' or '|' character before
export const getItemSteps = (item: string): string[] => {
  const parts = item.split(/(\||[\w-]+\.*)/g).filter(Boolean);
  const keywords = ['q', 'dataq'];

  return parts
    .map((step, ind) => {
      const previous = parts?.[ind - 1] || null;
      // need that as Safari doesn't support lookbehind expressions

      const keywordPart = keywords.find((s) => step.startsWith(s));

      if (keywordPart && !item.startsWith('variable.') && (!previous || previous.endsWith('.') || previous.endsWith('|'))) {
        return [keywordPart, step.replace(keywordPart, '')];
      }

      return step;
    })
    .flat();
};
