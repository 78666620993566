
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { PaperTrailsFilters } from '@app/models/paper-trail-version';
  import { DEFAULT_FILTERS } from '@app/models/paper-trail-version';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown } })
  export default class ModuleTabListActions extends Vue {
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;

    moduleTabIds: number[] = [];

    beforeMount(): void {
      this.$api
        .getModuleTabs({
          filters: { related_module_name: { id: this.moduleNameId } },
          per_page: -1,
          only: ['id'],
        })
        .then(({ data }) => (this.moduleTabIds = data.map((moduleTab) => moduleTab.id)));
    }

    get filters(): PaperTrailsFilters {
      return { ...DEFAULT_FILTERS, item_type: ['ModuleTab'] };
    }
  }
