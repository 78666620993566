import { isEmpty, omit, pick } from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';
import type { ValidationObserver } from 'vee-validate';
import { SCHEDULED_EVENT_ONLY } from '@app/components/admin/scheduled-events/utils';
import type { ScheduledEvent } from '@app/models/scheduled-event';
import type { Event } from '@app/models/event';
import { toaster } from '@app/utils/toaster';

type RuleBuilderRef = {
  ruleBuilderPanel: {
    builder: JQuery<HTMLElement>;
  };
};

@Component
export default class WithScheduleSubmit extends Vue {
  @Ref() readonly form!: {
    createObjectEventForm: RuleBuilderRef & { showRuleBuilder: boolean };
    formElement: HTMLFormElement;
    validator: InstanceType<typeof ValidationObserver>;
  };

  submitting = false;

  async validateForm(): Promise<boolean> {
    const newFormValid = await this.form.validator?.validate();

    return !!newFormValid;
  }

  constructEntity(entity: Partial<ScheduledEvent>, event: Partial<Event>): Partial<ScheduledEvent> {
    return {
      ...pick(entity, ['name', 'schedule', 'active', 'status', 'recurring_setup']),
      event: { ...this.clearEvent(event), chained_events: this.constructChainedEvents(event) } as Event,
    };
  }

  clearEvent(event: Partial<Event>): Partial<Event> {
    const callToActionPathToRemove = isEmpty(event.options?.values?.call_to_action) ? 'options.values.call_to_action' : '';
    return omit(event, 'id', 'created_at', 'updated_at', callToActionPathToRemove) as Event;
  }

  constructChainedEvents(parentEvent: Partial<Event>): Event[] {
    if (!parentEvent.chained_events?.[0]) return [];

    const event = parentEvent.chained_events[0];
    if (event.event_type == null) return [];

    return [this.clearEvent(event) as Event];
  }

  async submit(
    se: Partial<ScheduledEvent>,
    event: Partial<Event>,
    opts: {
      onSubmitComplete: (se: ScheduledEvent) => void;
    }
  ): Promise<void> {
    const { onSubmitComplete } = opts;
    this.submitting = true;
    const formValid = await this.validateForm();

    if (!formValid) {
      this.submitting = false;
      return;
    }

    const createUpdateSe = this.constructEntity(se, event);
    const promise = se.id
      ? this.$api.updateScheduledEvent(se.id, { ...createUpdateSe, only: SCHEDULED_EVENT_ONLY })
      : this.$api.createScheduledEvent({ ...createUpdateSe, only: SCHEDULED_EVENT_ONLY });

    promise
      .then(({ data }) => {
        this.$api.cache.clear();
        onSubmitComplete(data);
      })
      .catch(({ data }) => {
        this.submitting = false;
        toaster({ text: data.error, icon: 'error' });
      });
  }
}
