
  import BaseTable from '@app/components/base-table/base-table.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import SearchInput from '@app/components/search-input.vue';
  import Select2 from '@app/components/select2.vue';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { SubForm } from '@app/models/sub-form';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({ components: { Select2, FilterSelect, EntitySelector, SearchInput, BaseTable } })
  export default class AdminSubFormsListPage extends Vue {
    @Prop([Number, String]) readonly moduleNameId?: number | string;
    @Prop(Boolean) readonly showModuleColumn?: boolean;

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];

    manager = new ListManager<SubForm>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<SubForm> = [
          'id',
          'title',
          'active',
          'module_name',
          {
            sub_form_module_name: ['id', 'display', 'name'],
            sub_form_lists: ['title', 'module_tab_id'],
          },
        ];
        return this.$api.getSubForms(
          {
            ...params,
            filters: {
              ...params.filters,
              '!module_name': MAIN_FORM_MODULE_NAME, // just in case
              sub_form_module_name: { id: this.moduleNameId, active: true },
            },
            only,
          },
          { cache: true }
        );
      },
      useHistory: true,
      customFilters: { search: '' },
      sortOrder: [{ direction: 'desc', field: 'id', sortField: 'id' }],
      per_page: 25,
      fields: [
        ...[
          { title: 'ID', name: 'id', sortField: 'id' },
          { title: 'Title', name: 'title', sortField: 'title' },
          { title: 'Tab Sections', name: 'tab_sections' },
        ],
        ...(this.showModuleColumn ? [{ title: 'Module', name: 'module_name', filter: true }] : []),
        ...[{ title: 'Active', name: 'active', sortField: 'active', filter: true }],
      ],
      allowFilters: true,
    });
  }
