
  import SubFormListFormModal from '@app/components/sub-form-list/sub-form-list-form-modal.vue';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { SubFormListFormModal } })
  export default class SubFormListNewPage extends mixins(Blocking) {
    @Prop(Number) readonly moduleTabId?: number;
    @Prop(Number) readonly selectedSubFormId?: number;

    visible = true;

    subFormList: Partial<SubFormList> | null = null;
    submit(form: Partial<SubFormList>): void {
      this.blocking(async () => {
        return this.$api
          .createSubFormList(form)
          .then(() => this.closeModal('reload'))
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      });
    }

    beforeMount(): void {
      this.subFormList = {
        sub_form_list_type: SubFormListType.normal,
        sub_form_ids: this.selectedSubFormId ? [Number(this.selectedSubFormId)] : [],
        draft: true,
        draft_after_complete: true,
        module_tab_id: Number(this.moduleTabId),
      };
    }

    closeModal(command: ModalCloseCommand): void {
      if (this.$route.params.moduleNameId) {
        this.$router.replace({
          name: 'admin-module-tabs-view-page',
          params: { moduleNameId: this.$route.params.moduleNameId, moduleTabId: `${this.moduleTabId}`, reloadTable: command },
          query: this.$route.query,
        });
      } else {
        this.$router.replace({
          name: 'admin-module-tab-redirect',
          params: { moduleTabId: `${this.moduleTabId}`, reloadTable: command },
          query: this.$route.query,
        });
      }
    }
  }
