
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { RegulatoryReport } from '../../models/regulatory-report';
  import { titleize } from '../../utils/titleize';

  @Component({
    methods: { titleize },
    components: { DsModal },
  })
  export default class RegulatoryReportErrorsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly regulatoryReport!: RegulatoryReport;
  }
