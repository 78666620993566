import type { BaseEntity } from '@app/models/base-entity';

type Id = number;
export enum Severity {
  error = 'error',
  warning = 'warning',
}

export interface SuperImportError extends BaseEntity {
  attachment_id?: Id;
  error?: string;
  info?: string;
  legacy?: boolean;
  message: string;
  row_number?: number;
  severity: Severity;
  stacktrace?: string;
  super_import_id: Id;
}
