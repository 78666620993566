
  import { Collapse } from 'uiv';
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import type { SuperImport } from '@app/models/super-import';
  import type { Severity, SuperImportError } from '@app/models/super-import-error';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerFetchDataParams, ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { AxiosPromise } from 'axios';
  import SearchInput from '@app/components/search-input.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  import { BaseTable } from '../../components/base-table';

  @Component({ components: { Collapse, SearchInput, BaseTable } })
  export default class DisplayError extends Vue {
    @Prop(Object) superImport!: SuperImport;
    @Prop() attachmentId!: number | string;
    @Prop(String) scope!: Severity;

    collapseErrors: Record<number, boolean> = {};
    manager: Nullable<ListManager<SuperImportError>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get fields(): ListManagerField<SuperImportError>[] {
      let fields = [
        { title: 'Row Number', name: 'row_number', sortField: 'id' },
        { title: 'Message', name: 'message', sortField: 'message', filter: true },
        { title: 'Info', name: 'info', sortField: 'info', filter: true },
      ];

      if (!!this.currentUserStore.data?.technical_admin) {
        fields.push({ title: 'Stacktrace', name: 'stacktrace', sortField: 'stacktrace' });
      }

      return fields;
    }

    toggleCollapse(id: number) {
      this.collapseErrors = { ...this.collapseErrors, [id]: !this.collapseErrors[id] };
    }

    getManager(): ListManager<SuperImportError> {
      return new ListManager<SuperImportError>({
        fetchDataFunction: this.fetchDataFunction,
        per_page: 50,
        fields: this.fields,
        allowFilters: true,
      });
    }

    fetchDataFunction(params: ListManagerFetchDataParams<SuperImportError, {}>): AxiosPromise<SuperImportError[]> {
      let only: string[] = ['id', 'row_number', 'message', 'severity', 'error', 'info', 'attachment_id'];
      if (this.currentUserStore.isDefaultTechAdmin) {
        only.push('stacktrace');
      }

      return this.$api.getSuperImportErrors(
        this.superImport.id,
        {
          ...params,
          filters: {
            severity: this.scope,
            attachment_id: this.attachmentId,
            ...params.filters,
          },
          only: only as OnlyOptions<SuperImportError>,
        },
        { cache: false }
      );
    }

    beforeMount() {
      this.manager = this.getManager();
    }
  }
