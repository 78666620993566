
  import { useAccountStore } from '@app/stores/account';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import SubFormListBase from '@app/components/sub-form-list-types/sub-form-list-base';
  import { entityPathPrefix } from '@app/services/helpers';
  import { Component } from 'vue-property-decorator';
  import type { Procedure } from '@app/models/procedure';
  import type { ProcedureAcknowledgement } from '@app/models/procedure-acknowledgement';
  import type { ProcedureModuleRecord } from '@app/models/procedure-module-record';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DsDropdown, BaseTable }, methods: {} })
  export default class ProceduresTab extends SubFormListBase {
    procedureModuleRecords: ProcedureModuleRecord[] = [];
    procedures: Procedure[] = [];
    userInvolvements: UserInvolvement[] = [];
    acknowledgementsLoaded = false;

    acknowledgements: ProcedureAcknowledgement[] = [];

    get accountStore() {
      return useAccountStore();
    }

    procedurePath(procedureId: number): string {
      return `/${entityPathPrefix('Procedure')}/${procedureId}`;
    }

    addProcedure(procedure: Procedure): void {
      this.$api
        .createProcedureModuleRecord({
          procedure_id: procedure.id,
          module_record_id: this.record.id,
          sub_form_list_id: this.subFormList.id,
        })
        .then(() => {
          this.$api.cache.clear();
          this.fetchData();
        });
    }

    isAcknowledged(procedure: Procedure, userId: number): boolean {
      return this.acknowledgements.some((a) => a.user_id === userId && a.procedure_id === procedure.id);
    }

    removeProcedure(procedureModuleRecord: ProcedureModuleRecord): void {
      this.$api.deleteProcedureModuleRecord(procedureModuleRecord.id).then(() => {
        this.$api.cache.clear();
        this.fetchData();
      });
    }

    fetchData(): Promise<void> {
      return this.$api
        .getProcedureModuleRecords(
          {
            filters: {
              module_record_id: this.record.id,
              sub_form_list_id: this.subFormList.id,
            },
            include: ['procedure'],
            per_page: -1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.procedureModuleRecords = data;
        })
        .then(() => {
          this.$api
            .getProcedures(
              {
                filters: {
                  '!id': this.procedureModuleRecords.map((pmr) => pmr.procedure_id).join(','),
                },
                only: ['id', 'name'],
                per_page: -1,
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.procedures = data;
            });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    fetchAcknowledgements(): void {
      this.subFormList.involvement_id &&
        this.procedureModuleRecords.length &&
        this.$api
          .getUserInvolvements(
            {
              filters: {
                record_id: this.record.id,
                record_type: 'ModuleRecord',
                involvement_id: this.subFormList.involvement_id,
              },
              only: ['id', 'user_id', { user: ['id', 'full_name'] }],
              per_page: -1,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.userInvolvements = data;
            if (this.userInvolvements.length) {
              return this.$api
                .getProcedureAcknowledgements({
                  filters: {
                    user_id: this.userInvolvements.map((ui) => ui.user_id),
                    procedure_id: this.procedureModuleRecords.map((pmr) => pmr.procedure_id),
                  },
                  per_page: -1,
                })
                .then(({ data }) => {
                  this.acknowledgements = data;
                });
            }
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          })
          .finally(() => {
            this.acknowledgementsLoaded = true;
          });
    }

    beforeMount(): void {
      this.fetchData().then(() => this.fetchAcknowledgements());
    }
  }
