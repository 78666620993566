
  import ActivitiesList from '@app/components/activities/activities-list.vue';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import { Component, Ref } from 'vue-property-decorator';
  import type { ActionWidgetOptions } from '@app/models/widget';
  import { ActionSortOptions } from '@app/models/widget';
  import type { Activity } from '@app/models/activity';
  import { SpecialPermissionName } from '@app/models/extended-permission';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { buildLink } from '@app/utils/build-link';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';

  import BaseWidget from './base-widget';

  @Component({ components: { ActivitiesList } })
  export default class ActionsWidget extends BaseWidget<ActionWidgetOptions> {
    @Ref() readonly activitiesList?: ActivitiesList;
    loading = false;
    reverse = true;

    get sort(): ActionSortOptions {
      if (!this.widget.options.sort) return ActionSortOptions.date;
      const sort = this.widget.options.sort; // something weird might be stored here
      const availableSortOptions = Object.values(ActionSortOptions);
      return availableSortOptions.includes(sort) ? sort : ActionSortOptions.date;
    }

    get canCreate(): boolean {
      return (
        this.currentUserStore.canCreateActivity &&
        this.currentUserStore.hasExtendedPermission(SpecialPermissionName.standalone_activity_creation)
      );
    }

    get actionsFilters(): DonesafeFilterOptions<Activity, DonesafeActivitiesApiExtraOptions> {
      return convertInFormFilters(this.widget.options.filters, { user: this.currentUserStore.data });
    }

    get listSorting(): string {
      const reversePrefix = this.reverse ? '-' : '';
      return `${reversePrefix}${this.sort}`;
    }

    get href(): string {
      return buildLink('/actions', { filters: this.actionsFilters, sort: this.listSorting });
    }

    showAddNewModal(): void {
      this.activitiesList?.addNewActivity();
    }

    beforeMount(): void {
      this.reverse = (this.widget.options.reverse || 'true') === 'true';
    }
  }
