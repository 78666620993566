
  import { Component } from 'vue-property-decorator';
  import type { Notification } from '@app/models/notification';
  import { toaster } from '@app/utils/toaster';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  @Component({})
  export default class UserNotificationsTab extends mixins(WithUser) {
    notifications: Notification[] = [];
    notificationIds: number[] = [];

    beforeMount(): void {
      this.$api.getNotifications({ only: ['id', 'name', 'description'], sort: 'id' }, { cache: true }).then(({ data }) => {
        this.notifications = data;
      });
      this.$api.getTenantUser(this.userId, { include: ['notification_ids'] }, { cache: true }).then(({ data }) => {
        this.notificationIds = data.notification_ids || [];
      });
    }

    submitForm(): void {
      this.$api
        .updateTenantUser(this.userId, { notification_ids: this.notificationIds }, { include: ['notification_ids'] })
        .then(() => {
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_notifications.success'),
            position: 'top-right',
          });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
