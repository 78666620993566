
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { SinglePersonSelectorQuestionOptions } from '@app/models/question-options/single-person-selector-question-options';

  @Component({ components: {} })
  export default class SinglePersonSelectorShowOptions extends BaseShowOptions<SinglePersonSelectorQuestionOptions> {
    get types() {
      return (
        this.config.filters
          ?.filter((f) => f.key === 'type')
          .map((f) => f.value)
          .flat()
          .join(', ') || this.$t('app.labels.na')
      );
    }
  }
