
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import { chunk, intersection, toPairs } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import qs from 'qs';
  import { Component, PropSync } from 'vue-property-decorator';
  import type { DomoChartFeature, DomoWidgetOptions } from '@app/models/widget';
  import { DOMO_CHART_FEATURES, DomoFilterEmptyBehaviourOption, DomoFilterOperator } from '@app/models/widget';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({
    components: { AdvancedAutosuggest, ValidationObserver, ValidationProvider },
    methods: { chunk },
  })
  export default class DomoWidgetSettings extends BaseWidgetSettings<DomoWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<DomoWidgetOptions>;

    injectableOptions: PlaceholderItem[] = [];

    get operandOptions(): string[] {
      return this.widgetOptions.embed_style === 'private'
        ? Object.keys(DomoFilterOperator).filter((key) => key != 'BETWEEN')
        : Object.keys(DomoFilterOperator);
    }

    get legacyWidget(): boolean {
      return this.widgetOptions.embed_style === 'legacy';
    }

    get selectedFeatures(): DomoChartFeature[] {
      return this.widgetOptions.features || [];
    }

    get chartFeatures(): string[][] {
      return toPairs(DOMO_CHART_FEATURES);
    }

    get emptyBehaviourOptions(): string[] {
      return Object.keys(DomoFilterEmptyBehaviourOption);
    }

    toggleFeature(feature: DomoChartFeature, value: boolean): void {
      const oldFeatures = this.selectedFeatures;
      const features = value ? oldFeatures.concat([feature]) : oldFeatures.filter((f: string) => f !== feature);
      this.widgetOptions.features = features;
      this.$emit('update:options', this.widgetOptions);
    }

    featureSelected(feature: DomoChartFeature): boolean {
      return this.selectedFeatures.some((f) => f === feature);
    }

    onUrlChange(str: string): void {
      const path = str.split('?')[0];
      const params = str.split('?')[1];
      const query = qs.parse(params);
      const tempFeatures = Array.isArray(query.enable)
        ? [...(query.enable as string[])]
        : ((query?.enable as string) || '').split(',') || [];
      delete query.enable;

      if (tempFeatures.length) {
        this.updateFeatures(intersection(Object.keys(DOMO_CHART_FEATURES), tempFeatures) as DomoChartFeature[]);
      }

      const url = Object.keys(query).length ? `${path}?${qs.stringify(query)}` : path;
      this.widgetOptions.url = url;
      this.$emit('update:options', this.widgetOptions);
    }

    updateFeatures(features: DomoChartFeature[]): void {
      this.widgetOptions.features = features;
      this.$emit('update:options', this.widgetOptions);
    }

    removeFilter(index: number): void {
      const newFilters = this.widgetOptions.filters?.slice(0, index).concat(this.widgetOptions.filters.slice(index + 1)) || [];
      this.widgetOptions.filters = newFilters;
      this.$emit('update:options', this.widgetOptions);
    }

    removeValue(filterIndex: number, valueIndex: number): void {
      const newFilters =
        this.widgetOptions.filters?.map((filter, index) => {
          if (index === filterIndex) {
            const values = filter.values.slice(0, valueIndex).concat(filter.values.slice(valueIndex + 1)) || [];
            return { ...filter, values };
          }
          return filter;
        }) || [];
      this.widgetOptions.filters = newFilters;
      this.$emit('update:options', this.widgetOptions);
    }

    addNewFilter(): void {
      const newFilter = {
        column: '',
        operand: DomoFilterOperator.EQUALS,
        values: [''],
        empty_behaviour: DomoFilterEmptyBehaviourOption.skip,
      };
      this.widgetOptions.filters = [...(this.widgetOptions.filters || []), newFilter];
      this.$emit('update:options', this.widgetOptions);
    }

    beforeMount(): void {
      this.widgetOptions = {
        filters: [],
        embed_style: !this.widget.id || !this.widgetOptions?.embed_id ? 'private' : 'legacy',
        embed_type: 'card',
        ...this.widgetOptions,
      };

      this.$api
        .getInjectableOptions({
          concept_name: 'CurrentContext',
          injectable_arguments: { include_current_user: true },
        })
        .then(({ data }) => {
          this.injectableOptions = data;
        });
    }
  }
