
  import { OPERATORS_BY_TYPE } from '@app/components/ds-query-builder/defaults';
  import { toPairs, map, fromPairs } from 'lodash';
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import Select2 from '@app/components/select2.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import CollapsedText from './collapsed-text.vue';
  import { extend, ValidationProvider } from 'vee-validate';
  import existsInOptionsOrDbRule from '@app/validators/exists-in-options-or-db-rule';

  @Component({ components: { Select2, LocationSelector, OrganizationSelector, DsIcon, CollapsedText, ValidationProvider } })
  export default class RuleValueSelect extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() name!: string;
    @Prop() rule!: DsRuleNode;

    readonlyValue: string | string[] | number | number[] | null = null;

    get allowMultiple(): boolean {
      return !!(this.rule.operator && OPERATORS_BY_TYPE[this.rule.operator]?.multiple);
    }

    get dynamicSelectorLabelKey() {
      switch (this.filter?.lookup_type) {
        case 'location':
        case 'organization':
          return 'name';
      }
    }

    get select2Options() {
      if (this.filter?.plugin !== 'select2') {
        if (Array.isArray(this.values)) {
          return map(this.values, (option) => ({
            text: option,
            id: option,
            ...(this.dynamicSelectorLabelKey ? { [this.dynamicSelectorLabelKey]: option } : {}),
          }));
        }
      }

      return map(toPairs(this.values), ([id, text]) => ({
        text: text,
        id: id,
        ...(this.dynamicSelectorLabelKey ? { [this.dynamicSelectorLabelKey]: text } : {}),
      }));
    }

    get filter() {
      return this.rule.filter;
    }

    get values() {
      if (!this.filter || !this.filter.values) return {};
      if (Array.isArray(this.filter.values)) return fromPairs(this.filter.values.map((value) => [value, value]));

      return this.filter.values || {};
    }

    get dynamicSelector() {
      if (!this.filter?.lookup_type) return;
      return `${this.filter.lookup_type}-selector`;
    }

    get rules() {
      return {
        required: true,
        'exists-in-options-or-db': {
          type: this.filter?.lookup_type,
          options: this.validNonLookupIds,
        },
      };
    }

    get validNonLookupIds() {
      return this.select2Options.map((option) => option.id);
    }

    async fetchLocationName(id: string | number) {
      return this.$api
        .getLocation(Number(id), {}, { cache: true, join: true })
        .then(({ data: location }) => {
          return location?.name || id;
        })
        .catch(() => {
          return this.$t('app.labels.not_accessible');
        });
    }

    async fetchOrganizationName(id: string | number) {
      return this.$api
        .getOrganization(Number(id), {}, { cache: true, join: true })
        .then(({ data: organization }) => {
          return organization?.name || id;
        })
        .catch(() => {
          return this.$t('app.labels.not_accessible');
        });
    }

    async lookupValue(value: string | number) {
      if (!this.filter?.lookup_type) return value;

      switch (this.filter.lookup_type) {
        case 'location':
          return this.fetchLocationName(value);
        case 'organization':
          return this.fetchOrganizationName(value);
        default:
          return value;
      }
    }

    async getValue(value: string | number) {
      return this.values[value] || (await this.lookupValue(value));
    }

    async getReadonlyValue() {
      const { value } = this.rule;

      if (this.qb.editable || !value) return null;

      if (Array.isArray(value)) {
        const values = await Promise.all(value.map((v) => this.getValue(v)));
        return values.join(', ');
      }

      return typeof value === 'string' || typeof value === 'number' ? await this.getValue(value) : value;
    }

    updateValue(value: string | string[]) {
      this.rule.updateValue(value);
    }

    beforeMount() {
      if (!this.qb.editable) {
        this.getReadonlyValue().then((readonlyValue) => {
          this.readonlyValue = readonlyValue;
        });
      } else {
        extend('exists-in-options-or-db', existsInOptionsOrDbRule);
      }
    }
  }
