
  import CommentFormModal from '@app/components/comment/comment-form-modal.vue';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import DsButton from '../ds-button.vue';
  import BaseActivity from './base-activity';
  import type { ActivityTableActivityOnly } from './utils';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { Activity } from '@app/models/activity';
  import { ActivityState } from '@app/models/activity';

  @Component({ components: { CommentFormModal, DsButton, DsIconText } })
  export default class ActivityActionButton extends BaseActivity<Pick<Activity, ActivityTableActivityOnly>> {
    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Boolean) readonly slim?: boolean;

    @Watch('activityButtonState')
    onActivityButtonStateChanged(val: string, oldVal: string) {
      this.setAnimationClasses(val, oldVal);
    }

    additionalClasses = {};

    get activityButtonState() {
      if (this.activity.state === ActivityState.Open) {
        return 'open';
      } else if (this.requiresApproval) {
        return 'pending';
      } else if (this.activity.state === ActivityState.Closed) {
        return 'closed';
      }
    }

    get defaultButtonClasses() {
      return { 'btn-sm': true, 'btn-rounded-xs': true };
    }

    get showButton() {
      return this.canMarkAsComplete || this.showApprovalButton || this.showCompletedChip;
    }

    get showCompletedChip() {
      if (this.canMarkAsComplete || this.showApprovalButton) return false;
      return this.activity.state === ActivityState.Closed;
    }

    get btnTextClasses() {
      return { 'activity-btn-text': this.slim };
    }

    setAnimationClasses(newState: string, oldState: string) {
      this.additionalClasses = {
        ...this.additionalClasses,
        [oldState]: { 'puff-out-hor': true },
        [newState]: { 'puff-in-hor': true },
      };

      setTimeout(this.resetAnimationClasses, 500);
    }

    resetAnimationClasses() {
      this.additionalClasses = {
        ...this.additionalClasses,
        open: {},
        pending: {},
        closed: {},
      };
    }
  }
