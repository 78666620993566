
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import Select2 from '../select2.vue';

  interface TypeOption {
    code: string;
    model_name: string;
    label: string;
    hide_instructions: boolean;
  }

  @Component({ components: { Select2 } })
  export default class ImportInstructions extends Vue {
    @Prop(Array) readonly importOptions!: TypeOption[];
    @Prop(String) readonly path!: string;

    get visibleImportOptions(): TypeOption[] {
      return this.importOptions.filter((option) => !option.hide_instructions);
    }

    downloadImportTemplate(type: string): string {
      return `${this.path}/download_csv_template?model_name=${type}`;
    }

    templateManual(type: string): string {
      return `${this.path}/template_manual?model_name=${type}`;
    }
  }
