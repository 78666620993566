
  import { Component, Model, Vue } from 'vue-property-decorator';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  @Component({ components: { FormField } })
  export default class DaysAfterDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & { default: { days_from_now: number } }>;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['days_form_now'].forEach((key) => this.$delete(this.value.config.default, key));
    }
  }
