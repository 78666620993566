
  import WorkplaceIndustryFormModal from '@app/components/admin/user-settings/tabs/workplace-industry-form-modal.vue';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { Component, Ref } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import { BaseTable } from '../../../base-table';
  import type { WorkplaceIndustry } from '@app/models/workplace-industry';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Tab, Tabs, BaseTable, WorkplaceIndustryFormModal } })
  export default class WorkplaceIndustryTab extends mixins(Blocking) {
    @Ref() readonly table?: BaseTable<WorkplaceIndustry>;
    @Ref() readonly modal?: WorkplaceIndustryFormModal;

    manager: Nullable<ListManager<WorkplaceIndustry>> = null;
    modalVisible = false;

    getManager(): ListManager<WorkplaceIndustry> {
      return new ListManager<WorkplaceIndustry>({
        fetchDataFunction: (params) => {
          return this.$api.getWorkplaceIndustries(
            {
              ...params,
              only: ['active', 'code', 'id', 'name'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'desc', field: 'code', sortField: 'code' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.code'),
            name: 'code',
            sortField: 'code',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.name'),
            name: 'name',
            sortField: 'name',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.active'),
            name: 'active',
            sortField: 'active',
          },
        ],
      });
    }

    createOrUpdateWorkplaceIndustry(form: Partial<WorkplaceIndustry>): void {
      const promise = form.id ? this.$api.updateWorkplaceIndustry(form.id as number, form) : this.$api.createWorkplaceIndustry(form);
      const message = form.id
        ? this.$t('tenant.admin.settings.menus.user_settings.updated.workplace_industry')
        : this.$t('tenant.admin.settings.menus.user_settings.created.workplace_industry');
      this.blocking(async () => {
        await promise;
        toaster(message);
        this.toggleModal(false);
        this.$api.cache.clear();
        this.table?.reload();
      });
    }

    toggleModal(value: boolean, workplaceIndustry?: Partial<WorkplaceIndustry>): void {
      value && this.modal?.reset(workplaceIndustry);
      this.modalVisible = value;
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }
  }
