import Blocking from '@app/mixins/blocking';
import type { AsfSfsgOnly, AsfSfsOnly, SubFormDndList, DraggableChangeEvent } from './utils';
import { intermediateIndex } from './utils';
import type { AxiosPromise } from 'axios';
import { Component } from 'vue-property-decorator';
import { difference, isEqual, omit, sortBy } from 'lodash';
import type { SubFormSection } from '@app/models/sub-form-section';
import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';
import type { UpdateIndexParams } from '@app/services/donesafe-api-utils';
import { toaster } from '@app/utils/toaster';

type IndexParams = UpdateIndexParams['data'];

@Component
export default class DraggableSectionGroupHelper extends Blocking {
  dragging = false;
  sectionsById: Record<string, Pick<SubFormSection, AsfSfsOnly>> = {};
  sectionGroupsById: Record<string, Pick<SubFormSectionGroup, AsfSfsgOnly>> = {};
  dndList: SubFormDndList = [];

  get subFormSections(): Pick<SubFormSection, AsfSfsOnly>[] {
    return Object.values(this.sectionsById);
  }

  get subFormSectionGroups(): Pick<SubFormSectionGroup, AsfSfsgOnly>[] {
    return Object.values(this.sectionGroupsById);
  }

  getOrphanedSections(): Pick<SubFormSection, AsfSfsOnly>[] {
    return this.subFormSections.filter((section) => !section.sub_form_section_group_id);
  }

  onDragStart(): void {
    this.dragging = true;
  }

  onDragEnd(): void {
    this.dragging = false;
  }

  // triggered only for sections or groups that are in the root of the dndList
  isDragAllowed(event: {
    draggedContext?: {
      element: Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>;
    };
    to?: HTMLElement;
  }): boolean {
    const target = event.to;
    const element = event.draggedContext?.element;

    if (target == null) return true;
    // Use this data on the DOM and to denote that it's not a droppable target.
    // This is the recommended way according to
    // https://github.com/SortableJS/Vue.Draggable/issues/897
    // disallow dragging of group items inside another group
    if (target.getAttribute('data-disallow-sfsg-drop') === 'true' && !!element && 'children' in element) return false;

    return true;
  }

  // triggered on group level change only
  // adds 'sub_form_section_group_id' if section was moved to group
  // updates indexes for all sections inside a group
  // syncs local data after server requests are done
  onGroupDragChange(event: DraggableChangeEvent<Pick<SubFormSection, AsfSfsOnly>>, sectionGroup: Pick<SubFormSectionGroup, AsfSfsgOnly>) {
    const { moved, added } = event;

    if (!moved && !added) return;
    let promises: (AxiosPromise<SubFormSection> | Promise<void>)[] = [];

    if (!!added?.element.id) {
      promises = [...promises, this.$api.updateSubFormSection(added.element.id, { sub_form_section_group_id: sectionGroup.id })];
    }

    // get the current indexes of all sections inside a group
    const sectionIndexesInsideGroup = this.getGroupIndexesFromDndList(sectionGroup);
    promises = [...promises, this.saveIndexes(sectionIndexesInsideGroup)];

    Promise.all(promises)
      .then(() => {
        this.syncLocalAfterGroupUpdate(sectionIndexesInsideGroup, sectionGroup, added);
        toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
      })
      .catch(({ data }) => {
        toaster({ text: data.error, position: 'top-right', icon: 'error' });
      });
  }

  // triggered on root level change only
  // clears 'sub_form_section_group_id' if section was moved to root
  // updates indexes for all sections and groups on root level
  // syncs local data after server requests are done
  onRootDragChange(event: DraggableChangeEvent<Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>>) {
    const { added, removed } = event || {};
    let promises: (AxiosPromise<SubFormSection> | Promise<void>)[] = [];

    if (added && added.element && !('children' in added.element)) {
      promises = [...promises, this.$api.updateSubFormSection(added.element.id, { sub_form_section_group_id: null })];
    }

    const [sectionIndexes, groupIndexes] = this.getCurrentRootIndexes();
    promises.push(this.saveIndexes(sectionIndexes, groupIndexes));

    Promise.all(promises)
      .then(() => {
        this.syncLocalAfterRootUpdate(sectionIndexes, groupIndexes, added);
        // avoid showing the toaster twice if a section was added to a group
        !removed && toaster({ text: this.$t('app.labels.order_saved'), position: 'top-right' });
      })
      .catch(({ data }) => {
        toaster({ text: data.error, position: 'top-right', icon: 'error' });
      });
  }

  // event listener for modal related changes ('create', 'delete', 'update') for both sections and groups
  // syncs local data after response received from the modal
  // create a 'new' dndList based on the updated data
  // updates indexes for all sections and groups inside this subform
  onSectionOrGroupUpdated(params: {
    action: 'create' | 'delete' | 'update';
    group?: Pick<SubFormSectionGroup, AsfSfsgOnly>;
    section?: Pick<SubFormSection, AsfSfsOnly>;
    triggerFromIndex?: number;
  }): void {
    this.blocking(async () => {
      const { group, section, triggerFromIndex, action } = params;

      // sync local data and insert new sectionOrGroup into the correct position with intermediateIndex
      if (action === 'create' && typeof triggerFromIndex === 'number') {
        if (!!group) {
          this.sectionGroupsById = { ...this.sectionGroupsById, [group.id]: { ...group, index: intermediateIndex(triggerFromIndex) } };
          group.sub_form_section_ids?.forEach((id) => this.modifySection(id, { sub_form_section_group_id: group.id }));
          this.syncSectionsOrderInsideGroup(group);
        } else if (!!section) {
          // if the section is a new section, insert it with the correct intermediate position
          this.sectionsById = { ...this.sectionsById, [section.id]: { ...section, index: intermediateIndex(triggerFromIndex) } };
          // if the section is not an orpaned section, update the section group's sub_form_sections
          // find the section group that the section belongs to and insert the section into the correct position in the list
          if (!!section.sub_form_section_group_id) {
            this.modifySectionGroup(section.sub_form_section_group_id, {
              sub_form_section_ids: [...(this.sectionGroupsById[section.sub_form_section_group_id].sub_form_section_ids || []), section.id],
            });
          }
        }
      }

      // remove deleted sectionOrGroup from the local data
      if (action === 'delete') {
        if (!!group) {
          // reattach all sections inside the group to the root level before deleting the group
          group.sub_form_section_ids?.forEach((sectionId) => {
            this.attachSectionToGroupOrRoot(sectionId);
          });
          this.sectionGroupsById = omit(this.sectionGroupsById, group.id);
        } else if (!!section) {
          this.sectionsById = omit(this.sectionsById, section.id);
          this.clearSectionFromGroup(section);
        }
      }

      // update the sectionOrGroup in the list
      if (action === 'update') {
        if (!!group) {
          // compare with local sub form section group section_ids
          const localGroup = this.sectionGroupsById[group.id];
          if (localGroup) {
            const localSectionIds = localGroup.sub_form_section_ids || [];
            const remoteSectionIds = group.sub_form_section_ids || [];
            const [addIds, removeIds] = [difference(remoteSectionIds, localSectionIds), difference(localSectionIds, remoteSectionIds)];

            // sync title
            if (!isEqual(localGroup.title, group.title)) this.modifySectionGroup(localGroup.id, { title: group.title });

            addIds.forEach((id) => this.attachSectionToGroupOrRoot(id, { toSectionGroupId: group.id }));
            removeIds.forEach((id) => this.attachSectionToGroupOrRoot(id));

            this.syncSectionsOrderInsideGroup(group);
          }
        } else if (!!section) {
          const localSection = this.sectionsById[section.id];
          if (localSection) {
            // sync title and description
            if (!isEqual(localSection.title, section.title) || !isEqual(localSection.description, section.description)) {
              this.modifySection(section.id, { title: section.title, description: section.description });
            }
          }
        }
      }

      // create new dnd list from the updated sub form sections and sub form section groups
      this.dndList = this.initializeDndList();
      await this.saveAllSectionAndGroupIndexes();
    });
  }

  clearSectionFromGroup({ sub_form_section_group_id, id }: Pick<SubFormSection, 'sub_form_section_group_id' | 'id'>): void {
    if (!!sub_form_section_group_id) {
      const newSubFormSectionIds = this.sectionGroupsById[sub_form_section_group_id].sub_form_section_ids?.filter((sId) => sId !== id);
      this.modifySectionGroup(sub_form_section_group_id, { sub_form_section_ids: newSubFormSectionIds });
    }
  }

  modifySection(sectionId: number, params: Partial<Pick<SubFormSection, AsfSfsOnly>>): void {
    this.sectionsById = {
      ...this.sectionsById,
      [sectionId]: { ...this.sectionsById[sectionId], ...params },
    };
  }

  modifySectionGroup(sectionGroupId: number, params: Partial<Pick<SubFormSectionGroup, AsfSfsgOnly>>): void {
    this.sectionGroupsById = {
      ...this.sectionGroupsById,
      [sectionGroupId]: { ...this.sectionGroupsById[sectionGroupId], ...params },
    };
  }

  // if order in group has changed
  // update the local section indexes to match the remote
  syncSectionsOrderInsideGroup(group: Pick<SubFormSectionGroup, AsfSfsgOnly>): void {
    const remoteSectionIds = group.sub_form_section_ids || [];
    const orderedLocalSectionIds = this.subFormSections
      .filter((s) => s.sub_form_section_group_id === group.id)
      .sort((a, b) => a.index - b.index)
      .map((s) => s.id);

    if (!!remoteSectionIds.length && !isEqual(orderedLocalSectionIds, remoteSectionIds)) {
      remoteSectionIds.forEach((id, index) => this.modifySection(id, { index: index + 1 }));
    }
  }

  // iterate though dndList and construct indexes for specific group
  getGroupIndexesFromDndList(sectionGroup: Pick<SubFormSectionGroup, AsfSfsgOnly>): IndexParams {
    const group = this.dndList.find((el) => 'children' in el && el.id === sectionGroup.id);
    if (!group) return [];
    return group.children?.map(({ id }, index) => ({ id, index: index + 1 })) || [];
  }

  // iterate though dndList and construct indexes for orphaned sections and groups
  getCurrentRootIndexes(): [IndexParams, IndexParams] {
    return this.dndList.reduce(
      (acc, el, index) => {
        let [sectionIndexes, groupIndexes] = acc;
        if ('children' in el) groupIndexes = [...groupIndexes, { id: el.id, index: index + 1 }];
        else sectionIndexes = [...sectionIndexes, { id: el.id, index: index + 1 }];
        return [sectionIndexes, groupIndexes];
      },
      [[], []] as [IndexParams, IndexParams]
    );
  }

  syncLocalAfterGroupUpdate(
    sectionIndexes: IndexParams,
    sectionGroup: Pick<SubFormSectionGroup, AsfSfsgOnly>,
    added?: DraggableChangeEvent<Pick<SubFormSection, AsfSfsOnly>>['added']
  ) {
    this.syncLocalIndexes(sectionIndexes);
    // if a section was added to a group, specify its sub_form_section_group_id and add it to sub_form_section_ids of the group
    !!added?.element.id && this.attachSectionToGroupOrRoot(added.element.id, { toSectionGroupId: sectionGroup.id, noIndexChange: true });
  }

  syncLocalAfterRootUpdate(
    sectionIndexes: IndexParams,
    groupIndexes: IndexParams,
    added?: DraggableChangeEvent<Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>>['added']
  ) {
    this.syncLocalIndexes(sectionIndexes, groupIndexes);
    // if added need to remove sub_form_section_group_id from section and sub_form_section_ids from the group
    !!added && this.attachSectionToGroupOrRoot(added.element.id, { noIndexChange: true });
  }

  // sync the indexes with the local data manually
  syncLocalIndexes(sectionIndexes: IndexParams, groupIndexes?: IndexParams) {
    sectionIndexes.forEach(({ id, index }) => this.modifySection(id, { index }));
    groupIndexes?.forEach(({ id, index }) => this.modifySectionGroup(id, { index }));
  }

  attachSectionToGroupOrRoot(sectionId: number, opts: { noIndexChange?: boolean; toSectionGroupId?: number } = {}) {
    const { toSectionGroupId, noIndexChange } = opts;
    const currentSectionGroupId = this.sectionsById[sectionId].sub_form_section_group_id;

    // attach to root as an orphan section and sync local data
    if (!toSectionGroupId) {
      const groupIndex = currentSectionGroupId ? this.sectionGroupsById[currentSectionGroupId]?.index : 1;
      const newIndex = !noIndexChange ? { index: intermediateIndex(groupIndex + this.sectionsById[sectionId].index / 100) } : {};

      this.modifySection(sectionId, { sub_form_section_group_id: undefined, ...newIndex });
      this.clearSectionFromGroup({ sub_form_section_group_id: currentSectionGroupId, id: sectionId });
    }

    // attach to a different section group and sync local data
    if (!!toSectionGroupId) {
      this.modifySection(sectionId, { sub_form_section_group_id: toSectionGroupId });
      this.modifySectionGroup(toSectionGroupId, {
        sub_form_section_ids: [...(this.sectionGroupsById[toSectionGroupId].sub_form_section_ids || []), sectionId],
      });
      this.clearSectionFromGroup({ sub_form_section_group_id: currentSectionGroupId, id: sectionId });
    }
  }

  getOrphanedSectionsAndAllGroups(): (Pick<SubFormSection, AsfSfsOnly> | Pick<SubFormSectionGroup, AsfSfsgOnly>)[] {
    return [...this.getOrphanedSections(), ...this.subFormSectionGroups];
  }

  // initialize dnd list from the 'orphanedSections' and 'subFormSectionGroups'
  initializeDndList(): SubFormDndList {
    return sortBy(this.getOrphanedSectionsAndAllGroups(), 'index').reduce((acc, sectionOrGroup) => {
      if ('sub_form_section_ids' in sectionOrGroup) {
        const dndGroup = {
          id: sectionOrGroup.id,
          children: sortBy(
            sectionOrGroup.sub_form_section_ids?.reduce((innerAcc, id) => {
              const section = this.sectionsById[id];
              if (section) innerAcc.push({ id: section.id, index: section.index });
              return innerAcc;
            }, [] as { id: number; index: number }[]),
            'index'
          ).map(({ id }) => ({ id })),
        };
        acc = [...acc, dndGroup];
      } else {
        acc = [...acc, { id: sectionOrGroup.id }];
      }
      return acc;
    }, [] as SubFormDndList);
  }

  // updates provided indexes on the server
  async saveIndexes(sectionIndexes: IndexParams, groupIndexes?: IndexParams): Promise<void> {
    let promises: AxiosPromise<void>[] = [];

    if (!!sectionIndexes.length) promises = [...promises, this.$api.updateSubFormSectionIndexes({ data: sectionIndexes })];
    if (!!groupIndexes?.length) promises = [...promises, this.$api.updateSubFormSectionGroupIndexes({ data: groupIndexes })];

    await Promise.all(promises);
  }

  // updates all the indexes based on the position stored in the dnd list
  async saveAllSectionAndGroupIndexes(): Promise<void> {
    const { sectionIndexes, groupIndexes } = this.dndList.reduce(
      (acc, el, index) => {
        if ('children' in el) {
          acc.groupIndexes = [...acc.groupIndexes, { id: el.id, index: index + 1 }];
          el.children?.forEach((child, childIndex) => {
            acc.sectionIndexes = [...acc.sectionIndexes, { id: child.id, index: childIndex + 1 }];
          });
        } else {
          acc.sectionIndexes = [...acc.sectionIndexes, { id: el.id, index: index + 1 }];
        }
        return acc;
      },
      { sectionIndexes: [] as IndexParams, groupIndexes: [] as IndexParams }
    );

    await this.saveIndexes(sectionIndexes, groupIndexes);
    this.syncLocalIndexes(sectionIndexes, groupIndexes);
  }
}
