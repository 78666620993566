import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';

export const activeRecordBudgetBehaviours = (recordBudget?: ExpensableRecordBudget | null) => {
  return (
    recordBudget?.record_budget_behaviours?.filter(
      (b) =>
        b.active &&
        b.behaviour &&
        recordBudget?.expensing_table?.expensing_table_behaviours?.some(
          (etb) => etb.active && etb.expensable_behaviour_id === b.expensable_behaviour_id
        )
    ) || []
  );
};
