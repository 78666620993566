export const types = {
  string: 'string',
  integer: 'number',
  double: 'number',
  boolean: 'boolean',
};

const PASS_COLOR = 'rgb(41,175,84)';
const FAIL_COLOR = 'rgb(185,79,72)';
export const OPERATORS_BY_TYPE = {
  equal: {
    apply_to: ['string', 'number', 'datetime', 'boolean'],
    label: 'Equal To',
    multiple: false,
    icons: ['mdi:equal'],
    index: 1,
  },
  not_equal: {
    multiple: false,
    apply_to: ['string', 'number', 'datetime', 'boolean'],
    label: 'Not Equal To',
    icons: ['mdi:not-equal-variant'],
    index: 2,
  },
  less: {
    multiple: false,
    apply_to: ['number', 'datetime'],
    label: 'Less than',
    icons: ['mdi:less-than'],
    index: 3,
  },
  less_or_equal: {
    multiple: false,
    apply_to: ['number', 'datetime'],
    label: 'Less than or equal to',
    icons: ['mdi:less-than-or-equal'],
    index: 4,
  },
  greater: {
    multiple: false,
    apply_to: ['number', 'datetime'],
    label: 'Greater than',
    icons: ['mdi:greater-than'],
    index: 5,
  },
  greater_or_equal: {
    multiple: false,
    apply_to: ['number', 'datetime'],
    label: 'Greater than or equal to',
    icons: ['mdi:greater-than-or-equal'],
    index: 6,
  },
  contains: {
    multiple: false,
    apply_to: ['string'],
    label: 'Contains',
    icons: ['mdi:contain'],
    index: 7,
  },
  not_contains: {
    multiple: false,
    apply_to: ['string'],
    label: 'Does not contain',
    icons: ['mdi:contain-end', 'mdi:contain-start'],
    index: 8,
  },
  contained_in_or_equal: {
    multiple: true,
    apply_to: ['string', 'integer', 'number'],
    label: 'Contained in or equal to',
    icons: ['mdi:contain'],
    index: 9,
  },
  not_contained_in_or_not_equal: {
    multiple: true,
    apply_to: ['string', 'integer', 'number'],
    label: 'Not contained in or not equal to',
    icons: ['mdi:contain-end', 'mdi:contain-start'],
    index: 10,
  },
  included_in: {
    multiple: true,
    apply_to: ['string', 'integer', 'number'],
    label: 'Included in',
    icons: ['mdi:contain'],
    index: 11,
  },
  not_included_in: {
    multiple: true,
    apply_to: ['string', 'integer', 'number'],
    label: 'Not included in',
    icons: ['mdi:contain-end', 'mdi:contain-start'],
    index: 12,
  },
  intersects: {
    multiple: true,
    apply_to: ['string'],
    label: 'Intersects',
    icons: ['mdi:set-center'],
    index: 13,
  },
  not_intersects: {
    multiple: true,
    apply_to: ['string'],
    label: 'Does not Intersect',
    icons: ['mdi:not-equal-variant', 'mdi:set-center'],
    index: 14,
  },
};

export const DEFAULTS = {
  sort_filters: false,
  display_errors: true,
  allow_groups: -1,
  allow_empty: false,
  default_condition: 'AND',
  inputs_separator: ' , ',
  select_placeholder: '------',
  display_empty_filter: true,
  default_filter: null,
  optgroups: {},
  default_rule_flags: {
    filter_readonly: false,
    operator_readonly: false,
    value_readonly: false,
    no_delete: false,
  },
  default_group_flags: {
    condition_readonly: false,
    no_add_rule: false,
    no_add_group: false,
    no_delete: false,
  },
  lang_code: 'en',
  lang: {},
  conditions: ['AND', 'OR'],
  icons: {
    add_group: 'add',
    add_rule: 'add',
    drag: 'drag_indicator',
    validation_error: 'warning',
    remove_rule: 'delete',
    remove_group: 'delete',
    debug_pass: 'check_circle_outline',
    debug_fail: 'highlight_off',
    edit_group_label: 'edit',
    expand_text: 'expand_more',
    collapse_text: 'expand_less',
    more_options: 'more_vert',
    expand_all: 'expand_more',
    collapse_all: 'expand_less',
  },
  styles: {
    group: {
      body: { line_color: { and: '#C7D5E9', any: '#FFBD12' } },
      container: {
        bg_color: { even: 'rgb(240, 240, 240)', odd: 'rgb(255, 255, 255)' },
        border_color: { pass: PASS_COLOR, fail: FAIL_COLOR },
        box_shadow: { first: 'none', other: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' },
      },
      rule_divider_color: { even: 'rgba(0, 0, 0, 0.1)', odd: 'rgb(240, 240, 240)' },
    },
    rule: { validation_error_color: FAIL_COLOR, debug: { pass: PASS_COLOR, fail: FAIL_COLOR }, operator_icon_size: '18px' },
  },
};
