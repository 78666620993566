
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import SetupConfidentialAccessModal from './setup-confidential-access-modal.vue';
  import type { ModuleName } from '@app/models/module-name';

  @Component({ components: { SetupConfidentialAccessModal } })
  export default class SetupConfidentialAccessModalButton extends Vue {
    @Prop(Number) readonly recordId!: number;
    @Prop(String) readonly recordType!: string;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id'>;
    modalVisible = false;
  }
