import { Component, Prop, Vue } from 'vue-property-decorator';
import type { BaseRecord } from '@app/models/module-record';
import type { SubFormList } from '@app/models/sub-form-list';

@Component({})
export default class SubFormListBase extends Vue {
  // TODO: still needs to be connected
  @Prop(Object) readonly subFormList!: Pick<
    SubFormList,
    | 'id'
    | 'index'
    | 'sub_form_ids'
    | 'sub_form_list_type'
    | 'order_options'
    | 'title'
    | 'response_counter_options'
    | 'relationship_options'
    | 'default_template_id'
    | 'add_background_color'
    | 'options'
    | 'record_relation_question_id'
    | 'record_relations_filters'
    | 'involvement_id'
    | 'expensable_expensing_table_id'
    | 'module_name'
  >;
  @Prop(Object) readonly record!: Pick<BaseRecord, 'id'>;
  @Prop(Boolean) readonly editable?: boolean;
  @Prop(Boolean) readonly printing?: boolean;
  @Prop(Boolean) readonly archivable?: boolean;
}
