
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { ModuleName } from '@app/models/module-name';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { Role } from '@app/models/role';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { LocationTag } from '@app/models/location-tag';
  import type { RoleProfile } from '@app/models/role-profile';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  import type { CustomEntityFilterOption, FilterOption, StaticFilterOption } from './model';
  import FilterConfigurator from './filter-configurator.vue';

  @Component({ components: { FilterConfigurator } })
  export default class UserFilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(Object) readonly currentQuestion?: SubFormQuestion;
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(Boolean) readonly hasStickyFilters?: boolean;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noCurrentUser?: boolean;
    @Prop(Boolean) readonly isMobileApp?: boolean;

    userRelatedModuleNames: Pick<ModuleName, 'id' | 'display'>[] = [];
    locationTags: Pick<LocationTag, 'id' | 'name'>[] = [];
    roleProfileOptions: StaticFilterOption['options'] = [];

    get personTypes(): StaticFilterOption['options'] {
      return [
        ['TenantUser', 'Tenant'],
        ['ContactUser', 'Contact'],
        ['VisitorUser', 'Visitor'],
        ['ContractorUser', 'Contractor'],
        ['MedicalUser', 'Medical User'],
      ].map(([key, value]) => ({ key, value }));
    }

    get configOptions(): (FilterOption | FilterOption<Role> | FilterOption<RoleProfile> | FilterOption<LocationTag>)[] {
      return [
        {
          type: 'select',
          label: 'Type',
          multiple: true,
          options: this.personTypes,
          key: 'type',
          sticky: true,
        },
        {
          type: 'location',
          label: 'Home Location',
          multiple: true,
          key: 'home_location_id',
        },
        {
          type: 'location',
          label: 'Location Ceiling',
          multiple: true,
          key: 'location_ceiling_id',
        },
        {
          type: 'location',
          label: 'Location Ceiling Ancestor (including self)',
          multiple: true,
          key: 'location_ceiling__ancestor_ids',
        },
        {
          type: 'location',
          label: 'Home Location Ancestor (including self)',
          multiple: true,
          key: 'home_location__ancestor_ids',
        },
        {
          type: 'organization',
          label: 'Home Organization',
          multiple: true,
          key: 'home_organization_id',
        },
        {
          type: 'organization',
          label: 'Home Organization Ancestor (including self)',
          multiple: true,
          key: 'home_organization__ancestor_ids',
        },
        {
          type: 'organization',
          label: 'Organization Ceiling',
          multiple: true,
          key: 'organization_ceiling',
        },
        {
          type: 'organization',
          label: 'Organization Ceiling Ancestor (including self)',
          multiple: true,
          key: 'organization_ceiling__ancestor_ids',
        },
        {
          type: 'user',
          label: "Users who's home location is accessible to user...",
          multiple: true,
          key: 'home_location_accessible_by_user_id',
        },
        {
          type: 'user',
          label: "Users who's home organization is accessible to user...",
          multiple: true,
          key: 'home_organization_accessible_by_user_id',
        },
        {
          type: 'location',
          label: "Users' accessible locations include...",
          multiple: true,
          key: 'accessible_location_id',
        },
        {
          type: 'organization',
          label: "Users' accessible organizations include...",
          multiple: true,
          key: 'accessible_organization_id',
        },
        {
          type: 'location',
          label: "Users' Active Locations Include...",
          multiple: true,
          key: 'user_locations__location_id',
        },
        {
          type: 'organization',
          label: "Users' Active Organizations Include...",
          multiple: true,
          key: 'user_organizations__organization_id',
        },
        {
          label: 'User',
          type: 'user',
          filters: { active: true },
          multiple: true,
          key: 'id',
        },
        {
          type: 'user',
          filters: { active: true },
          label: 'Manager',
          allowNull: true,
          multiple: true,
          key: 'manager_id',
        },
        {
          type: 'user',
          label: this.$t('tenant.filter_configurator.ancestors_with_self'),
          filters: { active: true },
          multiple: true,
          key: 'ancestor_id',
        },
        {
          type: 'user',
          label: this.$t('tenant.filter_configurator.descendants_with_self'),
          filters: { active: true },
          multiple: true,
          key: 'descendant_id',
        },
        {
          type: 'entity',
          labelKey: 'name',
          valueKey: 'id',
          only: ['id', 'name'],
          method: this.$api.getRoles,
          filters: { active: true },
          label: this.$t('app.labels.role'),
          multiple: true,
          key: 'role_id',
        },
        {
          type: 'entity',
          label: this.$t('app.labels.role_profile'),
          templateResult: this.roleProfileTemplate,
          templateSelection: this.roleProfileTemplate,
          labelKey: 'name',
          valueKey: 'id',
          only: ['id', 'name', 'secondary_information'],
          multiple: true,
          key: 'role_profile_ids',
          method: this.$api.getRoleProfiles,
          filters: { active: true, module_name: { active: true } },
        },
        {
          type: 'text',
          label: 'Position',
          key: 'position',
        },
        {
          type: 'text',
          label: 'Mobile',
          key: 'mobile',
        },
        {
          type: 'entity',
          labelKey: 'name',
          valueKey: 'id',
          only: ['id', 'name'],
          method: this.$api.getLocationTags,
          label: 'Location Tags',
          multiple: true,
          key: 'location_tag_ids',
        },
        ...this.userModuleRecordOptions,
        ...this.locationTagOptions,
        ...this.locationTagHierarchyOptions,
      ];
    }

    get locationTagOptions(): CustomEntityFilterOption<LocationTag>[] {
      return this.locationTags.map(({ id, name }) => {
        return {
          type: 'location',
          label: `Has ${name} Tag in Location...`,
          multiple: true,
          key: `location_tag_${id}`,
        };
      });
    }

    get locationTagHierarchyOptions(): CustomEntityFilterOption<LocationTag>[] {
      return this.locationTags.reduce<CustomEntityFilterOption<LocationTag>[]>((accumulator, { id, name }) => {
        const aboveTag: CustomEntityFilterOption<LocationTag> = {
          type: 'location',
          label: `Has ${name} Tag in Location hierarchically ABOVE/INCLUDING...`,
          multiple: true,
          key: `location_tag_hierarchy_above_${id}`,
        };

        const belowTag: CustomEntityFilterOption<LocationTag> = {
          type: 'location',
          label: `Has ${name} Tag in Location hierarchically BELOW/INCLUDING...`,
          multiple: true,
          key: `location_tag_hierarchy_below_${id}`,
        };

        return accumulator.concat([aboveTag, belowTag]);
      }, []);
    }

    get userModuleRecordOptions(): CustomEntityFilterOption<ModuleRecord>[] {
      return this.userRelatedModuleNames.map(({ id, display }) => {
        return {
          type: 'record',
          label: display,
          multiple: true,
          allowNull: true,
          key: `user_module_records_${id}`,
          filters: {
            module_name_id: id,
          },
        };
      });
    }

    getLocationTags(): void {
      this.$api
        .getLocationTags({
          filters: {
            active: true,
          },
          per_page: -1,
          only: ['id', 'name'],
        })
        .then(({ data }) => {
          this.locationTags = data;
        });
    }

    getModuleNames(): void {
      this.$api
        .getModuleNames({
          filters: {
            active: true,
            feature_set: {
              user_related: true,
            },
          },
          per_page: -1,
          only: ['id', 'display'],
        })
        .then(({ data }) => {
          this.userRelatedModuleNames = data;
        });
    }

    beforeMount(): void {
      this.getModuleNames();
      this.getLocationTags();
      this.getRoleProfiles();
    }

    getRoleProfiles(): void {
      this.$api
        .getRoleProfiles(
          {
            filters: { active: true, module_name: { active: true } },
            only: ['id', 'name', 'secondary_information'],
            sort: 'module_name.plural_display,name',
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.roleProfileOptions = data.map(({ id, name, module_name }) => ({
            key: `${id}`,
            value: `${module_name?.plural_display}: ${name}`,
          }));
        });
    }

    roleProfileTemplate(option: Pick<RoleProfile, 'id' | 'name' | 'secondary_information'>): JQuery {
      return select2ResponseTemplate(option, {
        primaryAttribute: 'name',
        secondaryAttribute: 'secondary_information',
      });
    }
  }
