
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Video } from '@app/models/video';

  @Component({ components: { DsModal } })
  export default class VideoShowModal extends Vue {
    @Prop(Object) readonly video!: Video;
    @Model('change', { type: Boolean }) readonly visible!: boolean;
  }
