
  import { Btn } from 'uiv';
  import { Component, Vue, Prop, Model, Emit } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';

  @Component({ components: { DsDropdown, Btn } })
  export default class DsCollapseLink extends Vue {
    @Model('input') readonly value?: boolean;
    @Prop(String) readonly text?: string;

    @Emit('input')
    toggle(): boolean {
      return !this.value;
    }
  }
