
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ApiRequestOption } from '../../../../models/question-options/shared';
  import Select2 from '../../../select2.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

  @Component({ components: { AdvancedAutosuggest, Select2 } })
  export default class ApiLookupOptionItem extends Vue {
    @Model('input') readonly value!: ApiRequestOption;
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly injectableOptions!: PlaceholderItem[];
  }
