
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';

  import DsRuleItem from '../rule/ds-rule-item.vue';
  import type { DsGroupNode } from '../models/ds-group-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import DsIconText from '../../ds-icon-text.vue';

  import GroupHeader from './group-header.vue';
  import GroupConditions from './group-conditions.vue';

  @Component({
    name: 'DsGroupItem',
    components: {
      DsRuleItem,
      Draggable,
      DsIconText,
      GroupHeader,
      GroupConditions,
    },
  })
  export default class DsGroupItem extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() readonly group!: DsGroupNode;
    @Prop(Boolean) readonly hideLabel?: boolean;

    get ruleGroupBodyStyle() {
      return {
        '--group-body-line-color':
          this.group.condition === 'AND'
            ? this.qb.config.styles.group.body.line_color.and
            : this.qb.config.styles.group.body.line_color.any,
      };
    }

    get ruleGroupContainerStyle() {
      const groupEven = this.group.level % 2 === 0;

      return {
        ...{
          '--group-container-bg-color': groupEven
            ? this.qb.config.styles.group.container.bg_color.even
            : this.qb.config.styles.group.container.bg_color.odd,
          '--rule-divider-color': groupEven
            ? this.qb.config.styles.group.rule_divider_color.even
            : this.qb.config.styles.group.rule_divider_color.odd,
        },
        ...(this.qb.debug
          ? {
              '--group-container-border-color': this.group.pass
                ? this.qb.config.styles.group.container.border_color.pass
                : this.qb.config.styles.group.container.border_color.fail,
            }
          : {}),
        ...{
          '--group-container-box-shadow':
            this.group.level === 1
              ? this.qb.config.styles.group.container.box_shadow.first
              : this.qb.config.styles.group.container.box_shadow.other,
        },
      };
    }
  }
