
  import { Component, Model, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { select2IconResponseTemplate } from '@app/utils/select2-icon-response-template';

  @Component({ components: { Select2, FormField, EntitySelector } })
  export default class CanAddSelector extends Vue {
    @Model('input') value!: SubFormQuestion;

    canAddOptions: [string, string][] = [];

    HARDCODED_MODULE_CODES = HARDCODED_MODULE_CODES;

    select2IconResponseTemplate = select2IconResponseTemplate;

    onDefaultTemplateChange(moduleNameId: number, defaultTemplateId: number) {
      const moduleRecordsDefault = this.value.config.add?.module_records_default;

      if (!moduleRecordsDefault) {
        return;
      }

      this.$set(moduleRecordsDefault, moduleNameId, defaultTemplateId);
    }

    moduleName(moduleNameId: number) {
      return (this.canAddOptions.find(([id]) => '' + id === '' + moduleNameId) || [])[1];
    }

    onCanAddOptions(options: ModuleName[]) {
      this.canAddOptions = options.map(({ id, display }) => ['' + id, display]);
    }

    get moduleFilters() {
      // inlining this in a template will cause infinite update loop
      return { active: true, '!name': HARDCODED_MODULE_CODES };
    }
  }
