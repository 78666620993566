import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { detailedDiff } from 'deep-object-diff';
import { isEmpty, omit, sortBy } from 'lodash';
import type { RoleTabDetailsFormType, RoleTabDetailsRoleOnly } from './utils';
import type { Role } from '@app/models/role';

@Component
export default class WithDetectRoleChanges extends Vue {
  @Prop(Object) readonly role!: Nullable<Pick<Role, RoleTabDetailsRoleOnly>>;

  form: RoleTabDetailsFormType = {};
  defaultNewForm: RoleTabDetailsFormType = {};

  @Watch('formAndRoleEqual', { immediate: true })
  onFormAndRoleEqualChange(formAndRoleEqual: boolean) {
    this.$emit('form-has-changes', !formAndRoleEqual);
  }

  get roleToCompare() {
    return this.role?.id ? this.role : this.defaultNewForm;
  }

  get permissionsAndEpDiff() {
    const rolePermissionsAndEp = {
      permissions: this.roleToCompare?.permissions || [],
      extended_permissions: (this.roleToCompare?.extended_permissions || []).map(({ name, concept_name }) => ({ name, concept_name })),
    };
    const formPermissionsAndEp = { permissions: this.form?.permissions || [], extended_permissions: this.form.extended_permissions || [] };

    return detailedDiff(rolePermissionsAndEp, formPermissionsAndEp);
  }

  get roleModuleNamesDiff() {
    return detailedDiff(
      sortBy(this.roleToCompare?.role_module_names, 'module_name_id'),
      sortBy(this.form.role_module_names, 'module_name_id')
    );
  }

  get otherPropsDiff() {
    const keysToOmit = ['permissions', 'extended_permissions', 'role_profiles', 'role_module_names'];
    return detailedDiff(omit(this.roleToCompare, keysToOmit), omit(this.form, keysToOmit));
  }

  get permissionsAndEpEqual() {
    return isEmpty(this.permissionsAndEpDiff.added) && isEmpty(this.permissionsAndEpDiff.deleted);
  }

  get roleModuleNamesEqual() {
    return (
      isEmpty(this.roleModuleNamesDiff.added) && isEmpty(this.roleModuleNamesDiff.deleted) && isEmpty(this.roleModuleNamesDiff.updated)
    );
  }

  get otherPropsEqual() {
    return isEmpty(this.otherPropsDiff.added) && isEmpty(this.otherPropsDiff.deleted) && isEmpty(this.otherPropsDiff.updated);
  }

  get formAndRoleEqual() {
    return this.permissionsAndEpEqual && this.roleModuleNamesEqual && this.otherPropsEqual;
  }
}
