
  import { Component } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import RoleActions from '@app/components/admin/roles/role-actions.vue';
  import RoleTabDetails from '@app/components/admin/roles/role-tab-details/role-tab-details.vue';
  import BaseAdminRolesPage from './base-admin-roles-page';
  import type { NavigationGuardNext, Route } from 'vue-router';

  Component.registerHooks(['beforeRouteLeave']);

  @Component({
    components: { Tabs, Tab, RoleActions, RoleTabDetails },
  })
  export default class AdminRolesNew extends BaseAdminRolesPage {
    tabIndex: number | null = 0;

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
      this.beforeRouteLeaveHelper(to, from, next);
    }
  }
