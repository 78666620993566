
  import { flatten } from 'lodash';
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import type { SelectOption } from '@app/models/question-options/shared';
  import EntitySelector from '../../entity-selector.vue';
  import LocationSelector from '../../location/location-selector.vue';
  import OrganizationSelector from '../../organization/organization-selector.vue';
  import RecordSelector from '../../record-selector.vue';
  import Select2 from '../../select2.vue';
  import UserSelector from '../../user/user-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { MobileAppConfig } from '@app/models/mobile-app/config';
  import type { MobileAppRegistration, VisitResponseValueItem } from '@app/models/mobile-app/registration';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { FieldType } from '@app/models/sub-form-question';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';

  @Component({
    components: {
      LocationSelector,
      OrganizationSelector,
      RecordSelector,
      Select2,
      UserSelector,
      EntitySelector,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class MobileAppRegistrationForm extends Vue {
    @Prop(Object) readonly registration?: Partial<MobileAppRegistration>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    mainFormQuestions: SubFormQuestion[] = [];
    form: Partial<MobileAppRegistration> = {};
    mobileAppConfig: Nullable<MobileAppConfig> = null;
    courseModule: Nullable<ModuleName> = null;

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    mappingTypes = {
      sign_in: 'Sign In Default Value mapping',
      sign_out: 'Sign Out Default Value mapping',
    };

    selectOptions(question: SubFormQuestion): SelectOption[] {
      return Object.values(question.options.values || {});
    }

    removeQuestion(type: keyof MobileAppRegistration['config']['response_mapping'], questionId: number): void {
      this.form.config = {
        ...this.form.config,
        response_mapping: {
          ...this.form.config?.response_mapping,
          [type]: this.questionMapping(type).filter((q) => q.id !== questionId),
        },
      };
    }

    questionTitle(questionId: number): string | undefined {
      return this.findQuestion(questionId)?.title;
    }

    questionType(questionId: number): FieldType | undefined {
      return this.findQuestion(questionId)?.field_type;
    }

    findQuestion(questionId: number): SubFormQuestion | undefined {
      return this.mainFormQuestions.find((q) => `${q.id}` === `${questionId}`);
    }

    onQuestionSelect(type: keyof MobileAppRegistration['config']['response_mapping'], questionId: number): void {
      this.form.config = {
        ...this.form.config,
        response_mapping: {
          ...this.form.config?.response_mapping,
          [type]: [...this.questionMapping(type), { id: questionId, value: '' }],
        },
      };
    }

    questionMapping(type: keyof MobileAppRegistration['config']['response_mapping']): VisitResponseValueItem[] {
      return this.form.config?.response_mapping?.[type] || [];
    }

    mappableQuestions(type: keyof MobileAppRegistration['config']['response_mapping']): SubFormQuestion[] {
      const selectedQuestions = this.questionMapping(type).map((m) => m.id);
      return this.mainFormQuestions
        .filter((q) => selectedQuestions.indexOf(q.id) < 0)
        .filter(
          (q) => `${q.id}` !== this.mobileAppConfig?.config.mapping?.visitee && `${q.id}` !== this.mobileAppConfig?.config.mapping?.visitor
        )
        .filter((q) => {
          return (
            [
              FieldType.main_form_relation,
              FieldType.single_person_selector,
              FieldType.location,
              FieldType.organization,
              FieldType.text,
              FieldType.textarea,
              FieldType.single_select,
              FieldType.button_select,
              FieldType.workflow_state,
              FieldType.radio,
            ].indexOf(q.field_type) > -1
          );
        });
    }

    get courseSubFormFilter(): DonesafeFilterOptions<SubForm> | undefined {
      if (this.courseModule) {
        const ids = this.courseModule.sub_form_lists
          ?.filter((sfl) => sfl.sub_form_list_type === SubFormListType.normal && this.isHybridSubFormList(sfl))
          .map((sfl) => sfl.sub_form_ids)
          .flat();
        return {
          id: ids,
          module_name: this.courseModule.name,
          // active: true, allow selection of inactive courses
        };
      }
    }

    isHybridSubFormList(subFormList: SubFormList): boolean {
      if (this.courseModule) {
        return !!this.courseModule.module_tabs?.some((t) => t.id === subFormList.module_tab_id && HYBRID_MODULE_TAB_CODE === t.code);
      }
      return false;
    }

    loadCourseModule(moduleNameId?: number | string): void {
      moduleNameId &&
        this.$api
          .getModuleName(Number(moduleNameId), { only: ['id', 'name', 'sub_form_lists', 'module_tabs'] }, { cache: true })
          .then(({ data }) => {
            this.courseModule = data;
          });
    }

    get showCourseSelector(): boolean {
      return !!this.mobileAppConfig?.config.courses?.restrict_to_specific_kiosks && !!this.courseModule;
    }

    beforeMount(): void {
      this.form = { ...this.registration, config: { ...this.registration?.config } };
      if (this.registration?.mobile_app_config_id) {
        const only: OnlyOptions<MobileAppConfig> = [
          'id',
          'config',
          'module_name_id',
          { module_name: [{ main_form: [{ sub_form_sections: ['sub_form_questions'] }] }] },
        ];
        this.$api.getMobileAppConfig(this.registration.mobile_app_config_id, { only }, { cache: true }).then(({ data }) => {
          this.mobileAppConfig = data;
          this.mainFormQuestions = flatten(data.module_name?.main_form?.sub_form_sections?.map((s) => s.sub_form_questions || []) || []);

          this.loadCourseModule(this.mobileAppConfig.config.courses?.course_module_name_id);
        });
      }
    }
  }
