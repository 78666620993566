
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { startCase, uniq } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { MobileAppConfig } from '@app/models/mobile-app/config';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { FieldType } from '@app/models/sub-form-question';
  import { HARDCODED_MODULE_CODES, ModuleType } from '@app/models/module-name';
  import { MobileAppConfigProfile } from '@app/models/mobile-app/config';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import UserFilterConfigurator from '../questions/filter-configurator/user-filter-configurator.vue';
  import Select2 from '../../select2.vue';
  import EntitySelector from '../../entity-selector.vue';

  @Component({
    components: { Select2, UserFilterConfigurator, EntitySelector, ValidationObserver, ValidationProvider },
  })
  export default class MobileAppConfigForm extends Vue {
    @Prop(Object) readonly config?: Partial<MobileAppConfig>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<MobileAppConfig> = {};

    extraQuestionId = null;

    questionsRelatedToVisitorModule: Pick<SubFormQuestion, 'id' | 'sub_form_section'>[] = [];

    selectedSubForm: Nullable<SubForm> = null;

    userTypes = [
      ['TenantUser', 'Tenant'],
      ['ContactUser', 'Contact'],
      ['VisitorUser', 'Visitor'],
      ['ContractorUser', 'Contractor'],
      ['MedicalUser', 'Medical'],
    ];

    selectedModule: Nullable<ModuleName> = null;
    courseModule: Nullable<ModuleName> = null;

    get extraQuestionsFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return {
        field_type: 'text',
        active: true,
        sub_form_section: {
          sub_form_id: this.selectedModule?.sub_form_id,
        },
      };
    }

    get moduleNameFilters(): DonesafeFilterOptions<ModuleName> {
      return { active: true, '!name': HARDCODED_MODULE_CODES };
    }

    get courseModuleNameFilters(): DonesafeFilterOptions<ModuleName> {
      return {
        ...this.moduleNameFilters,
        module_type: ModuleType.form_managed,
        sub_form_id: uniq(this.questionsRelatedToVisitorModule.map((q) => q.sub_form_section?.sub_form_id).filter(Boolean)),
      };
    }

    get profileOptions(): [string, string][] {
      return Object.values(MobileAppConfigProfile).map((key) => [key, startCase(key)]);
    }

    get notFoundEntryMessage(): string {
      return (
        this.config?.config?.not_found_message ||
        'Not Found: Sorry, the details above are not currently registered for this sign in attempt. Please try searching again if this is incorrect or contact administration.'
      );
    }

    addNewExtraField(): void {
      this.form = {
        ...this.form,
        config: {
          ...this.form.config,
          extra_fields: [...(this.form.config?.extra_fields || []), { question_id: '' as unknown as number, label: '' }],
        },
      };
    }

    removeExtraField(index: number): void {
      this.form = {
        ...this.form,
        config: {
          ...this.form.config,
          extra_fields: [...(this.form.config?.extra_fields || [])].filter((_, idx) => idx !== index),
        },
      };
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    loadCourseModule(moduleNameId?: number | string): void {
      `${moduleNameId}` !== this.form.config?.courses?.course_module_name_id && this.resetCourseConfig();
      if (moduleNameId) {
        this.$api.getModuleName(Number(moduleNameId), { only: ['id', 'sub_form_id'] }, { cache: true }).then(({ data }) => {
          this.courseModule = data;
        });
      } else {
        this.courseModule = null;
      }
    }

    loadModuleName(moduleNameId?: number, reset = false): void {
      reset && this.resetModuleConfig();
      this.selectedModule = null;
      this.questionsRelatedToVisitorModule = [];
      moduleNameId &&
        this.$api
          .getModuleName(moduleNameId, { include: ['main_form', 'sub_form_sections', 'sub_form_questions'] }, { cache: true })
          .then(({ data }) => {
            this.selectedModule = data;
            this.$api
              .getSubFormQuestions(
                {
                  filters: {
                    active: true,
                    field_type: FieldType.main_form_relation,
                    config: { main_form_id: data.id },
                  },
                  per_page: -1,
                  only: ['id', { sub_form_section: ['sub_form_id'] }],
                  include: ['sub_form_section'],
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.questionsRelatedToVisitorModule = data;
              });
          });
    }

    resetCourseConfig(): void {
      this.form = {
        ...this.form,
        config: { ...this.form.config, courses: {} },
      };
    }

    resetModuleConfig(): void {
      this.form = {
        ...this.form,
        config: {
          ...this.form.config,
          extra_fields: [],
          mapping: {},
        },
      };
      this.loadCourseModule();
    }

    beforeMount(): void {
      this.form = {
        ...this.config,
        config: {
          ...this.config?.config,
          filters: { ...this.config?.config?.filters },
          mapping: { ...this.config?.config?.mapping },
          extra_fields: this.config?.config?.extra_fields || [],
          courses: { ...this.config?.config?.courses },
          not_found_message: this.notFoundEntryMessage,
        },
      };

      this.loadModuleName(this.form.module_name_id);
      this.loadCourseModule(this.form.config?.courses?.course_module_name_id);
    }
  }
