
  import WorkflowLinkFormModal from '@app/components/admin/workflow-links/workflow-link-form-modal.vue';
  import WorkflowFormModal from '@app/components/admin/workflows/workflow-form-modal.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import I18n from '@app/i18n';
  import { normalizedWorkflowOperators } from '@app/legacy/workflow';
  import type { OperatorBlobType, WithWorkflows } from '@app/models/mixins';
  import type { Workflow } from '@app/models/workflow';
  import type { WorkflowLink } from '@app/models/workflow-link';
  import Panzoom from '@app/services/panzoom/panzoom';
  import bootbox from 'bootbox';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

  const DEFAULT_LINK_COLOR = '#005F7F';
  const REJECTION_LINK_COLOR = '#ff5f5f';

  const buildLinks = (workflows: Workflow[]) => {
    return workflows.reduce((memo, workflow) => {
      return workflow.next_links.reduce((m, link) => {
        if (!workflows.some((w) => w.id === link.to_id)) {
          return m;
        }
        const color = link.rejection ? REJECTION_LINK_COLOR : DEFAULT_LINK_COLOR;
        const linkData = {
          fromOperator: `operator_${link.from_id}`,
          fromConnector: `link_${link.id}`,
          toOperator: `operator_${link.to_id}`,
          toConnector: `link_${link.id}`,
          color: color,
        };
        return { ...m, [`link_${link.id}`]: linkData };
      }, memo);
    }, {});
  };

  const buildWorkflowBlob = (entity: WithWorkflows) => {
    const workflows = entity.workflows?.filter((w) => w.active) || [];
    const links = buildLinks(workflows);
    const operators = workflows.reduce<
      OperatorBlobType<{
        class?: string;
        inputs?: Record<string, { label: string }>;
        outputs?: Record<string, { label: string }>;
        title?: string;
      }>
    >((memo, workflow) => {
      return {
        ...memo,
        [`operator_${workflow.id}`]: {
          left: 0,
          top: 0,
          ...entity.operator_blob?.[`operator_${workflow.id}`],
          properties: {
            title: workflow?.name || `${I18n.t('app.labels.na')} [${workflow.id}]`,
            class: entity.start_workflow_id == workflow.id ? 'workflow--starting' : '',
            inputs: { new_input: { label: '' } },
            outputs: { new_output: { label: '' } },
          },
        },
      };
    }, {});

    workflows.forEach((workflow) => {
      workflow.next_links?.forEach((link) => {
        const linkData = { [`link_${link.id}`]: { label: '' } };

        const fromKey = `operator_${link.from_id}`;
        const toKey = `operator_${link.to_id}`;
        if (operators[fromKey] && operators[toKey]) {
          operators[fromKey].properties = {
            ...operators[fromKey].properties,
            outputs: { ...operators[fromKey].properties?.outputs, ...linkData },
          };
          operators[toKey].properties = {
            ...operators[toKey].properties,
            inputs: { ...operators[toKey].properties?.inputs, ...linkData },
          };
        }
      });
    });

    return { links, operators: normalizedWorkflowOperators(operators) };
  };

  @Component({ components: { WorkflowLinkFormModal, WorkflowFormModal, DsModal } })
  export default class WorkflowConfigurator extends Vue {
    @Prop({ required: true, type: Object }) entity!: WithWorkflows;
    @Prop({ required: true, type: String }) entityType!: 'ModuleName' | 'SubFormList';
    @Prop(String) readonly conceptName?: string;

    @Ref() readonly container!: HTMLElement;
    @Ref() readonly flowChart!: HTMLElement;

    selectedWorkflowLink: Nullable<Partial<WorkflowLink>> = null;
    selectedWorkflow: Nullable<Partial<Workflow>> = null;

    get inactiveWorkflows(): Workflow[] {
      return this.entity.workflows?.filter((w) => !w.active) || [];
    }

    get showWorkflowFormModal(): boolean {
      return !!this.selectedWorkflow;
    }

    get showWorkflowLinkFormModal(): boolean {
      return !!this.selectedWorkflowLink;
    }

    set showWorkflowLinkFormModal(value: boolean) {
      if (!value) {
        this.selectedWorkflowLink = null;
      }
    }

    set showWorkflowFormModal(value: boolean) {
      if (!value) {
        this.selectedWorkflow = null;
      }
    }

    beforeDestroy() {
      $(this.container).off('mousewheel.focal');
    }

    addNewWorkflow() {
      const relationData = this.entityType === 'ModuleName' ? { module_name_id: this.entity.id } : { sub_form_list_id: this.entity.id };
      this.selectedWorkflow = { name: '', code: '', active: true, ...relationData };
    }

    initFlowChart() {
      const $container = $(this.container);
      const $flowchart = $(this.flowChart);

      const fW = $flowchart.width() || 0;
      const cW = $container.width() || 0;

      const fH = $flowchart.height() || 0;
      const cH = $container.height() || 0;

      const startX = (-fW + cW) / 2;
      const startY = (-fH + cH) / 2;

      const panzoom = Panzoom($flowchart[0], {
        minScale: 0.5,
        maxScale: 3,
        excludeClass: ['ui-draggable', 'ui-draggable-handle'],
        startX: startX,
        startY: startY,
      });

      $container.on('mousewheel.focal', (e) => {
        const state = panzoom.zoomWithWheel((e as unknown as { originalEvent: WheelEvent }).originalEvent);
        state && $flowchart.flowchart('setPositionRatio', state.scale);
      });

      const { links, operators } = buildWorkflowBlob(this.entity);
      // Apply the plugin on a standard, empty div...
      $flowchart.flowchart({
        data: { operators, links },
        defaultLinkColor: DEFAULT_LINK_COLOR,
        onLinkSelect: (linkId: string) => {
          if (linkId) {
            this.selectedWorkflowLink =
              this.entity.workflows
                ?.map((w) => w.next_links)
                .flat()
                .find((l) => `link_${l.id}` === linkId) || null;
          } else {
            bootbox.alert({ message: 'Save Workflow To Edit New Path', backdrop: true });
          }
        },
        onLinkCreate: (linkId: string | 0, linkData: { fromOperator: string; toOperator: string }) => {
          if (linkId) {
            return true;
          } else {
            this.selectedWorkflowLink = {
              from_id: Number(linkData.fromOperator.replace('operator_', '')),
              to_id: Number(linkData.toOperator.replace('operator_', '')),
              rejection: false,
            };

            return false; // do not create link yet.
          }
        },
        onOperatorMoved: () => {
          this.$emit('blob-change', $flowchart.flowchart('getData'));
        },
        onOperatorSelect: (operatorId: string) => {
          this.selectedWorkflow = this.entity.workflows?.find((w) => `operator_${w.id}` === operatorId) || null;
        },
      });
    }

    mounted() {
      $(this.initFlowChart);
    }
  }
