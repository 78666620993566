
  import WorkflowConfigurator from '@app/components/admin/workflows/workflow-configurator.vue';
  import type { OperatorBlobType } from '@app/models/mixins';
  import type { ModuleName } from '@app/models/module-name';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: { WorkflowConfigurator } })
  export default class AdminModuleNameWorkflowPage extends Vue {
    @Prop({ required: true, type: [String, Number] }) readonly moduleNameId!: string | number;

    moduleName: Nullable<ModuleName> = null;
    blobChange(blob: { operators: OperatorBlobType }) {
      this.moduleName &&
        this.$api.updateWorkflowBlob({
          entity_type: 'ModuleName',
          entity_id: this.moduleName.id,
          operator_blob: blob.operators,
        });
    }

    beforeMount(): void {
      this.$api
        .getModuleName(Number(this.moduleNameId), { include: ['workflows', 'next_links', 'operator_blob', 'rule_set'] }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        });
    }
  }
