
  import { Component, Model, Vue } from 'vue-property-decorator';

  import FormField from '../questions/edit/form-field.vue';

  import type { Form } from './models';

  @Component({ components: { FormField } })
  export default class AutomationDetailsForm extends Vue {
    @Model() readonly form!: Form;
  }
