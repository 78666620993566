
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { API_NULL } from '@app/constants';
  import type { AxiosPromise } from 'axios';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import DsDropdown from '../../ds-dropdown.vue';
  import { isEqual, omit } from 'lodash';
  import type { Activity } from '@app/models/activity';
  import type { IndexFilter } from '@app/models/index-filter';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Tooltip, DsDropdown } })
  export default class ActionFilterIndexDropdown extends Vue {
    @Model('input') readonly value?: IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>;
    @Prop(String) readonly indexFilterIndex?: string;
    @Prop({ type: Boolean, default: false }) readonly global!: boolean;
    @Prop(Boolean) readonly resetOnInput?: Boolean;

    loading = false;
    indexFilter: Nullable<Partial<IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>>> = null;
    visibility = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    beforeMount(): void {
      this.fetchIndexFilter();
    }

    indexFilterOrDefault(): Promise<Pick<IndexFilter, 'filter'>> {
      this.loading = true;
      const userId = this.global ? [API_NULL] : [this.currentUserStore.data?.id, API_NULL];
      return this.$api
        .getIndexFilters({ filters: { user_id: userId, index: this.index } })
        .then(({ data }) => this.defaultIndexFilter(this.selectIndexFilter(data)))
        .finally(() => (this.loading = false));
    }

    filterSelected(key: keyof DonesafeActivitiesApiExtraOptions, value: string): boolean {
      return this.indexFilter?.filter?.[key] === value;
    }

    saveFilter(): void {
      if (!this.indexFilter) return;

      this.createOrUpdateIndexFilter(this.indexFilter)
        .then(({ data }) => {
          toaster(this.$t('tenant.index_filters.create.saved_new_filter'));
          this.updateIndexFilter(data);
          this.visibility = false;
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    selectFilter(key: keyof DonesafeActivitiesApiExtraOptions, value: string): void {
      if (!this.indexFilter?.filter) return;

      this.updateIndexFilter({
        ...this.indexFilter,
        filter: { ...this.indexFilter.filter, [key]: value },
      });
    }

    selectIndexFilter(indexFilters: IndexFilter[]): IndexFilter | undefined {
      const userFilter = indexFilters.find((indexFilter) => indexFilter.user_id === this.currentUserStore.data?.id);
      const globalFilter = indexFilters.find((indexFilter) => indexFilter.user_id == null);
      return userFilter || globalFilter;
    }

    defaultIndexFilter(
      indexFilter?: IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>
    ): Pick<IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>, 'filter'> {
      const scopeFilter = ['mine', 'all'].includes(indexFilter?.filter.scope_filter || '') ? indexFilter?.filter.scope_filter : 'mine';
      const statusFilter = ['open', 'all'].includes(indexFilter?.filter.status_filter || '') ? indexFilter?.filter.status_filter : 'open';
      return {
        ...indexFilter,
        filter: { ...indexFilter?.filter, scope_filter: scopeFilter, status_filter: statusFilter },
      };
    }

    createOrUpdateIndexFilter(indexFilter: Partial<IndexFilter>): AxiosPromise<IndexFilter> {
      if (this.global) {
        return indexFilter.id
          ? this.$api.updateIndexFilter(indexFilter.id, { ...indexFilter, user_id: undefined })
          : this.$api.createIndexFilter({ index: this.index, ...this.indexFilter, global: this.global });
      }

      return indexFilter.id && indexFilter.user_id === this.currentUserStore.data?.id
        ? this.$api.updateIndexFilter(indexFilter.id, indexFilter)
        : this.$api.createIndexFilter({
            index: this.index,
            ...omit(this.indexFilter, 'id', 'created_at', 'updated_at'),
          });
    }

    updateIndexFilter(indexFilter: Partial<IndexFilter>): void {
      if (isEqual(this.indexFilter, indexFilter)) return;

      this.indexFilter = indexFilter;
      this.$emit('input', this.indexFilter);
    }

    fetchIndexFilter(): void {
      this.indexFilterOrDefault().then((data) => this.updateIndexFilter(data));
    }

    resetIndexFilter(visibility: boolean): void {
      this.resetOnInput && this.fetchIndexFilter();
      this.visibility = visibility;
    }

    get index(): string {
      if (this.indexFilterIndex) {
        return this.indexFilterIndex;
      }
      return 'actions';
    }
  }
