
  import { useAccountStore } from '@app/stores/account';
  import { AccountService } from '@app/components/paper-trails/services';
  import { Tooltip } from 'uiv';

  import { Component, Vue } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import { debounce, map } from 'lodash';
  import DropdownSelect from '../dropdown-select.vue';
  import Select2 from '../select2.vue';
  import ActionsFilterFormModal from './actions/actions-filter-form-modal.vue';
  import AccountActions from '@app/components/admin/accounts/account-actions.vue';
  import ActionPriorities from '@app/components/admin/actions/action-priorities.vue';
  import type { ActionOptions } from '@app/models/account';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      Select2,
      Draggable,
      Tooltip,
      DropdownSelect,
      ActionsFilterFormModal,
      AccountActions,
      ActionPriorities,
    },
  })
  export default class AdminActionSettingsPage extends Vue {
    loading = false;
    submitting = false;
    filterModalVisible = false;
    form: Partial<ActionOptions> = {};
    selectedIndexOption: string | null = null;
    debounceSubmit = debounce(this.submit, 1000);

    get accountStore() {
      return useAccountStore();
    }

    get auditTrailAttributeScope(): string[] {
      return [
        ...AccountService.actionSettingsAttributes,
        'ActionPriority.active',
        'ActionPriority.code',
        'ActionPriority.color',
        'ActionPriority.index',
        'ActionPriority.is_default',
        'ActionPriority.title',
        'ActionPriority::Translation.title',
      ];
    }

    get availableIndexOptions(): [string, string][] {
      return map(this.activityIndexActions, (option, key) => [key, option] as [string, string]).filter((pair) => {
        return !this.form.index_options?.find((opt) => opt.key === pair[0]);
      });
    }

    get activityIndexActions(): Record<string, string> {
      return {
        actionable_id: this.$t('app.labels.related_id'),
        actionable_type_filter: this.$t('app.labels.actionable_type_filter'),
        approved_at: this.$t('app.labels.approved_at'),
        approved_by: this.$t('app.labels.approved_by'),
        assigned_approver: this.$t('app.labels.assigned_approver'),
        assigned_to: this.$t('app.labels.assigned_to'),
        closed_at: this.$t('app.labels.closed_at'),
        completed_by: this.$t('app.labels.completed_by'),
        created_at: this.$t('app.labels.created_at'),
        created_by: this.$t('app.labels.created_by'),
        date: this.$t('app.labels.due'),
        description: this.$t('app.labels.title'),
        id: this.$t('app.labels.ID'),
        location: this.$t('app.labels.location'),
        priority: this.$t('app.labels.priority'),
        related_to: this.$t('app.labels.related_record'),
        state: this.$t('app.labels.status'),
        sub_form_completion: this.$t('app.labels.related_form'),
      };
    }

    get showLocationRestrictionsLimit(): boolean {
      return this.accountStore.data.limit_permissions_by_location || this.accountStore.limitPermissionsByExplicitLocations;
    }

    beforeMount(): void {
      this.form = { completion_comment: { default: 'false', hide: 'false' }, ...this.accountStore.data.action_options };
    }

    submit(): void {
      this.$api
        .updateCurrentAccount({ action_options: this.form })
        .then(() => {
          toaster({ text: this.$t('app.labels.action_settings_saved') });
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    toggleFilterModal(): void {
      this.filterModalVisible = !this.filterModalVisible;
    }

    removeIndexOption(key: string): void {
      this.form = {
        ...this.form,
        index_options: this.form.index_options?.filter((option) => option.key !== key) || [],
      };
      this.debounceSubmit();
    }

    addSelectedOption(): void {
      if (!this.selectedIndexOption) return;

      this.form.index_options = [...(this.form.index_options || []), { key: this.selectedIndexOption as string }];
      this.selectedIndexOption = null;
      this.debounceSubmit();
    }
  }
