
  import type { AxiosPromise } from 'axios';

  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import UserFilterConfigurator from '../filter-configurator/user-filter-configurator.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '../edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { ModuleName } from '@app/models/module-name';
  import type { Involvement } from '@app/models/involvement';
  import { UserType } from '@app/models/tenant-user';
  import type { MultiPersonSelectorQuestionOptions } from '@app/models/question-options/multi-person-selector-question-options';
  import type { DonesafeFilterOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({
    components: { EntitySelector, Select2, UserFilterConfigurator, DsCheckbox, FormField },
  })
  export default class MultiPersonSelectorFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<MultiPersonSelectorQuestionOptions>;

    @Prop(Object) readonly subForm!: SubForm;

    moduleName: Nullable<ModuleName> = null;

    get userTypeOptions(): [string, string][] {
      return [
        ['TenantUser', 'Normal'],
        ['All', 'All'],
        ['ContactUser', 'Contact'],
        ['VisitorUser', 'Visitor'],
        ['ContractorUser', 'Contractor'],
        ['MedicalUser', 'Medical'],
      ];
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    get isPublic(): boolean {
      return !!this.moduleName?.feature_set?.is_public;
    }

    get involvementFilters(): DonesafeFilterOptions<Involvement> {
      return {
        active: true,
        module_name: this.moduleName?.name,
        involvement_type: 'standard',
      };
    }

    onUserTypeChange(type: string): void {
      if (type === 'TenantUser') {
        this.$$patch(this.value.config, { enable_add_new_user: 'false' });
      }
    }

    initForm(): void {
      this.$$patch(
        this.value.config,
        {
          required_filters: {
            active: 'true',
          },
          person_type: UserType.TenantUser,
          filters: [],
        },
        { ifEmpty: true }
      );
    }

    getInvolvements(options: DonesafeIndexApiOptions<Involvement>, config?: object): AxiosPromise<(Involvement & { label: string })[]> {
      return this.$api.getInvolvements(options, config).then((result) => {
        return {
          ...result,
          data: result.data.map((involvement) => {
            return {
              ...involvement,
              label: involvement.active ? involvement.name : this.$t('app.labels.archived_name', { name: involvement.name }),
            };
          }),
        };
      });
    }

    async beforeMount(): Promise<void> {
      this.initForm();
      await this.loadModuleName();
    }

    async loadModuleName(): Promise<ModuleName> {
      const moduleNameFilter = this.onMainForm ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({
        include: ['feature_set'],
        filters: {
          active: true,
          ...moduleNameFilter,
        },
      });

      return (this.moduleName = moduleName);
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'person_type',
        'random_selection',
        'enable_add_new_user',
        'involvement_id',
        'notifications_on',
        'filters',
        'required_filters',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
