
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SubFormCompletionActions from './sub-form-completion-actions.vue';
  import UserImageLink from '../user-image-link.vue';
  import type { NormalTabRecordOnly, NormalTabSfcOnly, NormalTabSflOnly, SubFormCompletionShowOnly } from './utils';
  import { SUB_FORM_COMPLETION_SHOW_ONLY } from './utils';
  import SubFormCompletionShow from './sub-form-completion-show.vue';
  import { Collapse, Tooltip } from 'uiv';
  import { DatetimeToString } from '@app/services/datetime_to_string';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { BaseRecord } from '@app/models/module-record';
  import { WORKFLOW_DEFAULT_BACKGROUND_COLOR } from '@app/models/workflow';
  import { ScoringViewOption } from '@app/models/sub-form';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { scoreBandProfileShouldPrintScore } from '@app/utils/score-band-profile-should-print-score';

  @Component({ components: { SubFormCompletionActions, UserImageLink, SubFormCompletionShow, Collapse, Tooltip } })
  export default class SubFormCompletionIndexItem extends Vue {
    @Prop(Object) readonly subFormList!: Pick<SubFormList, NormalTabSflOnly>;
    @Prop(Object) readonly completion!: Pick<SubFormCompletion, NormalTabSfcOnly>;
    @Prop(Object) readonly record!: Pick<BaseRecord, NormalTabRecordOnly>;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly editable?: boolean;
    @Prop(Boolean) readonly approvable?: boolean;
    @Prop(Boolean) readonly archivable?: boolean;
    @Prop(Array) readonly auditReportTemplates?: AuditReportTemplate[];

    completionShown = false;
    completionWithResponses: Nullable<Pick<SubFormCompletion, SubFormCompletionShowOnly>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get createdAt() {
      return (
        this.completion.created_at &&
        new DatetimeToString({
          currentAccount: this.accountStore.data,
          datetime: this.completion.created_at,
          timezone: this.record?.display_timezone,
          reportFormat: 'default',
          datetimeFormat: this.$t('app.labels.standard_datetime_format', {
            date: this.accountStore.data.datetimepicker_date_format,
            time: this.accountStore.data.datetimepicker_time_format,
          }),
        }).getResult()
      );
    }

    get subForm() {
      return this.subFormList.sub_forms?.find((sf) => sf.id === this.completion.sub_form_id);
    }

    get scoreBand() {
      return this.completion.score_band;
    }

    get scoreBandProfile() {
      return this.subForm?.score_band_profile;
    }

    get shouldPrintScore() {
      if (!this.subForm?.scoring) return false;
      if (this.subForm.scoring_view_option === ScoringViewOption.hide_scoring_view_option) return false;

      return this.subForm.scoring_view_option !== ScoringViewOption.score_band || scoreBandProfileShouldPrintScore(this.scoreBandProfile);
    }

    get scoringColor() {
      if (
        this.subForm?.scoring_view_option === ScoringViewOption.score_band &&
        !!this.scoreBandProfile?.options?.show_band_color &&
        !!this.scoreBand?.color
      ) {
        return this.scoreBand.color;
      }
    }

    get actionsCountClass() {
      if (this.completion.total_actions_count === 0 || this.completion.total_actions_count == this.completion.closed_actions_count) {
        return 'no-actions';
      }

      return 'pending-actions';
    }

    get showAvatar() {
      return this.subFormList.user_avatar_option !== 'none' && this.completion.display_user;
    }

    get showDocumentExportButton() {
      return !!this.auditReportTemplates?.length;
    }

    get workflowBackgroundColor() {
      return this.completion.workflow?.color || WORKFLOW_DEFAULT_BACKGROUND_COLOR;
    }

    get showClosedActions() {
      return (
        this.accountStore.data.action_options?.link_to_sub_form_completion === 'true' &&
        this.subFormList.display_actions_count &&
        this.subFormList.module_name?.show_options?.actions &&
        this.currentUserStore.canViewActivity
      );
    }

    get actionsCount() {
      return `${this.completion.closed_actions_count}/${this.completion.total_actions_count}`;
    }

    get approval(): boolean {
      return (
        this.subFormList.sub_form_list_type === SubFormListType.approval || this.subFormList.sub_form_list_type === SubFormListType.workflow
      );
    }

    get showAddApprovalButton(): boolean {
      return this.approval && !!this.approvable;
    }

    editSubFormCompletionPath(completion: SubFormCompletion) {
      return `/sub_form_completions/${completion.id}/edit`;
    }

    stateToLabelClass(state: string) {
      switch (state) {
        case 'Draft':
          return 'label-warning';
        case 'Complete':
          return 'label-success-ds';
        case 'Approved':
          return 'label-success';
        case 'Rejected':
          return 'label-danger';
        default:
          return 'label-info';
      }
    }

    updateQueryParams() {
      const isCompletionIdDifferent = this.$route.query.open_completion_id !== `${this.completion.id}`;
      const hasCompletionId = !!this.$route.query.open_completion_id;

      if ((this.completionShown && isCompletionIdDifferent) || (!this.completionShown && hasCompletionId)) {
        this.$router.push({
          query: {
            ...this.$route.query,
            open_completion_id: this.completionShown ? `${this.completion.id}` : undefined,
          },
        });
      }
    }

    toggleCompletion(completionShown: boolean) {
      // toggle completion collapse
      this.completionShown = !completionShown;

      // fetch completion with responses if not already fetched
      if (!this.completionWithResponses) this.fetchCompletionWithResponses();

      this.updateQueryParams();
    }

    fetchCompletionWithResponses() {
      this.$api.getSubFormCompletion(this.completion.id, { only: SUB_FORM_COMPLETION_SHOW_ONLY }, { cache: true }).then(({ data }) => {
        this.completionWithResponses = data;
      });
    }

    beforeMount() {
      if (this.printing || this.$route.query?.open_completion_id === `${this.completion.id}`) this.toggleCompletion(this.completionShown);
    }
  }
