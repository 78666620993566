import type { InjectableArguments, PerOptionResponse } from '@app/services/api/utils-api';
import { merge } from 'lodash';
import type { EventWithTempId } from '@app/components/admin/automation-definitions/with-automation-submit';
import type { EventSelectableOptions, UpdateTarget } from '@app/models/event';
import { Component, PropSync } from 'vue-property-decorator';
import type { ModuleRecordCollection } from '@app/models/module-record-collection';
import type { RuleSet } from '@app/models/rule-set';
import type { SubFormCompletionCollection } from '@app/models/sub-form-completion-collection';
import type { UserCollection } from '@app/models/user-collection';
import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
import type { RequiredBy } from '@app/utils/types/required-by';
import { processSelectableOptions } from '@app/utils/process-selectable-options';
import {
  RECORD_COLLECTIONS_PREFIX,
  COMPLETION_COLLECTIONS_PREFIX,
  NEW_RECORD_COLLECTIONS_PREFIX,
  NEW_COMPLETION_COLLECTIONS_PREFIX,
  NEW_USER_COLLECTION,
} from '@app/models/event';
import type { QueryBuilderBlob } from '@app/models/query-builder-blob';

import Blocking from './blocking';

@Component
export default class BaseCreateObjectEvent extends Blocking {
  @PropSync('event') syncedEvent!: EventWithTempId;

  ruleBuilderLoading = true;
  createObjectEventFormLoading = true;
  openRuleBuilderInEditMode = false;

  perOptions: [string, string][] = [];
  collection: Nullable<ModuleRecordCollection | SubFormCompletionCollection | UserCollection> = null;
  ruleSet: Nullable<RuleSet> = null;
  perFieldOptions: EventSelectableOptions = {};
  perDateOptions: [string, string][] = [];
  perTags: PlaceholderItem[] = [];
  perArguments: PerOptionResponse | null = null;
  globalInjectableOptions: PlaceholderItem[] = [];
  triggerInjectableOptions: PlaceholderItem[] = [];
  dateOptions: [string, string][] = [];
  fieldOptions = {};
  contextFieldOptions = {};

  get staticFieldOptions(): EventSelectableOptions {
    return merge({}, this.fieldOptions, this.contextFieldOptions);
  }

  get allInjectableOptions(): PlaceholderItem[] {
    return [...this.triggerInjectableOptions, ...this.globalInjectableOptions];
  }

  get per(): string {
    return this.syncedEvent.per || '';
  }

  get target(): UpdateTarget | undefined {
    return this.syncedEvent.options?.target;
  }

  get allTags(): PlaceholderItem[] {
    return [...(this.allInjectableOptions || []), ...this.perTags];
  }

  get ruleBuilderName(): string | undefined {
    if (this.per === 'record_collections' || this.per.startsWith(NEW_RECORD_COLLECTIONS_PREFIX)) {
      return 'record_collection_builder_rules';
    } else if (this.per === 'completion_collections' || this.per.startsWith(NEW_COMPLETION_COLLECTIONS_PREFIX)) {
      return 'completion_collection_builder_rules';
    } else {
      return 'user_collection_builder_rules';
    }
  }

  get mergedFieldOptions(): EventSelectableOptions {
    return merge({}, this.staticFieldOptions, this.perFieldOptions);
  }

  get showRuleBuilder(): boolean {
    if (this.blocked) return false;
    if (!this.perArguments) return false;
    if (this.globalUserCollection) return false;

    return (
      !!this.syncedEvent.collection_rules ||
      this.numericPerCollection(this.per) ||
      this.per === NEW_USER_COLLECTION ||
      this.per.startsWith(NEW_RECORD_COLLECTIONS_PREFIX) ||
      this.per.startsWith(NEW_COMPLETION_COLLECTIONS_PREFIX) ||
      this.per.startsWith(RECORD_COLLECTIONS_PREFIX) ||
      this.per.startsWith(COMPLETION_COLLECTIONS_PREFIX)
    );
  }

  /** when numeric is a user collection */
  get allDateOptions(): [string, string][] {
    return [...(this.dateOptions || []), ...this.perDateOptions];
  }

  get perOptionResponseKey(): string {
    return JSON.stringify({ ...this.perArguments, ...this.builderRuleSet });
  }

  get builderRuleSet(): Partial<RuleSet> {
    const fromEvent: Partial<RuleSet> = { query_builder_blob: this.syncedEvent.collection_rules as QueryBuilderBlob };
    return this.ruleSet || fromEvent;
  }

  get globalUserCollection(): boolean {
    return this.numericPerCollection(this.per) && !!this.collection && 'global' in this.collection && this.collection.global;
  }

  numericPerCollection(per: string): boolean {
    return /^\d+$/.test(per);
  }

  ruleBuilderReady(): void {
    this.ruleBuilderLoading = false;
    this.sendLoaded();
  }

  sendLoaded(): void {
    !(this.showRuleBuilder && this.ruleBuilderLoading) && !this.createObjectEventFormLoading && this.$emit('loaded');
  }

  translationPerPrefix(per: string): string {
    if (this.numericPerCollection(per) || per === NEW_USER_COLLECTION) {
      return 'app.labels.user_in_collection_prefixed';
    } else if (
      per === 'record_collections' ||
      per.startsWith(NEW_RECORD_COLLECTIONS_PREFIX) ||
      per === 'completion_collections' ||
      per.startsWith(NEW_COMPLETION_COLLECTIONS_PREFIX)
    ) {
      return 'app.labels.iterated_record_prefixed';
    } else if (per.startsWith('~users_') || this.per === '~user_involvements_') {
      return 'app.labels.iterated_user_prefixed';
    }

    return 'app.labels.iterated_record_prefixed';
  }

  setPer(per: string): void {
    this.syncedEvent = {
      ...this.syncedEvent,
      per,
      options: {
        ...this.syncedEvent.options,
      },
    };
  }

  getAutomationOptionsPromises(
    injectable_arguments: RequiredBy<InjectableArguments, 'concept_name'>,
    execution_context: 'schedule' | 'automation',
    chained = false
  ): Promise<void>[] {
    const promises = [];

    if (injectable_arguments?.concept_name) {
      promises.push(
        this.$api
          .getDateOptions(
            {
              concept_name: injectable_arguments.concept_name,
              injectable_arguments: injectable_arguments,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.dateOptions = data;
          })
      );

      promises.push(
        this.$api
          .getSelectableInjectableOptions(
            {
              concept_name: injectable_arguments.concept_name,
              injectable_arguments: injectable_arguments,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.fieldOptions = processSelectableOptions(data, '~', (name) =>
              this.$t('app.labels.triggering_record_prefixed', { name }).toString()
            );
          })
      );

      promises.push(
        this.$api
          .getInjectableOptions(
            {
              concept_name: injectable_arguments.concept_name,
              injectable_arguments: injectable_arguments,
              execution_context,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.triggerInjectableOptions = data;
          })
      );
    }

    return [...promises, ...this.getDefaultOptionsPromises(execution_context, chained)];
  }

  getDefaultOptionsPromises(execution_context: 'schedule' | 'automation', chained = false): Promise<void>[] {
    const promises = [];

    promises.push(
      this.$api.getSelectableInjectableOptions({ concept_name: 'CurrentContext' }, { cache: true }).then(({ data }) => {
        this.contextFieldOptions = processSelectableOptions(data, '&', (name) =>
          this.$t('app.labels.current_context_prefixed', { name }).toString()
        );
      })
    );

    promises.push(
      this.$api.getInjectableOptions({ execution_context, chained }, { cache: true }).then(({ data }) => {
        this.globalInjectableOptions = data;
      })
    );

    return promises;
  }

  async updatePerArguments(per: string, execution_context: 'schedule' | 'automation'): Promise<void> {
    if (per) {
      const perArguments: PerOptionResponse | null = await this.$api
        .evaluatePerOption({ per }, { cache: true })
        .then(({ data }) => data)
        .catch(() => null);

      if (!perArguments) return;
      this.perArguments = perArguments;

      // TODO: parallel requests
      const { data: selectableInjectableOptions } = await this.$api.getSelectableInjectableOptions(this.perArguments, { cache: true });
      this.perFieldOptions = processSelectableOptions(selectableInjectableOptions, '^', (name) =>
        this.$t(this.translationPerPrefix(per), { name }).toString()
      );

      const { data: dateOptions } = await this.$api.getDateOptions(this.perArguments, { cache: true });
      this.perDateOptions = dateOptions.map(([key, value]) => [
        `^${key}`,
        this.$t(this.translationPerPrefix(per), { name: value }).toString(),
      ]);

      const { data: injectableOptions } = await this.$api.getInjectableOptions({ ...perArguments, execution_context }, { cache: true });
      this.perTags = injectableOptions.map((placeholder) => ({ ...placeholder, val: `iterated.${placeholder.val}` }));
    } else {
      this.perArguments = null;
      this.perFieldOptions = {};
      this.perDateOptions = [];
      this.perTags = [];
    }
  }

  async getCollection(per: string, { set = false } = {}): Promise<void> {
    if (per.startsWith(RECORD_COLLECTIONS_PREFIX)) {
      const moduleRecordCollectionId = per.replace(RECORD_COLLECTIONS_PREFIX, '');

      // TODO: getModuleRecordCollection => getModuleRecordCollections (later)
      const { data: collection } = await this.$api.getModuleRecordCollection(
        moduleRecordCollectionId,
        { include: ['rule_set'] },
        { cache: true }
      );

      this.collection = collection;
      this.ruleSet = this.collection.rule_set || null;
      set && this.setPer(`new_record_collection_${this.collection.module_name_id}`);
    } else if (per.startsWith(COMPLETION_COLLECTIONS_PREFIX)) {
      const subFormCompletionCollectionId = per.replace(COMPLETION_COLLECTIONS_PREFIX, '');

      const { data: collection } = await this.$api.getSubFormCompletionCollection(
        subFormCompletionCollectionId,
        { include: ['rule_set'] },
        { cache: true }
      );

      this.collection = collection;
      this.ruleSet = this.collection.rule_set || null;
      set && this.setPer(`new_completion_collection_${this.collection.sub_form_id}`);
    } else if (this.numericPerCollection(this.per)) {
      this.collection = await this.$api
        .getUserCollection(per, { include: ['rule_set'] }, { cache: true })
        .then(({ data }) => data)
        .catch(() => null);

      if (this.collection) {
        this.ruleSet = this.collection.rule_set || null;
        set && !this.collection.global && this.setPer(NEW_USER_COLLECTION);
      } else {
        this.ruleSet = null;

        this.syncedEvent.per = 'NOT_FOUND';
        // setting it 👆 to null would mean changing the per to "trigger" which is not what we want
        // setting it to "NOT_FOUND" will show the error message, forcing the user to change the per
      }
    } else {
      this.collection = null;
      this.ruleSet = null;
    }
  }
}
