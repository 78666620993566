
  import { Component } from 'vue-property-decorator';
  import EventInputMethodSwitch from '@app/components/admin/events/event-input-method-switch.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Relationship } from '@app/models/relationship';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface RemoveRecordRelationEventFormOptions {
    from_id: string;
    relationship_code: string;
    to_id: string;
  }

  @Component({ components: { Select2, EntitySelector, EventInputMethodSwitch, AdvancedAutosuggest, FormField } })
  export default class RemoveRecordRelationEventForm extends BaseEventForm<RemoveRecordRelationEventFormOptions> {
    get relationshipFilters(): DonesafeFilterOptions<Relationship> {
      return {
        active: true,
      };
    }

    templateResult(result: { [key: string]: string } = {}): JQuery {
      return select2ResponseTemplate(result, { secondaryAttribute: 'code' });
    }

    beforeMount(): void {
      this.form = { ...this.values };
    }
  }
