import { BehaviorSubject } from 'rxjs';

export class Collapsible {
  private expandedSubject = new BehaviorSubject<boolean>(false);

  constructor(initialState: boolean = false) {
    this.expandedSubject.next(initialState);
  }

  get expandedChanges$() {
    return this.expandedSubject.asObservable();
  }

  toggle() {
    this.expandedSubject.next(!this.expandedSubject.value);
  }

  setExpanded(expanded: boolean) {
    this.expandedSubject.next(expanded);
  }

  isExpanded(): boolean {
    return this.expandedSubject.value;
  }
}
