
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { Location } from 'vue-router';
  import { BaseTable } from '../../base-table';
  import ModuleTabListActions from '../module-tab/module-tab-list-actions.vue';
  import type { ModuleTab } from '@app/models/module-tab';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ModuleTabListActions, BaseTable } })
  export default class ModuleTabsPage extends Vue {
    @Prop({ type: [Number, String] }) readonly moduleNameId!: number | string;
    @Ref() readonly table?: BaseTable<ModuleTab>;

    manager: Nullable<ListManager<ModuleTab>> = null;

    updateIndex(): void {
      if (this.manager) {
        const sort = this.manager.getSort(this.manager.sortOrder);
        const indexes = this.manager.items.map((item, index) => index);
        const desc = sort && sort[0] === '-';
        desc && indexes.reverse();
        const data = this.manager.items.map((item, index) => ({
          id: item.id,
          index: indexes[index],
        }));

        this.$api
          .updateModuleTabIndexes({ data, sort })
          .then(({ data }) => {
            toaster({
              text: this.$t('app.labels.order_saved'),
              position: 'top-right',
            });
            this.$api.cache.clear();
            this.table?.setData(data);
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    getManager(): ListManager<ModuleTab> {
      return new ListManager<ModuleTab>({
        fetchDataFunction: (params) => {
          return this.$api.getModuleTabs({ ...params, filters: { related_module_name: { id: this.moduleNameId } } }, { cache: true });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          { title: this.$t('app.labels.index'), name: 'index', sortField: 'index', width: '60px' },
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: '50px' },
          { title: this.$t('app.labels.title'), name: 'title', sortField: 'title' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
        ],
      });
    }

    // todo typing
    moduleTabLink(moduleTab: ModuleTab): Location {
      if (moduleTab.system) {
        return {
          name: 'admin-module-tabs-edit-page',
          params: { moduleNameId: `${this.moduleNameId}`, moduleTabId: `${moduleTab.id}` },
        };
      } else {
        return {
          name: 'admin-module-tabs-view-page',
          params: { moduleNameId: `${this.moduleNameId}`, moduleTabId: `${moduleTab.id}` },
        };
      }
    }

    mounted(): void {
      this.manager = this.getManager();
    }
  }
