
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import BaseTable from '@app/components/base-table/base-table.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { Matrix } from '@app/models/matrix/matrix';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: {
      BaseTable,
      FilterSelect,
      Select2,
      SearchInput,
    },
  })
  export default class AdminSettingsMatricesIndex extends Vue {
    @Ref() readonly table?: BaseTable<Matrix>;

    manager = new ListManager<Matrix>({
      fetchDataFunction: (params) =>
        this.$api.getMatrices({ ...params, only: ['id', 'name', 'active', { calculation_rule: ['id', 'name'] }] }, { cache: true }),
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('app.labels.calculation_rule'), name: 'calculation_rule', sortField: 'calculation_rule.name' },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
      ],
      allowFilters: true,
    });

    activeOptions = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
  }
