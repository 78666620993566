import { useAccountStore } from '@app/stores/account';
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import { mapValues, toPairs } from 'lodash';
import type { Dictionary } from '@app/models/dictionary';
import type { Event, EventSelectableOptions, UpdateTarget } from '@app/models/event';
import type { PlaceholderItem } from '@app/utils/types/placeholder-item';
import { mixins } from 'vue-class-component';

@Component
export class WithUniqID extends Vue {
  @Prop({ type: [Number, String], default: 0 }) readonly identifier?: number | string;

  uniqID(id: string): string {
    return `${id}__${this.identifier}`;
  }
}

@Component
export default class BaseEventForm<T> extends mixins(WithUniqID) {
  @Prop(Array) readonly tags?: PlaceholderItem[];
  @Prop(Object) readonly values?: T;
  @Prop(Object) readonly fieldOptions?: EventSelectableOptions;
  @Prop(String) readonly per?: string;
  @Prop(Object) readonly target?: UpdateTarget;
  @Prop(String) readonly executionContext!: 'automation' | 'schedule';
  @PropSync('event') syncedEvent?: Event;

  formReady = true;
  form: Partial<T> = {};

  get flatFieldOptionsRecord(): Record<string, [string, string][]> {
    return mapValues(this.fieldOptions, (value) => toPairs(value));
  }

  get injectableOptions(): PlaceholderItem[] {
    return this.tags || [];
  }

  get accountStore() {
    return useAccountStore();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: Dictionary<string>): void {
    if (this.syncedEvent) {
      if (this.syncedEvent?.event_type !== 'api_request') {
        this.syncedEvent.options = {
          ...this.syncedEvent.options,
          values: val,
        };
      }
    }
  }

  formLoaded() {
    this.formReady = true;
    this.$emit('loaded');
  }

  mounted() {
    this.formReady && this.$emit('loaded');
  }
}
