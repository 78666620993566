
  import type { ModuleTab } from '@app/models/module-tab';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import { camelCase, groupBy, upperFirst, sortBy, keyBy, uniq } from 'lodash';
  import { Component, Prop } from 'vue-property-decorator';
  import BasePrint from './base-print';
  import type { PrintCompletionOnly, PrintModuleTabsOnly, PrintSubFormListsOnly } from './utils';
  import SubFormCompletionShow from '@app/components/sub-form-completion/sub-form-completion-show.vue';
  import ExpenseEstimatesTab from '@app/components/sub-form-list-types/expense_estimates/expense-estimates-tab.vue';
  import ProceduresTab from '@app/components/sub-form-list-types/procedures-tab.vue';
  import RecordRelationsTab from '@app/components/sub-form-list-types/record-relations-tab.vue';
  import ResponseCounterTab from '@app/components/sub-form-list-types/response-counter-tab.vue';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';

  @Component({
    components: {
      SubFormCompletionShow,
      ExpenseEstimatesTab,
      ProceduresTab,
      RecordRelationsTab,
      ResponseCounterTab,
    },
  })
  export default class PrintCompletions extends BasePrint {
    @Prop() readonly moduleTabs!: Pick<ModuleTab, PrintModuleTabsOnly>[];
    @Prop() readonly subFormCompletions!: Pick<SubFormCompletion, PrintCompletionOnly>[];
    @Prop() readonly subFormListIds!: number[];

    sortBy = sortBy;

    normal = new Set([SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow]);
    special = new Set([
      SubFormListType.expense_estimates,
      SubFormListType.procedures,
      SubFormListType.record_relations,
      SubFormListType.relationship,
      SubFormListType.response_counter,
    ]);

    get approvalCompletionIds() {
      return this.subFormCompletions.filter(({ approval_form }) => !!approval_form).map(({ approval_form }) => approval_form?.id);
    }

    get completionsBySubFormList() {
      return groupBy(this.subFormCompletions.filter(({ id }) => !this.approvalCompletionIds.includes(id)) || {}, 'sub_form_list_id');
    }

    get appprovalCompletions() {
      return keyBy(
        this.subFormCompletions.filter(({ id }) => this.approvalCompletionIds.includes(id)),
        'id'
      );
    }

    get completionSubFormLists() {
      return uniq(this.subFormCompletions?.map(({ sub_form_list_id }) => sub_form_list_id));
    }

    getFilteredSortedSubFormLists(tab: Pick<ModuleTab, PrintModuleTabsOnly>) {
      return sortBy(
        tab.sub_form_lists?.filter((sfl: Pick<SubFormList, PrintSubFormListsOnly>) =>
          uniq([...this.subFormListIds, this.completionSubFormLists]).includes(sfl.id)
        ),
        'index'
      );
    }

    specialTabName(tabType: SubFormListType) {
      if (!this.special.has(tabType)) return;

      return `${upperFirst(camelCase(tabType))}Tab`;
    }

    printSubFormListTitle(sfl: Pick<SubFormList, PrintSubFormListsOnly>) {
      if (sfl.title) {
        return sfl.title;
      } else if (sfl.sub_forms?.length) {
        const { active, title } = sfl.sub_forms[0];
        return active ? title : this.$t('app.labels.archived_name', { naem: title });
      } else if (sfl.sub_form_list_type === SubFormListType.expense_estimates) {
        return this.$t('app.labels.expense_estimates');
      }
    }
  }
