import {
  RecordIndexWidget,
  ReportCountWidget,
  DomoWidget,
  IframeWidget,
  ImageWidget,
  VideoWidget,
  TextWidget,
  ReportGraphWidget,
  ActionsWidget,
  LinkWidget,
  ReportDataWidget,
  SubFormIndexWidget,
  FollowedRecordsWidget,
} from './index';

export const WIDGET_COMPONENTS = {
  RecordIndexWidget,
  ReportCountWidget,
  DomoWidget,
  IframeWidget,
  ImageWidget,
  VideoWidget,
  TextWidget,
  ReportGraphWidget,
  ActionsWidget,
  LinkWidget,
  ReportDataWidget,
  SubFormIndexWidget,
  FollowedRecordsWidget,
};
