
  import { Component, Prop } from 'vue-property-decorator';
  import BaseDashboardGrid from '@app/components/admin/dashboards/base-dashboard-grid';
  import type { GridStackOptions, GridStackWidget } from 'gridstack';
  import { CustomError } from '@app/utils/custom-error';
  import { isEmpty } from 'lodash';
  import type { Widget } from '@app/models/widget';

  import type { SaveDashboardResult } from './utils';
  import {
    MAX_SIX_BY_SIX_SUB_GRID_HEIGHT,
    convertDBWidgetToGridStackWidget,
    MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT,
    isSubGridWidget,
  } from './utils';

  const INVALID_SUBGRID_CLASS = 'grid-stack-sub-grid--invalid';

  @Component({ components: {} })
  export default class DashboardSixBySixEditGrid extends BaseDashboardGrid {
    @Prop() readonly config?: GridStackWidget[];

    get configWidgetIds() {
      return this.gridConfig().map((widget) => Number(widget.id));
    }

    async save(skipValidation = false): Promise<SaveDashboardResult> {
      const widgetsToSave = this.grid?.save(false) as GridStackWidget[];
      const subGrid = widgetsToSave.find(isSubGridWidget);
      const subGridWidgets = subGrid?.subGridOpts?.children || [];

      if (!skipValidation) {
        !subGridWidgets.length && this.showError(this.$t('tenant.admin.dashboard_panes.errors.no_subgrid_widgets'));

        this.widgetsOutOfSubGrid(subGridWidgets).length &&
          this.showError(this.$t('tenant.admin.dashboard_panes.errors.widgets_out_of_subgrid'));

        this.removeInvalidSubgridClass();
      }

      return { subGridWidgets, widgetsToSave: [...widgetsToSave.filter((w) => !isSubGridWidget(w)), ...subGridWidgets] };
    }

    showError(text: string) {
      this.addInvalidSubgridClass();
      throw new CustomError(text);
    }

    widgetsOutOfSubGrid(widgets: GridStackWidget[]) {
      return widgets.filter((node) => (node?.y || 0) + (node?.h || 0) > MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT);
    }

    addInvalidSubgridClass() {
      this.firstSubGridHTMLElement && this.firstSubGridHTMLElement.classList.add(INVALID_SUBGRID_CLASS);
    }

    removeInvalidSubgridClass() {
      this.firstSubGridHTMLElement && this.firstSubGridHTMLElement.classList.remove(INVALID_SUBGRID_CLASS);
    }

    gridConfig() {
      return isEmpty(this.config) ? [] : this.config || [];
    }

    getAllGridChildren() {
      return [
        this.getSixBySixWidgetAndDependants(this.gridConfig()),
        ...this.widgets.filter(this.widgetNotExistInConfig).map(convertDBWidgetToGridStackWidget),
      ];
    }

    sixBySixGridOptions() {
      return {
        acceptWidgets: true,
        children: this.getAllGridChildren(),
        disableOneColumnMode: true,
        oneColumnModeDomSort: false,
      };
    }

    widgetNotExistInConfig(widget: Widget) {
      return !this.configWidgetIds.includes(widget.id);
    }

    getSixBySixWidgetAndDependants(children: GridStackWidget[]): GridStackWidget {
      return {
        id: 'six_by_six',
        x: 0,
        y: 0,
        w: 6,
        h: MAX_SIX_BY_SIX_SUB_GRID_HEIGHT,
        noMove: true,
        noResize: true,
        subGridOpts: {
          children: children,
          margin: 10,
          cellHeight: 60,
          acceptWidgets: true,
          minRow: 1,
        } as GridStackOptions,
        locked: true,
      };
    }

    mounted() {
      this.initGridstack({ ...this.sixBySixGridOptions(), ...this.gridstackOptions });
      this.afterGridStackInit();
    }
  }
