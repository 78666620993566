
  import Select2 from '@app/components/select2.vue';
  import { Component, Model, Vue } from 'vue-property-decorator';
  import type { PiiFieldQuestion } from '@app/services/api/sub-form-questions-api';
  import { titleize } from '@app/utils/titleize';
  import { POSSIBLE_PII_FIELDS } from '@app/constants';

  @Component({ components: { Select2 } })
  export default class PiiSelector extends Vue {
    @Model('input') value!: string[];

    questions: Record<string, PiiFieldQuestion[]> = {};
    listOpen = false;

    beforeMount(): void {
      this.refreshPii(this.value || []);
    }

    toggleList(): void {
      this.listOpen = !this.listOpen;
    }

    refreshPii(lookupOptions: string[]): void {
      if (lookupOptions.length) {
        const newQuestions: Record<string, PiiFieldQuestion[]> = {};
        const valuesWithFormatted = [...lookupOptions, ...lookupOptions.map((field) => `formatted_${field}`)];
        const filters = { config: { mode: 'lookup', lookup: { user_attribute: valuesWithFormatted } } };
        this.$api.getSubFormQuestions({ filters, include: ['sub_form_section'] }, { cache: true }).then(({ data }) => {
          data.forEach((question) => {
            let field = question.config.lookup?.user_attribute;
            if (field && question.sub_form_section?.sub_form_id) {
              if (field.startsWith('formatted_')) field = field.replace('formatted_', '');
              if (!newQuestions[field]) newQuestions[field] = [];
              newQuestions[field].push({ id: question.id, code: question.code, sub_form_id: question.sub_form_section.sub_form_id });
            }
          });
          this.questions = newQuestions;
        });
      } else {
        this.questions = {};
      }
      this.$emit('input', lookupOptions);
    }

    subFormEditPath(question: PiiFieldQuestion): string {
      return `/admin/settings/sub_forms/${question.sub_form_id}`;
    }

    fieldLabel(field: string): string {
      return titleize(field.replace(/_id$/, ''));
    }

    get fieldsAffected(): string[] {
      return Object.keys(this.questions);
    }

    get userPiiFields(): { label: string; value: string }[] {
      return POSSIBLE_PII_FIELDS.map((field) => {
        return { value: field, label: this.fieldLabel(field) };
      });
    }
  }
