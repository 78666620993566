
  import { Component, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';

  @Component({
    components: {
      DsDropdown,
    },
  })
  export default class ScheduledEventsActions extends Vue {
    get logLink(): string {
      return '/admin/settings/event_occurrences?filters[triggered_concept]=NULL';
    }
  }
