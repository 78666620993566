
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import Select2 from '@app/components/select2.vue';
  import RadioButtons from './radio-buttons.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { LookupOption, LookupSourceOption } from '@app/services/api/sub-form-questions-api';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType, SELF_LOOKUP_KEY } from '@app/models/sub-form-question';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({ components: { FormField, Select2, RadioButtons, DsCheckbox } })
  export default class LookupConfigurator extends Vue {
    @Model('input') value!: SubFormQuestion;

    @Prop(Object) readonly subForm!: SubForm;

    lookupSourceOptions: LookupSourceOption[] = [];
    stickToReadonly = false;

    get isMFQL(): boolean {
      return this.value.field_type === FieldType.main_form_question_list;
    }

    get advanced() {
      return this.value.config.lookup?.advanced === 'true';
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }

    get showLoopingReadOnlyLookupWarning(): boolean {
      return this.value.config?.lookup?.related_record_question_code === this.value.code;
    }

    get selectedLookupOption(): LookupSourceOption | undefined {
      return this.lookupSourceOptions.find((option) => option.code === this.value.config?.lookup?.related_record_question_code);
    }

    get isPiiUserField(): boolean {
      const option = this.selectedLookupOption;
      if (option?.type === 'user_attribute') {
        const selectedField = this.value.config?.lookup?.[option.type];
        return this.piiFields.some((field) => field === selectedField || `formatted_${field}` === selectedField);
      } else {
        return false;
      }
    }

    get accountStore() {
      return useAccountStore();
    }

    get piiFields(): string[] {
      return [
        ...(this.accountStore.data.pii_fields || []),
        'profile_other_names',
        'profile_suburb',
        'profile_postcode',
        'profile_personal_email',
        'profile_home_number',
        'profile_residential_address_1',
        'profile_residential_address_2',
        'profile_state_display',
        'profile_country_display',
        'profile_native_language',
        'profile_special_needs',
      ];
    }

    get readOnlyOptions(): { key: string; label: string }[] {
      return [
        { key: 'true', label: this.$t('tenant.admin.sub_form_questions.advanced_options.read') },
        { key: 'false', label: this.$t('tenant.admin.sub_form_questions.advanced_options.fetch') },
      ];
    }

    get lookupTargetWarning() {
      if (this.isPiiUserField) {
        return this.$t('tenant.admin.sub_form_questions.advanced_options.lookup_pii_field_warning');
      }

      const { options = [], type = null } = this.selectedLookupOption || {};
      const lookup = this.value.config.lookup;

      if (lookup && type && (options || []).find(({ code }) => code === (lookup[type] || {}))?.hidden) {
        return this.$t('tenant.admin.sub_form_questions.advanced_options.lookup_rights_sensitive');
      }
    }

    @Watch('selectedLookupOption')
    setDefaultMode() {
      if (this.selectedLookupOption) {
        this.$$patch(this.value.config.lookup, { readonly: 'false' }, { ifEmpty: true });
      }
    }

    beforeMount() {
      this.$$patch(this.value.config, { lookup: { readonly: 'false' } }, { ifEmpty: true });
      this.stickToReadonlyRelatedRecord(this.value.config?.lookup?.related_record_question_code);
      this.loadLookupSourceOptions();
    }

    onRelationSourceSelect(value?: string): void {
      if (!this.value.config.lookup) return;

      if (value === SELF_LOOKUP_KEY) {
        this.$$patch(this.value.config.lookup, { user_attribute: undefined, sub_form_question_code: undefined });
      }

      this.stickToReadonlyRelatedRecord(value);
    }

    stickToReadonlyRelatedRecord(value?: string): void {
      this.stickToReadonly = value === SELF_LOOKUP_KEY && this.onMainForm;

      if (this.stickToReadonly && this.value.config.lookup) {
        this.$$patch(this.value.config.lookup, { readonly: 'true', protected: 'true' });
      }
    }

    async loadLookupSourceOptions() {
      const { data: lookupSourceOptions } = await this.$api.getSubFormQuestionLookupSourceOptions(
        this.value.id,
        { advanced: this.advanced },
        { cache: true }
      );
      return (this.lookupSourceOptions = lookupSourceOptions);
    }

    readonlyToggled(readonly: StringBoolean): void {
      this.$$patch(this.value.config.lookup, { readonly });

      if (readonly === 'true' && this.value.config.lookup) {
        this.$$patch(this.value.config.lookup, { protected: 'true' });
      }
    }

    handleHiddenOptions(options: LookupOption[], currentValue: string) {
      return options.filter(({ hidden, code }) => !hidden || code === currentValue).map((o) => ({ ...o, disabled: o.hidden }));
    }
  }
