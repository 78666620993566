
  import DsLabel from '@app/components/ds-label.vue';
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import type { ActionPriority } from '@app/models/action-priority';

  @Component({ components: { DsLabel } })
  export default class PriorityLabel extends Vue {
    @Prop(Number) priorityId?: number;

    priority: ActionPriority | null = null;

    beforeUpdate() {
      this.fetchPriority();
    }

    beforeMount() {
      this.fetchPriority();
    }

    async fetchPriority() {
      if (!this.priorityId) return;

      const { data: priority } = await this.$api.getActionPriority(this.priorityId, {}, { cache: true, join: true });

      this.priority = priority;
    }

    get color(): Maybe<string> {
      return this.priority?.color || '#808080';
    }

    get displayText(): Maybe<string> {
      if (!this.priority) return;

      const displayTitle = (ap?: ActionPriority) => {
        if (!ap) return this.$t('app.labels.na');

        return ap.active ? ap.title : this.$t('app.labels.archived_name', { name: ap.title });
      };

      return this.$t('app.labels.action_priority', { action_priority: displayTitle(this.priority) }) as string;
    }
  }
