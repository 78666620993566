import { render, staticRenderFns } from "./tab-visual-options.vue?vue&type=template&id=627a36fb&scoped=true&lang=pug"
import script from "./tab-visual-options.vue?vue&type=script&lang=ts"
export * from "./tab-visual-options.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627a36fb",
  null
  
)

export default component.exports