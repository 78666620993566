
  import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsIcon from '@app/components/ds-icon.vue';
  import type { DsGroupNode } from '../models/ds-group-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import CollapsedText from '../rule/collapsed-text.vue';

  const INPUT_FACTOR = 8.5;

  @Component({ components: { DsIcon, CollapsedText } })
  export default class GroupLabel extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() readonly group!: DsGroupNode;
    @Ref() readonly input!: HTMLInputElement;

    labelEditable = false;
    inputWidth = 50;

    get value() {
      return this.group.label;
    }

    get inputStyle() {
      return { width: this.inputWidth + 'px', background: !this.labelEditable ? 'transparent' : '' };
    }

    focusOnInput() {
      this.$nextTick(() => this.input.focus());
    }

    toggleLabelEditable() {
      this.labelEditable = !this.labelEditable;

      if (this.labelEditable) this.focusOnInput();
    }

    setInputWidth(size: number) {
      this.inputWidth = (size + 1) * INPUT_FACTOR;
    }

    updateLabel(event: Event) {
      const target = event.target as HTMLInputElement;
      this.setInputWidth(target.value.length);
      this.group.updateLabel(target.value);
    }

    mounted() {
      this.setInputWidth(this.value.length);
    }
  }
