
  import GenderListFormModal from '@app/components/admin/user-settings/tabs/gender-list-form-modal.vue';
  import Blocking from '@app/mixins/blocking';
  import { mixins } from 'vue-class-component';
  import { Component, Ref } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';
  import { BaseTable } from '../../../base-table';
  import type { GenderIdentity } from '@app/models/gender-identity';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Tab, Tabs, BaseTable, GenderListFormModal } })
  export default class GenderListTab extends mixins(Blocking) {
    @Ref() readonly table?: BaseTable<GenderIdentity>;
    @Ref() readonly modal?: GenderListFormModal;

    manager: Nullable<ListManager<GenderIdentity>> = null;
    modalVisible = false;

    getManager(): ListManager<GenderIdentity> {
      return new ListManager<GenderIdentity>({
        fetchDataFunction: (params) => {
          return this.$api.getGenderIdentities(
            {
              ...params,
              only: ['active', 'code', 'id', 'identity'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'desc', field: 'code', sortField: 'code' }],
        per_page: 25,
        fields: [
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.name'),
            name: 'code',
            sortField: 'code',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.identity'),
            name: 'identity',
            sortField: 'identity',
          },
          {
            title: this.$t('tenant.admin.settings.menus.user_settings.labels.active'),
            name: 'active',
            sortField: 'active',
          },
        ],
      });
    }

    createOrUpdateGenderIdentity(form: Partial<GenderIdentity>): void {
      const promise = form.id ? this.$api.updateGenderIdentity(form.id as number, form) : this.$api.createGenderIdentity(form);
      const message = form.id
        ? this.$t('tenant.admin.settings.menus.user_settings.updated.gender_identity')
        : this.$t('tenant.admin.settings.menus.user_settings.created.gender_identity');
      this.blocking(async () => {
        await promise;
        toaster(message);
        this.toggleModal(false);
        this.$api.cache.clear();
        this.table?.reload();
      });
    }

    toggleModal(value: boolean, genderIdentity?: Partial<GenderIdentity>): void {
      value && this.modal?.reset(genderIdentity);
      this.modalVisible = value;
    }

    beforeMount(): void {
      this.manager = this.getManager();
    }
  }
