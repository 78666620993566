
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import bootbox from 'bootbox';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import RoleLegacySettingsModal from './role-legacy-settings-modal.vue';
  import type { RoleLegacySettingsForm } from './role-tab-details/utils';
  import { omit } from 'lodash';
  import type { Role } from '@app/models/role';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown, RoleLegacySettingsModal } })
  export default class RoleActions extends Vue {
    @Prop({ type: Number }) readonly roleId!: number;
    @Prop({ type: String, default: () => 'div' }) readonly tag!: string;

    role: Nullable<Role> = null;
    showLegacySettingsModal = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get showLegacySettingsLink(): boolean {
      return !!this.currentUserStore.data?.admin;
    }

    toggleRoleLegacySettingsModal(): void {
      this.showLegacySettingsModal = !this.showLegacySettingsModal;
    }

    archiveRole(): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        callback: (result: boolean) => {
          result &&
            this.$api
              .updateRole(this.roleId, { active: false })
              .then(() => {
                this.$router.push({ name: 'admin-settings-roles' });
              })
              .catch(({ data }) => {
                toaster({ text: data?.error, position: 'top-right', icon: 'error' });
              });
        },
      });
    }

    updateRole(form: RoleLegacySettingsForm): void {
      this.$api
        .updateRole(this.roleId, omit(form, 'id'))
        .then(() => {
          this.toggleRoleLegacySettingsModal();
          this.$router.go(0);
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
