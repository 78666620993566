import { render, staticRenderFns } from "./normal-tab.vue?vue&type=template&id=6c493568&scoped=true&lang=pug"
import script from "./normal-tab.vue?vue&type=script&lang=ts"
export * from "./normal-tab.vue?vue&type=script&lang=ts"
import style0 from "./normal-tab.vue?vue&type=style&index=0&id=6c493568&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c493568",
  null
  
)

export default component.exports