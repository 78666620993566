
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import Blocking from '@app/mixins/blocking';
  import { uniq, groupBy, merge } from 'lodash';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { BaseRecord } from '@app/models/module-record';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { SubFormCompletionStage } from '@app/models/sub-form-completion';
  import { SUBFORM_FILTERABLE_TYPES } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';
  import { Tuple } from '@app/utils/types/tuple';
  import { buildLink } from '@app/utils/build-link';
  import { textColor } from '@app/utils/text-color';
  import { changeLocation } from '@app/utils/change-location';

  import { NORMAL_TAB_SFL_ONLY } from '../sub-form-completion/utils';
  import type { NormalTabSfcOnly, NormalTabSflOnly } from '../sub-form-completion/utils';
  import SubFormCompletionIndex from '../sub-form-completion/sub-form-completion-index.vue';
  import EntitySelector from '../entity-selector.vue';

  const NORMAL_TAB_SFQ_ONLY = Tuple(['code', 'title', 'config', 'id', 'options'] as const);
  type NormalTabSfqOnly = (typeof NORMAL_TAB_SFQ_ONLY)[number];

  @Component({ components: { DsDropdown, EntitySelector, Select2, SubFormCompletionIndex } })
  export default class NormalTab extends Blocking {
    @Ref() readonly subFormCompletionIndex!: SubFormCompletionIndex;
    @Prop(String) readonly recordType!: string;
    @Prop(Boolean) readonly approvable?: boolean;
    @Prop(Boolean) readonly canAddMore?: boolean;
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Object) readonly record!: Pick<BaseRecord, 'id'>;
    @Prop(Boolean) readonly editable?: boolean;
    @Prop(Boolean) readonly printing?: boolean;
    @Prop(Boolean) readonly archivable?: boolean;
    @Prop(Number) readonly subFormListId!: number;

    subFormList: Nullable<Pick<SubFormList, NormalTabSflOnly>> = null;
    questionsByCode: Record<string, Pick<SubFormQuestion, NormalTabSfqOnly>[]> = {};

    questionFilterValuesByCode = {};
    completionsLoaded = true;

    get tabTitleClass() {
      if (this.canAddMore) {
        return 'col-xs-7';
      }

      return 'col-xs-12';
    }

    get extraAddButtonStyle() {
      return {
        ...(this.subFormList?.add_background_color
          ? {
              backgroundColor: `${this.subFormList.add_background_color} !important`,
              color: `${textColor(this.subFormList.add_background_color)} !important`,
            }
          : {}),
      };
    }

    get autoSave() {
      return this.subFormList?.draft && this.subFormList?.auto_save;
    }

    get displaySubFormQuestionCodes() {
      return (this.subFormList?.display_sub_form_question_codes || []).filter(Boolean);
    }

    get addTextButton() {
      return this.subFormList?.add_text || this.$t('app.buttons.add');
    }

    completionParams(subFormId: number) {
      return {
        record_id: this.record.id,
        record_type: this.recordType,
        sub_form_id: subFormId,
        sub_form_list_id: this.subFormList?.id,
      };
    }

    editFormRoute(subFormList: Pick<SubFormList, NormalTabSflOnly>) {
      return {
        name: 'admin-sub-forms-show-redirect',
        params: { subFormId: `${subFormList.sub_form_ids?.[0]}` },
        query: { sub_forms_list: 'true' },
      };
    }

    newCompletionLink(subFormId: number) {
      return this.autoSave ? null : buildLink(`/sub_form_completions/new`, this.completionParams(subFormId));
    }

    async autoSaveAction(subFormId: number): Promise<void> {
      await this.blocking(async () => {
        const { data: responses } = await this.$api.getSubFormCompletionDefaultValues(this.completionParams(subFormId));
        const { data } = await this.$api.createSubFormCompletion({
          ...this.completionParams(subFormId),
          responses,
          stage: SubFormCompletionStage.Draft,
          lookup: true,
        });
        changeLocation(`/sub_form_completions/${data.id}/edit?auto_save=true`);
      });
    }

    newCompletionAction(subFormId: number) {
      if (!this.autoSave || this.blocked) {
        return;
      }

      this.autoSaveAction(subFormId);
    }

    onQuestionFilterInput(key: string, value: string) {
      this.questionFilterValuesByCode = {
        ...this.questionFilterValuesByCode,
        [key]: value,
      };

      this.subFormCompletionIndex.fetchCompletions(this.questionFilterValuesByCode);
    }

    questionPlaceholder(questions: Pick<SubFormQuestion, NormalTabSfqOnly>[]) {
      return uniq(questions.map(({ title }) => title.trim())).join(' | ');
    }

    optionValuesArray(question: Pick<SubFormQuestion<BaseSelectQuestionOptions>, NormalTabSfqOnly>, key = 'values'): SelectOption[] {
      const questionOptions = merge(question.config, question.options);
      return Object.values((questionOptions as Record<string, SelectOption>)[key]);
    }

    questionOptions(questions: Pick<SubFormQuestion, NormalTabSfqOnly>[]) {
      return Object.values(
        groupBy(
          questions.flatMap((q) => this.optionValuesArray(q).map((x) => [x.value, x.key])),
          ([, key]) => key
        )
      ).map((opts) => [opts[0][1], uniq(opts.map(([first]) => first.trim())).join(' | ')]);
    }

    fetchFilterQuestions() {
      !!this.displaySubFormQuestionCodes.length &&
        this.$api
          .getSubFormQuestions(
            {
              filters: {
                active: true,
                code: this.displaySubFormQuestionCodes.join(','),
                field_type: SUBFORM_FILTERABLE_TYPES,
                sub_form_section: { sub_form_id: this.subFormList?.sub_form_ids?.[0] },
              },
              only: NORMAL_TAB_SFQ_ONLY,
              sort: 'index',
              per_page: -1,
            },
            {
              cache: true,
            }
          )
          .then(({ data }) => {
            this.questionsByCode = groupBy(data, 'code');
          });
    }

    onSubFormCompletionsFetched(data: Pick<SubFormCompletion, NormalTabSfcOnly>[]) {
      this.completionsLoaded = true;
      if (!!data?.length && this.displaySubFormQuestionCodes.length) {
        this.fetchFilterQuestions();
      }
    }

    beforeMount() {
      // TODO: replace this with a prop, once we going to rewrite the whole page to Vue
      this.$api.getSubFormList(this.subFormListId, { only: NORMAL_TAB_SFL_ONLY }, { cache: true, join: true }).then(({ data }) => {
        this.subFormList = data;
      });
    }
  }
