import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import type { ModuleName } from '@app/models/module-name';
import type { SubFormList } from '@app/models/sub-form-list';

@Component({})
export default class SubFormListSettingsBase extends Vue {
  @Prop(Object) readonly moduleName!: ModuleName;
  @Prop(Boolean) readonly isHybrid?: boolean;
  @PropSync('subFormList') subFormListSync!: Partial<SubFormList>;
}
