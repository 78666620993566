
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '../form-field.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';

  @Component({ components: { EntitySelector, FormField } })
  export default class RecordCalculationDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & { default: { id: string } }>;
    @Prop(Object) subForm!: SubForm;

    moduleNameId: number | null = null;

    async beforeMount() {
      const filters =
        this.subForm.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };
      const {
        data: [{ id }],
      } = await this.$api.getModuleNames({ filters, only: ['id'] }, { cache: true });

      this.moduleNameId = id;
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['id'].forEach((key) => this.$delete(this.value.config.default, key));
    }
  }
