
  import { ValidationObserver } from 'vee-validate';
  import { Component, Model, Vue } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Oauth2Config } from '@app/pages/admin/placeholder_profiles/utils';
  import { PlaceholderProfileOauth2AuthType } from '@app/pages/admin/placeholder_profiles/utils';

  interface OauthFormField {
    inputType?: 'text' | 'password' | 'number';
    key: string;
    label: string;
    required?: boolean;
  }

  @Component({ components: { ValidationObserver, Select2, Tooltip, DsTextInput } })
  export default class Oauth2PlaceholderProfileConfigForm extends Vue {
    @Model('input') readonly config!: Oauth2Config;

    get authTypeOptions(): [string, string][] {
      return [
        ['client_secret', this.$t('tenant.settings.placeholder_profiles.form.auth_type_labels.client_secret')],
        ['client_assertion', this.$t('tenant.settings.placeholder_profiles.form.auth_type_labels.client_assertion')],
      ];
    }

    get availablePlaceholders(): string[] {
      return ['access_token', 'token_type', 'expires_in', 'scope', 'id_token'];
    }

    get oauth2Variables(): OauthFormField[] {
      let variables: OauthFormField[] = [
        {
          key: 'access_token_url',
          label: this.$t('tenant.settings.placeholder_profiles.form.oauth2_general.access_token_url'),
          required: true,
        },
        { key: 'scope', label: this.$t('tenant.settings.placeholder_profiles.form.oauth2_general.scope') },
        { key: 'resource', label: this.$t('tenant.settings.placeholder_profiles.form.oauth2_general.resource') },
        { key: 'grant_type', label: this.$t('tenant.settings.placeholder_profiles.form.oauth2_general.grant_type'), required: true },
      ];
      if (this.config.oauth2_auth_type === PlaceholderProfileOauth2AuthType.client_secret) {
        variables = [
          ...variables,
          {
            key: 'client_id',
            label: this.$t('tenant.settings.placeholder_profiles.form.client_secret_mode.client_id'),
            inputType: 'password',
            required: true,
          },
          {
            key: 'client_secret',
            label: this.$t('tenant.settings.placeholder_profiles.form.client_secret_mode.client_secret'),
            inputType: 'password',
            required: true,
          },
        ];
      } else if (this.config.oauth2_auth_type === PlaceholderProfileOauth2AuthType.client_assertion) {
        variables = [
          ...variables,
          {
            key: 'client_assertion_type',
            label: this.$t('tenant.settings.placeholder_profiles.form.client_assertion_mode.client_assertion_type'),
            required: true,
          },
          { key: 'jwt_aud', label: this.$t('tenant.settings.placeholder_profiles.form.client_assertion_mode.jwt_aud'), required: true },
          { key: 'jwt_sub', label: this.$t('tenant.settings.placeholder_profiles.form.client_assertion_mode.jwt_sub'), required: true },
          { key: 'jwt_iss', label: this.$t('tenant.settings.placeholder_profiles.form.client_assertion_mode.jwt_iss'), required: true },
          {
            key: 'jwt_token_ttl',
            label: this.$t('tenant.settings.placeholder_profiles.form.client_assertion_mode.jwt_token_ttl'),
            required: true,
          },
        ];
      }
      return variables;
    }
  }
