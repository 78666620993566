
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import DsQueryBuilder from '@app/components/ds-query-builder/ds-query-builder.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { pick } from 'lodash';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { Dictionary } from '@app/models/dictionary';
  import type { RuleSetFilter } from '@app/models/rule-set-filter';

  @Component({
    components: {
      DsQueryBuilder,
      DsModal,
    },
  })
  export default class TriggerConditionsModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;

    rules = '';
    loading = true;
    filters: RuleSetFilter[] = [];
    error: Nullable<string> = null;

    get injectableArguments(): InjectableArguments {
      return {
        ...pick(this.automationDefinition || {}, [
          'concept_name',
          'sub_form_id',
          'involvement_id',
          'relationship_code',
          'regulatory_report_config_id',
        ]),
      };
    }

    get automationRuleSetBuilder(): AutomationRuleSetBuilder {
      return {
        concept_name: this.automationDefinition?.concept_name,
        injectable_arguments: this.injectableArguments,
        triggering_concept_name: null,
        for_collection_options: false,
        triggering_injectable_arguments: {},
      };
    }

    async fetchData(): Promise<void> {
      this.loading = true;
      this.error = null;
      this.rules = JSON.stringify(this.automationDefinition.rule_set?.query_builder_blob);
      try {
        const { data } = await this.$api.getRuleSetFilters(
          {
            concept_name: this.automationRuleSetBuilder.concept_name,
            injectable_arguments: this.automationRuleSetBuilder.injectable_arguments as Dictionary<string>,
            triggering_concept_name: this.automationRuleSetBuilder.triggering_concept_name as string,
            triggering_injectable_arguments: this.automationRuleSetBuilder.triggering_injectable_arguments,
            execution_context: 'automation',
          },
          { cache: true }
        );
        this.filters = data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const message = error.data?.error || error.message;
        this.error =
          typeof message === 'string'
            ? this.$t('tenant.admin.automation_definitions.conditions_modal.fetch_error', { error: message })
            : this.$t('tenant.admin.automation_definitions.conditions_modal.unknown_fetch_error');
      } finally {
        this.loading = false;
      }
    }

    handleHide() {
      this.loading = true;
      this.$emit('hide');
    }
  }
