
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { intersection, keyBy } from 'lodash';
  import type { ShowOptions } from '@app/models/module-name';
  import type { DisplayScoringGroup, Score, ScoreData } from '@app/components/module-record/display-score';
  import ModuleRecordScoreView from '@app/components/module-record/module-record-score-view.vue';

  @Component({ components: { ModuleRecordScoreView } })
  export default class ModuleRecordScore extends Vue {
    @Prop(Number) readonly recordId!: number;

    showOptions: Nullable<ShowOptions> = null;
    total: Nullable<Score> = null;
    groups: Nullable<DisplayScoringGroup[]> = null;

    get loaded(): boolean {
      return !!this.total && !!this.groups && !!this.showOptions;
    }

    beforeMount() {
      this.loadScoringGroups();
    }

    async loadScoringGroups() {
      const {
        data: {
          module_name_id,
          record_scoring_groups = [],
          score_band_profile_id,
          score_band_code,
          score_data,
          scoring_groups_settings = {},
        },
      } = await this.$api.getModuleRecord(this.recordId, {
        only: [
          'module_name_id',
          'score_band_profile_id',
          'score_band_code',
          'score_data',
          'scoring_groups_settings',
          { record_scoring_groups: ['scoring_group_id', 'score_data', 'score_band_code'] },
        ],
      });

      if (score_band_profile_id && score_band_code) {
        this.$api
          .getScoreBandProfile(score_band_profile_id)
          .then(({ data: profile }) => (this.total = { score: score_data as ScoreData, scoreBand: { profile, code: score_band_code } }));
      } else if (score_data) {
        this.total = { score: score_data };
      }
      await this.loadScoringSettings(module_name_id);

      const { data: scoringGroups } = await this.$api.getScoringGroups({
        filters: {
          id: intersection(
            record_scoring_groups.map(({ scoring_group_id }) => scoring_group_id),
            Object.values(scoring_groups_settings)
          ),
        },
        only: ['id', 'title', 'score_band_profile', 'display_method'],
        sort: 'index',
      });
      const groupScores = keyBy(record_scoring_groups, 'scoring_group_id');

      this.groups = scoringGroups.reduce((memo, sg) => {
        const { id, title, display_method, score_band_profile } = sg;
        const rsg = groupScores[id];
        if (rsg) {
          const { score_band_code, score_data } = rsg || {};
          const data = {
            id,
            title,
            displayMethod: display_method,
            score: {
              score: score_data as ScoreData,
              scoreBand: score_band_code && score_band_profile ? { code: score_band_code, profile: score_band_profile } : undefined,
            },
          };
          return [...memo, data];
        }

        return memo;
      }, [] as DisplayScoringGroup[]);
    }

    async loadScoringSettings(moduleNameId: number) {
      const {
        data: { show_options },
      } = await this.$api.getModuleName(moduleNameId, { only: ['show_options'] });

      this.showOptions = show_options;
    }
  }
