
  import { merge } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class ConfigValues extends Vue {
    @Prop() readonly question!: SubFormQuestion;

    get prettyOptions() {
      const optionsHash = merge(this.question.options, this.question.config);
      return Object.values(optionsHash.values || {})
        .map((o) => o.value)
        .join(', ');
    }
  }
