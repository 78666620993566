
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class AutomationDetailsPaneSelector extends Vue {
    @Prop(Boolean) readonly selected!: boolean;
    @Prop(Boolean) readonly invalid!: boolean;
    @Prop(String) readonly controls!: string;
    @Prop(String) readonly label!: string;
    @Prop(Boolean) readonly disabled?: boolean;
  }
