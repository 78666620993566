
  import type { ModuleName } from '@app/models/module-name';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import ModuleRecordPrintConfigModal from './module-record-print-config-modal.vue';

  @Component({ components: { ModuleRecordPrintConfigModal } })
  export default class ModuleRecordPrintConfigModalButton extends Vue {
    @Prop(Number) readonly recordId!: number;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'name'>;
    @Prop({ type: String, default: () => 'li' }) readonly tag!: string;
    modalVisible = false;
  }
