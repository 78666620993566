
  import type { DomoEmbedParams } from '@app/services/api/utils-api';
  import qs from 'qs';
  import { uniq, flatten } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import BaseWidget from './base-widget';
  import type { Dictionary } from '@app/models/dictionary';
  import type { DomoWidgetOptions } from '@app/models/widget';
  import { DomoFilterEmptyBehaviourOption } from '@app/models/widget';

  @Component({ components: {} })
  export default class DomoWidget extends BaseWidget<DomoWidgetOptions> {
    placeholderDictionary: Dictionary<string> = {};
    initialized = false;

    get src(): string | undefined {
      const url = this.widget.options.url;
      const pfilters =
        this.widget.options.filters
          ?.filter((filter) => {
            const isEmpty = !filter.values.some((f) => f);
            return !(filter.empty_behaviour === DomoFilterEmptyBehaviourOption.skip && isEmpty);
          })
          .map((filter) => {
            return {
              label: filter.column,
              column: filter.column,
              operand: filter.operand,
              values: filter.values.map((v) => (v in this.placeholderDictionary ? [v] : v)),
            };
          }) || [];

      const existingParams = url.split('?')[1];
      return `${url.split('?')[0]}?${qs.stringify({
        ...qs.parse(existingParams),
        pfilters: pfilters.length ? JSON.stringify(pfilters) : undefined,
        enable: this.widget.options.features?.join(','),
      })}`;
    }

    get privateDomo(): boolean {
      return this.widget.options.embed_style === 'private';
    }

    get embedId(): string | undefined {
      return this.widget.options.embed_id;
    }

    get embedType(): DomoWidgetOptions['embed_type'] {
      return this.widget.options.embed_type;
    }

    getGeneratedEmbedURL(data: DomoEmbedParams): string {
      return `
        <html lang="en">
          <body>
            <form id="form" action="${data.embed_origin}${data.embed_path}" method="post">
              <input type="hidden" name="embedToken" value="${data.embed_token}">
            </form>
            <script>
              document.getElementById('form').submit();
            <\/script>
          </body>
        </html>`;
    }

    beforeMount(): void {
      if (this.embedId && this.embedType) {
        this.$api.getDomoWidgetData({ embed_cache: !this.canEdit, widget_id: this.widget.id }).then(({ data }) => {
          const src = this.getGeneratedEmbedURL(data);
          const iframe = this.$refs.newIframe as HTMLIFrameElement;
          iframe.srcdoc = src;

          const supportsSrcdoc = 'srcdoc' in document.createElement('iframe');
          if (!supportsSrcdoc) {
            iframe.dataset.src = src;
            const js = 'javascript: window.frameElement.getAttribute("data-src")';
            iframe.setAttribute('src', js);
            if (iframe.contentWindow) {
              iframe.contentWindow.location = js;
            }
          }
        });
      } else {
        const placeholders = uniq(flatten(this.widget.options.filters?.map((f) => f.values) || []));
        if (placeholders.length) {
          this.$api.evaluatePlaceholders({ placeholders }).then(({ data }) => {
            this.placeholderDictionary = data;
            this.initialized = true;
          });
        } else {
          this.initialized = true;
        }
      }
    }
  }
