export { default as RecordIndexWidgetSettings } from './record-index-widget-settings.vue';
export { default as FollowedRecordsWidgetSettings } from './followed-records-widget-settings.vue';
export { default as DomoWidgetSettings } from './domo-widget-settings.vue';
export { default as ReportCountWidgetSettings } from './report-count-widget-settings.vue';
export { default as IframeWidgetSettings } from './iframe-widget-settings.vue';
export { default as ImageWidgetSettings } from './image-widget-settings.vue';
export { default as VideoWidgetSettings } from './video-widget-settings.vue';
export { default as TextWidgetSettings } from './text-widget-settings.vue';
export { default as ReportGraphWidgetSettings } from './report-graph-widget-settings.vue';
export { default as ActionsWidgetSettings } from './actions-widget-settings.vue';
export { default as LinkWidgetSettings } from './link-widget-settings.vue';
export { default as ReportDataWidgetSettings } from './report-data-widget-settings.vue';
export { default as SubFormIndexWidgetSettings } from './sub-form-index-widget-settings.vue';
