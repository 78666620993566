
  import { Component, Inject, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import type { Subscription } from 'rxjs';

  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import type { DsGroupNode } from '../models/ds-group-node';

  import GroupLabel from './group-label.vue';
  import GroupSubHeader from './group-conditions.vue';

  @Component({ components: { DsDropdown, DsIcon, DsIconText, GroupLabel, GroupSubHeader } })
  export default class GroupHeader extends WithBootbox {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() readonly group!: DsGroupNode;
    @Prop(Boolean) readonly hideLabel?: boolean;

    isExpandedShown = false;
    isExpandedShown$: Nullable<Subscription> = null;

    async removeGroup() {
      const result = await this.confirm(this.$t('components.ds_query_builder.delete_group_confirmation'), {
        title: this.$t('components.ds_query_builder.delete_group'),
        className: 'above-all-modals',
        buttons: {
          confirm: { label: this.$t('app.labels.delete'), className: 'btn-danger' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
      });
      if (result) this.group.remove();
    }

    beforeMount() {
      this.isExpandedShown$ = this.group.isExpandedShown$.subscribe((value) => {
        this.isExpandedShown = value;
      });
    }

    beforeDestroy() {
      this.isExpandedShown$?.unsubscribe();
    }
  }
