
  import QrCode from '@chenfengyuan/vue-qrcode';
  import DsModal from '@app/components/ds-modal.vue';
  import type { Subscription } from '@rails/actioncable';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import consumer from '../../../channels/consumer';
  import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
  import { MobileAppConfigProfile } from '@app/models/mobile-app/config';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { qrCodeLink } from '@app/utils/qr-code-link';

  @Component({ components: { QrCode, DsModal } })
  export default class ViewRegistrationPage extends Vue {
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Prop([String, Number]) readonly mobileAppRegistrationId!: string | number;

    registration: Nullable<MobileAppRegistration> = null;
    registrationSubscription: Nullable<Subscription> = null;
    visible = true;

    get title(): string {
      if (this.registration?.mobile_app_config?.config?.profile === MobileAppConfigProfile.visitor_app) {
        return 'Visitor Sign in Point - Kiosk Device Registration';
      }
      return 'Kiosk Device Registration';
    }

    get downloadQrCodeLink(): string | undefined {
      return (
        this.registration?.registration_data &&
        qrCodeLink(this.registration.registration_data.registration_url, {
          output_format: 'png',
          download: true,
        })
      );
    }

    beforeMount(): void {
      const only: OnlyOptions<MobileAppRegistration> = ['name', 'registration_data', 'mobile_app_config', 'state'];
      this.$api.getMobileAppRegistration(this.mobileAppRegistrationId, { only }, { cache: true }).then(({ data }) => {
        this.registration = data;

        if (this.registration.state !== 'draft') {
          this.closeModal('dismiss');
        }
      });
    }

    beforeDestroy(): void {
      this.registrationSubscription?.unsubscribe();
    }

    mounted(): void {
      this.registrationSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'MobileApp::Registration' },
        {
          received: (data: { id: number; state: MobileAppRegistration['state'] }) => {
            if (`${data.id}` === `${this.mobileAppRegistrationId}` && data.state === 'registered') {
              this.closeModal('dismiss');
            }
          },
        }
      );
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }
  }
