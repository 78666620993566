import bootbox from 'bootbox';
import I18n from '../../i18n';

export const showArchiveConfirmation = (): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    bootbox.confirm({
      backdrop: false,
      size: 'small',
      message: I18n.t('app.labels.role_profile_confirm_archive'),
      buttons: {
        confirm: { label: I18n.t('app.buttons.confirm'), className: 'btn-success' },
        cancel: { label: I18n.t('app.buttons.cancel'), className: 'btn-default' },
      },
      callback: (result: boolean) => {
        return resolve(result);
      },
    });
  });
};
