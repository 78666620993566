
  import LocationFilterConfigurator from '@app/components/admin/questions/filter-configurator/location-filter-configurator.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { LocationQuestionOptions } from '@app/models/question-options/location-question-options';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  @Component({
    components: { LocationFilterConfigurator, DsCheckbox },
  })
  export default class LocationFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<LocationQuestionOptions>;
    @Prop(Object) subForm!: SubForm;

    multiLevelChanged(value: StringBoolean): void {
      if (value === 'true') {
        this.value.config.filters = [];
      }
    }

    get isMultiLevelSelect(): boolean {
      return this.value.config.multi_level === 'true';
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['multi_level', 'scope_to_current_user', 'required_bottom', 'filters'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
