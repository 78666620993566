import type { ACTIVITY_CONCEPT } from '@app/constants';
import type { AdminPermissionFeature } from '@app/models/admin-permission';

export interface ModuleAcl {
  create: boolean;
  hide_on_main_menu?: boolean;
  hide_quick_add_button?: boolean;
  mha: boolean;
  view: boolean;
}

export interface Acl {
  // TODO: somehow refactor this
  global?: Record<string | typeof ACTIVITY_CONCEPT, { view: boolean }>;
  manageable_admin_features?: AdminPermissionFeature[];
  module?: Record<string | typeof ACTIVITY_CONCEPT, ModuleAcl>;
}

export const TECHNICAL_ADMIN_EMAIL = 'admin@donesafe.com';
