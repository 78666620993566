
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import OrganizationSelector from '../../organization/organization-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Organization } from '@app/models/organization';

  @Component({ components: { OrganizationSelector, ValidationObserver, ValidationProvider } })
  export default class AdminSettingsCostCentreForm extends Vue {
    @Prop(Object) readonly organization?: Partial<Organization>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Organization> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', { ...this.form, organization_id: this.form.organization_id || null });
      });
    }

    beforeMount(): void {
      this.form = { ...this.organization };
    }
  }
