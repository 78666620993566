
  import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import type { ConfiguratorFilterValue } from '@app/models/configurator-filter';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import { isEmpty } from 'lodash';

  import Select2 from '../../../../select2.vue';
  import type { FilterMode, FilterOption, MatchingMode, SimpleOption } from '../model';

  import FilterConfiguratorItemValue from './filter-configurator-item-value.vue';
  import FilterConfiguratorItemRequired from './filter-configurator-item-required.vue';

  @Component({
    components: {
      Select2,
      FilterConfiguratorItemValue,
      FilterConfiguratorItemRequired,
    },
  })
  export default class FilterConfiguratorItem extends Vue {
    @Model('input') readonly value!: FilterOption;
    @Ref() readonly filterConfiguratorItemValue!: FilterConfiguratorItemValue;

    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly hasStickyFilters?: boolean;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly isMobileApp?: boolean;
    @Prop(Boolean) readonly invertDisabled?: boolean;
    @Prop(Boolean) readonly noReverse?: boolean;
    @Prop(String) readonly valueColumnClass!: string;
    @Prop(Object) readonly onlyFilters?: object;

    get optionInvertDisabled(): boolean {
      return !!this.option?.invertDisabled && this.mode !== 'range';
    }

    get validModes(): FilterMode[] {
      const modes: FilterMode[] = [
        {
          mode: 'explicit',
          name: this.value.type === 'text' ? 'Equals' : 'Equals/Includes',
        },
        {
          mode: 'dynamic',
          source: ConfiguratorFilterSource.question_id,
          name: 'Form values',
        },
        {
          mode: 'dynamic_code',
          source: ConfiguratorFilterSource.question_code,
          name: 'Form values (Code Matched)',
        },
        {
          mode: 'record',
          source: ConfiguratorFilterSource.current_record,
          name: 'Main record attributes',
        },
        {
          mode: 'completion',
          source: ConfiguratorFilterSource.current_completion,
          name: 'Sub Form Completion attributes',
        },
        {
          mode: 'user',
          source: ConfiguratorFilterSource.current_user,
          name: 'Current user attributes',
        },
        {
          mode: 'registration_default',
          source: ConfiguratorFilterSource.registration_default,
          name: 'Registration default value',
        },
        { mode: 'range', source: ConfiguratorFilterSource.range, name: 'Range' },
      ];
      return modes.filter(this.isModeValid);
    }

    get option(): FilterOption {
      return this.value;
    }

    get mode(): MatchingMode {
      return this.validModes.find((x) => x.source === this.value.source)?.mode || 'explicit';
    }

    get modeSelectionDisabled(): boolean {
      return (
        this.mode === 'explicit' &&
        !this.option.dynamicOptions?.length &&
        !this.option.userOptions?.length &&
        !this.option.recordOptions?.length &&
        !this.option.completionOptions?.length &&
        !this.option.dynamicCodeOptions?.length &&
        !this.availableModesKeys.includes(ConfiguratorFilterSource.range)
      );
    }

    get availableModesKeys(): string[] {
      return this.validModes.map((x) => x.mode);
    }

    get availableModes(): [string, string][] {
      return this.validModes.map((x) => [x.mode as string, x.name]);
    }

    @Emit('input')
    onInputUpdate(value: FilterOption): FilterOption {
      return value;
    }

    @Emit('remove')
    remove(): FilterOption {
      return this.value;
    }

    @Emit('input')
    onInvertInput(invert: boolean): FilterOption {
      return { ...this.value, invert: `${invert}` };
    }

    @Emit('input')
    onModeChange(mode: MatchingMode): FilterOption {
      let value: ConfiguratorFilterValue = '';
      let source: ConfiguratorFilterSource | undefined;
      switch (mode) {
        case 'dynamic':
          source = ConfiguratorFilterSource.question_id;
          break;
        case 'record':
          source = ConfiguratorFilterSource.current_record;
          break;
        case 'completion':
          source = ConfiguratorFilterSource.current_completion;
          break;
        case 'user':
          source = ConfiguratorFilterSource.current_user;
          break;
        case 'dynamic_code':
          source = ConfiguratorFilterSource.question_code;
          break;
        case 'registration_default':
          source = ConfiguratorFilterSource.registration_default;
          break;
        case 'range':
          source = ConfiguratorFilterSource.range;
        default:
          value = [];
          break;
      }
      return { ...this.value, key: this.value.key, value, source };
    }

    isInvalidFilter(option: FilterOption): boolean {
      return !!option.invalid || this.hasOptionKeyError(option);
    }

    hasOptionKeyError(option: FilterOption): boolean {
      if (option && !isEmpty(this.onlyFilters)) {
        return !Object.keys(this.onlyFilters).includes(option.key);
      }

      return false;
    }

    onRangeRequiredChange({ value, index }: { index: number; value: StringBoolean }): void {
      const ref = this.filterConfiguratorItemValue?.dateRangeFilterValueRow?.[`${index}`];
      if (ref) ref.onValueUpdate(value, 'required');
    }

    dynamicOptions(mode: MatchingMode): SimpleOption[] {
      switch (mode) {
        case 'dynamic':
          return this.option.dynamicOptions || [];
        case 'dynamic_code':
          return this.option.dynamicCodeOptions || [];
        case 'user':
          return this.option.userOptions || [];
        case 'record':
          return this.option.recordOptions || [];
        case 'completion':
          return this.option.completionOptions || [];
        case 'registration_default':
          return this.option.dynamicCodeOptions || [];
        default:
          return [];
      }
    }

    isModeValid(mode: FilterMode): boolean {
      if (this.isMobileApp) {
        return mode.mode === 'explicit' || mode.mode == 'registration_default';
      } else {
        return (
          mode.mode === 'explicit' ||
          (mode.mode !== 'registration_default' && !!this.dynamicOptions(mode.mode).length) ||
          (mode.mode === 'range' && ['date', 'datetime'].includes(this.option.type))
        );
      }
    }
  }
