import { isEmpty, omit, pick } from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';
import type { InjectableArguments } from '@app/services/api/utils-api';
import bootbox from 'bootbox';
import type { AutomationDefinition } from '@app/models/automation-definition';
import type { Event } from '@app/models/event';
import { ruleSetChanged } from '@app/utils/rule-set-changed';
import { toaster } from '@app/utils/toaster';
import type { AutomationDefinitionExtraParameters } from '@app/services/api/automation-definition-api';
import type { ValidationObserver } from 'vee-validate';
import type { AutomationEventGroup } from '@app/models/automation-event-group';
import type { QueryBuilderBlob } from '@app/models/query-builder-blob';

import type AutomationDefinitionForm from './automation-definition-form.vue';
import type { RedirectionMode } from './models';

export type EventWithTempId = Omit<Event, 'id'> & {
  automation_event_group_id: number | string | null;
  collection_rules?: Nullable<QueryBuilderBlob>;
  id: string | number;
  index: number;
};

export type GroupWithTempId = Omit<AutomationEventGroup, 'id' | 'parent_id'> & {
  id: string | number;
  index?: number;
  items: GroupOrEvent[];
  parent_id: number | string | null;
};

export type GroupOrEvent = GroupWithTempId | EventWithTempId;

type RuleBuilderRef = {
  ruleBuilderPanel: {
    builder: JQuery<HTMLElement>;
  };
};

@Component
export default class WithAutomationSubmit extends Vue {
  @Ref() readonly form!: AutomationDefinitionForm & {
    createObjectEventForm: RuleBuilderRef & { showRuleBuilder: boolean };
    formValidator: InstanceType<typeof ValidationObserver>;
  };

  events: Partial<EventWithTempId>[] = [];
  groups: Partial<GroupWithTempId>[] = [];
  submitting = false;
  invalidEventForm: number[] = [];

  get confirmActivationMessage(): string {
    return [
      this.$t('tenant.admin.automation_definitions.edit.proceed_even_if_disabled'),
      this.$t('tenant.admin.automation_definitions.edit.triggering_rules_have_changed'),
      this.$t('tenant.admin.automation_definitions.edit.are_you_sure'),
    ].join('\n\n');
  }

  async validateForm(): Promise<boolean> {
    return new Promise((resolve) =>
      this.form.formValidator
        .validate()
        .then((valid) => {
          resolve(valid);
        })
        .catch(() => {
          resolve(false);
        })
    );
  }

  confirmActivation(
    ad: Partial<AutomationDefinition> & AutomationDefinitionExtraParameters,
    opts: {
      mode?: RedirectionMode;
      onSubmitComplete: (ad: AutomationDefinition, mode?: RedirectionMode) => void;
    }
  ): void {
    const { mode, onSubmitComplete } = opts;

    bootbox.confirm({
      size: 'medium',
      backdrop: false,
      message: this.confirmActivationMessage,
      buttons: {
        confirm: { label: this.$t('app.buttons.save_and_continue'), className: 'btn-success' },
        cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
      },
      callback: (result: boolean) => {
        result
          ? this.updateAutomation(ad, {
              mode,
              onSubmitComplete,
            })
          : (this.submitting = false);
      },
    });
  }

  updateAutomation(
    updateAutomationDefinition: Partial<AutomationDefinition>,
    opts: {
      mode?: RedirectionMode;
      onSubmitComplete: (ad: AutomationDefinition, mode?: RedirectionMode) => void;
    }
  ): void {
    const { mode, onSubmitComplete } = opts;

    !!updateAutomationDefinition.id &&
      this.$api
        .updateAutomationDefinition(updateAutomationDefinition.id, {
          ...updateAutomationDefinition,
          include: ['display', 'event_type', 'events', 'rule_set'],
        })
        .then(({ data }) => {
          onSubmitComplete(data, mode);
        })
        .catch(({ data }) => {
          this.submitting = false;
          toaster({ text: data.error, icon: 'error' });
        });
  }

  constructEntity(
    entity: Partial<AutomationDefinition> & AutomationDefinitionExtraParameters,
    events: Partial<EventWithTempId>[],
    injectableArguments: InjectableArguments = {}
  ): Partial<AutomationDefinition> & AutomationDefinitionExtraParameters {
    const cleanEvents = events.map((event) => {
      return { ...this.clearEvent(event), ...this.constructChainedEvents(event) } as Event;
    });

    const cleanGroups = this.groups.map((group) => {
      return { ...pick(group, ['id', 'name', 'parent_id']) };
    });

    return {
      ...pick(entity, [
        'name',
        'index',
        'trigger_when',
        'trigger_on',
        'triggering_fields',
        'rule_builder_rules',
        'id',
        'rule_set_id',
        'description',
      ]),
      events: cleanEvents,
      automation_event_groups: cleanGroups as AutomationEventGroup[],
      ...injectableArguments,
    };
  }

  clearEvent(event: Partial<EventWithTempId>): Partial<EventWithTempId> {
    const callToActionPathToRemove = isEmpty(event.options?.values?.call_to_action) ? 'options.values.call_to_action' : '';
    const cleanEvents = { ...event, id: typeof event.id === 'string' ? null : event.id };
    return omit(cleanEvents, 'created_at', 'updated_at', 'chained_events', callToActionPathToRemove) as Event;
  }

  constructChainedEvents(parentEvent: Partial<EventWithTempId>): { chained_events?: Event[] } {
    if (!parentEvent.chained_events?.[0]) return {};

    const event = parentEvent.chained_events[0];
    if (!event.event_type) return {};

    return { chained_events: [this.clearEvent(event) as Event] };
  }

  async submit(
    ad: Partial<AutomationDefinition> & AutomationDefinitionExtraParameters,
    events: Partial<EventWithTempId>[],
    opts: {
      injectableArguments?: InjectableArguments;
      mode?: RedirectionMode;
      onSubmitComplete: (ad: AutomationDefinition, mode?: RedirectionMode) => void;
    }
  ) {
    const { mode, onSubmitComplete, injectableArguments } = opts;
    this.submitting = true;
    const formValid = await this.validateForm();

    if (!formValid) {
      this.submitting = false;
      return;
    }

    const createUpdateAd = this.constructEntity(ad, events, injectableArguments);

    const oldRules = ad.rule_set?.query_builder_blob || null;
    const newRules = createUpdateAd.rule_builder_rules;

    const rulesChanged = ruleSetChanged(oldRules, newRules);

    if (rulesChanged && ad.id) {
      this.confirmActivation(createUpdateAd, { mode, onSubmitComplete });
    } else if (!rulesChanged && ad.id) {
      this.updateAutomation(createUpdateAd, { mode, onSubmitComplete });
    } else if (!ad.id) {
      this.$api
        .createAutomationDefinition(createUpdateAd)
        .then(({ data }) => onSubmitComplete(data, mode))
        .catch(({ data }) => {
          this.submitting = false;
          toaster({ text: data.error, icon: 'error' });
        });
    }
  }
}
