
  import { map } from 'lodash';
  import { Component } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ModuleName } from '@app/models/module-name';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import { HARDCODED_MODULE_CODES, RecordLinkBehaviour, BASE_FILTER_OPTIONS } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';

  import RecordFilterConfigurator from '../../questions/filter-configurator/record-filter-configurator.vue';
  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';

  import BaseWidgetSettings from './base-widget-settings';

  interface RecordIndexWidgetOptions {
    filters?: ConfiguratorFilter[];
    record_link_behaviour?: RecordLinkBehaviour;
  }

  @Component({
    components: { Select2, EntitySelector, RecordFilterConfigurator, ValidationObserver, ValidationProvider },
    methods: { getRecordLinkBehaviourOptions },
  })
  export default class FollowedRecordsWidgetSettings extends BaseWidgetSettings<RecordIndexWidgetOptions> {
    get availableIndexOptions(): [string, string][] {
      return map(BASE_FILTER_OPTIONS, (option, key) => [key, option.name]);
    }

    get moduleNameFilter(): DonesafeFilterOptions<ModuleName> {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES,
        show_options: { follow: true },
      };
    }

    get moduleNameIds(): string[] {
      return (this.form.filters?.find((filter) => filter.key === 'module_name_id')?.value as string[]) || [];
    }

    get recordConfiguratorFilters(): ConfiguratorFilter[] {
      return [...(this.form.filters || [])];
    }

    get selectedModuleNamesKey(): string {
      return this.moduleNameIds.join('-');
    }

    set recordConfiguratorFilters(filters: ConfiguratorFilter[]) {
      this.form.filters = [
        ...(filters || []).filter((f) => f.key !== 'module_name_id'),
        ...this.recordConfiguratorFilters.filter((f) => f.key === 'module_name_id'),
      ];
    }

    beforeMount(): void {
      this.form = {
        record_link_behaviour: RecordLinkBehaviour.GoToRecord,
        ...this.widget.options,
      };
      this.onModuleNameSelect(this.moduleNameIds);
    }

    onModuleNameSelect(ids: string[]): void {
      if (ids.length) {
        const newFilters = [...(this.form.filters || []).filter((f) => f.key !== 'module_name_id'), { key: 'module_name_id', value: ids }];
        this.form = { ...this.form, filters: newFilters };
      } else {
        this.form = { ...this.form, filters: [] };
      }
      this.$emit('update:options', this.form);
    }
  }
