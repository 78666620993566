
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { AutomationDefinitionsTable } from '@app/components';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { AutomationDefinitionState } from '@app/models/automation-definition';
  import type { Dictionary } from 'lodash';
  import { isEqual } from 'lodash';

  @Component({
    components: { AutomationDefinitionsTable },
  })
  export default class ModuleAutomationDefinitionsPage extends Vue {
    @Prop(Number) readonly moduleNameId!: number;

    moduleNameName: Nullable<string> = null;

    newLink(type: string, injectableArguments?: InjectableArguments) {
      const query = { ...injectableArguments, type, redirect_to: window.location.href };
      return {
        name: 'admin-module-automation-definitions-new',
        query: query,
      };
    }

    editLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-module-automation-definitions-edit');
    }

    cloneLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-module-automation-definitions-clone');
    }

    logLink(automationDefinition: AutomationDefinition) {
      return this.link(automationDefinition, 'admin-module-automation-definitions-event-occurrences');
    }

    link(automationDefinition: AutomationDefinition, name: string) {
      return {
        name,
        params: { automationDefinitionId: String(automationDefinition.id) } as Record<string, string>,
        query: { redirect_to: window.location.href } as Record<string, string>,
      };
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(this.moduleNameId, { only: ['id', 'name'] }, { cache: true });

      const paramsToAdd = [
        ['tab', [data.name]],
        ['state', [AutomationDefinitionState.active, AutomationDefinitionState.activating]],
      ];

      const filters = (this.$route.query.filters as unknown as Record<string, string[]>) || {};
      const newFilters = paramsToAdd.reduce((acc, [key, value]) => {
        if (!acc[key as string]) {
          acc[key as string] = value as string[];
        }

        return acc;
      }, filters);
      const newQuery = { ...this.$route.query, filters: newFilters } as unknown as Dictionary<string>;

      if (!isEqual(this.$route.query, newQuery)) {
        await this.$router.replace({ query: newQuery });
      }

      this.moduleNameName = data.name;
    }
  }
