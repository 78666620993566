
  import OrganizationFilterConfigurator from '@app/components/admin/questions/filter-configurator/organization-filter-configurator.vue';
  import Select2 from '@app/components/select2.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { OrganizationQuestionOptions } from '@app/models/question-options/organization-question-options';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  @Component({ components: { FormField, Select2, DsCheckbox, OrganizationFilterConfigurator } })
  export default class OrganizationFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<OrganizationQuestionOptions>;
    @Prop(Object) readonly subForm!: SubForm;

    costCentreOptions: [string, string][] = [
      ['all', 'All'],
      ['cost_centre', 'Only Cost Centres'],
      ['no_cost_centre', 'No Cost Centres'],
    ];

    multiLevelChanged(value: StringBoolean): void {
      if (value === 'true') {
        this.$$patch(this.value.config, { filters: [] });
      }
    }

    beforeMount(): void {
      this.$$patch(this.value.config, { organization_type: 'all' }, { ifEmpty: true });
    }

    get isMultiLevelSelect(): boolean {
      return this.value.config.multi_level === 'true';
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['multi_level', 'scope_to_current_user', 'required_bottom', 'organization_type', 'filters'].forEach((key) =>
        this.$delete(this.value.config, key)
      );
    }
  }
