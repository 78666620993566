
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import Select2 from '../select2.vue';
  import bootbox from 'bootbox';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import type { ScoringGroup } from '@app/models/scoring-group';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  const DEFAULT_DISPLAY_TYPES = [
    { label: 'fraction', value: 'fraction' },
    { label: 'percent', value: 'percent' },
  ];
  type DisplayOption = { label: string; value: string };

  @Component({ components: { DsModal, Select2, ValidationObserver, ValidationProvider } })
  export default class ScoringGroupFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ type: [String, Number] }) readonly scoringGroupId!: number | string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<ScoringGroup> = {};
    visible = true;
    scoringGroup: Partial<ScoringGroup> = {};
    activeScoreBandProfiles: Partial<ScoreBandProfile>[] = [];
    moduleName: Nullable<ModuleName> = null;

    get formTitle(): string {
      if (this.$route?.params?.scoringGroupId) {
        return this.$t('tenant.admin.scoring_groups.edit.form_title');
      } else {
        return this.$t('tenant.admin.scoring_groups.new.form_title');
      }
    }

    get showForm(): boolean {
      return !!this.scoringGroup.module_name;
    }

    changeDisplayMethod(): void {
      if (this.form.score_band_profile_id) {
        this.form = { ...this.form, display_method: 'score_band' };
      } else if (this.form.display_method === 'score_band') {
        this.form = { ...this.form, display_method: 'fraction' };
      }
    }

    get displayTypes(): DisplayOption[] {
      if (this.form.score_band_profile_id) {
        const selectedScoreBandProfile = this.activeScoreBandProfiles.find(
          (band_profile) => `${band_profile.id}` === `${this.form.score_band_profile_id}`
        );
        return [...DEFAULT_DISPLAY_TYPES, ...[{ label: `Banding Profile: ${selectedScoreBandProfile?.name}`, value: 'score_band' }]];
      } else {
        return DEFAULT_DISPLAY_TYPES;
      }
    }

    createUpdateScoringGroup(scoringGroup: Partial<ScoringGroup>): void {
      const promise = scoringGroup.id
        ? this.$api.updateScoringGroup(scoringGroup.id, scoringGroup)
        : this.$api.createScoringGroup(scoringGroup);

      promise
        .then(({}) => {
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    archive(): void {
      const message = this.$t('tenant.admin.scoring_groups.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.createUpdateScoringGroup({ ...this.scoringGroup, active: false });
          }
        },
      });
    }

    restore(): void {
      const message = this.$t('tenant.admin.scoring_groups.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.createUpdateScoringGroup({ ...this.scoringGroup, active: true });
          }
        },
      });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.createUpdateScoringGroup({
            ...this.form,
            score_band_profile_id: this.form.score_band_profile_id || undefined,
          });
        }
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-name-scoring-scoring-groups',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    getScoreBandProfiles(): void {
      this.$api
        .getScoreBandProfiles(
          {
            only: ['id', 'name'],
            filters: {
              module_name_id: this.moduleNameId,
              active: true,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.activeScoreBandProfiles = data;
        });
    }

    initForm(): void {
      this.form = { ...this.scoringGroup };
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true });
      this.moduleName = data;

      this.getScoreBandProfiles();
      if (this.scoringGroupId) {
        this.$api.getScoringGroup(this.scoringGroupId).then(({ data }) => {
          this.scoringGroup = data;
          this.initForm();
        });
      } else {
        this.scoringGroup = { module_name: this.moduleName.name };
        this.initForm();
      }
    }
  }
