import type DashboardPane from '@app/models/dashboard-pane';
import type { ConfiguratorFilter, BaseConfiguratorFilter } from '@app/models/configurator-filter';
import type { StringBoolean } from '@app/utils/types/string-boolean';

import type { BaseEntity } from './base-entity';
import type { WithSourceUuidAndUuid } from './mixins';
import type { RecordLinkBehaviour } from './module-name';

export enum WidgetType {
  actions = 'actions',
  domo = 'domo',
  followed_records = 'followed_records',
  iframe = 'iframe',
  image = 'image',
  link = 'link',
  record_index = 'record_index',
  report_count = 'report_count',
  report_data = 'report_data',
  report_graph = 'report_graph',
  sub_form_index = 'sub_form_index',
  text = 'text',
  video = 'video',
}

export interface Widget<T = object> extends BaseEntity, WithSourceUuidAndUuid {
  dashboard_id: Nullable<number>;
  dashboard_pane?: DashboardPane;
  dashboard_pane_id: Nullable<number>;
  height: number;
  options: T;
  title: string;
  translated_options: WidgetTranslatedOptions;
  widget_type: WidgetType;
  width: number;
  x: number;
  y: number;
}

export enum DomoFilterOperator {
  BETWEEN = 'BETWEEN',
  EQUALS = 'EQUALS',
  GREAT_THAN = 'GREAT_THAN',
  GREAT_THAN_EQUALS_TO = 'GREAT_THAN_EQUALS_TO',
  IN = 'IN',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUALS_TO = 'LESS_THAN_EQUALS_TO',
  NOT_EQUALS = 'NOT_EQUALS',
}

export enum DomoFilterEmptyBehaviourOption {
  include = 'include',
  skip = 'skip',
}

export interface DomoFilter {
  column: string;
  empty_behaviour: DomoFilterEmptyBehaviourOption;
  operand: DomoFilterOperator;
  values: string[];
}

export const DOMO_CHART_FEATURES = {
  title: 'Title',
  summary: 'Summary',
  drill: 'Drill',
  table: 'Table',
  picker: 'Picker',
  data_export: 'Data Export',
  filter: 'Filter',
};

export type DomoChartFeature = keyof typeof DOMO_CHART_FEATURES;

export interface DomoWidgetOptions {
  embed_id?: string;
  embed_style?: 'legacy' | 'private';
  embed_type?: 'card' | 'dashboard';
  features?: DomoChartFeature[];
  filters?: DomoFilter[];
  url: string;
}

export interface RecordIndexWidgetOptions {
  allow_custom_filters?: StringBoolean;
  available_index_options?: string[];
  filters?: ConfiguratorFilter[];
  record_link_behaviour?: RecordLinkBehaviour;
  reverse?: StringBoolean;
  sort?: string;
  use_history?: StringBoolean;
}

export enum ReportCountTargetDifference {
  difference = 'difference',
  total = 'total',
}

export interface ReportCountWidgetOptions {
  background_color?: string;
  icon?: string;
  overrides?: {
    arrow_negative_style?: string;
    arrow_parity_style?: string;
    arrow_positive_style?: string;
    percentage_style?: string;
    title_style?: string;
    value_style?: string;
    widget_content_style?: string;
  };
  super_report_id: string;
  target_difference?: ReportCountTargetDifference;
  text_color?: string;
}

export interface ReportGraphWidgetOptions {
  super_report_id: string;
}

export interface IframeWidgetOptions {
  src_url: string;
}

export interface ImageWidgetOptions {
  link_url: string;
}

export interface VideoWidgetOptions {
  video_url: string;
}

export enum TextWidgetAlignment {
  center = 'center',
  left = 'left',
  right = 'right',
}

export enum TextWidgetFontSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum TextWidgetFontWeight {
  bold = 'bold',
  normal = 'normal',
}

export interface TextWidgetOptions {
  alignment: TextWidgetAlignment;
  font_size: TextWidgetFontSize;
  font_weight: TextWidgetFontWeight;
  overrides?: {
    text_style?: string;
  };
}

export interface ReportDataWidgetOptions {
  super_report_id: string;
}

export interface LinkWidgetOptions {
  background_color?: string;
  link_url?: string;
  show_border?: StringBoolean;
  target?: string;
  text_color?: string;
}

export enum ActionSortOptions {
  assignedTo = 'assigned_to',
  closedAt = 'closed_at',
  createdAt = 'created_at',
  date = 'date',
  description = 'description',
  id = 'id',
  priority = 'priority',
}

export interface ActionWidgetOptions {
  filters?: BaseConfiguratorFilter[];
  reverse?: StringBoolean;
  show_border?: StringBoolean;
  sort?: ActionSortOptions;
}

export interface SubFormIndexWidgetOptions {
  reverse?: StringBoolean;
  show_border?: StringBoolean;
  sort?: string;
  sub_form_id?: number | string;
  sub_form_list_id?: number | string;
}

export interface SubFormIndexWidgetOptions {
  reverse?: StringBoolean;
  show_border?: StringBoolean;
  sort?: string;
  sub_form_id?: number | string;
  sub_form_list_id?: number | string;
}

export interface WidgetTranslatedOptions {
  text?: string;
}
