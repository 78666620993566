
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import type { Components, Rules, Variables } from '../models/types';
  import ActionIcons from './action-icons.vue';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ components: { ActionIcons } })
  export default class ElementItem extends Vue {
    @Prop({ required: true }) readonly value!: string;
    @Prop() readonly prefix?: string;
    @Prop({ required: true }) readonly elementsObject!: Rules | Components | Variables | null;
    @Prop() readonly id?: number | string;
    @Prop() readonly index?: number;
    @Prop() readonly canDrag?: boolean;
    @Prop() readonly isDisplayItem?: boolean;
    @Prop() readonly allowDisplayIcon?: boolean;

    mouseOverElement = false;

    toggleMouseOverElement(state: boolean): void {
      this.mouseOverElement = state;
    }

    get elementItemStyles(): Dictionary<string> {
      const style: Dictionary<string> = {};
      if (!this.canDrag) style.cursor = 'default';
      if (this.value === 'none') style.color = 'grey';
      const color = this.elementsObject?.[this.value]?.color;
      if (color) style.backgroundColor = color;
      return style;
    }

    get elementItemText(): string {
      const name = this.elementsObject?.[this.value]?.name;
      return (this.prefix || '') + (name || '');
    }

    get showDisplayIcon(): boolean {
      return !!this.allowDisplayIcon && (this.mouseOverElement || !!this.isDisplayItem);
    }
  }
