
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ModuleRenameFormModal from './module-rename-form-modal.vue';
  import type { ModuleName } from '@app/models/module-name';

  @Component({ components: { Vue, ModuleRenameFormModal } })
  export default class ModuleSystemNameFormGroup extends Vue {
    @Model('input') readonly disabled!: boolean;
    @Prop(Object) readonly moduleName!: ModuleName;

    form: Partial<ModuleName> = {};
    openModal = false;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get systemNameClass(): string {
      return this.showChangeLink ? 'col-sm-6' : 'col-sm-8';
    }

    get showChangeLink(): boolean {
      return this.currentUserStore.isDefaultTechAdmin && !!this.form.id;
    }

    beforeMount(): void {
      this.initForm();
    }

    initForm(): void {
      this.form = { ...this.moduleName };
    }
  }
