
  import DsLabel from '@app/components/ds-label.vue';
  import WithAdminPermissions from '@app/mixins/with-admin-permissions';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import type { AdminAccessFlag, ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { Component, Ref, Mixins } from 'vue-property-decorator';
  import type { AxiosPromise } from 'axios';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { ListManagerFetchDataParams } from '@app/services/list-manager/types';
  import { toaster } from '@app/utils/toaster';
  import { Tooltip } from 'uiv';
  import { changeLocation } from '@app/utils/change-location';

  import { BaseTable } from '../../base-table';

  import FormModal from './form-modal.vue';

  type FeatureOption = { label: string; value: string };

  @Component({ components: { DsLabel, Select2, FilterSelect, FormModal, BaseTable, Tooltip } })
  export default class AdminAdministrativeRoles extends Mixins(WithAdminPermissions) {
    @Ref() readonly table?: BaseTable<TenantUser>;

    tenantUser: Nullable<TenantUser> = null;
    modalVisible = false;
    manager: Nullable<ListManager<TenantUser, ExtraUserApiOptions>> = null;

    activeOptions = [
      ['true', this.$t('app.labels.active')],
      ['false', this.$t('app.labels.inactive')],
    ];

    get manageableAdminFeatures(): AdminPermissionFeature[] {
      return Object.values(AdminPermissionFeature).filter((feature) => this.permissionCheck(feature));
    }

    get featureOptions(): FeatureOption[] {
      return this.formatFeatureOptions(this.defaultFeatureFilters);
    }

    get defaultFeatureFilters(): AdminAccessFlag[] {
      if (this.showTechAdminLinks) {
        return ['admin', ...Object.values(AdminPermissionFeature)];
      }

      return this.manageableAdminFeatures;
    }

    get showTechAdminLinks(): boolean {
      return this.currentUserStore.isTechAdmin;
    }

    permissionCheck(feature: AdminPermissionFeature): boolean {
      if (this.moduleConfigDisabledForNonTechAdmins(feature)) {
        return false;
      } else {
        return this.currentUserStore.isAdminPermissionManageable(feature);
      }
    }

    onSubmit(form: Partial<TenantUser>): void {
      form?.id &&
        this.$api
          .updateTenantUser(form.id, form)
          .then(() => {
            this.$api.cache.clear();
            this.closeModal();
            // refresh if changing current user's features
            if (`${form?.id}` === `${this.currentUserStore.data?.id}`) {
              changeLocation('/admin/settings/administrative_roles');
            } else {
              this.table?.reload();
            }
          })
          .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
    }

    onEdit(tenantUser: TenantUser): void {
      this.tenantUser = { ...tenantUser };
      this.modalVisible = true;
    }

    onCreate(): void {
      this.tenantUser = null;
      this.modalVisible = true;
    }

    closeModal(): void {
      this.tenantUser = null;
      this.modalVisible = false;
    }

    featuresCell(row: Partial<TenantUser>): string[] {
      let features = row.admin_permission_features || [];

      if (!this.accountStore.moduleConfigForNonTechAdmins) {
        features = features.filter((feature) => feature !== AdminPermissionFeature.module_config);
      }
      return features.filter((feature) => this.permissionCheck(feature)).map((feature) => this.adminPermissionNames[feature] || '');
    }

    formatFeatureOptions(features: AdminAccessFlag[]): FeatureOption[] {
      return features.map((feature) => ({
        label: feature === 'admin' ? this.$t('app.labels.technical_admin') : this.adminPermissionNames[feature],
        value: feature,
      }));
    }

    beforeMount(): void {
      this.manager = new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params: ListManagerFetchDataParams<TenantUser, ExtraUserApiOptions>): AxiosPromise<TenantUser[]> =>
          this.$api.getTenantUsers(
            {
              ...params,
              only: ['id', 'full_name', 'admin_permission_features', 'internal', 'admin', 'active'],
              filters: {
                ...params.filters,
                admin_flags: params.filters?.admin_flags?.length ? params.filters?.admin_flags : this.defaultFeatureFilters,
              },
            },
            { cache: true }
          ),
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        fields: [
          {
            title: this.$t('tenant.admin.settings.administrative_roles.table_heads.full_name'),
            name: 'full_name',
            sortField: 'full_name',
          },
          {
            title: this.$t('app.labels.ID'),
            name: 'id',
            sortField: 'id',
          },
          {
            title: this.$t('tenant.admin.settings.administrative_roles.table_heads.permissions'),
            name: 'admin_flags',
            filter: true,
          },
          {
            title: this.$t('app.labels.active'),
            name: 'active',
            sortField: 'active',
            filter: true,
          },
        ],
        allowFilters: true,
      });
    }
  }
