
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  interface CompanyRegisterQuestionOptions extends BaseQuestionOptions {
    all_fields: StringBoolean;
    service_category_id: number;
  }

  @Component({
    components: { FormField, DsCheckbox, EntitySelector },
  })
  export default class CompanyRegisterFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<CompanyRegisterQuestionOptions>;

    beforeMount() {
      this.$$patch(this.value.config, { all_fields: 'true' }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['service_category_id', 'all_fields'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
