
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import IndicatorSetForm from '../../../components/admin/indicator-sets/indicator-set-form.vue';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { IndicatorSetForm } })
  export default class IndicatorSetNew extends Vue {
    @Ref() readonly form?: IndicatorSetForm;

    indicatorSet: Nullable<IndicatorSet> = null;
    visible = true;

    beforeMount(): void {
      this.$api
        .getIndicatorSet(
          Number(this.$route.params.indicatorSetId),
          {
            only: ['active', 'id', 'system_code', 'multi', 'name', 'hidden', 'display_only', 'hide_title'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.indicatorSet = data;
        });
    }
    onFormSubmit(indicatorSet: IndicatorSet): void {
      this.updateIndicatorSet(indicatorSet);
    }

    updateIndicatorSet(indicatorSet: IndicatorSet): void {
      this.$api
        .updateIndicatorSet(indicatorSet.id, indicatorSet)
        .then(() => {
          this.$api.cache.clear();
          toaster(this.$t('tenant.admin.indicator_sets.update'));
          this.$router.replace({
            name: 'admin-indicator-set-show',
            query: this.$route.query,
          });
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
