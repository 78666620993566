
  import { moduleRecordPath } from '@app/services/helpers';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
  import { uniqBy, sortBy } from 'lodash';
  import type { Activity } from '@app/models/activity';
  import type { Indicator } from '@app/models/indicator';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleTab } from '@app/models/module-tab';
  import type { Relation } from '@app/models/relation';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { Comment } from '@app/models/comment';
  import type { PrintConfigOptions } from '@app/models/print-configuration';
  import Blocking from '@app/mixins/blocking';
  import PrintIndicators from '@app/components/module-record/print/print-indicators.vue';
  import PrintInvolvements from '@app/components/module-record/print/print-involvements.vue';
  import PrintActivities from '@app/components/module-record/print/print-activities.vue';
  import PrintRelatedTo from '@app/components/module-record/print/print-related-to.vue';
  import PrintCompletions from '@app/components/module-record/print/print-completions.vue';
  import PrintComments from '@app/components/module-record/print/print-comments.vue';
  import PrintAttachments from '@app/components/module-record/print/print-attachments.vue';
  import type {
    PrintActivitiesOnly,
    PrintModuleNameOnly,
    PrintModuleTabsOnly,
    PrintRecord,
    PrintRelatedOnly,
    PrintCompletionOnly,
  } from '@app/components/module-record/print/utils';
  import {
    PRINT_COMPLETION_ONLY,
    PRINT_RECORD_ONLY,
    PRINT_ACTIVITIES_ONLY,
    PRINT_RELATION_ONLY,
  } from '@app/components/module-record/print/utils';
  import type { StringBoolean } from '@app/utils/types/string-boolean';

  import SubFormCompletionShow from '../../components/sub-form-completion/sub-form-completion-show.vue';
  import QrCodePanel from '../../components/module-record/qr-code-panel.vue';

  const ATTACHMENT_PREVIEW_OPTIONS = { url_options: { resize_to_fit: [600, 400] } };

  @Component({
    components: {
      QrCodePanel,
      SubFormCompletionShow,
      PrintIndicators,
      PrintInvolvements,
      PrintActivities,
      PrintRelatedTo,
      PrintCompletions,
      PrintComments,
      PrintAttachments,
    },
  })
  export default class ModuleRecordPrintPage extends Mixins(Blocking) {
    @Prop(String) readonly recordId!: string;

    record: Nullable<PrintRecord> = null;
    recordType = 'ModuleRecord';

    moduleName: Nullable<Pick<ModuleName, PrintModuleNameOnly>> = null;
    moduleTabs: Pick<ModuleTab, PrintModuleTabsOnly>[] = [];
    indicatorSets: IndicatorSet[] = [];
    recordIndicators: Indicator[] = [];
    involvements: Involvement[] = [];
    activities: Pick<Activity, PrintActivitiesOnly>[] = [];
    relations: Pick<Relation, PrintRelatedOnly>[] = [];
    comments: Comment[] = [];
    subFormCompletions: Pick<SubFormCompletion, PrintCompletionOnly>[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get printConfig() {
      return this.$route.query as unknown as {
        options: Pick<PrintConfigOptions, 'sub_form_list_ids'> & {
          [K in keyof Omit<PrintConfigOptions, 'sub_form_list_ids'>]: StringBoolean;
        };
      };
    }

    get accountStore() {
      return useAccountStore();
    }

    get subFormListIds(): number[] {
      return this.printConfig?.options?.sub_form_list_ids?.map(Number) || [];
    }

    get showScore() {
      return ['percent', 'fraction', 'score_band'].includes(this.moduleName?.show_options?.show_total_scoring || '');
    }

    get recordUrl() {
      return `${window.location.origin}/${moduleRecordPath(this.recordId)}`;
    }

    get recordTitle() {
      return this.$t('module_names.copy_link_modal.module_record', { id: this.record?.id });
    }

    @Watch('blocked')
    onIsComponentLoadingChanged(val: boolean) {
      if (!val) {
        let interval: Nullable<NodeJS.Timeout> = null;
        if ('onbeforeprint' in window) {
          window.addEventListener('beforeprint', () => {
            interval && clearInterval(interval);
            document.querySelectorAll('*').forEach((element) => {
              if (!(element instanceof HTMLElement)) return;

              const computedColor = window.getComputedStyle(element).color;
              element.style.setProperty('color', computedColor, 'important');
              element.style.setProperty('page-break-inside', 'auto', 'important');
            });
          });
        }

        interval = setInterval(() => {
          if (this.pageFullyLoaded()) {
            window.print();
          }
        }, 1000);
      }
    }

    printConfigOptions(option: keyof PrintConfigOptions) {
      return this.printConfig.options?.[option] === 'true';
    }

    getIndicatorSets(moduleName: string) {
      return this.$api
        .getIndicatorSets({ per_page: -1, filters: { module_name: moduleName }, include: ['indicators'], sort: 'index' })
        .then(({ data }) => {
          this.indicatorSets = data;
        });
    }

    getInvolvements(moduleName: string) {
      return this.$api
        .getInvolvements({
          filters: { module_name: moduleName, active: true },
          per_page: -1,
          sort: 'index',
        })
        .then(({ data }) => {
          this.involvements = data;
        });
    }

    getActivities() {
      return this.$api
        .getActivities({
          per_page: -1,
          filters: { actionable_id: this.recordId, actionable_type: this.recordType },
          only: PRINT_ACTIVITIES_ONLY,
          include: ['attachments', 'user', 'web_thumbnail_url'],
          ...ATTACHMENT_PREVIEW_OPTIONS,
        })
        .then(({ data }) => {
          this.activities = data;
        });
    }

    getRelations() {
      const fromPromise = this.$api.getRelations({
        filters: { from_id: this.recordId, from_type: this.recordType },
        only: PRINT_RELATION_ONLY,
        per_page: -1,
      });
      const toPromise = this.$api.getRelations({ filters: { to_id: this.recordId, to_type: this.recordType }, only: PRINT_RELATION_ONLY });

      return Promise.all([fromPromise, toPromise]).then(([fromRes, toRes]) => {
        this.relations = uniqBy([...(fromRes?.data || []), ...(toRes?.data || [])], (r) =>
          [`${r.from_id}_${r.from_type}`, `${r.to_id}_${r.to_type}`].sort((a, b) => a.localeCompare(b)).join('-')
        );
      });
    }

    getComments(): Promise<void> {
      return this.$api
        .getComments({
          filters: { commentable_id: this.recordId, commentable_type: this.recordType },
          include: ['user'],
          sort: '-created_at',
          per_page: -1,
        })
        .then(({ data }) => {
          this.comments = data;
        });
    }

    getSubFormCompletions() {
      return this.$api
        .getSubFormCompletions({
          filters: { record_id: this.recordId, record_type: this.recordType, sub_form_list_id: this.subFormListIds },
          only: PRINT_COMPLETION_ONLY,
          per_page: -1,
        })
        .then(({ data }) => {
          this.subFormCompletions = data;
        });
    }

    getDepsCalls(module_name?: string) {
      const promises = [];

      if (module_name) {
        this.printConfigOptions('indicators') && promises.push(this.getIndicatorSets(module_name));
        this.printConfigOptions('involvements') && promises.push(this.getInvolvements(module_name));
      }

      this.printConfigOptions('actions') && promises.push(this.getActivities());
      this.printConfigOptions('related_to') && promises.push(this.getRelations());
      this.printConfigOptions('comments') && promises.push(this.getComments());
      this.subFormListIds.length && promises.push(this.getSubFormCompletions());

      return promises;
    }

    getModuleTabs(moduleName: Pick<ModuleName, PrintModuleNameOnly>) {
      return sortBy(
        (moduleName?.module_tabs || []).filter((tab) => {
          const sflIds = tab?.sub_form_lists?.map(({ id }) => id);
          return tab.active && this.subFormListIds.filter((id) => sflIds?.includes(id)).length > 0;
        }),
        'index'
      );
    }

    deriveFromRecord(record: PrintRecord) {
      const { module_name: module = null, indicators = [] } = record;

      this.record = record;
      this.moduleName = module;
      this.recordIndicators = indicators;

      if (module) this.moduleTabs = this.getModuleTabs(module);
    }

    pageFullyLoaded() {
      return Array.from(document.querySelectorAll('img')).every((el) => el.complete) && document.querySelectorAll('.loader').length === 0;
    }

    beforeMount() {
      this.blocking(async () => {
        const { data: record } = await this.$api.getModuleRecord(
          Number(this.recordId),
          {
            only: PRINT_RECORD_ONLY,
            include: ['web_thumbnail_url'],
            ...ATTACHMENT_PREVIEW_OPTIONS,
          },
          { cache: true }
        );
        this.deriveFromRecord(record);
        await Promise.all(this.getDepsCalls(this.moduleName?.name));
      });
    }
  }
