
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type {
    AppBundleInstallationError,
    AppBundleInstallationErrors as AppBundleInstallationErrorsType,
  } from '@app/models/app-bundles/installation';
  import { AppBundleInstallationErrorReason } from '@app/models/app-bundles/installation';
  import { startCase } from 'lodash';

  @Component
  export default class AppBundleInstallationErrors extends Vue {
    @Prop(Object) installationErrors!: AppBundleInstallationErrorsType;

    generateReason(error: AppBundleInstallationError): string {
      const { id, question, code, title, name, file_name: fileName } = error.record || {};

      const sanitize = (text: string | null) => text && this.$adminSanitize(text, { ALLOWED_TAGS: [] });

      switch (error.reason) {
        case AppBundleInstallationErrorReason.question_field_type_skip:
          return `Question "${sanitize(question)}" was skipped.`;
        case AppBundleInstallationErrorReason.response_field_type_skip:
          const { sub_form_question_code } = error.record || {};
          return `Response for Question "${sanitize(sub_form_question_code)}" was skipped.`;
        case AppBundleInstallationErrorReason.attachment_skip:
          return `Attachment "${fileName}" was skipped`;
        case AppBundleInstallationErrorReason.question_config_filters:
          return `Please reconfigure filters for question "${sanitize(question)}" with code "${code}"`;
        case AppBundleInstallationErrorReason.question_config_index_options:
          return `Please reconfigure index options for question "${sanitize(question)}" with code "${code}"`;
        case AppBundleInstallationErrorReason.sub_form_list_filters:
          return `Please reconfigure filters for tab section "${sanitize(title)}"`;
        case AppBundleInstallationErrorReason.sub_form_list_index_options:
          return `Please reconfigure index options for tab section "${sanitize(title)}"`;
        case AppBundleInstallationErrorReason.record_collection_rule_set_invalid:
          const sourceId = error.record.source_id;
          const model = startCase(error.record.model);
          return `${model} Collection #${sourceId} rules have not been properly converted. Please reconfigure the <a target="_blank" href="/admin/settings/collections/${sourceId}">'For Every' rule set</a>`;
        case AppBundleInstallationErrorReason.event_per_skip:
          if (id) {
            return `Please reconfigure automation <a target="_blank" href="/admin/settings/automation_definitions/${id}/edit">${sanitize(
              name
            )}</a>`;
          } else {
            return `Automation "${sanitize(name)}" was skipped`;
          }
        case AppBundleInstallationErrorReason.svg_library_template_not_copied:
          return `Question missing SVG Template <a target="_blank" href="/admin/settings/sub_form_questions/${id}/edit">${sanitize(
            question
          )}</a>`;
        case AppBundleInstallationErrorReason.no_default_value:
          return `Question default value was removed. Please reconfigure <a target="_blank" href="/admin/settings/sub_form_questions/${id}/edit">${sanitize(
            question
          )}</a>`;
        default:
          return error.reason;
      }
    }

    get modulesErrors(): Record<string, AppBundleInstallationError[]> {
      const errors = this.installationErrors;

      return Object.keys(errors.modules).reduce((acc, currentModule) => {
        const moduleErrors = Object.values(errors.modules[currentModule]).flat();
        if (!!moduleErrors.length) {
          return { ...acc, [currentModule]: moduleErrors };
        }
        return acc;
      }, {});
    }
  }
