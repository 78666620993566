
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import { Component, Model, Prop, Vue, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import moment from 'moment';
  import { API_NULL } from '@app/constants';
  import type { ModuleName } from '@app/models/module-name';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { IndexFilter } from '@app/models/index-filter';
  import type { Dictionary } from '@app/models/dictionary';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  import ScopeFilter from '../../filters/scope-filter.vue';
  import GlobalDateFilter from '../../filters/global-date-filter.vue';
  import ModuleRecordsTable from '../../module-record/module-records-table.vue';

  @Component({ components: { ModuleRecordsTable, ScopeFilter, GlobalDateFilter, DsModal } })
  export default class SystemFilterFormModal extends Vue {
    @Model('input') value!: boolean;
    @Prop(Object) readonly moduleName!: ModuleName;
    @Ref() readonly moduleRecordsTable?: ModuleRecordsTable;

    initialized = false;
    indexFilter: Nullable<Partial<IndexFilter>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get indexOptions(): string[] {
      return this.moduleName.index_options.map((o) => o.key);
    }

    get sort(): string | undefined {
      return this.indexFilter?.sorting_order?.sort;
    }

    get reverse(): boolean {
      return !!this.indexFilter?.sorting_order?.reverse;
    }

    get defaultIndexFilter(): Partial<IndexFilter<ModuleRecord, DonesafeModuleRecordExtraFilters>> {
      return { filter: { scope: 'mine', created_at: 'week', module_name_id: this.moduleName.id } };
    }

    get customFilters(): DonesafeFilterOptions<ModuleRecord> | undefined {
      return this.moduleRecordsTable?.manager?.customFilters;
    }

    saveIndexFilter(): void {
      const filterParams: Dictionary<string> = {};
      const filters = this.moduleRecordsTable?.table?.allFilters as Dictionary<string | Date>;

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          if (filters[key] instanceof Date) {
            filterParams[key] = moment(filters[key]).format(this.accountStore.dateTimePickerFormatWithTimeZone);
          } else {
            filterParams[key] = filters[key] as string;
          }
        }
      });
      const sort = this.moduleRecordsTable?.sortOrder?.[0]?.sortField;
      const reverse = this.moduleRecordsTable?.sortOrder?.[0]?.direction === 'desc';
      const updateIndexFilter: Partial<IndexFilter> = {
        index: 'module_records',
        filter: filterParams,
        sorting_order: (sort && { sort, reverse }) || undefined,
      };
      const createIndexFilter = { ...updateIndexFilter, global: true };

      const promise = this.indexFilter?.id
        ? this.$api.updateIndexFilter(this.indexFilter.id, updateIndexFilter)
        : this.$api.createIndexFilter(createIndexFilter);
      promise
        .then((response) => {
          this.indexFilter = response.data;
          toaster({
            text: this.$t('tenant.admin.involvements.visual_options.default_filters_saved'),
            position: 'top-right',
          });
          this.$emit('input', false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    mounted(): void {
      this.$api
        .getIndexFilters({ filters: { user_id: API_NULL, filter: { module_name_id: this.moduleName.id } } }, { cache: true })
        .then(({ data }) => {
          this.indexFilter = data[0] || this.defaultIndexFilter;
        });
    }
  }
