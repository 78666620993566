
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import bootbox from 'bootbox';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { SuperReport } from '@app/models/super-report';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown } })
  export default class ReportFormActions extends Vue {
    @Prop(Object) readonly superReport!: SuperReport;

    get existingRecord(): boolean {
      return !!this.superReport.id;
    }

    get buttonText(): string {
      return this.existingRecord ? this.$t('app.buttons.edit') : this.$t('app.buttons.save');
    }

    deleteSuperReport(report: Partial<SuperReport>): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure you want to delete this report? All widgets associated will also be deleted.',
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteSuperReport(report.id as number)
              .then(() => {
                toaster(this.$t('tenant.admin.reports.actions.deleted'));
                changeLocation('/admin/settings/reports');
              })
              .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }
  }
