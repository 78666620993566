
  import { Component, Prop } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import UserCollectionForm from '@app/components/admin/user-collections/user-collection-form.vue';
  import Blocking from '@app/mixins/blocking';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
  import type { UserCollection } from '@app/models/user-collection';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  const shape: { only: OnlyOptions<UserCollection> } = {
    only: ['id', 'name', 'global', 'active', { rule_set: ['query_builder_blob'] }],
  };

  @Component({
    components: { UserCollectionForm },
  })
  export default class EditUserCollection extends mixins(Blocking, WithBootbox) {
    @Prop([String, Number]) readonly id!: string | number;

    entity: Nullable<UserCollection> = null;

    beforeMount(): void {
      this.load();
    }

    async onSubmit(form: Partial<UserCollection>): Promise<void> {
      await this.blocking(async () => {
        const { data } = await this.$api.updateUserCollection(this.id, { ...shape, ...form });
        this.entity = data;
        toaster({ text: this.$t('tenant.admin.user_collections.updated'), position: 'top-right' });
      });
    }

    async onChangeActive(active: boolean): Promise<void> {
      await this.blocking(async () => {
        if (!(await this.confirm(`${this.$t('app.labels.are_you_sure')}`))) return;

        await this.$api.updateUserCollection(this.id, { active, ...shape });
        await this.$router.push('/admin/settings/user_collections');
      });
    }

    async load(): Promise<void> {
      const { data } = await this.$api.getUserCollection(
        this.id,
        {
          ...shape,
        },
        { cache: true }
      );

      this.entity = data;
    }
  }
