import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export const SCHEDULED_EVENT_ONLY = Tuple([
  'status',
  'id',
  'active',
  'name',
  'recurring_setup',
  'schedule',
  {
    event: [
      ...['id', 'per', 'retries', 'options', 'notification_email', 'mapping', 'event_type', 'enable_retries'],
      { chained_events: ['id', 'retries', 'options', 'notification_email', 'mapping', 'event_type', 'enable_retries'] },
    ],
  },
] as const);
export type ScheduledEventOnly = FirstLevelKeyLiterals<(typeof SCHEDULED_EVENT_ONLY)[number]>;
