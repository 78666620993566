
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import ColorInput from '../../../color-input.vue';
  import type { SvgSelectorCategory } from '@app/models/question-options/svg-selector-question-config';

  @Component({ components: { ColorInput } })
  export default class CategoryInput extends Vue {
    @Model('input', Object) readonly value!: SvgSelectorCategory;
    @Prop(String) readonly name!: string;
    @Prop({ type: Boolean, default: true }) readonly deletable!: boolean;

    @Emit('input')
    changeCode(code: string): SvgSelectorCategory {
      return { ...this.value, code: code.replace(/[^a-z0-9-_]/g, '') };
    }
  }
