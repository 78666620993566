
  import { Component, Model, Vue, Prop } from 'vue-property-decorator';
  import { Chrome as ChromeColorPicker } from 'vue-color';
  import DsPopover from '@app/components/ds-popover.vue';

  const DEFAULT_COLOR = '#FFFFFF';

  @Component({ components: { ChromeColorPicker, DsPopover } })
  export default class ColorInput extends Vue {
    @Model('input') readonly value?: string;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly disabled?: boolean;
    @Prop(Boolean) readonly compact?: boolean;
    @Prop(String) readonly size?: 'sm' | 'lg';
    @Prop(Boolean) readonly hex8?: boolean;
    @Prop(Boolean) readonly required?: boolean;
    @Prop(String) readonly placeholder?: string;

    showPopover = false;

    popoverColorValue = DEFAULT_COLOR;

    inputClick(): void {
      if (this.disabled || this.showPopover) {
        return;
      }

      this.togglePopover(true);
    }

    clear(closePopover?: boolean): void {
      closePopover && this.togglePopover(false);
      this.$emit('input', undefined);
    }

    togglePopover(value: boolean): void {
      this.showPopover = value;
      this.popoverColorValue = this.value || DEFAULT_COLOR;
    }

    selectColor(): void {
      this.showPopover = false;
      this.$emit('input', this.popoverColorValue);
    }

    onColorChange(data: { hex: string; hex8: string }): void {
      this.popoverColorValue = this.hex8 ? data.hex8 : data.hex;
    }
  }
