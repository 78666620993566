
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { IframeWidgetOptions } from '@app/models/widget';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({
    components: { ValidationObserver, ValidationProvider },
  })
  export default class IframeWidgetSettings extends BaseWidgetSettings<IframeWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<IframeWidgetOptions>;

    beforeMount(): void {}
  }
