
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import AuditReportTemplateForm from '@app/components/audit-report-templates/audit-report-template-form.vue';
  import { AUDIT_REPORT_TEMPLATE_ONLY } from '@app/components/admin/modules/utils';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { DsModal, AuditReportTemplateForm } })
  export default class EditAuditReportTemplateFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ type: [String, Number] }) readonly auditReportTemplateId!: number | string;
    @Ref() readonly auditReportTemplateForm?: AuditReportTemplateForm;

    auditReportTemplate: Nullable<AuditReportTemplate> = null;
    visible = true;
    saving = false;

    deleteAuditReportTemplate(auditReportTemplateId: number): void {
      this.$api
        .deleteAuditReportTemplate(auditReportTemplateId)
        .then(({}) => {
          this.$api.cache.clear();
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    submitForm(auditReportTemplate: AuditReportTemplate & { file?: string }): void {
      this.saving = true;
      this.$api
        .updateAuditReportTemplate(auditReportTemplate.id, auditReportTemplate)
        .then(() => {
          toaster(this.$t('tenant.admin.audit_report_template.form.updated'));
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        })
        .finally(() => (this.saving = false));
    }

    beforeMount(): void {
      this.$api
        .getAuditReportTemplate(Number(this.auditReportTemplateId), { only: AUDIT_REPORT_TEMPLATE_ONLY }, { cache: true })
        .then(({ data }) => {
          this.auditReportTemplate = data;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-audit-report-templates',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }
  }
