
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import MobileAppRegistrationForm from '../../../components/admin/mobile-apps/mobile-app-registration-form.vue';
  import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppRegistrationForm, DsModal } })
  export default class NewRegistrationPage extends Vue {
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Ref() readonly form?: MobileAppRegistrationForm;

    registration: Partial<MobileAppRegistration> = {
      mobile_app_config_id: Number(this.mobileAppConfigId),
      active: true,
    };

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<MobileAppRegistration>): void {
      this.$api
        .createMobileAppRegistration(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
