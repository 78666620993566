
  import RuleValueSelect from './rule-value-select.vue';
  import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import CollapsedText from './collapsed-text.vue';
  import { ValidationProvider } from 'vee-validate';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';

  @Component({ components: { RuleValueSelect, CollapsedText, ValidationProvider, AdvancedAutosuggest } })
  export default class RuleValueInput extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() rule!: DsRuleNode;

    get filter() {
      return this.rule.filter;
    }

    get name() {
      return `${this.rule.id}_value`;
    }

    get placeholderValue() {
      return this.filter?.placeholder;
    }

    get isNumberInput() {
      if (this.filter?.input === 'text') return false;

      return this.filter?.type === 'double' || this.filter?.type === 'integer';
    }

    get step() {
      if (this.filter?.type === 'double') return 0.01;
    }

    get rules() {
      const base = { required: true };

      if (this.isNumberInput) {
        const numberRule = this.filter?.type === 'integer' ? 'numeric' : 'double';
        return {
          ...base,
          [numberRule]: true,
        };
      } else {
        return base;
      }
    }
  }
