
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestionReference } from '@app/components/admin/questions/edit/models';
  import SubFormQuestionSelector from '@app/components/admin/questions/edit/sub-form-question-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: { SubFormQuestionSelector } })
  export default class FormBeingApprovedDefaultTypeOptions extends Vue {
    @Model('input') value!: SubFormQuestion;
    @Prop(Object) subForm!: SubForm;

    approvalSubFormId: number | null = null;

    onQuestionChange(change: SubFormQuestionReference) {
      if (!this.value.config.default) {
        return;
      }

      this.$$patch(this.value.config.default, change);
    }

    async beforeMount() {
      const { data: [{ id: subFormListId }] = [] } = await this.$api.getSubFormLists(
        {
          filters: { approval_sub_form_id: this.subForm.id },
        },
        { cache: true }
      );
      const {
        data: [{ id: approvalSubFormId }],
      } = await this.$api.getSubForms({ filters: { sub_form_lists: { id: subFormListId } } }, { cache: true });
      this.approvalSubFormId = approvalSubFormId;
    }
  }
