
  import { Component, Prop } from 'vue-property-decorator';
  import WithReportGraph from '@app/mixins/with-report-graph';
  import type { Library } from '@app/models/widgets/report-graph';
  import type { SuperReportGraphData } from '@app/models/super-report';

  @Component({})
  export default class SuperReportGraphTab extends WithReportGraph {
    @Prop() readonly reportData!: SuperReportGraphData;

    pieDataLabels: Library['plotOptions']['pie']['dataLabels'] = { enabled: true };
    defaultRadius = 4;
  }
