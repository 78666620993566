
  import { Tooltip } from 'uiv';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import WithImportModal from '@app/mixins/with-import-modal';
  import WithAuditTrailModal from '@app/mixins/with-audit-trail-modal';
  import PaperTrailsModalLink from '../../paper-trails/paper-trails-modal-link.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { ModuleTab } from '@app/models/module-tab';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';

  // TODO: add delete button to the dropdown
  @Component({ components: { PaperTrailsModalLink, Tooltip, DsDropdown, DsIconText } })
  export default class ModuleTabControlPanel extends mixins(WithAuditTrailModal, WithImportModal) {
    @Prop(Object) readonly moduleTab!: Pick<ModuleTab, 'active' | 'id' | 'code' | 'title'>;

    get hybrid(): boolean {
      return HYBRID_MODULE_TAB_CODE === this.moduleTab.code;
    }
  }
