
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Dashboard } from '@app/models/dashboard';

  @Component({ components: { DsModal, ValidationObserver, ValidationProvider } })
  export default class DashboardFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly dashboard!: Dashboard;
    @Prop(String) readonly title!: string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Dashboard> = {};

    get formTitle(): string {
      if (this.dashboard.id) {
        return this.$t('tenant.dashboards.edit_dashboard');
      } else {
        return this.$t('tenant.dashboards.new_dashboard');
      }
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    initForm(): void {
      this.form = { ...this.dashboard };
    }
  }
