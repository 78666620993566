
  import ModuleDefaultTemplateActions from '@app/components/admin/modules/module-default-template-actions.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import SubFormCompletionForm from '@app/components/sub-form-completion/form/sub-form-completion-form.vue';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import * as bootbox from 'bootbox';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';

  @Component({
    components: { SubFormCompletionForm, ValidationProvider, ValidationObserver, DsCheckbox, DsTextInput, ModuleDefaultTemplateActions },
  })
  export default class DefaultTemplateForm extends Vue {
    @Prop(Object) readonly defaultTemplate!: Partial<DefaultTemplate>;
    @Prop(Boolean) readonly blocked?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly completionForm?: SubFormCompletionForm;

    form: Partial<DefaultTemplate> = {};
    completionFormValue: SubFormData = {};
    moduleName: Nullable<ModuleName> = null;

    get subFormCompletion() {
      return this.defaultTemplate.sub_form_completion_id
        ? this.defaultTemplate.sub_form_completion
        : { sub_form_id: this.moduleName?.sub_form_id };
    }

    onSubmit() {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', { ...this.form, responses: this.completionFormValue });
        }
      });
    }

    toggleActive(): void {
      bootbox.confirm({
        size: 'small',
        // backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.$emit('submit', { active: !this.defaultTemplate.active });
        },
      });
    }

    beforeMount() {
      this.form = { ...this.defaultTemplate };

      this.$api.getModuleName(Number(this.defaultTemplate.module_name_id), {}, { cache: true }).then(({ data }) => {
        this.moduleName = data;
        const params = this.defaultTemplate.sub_form_completion_id
          ? { sub_form_completion_id: this.defaultTemplate.sub_form_completion_id }
          : { sub_form_id: this.moduleName.sub_form_id };

        this.$api.getSubFormCompletionDefaultValues({ ...params, default_templating: true }).then(({ data }) => {
          this.completionFormValue = data;
        });
      });
    }
  }
