
  import MultiFormSubFormListSettings from '@app/components/sub-form-list-types/multi-form-sub-form-list-settings.vue';
  import SingleFormSubFormListSettings from '@app/components/sub-form-list-types/single-form-sub-form-list-settings.vue';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { Component } from 'vue-property-decorator';

  @Component({ components: { MultiFormSubFormListSettings, SingleFormSubFormListSettings } })
  export default class NormalTabSettings extends SubFormListSettingsBase {
    singleMode = true;
    beforeMount(): void {
      this.singleMode = this.subFormsCount < 2;
    }

    get subFormsCount(): number {
      return this.subFormListSync.sub_form_ids?.length || 0;
    }

    switchMode(singleMode: boolean): void {
      if (singleMode) {
        const newSubFormIds = this.subFormListSync.sub_form_ids?.[0] ? [this.subFormListSync.sub_form_ids[0]] : [];
        this.subFormListSync = { ...this.subFormListSync, sub_form_ids: newSubFormIds };
      }
      this.singleMode = singleMode;
    }
  }
