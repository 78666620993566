
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import MenuCollectionsForm from '@app/components/admin/menu-collections/menu-collections-form.vue';
  import bootbox from 'bootbox';
  import { isEmpty } from 'lodash';
  import Blocking from '@app/mixins/blocking';
  import type { MenuCollectionsFormOnly } from './utils';
  import { MENU_COLLECTIONS_FORM_ONLY } from './utils';
  import type { MenuCollection } from '@app/models/menu-collection';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MenuCollectionsForm, DsModal } })
  export default class MenuCollectionsEdit extends Blocking {
    @Ref() readonly form!: MenuCollectionsForm;
    @Prop([Number]) readonly menuCollectionId!: number;

    menuCollection: Partial<Pick<MenuCollection, MenuCollectionsFormOnly>> = {};
    visible = true;

    get menuCollectionLoaded(): boolean {
      return !isEmpty(this.menuCollection);
    }

    toggleActive({ active, id }: Partial<Pick<MenuCollection, MenuCollectionsFormOnly>>): void {
      if (active) {
        bootbox.confirm({
          size: 'small',
          message: this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.buttons.proceed'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result && id) this.updateMenuCollection({ id, active: !active });
          },
        });
      } else {
        this.updateMenuCollection({ id, active: !active });
      }
    }

    onFormSubmit(form: Partial<Partial<Pick<MenuCollection, MenuCollectionsFormOnly>>>): void {
      form.id && this.updateMenuCollection(form);
    }

    updateMenuCollection(form: Partial<Partial<Pick<MenuCollection, MenuCollectionsFormOnly>>>): void {
      const id = form.id;
      if (id) {
        this.blocking(async () => {
          await this.$api.updateMenuCollection(id, form);
          toaster(this.$t('tenant.settings.menu_collections.edit.updated'));
          this.$api.cache.clear();
          this.closeModal('reload');
        });
      }
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-menu-collections',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    beforeMount(): void {
      this.$api.getMenuCollection(this.menuCollectionId, { only: MENU_COLLECTIONS_FORM_ONLY, cache: true }).then(({ data }) => {
        this.menuCollection = data;
      });
    }
  }
