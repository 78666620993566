
  import Blocking from '@app/mixins/blocking';
  import { entityPathPrefix } from '@app/services/helpers';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import WithSubFormCompletionSubmit from '@app/mixins/with-sub-form-completion-submit';
  import type { ParsedQs } from 'qs';
  import qs from 'qs';
  import type { SubFormData } from '@app/services/api/sub-form-completions-api';
  import type { SfcFormRecordOnly } from '@app/pages/module-records/utils';
  import { moduleRecordErrorHandler, SFC_FORM_RECORD_ONLY, SUB_FORM_LIST_ONLY } from '@app/pages/module-records/utils';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { ResponseValue, SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import { WithPreviousCompletionForm } from '@app/mixins/with-previous-completion-form';
  import WithCompletionDefaultValues from '@app/mixins/with-completion-default-values';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import WithCompletionUnsaved from '@app/mixins/with-completion-unsaved';
  import { changeLocation } from '@app/utils/change-location';

  import PanelPage from './panel-page.vue';
  import { SUB_FORM_COMPLETION_SHOW_ONLY, SUB_FORM_SFC_FORM_ONLY } from './utils';
  import type { WithSfcSubmitOnly } from './utils';
  import SubFormCompletionShow from './sub-form-completion-show.vue';
  import MainRecordDetails from './form/main-record-details.vue';
  import SubFormCompletionForm from './form/sub-form-completion-form.vue';

  // TODO: pass the following props to sub-form-completion-form
  // completeButtonText
  // completeAnotherButtonText
  // draftButtonText
  // draftAnotherButtonText
  @Component({
    components: { SubFormCompletionForm, MainRecordDetails, SubFormCompletionShow, PanelPage, DsIconText },
  })
  export default class SubFormCompletionPage extends mixins(
    WithSubFormCompletionSubmit,
    WithPreviousCompletionForm,
    WithCompletionDefaultValues,
    WithCompletionUnsaved,
    Blocking
  ) {
    @Ref() readonly sfcForm!: SubFormCompletionForm;

    @Prop(Number) readonly subFormCompletionId?: number;
    @Prop(Boolean) readonly isPublic?: boolean;

    record: Nullable<Pick<ModuleRecord, SfcFormRecordOnly>> = null;
    defaults: null | SubFormData = null;
    form: SubFormData = {};
    loaded = false;
    initialFetching = false;

    recordLoaded = false;

    invalidSections: Record<string, boolean> = {};
    approvalForSubFormCompletion: Nullable<SubFormCompletion> = null;

    get params(): ParsedQs {
      return qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    get isEnhanced(): boolean {
      return this.subForm?.free_navigation || false;
    }

    get showPage(): boolean {
      return !!this.subFormCompletion && !!this.subForm && !!this.defaults && this.recordLoaded;
    }

    isSectionGroupActive(sectionGroup: SubFormSection[]): boolean {
      return this.sfcForm?.activeSectionGroup?.[0]?.id === sectionGroup?.[0]?.id;
    }

    onUpdateField(event: { question: SubFormQuestion; value: ResponseValue }) {
      if (!this.isEnhanced) {
        return;
      }

      if (!event?.question?.sub_form_section_id) this.$rollbar.info('No sub form section id', event);

      this.invalidSections[`${event.question.sub_form_section_id}`] = false;
    }

    onValidation(event: Record<string, boolean>) {
      if (!this.isEnhanced) {
        return;
      }

      this.invalidSections = event;
    }

    parentRecordPath(recordType: string, recordId: number): string {
      return `/${entityPathPrefix(recordType)}/${recordId}`;
    }

    async onCompletionSubmitComplete(subFormCompletion: Pick<SubFormCompletion, WithSfcSubmitOnly>): Promise<void> {
      const completionId = subFormCompletion.record_id || this.subFormCompletion?.record_id;
      if (completionId && subFormCompletion.record_type) {
        this.shouldPreventNavigation = false;
        changeLocation(
          this.subFormList
            ? `${this.parentRecordPath(subFormCompletion.record_type, completionId)}?open_completion_id=${subFormCompletion.id}`
            : this.parentRecordPath(subFormCompletion.record_type, completionId)
        );
      }
    }

    iconClass(section: SubFormSection): string {
      if (this.invalidSections[`${section.id}`]) {
        return 'error danger-color-text';
      } else if (this.sfcForm?.sectionIncomplete(section)) {
        return 'incomplete warning-color-text';
      }

      return 'completed primary-color-text';
    }

    isSubForm(): boolean {
      return this.record?.sub_form_completion?.sub_form_id !== this.subFormCompletion?.sub_form_id;
    }

    async fetchInitialValues(subFormCompletion: Partial<SubFormCompletion>): Promise<void> {
      const promises: Promise<void>[] = [];
      if (subFormCompletion.record_id && subFormCompletion.record_type === 'ModuleRecord') {
        promises.push(
          this.$api
            .getModuleRecord(subFormCompletion.record_id, { only: SFC_FORM_RECORD_ONLY })
            .then(({ data }) => {
              this.record = data;
              this.recordLoaded = true;
            })
            .catch((data) => moduleRecordErrorHandler(data, subFormCompletion.record_id as number))
        );
      } else {
        this.recordLoaded = true;
      }

      if (subFormCompletion.sub_form_id) {
        promises.push(
          this.$api
            .getSubForm(
              subFormCompletion.sub_form_id,
              { only: SUB_FORM_SFC_FORM_ONLY, url_options: { expirable: this.isPublic } },
              { cache: true }
            )
            .then(({ data }) => {
              this.subForm = data;
            })
        );
      }

      if (subFormCompletion.sub_form_list_id) {
        promises.push(
          this.$api.getSubFormList(subFormCompletion.sub_form_list_id, { only: SUB_FORM_LIST_ONLY }, { cache: true }).then(({ data }) => {
            this.subFormList = data;
          })
        );
      }

      promises.push(
        this.getDefaultValuesPromise(subFormCompletion, { params: this.params }).then(({ data }) => {
          this.defaults = data;
          this.form = { ...this.defaults };
          this.savedForm = { ...this.form };
        })
      );

      if (subFormCompletion.approval_for_sub_form_completion_id) {
        promises.push(
          this.$api
            .getSubFormCompletion(subFormCompletion.approval_for_sub_form_completion_id, {
              only: [...SUB_FORM_COMPLETION_SHOW_ONLY, { sub_form: ['title'] }],
            })
            .then(({ data }) => {
              this.approvalForSubFormCompletion = data;
            })
        );
      }

      this.initialFetching = true;
      await this.blocking(async () => {
        await Promise.all(promises).finally(() => {
          this.initialFetching = false;
        });
      });
    }

    async fetchSubFormCompletion(subFormCompletionId: SubFormCompletion['id']) {
      await this.blocking(async () => {
        const { data } = await this.$api.getSubFormCompletion(subFormCompletionId, { include: ['workflow'] });

        this.subFormCompletion = data;
        this.previousCompletionForm = this.getPreviousForm(data);

        await this.fetchInitialValues(this.subFormCompletion);
      });
    }

    beforeMount(): void {
      if (this.subFormCompletionId) {
        this.fetchSubFormCompletion(this.subFormCompletionId);
      } else {
        this.subFormCompletion = {
          ...(this.params.sub_form_id && {
            sub_form_id: Number(this.params.sub_form_id),
          }),
          ...(this.params.sub_form_list_id && {
            sub_form_list_id: Number(this.params.sub_form_list_id),
          }),
          ...(this.params.approval_for_sub_form_completion_id && {
            approval_for_sub_form_completion_id: Number(this.params.approval_for_sub_form_completion_id),
          }),
          ...(this.params.record_id && { record_id: Number(this.params.record_id) }),
          ...(this.params.record_type && { record_type: this.params.record_type as string }),
        };

        this.fetchInitialValues(this.subFormCompletion);
      }
    }
  }
