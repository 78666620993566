
  import CompletionFilterConfigurator from '@app/components/admin/questions/filter-configurator/completion-filter-configurator.vue';
  import type { AxiosPromise, AxiosResponse } from 'axios';
  import { Component, Model, Prop } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '../edit/form-field.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormRelationQuestionOptions } from '@app/models/question-options/sub-form-relation-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import OrderOptionsSelector from '@app/components/admin/questions/order-options/order-options-selector.vue';
  import { mixins } from 'vue-class-component';
  import type { GroupedOption } from '@app/components/admin/questions/order-options/with-order-options';
  import { WithOrderOptions } from '@app/components/admin/questions/order-options/with-order-options';

  type SubFormOption = Pick<SubForm, 'id' | 'title'>;

  interface QuestionOption {
    active?: boolean;
    label: string;
    value: string;
  }

  @Component({ components: { CompletionFilterConfigurator, Select2, EntitySelector, FormField, DsCheckbox, OrderOptionsSelector } })
  export default class SubFormRelationFieldOptions extends mixins(WithOrderOptions) {
    @Model('input') value!: SubFormQuestion<SubFormRelationQuestionOptions>;

    @Prop(Object) subForm!: SubForm;

    moduleName: Nullable<ModuleName> = null;
    relatedSubForm: Nullable<SubForm> = null;

    questionOptions: QuestionOption[] = [];
    subFormOptions: SubFormOption[] = [];
    sortingOptions: GroupedOption[] = [];

    get sortByOptions(): GroupedOption[] {
      return [...this.sortingOptions];
    }

    get subFormFilters(): DonesafeFilterOptions<SubForm> {
      return {
        active: true,
        module_name: this.moduleName?.name,
      };
    }

    get subFormListFilters(): DonesafeFilterOptions<SubFormList> {
      return {
        active: true,
        sub_form_list_type: ['normal', 'approval', 'workflow'],
        module_tab: { module_name: this.moduleName?.name },
      };
    }

    get showSubFormInput(): Boolean {
      const {
        config: { sub_form_list_id, sub_form_id },
      } = this.value;

      return (
        this.subFormOptions.length > 1 ||
        (!sub_form_list_id && !!sub_form_id) ||
        (this.subFormOptions.length > 1 && // TODO redundant checks
          !!sub_form_id &&
          !this.subFormOptions.find((option) => {
            return `${option.id}` === sub_form_id;
          }))
      );
    }

    useFirstSubForm(): void {
      const [{ id = undefined } = {}] = this.subFormOptions;
      const sub_form_id = `${id || ''}`;
      this.$$patch(this.value.config, { sub_form_id });
      this.loadSubFormData(sub_form_id);
    }

    getQuestionsFromSubFormId(subFormId: string | number): AxiosPromise<SubFormQuestion[]> {
      return this.$api.getSubFormQuestions(
        {
          filters: {
            active: true,
            sub_form_section: {
              sub_form_id: subFormId,
            },
          },
          only: ['id', 'active', 'title', 'code'],
          per_page: -1,
        },
        { cache: true }
      );
    }

    loadFormWithQuestions(subFormId: string | number): Promise<SubForm> {
      return this.$api
        .getSubForm(Number(subFormId), { include: ['sub_form_sections', 'sub_form_questions'] }, { cache: true })
        .then(({ data }) => {
          this.relatedSubForm = data;
          return data;
        });
    }

    selectSubFormList(subFormListId: string | number | undefined): void {
      if (subFormListId) {
        // clear sort on sfl change
        this.$$patch(this.value.config, { sort: undefined });

        this.fetchSubFormsOptions(subFormListId, undefined).then(() => {
          this.questionOptions = [];
          this.useFirstSubForm();
        });
      } else {
        this.subFormOptions = [];
        this.questionOptions = [];

        this.$$patch(this.value.config, { sub_form_id: '', question_ids: [] });
      }
    }

    async fetchSubFormsOptions(
      subFormListId: string | number | undefined,
      subFormId: string | number | undefined
    ): Promise<SubFormOption[]> {
      if (!subFormListId && subFormId) {
        // For old code
        return (this.subFormOptions = await this.loadSubFormsOptionsByFormId(subFormListId));
      }
      return (this.subFormOptions = await this.loadSubFormsOptionsByListId(subFormListId));
    }

    async loadSubFormsOptionsByListId(subFormListId: string | number | undefined): Promise<SubFormOption[]> {
      if (!subFormListId) {
        return [];
      }

      const {
        data: { sub_form_ids = [], approval_sub_form_id },
      } = await this.$api.getSubFormList(Number(subFormListId), { only: ['sub_form_ids', 'approval_sub_form_id'] }, { cache: true });

      const subFormIds = [...sub_form_ids, ...[approval_sub_form_id].filter((v) => !!v)];

      const { data: subFormOptions } = await this.$api.getSubForms(
        { filters: { ...this.subFormFilters, id: subFormIds }, only: ['id', 'title'] },
        { cache: true }
      );

      return subFormOptions;
    }

    async loadSubFormsOptionsByFormId(subFormId: string | number | undefined): Promise<SubFormOption[]> {
      if (!subFormId) {
        return [];
      }

      const { data: subFormOptions } = await this.$api.getSubForms(
        { filters: { ...this.subFormFilters }, only: ['id', 'title'] },
        { cache: true }
      );

      return subFormOptions;
    }

    async loadSubFormData(subFormId: string | undefined): Promise<void> {
      const { sub_form_list_id } = this.value.config;

      await Promise.all([
        this.loadQuestionOptions(subFormId).then((opts) => (this.questionOptions = opts)),
        this.loadSortingOptions({ subFormId, subFormListId: sub_form_list_id }).then((opts) => (this.sortingOptions = opts)),
      ]);
    }

    async loadQuestionOptions(subFormId: string | undefined): Promise<QuestionOption[]> {
      if (!subFormId) {
        return [];
      }

      const [baseFormQuestions, approvalQuestions] = await Promise.all([
        this.baseFormQuestions(subFormId),
        this.approvalFormQuestions(subFormId),
      ]);

      return [
        ...baseFormQuestions.map(({ id, active, title, code }) => ({
          value: `${id}`,
          active,
          label: `${title} (${code})`,
        })),
        ...approvalQuestions.map(({ id, active, title, code }) => ({
          value: `^${id}`,
          active,
          label: `Original Form: ${title} (${code})`,
        })),
      ].filter(({ active, value }) => active || this.value.config.question_ids?.includes(value));
    }

    baseFormQuestions(subFormId?: string) {
      if (!subFormId) {
        return [];
      }

      return this.loadFormWithQuestions(subFormId).then(
        (subForm) => subForm.sub_form_sections?.map((s) => s.sub_form_questions || []).flat() || []
      );
    }

    async approvalFormQuestions(subFormId?: string) {
      if (!subFormId) {
        return [];
      }

      const { data: approvalQuestions } = await this.$api
        .getSubFormLists({ filters: { approval_sub_form_id: subFormId }, only: ['sub_form_ids'] }, { cache: true })
        .then(({ data }) => {
          if (data[0]?.sub_form_ids?.length) {
            return this.getQuestionsFromSubFormId(data[0].sub_form_ids[0]);
          }
          return { data: [] } as unknown as AxiosResponse<SubFormQuestion[]>;
        });

      return approvalQuestions;
    }

    async beforeMount(): Promise<void> {
      const moduleNameFilter =
        this.subForm.module_name === MAIN_FORM_MODULE_NAME ? { sub_form_id: this.subForm.id } : { name: this.subForm.module_name };

      const {
        data: [moduleName],
      } = await this.$api.getModuleNames({ filters: { ...moduleNameFilter }, only: ['id', 'name'] }, { cache: true });

      this.moduleName = moduleName;

      const { sub_form_id, sub_form_list_id } = this.value.config;

      this.subFormOptions = await this.fetchSubFormsOptions(sub_form_list_id, sub_form_id);

      if (!!sub_form_id && this.subFormOptions.find((option) => `${option.id}` === sub_form_id)) {
        this.loadSubFormData(sub_form_id);
      } else {
        this.useFirstSubForm();
      }
    }

    get autoSelectEnabled(): boolean {
      return this.value.config.auto_select_enabled === 'true';
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      [
        'sub_form_list_id',
        'sub_form_id',
        'question_ids',
        'filters',
        'sort',
        'out_of_scope_selection',
        'auto_select_enabled',
        'sort_order',
      ].forEach((key) => this.$delete(this.value.config, key));
    }
  }
