
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import AttachmentItem from '@app/components/attachment/attachment-item.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Comment } from '@app/models/comment';

  @Component({ components: { AttachmentItem } })
  export default class CommentsList extends Vue {
    @Prop({ type: Array, default: () => [] }) comments!: Comment[];
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Number) currentUserId?: number;
    @Prop({ type: Boolean, default: () => false }) allowToDelete?: boolean;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    canDelete(comment: Pick<Comment, 'user_id'>): boolean {
      return this.canEdit || (this.allowToDelete == true && comment.user_id == this.currentUserStore.data?.id);
    }

    contextLabel(comment: Pick<Comment, 'context'>): string | undefined {
      const mapping = {
        action_approved: this.$t('tenant.comments.contexts.action_approved'),
        action_closed: this.$t('tenant.comments.contexts.action_closed'),
        action_reopened: this.$t('tenant.comments.contexts.action_reopened'),
        action_unapproved: this.$t('tenant.comments.contexts.action_unapproved'),
      };
      return comment.context && mapping[comment.context];
    }

    get accountStore() {
      return useAccountStore();
    }
  }
