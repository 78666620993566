
  import { Component, Vue } from 'vue-property-decorator';
  import SvgInteractiveTemplate from '../../../components/svg-template/svg-interactive-template.vue';
  import bootbox from 'bootbox';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: { SvgInteractiveTemplate, DsModal },
  })
  export default class ViewSvgTemplatePage extends Vue {
    template: Nullable<SvgLibraryTemplate> = null;
    svgTemplateContents: Nullable<string> = null;
    selectedComponent: Nullable<string> = null;
    hoveringComponent: Nullable<string> = null;
    loading = false;
    visible = true;

    beforeMount(): void {
      this.fetchTemplate();
    }

    onComponentOver(id: string): void {
      this.hoveringComponent = id;
    }

    onComponentOut(id: string): void {
      if (id === this.hoveringComponent) {
        this.hoveringComponent = null;
      }
    }

    onComponentNameOver(id: string): void {
      this.selectedComponent = id;
    }

    onComponentNameOut(): void {
      this.selectedComponent = null;
    }

    fetchTemplate(): void {
      this.loading = true;
      this.$api
        .getSvgTemplate(this.$route.params.svgTemplateId, { include: ['components', 'attachment'] }, { cache: false })
        .then(({ data: template }) => {
          this.template = template;

          return this.$api.getSvgTemplateFile(template.id);
        })
        .then(({ data }) => {
          this.svgTemplateContents = data;
        })
        .catch(({ data, status }) => {
          const error =
            data.error || status == 404
              ? this.$t('app.labels.svg_template_file_not_found')
              : `${this.$t('app.labels.svg_template_status_error')} ${status}`;
          toaster({ text: error, icon: 'error' });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    removeTemplate(): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: 'Are you sure you want to remove this template?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (!(result && this.template)) {
            return;
          }

          this.$api
            .deleteSvgTemplate(this.template.id)
            .then(() => {
              this.$api.cache.clear();
              this.$router.replace({ name: 'svg-templates-index', params: { reloadTable: 'reload' }, query: this.$route.query });
            })
            .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'svg-templates-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
