
  import { Component, Prop } from 'vue-property-decorator';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { Tuple } from '@app/utils/types/tuple';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';

  import EventQuestionFields from '../event-question-fields.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  const moduleNameAttributes = Tuple(['id', 'display', 'sub_form_id', { main_form: ['id'] }] as const);
  type ModuleNameAttributes = FirstLevelKeyLiterals<typeof moduleNameAttributes>[number];

  @Component({ components: { Select2, EventQuestionFields, FormField } })
  export default class ModuleRecordEventForm extends BaseEventForm<Dictionary<string>> {
    @Prop(String) readonly createModule!: string;

    @Prop(Array) readonly dateOptions?: [string, string][];
    @Prop(Object) readonly injectableArguments!: Dictionary<string>;

    selectedModuleName: Nullable<Pick<ModuleName, ModuleNameAttributes>> = null;
    formQuestions: SubFormQuestion[] = [];

    get confidentialityOptions(): [string, string][] {
      return [
        ...(this.flatFieldOptionsRecord?.Confidentiality || []),
        ['[]', this.$t('app.labels.no_confidentialities').toString()] as [string, string],
      ];
    }

    get moduleNameId(): number | undefined {
      if (!this.createModule.startsWith('module_record')) {
        return;
      }

      return parseInt(this.createModule.replace('module_record', ''));
    }

    async beforeMount(): Promise<void> {
      if (!this.moduleNameId) {
        return;
      }

      this.form = { confidential_users: '[]', ...this.values };
      const { data: selectedModuleName } = await this.$api.getModuleName(
        this.moduleNameId,
        { only: moduleNameAttributes },
        { cache: true }
      );

      this.selectedModuleName = selectedModuleName;

      if (isFinite(Number(selectedModuleName?.sub_form_id))) {
        const { data: questions } = await this.$api.getSubFormQuestions(
          {
            filters: { sub_form_section: { sub_form_id: selectedModuleName?.sub_form_id }, active: true },
            per_page: -1,
            sort: 'sub_form_section.index,index',
          },
          { cache: true }
        );
        this.formQuestions = questions;
      }
    }
  }
