import { render, staticRenderFns } from "./integrated-modules-role-settings.vue?vue&type=template&id=51cbb74e&scoped=true&lang=pug"
import script from "./integrated-modules-role-settings.vue?vue&type=script&lang=ts"
export * from "./integrated-modules-role-settings.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51cbb74e",
  null
  
)

export default component.exports