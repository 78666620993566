
  import { Component, Vue } from 'vue-property-decorator';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import { toaster } from '@app/utils/toaster';
  import type { DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import type { Event } from '@app/models/event';

  import EventOccurrences from '../event-occurrences/event-occurrences.vue';

  @Component({ components: { EventOccurrences } })
  export default class ScheduledEventsEventOccurrences extends Vue {
    scheduledEvent: Nullable<ScheduledEvent> = null;

    get scheduledEventId(): number {
      return +this.$route.params.scheduledEventId;
    }

    get eventFilters(): DonesafeIndexApiOptions<Event> {
      return { scheduled_events: { id: this.scheduledEventId } };
    }

    get eventIds(): number[] {
      return (
        (this.scheduledEvent?.event?.id && [
          this.scheduledEvent.event.id,
          ...(this.scheduledEvent.event.chained_events?.map(({ id }) => id) || []),
        ]) ||
        []
      );
    }

    beforeMount(): void {
      this.$api
        .getScheduledEvent(this.scheduledEventId, { only: [{ event: ['id', { chained_events: ['id'] }] }] }, { cache: true })
        .then(({ data }) => {
          this.scheduledEvent = data;
        })
        .catch(() => {
          toaster({ text: 'Schedule does not exist', icon: 'error' });
          this.$router.go(-1);
        });
    }
  }
