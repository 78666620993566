
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import MobileAppConfigForm from '../../../components/admin/mobile-apps/mobile-app-config-form.vue';
  import type { MobileAppConfig } from '@app/models/mobile-app/config';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppConfigForm, DsModal } })
  export default class NewConfigPage extends Vue {
    @Ref() readonly form?: MobileAppConfigForm;

    config = {};
    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-configs',
        params: { reload: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<MobileAppConfig>): void {
      this.$api
        .createMobileAppConfig(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
