
  import type { ExpenseCategory, ExpenseCategoryBase } from '@app/models/expense-category';
  import { keyBy, pick } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, ValidationObserver, ValidationProvider } })
  export default class ExpenseEstimatesTabEditModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop({ type: Array, default: () => [] }) expenseCategories!: Pick<ExpenseCategory, 'id' | 'name' | keyof ExpenseCategoryBase>[];
    @Prop({ type: Array, default: () => [] }) expenseCategoryOverwrites!: ExpenseCategoryBase[];

    form: Record<number, ExpenseCategoryBase> = {};

    initForm(): void {
      const overwritesHash = keyBy(this.expenseCategoryOverwrites, 'expense_category_id');
      this.form = this.expenseCategories.reduce((memo, category) => {
        const overwrite = overwritesHash[category.id];
        return {
          [category.id]: pick(overwrite || category, ['default_max_sessions', 'spend_per_claim', 'spend_per_unit', 'active']),
          ...memo,
        };
      }, {});
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }
  }
