
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { UserCollection } from '@app/models/user-collection';

  @Component({ components: { PaperTrailsModalLink, DsDropdown } })
  export default class UserCollectionActions extends Vue {
    @Prop(Object) readonly userCollection!: UserCollection;
  }
