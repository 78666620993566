
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import HybridForm from './hybrid-form.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      DsModal,
      HybridForm,
    },
  })
  export default class NewHybridPage extends Vue {
    @Prop(Number) moduleNameId!: number;
    @Ref() readonly form?: HybridForm;

    moduleName: Nullable<ModuleName> = null;
    showModal = true;

    get modalTitle(): string {
      return this.$t('tenant.settings.hybrid.form_managed.form.new_sub_form_caption', { name: this.moduleName?.display });
    }

    submit(newSubFormList: Partial<SubFormList>): void {
      if (newSubFormList.title) {
        this.$api
          .createManagedSubFormList({ module_name_id: this.moduleNameId, title: newSubFormList.title })
          .then(() => {
            toaster(this.$t('tenant.settings.hybrid.form_managed.form.sub_form_created', { name: this.moduleName?.display }));
            this.closeModal('reload');
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    beforeMount(): void {
      this.$api.getModuleName(this.moduleNameId, { only: ['display'] }).then((response) => {
        this.moduleName = response.data;
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-hybrid-module-page',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
