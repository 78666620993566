
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop } from 'vue-property-decorator';
  import type { MenuCollection } from '@app/models/menu-collection';
  import type { ModuleName, TranslatedType } from '@app/models/module-name';
  import { isLegacyModule, ModuleType, moduleTypeOptions } from '@app/models/module-name';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { mixins } from 'vue-class-component';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import TextHighlight from 'vue-text-highlight';
  import { Tooltip } from 'uiv';

  @Component({ components: { DsDropdown, TextHighlight, Tooltip } })
  export default class ModuleNameLeftHandBar extends mixins(WithSearchableDsDropdown) {
    @Prop(Object) readonly moduleName!: ModuleName;

    menuCollection: Nullable<MenuCollection> = null;
    hierarchyTypeRequested = false;
    solutionTypeRequested = false;
    hierarchyTypeOptions: TranslatedType[] = [];
    solutionTypeOptions: TranslatedType[] = [];
    moduleNames: Pick<ModuleName, 'id' | 'plural_display'>[] = [];
    dropdownOpen = false;

    get searchableModules() {
      return this.getSearchableItems(this.moduleNames, 'plural_display');
    }

    get configurationType(): string | undefined {
      if (this.moduleName) {
        if (isLegacyModule(this.moduleName)) {
          if (this.moduleName.name === 'Procedure') {
            return this.$t('tenant.admin.module_names.sections.general.type_knowledge_base');
          } else {
            return this.$t('tenant.admin.module_names.sections.general.type_legacy');
          }
        } else {
          return moduleTypeOptions(this.$t)[this.moduleName.module_type];
        }
      }
    }

    get accountStore() {
      return useAccountStore();
    }

    get publicNewModuleRecordsUrl() {
      return `${window.location.origin}/module_records/public_new?module_name_id=${this.moduleName.id}`;
    }

    get embedModuleRecordsUrl() {
      return `${window.location.origin}/module_records/embed?module_name_id=${this.moduleName.id}`;
    }

    get hardDeleteAttachments() {
      return this.accountStore.data.hard_delete_attachments || this.moduleName?.hard_delete_attachments;
    }

    get solutionType(): string | undefined {
      const solution = this.solutionTypeOptions.find((type) => type.system_code === this.moduleName.solution_type);
      const hierarchy = this.hierarchyTypeOptions.find((type) => type.system_code === this.moduleName.hierarchy_type);
      if (solution && hierarchy) {
        return `${solution?.display} (${hierarchy?.display})`;
      }
    }

    get isPublished(): boolean {
      return !!this.moduleName.published_module_version.installation_date;
    }

    get amsOptionsRequested(): boolean {
      return this.solutionTypeRequested && this.hierarchyTypeRequested;
    }

    get publishedName(): string {
      return `${this.moduleName.published_module_version?.internal_name} (${this.moduleName.published_module_version?.identifier})`;
    }

    get isIntegrated(): boolean {
      return this.moduleName.module_type === ModuleType.integrated;
    }

    moduleLink(moduleNameId: number) {
      const basePath = '/admin/settings/module_names/';
      const path = this.$route.path;
      const regex = new RegExp(`${basePath}${this.moduleName.id}/([^/]+)`);
      const match = path.match(regex);
      return `${basePath}${moduleNameId}/${match?.[1]}/`;
    }

    beforeMount(): void {
      if (this.moduleName.menu_collection_id) {
        this.$api
          .getMenuCollection(this.moduleName.menu_collection_id, { only: ['name'] }, { cache: true })
          .then(({ data }) => {
            this.menuCollection = data;
          })
          .catch(() => {
            // TODO: redirect to fallback route and show notice after fully switched to vue-router
          });
      }
      this.$api.getHierarchyTypes({}, { cache: true }).then(({ data }) => {
        this.hierarchyTypeOptions = data;
        this.hierarchyTypeRequested = true;
      });
      this.$api.getSolutionTypes({}, { cache: true }).then(({ data }) => {
        this.solutionTypeOptions = data;
        this.solutionTypeRequested = true;
      });
      this.$api
        .getModuleNames(
          { only: ['id', 'plural_display'], per_page: -1, sort: 'plural_display', filters: { active: true } },
          { cache: true }
        )
        .then(({ data }) => {
          this.moduleNames = data;
        });
    }
  }
