import { render, staticRenderFns } from "./comment-form-modal.vue?vue&type=template&id=199fc5af&scoped=true&lang=pug"
import script from "./comment-form-modal.vue?vue&type=script&lang=ts"
export * from "./comment-form-modal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199fc5af",
  null
  
)

export default component.exports