
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ApiLookupOptions, ApiRequestOptions } from '@app/models/question-options/shared';
  import Select2 from '../../../select2.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { PartialDeep } from 'type-fest';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';

  @Component({ components: { Select2, FormField, EntitySelector } })
  export default class ApiLookupConfigurator extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseQuestionOptions & PartialDeep<ApiLookupOptions>>;

    @Prop(Object) subForm!: SubForm;

    apiRequestQuestions: SubFormQuestion[] = [];

    get question(): Maybe<SubFormQuestion> {
      return this.apiRequestQuestions.find((q) => q.code === this.value.config.api_lookup?.api_request_question_code);
    }

    get isSingleSelectQuestion(): boolean {
      return FieldType.single_select === this.question?.field_type;
    }

    get isSelfReferencing(): boolean {
      return (this.question?.config as ApiRequestOptions)?.api_request?.self_referencing === 'true';
    }

    get resultPathHelp() {
      return !this.isSelfReferencing && this.isSingleSelectQuestion ? "Available options can be: 'text' or 'id'" : '';
    }

    optionsUpdated(apiRequestQuestions: SubFormQuestion[]) {
      this.apiRequestQuestions = apiRequestQuestions;
    }

    get questionFilters() {
      return {
        active: true,
        sub_form_section: { sub_form_id: this.subForm.id },
        config: { mode: 'api_request' },
      };
    }
  }
