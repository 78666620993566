
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { Saml } from '@app/models/saml';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown } })
  export default class SamlIndexActions extends Vue {
    @Prop(Object) readonly saml!: Saml;
  }
