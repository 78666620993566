
  import { Component, Vue, Model, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { ReportBlob, ReportColumn, BlobColumn } from '@app/models/super-report';
  import { isColumnsEqual } from '@app/models/super-report';
  import { getCsrfToken } from '@app/utils/get-csrf-token';

  @Component({
    components: {
      DsModal,
      isColumnsEqual,
      getCsrfToken,
    },
  })
  export default class AdminReportColumnsRenameModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly reportBlob!: ReportBlob;
    @Prop(Array) readonly reportColumns!: ReportColumn[];

    newColumns: BlobColumn[] = [];
    get columnsJson(): string {
      const columnsData = this.newColumns.map((col) => {
        let columnData: BlobColumn = { key: col.key, column_name: col.column_name, column_type: col.column_type };

        if (col.source) {
          columnData.source = col.source;
        }

        return columnData;
      });

      return JSON.stringify(columnsData);
    }

    get filtersJson(): string {
      return JSON.stringify(this.reportBlob.filters);
    }

    get graphJson(): string {
      return JSON.stringify(this.reportBlob.graph);
    }

    get cfrToken(): string {
      return getCsrfToken();
    }

    beforeMount(): void {
      this.newColumns = [...this.reportBlob.table.columns];
    }

    submitForm() {
      this.newColumns = this.newColumns.map((column) => {
        if (!column.column_name.trim()) {
          const originalColumn = this.reportColumns.find(
            (orig) => orig.key === column.key && orig.column_type === column.column_type && orig.source === column.source
          );

          if (originalColumn && originalColumn.column_name) {
            return { ...column, column_name: originalColumn.column_name };
          }
        }
        return column;
      });

      this.$nextTick(() => {
        const form = this.$refs.form as HTMLFormElement;
        form.submit();
      });
    }

    defaultName(column: BlobColumn): string {
      const reportColumn = this.reportColumns.find((reportColumn) => isColumnsEqual(reportColumn, column));
      return reportColumn ? reportColumn.column_name : column.column_name;
    }
  }
