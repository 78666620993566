
  import { Component } from 'vue-property-decorator';
  import type { Indicator } from '@app/models/indicator';
  import type { ModuleName } from '@app/models/module-name';

  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    indicatable_id: string;
    indicator_id: string;
  }

  @Component({ components: { Select2, FormField } })
  export default class RemoveRecordIndicatorEventForm extends BaseEventForm<FormValues> {
    indicators: Indicator[] = [];
    moduleNames: Pick<ModuleName, 'id' | 'name' | 'display'>[] = [];

    get indicatorOptions(): { group?: string; label: string; value: string }[] {
      return this.indicators
        .map((indicator) => ({
          value: `${indicator.id}`,
          label: `${indicator.indicator_set?.name}: ${indicator.name}`,
          group: this.moduleNames.find((moduleName) => moduleName.name === indicator.indicator_set?.module_name)?.display,
        }))
        .filter((indicator) => indicator.group);
    }

    beforeMount(): void {
      this.form = { ...this.values };
      const moduleNamesPromise = this.$api.getModuleNames(
        {
          filters: { active: true },
          only: ['id', 'name', 'display'],
          per_page: -1,
        },
        { cache: true }
      );
      const indicatorsPromise = this.$api.getIndicators(
        {
          filters: {
            active: true,
          },
          sort: 'name',
          only: ['id', 'name', { indicator_set: ['name', 'module_name'] }],
          per_page: -1,
        },
        { cache: true }
      );
      Promise.all([moduleNamesPromise, indicatorsPromise]).then(([moduleNames, indicators]) => {
        this.moduleNames = moduleNames.data;
        this.indicators = indicators.data;
      });
    }
  }
