
  import UserProfileModalInstance from '@app/components/admin/users/user-profile-modal-instance.vue';
  import type DashboardPaneGroup from '@app/models/dashboard-pane-group';
  import { useAccountStore } from '@app/stores/account';
  import { Component } from 'vue-property-decorator';
  import DashboardGrid from '@app/components/dashboard-grid.vue';
  import { keyBy, orderBy, mapValues, filter, isEmpty } from 'lodash';
  import type { GridStackOptions } from 'gridstack';
  import {
    DEFAULT_DYNAMIC_DASHBOARD_SETTINGS,
    generatePersonalDashboardConfig,
    MAX_SIX_BY_SIX_SUB_GRID_HEIGHT,
    MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT,
    ONE_COLUMN_BREAKPOINT,
  } from '@app/components/admin/dashboards/utils';
  import Blocking from '@app/mixins/blocking';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { Subscription } from 'rxjs';
  import { debounceTime } from 'rxjs/operators';
  import reloadDynamicDashboard from '@app/reloadDynamicDashboard';
  import { UI_OBSERVER } from '@app/services/global-observer';

  @Component({ components: { DsIconText, DashboardGrid } })
  export default class PersonalDashboardPage extends Blocking {
    dashboardPaneGroups: DashboardPaneGroup[] = [];
    accessiblePaneIds: number[] = [];
    reloadSubscription?: Subscription;
    resizeSubscription?: Subscription;
    modalVisible = false;

    get accountStore() {
      return useAccountStore();
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get subGridTitlesById() {
      if (this.hideDisplayNames) return;

      return mapValues(
        keyBy(this.allDashboardPanes, ({ dashboard_pane_group_id, id }) => `${dashboard_pane_group_id}_${id}`),
        'name'
      );
    }

    get allDashboardPanes() {
      return this.dashboardPaneGroups.flatMap((group) =>
        orderBy(
          filter(group.dashboard_panes, ({ config, active, id }) => !isEmpty(config) && active && this.accessiblePaneIds.includes(id)),
          'priority',
          'asc'
        )
      );
    }

    get hideDisplayNames() {
      return !!this.accountOrUserDynamicDashboardSettings?.hide_display_names;
    }

    get stretchPanes() {
      return !!this.accountOrUserDynamicDashboardSettings?.stretch_panes;
    }

    get hideWhiteSpace() {
      return this.accountOrUserDynamicDashboardSettings?.white_space === 'hide';
    }

    get accountOrUserDynamicDashboardSettings() {
      if (this.currentUserStore.hasDynamicDashboardPersonalization) {
        return this.currentUserStore.data?.dynamic_dashboard_settings;
      }

      return isEmpty(this.accountStore.data.dynamic_dashboard_settings)
        ? DEFAULT_DYNAMIC_DASHBOARD_SETTINGS
        : this.accountStore.data.dynamic_dashboard_settings;
    }

    get widgets() {
      return this.allDashboardPanes.map((dashboardPane) => dashboardPane?.widgets || []).flat();
    }

    get priorityOrder() {
      return this.accountOrUserDynamicDashboardSettings?.white_space === 'priority_order';
    }

    get personalDashboardConfig() {
      const { stretchPanes, sixBySixSubGridHeight, hideWhiteSpace, hideDisplayNames, priorityOrder } = this;

      return generatePersonalDashboardConfig(this.allDashboardPanes, {
        stretchPanes,
        sixBySixSubGridHeight,
        hideWhiteSpace,
        hideDisplayNames,
        priorityOrder,
      });
    }

    get sixBySixSubGridHeight() {
      return this.hideDisplayNames ? MAX_SIX_BY_SIX_WIDGET_BOUNDING_HEIGHT : MAX_SIX_BY_SIX_SUB_GRID_HEIGHT;
    }

    get gridOptions(): GridStackOptions {
      return {
        children: this.personalDashboardConfig,
        oneColumnModeDomSort: true,
        margin: this.hideDisplayNames ? -1 : 10,
      };
    }

    get sort() {
      if (this.currentUserStore.hasDynamicDashboardPersonalization) {
        return 'user_priority';
      }

      return 'priority';
    }

    personalise() {
      this.modalVisible = true;
      new UserProfileModalInstance({
        parent: this,
        propsData: {
          userId: this.currentUserStore.data?.id,
          tabName: 'dynamic_dashboards',
        },
      })
        .$on('hook:destroyed', () => {
          this.modalVisible = false;
        })
        .$mount();
    }

    refreshOnBreakpoint(prevMode: 'mobile' | 'desktop') {
      const newMode = window.innerWidth <= ONE_COLUMN_BREAKPOINT ? 'mobile' : 'desktop';
      if (prevMode !== newMode) this.loadDashboard();
      return newMode;
    }

    beforeMount() {
      this.loadDashboard();

      this.reloadSubscription = reloadDynamicDashboard.pipe(debounceTime(1000)).subscribe(this.refreshDashboard);

      if (this.hideWhiteSpace) {
        let prevMode: 'mobile' | 'desktop' = window.innerWidth <= ONE_COLUMN_BREAKPOINT ? 'mobile' : 'desktop';

        this.resizeSubscription = UI_OBSERVER.resize$
          .pipe(debounceTime(250))
          .subscribe(() => (prevMode = this.refreshOnBreakpoint(prevMode)));
      }
    }

    beforeDestroy(): void {
      this.reloadSubscription?.unsubscribe();
      this.resizeSubscription?.unsubscribe();
    }

    async refreshDashboard() {
      if (this.currentUserStore?.data?.id) {
        const {
          data: { dynamic_dashboard_settings },
        } = await this.$api.getTenantUser(this.currentUserStore.data.id, { only: ['dynamic_dashboard_settings'] });

        this.currentUserStore.$patch((state) => state.data && (state.data.dynamic_dashboard_settings = dynamic_dashboard_settings));
      }

      this.loadDashboard();
    }

    loadDashboard() {
      this.blocking(async () => {
        const { sort } = this;
        const { data } = await this.$api.getDashboardPaneGroups(
          {
            only: [
              'id',
              'priority',
              'user_priority',
              { dashboard_panes: ['widgets', 'id', 'name', 'config', 'size', 'dashboard_pane_group_id', 'priority', 'active'] },
            ],
            sort,
            filters: { accessible: true, with_config: true },
            per_page: -1,
          },
          { cache: true }
        );

        this.dashboardPaneGroups = data;

        const { data: dashboardPanes } = await this.$api.getDashboardPanes(
          { only: ['id'], filters: { accessible: true }, per_page: -1 },
          { cache: true }
        );

        this.accessiblePaneIds = dashboardPanes.map(({ id }) => id);
      });
    }
  }
