
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { SubFormQuestionReference } from '@app/components/admin/questions/edit/models';
  import SubFormQuestionSelector from '@app/components/admin/questions/edit/sub-form-question-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import FormField from '../../edit/form-field.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import { MAIN_FORM_MODULE_NAME } from '@app/constants';
  import type { DateRestrictionOptions } from '../models';

  @Component({ components: { SubFormQuestionSelector, Select2, FormField } })
  export default class RestrictOptions extends Vue {
    @Model('input') value!: SubFormQuestion<BaseQuestionOptions & DateRestrictionOptions>;

    @Prop(Object) readonly subForm!: SubForm;

    mainFormId: SubForm['id'] | null = null;

    get questionReference() {
      return this.value.config.restrict_to;
    }

    onQuestionChange(change: SubFormQuestionReference) {
      if (!this.value.config.restrict_to) {
        return;
      }

      this.$$patch(this.value.config.restrict_to, change);
    }

    onRestrictTypeChange(type?: 'past' | 'future') {
      if (!type || type === 'past' || type === 'future') {
        this.$$patch(this.value.config.restrict_to, {
          if_empty: undefined,
          operator: undefined,
          sub_form_question_code: undefined,
          sub_form_list_id: undefined,
          transform: undefined,
          if_missing: undefined,
        });
      }
    }

    get restrictionOptions() {
      const base = {
        future: 'Future Dates',
        past: 'Past Dates',
        in_form: 'In Form',
      };

      const subForm = {
        main_form: 'Main Form',
        most_recent_form: 'Most Recent Form',
        highest_form: 'Highest From',
        lowest_form: 'Lowest Form',
      };

      const approvalOrWorkflow = {
        original_form: 'Original Form',
      };

      return this.hashToOptions({
        ...base,
        ...(this.onMainForm ? {} : subForm),
        ...(['approval', 'workflow'].includes(this.subForm.sub_form_type) ? approvalOrWorkflow : {}),
      });
    }

    get operatorOptions() {
      return this.hashToOptions({
        '<=': 'Less or Equal To',
        '<': 'Less Than',
        '>': 'Greater Than',
        '>=': 'Greater or Equal To',
      });
    }

    get ifEmptyOptions() {
      return this.hashToOptions({
        disable: 'Disable',
        allow: 'Allow Free Entry',
      });
    }

    get questionSelector() {
      const restrictType = this.value.config?.restrict_to?.restrict_type;

      if (!restrictType) {
        return false;
      }

      return ['in_form', 'main_form', 'original_form', 'most_recent_form', 'highest_form', 'lowest_form', 'main_form'].includes(
        restrictType
      );
    }

    get selectForm() {
      const restrictType = this.value.config?.restrict_to?.restrict_type;

      if (!restrictType) {
        return false;
      }

      return !['in_form', 'main_form', 'original_form'].includes(restrictType);
    }

    get questionSubForm() {
      const restrictType = this.value.config?.restrict_to?.restrict_type;

      if (!restrictType) {
        return null;
      }

      if (restrictType === 'main_form') {
        this.ensureMainFormId();
        return this.mainFormId;
      }

      if (restrictType === 'original_form') {
        this.ensureFormBeingApprovedId();
        return this.formBeingApprovedId;
      }

      if (restrictType === 'in_form') {
        return this.subForm.id;
      }

      return null;
    }

    hashToOptions(hash: Dictionary<string>) {
      return Object.keys(hash).map((k) => [k, hash[k]]);
    }

    async ensureMainFormId() {
      const {
        data: [{ sub_form_id: mainFormId }],
      } = await this.$api.getModuleNames({ filters: { name: this.subForm.module_name }, only: ['id', 'sub_form_id'] });

      if (!mainFormId) {
        return;
      }

      this.mainFormId = mainFormId;
    }

    formBeingApprovedId: SubForm['id'] | null = null;

    async ensureFormBeingApprovedId() {
      const { data: [{ id: subFormListId }] = [] } = await this.$api.getSubFormLists({
        filters: { approval_sub_form_id: this.subForm.id },
      });
      const {
        data: [{ id: formBeingApprovedId }],
      } = await this.$api.getSubForms({ filters: { sub_form_lists: { id: subFormListId } } });

      this.formBeingApprovedId = formBeingApprovedId;
    }

    get onMainForm(): boolean {
      return this.subForm.module_name === MAIN_FORM_MODULE_NAME;
    }
  }
