import { render, staticRenderFns } from "./predefined-value-copy-input.vue?vue&type=template&id=73356486&scoped=true&lang=pug"
import script from "./predefined-value-copy-input.vue?vue&type=script&lang=ts"
export * from "./predefined-value-copy-input.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73356486",
  null
  
)

export default component.exports