
  import DsIconText from '@app/components/ds-icon-text.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { SuperReport } from '@app/models/super-report';

  @Component({ components: { DsIconText, PaperTrailsModalLink, DsDropdown } })
  export default class AdminReportPageActions extends Vue {
    @Prop(Object) readonly report!: SuperReport;
    // TODO: check if edit link necessary
  }
