
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class RadioButtons extends Vue {
    @Model('input') value!: string;

    @Prop(Array) options!: { key: string; label: string }[];
    @Prop(Boolean) disabled?: boolean;
  }
