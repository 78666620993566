import type { BaseEntity } from './base-entity';
import type { RoleModuleName } from './role-module-name';
import type { SuperReport } from './super-report';
import type { ExtendedPermission } from '@app/models/extended-permission';
import type { Permission } from './permission';
import type { RoleProfile } from './role-profile';

export type TabPermissionAccess = '' | 'edit' | 'view';
export const ACTION_APPROVAL_RELATIONSHIPS = ['assigned_approver', 'approved_by'];

export interface ProfilePermission {
  basic?: TabPermissionAccess;
  documents?: TabPermissionAccess;
  learning?: TabPermissionAccess;
  notification_subscriptions?: TabPermissionAccess;
  pay_details?: TabPermissionAccess;
  permission_based?: TabPermissionAccess;
  personal_details?: TabPermissionAccess;
  tags?: TabPermissionAccess;
}

export interface PermissionProvider {
  extended_permissions?: Pick<ExtendedPermission, 'name' | 'concept_name'>[];
  managerial_hierarchy_access?: boolean;
  permissions?: Permission[];
}

export interface Role extends BaseEntity {
  active: boolean;
  can_create_contacts: boolean;
  confidential_access: boolean;
  dashboard_id?: number;
  dashboard_ids?: number[];
  dashboard_pane_ids?: number[];
  dynamic_dashboard_access?: boolean;
  dynamic_dashboard_customisation?: boolean;
  excluded_dashboard_pane_ids?: number[];
  extended_permissions?: ExtendedPermission[];
  line_approval_percentage: number;
  managerial_hierarchy_access_level?: number;
  max_overall_approval_cost: number;
  module_managerial_access?: Record<number, number>;
  name: string;
  organization_access: boolean;
  overall_approval_percentage: number;
  permissions?: Permission[];
  profile_permissions?: {
    others?: ProfilePermission & { collection: 'all' | 'team' | 'organization' | '' };
    self?: ProfilePermission;
  };
  report_builder_access: boolean;
  role_module_names?: RoleModuleName[];
  role_profile_ids?: number[];
  role_profiles?: RoleProfile[];
  super_report_ids?: number[];
  super_reports?: SuperReport[];
  system: boolean;
  system_code: string;
  user_count?: number;
  user_directory_access: boolean;
  user_ids?: number[];
  user_personal_details_access: boolean;
}
