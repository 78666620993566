
  import { Component, Ref } from 'vue-property-decorator';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type DashboardPane from '@app/models/dashboard-pane';
  import type { ModuleName } from '@app/models/module-name';
  import { Tooltip, tooltip } from 'uiv';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { mixins } from 'vue-class-component';
  import { BaseTable } from '@app/components/base-table';
  import JoinEntities from '@app/components/join-entities.vue';
  import DashboardPaneModal from '@app/components/admin/dashboard-panes/dashboard-pane-modal.vue';
  import WithDashboardPaneForm from '@app/components/admin/dashboard-panes/with-dashboard-pane-form';
  import PageLayout from '../dashboard-panes/page-layout.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { AdminPermissionFeature } from '@app/models/admin-permission';

  @Component({
    components: {
      DashboardPaneModal,
      BaseTable,
      PageLayout,
      JoinEntities,
      Tooltip,
      DsDropdown,
      DsIconText,
    },
    directives: {
      tooltip,
    },
  })
  export default class ModuleDashboardPanesPage extends mixins(WithDashboardPaneForm) {
    @Ref() readonly table?: BaseTable<DashboardPane>;

    manager: Nullable<ListManager<DashboardPane>> = null;
    refreshed = 0;

    moduleName: Nullable<Pick<ModuleName, 'id' | 'display' | 'plural_display' | 'name'>> = null;

    get settingsEnabled() {
      return this.currentUserStore.featureEnabled([
        AdminPermissionFeature.reporting,
        AdminPermissionFeature.module_config,
        AdminPermissionFeature.roles_and_tags,
      ]);
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    getManager(): ListManager<DashboardPane> {
      return new ListManager<DashboardPane>({
        fetchDataFunction: (params) => {
          const only = ['id', 'name', 'description', 'priority', 'role_profile_ids', 'size', 'active'] as OnlyOptions<DashboardPane>;

          return this.$api.getDashboardPanes({ ...params, filters: { module_name_id: this.moduleNameId }, only }, { cache: true });
        },
        afterFetch: () => (this.refreshed += 1),
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'priority', sortField: 'priority' }],
        per_page: -1,
        fields: [
          { title: '', name: 'sortable_handle' },
          { title: this.$t('tenant.admin.dashboard_panes.priority'), name: 'priority', sortField: 'priority' },
          { title: this.$t('tenant.admin.dashboard_panes.display_name'), name: 'name', sortField: 'name' },
          { title: this.$t('tenant.admin.dashboard_panes.description'), name: 'description' },
          { title: this.$t('tenant.admin.dashboard_panes.role_profiles'), name: 'role_profiles' },
          { title: this.$t('tenant.admin.dashboard_panes.size'), name: 'size', sortField: 'size' },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active' },
          { title: '', name: 'open' },
          { title: '', name: 'actions' },
        ],
      });
    }

    beforeMount(): void {
      this.manager = this.getManager();
      this.loadModuleName();
    }

    async saveIndexes() {
      if (!this.manager) return;

      await this.$api.updateDashboardPanesIndexes({ data: this.manager.items.map(({ id }, i) => ({ id, index: i + 1 })) });
      this.refresh();
    }

    refresh() {
      this.$api.cache.clear();
      this.table?.debounceUpdate();
    }

    async loadModuleName(): Promise<void> {
      if (this.moduleNameId) {
        const only: OnlyOptions<ModuleName> = ['id', 'name', 'display', 'plural_display'];
        const { data } = await this.$api.getModuleName(Number(this.moduleNameId), { only }, { cache: true });
        this.moduleName = data;
      }
    }
  }
