
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';
  import { Component, Model, Prop, Watch, Ref } from 'vue-property-decorator';
  import type { AppBundleValidationResponse } from '@app/models/app-bundles/validation-response';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import appBundleUniqueCodeRule from '@app/validators/app-bundle-unique-code-rule';
  import PlaceholderProfiles from '@app/components/admin/events/forms/placeholder-profiles.vue';
  import type { AppBundle } from '@app/models/app-bundle';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';
  import type { ModuleName } from '@app/models/module-name';
  import type { SvgLibraryTemplate } from '@app/models/svg-library';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import DsModal from '@app/components/ds-modal.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import { API_NULL } from '@app/constants';
  import { compact, uniq } from 'lodash';
  import { mixins } from 'vue-class-component';
  import WithBootbox from '@app/components/admin/user-collections/with-bootbox';

  import EntitySelector from '../entity-selector.vue';

  @Component({ components: { EntitySelector, DsModal, ValidationObserver, ValidationProvider, PlaceholderProfiles, FormField } })
  export default class AppBundleFormModal extends mixins(WithBootbox) {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly appBundle!: AppBundle;
    @Prop(String) mode?: 'loading' | 'create' | 'edit' | 'show' | 'errors';
    @Prop(Object) data?: AppBundleValidationResponse;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<AppBundle> = {};
    availablePlaceholderProfiles: PlaceholderProfile[] = [];
    availableSvgTemplates: SvgLibraryTemplate[] = [];

    get title(): string {
      switch (this.mode) {
        case 'create':
          return this.$t('app.labels.publish_new_app');
        case 'edit':
          return this.$t('app.labels.edit_app');
        case 'show':
          return this.$t('app.labels.show_app');
        case 'loading':
          return this.$t('app.labels.loading');
        case 'errors':
          return this.$t('app.labels.app_bundles.labels.errors');
      }
      return this.$t('app.labels.loading');
    }

    get moduleNameFilters(): DonesafeFilterOptions<ModuleName> {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES.join(','),
      };
    }

    get moduleNames(): string | undefined {
      return this.form.module_names?.map((module) => module.display).join(', ');
    }

    get dashboards(): string | undefined {
      return this.form.dashboards?.map((dashboard) => dashboard.name).join(', ');
    }

    get dashboardPanes(): string | undefined {
      return this.form.dashboard_panes?.map(({ name }) => name).join(', ');
    }

    get regulatoryReportConfigs(): string | undefined {
      return this.form.regulatory_report_configs?.map((config) => config.name).join(', ');
    }

    get svgTemplatesLabel(): string | undefined {
      return this.form.svg_library_templates?.map((template) => template.name).join(', ');
    }

    get standaloneDashboardPaneFilter() {
      return { module_name_id: API_NULL };
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.$nextTick(() => {
        this.validator?.reset();
      });
    }

    archivedNameTemplate(appBundle: Pick<AppBundle, 'id' | 'name' | 'active'>) {
      return inactiveTitleTemplate(appBundle, 'name', 'app.labels.archived_name');
    }

    async beforeMount(): Promise<void> {
      extend('app-bundle-unique-code', appBundleUniqueCodeRule);
      this.initForm();
      this.availablePlaceholderProfiles = await this.fetchAvailablePlaceholderProfiles();
      this.availableSvgTemplates = await this.fetchAvailableSvgTemplates();
    }

    async fetchAvailablePlaceholderProfiles() {
      const { data } = await this.$api.getPlaceholderProfiles(
        {
          filters: { active: true },
          only: ['id', 'name'],
          sort: 'name',
        },
        { cache: true }
      );

      return data;
    }

    async fetchAvailableSvgTemplates() {
      const { data } = await this.$api.getSvgTemplates(
        {
          only: ['id', 'name'],
          sort: 'name',
        },
        { cache: true }
      );

      return data;
    }

    async submitForm(published: boolean): Promise<void> {
      await this.checkMissingSvgTemplates();
      this.submit(published);
    }

    async checkMissingSvgTemplates(): Promise<void> {
      if (!this.form.module_name_ids?.length) {
        return;
      }

      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          filters: { id: this.form.module_name_ids },
          include: ['sub_forms', 'main_form'],
          only: [{ sub_forms: ['id'], main_form: ['id'] }],
        },
        { cache: true }
      );

      const formIds = moduleNames.flatMap(({ sub_forms = [], main_form }) => [...sub_forms.map(({ id }) => id), main_form?.id]).join(',');

      const { data: questions } = await this.$api.getSubFormQuestions(
        {
          filters: {
            field_type: 'svg_selector',
            sub_form_section: {
              sub_form_id: formIds,
            },
          },
          only: ['config'],
        },
        { cache: true }
      );

      const templateIds = uniq(compact(questions.map(({ config: { svg_template_id } }) => svg_template_id)));
      const missingTemplateIds = templateIds.filter((id) => !this.form.svg_library_template_ids?.includes(+id));
      if (!missingTemplateIds.length) {
        return;
      }

      const { data: missingTemplates } = await this.$api.getSvgTemplates(
        { filters: { id: missingTemplateIds }, only: ['name'] },
        { cache: true }
      );

      const addMissing = await this.confirm(missingTemplates.map(({ name }) => name).join(', '), {
        backdrop: false,
        title: this.$t('app.labels.missing_svg_templates'),
        buttons: {
          confirm: { label: this.$t('app.labels.add_svg_templates'), className: 'btn-primary' },
          cancel: { label: this.$t('app.labels.skip_svg_templates'), className: 'btn-warning' },
        },
      });

      if (addMissing) {
        const svg_library_template_ids = this.form.svg_library_template_ids || [];
        this.form = { ...this.form, svg_library_template_ids: [...missingTemplateIds.map(Number), ...svg_library_template_ids] };
      }
    }

    initForm(): void {
      this.form = { published: true, ...this.appBundle };
    }

    submit(published: boolean): void {
      this.form = { ...this.form, published };
      this.$nextTick(() => {
        this.validator?.validate().then((result: boolean) => {
          if (result) {
            this.$emit('submit', { ...this.form, published: published });
          } else if (!published && this.form.published) {
            this.form = { ...this.form, published: !published };
          }
        });
      });
    }

    async archive(): Promise<void> {
      if (await this.confirm(this.$t('app.labels.are_you_sure'), { backdrop: false })) {
        this.$emit('submit', { ...this.form, active: false });
      }
    }

    onEdit(): void {
      this.$emit('edit-bundle', this.appBundle);
    }

    onDownload(): void {
      this.$emit('download-file', this.form.id);
    }

    focusName(): void {
      if (this.mode === 'show' || this.mode === 'errors') {
        return;
      }

      this.$nextTick(() => (this.$refs.name as HTMLInputElement).focus());
    }

    updateFormValue(bundle: AppBundle): void {
      this.form = { ...bundle };
    }
  }
