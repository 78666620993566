import type { AxiosPromise } from 'axios';
import type { BaseEntity } from '@app/models/base-entity';
import { ListManagerBase } from './list-manager-base';
import type { ListManagerPaginationParams, ListManagerSortItem, ListManagerStaticParams } from './types';
import type { DonesafeFilterOptions, FilterValue } from '@app/services/donesafe-api-utils';
import orderBy from 'lodash/orderBy';

export class ListManagerStatic<T = BaseEntity, F = {}> extends ListManagerBase<T> {
  localData?: T[];

  constructor(params: ListManagerStaticParams<T, F>) {
    super({ ...params });
    this.localData = params?.localData || [];
    this.customFilters = { ...params.customFilters } as DonesafeFilterOptions<T, F>;
  }

  refresh(): void {
    this.fetchData(this.sortOrder || [], { current_page: 1, per_page: this.per_page as number });
  }

  fetchData(sort: ListManagerSortItem[], pagination: ListManagerPaginationParams): AxiosPromise<T[]> {
    this.fetching = true;
    this.items = this.applySort(this.applyFilters(this.localData || [], this.customFilters), sort);
    this.fetching = false;
    this.total = this.items.length;
    this.pagination = this.makePagination(
      this.total,
      pagination.per_page || this.per_page || 25,
      pagination.current_page || this.page || 1
    );
    return new Promise((resolve) => {
      resolve({
        data: this.items,
        headers: {
          total: `${this.items.length}`,
          per_page: `${this.per_page}`,
        },
        status: 200,
        statusText: 'OK',
        config: {},
      });
    });
  }

  applyFilters(data: T[], filters: DonesafeFilterOptions<T>): T[] {
    if (!data?.length) return [];
    if (!filters) return data;

    let filteredData = data;
    Object.entries(filters).forEach(([filterKey, filterValue]) => {
      if (filterValue) filteredData = this.applyFilter(filteredData, filterKey, filterValue);
    });

    return filteredData;
  }

  applyFilter(array: T[], filterKey: string, filterValue?: FilterValue | FilterValue[]): T[] {
    const values = (Array.isArray(filterValue) ? filterValue : [filterValue]).map((v) => `${v}`);
    if (!values.length) return array;

    const invert = String(filterKey)[0] === '!';
    const cleanKey = (invert ? String(filterKey).substring(1) : filterKey) as keyof T;
    return array.filter((entity) => {
      if (entity[cleanKey] == null) return false;

      const index = values.indexOf(`${entity[cleanKey]}`);
      return invert ? index < 0 : index > -1;
    });
  }

  applySort(array: T[], sort?: ListManagerSortItem[]): T[] {
    if (!sort) return array;
    const sortKeys = sort.map((item) => item.sortField);
    const sortOrders = sort.map((item) => item.direction);
    return orderBy(array, sortKeys, sortOrders);
  }
}
