
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import FormsUsingLogicSet from './forms-using-logic-set.vue';
  import type { LogicSetDefinitions, LogicSet } from '../models/types';
  import type { AxiosPromise, AxiosResponse } from 'axios';
  import type { CalculatorQuestionOptions } from '@app/models/question-options/calculator-question-options';
  import { objectCloneDeep } from '@app/utils/object-clone-deep';

  @Component({ components: { FormsUsingLogicSet } })
  export default class LogicSetSelector extends Vue {
    @Prop({ required: true }) readonly setFormulas!: (id: string, logicSetDefinitions: LogicSetDefinitions) => void;
    @Prop({ required: true }) readonly formsUsingLogicSetEndPoint!: (logicSetId: string) => AxiosPromise;
    @Prop({ required: true }) readonly archiveFormula!: (logicSetId: string, callback: () => void) => void;
    @Prop({ required: true }) readonly updateQuestionConfig!: (
      newConfigItems: Partial<CalculatorQuestionOptions>
    ) => CalculatorQuestionOptions;

    loading = true;
    selectedLogicSetId = 'new';
    logicSets: LogicSet[] = [];
    logicSetDefinitionsTemplate = {
      components: {},
      parentRule: {
        name: '',
        color: '#000000',
        formula: [],
      },
      rules: {},
      variables: {},
      nameValidation: '',
    };
    filePath = '';
    importFile: Nullable<File> = null;
    formsUsingLogicSetLoading = false;
    formsUsingLogicSet = [];
    importing = false;
    importErrors = [];
    nameValidationString = '';

    getLogicSets(): void {
      this.$api
        .getLogicSets({})
        .then(({ data }) => {
          this.logicSets = data;
          this.selectedLogicSetId = 'new';
          this.formsUsingLogicSet = [];
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    createNewLogicSet(): void {
      if (this.nameValidation()) {
        const newLogicSet = {
          logic_sets: {
            definitions: JSON.stringify(this.logicSetDefinitionsTemplate),
          },
        };

        this.$api
          .createLogicSet(newLogicSet)
          .then((response) => {
            this.setFormulas(String(response.data.id), response.data.definitions);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    nameValidation(): boolean {
      if (this.logicSetDefinitionsTemplate.parentRule.name) {
        this.nameValidationString = '';
        return true;
      } else {
        this.nameValidationString = 'Name cannot be blank.';
        return false;
      }
    }

    changeSelection(value: string): void {
      const logicSetId = value;

      this.selectedLogicSetId = logicSetId;
      this.importErrors = [];

      this.$nextTick(() => {
        if (logicSetId !== 'new' && logicSetId !== 'import') this.getFormsUsingLogicSet();
      });
    }

    changeNewLogicSetName(value: string): void {
      const logicSetDefinitionsTemplate = objectCloneDeep(this.logicSetDefinitionsTemplate);
      logicSetDefinitionsTemplate.parentRule.name = value;

      this.logicSetDefinitionsTemplate = logicSetDefinitionsTemplate;
    }

    selectFormula(): void {
      if (this.selectedLogicSetId === 'new') {
        this.createNewLogicSet();
        return;
      }

      const logicSet = this.logicSets.find((logicSet) => String(logicSet.id) == this.selectedLogicSetId);

      if (logicSet) {
        this.setFormulas(String(logicSet.id), logicSet.definitions);
      }
    }

    handleFileSelect(value: string, file: File): void {
      this.filePath = value;
      this.importFile = file;
    }

    handleImport(): void {
      if (!this.importFile) {
        return;
      }
      const fileToImport = new FormData();
      fileToImport.append('file', this.importFile);

      this.importing = true;
      this.importErrors = [];

      this.$nextTick(() => {
        this.$api
          .importLogicSet(fileToImport)
          .then((response) => {
            this.importing = false;

            const responseJson = response.data;
            this.setFormulas(String(responseJson.result.id), responseJson.result.definitions);
          })
          .catch(({ data }) => {
            this.importing = false;
            this.importErrors = data.result;
          });
      });
    }

    getFormsUsingLogicSet(): void {
      this.formsUsingLogicSetLoading = true;
      this.$nextTick(() => {
        this.formsUsingLogicSetEndPoint(this.selectedLogicSetId)
          .then((response: AxiosResponse) => {
            this.formsUsingLogicSetLoading = false;
            this.formsUsingLogicSet = response.data.result;
            this.updateQuestionConfig({ logic_set_id: this.selectedLogicSetId });
          })
          .catch((error: Error) => {
            console.error(error);
            this.formsUsingLogicSetLoading = false;
          });
      });
    }

    mounted(): void {
      this.getLogicSets();
    }
  }
