
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import bootbox from 'bootbox';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Widget } from '@app/models/widget';
  import { WidgetType } from '@app/models/widget';
  import ActionsWidgetSettings from '@app/components/admin/dashboards/widgets/actions-widget-settings.vue';
  import DomoWidgetSettings from '@app/components/admin/dashboards/widgets/domo-widget-settings.vue';
  import FollowedRecordsWidgetSettings from '@app/components/admin/dashboards/widgets/followed-records-widget-settings.vue';
  import IframeWidgetSettings from '@app/components/admin/dashboards/widgets/iframe-widget-settings.vue';
  import ImageWidgetSettings from '@app/components/admin/dashboards/widgets/image-widget-settings.vue';
  import LinkWidgetSettings from '@app/components/admin/dashboards/widgets/link-widget-settings.vue';
  import RecordIndexWidgetSettings from '@app/components/admin/dashboards/widgets/record-index-widget-settings.vue';
  import ReportCountWidgetSettings from '@app/components/admin/dashboards/widgets/report-count-widget-settings.vue';
  import ReportDataWidgetSettings from '@app/components/admin/dashboards/widgets/report-data-widget-settings.vue';
  import ReportGraphWidgetSettings from '@app/components/admin/dashboards/widgets/report-graph-widget-settings.vue';
  import SubFormIndexWidgetSettings from '@app/components/admin/dashboards/widgets/sub-form-index-widget-settings.vue';
  import TextWidgetSettings from '@app/components/admin/dashboards/widgets/text-widget-settings.vue';
  import VideoWidgetSettings from '@app/components/admin/dashboards/widgets/video-widget-settings.vue';
  import { toaster } from '@app/utils/toaster';
  import { titleize } from '@app/utils/titleize';
  import { cloneDeep } from 'lodash';

  import Select2 from './../../../select2.vue';

  interface WidgetTypeItem {
    label: string;
    value: WidgetType;
  }

  @Component({
    components: {
      DsModal,
      ValidationObserver,
      ValidationProvider,
      Select2,
      ActionsWidgetSettings,
      DomoWidgetSettings,
      FollowedRecordsWidgetSettings,
      IframeWidgetSettings,
      ImageWidgetSettings,
      LinkWidgetSettings,
      RecordIndexWidgetSettings,
      ReportCountWidgetSettings,
      ReportDataWidgetSettings,
      ReportGraphWidgetSettings,
      SubFormIndexWidgetSettings,
      TextWidgetSettings,
      VideoWidgetSettings,
    },
  })
  export default class WidgetFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly widget?: Widget;
    @Prop(String) readonly title!: string;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Widget> = {};
    get widgetTypes(): WidgetTypeItem[] {
      return (
        Object.keys(WidgetType).map((key): WidgetTypeItem => {
          const value: WidgetType = WidgetType[key as keyof typeof WidgetType];
          return { label: titleize(key), value: value };
        }) ?? []
      );
    }

    get currentWidgetSettingsComponent() {
      const widgetTypeComponentMap = {
        [WidgetType.actions]: ActionsWidgetSettings,
        [WidgetType.domo]: DomoWidgetSettings,
        [WidgetType.followed_records]: FollowedRecordsWidgetSettings,
        [WidgetType.iframe]: IframeWidgetSettings,
        [WidgetType.image]: ImageWidgetSettings,
        [WidgetType.link]: LinkWidgetSettings,
        [WidgetType.record_index]: RecordIndexWidgetSettings,
        [WidgetType.report_count]: ReportCountWidgetSettings,
        [WidgetType.report_data]: ReportDataWidgetSettings,
        [WidgetType.report_graph]: ReportGraphWidgetSettings,
        [WidgetType.sub_form_index]: SubFormIndexWidgetSettings,
        [WidgetType.text]: TextWidgetSettings,
        [WidgetType.video]: VideoWidgetSettings,
      };

      if (this.form.widget_type) {
        return widgetTypeComponentMap[this.form.widget_type] || null;
      }
      return null;
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    onSelect(): void {
      this.form = { ...this.form, options: {}, translated_options: {} };
      this.validator?.reset();
    }

    deleteWidget(): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('app.views.admin.widgets.edit.delete_widget'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.widget?.id &&
            this.$api
              .deleteWidget(this.widget.id)
              .then(() => {
                this.$api.cache.clear();
                toaster(this.$t('app.views.admin.widgets.edit.successfully_deleted'));
                this.$emit('hide', 'reload');
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    handleWidgetOptionsUpdate(options: Widget['options']) {
      this.form = { ...this.form, options };
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    initForm(): void {
      const defaultValues = {
        widget_type: WidgetType.report_graph,
        options: {},
        translated_options: {},
      };
      this.form = cloneDeep({
        ...defaultValues,
        ...this.widget,
      });
    }
  }
