
  import { useAccountStore } from '@app/stores/account';
  import type { Subscription } from 'rxjs';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { dispatchers, init, subjects } from '@app/services/courier';

  @Component
  export default class OneHsiCourier extends Vue {
    @Prop(String) readonly targetUrl?: string;

    styleElement: HTMLElement | null = null;
    subscription: Subscription | null = null;

    get accountStore() {
      return useAccountStore();
    }

    applyStyles(style: string): void {
      if (!this.styleElement) return;

      this.styleElement.innerHTML = style;
    }

    mounted(): void {
      if (!this.accountStore.data.one_hsi_ui) return;
      if (!this.targetUrl) return;

      const element = document.getElementById('one-hsi-content');
      if (!element) return;

      this.styleElement = document.createElement('style');
      document.querySelector('body')?.appendChild(this.styleElement);

      init({
        recipient: window.parent,
        targetOrigin: this.targetUrl,
        originWhitelist: [this.targetUrl],
        boundingElement: element,
      });
      dispatchers.location.dispatch(window.location.href);
      this.$router.afterEach((to) => dispatchers.location.dispatch(`${window.location.origin}${to.fullPath}`));
      this.subscription = subjects.styles.subscribe(this.applyStyles);
    }

    beforeDestroy(): void {
      this.subscription?.unsubscribe();
      if (!this.styleElement) return;

      document.querySelector('body')?.removeChild(this.styleElement);
      this.styleElement = null;
    }
  }
