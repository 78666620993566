
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { toaster } from '@app/utils/toaster';

  interface RenameForm {
    newName: string;
    oldName: string;
  }

  @Component({ components: { DsModal, ValidationObserver, ValidationProvider } })
  export default class ModuleRenameFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(String) readonly oldName!: string;
    @Prop(Number) readonly id!: number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<RenameForm> = {};

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$api
            .renameModuleName(this.id, this.form.newName)
            .then(({}) => {
              toaster({
                text: this.$t('tenant.admin.module_names.rename.success'),
                position: 'top-right',
              });
              this.$emit('change', this.form.newName);
              this.$emit('input', false);
            })
            .catch(({ data }) => {
              toaster({ text: data?.error, position: 'top-right', icon: 'error' });
            });
        }
      });
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.validator?.reset();
    }

    initForm(): void {
      this.form = { oldName: this.oldName, newName: '' };
    }
  }
