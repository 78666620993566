
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import EntitySelector from '../../entity-selector.vue';
  import Select2 from '../../select2.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import { toaster } from '@app/utils/toaster';

  const DEFAULT_DISPLAY_TYPES = [
    { label: 'Hide Score', value: 'none' },
    { label: 'As Percent', value: 'percent' },
    { label: 'As Fraction', value: 'fraction' },
  ];
  type DisplayOption = { label: string; value: string };

  @Component({
    components: { EntitySelector, Select2 },
  })
  export default class ModuleScoringOptionsPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    activeScoreBandProfiles: Partial<ScoreBandProfile>[] = [];
    form: Partial<ModuleName> = {};
    moduleName: Nullable<ModuleName> = null;

    submitForm(): void {
      this.$api
        .updateModuleName(
          Number(this.moduleNameId),
          { ...this.form, score_band_profile_id: this.form.score_band_profile_id || null },
          { only: ['id', 'show_options', 'score_band_profile_id'] }
        )
        .then((response) => {
          this.form = { ...response.data };
          return response;
        })
        .then(() => toaster('Success'))
        .catch((response) => toaster({ text: response.data.error, position: 'top-right', icon: 'error' }));
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(
        Number(this.moduleNameId),
        { only: ['score_band_profile_id', 'show_options'] },
        { cache: true }
      );
      this.moduleName = data;
      this.initForm();
    }

    changeDisplayMethod(): void {
      const defaultDisplayType =
        this.form.show_options?.show_total_scoring === 'score_band' ? 'none' : this.form.show_options?.show_total_scoring;
      this.form = {
        ...this.form,
        show_options: {
          ...this.form.show_options,
          show_total_scoring: this.form.score_band_profile_id ? 'score_band' : defaultDisplayType,
        },
      };
    }

    get displayTypes(): DisplayOption[] {
      if (this.form.score_band_profile_id) {
        const selectedScoreBandProfile = this.activeScoreBandProfiles.find((band) => `${band.id}` === `${this.form.score_band_profile_id}`);
        return [...DEFAULT_DISPLAY_TYPES, ...[{ label: `Banding Profile: ${selectedScoreBandProfile?.name}`, value: 'score_band' }]];
      } else {
        return DEFAULT_DISPLAY_TYPES;
      }
    }

    initForm(): void {
      this.form = { ...this.moduleName };
      this.$api
        .getScoreBandProfiles(
          {
            only: ['id', 'name'],
            filters: {
              module_name_id: this.moduleNameId,
              active: true,
            },
            page: 1,
            per_page: 25,
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.activeScoreBandProfiles = data;
        });
    }
  }
