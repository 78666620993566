import { render, staticRenderFns } from "./related-main-form.vue?vue&type=template&id=6bd1df8d&scoped=true&lang=pug"
import script from "./related-main-form.vue?vue&type=script&lang=ts"
export * from "./related-main-form.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd1df8d",
  null
  
)

export default component.exports