
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import { Component, Model, Vue, Ref, Prop } from 'vue-property-decorator';
  import type { ScoringGroup } from '@app/models/scoring-group';
  import type { SubForm } from '@app/models/sub-form';
  import { ScoringViewOption } from '@app/models/sub-form';
  import type { ScoreBandProfile } from '@app/models/score-band-profile';
  import { CustomScoring } from '@app/models/sub-form-options';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';

  import CustomScoringPanel from '../scoring-custom/custom-scoring-panel.vue';
  import EntitySelector from '../entity-selector.vue';
  import Select2 from '../select2.vue';

  interface StringOption {
    code: string;
    label: string;
  }

  @Component({
    components: { FormField, Select2, EntitySelector, CustomScoringPanel, ValidationObserver, ValidationProvider },
  })
  export default class ScoringTypePanel extends Vue {
    @Model('input') value!: SubForm;
    @Prop(Boolean) readonly showScoringTypeSelector?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    // TODO: use entity-selector later
    activeScoreBandProfiles: Partial<ScoreBandProfile>[] = [];

    get defaultScoringViewOptions(): { code: ScoringViewOption; label: string }[] {
      return [
        { code: ScoringViewOption.number, label: this.$t('tenant.admin.sub_forms.scoring_view_options.number') },
        {
          code: ScoringViewOption.percentage,
          label: this.$t('tenant.admin.sub_forms.scoring_view_options.percentage'),
        },
        { code: ScoringViewOption.both, label: this.$t('tenant.admin.sub_forms.scoring_view_options.both') },
        {
          code: ScoringViewOption.hide_scoring_view_option,
          label: this.$t('tenant.admin.sub_forms.scoring_view_options.hide_scoring_view_option'),
        },
      ];
    }

    get scoringTypeOptions(): StringOption[] {
      return [
        { code: 'addition', label: this.$t('tenant.admin.sub_forms.scoring_types.addition') },
        {
          code: 'multiplication',
          label: `${this.$t('tenant.admin.sub_forms.scoring_types.multiplication')} (${this.$t(
            'tenant.admin.sub_forms.scoring_types.skips_zeros'
          )})`,
        },
      ];
    }

    get scoringViewOptions(): StringOption[] {
      if (this.value.score_band_profile_id) {
        const selectedScoreBandProfile = this.activeScoreBandProfiles.find(
          (band) => `${band.id}` === `${this.value.score_band_profile_id}`
        );
        return [
          ...this.defaultScoringViewOptions,
          ...[
            {
              label: this.$t('tenant.admin.sub_forms.scoring_view_options.score_band', {
                band_name: selectedScoreBandProfile?.name,
              }),
              code: 'score_band',
            },
          ],
        ];
      } else {
        return this.defaultScoringViewOptions;
      }
    }

    get scoringGroupFilters(): object {
      return {
        module_name: this.value.module_name,
        default: false,
      };
    }

    get showCustomScoringPanel(): boolean {
      return this.value.scoring_type === 'addition';
    }

    beforeMount(): void {
      this.initForm();
    }

    initForm(): void {
      if (!this.value.options || !this.value.options.custom_scoring_type) {
        this.$$patch(this.value, {
          options: {
            ...this.value.options,
            custom_scoring_type: CustomScoring.none,
          },
        });
      }
      this.$api
        .getScoreBandProfiles(
          {
            only: ['id', 'name'],
            filters: {
              module_name: { name: this.value.module_name },
              active: true,
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.activeScoreBandProfiles = data;
        });
    }

    changeDisplayMethod(): void {
      const defaultScoringViewOption =
        this.value.scoring_view_option === ScoringViewOption.score_band ? ScoringViewOption.number : this.value.scoring_view_option;
      this.$$patch(this.value, {
        scoring_view_option: this.value.score_band_profile_id ? ScoringViewOption.score_band : defaultScoringViewOption,
      });
    }

    changeDisplayMethodByScoring(scoringGroup: Pick<ScoringGroup, 'id' | 'score_band_profile_id'>): void {
      if (scoringGroup.score_band_profile_id) {
        this.$$patch(this.value, {
          scoring_view_option: ScoringViewOption.score_band,
          score_band_profile_id: scoringGroup.score_band_profile_id,
        });
      }
    }
  }
