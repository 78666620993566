
  import GlobalDateFilter from '@app/components/filters/global-date-filter.vue';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Model, Vue, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import moment from 'moment';
  import ActivitiesTable from '@app/components/activities/activities-table.vue';
  import { API_NULL, ACTIVITY_CONCEPT } from '@app/constants';
  import type { Activity } from '@app/models/activity';
  import type { ModuleName } from '@app/models/module-name';
  import type { IndexFilter } from '@app/models/index-filter';
  import type { Dictionary } from '@app/models/dictionary';
  import { toaster } from '@app/utils/toaster';

  import ScopeFilter from '../../filters/scope-filter.vue';
  import ActivitiesStatusFilter from '../../filters/activities-status-filter.vue';

  @Component({ components: { GlobalDateFilter, ActivitiesTable, DsModal, ScopeFilter, ActivitiesStatusFilter } })
  export default class ActionsFilterFormModal extends Vue {
    @Model('input') value!: boolean;
    @Ref() table?: ActivitiesTable;

    initialized = false;
    indexFilter: Nullable<Partial<IndexFilter>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get indexOptions(): string[] {
      return (this.accountStore.data.action_options.index_options || []).map((o) => o.key);
    }

    get sort(): string | undefined {
      return this.indexFilter?.sorting_order?.sort;
    }

    get reverse(): boolean {
      return !!this.indexFilter?.sorting_order?.reverse;
    }

    get defaultIndexFilter(): Partial<IndexFilter<Activity, DonesafeActivitiesApiExtraOptions>> {
      return { filter: { scope_filter: 'mine', date: 'week', status_filter: 'open' } };
    }

    get actionsPseudoModule(): Pick<ModuleName, 'name' | 'plural_display'> {
      return { name: ACTIVITY_CONCEPT, plural_display: this.$t('app.labels.actions').toString() };
    }

    saveIndexFilter(): void {
      const filterParams: Dictionary<string> = {};
      const filters = this.table?.table?.allFilters as Dictionary<string | Date>;

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          if (filters[key] instanceof Date) {
            filterParams[key] = moment(filters[key]).format(this.accountStore.dateTimePickerFormatWithTimeZone);
          } else {
            filterParams[key] = filters[key] as string;
          }
        }
      });
      const sort = this.table?.sortOrder?.[0]?.sortField;
      const reverse = this.table?.sortOrder?.[0]?.direction === 'desc';
      const updateIndexFilter: Partial<IndexFilter> = {
        index: 'actions',
        filter: filterParams,
        sorting_order: (sort && { sort, reverse }) || undefined,
      };
      const createIndexFilter = { ...updateIndexFilter, global: true };

      const promise = this.indexFilter?.id
        ? this.$api.updateIndexFilter(this.indexFilter.id, updateIndexFilter)
        : this.$api.createIndexFilter(createIndexFilter);
      promise
        .then((response) => {
          this.indexFilter = response.data;
          toaster({
            text: this.$t('tenant.admin.involvements.visual_options.default_filters_saved'),
          });
          this.$emit('input', false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
        });
    }

    mounted(): void {
      this.$api.getIndexFilters({ filters: { user_id: API_NULL, index: 'actions' } }, { cache: true }).then(({ data }) => {
        this.indexFilter = data[0] || this.defaultIndexFilter;
      });
    }
  }
