
  import { Component } from 'vue-property-decorator';
  import type { GridStackWidget } from 'gridstack';
  import BaseDashboardGrid from '@app/components/admin/dashboards/base-dashboard-grid';

  import type { SaveDashboardResult } from './admin/dashboards/utils';
  import { convertDBWidgetToGridStackWidget } from './admin/dashboards/utils';

  @Component({})
  export default class DashboardGrid extends BaseDashboardGrid {
    async save(): Promise<SaveDashboardResult> {
      const widgetsToSave = this.grid?.save(false) as GridStackWidget[];
      return { widgetsToSave };
    }

    mounted(): void {
      this.initGridstack({ children: this.widgets.map(convertDBWidgetToGridStackWidget), ...this.gridstackOptions });
      this.afterGridStackInit();
    }
  }
