
  import { Component, Vue } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';

  // TODO: refactor
  interface TabDetail {
    routeCommonKey: string;
    routeName: string;
    title: string;
  }

  @Component({ components: { Tab, Tabs } })
  export default class AdminUserSettingsPage extends Vue {
    tabIndex = 0;

    get tabs(): TabDetail[] {
      return [
        {
          routeName: 'user-settings-tab',
          title: this.$t('tenant.admin.settings.menus.user_settings.tabs.user_settings'),
          routeCommonKey: 'user-settings',
        },
        {
          routeName: 'gender-list-tab',
          title: this.$t('tenant.admin.settings.menus.user_settings.tabs.gender_list'),
          routeCommonKey: 'gender-list',
        },
        {
          routeName: 'workplace-industry-tab',
          title: this.$t('tenant.admin.settings.menus.user_settings.tabs.workplace_industry'),
          routeCommonKey: 'workplace-industry',
        },
        {
          routeName: 'employment-types-tab',
          title: this.$t('tenant.admin.settings.menus.user_settings.tabs.employment_types'),
          routeCommonKey: 'employment-types',
        },
      ];
    }

    beforeMount(): void {
      this.tabIndex = this.tabs.findIndex((tab) => this.$route?.name?.includes(tab.routeCommonKey)) || 0;
    }

    beforeChangeTab(indexFrom: number, indexTo: number, done: (err?: unknown) => void): void {
      this.$router.push({ name: this.tabs[indexTo].routeName });
      done();
    }
  }
