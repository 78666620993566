
  import { Component, Prop } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { SubFormListType } from '@app/models/sub-form-list';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { pick } from 'lodash';

  import EventQuestionFields from '../event-question-fields.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  @Component({
    components: {
      EventInputMethodSwitch,
      EntitySelector,
      Select2,
      AdvancedAutosuggest,
      EventQuestionFields,
      FormField,
    },
  })
  export default class SubFormCompletionEventForm extends BaseEventForm<Dictionary<string>> {
    // TODO; remove this from props
    @Prop(Array) readonly dateOptions?: [string, string][];

    formReady = false;
    moduleNameId: Nullable<number> = null;
    selectedModuleName: Nullable<Pick<ModuleName, 'id' | 'module_tabs' | 'sub_forms'>> = null;
    selectedSubFormList: Nullable<SubFormList> = null;
    selectedSubForm: Nullable<Pick<SubForm, 'sub_form_type'>> = null;
    formQuestions: SubFormQuestion[] = [];
    moduleNameAttributes: OnlyOptions<ModuleName> = [
      'id',
      'display',
      'sub_forms',
      {
        module_tabs: ['id', 'active'],
        sub_forms: ['id'],
      },
    ];
    subFormListAttributes: OnlyOptions<SubFormList> = [
      'id',
      'sub_form_list_type',
      'sub_form_ids',
      'approval_sub_form_id',
      {
        sub_forms: ['id', 'title'],
        module_tab: ['id', 'module_name'],
        approval_sub_form: ['id', 'title'],
      },
    ];

    optionsSet = {
      moduleNames: false,
      subFormLists: false,
      subForms: false,
    };
    loaded = false;

    get isApprovalForm(): boolean {
      return this.hasApprovalForm && this.form.sub_form_id === `${this.selectedSubFormList?.approval_sub_form?.id}`;
    }

    get hasMultipleForms(): boolean {
      return this.hasApprovalForm || (this.selectedSubFormList?.sub_form_ids?.length || 0) > 1;
    }

    get hasApprovalForm(): boolean {
      return !!this.selectedSubFormList?.approval_sub_form?.id;
    }

    get moduleNameFilters(): DonesafeFilterOptions<ModuleName> {
      return {
        active: true,
        '!name': HARDCODED_MODULE_CODES.join(','),
      };
    }

    get subFormListFilters(): DonesafeFilterOptions<SubFormList> {
      if (this.selectedModuleName) {
        return {
          module_tab_id: this.selectedModuleName.module_tabs?.filter((t) => t.active)?.map((t) => t.id),
          sub_form_list_type: [SubFormListType.normal, SubFormListType.approval, SubFormListType.workflow],
        };
      }
      return {};
    }

    get subFormFilters(): DonesafeFilterOptions<SubForm> {
      return { id: [...(this.selectedSubFormList?.sub_form_ids || []), this.selectedSubFormList?.approval_sub_form_id] };
    }

    get stageOptions(): string[][] {
      return [
        ['Draft', this.$t('app.labels.draft')],
        ['Complete', this.$t('app.labels.complete')],
      ];
    }

    get retriggerSubFormKey() {
      return JSON.stringify({ ...this.subFormFilters, sub_form_list_id: this.form.sub_form_list_id });
    }

    optionsLoaded(type: 'moduleNames' | 'subFormLists' | 'subForms'): void {
      this.optionsSet[type] = true;

      if (this.optionsSet.moduleNames && this.optionsSet.subFormLists && this.optionsSet.subForms) {
        this.$nextTick(() => {
          this.formLoaded();
        });
      }
    }

    // TODO: clear options when sfl or module name is changed
    onModuleNameSelect(moduleNameId?: number): void {
      this.resetForm();
      if (moduleNameId) {
        this.selectedSubFormList = null;
        this.$api.getModuleName(moduleNameId, { only: this.moduleNameAttributes }, { cache: true }).then(({ data }) => {
          this.selectedModuleName = data;
        });
      } else {
        this.selectedModuleName = null;
      }
    }

    clearSubFormListSelectValues() {
      this.form = pick(this.form, ['sub_form_id', 'sub_form_list_id']);
    }

    async onSubFormListSelect(subFormListId?: number | string, triggerSubFormIdReset = true): Promise<void> {
      if (subFormListId) {
        const { data: selectedSubFormList } = await this.$api.getSubFormList(
          Number(subFormListId),
          { only: this.subFormListAttributes },
          { cache: true }
        );
        this.selectedSubFormList = selectedSubFormList;

        if (triggerSubFormIdReset) {
          const firstSubFormId = `${selectedSubFormList.sub_forms?.[0].id}`;
          this.form.sub_form_id = firstSubFormId;
        }

        if (!this.selectedModuleName) {
          const { data: moduleNames } = await this.$api.getModuleNames({
            filters: { name: selectedSubFormList.module_tab?.module_name },
            only: this.moduleNameAttributes,
          });

          this.selectedModuleName = moduleNames[0];
          this.moduleNameId = this.selectedModuleName.id;
          (this.$refs.moduleNameSelector as EntitySelector<ModuleName>).setOptions(moduleNames);
        }
      } else {
        this.clearSubFormListSelectValues();
        this.selectedSubFormList = null;
        this.form.sub_form_id = undefined;
      }
      await this.onSubFormSelect(this.form.sub_form_id, triggerSubFormIdReset);
    }

    async onSubFormSelect(subFormId: unknown, triggerSubFormIdReset: boolean): Promise<void> {
      triggerSubFormIdReset && this.clearSubFormListSelectValues();
      this.formQuestions = [];
      if (subFormId && isFinite(Number(subFormId))) {
        const { data: questions } = await this.$api.getSubFormQuestions(
          {
            filters: { sub_form_section: { sub_form_id: subFormId }, active: true },
            per_page: -1,
            sort: 'sub_form_section.index,index',
          },
          { cache: true }
        );
        this.formQuestions = questions;
        const { data: subForm } = await this.$api.getSubForm(
          Number(subFormId),
          {
            filters: { only: ['id', 'sub_form_type'] },
          },
          { cache: true }
        );
        this.selectedSubForm = subForm;
      }
    }

    async beforeMount() {
      this.form = { stage: 'Complete', ...this.values };
      await this.onSubFormListSelect(this.form.sub_form_list_id, false);
      this.loaded = true;
    }

    resetForm(): void {
      this.form = {};
    }
  }
