
  import Select2 from '@app/components/select2.vue';
  import { FieldType } from '@app/models/sub-form-question';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import type { DonesafeFilterOptions } from '../../../../../services/donesafe-api-utils';
  import { displayQuestionTitleAndId } from '../../../../../utils/display-question';
  import FormField from '../../edit/form-field.vue';
  import RadioButtons from '../../edit/radio-buttons.vue';
  import type { DateCalculation, DateCalculationEmptyType, DateCalculationSourceType } from '../models';

  @Component({ components: { RadioButtons, Select2, FormField } })
  export default class DateCalculationOptions extends Vue {
    @Model('input') value!: { date_calculation: DateCalculation };

    @Prop(Number) readonly id!: number;
    @Prop(Object) readonly subForm!: SubForm;

    form: Partial<DateCalculation> = {};
    calculationOptions: [DateCalculationSourceType, string][] = [
      ['custom', this.$t('components.admin.questions.date_calculation_options.add_set_days_to_date_field')],
      ['question', this.$t('components.admin.questions.date_calculation_options.add_user_input_days_to_date_field')],
    ];
    datetimeQuestionOptions: [string, string][] = [];
    daysQuestionOptions: [string, string][] = [];
    emptyOptions: { key: DateCalculationEmptyType; label: string }[] = [
      {
        key: 'disabled',
        label: this.$t('components.admin.questions.date_calculation_options.if_empty.do_not_recalculate'),
      },
      { key: 'clear', label: this.$t('components.admin.questions.date_calculation_options.if_empty.clear') },
      {
        key: 'reinitialise',
        label: this.$t('components.admin.questions.date_calculation_options.if_empty.reinitialise'),
      },
    ];

    questionFilters(fieldTypes: FieldType[]): Nullable<DonesafeFilterOptions<SubFormQuestion>> {
      const base: DonesafeFilterOptions<SubFormQuestion> = {
        active: true,
        '!id': this.id,
        field_type: fieldTypes,
      };

      if (!this.subFormId) {
        return null;
      }

      return {
        ...base,
        sub_form_section: { sub_form_id: this.subFormId },
      };
    }

    get subFormId(): SubForm['id'] {
      return this.subForm.id;
    }

    get accountStore() {
      return useAccountStore();
    }

    @Emit('input')
    onChange(key: string, value: string | undefined) {
      return { ...this.value, date_calculation: { ...this.form, [key]: value } };
    }

    @Emit('input')
    onSourceTypeChange(value: DateCalculation['source_type'] | undefined) {
      if (!value) return { ...this.value, date_calculation: undefined };

      return { ...this.value, date_calculation: { ...this.form, source_type: value } };
    }

    async fetchQuestions(filters: DonesafeFilterOptions<SubFormQuestion>) {
      const { data: questions } = await this.$api.getSubFormQuestions(
        {
          per_page: -1,
          only: ['system_code', 'code', 'title', 'config', 'field_type', 'id'],
          filters: {
            active: true,
            ...filters,
          },
        },
        { cache: true }
      );

      return questions;
    }

    beforeMount() {
      this.form = { if_empty: 'clear', ...(this.value.date_calculation || {}) };

      const calculationDateTimeFilters = this.questionFilters([
        FieldType.date,
        FieldType.datetime,
        FieldType.calculator,
        FieldType.calculation_select,
        FieldType.calculation_text,
      ]);
      calculationDateTimeFilters &&
        this.fetchQuestions(calculationDateTimeFilters).then((questions) => {
          const filteredQuestions = questions.filter((q) => q.config.lookup?.readonly !== 'true');

          this.datetimeQuestionOptions = filteredQuestions
            .filter((q) => q.field_type === FieldType.date || q.field_type === FieldType.datetime)
            .map((question) => [question.system_code, displayQuestionTitleAndId(question)]);
          this.daysQuestionOptions = filteredQuestions
            .filter(
              (q) =>
                q.field_type === FieldType.calculator ||
                q.field_type === FieldType.calculation_select ||
                q.field_type === FieldType.calculation_text
            )
            .map((question) => [question.system_code, displayQuestionTitleAndId(question)]);
        });
    }
  }
