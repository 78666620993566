
  import { Component, Model, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import VJsoneditor from 'v-jsoneditor';
  import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: { DsModal, ValidationObserver, ValidationProvider, VJsoneditor },
  })
  export default class RegulatoryReportConfigFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly regulatoryReportConfig!: RegulatoryReportConfig;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<RegulatoryReportConfig> = {};
    jsonValid: boolean | null = null;

    editorOptions = {
      mode: 'code',
      modes: ['code', 'tree', 'preview'], // allowed modes
    };

    initForm(): void {
      this.form = {
        ...this.regulatoryReportConfig,
      };

      if (this.regulatoryReportConfig?.config) {
        this.jsonValid = true;
      }
    }

    onEditorError(): void {
      this.jsonValid = null;
    }

    onEditorInput(): void {
      this.jsonValid = true;
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }
  }
