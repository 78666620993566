
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import { pick } from 'lodash';

  import AutomationDefinitionDebugModal from './automation-definition-debug-modal.vue';

  @Component({
    components: { AutomationDefinitionDebugModal, DsIconText },
  })
  export default class AutomationDefinitionDebugModalLink extends Vue {
    @Prop({ type: String, default: () => 'div' }) readonly tag!: string;
    @Prop(Object) readonly automationDefinition!: AutomationDefinition;

    debugModal = false;

    get injectableArguments(): InjectableArguments {
      return {
        ...pick(this.automationDefinition || {}, [
          'concept_name',
          'sub_form_id',
          'involvement_id',
          'relationship_code',
          'regulatory_report_config_id',
        ]),
      };
    }
  }
