
  import { omit } from 'lodash';
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import type { Matrix } from '@app/models/matrix/matrix';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import MatrixForm from './matrix-form.vue';

  @Component({ components: { MatrixForm } })
  export default class MatrixEditPage extends Vue {
    @Prop({ type: [Number, String] }) readonly matrixId!: number | string;
    @Ref() readonly form?: MatrixForm;

    matrix: Nullable<Matrix> = null;
    loading = false;

    onFormSubmit(updatedMatrix: Partial<Matrix>): void {
      const params = omit(updatedMatrix, 'active'); // active is being handled by publish button => setActive
      if (this.matrix) {
        this.$api
          .updateMatrix(this.matrix.id, params)
          .then(({ data }) => {
            changeLocation(`/admin/settings/matrices/${data.id}`);
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      }
    }

    setActive(active: boolean) {
      if (this.matrix) {
        this.$api
          .updateMatrix(this.matrix.id, { active })
          .then(({ data }) => {
            const toast: string = data.active ? 'published' : 'unpublished';
            toaster({
              text: this.$t(`tenant.settings.matrices.edit.update.${toast}`),
            });
            this.matrix = { ...data };
          })
          .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
      }
    }

    beforeMount() {
      this.loading = true;
      this.$api
        .getMatrix(Number(this.matrixId))
        .then(({ data }) => {
          this.matrix = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
