
  import { Component, Prop } from 'vue-property-decorator';
  import { keyBy, orderBy } from 'lodash';
  import IndicatorItem from './indicator-item.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Indicator } from '@app/models/indicator';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { RecordIndicator } from '@app/models/record-indicator';

  @Component({ components: { IndicatorItem, DsDropdown } })
  export default class IndicatorsSet extends mixins(Blocking) {
    @Prop(Array) recordIndicators!: RecordIndicator[];
    @Prop(Object) readonly indicatorSet!: IndicatorSet;
    @Prop(Boolean) readonly canEdit!: boolean;
    @Prop(String) readonly recordType!: string;
    @Prop(Number) readonly recordId!: number;

    get indicatorsById(): Dictionary<Indicator> {
      return keyBy(this.indicatorSet.indicators, ({ id }) => id);
    }

    get availableIndicators(): Indicator[] {
      const recordIndicatorsByIndicatorId = keyBy(this.recordIndicators, ({ indicator_id }) => indicator_id);

      return (this.indicators || []).filter(({ active }) => active).filter(({ id }) => !recordIndicatorsByIndicatorId[id]);
    }

    get indicators(): Indicator[] {
      return orderBy(this.indicatorSet.indicators, 'index');
    }

    recordIndicatorColor(recordIndicator?: RecordIndicator): string {
      return recordIndicator ? this.indicatorsById[recordIndicator.indicator_id]?.color : '#777';
    }

    removeRecordIndicator(recordIndicator: RecordIndicator): void {
      this.blocking(async () => {
        const { data } = await this.$api.deleteRecordIndicator(recordIndicator.id);
        this.recordIndicators = this.recordIndicators.filter((ri) => ri.id !== data.id);
        window.location.reload();
      });
    }

    createRecordIndicator(indicator: Indicator): void {
      this.blocking(
        async () => {
          await this.$api.createRecordIndicator({
            indicator_id: indicator.id,
            indicatable_id: this.recordId,
            indicatable_type: this.recordType,
          });
          window.location.reload();
        },
        { exclusive: true }
      );
    }
  }
