
  import DsIconText from '@app/components/ds-icon-text.vue';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Tooltip } from 'uiv';
  import DsDropdown from '../../ds-dropdown.vue';
  import PaperTrailsModalLink from '../../paper-trails/paper-trails-modal-link.vue';
  import { showArchiveConfirmation } from '../../role-profile/utils';
  import ActionFilterIndexDropdown from '../actions/action-filter-index-dropdown.vue';
  import RelationshipRightsTable from '../roles/role-tab-details/relationship-rights-table.vue';
  import { epOptionsWithLabels } from '../roles/role-tab-details/utils';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import type { ConfidentialityType } from '@app/models/confidentiality-type';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import type { ModuleName } from '@app/models/module-name';
  import type { RoleProfile } from '@app/models/role-profile';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { getUpdateIndexParams } from '@app/utils/get-update-index-params';
  import JoinEntities from '@app/components/join-entities.vue';

  @Component({
    components: {
      JoinEntities,
      DsIconText,
      ActionFilterIndexDropdown,
      Tooltip,
      DsDropdown,
      PaperTrailsModalLink,
      RelationshipRightsTable,
      SimpleGridTable,
    },
  })
  export default class ModuleRoleProfilesPage extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: string | number;
    @Ref() readonly table?: SimpleGridTable<RoleProfile>;

    manager: Nullable<ListManager<RoleProfile>> = null;
    confidentialityTypes: Pick<ConfidentialityType, 'name' | 'id' | 'active'>[] = [];
    moduleName: Nullable<Pick<ModuleName, 'id' | 'name'>> = null;

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }

    updateIndex(): void {
      if (!this.manager) return;

      const indexParams = getUpdateIndexParams<RoleProfile>(this.manager);
      this.$api
        .updateRoleProfileIndexes(indexParams)
        .then(({ data }) => {
          this.$api.cache.clear();
          toaster({ text: this.$t('app.labels.order_saved') });
          this.table?.setData(data);
          this.table?.reload();
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
        });
    }

    getManager(): ListManager<RoleProfile> {
      return new ListManager<RoleProfile>({
        fetchDataFunction: (params) => {
          return this.$api.getRoleProfiles(
            {
              ...params,
              filters: { module_name_id: this.moduleNameId },
              include: ['extended_permissions', 'permissions', 'roles', 'dashboard_pane_ids'],
            },
            { cache: true }
          );
        },
        afterFetch: async () => {
          const { data: confidentialityTypes } = await this.$api.getConfidentialityTypes(
            { filters: { module_name_id: this.moduleNameId }, only: ['id', 'name', 'active', { module_name: ['name'] }], per_page: -1 },
            { cache: true }
          );
          this.confidentialityTypes = confidentialityTypes;
        },
        customFilters: { active: true },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'index', sortField: 'index' }],
        per_page: -1,
        fields: [
          {
            title: this.$t('app.labels.index'),
            titleClass: 'justify-content-center',
            name: 'index',
            sortField: 'index',
            dataClass: 'text-center',
            width: 'minmax(80px, 0.5fr)',
          },
          { title: 'Name', titleClass: '', name: 'name', sortField: 'name', width: 'minmax(80px, 1fr)' },
          {
            title: 'Can Create',
            name: 'can_create',
            titleClass: 'justify-content-center',
            dataClass: 'text-center',
            width: 'minmax(90px, 0.5fr)',
          },
          { title: '', name: 'involvements', dataClass: 'p-none', width: 'minmax(400px, 2fr)' },
          { title: 'Special Permissions', name: 'specials', width: 'minmax(200px, 1fr)' },
          { title: 'Linked Roles', name: 'roles', width: 'minmax(160px, 1fr)' },
          {
            title: 'Active',
            titleClass: 'justify-content-center',
            name: 'active',
            sortField: 'active',
            dataClass: 'text-center',
            width: '100px',
          },
          {
            title: '',
            name: 'actions',
            titleClass: 'justify-content-center',
            dataClass: 'text-center',
            width: 'minmax(50px, 0.5fr)',
          },
        ],
      });
    }

    beforeMount(): void {
      const only: OnlyOptions<ModuleName> = ['name'];
      this.$api.getModuleName(Number(this.moduleNameId), { only, show_all: true }, { cache: true }).then(({ data: moduleName }) => {
        this.moduleName = moduleName;
      });
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    canCreate(profile: RoleProfile): boolean {
      return !!profile.extended_permissions?.some((p) => p.name === 'global_create_access');
    }

    archive(roleProfile: RoleProfile): void {
      showArchiveConfirmation().then((confirmed) => {
        if (!confirmed) return;

        this.updateRoleProfile(roleProfile.id, { active: false }, this.$t('tenant.admin.role_profiles.form.archived'));
      });
    }

    restore(roleProfile: RoleProfile): void {
      this.updateRoleProfile(roleProfile.id, { active: true }, this.$t('tenant.admin.role_profiles.form.restored'));
    }

    updateRoleProfile(id: number, params: object, message: string): void {
      this.$api
        .updateRoleProfile(id, params)
        .then(() => {
          toaster(message);
          this.reloadData('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, icon: 'error' });
        });
    }

    extendedPermissions(roleProfile: RoleProfile): Pick<ExtendedPermission, 'name' | 'concept_name'>[] {
      return (roleProfile.extended_permissions || []).filter((p) => p.concept_name === this.moduleName?.name);
    }

    get extendedPermissionsOptions(): Record<string, string> {
      if (!this.moduleName) return {};

      return this.conceptOptionsMap(this.moduleName.name);
    }

    conceptOptionsMap(concept_name: string): Record<string, string> {
      const options = epOptionsWithLabels(concept_name, this.confidentialityTypes);

      return options.reduce((memo, ep) => {
        return {
          ...memo,
          [ep.name]: ep.label,
        };
      }, {});
    }

    get joinedDashboardPanesParams() {
      return { only: ['name', 'id'] };
    }
  }
