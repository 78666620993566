import { Vue, Component } from 'vue-property-decorator';
import I18n from '@app/i18n';
import { PlaceholderProfileType } from '../pages/admin/placeholder_profiles/utils';

@Component
export default class WithPlaceholderProfileType extends Vue {
  get profileTypeOptions(): [string, string][] {
    return [
      [PlaceholderProfileType.global_placeholder, I18n.t('tenant.settings.placeholder_profiles.form.global_placeholder')],
      [PlaceholderProfileType.oauth2, I18n.t('tenant.settings.placeholder_profiles.form.oauth2')],
    ];
  }
}
