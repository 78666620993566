
  import { API_NULL } from '@app/constants';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { orderBy, sortBy } from 'lodash';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import TextHighlight from 'vue-text-highlight';
  import qs from 'qs';
  import type { ExpensableModuleBudget } from '@app/models/expensable-module-budget';
  import type { ModuleName } from '@app/models/module-name';
  import type { Relationship } from '@app/models/relationship';

  interface ReportLinkFilter {
    comparison: string;
    key: string;
    type: string;
    value: string | number;
  }

  interface ReportLink {
    active?: boolean;
    children?: ReportLinksGroup[];
    filters?: ReportLinkFilter[];
    hint?: string;
    key: string;
    klass: string;
    label: string;
  }

  interface ReportLinksGroup {
    links: ReportLink[];
  }

  @Component({ components: { DsDropdown, TextHighlight } })
  export default class AdminReportPicker extends Vue {
    @Prop(String) readonly title?: string;

    moduleNames: ModuleName[] = [];
    search = '';

    opened = false;
    loaded = false;

    moduleBudgets: Pick<ExpensableModuleBudget, 'id' | 'module_name_id'>[] = [];
    relationships: Pick<Relationship, 'id' | 'from_module' | 'name' | 'code'>[] = [];

    get filteredReportLinkGroups(): ReportLinksGroup[] {
      const search = this.search.toLocaleLowerCase();
      return this.filterGroupsBySearch(this.allReportLinkGroups, search);
    }

    filterGroupsBySearch(groups: ReportLinksGroup[], search: string): ReportLinksGroup[] {
      if (!search) {
        return groups;
      }
      return groups.map((g) => ({ ...g, links: this.filterLinksBySearch(g.links, search) })).filter((g) => g.links.length);
    }

    filterLinksBySearch(links: ReportLink[], search: string): ReportLink[] {
      if (!search) {
        return links;
      }
      return links.filter(
        (link) => link.label.toLocaleLowerCase().includes(search) || this.filterGroupsBySearch(link.children || [], search).length
      );
    }

    get allReportLinkGroups(): ReportLinksGroup[] {
      const globalLinks = {
        links: [
          { label: 'Actions', klass: 'Activity' },
          { label: 'Comments', klass: 'Comment' },
          { label: 'Estimates', klass: 'Expensable::RecordBudgetCategory' },
          { label: 'Locations', klass: 'Location' },
          { label: 'Organizations', klass: 'Organization' },
          { label: 'Relations', klass: 'Relation' },
          { label: 'Users', klass: 'User' },
          { label: 'User Documents', klass: 'Document' },
          { label: 'User Location Tags', klass: 'UserLocationTag' },
          { label: 'Calculations', klass: 'Calculation' },
          { label: 'User Acknowledgment', klass: 'ProcedureAcknowledgment' },
          { label: 'User Involvements', klass: 'UserInvolvement' },
        ].map((l) => ({ ...l, key: `global-${l.klass}` })),
      };

      const moduleLinks = {
        links: [
          ...this.moduleNames.map((moduleName) => {
            const subFormLinks =
              moduleName.sub_forms?.map((f) => ({
                key: `form-${f.id}`,
                label: f.title,
                klass: 'SubFormCompletion',
                filters: [{ type: 'base_value', key: 'sub_form_id', value: f.id, comparison: '=' }],
                hint: f.active ? '' : `(${this.$t('app.labels.inactive')})`,
                active: f.active,
              })) || [];
            const relationshipLinks = this.moduleFromRelationships(moduleName).map((r) => ({
              key: `relationship-${r.code}`,
              label: `Relations of type: ${r.name}`,
              klass: 'RecordRelation',
              filters: [
                { type: 'base_value', key: 'relationship_code', value: r.code, comparison: '=' },
                { type: 'base_value', key: 'active', value: 'true', comparison: '=' },
              ],
            }));

            const budgetLinks = this.hasBudgets(moduleName)
              ? [
                  {
                    key: `module-${moduleName.id}-budget`,
                    label: 'Estimates',
                    klass: 'Expensable::RecordBudgetCategory',
                    filters: [{ type: 'base_value', key: 'module_name_id', value: moduleName.id, comparison: '=' }],
                  },
                ]
              : [];
            const children = [
              ...[subFormLinks, relationshipLinks, budgetLinks]
                .map((links) => ({ links: orderBy(links, ['active', 'label'], ['desc', 'asc']) }))
                .filter((g) => g.links.length),
              {
                links: [
                  {
                    key: `moduleName-${moduleName.id}-workflow`,
                    label: 'Workflow Changes',
                    klass: 'MongoTrails::Version',
                    filters: [{ type: 'version_module', key: 'version_module', value: moduleName.id, comparison: '=' }],
                  },
                ],
              },
            ];
            return {
              key: `moduleName-${moduleName.id}`,
              label: moduleName.display,
              klass: 'ModuleRecord',
              filters: [{ type: 'base_value', key: 'module_name_id', value: moduleName.id, comparison: '=' }],
              children,
            };
          }),
        ],
      };

      return [moduleLinks, globalLinks];
    }

    hasBudgets(moduleName: ModuleName): boolean {
      return this.moduleBudgets.some((mb) => mb.module_name_id === moduleName.id);
    }

    moduleFromRelationships(moduleName: ModuleName): Pick<Relationship, 'id' | 'name' | 'from_module' | 'code'>[] {
      return sortBy(
        this.relationships.filter((r) => r.from_module === moduleName.name),
        'name'
      );
    }

    onDropdownSearch(search: string): void {
      this.search = search;
    }

    newReportPath({ filters, klass }: ReportLink): string {
      const filterBlob = { klass, filters: filters?.reduce((memo, f, i) => ({ ...memo, [i]: f }), {}) };
      return `/admin/settings/reports/new?${qs.stringify({ filter_blob: filterBlob })}`;
    }

    async beforeMount(): Promise<void> {
      const [{ data: moduleNames }, { data: moduleBudgets }, { data: relationships }] = await Promise.all([
        this.$api.getModuleNames(
          {
            filters: { active: true, '!sub_form_id': API_NULL },
            per_page: -1,
            sort: 'display',
            only: ['id', 'name', 'display', { sub_forms: ['id', 'active', 'title'] }],
          },
          { cache: true }
        ),
        this.$api.getModuleBudgets(
          {
            filters: { active: true },
            only: ['id', 'module_name_id'],
            per_page: -1,
          },
          { cache: true }
        ),
        this.$api.getRelationships(
          {
            filters: {},
            only: ['id', 'from_module', 'name', 'code'],
            per_page: -1,
          },
          { cache: true }
        ),
      ]);

      this.moduleNames = moduleNames;
      this.moduleBudgets = moduleBudgets;
      this.relationships = relationships;
      this.loaded = true;
    }
  }
