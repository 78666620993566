
  import { Component, Model, Vue } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  @Component({ components: { DsCheckbox } })
  export default class HideQuestion extends Vue {
    @Model('input') readonly value!: SubFormQuestion<BaseSelectQuestionOptions>;
  }
