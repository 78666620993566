
  import EntitySelector from '@app/components/entity-selector.vue';
  import NormalSingleSubFormListFlags from '@app/components/sub-form-list-types/normal-single-sub-form-list-flags.vue';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';
  import { Component, Ref } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import type { SubForm } from '@app/models/sub-form';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { EntitySelector, NormalSingleSubFormListFlags, ValidationProvider, ValidationObserver } })
  export default class SingleFormSubFormListSettings extends SubFormListSettingsBase {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    get newSubFormUrl(): string {
      return `/admin/settings/module_names/${this.moduleName.id}/sub_forms/new?module_tab_id=${this.subFormListSync.module_tab_id}`;
    }

    get subFormFilters(): DonesafeFilterOptions<SubForm> {
      return {
        module_name: this.moduleName.name,
        '!sub_form_type': ['workflow', 'approval'],
      };
    }

    get subFormId(): number | null {
      return Number(this.subFormListSync.sub_form_ids?.[0]) || null;
    }

    set subFormId(value: number | null) {
      this.$set(this.subFormListSync, 'sub_form_ids', value ? [value] : []);
    }
  }
