
  import { Component, Model, Vue } from 'vue-property-decorator';
  import ModuleRenameFormModal from '@app/components/admin/modules/module-rename-form-modal.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import FormField from '../../edit/form-field.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: { ModuleRenameFormModal, DsCheckbox, FormField } })
  export default class ScoredQuestionConfig extends Vue {
    @Model('input') readonly value!: SubFormQuestion;

    beforeMount() {
      this.$$patch(this.value.config, { weighting: '1', scored: 'false' }, { ifEmpty: true });
    }

    get isScored(): boolean {
      return this.value.config.scored + '' === 'true';
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['scored', 'weighting'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
