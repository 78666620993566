
  import UserAdminPermissionsForm from '@app/components/admin/users/user-admin-permissions-form.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { toaster } from '@app/utils/toaster';
  import { Tuple } from '@app/utils/types/tuple';
  import { Component, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  const USER_ONLY_OPTIONS = Tuple(['id', 'admin', 'admin_permission_features', 'internal'] as const);
  @Component({ components: { UserAdminPermissionsForm } })
  export default class UserAdminPermissionsPage extends mixins(WithUser) {
    @Ref() readonly form?: InstanceType<typeof UserAdminPermissionsForm>;

    user: Nullable<Pick<TenantUser, 'id' | 'admin_permission_features' | 'admin'>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    beforeMount() {
      this.$api.getTenantUser(Number(this.userId), { only: USER_ONLY_OPTIONS }, { cache: true }).then(({ data }) => {
        this.user = data;
      });
    }

    onSubmit(user: Partial<TenantUser>) {
      this.user &&
        this.$api
          .updateTenantUser(this.user.id, user, { only: USER_ONLY_OPTIONS })
          .then(({ data }) => {
            this.$api.cache.clear();
            this.user = data;

            toaster('User has been updated');
            if (this.user.id === this.currentUserStore.data?.id) {
              window.location.reload();
            }
            this.$nextTick(() => this.form?.reset());
          })
          .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
    }
  }
