
  import bootbox from 'bootbox';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { MtSflOnly } from '../module-tabs/utils';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { SubFormList } from '@app/models/sub-form-list';
  import { SubFormListType } from '@app/models/sub-form-list';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      PaperTrailsModalLink,
      DsDropdown,
      DsIconText,
    },
  })
  export default class SubFormListActions extends Vue {
    @Prop() readonly subFormList!: Pick<SubFormList, MtSflOnly>;

    get formBasedSubFormList(): boolean {
      return [SubFormListType.normal, SubFormListType.workflow].includes(this.subFormList.sub_form_list_type);
    }

    toggleActive(): void {
      if (this.subFormList.active) {
        bootbox.confirm({
          size: 'small',
          message: this.formBasedSubFormList ? this.$t('tenant.admin.sub_form_lists.archive.confirm') : this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
            cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.sendToggleRequest();
            }
          },
        });
      } else {
        this.sendToggleRequest();
      }
    }

    sendToggleRequest(): void {
      this.subFormList.id &&
        this.$api
          .updateSubFormList(this.subFormList.id, { active: !this.subFormList.active })
          .then(() => {
            this.$emit('reload');
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    deleteSubFormList(): void {
      if (!this.subFormList.active) {
        bootbox.confirm({
          size: 'small',
          message: this.formBasedSubFormList ? this.$t('tenant.admin.sub_form_lists.delete.confirm') : this.$t('app.labels.are_you_sure'),
          buttons: {
            confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
            cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            if (result) {
              this.subFormList.id &&
                this.$api
                  .deleteSubFormList(this.subFormList.id)
                  .then(() => {
                    this.$emit('reload');
                  })
                  .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
            }
          },
        });
      }
    }
  }
