
  import type { PartialNullBy } from '@app/utils/types/partial';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import AdminSettingsOrganizationForm from '../../../components/admin/organizations/admin-settings-organization-form.vue';
  import bootbox from 'bootbox';
  import type { Organization } from '@app/models/organization';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { AdminSettingsOrganizationForm, DsModal } })
  export default class AdminSettingsOrganizationEdit extends Vue {
    @Ref() readonly form?: AdminSettingsOrganizationForm;
    @Prop([String, Number]) readonly organizationId!: string | number;

    organization: Nullable<Organization> = null;

    visible = true;

    beforeMount(): void {
      this.$api
        .getOrganization(
          Number(this.organizationId),
          {
            only: [
              'active',
              'descendants',
              'external_uuid',
              'hours_worked',
              'id',
              'level',
              'name',
              'organization_id',
              { organization: ['name'] },
            ],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.organization = data;
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-organizations-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    archiveOrRestore(organization: Organization, active: boolean): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.updateOrganization({ ...organization, active });
        },
      });
    }

    updateOrganization(organization: Partial<PartialNullBy<Organization, 'organization_id'>>): void {
      this.$api
        .updateOrganization(Number(this.organizationId), organization)
        .then(() => {
          this.closeModal('reload');
          toaster(this.$t('tenant.settings.organizations.update.organization_updated'));
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
