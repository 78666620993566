
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { isEmpty } from 'lodash';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import UserSelector from '../user/user-selector.vue';
  import { MODULE_RECORD_SHOW_ONLY } from '@app/pages/module-records/utils';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { ModuleRecord, BaseRecord } from '@app/models/module-record';
  import { UserType } from '@app/models/tenant-user';
  import { ConfiguratorFilterSource } from '@app/models/configurator-filter';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { convertInFormFilters } from '@app/utils/convert-in-form-filters';

  @Component({ components: { UserSelector } })
  export default class UserInvolvementForm extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly userFilters!: ConfiguratorFilter[];
    @Prop(Object) readonly record!: Pick<BaseRecord, 'id'>;
    @Prop(String) readonly recordType!: string;
    @Prop(Array) readonly userInvolvements?: UserInvolvement[];
    @Prop(Boolean) multiple?: boolean;
    @Prop(Boolean) editing?: boolean;
    @Ref() readonly userSelector?: UserSelector;

    selected: string[] | null = null;

    fetchedRecord: ModuleRecord | null = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get filters(): DonesafeFilterOptions<TenantUser> {
      const inFormFilters = convertInFormFilters(this.userFilters, {
        record: this.fetchedRecord || undefined,
        useRecordDbResponses: true,
        user: this.currentUserStore.data,
      });
      return {
        type: UserType.TenantUser, // MUST be default unless overridden
        active: true, // MUST be default unless overridden
        ...inFormFilters,
        '!id': this.userInvolvements?.map((ui) => ui.user_id)?.join(',') || undefined,
      };
    }

    get hasSelection(): boolean {
      return !isEmpty(this.selected);
    }

    onSelect(selected: string[]): void {
      this.selected = selected;
      this.$emit('select', this.selected);
    }

    beforeMount(): void {
      if (this.recordType === 'ModuleRecord') {
        const hasFormBasedFilters = this.userFilters.some((filter) => {
          if (filter.source === ConfiguratorFilterSource.range) {
            return filter.value?.some(
              (f) =>
                f.source === ConfiguratorFilterSource.question_id ||
                f.source === ConfiguratorFilterSource.question_code ||
                f.source === ConfiguratorFilterSource.current_record
            );
          }
          return (
            filter.source === ConfiguratorFilterSource.question_id ||
            filter.source === ConfiguratorFilterSource.question_code ||
            filter.source === ConfiguratorFilterSource.current_record
          );
        });
        if (hasFormBasedFilters) {
          this.$api.getModuleRecord(this.record.id, { only: MODULE_RECORD_SHOW_ONLY }, { cache: true, join: true }).then(({ data }) => {
            this.fetchedRecord = data;
          });
        }
      }
    }

    submitForm(): void {
      this.$emit('submit', this.selected);
    }

    deleteUserInvolvement(): void {
      this.$emit('delete');
    }
  }
