
  import { Component, Prop } from 'vue-property-decorator';
  import { omit } from 'lodash';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { automationDefinitionEditPath } from '@app/utils/automation-definition-edit-path';
  import { getValueFromLocationSearch } from '@app/utils/get-value-from-location-search';
  import { toaster } from '@app/utils/toaster';
  import { v4 as generateUUID } from 'uuid';
  import automationDefinitionProjection from '@app/components/admin/automation-definitions/automation-definition-projection';
  import { changeLocation } from '@app/utils/change-location';

  import WithAutomationSubmit from './with-automation-submit';
  import type { RedirectionMode } from './models';
  import AutomationDefinitionForm from './automation-definition-form.vue';

  @Component({ components: { AutomationDefinitionForm } })
  export default class AutomationDefinitionClone extends WithAutomationSubmit {
    @Prop({ type: [Number, String], required: true }) readonly automationDefinitionId!: number | string;
    automationDefinition: Nullable<Partial<AutomationDefinition>> = null;
    loading = false;

    get injectableArguments(): InjectableArguments {
      return {
        concept_name: this.automationDefinition?.concept_name,
        sub_form_id: this.automationDefinition?.sub_form_id,
        involvement_id: this.automationDefinition?.involvement_id,
        relationship_code: this.automationDefinition?.relationship_code,
        regulatory_report_config_id: this.automationDefinition?.regulatory_report_config_id,
      };
    }

    get automationRuleSetBuilder(): AutomationRuleSetBuilder {
      return {
        concept_name: this.automationDefinition?.concept_name,
        injectable_arguments: this.injectableArguments,
        triggering_concept_name: null,
        for_collection_options: false,
        triggering_injectable_arguments: {},
      };
    }

    onSubmitEvent(
      automationDefinition: Partial<AutomationDefinition> & { rule_builder_rules: Nullable<QueryBuilderBlob> },
      mode?: RedirectionMode
    ) {
      this.submit(omit(automationDefinition, 'id'), this.events, {
        mode,
        onSubmitComplete: this.onSubmitComplete,
        injectableArguments: this.injectableArguments,
      });
    }

    onSubmitComplete(data: AutomationDefinition, mode?: RedirectionMode) {
      toaster('Automation successfully added!');
      if (mode === 'debug') {
        changeLocation(`/admin/settings/automation_definitions/${data.id}/edit?debug=true`);
      } else if (mode === 'redirect') {
        changeLocation(getValueFromLocationSearch('redirect_to') || '/admin/settings/automation_definitions');
      } else {
        changeLocation(automationDefinitionEditPath(data.id, { redirect_to: getValueFromLocationSearch('redirect_to') }));
      }
    }

    fetchAutomation() {
      this.$api
        .getAutomationDefinition(this.automationDefinitionId, { ...automationDefinitionProjection({ withoutIds: true }) }, { cache: true })
        .then(({ data }) => {
          // TODO get automated events
          this.automationDefinition = { ...data, state: undefined, name: `Copy of ${data.name}` };

          const groupMap: Record<number, string> =
            data.automation_event_groups?.reduce<Record<number, string>>((memo, group) => {
              if (typeof group.id !== 'number') return memo;
              memo[group.id] = generateUUID();
              return memo;
            }, {}) || {};

          this.groups =
            data.automation_event_groups?.map((group) => {
              return { ...group, id: groupMap[group.id], parent_id: group.parent_id ? groupMap[group.parent_id] : null };
            }) || [];

          this.events =
            data.automated_events?.map((automatedEvent) => {
              return {
                index: automatedEvent.index,
                ...automatedEvent.event,
                automation_event_group_id: automatedEvent.automation_event_group_id
                  ? groupMap[automatedEvent.automation_event_group_id]
                  : null,
                id: generateUUID(),
              };
            }) || [];
        })
        .catch(() => {
          toaster({ text: 'Automation does not exist', icon: 'error' });
          this.$router.go(-1);
        });
    }

    beforeMount(): void {
      this.loading = true;
      this.fetchAutomation();
    }
  }
