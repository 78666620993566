
  import { useAccountStore } from '@app/stores/account';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { DetailQuestionOptions } from '@app/models/question-options/detail-question-options';
  import { DETAILS_MAPPING } from '@app/models/question-options/detail-question-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  @Component({ components: { EntitySelector, Select2, FormField } })
  export default class DetailFieldOptions extends Vue {
    @Model('input') value!: SubFormQuestion<DetailQuestionOptions>;
    @Prop(Object) subForm!: SubForm;

    fieldTypesHash: Record<string, FieldType> = {};

    optionsUpdated(questions: SubFormQuestion[]): void {
      this.fieldTypesHash = questions.reduce((acc, question) => {
        return { ...acc, [question.system_code]: question.field_type };
      }, this.fieldTypesHash);
    }

    questionSelected(question: SubFormQuestion) {
      this.fieldTypesHash = {
        ...this.fieldTypesHash,
        [question.system_code]: question.field_type,
      };
    }

    subFormQuestionDisplay(subFormQuestion: SubFormQuestion): String {
      return subFormQuestion.active ? subFormQuestion.title : this.$t('app.labels.archived_name', { name: subFormQuestion.title });
    }

    get accountStore() {
      return useAccountStore();
    }

    get questionFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return {
        field_type: Object.keys(DETAILS_MAPPING),
        active: true,
        sub_form_section: {
          sub_form_id: this.subForm.id,
        },
      };
    }

    get availableMethodOptions(): [string, string][] {
      const code = this.value.config.sub_form_question_system_code;

      if (!code || !this.fieldTypesHash[code]) {
        return [];
      }

      const fieldType = this.fieldTypesHash[code];
      const options = Object.entries(DETAILS_MAPPING[fieldType as keyof typeof DETAILS_MAPPING].available_methods);

      if (fieldType === FieldType.single_person_selector) {
        return options.filter(([key]) => {
          if (key === 'formatted_start_date') {
            return this.accountStore.data.display_employment_start_date;
          }
          if (key === 'formatted_end_date') {
            return this.accountStore.data.display_employment_separation_date;
          }

          return true;
        });
      }

      return options;
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['method', 'sub_form_question_system_code'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
