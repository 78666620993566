
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import HybridForm from './hybrid-form.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({
    components: {
      DsModal,
      HybridForm,
    },
  })
  export default class EditHybridPage extends Vue {
    @Prop(Number) subFormListId!: number;
    @Prop(Number) moduleNameId!: number;
    @Ref() readonly form?: HybridForm;

    subFormList: Nullable<SubFormList> = null;
    moduleName: Nullable<ModuleName> = null;
    showModal = true;

    get modalTitle(): string {
      return this.$t('tenant.settings.hybrid.form_managed.form.rename_sub_form_caption', { name: this.moduleName?.display });
    }
    submit(newSubFormList: Partial<SubFormList>): void {
      if (newSubFormList.title) {
        this.$api
          .updateManagedSubFormList(this.subFormListId, newSubFormList.title)
          .then(() => {
            toaster(this.$t('tenant.settings.hybrid.form_managed.form.sub_form_renamed', { name: this.moduleName?.display }));
            this.closeModal('reload');
          })
          .catch(({ data }) => {
            toaster({ text: data.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    beforeMount(): void {
      this.$api
        .getSubFormList(
          this.subFormListId,
          {
            only: ['title', 'module_name'],
            include: ['module_name'],
          },
          { cache: true }
        )
        .then((response) => {
          this.subFormList = response.data;
          if (response.data.module_name) {
            this.moduleName = response.data.module_name;
            if (this.moduleName.id !== this.moduleNameId) {
              this.$router.replace({
                name: 'edit-hybrid-page',
                params: { subFormListId: String(this.subFormListId), moduleNameId: String(this.moduleName.id) },
              });
            }
          }
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-hybrid-module-page',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
