
  import { API_NULL } from '@app/constants';
  import bootbox from 'bootbox';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { UserRecordCalendar } from '@app/models/user-record-calendar';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  import UserRecordCalendarForm from './user-record-calendar-form.vue';

  @Component({ components: { UserRecordCalendarForm, DsModal } })
  export default class UserRecordCalendarModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly moduleName!: ModuleName;
    @Prop(Boolean) readonly global?: boolean;
    @Prop(Array) readonly filters?: ConfiguratorFilter[];

    questions: SubFormQuestion[] = [];

    calendars: UserRecordCalendar[] = [];

    selectedCalendar: Nullable<Partial<UserRecordCalendar>> = null;

    get title(): string {
      return `${this.moduleName.display} calendars`;
    }

    selectCalendar(calendar?: UserRecordCalendar | null): void {
      this.selectedCalendar = calendar || null;
    }

    selectCalendarById(calendarId: number): void {
      this.fetchCalendars(calendarId);
    }

    addNewCalendar(): void {
      this.selectedCalendar = { module_name_id: this.moduleName.id };
    }

    onRefresh(calendar: UserRecordCalendar): void {
      bootbox.confirm({
        backdrop: false,
        title: 'Are you sure?',
        message: 'All the subscribed calendars will stop working',
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .refreshUserRecordCalendar(calendar.id)
              .then(({ data }) => {
                this.$api.cache.clear();
                this.fetchCalendars(data.id);
              })
              .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }

    onDelete(calendar: UserRecordCalendar): void {
      bootbox.confirm({
        backdrop: false,
        title: 'Are you sure?',
        message: 'Calendar will be deleted',
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .deleteUserRecordCalendar(calendar.id)
              .then(() => {
                this.$api.cache.clear();
                this.fetchCalendars(null);
              })
              .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
          }
        },
      });
    }

    onFormSubmit(data: Partial<UserRecordCalendar>): void {
      const promise = data.id
        ? this.$api.updateUserRecordCalendar(data.id, data)
        : this.$api.createUserRecordCalendar({ ...data, global: this.global });

      promise
        .then(({ data }) => {
          this.$api.cache.clear();
          this.fetchCalendars();
          this.selectedCalendar = data;
        })
        .then(() => {
          toaster('Calendar config saved');
          this.$api.cache.clear();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }

    fetchCalendars(calendarId?: number | null): void {
      this.$api
        .getUserRecordCalendars(
          {
            sort: 'name',
            filters: {
              user_id: this.global ? API_NULL : undefined,
              module_name_id: this.moduleName.id,
            },
            per_page: -1,
            only: ['name', 'user_id', 'config', 'url', 'id'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.calendars = data;
          if (calendarId !== undefined) {
            this.selectCalendar(calendarId ? this.calendars.find((cal) => cal.id == calendarId) : null);
          }
        });
    }

    beforeMount(): void {
      this.fetchCalendars();
    }
  }
