
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { AccountService } from '@app/components/paper-trails/services';
  import Select2 from '@app/components/select2.vue';
  import { startCase, pick } from 'lodash';
  import { Component, Vue } from 'vue-property-decorator';
  import AccountActions from '@app/components/admin/accounts/account-actions.vue';
  import PiiSelector from '@app/components/admin/user-settings/tabs/pii-selector.vue';
  import type { Account } from '@app/models/account';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { Select2, AccountActions, PiiSelector } })
  export default class UserSettingsTab extends Vue {
    form: Partial<Account> = {};
    showCompanyRegisterField = false;
    userSecondaryInformationKeys = [
      'home_location',
      'home_organization',
      'role',
      'payroll_identifier',
      'external_uuid',
      'position',
      'email',
    ];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get auditTrailAttributeScope(): (keyof Account)[] {
      return AccountService.userSettingsAttributes;
    }

    get userInformationOptions(): [string, string][] {
      return this.userSecondaryInformationKeys.map((value) => [value, startCase(value)]);
    }

    beforeMount(): void {
      this.form = { ...this.accountStore.data };
      this.$api
        .getModuleNames({ filters: { name: 'CompanyRegister', active: true }, only: ['active'] }, { cache: true })
        .then(({ data }) => {
          this.showCompanyRegisterField = !!data[0]?.active || this.accountStore.data.display_company_register_field;
        });
    }

    submit(account: Partial<Account>): void {
      const accountUpdatedFields = pick(account, [
        'display_company_register_field',
        'display_position_field',
        'display_date_of_birth',
        'display_mobile_number',
        'display_employment_start_date',
        'display_employment_separation_date',
        'show_pay_details_tab',
        'user_information_options',
        'contractor_confirmation_email_subject',
        'contractor_confirmation_email',
        'pii_fields',
      ]);
      this.$api
        .updateCurrentAccount(accountUpdatedFields)
        .then(() => {
          this.accountStore.$patch({ data: { ...this.accountStore.data, ...accountUpdatedFields } });
          toaster(this.$t('tenant.admin.settings.menus.user_settings.updated.account_settings'));
          this.$api.cache.clear();
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }));
    }
  }
