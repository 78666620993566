
  import EntitySelector from '@app/components/entity-selector.vue';
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import RoleOverrideForm from '@app/components/sub-form-list/role-override-form.vue';
  import Select2 from '../select2.vue';
  import SubFormListRestrictionForm from '@app/components/sub-form-list/sub-form-list-restriction-form.vue';
  import RoleProfileSelector from './role-profile-selector.vue';
  import type { Restriction, RestrictionSetForm, RoleOverride } from './utils';
  import type { Involvement } from '@app/models/involvement';

  @Component({ components: { EntitySelector, RoleOverrideForm, SubFormListRestrictionForm, RoleProfileSelector, Select2 } })
  export default class SubFormListRestrictionSet extends Vue {
    @Prop(String) readonly permissionType!: string;
    @Prop(String) readonly conceptName!: string;
    @Prop(Object) readonly form!: RestrictionSetForm;

    involvements: Involvement[] = [];

    @Emit('update')
    update(): RestrictionSetForm {
      return this.form;
    }

    get defaultRoleRelationship(): Record<string, string> {
      const user = this.$t('app.labels.creator');

      const activity_assignees = this.$t('app.labels.activity_assignees');
      return { user, activity_assignees };
    }

    get roleRelationshipsMapping(): Record<string, Record<string, string>> {
      const user = this.$t('app.labels.creator');
      const assignee = this.$t('app.labels.assignee');
      const auditors = this.$t('app.labels.auditors');
      const auditees = this.$t('app.labels.auditees');
      const activity_assignees = this.$t('app.labels.activity_assignees');
      const case_manager = this.$t('app.labels.case_manager');
      const contract_manager = this.$t('app.labels.contract_manager');
      const instructors = this.$t('app.labels.instructors');
      const investigators = this.$t('app.labels.investigators');
      const observees = this.$t('app.labels.observees');
      const observers = this.$t('app.labels.observers');
      const participant = this.$t('app.labels.participant');
      const participants = this.$t('app.labels.participants');
      const participating_users = this.$t('app.labels.participating_users');
      const person_involved = this.$t('app.labels.person_involved');
      const reported_by = this.$t('app.labels.reported_by');
      const reported_to = this.$t('app.labels.reported_to');
      const responsible_supervisor = this.$t('app.labels.responsible_supervisor');
      const treated_by = this.$t('app.labels.treated_by');
      const visitee = this.$t('app.labels.visitee');
      const visitor = this.$t('app.labels.visitor');
      return {
        Activity: { user, assignee },
        Audit: { user, auditors, auditees, activity_assignees },
        ChemicalRegister: { user, activity_assignees },
        CompanyRegister: { user, contract_manager },
        GhsChemicalRegister: { user, activity_assignees },
        Hazard: { user, reported_by, reported_to, activity_assignees },
        Incident: { user, reported_by, reported_to, participating_users, investigators, activity_assignees },
        JobManagementRegister: { user, responsible_supervisor },
        Kpi: { user },
        LearningRecord: { user, instructors, participants, activity_assignees },
        LmsCourseEnrollment: { user, participant, activity_assignees },
        ModuleRecord: { user, activity_assignees },
        Observation: { user, observers, observees, activity_assignees },
        Procedure: { user, activity_assignees },
        SafetyDataSheet: { user, activity_assignees },
        VisitorRegister: { user, visitor, visitee, activity_assignees },
        WorkerCompensation: { user, treated_by, person_involved, case_manager, activity_assignees },
      };
    }

    get roleRelationships(): [string, string][] {
      const roleRelationships = this.roleRelationshipsMapping[this.conceptName] || this.defaultRoleRelationship;
      return Object.keys(roleRelationships).map((key) => [key, roleRelationships[key]]);
    }

    updateRoleOverrides(newOverrides: RoleOverride[]): void {
      this.form.role_overrides = newOverrides;
    }

    updateRestrictions(update: { relationships: Record<string, string>; restrictions: Restriction[] }) {
      const otherRestrictions = this.form.restrictions.filter((r) => !(r.relationship in update.relationships));
      this.form.restrictions = [...update.restrictions, ...otherRestrictions];
    }

    get permissionTypeAccessLabel(): string {
      switch (this.permissionType) {
        case 'edit':
          return `${this.$t('tenant.module_tabs.show.restriction_labels.edit_access')}`;
        case 'archive':
          return `${this.$t('tenant.module_tabs.show.restriction_labels.archive_access')}`;
        case 'approve':
          return `${this.$t('tenant.module_tabs.show.restriction_labels.approve_access')}`;
        default:
          return `${this.$t('tenant.module_tabs.show.restriction_labels.view_access')}`;
      }
    }

    beforeMount(): void {
      this.$api
        .getInvolvements({ filters: { module_name: this.conceptName, active: true }, per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.involvements = data;
        });
    }
  }
