
  import { Btn } from 'uiv';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { AsfSfsOnly } from '../sub-forms/utils';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import EditQuestion from '../questions/edit/edit-question.vue';
  import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import { Tuple } from '@app/utils/types/tuple';

  const QS_ONLY = Tuple(['id', 'name'] as const);
  type QsOnly = (typeof QS_ONLY)[number];

  @Component({ components: { DsDropdown, Btn, EditQuestion } })
  export default class NewQuestion extends Vue {
    @Prop(Object) readonly subFormSection!: Pick<SubFormSection, AsfSfsOnly>;
    @Prop(String) readonly moduleNameName!: string;
    @Prop(Number) readonly subFormId!: number;

    questionSets: Pick<SubFormQuestionSet, QsOnly>[] = [];
    showModal = false;

    onCloseModal() {
      this.showModal = false;
      this.$emit('reload');
    }

    onNewQuestionSetClick(questionSet: Pick<SubFormQuestionSet, QsOnly>): void {
      sessionStorage.setItem('sectionIdAfterNew', `${this.subFormSection.id}`);
    }

    newQuestionSetLink(questionSet: Pick<SubFormQuestionSet, QsOnly>): string {
      return `/admin/settings/sub_form_question_sets/copy?sub_form_question_set_id=${questionSet.id}&sub_form_section_id=${this.subFormSection.id}`;
    }

    get newQuestionWrapperClass(): string {
      return this.questionSets.length ? 'col-md-6' : 'col-md-12';
    }

    beforeMount(): void {
      this.$api
        .getQuestionSets(
          { filters: { active: true, module_name: this.moduleNameName }, per_page: -1, only: QS_ONLY, sort: 'name' },
          { cache: true }
        )
        .then(({ data }) => {
          this.questionSets = data;
        });
    }
  }
