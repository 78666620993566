
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import MobileAppLayoutForm from '../../../components/admin/mobile-apps/mobile-app-layout-form.vue';
  import type { MobileAppLayout } from '@app/models/mobile-app/layout';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { MobileAppLayoutForm, DsModal } })
  export default class NewLayoutPage extends Vue {
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;
    @Ref() readonly form?: MobileAppLayoutForm;

    layout = {
      mobile_app_config_id: Number(this.mobileAppConfigId),
    };

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'mobile-app-config-view',
        params: {
          mobileAppConfigId: `${this.mobileAppConfigId}`,
          reload: command,
        },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<MobileAppLayout>): void {
      this.$api
        .createMobileAppLayout(form)
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
