
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import UserFilterConfigurator from '../filter-configurator/user-filter-configurator.vue';
  import RecordFilterConfigurator from '../filter-configurator/record-filter-configurator.vue';
  import type { SinglePersonSelectorFormField } from './model';
  import Draggable from 'vuedraggable';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { ModuleName } from '@app/models/module-name';
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '../filter-configurator/utils';

  type RelatedModuleType = Pick<ModuleName, FirstLevelKeyLiterals<(typeof WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY)[number]>>;

  @Component({
    components: {
      UserFilterConfigurator,
      RecordFilterConfigurator,
      Select2,
      Draggable,
      DsCheckbox,
    },
  })
  export default class SinglePersonSelectorFieldPicker extends Vue {
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(String) readonly name!: string;
    @Model('input') readonly value?: SinglePersonSelectorFormField[];

    selectedField: Nullable<SinglePersonSelectorFormField> = null;

    relatedModuleNames: RelatedModuleType[] = [];
    loading = true;

    get isPickerEmpty(): boolean {
      return !this.value || !Object.keys(this.value).length;
    }

    get selectedFields(): SinglePersonSelectorFormField[] {
      return this.value || [];
    }

    get availableFields(): SinglePersonSelectorFormField[] {
      return this.allFields.filter((field) => !this.selectedFields.some((v) => v.key === field.key));
    }

    get baseFields(): SinglePersonSelectorFormField[] {
      return [
        { name: '[user][email]', key: 'email', type: 'email', label: this.$t('app.user_details.email') },
        { name: '[user][mobile]', key: 'mobile', type: 'text', label: this.$t('app.labels.mobile') },
        {
          name: '[user][manager_id]',
          key: 'manager_id',
          type: 'user',
          label: this.$t('app.labels.manager'),
          requiredFilters: { active: true },
        },
        {
          name: '[user][payroll_identifier]',
          key: 'payroll_identifier',
          type: 'text',
          label: this.$t('tenant.users.sections.edit.tab_details.payroll_identifier'),
        },
        {
          name: '[user][date_of_birth]',
          key: 'date_of_birth',
          type: 'date',
          label: this.$t('app.user_details.date_of_birth'),
        },
        { name: '[user][position]', key: 'position', type: 'text', label: this.$t('app.labels.position') },
        { name: '[user][start_date]', key: 'start_date', type: 'date', label: this.$t('app.labels.start_date') },
        { name: '[user][end_date]', key: 'end_date', type: 'date', label: this.$t('app.labels.end_date') },
        {
          name: '[user][profile_attributes][country_code]',
          key: 'country_code',
          type: 'country_code',
          label: this.$t('app.user_details.country'),
        },
        { name: '[user][profile_attributes][state_code]', key: 'state_code', type: 'state_code', label: this.$t('app.user_details.state') },
        { name: '[user][language]', key: 'language', type: 'language', label: this.$t('app.user_details.language') },
      ];
    }

    get profileFields(): SinglePersonSelectorFormField[] {
      return [
        {
          name: '[user][profile_attributes][address_line_1]',
          key: 'address_line_1',
          type: 'text',
          label: 'Address Line 1',
        },
        {
          name: '[user][profile_attributes][address_line_2]',
          key: 'address_line_2',
          type: 'text',
          label: 'Address Line 2',
        },
        {
          name: '[user][profile_attributes][suburb]',
          key: 'suburb',
          type: 'text',
          label: 'Suburb',
        },
        {
          name: '[user][profile_attributes][postcode]',
          key: 'postcode',
          type: 'text',
          label: 'Postcode',
        },
      ];
    }

    get recordRelatedFields(): SinglePersonSelectorFormField[] {
      return this.relatedModuleNames.map((moduleName) => {
        return {
          key: `user_module_record_${moduleName.id}`,
          type: 'record',
          name: `[user][user_module_records][${moduleName.id}]`,
          label: moduleName.display,
          requiredFilters: { module_name_id: moduleName.id },
        };
      });
    }

    get allFields(): SinglePersonSelectorFormField[] {
      return [...this.baseFields, ...this.profileFields, ...this.recordRelatedFields];
    }

    set selectedFields(fields: SinglePersonSelectorFormField[]) {
      this.$emit('input', fields);
    }

    @Emit('input')
    onFieldSelect(key: SinglePersonSelectorFormField['key']): SinglePersonSelectorFormField[] {
      const field = this.allFields.find((f) => f.key === key);
      this.selectedField = null;
      if (field) {
        return [...this.selectedFields, field];
      }
      return this.selectedFields;
    }

    @Emit('input')
    removeField(field: SinglePersonSelectorFormField): SinglePersonSelectorFormField[] {
      return this.selectedFields.filter((f) => f.key !== field.key);
    }

    moduleNameById(id: number): Maybe<RelatedModuleType> {
      return this.relatedModuleNames.find((mn) => mn.id == id);
    }

    fieldPlaceholder(field: SinglePersonSelectorFormField): Maybe<string> {
      return this.allFields.find((f) => f.key === field.key)?.label;
    }

    beforeMount(): void {
      this.$api
        .getModuleNames({
          filters: { active: true, feature_set: { user_related: true } },
          only: WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY,
        })
        .then(({ data }) => {
          this.relatedModuleNames = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
