import { render, staticRenderFns } from "./admin-look-and-logo-page.vue?vue&type=template&id=7949ef2e&scoped=true&lang=pug"
import script from "./admin-look-and-logo-page.vue?vue&type=script&lang=ts"
export * from "./admin-look-and-logo-page.vue?vue&type=script&lang=ts"
import style0 from "./admin-look-and-logo-page.vue?vue&type=style&index=0&id=7949ef2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7949ef2e",
  null
  
)

export default component.exports