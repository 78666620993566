
  import { Component, Vue, Model } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SignatureQuestionOptions } from '@app/models/question-options/signature-question-options';

  @Component({ components: { EntitySelector, DsCheckbox } })
  export default class SignatureFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<SignatureQuestionOptions>;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['display_signature_added_by', 'quick_add_own_signature'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
