
  import { Component, PropSync } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import ColorInput from '@app/components/color-input.vue';
  import type { LinkWidgetOptions } from '@app/models/widget';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({ components: { Select2, ColorInput, ValidationProvider, ValidationObserver } })
  export default class LinkWidgetSettings extends BaseWidgetSettings<LinkWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<LinkWidgetOptions>;

    get targetOptions(): [string, string][] {
      return [
        ['_self', this.$t('tenant.admin.widgets.types.edit.link.same_window')],
        ['_blank', this.$t('tenant.admin.widgets.types.edit.link.new_window')],
      ];
    }
  }
