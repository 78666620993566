import { SubFormListType } from '@app/models/sub-form-list';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export const MT_SFL_ONLY = Tuple([
  'id',
  'title',
  'print_title',
  'sub_form_list_type',
  'draft',
  'draft_after_complete',
  'allow_confidentiality',
  'auto_save',
  'multiple',
  'index',
  'rule_set_id',
  'system_code',
  'sub_form_list_restrictions',
  'expensable_expensing_table',
  'approval_button_text',
  'approval_button_icon',
  'order_options',
  'module_tab_id',
  'show_in_list',
  'active',
  'sub_form_ids',
  'relationship_options',
  'response_counter_options',
  'updated_at',
  'record_relation_question_id',
  {
    involvement: ['id', 'name'],
    default_template: ['id', 'name'],
    approval_sub_form: ['id', 'title'],
  },
] as const);
export type MtSflOnly = FirstLevelKeyLiterals<(typeof MT_SFL_ONLY)[number]>;

export const SUB_FORM_LIST_TYPE_LABELS = {
  [SubFormListType.normal]: 'tenant.admin.sub_form_lists.types.normal',
  [SubFormListType.workflow]: 'tenant.admin.sub_form_lists.types.workflow',
  [SubFormListType.record_relations]: 'tenant.admin.sub_form_lists.types.record_relations',
  [SubFormListType.relationship]: 'tenant.admin.sub_form_lists.types.relationship',
  [SubFormListType.response_counter]: 'tenant.admin.sub_form_lists.types.response_counter',
  [SubFormListType.approval]: 'tenant.admin.sub_form_lists.types.approval',
  [SubFormListType.expense_estimates]: 'tenant.admin.sub_form_lists.types.expense_estimates',
  [SubFormListType.budgets]: 'tenant.admin.sub_form_lists.types.budgets',
  [SubFormListType.procedures]: 'tenant.admin.sub_form_lists.types.procedures',
};
