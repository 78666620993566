
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import ColorInput from '@app/components/color-input.vue';
  import type { Blob as ActiveStorageBlob } from '@rails/activestorage';
  import { pick, uniq } from 'lodash';
  import { Component, Vue } from 'vue-property-decorator';
  import FileUploader from '@app/components/file-uploader/file-uploader.vue';
  import { Tooltip } from 'uiv';
  import type { Account } from '@app/models/account';
  import { toaster } from '@app/utils/toaster';
  import { Select2 } from '@app/components';
  import { changeLocation } from '@app/utils/change-location';

  const DEFAULT_GRAPH_COLORS = ['graph_color_1', 'graph_color_2', 'graph_color_3', 'graph_color_4', 'graph_color_5'];
  @Component({ components: { ColorInput, FileUploader, Tooltip, Select2 } })
  export default class AdminLookAndLogoPage extends Vue {
    form: Partial<Account> = {};

    accountAttributes: (keyof Account)[] = [
      'name',
      'display_name',
      'show_email_logo',
      'primary_color',
      'secondary_color',
      'header_text_color',
      'sidebar_text_color',
      'search_bar_background_color',
      'search_bar_text_color',
      'logo_image',
      'email_logo_image',
      'login_button_text',
      'login_button_color',
      'sso_login_button_text',
      'sso_login_button_color',
      'login_logo_image',
      'favicon',
      'primary_button_color',
      'info_button_color',
      'success_button_color',
      'warning_button_color',
      'danger_button_color',
      'graph_1_color',
      'graph_2_color',
      'graph_3_color',
      'graph_4_color',
      'graph_color_config',
      'sign_in_config',
    ];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get graphColors(): (keyof Account['graph_color_config'])[] {
      return uniq([...Object.keys(this.form.graph_color_config || {}), ...DEFAULT_GRAPH_COLORS]).sort();
    }

    get oneHsiUiEnabled(): boolean {
      return !!this.accountStore.data?.one_hsi_ui;
    }

    beforeMount(): void {
      this.form = {
        ...this.accountStore.data,
        sign_in_config: {
          email_enabled: true,
          forgot_password_links_enabled: true,
          policy_links_enabled: true,
          ...this.accountStore.data.sign_in_config,
        },
      };
    }

    setFile(key: string, file?: ActiveStorageBlob & { blob: Blob }): void {
      if (file) {
        const urlCreator = window.URL || window.webkitURL;
        this.form = { ...this.form, [`${key}_url`]: urlCreator.createObjectURL(file.blob), [this.imageKey(key)]: file.signed_id };
      } else {
        this.form = { ...this.form, [`${key}_url`]: null, [this.imageKey(key)]: null };
      }
    }

    imageKey(key: string): string {
      return key === 'favicon' ? key : `${key}_image`;
    }

    submit(account: Partial<Account>): void {
      const mappedAttributes = {
        ...pick(account, this.accountAttributes),
        primary_color: account.primary_color || null,
        secondary_color: account.secondary_color || null,
        header_text_color: account.header_text_color || null,
        sidebar_text_color: account.sidebar_text_color || null,
        search_bar_background_color: account.search_bar_background_color || null,
        search_bar_text_color: account.search_bar_text_color || null,
        primary_button_color: account.primary_button_color || null,
        info_button_color: account.info_button_color || null,
        success_button_color: account.success_button_color || null,
        warning_button_color: account.warning_button_color || null,
        danger_button_color: account.danger_button_color || null,
        graph_1_color: account.graph_1_color || null,
        graph_2_color: account.graph_2_color || null,
        graph_3_color: account.graph_3_color || null,
        graph_4_color: account.graph_4_color || null,
        login_button_color: account.login_button_color || null,
        sso_login_button_color: account.sso_login_button_color || null,
      } as Partial<Account>;

      this.$api
        .updateCurrentAccount(mappedAttributes)
        .then(({ data }) => {
          toaster(this.$t('tenant.admin.looks.messages.updated'));
          this.setAccountChanges(data);
          // TODO: make re-active, app/views/layouts/tenant/_color_config.html.haml
          changeLocation('/admin/settings/looks');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    setAccountChanges(account: Account): void {
      const attributesToUpdate = this.accountAttributes.reduce((acc, column) => ({ ...acc, [column]: account[column] }), {});
      this.accountStore.$patch({ data: { ...this.accountStore.data, ...attributesToUpdate } });
    }
  }
