
  import bootbox from 'bootbox';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import ActivityForm from './activity-form.vue';
  import type { Activity } from '@app/models/activity';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ActivityForm } })
  export default class EditActivityPage extends Vue {
    @Ref() readonly form?: ActivityForm;

    activity: Nullable<Activity> = null;

    visible = true;

    closeModal(reload = false): void {
      this.$emit('close', reload ? 'reload' : null);
    }

    get activityId(): number {
      return parseInt(this.$route.params.activityId);
    }

    beforeMount(): void {
      this.$api
        .getActivity(
          this.activityId,
          {
            include: ['can_delete', 'can_approve', 'can_edit'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.activity = data;
        });
    }

    deleteActivity(): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.shared.tab_actions.delete_this_action'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result &&
            this.activity &&
            this.$api
              .deleteActivity(this.activity.id)
              .then(() => {
                toaster(this.$t('tenant.activities.destroy.activity_successfully_deleted'));
                this.closeModal(true);
              })
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
        },
      });
    }

    onFormSubmit(form: Partial<Activity>): void {
      form.id &&
        this.$api
          .updateActivity(form.id, {
            ...form,
            sub_form_completion_id: (form.sub_form_completion_id || null) as Activity['sub_form_completion_id'],
          })
          .then(() => {
            toaster(this.$t('tenant.activities.update.activity_successfully_updated'));
            this.closeModal(true);
            this.$api.cache.clear();
            this.$router.push({ name: 'activity-view', params: { activityId: `${form.id}` }, query: this.$route.query });
          })
          .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
