import { OPERATORS_BY_TYPE } from '@app/components/ds-query-builder/defaults';
import type { DebugQueryBuilderRule, QueryBuilderRule } from '@app/models/query-builder-blob';
import type { RuleSetFilter } from '@app/models/rule-set-filter';
import { isNil } from 'lodash';
import type { Observable } from 'rxjs';

import type { ClampedType } from './clamped-state';
import { ClampedState } from './clamped-state';
import type { NodeConfig } from './ds-node';
import { DsNode } from './ds-node';
import { ExpandedItems } from './expanded';

interface DsRuleNodeConfig extends NodeConfig {
  rule: QueryBuilderRule | DebugQueryBuilderRule;
}

export class DsRuleNode extends DsNode {
  id: QueryBuilderRule['id'];
  field: QueryBuilderRule['field'];
  type: QueryBuilderRule['type'];
  input: QueryBuilderRule['input'];
  operator?: QueryBuilderRule['operator'];
  value?: QueryBuilderRule['value'];
  pass?: DebugQueryBuilderRule['pass'];

  private clampedState: ClampedState;
  private expandedItems: ExpandedItems;

  constructor({ rule, ...nodeConfig }: DsRuleNodeConfig) {
    super({ ...nodeConfig });
    this.id = rule.id;
    this.field = rule.field;
    this.type = rule.type;
    this.input = rule.input;
    this.operator = rule.operator;
    this.value = rule.value;

    this.clampedState = new ClampedState();
    this.expandedItems = new ExpandedItems();

    if ('pass' in rule) {
      this.pass = rule.pass;
    }
  }

  get filter() {
    return this.getFilterById(this.id);
  }

  get isClamped$(): Observable<boolean> {
    return this.clampedState.clampedState$;
  }

  get expandedItems$(): Observable<boolean> {
    return this.expandedItems.expandedItems$;
  }

  setDefaultValue(filter?: RuleSetFilter) {
    const multiple = this.operator && OPERATORS_BY_TYPE[this.operator]?.multiple;
    this.value = multiple ? (filter?.default_value ? [filter?.default_value] : []) : filter?.default_value || null;
  }

  updateOperator(operator: keyof typeof OPERATORS_BY_TYPE) {
    this.operator = operator;
    this.filter && this.setDefaultValue(this.filter);
  }

  updateRuleById(id: string) {
    const filter = this.getFilterById(id);

    if (filter) {
      this.id = id;
      this.type = filter.type;
      this.input = filter.input;
      this.field = filter.field;
      this.operator = filter.operators[0];
      this.setDefaultValue(filter);
    }
  }

  updateValue(value: string | string[]) {
    this.value = value;
  }

  getFilterById(id: string): Maybe<RuleSetFilter> {
    return this.filters.find((f) => f.id == id);
  }

  prepareForSave(): QueryBuilderRule {
    if (isNil(this.operator) || isNil(this.value)) {
      throw new Error(`Filter with id ${this.id} not found`);
    }

    return {
      id: this.id,
      field: this.field,
      type: this.type,
      input: this.input,
      operator: this.operator as QueryBuilderRule['operator'],
      value: this.value as QueryBuilderRule['value'],
    };
  }

  toggleClamped(value: ClampedType, clamped: boolean) {
    if (clamped) this.clampedState?.setClampedValues(value);
    else this.clampedState?.removeClampedValues(value);
  }

  setExpandedItems(value: ClampedType, expanded: boolean) {
    if (expanded) this.expandedItems?.setExpandedValues(value);
    else this.expandedItems?.removeExpandedValues(value);
  }
}
