
  import { WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY } from '@app/components/admin/questions/filter-configurator/utils';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';
  import { map } from 'lodash';
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ModuleName, RecordLinkBehaviour } from '@app/models/module-name';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { getRecordLinkBehaviourOptions } from '@app/utils/get-record-link-behaviour-options';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import RecordFilterConfigurator from '../../questions/filter-configurator/record-filter-configurator.vue';

  import BaseWidgetSettings from './base-widget-settings';

  interface RecordIndexWidgetOptions {
    filters?: ConfiguratorFilter[];
    record_link_behaviour?: RecordLinkBehaviour;
  }

  const MODULE_NAME_ONLY = Tuple(['available_index_options', ...WITH_RECORD_FILTER_CONFIGURATOR_MODULE_ONLY] as const);

  @Component({
    components: { Select2, EntitySelector, RecordFilterConfigurator, ValidationObserver, ValidationProvider },
    methods: { getRecordLinkBehaviourOptions },
  })
  export default class RecordIndexWidgetSettings extends BaseWidgetSettings<RecordIndexWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<RecordIndexWidgetOptions>;

    selectedModuleId: number | null = null;
    selectedModuleName: Pick<ModuleName, FirstLevelKeyLiterals<(typeof MODULE_NAME_ONLY)[number]>> | null = null;

    get availableIndexOptions(): [string, string][] {
      return map(this.selectedModuleName?.available_index_options || {}, (option, key) => [key, option.name]);
    }

    get moduleNameFilter(): object {
      return { active: true, '!name': HARDCODED_MODULE_CODES };
    }

    get moduleNameId(): string | undefined {
      return this.widgetOptions.filters?.find((filter) => filter.key === 'module_name_id')?.value as string;
    }

    beforeMount(): void {
      this.onModuleNameSelect(this.moduleNameId as number | undefined);
    }

    onModuleNameSelect(id?: number) {
      if (id) {
        this.$api.getModuleName(id, { only: MODULE_NAME_ONLY }, { cache: true }).then(({ data }) => {
          this.selectedModuleName = data;
        });
        const newFilters = [
          ...(this.widgetOptions.filters || []).filter((f) => f.key !== 'module_name_id'),
          { key: 'module_name_id', value: `${id}` },
        ];
        this.$set(this.widgetOptions, 'filters', newFilters);
        this.selectedModuleId = id;
      } else {
        this.selectedModuleName = null;
        this.selectedModuleId = null;
        this.$set(this.widgetOptions, 'filters', []);
      }
    }
  }
