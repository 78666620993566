
  import type { MobileAppConfig } from '@app/models/mobile-app';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import QrCode from '@chenfengyuan/vue-qrcode';
  import type { Subscription } from '@rails/actioncable';
  import bootbox from 'bootbox';
  import { Tooltip } from 'uiv';
  import { ToggleButton } from 'vue-js-toggle-button';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import type { MobileAppLayout } from '@app/models/mobile-app/layout';
  import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { qrCodeLink } from '@app/utils/qr-code-link';
  import { toaster } from '@app/utils/toaster';

  import DsDropdown from '../../../components/ds-dropdown.vue';
  import BaseTable from '../../../components/base-table/base-table.vue';
  import consumer from '../../../channels/consumer';

  @Component({ components: { BaseTable, QrCode, Tooltip, ToggleButton, DsDropdown } })
  export default class ViewConfigPage extends Vue {
    @Ref() readonly layoutsTable?: BaseTable<MobileAppLayout>;
    @Ref() readonly registrationsTable?: BaseTable<MobileAppRegistration>;
    @Prop([String, Number]) readonly mobileAppConfigId!: string | number;

    registrationSubscription: Nullable<Subscription> = null;
    mobileAppConfig: Nullable<MobileAppConfig> = null;
    registrationsManager: Nullable<ListManager<MobileAppRegistration>> = null;

    layoutsManager = new ListManager<MobileAppLayout>({
      fetchDataFunction: (params) => {
        return this.$api.getMobileAppLayouts(
          { ...params, filters: { ...params.filters, mobile_app_config_id: this.mobileAppConfigId } },
          { cache: true }
        );
      },
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: 'Name', name: 'name', sortField: 'name' },
        { title: 'Code', name: 'code', sortField: 'code' },
        { title: 'Default', name: 'isDefault', sortField: 'default' },
        { title: '', name: 'operations' },
      ],
    });

    get currentUserStore() {
      return useCurrentUserStore();
    }

    @Watch('$route.params.reload')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.registrationsTable?.reload();
        this.layoutsTable?.reload();
        this.fetchConfig();
      }
    }

    beforeDestroy(): void {
      this.registrationSubscription?.unsubscribe();
    }

    fetchConfig() {
      this.$api.getMobileAppConfig(this.mobileAppConfigId, { cache: true }).then(({ data }) => {
        this.mobileAppConfig = data;
        const staticQrColumns = this.mobileAppConfig.config.static_qr_code
          ? [
              {
                title: 'Static QR Sign In',
                name: 'static_active',
                dataClass: 'text-center',
                titleClass: 'justify-content-center',
              },
              {
                title: 'Static QR',
                name: 'static_qr',
                dataClass: 'text-center',
                titleClass: 'justify-content-center',
                width: '90px',
              },
            ]
          : [];
        const fields = [
          { title: 'Name', name: 'name', sortField: 'name' },
          { title: 'Test', name: 'test', dataClass: 'text-center', titleClass: 'justify-content-center' },
          { title: 'Device Pairing Status', name: 'state', dataClass: 'text-center', titleClass: 'justify-content-center' },
          ...staticQrColumns,
          { title: 'Dev tools', name: 'tools', dataClass: 'text-center', titleClass: 'justify-content-center' },
          { title: 'Active', name: 'active', sortField: 'active', dataClass: 'text-center', titleClass: 'justify-content-center' },
        ];

        this.registrationsManager = new ListManager<MobileAppRegistration>({
          fetchDataFunction: (params) => {
            return this.$api.getMobileAppRegistrations(
              {
                ...params,
                filters: {
                  ...params.filters,
                  mobile_app_config_id: this.mobileAppConfigId,
                },
                include: ['mobile_app_config'],
              },
              { cache: true }
            );
          },
          per_page: 25,
          sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
          fields,
        });
      });
    }
    beforeMount(): void {
      this.fetchConfig();
    }

    mounted(): void {
      this.registrationSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'MobileApp::Registration' },
        { received: (data: { id: number }) => data.id && this.reloadData('reload') }
      );
    }

    resetRegistration(id: number): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure that you want to reset the registration?',
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api
              .updateMobileAppRegistration(id, { state: 'draft' })
              .then(() => this.reloadData('reload'))
              .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
          }
        },
      });
    }

    updateRegistration(id: number, params: Partial<MobileAppRegistration>): void {
      this.$api
        .updateMobileAppRegistration(id, params)
        .then(() => this.reloadData('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    deleteLayout(id: number): void {
      bootbox.confirm({
        size: 'small',
        message: 'Are you sure?',
        callback: (result: boolean) => {
          result && this.$api.deleteMobileAppLayout(id).then(() => this.reloadData('reload'));
        },
      });
    }

    staticQrCodePath(data: string): string {
      return qrCodeLink(data, {
        image_size: 1000,
        output_format: 'png',
        download: true,
      });
    }
  }
