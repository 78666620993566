
  import CalculationRuleForm from '../../../components/admin/calculation-rules/calculation-rule-form.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { CalculationRule } from '@app/models/calculation-rule';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { CalculationRuleForm, DsModal } })
  export default class CalculationRulesNewPage extends Vue {
    @Ref() readonly form?: CalculationRuleForm;

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-calculation-rules-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<CalculationRule>) {
      this.$api
        .createCalculationRule(form)
        .then(() => {
          this.closeModal('reload');
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }
  }
