
  import WidgetFormModal from '@app/components/admin/dashboards/widgets/widget-form-modal.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import type { WidgetCreate } from '@app/services/api/widgets-api';

  @Component({ components: { WidgetFormModal } })
  export default class AdminDashboardsNewWidgetPage extends Vue {
    @Prop({ type: [String, Number] }) readonly dashboardPaneId?: number | string;
    @Prop({ type: [String, Number] }) readonly dashboardId?: number | string;

    visible = true;

    get cancelTo() {
      switch (this.$route.name) {
        case 'admin-dashboard-pane-edit-new-widget':
          return 'admin-dashboard-pane-edit';
        case 'admin-module-dashboard-panes-edit-new-widget':
          return 'admin-module-dashboard-panes-edit';
        default:
          return 'admin-dashboards-show';
      }
    }

    submitWidgetForm(data: WidgetCreate): void {
      this.$api
        .createWidget({ ...{ dashboard_id: this.dashboardId, dashboard_pane_id: this.dashboardPaneId }, ...data })
        .then(() => {
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          console.error(data?.error);
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: this.cancelTo,
        params: { reloadTable: command, dashboardId: `${this.dashboardId}`, id: `${this.dashboardPaneId}` },
        query: this.$route.query,
      });
    }
  }
