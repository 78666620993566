
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import AdminSettingsLocationTagsForm from '@app/components/admin/location-tags/admin-settings-location-tags-form.vue';
  import bootbox from 'bootbox';
  import type { LocationTag } from '@app/models/location-tag';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { AdminSettingsLocationTagsForm, DsModal } })
  export default class AdminSettingsLocationTagsEdit extends Vue {
    @Ref() readonly form?: AdminSettingsLocationTagsForm;
    @Prop([String, Number]) readonly locationTagId!: string | number;

    locationTag: Nullable<LocationTag> = null;

    visible = true;

    beforeMount(): void {
      this.$api.getLocationTag(Number(this.locationTagId), { include: ['extended_permissions'] }, { cache: true }).then(({ data }) => {
        this.locationTag = data;
      });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-location-tags-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    archiveOrRestore(active: boolean): void {
      bootbox.confirm({
        backdrop: false,
        size: 'small',
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.updateLocationTag({ ...(this.locationTag as LocationTag), active });
        },
      });
    }

    onFormSubmit(locationTag: LocationTag): void {
      this.updateLocationTag({
        ...locationTag,
        id: locationTag.id,
      });
    }

    updateLocationTag(locationTag: LocationTag): void {
      this.$api
        .updateLocationTag(locationTag.id, locationTag)
        .then(() => {
          this.$api.cache.clear();
          this.closeModal('reload');
          toaster(this.$t('tenant.settings.locations_tags.edit.update.locations_tag_updated'));
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
