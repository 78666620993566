
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import HourTypeForm from '../../../components/admin/hour-types/hour-type-form.vue';
  import bootbox from 'bootbox';
  import type { HourType } from '@app/models/hour-type';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { HourTypeForm, DsModal } })
  export default class HourTypeEdit extends Vue {
    @Ref() readonly form?: HourTypeForm;

    hourType: Nullable<HourType> = null;

    visible = true;

    beforeMount(): void {
      this.$api
        .getHourType(
          Number(this.$route.params.hourTypeId),
          {
            only: ['active', 'id', 'percentage', 'name', 'inclusivity_type'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.hourType = data;
        });
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-hour-types-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    archiveOrRestore(hourType: HourType, active: boolean): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: {
            label: this.$t('app.buttons.confirm'),
            className: 'btn-success',
          },
          cancel: {
            label: this.$t('app.buttons.cancel'),
            className: 'btn-default',
          },
        },
        callback: (result: boolean) => {
          result && this.updateHourType({ ...hourType, active });
        },
      });
    }

    onFormSubmit(hourType: HourType): void {
      this.updateHourType(hourType);
    }

    updateHourType(hourType: HourType): void {
      this.$api
        .updateHourType(hourType.id, hourType)
        .then(() => {
          this.closeModal('reload');
          toaster(this.$t('app.labels.hour_type_update'));
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
