
  import { useAccountStore } from '@app/stores/account';
  import { pick } from 'lodash';

  import { Component, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import AccountActions from '@app/components/admin/accounts/account-actions.vue';
  import { AccountService } from '../paper-trails/services';
  import type { Account } from '@app/models/account';
  import type { Timezone } from '@app/models/timezone';
  import { toaster } from '@app/utils/toaster';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';

  @Component({ components: { Select2, AccountActions, LocationSelector, OrganizationSelector } })
  export default class AdminAccountSettingsPage extends Vue {
    form: Partial<Account> = {};
    timezones: Timezone[] = [];
    columns: (keyof Account)[] = [
      'disable_location_tag_report',
      'timezone',
      'complex_timezones',
      'apply_permission_to_selectors',
      'hide_inactive_olu_for_filters',
      'user_selector_filter_restrictions',
      'fallback_location_id',
      'fallback_organization_id',
    ];

    get accountStore() {
      return useAccountStore();
    }

    get auditTrailAttributeScope(): (keyof Account)[] {
      return AccountService.accountSettingsAttributes;
    }

    get userSelectorRestrictions(): [string, string][] {
      return [
        ['', this.$t('activerecord.models.accounts.restrict_non_configurable_user_selectors_by.none')],
        [
          'accessible_by_active_user',
          this.$t('activerecord.models.accounts.restrict_non_configurable_user_selectors_by.accessible_by_active_user'),
        ],
        [
          'accessible_to_active_user',
          this.$t('activerecord.models.accounts.restrict_non_configurable_user_selectors_by.accessible_to_active_user'),
        ],
      ];
    }

    beforeMount(): void {
      this.form = { ...this.accountStore.data };
      this.fetchTimezones();
    }

    fetchTimezones(): void {
      this.$api.getTimezones({ only: ['name', 'title'] }, { cache: true }).then(({ data }) => {
        this.timezones = data;
      });
    }

    submit(account: Partial<Account>): void {
      this.$api
        .updateCurrentAccount(
          pick({ ...account, user_selector_filter_restrictions: account.user_selector_filter_restrictions || '' }, this.columns)
        )
        .then(({ data }) => {
          toaster(this.$t('tenant.admin.settings.menus.account_settings.updated.account'));
          this.$api.cache.clear();
          this.setAccountChanges(data);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    setAccountChanges(account: Account): void {
      const attributesToUpdate = this.columns.reduce((acc, column) => ({ ...acc, [column]: account[column] }), {});
      this.accountStore.$patch({ data: { ...this.accountStore.data, ...attributesToUpdate } });
    }
  }
