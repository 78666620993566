
  import { RegulatoryReportState } from '@app/models/regulatory-report';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { Tooltip } from 'uiv';
  import type { AxiosPromise } from 'axios';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { downloadFile } from '@app/utils/download-file';
  import { titleize } from '@app/utils/titleize';
  import { toaster } from '@app/utils/toaster';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import type { RegulatoryReport } from '@app/models/regulatory-report';
  import type { RegulatoryReportConfig, RegulatoryReportFilter } from '@app/models/regulatory-report-config';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { Subscription } from '@rails/actioncable';

  import RegulatoryReportFormModal from '../regulatory-report/regulatory-report-form-modal.vue';
  import RegulatoryReportErrorsModal from '../regulatory-report/regulatory-report-errors-modal.vue';
  import { BaseTable } from '../base-table';
  import Blocking from '../../mixins/blocking';
  import consumer from '../../channels/consumer';

  interface ExtraFilters {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: { report_filters: Record<string, any> };
  }

  enum DownloadAllState {
    failed = 'failed',
    inProgress = 'inProgress',
    readyForDownload = 'readyForDownload',
    readyForZip = 'readyForZip',
  }

  interface ModalVisibility {
    opened: boolean;
    rendered: boolean;
  }

  @Component({
    methods: { titleize },
    components: { RegulatoryReportErrorsModal, RegulatoryReportFormModal, BaseTable, FilterSelect, Select2, Tooltip },
  })
  export default class AdminRegulatoryReportsPage extends Blocking {
    @Ref() readonly table?: BaseTable<RegulatoryReport>;
    @Prop({ type: [Number, String] }) readonly regulatoryReportConfigId!: number | string;

    reportConfig: Nullable<RegulatoryReportConfig> = null;
    reportConfigModalVisibility: ModalVisibility = { opened: false, rendered: false };
    issuesModalVisible = false;
    selectedRegulatoryReport: Nullable<RegulatoryReport> = null;

    manager: Nullable<ListManager<RegulatoryReport, ExtraFilters>> = null;
    downloadAllInProgressIds: Record<number, boolean> = {};
    notificationsSubscription: Nullable<Subscription> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get showExtractButton(): boolean {
      return !this.reportConfig?.config.hide_extract_button || this.currentUserStore.isDefaultTechAdmin;
    }

    get indexColumnsFromFilters(): RegulatoryReportFilter[] {
      return this.reportConfig?.config?.available_filters?.filter((filter) => filter.showInIndex) || [];
    }

    get indexColumnsFromFilterSlots(): string[] {
      return this.indexColumnsFromFilters.map((filter) => filter.key) || [];
    }

    get downloadAllColumns(): ListManagerField<RegulatoryReport>[] {
      if (!this.reportConfig?.config?.download_all_enabled) return [];

      return [
        {
          title: this.$t('components.admin.regulatory_reports.download_all'),
          name: 'download_all',
          titleClass: 'justify-content-center',
          dataClass: 'text-center',
        },
      ];
    }

    reportFilterValue(filter: string): string {
      return this.manager?.customFilters?.options?.report_filters[filter];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reportFilterCellValue(reportFilters: Record<string, any>, field: string): string {
      const blankValue = this.reportConfig?.config.available_filters?.find((filter) => filter.key === field)?.indexBlankValue;
      return reportFilters ? (reportFilters[field] || blankValue || '').toString() : ''; // toString for array
    }

    onIndexColumnFilterChanged(filter: string, value: string): void {
      if (this.manager) {
        this.manager.customFilters = {
          ...this.manager.customFilters,
          options: {
            ...(this.manager.customFilters.options || {}),
            report_filters: {
              ...this.manager.customFilters.options?.report_filters,
              [filter]: value,
            },
          },
        };
      }
    }

    getManager(): ListManager<RegulatoryReport, ExtraFilters> {
      return new ListManager<RegulatoryReport, ExtraFilters>({
        fetchDataFunction: (params) => {
          return this.$api.getRegulatoryReports(
            {
              ...params,
              include: ['user', 'attachments', 'data'],
              filters: {
                ...params.filters,
                regulatory_report_config_id: this.regulatoryReportConfigId,
              },
            },
            { cache: true }
          );
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created_at' }],
        fields: [
          { title: this.$t('components.admin.regulatory_reports.id'), name: 'id', sortField: 'id' },
          { title: this.$t('components.admin.regulatory_reports.created_at'), name: 'created_at', sortField: 'created_at' },
          { title: this.$t('components.admin.regulatory_reports.user_id'), name: 'user_id' },
          {
            title: this.$t('components.admin.regulatory_reports.state'),
            name: 'state',
            sortField: 'state',
            titleClass: 'justify-content-center',
            dataClass: 'text-center',
          },
          ...this.indexColumnsFromFilters.map((filter) => ({
            name: filter.key,
            title: filter.name,
            filter: filter.showIndexFilter,
          })),
          { title: this.$t('components.admin.regulatory_reports.attachments'), name: 'attachments' },
          ...this.downloadAllColumns,
        ],
        allowFilters: true,
      });
    }

    extractReport(): void {
      if (Object.keys(this.reportConfig?.config.available_filters || {}).length || this.reportConfig?.config?.available_formats?.length) {
        this.reportConfigModalVisibility = { ...this.reportConfigModalVisibility, rendered: true, opened: true };
      } else {
        this.createReport({ regulatory_report_config_id: +this.regulatoryReportConfigId });
      }
    }

    createReport(data: Partial<RegulatoryReport>): AxiosPromise<RegulatoryReport> {
      return this.$api.createRegulatoryReport(data).then((result) => {
        this.refresh();
        return result;
      });
    }

    createZip(report: RegulatoryReport) {
      this.downloadAllInProgressIds = { ...this.downloadAllInProgressIds, [report.id]: true };
      this.blocking(async () => {
        await this.$api.zipRegulatoryReportAttachments(report.id);
        this.$api.cache.clear();
        this.table?.reload();
      }).finally(() => this.$nextTick(() => (this.downloadAllInProgressIds[report.id] = false)));
    }

    downloadZip(report: RegulatoryReport) {
      const attachmentId = report.data?.download_all_attachment_id;
      if (!attachmentId) return;

      this.downloadAllInProgressIds = { ...this.downloadAllInProgressIds, [report.id]: true };
      this.blocking(async () => {
        const { data: attachment } = await this.$api.getAttachment(attachmentId);
        downloadFile(attachment.url, attachment.file_name);
      }).finally(() => (this.downloadAllInProgressIds[report.id] = false));
    }

    isReportCompleted(report: Partial<RegulatoryReport>): boolean {
      return report.state === RegulatoryReportState.completed;
    }

    downloadAllState(report: RegulatoryReport): Nullable<DownloadAllState> {
      if (!this.isReportCompleted(report)) return null;

      if (this.downloadAllInProgressIds[report.id]) {
        return DownloadAllState.inProgress;
      } else if (!!report.data?.download_all_error) {
        return DownloadAllState.failed;
      } else if (report.data?.download_all_attachment_id) {
        return DownloadAllState.readyForDownload;
      } else {
        return DownloadAllState.readyForZip;
      }
    }

    showIssues(report: RegulatoryReport) {
      this.selectedRegulatoryReport = { ...report };
      this.issuesModalVisible = true;
    }

    hasIssues(report: RegulatoryReport): boolean {
      return !!report.data?.issues?.length;
    }

    refresh() {
      this.$api.cache.clear();
      this.table?.reload();
    }

    onSubmit(form: Partial<RegulatoryReport>): void {
      this.createReport(form).then(() => {
        this.reportConfigModalVisibility = { ...this.reportConfigModalVisibility, opened: false };
      });
    }

    beforeMount(): void {
      this.notificationsSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'RegulatoryReport' },
        {
          received: this.refresh,
        }
      );
      this.$api
        .getRegulatoryReportConfig(Number(this.regulatoryReportConfigId))
        .then(({ data }) => {
          this.reportConfig = data;
          this.manager = this.getManager();
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, icon: 'error' });
          this.$router.replace({
            name: 'admin-regulatory-reports-index',
            query: this.$route.query,
          });
        });
    }

    beforeDestroy(): void {
      this.notificationsSubscription?.unsubscribe();
    }
  }
