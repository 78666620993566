
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import RoleProfileWarningsModal from '@app/components/admin/role-profiles/role-profile-warnings-modal.vue';
  import { keyBy } from 'lodash';
  import type { ModuleName } from '@app/models/module-name';
  import type { Role } from '@app/models/role';
  import type { RoleProfile } from '@app/models/role-profile';

  @Component({ components: { RoleProfileWarningsModal } })
  export default class RoleProfileWarnings extends Vue {
    @Prop({ type: Array, required: true }) readonly moduleNames!: Pick<ModuleName, 'id' | 'plural_display'>[];
    @Prop(Boolean) readonly withModalLink?: boolean;

    roleProfiles: Pick<RoleProfile, 'id' | 'name' | 'module_name_id' | 'roles'>[] = [];
    roles: Role[] = [];
    modalVisible = false;

    async beforeMount(): Promise<void> {
      await this.fetchRoleProfiles();
    }

    get warningMessagesList(): string[] {
      return this.unlinkedRoleProfiles
        .reduce<string[]>((memo, rp) => {
          const module = this.moduleNamesById[rp.module_name_id];
          if (module) {
            const message = this.$t('tenant.admin.role_profiles.warning_message', {
              module_plural_display: module.plural_display,
              role_profile_name: rp.name,
            });

            return [...memo, message];
          }
          return memo;
        }, [])
        .sort();
    }

    async fetchRoleProfiles(): Promise<void> {
      const { data: roleProfiles } = await this.$api.getRoleProfiles(
        {
          filters: { active: true, required: true, module_name_id: this.moduleNames.map((mn) => mn.id) },
          only: ['id', 'name', 'module_name_id', { roles: ['id', 'active'] }],
        },
        { cache: true }
      );

      this.roleProfiles = roleProfiles;
    }

    get moduleNamesById(): Record<number, Pick<ModuleName, 'id' | 'plural_display'>> {
      return keyBy(this.moduleNames, 'id');
    }

    get unlinkedRoleProfiles(): Pick<RoleProfile, 'id' | 'name' | 'module_name_id' | 'roles'>[] {
      return this.roleProfiles.filter((rp) => !(rp?.roles || []).filter((role) => role.active).length);
    }

    get warningsCount(): number {
      return this.unlinkedRoleProfiles.length;
    }

    get viewModalLinkText(): string {
      return this.$t('tenant.admin.role_profiles.view');
    }

    get warningText(): string {
      return this.$t('tenant.admin.role_profiles.warnings_count', { count: this.warningsCount });
    }

    toggleModal(): void {
      this.modalVisible = !this.modalVisible;
    }
  }
