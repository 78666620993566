
  import { Vue, Prop, Emit } from 'vue-property-decorator';
  import Component from 'vue-class-component';
  import BasicElement from './basic-element.vue';
  import TextElement from './text-element.vue';
  import NumberElement from './number-element.vue';
  import Bracket from './bracket.vue';
  import Draggable from 'vuedraggable';
  import type { ElementType, Formula, FormulaElement, TemplateItemObject } from '../models/types';
  import { LogicElementTypes } from '../models/types';
  import type { Dictionary } from '@app/models/dictionary';

  @Component({ name: 'logic-element', components: { BasicElement, TextElement, NumberElement, Bracket, Draggable } })
  export default class LogicElement extends Vue {
    @Prop({ required: true }) readonly id!: number;
    @Prop({ required: true }) readonly type!: ElementType;
    @Prop({ required: true }) value!: FormulaElement;
    @Prop({ required: true }) readonly componentTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly ruleTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly variableTemplateItems!: Dictionary<TemplateItemObject>;
    @Prop({ required: true }) readonly updateElementValue!: (logicElementId: string, newValue: string) => void;
    @Prop({ required: true }) readonly getElementType!: (logicElementValue: string | Formula) => ElementType;

    @Emit('update-dragging')
    updateDragging(state: boolean): boolean {
      return state;
    }

    LogicElementTypes = LogicElementTypes;
    control = 1;
    backgroundColorByType = {
      operator: '#6ddba8',
      comparison: '#82aae5',
      ifelse: 'orange',
    };

    get renderIcon(): boolean {
      const specialRenderItems = ['+', '-', '*', '/', '^', '<', '>', '<=', '>=', '=', 'IF', 'ELSIF', 'ELSE'];
      return typeof this.value === 'string' ? specialRenderItems.includes(this.value) : false;
    }

    onDragStart(): void {
      this.updateDragging(true);
    }
    onDragEnd(): void {
      this.updateDragging(false);
    }

    removeOnOutsideDrag(event: { oldIndex: number }): void {
      if (Array.isArray(this.value)) {
        this.value.splice(event.oldIndex, 1);
        this.control++;
      }
    }
  }
