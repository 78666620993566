
  import { Component } from 'vue-property-decorator';

  import EntitySelector from '../../../entity-selector.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import UserInvolvementEventForm from './user-involvement-event-form.vue';

  @Component({ components: { EntitySelector, Select2, FormField } })
  export default class RemoveUserInvolvementEventForm extends UserInvolvementEventForm {}
