
  import BaseTable from '@app/components/base-table/base-table.vue';
  import DsLabel from '@app/components/ds-label.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import type { Location } from '@app/models/location';
  import type { ModuleName } from '@app/models/module-name';
  import type { Procedure } from '@app/models/procedure';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
  import type { ProcedureIndexExtraFilters } from '@app/services/api/procedures-api';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import { entityPathPrefix } from '@app/services/helpers';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({
    components: { Select2, OrganizationSelector, FilterCheckbox, EntitySelector, LocationSelector, FilterSelect, DsLabel, BaseTable },
  })
  export default class ProceduresTable extends Vue {
    @Prop(Boolean) responsive?: boolean;
    @Prop(Boolean) allowFilters?: boolean;
    @Prop(Boolean) useHistory?: boolean;
    @Prop(String) readonly loaderPosition?: 'fixed' | 'absolute';

    manager: Nullable<ListManager<Procedure>> = null;

    kb: Nullable<Pick<ModuleName, 'use_location_permissions' | 'use_organization_permissions'>> = null;
    acknowledgeableOptions = [
      ['true', this.$t('app.labels.yes')],
      ['false', this.$t('app.labels.no')],
    ];
    procedureCategoryOptions: Nullable<[string, string][]> = null;

    get accountStore() {
      return useAccountStore();
    }

    get locationFilters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      const base = { with_restrictions: this.accountStore.data.apply_permission_to_selectors };
      if (this.accountStore.data.hide_inactive_olu_for_filters) {
        return { ...base, active: true };
      }
      return base;
    }

    get organizationFilters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      const base = { with_restrictions: this.accountStore.data.apply_permission_to_selectors };
      if (this.accountStore.data.hide_inactive_olu_for_filters) {
        return { ...base, active: true };
      }
      return base;
    }

    getManager() {
      return new ListManager<Procedure, ProcedureIndexExtraFilters>({
        allowFilters: this.allowFilters,
        useHistory: this.useHistory,
        fetchDataFunction: (params) =>
          this.$api.getProcedures(
            {
              ...params,
              only: [
                'id',
                'name',
                'acknowledgeable',
                'created_at',
                {
                  workflow: ['id', 'name'],
                  procedure_category: ['id', 'name'],
                  locations: ['id', 'name'],
                  organizations: ['id', 'name'],
                },
              ],
            },
            { cache: true }
          ),
        fields: [
          { name: 'name', title: this.$t('app.labels.name'), sortField: 'name' },
          { name: 'workflow', title: this.$t('app.labels.workflow'), sortField: 'workflow.name', filter: true },
          { name: 'procedure_category', title: this.$t('app.labels.category'), sortField: 'procedure_category.name', filter: true },
          ...(this.kb?.use_location_permissions &&
          (this.accountStore.limitPermissionsByLocation || this.accountStore.limitPermissionsByExplicitLocations)
            ? [{ name: 'locations', title: this.$t('app.labels.locations'), filter: true }]
            : []),
          ...(this.kb?.use_organization_permissions && this.accountStore.limitPermissionsByOrganization
            ? [{ name: 'organizations', title: this.$t('app.labels.organizations'), filter: true }]
            : []),
          { name: 'created_at', title: this.$t('app.labels.created_at'), sortField: 'created_at' },
          { name: 'acknowledgeable', title: this.$t('app.labels.acknowledgeable'), sortField: 'acknowledgeable', filter: true },
        ],
      });
    }

    procedurePath(procedure: Procedure) {
      return `/${entityPathPrefix('Procedure')}/${procedure.id}`;
    }

    async mounted() {
      const { data: moduleNames } = await this.$api.getModuleNames({
        filters: { name: 'Procedure' },
        only: ['use_location_permissions', 'use_organization_permissions'],
      });
      this.kb = moduleNames[0];

      if (this.kb) {
        this.manager = this.getManager();
        this.$emit('initialized');
      }

      const { data } = await this.$api.getProcedureCategoryFilters();
      this.procedureCategoryOptions = data;
    }
  }
