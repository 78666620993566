
  import I18n from '@app/i18n';
  import { ValidationObserver, extend } from 'vee-validate';
  import { Component, Prop, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import { Tooltip } from 'uiv';
  import bootbox from 'bootbox';
  import DsTextInput from '@app/components/ds-text-input.vue';
  import snakeCase from '@app/validators/snake-case-rule';
  import Select2 from '@app/components/select2.vue';
  import OauthTwoForm from './forms/oauth2-placeholder-profile-config-form.vue';
  import GlobalForm from './forms/global-placeholder-profile-config-form.vue';

  import type { PlaceholderFormOnly, PlaceholderProfileForm } from '@app/pages/admin/placeholder_profiles/utils';
  import { PlaceholderProfileType } from '@app/pages/admin/placeholder_profiles/utils';
  import type { PlaceholderProfile } from '@app/models/placeholder-profile';
  import type { PlaceholderVariable } from '@app/models/placeholder-variable';
  import WithPlaceholderProfileTypeOptions from '@app/mixins/with-placeholder-profile-type-options';

  @Component({ components: { ValidationObserver, Select2, Tooltip, DsTextInput, GlobalForm, OauthTwoForm } })
  export default class PlaceholderProfilesForm extends mixins(WithPlaceholderProfileTypeOptions) {
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly placeholderProfile?: Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>>;
    @Prop(Boolean) readonly disabled?: boolean;

    form: Partial<PlaceholderProfileForm> = {};

    updateVariableFromIndex(value: string, index: number): void {
      this.form.placeholder_variables &&
        this.$set(this.form.placeholder_variables, index, {
          ...(this.form.placeholder_variables?.[index] || {}),
          value,
        });
    }

    removeVariable(index: number): void {
      if (this.form.id) {
        bootbox.confirm({
          message: this.$t('tenant.settings.placeholder_profiles.form.remove_variable_warning'),
          backdrop: false,
          buttons: {
            confirm: { label: this.$t('app.buttons.proceed'), className: 'btn-success' },
            cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
          },
          callback: (result: boolean) => {
            result && this.form.placeholder_variables?.splice(index, 1);
          },
        });
      } else {
        this.form.placeholder_variables?.splice(index, 1);
      }
    }

    addVariable(): void {
      this.form = {
        ...this.form,
        placeholder_variables: [
          ...(this.form.placeholder_variables || []),
          {
            handle: '',
            value: '',
            description: '',
            restricted: false,
          } as PlaceholderVariable,
        ],
      };
    }

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    beforeMount(): void {
      extend('alpha_underscore', snakeCase);
      extend('uniq_variable_handle', {
        validate: (handle: unknown) => {
          return (this.form.placeholder_variables?.filter((v) => v.handle === handle).length || 0) < 2;
        },
        message: 'Handles should be uniq.',
      });

      extend('uniq_profile_handle', {
        validate: (handle: string) => {
          return this.$api
            .getPlaceholderProfiles({ only: 'handle', filters: { handle, '!id': this.form.id } }, { cache: true })
            .then(({ data }) => {
              return { valid: !data.length };
            })
            .catch(({ data }) => {
              return {
                valid: false,
                data: data?.error || I18n.t('app.labels.something_went_wrong'),
              };
            });
        },
        message: 'Profile handle is not uniq.',
      });

      this.form = {
        profile_type: PlaceholderProfileType.global_placeholder,
        placeholder_variables: [],
        ...this.placeholderProfile,
      };
    }
  }
