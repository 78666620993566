import type { BaseEntity } from '../base-entity';
import type { TenantUser } from '@app/models/tenant-user';

export enum AppBundleInstallationErrorReason {
  attachment_skip = 'attachment_skip',
  event_per_skip = 'event_per_skip',
  no_default_value = 'no_default_value',
  question_config_filters = 'question_config_filters',
  question_config_index_options = 'question_config_index_options',
  question_field_type_skip = 'question_field_type_skip',
  record_collection_rule_set_invalid = 'record_collection_rule_set_invalid',
  response_field_type_skip = 'response_field_type_skip',
  sub_form_list_filters = 'sub_form_list_filters',
  sub_form_list_index_options = 'sub_form_list_index_options',
  svg_library_template_not_copied = 'svg_library_template_not_copied',
}

export interface AppBundleInstallationError {
  backtrace?: string[];
  error: string;
  reason: AppBundleInstallationErrorReason | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: Record<string, any>;
}

export interface AppBundleMetadata {
  app_bundle_id: string;
  bundle_version: string;
  code: string;
  db_schema_version: number;
  menu_collection_id?: number;
  module_name_names?: Record<string, string>;
  name: string;
  source_schema: string;
}

export interface AppBundleInstallationErrors {
  base: object;
  dashboards: Record<string, { error: string }[]>;
  installed_at: string;
  modules: Record<string, AppBundleInstallationError[]>;
  placeholder_profiles: object;
  regulatory_report_configs: object;
  svg_library_templates: object;
}

export interface AppBundleInstallation extends BaseEntity {
  bundle_name: string;
  installation_errors: AppBundleInstallationErrors;
  installed_at?: string;
  metadata: AppBundleMetadata;
  stage: string;
  url?: string;
  user?: TenantUser;
  user_id: number;
}
