
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { OptionalSignatureData } from '@app/services/api/record-signatures-api';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SidePanel from '../side-panel.vue';
  import SignatureFormModal from './signature-form-modal.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { RecordSignature } from '@app/models/record-signature';
  import type { Signature } from '@app/models/signature';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { SignatureFormModal, SidePanel, DsDropdown } })
  export default class SignaturePanel extends Vue {
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(String) readonly entityType!: string;
    @Prop(Number) readonly entityId!: number;
    @Prop(Object) readonly moduleName!: Pick<ModuleName, 'id' | 'name' | 'show_options'>;

    submitting = false;
    modalVisible = false;
    recordSignatures: RecordSignature[] = [];

    get currentUserStore() {
      return useCurrentUserStore();
    }

    submitForm(data: OptionalSignatureData): void {
      this.submitting = true;
      this.$api
        .createRecordSignature({
          signable_id: this.entityId,
          signable_type: this.entityType,
          ...data,
        })
        .then(() => {
          this.toggleModal(false);
          this.fetchData(true);
        })
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => (this.submitting = false));
    }

    get isSigned(): boolean {
      return this.recordSignatures.some((rs) => this.isCurrentUserAuthor(rs?.signature));
    }

    deleteRecordSignature(recordSignature: RecordSignature): void {
      const deleteConfirmation = confirm(this.$t('tenant.shared.comments_and_relations.delete_your_signature') as string);
      if (!deleteConfirmation) return;
      this.$api.deleteRecordSignature(recordSignature.id).then(() => this.fetchData(true));
    }

    // TODO: not used
    useDefaultSignature(): void {
      if (this.currentUserStore.data?.primary_signature_id) {
        this.$api
          .createRecordSignature({
            signable_id: this.entityId,
            signable_type: this.entityType,
            signature_id: this.currentUserStore.data.primary_signature_id,
          })
          .then(() => {
            this.toggleModal(false);
            this.fetchData(true);
          });
      }
    }

    userName(signature: Signature): string {
      return signature.user?.full_name || 'N/A';
    }

    isCurrentUserAuthor(signature?: Signature): boolean {
      return signature?.user_id === this.currentUserStore.data?.id;
    }

    mounted(): void {
      this.fetchData();
    }

    fetchData(resetCache = false): void {
      resetCache && this.$api.cache.clear();
      this.$api
        .getRecordSignatures(
          {
            filters: {
              signable_id: this.entityId,
              signable_type: this.entityType,
            },
            include: ['signature', 'user'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordSignatures = data.filter((item) => {
            if (!item.signature) {
              this.$api.deleteRecordSignature(item.id);
            } else {
              return item;
            }
          });
        });
    }

    toggleModal(value: boolean): void {
      this.modalVisible = value;
    }
  }
