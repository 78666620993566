import { debounce, groupBy, uniq } from 'lodash';
import { API } from '@app/services/api-factory';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type { SubForm } from '@app/models/sub-form';

export enum DuplicateCategory {
  CanMatch = 'can-match',
  CannotMatch = 'cannot-match',
  CodeMatched = 'code-matched',
  CodeShared = 'code-shared',
  Inactive = 'inactive',
  MissingShowHideRules = 'missing-show-hide-rules',
  NoMatches = 'no-matches',
  NoShowHideRules = 'no-show-hide-rules',
}

export interface MatchData {
  category: DuplicateCategory;
  question: SubFormQuestion;
  sameType: boolean;
}

export default class DuplicateCodeQuestionsFinder {
  sourceQuestion!: SubFormQuestion;
  subForm!: SubForm;
  editingCode: boolean;

  sortedData: MatchData[] = [];
  topCategory: DuplicateCategory = DuplicateCategory.NoMatches;

  loading = false;
  sameCodeQuestions: SubFormQuestion[] = [];

  debounceRefresh = debounce(this.refresh, 1000);

  constructor(sourceQuestion: SubFormQuestion, subForm: SubForm, editingCode: boolean) {
    this.sourceQuestion = sourceQuestion;
    this.subForm = subForm;
    this.editingCode = editingCode;
  }

  get topCategoryIsError(): boolean {
    return this.categoryIsError(this.topCategory);
  }

  get editing(): boolean {
    return this.editingCode || !this.sourceQuestion.id;
  }

  get sourceQuestionShowHideValueArray(): string[] {
    return this.showHideValueArray(this.sourceQuestion);
  }

  setSortedData(): MatchData[] {
    const data = groupBy(
      this.sameCodeQuestions.map((q) => this.questionMatch(q)),
      'category'
    );
    this.sortedData = [
      DuplicateCategory.CannotMatch,
      DuplicateCategory.MissingShowHideRules,
      DuplicateCategory.NoShowHideRules,
      DuplicateCategory.CanMatch,
      DuplicateCategory.CodeMatched,
      DuplicateCategory.Inactive,
      DuplicateCategory.CodeShared,
    ].reduce<MatchData[]>((prev, curr) => prev.concat(data[curr] || []), []);
    return this.sortedData;
  }

  setTopCategory(): DuplicateCategory {
    const sortedCategories = [
      DuplicateCategory.CannotMatch,
      DuplicateCategory.MissingShowHideRules,
      DuplicateCategory.NoShowHideRules,
      DuplicateCategory.CanMatch,
      DuplicateCategory.Inactive,
      DuplicateCategory.CodeMatched,
      DuplicateCategory.CodeShared,
    ];
    this.topCategory = this.topCategoryOf(sortedCategories) || DuplicateCategory.NoMatches;
    return this.topCategory;
  }

  topCategoryOf(categories: DuplicateCategory[]): DuplicateCategory | undefined {
    return categories.find((cat) => this.sortedData.some((d) => d.category == cat));
  }

  errorCategory(): DuplicateCategory {
    const sortedCategories = [DuplicateCategory.CannotMatch, DuplicateCategory.MissingShowHideRules, DuplicateCategory.NoShowHideRules];
    return this.topCategoryOf(sortedCategories) || DuplicateCategory.NoMatches;
  }

  editingCategory(question: SubFormQuestion): DuplicateCategory {
    return question.active ? this.conditionsClash(question) : DuplicateCategory.Inactive;
  }

  questionMatch(question: SubFormQuestion): MatchData {
    return {
      category: this.editingCategory(question),
      question,
      sameType: this.sourceQuestion.field_type === question.field_type,
    };
  }

  conditionsClash(question: SubFormQuestion): DuplicateCategory {
    const questionShowHide = question.config.show;

    const showHideSource = this.sourceQuestion.config.show;

    if (!questionShowHide) return DuplicateCategory.NoShowHideRules;
    if (!showHideSource) return DuplicateCategory.MissingShowHideRules;
    if (!questionShowHide.id) return DuplicateCategory.NoShowHideRules;
    if (!showHideSource.id) return DuplicateCategory.MissingShowHideRules;
    if (showHideSource.id !== questionShowHide.id) return DuplicateCategory.CanMatch;
    // handle case when source is empty string or undefined or null
    if ((showHideSource.source || questionShowHide.source) && showHideSource.source !== questionShowHide.source) {
      return DuplicateCategory.CanMatch;
    }
    if (!questionShowHide.value.length) return DuplicateCategory.NoShowHideRules;
    if (!showHideSource.value.length) return DuplicateCategory.MissingShowHideRules;

    return ([] as string[]).concat(showHideSource.value).every((val) => !questionShowHide.value.includes(val))
      ? DuplicateCategory.CanMatch
      : DuplicateCategory.CannotMatch;
  }

  showHideValueArray(question?: SubFormQuestion): string[] {
    if (!question?.config.show) return [];
    return Array.isArray(question.config.show.value) ? question.config.show.value : [question.config.show.value];
  }

  conflicts(question: SubFormQuestion): string[] {
    if (this.conditionsClash(question) !== DuplicateCategory.CannotMatch) return [];

    const questionShowHide = question.config.show;
    const showHideSource = this.sourceQuestion.config.show;
    if (!showHideSource || !questionShowHide) return [];

    return this.sourceQuestionShowHideValueArray.filter((val) => this.showHideValueArray(question).includes(val));
  }

  codeClashes(): string[] {
    return uniq(this.sameCodeQuestions.flatMap((question) => this.conflicts(question)));
  }

  recalculate(): void {
    this.setSortedData();
    this.setTopCategory();
  }

  async refresh() {
    this.loading = true;
    if (this.sourceQuestion.code && this.sourceQuestion.code.length) {
      try {
        const { data } = await API.getSubFormQuestions(
          {
            only: ['id', 'config', 'code', 'active', 'field_type', 'system_code', 'question', 'title'],
            per_page: -1,
            filters: { '!id': this.sourceQuestion.id, code: this.sourceQuestion.code, sub_form_id: this.subForm.id },
          },
          { cache: true }
        );
        this.sameCodeQuestions = data;
        this.recalculate();
        return { errorCategory: this.errorCategory(), category: this.topCategory };
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.sameCodeQuestions = [];
      this.recalculate();
      return { errorCategory: this.errorCategory(), category: this.topCategory };
    }
  }

  categoryIsError(category: DuplicateCategory): boolean {
    return (
      category === DuplicateCategory.CannotMatch ||
      category === DuplicateCategory.MissingShowHideRules ||
      category === DuplicateCategory.NoShowHideRules
    );
  }
}
