import { render, staticRenderFns } from "./sub-form-section-show.vue?vue&type=template&id=059a7106&scoped=true&lang=pug"
import script from "./sub-form-section-show.vue?vue&type=script&lang=ts"
export * from "./sub-form-section-show.vue?vue&type=script&lang=ts"
import style0 from "./sub-form-section-show.vue?vue&type=style&index=0&id=059a7106&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059a7106",
  null
  
)

export default component.exports