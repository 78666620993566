
  import { Component, Watch } from 'vue-property-decorator';
  import { ValidationProvider } from 'vee-validate';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import { CALCULATE_COMMAND, RUN_AUTO_SELECT_COMMAND } from '@app/models/sub-form-question';
  import type { UpdateTarget } from '@app/models/event';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import Select2 from '../../../select2.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface EventValueOption {
    key: string;
    type: string;
    value: string | string[];
  }

  @Component({ components: { EventInputMethodSwitch, Select2, AdvancedAutosuggest, FormField, ValidationProvider } })
  export default class UpdateRecordEventForm extends BaseEventForm<Dictionary<string>> {
    options: EventValueOption[] = [];
    updateTarget: Partial<UpdateTarget> = {};

    fieldTypes = [
      ['file', 'File'],
      ['text', 'Text'],
      ['calculation', this.$t('app.labels.perform_calculation')],
      ['auto_select', this.$t('app.labels.auto_select_command.perform')],
    ];

    get recordTypes(): [string, string][] {
      return [
        ['ModuleRecord', this.$t('app.labels.main_form')],
        ['SubFormCompletion', this.$t('app.labels.sub_form')],
      ];
    }

    get calculatorSelectOptions(): [string, string][] {
      return [[CALCULATE_COMMAND, this.$t('app.labels.calculation')]];
    }

    get autoSelectSelectOptions(): [string, string][] {
      return [[RUN_AUTO_SELECT_COMMAND, this.$t('app.labels.auto_select')]];
    }

    @Watch('options', { deep: true })
    onOptionsChange(): void {
      this.syncForm();
    }

    @Watch('updateTarget', { deep: true })
    onTargetChange(target: UpdateTarget): void {
      if (this.syncedEvent) {
        if (this.syncedEvent?.event_type !== 'api_request') {
          this.syncedEvent.options = {
            ...this.syncedEvent.options,
            target: target,
          };
        }
      }
    }

    getOptions(): EventValueOption[] {
      return Object.keys(this.form).map((key) => {
        const fieldValue = this.form[key] as string;
        let type: string;
        if (Array.isArray(fieldValue)) {
          type = 'file';
        } else if (fieldValue === CALCULATE_COMMAND) {
          type = 'calculation';
        } else if (fieldValue === RUN_AUTO_SELECT_COMMAND) {
          type = 'auto_select';
        } else {
          type = 'text';
        }
        return {
          key,
          value: fieldValue,
          type,
        };
      });
    }

    onRecordTypeChange(): void {
      this.updateTarget = { ...this.updateTarget, record_id: undefined };
    }

    updateOptions(): void {
      this.options = this.getOptions();
    }

    removeOption(index: number): void {
      this.options = this.options.slice(0, index).concat(this.options.slice(index + 1));
    }

    onValueTab(_option: EventValueOption, index: number, $event: KeyboardEvent): void {
      if ($event.shiftKey) {
        return;
      }
      if (this.options.length - 1 === index) {
        this.addNewOption();
      }
    }

    onOptionValueChange(option: EventValueOption, value: string): void {
      this.options = this.options.map((o) => {
        return o.key !== option.key ? o : { ...o, value: value.trim() };
      });
    }

    syncForm(): void {
      this.form = {
        ...this.options.reduce((memo, o) => ({ ...memo, [o.key]: o.value }), {}),
      };
    }

    addNewOption(): void {
      this.options = [...this.options, { key: '', type: 'text', value: '' }];
    }

    onFieldTypeChange(fieldType: string, index: number): void {
      if (fieldType == 'text') {
        this.options[index].value = '';
      } else if (fieldType == 'file') {
        this.options[index].value = [];
      }
    }

    beforeMount(): void {
      this.form = { ...this.values };
      this.updateTarget = { ...this.target };
      this.updateOptions();
      if (!this.options.length) {
        this.addNewOption();
      }
    }
  }
