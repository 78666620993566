
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
  import RuleFilterSelect from './rule-filter-select.vue';
  import RuleOperatorSelect from './rule-operator-select.vue';
  import RuleValueInput from './rule-value-input.vue';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import type { DsGroupNode } from '../models/ds-group-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';
  import DsIcon from '@app/components/ds-icon.vue';

  @Component({
    components: {
      RuleFilterSelect,
      RuleOperatorSelect,
      RuleValueInput,
      DsIcon,
    },
  })
  export default class DsRuleItem extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Prop() readonly rule!: DsRuleNode;
    @Prop() readonly group!: DsGroupNode;
  }
