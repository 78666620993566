
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import SectionGroupFormModal from './section-group-form-modal.vue';
  import type { AsfSfsgOnly } from '../admin/sub-forms/utils';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';

  @Component({ components: { SectionGroupFormModal } })
  export default class OpenSectionGroupForm extends Vue {
    @Prop(Object) readonly subForm!: Pick<SubForm, 'id' | 'module_name' | 'scoring'>;
    @Prop(Array) readonly subFormSectionGroups?: Pick<SubFormSectionGroup, AsfSfsgOnly>[];

    open = false;
    sectionGroupId: Nullable<number> = null;
    showModal = false;
    triggerFromIndex = 0;

    edit(sectionGroupId: number): void {
      this.showModal = true;
      this.sectionGroupId = sectionGroupId;
      this.open = true;
    }

    create(index?: number) {
      this.showModal = true;
      this.triggerFromIndex = index || 0;
      this.open = true;
    }

    onHide(): void {
      this.open = false;
      this.showModal = false;
      this.sectionGroupId = null;
    }
  }
