
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import type { TabSectionGroup } from '@app/models/tab-section-group';
  import type { ModuleName } from '@app/models/module-name';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';

  import { BaseTable } from '../base-table';

  import TabSectionGroupFormModal from './tab-section-group-form-modal.vue';

  @Component({ components: { TabSectionGroupFormModal, BaseTable, DsDropdown, PaperTrailsModalLink } })
  export default class ModuleTabSectionGroupsPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    @Ref() readonly table?: BaseTable<TabSectionGroup>;

    moduleName: Nullable<ModuleName> = null;

    scoringGroupModalVisible = false;
    manager: Nullable<ListManager<TabSectionGroup>> = null;
    selectedTabSectionGroup: Partial<TabSectionGroup> = {};

    get moduleTabIds(): number[] {
      return this.moduleName?.module_tabs?.map((tab) => tab['id']) || [];
    }

    getManager(): ListManager<TabSectionGroup> {
      return new ListManager<TabSectionGroup>({
        fetchDataFunction: (params) => {
          return this.$api.getTabSectionGroups({ ...params, filters: { module_name: this.moduleName?.name } }, { cache: true });
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          { title: this.$t('tenant.admin.tab_section_groups.table_head.name'), name: 'name', sortField: 'name' },
          { title: this.$t('tenant.admin.tab_section_groups.table_head.code'), name: 'code', sortField: 'code' },
          { title: this.$t('tenant.admin.tab_section_groups.table_head.active'), name: 'active', sortField: 'active' },
          { title: '', name: 'operations', width: 'max-content' },
        ],
      });
    }

    addNewTabSectionGroup(): void {
      this.selectedTabSectionGroup = { module_name: this.moduleName?.name };
      this.toggleTabSectionGroupModal(true);
    }

    editTabSectionGroup(entity: TabSectionGroup): void {
      this.selectedTabSectionGroup = entity;
      this.toggleTabSectionGroupModal(true);
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    beforeMount(): void {
      this.$api
        .getModuleName(Number(this.moduleNameId), { only: ['name', 'module_tabs'] }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    toggleTabSectionGroupModal(value: boolean): void {
      this.scoringGroupModalVisible = value;
    }

    submitTabSectionGroupForm(entity: Partial<TabSectionGroup>): void {
      const promise = entity.id ? this.$api.updateTabSectionGroup(entity.id, entity) : this.$api.createTabSectionGroup(entity);

      promise
        .then(({}) => {
          this.$api.cache.clear();
          this.table?.reload();
          this.toggleTabSectionGroupModal(false);
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }
  }
