
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import type { MenuCollection } from '@app/models/menu-collection';
  import { entityPathPrefix } from '@app/services/helpers';
  import { mapKeys } from 'lodash';
  import PerfectScrollbar from 'vue-perfect-scrollbar';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import type { Nullable } from 'vitest';
  import type { Subscription } from 'rxjs';
  import type { Account } from '@app/models/account';
  import type { Acl } from '@app/models/acl';
  import type { CurrentUser } from '@app/models/current-user';
  import type { Dashboard } from '@app/models/dashboard';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ModuleName } from '@app/models/module-name';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { UI_OBSERVER } from '@app/services/global-observer';
  import type { PartialBy } from '@app/utils/types/partial';
  import type { RequiredBy } from '@app/utils/types/required-by';

  type AddPrefixToObject<T, P extends string> = {
    [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
  };
  // eslint-disable-line @typescript-eslint/no-explicit-any
  type IntersectionObserverPolyfill = IntersectionObserver & {
    POLL_INTERVAL: number;
  };

  interface MenuItem {
    click?: () => void;
    icon: string;
    id?: string;
    items?: PartialBy<MenuItem, 'icon'>[];
    name: string;
    path?: string;
    show?: string;
    target?: string;
  }

  @Component({ components: { PerfectScrollbar } })
  export default class SideMenu extends Vue {
    @Ref() readonly dropdowns?: HTMLElement[];

    activeModules: Pick<ModuleName, 'id' | 'name' | 'plural_display' | 'menu_collection_id' | 'module_type' | 'config'>[] = [];
    hover: Dictionary<{ top: number }> = {};
    hoverTimeout: Nullable<NodeJS.Timeout> = null;
    intersectionObserver?: IntersectionObserver;
    menuCollectionSubscription: Nullable<Subscription> = null;
    menuCollections: Pick<MenuCollection, 'id' | 'name' | 'icon'>[] = [];
    roleDashboards: Pick<Dashboard, 'id' | 'name'>[] = [];
    settings = { suppressScrollX: true, wheelPropagation: false };
    visibleDropdowns: Dictionary<boolean> = {};

    get accountStore() {
      return useAccountStore();
    }

    get appMenuStructure(): MenuItem[] {
      return [
        {
          icon: 'dashboard',
          name: this.$t('app.labels.dashboard'),
          path: '/',
          items: this.dashboardItems,
        },
        ...(this.moduleAcl.Activity?.view ? [{ icon: 'done', path: '/actions', name: this.$t('app.labels.actions') }] : []),
        ...(this.menuCollections
          .map((item) => {
            const items = this.activeModules
              .filter(
                (module) =>
                  module.menu_collection_id === item.id &&
                  this.moduleAccessible(module.name) &&
                  !this.moduleAcl[module.name]?.hide_on_main_menu
              )
              .map((module) => ({ name: module.plural_display, path: this.modulePath(module) }));
            if (items.length) {
              return {
                icon: item.icon,
                name: item.name,
                items,
              };
            }
          })
          .filter(Boolean) as MenuItem[]),
        ...(this.helpUrlPath
          ? [{ icon: 'help', target: '_blank', show: 'mobile', path: this.helpUrlPath, name: this.$t('app.labels.help') }]
          : []),
        ...(this.intercomEnabled
          ? [
              {
                icon: 'chat',
                id: 'intercom',
                click: () => typeof Intercom !== 'undefined' && Intercom('show'),
                path: '#',
                name: this.$t('app.labels.support'),
              },
            ]
          : []),
      ];
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get dashboardItems() {
      const personalDashboard = this.currentUserStore.data?.has_active_panes
        ? [{ name: this.$t('app.labels.personal_dashboard'), path: '/personal_dashboard' }]
        : [];

      return [
        ...this.roleDashboards.map((item) => ({
          name:
            item.id === this.currentUserStore.data?.role?.dashboard_id && this.roleDashboards.length > 1
              ? this.$t('app.labels.name_default', { name: item.name })
              : item.name,
          path: `/dashboards/${item.id}`,
        })),
        ...personalDashboard,
      ];
    }

    get filteredMenuStructure() {
      return this.menuStructure.filter((parentItem) => {
        return parentItem.path || (parentItem.items && parentItem.items.length);
      });
    }

    get helpUrlPath(): Maybe<string> {
      if (this.accountStore.data?.help_url) return this.rubyStringReplace(this.accountStore.data.help_url, this.linkPlaceholderHash);
    }

    get intercomEnabled(): boolean {
      return !!window.DONESAFE.INTERCOM;
    }

    get linkPlaceholderHash(): AddPrefixToObject<CurrentUser, 'user_'> & AddPrefixToObject<Account, 'account_'> {
      return {
        ...(mapKeys(this.currentUserStore.data, (value, key) => `user_${key}`) as AddPrefixToObject<CurrentUser, 'user_'>),
        ...(mapKeys(this.accountStore.data, (value, key) => `account_${key}`) as AddPrefixToObject<Account, 'account_'>),
      };
    }

    get logoUrl() {
      return this.accountStore.data?.logo_url || '/logo.png';
    }

    get menuStructure() {
      return this.accountStore.data ? this.appMenuStructure : this.portalMenuStructure;
    }

    get moduleAcl(): RequiredBy<Acl, 'module'>['module'] {
      return this.currentUserStore.data?.acl?.module ?? {};
    }

    get portalMenuStructure() {
      const accounts: MenuItem = {
        icon: 'domain',
        name: this.$t('layouts.portal.side_menu.portal_menu_structure.accounts'),
        path: '/accounts',
      };
      const users: MenuItem = {
        path: '/users',
        icon: 'supervisor_account',
        name: this.$t('layouts.portal.side_menu.portal_menu_structure.users'),
      };
      const partners: MenuItem = {
        icon: 'thumb_up',
        path: '/partners',
        name: this.$t('layouts.portal.side_menu.portal_menu_structure.partners'),
      };

      const items = [accounts];
      if (this.currentUserStore.data?.admin) {
        items.push(users);
        if (!window.DONESAFE.PORTAL_NO_PARTNERS) {
          items.push(partners);
        }
      }

      return items;
    }

    hideDropdown() {
      this.hover = {};
    }

    initModuleNamesAndMenuCollections() {
      const viewableModuleNames = Object.keys(this.moduleAcl).filter((moduleName) => this.moduleAccessible(moduleName));
      viewableModuleNames.length &&
        this.$api
          .getModuleNames(
            {
              filters: {
                active: true,
                name: viewableModuleNames.join(','),
              },
              show_all: true,
              sort: 'index',
              only: ['id', 'name', 'plural_display', 'menu_collection_id', 'module_type', 'config'],
              per_page: -1,
            },
            { cache: true }
          )
          .then(({ data }) => {
            this.activeModules = data;
          });

      this.$api
        .getMenuCollections({ filters: { active: true }, only: ['id', 'name', 'icon'], sort: 'index', per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.menuCollections = data;
        });
    }

    leave(): void {
      this.hoverTimeout && clearTimeout(this.hoverTimeout);
      this.hideDropdown();
    }

    moduleAccessible(moduleName: string): boolean {
      const acl = this.moduleAcl[moduleName];
      if (!acl) return false;

      return acl.view || acl.mha;
    }

    modulePath(moduleName: Pick<ModuleName, 'id' | 'name' | 'plural_display' | 'menu_collection_id' | 'module_type' | 'config'>): string {
      if (moduleName.module_type === 'integrated' && moduleName.config['index_url']) {
        const url = this.rubyStringReplace(moduleName.config.index_url, this.linkPlaceholderHash);
        return moduleName.config.use_jwt_hsi === 'true' ? `/jwt/hsi?url_to=${url}` : url;
      } else if (!HARDCODED_MODULE_CODES.includes(moduleName.name)) {
        return `/module_records?module_name_id=${moduleName.id}`;
      } else {
        return `/${entityPathPrefix(moduleName.name)}`;
      }
    }

    onIntersectionChange(index: number, entry: IntersectionObserverEntry) {
      this.visibleDropdowns[index] = entry.isIntersecting;
    }

    onItemClick(e: Event, item: Pick<MenuItem, 'click'>): void {
      if (item.click) {
        item.click();
        e.preventDefault();
      }
    }

    onParentItemClick(e: Event, item: Pick<MenuItem, 'click' | 'path'>, index: number): void {
      if (item.click) {
        item.click();
        e.preventDefault();
      } else if (item.path && !this.visibleDropdowns[index] && !this.hover[index]) {
        e.preventDefault();
      }
    }

    over(e: Event, index: number): void {
      const currentTarget = e.currentTarget as EventTarget;
      this.hoverTimeout = setTimeout(() => {
        this.showDropdown(currentTarget, index);
      }, 1);
    }

    // alternative to ruby: 'string' % hash
    // string can be something like: "https://something/%<account_subdomain>s/test"
    rubyStringReplace(str: string, replaceObject: Record<string, unknown>): string {
      return Array.from(str.matchAll(/%<(.+?)>s/g)).reduce((acc, match) => {
        return acc.replace(match[0], `${replaceObject[match[1]] || ''}`);
      }, str);
    }

    showDropdown(currentTarget: EventTarget, index: number): void {
      const scrollTop = $(window).scrollTop() || 0;
      const windowHeight = $(window).height() || 0;
      const $target = $(currentTarget);
      const targetOffset = ($target && $target.offset()) || { top: 0 };
      const elementOffset = targetOffset.top;
      const $menu = $target && $target.find('.dropdown-menu');
      const menuHeight = ($menu && $menu.outerHeight()) || 0;
      const offset = elementOffset - scrollTop;
      const diff = windowHeight - (offset + menuHeight);
      const top = diff < 0 ? offset + diff : offset;
      this.hover = { ...this.hover, [index]: { top } };
    }

    async beforeMount() {
      this.menuCollectionSubscription = UI_OBSERVER.menuCollectionOrderUpdate$.subscribe(() => {
        this.$api.cache.clear();
        this.initModuleNamesAndMenuCollections();
      });

      // do not make any calls for partner portal
      if (this.accountStore.data) {
        const { data: roleDashboards } = await this.$api.getDashboards(
          {
            filters: { active: true, hidden: false },
            only_accessible: true,
            only: ['id', 'name', 'dynamic'],
            per_page: -1,
          },
          { cache: true }
        );

        this.roleDashboards = roleDashboards.filter((d) => {
          if (!this.currentUserStore.data?.has_active_panes) return !d.dynamic;
          else return true;
        });

        this.initModuleNamesAndMenuCollections();
      }
    }

    mounted() {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;

          // IE < 11 HTMLElement does not have a `dataset` attribute
          const index = parseInt((target.dataset ? target.dataset.index : target.getAttribute('data-index')) as string, 10);
          const menuItem = this.menuStructure[index];
          if (menuItem.path) {
            this.onIntersectionChange(index, entry);
          }
        });
      });

      // Polyfill-specific property; for IE
      (this.intersectionObserver as IntersectionObserverPolyfill).POLL_INTERVAL = 100;

      (this.dropdowns ?? []).forEach((ref) => this.intersectionObserver?.observe(ref));
    }

    beforeDestroy() {
      this.intersectionObserver?.disconnect();
      this.menuCollectionSubscription?.unsubscribe();
    }
  }

  declare let Intercom: {
    (action: 'show' | 'showMessages'): void;
  };
