
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Select2 from '../../select2.vue';
  import type { HourType } from '@app/models/hour-type';

  @Component({ components: { Select2, ValidationObserver, ValidationProvider } })
  export default class HourTypeForm extends Vue {
    @Prop(Object) readonly hourType?: Partial<HourType>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    get inclusivityTypes(): {
      inclusivity_type: string;
      label: string;
    }[] {
      return [
        {
          inclusivity_type: 'include_hours',
          label: this.$t('app.labels.include_hours'),
        },
        { inclusivity_type: 'add', label: this.$t('app.labels.add') },
        { inclusivity_type: 'subtract', label: this.$t('app.labels.subtract') },
      ];
    }

    form: Partial<HourType> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    beforeMount(): void {
      this.form = { ...this.hourType };
    }
  }
