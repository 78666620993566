
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import formatPriorityOption from '@app/components/activities/format-priority-option';
  import type { ActionPriority } from '@app/models/action-priority';
  import type { CallToAction } from '@app/models/event';
  import type { Dictionary } from '@app/models/dictionary';
  import { isUserCollection } from '@app/utils/is-user-collection';

  import EventCallToActionPanel from '../event-call-to-action-panel.vue';
  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface ActionFormData {
    action_priority_code?: string | null;
    actionable_id?: string;
    assigned_approver_id?: string;
    assignee_id?: string;
    authentication_type?: string;
    call_to_action?: CallToAction;
    comment?: string;
    completed_by_id?: string;
    date: {
      days?: number;
      related_date?: string;
    };
    description?: string;
    require_completion_comment?: string;
    requires_approval?: string;
    sub_form_completion_id?: string;
    user_id?: string;
  }

  const DEFAULT = '!DEFAULT';

  @Component({ components: { Select2, EntitySelector, AdvancedAutosuggest, EventCallToActionPanel, EventInputMethodSwitch, FormField } })
  export default class ActionEventForm extends BaseEventForm<ActionFormData> {
    @Prop(Array) readonly dateOptions?: [string, string][];
    @Prop(Object) readonly injectableArguments!: Dictionary<string>;
    @Prop(Boolean) readonly noRelatedObjects?: boolean;

    priorities: ActionPriority[] = [];

    get requiresApproval(): boolean {
      return this.form.requires_approval === 'true';
    }

    get hasDefaultPriority(): boolean {
      return this.form.action_priority_code === DEFAULT;
    }

    get actionApprovalsEnabled(): boolean {
      return !!this.accountStore.data.enable_action_approvals;
    }

    get stateOptions(): [string, string][] {
      return [
        ['open', this.$t('app.labels.open')],
        ['closed', this.$t('app.labels.closed')],
      ];
    }

    get booleanOptions(): [string, string][] {
      return [
        ['true', this.$t('app.labels.yes')],
        ['false', this.$t('app.labels.no')],
      ];
    }

    get showRelatedSubFormCompletion(): boolean {
      return (
        this.accountStore.data.action_options?.link_to_sub_form_completion === 'true' &&
        this.form.actionable_id !== '^home_location_id' &&
        (this.form.actionable_id === '~#records_id' || !this.isUserCollection) &&
        !!this.injectableArguments?.sub_form_id
      );
    }

    get linkToSubFormCompletionOptions(): [string, string][] {
      // do not show for scheduler (sub_form will be nil for scheduler) and do not show for sub form automations
      if (this.noRelatedObjects || !this.injectableArguments?.sub_form_id) {
        return [];
      }

      return [['~id', this.$t('app.labels.actioned_record') as string]];
    }

    get isUserCollection(): boolean {
      return isUserCollection(this.per);
    }

    @Watch('showRelatedSubFormCompletion')
    clearLinkToSubFormCompletion(): void {
      if (this.showRelatedSubFormCompletion) return;

      this.form.sub_form_completion_id = '';
    }

    formatPriorityOption(option?: ActionPriority): string {
      return formatPriorityOption({ $t: this.$t })(option) as string;
    }

    beforeMount(): void {
      this.form = { call_to_action: {}, date: {}, action_priority_code: DEFAULT, ...this.values };
    }

    onDefaultPriorityChange(ev: InputEvent) {
      const checked = (ev.target as HTMLInputElement).checked;
      this.form.action_priority_code = checked ? DEFAULT : null;
    }
  }
