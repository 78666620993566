
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import SearchInput from '@app/components/search-input.vue';
  import type { LocationTag } from '@app/models/location-tag';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({ components: { PaperTrailsModalLink, DsDropdown, BaseTable, SearchInput } })
  export default class AdminSettingsLocationTagsIndex extends Vue {
    @Ref() readonly table?: BaseTable<LocationTag>;

    manager = new ListManager<LocationTag>({
      fetchDataFunction: (params) => this.$api.getLocationTags({ ...params, filters: { ...params.filters } }, { cache: true }),
      useHistory: true,
      per_page: 25,
      sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
      fields: [
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
        { title: this.$t('tenant.settings.locations_tags.form.tag_category'), name: 'tag_type', sortField: 'tag_type' },
        { title: this.$t('tenant.settings.locations_tags.form.allow_global'), name: 'allow_global', sortField: 'allow_global' },
        { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true },
        { title: '', name: 'operations' },
      ],
    });

    @Watch('$route.params.reloadTable')
    reloadData(value?: ModalCloseCommand): void {
      if (value === 'reload') {
        this.$api.cache.clear();
        this.table?.reload();
      }
    }
  }
