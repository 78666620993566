
  import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
  import MenuItem from './menu-item.vue';
  import type { RuleBuilderModes } from '../models/types';

  @Component({ components: { MenuItem } })
  export default class BuilderMenu extends Vue {
    @Prop() readonly logicSetSelected?: boolean;
    @Prop({ required: true }) readonly mode!: RuleBuilderModes;
    @PropSync('textEditMode') syncedTextEditMode!: boolean;

    @Emit('select-logic-set')
    selectLogicSet(skipWarningMessage: boolean): boolean {
      return skipWarningMessage;
    }

    @Emit('switch-page')
    switchPage(mode: RuleBuilderModes): RuleBuilderModes {
      return mode;
    }

    toggleTextEditMode(): void {
      this.syncedTextEditMode = !this.syncedTextEditMode;
    }
  }
