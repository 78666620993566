
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import WithImportModal from '@app/mixins/with-import-modal';
  import WithAuditTrailModal from '@app/mixins/with-audit-trail-modal';
  import type { SubForm } from '@app/models/sub-form';
  import { downloadFile } from '@app/utils/download-file';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import PaperTrailsModal from '../../paper-trails/paper-trails-modal.vue';

  import AdminSubFormActions from './admin-sub-form-actions.vue';
  import AdminSubFormImportModal from './admin-sub-form-import-modal.vue';

  @Component({ components: { AdminSubFormImportModal, AdminSubFormActions, PaperTrailsModal } })
  export default class AdminMainFormControlPanel extends mixins(WithAuditTrailModal, WithImportModal) {
    @Prop(Number) readonly subFormId!: number;
    @Prop(Number) readonly moduleNameId?: number;

    subForm: Nullable<SubForm> = null;

    beforeMount(): void {
      this.$api.getSubForm(this.subFormId, { only: ['id', 'title', 'active', 'module_name'] }, { cache: true }).then(({ data }) => {
        this.subForm = data;
      });
    }

    actionPath(action: string): string {
      return `/admin/settings/sub_forms/${this.subFormId}/${action}`;
    }

    onModalVisibilityUpdated(open: true): void {
      if (open) return;

      this.importCreated && changeLocation(`/admin/settings/module_names/${this.moduleNameId}?tab=sub_form`);
    }

    openExport(): void {
      this.$api
        .exportSubForm(this.subFormId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const fileName = response.headers['content-disposition'].split('=')[1];
          downloadFile(url, fileName);
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }
  }
