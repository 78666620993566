
  import { Component, Prop, Vue, Model } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import type { ValueCopy } from './models';

  @Component({ components: { DsDropdown } })
  export default class PredefinedValueCopyInput extends Vue {
    @Model('input') readonly value?: string;
    @Prop(Array) readonly copyValues!: ValueCopy[];
  }
