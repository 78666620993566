
  import DsTextInput from '@app/components/ds-text-input.vue';
  import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
  import RecurringSchedulerForm from '@app/components/recurring-setup/recurring-scheduler-form.vue';
  import { ValidationObserver } from 'vee-validate';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import type { Event } from '@app/models/event';
  import type { Schedule } from '@app/models/schedule';
  import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
  import { ScheduledEventStatus } from '@app/models/scheduled-event';
  import type { PartialNullBy } from '@app/utils/types/partial';
  import { titleize } from '@app/utils/titleize';

  import ScheduledEventActions from '../events/scheduled-event-actions.vue';

  import type { ScheduledEventOnly } from './utils';
  import SchedulerCreateObjectEventForm from './scheduler-create-object-event-form.vue';

  @Component({
    components: { DsTextInput, ScheduledEventActions, RecurringSchedulerForm, SchedulerCreateObjectEventForm, ValidationObserver },
  })
  export default class ScheduledEventsForm extends Vue {
    @Ref() readonly createObjectEventForm!: SchedulerCreateObjectEventForm;
    @Ref() readonly formElement!: HTMLElement;
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;

    @Prop(Object) readonly scheduledEvent?: Pick<ScheduledEvent, ScheduledEventOnly>;
    @PropSync('event') syncedEvent!: Event;
    @Prop() readonly submitting!: boolean;

    titleize = titleize;
    form: PartialNullBy<Pick<ScheduledEvent, ScheduledEventOnly>, 'id'> = {
      id: null,
      name: '',
      status: ScheduledEventStatus.unpublished,
      active: true,
    };

    createObjectLoading = true;

    get statusClass() {
      const statuses: Record<ScheduledEventStatus, string> = {
        active: 'label-success',
        completed: 'label-success',
        pending: 'label-info',
        unpublished: 'label-warning',
      };
      return !!this.form?.status ? statuses[this.form.status] : 'label-default';
    }

    setFormActive(value: boolean) {
      this.form = { ...this.form, active: value };
    }

    beforeMount(): void {
      this.form = {
        ...this.form,
        ...(this.scheduledEvent || {}),
      };
    }

    inputScheduleForm(schedule: Schedule): void {
      this.form = {
        ...this.form,
        schedule,
      };
    }

    inputSetupForm(recurring_setup: RecurringSetup): void {
      this.form = {
        ...this.form,
        recurring_setup,
      };
    }
  }
