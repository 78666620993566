
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Switcher from '../../switcher.vue';
  import type { IndicatorSet } from '@app/models/indicator-set';

  @Component({
    components: { ValidationObserver, ValidationProvider, Switcher },
  })
  export default class IndicatorSetForm extends Vue {
    @Prop(Object) readonly indicatorSet?: Partial<IndicatorSet>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<IndicatorSet> = {};

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    beforeMount(): void {
      this.form = { ...this.indicatorSet };
    }
  }
