
  import Select2 from '@app/components/select2.vue';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import ExportFormModal from './export-form-modal.vue';

  @Component({ components: { Select2, ExportFormModal } })
  export default class HybridControlPanel extends Vue {
    @Prop(Number) readonly moduleNameId!: number;
    @Prop(String) readonly title!: string;
    @Prop(String) readonly addTitle!: string;

    openExportModal = false;

    get importPath(): string {
      return `/admin/settings/super_imports/new?module_name_id=${this.moduleNameId}`;
    }
  }
