
  import DsTextInput from '@app/components/ds-text-input.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import SubFormListSettingsBase from '@app/components/sub-form-list-types/sub-form-list-settings-base';

  import { startCase } from 'lodash';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Draggable from 'vuedraggable';

  import { Component } from 'vue-property-decorator';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormListResponseCounterOptionsRow } from '@app/models/sub-form-list';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType, SCORABLE_TYPES } from '@app/models/sub-form-question';
  import { SubFormListType } from '@app/models/sub-form-list';

  @Component({ components: { DsTextInput, Select2, EntitySelector, Draggable, ValidationProvider, ValidationObserver } })
  export default class ResponseCounterTabSettings extends SubFormListSettingsBase {
    moduleSubForms: Pick<SubForm, 'id' | 'title'>[] = [];
    recordRelationQuestions: SubFormQuestion[] = [];

    removeResponseConfig(index: number): void {
      const configs = this.responseConfigs;
      this.responseConfigs = configs.slice(0, index).concat(configs.slice(index + 1));
    }

    addResponseConfig(): void {
      this.responseConfigs = [...this.responseConfigs, { title: '', value: '', response_types: [] }];
    }

    get subFormOptions(): [number, string][] {
      return [
        ...this.moduleSubForms.map((f) => [f.id, f.title] as [number, string]),
        ...this.recordRelationQuestions
          .map((q) => q.sub_form_section?.sub_form as SubForm)
          .filter(Boolean)
          .map((f) => [f.id, `Main Form: ${f.title}`] as [number, string]),
      ];
    }

    get responseConfigs(): SubFormListResponseCounterOptionsRow[] {
      return Object.values(this.subFormListSync.response_counter_options?.responses || {});
    }

    set responseConfigs(array: SubFormListResponseCounterOptionsRow[]) {
      const responses = array.reduce((acc, row, index) => {
        return { ...acc, [`${index + 1}`]: row };
      }, {} as Record<string, SubFormListResponseCounterOptionsRow>);
      this.subFormListSync.response_counter_options && this.$set(this.subFormListSync.response_counter_options, 'responses', responses);
    }

    get responseTypeOptions(): [string, string][] {
      const scoredTypes = SCORABLE_TYPES.map((t) => [`${t}|scored`, `Scored ${startCase(t)}`] as [string, string]);
      return Object.keys(FieldType)
        .map((t) => [t, startCase(t)] as [string, string])
        .concat(scoredTypes);
    }

    beforeMount(): void {
      const defaults = { sub_form_ids: [], responses: {} };
      this.$set(this.subFormListSync, 'response_counter_options', { ...defaults, ...this.subFormListSync.response_counter_options });
      !this.responseConfigs.length && this.addResponseConfig();

      this.$api
        .getSubForms({ filters: { module_name: this.moduleName?.name }, only: ['id', 'title'], per_page: -1 }, { cache: true })
        .then(({ data }) => {
          this.moduleSubForms = data;
        });

      this.$api
        .getSubFormLists(
          {
            filters: {
              module_tab: { module_name: this.moduleName?.name },
              sub_form_list_type: SubFormListType.record_relations,
            },
            per_page: 1,
          },
          { cache: true }
        )
        .then(({ data }) => {
          const questionIds = data.map((f) => f.record_relation_question_id);
          questionIds.length &&
            this.$api
              .getSubFormQuestions(
                {
                  filters: { id: questionIds, active: true },
                  only: ['id', { sub_form_section: [{ sub_form: ['id', 'title'] }] }],
                  per_page: -1,
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.recordRelationQuestions = data;
              });
        });
    }
  }
