
  import WorkflowForm from '@app/components/admin/workflows/workflow-form.vue';
  import DsModal from '@app/components/ds-modal.vue';
  import Blocking from '@app/mixins/blocking';
  import type { Workflow } from '@app/models/workflow';
  import { toaster } from '@app/utils/toaster';
  import bootbox from 'bootbox';
  import { Component, Mixins, Model, Prop, Ref } from 'vue-property-decorator';

  @Component({ components: { DsModal, WorkflowForm } })
  export default class WorkflowFormModal extends Mixins(Blocking) {
    @Model('input', { type: Boolean }) readonly value!: boolean;
    @Prop(Object) readonly workflow?: Partial<Workflow>;
    @Prop(Number) readonly startingStateId?: number;

    @Ref() readonly form?: InstanceType<typeof WorkflowForm>;

    get localWorkflow() {
      return (this.value && this.workflow) || null;
    }

    get title() {
      return this.workflow?.id ? 'Edit workflow' : 'Add new workflow';
    }

    get isLoading() {
      return !!this.form?.isLoading;
    }

    get createOrUpdateText(): string {
      return this.isNewRecord ? this.$t('app.buttons.create') : this.$t('app.buttons.update');
    }

    get archiveOrRestoreText(): string {
      return this.isActive ? this.$t('app.buttons.archive') : this.$t('app.buttons.restore');
    }

    get isNewRecord(): boolean {
      return !this.workflow?.id;
    }

    get isActive(): boolean {
      return !!this.workflow?.active;
    }

    get isStartingState(): boolean {
      return this.workflow?.id === this.startingStateId;
    }

    createOrUpdateWorkflow(workflow: Partial<Workflow>): void {
      const [promise, message] = workflow?.id
        ? [this.$api.updateWorkflow(Number(workflow.id), workflow), this.$t('tenant.admin.workflows.updated')]
        : [this.$api.createWorkflow(workflow), this.$t('tenant.admin.workflows.created')];

      this.blocking(async () =>
        promise.then(() => this.toasterAndRedirect(message)).catch(({ data }) => toaster({ text: data?.error, icon: 'error' }))
      );
    }
    archiveOrRestoreWorkflow(): void {
      if (this.workflow?.id) {
        const [promise, message] = this.workflow?.active
          ? [this.$api.updateWorkflow(Number(this.workflow.id), { active: false }), this.$t('tenant.admin.workflows.archived')]
          : [this.$api.updateWorkflow(Number(this.workflow.id), { active: true }), this.$t('tenant.admin.workflows.restored')];

        this.blocking(async () =>
          promise
            .then(() => this.toasterAndRedirect(message))
            .catch(({ data }) => {
              toaster({ text: data?.error, icon: 'error' });
            })
        );
      }
    }

    toasterAndRedirect(message: string): void {
      toaster(message);
      this.redirect();
    }

    redirect(): void {
      window.location.reload();
    }

    confirmArchiveOrRestore(): void {
      bootbox.confirm({
        backdrop: false,
        message: this.$t('app.labels.are_you_sure'),
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          result && this.archiveOrRestoreWorkflow();
        },
      });
    }
  }
