export const mapOldIndexFilterKeys = (filter: Record<string, string | string[]>): Record<string, string | string[]> => {
  const oldKeyMap: Record<string, string> = {
    state: 'workflow_id',
    organization: 'organization_id',
    location: 'location_id',
    created_by: 'user_id',
    parent: 'parent_id',
  };

  return Object.keys(filter).reduce((memo, key) => {
    if (oldKeyMap[key]) {
      memo[oldKeyMap[key]] = filter[key];
    } else {
      memo[key] = filter[key];
    }
    return memo;
  }, {} as Record<string, string | string[]>);
};
