
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { Involvement } from '@app/models/involvement';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  @Component({ components: {} })
  export default class RelatedInvolvement extends Vue {
    @Prop() readonly question!: SubFormQuestion;

    involvement: Partial<Pick<Involvement, 'id' | 'name'>> = { name: '' };

    beforeMount() {
      this.question.config.involvement_id &&
        this.$api
          .getInvolvement(this.question.config.involvement_id, { only: ['id', 'name'] }, { join: true, cache: true })
          .then(({ data }) => {
            this.involvement = data;
          });
    }
  }
