
  import { useAccountStore } from '@app/stores/account';
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Select2 from '@app/components/select2.vue';
  import type { ActionWidgetOptions } from '@app/models/widget';
  import { ActionSortOptions } from '@app/models/widget';

  import ActionFilterConfigurator from '../../questions/filter-configurator/action-filter-configurator.vue';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({ components: { ActionFilterConfigurator, Select2, ValidationObserver, ValidationProvider } })
  export default class ActionsWidgetSettings extends BaseWidgetSettings<ActionWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<ActionWidgetOptions>;

    get availableSortOptions(): [string, string][] {
      return [
        [ActionSortOptions.date, 'Date'],
        [ActionSortOptions.id, 'ID'],
        [ActionSortOptions.description, 'Description'],
        [ActionSortOptions.closedAt, 'Closed At'],
        [ActionSortOptions.assignedTo, 'Assignee'],
        [ActionSortOptions.createdAt, 'Created At'],
        ...(this.showPriorities ? [[ActionSortOptions.priority, 'Priority'] as [string, string]] : []),
      ];
    }

    get accountStore() {
      return useAccountStore();
    }

    get showPriorities(): boolean {
      return this.accountStore.data.action_options.show_action_priorities === 'true';
    }

    async beforeMount(): Promise<void> {
      this.form = { reverse: 'true', sort: ActionSortOptions.date, ...this.widget.options };
    }
  }
