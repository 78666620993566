
  import type { GeneratedFilterOption } from '@app/components/admin/questions/filter-configurator/utils';
  import { generateQuestionFilters } from '@app/components/admin/questions/filter-configurator/utils';
  import { RELATIONSHIP_PREFIX } from '@app/constants';
  import type { Relationship } from '@app/models/relationship';
  import { isReadonlyLookup } from '@app/services/model-helpers';
  import { flatten } from 'lodash';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { Indicator } from '@app/models/indicator';
  import type { Workflow } from '@app/models/workflow';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { BaseQuestionOptions } from '@app/models/question-options/base-question-options';
  import { inactiveTitleTemplate } from '@app/utils/inactive-title-template';

  import type { CustomEntityFilterOption, FilterOption } from './model';
  import FilterConfigurator from './filter-configurator.vue';

  type AnyFilterOption =
    | FilterOption
    | FilterOption<Workflow>
    | FilterOption<ModuleRecord>
    | FilterOption<Indicator>
    | FilterOption<TenantUser>
    | GeneratedFilterOption;

  @Component({ components: { FilterConfigurator } })
  export default class RecordFilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(Object) readonly currentQuestion?: SubFormQuestion;
    @Prop(Object) readonly moduleName?: ModuleName;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(Array) readonly onlyFilterTypes?: string[];
    @Prop(String) readonly name!: string;
    @Prop(String) readonly label?: string;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noCurrentUser?: boolean;
    @Prop(Boolean) readonly noReverse?: boolean;
    @Prop({ type: Function, default: () => true }) readonly filterOptions!: (_: AnyFilterOption) => boolean;

    get scopeOptions(): { key: string; value: string }[] {
      return [
        { key: 'all', value: 'All Accessible' },
        { key: 'mine', value: "User's records" },
        { key: 'team', value: "User Team's records" },
        { key: 'wider_team', value: "User Extended Team's records" },
      ];
    }

    get configOptions(): AnyFilterOption[] {
      let defaultOptions: (
        | FilterOption
        | FilterOption<Workflow>
        | FilterOption<ModuleRecord>
        | FilterOption<Indicator>
        | FilterOption<TenantUser>
      )[] = [
        {
          type: 'datetime',
          label: 'Created at',
          key: 'created_at',
          options: [
            { key: 'all', value: 'Anytime' },
            { key: 'week', value: 'This Week' },
            { key: 'today', value: 'Today' },
            { key: 'month', value: 'This Month' },
            { key: 'year', value: 'Within the past year' },
            { key: 'calendar_year', value: 'Within this calendar year' },
          ],
          invertDisabled: true,
        },
        {
          type: 'select',
          label: 'Visibility',
          key: 'scope',
          options: this.scopeOptions,
        },
        {
          type: 'location',
          label: 'Location',
          multiple: true,
          key: 'location_id',
        },
        {
          type: 'organization',
          label: 'Organization',
          multiple: true,
          key: 'organization_id',
        },
        {
          type: 'user',
          label: 'Creator',
          multiple: true,
          key: 'user_id',
          allowNull: true,
        },
        {
          type: 'record',
          label: 'ID',
          multiple: true,
          key: 'id',
          filters: {
            module_name_id: this.moduleName?.id,
          },
        },
        {
          type: 'select' as const,
          label: 'Confidential',
          key: 'confidential',
          options: [
            { key: 'true', value: 'Yes' },
            { key: 'false', value: 'No' },
          ],
        },
      ];

      if (!this.moduleName) {
        return defaultOptions;
      }

      const workflowOptions = [
        {
          type: 'entity',
          method: this.$api.getWorkflows,
          label: 'Workflow',
          only: ['id', 'name', 'active'],
          labelKey: 'name',
          valueKey: 'id',
          multiple: true,
          key: 'workflow_id',
          filters: { module_name_id: this.moduleName?.id, active: true },
          templateResult: (w: Pick<Workflow, 'id' | 'name' | 'active'>) => inactiveTitleTemplate(w),
          templateSelection: (w: Pick<Workflow, 'id' | 'name' | 'active'>) => inactiveTitleTemplate(w),
        },
      ] as FilterOption<Workflow>[];

      const mainFormQuestions = (
        flatten<SubFormQuestion<BaseQuestionOptions>>(
          this.moduleName?.main_form?.sub_form_sections?.map((s) => s.sub_form_questions || [])
        ) || []
      )?.filter((q) => !isReadonlyLookup(q));

      const moduleIndicatorOptions =
        this.moduleName?.indicator_sets?.map<FilterOption<Indicator>>((indicatorSet) => {
          return {
            type: 'entity',
            labelKey: 'name',
            valueKey: 'id',
            allowNull: true,
            only: ['id', 'name'],
            method: this.$api.getIndicators,
            multiple: true,
            label: indicatorSet.name,
            key: `indicator_${indicatorSet.id}`,
            sort: 'index',
            filters: {
              indicator_set_id: indicatorSet.id,
            },
          };
        }) || [];

      const relationshipFilters = [
        ...this.buildRelationshipFilters(this.moduleName?.to_relationships || [], 'to'),
        ...this.buildRelationshipFilters(this.moduleName?.from_relationships || [], 'from'),
      ];

      const moduleInvolvementOptions =
        this.moduleName?.involvements?.map<FilterOption<TenantUser>>((involvement) => {
          return {
            type: 'user',
            multiple: true,
            allowNull: true,
            label: involvement.name,
            key: `involvement_${involvement.id}`,
            filters: { type: 'TenantUser', user_involvements: { involvement_id: involvement.id } },
          };
        }) || [];

      const moduleQuestionOptions = generateQuestionFilters(mainFormQuestions, this.$api, this.currentQuestion);

      return [
        ...defaultOptions,
        ...workflowOptions,
        ...moduleQuestionOptions,
        ...moduleIndicatorOptions,
        ...moduleInvolvementOptions,
        ...relationshipFilters,
      ].filter(this.filterOptions);
    }

    buildRelationshipFilters(relationships: Relationship[], direction: 'to' | 'from'): CustomEntityFilterOption<ModuleRecord>[] {
      const field: keyof Relationship = direction === 'to' ? `from_module` : 'to_module';
      return relationships.map((relationship) => ({
        type: 'record',
        label: `Relationship ${relationship.name} [${relationship.code}]`,
        multiple: true,
        key: `${RELATIONSHIP_PREFIX}${direction}:${relationship.code}`,
        filters: { module_name: { name: relationship[field] } },
      }));
    }

    deriveOnlyFilters(): Record<string, string> {
      if (!this.onlyFilterTypes?.length) return {};

      const regex = new RegExp(this.onlyFilterTypes.join('|'), 'i');

      return this.configOptions.reduce((memo, option) => {
        if (regex.test(option.key)) {
          return { ...memo, [option.key]: option.label };
        }

        return memo;
      }, {});
    }
  }
