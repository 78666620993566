
  import { API_NULL, MAIN_FORM_MODULE_NAME } from '@app/constants';
  import { flatten, groupBy, isEmpty, keyBy, uniq } from 'lodash';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import Select2 from '../../../select2.vue';
  import type { FilterOption, SimpleOption } from './model';
  import FilterConfiguratorItem from './filter-configurator-item/filter-configurator-item.vue';
  import { Tooltip } from 'uiv';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormSection } from '@app/models/sub-form-section';
  import type { Dictionary } from '@app/models/dictionary';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { BaseEntity } from '@app/models/base-entity';

  interface QuestionDynamicOption extends SimpleOption {
    code?: string;
    fieldType?: FieldType;
  }

  interface OnlyFilters {
    [key: string]: string;
  }

  @Component({ components: { FilterConfiguratorItem, Select2, Tooltip } })
  export default class FilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Object) readonly subForm?: SubForm;
    @Prop(String) readonly name!: string;
    @Prop(String) readonly label?: string;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(Object) readonly onlyFilters?: OnlyFilters;
    @Prop(Boolean) readonly allowRecordDependsOnFilters?: boolean; // TODO: make use of this
    @Prop(Boolean) readonly allowUserDependsOnFilters?: boolean; // TODO: make use of this
    @Prop(Boolean) readonly hasStickyFilters?: boolean;
    @Prop(Array) readonly configOptions!: FilterOption[];
    @Prop(Object) readonly currentQuestion?: SubFormQuestion;
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noCurrentUser?: boolean;
    @Prop(Boolean) readonly isMobileApp?: boolean;
    @Prop(Boolean) readonly noReverse?: boolean;

    formQuestions: QuestionDynamicOption[] = [];

    get itemName(): string | undefined {
      if (this.name) {
        return `${this.name}[]`;
      }
    }

    get isPickerEmpty(): boolean {
      return !this.value || !this.value.length;
    }

    get visibleOptions() {
      return this.selectedOptions.filter(({ type }) => type !== 'system');
    }

    get selectedOptions(): FilterOption[] {
      const value = this.value || [];
      const options = value.map((item, index) => {
        const option = this.configOptions.find((option) => option.key === item.key);

        const sameKeyIndex = value.findIndex((valueItem) => valueItem.key === item.key);
        const sameKeyExists = sameKeyIndex !== index && sameKeyIndex != -1;
        const sameKeyObject = value.find((valueItem) => valueItem.key === item.key);

        // allow to remove second sticky filter by setting it's sticky value to false
        const sticky = sameKeyExists ? false : option?.sticky || false;

        // flip the invert value for the second filter with the same key
        const invert = sameKeyExists && sameKeyObject ? !sameKeyObject.invert : item.invert;

        const itemValue = Array.isArray(item.value) ? (item.value as string[]).filter((v) => v !== API_NULL) : item.value;
        const isNull = Array.isArray(item.value) && (item.value as string[]).includes(API_NULL);
        const optionBase = {
          key: item.key,
          ...option,
          null: isNull,
          value: itemValue,
          source: item.source,
          invert,
          sticky,
        };

        // hack to support widget filters
        if (item.key === 'module_name_id') {
          return {
            ...optionBase,
            type: 'system',
            label: 'Module Name',
          } as FilterOption;
        }
        return {
          ...optionBase,
          invalid: this.isInvalidOption(option),
          required: item.required,
        } as FilterOption;
      });
      return this.fillOptionsWithStatics(options); // fill dynamicOptions on load
    }

    get skipFiltersHash(): Dictionary<string> {
      return keyBy(this.skipFilters || {});
    }

    get availableConfigOptions(): FilterOption[] {
      const selectedHash = keyBy(this.selectedOptions, (option) => option.key);
      return this.fillOptionsWithStatics(
        this.configOptions.filter((option) => {
          if (!this.onlyFilterKeys.length) return this.validConfigOption(option, selectedHash);

          if (this.allowedOptionKey(option)) {
            return this.validConfigOption(option, selectedHash);
          }
        })
      );
    }

    get onlyFilterKeys(): string[] {
      if (!this.onlyFilters) return [];

      return Object.keys(this.onlyFilters);
    }

    get formQuestionsByType(): Record<string, QuestionDynamicOption[]> {
      return groupBy(this.formQuestions, 'fieldType');
    }

    get formQuestionsByCode(): Record<string, QuestionDynamicOption[]> {
      return groupBy(this.formQuestions, 'code');
    }

    get onMainForm(): boolean {
      return MAIN_FORM_MODULE_NAME === this.subForm?.module_name;
    }

    get userOptions(): SimpleOption[] {
      return [
        {
          id: 'id',
          title: 'ID',
          type: 'user',
        },
        {
          id: 'manager_id',
          title: 'Manager ID',
          type: 'user',
        },
        {
          id: 'home_location_id',
          title: 'Home Location ID',
          type: 'location',
        },
        {
          id: 'location_ids',
          title: 'Active Location IDs',
          type: 'location',
        },
        {
          id: 'home_organization_id',
          title: 'Home Organization ID',
          type: 'organization',
        },
        {
          id: 'organization_ids',
          title: 'Active Organization IDs',
          type: 'organization',
        },
      ];
    }

    get completionOptions(): SimpleOption[] {
      return [
        {
          id: 'id',
          title: 'ID',
          type: 'completion',
        },
        {
          id: 'user_id',
          title: 'Creator ID',
          type: 'user',
        },
        {
          id: 'approval_for_sub_form_completion_id',
          title: 'Base Completion ID',
          type: 'completion',
        },
      ];
    }

    get recordOptions(): SimpleOption[] {
      return [
        {
          id: 'id',
          title: 'ID',
          type: 'record',
        },
        {
          id: 'user_id',
          title: 'Creator ID',
          type: 'user',
        },
        {
          id: 'location_id',
          title: 'Location ID',
          type: 'location',
        },
        {
          id: 'organization_id',
          title: 'Organization ID',
          type: 'organization',
        },
        {
          id: 'workflow_id',
          title: 'Workflow ID',
          type: 'entity',
        },
      ];
    }

    get valueColumnClass(): string {
      let columnSize = 3;
      if (this.noRequiredFilters) {
        columnSize += 1;
      }
      if (this.readonly) {
        columnSize += 1;
      }
      if (this.noReverse) {
        columnSize += 2;
      }
      return `col-sm-${columnSize}`;
    }

    @Emit('input')
    onOptionChange(option: FilterOption, indexToUpdate: number): ConfiguratorFilter[] {
      return this.selectedOptions.map((opt, index) => {
        const finalOption = indexToUpdate === index ? option : opt;
        const finalValue = Array.isArray(finalOption.value)
          ? finalOption.null
            ? [...finalOption.value, API_NULL]
            : (finalOption.value as string[]).filter((v) => v !== API_NULL)
          : finalOption.value;
        return {
          key: finalOption.key,
          value: finalValue,
          source: finalOption.source,
          required: finalOption.required,
          invert: finalOption.invert,
        } as ConfiguratorFilter;
      });
    }

    @Emit('input')
    onOptionSelect(key: string): ConfiguratorFilter[] {
      const option = this.availableConfigOptions.find((co) => co.key === key) as FilterOption;
      const emptyValue = option?.multiple ? [] : '';
      return [...(this.value || []), { key, value: emptyValue }];
    }

    @Emit('input')
    onOptionRemove(option: FilterOption, indexToRemove: number): ConfiguratorFilter[] {
      return this.value.filter((filter, index) => index != indexToRemove);
    }

    isInvalidOption(option?: FilterOption): boolean {
      return !option || (!isEmpty(this.onlyFilters) && !this.onlyFilterKeys.includes(option.key));
    }

    allowedOptionKey(option: FilterOption): boolean {
      return !!this.onlyFilterKeys?.some((str) => option.key.startsWith(str));
    }

    validConfigOption(option: FilterOption, selectedHash: Dictionary<FilterOption<BaseEntity<string | number>>>): boolean {
      return (
        !this.skipFiltersHash[option.key] &&
        (!selectedHash[option.key] ||
          (!option.invertDisabled && this.selectedOptions.filter((selectedOption) => selectedOption.key === option.key).length === 1))
      );
    }

    extendStaticOption(option: FilterOption, fieldTypes: FieldType[]): FilterOption {
      let dynamicOptions = fieldTypes.reduce((memo, fieldType) => {
        const questionOptions = (this.formQuestionsByType[fieldType] || []).filter((opt) => opt.id !== this.currentQuestion?.id);
        return [...memo, ...questionOptions];
      }, [] as QuestionDynamicOption[]);
      if (!!option.dynamicOptions?.length) {
        dynamicOptions = dynamicOptions.concat(option.dynamicOptions);
      }
      const questionsByCode = this.formQuestionsByCode;
      let dynamicCodeOptions = Object.keys(questionsByCode).reduce((memo, code) => {
        const questions = questionsByCode[code];
        if (questions && this.currentQuestion?.code !== code) {
          const questionOption = questions.find((option) => option.fieldType && fieldTypes.includes(option.fieldType));
          if (questionOption) {
            const title = uniq(questions.map((option) => option.title)).join(' | ');
            return [...memo, { ...questionOption, title }];
          }
        }
        return memo;
      }, [] as QuestionDynamicOption[]);
      if (!!option.dynamicCodeOptions?.length) {
        dynamicCodeOptions = dynamicCodeOptions.concat(option.dynamicCodeOptions);
      }
      return { ...option, dynamicOptions, dynamicCodeOptions };
    }

    fillOptionsWithStatics(options: FilterOption[]): FilterOption[] {
      let newOptions = this.fillStaticOptionsByDynamicFormOptions(options);
      newOptions = this.fillStaticOptionsByDynamicUserOptions(newOptions);
      newOptions = this.fillStaticOptionsByDynamicCompletionOptions(newOptions);

      return this.fillStaticOptionsByDynamicRecordOptions(newOptions);
    }

    invertDisabled(key: string): boolean {
      // disable invert checkbox if both filters of the same key present
      return this.value.filter((valueItem) => valueItem.key === key).length > 1;
    }

    fillStaticOptionsByDynamicUserOptions(options: FilterOption[]): FilterOption[] {
      if (this.noCurrentUser) {
        return options;
      }
      return options.map((option) => ({
        ...option,
        userOptions: this.userOptions.filter((o) => o.type === option.type),
      }));
    }

    fillStaticOptionsByDynamicRecordOptions(options: FilterOption[]): FilterOption[] {
      const skipRecordOptions = (() => {
        if (this.isMobileApp) {
          return true;
        }

        if (this.currentQuestion?.field_type === FieldType.report) {
          return false;
        }

        if (!this.subForm) {
          return true;
        }

        // skip for main form when currentQuestion is passed in
        // involvement form won't have currentQuestion passed in but still want to use record options
        if (this.onMainForm && this.currentQuestion) {
          return true;
        }
      })();

      return skipRecordOptions
        ? options
        : options.map((option) => ({
            ...option,
            recordOptions: this.recordOptions.filter((o) => o.type === option.type),
          }));
    }

    fillStaticOptionsByDynamicCompletionOptions(options: FilterOption[]): FilterOption[] {
      // skip for main form when currentQuestion is passed in
      // involvement form won't have currentQuestion passed in but still want to use completion options
      if (this.isMobileApp || !this.subForm || this.onMainForm || !this.currentQuestion) {
        return options;
      }
      return options.map((option) => ({
        ...option,
        completionOptions: this.completionOptions.filter((o) => o.type === option.type),
      }));
    }

    fillStaticOptionsByDynamicFormOptions(options: FilterOption[]): FilterOption[] {
      if (!this.formQuestions.length) {
        return options;
      }
      return options.map((option) => {
        if (option.key === this.currentQuestion?.code || option.noDynamicOptions) {
          return option;
        }
        switch (option.type) {
          case 'location':
            return this.extendStaticOption(option, [FieldType.location]);
          case 'organization':
            return this.extendStaticOption(option, [FieldType.organization]);
          case 'record':
            return this.extendStaticOption(option, [FieldType.main_form_relation, FieldType.multi_main_form_relation]);
          case 'completion':
            return this.extendStaticOption(option, [FieldType.sub_form_relation]);
          case 'user':
            return this.extendStaticOption(option, [FieldType.single_person_selector, FieldType.multi_person_selector]);
          case 'select':
          case 'text':
            return this.extendStaticOption(option, [
              FieldType.single_select,
              FieldType.multi_select,
              FieldType.radio,
              FieldType.button_select,
              FieldType.multi_checkbox,
              FieldType.text,
              FieldType.calculation_select,
              FieldType.calculation_text,
              FieldType.calculator,
            ]);
          case 'date':
          case 'datetime':
            return this.extendStaticOption(option, [FieldType.date, FieldType.datetime]);
          default:
            return option;
        }
      });
    }

    beforeMount(): void {
      if (this.subForm) {
        this.formQuestions = flatten<SubFormQuestion>(
          this.subForm.sub_form_sections?.map((s: SubFormSection) => s.sub_form_questions || [])
        ).map((q) => this.getQuestionInfo(q));
        if (!this.onMainForm) {
          this.$api
            .getModuleNames({
              filters: { name: this.subForm.module_name },
              include: ['main_form', 'sub_form_sections', 'sub_form_questions'],
              only: ['id', { main_form: ['sub_form_sections'] }],
            })
            .then(({ data }) => {
              if (data[0]) {
                this.formQuestions = this.formQuestions.concat(
                  flatten<SubFormQuestion>(
                    data[0].main_form?.sub_form_sections?.map((s: SubFormSection) => s.sub_form_questions || []) || []
                  ).map((q) => this.getQuestionInfo(q, true))
                );
              }
            });
        }
      }
    }

    getQuestionInfo(question: SubFormQuestion, fromParentForm = false): QuestionDynamicOption {
      const title = fromParentForm ? `Parent form question: ${question.title}` : `Question: ${question.title}`;
      return { title, code: question.code, id: question.id, fieldType: question.field_type };
    }
  }
