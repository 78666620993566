
  import BaseTable from '@app/components/base-table/base-table.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import { activeRecordBudgetBehaviours } from '@app/components/expensable/expensable-helpers';
  import RecordBudgetCategoryFormModal from '@app/components/expensable/record-budget-category-form-modal.vue';

  import type { ExpensableCategory } from '@app/models/expensable-category';
  import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';
  import type { ExpensableRecordBudgetBehaviour } from '@app/models/expensable-record-budget-behaviour';
  import type { ExpensableRecordBudgetCategory } from '@app/models/expensable-record-budget-category';
  import { moduleRecordPath } from '@app/services/helpers';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

  @Component({ components: { RecordBudgetCategoryFormModal, DsDropdown, BaseTable } })
  export default class RecordBudgetCategoriesIndexPage extends Vue {
    @Prop([Number, String]) readonly id!: number | string;
    // @Prop(String) readonly expensingTableId!: string;
    @Ref() readonly table?: BaseTable<ExpensableRecordBudgetCategory>;
    manager: Nullable<ListManager<ExpensableRecordBudgetCategory>> = null;
    selectedRecordBudgetCategory: Nullable<ExpensableRecordBudgetCategory> = null;

    editRecordBudgetCategory(rbc: ExpensableRecordBudgetCategory) {
      this.selectedRecordBudgetCategory = rbc;
    }

    expectedValue(behaviour: ExpensableRecordBudgetBehaviour, recordBudgetCategory: ExpensableRecordBudgetCategory) {
      return (
        recordBudgetCategory?.record_budget_category_values?.find((value) => value.expensable_record_budget_behaviour_id === behaviour.id)
          ?.expected_value || ''
      );
    }

    get recordPath() {
      return moduleRecordPath(this.id, { tab: this.$route.query.tab });
    }

    enableCategory(category: ExpensableCategory) {
      const existingRecordBudgetCategory = this.manager?.items.find(
        (recordBudgetCategory) => recordBudgetCategory.expensable_category_id === category.id
      );
      if (this.recordBudget) {
        const promise = existingRecordBudgetCategory
          ? this.$api.updateExpensableRecordBudgetCategory(existingRecordBudgetCategory.id, { active: true })
          : this.$api.createExpensableRecordBudgetCategory({
              expensable_category_id: category.id,
              expensable_record_budget_id: this.recordBudget.id,
            });

        promise
          .then(() => {
            this.$api.cache.clear();
            this.table?.reload();
          })
          .catch(({ data }) => toaster({ text: data?.error, position: 'top-right', icon: 'error' }));
      }
    }

    get showEditModal() {
      return !!this.selectedRecordBudgetCategory;
    }

    set showEditModal(value: boolean) {
      if (!value) {
        this.selectedRecordBudgetCategory = null;
      }
    }

    recordBudget: Nullable<ExpensableRecordBudget> = null;
    allCategories: ExpensableCategory[] = [];
    getManager(budget: ExpensableRecordBudget) {
      return new ListManager<ExpensableRecordBudgetCategory>({
        fetchDataFunction: (params) =>
          this.$api.getExpensableRecordBudgetCategories(
            {
              ...params,
              per_page: -1,
              filters: {
                ...params.filters,
                expensable_record_budget_id: budget.id,
              },
              include: ['record_budget_category_values', 'category'],
            },
            { cache: true }
          ),
        useHistory: true,
        sortOrder: [{ direction: 'desc', field: 'category', sortField: 'category.name' }],
        fields: [
          { title: 'Expense Category', name: 'category', sortField: 'category.name' },
          ...this.activeBehaviours.map((b) => ({ title: b.behaviour?.name, name: 'behaviour', behaviour: b })),
          { name: 'active', title: 'Active', sortField: 'active' },
          { name: 'actions', title: '' },
        ],
      });
    }

    get activeBehaviours(): ExpensableRecordBudgetBehaviour[] {
      return activeRecordBudgetBehaviours(this.recordBudget);
    }

    get missingCategories(): ExpensableCategory[] {
      return this.allCategories.filter(
        (category) => !this.manager?.items.find((recordBudgetCategory) => recordBudgetCategory.expensable_category_id === category.id)
      );
    }

    updateCategory(recordBudgetCategory: ExpensableRecordBudgetCategory) {
      this.$api.updateExpensableRecordBudgetCategory(recordBudgetCategory.id, recordBudgetCategory).then(() => {
        this.$api.cache.clear();
        this.selectedRecordBudgetCategory = null;
        this.table?.reload();
      });
    }
    beforeMount() {
      this.$api
        .getExpensableRecordBudget(
          `${this.$route.query.record_budget_id}`,
          { include: ['record_budget_behaviours', 'behaviour', 'expensing_table', 'expensing_table_behaviours'] },
          { cache: true }
        )
        .then(({ data }) => {
          this.recordBudget = data;

          this.manager = this.getManager(this.recordBudget);
          this.$api
            .getExpensableCategories(
              {
                filters: {
                  expensing_table_categories: {
                    expensing_table_id: this.recordBudget?.expensable_expensing_table_id,
                  },
                },
              },
              { cache: true }
            )
            .then(({ data }) => {
              // all categories here
              this.allCategories = data;
            });
        });
    }
  }
