
  import Draggable from 'vuedraggable';
  import DsIcon from '@app/components/ds-icon.vue';
  import { Component, Model, Prop, PropSync, Ref } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import { ValidationProvider } from 'vee-validate';
  import { v4 as generateUUID } from 'uuid';
  import Blocking from '@app/mixins/blocking';

  import type { DraggableChangeEvent } from '../sub-forms/utils';

  import EventOptionsMixin from './event-options-mixin';
  import EventGroupPane from './event-group-pane.vue';
  import type { EventWithTempId, GroupOrEvent, GroupWithTempId } from './with-automation-submit';

  @Component({
    components: {
      Draggable,
      DsIcon,
      EventGroupPane,
      ValidationProvider,
    },
  })
  export default class EventGroupsSorting extends mixins(EventOptionsMixin, Blocking) {
    @Model('input', { type: Array }) readonly items!: GroupOrEvent[];

    @Ref() readonly validator!: InstanceType<typeof ValidationProvider>;

    @Prop({ default: null }) readonly parentId?: string | number | null;
    @Prop({ type: Object, default: () => ({}) }) invalidForms!: Record<string, boolean>;
    @PropSync('selected') selectedPane!: string | null;

    get isChild(): boolean {
      return !!this.parentId;
    }

    get empty(): boolean {
      return this.items.length === 0;
    }

    customRequired() {
      return this.items.length > 0;
    }

    validateEmptiness() {
      this.validator.validate();
    }

    select(item: GroupOrEvent) {
      const key = this.isGroup(item) ? 'group' : 'event';
      this.selectedPane = `${item.id}:${key}`;
    }

    newEventStructure(): Pick<EventWithTempId, 'per' | 'id' | 'event_type' | 'options'> {
      return {
        id: generateUUID(),
        per: '',
        event_type: '',
        options: {
          values: {},
        },
      };
    }

    newGroupStructure(): Pick<GroupWithTempId, 'name' | 'items' | 'id' | 'parent_id'> {
      return {
        id: generateUUID(),
        name: '',
        parent_id: this.parentId || null,
        items: [],
      };
    }

    isGroup(item: GroupWithTempId | EventWithTempId): item is GroupWithTempId {
      return 'items' in item;
    }

    updateTree() {
      this.$emit('input', this.items);
    }

    addEvent(): void {
      const newEvent = this.newEventStructure();
      this.items.push(newEvent as EventWithTempId);
      this.updateTree();
      this.select(newEvent as EventWithTempId);
    }

    addGroup(): void {
      const newGroup = this.newGroupStructure();
      this.items.push(newGroup as GroupWithTempId);
      this.updateTree();
      this.select(newGroup as GroupWithTempId);
    }

    removeItem(index: number): void {
      this.items.splice(index, 1);
      this.updateTree();
    }

    onDragChange(event: DraggableChangeEvent<GroupWithTempId | EventWithTempId>) {
      let element: GroupWithTempId | EventWithTempId;

      if (!!event.added) {
        element = event.added.element;
        this.updateTree();
        this.select(element);
      } else if (!!event.moved) {
        element = event.moved.element;
        this.updateTree();
        this.select(element);
      } else if (!!event.removed) {
        element = event.removed?.element;
      }
    }
  }
