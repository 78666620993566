
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Draggable from 'vuedraggable';
  import { Tooltip } from 'uiv';
  import Select2 from '@app/components/select2.vue';
  import DropdownSelect from '@app/components/dropdown-select.vue';
  import { keyBy, orderBy } from 'lodash';
  import type { IndicatorSet } from '@app/models/indicator-set';
  import type { Involvement } from '@app/models/involvement';
  import type { ModuleName, ShowIdOption } from '@app/models/module-name';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { toaster } from '@app/utils/toaster';

  interface GroupedOption {
    group: string;
    label: string;
    value: string;
  }

  @Component({
    components: {
      Select2,
      Draggable,
      Tooltip,
      DropdownSelect,
    },
  })
  export default class ModuleDisplayBarConfigTable extends Vue {
    @Prop(Object) readonly moduleName!: Pick<
      ModuleName,
      'id' | 'display' | 'show_options' | 'record_calculations' | 'indicator_sets' | 'involvements'
    >;
    @Prop(Array) readonly mainFormQuestions!: SubFormQuestion[];

    form: Partial<Pick<ModuleName, 'id' | 'show_options'>> = {};
    selectedOption: Nullable<string> = null;
    isUpdating = false;

    get mainFormQuestionLabel(): string {
      return this.$t('app.labels.main_form_question');
    }

    get recordCalculationLabel(): string {
      return this.$t('app.labels.record_calculation');
    }

    get involvementLabel(): string {
      return this.$t('app.labels.involvement');
    }

    get indicatorSetLabel(): string {
      return this.$t('app.labels.indicator_set');
    }

    get questionsAsGroupedOptions(): GroupedOption[] {
      return orderBy(this.mainFormQuestions, [(q) => q.index]).map((question) => {
        return { value: `sub_form_question|${question.id}`, label: question.title, group: this.mainFormQuestionLabel };
      });
    }

    get recordCalculationsAsGroupedOptions(): GroupedOption[] {
      return orderBy(this.moduleName?.record_calculations || [], 'index').map((rc) => {
        return { value: `record_calculation|${rc.id}`, label: rc.name, group: this.recordCalculationLabel };
      });
    }

    get indicatorSets(): IndicatorSet[] {
      return orderBy(this.moduleName?.indicator_sets || [], 'index');
    }

    get indicatorSetsById(): Record<number, IndicatorSet> {
      return keyBy(this.indicatorSets, 'id');
    }

    get indicatorSetsAsGroupedOptions(): GroupedOption[] {
      return this.indicatorSets.map((set) => {
        return { value: `indicator_set|${set.id}`, label: set.name, group: this.indicatorSetLabel };
      });
    }

    get involvements(): Involvement[] {
      return this.moduleName?.involvements || [];
    }

    get involvementsById(): Record<number, Involvement> {
      return keyBy(this.involvements, 'id');
    }

    get involvementsAsGroupedOptions(): GroupedOption[] {
      return this.involvements.map((involvement) => {
        return { value: `involvement|${involvement.id}`, label: involvement.name, group: this.involvementLabel };
      });
    }

    get showIdsGroupedByType(): Record<string, ShowIdOption[]> {
      return (this.form.show_options?.record_quick_display_config || []).reduce((memo, option) => {
        const identifier = `${option.type}|${option.id}`;
        const warning = this.warningText(option);
        if (warning) {
          return { ...memo, [identifier]: { ...option, warning: warning } };
        }

        return { ...memo, [identifier]: option };
      }, {});
    }

    get allOptionsByType(): Record<string, GroupedOption> {
      return this.allOptions.reduce((memo, record) => {
        return { ...memo, [record.value]: record };
      }, {});
    }

    get allOptions(): GroupedOption[] {
      return [
        ...this.questionsAsGroupedOptions,
        ...this.recordCalculationsAsGroupedOptions,
        ...this.indicatorSetsAsGroupedOptions,
        ...this.involvementsAsGroupedOptions,
      ];
    }

    get groupedOptions(): GroupedOption[] {
      return this.allOptions.filter((record) => {
        const [type, id] = record['value'].split('|');
        return (
          !this.showIdsGroupedByType[record['value']] &&
          !(type === 'indicator_set' && this.hiddenIndicatorSet(this.indicatorSetsById[Number(id)]))
        );
      });
    }

    get fieldTypeMapping(): Record<string, string> {
      return {
        sub_form_question: this.mainFormQuestionLabel,
        record_calculation: this.recordCalculationLabel,
        indicator_set: this.indicatorSetLabel,
        involvement: this.involvementLabel,
      };
    }

    beforeMount(): void {
      this.initForm(this.moduleName);
    }

    warningText(option: ShowIdOption): string | undefined {
      switch (option.type) {
        case 'indicator_set':
          const indicatorSet = this.indicatorSetsById[option.id];
          if (this.hiddenIndicatorSet(indicatorSet)) {
            const message = [];
            if (indicatorSet?.multi) message.push(this.$t('tenant.admin.show_options.table.labels.cannot_show_multi'));
            if (!indicatorSet?.active) message.push(this.$t('tenant.admin.show_options.table.labels.archived_records_will_not_be_shown'));
            if (message.length) return message.join('; ');
          }
          break;
        case 'involvement':
          const involvement = this.involvementsById[option.id];
          if (!involvement?.active) return this.$t('tenant.admin.show_options.table.labels.archived_records_will_not_be_shown');
      }
    }

    hiddenIndicatorSet(indicatorSet: Partial<IndicatorSet>): boolean {
      return indicatorSet?.multi || !indicatorSet?.active;
    }

    getTitle(option: ShowIdOption): string | undefined {
      const record = this.allOptionsByType[`${option.type}|${option.id}`];
      if (record) return record['label'];
    }

    dragEnd(): void {
      this.updateModuleName(this.form);
    }

    initForm(moduleName: Pick<ModuleName, 'id' | 'show_options'>): void {
      this.form = {
        id: moduleName.id,
        show_options: {
          ...moduleName.show_options,
          record_quick_display_config: moduleName.show_options?.record_quick_display_config || [],
        },
      };
    }

    onChangeOption(): void {
      this.updateModuleName(this.form);
    }

    removeOption(option: ShowIdOption): void {
      this.form = {
        ...this.form,
        show_options: {
          ...this.form.show_options,
          record_quick_display_config: this.form.show_options?.record_quick_display_config?.filter((showId) => showId !== option) || [],
        },
      };
      this.updateModuleName(this.form);
    }

    addSelectedOption(): void {
      if (this.selectedOption) {
        const [type, id] = this.selectedOption.split('|');
        const optionAsHash = { type: type, id: Number(id) } as ShowIdOption;
        this.form.show_options = {
          ...this.form.show_options,
          record_quick_display_config: [...(this.form.show_options?.record_quick_display_config || []), optionAsHash],
        };
        this.selectedOption = null;
        this.updateModuleName(this.form);
      }
    }

    updateModuleName(form: Partial<Pick<ModuleName, 'id' | 'show_options'>>): void {
      this.isUpdating = true;
      this.$api
        .updateModuleName(this.moduleName.id, form, { only: ['id', 'show_options'] })
        .then((response) => {
          this.initForm(response.data);
          this.$emit('update', response.data.show_options);
        })
        .then(() => {
          toaster(this.$t('app.labels.module_updated', { module_name: this.moduleName.display }));
          this.$api.cache.clear();
        })
        .catch((response) => toaster({ text: response.data.error, position: 'top-right', icon: 'error' }))
        .finally(() => {
          this.isUpdating = false;
        });
    }
  }
