import type { Widget } from '@app/models/widget';
import { GridStack } from 'gridstack';
import type { GridItemHTMLElement, GridStackNode, GridStackOptions } from 'gridstack';
import { debounce, keyBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetShow from '@app/components/widget-show.vue';
import Chartkick from 'vue-chartkick';
import { orientationDetector } from '@app/utils/orientation-detector';

import {
  GRID_STACK_ITEM_CONTENT_SELECTOR,
  GRID_STACK_ITEM_SELECTOR,
  GRID_STACK_SUB_GRID_SELECTOR,
  ONE_COLUMN_BREAKPOINT,
  isSubGridWidget,
} from './utils';

@Component
export default class BaseDashboardGrid extends Vue {
  @Prop(Array) readonly widgets!: Widget[];
  @Prop(Boolean) readonly canEdit?: boolean;
  @Prop(Boolean) readonly isPublicDashboard?: boolean;
  @Prop(Object) readonly gridOptions?: GridStackOptions;
  @Prop(Object) readonly subGridTitlesById?: Record<string, string>;
  @Prop(Boolean) readonly removeWidgetBorder?: boolean;
  @Prop({ type: [String, Number] }) readonly dashboardPaneId?: string | number;

  windowResizeEventDebounced = debounce(this.windowResizeEvent, 50);

  grid: Nullable<GridStack> = null;
  subGridHTMLElements: GridItemHTMLElement[] = [];
  finalGridStackOptions: GridStackOptions = {};
  unsubscribeOrientation: Nullable<() => void> = null;

  get gridstackOptions(): GridStackOptions {
    return {
      margin: 10,
      cellHeight: 60,
      animate: false,
      oneColumnSize: ONE_COLUMN_BREAKPOINT,
      ...this.canEditOptions,
      ...this.gridOptions,
    };
  }

  get canEditOptions() {
    return this.canEdit ? { disableDrag: false, disableResize: false } : { disableDrag: true, disableResize: true };
  }

  get firstSubGridHTMLElement() {
    return this.subGridHTMLElements?.[0] || null;
  }

  get subGridById() {
    return keyBy((this.finalGridStackOptions?.children || []).filter(isSubGridWidget), 'id');
  }

  get hasSubGrids() {
    return !!Object.keys(this.subGridById).length;
  }

  get wigdetsById() {
    return keyBy(this.widgets, 'id');
  }

  addNoShadow(widget: Widget) {
    if (this.removeWidgetBorder) return true;
    return widget.options && 'show_border' in widget.options && widget.options?.show_border === 'false';
  }

  resizeToConfig(element: GridItemHTMLElement) {
    const id = element.getAttribute('gs-id');
    id && this.grid?.update(element, this.subGridById[id]);
  }

  resizeSubGridsToContent() {
    if (this.grid && this.subGridHTMLElements.length) {
      this.grid?.batchUpdate();

      if (window.innerWidth <= ONE_COLUMN_BREAKPOINT) {
        this.subGridHTMLElements.forEach((el) => this.grid?.resizeToContent(el));
      } else if (window.innerWidth > ONE_COLUMN_BREAKPOINT) {
        this.subGridHTMLElements.forEach((el) => this.resizeToConfig(el));
      }

      this.grid?.batchUpdate(false);
    }
  }

  renderWidgetComponents() {
    document.querySelectorAll(GRID_STACK_ITEM_SELECTOR).forEach((element) => {
      const id = element.getAttribute('gs-id');
      const widget = id && this.wigdetsById[id];
      if (widget) {
        const contentElement = element.querySelector(GRID_STACK_ITEM_CONTENT_SELECTOR);
        this.addNoShadow(widget) && contentElement?.classList.add('no-shadow');

        const WidgetComponent = Vue.extend(WidgetShow);
        const instance = new WidgetComponent({
          parent: this,
          propsData: { widget, isPublicDashboard: this.isPublicDashboard, canEdit: this.canEdit, dashboardPaneId: this.dashboardPaneId },
        });
        instance.$mount();
        contentElement?.appendChild(instance.$el);
      }
    });
  }

  renderSubGridTitles() {
    this.subGridHTMLElements.forEach((element) => {
      const id = element.getAttribute('gs-id') || '';
      const title = this.subGridTitlesById?.[id];

      if (title) {
        const contentElement = element.querySelector(GRID_STACK_ITEM_CONTENT_SELECTOR);
        const titleElement = document.createElement('h4');
        titleElement.classList.add('grid-stack-sub-grid-title');
        titleElement.innerText = title;
        contentElement && contentElement.prepend(titleElement);
      }
    });
  }

  redrawChart(el: Element) {
    const chartkickItem = Chartkick.charts[el.id];
    chartkickItem && chartkickItem.redraw();
  }

  redrawCharts(element: GridItemHTMLElement | GridStackNode[] | GridStackNode | undefined) {
    const chartItems = Array.from((element as HTMLElement).getElementsByClassName('chart-item'));

    if (chartItems) {
      chartItems.forEach(this.redrawChart);
    }

    this.dragStopEvent();
  }

  resizeStopEvent(event: Event, element: GridItemHTMLElement | GridStackNode[] | GridStackNode | undefined) {
    this.redrawCharts(element);
  }

  addResizeStopEvent() {
    this.grid?.on('resizestop', this.resizeStopEvent);
  }

  dragStopEvent() {
    this.$emit('saveAfterDrag');
  }

  addDragStopEvent() {
    this.grid?.on('dragstop', this.dragStopEvent);
  }

  getSubGridHTMLElements() {
    return document.querySelectorAll(GRID_STACK_SUB_GRID_SELECTOR) as NodeListOf<GridItemHTMLElement>;
  }

  initSubGridsRecalc() {
    this.resizeSubGridsToContent();
  }

  initGridstack(options: GridStackOptions) {
    this.finalGridStackOptions = { ...options };
    this.grid = GridStack.init(options);
  }

  initSubGrids() {
    this.subGridHTMLElements = Array.from(this.getSubGridHTMLElements());
    this.initSubGridsRecalc();
    this.subGridTitlesById && this.renderSubGridTitles();
  }

  afterGridStackInit() {
    this.renderWidgetComponents();
    this.grid?.setAnimation(true);

    !this.gridOptions?.disableResize && this.addResizeStopEvent();
    this.hasSubGrids && this.initSubGrids();
    this.addDragStopEvent();
  }

  windowResizeEvent() {
    this.hasSubGrids && this.resizeSubGridsToContent();
  }

  mounted() {
    window.addEventListener('resize', this.windowResizeEventDebounced);
    this.unsubscribeOrientation = orientationDetector(debounce(this.windowResizeEventDebounced, 150));
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.windowResizeEventDebounced);
    this.unsubscribeOrientation && this.unsubscribeOrientation();
  }
}
