
  import UserProfileModalInstance from '@app/components/admin/users/user-profile-modal-instance.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsPopover from '@app/components/ds-popover.vue';
  import UserImage from '@app/components/user-image.vue';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import { AdminPermissionFeature } from '@app/models/admin-permission';
  import type { DefaultTemplate } from '@app/models/default-template';
  import type { ModuleName } from '@app/models/module-name';
  import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
  import { UI_OBSERVER } from '@app/services/global-observer';
  import { entityPathPrefix } from '@app/services/helpers';
  import { useAccountStore } from '@app/stores/account';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import type { Subscription } from 'rxjs';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import TextHighlight from 'vue-text-highlight';

  interface MenuItem {
    children?: Omit<MenuItem, 'id'>[];
    createMainFormInModal?: boolean;
    id: string;
    moduleNameId: number;
    name: string;
    path: string;
  }

  interface DropdownItem {
    badge?: string | number;
    class?: string;
    data?: Record<string, string>;
    events?: Record<string, () => void>;
    href?: string;
    id?: string;
    name?: string;
    params?: object;
    role?: 'button';
    tabindex?: number;
    target?: string;
  }

  @Component({
    components: { UserImage, DsDropdown, TextHighlight, DsPopover },
  })
  export default class PageHeader extends mixins(ModuleRecordModals, WithSearchableDsDropdown) {
    @Prop(String) readonly pageTitle?: string;
    @Prop(String) readonly backLink!: string;

    createModuleNames: Pick<ModuleName, 'name' | 'display' | 'id' | 'feature_set'>[] = [];
    defaultTemplates: Pick<DefaultTemplate, 'name' | 'system_code' | 'id' | 'module_name_id'>[] = [];
    activitiesLength: Nullable<number> = null;
    currentSearchValue = '';
    dropdownOpen = false;
    menuCollectionSubscription: Nullable<Subscription> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get finalPageTitle() {
      if (this.pageTitle) {
        return this.pageTitle;
      }

      if (this.$route.meta?.title) {
        return this.$t(this.$route.meta?.title, { defaultValue: this.$route.meta.title });
      }
    }

    get searchableCreateItems(): MenuItem[] {
      const createItemsWithSearchableName = this.createItems.map((i) => {
        return {
          ...i,
          searchableName: [i.name, ...(i.children || []).map((c) => c.name)].join(' '),
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return this.getSearchableItems(createItemsWithSearchableName, 'searchableName').map(({ searchableName, ...rest }) => rest);
    }

    get searchAction(): string {
      return this.accountStore.data ? '/search' : '/accounts';
    }

    get searchParamName(): string {
      return this.accountStore.data ? 'filters[search]' : 'query';
    }

    get hasAccessToSettings(): boolean {
      return (
        this.currentUserStore.isTechAdmin ||
        !!this.currentUserStore?.data?.admin_permission_features?.some((f) => {
          if (f === AdminPermissionFeature.module_config) {
            return this.accountStore.moduleConfigForNonTechAdmins;
          }
          return true;
        })
      );
    }

    get dropdownItems(): DropdownItem[] {
      let items: DropdownItem[] = [];
      if (!!this.accountStore.data) {
        if (this.currentUserStore.canViewActivity) {
          items = [
            ...items,
            {
              badge: (this.activitiesLength && `${this.activitiesLength}`) || undefined,
              href: '/actions',
              name: this.$t('app.labels.actions'),
            },
          ];
        }

        if (this.hasAccessToSettings) {
          items = [
            ...items,
            {
              href: '/admin/settings',
              name: this.$t('app.labels.settings'),
            },
          ];
        }

        if (this.accountStore.data?.help_url) {
          items = [
            ...items,
            {
              href: this.accountStore.data.help_url,
              name: this.$t('app.labels.help'),
              target: '_blank',
            },
          ];
        }

        items = [
          ...items,
          {
            name: this.$t('app.labels.profile'),
            role: 'button',
            tabindex: 0,
            class: 'user-profile-modal-button',
            events: {
              click: () => {
                new UserProfileModalInstance({ parent: this, propsData: { userId: this.currentUserStore.data?.id } }).$mount();
              },
            },
          },
        ];
      }

      items = [
        ...items,
        {
          href: '/users/sign_out',
          id: 'signout',
          name: this.$t('app.labels.logout'),
        },
      ];

      return items;
    }

    get createItems(): MenuItem[] {
      return this.createModuleNames.map((moduleName, index) => {
        const defaultTemplates = this.defaultTemplates.filter(({ module_name_id }) => module_name_id === moduleName.id);
        return {
          path: HARDCODED_MODULE_CODES.includes(moduleName.name)
            ? `/${entityPathPrefix(moduleName.name)}/new`
            : `/module_records/new?module_name_id=${moduleName.id}`,
          createMainFormInModal: !!moduleName?.feature_set?.create_main_form_in_modal,
          name: moduleName.display,
          moduleNameId: moduleName.id,
          id: `menu-dropdown-submenu-${index}`,
          children: defaultTemplates.map((defaultTemplate) => ({
            path: `/module_records/new?module_name_id=${moduleName.id}&default_template_id=${defaultTemplate.system_code}`,
            name: defaultTemplate.name,
            moduleNameId: moduleName.id,
            createMainFormInModal: !!moduleName?.feature_set?.create_main_form_in_modal,
          })),
        };
      });
    }

    get canCreateModuleNames(): string[] {
      const moduleAcl = this.currentUserStore.data?.acl?.module || {};
      return Object.keys(moduleAcl).reduce((memo: string[], key) => {
        const createAvailable = moduleAcl[key].create && !moduleAcl[key].hide_quick_add_button;
        if (createAvailable && key !== 'Report') return [...memo, key];
        return memo;
      }, []);
    }

    getItemAttributes(item: DropdownItem): DropdownItem {
      const { data = {}, ...rest } = item;
      const dataItems = Object.keys(data).reduce((memo, key) => ({ ...memo, [`data-${key}`]: data[key] }), {});

      return {
        ...rest,
        ...dataItems,
        events: undefined,
      };
    }

    addNewAction(item: Omit<MenuItem, 'id'>, event: MouseEvent): void {
      this.openModalOrLink({
        modal: !!item.createMainFormInModal,
        link: item.path,
        event,
        modalProps: {
          mode: 'module-record-edit',
          moduleNameId: item.moduleNameId,
          title: this.moduleRecordEditTitle(item.name),
        },
      });
    }

    async getCreateItems(): Promise<void> {
      const { data } = await this.$api.getModuleNames(
        {
          sort: 'menu_collection.index,index',
          filters: { active: true, name: this.canCreateModuleNames.join(',') },
          per_page: -1,
          only: ['name', 'display', 'id', { feature_set: ['create_main_form_in_modal'] }],
        },
        { cache: true }
      );
      this.createModuleNames = data;
      data.length &&
        this.$api
          .getDefaultTemplates(
            {
              filters: { module_name_id: data.map(({ id }) => id).join(','), show_ui: true, active: true },
              per_page: -1,
              sort: 'index',
              only: ['id', 'system_code', 'name', 'module_name_id'],
            },
            { cache: true }
          )
          .then(({ data: data_1 }) => {
            this.defaultTemplates = data_1;
          });
    }

    loadActionsCount(): void {
      if (this.currentUserStore.canViewActivity && this.activitiesLength === null) {
        this.$api
          .getActivities(
            {
              only: ['id'],
              filters: { assignee_id: this.currentUserStore.data?.id, status_filter: 'open' },
              per_page: 1,
            },
            { cache: true }
          )
          .then(({ headers }) => {
            this.activitiesLength = parseInt(headers.total);
          });
      }
    }

    beforeDestroy(): void {
      this.menuCollectionSubscription?.unsubscribe();
    }

    beforeMount(): void {
      if (this.accountStore.data && this.canCreateModuleNames.length) {
        this.menuCollectionSubscription = UI_OBSERVER.menuCollectionOrderUpdate$.subscribe(() => {
          this.$api.cache.clear();
          this.getCreateItems();
        });

        this.getCreateItems();
      }
    }

    mounted(): void {
      this.currentSearchValue = this.accountStore.data ? '' : (this.$route.query.query as string);
    }
  }
