
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import Select2 from '../select2.vue';
  import type { ModuleName } from '@app/models/module-name';

  interface StringOption {
    id: number;
    title: string;
  }

  @Component({ components: { Select2 } })
  export default class AdminModuleRecordsImportInstructions extends Vue {
    @Prop(Object) readonly moduleName!: ModuleName;
    @Prop(Array) readonly subForms?: StringOption[];

    downloadSubFormTemplate(subFormId: number): string {
      return `/admin/settings/template_manual?module_name=${this.moduleName.name}&sub_form_id=${subFormId}`;
    }
  }
