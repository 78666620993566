export const orientationDetector = (callback: Function) => {
  const mql = window.matchMedia('(orientation: portrait)');

  const orientationChangeHandler = (event: MediaQueryListEvent) => callback(event.matches ? 'portrait' : 'landscape');
  mql.addEventListener('change', orientationChangeHandler);
  callback(mql.matches ? 'portrait' : 'landscape');

  return () => {
    mql.removeEventListener('change', orientationChangeHandler);
  };
};
