
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import SvgLibraryTemplateForm from '../../../components/admin/svg-library/svg-library-template-form.vue';
  import type { UpdateSvgTemplateRequest } from '@app/services/api/svg-templates-api';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, SvgLibraryTemplateForm } })
  export default class EditSvgTemplatePage extends Vue {
    @Ref() readonly form?: SvgLibraryTemplateForm;

    visible = true;
    template: Nullable<SvgLibraryTemplate> = null;

    loading = true;

    beforeMount(): void {
      this.fetchTemplate();
    }

    fetchTemplate(): void {
      this.$api
        .getSvgTemplate(this.$route.params.svgTemplateId, { include: ['attachment', 'components'] })
        .then(({ data }) => {
          this.template = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    onFormSubmit(form: UpdateSvgTemplateRequest): void {
      this.$api
        .updateSvgTemplate(form)
        .then(() => this.$api.cache.clear())
        .then(() => this.closeModal('reload'))
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'svg-templates-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }
  }
