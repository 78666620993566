
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormRelationQuestionOptions } from '@app/models/question-options/sub-form-relation-question-options';

  @Component({ components: {} })
  export default class SubFormRelationShowOptions extends BaseShowOptions<SubFormRelationQuestionOptions> {
    subForm: Partial<Pick<SubForm, 'id' | 'title'>> = { title: '' };

    get subFormId(): Maybe<number> {
      return Number(this.config.sub_form_id) || undefined;
    }

    beforeMount(): void {
      !!this.subFormId &&
        this.$api
          .getSubForm(this.subFormId, { only: ['id', 'title'] }, { join: true, cache: true })
          .then(({ data }) => {
            this.subForm = data;
          })
          .catch(() => {
            this.subForm = { title: this.$t('tenant.admin.sub_form_questions.options.show.sub_form_does_not_exist') as string };
          });
    }
  }
