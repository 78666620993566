
  import { ExpensingMethod } from '@app/models/sub-form-question';
  import { Component, Vue } from 'vue-property-decorator';
  import Instructions from '@app/components/admin/questions/settings/shared/instructions.vue';

  @Component({
    computed: {
      ExpensingMethod() {
        return ExpensingMethod;
      },
    },
    components: { Instructions },
  })
  export default class ExpenseBudgetSelectFieldOptions extends Vue {}
