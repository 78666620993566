import I18n from '@app/i18n';
import { Vue, Component } from 'vue-property-decorator';
import type { PlaceholderKeyMapping } from '@app/components/advanced-autosuggest/utils';

@Component
export default class WithPlaceholderMappings extends Vue {
  get placeholderMappings(): PlaceholderKeyMapping[] {
    return [
      {
        key: 'q',
        title: 'Display Value of Field',
        description: 'Used when communicating with users or populating text fields.',
      },
      {
        key: 'dataq',
        title: 'Code value of Field',
        description: 'Most commonly required when populating other fields.',
      },
      {
        key: 'context.',
        title: 'Automation Context Values',
        description: 'Used to call up values contextual to the automation (e.g., time)',
      },
      {
        key: 'profile.',
        title: 'User Profile Values',
        description: 'This placeholder extension will allow you to return the fields in the "Personal Details" tab of a user profile.',
      },
      {
        key: 'response.',
        title: 'API request response data',
        description: 'Pulls values from an API request response. Please preview historical responses for placeholder handles.',
      },
      {
        key: 'record.',
        title: 'Parent Record Values**',
        description: 'Used to call up values from the parent module record.',
      },
      {
        key: 'relation.',
        title: 'Value from related record**',
        description: 'Used to call up values from the record selected in the target field',
      },
      {
        key: 'from.',
        title: 'From Record Values**',
        description: 'Used to call values from the record on the FROM side of the relationship.',
      },
      {
        key: 'to.',
        title: 'To Record Values**',
        description: 'Used to call values from the record on the TO side of the relationship.',
      },
      {
        key: 'approval.',
        title: 'Value from approval form**',
        description: 'Used on Sub-forms that have approval forms – can call values from the approval form.',
      },
      {
        key: 'base.',
        title: 'Value from original form**',
        description: 'Used in approval forms to call values from the form being approved.',
      },
      {
        key: 'iterated.',
        title: 'Iterated Value**',
        description: 'Used to call up iterated values from an evaluated set.',
      },
      {
        key: 'user.',
        title: 'User',
        description: I18n.t('app.services.event_options.activity.user'),
      },
      {
        key: 'approved_by.',
        title: 'User',
        description: I18n.t('app.services.event_options.activity.approved_by'),
      },
      {
        key: 'assigned_approver.',
        title: 'User',
        description: I18n.t('app.services.event_options.activity.assigned_approver'),
      },
      {
        key: 'completed_by.',
        title: 'User',
        description: I18n.t('app.services.event_options.activity.completed_by'),
      },
      {
        key: 'assignee.',
        title: 'User',
        description: I18n.t('app.services.event_options.activity.assignee'),
      },
      {
        key: 'sub_form.',
        title: 'Sub_form',
        description: 'Used to return details about the subform',
      },
      {
        key: 'workflow.',
        title: 'Workflow',
        description: 'Used to return details about the workflow',
      },
      {
        key: 'variable.',
        title: 'Variable',
        description: 'Used to call up any available global placeholders',
      },
      {
        key: 'calculation.',
        title: 'Calculation',
        description: 'Used to return calculation value',
      },
    ];
  }
}
