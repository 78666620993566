
  import { isEmpty, snakeCase, reduce } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { MenuCollection } from '@app/models/menu-collection';
  import Select2 from '@app/components/select2.vue';
  import type { AppBundleMetadata as AppBundleMetadataType } from '@app/models/app-bundles/installation';
  import FormField from '../questions/edit/form-field.vue';

  @Component({ components: { Select2, FormField } })
  export default class AppBundleMetadata extends Vue {
    @Prop(Object) metadata!: AppBundleMetadataType;

    menuCollectionsOptions: { id: number; name: string }[] = [];
    prefix: Nullable<string> = null;

    beforeMount(): void {
      this.loadMenuCollections();
      this.metadata.menu_collection_id ||= -1;
    }

    async loadMenuCollections(): Promise<MenuCollection[]> {
      const { data } = await this.$api.getMenuCollections({ cache: true });
      this.menuCollectionsOptions = data;
      return data;
    }

    nameKeydown(event: KeyboardEvent): void {
      if (/^[\WA-Z]$/.test(event.key)) {
        event.preventDefault();
      }
    }

    normalizeModuleNames(): void {
      const namesMap = this.metadata.module_name_names;
      if (!namesMap) return;

      const prefix = (this.prefix && `${snakeCase(this.prefix)}_`) || '';
      this.metadata.module_name_names = reduce(namesMap, (acc, value, key) => ({ ...acc, [key]: `${prefix}${snakeCase(key)}` }), {});
    }

    get hasModuleNames(): boolean {
      return !isEmpty(this.metadata.module_name_names);
    }

    get sortedModuleNames(): { newName?: string; oldName: string }[] {
      if (!this.metadata.module_name_names) return [];

      return Object.keys(this.metadata.module_name_names)
        .sort()
        .map((oldName) => ({ oldName, newName: this.metadata.module_name_names && this.metadata.module_name_names[oldName] }));
    }
  }
