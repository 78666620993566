
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import keys from '@app/typed-keys';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { BaseSelectQuestionOptions } from '@app/models/question-options/base-select-question-options';

  import SelectResponseList from '../../../question-response/select-response-list.vue';

  type Question = SubFormQuestion<BaseSelectQuestionOptions>;

  @Component({
    components: {
      ScoredQuestionConfig,
      DsCheckbox,
      SelectResponseList,
    },
  })
  export default class SingleSelectFieldOptions extends Vue {
    @Model('input') readonly value!: Question;

    @Prop(Object) readonly features!: ModuleFeatures;

    get hasScore(): boolean {
      return this.value.config.scored + '' === 'true';
    }

    beforeMount() {
      this.setDefaultValues();
    }

    setDefaultValues() {
      const defaultOption = this.value.config.default?.option;

      if (defaultOption && this.value.options.values && this.value.options.values[defaultOption]) {
        this.$set(this.value.options.values[defaultOption], 'is_default', true);
      }
    }

    updateDefaults(options: Record<string, { is_default: boolean }>) {
      const values = keys(options).filter((id) => options[id].is_default);

      this.$$patch(this.value.config.default, { option: values[0] });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      this.$delete(this.value.options, 'values');
      this.$delete(this.value.config, 'allow_clear_response');
    }
  }
