
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import IndicatorForm from '../../../components/admin/indicators/indicator-form.vue';
  import type { Indicator } from '@app/models/indicator';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { IndicatorForm } })
  export default class IndicatorNew extends Vue {
    @Ref() readonly form?: IndicatorForm;

    indicator: Nullable<Indicator> = null;

    onFormSubmit(indicator: Indicator): void {
      this.$api
        .createIndicator({
          ...indicator,
          indicator_set_id: Number(this.$route.params.indicator_set_id),
        })
        .then(({ data }) => {
          this.$api.cache.clear();
          this.$router.replace({
            name: 'admin-indicator-set-show',
            params: { indicatorSetId: `${data.indicator_set_id}` },
            query: this.$route.query,
          });
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }
  }
