import { render, staticRenderFns } from "./mobile-app-layout-entity-form.vue?vue&type=template&id=235162d5&scoped=true&lang=pug"
import script from "./mobile-app-layout-entity-form.vue?vue&type=script&lang=ts"
export * from "./mobile-app-layout-entity-form.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235162d5",
  null
  
)

export default component.exports