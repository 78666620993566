
  import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
  import { Sketch as SketchPicker } from 'vue-color';
  import type { Components, Config, CurrentTab, ParentRule, Rules, Validation, Variables } from '../models/types';
  import { LogicElementTypes } from '../models/types';

  @Component({ components: { SketchPicker } })
  export default class FormulaOptions extends Vue {
    @Prop({ required: true }) readonly configs!: Config;
    @Prop({ required: true }) readonly updateConfigs!: (name: string, checked: boolean) => void;
    @Prop({ required: true }) readonly validation!: Validation;
    @Prop({ required: true }) readonly currentTab!: CurrentTab;
    @Prop({ required: true }) readonly parentRule!: ParentRule;
    @Prop({ required: true }) readonly components!: Components;
    @Prop({ required: true }) readonly rules!: Rules;
    @Prop({ required: true }) readonly variables!: Variables;
    @Prop({ required: true }) readonly updateSavePending!: (state: boolean, callback?: () => void) => void;
    @Prop() readonly newObject?: boolean;
    @PropSync('currentName') syncedCurrentName!: string;
    @PropSync('currentColor') syncedCurrentColor!: string;

    colorPickerDisplay = false;

    toggleColorPickerDisplay(): void {
      this.colorPickerDisplay = !this.colorPickerDisplay;
    }

    nameOrColorSavePending(nameOrColor: 'name' | 'color', currentNameOrColor: string): boolean {
      let beforeNameOrColor;

      switch (this.currentTab.type) {
        case LogicElementTypes.PARENT_RULE:
          beforeNameOrColor = this.parentRule[nameOrColor];
          break;
        case LogicElementTypes.RULE:
          beforeNameOrColor = this.rules?.[this.currentTab.value]?.[nameOrColor];
          break;
        case LogicElementTypes.COMPONENT:
          beforeNameOrColor = this.components?.[this.currentTab.value]?.[nameOrColor];
          break;
        case LogicElementTypes.VARIABLE:
          beforeNameOrColor = this.variables?.[this.currentTab.value]?.[nameOrColor];
          break;
        default:
          break;
      }

      return beforeNameOrColor !== currentNameOrColor;
    }

    handleChange(value: string, name: 'name' | 'color'): void {
      if (!this.newObject) this.updateSavePending(this.nameOrColorSavePending(name, value));

      if (name === 'name') {
        this.syncedCurrentName = value;
      } else if (name === 'color') {
        this.syncedCurrentColor = value;
      }
    }
  }
