export const uniqWithDuplicates = <T>(array: T[], key: keyof T) => {
  const seen = new Map();
  const unique: T[] = [];
  const duplicates: T[] = [];

  array.forEach((item) => {
    const itemKey = item[key];
    if (seen.has(itemKey)) {
      duplicates.push(item);
    } else {
      seen.set(itemKey, true);
      unique.push(item);
    }
  });

  return { unique, duplicates };
};
