
  import { Component, Prop } from 'vue-property-decorator';
  import type { BlobFilters, GraphDataError, GraphDataArray } from '@app/services/api/super-reports-api';
  import WithReportGraph from '@app/mixins/with-report-graph';
  import type { SuperReportGraphData } from '@app/models/super-report';
  import { omit } from 'lodash';

  @Component({})
  export default class AdminReportGraph extends WithReportGraph {
    @Prop(String) readonly type!: string;
    @Prop(Array) readonly colors!: string[];
    @Prop(Boolean) readonly legend?: boolean;
    @Prop(Boolean) readonly stacked?: boolean;
    @Prop() readonly filterBlob!: BlobFilters['filter_blob'];

    defaultRadius = 1;
    loading = true;
    error = false;
    errorMessage: string = '';

    reportData: SuperReportGraphData = {
      graph_data: [],
      type: '',
      stacked: false,
      colors: this.colors,
    };

    get graphType(): string {
      return this.type?.replace('_', '-');
    }

    isGraphDataError(data: GraphDataArray | GraphDataError): data is GraphDataError {
      return 'data' in data && 'error' in data['data'];
    }

    beforeMount() {
      this.$api
        .buildSuperReportGraphData({
          filter_blob: omit(this.filterBlob, 'table'),
        })
        .then(({ data }) => {
          const graphData = data.data.graph_data;

          if (this.isGraphDataError(graphData)) {
            this.errorMessage = graphData.data.error;
            this.error = true;
          } else {
            this.reportData.graph_data = graphData;
          }
        })
        .catch(({ status, data }) => {
          if (status === 504) {
            this.errorMessage = this.$t('tenant.reports.super_report.report_timeout');
          } else if (data && typeof data['error'] === 'string') {
            this.errorMessage = data.error;
          } else {
            this.errorMessage = this.$t('tenant.reports.super_report.report_error');
          }
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
