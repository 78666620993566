
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { orderBy } from 'lodash';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import UserImage from '@app/components/user-image.vue';
  import UserImageLink from '@app/components/user-image-link.vue';
  import UserInvolvementForm from './user-involvement-form.vue';
  import { Tooltip } from 'uiv';
  import type { BaseRecord } from '@app/models/module-record';
  import type { Involvement } from '@app/models/involvement';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import DsPopover from '@app/components/ds-popover.vue';

  @Component({ components: { UserInvolvementForm, UserImage, UserImageLink, Tooltip, DsPopover } })
  export default class InvolvementsPanelItem extends Vue {
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly loading?: boolean;
    @Prop(Object) readonly entity!: Pick<BaseRecord, 'id'>;
    @Prop(String) readonly entityType!: string;
    @Prop(Object) readonly involvement!: Involvement;
    @Prop({ type: Array, default: () => [] }) readonly userInvolvementGroup!: UserInvolvement[];
    @Prop(String) readonly refreshKey!: string;
    @Ref() readonly userInvolvementForm?: UserInvolvementForm;
    @Ref() readonly popover?: typeof DsPopover;

    addNewPopover = false;

    popoverState: Record<number, boolean> = {};

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get orderedInvolvements(): UserInvolvement[] {
      return orderBy(this.userInvolvementGroup, 'created_at');
    }

    togglePopover(userInvolvementId: number): void {
      this.popoverState = { ...this.popoverState, [userInvolvementId]: !this.popoverState[userInvolvementId] };
    }

    toggleNewPopover(): void {
      this.addNewPopover = !this.addNewPopover;
    }
    openSelect(): void {
      this.$nextTick(() => {
        this.userInvolvementForm?.userSelector?.toggle(true);
      });
    }

    isVisible(involvement: Involvement): boolean {
      const hidden = involvement.hide_empty;
      return !hidden || (!!this.canEdit && involvement.can_add && involvement.involvement_type === 'standard');
    }

    canAddNewUserInvolvement(involvement: Involvement): boolean {
      return (
        !!this.canEdit &&
        involvement.involvement_type === 'standard' &&
        involvement.can_add &&
        (involvement.multiple || !this.userInvolvementGroup.length)
      );
    }

    canDeleteUserInvolvement(involvement: Involvement): boolean {
      return !!this.canEdit && involvement.involvement_type === 'standard' && involvement.can_delete;
    }

    deleteUserInvolvement(userInvolvementId: number): void {
      this.$emit('delete', userInvolvementId);
    }

    addInvolvement(userIds: number | number[]): void {
      this.$emit('add', userIds);
      this.toggleNewPopover();
    }

    editUserInvolvement(userId: number, userInvolvement: UserInvolvement): void {
      this.$emit('edit', { userId, userInvolvement });
      this.togglePopover(userInvolvement.id);
    }
  }
