
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import FormField from '@app/components/admin/questions/edit/form-field.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import { FieldType } from '@app/models/sub-form-question';
  import type { AreaQuestionOptions } from '@app/models/question-options/area-question-options';

  @Component({
    components: { FormField, EntitySelector },
  })
  export default class AreaFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<AreaQuestionOptions>;
    @Prop(Object) readonly subForm!: SubForm;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['sub_form_question_system_code'].forEach((key) => this.$delete(this.value.config, key));
    }

    get locationQuestionFilters() {
      return {
        field_type: FieldType.location,
        sub_form_section: { sub_form_id: this.subForm.id },
        active: true,
      };
    }
  }
