
  import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import EntitySelector from '@app/components/entity-selector.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import OrganizationSelector from '@app/components/organization/organization-selector.vue';
  import RecordSelector from '@app/components/record-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import UserSelector from '@app/components/user/user-selector.vue';
  import type { ConfiguratorFilterValue, DateRangeFilterValue } from '@app/models/configurator-filter';

  import FormField from '../../edit/form-field.vue';
  import type { FilterOption, MatchingMode, SimpleOption } from '../model';

  import DateRangeFilterValueRow from './date-range-filter-value-row.vue';

  // TODO remove parsley stuff once widgets are migrated to vee-validate
  @Component({
    components: {
      EntitySelector,
      Select2,
      UserSelector,
      RecordSelector,
      OrganizationSelector,
      LocationSelector,
      DateRangeFilterValueRow,
      FormField,
    },
  })
  export default class FilterConfiguratorItemValue extends Vue {
    @Model('input') readonly value!: FilterOption;
    @Prop(String) readonly name?: string;
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(String) readonly valueColumnClass!: string;
    @Prop(String) readonly mode!: MatchingMode;
    @Prop(Function) readonly dynamicOptions!: (mode: MatchingMode) => SimpleOption[];
    @Ref('dateRangeFilterValueRow') readonly dateRangeFilterValueRow?: DateRangeFilterValueRow[];

    get option(): FilterOption {
      return this.value;
    }

    get optionName(): string | undefined {
      if (this.name) {
        return this.option.multiple && !this.option.source ? `${this.name}[value][]` : `${this.name}[value]`;
      }
    }

    get explicitProps(): object {
      return {
        name: this.optionName,
        value: this.option.value,
        multiple: this.option.multiple,
        disabled: this.readonly,
        readonly: this.readonly,
        required: !this.option.null,
        'allow-clear': true,
        'data-parsley-errors-container': `#${this.messageKeyHolderContainerId(this.option)}`,
      };
    }

    @Emit('input')
    onValueUpdate(value: ConfiguratorFilterValue | DateRangeFilterValue[]): FilterOption {
      return { ...this.value, value };
    }

    @Emit('input')
    onNullInput(nullValue: boolean) {
      return { ...this.value, null: nullValue };
    }

    dynamicKey(mode: MatchingMode): 'id' | 'code' | undefined {
      switch (mode) {
        case 'dynamic':
        case 'user':
        case 'completion':
        case 'record':
          return 'id';
        case 'dynamic_code':
          return 'code';
        case 'registration_default':
          return 'id';
        default:
          return undefined;
      }
    }

    messageKeyHolderContainerId(option: FilterOption): string {
      return `message-key-holder-${this.htmlFriendlyAttribute(this.name)}-${this.htmlFriendlyAttribute(option.key)}`;
    }

    htmlFriendlyAttribute(string?: string): string {
      return `${string}`.replace(/[\[\]]+/g, '');
    }

    initializeDateRangeValues(ind: 0 | 1): void {
      // just in case template will change in the future
      if (this.mode !== 'range') return;

      this.$nextTick(() => {
        if (ind === 1 && (this.value.value as DateRangeFilterValue[])?.length === 0) {
          const greaterValue = this.dateRangeFilterValueRow?.[0]?.form;
          const lessValue = this.dateRangeFilterValueRow?.[1]?.form;
          if (greaterValue && lessValue) this.onValueUpdate([greaterValue, lessValue]);
        }
      });
    }
  }
