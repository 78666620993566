
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { Component, Ref } from 'vue-property-decorator';
  import { BaseTable } from '../../base-table';
  import UserSelector from '@app/components/user/user-selector.vue';
  import { Tooltip } from 'uiv';
  import DsModal from '@app/components/ds-modal.vue';
  import { isEmpty } from 'lodash';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import SearchInput from '@app/components/search-input.vue';
  import type { TenantUser } from '@app/models/tenant-user';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  type ReplaceManagerModalMode = 'edit' | 'remove';

  @Component({
    components: {
      BaseTable,
      UserSelector,
      Tooltip,
      DsModal,
      EntitySelector,
      FilterSelect,
      Select2,
      SearchInput,
    },
  })
  export default class UserManagerFor extends mixins(WithUser) {
    @Ref() readonly table?: BaseTable<TenantUser>;

    manager: Nullable<ListManager<TenantUser, ExtraUserApiOptions>> = null;
    selectedUsersOption: string[] = [];
    selectedManagerOption: string | null = null;
    addSubordinatesModal = false;
    replaceManagerModal = false;
    userToUpdate: string | null = null;
    activeOptions: [string, string][] = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
    replaceModalMode: ReplaceManagerModalMode | null = null;
    user: Nullable<TenantUser> = null;
    isEmpty = isEmpty;

    get replaceManagerModalTitle(): string {
      if (this.replaceModalMode === 'edit') {
        return this.$t('tenant.admin.users.sections.tab_manager.modal.titles.update');
      }
      return this.$t('tenant.admin.users.sections.tab_manager.modal.titles.remove');
    }

    get replaceManagerButtonName(): string {
      if (this.replaceModalMode === 'edit') {
        return this.$t('tenant.admin.users.sections.tab_manager.update_manager');
      }
      return this.$t('tenant.admin.users.sections.tab_manager.remove_manager');
    }

    getManager(): ListManager<TenantUser, ExtraUserApiOptions> {
      return new ListManager<TenantUser, ExtraUserApiOptions>({
        fetchDataFunction: (params) => {
          params.filters = { ...params.filters, manager_id: this.userId };
          return this.$api.getTenantUsers(
            {
              ...params,
              only: ['id', 'full_name', 'email', 'position', 'active', { role: ['id', 'name'] }, 'secondary_information'],
            },
            { cache: true }
          );
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'full_name', sortField: 'full_name' }],
        per_page: 25,
        fields: [
          {
            title: 'ID',
            name: 'id',
            sortField: 'id',
          },
          {
            title: 'Name',
            name: 'full_name',
            sortField: 'full_name',
          },
          {
            title: 'Email',
            name: 'email',
            sortField: 'email',
          },
          {
            title: 'Role',
            name: 'role',
            filter: true,
            sortField: 'role.name',
          },
          {
            title: 'Position',
            name: 'position',
            sortField: 'position',
          },
          {
            title: 'Active',
            name: 'active',
            sortField: 'active',
            filter: true,
          },
          {
            title: '',
            name: 'remove',
          },
        ],
        allowFilters: true,
      });
    }

    addManagerFor(managerId: number): void {
      if (this.selectedUsersOption.length > 0) {
        this.$api
          .getTenantUser(managerId, { only: ['id', 'child_ids'] })
          .then(({ data }) => {
            const existingChildIds = data?.child_ids || [];
            const childIds = [...existingChildIds, ...this.selectedUsersOption.map(Number)];
            this.$api
              .updateTenantUser(managerId, { child_ids: childIds })
              .then(() => {
                toaster({
                  text: this.$t('tenant.admin.users.sections.tab_manager.subordinate_added'),
                  position: 'top-right',
                });
                this.$api.cache.clear();
                this.table?.reload();
                this.selectedUsersOption = [];
                this.addSubordinatesModal = false;
              })
              .catch(({ data }) => {
                toaster({ text: data?.error, position: 'top-right', icon: 'error' });
              });
          })
          .catch(({ data }) => {
            toaster({ text: data?.error, position: 'top-right', icon: 'error' });
          });
      }
    }

    replaceManager(userId: number): void {
      this.$api
        .updateTenantUser(userId, {
          manager_id: this.selectedManagerOption !== null ? Number(this.selectedManagerOption) : null,
        })
        .then(() => {
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_manager.manager_updated'),
            position: 'top-right',
          });
          this.$api.cache.clear();
          this.table?.reload();
          this.selectedManagerOption = null;
          this.replaceManagerModal = false;
          this.userToUpdate = null;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    openReplaceManagerModal(userId: string, mode: ReplaceManagerModalMode): void {
      this.userToUpdate = userId;
      this.replaceModalMode = mode;
      this.replaceManagerModal = true;
    }

    userSelectorTemplateResult(result: Record<string, string> = {}): JQuery {
      return select2ResponseTemplate(result, {
        secondaryAttribute: (u: Partial<TenantUser>) => {
          return u.manager ? `${this.$t('tenant.admin.users.sections.tab_manager.manager')}: ${u.manager.full_name}` : null;
        },
        primaryAttribute: (u: { full_name: string; id: string; text: string }) => {
          return u.text;
        },
      });
    }

    userLinkEdit(userId: string): string {
      return `/admin/settings/users/${userId}/edit`;
    }

    mounted(): void {
      this.manager = this.getManager();
    }

    beforeMount() {
      this.$api.getTenantUser(this.userId, {}, { cache: true }).then(({ data }) => {
        this.user = data;
      });
    }
  }
