
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import ActivityActionButton from '@app/components/activities/activity-action-button.vue';
  import DsLabel from '@app/components/ds-label.vue';
  import type { DonesafeLocationsApiExtraOptions } from '@app/services/api/locations-api';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { entityPathPrefix } from '@app/services/helpers';
  import { Tooltip } from 'uiv';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { API_NULL, ACTIVITY_CONCEPT } from '@app/constants';
  import DsDatetimeSelector from '@app/components/ds-datetime-selector.vue';
  import type { SubmitCommentEvent } from '@app/services/api/comments-api';
  import type { Activity } from '@app/models/activity';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Location } from '@app/models/location';
  import type { ModuleName } from '@app/models/module-name';
  import type { Comment } from '@app/models/comment';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { ActionPriority } from '@app/models/action-priority';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField, ListManagerSortItem } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import type { DonesafeActivitiesApiExtraOptions } from '@app/services/api/activities-api';
  import { toaster } from '@app/utils/toaster';
  import type { SubFormCompletion } from '@app/models/sub-form-completion';

  import UserSelector from '../user/user-selector.vue';
  import Select2 from '../select2.vue';
  import LocationSelector from '../location/location-selector.vue';
  import FilterSelect from '../filter-select.vue';
  import BaseTable from '../base-table/base-table.vue';

  import { ACTIVITIES_TABLE_ACTIVITY_ONLY, getActivityUiState } from './utils';
  import ActivityActions from './activity-actions.vue';
  import formatPriorityOption from './format-priority-option';
  import PriorityLabel from './priority-label.vue';

  @Component({
    components: {
      FilterCheckbox,
      ActivityActionButton,
      DsLabel,
      Select2,
      LocationSelector,
      UserSelector,
      FilterSelect,
      BaseTable,
      Tooltip,
      PriorityLabel,
      ActivityActions,
      DsDatetimeSelector,
    },
  })
  export default class ActivitiesTable extends Vue {
    @Ref() readonly table?: BaseTable<Activity>;
    @Prop(Array) indexOptions?: string[];
    @Prop(Boolean) readonly useHistory?: boolean;
    @Prop(Boolean) readonly reverse?: boolean;
    @Prop(String) readonly sort?: string;
    @Prop({ type: Number, default: () => 25 }) readonly pageSize!: number;
    @Prop(Object) customFilters?: DonesafeFilterOptions<Activity, DonesafeActivitiesApiExtraOptions>;

    manager: Nullable<ListManager<Activity, DonesafeActivitiesApiExtraOptions>> = null;
    firstClassModuleNames: ModuleName[] = [];
    priorities: ActionPriority[] = [];
    statusFilters: string[] = [];

    getActivityUiState = getActivityUiState;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get creatorFilterIds(): string[] {
      return ((this.manager?.customFilters.user_id as string[]) || []).filter((id) => id !== API_NULL);
    }

    get stateFilterOptions(): [string, string][] {
      return this.statusFilters.map((v) => [v, this.stateFilterLabels[v]]);
    }

    get stateFilterLabels(): Record<string, string> {
      return {
        in_progress: this.$t('activerecord.models.activities.status.open'),
        closed_not_requiring_approval: this.$t('activerecord.models.activities.status.closed'),
        due_today: this.$t('activerecord.models.activities.status.due_today'),
        overdue_not_requiring_approval: this.$t('activerecord.models.activities.status.overdue'),
        overdue_pending_approval: this.$t('activerecord.models.activities.status.overdue_pending_approval'),
        completed_pending_approval: this.$t('activerecord.models.activities.status.completed_pending_approval'),
        closed_approved: this.$t('activerecord.models.activities.status.closed_and_approved'),
      };
    }

    get actionableTypes(): [string, string][] {
      const moduleNames = this.firstClassModuleNames.map((moduleName) => [moduleName.name, moduleName.display]) as [string, string][];
      return [['Location', this.$t('app.labels.standalone')], ...moduleNames];
    }

    get actionableTypesHash(): Dictionary<string> {
      return this.actionableTypes.reduce((memo, type) => ({ ...memo, [type[0]]: type[1] }), {
        Standalone: this.$t('app.labels.standalone'),
      });
    }

    get userSelectorFilters(): DonesafeFilterOptions<TenantUser, ExtraUserApiOptions> {
      const filters = { active: true };
      switch (this.accountStore.data.user_selector_filter_restrictions) {
        case 'accessible_by_active_user':
          return {
            ...filters,
            home_location_accessible_by_user_id: this.currentUserStore.data?.id,
            home_organization_accessible_by_user_id: this.currentUserStore.data?.id,
          };
        case 'accessible_to_active_user':
          return {
            ...filters,
            accessible_location_id: this.currentUserStore.data?.home_location_id,
            accessible_organization_id: this.currentUserStore.data?.home_organization_id,
          };
        default:
          return filters;
      }
    }

    get fields(): ListManagerField<Activity>[] {
      const availableColumns = [
        {
          title: this.$t('app.labels.actionable_type_filter'),
          name: 'actionable_type_filter',
          filter: true,
          sortField: 'actionable_type_filter',
        },
        { title: this.$t('app.labels.approved_at'), name: 'approved_at', filter: true, sortField: 'approved_at' },
        { title: this.$t('app.labels.approved_by'), name: 'approved_by', filter: true, sortField: 'approved_by.full_name' },
        {
          title: this.$t('app.labels.assigned_approver'),
          name: 'assigned_approver',
          filter: true,
          sortField: 'assigned_approver.full_name',
        },
        { title: this.$t('app.labels.assigned_to'), name: 'assigned_to', filter: true, sortField: 'assigned_to' },
        { title: this.$t('app.labels.closed_at'), name: 'closed_at', filter: true, sortField: 'closed_at' },
        { title: this.$t('app.labels.completed_by'), name: 'completed_by', filter: true, sortField: 'completed_by.full_name' },
        { title: this.$t('app.labels.created_at'), name: 'created_at', filter: true, sortField: 'created_at' },
        { title: this.$t('app.labels.created_by'), name: 'created_by', filter: true, sortField: 'user.full_name' },
        { title: this.$t('app.labels.due'), name: 'date', sortField: 'date' },
        { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id' },
        { title: this.$t('app.labels.location'), name: 'location', filter: true, sortField: 'location' },
        { title: this.$t('app.labels.related_form'), name: 'sub_form_completion', sortField: 'sub_form_completion.cached_title' },
        { title: this.$t('app.labels.related_id'), name: 'actionable_id', sortField: 'actionable_id' },
        { title: this.$t('app.labels.related_record'), name: 'related_to', sortField: 'related_to' },
        { title: this.$t('app.labels.status'), name: 'state', filter: true, sortField: 'state' },
        { title: this.$t('app.labels.title'), name: 'description', sortField: 'description' },
        this.showPriorities
          ? { title: this.$t('app.labels.priority'), name: 'priority', sortField: 'action_priority_id', filter: true }
          : null,
        { title: this.$t('app.labels.related_id'), name: 'actionable_id', sortField: 'actionable_id' },
        { title: '', name: 'checkbox', sticky: true },
      ]
        .filter((v) => !!v)
        .map((v) => ({ ...v, dataClass: 'd-flex align-items-center' } as ListManagerField<Activity>));

      return (
        this.indexOptions
          ?.map((option) => availableColumns.find((c) => c?.name === option) as ListManagerField<Activity>)
          .filter(Boolean) || []
      );
    }

    get sortOrder(): ListManagerSortItem[] {
      if (this.sort) {
        const direction = this.reverse ? 'desc' : 'asc';
        return [{ sortField: this.sort, field: this.sort, direction }];
      }
      return [{ sortField: 'date', field: 'date', direction: 'desc' }];
    }

    get showPriorities(): boolean {
      return this.accountStore.data.action_options.show_action_priorities === 'true' && !!this.priorities.length;
    }

    get locationFilters(): DonesafeFilterOptions<Location, DonesafeLocationsApiExtraOptions> {
      const base = { with_restrictions: this.accountStore.data.apply_permission_to_selectors };
      if (this.accountStore.data.hide_inactive_olu_for_filters) {
        return { ...base, active: true };
      }
      return base;
    }

    set creatorFilterIds(ids: string[]) {
      const includeNull = ((this.manager?.customFilters.user_id as string[]) || []).includes(API_NULL);
      this.manager?.setFilter('user_id', [...ids, ...(includeNull ? [API_NULL] : [])]);
    }

    getCompletionLink(completion: Pick<SubFormCompletion, 'id'>): string {
      return `/sub_form_completions/${completion.id}`;
    }

    userFullName(user: TenantUser): string | undefined {
      return user?.full_name;
    }

    updateRow(id: number, data: Partial<Activity>): void {
      this.manager && this.table?.setData(this.manager.items.map((a) => (id === a.id ? { ...a, ...data } : a)));
    }

    toggleLoading(activity: Activity, loading: boolean): void {
      this.updateRow(activity.id, { _loading: loading } as Partial<Activity>);
    }

    closeActivity(activity: Activity, data?: Partial<Pick<Comment, 'comment'> & { attachments?: string[] }>): void {
      this.toggleLoading(activity, true);
      const closeData = { ...data, attachments: data?.attachments || [], only: ACTIVITIES_TABLE_ACTIVITY_ONLY };
      this.$api
        .closeActivity(activity.id, closeData)
        .then(({ data }) => this.updateRow(activity.id, data))
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => this.toggleLoading(activity, false));
    }

    openActivity(activity: Activity, commentParams?: SubmitCommentEvent['data']): void {
      this.toggleLoading(activity, true);
      this.$api
        .openActivity(activity.id, { ...commentParams, only: ACTIVITIES_TABLE_ACTIVITY_ONLY })
        .then(({ data }) => this.updateRow(activity.id, data))
        .catch(({ data }) => toaster({ text: data.error, position: 'top-right', icon: 'error' }))
        .finally(() => this.toggleLoading(activity, false));
    }

    getManager(): ListManager<Activity, DonesafeActivitiesApiExtraOptions> {
      return new ListManager<Activity, DonesafeActivitiesApiExtraOptions>({
        fetchDataFunction: (params) =>
          this.$api.getActivities({ ...params, only: ACTIVITIES_TABLE_ACTIVITY_ONLY, include: ['legacy'] }, { cache: true }),
        useHistory: this.useHistory,
        per_page: this.pageSize,
        sortOrder: this.sortOrder,
        fields: this.fields,
        customFilters: this.customFilters,
        allowFilters: true,
      });
    }

    relatedToName(activity: Activity): string | undefined {
      if (activity.actionable_type !== 'Location') {
        if (activity.related_to === undefined) {
          return this.$t('app.labels.not_accessible').toString();
        } else if (activity.related_to === '') {
          return `${entityPathPrefix(activity.actionable_type)}/${activity.actionable_id}`;
        } else {
          return activity.related_to;
        }
      }
    }

    relatedToLink(e: MouseEvent, url?: string) {
      url && this.$link(e, url, 'location', { prevent: url.startsWith('/module_records') });
    }

    relatedToPath(activity: Activity): string | undefined {
      if (activity.actionable_type !== 'Location' && activity.related_to !== undefined) {
        const prefix = entityPathPrefix(activity.actionable_type);
        return `/${prefix}/${activity.actionable_id}`;
      }
    }

    reload(): void {
      this.table?.reload();
    }

    async beforeMount(): Promise<void> {
      await Promise.all([this.loadPriorities(), this.loadModules(), this.fetchStatusFilters()]);
      this.manager = this.getManager();
    }

    async loadModules() {
      const { data: moduleNames } = await this.$api.getModuleNames(
        {
          filters: { active: true },
          sort: 'display',
          only: ['id', 'name', 'display'],
          per_page: -1,
        },
        { cache: true }
      );
      this.firstClassModuleNames = moduleNames;
      return this.firstClassModuleNames;
    }

    async loadPriorities(): Promise<ActionPriority[]> {
      const { data } = await this.$api.getActionPriorities({ sort: '-active,index' }, { cache: true });
      this.priorities = data;
      return this.priorities;
    }

    async fetchStatusFilters(): Promise<void> {
      const { data } = await this.$api.getActivityStatusFilters({ cache: true });
      this.statusFilters = data;
    }

    formatPriorityOption(option?: { id: ActionPriority['id'] }): string {
      const priority = this.priorities.find(({ id }) => id + '' === option?.id + '');

      return formatPriorityOption({ $t: this.$t })(priority) as string;
    }

    reopenActivity(activity: Activity, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.openActivity(activity, commentParams);
    }

    approveActivity(activity: Activity, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .approveActivity(activity.id, {
          ...commentParams,
          comment: commentParams.comment,
          approved: true,
          only: ACTIVITIES_TABLE_ACTIVITY_ONLY,
        })
        .then(() => {
          this.$api.cache.clear();
          this.reload();
        });
    }

    unapproveActivity(activity: Activity, commentParams?: SubmitCommentEvent['data']): void {
      if (!commentParams?.comment) return;

      this.$api
        .unapproveActivity(activity.id, {
          ...commentParams,
          comment: commentParams.comment,
          approved: false,
          only: ACTIVITIES_TABLE_ACTIVITY_ONLY,
        })
        .then(() => {
          this.$api.cache.clear();
          this.reload();
        });
    }

    createComment(activity: Activity, commentParams: SubmitCommentEvent['data']): void {
      if (!commentParams.comment) {
        return;
      }

      this.$api
        .createComment({
          comment: commentParams.comment,
          attachments: commentParams.attachments,
          commentable_id: activity.id,
          commentable_type: ACTIVITY_CONCEPT,
        })
        .then(() => {
          this.$api.cache.clear();
          this.reload();
        });
    }
  }
