
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import VideoShowModal from './video-show-modal.vue';
  import VideoItem from './video-item.vue';
  import Select2 from '@app/components/select2.vue';
  import SidePanel from '@app/components/side-panel.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Video } from '@app/models/video';

  @Component({
    components: { VideoShowModal, Select2, DsModal, SidePanel, VideoItem, ValidationObserver, ValidationProvider },
  })
  export default class VideoPanel extends Vue {
    @Prop(Boolean) readonly canEdit!: boolean;
    @Prop(String) readonly entityType!: string;
    @Prop(Number) readonly entityId!: number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    videos: Video[] = [];

    form: Partial<Video> = {};

    modalVisible = false;

    selectedVideo: Nullable<Video> = null;

    onShowModalClose(): void {
      this.selectedVideo = null;
    }

    toggleModal(value: boolean, form: Partial<Video> = {}): void {
      this.modalVisible = value;
      this.form = form;
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$api
            .createVideo({
              ...this.form,
              videoable_id: this.entityId,
              videoable_type: this.entityType,
            })
            .then(() => {
              this.fetchVideos();
              this.toggleModal(false);
            });
        }
      });
    }

    onOpen(video: Video): void {
      this.selectedVideo = video;
    }

    onDelete(video: Video): void {
      if (confirm(this.$t('tenant.videos.delete_this_video') as string)) {
        this.$api.deleteVideo(video.id).then(() => {
          this.fetchVideos();
        });
      }
    }

    addVideo(): void {
      this.toggleModal(true);
    }

    onEdit(video: Video): void {
      this.toggleModal(true, video);
    }

    fetchVideos(): void {
      this.$api
        .getVideos({
          include: 'user',
          filters: {
            videoable_id: this.entityId,
            videoable_type: this.entityType,
          },
        })
        .then(({ data }) => {
          this.videos = data;
        });
    }

    mounted(): void {
      this.fetchVideos();
    }
  }
