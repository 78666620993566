
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import AdminSettingsLocationTagsForm from '@app/components/admin/location-tags/admin-settings-location-tags-form.vue';
  import type { LocationTag } from '@app/models/location-tag';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { AdminSettingsLocationTagsForm, DsModal } })
  export default class AdminSettingsLocationTagsNew extends Vue {
    @Ref() readonly form?: AdminSettingsLocationTagsForm;

    locationTag = { tag_type: 'safety' };

    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-location-tags-index',
        params: { reloadTable: command },
        query: this.$route.query,
      });
    }

    onFormSubmit(form: Partial<LocationTag>): void {
      this.$api
        .createLocationTag(form)
        .then(() => {
          this.$api.cache.clear();
          this.closeModal('reload');
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }
  }
