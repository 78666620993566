
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { sortBy, uniq } from 'lodash';
  import type { BlobColumn, ReportBlob, ReportColumn } from '@app/models/super-report';
  import { ColumnType, isColumnsEqual } from '@app/models/super-report';

  interface SelectOption {
    disabled: boolean;
    element: HTMLOptionElement;
    id: string;
    selected: boolean;
    text: string;
  }

  type SortKey = 'default' | 'column_name' | 'column_type' | 'display' | 'importable';
  const ResponseColumnTypes = [
    ColumnType.Response,
    ColumnType.AddressResponse,
    ColumnType.DateResponse,
    ColumnType.ApprovalResponse,
    ColumnType.ExpenseResponse,
    ColumnType.HybridResponse,
    ColumnType.MatrixResponse,
    ColumnType.RefReportingResponse,
    ColumnType.ToocsResponse,
  ];

  @Component({
    components: { Select2 },
  })
  export default class AdminReportColumnsSelector extends Vue {
    @Prop(Object) readonly filterBlob!: ReportBlob;
    @Prop(Array) readonly columns!: ReportColumn[];

    @Ref() readonly form!: HTMLFormElement;

    selectedColumns: string[] = [];
    format = 'html';
    action = '/admin/settings/reports/new';
    sortKey: SortKey = 'default';

    columnOptions: ReportColumn[] = [];
    indexedColumns: ReportColumn[] = [];

    get questionColumns(): string[] {
      return this.indexedColumns.filter(({ column_type }) => ResponseColumnTypes.includes(column_type)).map(({ id }) => id);
    }

    get importableColumns(): string[] {
      return this.columnOptions
        .filter(({ column_type, archived }) => column_type === ColumnType.Importable && !archived)
        .map(({ id }) => id);
    }

    get selectedColumnsJson() {
      const columnsData = this.selectedColumns
        .map((id) => {
          return this.columnOptions.find((col) => id === col.id);
        })
        .filter((col) => col !== undefined)
        .map((col) => {
          let columnData: BlobColumn = { key: col!.key, column_name: col!.column_name, column_type: col!.column_type };
          const blobColumn = this.filterBlob.table.columns.find((column) => isColumnsEqual(col!, column));

          if (col?.source) {
            columnData.source = col.source;
          }
          if (blobColumn?.column_name) {
            columnData.column_name = blobColumn.column_name;
          }

          return columnData;
        });

      return JSON.stringify(columnsData);
    }

    confirmSelectColumn(sortKey: SortKey, selectColumns: () => void): void {
      // no need to confirm if no columns are selected
      if (!this.selectedColumns.length || confirm(this.$t('app.labels.are_you_sure_to_replace_reporting_columns') as string)) {
        this.initColumnOrder(sortKey);
        selectColumns();
      }
    }

    initSelectedColumns(): void {
      this.selectedColumns = this.filterBlob.table.columns
        .map((column) => this.indexedColumns.find((item) => isColumnsEqual(item, column))?.id)
        .filter((id): id is string => id !== undefined);
      this.initColumnOrder('default');
    }

    // sortKey = 'default' will sort based on selected columns order
    initColumnOrder(sortKey: SortKey): void {
      this.sortKey = sortKey;
      if (sortKey === 'importable') {
        this.columnOptions = uniq([...this.columnOptions.filter((column) => column.key.includes('external_id')), ...this.columnOptions]);
      } else if (sortKey === 'default') {
        this.columnOptions = sortBy(this.indexedColumns, (item) => this.selectedColumns.indexOf(item.key));
      } else {
        this.columnOptions = sortBy(this.indexedColumns, [sortKey]);
      }
    }

    selectAll(): void {
      this.confirmSelectColumn('default', () => (this.selectedColumns = [...this.indexedColumns.map((column) => column.id)]));
    }

    clearAll(): void {
      this.confirmSelectColumn('default', () => (this.selectedColumns = []));
    }

    selectQuestions(): void {
      this.confirmSelectColumn('default', () => (this.selectedColumns = [...this.questionColumns]));
    }

    selectImportable(): void {
      this.confirmSelectColumn('importable', () => (this.selectedColumns = [...this.importableColumns]));
    }

    mounted(): void {
      this.indexedColumns = this.columns.map((option, index) => ({ ...option, id: index.toString() }));
      this.initSelectedColumns();
    }

    prefixForQuestion(type?: ColumnType): string {
      if (type && ResponseColumnTypes.includes(type)) {
        return 'Question: ';
      } else if (type === ColumnType.Importable) {
        return 'Importable: ';
      } else if (type === ColumnType.Involvement) {
        return 'Involvement: ';
      } else {
        return '';
      }
    }

    subTitleForQuestion(column?: ReportColumn): string | undefined {
      if (column?.column_type && ResponseColumnTypes.includes(column?.column_type)) {
        return column.key;
      } else if (column?.column_type === ColumnType.Importable) {
        return column.method_chain[0] === 'sub_form_responses_grouped_by_sub_form_question_code'
          ? column.key?.replace('importable_', '')
          : undefined;
      }
    }

    codeTag(code: string): string {
      return `<code style="font-size:10px;line-height:normal;">${code}</code>`;
    }

    templateResult(result: { [key: string]: string } = {}): JQuery {
      const column = this.columnOptions.find((column) => result.id === column.id);
      const subTitle = this.subTitleForQuestion(column);
      const questionCodeTag = subTitle ? this.codeTag(subTitle) : '';
      const archivedTag = column?.archived ? this.codeTag(this.$t('app.labels.inactive') as string) : '';
      return $(
        `<div>
          <p style="margin-bottom:0;">${this.prefixForQuestion(column?.column_type)}${column?.display}</p>
          ${questionCodeTag}
          ${archivedTag}
         </div>`
      );
    }

    templateSelection(result: { [key: string]: string } = {}): JQuery {
      const column = this.columnOptions.find((column) => result.id === column.id);
      const subTitle = this.subTitleForQuestion(column);
      const questionCodeTag = subTitle ? this.codeTag(subTitle) : '';
      const archivedTag = column?.archived ? this.codeTag(this.$t('app.labels.inactive') as string) : '';
      return $(
        `<div style="display:inline-block;float:left;padding-right:3px">
            <p style="margin-bottom:0;">
                <span style="color: #a2a2a2">${this.prefixForQuestion(column?.column_type)}</span>
                ${column?.display}
                ${questionCodeTag}
                ${archivedTag}
            </p>
         </div>`
      );
    }

    matcher(params: { term?: string }, data: SelectOption): SelectOption | null {
      const term = params.term?.trim();
      if (!term) return data;

      if (typeof data.text === 'undefined') {
        return null;
      }

      const column = this.columnOptions.find((column) => data.id === column.id);
      const title = `${this.prefixForQuestion(column?.column_type)}${data.text}`.toLocaleLowerCase();
      const subTitle = this.subTitleForQuestion(column)?.toLocaleLowerCase() || '';
      const subString = term.toLocaleLowerCase();
      if (title.indexOf(subString) > -1 || subTitle.indexOf(subString) > -1) {
        return data;
      }

      return null;
    }

    sorter(items: SelectOption[]): SelectOption[] {
      return sortBy(items, (item) => {
        const column = this.columnOptions.find((column) => item.id === column.id);
        return `${this.prefixForQuestion(column?.column_type)}${column?.display}`;
      });
    }
  }
