
  import { debounce } from 'lodash';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import DsIconText from './ds-icon-text.vue';
  import FollowIcon from './follow-icon.vue';

  // deprecated
  // TODO: remove this once the record page is migrated to VueJS
  @Component({ components: { FollowIcon, DsIconText } })
  export default class FollowButton extends Vue {
    @Prop(Boolean) initialState!: boolean;
    @Prop({ required: true, type: String }) readonly followableType!: string;
    @Prop({ required: true, type: [String, Number] }) readonly followableId!: string;
    @Prop({ required: false, type: String }) icon?: string;
    @Prop({ required: false, type: String }) activeIcon?: string;

    state = false;
    dbState = false;
    beforeMount(): void {
      this.state = this.initialState;
      this.dbState = this.initialState;
    }

    get followState(): string {
      return this.state ? this.$t('tenant.shared.follow_and_events.followed') : this.$t('tenant.shared.follow_and_events.follow');
    }

    followUnfollow(follow: boolean): void {
      if (this.dbState != follow) {
        const followParams = { followable_id: this.followableId, followable_type: this.followableType };
        const apiCall = follow ? this.$api.followEntity(followParams) : this.$api.unfollowEntity(followParams);
        apiCall.then(({ data }) => {
          this.dbState = !!data.follow;
        });
      }
    }

    debounceFollowUnfollow = debounce(this.followUnfollow, 500);

    onClick(): void {
      this.state = !this.state;
      this.debounceFollowUnfollow(this.state);
    }
  }
