import type { BaseEntity } from './base-entity';
import type { ExtendedPermission } from '@app/models/extended-permission';
import type { Permission } from './permission';
import type { Role } from './role';
import type { WithModuleName } from './mixins';
import type DashboardPane from '@app/models/dashboard-pane';

export interface RoleProfile extends BaseEntity, WithModuleName {
  active: boolean;
  code: string;
  dashboard_pane_ids?: number[];
  dashboard_panes: Partial<DashboardPane>[];
  description: string;
  extended_permissions?: Pick<ExtendedPermission, 'name' | 'concept_name'>[];
  index: number;
  managerial_hierarchy_access: boolean;
  module_name_id: number;
  name: string;
  notes: string;
  permissions?: Permission[];

  required: boolean;
  role_ids?: number[];

  roles?: Role[];

  secondary_information?: string;
}

export const ROLE_PROFILE_ADMIN_RECORD_CODE = 'system_admin';
