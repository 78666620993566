
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { MainFormQuestionListOptions } from '@app/models/question-options/main-form-question-list-options';

  @Component({ components: { DsCheckbox } })
  export default class MainFormQuestionListFieldOptions extends Vue {
    @Prop(Object) readonly value!: SubFormQuestion<MainFormQuestionListOptions>;

    beforeMount(): void {
      this.$$patch(this.value.config, { group_options: 'true' }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['group_options'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
