
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import type { InjectableArguments } from '@app/services/api/utils-api';
  import type { Subscription } from '@rails/actioncable';
  import { pick } from 'lodash';
  import { Component, Prop, Watch } from 'vue-property-decorator';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import { automationDefinitionEditPath } from '@app/utils/automation-definition-edit-path';
  import { getValueFromLocationSearch } from '@app/utils/get-value-from-location-search';
  import { toaster } from '@app/utils/toaster';
  import consumer from '@app/channels/consumer';
  import { AutomationDefinitionState } from '@app/models/automation-definition';
  import { mixins } from 'vue-class-component';
  import Blocking from '@app/mixins/blocking';
  import type { AutomationEventGroup } from '@app/models/automation-event-group';
  import automationDefinitionProjection from '@app/components/admin/automation-definitions/automation-definition-projection';
  import { changeLocation } from '@app/utils/change-location';

  import WithAutomationSubmit from './with-automation-submit';
  import type { RedirectionMode } from './models';
  import AutomationDefinitionForm from './automation-definition-form.vue';
  import AutomationDefinitionDebugModal from './automation-definition-debug-modal.vue';

  @Component({ components: { AutomationDefinitionForm, AutomationDefinitionDebugModal } })
  export default class AutomationDefinitionEdit extends mixins(Blocking, WithAutomationSubmit) {
    @Prop({ type: [Number, String], required: true }) readonly automationDefinitionId!: number | string;

    automationDefinitionSubscription: Nullable<Subscription> = null;
    ruleBuilderPanelKey = 0;
    automationActivationProgress = 0;
    automationDefinitionKey = 0;
    automationDefinition: Nullable<AutomationDefinition> = null;
    loading = false;
    debugModal = false;
    groups: AutomationEventGroup[] = [];

    get injectableArguments(): InjectableArguments {
      return {
        ...pick(this.automationDefinition || {}, [
          'concept_name',
          'sub_form_id',
          'involvement_id',
          'relationship_code',
          'regulatory_report_config_id',
        ]),
      };
    }

    get automationRuleSetBuilder(): AutomationRuleSetBuilder {
      return {
        concept_name: this.automationDefinition?.concept_name,
        injectable_arguments: this.injectableArguments,
        triggering_concept_name: null,
        for_collection_options: false,
        triggering_injectable_arguments: {},
      };
    }

    @Watch('$route.query.debug', { immediate: true })
    onRouteQueryDebugChanged(val: string): void {
      this.debugModal = val === 'true';
    }

    onCloseDebugModal(): void {
      this.changeDebugParam(undefined);
    }

    changeDebugParam(debug?: 'true'): void {
      this.$router.push({
        name: 'admin-settings-admin-automation-definition-edit',
        query: {
          ...this.$route.query,
          debug: debug,
        },
        params: { automationDefinitionId: String(this.automationDefinition?.id) },
      });
    }

    onSubmitEvent(
      automationDefinition: Partial<AutomationDefinition> & { rule_builder_rules: Nullable<QueryBuilderBlob> },
      mode?: RedirectionMode
    ) {
      this.submit(automationDefinition, this.events, { mode, onSubmitComplete: this.onSubmitComplete });
    }

    onSubmitComplete(data: AutomationDefinition, mode?: RedirectionMode) {
      toaster('Automation successfully updated!');

      if (mode === 'debug') {
        changeLocation(automationDefinitionEditPath(data.id, { debug: true }));
      } else if (mode === 'redirect') {
        changeLocation(getValueFromLocationSearch('redirect_to') || '/admin/settings/automation_definitions');
      } else {
        changeLocation(automationDefinitionEditPath(data.id, { redirect_to: getValueFromLocationSearch('redirect_to') }));
      }
    }

    deleteAutomation(): void {
      this.$api
        .deleteAutomationDefinition(this.automationDefinitionId)
        .then(() => {
          toaster(
            this.$t('tenant.admin.automation_definitions.form.automation_destroyed', {
              name: this.automationDefinition?.name,
            })
          );
          changeLocation(getValueFromLocationSearch('redirect_to') || '/admin/settings/automation_definitions');
        })
        .catch(({ data }) => toaster({ text: data.error, icon: 'error' }));
    }

    activateAutomation(): void {
      this.blocking(async () => {
        const {
          data: { state },
        } = await this.$api.activateAutomationDefinition(this.automationDefinitionId, { only: ['state'] });

        if (this.automationDefinition) {
          this.automationDefinition = { ...this.automationDefinition, state };
        }

        toaster({
          text: this.$t('tenant.admin.automation_definitions.form.activated') as string,
        });
      });
    }

    deactivateAutomation(): void {
      this.blocking(async () => {
        const {
          data: { state },
        } = await this.$api.deactivateAutomationDefinition(this.automationDefinitionId, { only: ['state'] });

        if (this.automationDefinition) {
          this.automationDefinition = { ...this.automationDefinition, state };
          this.automationActivationProgress = 0;
        }

        toaster({
          text: this.$t('tenant.admin.automation_definitions.form.deactivated') as string,
        });
      });
    }

    initForm(automationDefinition: AutomationDefinition) {
      this.automationDefinition = automationDefinition;
    }

    fetchAutomation() {
      this.$api
        .getAutomationDefinition(this.automationDefinitionId, { ...automationDefinitionProjection() }, { cache: true })
        .then(({ data }) => {
          // TODO get automated events

          this.groups = data.automation_event_groups?.map((groups) => ({ ...groups })) || [];
          this.events =
            data.automated_events?.map((automatedEvent) => {
              return {
                index: automatedEvent.index,
                ...automatedEvent.event,
                automation_event_group_id: automatedEvent.automation_event_group_id,
              };
            }) || [];

          this.initForm(data);
          this.initSubscription();
        })
        .catch(() => {
          // TODO: redirect to fallback route and show notice after fully switched to vue-router
          // toaster({ text: 'Automation does not exist', position: 'top-right', icon: 'error' });
        });
    }

    initSubscription() {
      if (!this.automationDefinition?.id) {
        return;
      }

      this.automationDefinitionSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'AutomationDefinition' },
        {
          received: async ({ id, state, progress }: { id: number; progress?: string; state: string }) => {
            if (!this.automationDefinition || !(this.automationDefinition.id === id)) {
              return;
            }

            if (progress && this.automationDefinition.state === AutomationDefinitionState.activating) {
              this.automationActivationProgress = +progress * 100;
            }

            if (state !== this.automationDefinition.state) {
              const {
                data: { state: loadedState },
              } = await this.$api.getAutomationDefinition(id, { only: ['state'] });

              this.automationDefinition = { ...this.automationDefinition, state: loadedState };
              this.ruleBuilderPanelKey++;
            }
          },
        }
      );
    }

    beforeMount(): void {
      this.loading = true;
      this.fetchAutomation();
    }

    beforeDestroy(): void {
      this.automationDefinitionSubscription?.unsubscribe();
    }
  }
