
  import BaseTable from '@app/components/base-table/base-table.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import PaperTrailsModalLink from '@app/components/paper-trails/paper-trails-modal-link.vue';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import type { Notification } from '@app/models/notification';
  import type { OnlyOptions } from '@app/services/donesafe-api-utils';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { useAccountStore } from '@app/stores/account';

  @Component({ components: { DsDropdown, PaperTrailsModalLink, BaseTable } })
  export default class AdminNotificationsIndexPage extends Vue {
    @Ref() readonly table?: BaseTable<Notification>;

    get filters(): Record<string, string> {
      return !!useAccountStore()?.data?.enable_action_approvals ? {} : { '!code': 'action_approvals' };
    }

    manager = new ListManager<Notification>({
      fetchDataFunction: (params) => {
        const only: OnlyOptions<Notification> = ['id', 'name', 'description', 'user_ids', 'active'];
        return this.$api.getNotifications({ ...params, only, filters: this.filters }, { cache: true });
      },
      per_page: 5,
      fields: [
        { title: 'Name', name: 'name' },
        { title: 'Description', name: 'description' },
        { title: 'Linked To', name: 'linked_to' },
        { title: 'Active', name: 'active', sortField: 'active', filter: true, width: '100px' },
        { title: '', name: 'operations', width: 'max-content' },
      ],
    });
  }
