
  import CategoryInput from '@app/components/admin/questions/svg-selector/category-input.vue';
  import Draggable from 'vuedraggable';
  import { Component, Emit, Model, Prop, Vue } from 'vue-property-decorator';
  import SvgInteractiveTemplate from '@app/components/svg-template/svg-interactive-template.vue';
  import { uniqBy, difference } from 'lodash';
  import ColorInput from '@app/components/color-input.vue';
  import Select2 from '@app/components/select2.vue';
  import FormField from '../edit/form-field.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SvgLibraryComponent } from '@app/models/svg-library/component';
  import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
  import type { SvgSelectorQuestionOptions, SvgSelectorCategory } from '@app/models/question-options/svg-selector-question-config';

  @Component({
    components: { CategoryInput, Select2, ColorInput, SvgInteractiveTemplate, Draggable, FormField },
  })
  export default class SvgTemplateConfigurator extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Object) readonly template!: SvgLibraryTemplate;
    @Prop(Boolean) readonly persisted!: boolean;

    @Model('input') readonly value!: Partial<SvgSelectorQuestionOptions>;

    svgContents = '';
    loading = false;
    empty = null;

    defaultSelectionEnabled = false;

    updateDefaultSelection(): void {
      this.defaultSelectionEnabled = this.defaultSelector.val() === 'values';
    }

    get defaultSelector(): JQuery {
      return $('.default-type');
    }

    get noDefaultCategories(): boolean {
      return !Object.values(this.value.default_categories || {}).some(Boolean);
    }

    mounted(): void {
      this.defaultSelector.on('change.svg-selector', this.updateDefaultSelection);
      this.updateDefaultSelection();
    }

    get defaultValues(): string[] {
      return this.value.default?.values || [];
    }

    set defaultValues(values: string[]) {
      if (this.value.allow_multiple === 'true' || !values.length) {
        const newValue = { ...this.value, default: { ...this.value.default, values } };
        this.$emit('input', newValue);
      } else {
        const defaults = this.value.default?.values || [];
        const newDefaults = [difference(values, defaults)[0]].filter(Boolean);
        const newValue = { ...this.value, default: { ...this.value.default, values: newDefaults } };
        this.$emit('input', newValue);
      }
    }

    onMultipleChange(): void {
      if (this.value.allow_multiple !== 'true') {
        this.defaultValues = [];
      }
    }

    beforeDestroy(): void {
      this.defaultSelector.off('change.svg-selector');
    }

    get allSelected(): boolean {
      return (this.value.selectable_components?.length ?? 0) === (this.template.components?.length ?? 0);
    }

    get hasMultipleCategories(): boolean {
      return this.availableCategories.length > 1;
    }

    get hasDuplicatedCategories(): boolean {
      const populatedCategories = this.value.categories?.filter((c) => c.code) || [];
      return uniqBy(populatedCategories, 'code').length !== populatedCategories.length;
    }

    get componentColors(): Dictionary<string> {
      return (
        this.value.selectable_components?.reduce((memo, path) => {
          const categoryCode = this.value.default_categories?.[path] || this.value.categories?.[0].code;
          const color = this.value.categories?.find((r) => r.code === categoryCode)?.color;
          return { ...memo, [path]: color };
        }, {}) || {}
      );
    }

    formatCategoryOptions(option: { id: string; text: string }): JQuery<HTMLElement> {
      if (!option.id) {
        return $(`<div>${option.text}</div>`);
      }
      const category = this.value.categories?.find((r) => r.code === option.id);
      return $(
        `<div>
            <span title="${option.text}" class="svg-component-color-bubble" style="background-color: ${option.text}">&nbsp;</span>
            <span class="m-l-xs">${category?.title}</span>
          </div>`
      );
    }

    addCategory(): void {
      const newCategory = { color: '#000000', title: '', code: '' };
      this.value.categories = [...(this.value.categories || []), newCategory];
    }

    get availableCategories(): SvgSelectorCategory[] {
      return this.value.categories?.filter((r) => r.code) || [];
    }

    get availableCategoriesKey(): string {
      return this.availableCategories.map((c) => `${c.code}_${c.title}`).join('-');
    }

    componentItemKey(component: SvgLibraryComponent): string {
      return `${component.path}-${this.availableCategoriesKey}`;
    }

    @Emit('input')
    toggleSelectAll(): Partial<SvgSelectorQuestionOptions> {
      if (this.allSelected) {
        return { ...this.value, selectable_components: [] };
      }

      return { ...this.value, selectable_components: (this.template.components ?? []).map((c) => c.path) };
    }

    removeCategory(index: number): void {
      const category = this.value.categories?.[index];
      this.value.categories?.splice(index, 1);
      this.value.default_categories = Object.keys(this.value.default_categories || {}).reduce((memo, path) => {
        const defaultCategory = this.value.default_categories?.[path];
        if (defaultCategory === category?.code) {
          return { ...memo, [path]: this.value.categories?.[0]?.code };
        }
        return memo;
      }, {});
    }

    @Emit('input')
    onSvgComponentClick(path: string): Partial<SvgSelectorQuestionOptions> {
      let newDefaults: SvgSelectorQuestionOptions['default_categories'] = this.value.default_categories ?? {};
      let newSelectables: SvgSelectorQuestionOptions['selectable_components'] = this.value.selectable_components ?? [];

      if ((this.value.selectable_components?.indexOf(path) ?? -1) >= 0) {
        newDefaults = Object.fromEntries(Object.entries(newDefaults).filter(([key]) => key !== path));
        newSelectables = newSelectables.filter((id) => id !== path);
      } else {
        newSelectables.push(path);
      }

      return {
        ...this.value,
        default_categories: newDefaults,
        selectable_components: newSelectables,
      };
    }

    beforeMount(): void {
      this.loading = true;
      this.$api
        .getSvgTemplateFile(this.template.id)
        .then(({ data }) => {
          this.svgContents = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    isSelectable(component: SvgLibraryComponent): boolean {
      return !!this.value.selectable_components && this.value.selectable_components.indexOf(component.path) >= 0;
    }
  }
