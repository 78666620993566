import I18n from '@app/i18n';
import { groupBy, isEqual, mapValues, sortBy, upperFirst } from 'lodash';
import type { ConfidentialityType } from '@app/models/confidentiality-type';
import type { ConfigurationType, RoleModuleName } from '@app/models/role-module-name';
import type { ExtendedPermission } from '@app/models/extended-permission';
import type { Permission } from '@app/models/permission';
import type { PermissionProvider, Role } from '@app/models/role';
import type { RoleProfile } from '@app/models/role-profile';
import {
  CONFIDENTIALITY_BYPASS_PREFIX,
  GlobalAccess,
  RecordActivityExtendedPermissions,
  SpecialPermissionName,
  UiToggles,
} from '@app/models/extended-permission';
import { HARDCODED_MODULE_CODES } from '@app/models/module-name';
import { API } from '@app/services/api-factory';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';
import { humanize } from '@app/utils/humanize';
import { ACTIVITY_CONCEPT } from '@app/constants';

export const RTB_RP_ONLY = Tuple([
  'id',
  'name',
  'description',
  'module_name_id',
  'permissions',
  'extended_permissions',
  'managerial_hierarchy_access',
  'required',
  'dashboard_pane_ids',
  { module_name: ['id', 'name'] },
  { roles: ['id', 'active'] },
] as const);
export type RoleTabDetailsRoleProfileOnly = FirstLevelKeyLiterals<(typeof RTB_RP_ONLY)[number]>;

export const RTB_RMN_ONLY = Tuple([
  'configuration_type',
  'module_name_id',
  'role_id',
  'role_profile_id',
  'managerial_hierarchy_access',
] as const);
export type RoleTabDetailsRoleModuleNameOnly = FirstLevelKeyLiterals<(typeof RTB_RMN_ONLY)[number]>;

export const RTB_PERMISSIONS_ONLY = Tuple(['id', 'name', 'access', 'concept_name', 'relationship'] as const);
export type RoleTabDetailsPermissionsOnly = FirstLevelKeyLiterals<(typeof RTB_PERMISSIONS_ONLY)[number]>;
export const RTB_ROLE_ONLY = Tuple([
  'id',
  'name',
  'system',
  'system_code',
  'dashboard_id',
  'dashboard_ids',
  'dashboard_pane_ids',
  'excluded_dashboard_pane_ids',
  'dynamic_dashboard_access',
  'dynamic_dashboard_customisation',
  'managerial_hierarchy_access_level',
  'user_directory_access',
  'permissions',
  'extended_permissions',
  'profile_permissions',
  { role_profiles: RTB_RP_ONLY, role_module_names: RTB_RMN_ONLY, permissions: RTB_PERMISSIONS_ONLY },
] as const);
export type RoleTabDetailsRoleOnly = FirstLevelKeyLiterals<(typeof RTB_ROLE_ONLY)[number]>;
export const RTB_MODULE_NAME_ONLY = Tuple(['id', 'display', 'name', 'module_type', 'plural_display'] as const);
export type RoleTabDetailsModuleNameOnly = (typeof RTB_MODULE_NAME_ONLY)[number];

export const EXTENDED_PERMISSIONS: Record<string, string[]> = {
  Activity: [
    SpecialPermissionName.standalone_activity_creation,
    SpecialPermissionName.edit_activity_content,
    SpecialPermissionName.audit_trail,
    SpecialPermissionName.allow_commenting_without_edit,
    SpecialPermissionName.approve_editable_standalone_actions,
  ],
  Incident: ['incident_notify_regulator', 'incident_high_threat'],
  Hazard: ['hazard_high_threat', 'duplicate_hazard'],
  Procedure: ['mandatory_for_visit'],
  WorkerCompensation: [
    'case_manager',
    'liability_status',
    'insurance_claim_status',
    'return_to_work_location',
    'notify_regulator',
    'return_to_work_leader',
    'return_to_work_approval_status',
    'relate_medical_certificate',
    'case_notes',
    'return_plan',
    'hours_and_days_lost',
    'delete_case_notes',
  ],
  CompanyRegister: ['bank_details'],
  Kpi: ['assign_to_everyone'],
};

export const extendedPermissionsByConceptAndName = (
  extended_permissions?: Pick<ExtendedPermission, 'name' | 'concept_name'>[]
): Record<string, Record<string, Pick<ExtendedPermission, 'name' | 'concept_name'>[]>> => {
  return mapValues(groupBy(extended_permissions || [], 'concept_name'), (arr) => groupBy(arr, ({ name }) => name));
};

export const rolePropsToUpdate = Tuple([
  'id',
  'organization_access',
  'can_create_contacts',
  'user_personal_details_access',
  'max_overall_approval_cost',
  'line_approval_percentage',
  'overall_approval_percentage',
  'profile_permissions',
] as const);
type LegacySettingsFormOnly = (typeof rolePropsToUpdate)[number];
export type RoleLegacySettingsForm = Pick<Role, LegacySettingsFormOnly>;

export const permissionViewName = (permission: Permission): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (permission.relationship === 'user') {
      resolve(I18n.t('app.labels.creator'));
    } else if (permission.relationship === 'activity_assignees') {
      resolve(I18n.t('tenant.admin.roles.rtb.action_assignee'));
    } else if (parseInt(permission.relationship) > 0) {
      API.getInvolvement(permission.relationship, { only: ['name', 'active', 'id'] }, { join: true })
        .then(({ data }) => {
          const involvementName = data.active ? data.name : I18n.t('app.labels.archived_name', { name: data.name });
          resolve(involvementName);
        })
        .catch(() => reject(permission.name));
    } else {
      resolve(permission.name);
    }
  });
};

export type CustomRightsForm = {
  concept_name: string;
  configuration_type: ConfigurationType;
  dashboard_pane_ids: number[];
  excluded_dashboard_pane_ids: number[];
  extended_permissions?: Pick<ExtendedPermission, 'name' | 'concept_name'>[];
  managerial_hierarchy_access: boolean;
  permissions?: Permission[];
  role_profile?: Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>;
};

export type RoleTabDetailsFormType = Partial<
  Pick<
    Omit<Role, 'managerial_hierarchy_access_level' | 'extended_permissions' | 'role_profiles' | 'role_module_names' | 'permissions'> & {
      extended_permissions: Pick<ExtendedPermission, 'concept_name' | 'name'>[];
      managerial_hierarchy_access_level: number | null;
      permissions?: Pick<Permission, RoleTabDetailsPermissionsOnly>[];
      role_module_names: Pick<RoleModuleName, RoleTabDetailsRoleModuleNameOnly>[];
      role_profiles?: Pick<RoleProfile, RoleTabDetailsRoleProfileOnly>[];
    },
    RoleTabDetailsRoleOnly
  >
>;

export const getProfilePermissionsBaseOptions = (): [string, string][] => {
  return [
    ['', I18n.t('app.labels.disabled')],
    ['view', I18n.t('tenant.admin.roles.rtb.can_view')],
    ['edit', I18n.t('tenant.admin.roles.rtb.can_edit')],
  ];
};

const epToLabelledObject = (ep: string): { label: string; name: string } => {
  return { name: ep, label: extendedPermissionLabel(ep) };
};

export const epOptionsWithLabels = (
  concept_name: string,
  confidentialityTypes: Pick<ConfidentialityType, 'name' | 'id' | 'active'>[]
): { label: string; name: string }[] => {
  let options: { label: string; name: string }[] = [
    ...(EXTENDED_PERMISSIONS[concept_name] || []),
    ...Object.values(GlobalAccess).filter((x) => x !== GlobalAccess.Create),
  ].map(epToLabelledObject);

  if (![...HARDCODED_MODULE_CODES, ACTIVITY_CONCEPT].includes(concept_name)) {
    options = [
      ...options,
      ...[
        SpecialPermissionName.confidentiality,
        SpecialPermissionName.audit_trail,
        SpecialPermissionName.allow_commenting_without_edit,
        SpecialPermissionName.approve_editable_actions,
      ].map(epToLabelledObject),
    ];
  }

  if (concept_name !== ACTIVITY_CONCEPT) {
    options = [
      ...options,
      ...Object.values(RecordActivityExtendedPermissions).map(epToLabelledObject),
      ...Object.values(UiToggles).map(epToLabelledObject),
    ];

    confidentialityTypes.forEach((ct) => {
      const label = `Can Bypass ${upperFirst(ct.name)} Restrictions`;
      options = [
        ...options,
        {
          name: `${CONFIDENTIALITY_BYPASS_PREFIX}${ct.id}`,
          label: ct.active ? label : I18n.t('app.labels.archived_name', { name: label }),
        },
      ];
    });
  }

  return sortBy(options, 'label');
};

const extendedPermissionLabel = (name: string) => {
  return I18n.t(`tenant.admin.roles.extended_permissions.${name}`, {
    defaultValue: `Can use ${humanize(name)}`,
  });
};

export enum UiRoleProfileIds {
  customised = 'customised',
  none = 'none',
}
export const areProfileRolePermsEqual = (
  concept: string,
  currentProfile: Pick<RoleProfile, 'permissions' | 'extended_permissions'>,
  currentRole: Pick<RoleProfile, 'permissions' | 'extended_permissions'>
): boolean => {
  const permsIds = (currentRole.permissions || [])
    .filter((p) => p.concept_name === concept)
    .map((p) => p.id)
    .sort();
  const extendedPerms = (currentRole.extended_permissions || [])
    .filter((p) => p.concept_name === concept)
    .map((p) => p.name)
    .sort();

  const profilePermsIds = (currentProfile.permissions || []).map((p) => p.id).sort();
  const profileExtendedPerms = (currentProfile.extended_permissions || []).map((p) => p.name).sort();

  return isEqual(permsIds, profilePermsIds) && isEqual(extendedPerms, profileExtendedPerms);
};

export const noRightsPerms: PermissionProvider = { permissions: [], extended_permissions: [], managerial_hierarchy_access: false };
