
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import TextHighlight from 'vue-text-highlight';
  import { API_NULL } from '@app/constants';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';

  import type { FilterType } from './models';

  @Component({
    components: {
      DsDropdown,
      TextHighlight,
    },
  })
  export default class AutomationDefinitionsActions extends mixins(WithSearchableDsDropdown) {
    @Prop(Array) readonly filterTypes!: FilterType[];

    dropdownOpen = false;

    get searchableOptions(): FilterType[] {
      return this.getSearchableItems<FilterType>(this.filterTypes, 'name');
    }
    objectLogLink(filterType: FilterType): string {
      if (!filterType.key) {
        return `/admin/settings/event_occurrences?filters[!triggered_concept]=${API_NULL}`;
      }

      const key = filterType.group === 'custom' ? `ModuleName|${filterType.id}` : filterType.key;
      return `/admin/settings/event_occurrences?filters[triggered_concept]=${key}`;
    }

    objectLogTitle(filterType: FilterType): string {
      if (!filterType.key) {
        return this.$t('tenant.admin.automation_definitions.index.full_automation_event_list');
      }

      return this.$t('tenant.admin.automation_definitions.index.object_event_list', { object: filterType.name });
    }

    displayDivider(index: number, items: FilterType[]): boolean {
      return items?.[index]?.group !== items?.[index + 1]?.group && items.length !== index + 1;
    }
  }
