
  import { displayCalculatedValue } from '@app/utils/display-calculated-value';
  import { upperFirst } from 'lodash';

  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import Uniq from './shared/uniq.vue';
  import type { CalculationTextQuestionOptions } from '@app/models/question-options/calculation-text-question-options';
  import { CalculationTextFormatValidation } from '@app/models/question-options/calculation-text-question-options';
  import { useAccountStore } from '@app/stores/account';

  @Component({ components: { Uniq } })
  export default class CalculationTextShowOptions extends BaseShowOptions<CalculationTextQuestionOptions> {
    CalculationTextFormatValidation = CalculationTextFormatValidation;

    displayValue(value?: string): Maybe<string> {
      if (value) return displayCalculatedValue(value, this.question, this.defaultCurrency);
    }

    get visibilityMap() {
      return {
        hide: this.$t('app.labels.hidden'),
        show: this.$t('tenant.admin.sub_form_questions.options.show.read_only'),
        override: this.$t('tenant.admin.sub_form_questions.options.show.editable'),
      };
    }

    get defaultDisplayValue(): Maybe<string> {
      const value = this.displayValue(this.config.default_value_amount) || this.displayValue(this.config.default?.value);
      if (value) return value;
    }

    get currencySymbol(): string {
      return this.config?.currency_symbol || this.defaultCurrency;
    }

    get accountStore() {
      return useAccountStore();
    }

    get defaultCurrency(): string {
      return this.accountStore.data.currency_symbol;
    }

    get formatValidation(): CalculationTextFormatValidation {
      return this.config.format_validation;
    }

    get prettyFormatValidation(): string {
      return upperFirst(this.formatValidation);
    }

    get decimalPlaces(): string {
      return !!this.config.decimal_places
        ? this.$t('tenant.admin.sub_form_questions.options.show.decimal_places', {
            decimal_places: this.config.decimal_places,
          })
        : '';
    }
  }
