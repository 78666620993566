
  import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { PartialDeep } from 'type-fest';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import type { UserCollection } from '@app/models/user-collection';

  import RuleBuilderPanel from '../events/rule-builder-panel.vue';

  import UserCollectionActions from './user-collection-actions.vue';

  @Component({
    components: {
      RuleBuilderPanel,
      ValidationProvider,
      ValidationObserver,
      UserCollectionActions,
    },
  })
  export default class UserCollectionForm extends Vue {
    @Prop(Object) readonly entity?: UserCollection;
    @Prop(Boolean) readonly disabled?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: PartialDeep<UserCollection> = {};

    get entityExists(): boolean {
      return !!this.entity?.id;
    }

    beforeMount(): void {
      this.form = { ...(this.entity || {}) };
    }

    async onSubmit(): Promise<void> {
      if (!(await this.validator?.validate())) return;

      this.$emit('submit', this.form);
    }

    onRuleBuilderChange(value: QueryBuilderBlob): void {
      this.form = {
        ...this.form,
        rule_set: {
          ...(this.form.rule_set || {}),
          query_builder_blob: value,
        },
      };
    }
  }
