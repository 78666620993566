import type { QueryBuilderGroup, QueryBuilderRule } from '@app/models/query-builder-blob';

export const extractQueryBuilderRules = (blob: QueryBuilderRule | QueryBuilderGroup): QueryBuilderRule[] => {
  if (blob && 'rules' in blob && blob.rules) {
    return blob.rules.reduce((accum: QueryBuilderRule[], r: QueryBuilderRule | QueryBuilderGroup) => {
      return [...accum, ...extractQueryBuilderRules(r)];
    }, []);
  } else {
    return [blob as QueryBuilderRule];
  }
};
