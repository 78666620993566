export { default as RecordIndexWidget } from './record-index-widget.vue';
export { default as FollowedRecordsWidget } from './followed-records-widget.vue';
export { default as ReportCountWidget } from './report-count-widget.vue';
export { default as DomoWidget } from './domo-widget.vue';
export { default as IframeWidget } from './iframe-widget.vue';
export { default as ImageWidget } from './image-widget.vue';
export { default as VideoWidget } from './video-widget.vue';
export { default as TextWidget } from './text-widget.vue';
export { default as ReportGraphWidget } from './report-graph-widget.vue';
export { default as ActionsWidget } from './actions-widget.vue';
export { default as LinkWidget } from './link-widget.vue';
export { default as ReportDataWidget } from './report-data-widget.vue';
export { default as SubFormIndexWidget } from './sub-form-index-widget.vue';
