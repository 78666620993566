
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import { useAccountStore } from '@app/stores/account';
  import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
  import { Tuple } from '@app/utils/types/tuple';
  import { Component, Ref } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import { Tooltip } from 'uiv';
  import SearchInput from '@app/components/search-input.vue';
  import LocationSelector from '@app/components/location/location-selector.vue';
  import bootbox from 'bootbox';
  import FilterSelect from '@app/components/filter-select.vue';
  import Select2 from '@app/components/select2.vue';
  import UserActiveLocationsModal from '@app/components/user-active-locations/user-active-locations-modal.vue';
  import type { AxiosResponse } from 'axios';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { Location } from '@app/models/location';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { toaster } from '@app/utils/toaster';
  import { mixins } from 'vue-class-component';
  import WithUser from '@app/mixins/with-user';

  const ONLY_OPTIONS = Tuple(['id', 'skip_explicit_location_restrictions', 'location_ids', 'home_location_id'] as const);

  @Component({
    components: {
      FilterCheckbox,
      BaseTable,
      Tooltip,
      SearchInput,
      LocationSelector,
      FilterSelect,
      Select2,
      UserActiveLocationsModal,
    },
  })
  export default class UserActiveLocations extends mixins(WithUser) {
    @Ref() readonly table!: BaseTable<Location>;
    @Ref() readonly modal?: UserActiveLocationsModal;

    form: Partial<Pick<TenantUser, FirstLevelKeyLiterals<(typeof ONLY_OPTIONS)[number]>>> = {};
    manager: Nullable<ListManager<Location>> = null;
    addLocationsModal = false;
    activeOptions: [string, string][] = [
      ['true', 'Active'],
      ['false', 'Inactive'],
    ];
    user: Nullable<TenantUser> = null;

    get accountStore() {
      return useAccountStore();
    }

    getManager(): ListManager<Location> {
      return new ListManager<Location>({
        fetchDataFunction: (params) => {
          const idsToFetch = this.form?.location_ids?.join(',');

          return !!idsToFetch
            ? this.$api.getLocations(
                {
                  ...params,
                  filters: {
                    ...params.filters,
                    id: idsToFetch,
                  },
                  only: ['id', 'name', 'active', { location: ['name'] }],
                },
                { cache: true }
              )
            : new Promise((resolve) => resolve({ data: [] as Location[] } as AxiosResponse<Location[]>));
        },
        useHistory: true,
        sortOrder: [{ direction: 'asc', field: 'name', sortField: 'name' }],
        per_page: 25,
        fields: [
          { title: this.$t('app.labels.ID'), name: 'id', sortField: 'id', width: 'max-content' },
          { title: this.$t('app.labels.name'), name: 'name', sortField: 'name' },
          {
            title: this.$t('tenant.settings.locations.sections.tab_details.parent_location'),
            name: 'location',
            sortField: 'location.name',
            filter: true,
          },
          { title: this.$t('app.labels.active'), name: 'active', sortField: 'active', filter: true, width: 'max-content' },
          { title: '', name: 'actions' },
        ],
        allowFilters: true,
      });
    }

    locationLinkEdit(locationId: string): string {
      return `/admin/settings/locations/${locationId}/edit`;
    }

    initForm(user: TenantUser): void {
      this.form = {
        ...this.form,
        skip_explicit_location_restrictions: user.skip_explicit_location_restrictions || false,
        location_ids: user.location_ids || [],
      };
      this.modal?.initForm();
    }

    locationName(location: Pick<Location, 'id' | 'name'>): string {
      if (location.id != this.user?.home_location_id) {
        return location.name;
      }
      return `${location.name} (${this.$t('app.labels.home_location')})`;
    }

    locationChange(id: number, checked: boolean): void {
      const newLocationIds = checked ? [...(this.form.location_ids || []), id] : this.form.location_ids?.filter((value) => value !== id);
      this.form = {
        ...this.form,
        location_ids: newLocationIds,
      };
    }

    removeLocation(location: Location): void {
      bootbox.confirm({
        size: 'small',
        backdrop: false,
        message: this.$t('tenant.admin.users.sections.tab_locations.remove_confirmation'),
        buttons: {
          confirm: { label: this.$t('app.labels.yes'), className: 'btn-success' },
          cancel: { label: this.$t('app.labels.no'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.locationChange(location.id, false);
            this.submitForm(this.form);
          }
        },
      });
    }

    submitForm(form: Partial<Pick<TenantUser, FirstLevelKeyLiterals<(typeof ONLY_OPTIONS)[number]>>>, fromModal = false): void {
      if (!this.user) return;

      this.$api
        .updateTenantUser(this.user.id, { ...form }, { only: ONLY_OPTIONS })
        .then(({ data }) => {
          toaster({
            text: this.$t('tenant.admin.users.sections.tab_locations.success'),
            position: 'top-right',
          });
          this.initForm(data);
          this.$api.cache.clear();
          this.table?.reload();
          fromModal && this.toggleModal();
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    toggleModal() {
      this.addLocationsModal = !this.addLocationsModal;
    }

    beforeMount(): void {
      this.$api.getTenantUser(this.userId, { only: ONLY_OPTIONS }, { cache: true }).then((response) => {
        this.user = response.data;

        this.initForm(this.user);
        this.manager = this.getManager();
      });
    }
  }
