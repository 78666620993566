
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import { AccountService } from '@app/components/paper-trails/services';
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { BaseTable } from '../base-table';
  import { Tooltip } from 'uiv';

  import AccountActions from '@app/components/admin/accounts/account-actions.vue';
  import type { Account } from '@app/models/account';
  import type { ModuleName } from '@app/models/module-name';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';

  @Component({
    components: {
      BaseTable,
      Tooltip,
      AccountActions,
    },
  })
  export default class AdminSettingsRestrictionMethodsPage extends Vue {
    @Ref() table?: BaseTable<ModuleName>;

    account: Partial<Account> = {};
    cellLoadingMap: Record<string, boolean> = {};

    permissionsHash: Record<number, ModuleName> = {};

    loading = false;
    manager: Nullable<ListManager<ModuleName>> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get accountStore() {
      return useAccountStore();
    }

    get auditTrailAttributeScope(): (keyof Account)[] {
      return AccountService.restrictionMethodAttributes;
    }

    getManager(): ListManager<ModuleName> {
      return new ListManager<ModuleName>({
        fetchDataFunction: (params) => {
          return this.$api.getModuleNames(
            {
              ...params,
              only: ['id', 'display', 'use_location_permissions', 'use_organization_permissions'],
              filters: { active: true },
            },
            { cache: true }
          );
        },
        afterFetch: (data) => {
          this.permissionsHash = data.reduce((acc, row) => ({ ...acc, [row.id]: row }), {});
        },
        useHistory: true,
        per_page: 25,
        sortOrder: [{ direction: 'asc', field: 'display', sortField: 'display' }],
        fields: this.fields,
      });
    }

    get fields(): ListManagerField<ModuleName>[] {
      return [
        { title: this.$t('module_names.restriction_bypasses.module'), name: 'display', sortField: 'display' },
        {
          title: this.$t('module_names.restriction_bypasses.records_restricted_by_location'),
          name: 'use_location_permissions',
          hidden: !this.accountStore.data.limit_permissions_by_location && !this.accountStore.data.limit_permissions_by_explicit_locations,
        },
        {
          title: this.$t('module_names.restriction_bypasses.records_restricted_by_organization'),
          name: 'use_organization_permissions',
          hidden: !this.accountStore.data.limit_permissions_by_organization,
        },
      ];
    }

    get canEditAccountSettings(): boolean {
      return this.currentUserStore.isTechAdmin && this.currentUserStore.isInternal;
    }

    toggleClass(moduleName: ModuleName, key: keyof ModuleName): string {
      if (this.cellLoadingMap[`${moduleName.id}-${key}`]) {
        return 'fa-spin fa-spinner';
      } else if (this.permissionsHash[moduleName.id]?.[key]) {
        return 'fa-toggle-on icon-on';
      } else {
        return 'fa-toggle-off icon-off';
      }
    }

    setLoading(moduleName: ModuleName, key: keyof ModuleName, state: boolean): void {
      this.cellLoadingMap = {
        ...this.cellLoadingMap,
        [`${moduleName.id}-${key}`]: state,
      };
    }

    tooltipText(moduleName: ModuleName, key: keyof ModuleName): string {
      return this.cellLoadingMap[`${moduleName.id}-${key}`]
        ? this.$t('app.labels.updating')
        : this.permissionsHash?.[moduleName.id]?.[key]
        ? this.$t('app.labels.disable')
        : this.$t('app.labels.enable');
    }

    togglePermissions(moduleName: ModuleName, key: keyof ModuleName): void {
      if (!this.cellLoadingMap[`${moduleName.id}-${key}`]) {
        this.setLoading(moduleName, key, true);
        this.$api
          .updateModuleName(moduleName.id, { [key]: !this.permissionsHash?.[moduleName.id]?.[key] })
          .then(({ data }) => {
            this.permissionsHash = { ...this.permissionsHash, [data.id]: data };
            this.$api.cache.clear();
          })
          .finally(() => this.setLoading(moduleName, key, false));
      }
    }

    persist(column: keyof Account, value: boolean): void {
      this.loading = true;
      this.$api
        .updateCurrentAccount({ [column]: value })
        .then(({ data }) => {
          this.$api.cache.clear();
          this.account = data;
          this.accountStore.$patch({ data: { ...this.accountStore.data, [column]: data[column] } });
          this.manager?.setFields(this.fields);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    beforeMount(): void {
      this.account = { ...this.accountStore.data };
      this.manager = this.getManager();
    }
  }
