
  import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import VClamp from 'vue-clamp';
  import DsIcon from '@app/components/ds-icon.vue';
  import { tooltip } from 'uiv';

  import type { DsGroupNode } from '../models/ds-group-node';
  import { ClampedType } from '../models/clamped-state';
  import type { DsRuleNode } from '../models/ds-rule-node';
  import type DsQueryBuilderHandler from '../models/ds-query-builder-handler';

  @Component({ components: { VClamp, DsIcon }, directives: { tooltip } })
  export default class CollapsedText extends Vue {
    @Inject() readonly qb!: DsQueryBuilderHandler;
    @Ref() readonly clamp!: InstanceType<typeof VClamp>;
    @Prop({ type: [String, Number] }) readonly text!: string | number;
    @Prop(Object) readonly group!: DsGroupNode;
    @Prop(Object) readonly rule?: DsRuleNode;
    @Prop(String) readonly clampedType!: ClampedType;

    expanded = false;
    clamped = false;

    get tooltipContent() {
      if (this.clamped) {
        return this.text;
      }
    }

    get tooltipProps() {
      return {
        text: this.tooltipContent,
        customClass: 'modal-tooltip',
      };
    }

    get expandedIcon() {
      return this.expanded ? this.qb.config.icons.collapse_text : this.qb.config.icons.expand_text;
    }

    @Watch('group.expanded')
    onGroupExpandedChanged(expanded: boolean) {
      if (expanded && !this.clamped) return;
      this.expanded = expanded;
    }

    onClampChange(clamped: boolean) {
      this.clamped = clamped;

      if (this.clampedType === ClampedType.GroupLabel) this.group.toggleClamped(this.clampedType, clamped);
      else this.rule?.toggleClamped(this.clampedType, clamped);
    }

    setExpandedItems(expanded: boolean) {
      if (this.clampedType === ClampedType.GroupLabel) this.group.setExpandedItems(this.clampedType, expanded);
      else this.rule?.setExpandedItems(this.clampedType, expanded);
    }

    withSetExpandedItems(toggle: () => void, expanded: boolean) {
      this.setExpandedItems(!expanded);
      return toggle();
    }
  }
