
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';

  import RoleActions from '@app/components/admin/roles/role-actions.vue';

  type TabDetail = {
    icon: string;
    routeName: string;
    title: string;
  };

  @Component({
    components: { Tabs, Tab, RoleActions },
  })
  export default class AdminRolesEdit extends Vue {
    @Prop({ type: [String, Number] }) readonly roleId!: string | number;

    @Watch('$route.name')
    onRouteChanged(routeName: string) {
      this.initTabIndex(routeName);
    }

    tabIndex: number | null = null;

    get tabs(): TabDetail[] {
      return [
        {
          routeName: 'admin-settings-roles-edit-details',
          title: this.$t('tenant.admin.roles.details'),
          icon: 'fa-list-ul',
        },
        {
          routeName: 'admin-settings-roles-edit-users',
          title: this.$t('tenant.admin.roles.users'),
          icon: 'fa-users',
        },
      ];
    }

    beforeChangeTab(indexFrom: number, indexTo: number, done: (err?: unknown) => void): void {
      const toRoute = {
        name: this.tabs[indexTo].routeName,
        params: { roleId: this.$route.params.roleId },
      };
      this.$router
        .push(toRoute)
        .then(() => done())
        .catch(() => done(false));
    }

    initTabIndex(routeName: string | null | undefined): void {
      const indexTo = this.tabs.findIndex((tab) => routeName?.includes(tab.routeName));
      this.tabIndex = indexTo >= 0 ? indexTo : 0;
    }

    beforeMount(): void {
      this.initTabIndex(this.$route.name);
    }
  }
