
  import { Component, Model, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import bootbox from 'bootbox';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { TabSectionGroup } from '@app/models/tab-section-group';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EntitySelector from '../entity-selector.vue';

  interface FilterOptions {
    active: boolean;
    module_tab_id: string[];
    sub_form_list_type: number[];
  }

  @Component({ components: { DsModal, EntitySelector, ValidationObserver, ValidationProvider } })
  export default class TabSectionGroupFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly tabSectionGroup!: TabSectionGroup;
    @Prop(Array) readonly moduleTabIds?: number[];
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<TabSectionGroup> = {};
    init = false;

    get subFormListFilter(): DonesafeFilterOptions<FilterOptions> {
      return {
        module_tab_id: this.moduleTabIds?.join(','),
        active: true,
        sub_form_list_type: ['normal', 'approval', 'workflow'],
      };
    }
    get formTitle(): string {
      if (this.tabSectionGroup.id) {
        return this.$t('tenant.admin.tab_section_groups.edit.form_title');
      } else {
        return this.$t('tenant.admin.tab_section_groups.new.form_title');
      }
    }
    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.init = value;
      this.validator?.reset();
    }
    onSubFormListSelect(ids: string[]): void {
      this.form = { ...this.form, sub_form_list_ids: ids };
    }

    toggleActive(active: boolean): void {
      const message = this.$t('tenant.admin.tab_section_groups.edit.confirm');
      bootbox.confirm({
        size: 'small',
        message,
        buttons: {
          confirm: { label: this.$t('app.buttons.confirm'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$emit('submit', { ...this.tabSectionGroup, active: active });
          }
        },
      });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.$emit('submit', this.form);
        }
      });
    }

    initForm(): void {
      this.form = { ...this.tabSectionGroup };
    }
  }
