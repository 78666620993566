import type { AutomationDefinition } from '@app/models/automation-definition';
import type { Event } from '@app/models/event';
/**
 * Renders the uniq event types in a sentence and adds a counter to each event type
 * example: if an automation generates 1 Action, 6 different notifications and 2 record updates
 * it would show as ‘Action, Notification (6), Update Record (2)’
 * @param {AutomationDefinition} automationDefinition - Automation Definition object make sure it has the events
 * @returns {string} - "event_type (:count)?, ..."
 */
export const groupAndCountEventTypes = (automationDefinition: Pick<AutomationDefinition, 'events'>): string => {
  if (!automationDefinition.events?.length) return '';

  const mapType = (e: Event) => e.event_type_name;

  const regularEvents = automationDefinition.events.map(mapType);
  const chainedEvents = automationDefinition.events.map((e) => e.chained_events?.map(mapType)).flat();

  const allEventTypes = [...regularEvents, ...chainedEvents].filter(Boolean);

  const counted = allEventTypes.reduce((memo, event) => {
    if (!event) return memo;

    memo[event] ||= 0;
    memo[event]++;

    return memo;
  }, {} as Record<string, number>);

  return Object.keys(counted)
    .map((key) => (counted[key] === 1 ? key : `${key} (${counted[key]})`))
    .join(', ');
};
