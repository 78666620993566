
  import { KLASS_MONGO_TRAILS_VERSION } from '@app/models/super-report';
  import { Component, Prop } from 'vue-property-decorator';
  import { useAccountStore } from '@app/stores/account';
  import SuperReportDataTable from './super-report-data-table.vue';
  import SuperReportDataTab from './super-report-data-tab.vue';
  import SuperReportGraphTab from './super-report-graph-tab.vue';
  import SuperReportTargetsTab from './super-report-targets-tab.vue';
  import { isNull } from 'lodash';
  import { Tab, Tabs } from 'uiv';
  import Blocking from '@app/mixins/blocking';
  import Heatmap from './heatmap.vue';
  import type { LatLng } from '@app/models/geocoder-result';
  import type { ReportBlob, SuperReport, SuperReportGraphData } from '@app/models/super-report';
  import { Tuple } from '@app/utils/types/tuple';

  const KLASS_CALCULATION = 'Calculation';

  const only = Tuple(['report_blob', 'name', 'description'] as const);
  type SuperReportOnly = (typeof only)[number];

  @Component({
    components: { SuperReportDataTable, SuperReportDataTab, SuperReportTargetsTab, SuperReportGraphTab, Tabs, Tab, Heatmap },
  })
  export default class SuperReportPage extends Blocking {
    @Prop() readonly superReportId!: number;
    @Prop() readonly page!: string;

    superReport: Nullable<Pick<SuperReport, SuperReportOnly>> = null;
    superReportGraphData: Nullable<SuperReportGraphData> = null;
    tabClasses: Record<string, boolean> = {
      tab: true,
    };

    get getCurrentPage(): number {
      return this.page ? parseInt(this.page) : 1;
    }

    get reportBlob(): Nullable<ReportBlob> {
      return this.superReport && !isNull(this.superReport) ? this.superReport.report_blob : null;
    }

    get constKlassMongoTailsVersion(): string {
      return KLASS_MONGO_TRAILS_VERSION;
    }

    get constsCheck(): boolean {
      return this.reportBlob ? ![KLASS_MONGO_TRAILS_VERSION, KLASS_CALCULATION].includes(this.reportBlob.klass) : false;
    }

    get target(): boolean {
      return !!this.reportBlob?.graph?.target?.some((i) => !!`${i[1]}`);
    }

    get heatmap(): boolean {
      return this.reportBlob?.graph?.type === 'heatmap';
    }

    get notCalculationKlass(): boolean {
      return this.reportBlob?.klass !== KLASS_CALCULATION;
    }

    get notMongoKlass(): boolean {
      return this.reportBlob?.klass !== KLASS_MONGO_TRAILS_VERSION;
    }

    get coordinates(): LatLng[] {
      if (!this.superReportGraphData?.map_informations) return [];

      return this.superReportGraphData.map_informations
        .filter(([lat, lng]) => lat !== null && lng !== null)
        .map(([lat, lng]) => ({ lat, lng }));
    }

    get accountStore() {
      return useAccountStore();
    }

    getData(): void {
      this.blocking(
        async () => {
          const { data: superReport } = await this.$api.getSuperReport(this.superReportId, { only }, { cache: true });
          const { data: superReportGraphData } = await this.$api.getSuperReportGraphData(this.superReportId);
          this.superReport = superReport;
          this.superReportGraphData = superReportGraphData;
        },
        {
          loading: true,
        }
      );
    }

    beforeMount(): void {
      this.getData();
    }
  }
