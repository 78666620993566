
  import { AutomationDefinitionState, AutomationTriggerOn } from '@app/models/automation-definition';
  import type { AutomationRuleSetBuilder } from '@app/services/api/automation-definition-api';
  import { Component, Model, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
  import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import DsIcon from '@app/components/ds-icon.vue';
  import { DEFAULT_GROUP_LABEL } from '@app/components/ds-query-builder/models/ds-group-node';
  import { ValidationObserver } from 'vee-validate';

  import RuleBuilderPanel from '../events/rule-builder-panel.vue';

  import DsSection from './ds-section.vue';
  import AutomationDetailsPaneSelector from './automation-details-pane-selector.vue';
  import type { Form } from './models';

  const paneId = 'condition-details';

  @Component({
    components: {
      DsSection,
      RuleBuilderPanel,
      AutomationDetailsPaneSelector,
      DsIconText,
      DsIcon,
      ValidationObserver,
    },
  })
  export default class AutomationConditionsForm extends Vue {
    @Ref() readonly ruleBuilderPanel!: RuleBuilderPanel;
    @Ref() readonly validator!: InstanceType<typeof ValidationObserver>;

    @Model() readonly form!: Form;

    @PropSync('selected') selectedPane?: string | null;

    @Prop(Object) readonly automationRuleSetBuilder!: AutomationRuleSetBuilder;
    @Prop(Number) readonly ruleBuilderPanelKey?: number;

    ruleBuilderLoaded = false;
    openRuleBuilderInEditMode = false;
    invalidConditions = false;

    get isSelected() {
      return this.selectedPane === paneId;
    }

    get title() {
      return this.$t('tenant.admin.automation_definitions.form.automation_conditions');
    }

    get hasConditions() {
      return this.form.trigger_on === AutomationTriggerOn.ConditionsMet;
    }

    get isActivating(): boolean {
      return this.form.state === AutomationDefinitionState.activating;
    }

    get firstGroupLabel() {
      return this.existingOrLocalRuleset?.label || DEFAULT_GROUP_LABEL;
    }

    get existingOrLocalRuleset() {
      if (!!this.form.rule_builder_rules) {
        return this.form.rule_builder_rules;
      }

      if (!!this.form.rule_set) {
        return this.form.rule_set?.query_builder_blob;
      }
    }

    get conditionsLabel() {
      return !this.existingOrLocalRuleset ? 'Add Conditions' : this.firstGroupLabel;
    }

    async validate(): Promise<boolean> {
      if (!this.hasConditions) {
        this.invalidConditions = false;
        return true;
      }

      const valid = await this.validator?.validate().then((valid) => {
        this.invalidConditions = !valid;
        return valid;
      });

      return valid;
    }

    select(): void {
      this.selectedPane = paneId;
    }

    trigger(_e: UIEvent | null, type: 'click' | 'focus' = 'click') {
      switch (type) {
        case 'click':
          if (!this.existingOrLocalRuleset) {
            this.ruleBuilderPanel?.toggleEditModal();
          }
          break;
        case 'focus':
          if (!this.isSelected && !this.existingOrLocalRuleset) {
            this.ruleBuilderPanel?.toggleEditModal();
          }
          break;
      }

      if (!this.isSelected) this.select();
    }

    onRuleBuilderChange(value?: QueryBuilderBlob): void {
      this.$$patch(this.form, { rule_builder_rules: value ? value : null });
      this.select();
      this.$nextTick(() => this.validate());
    }

    onRuleBuilderPanelLoaded() {
      this.ruleBuilderLoaded = true;
      this.$emit('rule-builder-loaded', true);
    }

    shouldShowOnTheRight() {
      return this.hasConditions && !this.$route.query.event_id && this.existingOrLocalRuleset;
    }

    onRuleBuilderMounted() {
      if (this.shouldShowOnTheRight()) {
        this.select();
      }
    }
  }
