
  import { Component } from 'vue-property-decorator';
  import BaseShowOptions from './base-show-options';
  import type { ServiceCategory } from '@app/models/service-category';
  import type { CompanyRegisterQuestionOptions } from '@app/models/question-options/company-register-question-options';

  @Component({ components: {} })
  export default class CompanyRegisterShowOptions extends BaseShowOptions<CompanyRegisterQuestionOptions> {
    serviceCategory: Partial<Pick<ServiceCategory, 'id' | 'name'>> = { name: 'N/A' };

    get serviceCategoryId(): Maybe<number> {
      return Number(this.config.service_category_id);
    }

    beforeMount(): void {
      this.serviceCategoryId &&
        this.$api.getServiceCategory(this.serviceCategoryId, { only: ['id', 'name'] }, { join: true, cache: true }).then(({ data }) => {
          this.serviceCategory = data;
        });
    }
  }
