
  import { Component, Vue } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import { UNCATEGORISED } from '@app/models/module-name';
  import { toaster } from '@app/utils/toaster';
  import { changeLocation } from '@app/utils/change-location';

  import ModuleNameForm from './module-name-form.vue';

  @Component({ components: { ModuleNameForm } })
  export default class ModuleNameNewPage extends Vue {
    submitting = false;
    moduleName: Partial<ModuleName> = {
      use_location_permissions: true,
      use_organization_permissions: true,
      solution_type: UNCATEGORISED,
      hierarchy_type: UNCATEGORISED,
      config: {},
    };

    submit(moduleName: ModuleName): void {
      this.submitting = true;

      this.$api
        .createModuleName(moduleName)
        .then(({ data }) => {
          toaster('Module successfully created!');
          changeLocation(`/admin/settings/module_names/${data.id}`);
        })
        .catch(({ data }) => {
          this.submitting = false;
          toaster({ text: data.error, icon: 'error' });
        });
    }
  }
