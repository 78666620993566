
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import type { ConfiguratorFilter } from '@app/models/configurator-filter';
  import type { FilterOption } from '@app/components/admin/questions/filter-configurator/model';
  import type { ModuleName } from '@app/models/module-name';
  import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
  import type { AxiosResponse } from 'axios';
  import type { ActionPriority } from '@app/models/action-priority';
  import { ActivityStatusCodes } from '@app/models/activity';

  import FilterConfigurator from './filter-configurator.vue';

  type AnyFilterOption = FilterOption | FilterOption<ModuleName> | FilterOption<ActionPriority>;

  @Component({ components: { FilterConfigurator } })
  export default class ActionFilterConfigurator extends Vue {
    @Model('input', { type: Array, default: () => [] }) readonly value!: ConfiguratorFilter[];
    @Prop(Boolean) readonly readonly?: boolean;
    @Prop(String) readonly name!: string;
    @Prop(Array) readonly skipFilters?: string[];
    @Prop(Boolean) readonly noRequiredFilters?: boolean;
    @Prop(Boolean) readonly noReverse?: boolean;

    firstClassModuleNames: ModuleName[] = [];

    get configOptions(): AnyFilterOption[] {
      const dayTimeKeys = [
        ['created_at', 'app.labels.created_at'],
        ['approved_at', 'app.labels.approved_at'],
        ['closed_at', 'app.labels.closed_at'],
      ];
      const userFilterKeys = [
        ['user_id', 'app.labels.created_by'],
        ['completed_by_id', 'app.labels.completed_by'],
        ['assignee_id', 'app.labels.assigned_to'],
        ['approved_by_id', 'app.labels.approved_by'],
        ['assigned_approver_id', 'app.labels.assigned_approver'],
      ];
      const dayTimeFilters = dayTimeKeys.map(([key, translation]) => this.dateTimeFilter(key, this.$t(translation)));
      const userFilters = userFilterKeys.map(([key, translation]) => this.userFilter(key, this.$t(translation)));

      let defaultOptions: FilterOption[] = [
        {
          type: 'select',
          label: this.$t('tenant.admin.widgets.types.edit.actions.user_visibility_filter'),
          key: 'scope_filter',
          invertDisabled: true,
          options: [
            { key: 'mine', value: this.$t('tenant.admin.widgets.types.edit.actions.mine') },
            { key: 'created', value: this.$t('tenant.admin.widgets.types.edit.actions.created') },
            { key: 'team', value: this.$t('tenant.admin.widgets.types.edit.actions.team') },
            { key: 'wider_team', value: this.$t('tenant.admin.widgets.types.edit.actions.wider_team') },
            { key: 'all', value: this.$t('tenant.admin.widgets.types.edit.actions.all') },
          ],
        },
        {
          type: 'select',
          label: this.$t('tenant.admin.widgets.types.edit.actions.parent_status_filter'),
          key: 'status_filter',
          invertDisabled: true,
          options: [
            { key: 'open', value: this.$t('app.actions.labels.open') },
            { key: 'all', value: this.$t('app.labels.all') },
            { key: 'closed', value: this.$t('app.actions.labels.closed') },
          ],
        },
        {
          type: 'date',
          label: this.$t('tenant.admin.widgets.types.edit.actions.due_date'),
          key: 'date',
          options: [
            { key: 'today', value: this.$t('app.labels.due_today') },
            { key: 'week', value: this.$t('app.labels.due_within_week') },
            { key: 'month', value: this.$t('app.labels.due_within_month') },
            { key: 'all', value: this.$t('app.labels.due_anytime') },
          ],
        },
        {
          type: 'select',
          label: this.$t('tenant.admin.widgets.types.edit.actions.status_filter'),
          key: 'status',
          invertDisabled: true,
          options: [
            { key: ActivityStatusCodes.InProgress, value: this.$t('activerecord.models.activities.status.open') },
            { key: ActivityStatusCodes.ClosedNotRequiringApproval, value: this.$t('activerecord.models.activities.status.closed') },
            { key: ActivityStatusCodes.DueToday, value: this.$t('activerecord.models.activities.status.due_today') },
            { key: ActivityStatusCodes.OverdueNotRequiringApproval, value: this.$t('activerecord.models.activities.status.overdue') },
            {
              key: ActivityStatusCodes.OverduePendingApproval,
              value: this.$t('activerecord.models.activities.status.overdue_pending_approval'),
            },
            {
              key: ActivityStatusCodes.CompletedPendingApproval,
              value: this.$t('activerecord.models.activities.status.completed_pending_approval'),
            },
            { key: ActivityStatusCodes.ClosedApproved, value: this.$t('activerecord.models.activities.status.closed_and_approved') },
          ],
          multiple: true,
        },
        ...dayTimeFilters,
        ...userFilters,
        {
          type: 'location',
          label: this.$t('app.labels.location'),
          multiple: true,
          key: 'location',
          invertDisabled: true,
        },
      ];

      const moduleNames = [
        {
          type: 'entity',
          method: this.fetchModuleNameOptions,
          label: this.$t('app.labels.type'),
          only: ['name', 'display'],
          labelKey: 'display',
          valueKey: 'name',
          multiple: true,
          key: 'actionable_type_filter',
          filters: { active: true },
        },
      ] as FilterOption<ModuleName>[];

      const actionPriority = [
        {
          type: 'entity',
          method: this.$api.getActionPriorities,
          label: this.$t('app.labels.priority'),
          only: ['id', 'title'],
          labelKey: 'title',
          valueKey: 'id',
          multiple: true,
          allowNull: true,
          key: 'action_priority_id',
          filters: { active: true },
        },
      ] as FilterOption<ActionPriority>[];

      return [...defaultOptions, ...moduleNames, ...actionPriority];
    }

    dateTimeFilter(key: string, label: string): FilterOption {
      return {
        type: 'datetime',
        label,
        key,
        options: [
          { key: 'today', value: this.$t('app.labels.today') },
          { key: 'week', value: this.$t('app.labels.within_last_week') },
          { key: 'month', value: this.$t('app.labels.within_last_month') },
          { key: 'year', value: this.$t('app.labels.within_last_year') },
        ],
      };
    }

    userFilter(key: string, label: string): FilterOption {
      return {
        type: 'user',
        label,
        key,
        multiple: true,
        allowNull: true,
      };
    }

    async fetchModuleNameOptions(options: DonesafeIndexApiOptions<ModuleName>, config?: ApiRequestConfig) {
      const location = { name: 'Location', display: this.$t('app.labels.standalone') };
      const response = await this.$api.getModuleNames(options, config);
      const modifiedData = [location as ModuleName, ...response.data];
      const newResponse: AxiosResponse<ModuleName[]> = {
        ...response,
        data: modifiedData,
      };

      return newResponse;
    }
  }
