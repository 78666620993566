import type { ValidationRule } from 'vee-validate/dist/types/types';
import { API } from '@app/services/api-factory';
import I18n from '../i18n';

type ValidationParams = {
  options: string[];
  type?: 'location' | 'organization';
};

const existsInOptionsOrDbRule: ValidationRule = {
  params: ['options', 'type'],
  async validate(value: string | string[] | number | number[], params: Record<string, unknown> | unknown[]) {
    const { options, type } = params as ValidationParams;

    const stringValue = Array.isArray(value) ? value.map(String) : String(value);
    const invalidOptions = checkStaticOptions(stringValue, options);

    if (!type) {
      return validateResult(invalidOptions);
    } else {
      if (!invalidOptions.length) {
        return true;
      } else {
        const invalidValues = [];
        for (const singleValue of invalidOptions) {
          const result = await hasDbValue(singleValue, type);
          if (!result) invalidValues.push(singleValue);
        }

        return validateResult(invalidValues);
      }
    }
  },
};

const checkStaticOptions = (value: string | string[], options: ValidationParams['options']) => {
  const optionsSet = new Set(options);
  const valuesArray = Array.isArray(value) ? value : [value];
  return valuesArray.filter((singleValue) => !optionsSet.has(singleValue));
};

const validateResult = (invalidValues: string[]) => {
  if (!invalidValues.length) {
    return true;
  } else {
    return I18n.t('components.ds_query_builder.values_not_found', { values: invalidValues.join(', ') });
  }
};

async function hasDbValue(value: string | string[] | number[], type: ValidationParams['type']) {
  let apiCall: ReturnType<(typeof API)['getLocation'] | (typeof API)['getOrganization']>;

  switch (type) {
    case 'location':
      apiCall = API.getLocation(Number(value), {}, { cache: true, join: true });
      break;
    case 'organization':
      apiCall = API.getOrganization(Number(value), {}, { cache: true, join: true });
      break;
    default:
      return false;
  }

  try {
    const { data } = await apiCall;
    return data ? true : false;
  } catch (error) {
    return false;
  }
}

export default existsInOptionsOrDbRule;
