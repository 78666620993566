
  import RecordCalculationTabs from '@app/components/record-calculations/record-calculation-tabs.vue';
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';

  @Component({
    components: {
      DsModal,
      RecordCalculationTabs,
    },
  })
  export default class NewRecordCalculationFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Ref() readonly recordCalculationTabs?: RecordCalculationTabs;

    recordCalculation: Partial<Nullable<RecordCalculation>> = null;
    visible = true;

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-record-calculations',
        params: {
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    beforeMount(): void {
      if (this.moduleNameId) {
        this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true }).then(({ data }) => {
          this.recordCalculation = { module_name_id: Number(this.moduleNameId), module_name: data };
        });
      }
    }
  }
