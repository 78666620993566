
  import { Component, Emit, Model, Vue } from 'vue-property-decorator';
  import DsDropdown from '@app/components/ds-dropdown.vue';

  @Component({ components: { DsDropdown } })
  export default class ActivitiesStatusFilter extends Vue {
    @Model('input') readonly value!: string;

    get options(): Record<string, string> {
      return {
        open: this.$t('app.actions.labels.open'),
        all: this.$t('app.labels.all'),
        closed: this.$t('app.actions.labels.closed'),
      };
    }

    get label(): string {
      return this.options[this.value] || this.options.all;
    }

    @Emit('input')
    selectOption(option: string): string {
      return option;
    }
  }
