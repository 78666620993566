
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { WIDGET_COMPONENTS } from '@app/components/widgets/components';
  import { camelCase, upperFirst } from 'lodash';
  import type { Widget } from '@app/models/widget';

  @Component({ components: { ...WIDGET_COMPONENTS } })
  export default class WidgetShow extends Vue {
    @Prop(Object) readonly widget!: Widget;
    @Prop(Boolean) readonly canEdit?: boolean;
    @Prop(Boolean) readonly isPublicDashboard?: boolean;
    @Prop({ type: [String, Number] }) readonly dashboardPaneId?: string;

    get widgetComponentName(): string {
      return `${upperFirst(camelCase(this.widget.widget_type))}Widget`;
    }

    get editWidgetRouteName(): string {
      switch (this.$route.name) {
        case 'admin-dashboard-pane-edit':
          return 'admin-dashboard-pane-edit-widget';
        case 'admin-module-dashboard-panes-edit':
          return 'admin-module-dashboard-panes-edit-widget';
        default:
          return 'admin-dashboards-edit-widget';
      }
    }
  }
