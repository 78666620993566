
  import EntitySelector from '@app/components/entity-selector.vue';
  import Select2 from '@app/components/select2.vue';
  import type { RecordCalculation } from '@app/models/record-calculation';
  import { CalculationClass, CalculationMethod, RoundingBehaviour } from '@app/models/record-calculation';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import FormField from '../admin/questions/edit/form-field.vue';
  import DsCheckbox from '../ds-checkbox.vue';

  @Component({ components: { FormField, DsCheckbox, Select2, EntitySelector } })
  export default class RecordCalculationValueHandlingForm extends Vue {
    @Model('input') value!: RecordCalculation;
    @Prop(Object) readonly recordCalculation!: Partial<RecordCalculation>;

    get actualRoundingBehaviourOptions(): [string, string][] {
      return Object.keys(RoundingBehaviour).map((key) => {
        const name =
          key === RoundingBehaviour.default
            ? this.$t('components.admin.record_calculations.rounding_behaviours.do_not_round_with_note')
            : this.$t('components.admin.record_calculations.rounding_behaviours.round');
        return [key, name];
      });
    }

    showEmptyHandling(recordCalculation: Partial<RecordCalculation>): boolean {
      return (
        recordCalculation.calculation_class !== CalculationClass.stored && recordCalculation.calculation_method !== CalculationMethod.total
      );
    }

    get isNonRecordCountCalculationMethod(): boolean {
      return this.value.calculation_method !== CalculationMethod.record_count;
    }
  }
