
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import type { ModuleName } from '@app/models/module-name';
  import ModuleNameLeftHandBar from '@app/components/admin/module-names/module-name-left-hand-bar.vue';
  import ModuleNameTabs from '@app/components/admin/module-names/module-name-tabs.vue';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { ModuleNameLeftHandBar, ModuleNameTabs } })
  export default class ModuleNameShowPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;

    moduleName: Nullable<ModuleName> = null;

    get tab() {
      return this.currentTab || 'workflow';
    }

    get currentTab() {
      const path = this.$route.path;
      const regex = new RegExp(`/module_names/${this.moduleNameId}/([^/]+)`);
      const match = path.match(regex);
      return match && match[1];
    }

    @Watch('moduleNameId')
    onModuleNameIdChange() {
      this.fetchModuleName();
    }

    fetchModuleName() {
      this.moduleName = null;
      this.$api
        .getModuleName(Number(this.moduleNameId), { include: ['feature_set'] }, { cache: true })
        .then(({ data }) => {
          this.moduleName = data;
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    beforeMount(): void {
      this.fetchModuleName();
    }
  }
