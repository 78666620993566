import { Tuple } from '@app/utils/types/tuple';
import type { PlaceholderProfile } from '@app/models/placeholder-profile';

export type Oauth2Config = {
  oauth2_auth_type: PlaceholderProfileOauth2AuthType;
  resource?: string;
  scope?: string;
} & (Oauth2ClientSecretFields | Oauth2ClientAssertionFields);
interface Oauth2ClientSecretFields {
  client_id: string;
  client_secret: string;
}
type Oauth2ClientAssertionFields = {
  client_assertion_type: string;
} & Jwt;
interface Jwt {
  jwt_aud: string;
  jwt_iss: string;
  jwt_sub: string;
  jwt_token_ttl: string;
}

export const PLACEHOLDER_FORM_ONLY = Tuple(['id', 'name', 'handle', 'profile_type', 'config', 'placeholder_variables', 'active'] as const);
export type PlaceholderFormOnly = (typeof PLACEHOLDER_FORM_ONLY)[number];
export type PlaceholderProfileForm = Partial<Pick<PlaceholderProfile, PlaceholderFormOnly>> & Oauth2Config;

export enum PlaceholderProfileType {
  global_placeholder = 'global_placeholder',
  oauth2 = 'oauth2',
}
export enum PlaceholderProfileOauth2AuthType {
  client_assertion = 'client_assertion',
  client_secret = 'client_secret',
}
