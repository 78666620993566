
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { isString } from 'lodash';
  import QuestionMapping from '../config/QuestionMapping';
  import type { VariableMap, Variables } from '../models/types';
  import type { Dictionary } from '@app/models/dictionary';
  import type { SubFormQuestion } from '@app/models/sub-form-question';

  interface OptionsForSubFormQuestionType {
    key: string;
    value: string;
    id: number;
    title: string;
    fieldOption: string;
  }

  @Component({ components: {} })
  export default class VariableMapping extends Vue {
    @Prop({ required: true }) readonly variableMapping!: VariableMap;
    @Prop({ required: true }) readonly variables!: Variables;
    @Prop({ required: true }) readonly updateVariableMapping!: (value: string, name: string) => void;
    @Prop({ required: true }) readonly overrideQuestion!: string;
    @Prop({ required: true }) readonly updateOverrideQuestion!: (value: string) => void;
    @Prop({ required: true }) readonly calcValueSubFormQuestions!: SubFormQuestion[];

    mappingOptionsForSubFormQuestionType(calcValueSubFormQuestion: SubFormQuestion, key: number): OptionsForSubFormQuestionType[] {
      // return an array of options for each subform question type
      const { id, field_type, title } = calcValueSubFormQuestion;
      const fieldOptions = this.getFieldOptionsForSubFormQuestionType(field_type);

      const optionsForSubFormQuestionType = fieldOptions.map((fieldOption: string) => {
        const subFormQuestionMapping = {
          id,
          fieldType: field_type,
          fieldOption,
        };

        return {
          key: key + '-' + fieldOption,
          value: JSON.stringify(subFormQuestionMapping),
          id,
          title,
          fieldOption,
        };
      });

      return optionsForSubFormQuestionType;
    }

    getFieldOptionsForSubFormQuestionType(fieldType: string): string[] {
      const FIELD_OPTIONS: Dictionary<string[]> = {};
      const questionMappingObject = QuestionMapping();

      Object.keys(questionMappingObject).map((questionType) => {
        FIELD_OPTIONS[questionType] = [];
        Object.keys(questionMappingObject[questionType]).map((valueType) => FIELD_OPTIONS[questionType].push(valueType));
      });

      return FIELD_OPTIONS[fieldType];
    }

    variableMappingValue(elementId: string): string {
      const value = this.variableMapping[elementId];
      return isString(value) ? value : JSON.stringify(value);
    }

    get generatedMappingOptions(): OptionsForSubFormQuestionType[] {
      const allowedQuestionTypes = Object.keys(QuestionMapping());
      const mappableSubFormQuestions = this.calcValueSubFormQuestions.filter(
        (subFormQuestion: SubFormQuestion) => allowedQuestionTypes.indexOf(subFormQuestion.field_type) >= 0
      );

      const mappingOptions = mappableSubFormQuestions.map((calcValueSubFormQuestion: SubFormQuestion, i: number) => {
        return this.mappingOptionsForSubFormQuestionType(calcValueSubFormQuestion, i);
      });

      // flatten any sub arrays
      return mappingOptions.flat();
    }
  }
