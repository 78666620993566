
  import DsModal from '@app/components/ds-modal.vue';
  import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

  import EntitySelector from '../../entity-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import AppBundleMetadata from './app-bundle-metadata.vue';
  import AppBundleInstallationErrors from '@app/components/admin/app-bundles/app-bundle-installation-errors.vue';
  import type { AppBundleInstallation } from '@app/models/app-bundles/installation';

  @Component({
    components: {
      AppBundleInstallationErrors,
      EntitySelector,
      DsModal,
      ValidationObserver,
      ValidationProvider,
      AppBundleMetadata,
    },
  })
  export default class AppBundleInstallationFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    $refs!: { fileField: HTMLFormElement };
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    file: Nullable<File> = null;

    @Prop(String) readonly mode!: 'create' | 'errors' | 'metadata';
    @Prop(Object) readonly appBundleInstallation!: AppBundleInstallation;

    beforeMount(): void {
      this.initForm();
    }

    initForm(): void {
      this.file = null;
    }

    submitForm(): void {
      const formData = new FormData();
      formData.append('file', this.$refs.fileField.files[0]);

      this.$nextTick(async () => {
        const valid = await this.validator?.validate();

        if (valid) this.$emit('submit', formData);
      });
    }

    async submitMetadata(): Promise<void> {
      const valid = await this.validator?.validate();

      if (valid) this.$emit('startCopy', this.appBundleInstallation);
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.$nextTick(() => {
        this.validator?.reset();
      });
    }

    get title(): string {
      return this.$t(`app.labels.app_bundles.installations.${this.mode}`);
    }
  }
