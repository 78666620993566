
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import HideQuestion from '@app/components/admin/questions/settings/shared/hide-question.vue';
  import ScoredQuestionConfig from '@app/components/admin/questions/settings/shared/scored-question-config.vue';
  import EntitySelector from '@app/components/entity-selector.vue';
  import FormField from '../edit/form-field.vue';
  import type ModuleFeatures from '@app/components/admin/questions/edit/module-features';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { ScormQuestionOptions } from '@app/models/question-options/scorm-question-options';

  @Component({
    components: { HideQuestion, ScoredQuestionConfig, EntitySelector, FormField },
  })
  export default class ScormFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<ScormQuestionOptions>;

    @Prop(Object) subForm!: SubForm;
    @Prop(Object) readonly features!: ModuleFeatures;

    beforeDestroy() {
      this.scrubFields();
    }

    scrubFields() {
      ['scorm_course_id', 'involvement_id'].forEach((key) => this.$delete(this.value.config, key));
    }
  }
