
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Tabs, Tab } from 'uiv';

  interface TabDetail {
    routeCommonKey: string;
    routeName: string;
    title: string;
  }

  @Component({
    components: { Tabs, Tab },
  })
  export default class ModuleScoringPage extends Vue {
    @Prop([String, Number]) readonly moduleNameId!: string | number;
    tabIndex: number | null = null;

    get tabs(): TabDetail[] {
      return [
        {
          routeName: 'admin-settings-module-name-scoring-score-bands',
          title: this.$t('tenant.admin.module_names.sections.tab_module_scoring.score_bands'),
          routeCommonKey: 'score-bands',
        },
        {
          routeName: 'admin-settings-module-name-scoring-scoring-groups',
          title: this.$t('tenant.admin.module_names.sections.tab_module_scoring.score_groups'),
          routeCommonKey: 'scoring-groups',
        },
        {
          routeName: 'admin-settings-module-name-scoring-module-scoring',
          title: this.$t('tenant.admin.module_names.sections.tab_module_scoring.module_scoring'),
          routeCommonKey: 'module-scoring',
        },
      ];
    }

    beforeChangeTab(indexFrom: number, indexTo: number, done: (err?: unknown) => void): void {
      const toRoute = {
        name: this.tabs[indexTo].routeName,
        params: { moduleNameId: `${this.moduleNameId}` },
      };

      this.$router.push(toRoute);
      done();
    }

    beforeMount(): void {
      const indexTo = this.tabs.findIndex((tab) => this.$route?.name?.includes(tab.routeCommonKey));
      this.tabIndex = indexTo >= 0 ? indexTo : 2;
    }
  }
