
  import { Tabs, Tab } from 'uiv';
  import { Component, Vue } from 'vue-property-decorator';
  import LocationNewForm from '../../../components/location/location-new-form.vue';
  import type { Location } from '@app/models/location';
  import { toaster } from '@app/utils/toaster';

  @Component({ components: { LocationNewForm, Tabs, Tab } })
  export default class AdminSettingsLocationNew extends Vue {
    form: Partial<Location> = {};

    submit(location: Partial<Location>): void {
      this.$api
        .createLocation(location)
        .then(() => {
          this.$api.cache.clear(); // reload index table
          this.$router.replace({
            name: 'admin-settings-locations-index',
            query: this.$route.query,
          });
        })
        .catch(({ data }) => toaster({ text: data?.error, icon: 'error' }));
    }
  }
