
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import type { ScheduledEvent } from '@app/models/scheduled-event';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import type { EventOccurrencesExtraFilters } from '@app/services/api/event-occurrences-api';
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import type { AxiosPromise } from 'axios';
  import type { EventOccurrence } from '@app/models/event-occurrence';
  import { formatCreatedAt } from '@app/utils/format-created-at';
  import { startCase } from 'lodash';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerField } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { Tooltip } from 'uiv';
  import bootbox from 'bootbox';
  import { toaster } from '@app/utils/toaster';
  import type { Event } from '@app/models/event';

  import SearchInput from '../../../components/search-input.vue';
  import RecordIndexPageFiltersLayout from '../../../components/record-index-page-filters-layout.vue';
  import TriggerSourceFilter from '../../../components/filters/trigger-source-filter.vue';
  import GlobalDateFilter from '../../../components/filters/global-date-filter.vue';
  import { BaseTable } from '../../../components/base-table';
  import { DropdownSelect, FilterSelect, Select2 } from '../../../components';

  import ParentTypeRow from './parent-type-row.vue';
  import MessageCell from './message-cell.vue';

  @Component({
    methods: { formatCreatedAt },
    components: {
      BaseTable,
      DropdownSelect,
      FilterSelect,
      GlobalDateFilter,
      ParentTypeRow,
      RecordIndexPageFiltersLayout,
      SearchInput,
      Select2,
      TriggerSourceFilter,
      MessageCell,
      Tooltip,
    },
  })
  export default class EventOccurrences extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly eventIds!: number[];
    @Prop(Boolean) readonly hideTriggeredFrom?: boolean;
    @Prop(Boolean) readonly useHistory?: boolean;
    @Prop(String) readonly tab?: string;
    @Prop(Boolean) readonly isMasterEventLog?: boolean;
    @Prop({ type: Object, default: () => {} }) readonly eventFilters!: DonesafeFilterOptions<Event>;
    @Ref() readonly table?: BaseTable<EventOccurrence>;

    allAutomations: Pick<AutomationDefinition, 'id' | 'name'>[] = [];
    allScheduledEvents: Pick<ScheduledEvent, 'id' | 'name'>[] = [];
    manager: Nullable<ListManager<EventOccurrence, EventOccurrencesExtraFilters>> = null;
    initialized = false;
    resultOptions = [
      ['true', this.$t('app.labels.pass')],
      ['false', this.$t('app.labels.fail')],
      ['pending', this.$t('app.labels.pending')],
      ['retrying', this.$t('app.labels.retrying')],
    ];

    triggeredFromOptions: string[][] = [];
    iteratedOverOptions: string[][] = [];
    itemOptions: string[][] = [];
    eventIdNames: Record<string, string> = {};
    eventTypes: Record<string, string> = {};
    showAdvancedIdFilters = false;
    showTriggeredFrom = true;

    get eventIdOptions(): string[][] {
      return Object.entries(this.eventIdNames);
    }

    get eventTypeOptions(): string[][] {
      return Object.entries(this.eventTypes);
    }

    get showTriggerSourceFilter(): boolean {
      return !this.eventIds.length;
    }

    get fields(): ListManagerField<EventOccurrence>[] {
      const fields: ListManagerField<EventOccurrence>[] = [
        {
          title: this.$t('tenant.admin.event_occurrences.index.event_group'),
          name: 'event_group_uuid',
          filter: true,
          group: 'event_group_uuid',
        },
        { title: this.$t('app.labels.ID'), name: 'id', group: 'id' },
        { title: this.$t('tenant.admin.event_occurrences.index.result'), name: 'success', filter: true, group: 'head' },
        {
          title: this.$t('app.labels.message'),
          name: 'message',
          filter: true,
          width: '200px',
          titleClass: 'wrap-column-title',
          group: 'head',
        },
        {
          title: this.$t('tenant.admin.event_occurrences.index.triggered_from'),
          name: 'triggered_from',
          filter: true,
          group: 'triggered_from',
        },
        {
          title: this.$t('tenant.admin.event_occurrences.index.iterated_over'),
          name: 'iterated_over',
          filter: true,
          group: 'iterated_over',
        },
        {
          title: this.$t('tenant.admin.event_occurrences.index.event_type_of_config'),
          name: 'event_type',
          filter: true,
          group: 'event_type',
        },
        {
          title: this.$t('tenant.admin.event_occurrences.index.event'),
          name: 'event_id',
          filter: true,
          group: 'event_type',
        },
        { title: this.$t('app.labels.item'), name: 'item', filter: true, group: 'item' },
        { title: this.$t('app.labels.linked_to'), name: 'linked_to', group: 'tail', filter: true },
        { title: this.$t('tenant.admin.event_occurrences.index.date_time'), name: 'created_at', sortField: 'created_at', group: 'tail' },
        {
          title: this.$t('tenant.admin.event_occurrences.index.parent_event'),
          name: 'parent_occurrence_id',
          filter: true,
          group: 'parent_occurrence_id',
        },
      ];

      return fields
        .filter((field) => this.showTriggeredFrom || field.name !== 'triggered_from')
        .filter((f) => this.isMasterEventLog || f.name !== 'linked_to');
    }

    get accountStore() {
      return useAccountStore();
    }

    get linkedToOptions(): { group: string; key: string; label: string }[] {
      return [
        ...this.allAutomations.map(({ id, name }) => ({
          key: `AutomationDefinition|${id}`,
          label: `${name} [${id}]`,
          group: this.$t('app.labels.automation'),
        })),
        ...this.allScheduledEvents.map(({ id, name }) => ({
          key: `ScheduledEvent|${id}`,
          label: `${name} [${id}]`,
          group: this.$t('app.labels.scheduler'),
        })),
      ];
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    @Watch('fields')
    onFieldsChanged(fields: ListManagerField<EventOccurrence>[]) {
      this.$nextTick(() => {
        if (this.manager) this.manager.fields = fields;
      });
    }

    async fetchEventTypeFilters(): Promise<void> {
      const { data: eventTypes } = await this.$api.getAllEventTypeFilters({ cache: true });
      this.eventTypes = eventTypes;
    }

    async fetchEventIdFilters(): Promise<void> {
      const { data } = await this.$api.getEvents(
        { only: ['id', 'name'], filters: { ...this.eventFilters, with_event_occurrences: true }, per_page: -1 },
        { cache: true }
      );
      this.eventIdNames = data.reduce((memo: Record<string, string>, d) => {
        memo[d.id] = `[${d.id}] ${d.name || ''}`;
        return memo;
      }, {});
    }

    getManager(): ListManager<EventOccurrence, EventOccurrencesExtraFilters> {
      return new ListManager({
        fetchDataFunction: (params): AxiosPromise<EventOccurrence[]> => {
          const include = [
            'actioned_title',
            'automation_definition',
            'parent_automation_definition',
            'occurrence_event',
            'per_title',
            'scheduled_events',
            'triggered_title',
            'latest_retry',
            'user',
          ];
          const only: OnlyOptions<EventOccurrence> = [
            'actioned_at',
            'actioned_id',
            'actioned_type',
            'created_at',
            'event_id',
            'event_type',
            'message',
            'parent_occurrence_id',
            'per_id',
            'per_type',
            'id',
            'retryable',
            'success',
            'state',
            'event_group_uuid',
            'triggered_id',
            'triggered_type',
            {
              event: [
                'id',
                'name',
                'deleted',
                'options',
                this.isMasterEventLog
                  ? {
                      automation_definition: ['id', 'name'],
                      parent_automation_definition: ['id', 'name'],
                      scheduled_events: ['id', 'name'],
                      parent_scheduled_events: ['id', 'name'],
                    }
                  : {},
              ],
              user: ['id', 'full_name'],
              latest_retry: ['id', 'created_at', { user: ['id', 'full_name'] }],
            },
          ];
          const defaultFilters: Record<string, string | string[]> = { created_at: 'all' };
          if (this.eventIds.length > 0) {
            defaultFilters['event_id'] = this.eventIds.map((id) => `${id}`);
          }
          return this.$api.getEventOccurrences(
            { ...params, filters: { ...defaultFilters, ...params.filters }, include, only },
            { cache: true }
          );
        },
        useHistory: this.useHistory,
        per_page: 25,
        sortOrder: [{ direction: 'desc', field: 'created_at', sortField: 'created_at' }],
        fields: this.fields,
        allowFilters: true,
      });
    }

    gridColumnStyleFor(group: string): Record<string, string> {
      const size = this.fields.filter((f) => f.group === group).length;

      return {
        gridColumn: `span ${size}`,
      };
    }

    linkTo(eventOccurrence: EventOccurrence): { icon: string; id: number; name: string; path: string; typeName: string } | undefined {
      if (!!eventOccurrence.event?.automation_definition) {
        return {
          typeName: this.$t('app.labels.automation'),
          icon: 'flash_auto',
          path: this.automationDefinitionPath(eventOccurrence.event.automation_definition.id),
          id: eventOccurrence.event.automation_definition.id,
          name: eventOccurrence.event.automation_definition.name,
        };
      }
      if (!!eventOccurrence.event?.parent_automation_definition) {
        return {
          typeName: this.$t('app.labels.automation'),
          icon: 'flash_auto',
          path: this.automationDefinitionPath(eventOccurrence.event.parent_automation_definition.id),
          id: eventOccurrence.event.parent_automation_definition.id,
          name: eventOccurrence.event.parent_automation_definition.name,
        };
      }
      if (eventOccurrence.event?.scheduled_events?.length) {
        return {
          typeName: this.$t('app.labels.scheduler'),
          icon: 'access_time',
          path: this.scheduledEventPath(eventOccurrence.event.scheduled_events[0].id),
          id: eventOccurrence.event.scheduled_events[0].id,
          name: eventOccurrence.event.scheduled_events[0].name,
        };
      }
      if (eventOccurrence.event?.parent_scheduled_events?.length) {
        return {
          typeName: this.$t('app.labels.scheduler'),
          icon: 'access_time',
          path: this.scheduledEventPath(eventOccurrence.event.parent_scheduled_events[0].id),
          id: eventOccurrence.event.parent_scheduled_events[0].id,
          name: eventOccurrence.event.parent_scheduled_events[0].name,
        };
      }
    }

    eventType(eventOccurrence: EventOccurrence): Maybe<string> {
      const rawEventType = this.rawEventType(eventOccurrence);
      return rawEventType ? this.eventTypes[rawEventType] : this.$t('app.labels.na');
    }

    rawEventType(eventOccurrence: EventOccurrence): Maybe<string> {
      if (eventOccurrence.event_type) return eventOccurrence.event_type;

      return eventOccurrence.event?.event_type;
    }

    scheduledEventPath(id: number): string {
      return `/admin/settings/scheduled_events/${id}/edit`;
    }

    automationDefinitionPath(id: number): string {
      return `/admin/settings/automation_definitions/${id}/edit`;
    }

    display(type: string): string {
      switch (type) {
        case 'Activity':
          return 'Action';
        case 'TenantUser':
        case 'User':
          return 'User';
        case 'UserInvolvement':
          return 'Involvement';
        case 'RecordRelation':
          return 'Relationship';
        case 'RegulatoryReport':
          return 'Regulatory Report';
        case 'SubFormCompletion':
          return 'Sub Form';
        default: // 'MedicalCertificate', 'IncidentParticipant', 'Document'
          return startCase(type);
      }
    }

    onTriggerSourceChanged(concept: string): void {
      if (!this.manager) return;

      this.initTriggeredFrom();
      if (concept) {
        this.manager.setFilter('triggered_concept', concept);
      } else {
        this.manager.setFilter('triggered_concept', undefined);
      }
    }

    onAdvancedIdFilters(): void {
      if (this.showAdvancedIdFilters && this.manager) {
        this.manager.setFilter('actioned_id', undefined);
        this.manager.setFilter('event_group_uuid', undefined);
        this.manager.setFilter('triggered_id', undefined);
        this.manager.setFilter('per_id', undefined);
        this.manager.setFilter('id', undefined);
        this.manager.setFilter('parent_occurrence_id', undefined);
      }
      this.showAdvancedIdFilters = !this.showAdvancedIdFilters;
    }

    async fetchFilters(): Promise<void> {
      const { data: attributes } = await this.$api.getEventOccurrenceAttributes({}, { cache: true });
      this.triggeredFromOptions = attributes.triggered_types.map((type) => [
        [type.type, type.id].filter(Boolean).join('|'),
        this.display(type.type),
      ]);
      this.iteratedOverOptions = attributes.per_types.map((type) => [
        [type.type, type.id].filter(Boolean).join('|'),
        this.display(type.type),
      ]);
      this.itemOptions = attributes.actioned_types.map((type) => [[type.type, type.id].filter(Boolean).join('|'), this.display(type.type)]);
      await this.fetchEventTypeFilters();
      await this.fetchEventIdFilters();
    }

    initTriggeredFrom(): void {
      this.showTriggeredFrom = !this.hideTriggeredFrom;
    }

    beforeMount(): void {
      this.manager = this.getManager();
      this.initTriggeredFrom();
      this.fetchFilters().finally(() => (this.initialized = true));
      if (this.isMasterEventLog) {
        this.$api.getAutomationDefinitions({ only: ['id', 'name'], per_page: -1 }, { cache: true }).then(({ data }) => {
          this.allAutomations = data;
        });
        this.$api.getScheduledEvents({ only: ['id', 'name'], per_page: -1 }, { cache: true }).then(({ data }) => {
          this.allScheduledEvents = data;
        });
      }
    }

    retryOccurrence(eventOccurrence: EventOccurrence): void {
      bootbox.confirm({
        size: 'small',
        message: this.$t('tenant.admin.event_occurrences.index.retry_confirm'),
        buttons: {
          confirm: { label: this.$t('tenant.admin.event_occurrences.index.retry'), className: 'btn-success' },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default' },
        },
        callback: (result: boolean) => {
          if (result) {
            this.$api.retryEventOccurrence(eventOccurrence, { include: ['latest_retry', 'user'] }).then(({ data }) => {
              if (data.latest_retry && data.latest_retry.id !== eventOccurrence.latest_retry?.id) {
                eventOccurrence.state = data.state;
                eventOccurrence.latest_retry = data.latest_retry;
                eventOccurrence.retryable = false;
              } else {
                const text = this.$t('tenant.admin.event_occurrences.index.could_not_retry');
                toaster({ text, position: 'top-right', icon: 'error' });
              }
            });
          }
        },
      });
    }
  }
