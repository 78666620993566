
  import { Component } from 'vue-property-decorator';
  import { v4 as generateUUID } from 'uuid';
  import type { TextWidgetOptions } from '@app/models/widget';

  import BaseWidget from './base-widget';

  @Component({})
  export default class TextWidget extends BaseWidget<TextWidgetOptions> {
    uuid = generateUUID();

    get widgetId(): string {
      return `widget_${this.uuid}`;
    }

    get textStyle(): Record<string, string> {
      return {
        'text-align': this.widget.options.alignment,
        'font-size': this.widget.options.font_size,
        'font-weight': this.widget.options.font_weight,
      };
    }

    get overrideStyleHtml(): string {
      return `
        @media (min-width: 779px) {
          #${this.widgetId} p { ${this.widget.options.overrides?.text_style} }
       }
      `;
    }

    get text(): string | undefined {
      const translatedText = this.widget?.translated_options?.text;
      if (translatedText) {
        const finalText = translatedText
          ?.replace(/\r\n?/g, '\n')
          ?.split(/\n\n+/)
          ?.map((paragraph) => {
            return `<p>${paragraph.replace(/\n/g, '<br>')}</p>`;
          })
          ?.join('\n\n');
        return this.$adminSanitize(finalText);
      }
    }
  }
