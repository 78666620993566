import { SubFormListType } from '@app/models/sub-form-list';
import I18n from '../i18n';

export const completeLabel = (type?: SubFormListType): string => {
  if (type === SubFormListType.approval) {
    return I18n.t('app.labels.initial_approval_stage').toString();
  } else if (type === SubFormListType.workflow) {
    return I18n.t('app.labels.starting_workflow_state').toString();
  }
  return I18n.t('app.labels.complete').toString();
};
