
  import { Component, Prop } from 'vue-property-decorator';
  import { BaseTable } from '@app/components/base-table';
  import BaseRoleTable from './base-role-table';
  import type { RoleTabDetailsRoleOnly } from './utils';
  import CustomiseRightsModal from './customise-rights-modal.vue';
  import RelationshipRightsTable from './relationship-rights-table.vue';
  import SimpleGridTable from '@app/components/simple-grid-table/simple-grid-table.vue';
  import type { ExtendedPermission } from '@app/models/extended-permission';
  import type { ModuleName } from '@app/models/module-name';
  import type { Permission } from '@app/models/permission';
  import type { Role } from '@app/models/role';
  import { ListManagerStatic } from '@app/services/list-manager/list-manager-static';
  import { ACTIVITY_CONCEPT } from '@app/constants';

  @Component({ components: { BaseTable, CustomiseRightsModal, RelationshipRightsTable, SimpleGridTable } })
  export default class ActionRoleTable extends BaseRoleTable {
    @Prop(Object) readonly role!: Pick<Role, RoleTabDetailsRoleOnly>;
    @Prop(String) readonly title!: string;
    @Prop(Boolean) readonly disabled?: boolean;

    get moduleNameStrings(): string[] {
      return [this.moduleName.name];
    }

    get extendedPermissions(): Pick<ExtendedPermission, 'concept_name' | 'name'>[] {
      return this.extendedPermissionsFor(this.moduleName.name);
    }

    get extendedPermissionTitles(): string[] {
      return this.extendedPermissionTitlesFor(this.moduleName.name);
    }

    get permissions(): Permission[] {
      return this.permissionsFor(this.moduleName.name);
    }

    get moduleName(): ModuleName {
      return { name: ACTIVITY_CONCEPT } as ModuleName;
    }

    beforeMount(): void {
      this.manager = new ListManagerStatic<ModuleName>({
        localData: [this.moduleName],
        fields: [...this.baseFields, ...this.permissionsFields],
      });
    }
  }
