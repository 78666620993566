import { Component, Prop } from 'vue-property-decorator';
import type DashboardPane from '@app/models/dashboard-pane';
import { mixins } from 'vue-class-component';
import Blocking from '@app/mixins/blocking';
import WithBootbox from '@app/components/admin/user-collections/with-bootbox';
import { DashboardPaneSize } from '@app/models/dashboard-pane';

@Component
export default class WithDashboardPaneForm extends mixins(Blocking, WithBootbox) {
  @Prop({ type: [String, Number] }) readonly moduleNameId?: number | string;

  showModal = false;
  form: Partial<DashboardPane> = {};

  addNew(): void {
    this.form = {
      module_name_id: (this.moduleNameId && Number(this.moduleNameId)) || undefined,
      size: DashboardPaneSize.standard,
      active: true,
    };

    this.showModal = true;
  }

  async loadAndEdit(id: DashboardPane['id']): Promise<void> {
    const { data } = await this.$api.getDashboardPane(id, { include: 'role_ids' });
    this.edit(data);
  }

  edit(entity: DashboardPane): void {
    this.form = entity;
    this.showModal = true;
  }

  closeModal(refresh: boolean = true) {
    this.showModal = false;

    if (refresh) {
      this.refresh();
    }
  }

  async toggleActive(entity: DashboardPane) {
    await this.save({ id: entity.id, active: !entity.active });
    this.refresh();
  }

  async delete_(entity: DashboardPane): Promise<void> {
    await this.blocking(
      async () => {
        if (!(await this.confirm(this.$t('tenant.admin.dashboard_panes.delete_confirmation'), { backdrop: !this.showModal }))) return;

        await this.$api.deleteDashboardPane(entity.id);
        await this.saveIndexes();
        this.closeModal(false);
      },
      { exclusive: true }
    );
  }

  async save(form: Partial<DashboardPane>, editDashboard: boolean = false): Promise<void> {
    await this.blocking(async () => {
      const { data: updated } = await (form.id ? this.$api.updateDashboardPane(form.id, form) : this.$api.createDashboardPane(form));

      this.form = { ...updated };

      this.closeModal();

      if (editDashboard) {
        this.editDashboard(updated);
      }
    });
  }

  editDashboard(entity: DashboardPane): void {
    this.$router.push({
      name: this.moduleNameId ? 'admin-module-dashboard-panes-edit' : 'admin-dashboard-pane-edit',
      params: { id: `${entity.id}`, ...(this.moduleNameId ? { moduleNameId: `${this.moduleNameId}` } : {}) },
    });
  }

  refresh() {
    console.error('Not Implemented');
  }

  saveIndexes() {
    console.error('Not Implemented');
  }
}
