import Blocking from '@app/mixins/blocking';
import type { SubFormList } from '@app/models/sub-form-list';
import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
import { Mixins, Prop } from 'vue-property-decorator';

export default class BaseSubFormListPage extends Mixins(Blocking) {
  @Prop({ type: [Number, String] }) readonly subFormListId?: number | string;
  subFormList: SubFormList | null = null;
  closeModal(command: ModalCloseCommand): void {
    if (this.$route.params.moduleNameId) {
      this.$router.replace({
        name: 'admin-module-tabs-view-page',
        params: {
          moduleNameId: this.$route.params.moduleNameId,
          moduleTabId: `${this.subFormList?.module_tab_id}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    } else {
      this.$router.replace({
        name: 'admin-module-tab-redirect',
        params: { moduleTabId: `${this.subFormList?.module_tab_id}`, reloadTable: command },
        query: this.$route.query,
      });
    }
  }
}
