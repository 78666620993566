
  import { groupBy } from 'lodash';
  import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
  import { isSortable, SORTABLE_TYPES } from '@app/services/model-helpers';
  import Select2 from '../../../select2.vue';
  import FormField from '../edit/form-field.vue';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SortOption } from '@app/models/question-options/sort-option';
  import { DEFAULT_SORT_OPTION } from '@app/models/question-options/sort-option';

  interface GroupedOption {
    group?: string;
    label: string;
    value: string;
  }

  const wrapSortOptions = (sortOptions: SortOption[]): string => {
    return sortOptions
      .map((option) => {
        const prefix = option.direction === 'desc' ? '-' : '';
        return `${prefix}${option.field}`;
      })
      .join(',');
  };

  const unwrapSortOptions = (sortQuery: string): SortOption[] => {
    return sortQuery.split(',').map((query) => ({
      direction: query.startsWith('-') ? 'desc' : 'asc',
      field: query.replace('-', ''),
    }));
  };

  @Component({ components: { Select2, FormField } })
  export default class SortConfigurator extends Vue {
    @Model('input') readonly value?: string;

    @Prop(Object) readonly subForm!: SubForm;
    @Prop(Object) readonly question!: SubFormQuestion;
    @Prop(Boolean) readonly multiple?: boolean;
    @Prop(Boolean) readonly excludeRecordOpts?: boolean;

    form: SortOption[] = [];
    subFormQuestions: SubFormQuestion[] = [];

    async beforeMount(): Promise<void> {
      // Unwrap sort options if a value is present.
      if (this.value) {
        this.form = unwrapSortOptions(this.value);
      }
      // Set the default sort option if excludeRecordOpts is not set.
      else if (!this.excludeRecordOpts) {
        this.form = [DEFAULT_SORT_OPTION];
      }

      const { data: subFormQuestions } = await this.$api.getSubFormQuestions(
        {
          filters: {
            active: true,
            field_type: SORTABLE_TYPES,
            '!id': this.question.id,
            sub_form_section: {
              sub_form_id: this.subForm.id,
            },
          },
          per_page: -1,
          only: ['code', 'title', 'config', 'field_type'],
        },
        { cache: true }
      );

      this.subFormQuestions = subFormQuestions;

      // If value is not present, excludeRecordOpts is set, and subFormQuestions has data,
      // set the form option to the first subform question.
      if (!this.value && this.excludeRecordOpts && this.subFormQuestions.length > 0) {
        const firstQuestion = this.subFormQuestions[0];
        this.form = [{ field: firstQuestion.code, direction: DEFAULT_SORT_OPTION.direction }];
      }
    }

    @Watch('form', { deep: true })
    pushUpdate() {
      this.$emit('input', wrapSortOptions(this.form));
    }

    templateSelection(result: { id: string; text: string }): JQuery {
      const option = this.sortByOptions.find((option) => result.id === option.value);
      const text = option ? `${option.group}: ${option.label}` : result.text;

      return $(
        `<div style="display:inline-block;float:left;padding-right:3px">
            <p style="margin-bottom:0;">${text}</p>
         </div>`
      );
    }

    addOption() {
      this.form = [...this.form, DEFAULT_SORT_OPTION];
    }

    removeOption(index: number) {
      this.form = [...this.form.filter((_value, i) => i !== index)];
    }

    get sortDirectionOptions(): [string, string][] {
      return [
        ['asc', this.$t('app.labels.sort_asc') as string],
        ['desc', this.$t('app.labels.sort_desc') as string],
      ];
    }

    get baseRecordOptions(): [string, string][] {
      return [
        ['id', this.$t('app.labels.ID')],
        ['title', this.$t('app.labels.title')],
        ['score', this.$t('app.labels.score')],
        ['created_at', this.$t('app.labels.created_at')],
        ['created_by', this.$t('app.labels.created_by')],
        ['score_band', this.$t('app.labels.score_band')],
        ['location', this.$t('app.labels.location')],
        ['organization', this.$t('app.labels.organization')],
        ['state', this.$t('app.labels.workflow')],
      ];
    }

    get recordOptions(): GroupedOption[] {
      return this.baseRecordOptions.map(([value, label]) => ({
        group: this.$t('app.labels.record'),
        value,
        label,
      }));
    }

    get subFormOptions(): GroupedOption[] {
      const questionsByCode = groupBy(this.subFormQuestions.filter(isSortable), 'code');
      return Object.keys(questionsByCode).map((code) => ({
        group: this.$t('app.labels.form'),
        value: code,
        label: questionsByCode[code].map((question) => question.title).join(' | '),
      }));
    }

    get sortByOptions(): GroupedOption[] {
      return [...(this.excludeRecordOpts ? [] : this.recordOptions), ...this.subFormOptions];
    }
  }
