import type { RuleSetFilter } from '@app/models/rule-set-filter';
import { orderBy } from 'lodash';
import { OPERATORS_BY_TYPE, types } from './defaults';

export const getApplicableOperators = (filter: RuleSetFilter) => {
  const applicableOperators = (Object.keys(OPERATORS_BY_TYPE) as (keyof typeof OPERATORS_BY_TYPE)[]).filter((type) => {
    if (filter?.operators) {
      return filter.operators.includes(type);
    } else if (filter?.type) {
      return OPERATORS_BY_TYPE[type].apply_to.includes(types?.[filter.type as keyof typeof types] || '');
    }
    return true;
  });

  return orderBy(applicableOperators, (operator) => OPERATORS_BY_TYPE[operator]?.index);
};
