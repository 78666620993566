
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import EntitySelector from '@app/components/entity-selector.vue';
  import { get } from 'lodash';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubForm } from '@app/models/sub-form';
  import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
  import type { AutomationDefinition } from '@app/models/automation-definition';
  import { CustomScoring } from '@app/models/sub-form-options';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { Select2Placeholder } from '@app/utils/types/select2-placeholder';
  import { select2ResponseTemplate } from '@app/utils/select2-response-template';

  import CustomScoringPanel from '../scoring-custom/custom-scoring-panel.vue';

  @Component({ components: { Select2, CustomScoringPanel, ValidationObserver, ValidationProvider, EntitySelector } })
  export default class QuestionSetForm extends Vue {
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Prop(Object) readonly questionSet!: SubFormQuestionSet;
    @Prop(String) readonly moduleName!: string;

    isCustomScoringEnabled = false;
    form: Partial<SubFormQuestionSet> = {};

    get relatedQuestionFilters(): DonesafeFilterOptions<SubFormQuestion> {
      return {
        active: true,
        sub_form_section: {
          sub_form: {
            module_name: this.moduleName,
          },
        },
      };
    }

    get relatedAutomationDefinitionFilters(): DonesafeFilterOptions<
      AutomationDefinition & {
        sub_form?: Partial<SubForm>;
      }
    > {
      return {
        sub_form: {
          module_name: this.moduleName,
        },
      };
    }

    questionItemTemplateResult(question: Pick<SubFormQuestion, 'id' | 'title'>): JQuery {
      return select2ResponseTemplate(question, {
        primaryAttribute: (question: SubFormQuestion | Select2Placeholder) => {
          return question.id ? `${question.title} (${question.code})` : (question as Select2Placeholder).text;
        },
      });
    }

    submit(): void {
      this.$nextTick(() => {
        this.validator?.validate().then((result: boolean) => {
          if (result) {
            this.$emit('submit', this.form);
          }
        });
      });
    }

    groupBySubformQuestionsResults(option: SubFormQuestion): string {
      return `${get(option, 'sub_form_section.sub_form.title')} ( ${get(option, 'sub_form_section.title')} )`;
    }

    beforeMount(): void {
      this.initForm();
    }

    initForm(): void {
      this.isCustomScoringEnabled = !!this.form.options?.custom_scoring_type && this.form.options.custom_scoring_type !== 'none';
      this.form = {
        ...this.questionSet,
      };
    }

    enableCustomScoring(enabled: boolean): void {
      if (enabled) {
        this.form = { ...this.form, options: { custom_scoring_type: CustomScoring.both } };
      }
    }
  }
