
  import { useAccountStore } from '@app/stores/account';
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { Location } from '@app/models/location';
  import type { ModuleName } from '@app/models/module-name';

  import EntitySelector from '../entity-selector.vue';

  import LocationSelector from './location-selector.vue';

  @Component({ components: { LocationSelector, EntitySelector, ValidationObserver, ValidationProvider } })
  export default class LocationNewForm extends Vue {
    @Prop(Boolean) showMoreText?: boolean;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<Location> = {};
    showMoreToggled = false;

    legacyModules: Pick<ModuleName, 'id' | 'name'>[] = [];

    get legacyCheck(): boolean {
      return !!this.legacyModules.length;
    }

    get accountStore() {
      return useAccountStore();
    }

    beforeMount(): void {
      this.$api
        .getModuleNames({ filters: { name: ['Hazard', 'Incident'], active: true }, only: ['id', 'name'] }, { cache: true })
        .then(({ data }) => {
          this.legacyModules = data;
        });
    }

    toggleShowMore(): void {
      this.showMoreToggled = !this.showMoreToggled;
    }

    onSubmit(): void {
      this.form = { ...this.form, hours_worked: this.form.hours_worked || 1 };
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }
  }
