
  import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
  import DsModal from '@app/components/ds-modal.vue';
  import QuestionSetForm from '@app/components/sub-form-question-set/question-set-form.vue';
  import type { ModuleName } from '@app/models/module-name';
  import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
  import type { ModalCloseCommand } from '@app/utils/types/modal-close-command';
  import { toaster } from '@app/utils/toaster';

  @Component({
    components: {
      DsModal,
      QuestionSetForm,
    },
  })
  export default class QuestionSetsFormModal extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleNameId!: number | string;
    @Prop({ type: [String, Number] }) readonly questionSetId?: number | string;
    @Ref() readonly questionSetForm?: QuestionSetForm;

    questionSet: Nullable<SubFormQuestionSet> = null;
    visible = true;
    moduleName: Nullable<ModuleName> = null;

    get title(): string {
      if (this.questionSetId) {
        return 'Edit Question Set';
      }
      return 'Add Question Set';
    }

    submitForm(questionSet: Partial<SubFormQuestionSet>): void {
      this.createUpdateQuestionSet(questionSet);
    }

    createUpdateQuestionSet(questionSet: Partial<SubFormQuestionSet>): void {
      const promise = questionSet?.id
        ? this.$api.updateQuestionSet(Number(questionSet?.id), questionSet)
        : this.$api.createQuestionSet(questionSet);
      promise
        .then(() => {
          this.closeModal('reload');
        })
        .catch(({ data }) => {
          toaster({ text: data.error, position: 'top-right', icon: 'error' });
        });
    }

    archive(): void {
      if (this.questionSetForm?.form?.id) {
        this.createUpdateQuestionSet({ ...this.questionSetForm.form, active: false });
      }
    }

    restore(): void {
      if (this.questionSetForm?.form?.id) {
        this.createUpdateQuestionSet({ ...this.questionSetForm.form, active: true });
      }
    }

    closeModal(command: ModalCloseCommand): void {
      this.$router.replace({
        name: 'admin-settings-module-names-question-sets',
        params: {
          moduleNameId: `${this.moduleNameId}`,
          reloadTable: command,
        },
        query: this.$route.query,
      });
    }

    async beforeMount(): Promise<void> {
      const { data } = await this.$api.getModuleName(Number(this.moduleNameId), { only: ['id', 'name'] }, { cache: true });
      this.moduleName = data;

      if (this.questionSetId) {
        this.$api
          .getQuestionSet(Number(this.questionSetId), { include: ['automation_definition_ids', 'sub_form_question_ids'] }, { cache: true })
          .then(({ data }) => {
            this.questionSet = data;
          });
      } else {
        (this.questionSet as Partial<SubFormQuestionSet>) = { module_name: this.moduleName.name };
      }
    }
  }
