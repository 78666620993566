
  import { Component, Ref } from 'vue-property-decorator';
  import AdvancedAutosuggest from '@app/components/advanced-autosuggest/advanced-autosuggest.vue';
  import type { LocationTag } from '@app/models/location-tag';
  import type { Location } from '@app/models/location';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';

  import EventInputMethodSwitch from '../event-input-method-switch.vue';
  import Select2 from '../../../select2.vue';
  import EntitySelector from '../../../entity-selector.vue';
  import FormField from '../../questions/edit/form-field.vue';

  import BaseEventForm from './base-event-form';

  interface FormValues {
    location_id: string | null;
    location_tag_id: string;
    user_id: string;
  }

  @Component({ components: { EntitySelector, Select2, EventInputMethodSwitch, AdvancedAutosuggest, FormField } })
  export default class UserLocationTagEventForm extends BaseEventForm<FormValues> {
    @Ref() readonly tagSelector?: EntitySelector<LocationTag>;

    locations: Pick<Location, 'id' | 'name'>[] = [];
    locationTags: Pick<LocationTag, 'id' | 'name' | 'allow_global'>[] = [];

    formReady = false;
    optionsSet = {
      getLocations: false,
      tagSelector: false,
    };

    get tagFilters(): DonesafeFilterOptions<LocationTag> {
      return {
        active: true,
      };
    }

    get locationOptions(): [string, string][] {
      return [
        ...(this.flatFieldOptionsRecord?.Location || []),
        ...this.locations.map((location) => [`${location.id}`, location.name] as [string, string]),
      ];
    }

    get allowGlobal(): boolean {
      return !!this.locationTags.find((tag) => `${tag.id}` === `${this.form.location_tag_id}`)?.allow_global;
    }

    tagSelectorOptionsLoaded(results: Pick<LocationTag, 'id' | 'name' | 'allow_global'>[]) {
      this.locationTags = results;
      this.optionsLoaded('tagSelector');
    }

    optionsLoaded(type: 'tagSelector' | 'getLocations'): void {
      this.optionsSet[type] = true;

      if (this.optionsSet.tagSelector && this.optionsSet.getLocations) {
        this.$nextTick(() => {
          this.formLoaded();
        });
      }
    }

    beforeMount(): void {
      this.form = { ...this.values };
      this.$api.getLocations({ active: true, sort: 'name', only: ['id', 'name'], per_page: -1 }, { cache: true }).then(({ data }) => {
        this.locations = data;
        this.optionsLoaded('getLocations');
      });
    }
  }
