
  import { Component, PropSync } from 'vue-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ReportGraphWidgetOptions } from '@app/models/widget';

  import EntitySelector from '../../../entity-selector.vue';

  import BaseWidgetSettings from './base-widget-settings';

  @Component({
    components: { EntitySelector, ValidationObserver, ValidationProvider },
  })
  export default class ReportGraphWidgetSettings extends BaseWidgetSettings<ReportGraphWidgetOptions> {
    @PropSync('options') widgetOptions!: Partial<ReportGraphWidgetOptions>;

    get superReportFilter(): object {
      return { report_blob: { '!klass': 'MongoTrails::Version' } };
    }

    beforeMount(): void {
      this.form = this.widget.options;
    }
  }
