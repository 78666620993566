
  import UserAdminPermissionsForm from '@app/components/admin/users/user-admin-permissions-form.vue';
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import { Component, Model, Prop, Watch, Ref, Vue } from 'vue-property-decorator';
  import EntitySelector from '../../entity-selector.vue';
  import UserSelector from '../../user/user-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { TenantUser } from '@app/models/tenant-user';
  import DsModal from '@app/components/ds-modal.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';

  @Component({
    components: {
      UserAdminPermissionsForm,
      DsCheckbox,
      UserSelector,
      EntitySelector,
      DsModal,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class FormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Object) readonly tenantUser!: TenantUser;
    userId: string | null = null;

    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;
    @Ref() readonly form?: InstanceType<typeof UserAdminPermissionsForm>;

    loadingSelectedUser = false;
    mode: 'loading' | 'create' | 'edit' = 'loading';

    localUser: Nullable<TenantUser> = null;

    get currentUserStore() {
      return useCurrentUserStore();
    }

    beforeMount(): void {
      this.initForm();
    }

    initForm(): void {
      this.userId = null;
      this.mode = this.tenantUser?.id ? 'edit' : 'create';
      this.localUser = { ...this.tenantUser };
    }

    submitForm(user: Partial<TenantUser>): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', user);
      });
    }

    // load existing features
    onUserSelect(userId: number): void {
      this.loadingSelectedUser = true;
      this.$api
        .getTenantUser(userId, { only: ['id', 'admin_permission_features', 'internal', 'admin'] }, { cache: true })
        .then(({ data }) => {
          this.localUser = data;
        })
        .finally(() => {
          this.loadingSelectedUser = false;
        });
    }

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.$nextTick(() => {
        this.validator?.reset();
      });
    }

    get title(): string {
      switch (this.mode) {
        case 'create':
          return this.$t('tenant.admin.settings.administrative_roles.modal.titles.create');
        case 'edit':
          return this.$t('tenant.admin.settings.administrative_roles.modal.titles.edit');
        case 'loading':
          return this.$t('app.labels.loading');
      }
    }
  }
