
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
  import VJsoneditor from 'v-jsoneditor';
  import EntitySelector from '../../entity-selector.vue';
  import UserSelector from '../../user/user-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { MobileAppLayout } from '@app/models/mobile-app/layout';

  @Component({ components: { UserSelector, EntitySelector, VJsoneditor, ValidationObserver, ValidationProvider } })
  export default class MobileAppLayoutForm extends Vue {
    @Prop(Object) readonly layout?: Partial<MobileAppLayout>;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<MobileAppLayout> = {};

    options = {
      mode: 'code',
      modes: ['code', 'tree', 'preview'], // allowed modes
    };

    submit(): void {
      this.validator?.validate().then((result: boolean) => {
        result && this.$emit('submit', this.form);
      });
    }

    beforeMount(): void {
      this.form = { grid: { direction: 'row', grid_items: [] }, ...this.layout };
    }
  }
