
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
  import type { Blob } from '@rails/activestorage';
  import Select2 from '../select2.vue';
  import FileUploader from '../file-uploader/file-uploader.vue';
  import AttachmentsUploader from '@app/components/attachment/attachments-uploader.vue';
  import EntitySelector from '../entity-selector.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { SubFormList } from '@app/models/sub-form-list';
  import type { SuperImport } from '@app/models/super-import';
  import type { SubForm } from '@app/models/sub-form';
  import type { DonesafeFilterOptions, OnlyOptions } from '@app/services/donesafe-api-utils';
  import { toaster } from '@app/utils/toaster';

  interface TranslatedStringOption {
    code: string;
    label: string;
  }

  interface TypeOption {
    code: string;
    label: string;
    model_name: string;
  }

  @Component({
    components: {
      Select2,
      EntitySelector,
      FileUploader,
      AttachmentsUploader,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class ImportForm extends Vue {
    @Prop(Number) readonly importId?: number;
    @Prop(String) readonly importType?: SuperImport['import_type'];
    @Prop(Array) readonly subFormListIds?: number[];
    @Prop(Array) readonly importOptions?: TypeOption[];
    @Ref() readonly attachmentsUploader?: AttachmentsUploader;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<SuperImport & { files: string[] }> = { options: {} };
    blobs: Blob[] = [];
    subForms: SubForm[] = [];
    fetchingData = false;
    selectedSubFormList: Partial<SubFormList> = {};
    submitting = false;

    get importTypes(): TranslatedStringOption[] | TypeOption[] {
      return this.importType === 'ModuleRecord' ? this.moduleRecordImportTypes : this.importOptions || [];
    }

    get moduleRecordImportTypes(): TranslatedStringOption[] {
      return [
        {
          code: 'ModuleRecord',
          label: this.$t('tenant.admin.module_names.sections.tab_importer.main_form_type'),
        },
        {
          code: 'SubFormCompletion',
          label: this.$t('tenant.admin.module_names.sections.tab_importer.sub_form_type'),
        },
        {
          code: 'Attachment',
          label: this.$t('tenant.admin.module_names.sections.tab_importer.attachment_type'),
        },
      ];
    }

    get showSubFormSelector(): boolean {
      if (this.form.import_type !== 'SubFormCompletion' || !this.form.sub_form_list_id || this.fetchingData) {
        return false;
      }

      return this.subForms.length > 1;
    }

    get disableSubmitForEmptySubForms(): boolean {
      return this.fetchingData || (this.form.import_type === 'SubFormCompletion' && !!this.form.sub_form_list_id && !this.subForms.length);
    }

    get isSuperAdmin(): boolean {
      return useCurrentUserStore().isDefaultTechAdmin;
    }

    get subFormListFilter(): DonesafeFilterOptions<SubFormList> {
      return {
        id: this.subFormListIds || [],
        active: true,
        sub_form_list_type: ['normal', 'approval', 'workflow'],
      };
    }

    beforeMount(): void {
      this.resetForm();
    }

    resetForm(): void {
      this.submitting = false;
      this.attachmentsUploader?.reset();
      this.form = {
        files: [],
        options: {},
        import_type: this.importType,
        import_id: this.importId,
        sub_form_list_id: undefined,
        method: 'manual',
      };
      this.validator?.reset();
    }

    onSubFormListSelect(subFormListId?: string): void {
      if (!subFormListId) return;

      this.fetchingData = true;
      this.subForms = [];
      this.form = { ...this.form, import_id: undefined };
      const only: OnlyOptions<SubFormList> = ['id', { sub_forms: ['id', 'title', 'active'], approval_sub_form: ['id', 'title', 'active'] }];
      this.$api
        .getSubFormList(Number(subFormListId), { only }, { cache: true })
        .then(({ data }) => {
          this.selectedSubFormList = data;
          let subForms = data.sub_forms || [];
          if (data.approval_sub_form) {
            subForms = [...subForms, data.approval_sub_form];
          }
          this.subForms = subForms.filter((subForm) => subForm.active);
          this.fetchingData = false;
        })
        .catch(({ data }) => {
          toaster({ text: data?.error, position: 'top-right', icon: 'error' });
        });
    }

    setMainForm(): void {
      if (this.form.import_type !== 'ModuleRecord') {
        return;
      }

      this.form = { ...this.form, import_id: this.importId, sub_form_list_id: undefined };
    }

    createBlob(blob: Blob): void {
      this.form = {
        ...this.form,
        files: [...(this.form.files || []), blob.signed_id],
      };
      this.validator?.reset();
    }

    deleteBlob(blob: Blob): void {
      this.form = {
        ...this.form,
        files: this.form.files?.filter((signed_id) => signed_id === blob.signed_id) || [],
      };
    }

    submitForm(): void {
      if (this.submitting) return;

      if (this.form.import_type === 'SubFormCompletion' && !this.showSubFormSelector) {
        this.form = { ...this.form, import_id: this.subForms[0]?.id };
      }

      this.validator?.validate().then((result: boolean) => {
        if (result) {
          this.submitting = true;
          this.$emit('submit', this.form);
        }
      });
    }
  }
