
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Icon, addIcon } from '@iconify/vue2/dist/offline';
  import starCircle from '@iconify-icons/mdi/star-circle';
  import minusCircle from '@iconify-icons/mdi/minus-circle';
  import warning from '@iconify-icons/mdi/warning';

  addIcon('mdi:star-circle', starCircle);
  addIcon('mdi:minus-circle', minusCircle);
  addIcon('mdi:alert', warning);

  @Component({ components: { Icon } })
  export default class ActionIcons extends Vue {
    @Prop() readonly displayIcon?: boolean;
    @Prop() readonly deleteIcon?: boolean;
    @Prop() readonly warningIcon?: boolean;
    @Prop() readonly errors?: string[];

    get warningTitle(): string {
      return `This formula includes the following error(s): ${this.errors?.join(', ')}`;
    }
  }
