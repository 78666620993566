
  import { compact, debounce, isEmpty } from 'lodash';
  import type { ParsedQs } from 'qs';
  import qs from 'qs';
  import { Tooltip } from 'uiv';
  import { mixins } from 'vue-class-component';
  import { Component, Prop } from 'vue-property-decorator';
  import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
  import DateTimePicker from '../date-time-picker.vue';
  import DsDropdown from '../ds-dropdown.vue';
  import FilterSelect from '../filter-select.vue';
  import GlobalDateFilter from '../filters/global-date-filter.vue';
  import { processObjectChanges, showVersion } from '../paper-trails/mapping-utils';
  import type { CustomFiltersValue } from '../paper-trails/paper-trails-base';
  import PaperTrailsBase from '../paper-trails/paper-trails-base';
  import PaperTrailsPagination from '../paper-trails/paper-trails-pagination.vue';
  import PaperTrailsNoTableItem from '../paper-trails/table/paper-trails-no-table-item.vue';
  import RecordIndexPageFiltersLayout from '../record-index-page-filters-layout.vue';
  import SearchInput from '../search-input.vue';
  import Select2 from '../select2.vue';
  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
  import { DEFAULT_FILTERS, type PaperTrailVersion, type PaperTrailsFilters } from '@app/models/paper-trail-version';

  @Component({
    components: {
      PaperTrailsNoTableItem,
      FilterSelect,
      Select2,
      GlobalDateFilter,
      RecordIndexPageFiltersLayout,
      SearchInput,
      DateTimePicker,
      DsDropdown,
      Tooltip,
      DynamicScroller,
      DynamicScrollerItem,
      PaperTrailsPagination,
    },
  })
  export default class AdminAuditTrailsPage extends mixins(PaperTrailsBase) {
    @Prop({ type: String, default: () => 'Audit Trail' }) readonly title!: string;
    @Prop({ type: Number, default: () => 1000 }) readonly limitPerPage!: number;

    debouncedCallback: ((key: string, value: string) => void) | null = null;
    loading = true;
    customFilters: PaperTrailsFilters = DEFAULT_FILTERS;

    currentPage = 1;
    totalPage: Nullable<number> = null;
    totalCount: Nullable<number> = null;
    sortDirection: 'asc' | 'desc' = 'desc';
    minItemSize = 35;
    buffer = 1000;

    showVersion(version: PaperTrailVersion): boolean {
      const userLink = this.userLinksMap[version.whodunnit];
      return showVersion(
        version,
        this.customFilters,
        this.accountStore.data,
        () => compact([userLink?.title, userLink?.subTitle]),
        () => this.metaDataMap[version.id],
        this.$t
      );
    }

    get filteredVersions(): PaperTrailVersion[] {
      return this.versions.filter((version) => this.showVersion(version));
    }

    fetchVersions(): Promise<void> {
      this.fetchAttributes(this.recordId, this.recordType);
      const whodunnit = this.customFilters.user.map((u) => u.split('|')).flat();
      const objectChanges = this.customFilters.attributes.map((u) => u.split('|')).flat();
      return this.$api
        .getPaperTrails(
          {
            item_id: this.recordId,
            item_type: this.recordType,
            filters: {
              whodunnit,
              event: this.customFilters.event,
              item_type: this.customFilters.item_type,
              created_at: this.customFilters.created_at,
              '@object_changes': objectChanges,
            },
            with_children: true,
            sort: this.sortDirection === 'asc' ? 'created_at' : '-created_at',
            per_page: this.limitPerPage,
            page: this.currentPage,
          },
          { cache: true }
        )
        .then(({ data: versions, headers }) => {
          this.totalCount = +headers.total;
          this.totalPage = Math.ceil(+headers.total / +headers['per-page']);
          // Checks if we can show the version and filter unsupported attributes
          this.versions = versions.filter((version) => !isEmpty(processObjectChanges(version)));
          this.versions.forEach((version) => {
            this.fetchVersionData(version);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    initForm(): void {
      this.loading = true;
      this.currentPage = 1;
    }

    onCustomFiltersChanged(key: keyof PaperTrailsFilters, value: CustomFiltersValue): void {
      this.$nextTick(() => {
        this.initForm();
        this.customFilters[key] = value;
        this.fetchVersions();
      });
    }

    onSortDirectionChanged(): void {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
      this.onPageChanged();
    }

    onSearch(text: string): void {
      this.debouncedCallback && this.debouncedCallback(text, 'search');
    }

    reFetchData(): void {
      this.$api.cache.clear();
      this.initForm();
      this.fetchVersions();
    }

    onPageChanged(page = 1): void {
      this.initForm();
      this.currentPage = page;
      this.fetchVersions();
    }

    beforeMount(): void {
      this.currentPage = this.page;
      this.customFilters.search = this.search;
      this.debouncedCallback = debounce((...args) => {
        const filterKey = args[1];
        const filterValue = args[0];
        this.customFilters = {
          ...this.customFilters,
          [filterKey]: filterValue,
        };
      }, 250);

      this.fetchVersions();
    }

    get params(): ParsedQs {
      return qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    get recordId(): string {
      return this.params.recordId as string;
    }

    get recordType(): string {
      return this.params.recordType as string;
    }

    get page(): number {
      return Number(this.params.page || '1');
    }

    get presentResult(): boolean {
      return !!this.versions.length;
    }

    get search(): string {
      return (this.params.search as string) || '';
    }
  }
