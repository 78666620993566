
  import { Component, Prop, Vue, Model, Watch, Ref } from 'vue-property-decorator';
  import Select2 from '@app/components/select2.vue';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import type { ModuleTabExport } from '@app/models/module-tab-export';
  import type { SubForm } from '@app/models/sub-form';
  import { HYBRID_MODULE_TAB_CODE } from '@app/models/module-tab';
  import { toaster } from '@app/utils/toaster';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { Select2, DsModal, ValidationObserver, ValidationProvider } })
  export default class ExportFormModal extends Vue {
    @Model('input') readonly value!: boolean;
    @Prop(Number) readonly moduleNameId!: number;
    @Ref() readonly validator?: InstanceType<typeof ValidationObserver>;

    form: Partial<ModuleTabExport> = {};
    init = false;
    submitting = false;
    fetching = true;
    subForms: SubForm[] = [];

    @Watch('value', { immediate: true })
    watchModalVisible(value: boolean): void {
      value && this.initForm();
      this.init = value;
      this.validator?.reset();
    }

    initForm(): void {
      this.fetchSubForms();
    }

    fetchSubForms(): void {
      this.$api
        .getModuleNames(
          {
            filters: { id: this.moduleNameId, active: true },
            include: ['module_tabs'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          const moduleName = data[0];
          if (!moduleName) {
            toaster({
              text: this.$t('errors.export_form.module_name_could_not_find'),
              position: 'top-right',
              icon: 'error',
            });
            return;
          }

          const moduleTab = moduleName.module_tabs?.find((module_tab) => module_tab.code === HYBRID_MODULE_TAB_CODE);
          if (!moduleTab) {
            toaster({
              text: this.$t('errors.export_form.module_tab_could_not_find'),
              position: 'top-right',
              icon: 'error',
            });
            return;
          }

          this.$api
            .getSubFormLists(
              {
                per_page: -1,
                only: ['sub_forms'],
                filters: { module_tab_id: moduleTab.id },
                include: ['sub_forms'],
              },
              { cache: true }
            )
            .then(({ data }) => {
              this.subForms = data.map((list) => (list.sub_forms || [])[0]).filter((subForm) => subForm);
              this.form = {
                id: moduleTab.id,
                sub_form_ids: this.subForms.filter((v) => !!v).map((subForm) => subForm.id),
              };
              this.fetching = false;
            });
        });
    }

    submitForm(): void {
      this.validator?.validate().then((result: boolean) => {
        if (result && this.form.id) {
          this.submitting = true;
          this.$api
            .getModuleTabExport({ id: this.form.id, sub_form_ids: this.form.sub_form_ids })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', response.headers['content-disposition'].split('=')[1]);
              document.body.appendChild(link);
              link.click();
              this.submitting = false;
            })
            .catch(({ data }) => {
              toaster({ text: data?.error, position: 'top-right', icon: 'error' });
            });
        }
      });
    }

    selectOrClearAll(): void {
      const ids = this.form.sub_form_ids?.length ? [] : this.subForms.map((subForm) => subForm.id);
      this.form = { ...this.form, sub_form_ids: ids };
    }

    get selectOrClearAllTitle(): string {
      return this.form.sub_form_ids?.length ? this.$t('app.labels.clear_all') : this.$t('app.labels.select_all');
    }
  }
