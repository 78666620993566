
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleTab } from '@app/models/module-tab';
  import { changeLocation } from '@app/utils/change-location';

  @Component({})
  export default class ModuleTabRouter extends Vue {
    @Prop({ type: [String, Number] }) readonly moduleTabId!: string | number;
    beforeMount(): void {
      this.$api
        .getModuleTab(Number(this.moduleTabId), { only: ['id', { related_module_name: ['id'] }] }, { cache: true })
        .then(({ data }) => this.redirectUrl(data))
        .catch(() => changeLocation('/'));
    }

    redirectUrl(moduleTab: ModuleTab): void {
      const basePath = `/admin/settings/module_names/${moduleTab.related_module_name?.id}/module_tabs/${moduleTab.id}`;
      const childPath = this.$route.query.path || '';
      changeLocation(`${basePath}${childPath}`);
    }
  }
