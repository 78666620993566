import { render, staticRenderFns } from "./import-instructions.vue?vue&type=template&id=93cf4380&scoped=true&lang=pug"
import script from "./import-instructions.vue?vue&type=script&lang=ts"
export * from "./import-instructions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93cf4380",
  null
  
)

export default component.exports