
  import { Component, Model, Vue, Watch } from 'vue-property-decorator';
  import HideQuestion from '@app/components/admin/questions/settings/shared/hide-question.vue';
  import AttachmentsUploader from '@app/components/attachment/attachments-uploader.vue';
  import type { Attachment } from '@app/models/attachment';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { DisplayImageQuestionOptions } from '@app/models/question-options/display-image-question-options';

  import FormField from './form-field.vue';

  @Component({ components: { HideQuestion, AttachmentsUploader, FormField } })
  export default class DisplayImageFieldOptions extends Vue {
    @Model('input') readonly value!: SubFormQuestion<DisplayImageQuestionOptions>;

    edit = false;

    get imageUrl(): string {
      return [...(this.value.attachments || [])].reverse()[0]?.url;
    }

    get showUploader() {
      return this.edit || !this.value.id || !this.value.attachments;
    }

    @Watch('value.updated_at')
    onSave() {
      this.edit = false;
    }

    onAttachmentInput(input: string[]) {
      this.value.attachments = input.map((id) => ({ id })) as unknown as Attachment[];
    }

    scrubFields() {
      ['max_height', 'alt_text'].forEach((key) => this.$delete(this.value.config, key));
    }

    beforeMount() {
      this.$$patch(this.value.config, { max_height: '400' }, { ifEmpty: true });
    }

    beforeDestroy() {
      this.scrubFields();
    }
  }
